import React from 'react';
import classNames from 'classnames';
import { REPORT_LABOR_INSURANCE_SUMMARIES } from 'src/constants/EndpointUrls';
import styles from './DeclarationFormInsuranceHatching2022.scss';

const Hatching2022 = props => {
  if (props.isDisplay) {
    return (
      <div className={classNames(styles.hatching, styles.hatchingHeight)}>
        <p className={styles.annotation}>
          こちらは
          <a href={REPORT_LABOR_INSURANCE_SUMMARIES} className={styles.strLink}>
            算定基礎賃金集計表
          </a>
          の確定保険料算定内訳の値が反映されます
        </p>
      </div>
    );
  }
  return null;
};

export default Hatching2022;
