import contentDisposition from 'content-disposition';
import saveAs from 'file-saver';

import { FLAT_TAX_REDUCTION_LEDGER_CSV } from 'src/constants/EndpointUrls';
import { serializeHttpGetParams } from 'src/utils/Http';
import { setGlobalErrors } from '../global';

const downloadCsv = async (dispatch, encoding) => {
  const url = FLAT_TAX_REDUCTION_LEDGER_CSV;

  const params = { encoding };
  await fetch(`${url}?${serializeHttpGetParams(params)}`)
    .then(response => {
      if (response.ok) {
        response.blob().then(blob => {
          const disposition = contentDisposition.parse(response.headers.get('content-disposition') || '');
          saveAs(blob, disposition.parameters.filename);
        });
      } else {
        // サーバーエラー
        response.json().then(json => {
          dispatch(setGlobalErrors(json.errors.messages));
        });
      }
    })
    .catch(exception => {
      // 通信エラー
      // eslint-disable-next-line no-console
      console.warn(exception.message);
      dispatch(setGlobalErrors(['通信エラーが発生しました。', '通信状態の良い環境で再度お試しください。']));
    });
};

// eslint-disable-next-line import/prefer-default-export
export { downloadCsv };
