import React, { useState, useEffect } from 'react';
import _ from 'lodash';

import { ArrowDownSquare, ArrowRightSquare } from 'jbc-front/components/icons';
import LoadingView from 'src/components/LoadingView';
import { toMoneyFormat } from 'src/utils/Utils';
import styles from './PayWithInhabitantTable.scss';

const SummaryRow = ({ summary, total, changeExpand, isExpandingCity }) => (
  <tr>
    <td className={styles.expandArrowCell}>
      <span onClick={() => changeExpand(summary.cityId)} className={styles.icon} role="presentation">
        {isExpandingCity(summary.cityId) ? <ArrowDownSquare size={15} /> : <ArrowRightSquare size={15} />}
      </span>
    </td>
    <td>{summary.cityWithPrefecture}</td>
    <td>{summary.organizationCode}</td>
    <td>{summary.obligorDesignationNumber}</td>
    <td>{total}</td>
    <td />
    <td>{toMoneyFormat(summary.totalAmount)}</td>
    <td />
  </tr>
);

const DetailRow = ({ detail, isExpandingCity, bottom }) => (
  <React.Fragment>
    <tr style={{ display: isExpandingCity(detail.cityId) ? '' : 'none' }} className={styles.detail}>
      <td className={bottom ? styles.bottom : styles.first} />
      <td className={styles.second} colSpan={4} />
      <td>{detail.employeeName}</td>
      <td>{toMoneyFormat(detail.amount)}</td>
      <td>{detail.resignedOn ? detail.resignedOn : ''}</td>
    </tr>
    {bottom && (
      <tr style={{ display: isExpandingCity(detail.cityId) ? '' : 'none' }} className={styles.blank}>
        <td colSpan={8} />
      </tr>
    )}
  </React.Fragment>
);

const PayWithInhabitantRow = ({ index, tax, total, changeExpand, isExpandingCity }) => {
  if (tax.isSummary) {
    return <SummaryRow summary={tax} total={total} changeExpand={changeExpand} isExpandingCity={isExpandingCity} />;
  }
  if (index === total) {
    return <DetailRow detail={tax} isExpandingCity={isExpandingCity} bottom />;
  }
  return <DetailRow detail={tax} isExpandingCity={isExpandingCity} />;
};

const PayWithInhabitantTable = ({
  employeePayWithInhabitants,
  totalAmounts,
  totalEmployees,
  totalResigned,
  cityIds,
  isListLoading
}) => {
  const [expandingCityIds, setExpandingCityIds] = useState([]);
  const [isExpandAll, setIsExpandAll] = useState(true);

  const changeExpand = cityId => {
    const index = expandingCityIds.indexOf(cityId);
    // render走らせるためshallow copy
    const ids = expandingCityIds.slice();
    if (index !== -1) {
      ids.splice(index, 1);
      setIsExpandAll(false);
    } else {
      ids.push(cityId);
      setIsExpandAll(true);
    }

    setExpandingCityIds(ids);
  };

  const changeExpandAll = () => {
    if (expandingCityIds.length === cityIds.length || isExpandAll) {
      // 以下のケースは全て閉じる
      //   - 全て展開されている場合
      //   - 一部だけ展開している 且つ アイコンが展開済みの場合
      setIsExpandAll(false);
      setExpandingCityIds([]);
    } else {
      // 以下のケースは全て開く
      //   - 全て閉じている場合
      //   - 一部だけ展開している 且つ アイコンが未展開の場合
      setIsExpandAll(true);
      setExpandingCityIds(cityIds);
    }
  };

  const isExpandingCity = cityId => expandingCityIds.indexOf(cityId) !== -1;

  useEffect(
    () => {
      if (!_.isEmpty(cityIds)) {
        setExpandingCityIds(cityIds);
      }
    },
    [cityIds]
  );

  return (
    <LoadingView isLoading={isListLoading}>
      <div className="l-overflow-scroll">
        <table className="m-table-list m-table-fixed">
          <thead>
            <tr className="table-filled">
              <th width={30}>
                <span onClick={() => changeExpandAll()} className={styles.icon} role="presentation">
                  {isExpandAll ? <ArrowDownSquare size={15} /> : <ArrowRightSquare size={15} />}
                </span>
              </th>
              <th width={190}>納付先市区町村</th>
              <th width={100}>市区町村コード</th>
              <th width={98}>指定番号</th>
              <th width={67}>人数</th>
              <th width={230}>氏名</th>
              <th width={153}>徴収税額</th>
              <th width={90}>退職日</th>
            </tr>
          </thead>
          <tbody>
            {(!employeePayWithInhabitants || employeePayWithInhabitants.length === 0) && (
              <tr>
                <td colSpan="8">
                  住民税の納付先が設定されていないため対象の従業員が表示されません。
                  <br />
                  従業員情報の「住民税」の項目で納付先を設定してください。
                </td>
              </tr>
            )}
            {employeePayWithInhabitants.map(pay =>
              pay.employeeWithTaxes.map((tax, index) => (
                <PayWithInhabitantRow
                  key={tax.id}
                  tax={tax}
                  index={index}
                  total={pay.employeeWithTaxes.length - 1}
                  changeExpand={changeExpand}
                  isExpandingCity={isExpandingCity}
                />
              ))
            )}
            <tr className={`${styles.total} table-filled`}>
              <td colSpan={2} className="u-ta-l">
                合計
              </td>
              <td colSpan={2} />
              <td>{totalEmployees}人</td>
              <td />
              <td>{toMoneyFormat(totalAmounts)}</td>
              <td>{totalResigned}人</td>
            </tr>
          </tbody>
        </table>
      </div>
    </LoadingView>
  );
};

export default PayWithInhabitantTable;
