import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS, CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';

import { PAYROLL_RULES_GROUP_FORM } from 'src/constants/FormNames';

import { getItem, getItemErrors, setItem, mergeItemFields } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, newPayrollRulesGroup } from 'src/reducers/clients/payrollRulesGroups';

import PayrollRulesGroupForm from './containers/PayrollRulesGroupForm';

let NewPayrollRulesGroupForm = reduxForm({
  form: PAYROLL_RULES_GROUP_FORM
})(PayrollRulesGroupForm);

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    ...getItem(REDUCER_NAME, state),
    prescribedWorkingHoursPerDay: _.get(getItem(REDUCER_NAME, state), 'prescribedWorkingHoursPerDay') || '8:00',
    avgRegularWorkingDaysPerMonth: _.get(getItem(REDUCER_NAME, state), 'avgRegularWorkingDaysPerMonth') || '0.00',
    avgRegularWorkingHoursPerMonth: _.get(getItem(REDUCER_NAME, state), 'avgRegularWorkingHoursPerMonth') || '0:00'
  },
  isNewForm: true,
  isCopyForm: ownProps.isCopyForm,
  errors: getItemErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(newPayrollRulesGroup(data));
  }
});

NewPayrollRulesGroupForm = connect(mapStateToProps, mapDispatchToProps)(NewPayrollRulesGroupForm);

const NewPayrollRulesGroup = ({ dispatch, clientPayrollRulesGroup, copyOrgId, match }) => {
  const { path } = match;
  const isCopyForm = path.match(/copy/);

  dispatch(setItem(REDUCER_NAME, clientPayrollRulesGroup));
  dispatch(mergeItemFields(REDUCER_NAME, { copyOrgId }));

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">給与規定新規追加</h1>
      </div>
      <div className="l-wrap-xs">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="給与規定グループ一覧" path={CLIENT_PAYROLL_RULES_GROUP_LIST} />
            <BreadCrumb.Item label="給与規定新規追加" />
          </BreadCrumb>
        </div>
        <NewPayrollRulesGroupForm isCopyForm={isCopyForm} />
      </div>
    </div>
  );
};

export default connect()(NewPayrollRulesGroup);
