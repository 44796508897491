import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { ErrorsSection } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { EMPLOYEE_PAY_ALL_ROLLBACK_FORM } from 'src/constants/FormNames';
import { allRollbackEmployeePay, REDUCER_NAME } from 'src/reducers/employees/employeePays';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from './AllRollbackEmployeePayModal.scss';

const AllRollbackEmployeePayInsideForm = ({
  submitting,
  submit,
  cancelBtnClick,
  errors,
  header,
  yearMonth,
  uniquePaymentDates
}) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form className={styles.wrap}>
        <ErrorsSection errors={errors} />
        <div>
          {_.get(_.find(uniquePaymentDates, { value: yearMonth }), 'label')}給与の対象者全員に対して「手入力前に戻す」を行います。
        </div>
        <ul className={styles.notes}>
          <li>
            ※「手入力されている値」「CSVで入力されている値」が削除され、現在の設定を基に自動計算された値が入ります。
          </li>
          <li>※既に個別に確定処理を行なっている従業員の給与データは更新されません</li>
        </ul>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="実行" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let AllRollbakcEmployeePayForm = reduxForm({
  form: EMPLOYEE_PAY_ALL_ROLLBACK_FORM
})(AllRollbackEmployeePayInsideForm);

const mapStateToProp = state => ({
  errors: getItemErrors(REDUCER_NAME, state).rollbackEmployeePay,
  uniquePaymentDates: getSelectOptions(state, 'uniquePaymentDates')
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(allRollbackEmployeePay(ownProps.payrollRulesGroups, ownProps.yearMonth))
});

AllRollbakcEmployeePayForm = connect(mapStateToProp, mapDispatchToProp)(AllRollbakcEmployeePayForm);

const AllRollbackEmployeePayModal = ({ isOpen, hideModal, payrollRulesGroups, yearMonth }) => {
  const header = '一括で手入力前に戻す';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <AllRollbakcEmployeePayForm
        cancelBtnClick={hideModal}
        header={header}
        payrollRulesGroups={payrollRulesGroups}
        yearMonth={yearMonth}
      />
    </CommonModal>
  );
};

export default connect()(AllRollbackEmployeePayModal);
