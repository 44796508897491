import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';

import { ErrorsSection, LoadingWithDescription } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { REPORT_LABOR_INSURANCE_DECLARATION_RECALCULATION_FORM } from 'src/constants/FormNames';
import {
  recalculateReportLaborInsuranceDeclarationForm,
  getRecalculateReportLaborInsuranceDeclarationFormErrors
} from 'src/reducers/reports/reportLaborInsurances';

const ConfirmRecalcurateDeclarationInsideForm = ({
  handleSubmit,
  submitting,
  submit,
  cancelBtnClick,
  errors,
  header
}) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        {submitting && <LoadingWithDescription text="再計算中です。少々お待ちください。" />}
        {!submitting && (
          <div>
            算定基礎賃金集計表を基に再計算を行います。<br />労働保険申告書の編集したデータは失われますがよろしいですか。
          </div>
        )}
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="再計算" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let ConfirmRecalcurateDeclarationForm = reduxForm({
  form: REPORT_LABOR_INSURANCE_DECLARATION_RECALCULATION_FORM
})(ConfirmRecalcurateDeclarationInsideForm);
const mapStateToProp = state => ({
  errors: getRecalculateReportLaborInsuranceDeclarationFormErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(recalculateReportLaborInsuranceDeclarationForm(ownProps.laborInsuranceYear, ownProps.applicableOffice))
});

ConfirmRecalcurateDeclarationForm = connect(mapStateToProp, mapDispatchToProp)(ConfirmRecalcurateDeclarationForm);

class ConfirmRecalcurateDeclarationFormModal extends React.Component {
  render() {
    const header = '再計算';
    const { isOpen, hideModal, laborInsuranceYear, applicableOffice } = this.props;
    return (
      <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, laborInsuranceYear, applicableOffice }}>
        <ConfirmRecalcurateDeclarationForm
          cancelBtnClick={hideModal}
          header={header}
          laborInsuranceYear={laborInsuranceYear}
          applicableOffice={applicableOffice}
        />
      </CommonModal>
    );
  }
}

export default ConfirmRecalcurateDeclarationFormModal;
