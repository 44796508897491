import React from 'react';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { ErrorsSection } from 'src/components';
import styles from './ConfirmModal.scss';

const UnconfirmOneForm = ({ hideModal, errors, header, onSubmit }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form className={styles.wrap}>
        <ErrorsSection errors={errors} />
        <div>
          個別の確定解除を行います。<br />
          現在表示中の従業員のみ確定解除されます。<br />
          全体を確定したい場合は給与一覧画面で「確定解除」を押して下さい。
        </div>
        <ul className={styles.caution}>
          <li>【注意】</li>
          <li>勤怠・支給・控除項目は現在の情報を基に再計算されます。</li>
          <li>ただし、手入力した値やCSVで更新した値は変更されません。</li>
        </ul>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <Button primary onClick={onSubmit}>
        確定解除
      </Button>
    </CommonModal.Footer>
  </React.Fragment>
);

const UnconfirmOneModal = ({ isOpen, hideModal, onSubmit }) => {
  const header = '確定解除';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <UnconfirmOneForm hideModal={hideModal} header={header} onSubmit={onSubmit} />
    </CommonModal>
  );
};

export default UnconfirmOneModal;
