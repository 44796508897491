import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { SEARCH_POSTAL_CODE_URL } from 'src/constants/EndpointUrls';
import { TextField, BoxDouble, SelectField } from 'jbc-front/components/Form';
import { PostalCodeField } from 'src/components';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { setGlobalErrors } from 'src/reducers/global';

export const updateField = (field, value) => {
  const { _reduxForm } = field.context;
  const { autofill, sectionPrefix } = _reduxForm;
  const { name } = field.props;
  const inputName = !sectionPrefix ? name : `${sectionPrefix}.${name}`;
  autofill(inputName, value);
};

class Address extends React.Component {
  static defaultProps = {
    withKana: true
  };

  componentWillUnmount() {
    this._prefectureField = null;
    this._houseNumberField = null;
    this._cityField = null;
    this._kanaField = null;
  }

  handleChange = async (event, value) => {
    const { withKana, prefectureOptions } = this.props;

    if (
      event.target.name &&
      event.target.form &&
      ((event.target.name.match(/.*postalCodePart0/) && value.length === 3) ||
        (event.target.name.match(/.*postalCodePart1/) && value.length === 4))
    ) {
      const prefix = event.target.name.match(/(.*postalCodePart)\d/)[1];
      const postcode0 = event.target.form.elements[`${prefix}0`].value;
      const postcode1 = event.target.form.elements[`${prefix}1`].value;
      const postcode = `${postcode0}${postcode1}`;
      if (postcode.match(/\d{7}/)) {
        try {
          const response = await axios.get(SEARCH_POSTAL_CODE_URL, { params: { code: postcode } });
          const address = response.data.payload.postalInfo;

          if (
            this._prefectureField &&
            this._houseNumberField &&
            this._cityField &&
            address &&
            +postcode === address.postcode
          ) {
            const prefecture = prefectureOptions.find(
              prefectureOption => prefectureOption.value === address.prefectureId
            );
            if (prefecture) updateField(this._prefectureField, prefecture.value);
            updateField(this._cityField, address.city);
            updateField(this._houseNumberField, address.street);
            if (withKana && this._kanaField) {
              updateField(this._kanaField, address.prefectureKana + address.cityKana + address.streetKana);
            }
          }
        } catch (err) {
          this.props.dispatch(setGlobalErrors('入力した郵便番号によって場所が見つけませんでした。'));
        }
      }
    }
  };

  render() {
    const {
      required,
      prefix = '',
      withKana,
      prefectureOptions,
      postCodeRequired = false,
      disabled = false
    } = this.props;
    return (
      <div>
        <PostalCodeField
          required={postCodeRequired}
          prefix={prefix}
          handleOnChange={this.handleChange}
          disabled={disabled}
        />
        <BoxDouble>
          <SelectField
            name={`${prefix}prefectureId`}
            label="都道府県"
            options={prefectureOptions}
            required={required}
            disabled={disabled}
            refField={field => {
              this._prefectureField = field;
            }}
          />
          <TextField
            name={`${prefix}city`}
            label="市区町村"
            required={required}
            disabled={disabled}
            refField={field => {
              this._cityField = field;
            }}
          />
          <TextField
            name={`${prefix}street`}
            label="丁目番地号"
            required={required}
            disabled={disabled}
            refField={field => {
              this._houseNumberField = field;
            }}
          />
          <TextField name={`${prefix}building`} label="建物名" disabled={disabled} />
        </BoxDouble>
        {withKana && (
          <TextField
            name={`${prefix}addressKana`}
            label="住所カナ"
            disabled={disabled}
            refField={field => {
              this._kanaField = field;
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(state => ({
  prefectureOptions: getSelectOptions(state, 'prefectures')
}))(Address);
