import _ from 'lodash';

export const convertQueryToFormFieldOfDetails = (fieldName, value) => {
  switch (fieldName) {
    case 'office_ids':
      return { offices: value.replace(/_/g, ',') };
    case 'group_ids':
      return { groups: value.replace(/_/g, ',') };
    case 'position_ids':
      return { positions: value.split('_') };
    case 'employment_type_ids':
      return { employmentTypes: value.split('_') };
    case 'occupation_ids':
      return { occupations: value.split('_') };
    case 'memo_color_ids':
      return { memoColors: value.split('_') };
    case 'payroll_rule_group':
      return { payrollRuleGroup: value };
    case 'no_memo':
      return { noMemo: value };
    case 'q':
      return { q: value };
    case 'other_search':
      return { otherSearch: value };
    case 'bonus_target':
      return { bonusTarget: value };
    case 'my_number_status':
      return { myNumberStatus: value };
    case 'sync_yea':
      return { syncYea: value };
    case 'save_status':
      return { saveStatus: value };
    case 'is_confirmed_payroll':
      return { isConfirmedPayroll: value };
    case 'is_confirmed':
      return { isConfirmed: value };
    case 'is_electronic_delivery_agreement':
      return { isElectronicDeliveryAgreement: value.split('_') };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfDetails = (fieldName, value) => {
  switch (fieldName) {
    case 'filterType':
      return !_.isEmpty(value) ? { filter_type: value } : null;
    case 'offices': {
      return !_.isEmpty(value) ? { office_ids: value.replace(/,/g, '_') } : null;
    }
    case 'groups': {
      return !_.isEmpty(value) ? { group_ids: value.replace(/,/g, '_') } : null;
    }
    case 'positions':
      return !_.isEmpty(value) ? { position_ids: value.join('_') } : null;
    case 'employmentTypes':
      return !_.isEmpty(value) ? { employment_type_ids: value.join('_') } : null;
    case 'occupations':
      return !_.isEmpty(value) ? { occupation_ids: value.join('_') } : null;
    case 'memoColors':
      return !_.isEmpty(value) ? { memo_color_ids: value.join('_') } : null;
    case 'payrollRuleGroup':
      return !_.isEmpty(value) ? { payroll_rule_group: value } : null;
    case 'noMemo':
      return !_.isEmpty(value) ? { no_memo: value } : null;
    case 'q':
      return !_.isEmpty(value) ? { q: value } : null;
    case 'otherSearch':
      return !_.isEmpty(value) ? { other_search: value } : null;
    case 'bonusTarget':
      return !_.isEmpty(value) ? { bonus_target: value } : null;
    case 'myNumberStatus':
      return !_.isEmpty(value) ? { my_number_status: value } : null;
    case 'syncYea':
      return !_.isEmpty(value) ? { sync_yea: value } : null;
    case 'saveStatus':
      return !_.isEmpty(value) ? { save_status: value } : null;
    case 'isConfirmedPayroll':
      return !_.isEmpty(value) ? { is_confirmed_payroll: value } : null;
    case 'isConfirmed':
      return !_.isEmpty(value) ? { is_confirmed: value } : null;
    case 'isElectronicDeliveryAgreement':
      return !_.isEmpty(value) ? { is_electronic_delivery_agreement: value.join('_') } : null;
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfPays = (fieldName, value) => {
  switch (fieldName) {
    case 'payroll_rules_groups':
      return { uniquePayrollRuleGroups: value };
    case 'year_month':
      return { uniquePaymentDate: value };
    case 'closing_day':
      return { closingDay: value };
    case 'payment_day':
      return { paymentDay: value };
    case 'payment_month_type':
      return { paymentMonthType: value };
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'per_page':
      return { limit: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfPays = (fieldName, value) => {
  switch (fieldName) {
    case 'uniquePayrollRuleGroups':
      return !_.isEmpty(value) ? { payroll_rules_groups: value } : null;
    case 'uniquePaymentDate':
      return !_.isEmpty(value) ? { year_month: value } : null;
    case 'closingDay':
      return !_.isEmpty(value) ? { closing_day: value } : null;
    case 'paymentDay':
      return !_.isEmpty(value) ? { payment_day: value } : null;
    case 'paymentMonthType':
      return !_.isEmpty(value) ? { payment_month_type: value } : null;
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'limit':
      return { per_page: value };
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfBonuses = (fieldName, value) => {
  switch (fieldName) {
    case 'client_bonus_id':
      return { clientBonusId: value };
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'per_page':
      return { limit: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfBonuses = (fieldName, value) => {
  switch (fieldName) {
    case 'clientBonusId':
      return !_.isEmpty(value) ? { client_bonus_id: `${value}` } : null;
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'limit':
      return { per_page: value };
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfEmployees = (fieldName, value) => {
  switch (fieldName) {
    case 'enrollment_status':
      return { employmentType: value };
    case 'enrollment_statuses':
      return { enrollmentStatuses: value.split('_') };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfEmployees = (fieldName, value) => {
  switch (fieldName) {
    case 'employmentType':
      return !_.isEmpty(value) ? { enrollment_status: `${value}` } : null;
    case 'enrollmentStatuses':
      return !_.isEmpty(value) ? { enrollment_statuses: value.join('_') } : null;
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfPaging = (fieldName, value) => {
  switch (fieldName) {
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'per_page':
      return { limit: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfPaging = (fieldName, value) => {
  switch (fieldName) {
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'limit':
      return { per_page: value };
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfRevisions = (fieldName, value) => {
  switch (fieldName) {
    case 'revision_year_month':
      return { yearMonth: value };
    case 'per_page':
      return { limit: value };
    default:
      return { [_.camelCase(fieldName)]: value };
  }
};

export const convertFormToQueryFieldOfRevisions = (fieldName, value) => {
  switch (fieldName) {
    case 'yearMonth':
      return { revision_year_month: value };
    case 'limit':
      return { per_page: value };
    default:
      return { [_.snakeCase(fieldName)]: value };
  }
};

export const convertQueryToFormFieldOfNotificationOfBaseAmountRevisions = (fieldName, value) => {
  switch (fieldName) {
    case 'revision_year':
      return { yearMonth: value };
    case 'per_page':
      return { limit: value };
    default:
      return { [_.camelCase(fieldName)]: value };
  }
};

export const convertFormToQueryFieldOfNotificationOfBaseAmountRevisions = (fieldName, value) => {
  switch (fieldName) {
    case 'yearMonth':
      return { revision_year: value };
    case 'limit':
      return { per_page: value };
    case 'applicableOffice':
      return { applicable_office: value };
    case 'sortType':
      return { sort_type: value };
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfWageLedgers = (fieldName, value) => {
  switch (fieldName) {
    case 'year':
      return { year: value };
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'per_page':
      return { limit: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfWageLedgers = (fieldName, value) => {
  switch (fieldName) {
    case 'year':
      return !_.isEmpty(value) ? { year: `${value}` } : null;
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'limit':
      return { per_page: value };
    case 'yearMonthStart':
      return !_.isEmpty(value) ? { year_month_start: `${value}` } : null;
    case 'yearMonthEnd':
      return !_.isEmpty(value) ? { year_month_end: `${value}` } : null;
    case 'bonus':
      return { bonus: value };
    case 'yearSelected':
      return { year_selected: value };
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfClientRoleUsers = (fieldName, value) => {
  switch (fieldName) {
    case 'client_role_ids':
      return { clientRoles: value };
    case 'enrollment_statuses':
      return { enrollmentStatuses: value.split('_') };
    default:
      return null;
  }
};

export const DUMMY_ID_CLIENT_ROLE_ALL = '-1';
export const convertFormToQueryFieldOfClientRoleUsers = (fieldName, value) => {
  switch (fieldName) {
    case 'clientRoles':
      // 「すべて」が選択されているときはパラメタを送らない
      if (_.isEmpty(value) || value === DUMMY_ID_CLIENT_ROLE_ALL) {
        return null;
      }
      return { client_role_ids: value };
    case 'enrollmentStatuses':
      return !_.isEmpty(value) ? { enrollment_statuses: value.join('_') } : null;
    case 'accountType':
      if (_.isEmpty(value)) {
        return null;
      }
      // 片方だけチェックがついているときのみパラメタにセットする
      // (両方ともチェックがついているときは、フィルタなしと同じためパラメタを送らない)
      return value.length === 1 ? { account_type: value[0] } : null;
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfReportHealthInsuranceBonus = (fieldName, value) => {
  switch (fieldName) {
    case 'bonusId':
      return { bonusId: value };
    case 'applicable_office':
      return { applicableOffice: value };
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'per_page':
      return { limit: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfReportHealthInsuranceBonus = (fieldName, value) => {
  switch (fieldName) {
    case 'bonusId':
      return !_.isEmpty(String(value)) ? { bonusId: `${value}` } : null;
    case 'applicableOffice':
      return !_.isEmpty(String(value)) ? { applicable_office: `${value}` } : null;
    case 'page':
      return { page: value };
    case 'sort':
      return { sort: value };
    case 'order':
      return { order: value };
    case 'limit':
      return { per_page: value };
    default:
      return null;
  }
};

export const convertQueryToFormFieldOfLaborInsurance = (fieldName, value) => {
  switch (fieldName) {
    case 'laborInsuranceYear':
      return { labor_insurance_year: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfSyncYea = (fieldName, value) => {
  switch (fieldName) {
    case 'syncYea':
      return { sync_yea: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfSaveStatus = (fieldName, value) => {
  switch (fieldName) {
    case 'saveStatus':
      return { save_status: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfIsConfirmedPayroll = (fieldName, value) => {
  switch (fieldName) {
    case 'isConfirmedPayroll':
      return { is_confirmed_payroll: value };
    default:
      return null;
  }
};

export const convertFormToQueryFieldOfIsConfirmed = (fieldName, value) => {
  switch (fieldName) {
    case 'isConfirmed':
      return { is_confirmed: value };
    default:
      return null;
  }
};
