import React from 'react';

import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS, CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { PAYROLL_RULES_GROUP_FORM } from 'src/constants/FormNames';

import { getItem, getItemErrors, setItem } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, editPayrollRulesGroup } from 'src/reducers/clients/payrollRulesGroups';

import PayrollRulesGroupForm from './containers/PayrollRulesGroupForm';

let EditPayrollRulesGroupForm = reduxForm({
  form: PAYROLL_RULES_GROUP_FORM
})(PayrollRulesGroupForm);

const mapStateToProps = state => ({
  initialValues: getItem(REDUCER_NAME, state),
  isNewForm: false,
  errors: getItemErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(editPayrollRulesGroup(data));
  }
});

EditPayrollRulesGroupForm = connect(mapStateToProps, mapDispatchToProps)(EditPayrollRulesGroupForm);

const EditPayrollRulesGroup = ({ dispatch, clientPayrollRulesGroup }) => {
  dispatch(setItem(REDUCER_NAME, clientPayrollRulesGroup));
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">給与規定編集</h1>
      </div>
      <div className="l-wrap-xs">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="給与規定グループ一覧" path={CLIENT_PAYROLL_RULES_GROUP_LIST} />
            <BreadCrumb.Item label="給与規定編集" />
          </BreadCrumb>
        </div>
        <EditPayrollRulesGroupForm />
      </div>
    </div>
  );
};

export default connect()(EditPayrollRulesGroup);
