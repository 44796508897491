import React from 'react';
import { connect } from 'react-redux';

import { FormErrors } from 'src/components';
import {
  EditActionBtn,
  SaveActionBtn,
  AddActionBtn,
  CancelActionBtn,
  ExpandActionBtn,
  CollapseActionBtn
} from 'src/buttons';

import {
  getMode,
  getErrors,
  expandAll,
  collapseAll,
  changeToEditMode,
  changeToShowMode,
  addNone,
  updateAllGroupNodes,
  MODES,
  GROUP_DISABLED_REASON,
  getImportStatus
} from 'src/reducers/clients/groups';
import styles from './GroupsButtons.scss';

const GroupsButtons = ({
  isEditMode,
  errors,
  importing,
  onExpandAllClick,
  onCollapseAllClick,
  onChangeToEditModeClick,
  onAddClick,
  onCancelClick,
  onSaveClick,
  anyApiRunning
}) => (
  <div>
    <FormErrors errors={errors} />
    <div className={styles.action}>
      <ExpandActionBtn onClick={onExpandAllClick} />
      <CollapseActionBtn onClick={onCollapseAllClick} />
      {isEditMode && <AddActionBtn onClick={onAddClick} />}
      {isEditMode && (
        <SaveActionBtn disabled={importing} onClick={onSaveClick} disabledReason={GROUP_DISABLED_REASON} />
      )}
      {isEditMode && <CancelActionBtn onClick={onCancelClick} />}
      {!isEditMode && (
        <EditActionBtn
          onClick={onChangeToEditModeClick}
          disabled={anyApiRunning || importing}
          disabledReason={GROUP_DISABLED_REASON}
        />
      )}
    </div>
  </div>
);

const mapStateToProps = state => ({
  isEditMode: getMode(state) === MODES.EDIT,
  errors: getErrors(state),
  importing: getImportStatus(state)
});
const mapDispatchToProps = dispatch => ({
  onExpandAllClick: () => {
    dispatch(expandAll());
  },
  onCollapseAllClick: () => {
    dispatch(collapseAll());
  },
  onChangeToEditModeClick: () => {
    dispatch(changeToEditMode());
  },
  onAddClick: () => {
    dispatch(addNone());
  },
  onCancelClick: () => {
    dispatch(changeToShowMode());
  },
  onSaveClick: () => {
    dispatch(updateAllGroupNodes());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupsButtons);
