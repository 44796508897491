import { connect } from 'react-redux';
import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getList } from 'src/reducers/common/listReducer';
import EmployeeTable from '../components/EmployeeTable';

const mapStateToProps = state => ({
  employees: getList(REDUCER_NAME, state)
});

export default connect(mapStateToProps)(EmployeeTable);
