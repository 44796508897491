import { Field } from 'redux-form';
import React from 'react';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { Label, renderInlineInputField, Error } from 'jbc-front/components/Form';
import * as validators from 'jbc-front/utils/validators';
import styles from 'jbc-front/components/Form.scss';
import { kanaOrAlphanumeric, isRequiredPensionInsuranceCode } from '../../../../utils/CustomValidators';

const PensionInsuranceCodeField = () => (
  <div className={styles.boxWrap}>
    <Label
      text="厚生年金保険記号（事業所整理記号）"
      note="「数字2ケタ-カタカナまたは英数4ケタ以内」の形式です。年金事務所から送付された納付告知書などに記載されています。"
    />
    <span key={`field_insuranceCode1`}>
      <Field
        key="insuranceCode1"
        name="insuranceCode1"
        type="text"
        component={renderInlineInputField}
        width={50}
        maxLength={2}
        validate={[validators.number, isRequiredPensionInsuranceCode('insuranceCode2')]}
      />
      <LabelMapper name="insuranceCode1" label={`厚生年金保険記号（事業所整理記号）の1番`} />
    </span>
    &nbsp;-&nbsp;
    <span key={`field_insuranceCode2`}>
      <Field
        key="insuranceCode2"
        name="insuranceCode2"
        type="text"
        component={renderInlineInputField}
        width={80}
        maxLength={4}
        validate={[code => kanaOrAlphanumeric(code), isRequiredPensionInsuranceCode('insuranceCode1')]}
      />
      <LabelMapper name="insuranceCode2" label={`厚生年金保険記号（事業所整理記号）の2番`} />
    </span>
    <Field
      name="insuranceCode1"
      component={Error}
      key="error_insuranceCode1"
      label="厚生年金保険記号（事業所整理記号）の1番"
      validate={[validators.number, isRequiredPensionInsuranceCode('insuranceCode2')]}
    />
    <Field
      name="insuranceCode2"
      component={Error}
      key="error_insuranceCode2"
      label="厚生年金保険記号（事業所整理記号）の2番"
      validate={[code => kanaOrAlphanumeric(code), isRequiredPensionInsuranceCode('insuranceCode1')]}
    />
  </div>
);

export default PensionInsuranceCodeField;
