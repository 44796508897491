import { connect } from 'react-redux';
import {
  setEmployeePaysQueries,
  fetchCompletedDownloadPayCsvJob,
  getEmployeePayItemErrors
} from 'src/reducers/employees/employeePays';
import EmployeePayDownloadCsvPage from '../components/EmployeePayDownloadCsvPage';

const mapStateToProps = state => ({
  errors: getEmployeePayItemErrors(state)
});

const mapDispatchToProps = (dispatch, ownProps) => {
  const queries = ownProps.values.queries;

  return {
    setEmployeePays: () => {
      dispatch(setEmployeePaysQueries(queries));
    },
    fetchCompletedDownloadPayCsvJob: () => {
      dispatch(
        fetchCompletedDownloadPayCsvJob({
          yearMonth: queries.yearMonth,
          payrollRulesGroups: queries.payrollRulesGroups
        })
      );
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeePayDownloadCsvPage);
