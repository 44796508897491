import React from 'react';

import { Loading } from 'src/components';

import styles from './LoadingView.scss';

const LoadingComponent = () => (
  <div className={styles.loading}>
    <Loading type="spin" color="#e3e3e3" />
  </div>
);

class LoadingView extends React.Component {
  constructor(props) {
    super(props);
    // isInitLoading: 初期表示でローディング状態にするかの設定
    // 通信状態の状態遷移は、通信前(false) => 通信中(true) => 通信後(false)の流れになるため、
    // 画面初期表示時に初期データ取得までは、ローディング状態にならない。
    // これを解決するために、レンダリング後初回のローディングまではローディング状態にするためにこのフラグを追加。
    // 現状は必ず使われるようになっているため、個別で制御したい場合は別途実装すること
    this.state = { isInitLoading: props.isInitLoading };
  }
  render() {
    const { children, isLoading } = this.props;
    if (isLoading === true) {
      this.state.isInitLoading = false;
    }
    return isLoading || this.state.isInitLoading ? <LoadingComponent /> : <React.Fragment>{children}</React.Fragment>;
  }
}
LoadingView.defaultProps = {
  isInitLoading: true
};

export default LoadingView;
