import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import moment from 'moment';

import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { arrowRenderer } from 'jbc-front/components/Form';
import ActionButton from 'jbc-front/components/ActionButton';
import Button from 'jbc-front/components/Button';
import { Task, Upload } from 'jbc-front/components/icons';

import { REVISION_FILTER_TYPES, REVISION_SORT_TYPES } from 'src/constants/Options';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import {
  MONTHLY_REVISION_INSERT_BATCH_CSV,
  REPORT_MONTHLY_REVISIONS_PDF,
  EMPLOYEE_PAY_LIST
} from 'src/constants/EndpointUrls';
import MainSearchSelectBox from 'src/components/MainSearchSelectBox';
import GeneralBtn from 'src/buttons/GeneralBtn';
import LoadingView from 'src/components/LoadingView';
import { downloadCsv } from 'src/reducers/reports/monthlyRevisions';
import CsvDownloadModalForm from 'src/components/Modal/Report/SocialInsurance/CsvDownloadModalForm';
import { ErrorsSection } from 'src/components';
import { MONTHLY_REVISIONS_TARGET_TYPE } from 'src/reducers/egov';
import EgovModalContainer from 'src/containers/EgovModalContainer';
import Cookies from 'js-cookie';
import ConfirmRevisionModal from './ConfirmRevisionModal';
import styles from './Header.scss';
import EgovButton from '../../../components/Modal/Report/SocialInsurance/EgovButton';

const NoContent = () => (
  <div className={styles.noContent}>
    <div className={styles.noContentText}>
      <p className={styles.noContentTextPrimary}>表示できる月額変更届がありません。 </p>
      <p className={styles.noContentTextPrimary}>連続した4ヶ月分の給与を確定させると表示されます。</p>
      <p className={styles.noContentTextSecondary}>
        ※月額変更届に関する詳細は
        <a
          className="u-txt-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360002145953-%E9%9A%8F%E6%99%82%E6%94%B9%E5%AE%9A%E3%82%92%E8%A1%8C%E3%81%86-%E6%9C%88%E9%A1%8D%E5%A4%89%E6%9B%B4%E5%B1%8A%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-"
        >
          ヘルプページ
        </a>
        をご覧ください
      </p>
    </div>
    <div className={styles.noContentButton}>
      <GeneralBtn as="a" text="給与一覧へ" href={EMPLOYEE_PAY_LIST} />
    </div>
  </div>
);

const Alert = ({ message, alertRevisions, onFixClick }) => (
  <div className={styles.column}>
    <div>
      <p>
        {message}
        <br />
        詳細は
        <a
          className="u-txt-link"
          target="_blank"
          rel="noopener noreferrer"
          href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900000882163"
        >
          こちら
        </a>
        のヘルプページをご確認下さい。
      </p>
      <div className="u-mt10">
        <span className={styles.target}>対象の従業員</span>
        <ul>
          {alertRevisions.map(alertRevision => {
            const targetEmployeeInfo = alertRevision.staffCode
              ? `${alertRevision.staffCode} ${alertRevision.lastName} ${alertRevision.firstName}`
              : `${alertRevision.lastName} ${alertRevision.firstName}`;
            return <li>{targetEmployeeInfo}</li>;
          })}
        </ul>
      </div>
    </div>
    {onFixClick && <Button onClick={onFixClick}>反映</Button>}
  </div>
);

const Header = props => {
  const {
    revisionYearMonths,
    applicableOffices,
    yearMonth,
    applicableOffice,
    extras,
    hasErrors,
    errors,
    isOptionsLoading,
    staledRevisions,
    alertHealthInsuranceManualInputRevisions,
    alertPensionInsuranceManualInputRevisions,
    targetHealthInsurance,
    targetCount,
    fetchRevisionMonthSyncOptions,
    fetchRevisionMonthOfficeOptions,
    fixBeforeMonthlyInsuranceAmounts,
    changeFormValue,
    importing
  } = props;

  useEffect(() => {
    fetchRevisionMonthSyncOptions();
  }, []);

  const [isCsvModalOpen, setDownloadModal] = useState(false);
  const [isPdfPreview, setIsPdfPreview] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const isWithLms = Cookies.get('using_jbc_lms') === 'true';
  const [isEgovModalOpen, setEgovModal] = useState(false);

  const handleYearChange = () => {
    changeFormValue('applicableOffice', applicableOffices[0].value);
    fetchRevisionMonthOfficeOptions();
  };

  const showPreview = () => {
    setIsPdfPreview(true);
  };

  const openConfirmModal = () => {
    if (isConfirmModalOpen) return;
    setIsConfirmModalOpen(true);
  };

  const hidePreview = () => {
    setIsPdfPreview(false);
  };

  const closeConfirmModal = () => {
    if (!isConfirmModalOpen) return;
    setIsConfirmModalOpen(false);
  };

  const sortRenderValue = option => <div>表示順：{option.label}</div>;
  const filterRenderValue = option => <div>絞り込み：{option.label}</div>;

  const pdfUrl = revisionYearMonth =>
    `${REPORT_MONTHLY_REVISIONS_PDF}?pdf[revision_year_month]=${revisionYearMonth}&pdf[applicable_office]=${applicableOffice}`;
  const pdfDownloadUrl = revisionYearMonth =>
    `${REPORT_MONTHLY_REVISIONS_PDF}?pdf[revision_year_month]=${revisionYearMonth}&pdf[applicable_office]=${applicableOffice}pdf[type]=zip`;
  const hasTarget = targetCount <= 0;
  const isNoData = _.isEmpty(revisionYearMonths);
  const importUrl = revisionYearMonth =>
    `${MONTHLY_REVISION_INSERT_BATCH_CSV}?revision_year_month=${revisionYearMonth}&applicable_office=${applicableOffice}`;

  const prevMonthOfChangeStandardMonthlyDate = () =>
    moment(extras.changeStandardMonthlyDate.replace('年', '-').replace('月', ''))
      .add(-1, 'months')
      .format('YYYY年MM月');

  const disabledReason = '処理中です。完了までしばらくお待ちください。';

  return (
    <LoadingView isLoading={isOptionsLoading}>
      <div className="l-wrap-xl">
        {isNoData && <NoContent />}
        {!isNoData && (
          <div>
            <div className={styles.container}>
              <div className={styles.select}>
                <Field
                  name="yearMonth"
                  component={MainSearchSelectBox}
                  options={revisionYearMonths}
                  onSelect={handleYearChange}
                  arrowRenderer={arrowRenderer}
                  width={248}
                />
              </div>
              {applicableOffices.length > 1 &&
                !hasErrors && (
                  <div className={styles.selectOffice}>
                    <Field
                      name="applicableOffice"
                      component={MainSearchSelectBox}
                      options={applicableOffices}
                      arrowRenderer={arrowRenderer}
                      width={270}
                    />
                  </div>
                )}
            </div>
            {!hasErrors && (
              <div>
                {staledRevisions &&
                  staledRevisions.length > 0 && (
                    <Alert
                      message={
                        <span>
                          従前({prevMonthOfChangeStandardMonthlyDate()})の標準報酬が変更されている従業員がいます。<br />変更後の標準報酬月額を反映させるには反映ボタンを押してください。
                        </span>
                      }
                      alertRevisions={staledRevisions}
                      onFixClick={() =>
                        fixBeforeMonthlyInsuranceAmounts(staledRevisions.map(staledRevision => staledRevision.id))
                      }
                    />
                  )}
                {alertHealthInsuranceManualInputRevisions &&
                  alertHealthInsuranceManualInputRevisions.length > 0 && (
                    <Alert
                      message={
                        <span>
                          従前({prevMonthOfChangeStandardMonthlyDate()})の健康保険料の算出方法が固定額に設定されている従業員がいます。当該従業員の提出要否をご確認ください。
                        </span>
                      }
                      alertRevisions={alertHealthInsuranceManualInputRevisions}
                    />
                  )}
                {alertPensionInsuranceManualInputRevisions &&
                  alertPensionInsuranceManualInputRevisions.length > 0 && (
                    <Alert
                      message={
                        <span>
                          従前({prevMonthOfChangeStandardMonthlyDate()})の厚生年金保険料の算出方法が固定額に設定されている従業員がいます。当該従業員の提出要否をご確認ください。
                        </span>
                      }
                      alertRevisions={alertPensionInsuranceManualInputRevisions}
                    />
                  )}
                <div className={styles.column}>
                  <div>
                    <p>
                      ※{extras.changeStandardMonthlyDate}分以降の標準報酬月額が変更されます。(実際の徴収月は設定により異なります)
                    </p>
                    <div className={styles.target}>
                      対象者：<span>{targetCount}名</span>
                    </div>
                  </div>
                  <Button
                    primary
                    disabled={hasTarget || importing}
                    disabledReason={importing ? disabledReason : ''}
                    onClick={openConfirmModal}
                  >
                    標準報酬月額改定
                  </Button>
                </div>
                <ErrorsSection errors={errors} />
                <div className={styles.action}>
                  <div className={styles.csv}>
                    <ActionButton
                      primary
                      href={importUrl(yearMonth, applicableOffice)}
                      as="a"
                      icon={<Upload size={16} />}
                    >
                      CSVインポート
                    </ActionButton>
                  </div>
                  <div className={styles.actionSelectBoxes}>
                    <div className={styles.actionSelectBoxesChild}>
                      <Field
                        name="sortType"
                        component={SearchSelectBox}
                        options={REVISION_SORT_TYPES}
                        valueRenderer={sortRenderValue}
                      />
                    </div>
                    <div className={styles.actionSelectBoxesChild}>
                      <Field
                        name="filterType"
                        component={SearchSelectBox}
                        options={REVISION_FILTER_TYPES}
                        valueRenderer={filterRenderValue}
                        classNames={styles.actionSelectBoxesChild}
                      />
                    </div>
                  </div>

                  <div className={styles.actionButtons}>
                    <div className={styles.actionButtonsChild}>
                      <ActionButton primary disabled={hasTarget} onClick={showPreview} icon={<Task size={16} />}>
                        PDF出力
                      </ActionButton>
                    </div>
                    <EgovButton
                      isCsvDataExist={!hasTarget}
                      openCsvModal={() => setDownloadModal(true)}
                      isLmsActive={isWithLms}
                      openEgovModal={() => setEgovModal(true)}
                    />
                  </div>
                </div>
                <PreviewPdfModalContainer
                  header="月額変更届"
                  isOpen={isPdfPreview}
                  hideModal={hidePreview}
                  url={pdfUrl(yearMonth)}
                  downloadUrl={pdfDownloadUrl(yearMonth)}
                />
                {isConfirmModalOpen && (
                  <ConfirmRevisionModal
                    isOpen={isConfirmModalOpen}
                    hideModal={closeConfirmModal}
                    yearMonth={yearMonth}
                    extras={extras}
                  />
                )}
                {isCsvModalOpen && (
                  <CsvDownloadModalForm
                    isOpen={isCsvModalOpen}
                    searchKey={{ revisionYear: yearMonth, applicableOffice }}
                    insuranceType={targetHealthInsurance.insuranceType}
                    hideModal={() => setDownloadModal(false)}
                    modalTitle={'月額変更届CSVダウンロード'}
                    downloadCsv={downloadCsv}
                    withSubmitAt={false}
                    withEmployeeSearch={false}
                  />
                )}
                {isEgovModalOpen && (
                  <EgovModalContainer
                    isOpen={isEgovModalOpen}
                    hideModal={() => setEgovModal(false)}
                    postParams={{ yearMonth, applicableOffice }}
                    type={MONTHLY_REVISIONS_TARGET_TYPE}
                    withEmployeeSearch={false}
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </LoadingView>
  );
};

export default Header;
