import { combineReducers } from 'redux';
import _ from 'lodash';
import axios from 'axios';

import * as Urls from 'src/constants/EndpointUrls';
import { newItem, editItemFailure } from 'src/reducers/common/itemReducer';
import { fetchList, fetchListSuccess, fetchListFailure, setExtras, getExtras } from 'src/reducers/common/listReducer';
import { paramsWithFile } from 'src/utils/Form';
import { setGlobalSuccesses, setGlobalErrors } from 'src/reducers/global';

import createNamedWrapperReducer from '../createNamedWrapperReducer';
import { listReducer, itemReducer } from '../common';

export const REDUCER_NAME = 'myNumbers';
export const getDependentFamilies = state => _.get(getExtras(REDUCER_NAME, state), 'dependentFamilies');
export const getEmployeeMyNumber = state => _.get(getExtras(REDUCER_NAME, state), 'employeeMyNumber');
export const getIsLoading = state => _.get(getExtras(REDUCER_NAME, state), 'isLoading');
export const getJob = state => _.get(getExtras(REDUCER_NAME, state), 'job');

// Async Action Creators
export const fetchEmployees = (queries, callbackWithDispatch = undefined) => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    const response = await axios.get(`${Urls.SEARCH_MY_NUMBER_URL}`, { params: queries });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.employees,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    if (callbackWithDispatch) {
      dispatch(callbackWithDispatch(payload.data));
    }
  } catch (e) {
    let msg = '一覧の取得に失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages[0];
    }
    dispatch(fetchListFailure(REDUCER_NAME, msg));
  }
};

export const uploadCsv = (data = 'create') => async dispatch => {
  try {
    dispatch(newItem(REDUCER_NAME));

    const params = paramsWithFile(data, ['my_number_csv']);
    await axios.post(Urls.IMPORT_MY_NUMBER_CSV, params);
    dispatch(setGlobalSuccesses('ファイルを受け取りました。'));
  } catch (e) {
    let msg = 'CSVのアップロードに失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages;
    }
    dispatch(editItemFailure(REDUCER_NAME, msg));
  }
};

// モーダルのデータ初期化
export const initializeModalData = employeeId => async dispatch => {
  dispatch(setExtras(REDUCER_NAME, { isLoading: true }));

  try {
    const response = await axios.get(Urls.SHOW_DETAIL, { params: { employee_id: employeeId } });
    dispatch(
      setExtras(REDUCER_NAME, {
        dependentFamilies: response.data.payload.dependentFamilies,
        hasMyNumber: response.data.payload.hasMyNumber,
        isLoading: false
      })
    );
  } catch (e) {
    let msg = 'マイナンバー情報の取得に失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages[0];
    }
    dispatch(setGlobalErrors(msg));
  }
};

// モーダルクローズ時の処理
export const destructModalData = () => dispatch => {
  dispatch(setExtras(REDUCER_NAME, { dependentFamilies: null, employeeMyNumber: null }));
};

// マイナンバー取得（従業員）
export const fetchEmployeeMyNumber = employeeId => async dispatch => {
  try {
    const response = await axios.get(Urls.FETCH_MY_NUMBER, { params: { employee_id: employeeId } });

    dispatch(setExtras(REDUCER_NAME, { employeeMyNumber: response.data.payload.myNumber }));
  } catch (e) {
    let msg = 'マイナンバーの取得に失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages[0];
    }
    dispatch(setGlobalErrors(msg));
  }
};

// マイナンバー取得（扶養家族）
export const fetchDependentFamilyMyNumber = (employeeId, dependentFamily) => async (dispatch, getState) => {
  try {
    const dependentPayload = {
      id: dependentFamily.id,
      last_name: dependentFamily.lastName,
      first_name: dependentFamily.firstName,
      birthday: dependentFamily.birthday
    };
    const response = await axios.get(Urls.FETCH_MY_NUMBER, {
      params: { employee_id: employeeId, dependent_family: dependentPayload }
    });

    // 扶養家族のstate情報に取得したマイナンバーを追加
    //   DeepCopyしているのは、マイナンバー追加後dependentFamiliesを上書きしても再レンダリングが行われないため
    //   オブジェクトの参照先が変わらないのが原因？
    //   https://qiita.com/yasuhiro-yamada/items/aebda0dff79a70eb71d7
    //   他にいいやり方があれば修正してもらえると有り難いです
    const dependentFamilies = _.cloneDeep(_.get(getExtras(REDUCER_NAME, getState()), 'dependentFamilies'));
    dependentFamilies[dependentFamily.id].myNumber = response.data.payload.myNumber;
    dispatch(setExtras(REDUCER_NAME, { dependentFamilies }));
  } catch (e) {
    let msg = 'マイナンバーの取得に失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages[0];
    }
    dispatch(setGlobalErrors(msg));
  }
};

// ジョブ実行状況取得
export const getLastCsvJobStatus = currentJob => async dispatch => {
  try {
    const response = await axios.get(Urls.MY_NUMBER_JOB_STATUS, {
      params: { job_id: currentJob.id }
    });
    // 実行終わりのみアップデートする。
    const job = response.data.payload.job;
    if (job && (job.status === 'success' || job.status === 'failed')) {
      dispatch(setExtras(REDUCER_NAME, { anyJobRunning: false, job }));
      if (this.refreshIntervalId) {
        clearInterval(this.refreshIntervalId);
        this.refreshIntervalId = null;
      }
    }
  } catch (e) {
    console.log('error', e);
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
