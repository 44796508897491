import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { pollFetchExportWageLedgerStatus } from 'src/reducers/reports/reportWageLedger';
import { FORM_NAME_WAGE_LEDGER_SEARCH } from 'src/reducers/searchForm';
import ExportButton from '../components/ExportButton';

function mapStateToProps(state) {
  return {
    job: state.JobStatus.exportWageLedgerJob,
    formValues: getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    pollFetchExportWageLedgerStatus: (formValues, uniqueString) => {
      dispatch(pollFetchExportWageLedgerStatus(formValues, uniqueString));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportButton);
