import React from 'react';

import { NEW_LINE_CHARACTERS } from 'src/constants/Generals';
import { formatDateWithHour } from 'src/utils/Date';
import styles from './JobStatus.scss';

const JobFailed = ({ jobInfo, startAt, endAt, errorShowMsg, statusText }) => (
  <li>
    <p className={styles.title}>
      {jobInfo}
      {statusText}
    </p>
    <div className={styles.dates}>
      <dl>
        <dt>実行日時：</dt>
        <dd>{formatDateWithHour(startAt)}</dd>
      </dl>
      <dl>
        <dt>完了日時：</dt>
        <dd>{formatDateWithHour(endAt)}</dd>
      </dl>
    </div>
    <ul>{errorShowMsg && errorShowMsg.split(NEW_LINE_CHARACTERS).map(msg => <li key={msg}>{msg}</li>)}</ul>
  </li>
);

export default JobFailed;
