import React from 'react';
import classNames from 'classnames';

import { EMPLOYEE_PAY_LIST, UPDATE_BATCH_CSV } from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { formatDate } from 'src/utils/Date';
import { ErrorsSection } from 'src/components';
import EmployeePayDownloadCsvForm from './EmployeePayDownloadCsvForm';
import DownloadColumnDetail from './DownloadColumnDetail';
import DownloadPayCsvJobStatus from './DownloadPayCsvJobStatus';
import ImportPayCsvJobStatus from './ImportPayCsvJobStatus';
import styles from './EmployeePayDownloadCsvPage.scss';

const EmployeePayDownloadCsvPage = props => {
  props.setEmployeePays();
  props.fetchCompletedDownloadPayCsvJob();

  const { startDate, endDate } = props.values.targetPeriod;
  return (
    <div>
      <div className="l-wrap-xs l-contents-wrap">
        <BreadCrumb>
          <BreadCrumb.Item label="給与一覧" path={EMPLOYEE_PAY_LIST} />
          <BreadCrumb.Item label="支給・控除項目の更新" />
        </BreadCrumb>
      </div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">支給・控除項目の更新</h1>
      </div>
      <DownloadPayCsvJobStatus />
      <ImportPayCsvJobStatus />
      <ErrorsSection errors={props.errors} />
      <div className={classNames('l-wrap-xs', styles.description)}>
        <p>
          以下の月の給与の支給項目・控除項目の数値をCSVファイルで一括で更新する事ができます。<br />毎月変動する支給項目などをExcelなどで管理している場合に便利です。
        </p>
        <ul className="u-mt10">
          <li>
            ※ 従業員マスタの値は変更されません。従業員マスタごと変更したい場合は
            <a href={UPDATE_BATCH_CSV} className="u-txt-link">
              コチラ
            </a>
          </li>
        </ul>
        <div className={styles.target}>
          {props.values.jobInfo}
          <div className={styles.period}>
            <span>
              対象期間：{formatDate(startDate)}〜{formatDate(endDate)}
            </span>
          </div>
        </div>
      </div>
      <EmployeePayDownloadCsvForm jobInfo={props.values.jobInfo} />
      <div className={styles.detail}>
        <DownloadColumnDetail />
      </div>
    </div>
  );
};

export default EmployeePayDownloadCsvPage;
