import { connect } from 'react-redux';
import PreviewPdfModal from '../components/PreviewPdfModal';

const mapStateToProps = (state, ownProps) => {
  const {
    isOpen,
    hideModal,
    url,
    downloadUrl,
    header,
    canPrintPdf = true,
    editUrl = null,
    isMyPage = false
  } = ownProps;
  return {
    isOpen,
    hideModal,
    url,
    downloadUrl,
    header,
    canPrintPdf,
    editUrl,
    isMyPage
  };
};

export default connect(mapStateToProps)(PreviewPdfModal);
