import React from 'react';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import { ENCODING_OPTIONS } from 'src/constants/Options';

const DownloadCsvModal = ({ isOpen, hideModal, handleSubmit, submitting, submit }) => {
  const header = '所得税徴収高集計資料ダウンロード';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          <DownloadCsvEncodingRadioButton
            name={'encoding'}
            label={'ファイル形式を選択'}
            options={ENCODING_OPTIONS}
            required={false}
          />
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={hideModal} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="ダウンロード" {...{ submitting, submit }} />
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default DownloadCsvModal;
