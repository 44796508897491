import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { change } from 'redux-form';
import { RadioField } from 'jbc-front/components/Form';
import { FB_DATA_LINE_FEED_OPTIONS, FB_DATA_END_OF_FILE_LINE_FEED } from 'src/constants/Options';

const FbDataLineFeedRadioButton = props => {
  const [isUseLineFeed, setIsUseLineFeed] = useState(false);
  const propIsUseLineFeed = props.isUseLineFeed;
  const changeLineFeed = (e, newValue) => {
    setIsUseLineFeed(newValue !== 'none');
    if (!_.isEmpty(props.formName)) {
      const dispatch = props.dispatch;
      if (newValue === 'none') {
        dispatch(change(props.formName, 'end_of_file_line_feed', null));
      } else {
        dispatch(change(props.formName, 'end_of_file_line_feed', '0'));
      }
    }
  };

  return (
    <React.Fragment>
      <RadioField
        name="line_feed"
        label="改行コード"
        options={FB_DATA_LINE_FEED_OPTIONS}
        onChange={changeLineFeed}
        note={
          <p>
            銀行から指定がない場合は「なし」をご選択ください。<br />ご不明な場合は金融機関にお問い合わせください。
          </p>
        }
        required
      />
      {(propIsUseLineFeed || isUseLineFeed) && (
        <RadioField
          name="end_of_file_line_feed"
          label="ファイル終端の改行コード"
          options={FB_DATA_END_OF_FILE_LINE_FEED}
          note={
            <p>
              銀行から指定がない場合は「なし」をご選択ください。<br />多くの金融機関では「なし」を選択します。<br />ご不明な場合は金融機関にお問い合わせください。
            </p>
          }
          required
        />
      )}
    </React.Fragment>
  );
};

export default connect()(FbDataLineFeedRadioButton);
