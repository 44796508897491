import React, { useMemo } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { REDUCER_NAME, isMainOffice } from 'src/reducers/offices/offices';
import { OFFICE_EDIT } from 'src/constants/EndpointUrls';
import { setItem } from 'src/reducers/common/itemReducer';
import { concatParamsToUrl } from 'src/utils/Http';

import ShowOffice from '../components/ShowOffice';

// sprint132マージ時にコンフリクトしてたら消す
const ShowOfficeWrapper = props => {
  const { values, dispatch } = props;
  useMemo(() => dispatch(setItem(REDUCER_NAME, values)));

  return <ShowOffice {...props} />;
};

const mapStateToProps = (state, ownProps) => ({
  isOnlyMainOffice: ownProps.values.isOnlyMainOffice,
  planType: _.get(state, 'currentClient.item.data.planType'),
  isMainOffice: isMainOffice(ownProps.values.office.officeType),
  editUrl: concatParamsToUrl(OFFICE_EDIT, { id: ownProps.values.office.id })
});

export default connect(mapStateToProps)(ShowOfficeWrapper);
