import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors, change as formChange } from 'redux-form';
import { getSelectOptions } from 'src/reducers/selectOptions';
import _ from 'lodash';

import {
  getUiShowMode,
  getChangedYearMonths,
  uiChangeShowMode,
  uiChangeCalcMode,
  uiNotifyValueChanges,
  editRevision,
  recalculateRevision,
  resetRevision,
  getLastRevisionYearMonths,
  getErrorsOnSave,
  uiSetErrorsOnSave,
  INIT_CALC_MODE
} from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import RevisionItem from '../components/RevisionItem';

const mapStateToProps = (state, ownProps) => ({
  formRevision: getFormValues('revisions')(state).revisions[ownProps.revision.id],
  errors: _.get(getFormSyncErrors('revisions')(state), ['revisions', ownProps.revision.id]),
  showMode: getUiShowMode(state, ownProps.revision),
  changedYearMonths: getChangedYearMonths(state),
  lastRevisionYearMonths: getLastRevisionYearMonths(state),
  insuredPersonTypes: getSelectOptions(state, 'insuredPersonTypes'),
  errorsOnSave: getErrorsOnSave(state, ownProps.revision)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  changeToEditMode: () => {
    dispatch(uiChangeShowMode({ revision: ownProps.revision, showMode: 'EDIT' }));
  },
  changeToManualCalcMode: name => {
    dispatch(uiChangeCalcMode({ revision: ownProps.revision, name, calcMode: 'MANUAL' }));
  },
  notifyValueChanges: () => {
    dispatch(uiNotifyValueChanges({ revision: ownProps.revision }));
  },
  setChangedAmountByAmountChangedAt: amountChangedAt => {
    if (amountChangedAt !== null) {
      dispatch(
        formChange('revisions', `revisions.${ownProps.revision.id}.changedAmount`, ownProps.revision.changedAmount)
      );
    } else {
      dispatch(formChange('revisions', `revisions.${ownProps.revision.id}.changedAmount`, 0));
    }
  },
  setRetroactiveEnabledAllTrue: () => {
    dispatch(formChange('revisions', `revisions.${ownProps.revision.id}.firstMonthRetroactiveEnabled`, true));
    dispatch(formChange('revisions', `revisions.${ownProps.revision.id}.secondMonthRetroactiveEnabled`, true));
    dispatch(formChange('revisions', `revisions.${ownProps.revision.id}.thirdMonthRetroactiveEnabled`, true));
  },
  cancel: () => {
    dispatch(formChange('revisions', `revisions.${ownProps.revision.id}`, ownProps.revision));
    dispatch(uiChangeShowMode({ revision: ownProps.revision, showMode: 'SHOW' }));
    _.forIn(INIT_CALC_MODE, (value, name) => {
      dispatch(uiChangeCalcMode({ revision: ownProps.revision, name, calcMode: 'AUTO' }));
    });
    dispatch(uiSetErrorsOnSave({ revision: ownProps.revision, errors: {} }));
  },
  save: errors => {
    dispatch(editRevision(ownProps.revision.id, ownProps.applicableOffice));
    dispatch(uiSetErrorsOnSave({ revision: ownProps.revision, errors }));
  },
  recalculate: () => {
    dispatch(recalculateRevision(ownProps.revision.id, ownProps.applicableOffice));
  },
  reset: () => {
    dispatch(resetRevision(ownProps.revision.id, ownProps.applicableOffice));
  },
  setFormField: (name, val) => {
    dispatch(formChange('revisions', `revisions.${ownProps.revision.id}.${name}`, val));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(RevisionItem);
