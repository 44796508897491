import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { reduxForm, arrayPush as arrayPushForm, FieldArray, reset as resetForm } from 'redux-form';
import ErrorsSection from 'src/components/ErrorsSection';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import classnames from 'classnames';
import { concatParamsToUrl } from 'src/utils/Http';
import { setGlobalErrors } from 'src/reducers/global';
import { getList } from 'src/reducers/common/listReducer';
import ActionButton from 'jbc-front/components/ActionButton';
import { Edit, Strage, MinusCircle, ChangeRow } from 'jbc-front/components/icons';
import { EMPLOYEE_SHOW, EMPLOYEES_LIST } from 'src/constants/EndpointUrls';
import { EMPLOYEE_PERSONNEL_HISTORY_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';
import {
  fetchEmployeePersonnelHistories,
  savePersonnelHistories,
  REDUCER_NAME
} from 'src/reducers/employees/employeePersonnelHistories';
import RenderHistories from './components/RenderHistories';

import ConfirmButtons from './components/ConfirmButtons';
import styles from './PersonnelHistoriesList.scss';

const PersonnelHistoriesList = props => {
  const [isEditing, setIsEditing] = useState(false);
  const {
    title,
    employee,
    personnelHistories,
    dispatch,
    handleSubmit,
    submit,
    onAddRow,
    onCancel,
    errors,
    pristine,
    submitting
  } = props;

  useEffect(
    () => {
      dispatch(fetchEmployeePersonnelHistories(employee.id));
    },
    [employee.id]
  );

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">{title}</h1>
      </div>
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="従業員一覧" path={EMPLOYEES_LIST} />
              <BreadCrumb.Item label="従業員情報" path={concatParamsToUrl(EMPLOYEE_SHOW, { id: employee.id })} />
              <BreadCrumb.Item label="人事異動履歴" />
            </BreadCrumb>
          </div>
          <form onSubmit={handleSubmit}>
            <ErrorsSection errors={errors} />
            <div className={classnames('u-mb20', styles.btn_group)}>
              {isEditing ? (
                <div className={classnames(styles.action, styles.btn_edit)}>
                  <ActionButton
                    disabled={submitting || pristine}
                    onClick={submit}
                    className={styles.form_buttons}
                    icon={<Strage size={16} />}
                  >
                    保存
                  </ActionButton>
                  <ActionButton
                    onClick={async () => {
                      await onCancel();
                      await setIsEditing(false);
                    }}
                    className={styles.form_buttons}
                    icon={<MinusCircle size={16} />}
                  >
                    キャンセル
                  </ActionButton>
                  <ActionButton onClick={onAddRow} className={styles.form_buttons} icon={<ChangeRow size={16} />}>
                    行を追加
                  </ActionButton>
                </div>
              ) : (
                <div className={classnames(styles.action, styles.btn_edit)}>
                  <ActionButton
                    onClick={() => {
                      setIsEditing(true);
                    }}
                    icon={<Edit size={16} />}
                  >
                    編集
                  </ActionButton>
                </div>
              )}
              <ConfirmButtons />
            </div>
            <FieldArray
              name={EDITABLE_LIST_FIELDS_NAME}
              component={RenderHistories}
              histories={personnelHistories}
              isEditing={isEditing}
            />
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  initialValues: { [EDITABLE_LIST_FIELDS_NAME]: getList(REDUCER_NAME, state) }
});

const mapDispatchToProps = dispatch => ({
  onAddRow: () => {
    dispatch(
      arrayPushForm(EMPLOYEE_PERSONNEL_HISTORY_FORM, EDITABLE_LIST_FIELDS_NAME, {
        startedOn: '',
        officeId: '',
        employmentTypeId: '',
        positionId: '',
        occupationId: '',
        groupId: ''
      })
    );
  },
  onCancel: () => {
    dispatch(resetForm(EMPLOYEE_PERSONNEL_HISTORY_FORM));
  },
  onSubmit: async data => {
    await dispatch(savePersonnelHistories(data));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: EMPLOYEE_PERSONNEL_HISTORY_FORM,
    enableReinitialize: true,
    onSubmitFail: (errors, dispatch) => {
      errors.fields.forEach(item => {
        if (item.startedOn) {
          dispatch(setGlobalErrors(item.startedOn));
        }
        if (item.officeManageName) {
          dispatch(setGlobalErrors(item.officeManageName));
        }
      });
    }
  })
)(PersonnelHistoriesList);
