import { connect } from 'react-redux';
import get from 'lodash/get';
import { getFormValues } from 'redux-form';
import { withModal } from 'jbc-front/components/CommonModal';
import { getRadioOptions } from 'src/reducers/selectOptions';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import BankItemSection from '../components/BankItemSection';

const mapStateToProps = (state, ownProps) => ({
  depositTypes: getRadioOptions(state, 'depositTypes'),
  formKey: ownProps.formKey,
  bonusPaymentBanks: get(getFormValues(EMPLOYEE_FORM)(state), 'bonusPaymentBanks'),
  payPaymentBanks: get(getFormValues(EMPLOYEE_FORM)(state), 'payPaymentBanks')
});

export default connect(mapStateToProps)(withModal(BankItemSection));
