import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import {
  CLIENT_BONUS_ALLOWANCE_NEW,
  CLIENT_SETTINGS,
  CLIENT_BONUS_ALLOWANCE_CHANGE_DISPLAY_ORDERS
} from 'src/constants/EndpointUrls';
import Tag from 'jbc-front/components/Tag';
import ActionButton from 'jbc-front/components/ActionButton';
import { Plus, Drugging } from 'jbc-front/components/icons';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import { getClientAllowancesBonusList } from 'src/reducers/clients/bonusAllowances';
import { getSelectOptions } from 'src/reducers/selectOptions';
import BonusAllowancesTable from './BonusAllowancesTable';
import styles from './ClientBonusAllowancesTable.scss';

const SelectAllowanceType = ({ allowanceTypes, selectedAllowanceType, handleOnClick }) => (
  <div className={styles.filter}>
    {allowanceTypes.map(allowanceType => (
      <Tag
        key={allowanceType.value}
        active={allowanceType.value === selectedAllowanceType}
        onClick={e => {
          e.preventDefault();
          handleOnClick(allowanceType);
        }}
      >
        {allowanceType.label}
      </Tag>
    ))}
  </div>
);

class ClientBonusAllowancesTable extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnSelectAllowanceType = this.handleOnSelectAllowanceType.bind(this);

    this.state = {
      allAllowances: this.props.clientBonusAllowances,
      selectedAllowanceType: 'payment'
    };
  }

  getDisplayAllowances() {
    return this.state.allAllowances.filter(item => item.allowanceType === this.state.selectedAllowanceType);
  }

  handleOnSelectAllowanceType(allowanceType) {
    this.setState({
      ...this.state,
      selectedAllowanceType: allowanceType.value
    });
  }

  render() {
    const { bonusAllowanceTypes } = this.props;

    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">賞与規定</h1>
        </div>
        <div className="l-wrap-s l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
              <BreadCrumb.Item label="賞与規定" />
            </BreadCrumb>
          </div>

          <div className={styles.head}>
            <div>
              <SelectAllowanceType
                allowanceTypes={bonusAllowanceTypes}
                selectedAllowanceType={this.state.selectedAllowanceType}
                handleOnClick={this.handleOnSelectAllowanceType}
              />
            </div>

            <div>
              <ActionButton as="a" href={CLIENT_BONUS_ALLOWANCE_NEW} icon={<Plus size={10} />}>
                項目を追加
              </ActionButton>
              <ActionButton
                as="a"
                href={CLIENT_BONUS_ALLOWANCE_CHANGE_DISPLAY_ORDERS}
                className="u-ml10"
                icon={<Drugging size={10} />}
              >
                項目並べ替え
              </ActionButton>
            </div>
          </div>
          <BonusAllowancesTable allowances={this.getDisplayAllowances()} handleDeleteRow={this.handleDeleteRow} />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    bonusAllowanceTypes: getSelectOptions(state, 'bonusAllowanceTypes'),
    clientBonusAllowances: getClientAllowancesBonusList(state)
  }),
  dispatch => ({
    displayErrors: msg => dispatch(setGlobalErrors(msg)),
    displaySuccesses: msg => dispatch(setGlobalSuccesses(msg))
  })
)(ClientBonusAllowancesTable);
