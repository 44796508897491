import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import Expand from 'jbc-front/components/icons/Expand';

const ExpandActionBtn = ({ onClick, label = '全て展開', ...rest }) => (
  <ActionButton as="button" icon={<Expand />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default ExpandActionBtn;
