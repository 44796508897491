import React from 'react';

import styles from 'src/components/HeadErrors.scss';

const HeadErrors = ({ errors }) => (
  <div>
    {errors.length > 0 ? (
      <ul className={styles.list}>
        {errors.map(error => (
          <li key={error} className={styles.item}>
            {error}
          </li>
        ))}
      </ul>
    ) : null}
  </div>
);

export default HeadErrors;
