import React, { useEffect } from 'react';
import _ from 'lodash';
import AllowanceDeductionForm from 'src/components/AllowanceDeductionForm';
import { CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM } from 'src/constants/FormNames';
import { Label } from 'jbc-front/components/Form';
import { PaymentDateSelectBoxes } from 'src/components';

const ShowAllowanceDeductionForm = props => {
  const { formValues, uniquePayrollRuleGroups, formChange, fetchAllowancesDeductionsJobStatus, resetPolling } = props;

  useEffect(
    () => {
      const selectingPayrollRuleGroup = formValues.uniquePayrollRuleGroups;
      if (_.isUndefined(selectingPayrollRuleGroup)) {
        return;
      }
      const selectingPayrollRuleGroupParams = _.filter(uniquePayrollRuleGroups, { value: selectingPayrollRuleGroup });
      const uniqueString = [
        formValues.uniquePaymentDate,
        selectingPayrollRuleGroupParams[0].closingDay,
        selectingPayrollRuleGroupParams[0].paymentDay,
        selectingPayrollRuleGroupParams[0].paymentMonthType,
        formValues.formatId
      ].join('_');
      formChange('uniqueString', uniqueString);
    },
    [formValues.formatId, formValues.uniquePaymentDate, formValues.uniquePayrollRuleGroups]
  );

  useEffect(
    () => {
      const { formatId, uniquePaymentDate } = formValues;
      if (_.isUndefined(formatId) || _.isUndefined(uniquePaymentDate) || _.isUndefined(uniquePayrollRuleGroups)) {
        return;
      }
      resetPolling();
      fetchAllowancesDeductionsJobStatus(formValues.uniqueString);
    },
    [formValues.uniqueString]
  );

  const mainSelectField = () => (
    <React.Fragment>
      <Label text="給与" required />
      <PaymentDateSelectBoxes
        formName={CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM}
        updateLocation={false}
        onlyConfirmed
      />
    </React.Fragment>
  );
  return <AllowanceDeductionForm mainSelectField={mainSelectField} {...props} />;
};

export default ShowAllowanceDeductionForm;
