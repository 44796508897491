import React from 'react';

import styles from './EditableSelectField.scss';

class EditableSelectField extends React.Component {
  static defaultProps = {
    inputWidth: 60,
    inputVerticalPadding: 8
  };

  render() {
    const { input: inputProps, options, showValue, showMode, unit, innerWidth } = this.props;
    return (
      <div className={styles.container}>
        {showMode === 'SHOW' ? (
          <div style={{ display: 'inline' }}>{showValue}</div>
        ) : (
          <select width={innerWidth} {...inputProps}>
            {options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        )}
        {unit && <span className="unit">{unit}</span>}
      </div>
    );
  }
}

export default EditableSelectField;
