import { connect } from 'react-redux';

import { initialize } from 'redux-form';
import { push } from 'connected-react-router';

import MyNumberSearchForm from '../components/MyNumberSearchForm';

const mapStateToProps = state => ({
  location: state.router.location
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  handleClear: location => {
    const { formName, resetValues } = ownProps;
    dispatch(initialize(formName, resetValues));
    dispatch(push({ ...location, search: '' }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MyNumberSearchForm);
