import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import { connect } from 'react-redux';
import { CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM } from 'src/constants/FormNames';
import { downloadBonusAllowancesDeductions } from 'src/reducers/clients/clientBonusAsync';
import ShowAllowanceDeductionsFormContainer from './ShowAllowanceDeductionFormContainer';

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(downloadBonusAllowancesDeductions(data))
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM,
    initialValues: {
      exportType: 'each_employee',
      exportDirection: 'horizontal',
      exportTextCode: 'UTF-8'
    }
  })
)(ShowAllowanceDeductionsFormContainer);
