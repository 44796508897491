import React from 'react';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { TextField, FileField, RadioField, Required } from 'jbc-front/components/Form';
import { ErrorsSection } from 'src/components';
import Loading from 'src/components/Loading';
import { eGovSerialNumber } from 'src/utils/CustomValidators';

import styles from 'src/components/Egov/EgovModal.scss';

const CheckErrorHeaderTitle = () => (
  <pre>
    入力内容に誤りがあります。
    <br />
    修正後に再度申請をお願いします。
  </pre>
);

const EgovModal = props => {
  const {
    handleSubmit,
    submitting,
    pristine,
    submit,
    invalid,
    isOpen,
    hideModal,
    isLoading,
    dataCheckErrors,
    postErrors,
    withEmployeeSearch
  } = props;

  const egovTargetOptions = [
    { value: 'all_employee', label: '全従業員' },
    { value: 'searched_employee', label: '検索絞込中の従業員' }
  ];

  return (
    <CommonModal className={styles.modal} ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>電子申請（e-Gov連携）</CommonModal.Header>
      <form onSubmit={handleSubmit} className={styles.modalForm}>
        <CommonModal.Body>
          <React.Fragment>
            {isLoading && <Loading type="spin" color="#e3e3e3" />}
            {!isLoading && (
              <>
                <ErrorsSection errors={dataCheckErrors} title={<CheckErrorHeaderTitle />} />
                <ErrorsSection errors={postErrors} />
              </>
            )}
            <div className={styles.itemBox}>
              <div className={styles.inputLabel}>
                <p>媒体通番</p>
                <Required />
              </div>
              <TextField
                required
                noLabel
                label="媒体通番"
                type="text"
                name="serialNumber"
                maxLength="3"
                validate={[eGovSerialNumber]}
              />
            </div>
            <div className={styles.itemBox}>
              <div className={styles.inputLabel}>
                <p>添付書類</p>
              </div>
              <div className={styles.updateFiles}>
                <FileField name="uploadFiles" multiple accept={'.jpeg,.jpg,.pdf'} />
              </div>
            </div>
            {withEmployeeSearch && (
              <div>
                <div className={styles.itemBox}>
                  <div className={styles.inputLabel}>
                    <p>対象選択</p>
                  </div>
                  <div className={styles.egovRadioField}>
                    <RadioField name="egovTarget" options={egovTargetOptions} />
                  </div>
                </div>
                <div className={styles.note}>
                  対象選択：「検索絞込中の従業員」を選んだ場合もステータスによる絞り込み条件は無視されます
                </div>
              </div>
            )}
            <div className={styles.cautionArea}>
              <p className={styles.cautionText}>【注意】</p>
              <p>申請はジョブカン労務HRに登録されている電子証明書を利用して行います。</p>
              <p className={styles.buttomPaddingText}>申請前に登録情報が正しいかご確認ください。</p>
              <p className={styles.requiredText}>
                申請はジョブカン労務HRの「電子申請一覧」の画面より行うことができます。
              </p>
              <p>
                労務HRの全権管理者、事業所管理者、運用担当者でない場合は、権限の変更を受けるか、権限を持つ管理者に申請を依頼してください。
              </p>
            </div>
            <div className={styles.buttonBox}>
              <Button onClick={hideModal} className="u-mr20">
                キャンセル
              </Button>
              <Button primary onClick={submit} disabled={invalid || pristine || submitting}>
                申請を準備する
              </Button>
            </div>
          </React.Fragment>
        </CommonModal.Body>
      </form>
    </CommonModal>
  );
};

export default EgovModal;
