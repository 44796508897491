import React from 'react';
import { change as changeForm } from 'redux-form';
import { OFFICE_FORM } from 'src/constants/FormNames';
import { Section } from 'jbc-front/components/Form';
import { Copy } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import OfficeSection from '../../containers/edit/OfficeSectionContainer';
import styles from './LabourInsuranceContactInfoSection.scss';

const EmploymentInsuranceContactInfoSection = ({ office, employmentInsurance, dispatch }) => {
  const handleCopyButton = () => {
    Object.keys(employmentInsurance.contactInfo).forEach(k => {
      if (k !== 'id') {
        dispatch(changeForm(OFFICE_FORM, `employmentInsurance.contactInfo.${k}`, office[k]));
      }
    });
  };

  return (
    <Section title="雇用保険 事業所情報">
      <div className={styles.copyField}>
        <Button className={styles.copyButton} onClick={handleCopyButton}>
          <Copy />事業所情報をコピーする
        </Button>
      </div>
      <OfficeSection formSectionName={'employmentInsurance'} />
    </Section>
  );
};

export default EmploymentInsuranceContactInfoSection;
