import React from 'react';
import { FieldArray } from 'redux-form';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { PlusSquare } from 'jbc-front/components/icons';
import BankItemSectionContainer from '../containers/BankItemSectionContainer';

const NUMBER_OF_MAX_EMPLOYEE_BANK = 6;

const Banks = ({ fields, lastNameKana, firstNameKana, fieldInitialize }) => (
  <div>
    {fields.map((bank, index) => (
      <BankItemSectionContainer
        key={bank}
        index={index}
        formKey={bank}
        formName={EMPLOYEE_FORM}
        remove={() => fields.remove(index)}
      />
    ))}
    {fields.length < NUMBER_OF_MAX_EMPLOYEE_BANK && (
      <div className="u-ta-r u-mt20 u-mb20">
        <div
          role="button"
          tabIndex="0"
          onClick={() => {
            // 画面上に振込先口座が1つもない場合、支給設定を初期化する。
            if (fields.length === 0) {
              fieldInitialize();
            }
            fields.push({ accountHolderKana: [lastNameKana, firstNameKana].filter(word => word !== '').join('　') });
          }}
          className="u-txt-addinfo"
        >
          <PlusSquare size={20} className="u-txt-addinfo-icon" />新しい振込先を追加
        </div>
      </div>
    )}
  </div>
);
const BanksSection = ({ lastNameKana, firstNameKana, fieldInitialize }) => (
  <FieldArray
    name="employeeBanks"
    component={Banks}
    lastNameKana={lastNameKana}
    firstNameKana={firstNameKana}
    fieldInitialize={fieldInitialize}
  />
);

export default BanksSection;
