import React from 'react';
import Button from 'jbc-front/components/Button';
import DownloadBonusReports from 'src/features/EmployeeBonus/containers/DownloadBonusReportsContainer';
import EmployeeBonusMethod from './EmployeeBonusMethod';
import AllowanceDeductionsMethod from './AllowanceDeductionsMethod';
import styles from './ExtraActionsSection.scss';

class ExtraActionsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBonusBaloonOpen: false,
      isAllowanceDeductionsOpen: false
    };
  }

  render() {
    const {
      confirmed,
      downloadTemplateCsvPageUrl,
      showBonusFbDataUrl,
      currentBonus,
      clientBonusId,
      isConfirmed,
      downloadAccountingBonus
    } = this.props;
    return (
      <div className={styles.wrap}>
        <div className={styles.button}>
          <Button
            widthAuto
            className="ignore-react-onclickoutside"
            onClick={() =>
              this.setState({
                isAllowanceDeductionsOpen: !this.state.isAllowanceDeductionsOpen,
                isBonusBaloonOpen: false
              })
            }
          >
            支給控除項目
          </Button>
          {this.state.isAllowanceDeductionsOpen && (
            <AllowanceDeductionsMethod
              hide={() => this.setState({ isAllowanceDeductionsOpen: false })}
              downloadTemplateCsvPageUrl={downloadTemplateCsvPageUrl}
              currentBonus={currentBonus}
              confirmed={confirmed}
              downloadAccountingBonus={downloadAccountingBonus}
            />
          )}
        </div>
        <div className={styles.button}>
          <Button
            widthAuto
            disabled={!confirmed}
            className="ignore-react-onclickoutside"
            onClick={() =>
              this.setState({ isBonusBaloonOpen: !this.state.isBonusBaloonOpen, isAllowanceDeductionsOpen: false })
            }
          >
            賞与振込
          </Button>
          <EmployeeBonusMethod
            hide={() => this.setState({ isBonusBaloonOpen: false })}
            showBonusFbDataUrl={showBonusFbDataUrl}
            confirmed={confirmed}
            isOpen={this.state.isBonusBaloonOpen}
          />
        </div>
        <DownloadBonusReports clientBonusId={clientBonusId} isConfirmed={isConfirmed} />
      </div>
    );
  }
}

export default ExtraActionsSection;
