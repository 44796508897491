import { connect } from 'react-redux';
import ShowClientPaidPlan from '../components/ShowClientPaidPlan';

const mapStateToProps = state => ({
  trialFinishAt: state.currentClient.item.data.trialFinishAt,
  planType: state.currentClient.item.data.planType,
  planControllable: state.currentClient.item.data.planControllable
});

export default connect(mapStateToProps)(ShowClientPaidPlan);
