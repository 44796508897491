import React from 'react';
import _ from 'lodash';

import { getGroupFullPathName } from 'src/utils/Utils';
import { Glass, Close } from 'jbc-front/components/icons';
import styles from './SelectGroupItems.scss';

const flattenDeep = function*(groups) {
  for (let i = 0; i < groups.length; i += 1) {
    yield groups[i];
    yield* flattenDeep(groups[i].childrenGroups);
  }
};

const Search = ({ onChange, value }) => (
  <div className={styles.searchWrap}>
    <input
      className={styles.search}
      placeholder="キーワード入力"
      value={value}
      onChange={e => onChange(e.target.value)}
      type="text"
    />
    <span className={styles.searchIcon}>
      <Glass size={16} />
    </span>
    {value && (
      <span role="button" tabIndex="-1" className={styles.searchClear} onClick={() => onChange('')}>
        <Close size={16} />
      </span>
    )}
  </div>
);

const GroupItem = ({ className, label, onClick }) => (
  <div role="button" tabIndex="-1" className={className} onClick={onClick}>
    {label}
  </div>
);

class SelectGroupItems extends React.Component {
  state = {
    searchStr: ''
  };

  isSelecting = groupId => !!this.props.selectingGroups.find(id => id === groupId);
  subGroups = groupId => {
    const flattenGroups = [...flattenDeep(this.props.allGroups)];
    const group = flattenGroups.find(grp => grp.id === groupId);
    return [...flattenDeep(group.childrenGroups)];
  };
  selectGroupAndSubs = groupId => {
    const includeIds = [groupId, ...this.subGroups(groupId).map(g => g.id)];
    this.props.changeParentState({ selectingGroups: _.uniq([...this.props.selectingGroups, ...includeIds]) });
  };
  unselectGroupAndSubs = groupId => {
    const excludeIds = [groupId, ...this.subGroups(groupId).map(g => g.id)];
    const exclude = id => !excludeIds.find(grpId => id === grpId);
    this.props.changeParentState({ selectingGroups: [...this.props.selectingGroups.filter(exclude)] });
  };

  selectAllGroups = () => {
    const flattenGroups = [...flattenDeep(this.props.allGroups)];
    this.props.changeParentState({ selectingGroups: [...flattenGroups.map(g => g.id)] });
  };
  unselectAllGroups = () => this.props.changeParentState({ selectingGroups: [] });

  render() {
    const { searchStr } = this.state;
    const { allGroups } = this.props;

    const flattenAllGroups = [...flattenDeep(allGroups)];
    const groupsWithFullPaths = flattenAllGroups.map(group => ({
      ...group,
      label: getGroupFullPathName(flattenAllGroups, group.id, ' > ')
    }));
    const groupsWithSearch = searchStr
      ? groupsWithFullPaths.filter(g => g.label.indexOf(searchStr) >= 0)
      : groupsWithFullPaths;

    return (
      <div className={styles.listContainer}>
        <div>
          <div className={styles.title}>選択対象項目</div>
          <div className={styles.multiList}>
            <Search value={searchStr} onChange={value => this.setState({ searchStr: value })} />
            <div className={styles.listWithGroup}>
              {groupsWithSearch.map(group => {
                const selecting = this.isSelecting(group.id);
                const className = selecting ? styles.itemSelected : styles.item;
                let onClick;

                if (selecting) {
                  onClick = () => {
                    this.unselectGroupAndSubs(group.id);
                  };
                } else {
                  onClick = () => {
                    this.selectGroupAndSubs(group.id);
                  };
                }

                return <GroupItem key={group.value} className={className} label={group.label} onClick={onClick} />;
              })}
            </div>
          </div>
          <div className={styles.selectButtons}>
            <a role="button" tabIndex="-1" className={styles.selectButton} onClick={this.unselectAllGroups}>
              選択解除
            </a>
            <a role="button" tabIndex="-1" className={styles.selectButton} onClick={this.selectAllGroups}>
              全項目選択
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectGroupItems;
