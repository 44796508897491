import axios from 'axios';

import * as Urls from 'src/constants/EndpointUrls';
import { setGlobalErrors } from 'src/reducers/global';
import { setOptions } from 'src/reducers/selectOptions';

export const fetchClientPayrollRuleGroups = () => async dispatch => {
  try {
    const payrollRuleGroupsRes = await axios.get(Urls.OPTIONS_CLIENT_PAYROLL_RULE_GROUPS);
    dispatch(
      setOptions({
        payrollRuleGroups: payrollRuleGroupsRes.data.payload.options
      })
    );
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const fetchWageLedgerExportItems = () => async dispatch => {
  try {
    const res = await axios.get(`${Urls.GET_INIT_OPTIONS}?type=wage_ledger_export_items`);
    dispatch(setOptions({ wageLedgerExportItems: res.data.payload.options }));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const fetchCsvAvailableFields = () => async dispatch => {
  try {
    const res = await axios.get(`${Urls.GET_INIT_OPTIONS}?type=csv_avaiable_items`);
    dispatch(setOptions({ csvAvaiableItems: res.data.payload.options }));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const fetchAllGroupsAndSubgroups = (flatten = false) => async dispatch => {
  try {
    const flat = flatten ? '&flatten=true' : '';
    const res = await axios.get(`${Urls.GET_INIT_OPTIONS}?type=groups&sub_groups=true${flat}`);
    dispatch(setOptions({ groups: res.data.payload.options }));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const fetchCsvAllowanceDeductionFormats = () => async dispatch => {
  try {
    const res = await axios.get(`${Urls.GET_INIT_OPTIONS}?type=csv_allowance_deduction_formats`);
    dispatch(setOptions({ csvFormats: res.data.payload.options }));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const fetchAllClientBonus = (onlyConfirmed = false) => async dispatch => {
  try {
    const res = await axios.get(`${Urls.GET_INIT_OPTIONS}?type=bonuses&only_confirmed=${!!onlyConfirmed}`);
    dispatch(setOptions({ clientBonuses: res.data.payload.options }));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const fetchJbcKintaiCsvFormats = () => async dispatch => {
  try {
    const res = await axios.get(`${Urls.GET_INIT_OPTIONS}?type=jbc_csv_formats`);
    dispatch(setOptions({ jbcCsvFormats: res.data.payload.options }));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const fetchEmployeeDetails = () => async dispatch => {
  try {
    const res = await axios.get(`${Urls.GET_INIT_OPTIONS}?type=employee_details`);
    dispatch(setOptions({ ...res.data.payload.options }));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};
