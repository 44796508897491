import React from 'react';
import styles from './InsertBatchCsv.scss';

const InsertBatchCsvDescription = () => (
  <div className="l-wrap-xl">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              団体コード<span className={styles.required}>必須</span>
            </th>
            <td>団体コード</td>
            <td>11111</td>
          </tr>
          <tr>
            <th>市区町村名</th>
            <td>
              市区町村名
              <br />
              ※ダウンロード時には出力されますが、更新時には無視されます
            </td>
            <td>千葉県柏市</td>
          </tr>
          <tr>
            <th>
              指定番号<span className={styles.required}>必須</span>
            </th>
            <td>
              指定番号
              <br />
              登録したい指定番号を入力。
            </td>
            <td>11111</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default InsertBatchCsvDescription;
