import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { DateField, CheckboxField, SelectField } from 'jbc-front/components/Form';
import { FormSubmitBtn } from 'src/buttons';
import { CONFIRM_CLIENT_BONUS } from 'src/constants/FormNames';
import { formatDate } from 'src/utils/Date';
import { ErrorsSection, LoadingWithDescription } from 'src/components';
import { get24HoursOptions } from 'src/utils/Form';
import { getChangeConfirmBonusErrors } from 'src/reducers/clients/clientBonus';
import { takeBonusFromSearchForm } from 'src/reducers/searchForm';
import { isYYYYMMDDFormat } from 'src/utils/CustomValidators';
import styles from './ConfirmBonusModal.scss';

const DEFAULT_RELEASE_HOUR = '9';

const genInitialValue = selectedClientBonus => {
  const { payOn } = selectedClientBonus || {};
  if (isEmpty(payOn)) {
    return {};
  }
  return { releaseDate: payOn, releaseHour: DEFAULT_RELEASE_HOUR };
};

const ConfirmBonusInsideForm = ({ submitting, submit, cancelBtnClick, selectedClientBonus, errors, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form>
        <div className={styles.body}>
          <ErrorsSection errors={errors} title={'設定内容に誤りがあります'} />
          {submitting && (
            <div className="u-mb20">
              <LoadingWithDescription text="確定中です。少々お待ちください。" />
            </div>
          )}
          <div>
            賞与を確定します<br />
            (支給日 : {formatDate(selectedClientBonus.payOn)})
            <br />
            <br />
          </div>
          <DateField required name="releaseDate" label="従業員への明細公開時刻" validate={isYYYYMMDDFormat} />
          <div style={{ width: '120px' }}>
            <SelectField required label="" name="releaseHour" options={get24HoursOptions()} width={100} />
          </div>
          <div>
            <CheckboxField name="send_notify_to_employee" label="明細公開と同時に通知メールを従業員に送信する" />
          </div>
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="確定" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let ConfirmBonusForm = reduxForm({
  form: CONFIRM_CLIENT_BONUS
})(ConfirmBonusInsideForm);
const mapStateToProp = (state, ownProps) => ({
  initialValues: genInitialValue(ownProps.selectedClientBonus),
  errors: getChangeConfirmBonusErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: data => ownProps.confirmClientBonus(ownProps.selectedClientBonus.value, data, ownProps.cancelBtnClick)
});

ConfirmBonusForm = connect(mapStateToProp, mapDispatchToProp)(ConfirmBonusForm);

const ConfirmBonusModal = ({ isOpen, hideModal, confirmClientBonus, selectedClientBonus }) => {
  const header = '賞与確定';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <ConfirmBonusForm cancelBtnClick={hideModal} {...{ confirmClientBonus, selectedClientBonus }} header={header} />
    </CommonModal>
  );
};

const mapStateToProps = state => ({
  selectedClientBonus: takeBonusFromSearchForm(state)
});
export default connect(mapStateToProps)(ConfirmBonusModal);
