import React from 'react';

import { TextAreaField } from 'jbc-front/components/Form';
import { isModeEdit } from './EmployeeBonusForm';
import styles from './EmployeeBonusForm.scss';

const NoMemoDefaultDisplay = () => <div className={styles.noMemo} />;

const NoteSection = ({ mode, note }) => (
  <table className="m-table-obvious">
    <thead>
      <tr>
        <th>備考</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td className="m-table-obvious-remarks">
          {isModeEdit(mode) ? (
            <TextAreaField name="note" rows={3} />
          ) : (
            <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{note || <NoMemoDefaultDisplay />}</div>
          )}
        </td>
      </tr>
    </tbody>
  </table>
);

export default NoteSection;
