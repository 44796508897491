import React, { Component } from 'react';
import { reduxForm } from 'redux-form';

import Button from 'jbc-front/components/Button';
import { FileField } from 'jbc-front/components/Form';
import { Question, Upload } from 'jbc-front/components/icons';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { EMPLOYEES_LIST } from 'src/constants/EndpointUrls';
import isUseOtherApp from 'src/utils/JbcId';

import BusinessNameConfirmationModal from './BusinessNameConfirmationModal';
import HelpUpdateBatchCsv from '../HelpUpdateBatchCsv';
import styles from '../styles/FileImport.scss';

class FileImportUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHelp: false,
      isOpenConfirmationModal: false
    };
  }

  render() {
    const { submitting, handleSubmit, pristine, errors } = this.props;

    return (
      <div className={styles.wrap}>
        <div className={styles.head}>アップロード手順</div>
        <div className={styles.body}>
          <form onSubmit={handleSubmit}>
            <ErrorsSection errors={errors} />

            <dl className={styles.label}>
              <dt className={styles.number}>1</dt>
              <dd className={styles.detail}>
                <div>
                  従業員情報を<a href={EMPLOYEES_LIST} className="u-txt-link" target="_blank">
                    こちら
                  </a>
                  <span
                    className={styles.helpWrap}
                    onClick={() => {
                      this.setState({ ...this.state, showHelp: !this.state.showHelp });
                    }}
                    role="presentation"
                  >
                    <Question />
                    {this.state.showHelp && (
                      <HelpUpdateBatchCsv
                        hide={() => {
                          this.setState({ ...this.state, showHelp: false });
                        }}
                      />
                    )}
                  </span>
                  からダウンロードしてください。
                </div>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>2</dt>
              <dd className={styles.detail}>
                下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したい従業員の情報を入れてください。
                <div className={styles.warning}>
                  <span className={styles.warningTitle}>【注意】</span>
                  <ul className={styles.warningList}>
                    <li>
                      ①以下の項目は<span className={styles.cautionText}>入力必須</span>になります。
                      <ul className={styles.requireList}>
                        <li>・スタッフコード</li>
                        <li>・姓</li>
                        <li>・名</li>
                        <li>・入社日</li>
                        <li>・（基本給、</li>
                        <li>　　今年の年間収入見積額、</li>
                        <li>　　今年の年間所得見積額、</li>
                        <li>　　給与規定グループで設定した支給・控除項目、</li>
                        <li>　　給与規定グループで設定した単価を設定する場合）</li>
                        <li>　　・給与規定グループ</li>
                        <li>・（通勤手当の情報を登録する場合）</li>
                        <li>　　・通勤手段</li>
                        <li>　　・選択した通勤手段において設定可能な項目すべて</li>
                        <li>　　　※通勤手段が入力された場合、空欄の項目の値は削除</li>
                        <li>　　　　されます</li>
                        <li>・（被扶養者の情報を登録する場合）</li>
                        <li>　　・被扶養者 続柄</li>
                        <li>　　・被扶養者 姓</li>
                        <li>　　・被扶養者 名</li>
                        <li>　　・被扶養者 生年月日</li>
                        <li>・（支給設定（給与）銀行振込1 金額を登録する場合）</li>
                        <li>　　・支給設定（給与）銀行振込1 振込先</li>
                        <li>　　・支給設定（給与）銀行振込1 振込元</li>
                        <li>・（支給設定（賞与）銀行振込1 金額を登録する場合）</li>
                        <li>　　・支給設定（賞与）銀行振込1 振込先</li>
                        <li>　　・支給設定（賞与）銀行振込1 振込元</li>
                        <li>・（休職期間を登録する場合）</li>
                        <li>　　・休職開始日</li>
                        <li>　　・休職終了日</li>
                        <li>　　・休職理由</li>
                        <li>　　・固定的賃金を0とする</li>
                      </ul>
                    </li>
                    <li>
                      ②スタッフコードが一致する従業員情報を更新しますので<span className={styles.cautionText}>
                        正しいスタッフコードをご記入ください
                      </span>。
                    </li>
                    <li>
                      ③空白の状態で更新すると、<span className={styles.cautionText}>
                        データが削除されますのでご注意ください
                      </span>。（被扶養者の情報は空白の状態で更新しても削除されません。）
                    </li>
                    <li>
                      ④被扶養者の情報は姓、名、生年月日が一致している場合のみ更新されます。姓、名、生年月日が一致していない場合は、新規の被扶養者として登録されます。
                    </li>
                    {isUseOtherApp && (
                      <li>
                        ⑤更新するユーザが、ジョブカン他サービスにも登録されている場合、
                        <span className={styles.cautionText}>
                          姓名・メールアドレスを変更すると、ご利用中のジョブカン他サービスにも自動反映されます。
                          <br />
                          また、ビジネスネーム姓名に入力された場合はビジネスネーム姓名が他サービスに自動反映されます。
                          <br />
                          （但しジョブカン労務HR上でビジネスネームが設定済みの従業員に関しては、
                          ビジネスネーム姓名に対して自動反映されます）
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>3</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
                <FileField name="employee_csv" className={styles.input} />
              </dd>
            </dl>

            <Button
              primary
              icon={<Upload size={16} />}
              widthWide
              onClick={() => this.setState({ ...this.state, isOpenConfirmationModal: true })}
            >
              アップロード
            </Button>
          </form>
        </div>
        {this.state.isOpenConfirmationModal && (
          <BusinessNameConfirmationModal
            hideModal={() => this.setState({ ...this.state, isOpenConfirmationModal: false })}
            isOpen={this.state.isOpenConfirmationModal}
            isCsv
            {...{ handleSubmit, submitting, pristine }}
          />
        )}
      </div>
    );
  }
}

const CsvUpdateForm = reduxForm({
  form: EMPLOYEE_FORM,
  enableReinitialize: true
})(({ submitting, handleSubmit, errors }) => (
  <FileImportUpdate submitting={submitting} handleSubmit={handleSubmit} errors={errors} />
));

export default CsvUpdateForm;
