import React from 'react';
import { LowerPager } from 'src/components';
import AuditLogsTable from './AuditLogsTable';

export default class AuditLogsList extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const {
      onChangePerPage,
      onPageChange,
      pageCount,
      initialPage,
      perPage,
      perPages,
      totalCount,
      displayFrom,
      displayTo,
      isLoading
    } = this.props;
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">操作履歴一覧</h1>
        </div>
        <div className="l-wrap-s l-contents-wrap">
          <AuditLogsTable />
          <LowerPager
            pageCount={pageCount}
            totalCount={totalCount}
            displayFrom={displayFrom}
            displayTo={displayTo}
            initialPage={initialPage - 1}
            forcePage={initialPage - 1}
            perPage={perPage}
            perPages={perPages}
            onPageChange={e => onPageChange(e.selected)}
            onPerPageChange={e => onChangePerPage(e.target.value)}
            isLoading={isLoading}
          />
        </div>
      </div>
    );
  }
}
