import _ from 'lodash';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import classNames from 'classnames';
import { CLIENT_SETTINGS, OFFICE_NEW } from 'src/constants/EndpointUrls';
import { getList } from 'src/reducers/common/listReducer';
import Button from 'jbc-front/components/Button';
import { REDUCER_NAME, fetchOffices } from 'src/reducers/offices/offices';
import OfficeDetail from './components/OfficeDetail';
import styles from './OfficeList.scss';
import CoordinationLms from './components/CoordinationLms';

const OfficeList = props => {
  const { offices, dispatch, planType } = props;
  useEffect(() => {
    dispatch(fetchOffices());
  }, []);
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">事業所情報一覧</h1>
      </div>
      <div className="l-wrap-l l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="事業所一覧" />
          </BreadCrumb>
        </div>
        <div className={classNames('l-wrap-l', styles.registerBtn)}>
          <div className="u-mb20" style={{ paddingRight: 10 }}>
            <CoordinationLms isOnlyMainOffice={false} planType={planType} />
          </div>
          <div className="u-mb20">
            <Button href={OFFICE_NEW} as="a" primary>
              新規事業所登録
            </Button>
          </div>
        </div>
        <div className="l-overflow-scroll">
          <table className="m-table-list">
            <thead>
              <tr>
                <th />
                <th>事業所コード</th>
                <th>事業所</th>
                <th>住所</th>
                <th>電話番号</th>
                <th />
              </tr>
            </thead>
            <tbody>{offices && offices.map(office => <OfficeDetail key={office.id} office={office} />)}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  offices: getList(REDUCER_NAME, state),
  planType: _.get(state, 'currentClient.item.data.planType')
});

export default connect(mapStateToProps, null)(OfficeList);
