import { connect } from 'react-redux';
import {
  downloadPayrollFBData,
  getDownloadPayrollFBDataErrors,
  clearDownloadPayrollFBDataErrors
} from 'src/reducers/employees/employeePays';
import { FORM_NAME_PAYS_SEARCH, takePaymentRangeFormSearchForm } from 'src/reducers/searchForm';
import FbDataModal from '../components/PayrollFBDataModal';

const mapStateToProps = state => ({
  paymentRange: takePaymentRangeFormSearchForm(state, FORM_NAME_PAYS_SEARCH),
  errors: getDownloadPayrollFBDataErrors(state)
});
const mapDispatchToProps = dispatch => ({
  onModalHide: () => dispatch(clearDownloadPayrollFBDataErrors()),
  handleSubmit: (data, paymentRange) => {
    dispatch(downloadPayrollFBData({ ...data, ...paymentRange }));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(FbDataModal);
