import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { concatParamsToUrl } from 'src/utils/Http';

import {
  REPORT_LABOR_INSURANCE_SUMMARIES,
  REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS,
  UPDATE_BATCH_CSV
} from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { ErrorsSection } from 'src/components';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME } from 'src/reducers/reports/reportLaborInsurances';
import LaborInsuranceDownloadCsvForm from './LaborInsuranceDownloadCsvForm';
import LaborInsuranceDownloadColumnDetail from './LaborInsuranceDownloadColumnDetail';
import ImportCsvJobStatus from '../containers/ImportCsvJobStatusContainer';
import styles from './LaborInsuranceDownloadCsvPage.scss';

const LaborInsuranceDownloadCsvPage = props => {
  const { laborInsuranceYear } = props.values.queries;
  const errors = useSelector(state => getItemErrors(REDUCER_NAME, state));

  return (
    <div>
      <div className="l-wrap-xs l-contents-wrap">
        <BreadCrumb>
          <BreadCrumb.Item label="労働保険の年度更新" path={REPORT_LABOR_INSURANCE_SUMMARIES} />
          <BreadCrumb.Item
            label="内訳一覧"
            path={concatParamsToUrl(REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS, { year: laborInsuranceYear })}
          />
          <BreadCrumb.Item label="月別内訳データの更新" />
        </BreadCrumb>
      </div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">月別内訳データの更新</h1>
      </div>
      <ImportCsvJobStatus />
      <div className={classNames('l-wrap-xs', styles.description)}>
        <ErrorsSection errors={errors} />
        <p>
          以下の月別内訳データの数値をCSVファイルで一括で更新する事ができます。<br />労働保険の年度更新における修正対象者が多い場合に便利です。
        </p>
        <ul className="u-mt10">
          <li>
            ※ 従業員マスタの値は変更されません。従業員マスタごと変更したい場合は
            <a href={UPDATE_BATCH_CSV} className="u-txt-link">
              コチラ
            </a>
          </li>
        </ul>
        <div className={styles.target}>対象: {laborInsuranceYear}年度</div>
      </div>
      <LaborInsuranceDownloadCsvForm laborInsuranceYear={laborInsuranceYear} />
      <div className={styles.detail}>
        <LaborInsuranceDownloadColumnDetail />
      </div>
    </div>
  );
};

export default LaborInsuranceDownloadCsvPage;
