import React from 'react';
import SettingEmailForm from './components/SettingEmailForm';

const ChangeEmailPage = () => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">メールアドレス変更</h1>
    </div>
    <div>
      <SettingEmailForm />
    </div>
  </div>
);

export default ChangeEmailPage;
