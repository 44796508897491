import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import { createPdf } from 'src/reducers/reports/reportWageLedger';
import PdfCreateModal from '../components/PdfCreateModal';

function mapDispatchToProps(dispatch, ownProps) {
  return {
    createClick: () => {
      dispatch(createPdf(ownProps.params, ownProps.formValues, ownProps.uniqueString, ownProps.hideModal));
    }
  };
}

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'PdfCreateForm'
  })
)(PdfCreateModal);
