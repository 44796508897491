import { connect } from 'react-redux';
import _ from 'lodash';
import { REDUCER_NAME } from 'src/reducers/employees/employeePays';
import { getExtras } from 'src/reducers/common/listReducer';
import PayWithInhabitantTable from '../components/PayWithInhabitantTable';

const mapStateToProps = state => {
  const responseData = getExtras(REDUCER_NAME, state);
  return {
    totalAmounts: _.get(responseData, 'totalAmounts') || 0,
    totalEmployees: _.get(responseData, 'totalEmployees') || 0,
    totalResigned: _.get(responseData, 'totalResigned') || 0,
    cityIds: _.get(responseData, 'cityIds') || []
  };
};

export default connect(mapStateToProps)(PayWithInhabitantTable);
