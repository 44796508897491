import { change as formChange } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getExtras, getErrors } from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  getUnconfirmedAlertCount,
  getTargetHealthInsurance,
  getRevisionErrors,
  getImporting
} from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import {
  takeEmployeesResetFormQueries,
  fetchNotificationOfBaseAmountMonthOptions,
  fetchNotificationOfBaseAmountOfficeOptions,
  fetchNotificationOfBaseAmountOptions,
  notificationOfBaseAmountMonthsSearchFormSelector,
  isLoading,
  ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_REVISION_MONTH_OPTIONS,
  FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH
} from 'src/reducers/searchForm';
import Header from '../components/Header';

const buildResetValues = state =>
  // 年度、適用事業所は初期化しない
  // ソート、フィルターは初期化する
  ({
    yearMonth: notificationOfBaseAmountMonthsSearchFormSelector(state, 'yearMonth'),
    applicableOffice: notificationOfBaseAmountMonthsSearchFormSelector(state, 'applicableOffice'),
    sortType: 'staff_code',
    filterType: 'only_targets',
    ...takeEmployeesResetFormQueries(state, true)
  });

const mapStateToProps = state => ({
  revisionYear: getSelectOptions(state, 'revisionYears'),
  applicableOffices: getSelectOptions(state, 'applicableOffices'),
  yearMonth: notificationOfBaseAmountMonthsSearchFormSelector(state, 'yearMonth'),
  applicableOffice: notificationOfBaseAmountMonthsSearchFormSelector(state, 'applicableOffice'),
  resetValues: buildResetValues(state),
  extras: getExtras(REDUCER_NAME, state),
  hasInvalidMonthsErrors: !_.isEmpty(getErrors(REDUCER_NAME, state)),
  revisionErrors: getRevisionErrors(state),
  isOptionsLoading: isLoading(ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_REVISION_MONTH_OPTIONS, state),
  unconfirmedAlertCount: getUnconfirmedAlertCount(state),
  targetHealthInsurance: getTargetHealthInsurance(state),
  importing: getImporting(state)
});

const mapDispatchToProps = dispatch => ({
  filterAlertTargets: () => {
    dispatch(formChange('notificationOfBaseAmountMonthSearchForm', 'filterType', 'alert_targets'));
  },
  fetchNotificationOfBaseAmountOptions: () => {
    dispatch(fetchNotificationOfBaseAmountOptions());
  },
  fetchNotificationOfBaseAmountMonthOptions: () => {
    dispatch(fetchNotificationOfBaseAmountMonthOptions());
  },
  fetchNotificationOfBaseAmountOfficeOptions: () => {
    dispatch(fetchNotificationOfBaseAmountOfficeOptions());
  },
  changeFormValue: (field, value) => {
    dispatch(formChange(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH, field, value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
