import React from 'react';
import { formatDate } from 'src/utils/Date';

const ConfirmDate = ({ yearMonth, paymentDate }) => (
  <div>
    {yearMonth} 給与を確定します<br />
    (支給日 : {formatDate(paymentDate)})
    <br />
    <br />
  </div>
);

export default ConfirmDate;
