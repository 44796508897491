import React from 'react';
import { Field, FormSection } from 'redux-form';
import isNil from 'lodash/isNil';
import toInteger from 'lodash/toInteger';
import Decimal from 'decimal.js';
import { isMoneyNumber, isInsuranceRateFormat } from 'src/utils/CustomValidators';
import { roundSumConfirmedAccidentInsurance } from 'src/reducers/reports/reportLaborInsurances';
import { numberWithComma } from 'src/utils/Utils';
import { CheckboxField } from 'jbc-front/components/Form';
import EditableTextField from './EditableTextField';
import styles from './LaborInsuranceDeclarationForm2022.scss';

const replaceBlankToZero = value => {
  if (isNil(value) || value === '') {
    return 0;
  }

  return value;
};

const replaceMoney = value => {
  const replaced = Number(replaceBlankToZero(value));
  if (Number.isNaN(replaced)) {
    // 数値じゃない場合は0を返す
    return 0;
  }
  return replaced;
};

const replaceRate = value => {
  const replaced = replaceBlankToZero(value);
  if (toInteger(replaced) === 0) {
    return 0;
  }
  return replaced;
};

const isEdit = (actionMode, formVal) => {
  if (isNil(formVal)) {
    return 'SHOW';
  }
  const isAutoCalc = formVal.isAutoCalcConfirmedAccidentInsurance;
  if (actionMode === 'SHOW') {
    return actionMode;
  } else if (isAutoCalc) {
    // 自動計算するにチェックがある場合は編集できない
    return 'SHOW';
  }
  return 'EDIT';
};

const calculation = (updateCalcResult, formVal, changedValue) => {
  let param = {
    confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm: replaceMoney(
      formVal.confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm
    ),
    confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm: replaceMoney(
      formVal.confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm
    ),
    confirmedInsuranceFeeRateForAccidentInsurance: replaceRate(formVal.confirmedInsuranceFeeRateForAccidentInsurance)
  };
  // 変更された値を反映させる
  param = Object.assign(param, changedValue);

  // 前期: 確定保険額
  const confirmedAccidentInsuranceFirstTerm = new Decimal(param.confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm)
    .mul(new Decimal(param.confirmedInsuranceFeeRateForAccidentInsurance))
    .toNumber();
  // 後期: 確定保険額
  const confirmedAccidentInsuranceLatterTerm = new Decimal(
    param.confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm
  )
    .mul(new Decimal(param.confirmedInsuranceFeeRateForAccidentInsurance))
    .toNumber();
  // 合計: 保険料算定基礎額
  const sumConfirmedInsuranceFeeBaseForAccidentInsurance =
    toInteger(param.confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm) +
    toInteger(param.confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm);
  // 合計: 確定保険額
  const sumConfirmedAccidentInsurance = new Decimal(confirmedAccidentInsuranceFirstTerm)
    .add(new Decimal(confirmedAccidentInsuranceLatterTerm))
    .toNumber();
  // 合計: 確定保険額(端数切り上げ or 切り捨て)
  const tmpParamForCalc = Object.assign({}, formVal, param, { sumConfirmedAccidentInsurance });
  const sumConfirmedAccidentInsuranceRounded = roundSumConfirmedAccidentInsurance(tmpParamForCalc);

  updateCalcResult(
    'laborInsuranceDeclarationForm.confirmedAccidentInsuranceFirstTerm',
    confirmedAccidentInsuranceFirstTerm
  );
  updateCalcResult(
    'laborInsuranceDeclarationForm.confirmedAccidentInsuranceLatterTerm',
    confirmedAccidentInsuranceLatterTerm
  );
  updateCalcResult(
    'laborInsuranceDeclarationForm.sumConfirmedInsuranceFeeBaseForAccidentInsurance',
    sumConfirmedInsuranceFeeBaseForAccidentInsurance
  );
  updateCalcResult('laborInsuranceDeclarationForm.sumConfirmedAccidentInsurance', sumConfirmedAccidentInsurance);
  updateCalcResult(
    'laborInsuranceDeclarationForm.sumConfirmedAccidentInsuranceRounded',
    sumConfirmedAccidentInsuranceRounded
  );
};

const LaborInsuranceAccidentDeclarationForm2022 = ({ formVal, actionMode, updateCalcResult, blurMoney, blurRate }) => (
  <FormSection name={`laborInsuranceDeclarationForm`}>
    <div style={{ position: 'relative' }}>
      <div className="l-overflow-scroll">
        {actionMode === 'EDIT' && (
          <CheckboxField
            name="isAutoCalcConfirmedAccidentInsurance"
            label="保存時に確定保険料(労災保険分)算定内訳を自動計算する"
          />
        )}
        <table className={styles.laborInsuranceDeclarationFormTable}>
          <colgroup>
            <col width={220} />
            <col width={175} />
            <col width={230} />
            <col width={230} />
            <col width={230} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan="1" />
              <th colSpan="4">確定保険料算定内訳</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="2" colSpan="1">
                労災保険分
              </td>
              <td colSpan="6">算定期間 令和4年4月1日 から 令和5年3月31日 まで</td>
            </tr>
            <tr>
              <td colSpan="1">①保険料算定基礎額</td>
              <td colSpan="1">②保険料率</td>
              <td colSpan="1">③確定保険料額（その１）</td>
              <td colSpan="1">④確定保険料額（その２）</td>
            </tr>
            <tr>
              <td rowSpan="1" colSpan="1">
                前期分<br />（令和4年4月1日 〜<br />令和4年9月30日）
              </td>
              <td rowSpan="1" colSpan="1" className={styles.tdAmount}>
                <Field
                  name="confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm"
                  showValue={isNil(formVal) ? '' : formVal.confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm}
                  showMode={isEdit(actionMode, formVal)}
                  inputWidth={230}
                  validate={isMoneyNumber}
                  component={EditableTextField}
                  editProps={{ maxLength: 9 }}
                  onChange={e => {
                    calculation(updateCalcResult, formVal, {
                      confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm: replaceMoney(e.target.value)
                    });
                  }}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    blurMoney(
                      'laborInsuranceDeclarationForm.confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm',
                      previousValue
                    );
                    calculation(updateCalcResult, formVal, {
                      confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm: replaceMoney(newValue)
                    });
                  }}
                />
                <span className={styles.currency}>千円</span>
              </td>
              <td rowSpan="2" colSpan="1" className={styles.tdRate}>
                <div className={styles.employmentRate}>
                  1000分の
                  <Field
                    name="confirmedInsuranceFeeRateForAccidentInsurance"
                    showValue={isNil(formVal) ? '' : formVal.confirmedInsuranceFeeRateForAccidentInsurance}
                    showMode={isEdit(actionMode, formVal)}
                    inputWidth={230}
                    component={EditableTextField}
                    editProps={{ maxLength: 7 }}
                    validate={[isInsuranceRateFormat]}
                    onChange={e => {
                      calculation(updateCalcResult, formVal, {
                        confirmedInsuranceFeeRateForAccidentInsurance: replaceRate(e.target.value)
                      });
                    }}
                    onBlur={(e, newValue, previousValue) => {
                      e.preventDefault();
                      blurRate(
                        'laborInsuranceDeclarationForm.confirmedInsuranceFeeRateForAccidentInsurance',
                        previousValue
                      );
                      calculation(updateCalcResult, formVal, {
                        confirmedInsuranceFeeRateForAccidentInsurance: replaceRate(newValue)
                      });
                    }}
                  />
                </div>
              </td>
              <td rowSpan="1" colSpan="1">
                {isNil(formVal) ? '' : numberWithComma(formVal.confirmedAccidentInsuranceFirstTerm)}
                <span className={styles.currency}>円</span>
              </td>
              <td rowSpan="2" colSpan="1" />
            </tr>
            <tr>
              <td rowSpan="1" colSpan="1">
                後期分<br />（令和4年10月1日 〜<br />令和5年3月31日）
              </td>
              <td rowSpan="1" colSpan="1" className={styles.tdAmount}>
                <Field
                  name="confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm"
                  showValue={isNil(formVal) ? '' : formVal.confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm}
                  showMode={isEdit(actionMode, formVal)}
                  inputWidth={230}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                  editProps={{ maxLength: 9 }}
                  onChange={e => {
                    calculation(updateCalcResult, formVal, {
                      confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm: replaceMoney(e.target.value)
                    });
                  }}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    blurMoney(
                      'laborInsuranceDeclarationForm.confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm',
                      previousValue
                    );
                    calculation(updateCalcResult, formVal, {
                      confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm: replaceMoney(newValue)
                    });
                  }}
                />
                <span className={styles.currency}>千円</span>
              </td>
              <td rowSpan="1" colSpan="1">
                {isNil(formVal) ? '' : numberWithComma(formVal.confirmedAccidentInsuranceLatterTerm)}
                <span className={styles.currency}>円</span>
              </td>
            </tr>
            <tr>
              <td rowSpan="1" colSpan="1">
                合計
              </td>
              <td rowSpan="1" colSpan="1">
                {isNil(formVal) ? '' : numberWithComma(formVal.sumConfirmedInsuranceFeeBaseForAccidentInsurance)}
                <span className={styles.currency}>千円</span>
              </td>
              <td rowSpan="1" colSpan="1" />
              <td rowSpan="1" colSpan="1">
                {isNil(formVal) ? '' : numberWithComma(formVal.sumConfirmedAccidentInsurance)}
                <span className={styles.currency}>円</span>
              </td>
              <td rowSpan="1" colSpan="1">
                {isNil(formVal) ? '' : numberWithComma(formVal.sumConfirmedAccidentInsuranceRounded)}
                <span className={styles.currency}>円</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </FormSection>
);

export default LaborInsuranceAccidentDeclarationForm2022;
