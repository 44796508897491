import axios from 'axios';
import { createAction } from 'redux-actions';

import * as Urls from 'src/constants/EndpointUrls';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import { redirectTo } from 'src/utils/Http';

// Actions
export const AUTH = 'CURRENT_USER/AUTH';
export const AUTH_SUCCESS = 'CURRENT_USER/AUTH_SUCCESS';
export const AUTH_FAILURE = 'CURRENT_USER/AUTH_FAILURE';

// Action Creators
export const auth = createAction(AUTH);
export const authSuccess = createAction(AUTH_SUCCESS);
export const authFailure = createAction(AUTH_FAILURE);

// Selectors
export const getAuthorizedState = state => state.currentUser.isAuthorized;
export const getCurrentUserInfo = state => state.currentUser;
export const getLoginMessages = state => state.currentUser.messages;

export const editProfile = data => async dispatch => {
  try {
    await axios.put(Urls.USER_PROFILE_UPDATE, data);
    dispatch(setGlobalSuccesses('プロフィールを変更しました。'));
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const editEmail = data => async dispatch => {
  try {
    await axios.put(Urls.USER_CHANGE_EMAIL, data);
    dispatch(setGlobalSuccesses('確認メールを送信しました。'));
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const editPassword = data => async dispatch => {
  try {
    await axios.put(Urls.USER_CHANGE_PASSWORD, data);
    dispatch(setGlobalSuccesses('パスワードを変更しました。'));
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

// Async Action Creators
export const authRequest = data => async dispatch => {
  try {
    dispatch(auth());
    const response = await axios.post(Urls.USER_LOGIN, { user: data });
    redirectTo(response.headers.location);
  } catch (e) {
    let errorMsg = e.message;

    if (e.response.status === 401) {
      errorMsg = e.response.data;
    }

    dispatch(setGlobalErrors(errorMsg));
  }
};

export const isRoleTypeAdmin = roleType => roleType === 'admin';
export const isRoleTypeOperator = roleType => roleType === 'operator';
export const isRoleTypeEmployee = roleType => roleType === 'employee';

export const INITIAL_STATE = {
  isAuthorized: false,
  messages: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH:
      return { isAuthorized: false };
    case AUTH_SUCCESS:
      return { isAuthorized: true };
    case AUTH_FAILURE:
      return { isAuthorized: false, messages: action.payload };
    default:
      return state;
  }
};
