import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { deleteCheckedEmployee } from 'src/reducers/employees/employees';
import BatchDeleteEmployeeModal from '../components/BatchDeleteEmployeeModal';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => {
    dispatch(deleteCheckedEmployee(ownProps));
  }
});

export default connect(null, mapDispatchToProps)(
  reduxForm({
    form: 'BATCH_DELETE_EMPLOYEE_FORM'
  })(BatchDeleteEmployeeModal)
);
