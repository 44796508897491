import React from 'react';

import { CLIENT_FORM } from 'src/constants/FormNames';
import { PlusSquare } from 'jbc-front/components/icons';
import BankItemSectionContainer from '../../containers/edit/BankItemSectionContainer';

const MAX_CLIENT_BANK = 100;

const BanksSection = props => {
  const { fields } = props;
  return (
    <div>
      {fields.map((bank, index) => (
        <BankItemSectionContainer
          key={bank}
          keyIndex={index + 1}
          index={index}
          formKey={bank}
          formName={CLIENT_FORM}
          removable={!fields.get(index).hasEmployeePaymentBanks}
          remove={() => fields.remove(index)}
        />
      ))}

      {fields.length < MAX_CLIENT_BANK && (
        <div className="u-ta-r">
          <div role="button" tabIndex="0" onClick={() => fields.push({})} className="u-txt-addinfo">
            <PlusSquare size={20} className="u-txt-addinfo-icon" />
            新しい振込元を追加
          </div>
        </div>
      )}
    </div>
  );
};
export default BanksSection;
