import { combineReducers } from 'redux';
import _ from 'lodash';
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

import * as Urls from 'src/constants/EndpointUrls';
import { redirectTo, concatParamsToUrl } from 'src/utils/Http';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import { getList, setList } from 'src/reducers/common/listReducer';
import Cookies from 'js-cookie'; // eslint-disable-line
import { listReducer, itemReducer } from '../common';
import createNamedWrapperReducer from '../createNamedWrapperReducer';

export const REDUCER_NAME = 'clientCsvFormats';

const deductionsPayPath = Urls.SHOW_ALLOWANCE_DEDUCTIONS_PAY;
const deductionsBonusPath = Urls.SHOW_ALLOWANCE_DEDUCTIONS_BONUS;
const deductionsEmployeePath = Urls.EMPLOYEES_LIST;

export const createCsvFormat = data => async dispatch => {
  try {
    if (isEmpty(data.clientCsvFormatFields)) {
      dispatch(setGlobalErrors('項目を1個以上選択してください。'));
    } else {
      await axios.post(Urls.CLIENT_CSV_FORMAT_CREATE, data);
      if (data.noFlushMessage) {
        Cookies.set('formatSaved', 1, { expires: 10 });
      } else {
        dispatch(setGlobalSuccesses('フォーマットを作成しました。'));
      }

      let redirectUrl;
      if (
        data.goBackPath === deductionsPayPath ||
        data.goBackPath === deductionsBonusPath ||
        data.goBackPath === deductionsEmployeePath
      ) {
        redirectUrl = data.goBackPath;
      } else {
        redirectUrl = Urls.CLIENT_CSV_FORMATS_LIST;
      }
      redirectTo(redirectUrl);
    }
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const editCsvFormat = data => async dispatch => {
  try {
    if (isEmpty(data.clientCsvFormatFields)) {
      dispatch(setGlobalErrors('項目を1個以上選択してください。'));
    } else {
      const editUrl = concatParamsToUrl(Urls.CLIENT_CSV_FORMAT_UPDATE, { id: data.clientCsvFormat.id });
      await axios.put(editUrl, data);
      if (data.noFlushMessage) {
        Cookies.set('formatSaved', 1, { expires: 10 });
      } else {
        dispatch(setGlobalSuccesses('フォーマットを編集しました。'));
      }

      let redirectUrl;
      if (
        data.goBackPath === deductionsPayPath ||
        data.goBackPath === deductionsBonusPath ||
        data.goBackPath === deductionsEmployeePath
      ) {
        redirectUrl = data.goBackPath;
      } else {
        redirectUrl = Urls.CLIENT_CSV_FORMATS_LIST;
      }
      redirectTo(redirectUrl);
    }
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const copyCsvFormat = (csvFormatId, backPath = null) => async dispatch => {
  try {
    const copyUrl = concatParamsToUrl(Urls.CLIENT_CSV_FORMAT_COPY, { id: csvFormatId });
    const resp = await axios.put(copyUrl);
    if (resp.data.payload.clientCsvFormat.id) {
      let redirectPath = Urls.CLIENT_CSV_FORMAT_EDIT;
      if (backPath) {
        redirectPath = backPath;
      }
      redirectTo(concatParamsToUrl(redirectPath, { id: resp.data.payload.clientCsvFormat.id }));
    } else {
      dispatch(setGlobalErrors('フォーマットがコピーできません。'));
    }
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const deleteCsvFormat = (csvFormatId, noFlushMessage = false) => async (dispatch, getState) => {
  try {
    await axios.delete(concatParamsToUrl(Urls.CLIENT_CSV_FORMAT_UPDATE, { id: csvFormatId }));
    const csvFormats = getList(REDUCER_NAME, getState());
    const newList = _.filter(csvFormats, item => item.id !== csvFormatId);
    dispatch(setList(REDUCER_NAME, { data: newList }));
    if (!noFlushMessage) {
      dispatch(setGlobalSuccesses('フォーマットを削除しました。'));
    }
  } catch (e) {
    console.log('e', e);
  }
};

export const addUuidToItems = csvAvaiableItems => {
  const result = {};
  let offset = 1; // Avoid 0 here, because 0 == false in JS :(
  _.forEach(csvAvaiableItems, (groupItems, groupName) => {
    result[groupName] = {};
    _.forEach(groupItems, (items, label) => {
      result[groupName][label] = items.map((item, itemIndex) => ({ ...item, uuid: itemIndex + offset }));
      offset += items.length;
    });
  });

  return result;
};

const findUuidByAttrs = (item, hashItems) => {
  let uuid;

  _.forEach(hashItems, groupItems => {
    _.forEach(groupItems, items => {
      const found = items.find(
        i =>
          i.applyFor === item.applyFor &&
          i.group === item.group &&
          i.id === item.id &&
          i.kind === item.kind &&
          i.label === item.label
      );
      if (found) {
        uuid = found.uuid;
        return false;
      }
      return true;
    });
  });

  return uuid;
};

export const mapUuidToItems = (itemsWithUuid, anonymousItems) => {
  if (isEmpty(itemsWithUuid)) return [];

  const itemsWithoutUuid = anonymousItems
    .filter(item => !findUuidByAttrs(item, itemsWithUuid))
    .toSorted((a, b) => a.name.localeCompare(b.name));

  return anonymousItems.map(item => {
    const uuid = findUuidByAttrs(item, itemsWithUuid);
    if (uuid) {
      return { ...item, uuid };
    }
    return { ...item, uuid: -itemsWithoutUuid.indexOf(item) - 1 };
  });
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
