import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { deleteOfficeStationSetting } from 'src/reducers/settings/external_coordinations';

const OfficeStationDeleteModal = ({ isOpen, hideModal, onDelete }) => {
  const [isSubmitting, setSubmitting] = useState(false);

  return (
    <CommonModal isOpen={isOpen} hideModal={hideModal} ariaHideApp={false} closeOnClick>
      <CommonModal.Header hideModal={hideModal}>オフィスステーション連携情報</CommonModal.Header>
      <CommonModal.Body>連携を解除しますか？</CommonModal.Body>
      <CommonModal.Footer>
        <div>
          <Button onClick={hideModal} className="u-mr20">
            いいえ
          </Button>
          <Button
            primary
            onClick={() => {
              setSubmitting(true);
              onDelete();
            }}
            disabled={isSubmitting}
          >
            はい
          </Button>
        </div>
      </CommonModal.Footer>
    </CommonModal>
  );
};

const mapDispatchToProps = dispatch => ({
  onDelete: () => {
    dispatch(deleteOfficeStationSetting());
  }
});

export default connect(null, mapDispatchToProps)(OfficeStationDeleteModal);
