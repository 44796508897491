import React from 'react';
import { reduxForm } from 'redux-form';
import { EMPLOYEE_BONUS_ROLLBACK_FORM } from 'src/constants/FormNames';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { ErrorsSection } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import styles from './RollbackEmployeeBonusModal.scss';

const PrepareRollbackEmployeeBonusModal = ({ submitting, submit, isOpen, errors, hideModal }) => (
  <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
    <CommonModal.Header hideModal={hideModal}>操作確認</CommonModal.Header>
    <CommonModal.Body>
      <form className={styles.wrap}>
        <ErrorsSection errors={errors} />
        手入力前に戻しますか？
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="手入力前に戻す" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </CommonModal>
);

const RollbackEmployeeBonusModal = reduxForm({
  form: EMPLOYEE_BONUS_ROLLBACK_FORM
})(PrepareRollbackEmployeeBonusModal);

export default RollbackEmployeeBonusModal;
