import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { updateElectronicDeliveryAgreementSetting } from 'src/reducers/clients/electronicDeliveryAgreementSetting';
import { CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM } from 'src/constants/FormNames';
import ElectronicDeliveryAgreementSetting from '../components/ElectronicDeliveryAgreementSetting';

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    isElectronicDeliveryAgreementSetting: ownProps.values.isElectronicDeliveryAgreementSetting
  },
  formValues: getFormValues(CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM)(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => {
    dispatch(updateElectronicDeliveryAgreementSetting(ownProps.values.lockVersion));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ElectronicDeliveryAgreementSetting);
