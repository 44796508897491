import React from 'react';

// Form
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';

const DownloadCsvInsideForm = ({ handleSubmit, submitting, submit, cancelBtnClick }) => {
  const header = '賃金台帳ダウンロード';

  return (
    <React.Fragment>
      <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          <DownloadCsvEncodingRadioButton
            name={'encoding'}
            label={'ファイル形式を選択'}
            options={ENCODING_OPTIONS}
            required={false}
          />
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={cancelBtnClick} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="ダウンロード" {...{ submitting, submit }} />
      </CommonModal.Footer>
    </React.Fragment>
  );
};

export default DownloadCsvInsideForm;
