import { connect } from 'react-redux';
import { confirmOne } from '../../../reducers/employees/employeePays';
import ConfirmOneModal from '../components/ConfirmOneModal';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => {
    dispatch(confirmOne(ownProps.empId));
    if (typeof ownProps.hideModal === 'function') {
      ownProps.hideModal();
    }
    if (typeof ownProps.submitCallback === 'function') {
      ownProps.submitCallback();
    }
  }
});

export default connect(null, mapDispatchToProps)(ConfirmOneModal);
