import React, { useState } from 'react';
import { numberWithComma } from 'src/utils/Utils';
import { Edit } from 'jbc-front/components/icons';
import style from './ReportHealthInsuranceBonusTableRow.scss';
import EditBonusModalContainer from '../containers/Modal/EditBonusModalContainer';

const ReportHealthInsuranceBonusTableRow = ({ bonus }) => {
  const [isEditBonusModalOpen, setIsEditBonusModalOpen] = useState(false);

  const hideModal = () => {
    setIsEditBonusModalOpen(false);
  };

  return (
    <React.Fragment>
      <tr className={style.tableRow}>
        <td>{bonus.staffCode}</td>
        <td>{bonus.healthInsuranceInsuredPersonnelNumber}</td>
        <td>{bonus.name}</td>
        <td>{bonus.birthdayHuman}</td>
        <td>
          <p className={style.twoStepTableRow}>{numberWithComma(bonus.currencyBonus)} 円</p>
          <p className={style.twoStepTableRow}>{numberWithComma(bonus.genbutsuBonus)} 円</p>
        </td>
        <td>{numberWithComma(bonus.totalBonus)} 千円</td>
        <td>
          <p className={bonus.over70 ? '' : style.gray}>1. 70歳以上被用者</p>
          <p className={bonus.isWorkInTwoOrMore ? '' : style.gray}>2. 二以上勤務</p>
          <p className={bonus.isAddUp ? '' : style.gray}>3. 同一月内の賞与合算</p>
          <p className={bonus.isAddUp ? '' : style.gray}>（初回 {bonus.firstPaymentDay} 日）</p>
        </td>
        <td>
          <span className={style.icon}>
            <Edit onClick={() => setIsEditBonusModalOpen(true)} size={20} />
          </span>
        </td>
      </tr>
      {isEditBonusModalOpen && (
        <EditBonusModalContainer isOpen={isEditBonusModalOpen} bonus={bonus} hideModal={hideModal} />
      )}
    </React.Fragment>
  );
};
export default ReportHealthInsuranceBonusTableRow;
