import _ from 'lodash';
import humps from 'humps';
import fileDownload from 'react-file-download';

/**
 * Object を HTTP のパラメータにシリアライズする
 *
 * @param obj
 * @returns {string}
 */
export const serializeHttpGetParams = obj => {
  const encoded = Object.keys(obj).map(k => {
    const key = humps.decamelize(k);
    return `${encodeURIComponent(key)}=${encodeURIComponent(obj[k])}`;
  });
  return encoded.join('&');
};

/**
 * undefined と null を Object から除外する
 *
 * @param obj
 * @returns Object
 */
export const excludeValuesIfNotDefined = obj => _.pickBy(obj, _.identity);

/**
 * Map params into endpoint url
 *
 * @param endPointUrl has format: /some/path/:param1/action/:param2
 * @param params is a object with format: { param1: '1', param2: 'abc' }
 * @returns {string}
 */
export const concatParamsToUrl = (endPointUrl, params) => {
  let endPointWithParams = endPointUrl;

  Object.keys(params).forEach(paramName => {
    endPointWithParams = endPointWithParams.replace(`:${paramName}`, params[paramName]);
  });
  return endPointWithParams;
};

export const saveFile = (response, defaultFileName = null) => {
  const headers = response.headers;
  const fileContent = response.data;
  const contentType = headers['content-type'] || headers['Content-Type'];
  const fileName =
    defaultFileName ||
    (headers['content-disposition'] || headers['Content-Disposition']).split('filename=')[1].replace(/"/g, '');
  fileDownload(fileContent, fileName, contentType);
};

export const redirectTo = url => {
  window.location.href = url;
};

export const openNewTab = url => {
  window.open(url, 'rel="noopener noreferrer"');
};

export const detailUrlWithQueries = (id, queries, urlFormat, ids) => {
  if (!id) {
    return '';
  }
  const showUrl = concatParamsToUrl(urlFormat, { id });
  const params = serializeHttpGetParams(excludeValuesIfNotDefined(queries));
  if (ids) {
    return `${showUrl}?${params}&ids=${encodeURIComponent(ids)}`;
  }
  return `${showUrl}?${params}`;
};

export const getIdsFromArr = objArr => (objArr ? objArr.map(item => item.id) : []);
export const getCustomErrors = e => _.get(e, ['response', 'data', 'errors', 'messages']);
