import axios from 'axios';
import { combineReducers } from 'redux';
import { change as changeForm, getFormValues } from 'redux-form';

import * as Urls from 'src/constants/EndpointUrls';

import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { newItem, newItemFailure, editItem, editItemFailure } from 'src/reducers/common/itemReducer';
import { setList, fetchListSuccess, fetchListFailure } from 'src/reducers/common/listReducer';
import { redirectTo, concatParamsToUrl } from 'src/utils/Http';
import { scrollToTop } from 'src/utils/Utils';
import { setGlobalErrors } from 'src/reducers/global';
import { OFFICE_FORM } from 'src/constants/FormNames';

export const REDUCER_NAME = 'offices';

export const fetchOffices = () => async dispatch => {
  try {
    // ブラウザバック時にjsonが表示されること回避のためtimestamp付与
    const response = await axios.get(Urls.LIST_OFFICES_URL, { params: { timestamp: Date.now() } });
    const payload = { data: response.data.payload.offices };
    await dispatch(setList(REDUCER_NAME, payload));
    await dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

// Async Action Creators
export const newOffice = data => async dispatch => {
  try {
    dispatch(newItem(REDUCER_NAME));
    const response = await axios.post(Urls.CREATE_OFFICE_URL, data);
    redirectTo(concatParamsToUrl(Urls.OFFICE_SHOW, { id: response.data.payload.office.id }));
  } catch (exception) {
    dispatch(newItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editOffice = data => async dispatch => {
  try {
    dispatch(editItem(REDUCER_NAME));
    const updateUrl = concatParamsToUrl(Urls.UPDATE_OFFICE_URL, { id: data.office.id });
    await axios.put(updateUrl, data);
    redirectTo(concatParamsToUrl(Urls.OFFICE_SHOW, { id: data.office.id }));
  } catch (exception) {
    scrollToTop();
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const deleteOffice = id => async dispatch => {
  try {
    await axios.delete(concatParamsToUrl(Urls.DELETE_OFFICE_URL, { id }));
    redirectTo(Urls.LIST_OFFICES_URL);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});

export const copy = () => async (dispatch, getState) => {
  const mainContactInfo = getFormValues(OFFICE_FORM)(getState()).mainOfficeContactInfo;
  dispatch(changeForm(OFFICE_FORM, 'office.name', mainContactInfo.name));
  dispatch(changeForm(OFFICE_FORM, 'office.nameKana', mainContactInfo.nameKana));
  dispatch(changeForm(OFFICE_FORM, 'office.postalCodePart0', mainContactInfo.postalCodePart0));
  dispatch(changeForm(OFFICE_FORM, 'office.postalCodePart1', mainContactInfo.postalCodePart1));
  dispatch(changeForm(OFFICE_FORM, 'office.prefectureId', mainContactInfo.prefectureId));
  dispatch(changeForm(OFFICE_FORM, 'office.city', mainContactInfo.city));
  dispatch(changeForm(OFFICE_FORM, 'office.street', mainContactInfo.street));
  dispatch(changeForm(OFFICE_FORM, 'office.building', mainContactInfo.building));
  dispatch(changeForm(OFFICE_FORM, 'office.addressKana', mainContactInfo.addressKana));
  dispatch(changeForm(OFFICE_FORM, 'office.telPart0', mainContactInfo.telPart0));
  dispatch(changeForm(OFFICE_FORM, 'office.telPart1', mainContactInfo.telPart1));
  dispatch(changeForm(OFFICE_FORM, 'office.telPart2', mainContactInfo.telPart2));
  dispatch(changeForm(OFFICE_FORM, 'office.position', mainContactInfo.position));
  dispatch(changeForm(OFFICE_FORM, 'office.representativeLastName', mainContactInfo.representativeLastName));
  dispatch(changeForm(OFFICE_FORM, 'office.representativeFirstName', mainContactInfo.representativeFirstName));
};

export const isMainOffice = officeType => officeType === 'main';
