import React from 'react';
import { connect } from 'react-redux';

// Utils
import { EMPLOYEE_BONUS_EDIT } from 'src/constants/EndpointUrls';
import { redirectTo, detailUrlWithQueries, getIdsFromArr } from 'src/utils/Http';
import { getOptionLabel, isInTargetDateRange } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';

// Form
import { Hamburger, Alert } from 'jbc-front/components/icons';
import MemoList from 'src/features/Memos';
import { CheckboxFieldWithoutForm } from 'src/components';
import SortableTh from 'src/components/SortableTh';
// Reducer
import { getItem } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME as CLIENT_BONUS_REDUCER_NAME } from 'src/reducers/clients/clientBonus';
import { FORM_NAME_BONUSES_SEARCH } from 'src/reducers/searchForm';
import {
  getEmployeeBonusList,
  getEmployeeBonusQueries,
  selectAllPages,
  getAllBonusCheckedState,
  changeBonusItemCheckedState,
  setAllBonusCheckedTo,
  setIsNeedSavingConfirmation
} from 'src/reducers/employees/employeeBonus';
import { getSelectOptions } from 'src/reducers/selectOptions';
import TargetEmployeeBonusTableMethod from './TargetEmployeeBonusTableMethod';
import styles from './TargetEmployeeBonusTable.scss';

const EmployeeBonusRow = ({
  onClick,
  employeeBonusItem,
  clientEmploymentTypes,
  onClickCheckbox,
  clientBonus,
  checked
}) => {
  const { applyForTimeFrom, applyForTimeTo } = clientBonus || {};
  const joinedOnStyle = isInTargetDateRange(applyForTimeFrom, applyForTimeTo, employeeBonusItem.joinedOn) ? (
    <span className={styles.icon}>
      <Alert size={16} />
    </span>
  ) : (
    ''
  );
  const resignedOnStyle = isInTargetDateRange(applyForTimeFrom, applyForTimeTo, employeeBonusItem.resignedOn) ? (
    <span className={styles.icon}>
      <Alert size={16} />
    </span>
  ) : (
    ''
  );
  const isChecked = checked ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
  const className = checked ? styles.abled : styles.disabled;

  return (
    <tr onClick={onClick} className={`${className} table-hover`}>
      <td
        onClick={e => {
          e.stopPropagation();
          onClickCheckbox(employeeBonusItem.id);
        }}
        role="presentation"
        className={isChecked}
      >
        <CheckboxFieldWithoutForm
          checked={checked}
          onChange={() => {
            onClickCheckbox(employeeBonusItem.id);
          }}
        />
      </td>
      <td>
        <MemoList employeeId={employeeBonusItem.employeeId} />
      </td>
      <td>{employeeBonusItem.staffCode}</td>
      <td>{employeeBonusItem.employeeDisplayName}</td>
      <td>{getOptionLabel(employeeBonusItem.employmentTypeId)(clientEmploymentTypes)}</td>
      <td>
        <span className={styles.date}>
          {formatDate(employeeBonusItem.joinedOn)}
          {joinedOnStyle}
        </span>
      </td>
      <td>
        <span className={styles.date}>
          {formatDate(employeeBonusItem.resignedOn)}
          {resignedOnStyle}
        </span>
      </td>
    </tr>
  );
};

class TargetEmployeeBonusTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false
    };
  }

  render() {
    const { employeeBonusList, queries, clientBonus, isAllBonusChecked, clientEmploymentTypes, dispatch } = this.props;
    return (
      <div className="l-overflow-scroll">
        <table className="m-table-list">
          <thead>
            <tr>
              <th width={40}>
                <div className={styles.wrap}>
                  <div
                    role="presentation"
                    onClick={() => {
                      dispatch(setIsNeedSavingConfirmation(true));
                      dispatch(setAllBonusCheckedTo(!isAllBonusChecked));
                    }}
                    className={isAllBonusChecked ? 'theadCheckboxOn' : 'theadCheckboxOff'}
                  >
                    <CheckboxFieldWithoutForm
                      checked={isAllBonusChecked}
                      value={isAllBonusChecked}
                      onChange={() => {
                        dispatch(setIsNeedSavingConfirmation(true));
                        dispatch(setAllBonusCheckedTo(!isAllBonusChecked));
                      }}
                    />
                  </div>
                  <div className={styles.button}>
                    <Hamburger
                      size={12}
                      className={`${styles.menu} ignore-react-onclickoutside`}
                      onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}
                    />
                    {this.state.isMenuOpen && (
                      <TargetEmployeeBonusTableMethod hide={() => this.setState({ isMenuOpen: false })} />
                    )}
                  </div>
                </div>
              </th>
              <th width={68}>メモ</th>
              <SortableTh
                field="staffCode"
                globalClassName="m-table-list-staffcode"
                formName={FORM_NAME_BONUSES_SEARCH}
              >
                スタッフコード
              </SortableTh>
              <SortableTh field="name" formName={FORM_NAME_BONUSES_SEARCH}>
                氏名
              </SortableTh>
              <SortableTh field="employmentTypeId" formName={FORM_NAME_BONUSES_SEARCH}>
                雇用形態
              </SortableTh>
              <SortableTh field="joinedOn" formName={FORM_NAME_BONUSES_SEARCH}>
                入社日
              </SortableTh>
              <SortableTh field="resignedOn" formName={FORM_NAME_BONUSES_SEARCH}>
                退職日
              </SortableTh>
            </tr>
          </thead>
          <tbody>
            {employeeBonusList &&
              employeeBonusList.length > 0 &&
              employeeBonusList.map(item => (
                <EmployeeBonusRow
                  onClick={() =>
                    redirectTo(
                      detailUrlWithQueries(item.id, queries, EMPLOYEE_BONUS_EDIT, getIdsFromArr(employeeBonusList))
                    )
                  }
                  key={item.id}
                  onClickCheckbox={bonusItemId => {
                    dispatch(setIsNeedSavingConfirmation(true));
                    dispatch(changeBonusItemCheckedState(bonusItemId));
                  }}
                  employeeBonusItem={item}
                  clientBonus={clientBonus}
                  clientEmploymentTypes={clientEmploymentTypes}
                  checked={item.isChecked}
                />
              ))}

            {(!employeeBonusList || employeeBonusList.length === 0) && (
              <tr>
                <td colSpan={8}>データがありません。</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(state => ({
  clientBonus: getItem(CLIENT_BONUS_REDUCER_NAME, state),
  employeeBonusList: getEmployeeBonusList(state),
  queries: getEmployeeBonusQueries(state),
  isAllBonusChecked: getAllBonusCheckedState(state),
  clientEmploymentTypes: getSelectOptions(state, 'clientEmploymentTypes')
}))(TargetEmployeeBonusTable);
