import React from 'react';
import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import { getRadioOptions } from 'src/reducers/selectOptions';
import selector, { getOptionLabel, display } from 'src/utils/Utils';
import tableStyles from '../styles/GeneralTableInfo.scss';

const TaxClassificationSectionShow = props => {
  const {
    employeeTaxClassification,
    singleParentWidowClassifications,
    residentClassifications,
    incomeTaxCalcMethods,
    taxScheduleClassifications,
    widowClassifications,
    disabledPersonClassifications,
    graduateStudentClassifications,
    disasterClassifications,
    foreignerClassifications
  } = props;

  const isNotCover = employeeTaxClassification.residentClassification === 'not_cover';
  const isCalcIncomeTax = employeeTaxClassification.taxScheduleClassification !== 'no_calc_income_tax';

  return (
    <div>
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>非居住者区分：</th>
            <td>
              {display(getOptionLabel(employeeTaxClassification.residentClassification)(residentClassifications))}
            </td>
          </tr>
          {isNotCover ? (
            <tr>
              <th>税額表区分：</th>
              <td>
                {display(
                  getOptionLabel(employeeTaxClassification.taxScheduleClassification)(taxScheduleClassifications)
                )}
              </td>
            </tr>
          ) : (
            <tr>
              <th>所得税計算：</th>
              <td>{display(getOptionLabel(employeeTaxClassification.incomeTaxCalcMethod)(incomeTaxCalcMethods))}</td>
            </tr>
          )}
          {isCalcIncomeTax &&
            isNotCover && (
              <React.Fragment>
                <tr>
                  <th>障害者区分：</th>
                  <td>
                    {display(
                      getOptionLabel(employeeTaxClassification.disabledPersonClassification)(
                        disabledPersonClassifications
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <th>勤労学生区分：</th>
                  <td>
                    {display(
                      getOptionLabel(employeeTaxClassification.graduateStudentClassification)(
                        graduateStudentClassifications
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <th>ひとり親・寡婦区分：</th>
                  <td>
                    {display(
                      getOptionLabel(employeeTaxClassification.singleParentWidowClassification)(
                        singleParentWidowClassifications
                      )
                    )}
                  </td>
                </tr>
                <tr>
                  <th>寡婦（夫）区分（2020年以前）：</th>
                  <td>
                    {display(getOptionLabel(employeeTaxClassification.widowClassification)(widowClassifications))}
                  </td>
                </tr>
                <tr>
                  <th>災害者区分：</th>
                  <td>
                    {display(getOptionLabel(employeeTaxClassification.disasterClassification)(disasterClassifications))}
                  </td>
                </tr>
                <tr>
                  <th>外国人区分：</th>
                  <td>
                    {display(
                      getOptionLabel(employeeTaxClassification.foreignerClassification)(foreignerClassifications)
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )}
        </tbody>
      </table>
    </div>
  );
};

export default connect(state => ({
  employeeTaxClassification: selector(getItem(REDUCER_NAME, state), 'employeeTaxClassification') || {},
  singleParentWidowClassifications: getRadioOptions(state, 'singleParentWidowClassifications'),
  residentClassifications: getRadioOptions(state, 'residentClassifications'),
  incomeTaxCalcMethods: getRadioOptions(state, 'incomeTaxCalcMethods'),
  taxScheduleClassifications: getRadioOptions(state, 'taxScheduleClassifications'),
  widowClassifications: getRadioOptions(state, 'widowClassifications'),
  disabledPersonClassifications: getRadioOptions(state, 'disabledPersonClassifications'),
  graduateStudentClassifications: getRadioOptions(state, 'graduateStudentClassifications'),
  disasterClassifications: getRadioOptions(state, 'disasterClassifications'),
  foreignerClassifications: getRadioOptions(state, 'foreignerClassifications')
}))(TaxClassificationSectionShow);
