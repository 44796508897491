import React from 'react';
import SettingElectronicDeliveryForm from 'src/features/Users/containers/SettingElectronicDeliveryContainer';
import styles from './electronic_delivery_agreement.scss';

const electronicDeliveryAgreement = ({ employee, redirectPath }) => (
  <div className={styles.main}>
    <div className="l-title-wrap">
      <h1 className="m-title-main">アカウント情報</h1>
    </div>
    <div className={styles.description}>
      <div className={styles.center}>
        <p>給与明細・源泉徴収票をご覧いただく場合、従業員からの同意が法律的に必要となるため、</p>
      </div>
      <div className={styles.center}>
        <p>下記項目に同意していただく必要があります。</p>
      </div>
    </div>
    <SettingElectronicDeliveryForm employee={employee} redirectPath={redirectPath} isFlex />
  </div>
);

export default electronicDeliveryAgreement;
