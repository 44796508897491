import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { DASHBOARD_SHOW } from 'src/constants/EndpointUrls';
import { USER_ACCOUNT_PASSWORD_FORM } from 'src/constants/FormNames';
import { Section, TextField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import { editPassword } from 'src/reducers/users/currentUser';
import { redirectTo } from 'src/utils/Http';

const RawSettingPasswordForm = ({ handleSubmit, submit, submitting, pristine }) => (
  <form onSubmit={handleSubmit}>
    <Section>
      <TextField label="旧パスワード" name="oldPassword" type="password" required />
      <TextField label="新パスワード" name="password" type="password" required />
      <TextField label="新パスワード(確認用)" name="passwordConfirmation" type="password" required />
    </Section>
    <div className="u-ta-c u-mt30">
      <Button onClick={() => redirectTo(DASHBOARD_SHOW)} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn {...{ submit, submitting, pristine }} />
    </div>
  </form>
);

const SettingPasswordForm = reduxForm({
  form: USER_ACCOUNT_PASSWORD_FORM
})(RawSettingPasswordForm);

export default connect(null, dispatch => ({
  onSubmit: data => dispatch(editPassword(data))
}))(SettingPasswordForm);
