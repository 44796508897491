import React from 'react';
import { connect } from 'react-redux';
import { Field, blur, touch } from 'redux-form';
import _ from 'lodash';

import { REPORT_LABOR_INSURANCE_DECLARATION_FORM } from 'src/constants/FormNames';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { emptyIntegerFormat } from 'src/utils/Completion';
import { numberWithComma } from 'src/utils/Utils';
import EditableTextField from './EditableTextField';
import Hatching from './DeclarationFormInsuranceFeeHatching';
import Hatching2022 from './DeclarationFormInsuranceHatching2022';
import styles from './DeclarationFormConfirmedInsuranceFee.scss';

class DeclarationFormConfirmedInsuranceFee extends React.Component {
  constructor(props) {
    super(props);
    const { declarationForm } = props;
    this.state = { ...declarationForm };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  calculation(params) {
    // 労災保険額
    const is2022 = this.props.is2022;
    const confirmedInsuranceFeeAmountForAccidentInsurance = is2022
      ? params.sumConfirmedAccidentInsuranceRounded
      : _.toInteger(
          params.confirmedInsuranceFeeBaseForAccidentInsurance * params.confirmedInsuranceFeeRateForAccidentInsurance,
          10
        );

    // 高年齢労働者
    const confirmedInsuranceFeeAmountForAgedEmploymentInsurance = _.toInteger(
      params.confirmedInsuranceFeeBaseForAgedEmploymentInsurance *
        params.confirmedInsuranceFeeRateForAgedEmploymentInsurance,
      10
    );

    // 保険料算定対象者分算定基礎額
    const confirmedInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance =
      _.toInteger(params.confirmedInsuranceFeeBaseForAppliedEmploymentInsurance, 10) -
      _.toInteger(params.confirmedInsuranceFeeBaseForAgedEmploymentInsurance, 10);

    // 保険料算定対象者分保険料
    const confirmedInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance = _.toInteger(
      confirmedInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance *
        params.confirmedInsuranceFeeRateForCalculationTargetOfEmploymentInsurance,
      10
    );

    // 雇用保険分 保険料（2020/04 以降）
    const confirmedInsuranceFeeAmountAfter2020 = is2022
      ? params.sumConfirmedEmploymentInsuranceRounded
      : _.toInteger(
          params.confirmedInsuranceFeeBaseForAppliedEmploymentInsurance *
            params.confirmedInsuranceFeeRateForCalculationTargetOfEmploymentInsurance,
          10
        );

    // 一般拠出金
    const confirmedInsuranceFeeAmountForGeneralContributeion = _.toInteger(
      params.confirmedInsuranceFeeBaseForGeneralContribution * params.confirmedInsuranceFeeRateForGeneralContributeion,
      10
    );

    // 労働保険料
    const confirmedInsuranceFeeAmountForLaborInsurance =
      confirmedInsuranceFeeAmountForAccidentInsurance +
      (is2022
        ? confirmedInsuranceFeeAmountAfter2020
        : confirmedInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance);

    const calcValues = {
      confirmedInsuranceFeeAmountForAccidentInsurance,
      confirmedInsuranceFeeAmountForAgedEmploymentInsurance,
      confirmedInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance,
      confirmedInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance,
      confirmedInsuranceFeeAmountAfter2020,
      confirmedInsuranceFeeAmountForGeneralContributeion,
      confirmedInsuranceFeeAmountForLaborInsurance
    };

    this.setState({
      ...params,
      ...calcValues
    });

    return { ...params, ...calcValues };
  }

  // 労災保険の算定基礎額変更イベント
  onChangeConfirmedInsuranceFeeBaseForAccidentInsurance(value) {
    const params = {
      ...this.state,
      confirmedInsuranceFeeBaseForAccidentInsurance: value
    };
    this.props.allCalculations(params);
  }

  // 高年齢労働者算出基礎変更イベント
  onChangeConfirmedInsuranceFeeBaseForAppliedEmploymentInsurance(value) {
    const params = {
      ...this.state,
      confirmedInsuranceFeeBaseForAppliedEmploymentInsurance: value
    };
    this.props.allCalculations(params);
  }

  // 高年齢労働者算出基礎変更イベント
  onChangeConfirmedInsuranceFeeBaseForAgedEmploymentInsurance(value) {
    const params = {
      ...this.state,
      confirmedInsuranceFeeBaseForAgedEmploymentInsurance: value
    };
    this.props.allCalculations(params);
  }

  // 高年齢労働者算出基礎変更イベント
  onChangeConfirmedInsuranceFeeBaseForGeneralContribution(value) {
    const params = {
      ...this.state,
      confirmedInsuranceFeeBaseForGeneralContribution: value
    };
    this.props.allCalculations(params);
  }

  render() {
    const { dispatch, actionMode, declarationForm, isLessThan2020, is2022 } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <p className="u-mb10">確定 保険料算定内訳</p>
        {is2022 ? (
          <Hatching2022 isDisplay={actionMode === 'EDIT'} />
        ) : (
          <Hatching
            isDisplay={
              actionMode === 'SHOW' &&
              declarationForm.confirmedInsuranceFeeBaseForAccidentInsurance ===
                declarationForm.confirmedInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance
            }
            isLessThan2020={isLessThan2020}
          />
        )}
        <div className="l-overflow-scroll">
          <table className={styles.table}>
            <colgroup>
              <col width={60} />
              <col width={180} />
              <col width={240} />
              <col width={240} />
              <col width={240} />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="2">区分</th>
                <th>保険料・一般拠出金算定基礎額</th>
                <th>保険料・一般拠出金料率</th>
                <th>確定保険料・一般拠出金額</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan="2">労働保険料</th>
                <td />
                <td>{!is2022 && <span>1000分の{declarationForm.confirmedInsuranceFeeRateForLaborInsurance}</span>}</td>
                <td>
                  {numberWithComma(this.state.confirmedInsuranceFeeAmountForLaborInsurance)}{' '}
                  <span className={styles.currency}>円</span>
                </td>
              </tr>
              <tr>
                <th colSpan="2">労災保険分</th>
                <td>
                  <Field
                    name="confirmedInsuranceFeeBaseForAccidentInsurance"
                    showValue={declarationForm.confirmedInsuranceFeeBaseForAccidentInsurance}
                    showMode={is2022 ? 'SHOW' : actionMode}
                    inputWidth={70}
                    validate={[isMoneyNumber]}
                    component={EditableTextField}
                    onChange={e => {
                      this.onChangeConfirmedInsuranceFeeBaseForAccidentInsurance(e.target.value);
                    }}
                    onBlur={(e, newValue, previousValue) => {
                      e.preventDefault();
                      dispatch(
                        blur(
                          `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                          `confirmedInsuranceFeeBaseForAccidentInsurance`,
                          emptyIntegerFormat(previousValue)
                        )
                      );
                      dispatch(
                        touch(
                          `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                          `confirmedInsuranceFeeBaseForAccidentInsurance`
                        )
                      );
                      this.onChangeConfirmedInsuranceFeeBaseForAccidentInsurance(e.target.value);
                    }}
                  />
                  <span className={styles.currency}>千円</span>
                </td>
                <td>
                  {!is2022 && <span>1000分の{declarationForm.confirmedInsuranceFeeRateForAccidentInsurance}</span>}
                </td>
                <td>
                  {numberWithComma(this.state.confirmedInsuranceFeeAmountForAccidentInsurance)}{' '}
                  <span className={styles.currency}>円</span>
                </td>
              </tr>
              {isLessThan2020 ? (
                <React.Fragment>
                  <tr>
                    <th rowSpan="3" className={styles.vertical}>
                      雇用保険分
                    </th>
                    <th>雇用保険法適用者分</th>
                    <td>
                      <Field
                        name="confirmedInsuranceFeeBaseForAppliedEmploymentInsurance"
                        showValue={declarationForm.confirmedInsuranceFeeBaseForAppliedEmploymentInsurance}
                        showMode={actionMode}
                        inputWidth={70}
                        validate={[isMoneyNumber]}
                        component={EditableTextField}
                        onChange={e => {
                          this.onChangeConfirmedInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                        }}
                        onBlur={(e, newValue, previousValue) => {
                          e.preventDefault();
                          dispatch(
                            blur(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `confirmedInsuranceFeeBaseForAppliedEmploymentInsurance`,
                              emptyIntegerFormat(previousValue)
                            )
                          );
                          dispatch(
                            touch(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `confirmedInsuranceFeeBaseForAppliedEmploymentInsurance`
                            )
                          );
                          this.onChangeConfirmedInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                        }}
                      />
                      <span className={styles.currency}>千円</span>
                    </td>
                    <td colSpan="2">-</td>
                  </tr>
                  <tr>
                    <th>高年齢労働者分</th>
                    <td>
                      <Field
                        name="confirmedInsuranceFeeBaseForAgedEmploymentInsurance"
                        showValue={declarationForm.confirmedInsuranceFeeBaseForAgedEmploymentInsurance}
                        showMode={actionMode}
                        inputWidth={70}
                        validate={[isMoneyNumber]}
                        component={EditableTextField}
                        onChange={e => {
                          this.onChangeConfirmedInsuranceFeeBaseForAgedEmploymentInsurance(e.target.value);
                        }}
                        onBlur={(e, newValue, previousValue) => {
                          e.preventDefault();
                          dispatch(
                            blur(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `confirmedInsuranceFeeBaseForAgedEmploymentInsurance`,
                              emptyIntegerFormat(previousValue)
                            )
                          );
                          dispatch(
                            touch(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `confirmedInsuranceFeeBaseForAppliedEmploymentInsurance`
                            )
                          );
                          this.onChangeConfirmedInsuranceFeeBaseForAgedEmploymentInsurance(e.target.value);
                        }}
                      />
                      <span className={styles.currency}>千円</span>
                    </td>
                    <td>1000分の{declarationForm.confirmedInsuranceFeeRateForAgedEmploymentInsurance}</td>
                    <td>
                      {numberWithComma(this.state.confirmedInsuranceFeeAmountForAgedEmploymentInsurance)}{' '}
                      <span className={styles.currency}>円</span>
                    </td>
                  </tr>
                  <tr>
                    <th>保険料算定対象者分</th>
                    <td>
                      {numberWithComma(this.state.confirmedInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance)}{' '}
                      <span className={styles.currency}>千円</span>
                    </td>
                    <td>
                      1000分の{declarationForm.confirmedInsuranceFeeRateForCalculationTargetOfEmploymentInsurance}
                    </td>
                    <td>
                      {numberWithComma(this.state.confirmedInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance)}{' '}
                      <span className={styles.currency}>円</span>
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <tr>
                  <th colSpan="2" className={styles.vertical}>
                    雇用保険分
                  </th>
                  <td>
                    <Field
                      name="confirmedInsuranceFeeBaseForAppliedEmploymentInsurance"
                      showValue={declarationForm.confirmedInsuranceFeeBaseForAppliedEmploymentInsurance}
                      showMode={is2022 ? 'SHOW' : actionMode}
                      inputWidth={70}
                      validate={[isMoneyNumber]}
                      component={EditableTextField}
                      onChange={e => {
                        this.onChangeConfirmedInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                      }}
                      onBlur={(e, newValue, previousValue) => {
                        e.preventDefault();
                        dispatch(
                          blur(
                            `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                            `confirmedInsuranceFeeBaseForAppliedEmploymentInsurance`,
                            emptyIntegerFormat(previousValue)
                          )
                        );
                        dispatch(
                          touch(
                            `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                            `confirmedInsuranceFeeBaseForAppliedEmploymentInsurance`
                          )
                        );
                        this.onChangeConfirmedInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                      }}
                    />
                    <span className={styles.currency}>千円</span>
                  </td>
                  <td>
                    {!is2022 && (
                      <span>
                        1000分の{declarationForm.confirmedInsuranceFeeRateForCalculationTargetOfEmploymentInsurance}
                      </span>
                    )}
                  </td>
                  <td>
                    {numberWithComma(this.state.confirmedInsuranceFeeAmountAfter2020)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                </tr>
              )}
              <tr>
                <th colSpan="2">一般拠出金</th>
                <td>
                  <Field
                    name="confirmedInsuranceFeeBaseForGeneralContribution"
                    showValue={declarationForm.confirmedInsuranceFeeBaseForGeneralContribution}
                    showMode={actionMode}
                    inputWidth={70}
                    validate={[isMoneyNumber]}
                    component={EditableTextField}
                    onChange={e => {
                      this.onChangeConfirmedInsuranceFeeBaseForGeneralContribution(e.target.value);
                    }}
                    onBlur={(e, newValue, previousValue) => {
                      e.preventDefault();
                      dispatch(
                        blur(
                          `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                          `confirmedInsuranceFeeBaseForGeneralContribution`,
                          emptyIntegerFormat(previousValue)
                        )
                      );
                      dispatch(
                        touch(
                          `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                          `confirmedInsuranceFeeBaseForGeneralContribution`
                        )
                      );
                      this.onChangeConfirmedInsuranceFeeBaseForGeneralContribution(e.target.value);
                    }}
                  />
                  <span className={styles.currency}>千円</span>
                </td>
                <td>1000分の{declarationForm.confirmedInsuranceFeeRateForGeneralContributeion}</td>
                <td>
                  {numberWithComma(this.state.confirmedInsuranceFeeAmountForGeneralContributeion)}{' '}
                  <span className={styles.currency}>円</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connect()(DeclarationFormConfirmedInsuranceFee);
