import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import MinusCircle from 'jbc-front/components/icons/MinusCircle';

const CancelActionBtn = ({ onClick, label = 'キャンセル', ...rest }) => (
  <ActionButton as="button" icon={<MinusCircle />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default CancelActionBtn;
