import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  setDownloadPayCsvJobInvisible,
  fetchDownloadPayCsvJobs,
  getDownloadPayCsvJobs
} from 'src/reducers/employees/employeePays';
import { Close } from 'jbc-front/components/icons';
import { JobFailed, JobInprogress, JobSuccess } from 'src/components/JobStatus';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import styles from './DownloadPayCsvJobStatus.scss';

const DownloadJobResult = ({ tasks, status, hideDownloadJobResults }) => {
  let statusText = 'の給与データCSVの作成が完了しました。';
  let tasksStyle = styles.tasksSuccess;

  if (status === 'failed') {
    statusText = 'の給与データCSVの作成が失敗しました。';
    tasksStyle = styles.tasksFailed;
  } else if (status === 'inProgress' || status === 'waiting') {
    statusText = 'の給与データCSVを作成中です。';
    tasksStyle = styles.tasksInprogress;
  }

  return (
    <React.Fragment>
      {!isEmpty(tasks) &&
        tasks.length > 0 && (
          <div className={tasksStyle}>
            <div className={styles.inner}>
              {status === 'success' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideDownloadJobResults(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobSuccess key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
              {status === 'failed' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideDownloadJobResults(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobFailed key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
              {(status === 'inProgress' || status === 'waiting') && (
                <React.Fragment>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobInprogress key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      {isEmpty(tasks) && null}
    </React.Fragment>
  );
};

class DownloadPayCsvJobStatus extends React.Component {
  componentDidMount() {
    this.props.fetchVisibleDownloadCsvJobs();
    this.pollFetchVisibleDownloadCsvJobs(INITIAL_POLLING_INTERVAL);
  }

  pollFetchVisibleDownloadCsvJobs = interval => {
    const { downloadJobs, fetchVisibleDownloadCsvJobs } = this.props;
    let newInterval = interval;
    if (
      downloadJobs &&
      (Object.keys(downloadJobs).includes('inProgress') || Object.keys(downloadJobs).includes('waiting'))
    ) {
      fetchVisibleDownloadCsvJobs();
      if (newInterval < MAX_POLLING_INTERVAL) {
        newInterval *= 2;
      }
    }
    setTimeout(this.pollFetchVisibleDownloadCsvJobs, newInterval, newInterval);
  };

  render() {
    const { downloadJobs = {}, hideDownloadJobResults } = this.props;
    const sortCondition = (_status1, status2) => {
      if (status2 === 'inProgress' || status2 === 'waiting') return -1; // In progress tasks are on bottom
      if (status2 === 'success') return -1; // Successful tasks are on top
      return 0; // Failed tasks are in middle
    };
    return (
      <React.Fragment>
        {isEmpty(downloadJobs) ? null : (
          <div>
            {Object.keys(downloadJobs)
              .sort(sortCondition)
              .map(status => (
                <DownloadJobResult
                  key={status}
                  tasks={downloadJobs[status]}
                  status={status}
                  hideDownloadJobResults={hideDownloadJobResults}
                />
              ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    downloadJobs: getDownloadPayCsvJobs(state)
  }),
  dispatch => ({
    hideDownloadJobResults: tasks => {
      if (isEmpty(tasks)) return;

      const taskIds = tasks.map(j => j.id).join('_');
      dispatch(setDownloadPayCsvJobInvisible(taskIds));
    },
    fetchVisibleDownloadCsvJobs: () => dispatch(fetchDownloadPayCsvJobs(true))
  })
)(DownloadPayCsvJobStatus);
