import axios from 'axios';
import { combineReducers } from 'redux';
import moment from 'moment';

import { formatDate } from 'src/utils/Date';
import * as Urls from 'src/constants/EndpointUrls';
import { redirectTo } from 'src/utils/Http';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { itemReducer } from 'src/reducers/common';
import { setItem, editItem, editItemSuccess, editItemFailure } from '../common/itemReducer';

export const REDUCER_NAME = 'reportEditHealthInsuranceBonus';

// Async Action Creators
export const setReportHealthInsuranceBonusItem = fieldValues => dispatch => {
  const values = { ...fieldValues };
  // 日付が設定されていない場合は、今日の日付をセットする
  if (values.submitOn === null) {
    values.submitOn = formatDate(moment());
  }
  dispatch(setItem(REDUCER_NAME, values));
};

export const editReportHealthInsuranceBonusItem = data => async dispatch => {
  dispatch(editItem(REDUCER_NAME));
  try {
    const url = Urls.REPORT_HEALTH_INSURANCE_BONUS_ITEM_UPDATE;
    const httpBody = { ...data };
    const response = await axios.put(url, httpBody);
    dispatch(editItemSuccess(REDUCER_NAME, response.data.payload.healthInsuranceBonusItem));
    redirectTo(Urls.REPORT_HEALTH_INSURANCE_BONUS_LIST);
  } catch (e) {
    dispatch(editItemFailure(REDUCER_NAME, e.response.data.errors.messages));
  }
};

export default combineReducers({
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
