import React from 'react';
import isEmpty from 'lodash/isEmpty';
import ListGroup from 'jbc-front/components/ListGroup';
import styles from './Setup.scss';
import EmployeePopUp from './EmployeePopUp';

const STEPS = [
  {
    url: '/clients/main_office',
    type: 'client_info',
    description: (
      <dl className={styles.setup}>
        <dt className={styles.setupTitle}>事業所の登録</dt>
        <dd className={styles.setupText}>名称や住所などの事業所情報や各種保険情報、振込元口座情報を設定します。</dd>
      </dl>
    )
  },
  {
    url: '/clients/payroll_rules_groups',
    type: 'payroll_rules_groups',
    description: (
      <dl className={styles.setup}>
        <dt className={styles.setupTitle}>勤怠項目と給与規定の設定</dt>
        <dd className={styles.setupText}>
          <p>
            ①<a href="/clients/allowance_items" target="_blank" className={styles.link}>
              勤怠項目一覧
            </a>を確認し不足している項目があれば追加してください。
          </p>
          <p>②給与規定グループを作成します。</p>
          <p>③支給・控除項目を作成します。</p>
          <p>④「割増基礎単価」などの単価を作成します。</p>
          <p>⑤複数給与規定グループを作成する場合は②～④を繰り返します。</p>
        </dd>
      </dl>
    )
  },
  {
    url: '/employees',
    type: 'employees',
    description: (
      <dl className={styles.setup}>
        <dt className={styles.setupTitle}>従業員の設定</dt>
        <dd className={styles.setupText}>
          <p>新しい従業員の登録を行います。</p>
          <div>
            給与計算に必要な情報は
            <span>
              <EmployeePopUp />
            </span>
            を参照してください。
          </div>
        </dd>
      </dl>
    )
  }
];

const Setup = ({ notCompletedSetting }) => {
  if (isEmpty(notCompletedSetting)) {
    return null;
  }
  return (
    <div className={styles.setups}>
      <div className="l-wrap-xl">
        <h2>まずは必要なセットアップを完了しましょう</h2>
        <ListGroup>
          {STEPS.filter(item => notCompletedSetting.includes(item.type)).map(step => (
            <ListGroup.Item className={styles.list} key={step.type} {...{ href: step.url, target: '_blank', as: 'a' }}>
              {step.description}
              <ListGroup.Icon />
            </ListGroup.Item>
          ))}
        </ListGroup>
      </div>
    </div>
  );
};
export default Setup;
