import React from 'react';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { ErrorsSection } from 'src/components';
import styles from './ConfirmModal.scss';

const UnconfirmAllEmployeePayForm = ({ hideModal, errors, header, onSubmit }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form className={styles.wrap}>
        <ErrorsSection errors={errors} />
        {/* Todo: 選択個別確定が実装されたら文言をこっちに変える
              <div>選択された従業員の確定処理を行います。 確定を行う場合は確定ボタンを押してください。</div>
          */}
        <div>
          全従業員の確定解除処理を行います。<br />
          確定解除を行う場合は解除ボタンを押してください。
        </div>
        <ul className={styles.notes}>
          <li>※既に個別に確定解除処理を行なっている従業員の給与データは更新されません</li>
        </ul>
        <ul className={styles.caution}>
          <li>【注意】</li>
          <li>勤怠・支給・控除項目は現在の情報を基に再計算されます。</li>
          <li>ただし、手入力した値やCSVで更新した値は変更されません。</li>
        </ul>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <Button primary onClick={onSubmit}>
        解除
      </Button>
    </CommonModal.Footer>
  </React.Fragment>
);

const UnconfirmAllEmployeePayModal = ({ isOpen, hideModal, onSubmit }) => {
  const header = '確定解除';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <UnconfirmAllEmployeePayForm hideModal={hideModal} header={header} onSubmit={onSubmit} />
    </CommonModal>
  );
};

export default UnconfirmAllEmployeePayModal;
