import {
  FORM_NAME_BONUSES_SEARCH,
  takeBonusConfirmedStateFromSearchForm,
  takeBonusesFbDataUrl,
  takeBonusesQueriedUrl,
  takeBonusFromSearchForm
} from 'src/reducers/searchForm';
import { downloadAccountingBonusCsv } from 'src/reducers/employees/employeeBonus';
import * as Urls from 'src/constants/EndpointUrls';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import ExtraActionsSection from '../components/ExtraActionsSection';

const mapStateToProps = state => ({
  confirmed: takeBonusConfirmedStateFromSearchForm(state),
  downloadTemplateCsvPageUrl: takeBonusesQueriedUrl(
    Urls.EMPLOYEE_BONUS_DOWNLOAD_CSV_PAGE_URL,
    getFormValues(FORM_NAME_BONUSES_SEARCH)(state)
  ),
  showBonusFbDataUrl: takeBonusesFbDataUrl(getFormValues(FORM_NAME_BONUSES_SEARCH)(state)),
  currentBonus: takeBonusFromSearchForm(state)
});

const mapDispatchToProps = dispatch => ({
  downloadAccountingBonus: () => dispatch(downloadAccountingBonusCsv())
});

export default connect(mapStateToProps, mapDispatchToProps)(ExtraActionsSection);
