import axios from 'axios';
import humps from 'humps';
import _ from 'lodash';

import {
  ACCOUNT_REGISTRATION_CREATE,
  ACCOUNT_REGISTRATION_COMPLETED_NOTIFY,
  USER_INVITE_TO_DASHBOARDS
} from 'src/constants/EndpointUrls';
import { newItemFailure, getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME } from 'src/reducers/clients/clients';
import { redirectTo } from 'src/utils/Http';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

export const getRegistrationErrors = state => getItemErrors(REDUCER_NAME, state);

export const registerAccount = data => async dispatch => {
  try {
    await axios.post(ACCOUNT_REGISTRATION_CREATE, data);
    redirectTo(`${ACCOUNT_REGISTRATION_COMPLETED_NOTIFY}?email=${encodeURIComponent(data.userEmail)}`);
  } catch (e) {
    dispatch(newItemFailure(REDUCER_NAME, e.response.data.errors.messages));
  }
};

export const inviteAccount = data => async dispatch => {
  try {
    const snakeValues = humps.decamelizeKeys(data);
    const params = { name: snakeValues.name, email: snakeValues.email, authority: snakeValues.authority };
    await axios.post(USER_INVITE_TO_DASHBOARDS, params);
    dispatch(setGlobalSuccesses('アカウント招待メールを送信しました'));
  } catch (e) {
    let msg = 'アカウント招待メールの送信に失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages[0];
    }
    dispatch(setGlobalErrors(msg));
  }
};
