import React, { Component } from 'react';
import { DeleteSquare, EditSquare } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import styles from './DeleteEditEmployeeBtn.scss';

export default class DeleteEditEmployeeBtn extends Component {
  state = {
    isModalOpen: false
  };

  closeModal = () => this.setState({ ...this.state, isModalOpen: false });

  render() {
    const { deletable, handleDelete, editUrl } = this.props;
    const header = '従業員情報の削除';

    function Title() {
      if (deletable) {
        return (
          <CommonModal.Body>
            <p>
              従業員情報の削除は取り戻しできません。<br />
              従業員のアカウントも一緒に削除され、ログインできなくなりますのでご注意ください。<br />
              <span className={styles.warning}>本当に従業員を削除しますか</span>
            </p>
          </CommonModal.Body>
        );
      }
      return (
        <CommonModal.Body>
          <p className={styles.warning}>給与もしくは賞与の確定情報があるため削除することができません。</p>
        </CommonModal.Body>
      );
    }
    return (
      <div className="u-ta-r u-mt30">
        <span
          className={styles.delete}
          role="presentation"
          onClick={() => this.setState({ ...this.state, isModalOpen: true })}
        >
          <DeleteSquare icon={16} />従業員を削除
        </span>
        <span className={styles.edit}>
          <a href={editUrl}>
            <EditSquare icon={16} />従業員情報編集画面へ
          </a>
        </span>
        <CommonModal header={header} isOpen={this.state.isModalOpen} hideModal={this.closeModal} ariaHideApp={false}>
          <CommonModal.Header hideModal={this.closeModal}>{header}</CommonModal.Header>
          <Title />
          {deletable && (
            <CommonModal.Footer>
              <Button onClick={this.closeModal} className="u-mr20">
                いいえ
              </Button>
              <Button
                onClick={() => {
                  handleDelete();
                  this.closeModal();
                }}
                primary
              >
                はい
              </Button>
            </CommonModal.Footer>
          )}
        </CommonModal>
      </div>
    );
  }
}
