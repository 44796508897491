import { connect } from 'react-redux';
import _ from 'lodash';

import InsuranceSection from '../components/InsuranceSection';
import { REDUCER_NAME } from '../../../reducers/employees/employees';
import { getItem } from '../../../reducers/common/itemReducer';

const mapStateToProps = state => ({
  hasTargetHealthInsuranceAssociations: _.get(getItem(REDUCER_NAME, state), 'hasTargetHealthInsuranceAssociations')
});

export default connect(mapStateToProps)(InsuranceSection);
