import React from 'react';
import { Link } from 'react-router-dom';

import { CLIENT_ALLOWANCE_EDIT } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { Edit } from 'jbc-front/components/icons';
import Hint from 'jbc-front/components/Hint';
import styles from './AllowanceRow.scss';

const allowanceEditUrl = allowance =>
  concatParamsToUrl(CLIENT_ALLOWANCE_EDIT, {
    allowanceId: allowance.id,
    payrollRulesGroupId: allowance.clientPayrollRulesGroupId
  });

const AllowanceRow = ({ allowance }) => (
  <tr>
    <td className={allowance.isInDisuse ? styles.through : ''} style={{ wordBreak: 'break-all' }}>
      {allowance.name}
    </td>
    <td className={allowance.isInDisuse ? styles.through : ''} style={{ wordBreak: 'break-all' }}>
      {allowance.displayFormulaDescription}
      {allowance.formulaMethodName === 'income_tax' && (
        <Hint
          text={
            <p>
              「課税対象額」（所得税の計算対象合計 -
              社会保険料等の所得税控除）と、「扶養親族等の数」に応じて所得税の金額が変わります。詳細は国税庁「給与所得の源泉徴収税額表（月額表）」をご覧ください。
            </p>
          }
        />
      )}
      {allowance.useYearEndAdjustment && (
        <Hint
          text={
            <p>
              年末調整の過不足税額を12月給与・1月給与で精算する場合に使用してください。<br />
              年末調整機能で「過不足額の精算」画面から「ジョブカン給与計算へ反映」を行った場合に自動で「超過額又は不足額」の値が入ります。
            </p>
          }
        />
      )}
    </td>
    <td>
      <div className={styles.icons}>
        <span>
          <Link to={allowanceEditUrl(allowance)}>
            <Edit size={18} />
          </Link>
        </span>
      </div>
    </td>
  </tr>
);

export default AllowanceRow;
