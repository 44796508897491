import React from 'react';
import PropTypes from 'prop-types';
import RevisionGrade from './RevisionGrade';

const RevisionGradeAmount = props => {
  const { isJoined, amount } = props;
  return isJoined ? (
    <React.Fragment>
      {amount}
      <span className="unit">円</span>
    </React.Fragment>
  ) : (
    '―'
  );
};

RevisionGrade.propTypes = {
  isJoined: PropTypes.bool
};

RevisionGrade.defaultProps = {
  isJoined: false
};

export default RevisionGradeAmount;
