import React from 'react';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';

const SimpleConfirmModal = ({
  header = '操作確認',
  isOpen,
  hideModal,
  body,
  onClick,
  cancelBtnText = 'キャンセル',
  confirmBtnText = '確定',
  disabled = false
}) => (
  <CommonModal header={header} isOpen={isOpen} hideModal={hideModal} ariaHideApp={false}>
    <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
    <CommonModal.Body>{body}</CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        {cancelBtnText}
      </Button>
      <Button onClick={onClick} primary disabled={disabled}>
        {confirmBtnText}
      </Button>
    </CommonModal.Footer>
  </CommonModal>
);

export default SimpleConfirmModal;
