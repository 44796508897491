/**
 * 日数・時間の整数(時間)部を整形
 */
export const toFormattedInteger = input => {
  try {
    const intValue = parseInt(input, 10);
    if (intValue < 0) {
      return input;
    }
    return intValue.toString();
  } catch (e) {
    return input;
  }
};

export const emptyIntegerFormat = value => {
  if (!value) {
    return '0';
  }
  return value;
};

/**
 * 回数の整数部を整形
 */
export const toFormattedTimeInteger = input => {
  try {
    return parseInt(input, 10).toString();
  } catch (e) {
    return input;
  }
};

/**
 * 8 -> 8:00
 *
 * @param value
 * @returns {*}
 */
const toHourFormatPattern1 = value => {
  const regex = /^(\d{1,3}):?$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, hours] = matches;
    return `${toFormattedInteger(hours)}:00`;
  }
  return false;
};

/**
 * 8:2 -> 8:20
 *
 * @param value
 * @returns {*}
 */
const toHourFormatPattern2 = value => {
  const regex = /^(\d{1,3}):([0-5])$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, hours, minutes] = matches;
    return `${toFormattedInteger(hours)}:${minutes}0`;
  }
  return false;
};

/**
 * 8:20 -> 8:20
 *
 * @param value
 * @returns {*}
 */
const toHourFormatPattern3 = value => {
  const regex = /^(\d{1,3}):([0-5][0-9])$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, hours, minutes] = matches;
    return `${toFormattedInteger(hours)}:${minutes}`;
  }
  return false;
};

const emptyHourFormat = value => {
  if (!value) {
    return '0:00';
  }
  return false;
};

/**
 * 8 -> 8.20
 *
 * @param value
 * @returns {*}
 */
const toFloatFormatPattern1 = value => {
  const regex = /^(\d{1,3})\.?$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, integer] = matches;
    return `${toFormattedInteger(integer)}.00`;
  }
  return false;
};

/**
 * 8.2 -> 8.20
 *
 * @param value
 * @returns {*}
 */
const toFloatFormatPattern2 = value => {
  const regex = /^(\d{1,3})\.(\d)$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, integer, decimal] = matches;
    return `${toFormattedInteger(integer)}.${decimal}0`;
  }
  return false;
};

/**
 * 8.2 -> 8.20
 *
 * @param value
 * @returns {*}
 */
const toFloatFormatPattern3 = value => {
  const regex = /^(\d{1,3})\.(\d{2})$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, integer, decimal] = matches;
    return `${toFormattedInteger(integer)}.${decimal}`;
  }
  return false;
};

const emptyFloatFormat = value => {
  if (!value) {
    return '0.00';
  }
  return false;
};

export const toHourFormat = input => {
  let converted = '';
  [toHourFormatPattern1, toHourFormatPattern2, toHourFormatPattern3, emptyHourFormat].some(func => {
    const result = func(input);
    if (result) {
      converted = result;
      return true;
    }
    return false;
  });
  return converted !== '' ? converted : input;
};

export const toFloatFormat = input => {
  let converted = '';
  [toFloatFormatPattern1, toFloatFormatPattern2, toFloatFormatPattern3, emptyFloatFormat].some(func => {
    const result = func(input);
    if (result) {
      converted = result;
      return true;
    }
    return false;
  });
  return converted !== '' ? converted : input;
};

/**
 * 8 -> 8.2
 *
 * @param value
 * @returns {*}
 */
const toInsuranceRateFormatPattern1 = value => {
  const regex = /^(\d{1,3})\.?$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, integer] = matches;
    return `${toFormattedInteger(integer)}.0`;
  }
  return false;
};

/**
 * 8.2 -> 8.2
 *
 * @param value
 * @returns {*}
 */
const toInsuranceRateFormatPattern2 = value => {
  const regex = /^(\d{1,3})\.(\d)$/;
  const matches = regex.exec(value);
  if (matches) {
    const [, integer, decimal] = matches;
    return `${toFormattedInteger(integer)}.${decimal}`;
  }
  return false;
};

const emptyInsuranceRateFormat = value => {
  if (!value) {
    return '0.0';
  }
  return false;
};

export const toInsuranceRateFormat = input => {
  let converted = '';
  [emptyInsuranceRateFormat, toInsuranceRateFormatPattern1, toInsuranceRateFormatPattern2].some(func => {
    const result = func(input);
    if (result) {
      converted = result;
      return true;
    }
    return false;
  });
  return converted !== '' ? converted : input;
};
