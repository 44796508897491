import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';

import { EMPLOYEE_PAY_FORM } from 'src/constants/FormNames';
import { getIsLoading } from 'src/reducers/common/listReducer';
import { getItem, getItemErrors, setItemError } from 'src/reducers/common/itemReducer';
import {
  getEmployeePayId,
  getEmployeePayIsConfirmed,
  getPaymentMonthConfirmed,
  getAllowanceItems,
  getAllEmployeePays,
  getConfirmJobs,
  getDownloadPayCsvJobs,
  getImportPayCsvJobs,
  editEmployeePay,
  changeLock,
  REDUCER_NAME
} from 'src/reducers/employees/employeePays';
import EmployeePayForm from '../components/EmployeePayForm';

const mapStateToProps = state => ({
  confirmPayJobs: getConfirmJobs(state) || {},
  downloadPayCsvJobs: getDownloadPayCsvJobs(state) || {},
  importPayCsvJobs: getImportPayCsvJobs(state) || {},
  isListLoading: getIsLoading(REDUCER_NAME, state),
  initialValues: getItem(REDUCER_NAME, state),
  errors: getItemErrors(REDUCER_NAME, state),
  empId: getEmployeePayId(state),
  employeePayIsConfirmed: getEmployeePayIsConfirmed(state),
  paymentMonthConfirmed: getPaymentMonthConfirmed(state),
  allowanceItems: getAllowanceItems(state),
  allEmployeePays: getAllEmployeePays(state)
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(editEmployeePay(data, data.employeePay.id));
  },
  handleLockItem: (employeePayId, currentConfirmed) => {
    dispatch(changeLock(employeePayId, currentConfirmed));
  },
  clearRollbackError: () => dispatch(setItemError(REDUCER_NAME, { rollbackEmployeePay: null }))
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: EMPLOYEE_PAY_FORM
  })
)(EmployeePayForm);
