import { combineReducers } from 'redux';
import axios from 'axios';
import humps from 'humps';
import { startSubmit, getFormValues, getFormSyncErrors, change as formChange } from 'redux-form';
import { createAction } from 'redux-actions';
import qs from 'qs';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import _ from 'lodash';

import * as Urls from 'src/constants/EndpointUrls';
import { serializeHttpGetParams, concatParamsToUrl, redirectTo } from 'src/utils/Http';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { editItemFailure, getItemErrors, setItemError, newItem } from 'src/reducers/common/itemReducer';
import {
  fetchList,
  fetchListSuccess,
  fetchListFailure,
  updateOne,
  setExtras,
  getExtras,
  setList,
  getList,
  getListPageCount,
  getIsLoading
} from 'src/reducers/common/listReducer';
import { setGlobalSuccesses, setGlobalErrors } from 'src/reducers/global';
import { takeRevisionsSearchQueriesFromLocation } from 'src/reducers/searchForm';
import { paramsWithFile } from 'src/utils/Form';
import { fetchLatestJobStatus, isJobIncomplete } from 'src/reducers/JobStatus';
import { MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import { UPDATE_SUBJECT_MONTHLY_REVISIONS_FORM } from 'src/constants/FormNames';

export const REDUCER_NAME = 'monthlyRevisions';

export const UI_SET = 'REVISIONS_UI/SET';
export const UI_CHANGE_SHOW_MODE = 'REVISIONS_UI/CHANGE_SHOW_MODE';
export const UI_CHANGE_CALC_MODE = 'REVISIONS_UI/UI_CHANGE_CALC_MODE';
export const UI_ERRORS_ON_SAVE = 'REVISIONS_UI/UI_ERRORS_ON_SAVE';
export const UI_NOTIFY_VALUE_CHANGES = 'REVISIONS_UI/UI_NOTIFY_VALUE_CHANGES';
export const UI_RESET_VALUE_CHANGES = 'REVISIONS_UI/UI_RESET_VALUE_CHANGES';

export const uiSet = createAction(UI_SET);
export const uiChangeShowMode = createAction(UI_CHANGE_SHOW_MODE);
export const uiChangeCalcMode = createAction(UI_CHANGE_CALC_MODE);
export const getErrorsOnSave = (state, revision) => _.get(state, `monthlyRevisions.ui.revisions.${revision.id}.errors`);
export const uiSetErrorsOnSave = createAction(UI_ERRORS_ON_SAVE);
export const uiNotifyValueChanges = createAction(UI_NOTIFY_VALUE_CHANGES);
export const uiResetValueChanges = createAction(UI_RESET_VALUE_CHANGES);

export const getUiShowMode = (state, revision) => _.get(state, `monthlyRevisions.ui.revisions.${revision.id}.showMode`);
export const INIT_CALC_MODE = {
  modifiedAverageAmount: 'AUTO',
  changedAmount: 'AUTO',
  retroactiveAmount: 'AUTO'
};
export const getUiCalcMode = (state, revision, name) =>
  _.get(state, `monthlyRevisions.ui.revisions.${revision.id}.calcMode.${name}`);
export const getReadyForRecalculating = (state, revision) =>
  _.get(state, `monthlyRevisions.ui.revisions.${revision.id}.readyForRecalculating`);
export const getChangedYearMonths = state => _.get(state, 'monthlyRevisions.ui.changedYearMonths');
export const getLastRevisionYearMonths = state => _.get(getExtras(REDUCER_NAME, state), 'lastRevisionYearMonths');
export const getTargetHealthInsurance = state => _.get(getExtras(REDUCER_NAME, state), 'targetHealthInsurance');
export const getTargetRevisions = state => _.get(getExtras(REDUCER_NAME, state), 'targetRevisions');
export const getStaledRevisions = state => _.get(getExtras(REDUCER_NAME, state), 'staledRevisions');
export const getLoadingRevisions = state => getIsLoading(REDUCER_NAME, state) || false;

export const transformRevisionsToFormValues = (reducerName, state) =>
  state[reducerName].list.data.reduce(
    (pre, revision) => ({ revisions: { ...pre.revisions, [revision.id]: revision } }),
    { revisions: {} }
  );

export const getConfirmRevisionErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return (errors[0] || {}).changeMonthlyRevisionErrors || [];
};

// Async Action Creators
export const fetchRevisions = queries => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));

    const response = await axios.get(Urls.MONTHLY_REVISION_SEARCH, { params: humps.decamelizeKeys(queries) });
    const payload = {
      data: response.data.payload.revisions,
      pageCount: response.data.payload.totalPages,
      totalCount: response.data.payload.totalCount
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    // 標準報酬月額改訂対象者情報
    const targetParams = Object.assign(response.data.payload.targetDates, {
      targetCount: response.data.payload.targetRevisions.length,
      lastRevisionYearMonths: response.data.payload.lastRevisionYearMonths,
      targetRevisions: response.data.payload.targetRevisions
    });
    dispatch(setExtras(REDUCER_NAME, targetParams));

    // 従前反映対象者情報
    dispatch(setExtras(REDUCER_NAME, { staledRevisions: response.data.payload.staledRevisions }));
    dispatch(
      setExtras(REDUCER_NAME, {
        alertHealthInsuranceManualInputRevisions: response.data.payload.alertHealthInsuranceManualInputRevisions
      })
    );
    dispatch(
      setExtras(REDUCER_NAME, {
        alertPensionInsuranceManualInputRevisions: response.data.payload.alertPensionInsuranceManualInputRevisions
      })
    );
    dispatch(setExtras(REDUCER_NAME, { targetHealthInsurance: response.data.payload.targetHealthInsurance }));

    dispatch(
      uiSet({ revisions: response.data.payload.revisions, changedYearMonths: response.data.payload.changedYearMonths })
    );
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editRevision = revisionId => async (dispatch, getState) => {
  try {
    const state = getState();
    const revision = _.get(getFormValues('revisions')(state), `revisions.${revisionId}`);
    let hasFormErrors = false;

    // エラーがあるならスキップ
    if (_.get(getFormSyncErrors('revisions')(state), `revisions.${revisionId}`)) {
      hasFormErrors = true;
      return hasFormErrors;
    }

    const httpBody = {
      revision
    };

    const updatePayUrl = concatParamsToUrl(Urls.MONTHLY_REVISION_UPDATE, { id: revision.id });
    const response = await axios.put(updatePayUrl, httpBody);

    dispatch(formChange('revisions', `revisions.${revisionId}`, response.data.payload.revision));
    dispatch(updateOne(REDUCER_NAME, { id: revisionId, item: response.data.payload.revision }));
    dispatch(uiChangeShowMode({ revision: response.data.payload.revision, showMode: 'SHOW' }));
    dispatch(uiSetErrorsOnSave({ revision: response.data.payload.revision, errors: {} }));

    _.forIn(INIT_CALC_MODE, (value, key) => {
      dispatch(uiChangeCalcMode({ revision, name: key, calcMode: 'AUTO' }));
    });
    dispatch(uiResetValueChanges({ revision }));
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const recalculateRevision = revisionId => async (dispatch, getState) => {
  try {
    const state = getState();
    const revision = _.get(getFormValues('revisions')(state), `revisions.${revisionId}`);

    // エラーがあるならスキップ
    if (_.get(getFormSyncErrors('revisions')(state), `revisions.${revisionId}`)) {
      return;
    }

    if (!getReadyForRecalculating(state, revision)) {
      return;
    }

    // CalcModeに登録されている対象のキーでstateがAUTOの場合、サーバー側で自動算出した値をセットするためキー自体を送信しない
    _.forIn(revision, (value, name) => {
      if (getUiCalcMode(state, revision, name) === 'AUTO') {
        delete revision[name];
      }
    });

    const httpBody = {
      revision
    };

    const updatePayUrl = concatParamsToUrl(Urls.MONTHLY_REVISION_RECALCULATE, { id: revision.id });
    const response = await axios.post(updatePayUrl, httpBody);

    dispatch(formChange('revisions', `revisions.${revisionId}`, response.data.payload.revision));

    dispatch(uiResetValueChanges({ revision }));
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const resetRevision = revisionId => async (dispatch, getState) => {
  try {
    const state = getState();
    const revision = _.get(getFormValues('revisions')(state), `revisions.${revisionId}`);

    const updatePayUrl = concatParamsToUrl(Urls.MONTHLY_REVISION_RESET, { id: revisionId });
    const response = await axios.post(updatePayUrl);

    dispatch(formChange('revisions', `revisions.${revisionId}`, response.data.payload.revision));

    _.forIn(INIT_CALC_MODE, (value, name) => {
      dispatch(uiChangeCalcMode({ revision, name, calcMode: 'AUTO' }));
    });
    dispatch(uiResetValueChanges({ revision }));
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const fixBeforeMonthlyInsuranceAmounts = revisionIds => async (dispatch, getState) => {
  try {
    const requestUrl = Urls.MONTHLY_REVISION_FIX_BEFORE_MONTHLY_INSURANCE_AMOUNTS;
    const response = await axios.put(requestUrl, { revisionIds });
    if (response.status === 200) {
      const fixedRevisions = response.data.fixedRevisions;
      let monthlyRevisions = getList(REDUCER_NAME, getState());
      let targetRevisions = getTargetRevisions(getState());
      const targetHealthInsurance = getTargetHealthInsurance(getState());
      let staledRevisions = getStaledRevisions(getState());
      fixedRevisions.forEach(fixedRevision => {
        // 従前反映を行なうと標準報酬月額改訂対象（targetRevisions）かどうかが変化する可能性があるので、
        // 対象外であれば削除、対象であれば追加する
        targetRevisions = _.reject(targetRevisions, { id: fixedRevision.id, isSubject: false });
        if (fixedRevision.isSubject && _.filter(targetRevisions, { id: fixedRevision.id }).length === 0) {
          targetRevisions.push(fixedRevision);
        }

        staledRevisions = _.reject(staledRevisions, ['id', fixedRevision.id]);
        monthlyRevisions = _.map(monthlyRevisions, item => {
          const newItem = item;
          if (item.id === fixedRevision.id) {
            newItem.beforeMonthlyCompensationHealthInsuranceAmount =
              fixedRevision.beforeMonthlyCompensationHealthInsuranceAmount;
            newItem.beforeMonthlyRemunerationPensionInsuranceAmount =
              fixedRevision.beforeMonthlyRemunerationPensionInsuranceAmount;
            newItem.isSubject = fixedRevision.isSubject;
            newItem.isStaleBeforeMonthlyInsuranceAmounts = fixedRevision.isStaleBeforeMonthlyInsuranceAmounts;
          }
          return newItem;
        });
      });
      dispatch(setExtras(REDUCER_NAME, { staledRevisions }));
      dispatch(setExtras(REDUCER_NAME, { targetHealthInsurance }));
      dispatch(
        setList(REDUCER_NAME, { data: monthlyRevisions, pageCount: getListPageCount(REDUCER_NAME, getState()) })
      );
      dispatch(formChange('revisions', monthlyRevisions));
      dispatch(setGlobalSuccesses('標準報酬月額を反映しました'));
      // flushのためsleep & 画面にも従前の変更を反映させるためリロード
      await new Promise(r => setTimeout(r, 1000));
      window.location.reload();
    } else {
      dispatch(setGlobalErrors('エラーが発生しました。時間をおいて再度お試しください'));
    }
  } catch (exception) {
    dispatch(setGlobalErrors('エラーが発生しました。時間をおいて再度お試しください'));
  }
};

export const changeMonthlyRevisionConfirm = (props, callBack) => async dispatch => {
  const revisionIds = [];
  props.revisions.forEach(revision => {
    if (revision.isSubject || revision.isPensionInsuranceSubject) revisionIds.push(revision.id);
  });

  try {
    const httpBody = humps.decamelizeKeys({ revisionIds, revisionYearMonth: props.yearMonth });
    const response = await axios.put(Urls.MONTHLY_REVISION_CONFIRM, httpBody);
    if (response.status === 200) {
      dispatch(setGlobalSuccesses('標準報酬月額を改定しました'));
    } else {
      dispatch(setGlobalErrors('標準報酬月額の改定処理が失敗しました'));
    }
    callBack();
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

const uiReducer = (state = { revisions: {}, changedYearMonths: [] }, action) => {
  switch (action.type) {
    case UI_SET:
      return {
        changedYearMonths: action.payload.changedYearMonths,
        revisions: action.payload.revisions.reduce(
          (pre, revision) => ({
            ...pre,
            [revision.id]: {
              showMode: 'SHOW',
              readyForRecalculating: false,
              calcMode: { ...INIT_CALC_MODE }
            }
          }),
          {}
        )
      };
    case UI_CHANGE_SHOW_MODE:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: {
            ...state.revisions[action.payload.revision.id],
            showMode: action.payload.showMode
          }
        }
      };
    case UI_CHANGE_CALC_MODE:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: {
            ...state.revisions[action.payload.revision.id],
            calcMode: {
              ...state.revisions[action.payload.revision.id].calcMode,
              [action.payload.name]: action.payload.calcMode
            }
          }
        }
      };
    case UI_ERRORS_ON_SAVE:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: {
            ...state.revisions[action.payload.revision.id],
            errors: action.payload.errors
          }
        }
      };
    case UI_NOTIFY_VALUE_CHANGES:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: { ...state.revisions[action.payload.revision.id], readyForRecalculating: true }
        }
      };
    case UI_RESET_VALUE_CHANGES:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: { ...state.revisions[action.payload.revision.id], readyForRecalculating: false }
        }
      };
    default:
      return state;
  }
};

export const downloadCsv = (data, searchKey, callBack) => async dispatch => {
  try {
    dispatch(setItemError(REDUCER_NAME, []));
    const params = {
      year_month: searchKey.revisionYear,
      applicable_office: searchKey.applicableOffice,
      payment_for: data.paymentFor,
      serial_number: data.serialNumber
    };
    const query = qs.stringify(humps.decamelizeKeys({ csv: params }), { arrayFormat: 'brackets' });
    const downloadUrl = `${Urls.REPORT_MONTHLY_REVISIONS_CSV}?${query}`;
    const response = await fetch(downloadUrl);

    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      throw new Error(json.errors.messages);
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (exception) {
    dispatch(setItemError(REDUCER_NAME, exception.message.split(',')));
  } finally {
    if (callBack) callBack();
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME),
  ui: uiReducer
});

export const uploadCsv = (data = 'create') => async (dispatch, getState) => {
  try {
    dispatch(newItem(REDUCER_NAME));

    const params = paramsWithFile(data, ['monthly_revisions_csv']);
    await axios.post(Urls.MONTHLY_REVISION_IMPORT, params);
    dispatch(setGlobalSuccesses('ファイルを受け取りました。'));
    const yearMonth = takeRevisionsSearchQueriesFromLocation(getState().router.location).yearMonth;
    redirectTo(`${Urls.MONTHLY_REVISION_LIST}?${serializeHttpGetParams({ revision_year_month: yearMonth })}`);
  } catch (e) {
    let msg = 'CSVのアップロードに失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages;
    }
    dispatch(setItemError(REDUCER_NAME, msg));
  }
};

export const getImportCsvJobs = state => getExtras(REDUCER_NAME, state).importCsvJobs;

export const getImporting = state => getExtras(REDUCER_NAME, state).importing;

export const fetchImportCsvJobs = interval => async (dispatch, getState) => {
  const params = {
    job_name: 'importMonthlyRevisionCsvJob'
  };
  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.importMonthlyRevisionCsvJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchImportCsvJobs(nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
  }
};

export const hideJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'importMonthlyRevisionCsvJob'
    });
    dispatch(fetchImportCsvJobs());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const updateSubject = (formValues, revision) => async dispatch => {
  try {
    dispatch(startSubmit(UPDATE_SUBJECT_MONTHLY_REVISIONS_FORM));
    const updateHealthInsuranceSubject = formValues.updateHealthInsuranceSubject;
    const updatePensionInsuranceSubject = formValues.updatePensionInsuranceSubject;
    const url = concatParamsToUrl(Urls.MONTHLY_REVISION_UPDATE_SUBJECT, {
      id: revision.id
    });

    const httpBody = {
      updateHealthInsuranceSubject,
      updatePensionInsuranceSubject,
      lockVersion: revision.lockVersion
    };

    await axios.post(url, httpBody);

    window.location.reload();
  } catch (exception) {
    dispatch(formChange(UPDATE_SUBJECT_MONTHLY_REVISIONS_FORM, 'errors', exception.response.data.errors.messages));
  }
};
