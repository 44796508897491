import React, { useState } from 'react';
import { FormSection } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { RadioField } from 'jbc-front/components/Form';
import { getItem } from 'src/reducers/common/itemReducer';
import { getRadioOptions } from 'src/reducers/selectOptions';
import { REDUCER_NAME } from 'src/reducers/employees/employees';

const TaxClassificationSection = props => {
  const {
    residentClassification,
    taxScheduleClassification,
    residentClassifications,
    incomeTaxCalcMethods,
    taxScheduleClassifications,
    singleParentWidowClassifications,
    widowClassifications,
    disabledPersonClassifications,
    graduateStudentClassifications,
    disasterClassifications,
    foreignerClassifications
  } = props;
  const [isNotCover, setIsNotCover] = useState(residentClassification === 'not_cover');
  const [isCalcIncomeTax, setIsCalcIncomeTax] = useState(taxScheduleClassification !== 'no_calc_income_tax');
  const RESIDENT_DESC_URL = 'https://www.nta.go.jp/taxes/shiraberu/taxanswer/gensen/2875.htm';

  return (
    <div>
      <FormSection name="employeeTaxClassification">
        <RadioField
          label="非居住者区分"
          name="residentClassification"
          onChange={(e, selected) => setIsNotCover(selected === 'not_cover')}
          options={residentClassifications}
          note={
            <div>
              一般的に国内に住所を有する場合は対象外（居住者）、国外に住所を有する場合は非居住者に区分します。 詳細は<a
                href={RESIDENT_DESC_URL}
                rel="noreferrer"
                target="_blank"
              >
                国税庁のページ
              </a>をご確認ください。
            </div>
          }
        />
        {isNotCover ? (
          <RadioField
            label="税額表区分"
            name="taxScheduleClassification"
            onChange={(e, selected) => setIsCalcIncomeTax(selected !== 'no_calc_income_tax')}
            options={taxScheduleClassifications}
          />
        ) : (
          <RadioField label="所得税計算" name="incomeTaxCalcMethod" options={incomeTaxCalcMethods} />
        )}
        {isCalcIncomeTax &&
          isNotCover && (
            <React.Fragment>
              <RadioField
                label="障害者区分"
                name="disabledPersonClassification"
                options={disabledPersonClassifications}
              />

              <RadioField
                label="勤労学生区分"
                name="graduateStudentClassification"
                options={graduateStudentClassifications}
              />
              <RadioField
                label="ひとり親・寡婦区分"
                name="singleParentWidowClassification"
                options={singleParentWidowClassifications}
              />
              <RadioField
                label="寡婦（夫）区分（2020年以前）"
                name="widowClassification"
                options={widowClassifications}
              />
              <RadioField label="災害者区分" name="disasterClassification" options={disasterClassifications} />
              <RadioField label="外国人区分" name="foreignerClassification" options={foreignerClassifications} />
            </React.Fragment>
          )}
      </FormSection>
    </div>
  );
};

const mapStateToProps = state => ({
  residentClassification: get(getItem(REDUCER_NAME, state), 'employeeTaxClassification.residentClassification'),
  taxScheduleClassification: get(getItem(REDUCER_NAME, state), 'employeeTaxClassification.taxScheduleClassification'),
  residentClassifications: getRadioOptions(state, 'residentClassifications'),
  incomeTaxCalcMethods: getRadioOptions(state, 'incomeTaxCalcMethods'),
  taxScheduleClassifications: getRadioOptions(state, 'taxScheduleClassifications'),
  singleParentWidowClassifications: getRadioOptions(state, 'singleParentWidowClassifications'),
  widowClassifications: getRadioOptions(state, 'widowClassifications'),
  disabledPersonClassifications: getRadioOptions(state, 'disabledPersonClassifications'),
  graduateStudentClassifications: getRadioOptions(state, 'graduateStudentClassifications'),
  disasterClassifications: getRadioOptions(state, 'disasterClassifications'),
  foreignerClassifications: getRadioOptions(state, 'foreignerClassifications')
});

export default connect(mapStateToProps)(TaxClassificationSection);
