import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/offices/offices';
import { getExtras } from 'src/reducers/common/listReducer';
import { InsurancePeriodSetting } from 'src/components';

const mapStateToProps = state => {
  const insurances = getExtras(REDUCER_NAME, state).insurances;
  const healthInsurances = insurances.health.history;
  const pensionInsurances = insurances.pension.history;

  // 健保の当月徴収のフラグを厚年にも同じ値として設定する
  const keyToMerge = 'isCurrentMonthCollection';
  const updatedPensionInsurances = { ...pensionInsurances };
  let previousKeyValue = false;
  Object.entries(healthInsurances).forEach(([key, value]) => {
    // 当月徴収フラグのチェック状態が変わった時だけ厚年のリストにマージする
    if (value[0][keyToMerge] !== previousKeyValue) {
      updatedPensionInsurances[key] = [{ ...updatedPensionInsurances[key]?.[0], [keyToMerge]: value[0][keyToMerge] }];
    }
    previousKeyValue = value[0][keyToMerge];
  });

  return {
    upperLowInsurances: healthInsurances,
    lowerLowInsurances: updatedPensionInsurances,
    minSelectableYear: insurances.minSelectableYear
  };
};

export default connect(mapStateToProps)(InsurancePeriodSetting);
