import { getFormValues, change } from 'redux-form';
import { connect } from 'react-redux';
import { CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM } from 'src/constants/FormNames';
import { INITIAL_POLLING_INTERVAL } from 'src/constants/Generals';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { copyCsvFormat } from 'src/reducers/clients/csvFormats';
import {
  getIsCreating,
  fetchDownloadPayAllowancesDeductionsJobStatus,
  resetPolling
} from 'src/reducers/employees/employeePaysAsync';
import ShowAllowanceDeductionForm from '../components/ShowAllowanceDeductionForm';

const mapStateToProps = state => ({
  csvFormats: getSelectOptions(state, 'csvFormats'),
  groups: getSelectOptions(state, 'groups'),
  formValues: getFormValues(CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM)(state),
  uniquePayrollRuleGroups: getSelectOptions(state, 'uniquePayrollRuleGroups'),
  isCreating: getIsCreating(state)
});

const mapDispatchToProps = dispatch => ({
  formChange: (key, value) => dispatch(change(CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM, key, value)),
  copyFormat: csvFormatId => dispatch(copyCsvFormat(csvFormatId)),
  fetchAllowancesDeductionsJobStatus: uniqueString =>
    dispatch(fetchDownloadPayAllowancesDeductionsJobStatus(INITIAL_POLLING_INTERVAL, uniqueString)),
  resetPolling: () => dispatch(resetPolling())
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowAllowanceDeductionForm);
