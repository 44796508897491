import { connect } from 'react-redux';
import _ from 'lodash';
import { getSelectOptions } from 'src/reducers/selectOptions';

import SelectInhabitantTaxTablePDFModal from '../components/SelectInhabitantTaxTablePDFModal';

const mapStateToProps = (state, ownProps) => {
  const { formValues, payrollGroupSelectorType } = ownProps;

  // 一覧で選択されているセレクトボックスの値から表示用の文字列を作る
  let selectedLabel = '';
  if (payrollGroupSelectorType === 'all') {
    const groupedPaymentMonths = getSelectOptions(state, 'groupedPaymentMonths');
    selectedLabel = _.find(groupedPaymentMonths, pm => pm.value === formValues.groupedPaymentMonths).label;
  } else {
    const uniquePaymentDates = getSelectOptions(state, 'uniquePaymentDates');
    const uniquePaymentDate = _.find(uniquePaymentDates, pd => pd.value === formValues.uniquePaymentDate);
    selectedLabel = uniquePaymentDate.label.concat('分');
  }

  return {
    selectedLabel
  };
};

export default connect(mapStateToProps)(SelectInhabitantTaxTablePDFModal);
