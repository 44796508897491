import React from 'react';
import { reduxForm } from 'redux-form';
import { ErrorsSection } from 'src/components';
import { Download, Upload } from 'jbc-front/components/icons';
import { FileField } from 'jbc-front/components/Form';
import { RESIDENT_TAX_PAYMENT_CSV_IMPORT_FORM } from 'src/constants/FormNames';
import Button from 'jbc-front/components/Button';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { CLIENT_RESIDENT_TAX_PAYMENTS_TEMPLATE_CSV } from 'src/constants/EndpointUrls';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import InsertBatchCsvDescription from './InsertBatchCsvDescription';
import styles from './InsertBatchCsv.scss';

const InsertBatchCsvComponent = props => {
  const { submitting, handleSubmit, selectedEncoding, errors } = props;

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">指定番号一括登録</h1>
        <p className="m-title-main-note">住民税納付先の指定番号を、一括登録できます。</p>
      </div>
      <ErrorsSection errors={errors} />
      <div className={styles.wrap}>
        <div className={styles.head}>アップロード手順</div>
        <div className={styles.body}>
          <form onSubmit={handleSubmit}>
            <dl className={styles.label}>
              <dt className={styles.number}>1</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>ファイルの出力形式を選択してダウンロードしたCSVファイルをご利用ください。</p>
                <div className="u-mt30">
                  <DownloadCsvEncodingRadioButton
                    name={'encoding'}
                    label={null}
                    options={ENCODING_OPTIONS}
                    required={false}
                  />
                </div>
                <Button
                  widthWide
                  icon={<Download size={16} />}
                  onClick={() =>
                    redirectTo(
                      `${CLIENT_RESIDENT_TAX_PAYMENTS_TEMPLATE_CSV}?${serializeHttpGetParams({
                        encoding: selectedEncoding
                      })}`
                    )
                  }
                >
                  サンプルデータをダウンロード
                </Button>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>2</dt>
              <dd className={styles.detail}>
                下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したいデータを入力して下さい。
                <div className={styles.warning}>
                  <span className={styles.warningTitle}>【注意】</span>
                  <ul className={styles.warningList}>
                    <li>
                      以下の項目は<span className={styles.requiredText}>入力必須</span>になります。
                      <ul className={styles.requireList}>
                        <li>・団体コード</li>
                        <li>・指定番号</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>3</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
                <FileField name="client_resident_tax_payments_csv" className={styles.input} />
              </dd>
            </dl>
            <Button as="button" primary type="submit" disabled={submitting} icon={<Upload size={16} />} widthWide>
              アップロード
            </Button>
          </form>
        </div>
      </div>
      <div className={styles.description}>
        <InsertBatchCsvDescription />
      </div>
    </div>
  );
};

const InsertBatchCsv = reduxForm({
  form: RESIDENT_TAX_PAYMENT_CSV_IMPORT_FORM,
  initialValues: { encoding: 'UTF-8' },
  enableReinitialize: true
})(InsertBatchCsvComponent);
export default InsertBatchCsv;
