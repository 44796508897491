import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { downloadCsv } from 'src/reducers/reports/reportWageLedger';
import _ from 'lodash';
import compose from 'lodash/fp/compose';
import DownloadCsvInsideForm from '../components/DownloadCsvInsideForm';

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onSubmit: data => {
      dispatch(downloadCsv(data, ownProps.downloadParams));
      ownProps.cancelBtnClick();
    }
  };
}

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: 'DownloadCsvInsideForm',
    initialValues: { encoding: 'UTF-8' }
  })
)(DownloadCsvInsideForm);
