import React, { Component } from 'react';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';

import ConfirmBonusModal from 'src/features/EmployeeBonus/components/ConfirmBonusModal';
import UnconfirmBonusModal from 'src/features/EmployeeBonus/components/UnconfirmBonusModal';
import { isJobIncomplete } from 'src/reducers/JobStatus';
import styles from './ConfirmBonus.scss';

export default class ConfirmBonus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmModalOpen: false,
      isUnconfirmModalOpen: false
    };
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.openUnconfirmModal = this.openUnconfirmModal.bind(this);
    this.closeUnconfirmModal = this.closeUnconfirmModal.bind(this);
  }

  openConfirmModal() {
    this.props.setChangeConfirmBonusErrors([]);
    if (this.state.isConfirmModalOpen) return;
    this.setState({ ...this.state, isConfirmModalOpen: true });
  }

  closeConfirmModal() {
    if (!this.state.isConfirmModalOpen) return;
    this.setState({ ...this.state, isConfirmModalOpen: false });
  }

  openUnconfirmModal() {
    if (this.state.isUnconfirmModalOpen) return;
    this.setState({ ...this.state, isUnconfirmModalOpen: true });
  }

  closeUnconfirmModal() {
    if (!this.state.isUnconfirmModalOpen) return;
    this.setState({ ...this.state, isUnconfirmModalOpen: false });
  }

  validateSelectingClientBonus() {
    const { currentBonus } = this.props;
    if (!currentBonus) {
      this.props.displayErrors('賞与を選択してください。');
    }
  }

  handle = (id, data, closeModalCallBack) => {
    this.validateSelectingClientBonus();
    const { currentBonus } = this.props;

    if (!currentBonus) return;

    if (currentBonus.isConfirmed) {
      this.props.unconfirmClientBonus(id, this.props.lockAllTableRows, closeModalCallBack);
    } else {
      this.props.confirmClientBonus(id, data, this.props.unlockAllTableRows, closeModalCallBack);
    }
  };

  render() {
    const { currentBonus, runningJob, employeeBonusList, isAvailableOfficeStation } = this.props;
    const disabledReason =
      isJobIncomplete(_.get(runningJob, 'status')) === true
        ? '確定処理中です。完了までしばらくお待ちください。'
        : !employeeBonusList || employeeBonusList.length === 0
          ? '賞与の対象者がいません。'
          : null;
    let confirmBtn = (
      <Button
        disabled={disabledReason !== null}
        disabledReason={disabledReason}
        primary
        onClick={this.openConfirmModal}
        className={isAvailableOfficeStation ? styles.smallButton : styles.button}
      >
        賞与確定
      </Button>
    );

    if (!currentBonus) {
      return null;
    }

    if (currentBonus.isConfirmed) {
      confirmBtn = (
        <Button
          disabled={isJobIncomplete(this.props.jobStatus.status) === true}
          primary
          onClick={this.openUnconfirmModal}
          className={isAvailableOfficeStation ? styles.smallButton : styles.button}
        >
          確定解除
        </Button>
      );
    }

    return (
      <div>
        {confirmBtn}
        <ConfirmBonusModal
          isOpen={this.state.isConfirmModalOpen}
          hideModal={this.closeConfirmModal}
          confirmClientBonus={this.handle}
          selectedClientBonus={currentBonus}
        />
        <UnconfirmBonusModal
          isOpen={this.state.isUnconfirmModalOpen}
          hideModal={this.closeUnconfirmModal}
          confirmClientBonus={this.handle}
          selectedClientBonus={currentBonus}
        />
      </div>
    );
  }
}
