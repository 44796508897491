import React, { Component } from 'react';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import styles from './SelectEmployeeMenu.scss';
import ChangedContext from '../ChangedContext';

const SelectEmployeeMenu = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };
    render() {
      const { hide } = this.props;

      return (
        <div className={styles.baloonWrap}>
          <div className={styles.baloon}>
            <ChangedContext.Consumer>
              {({ changeAll }) => (
                <ul className={styles.links}>
                  <li>
                    <a
                      onClick={() => {
                        changeAll(true);
                        hide();
                      }}
                      role="presentation"
                    >
                      全ページの従業員を選択
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        changeAll(false);
                        hide();
                      }}
                      role="presentation"
                    >
                      全ページの従業員を解除
                    </a>
                  </li>
                </ul>
              )}
            </ChangedContext.Consumer>
          </div>
        </div>
      );
    }
  }
);

export default connect()(SelectEmployeeMenu);
