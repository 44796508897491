import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Loading from 'src/components/Loading';
import Button from 'jbc-front/components/Button';
import PayReportsCreateContainer from '../containers/PayReportsCreateModalContainer';
import PayReportsDownloadContainer from '../containers/PayReportsDownloadModalContainer';
import styles from './DownloadPayReports.scss';

const DownloadPayReports = props => {
  const { payrollRulesGroups, yearMonth, downloadObj, isConfirmed, isReportJobPolling } = props;
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

  const isJobSuccess = jobStatus => jobStatus === 'success';
  const isJobIncomplete = jobStatus => ['waiting', 'running'].includes(jobStatus);

  useEffect(
    () => {
      if (isJobIncomplete(downloadObj.status) === true && _.get(isReportJobPolling, downloadObj.id) !== true) {
        props.pollFetchReportJobStatus(downloadObj, yearMonth);
      }
    },
    [downloadObj.status, yearMonth]
  );

  if (isJobIncomplete(downloadObj.status) === true) {
    return (
      <div className={styles.loading}>
        <Button primary widthWide disabled icon={<Loading />}>
          データ作成中
        </Button>
      </div>
    );
  }

  if (isJobSuccess(downloadObj.status) === true) {
    return (
      <React.Fragment>
        <div className={styles.button}>
          <Button disabled={!isConfirmed} onClick={() => setDownloadModalOpen(true)} primary widthWide>
            明細一括出力
          </Button>
        </div>
        <PayReportsDownloadContainer
          isOpen={isDownloadModalOpen}
          hideModal={() => setDownloadModalOpen(false)}
          downloadId={_.get(downloadObj, 'id')}
        />
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={styles.button}>
        <Button disabled={!isConfirmed} onClick={() => setCreateModalOpen(true)} primary widthWide>
          明細一括作成
        </Button>
      </div>
      <PayReportsCreateContainer
        isOpen={isCreateModalOpen}
        hideModal={() => setCreateModalOpen(false)}
        objId={_.get(downloadObj, 'id')}
        payrollRulesGroups={payrollRulesGroups}
        paymentDate={yearMonth}
      />
    </React.Fragment>
  );
};
export default DownloadPayReports;
