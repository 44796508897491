export const SAVE_THEN_BACK_TO_LIST = 'saveThenBackToList';
export const SAVE_THEN_MOVE_TO_EDIT = 'saveThenMoveToEdit';
export const DATE_FORMAT = 'YYYY/MM/DD';
export const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';
export const HEALTH_INSURANCE_MENU = 'health_insurance_menu';
export const PENSION_INSURANCE_MENU = 'pension_insurance_menu';
export const EMPLOYMENT_INSURANCE_MENU = 'employment_insurance_menu';
export const ACCIDENT_INSURANCE_MENU = 'accident_insurance_menu';
export const DEFAULT_MEMO_COLOR = {
  1: true,
  2: true,
  3: true,
  4: true,
  5: true,
  6: true
};
export const WINDOWS_31J = 'Windows-31J';
export const UTF_8 = 'UTF-8';
export const NEW_LINE_CHARACTERS = /\r\n|\n|\r|\\r\\n|\\n|\\r/;
export const HYPHEN_CHARACTER = '―';
export const INITIAL_POLLING_INTERVAL = 10000;
export const MAX_POLLING_INTERVAL = 320000;
