import React from 'react';

import { FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import SortableTh from 'src/components/SortableTh';
import EmployeeTableRow from '../containers/EmployeeTableRowContainer';

const EmployeeTable = ({ employees }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list">
      <thead>
        <tr>
          <SortableTh field="staffCode" globalClassName="m-table-list-staffcode" formName={FORM_NAME_EMPLOYEES_SEARCH}>
            スタッフコード
          </SortableTh>
          <th>氏名</th>
          <th>在職状況</th>
          <SortableTh field="joinedOn" formName={FORM_NAME_EMPLOYEES_SEARCH}>
            入社年月日
          </SortableTh>
          <th>マイナンバー</th>
        </tr>
      </thead>
      <tbody>
        {(!employees || employees.length === 0) && (
          <tr>
            <td colSpan={7}>データがありません。</td>
          </tr>
        )}
        {employees.map(employee => <EmployeeTableRow key={employee.id} {...{ employee }} />)}
      </tbody>
    </table>
  </div>
);

export default EmployeeTable;
