/* eslint-disable no-underscore-dangle */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import axios from 'axios';
import humps from 'humps';
import Modal from 'react-modal';
import 'normalize.css/normalize.css';
import 'react-datetime/css/react-datetime.css';
import 'jbc-front/styles/default.scss';
import 'font-awesome/scss/font-awesome.scss';
import { csrfToken } from 'src/utils/Utils';

import Shared from '../src/Shared';

Modal.setAppElement('div[data-react-class="App"]');

// Support component names relative to this directory:
const componentRequireContext = require.context('../adapters', true);
const ReactRailsUJS = require('react_ujs');

const splitRegex = /(?=[A-Z])|(\d+)/;
const alterConvert = (string, options) => {
  const alterOptions = options || {};
  const separator = alterOptions.separator || '_';
  const split = alterOptions.split || /(?=[A-Z])/;

  return string
    .split(split)
    .filter(item => !!item)
    .join(separator)
    .toLowerCase();
};

ReactRailsUJS.useContext(componentRequireContext);

axios.defaults.headers.common['X-CSRF-Token'] = csrfToken();
axios.defaults.headers.common.Accept = 'application/json';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.transformRequest = axios.defaults.transformRequest.concat(data => {
  let transformed = data;
  if (data && !(data instanceof window.FormData)) {
    const alterData = humps.decamelizeKeys(JSON.parse(data), {
      split: splitRegex,
      process(key, convert, options) {
        return alterConvert(key, options);
      }
    });

    transformed = JSON.stringify(alterData);
  }
  return transformed;
});
axios.defaults.transformResponse = axios.defaults.transformResponse.concat(
  data => (data ? humps.camelizeKeys(data) : undefined)
);
if (process.env.USING_API_MOCK) {
  axios.defaults.baseURL = 'http://localhost:4002';
}

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const shared = Shared.instance;
shared.initializeStore(humps.camelizeKeys(preloadedState));
