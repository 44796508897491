import React from 'react';
import { FieldArray, change as formChange } from 'redux-form';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { Section } from 'jbc-front/components/Form';
import PaymentBankItemSection from './PaymentBankItemSection';
import styles from './PaymentBankSection.scss';

const INDEX_OF_GENKIN_PAYMENT = 3;

class PaymentBanks extends React.Component {
  changeOtherChecked = (dispatch, index) => {
    const { fields } = this.props;
    fields.forEach((bank, idx) => {
      if (idx !== index) {
        dispatch(formChange(EMPLOYEE_FORM, `${bank}.checked`, false));
        dispatch(formChange(EMPLOYEE_FORM, `${bank}.transferType`, null));
        dispatch(formChange(EMPLOYEE_FORM, `${bank}.employeeBankId`, null));
      }
    });
  };
  isGenkin = paymentOrder => paymentOrder === 'fourth';
  isLessThanNumberOfAccounts = index => {
    const { employeeBankList } = this.props;
    return employeeBankList.length >= (index <= INDEX_OF_GENKIN_PAYMENT ? index + 1 : index);
  };
  isDisplayable = (paymentOrder, index) =>
    this.isGenkin(paymentOrder) || this.isLessThanNumberOfAccounts(index) ? styles.section : styles.displayNone;

  makePaymentsInfo = () => {
    const {
      fields,
      clientBanks,
      employeeBankList,
      fieldInitialize,
      currentPayPaymentBanks,
      currentBonusPaymentBanks,
      label
    } = this.props;

    // 口座情報が削除されたりなどで変化した場合に、支給設定も初期化する
    fields.forEach(value => {
      const index = parseInt(value.replace(/[^0-9]/g, ''), 10);
      const adjustedIndex = index < 4 ? index + 1 : index;
      if (
        index !== INDEX_OF_GENKIN_PAYMENT &&
        employeeBankList.length < adjustedIndex &&
        fieldInitialize !== undefined
      ) {
        if (label === '給与' && currentPayPaymentBanks[index].checked === true) {
          fieldInitialize(value);
        }
        if (label === '賞与' && currentBonusPaymentBanks[index].checked === true) {
          fieldInitialize(value);
        }
      }
    });

    const paymentsInfo = [];
    const mapper = {
      0: 'first',
      1: 'second',
      2: 'third',
      3: 'fourth',
      4: 'fifth',
      5: 'sixth',
      6: 'seventh'
    };

    // 支給設定の銀行振込の配列を作成
    fields.forEach(value => {
      const index = parseInt(value.replace(/[^0-9]/g, ''), 10);
      const hash = {
        paymentBank: value,
        clientBank: clientBanks,
        clientBankId: value.clientBankId,
        label: null,
        paymentOrder: mapper[index],
        index
      };
      if (index !== INDEX_OF_GENKIN_PAYMENT) {
        hash.label = `銀行振込${index <= INDEX_OF_GENKIN_PAYMENT ? index + 1 : index}`;
        paymentsInfo.push(hash);
      }
    });

    // 現金は最後尾にレンダリングしたいので最後に入れる
    fields.forEach(value => {
      const index = parseInt(value.replace(/[^0-9]/g, ''), 10);
      const hash = {
        paymentBank: value,
        clientBank: null,
        clientBankId: null,
        label: null,
        paymentOrder: mapper[index],
        index
      };
      if (index === INDEX_OF_GENKIN_PAYMENT) {
        hash.label = '現金';
        paymentsInfo.push(hash);
      }
    });
    return paymentsInfo;
  };

  render() {
    const { fields, label, employeeBankList } = this.props;
    const paymentsInfo = this.makePaymentsInfo();

    return (
      <div>
        <Section title={label}>
          {paymentsInfo.map(paymentInfo => (
            <div
              className={this.isDisplayable(paymentInfo.paymentOrder, paymentInfo.index)}
              key={paymentInfo.paymentBank}
            >
              <PaymentBankItemSection
                key={paymentInfo.paymentBank}
                index={paymentInfo.index}
                formKey={paymentInfo.paymentBank}
                formName={EMPLOYEE_FORM}
                employeeBankList={employeeBankList}
                clientBanks={paymentInfo.clientBank}
                changeOtherChecked={this.changeOtherChecked}
                name={fields.name}
                label={paymentInfo.label}
                paymentOrder={paymentInfo.paymentOrder}
              />
            </div>
          ))}
        </Section>
      </div>
    );
  }
}

const PaymentBankSection = ({
  employeeBankList,
  clientBanks,
  currentPayPaymentBanks,
  currentBonusPaymentBanks,
  fieldInitialize
}) => (
  <div>
    <FieldArray
      name="payPaymentBanks"
      component={PaymentBanks}
      employeeBankList={employeeBankList}
      clientBanks={clientBanks}
      currentPayPaymentBanks={currentPayPaymentBanks}
      fieldInitialize={fieldInitialize}
      label="給与"
    />
    <FieldArray
      name="bonusPaymentBanks"
      component={PaymentBanks}
      employeeBankList={employeeBankList}
      clientBanks={clientBanks}
      currentBonusPaymentBanks={currentBonusPaymentBanks}
      fieldInitialize={fieldInitialize}
      label="賞与"
    />
  </div>
);

export default PaymentBankSection;
