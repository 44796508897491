import React from 'react';
import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions, getRadioOptions } from 'src/reducers/selectOptions';
import selector, { getOptionLabel, employeeDisplay, display } from 'src/utils/Utils';
import { isHonnin } from 'src/utils/Form';
import tableStyles from '../styles/GeneralTableInfo.scss';

const BasicInfoSectionShow = ({ employee, prefectures, genders }) => (
  <div>
    <table className={tableStyles.mTablePersonal}>
      <tbody>
        <tr>
          <th>氏名：</th>
          <td>{employeeDisplay.fullName(employee, true)}</td>
        </tr>
        <tr>
          <th>ビジネスネーム：</th>
          <td>{employeeDisplay.fullBusinessName(employee)}</td>
        </tr>
        <tr>
          <th>性別：</th>
          <td>{display(employee.gender && getOptionLabel(employee.gender)(genders))}</td>
        </tr>
        <tr>
          <th>生年月日：</th>
          <td>{employeeDisplay.birthday(employee)}</td>
        </tr>
        <tr>
          <th>住所：</th>
          <td>{employeeDisplay.address(employee, prefectures)}</td>
        </tr>
        <tr>
          <th>電話番号：</th>
          <td>{employeeDisplay(employee.tel)}</td>
        </tr>
        <tr>
          <th>メールアドレス：</th>
          <td>{employeeDisplay(employee.email)}</td>
        </tr>
        <tr>
          <th>世帯主の続柄：</th>
          <td>{display(employee.headOfHousehold)}</td>
        </tr>
        {!isHonnin(employee.headOfHousehold) &&
          employee.headOfHousehold && (
            <tr>
              <th>世帯主の氏名：</th>
              <td>{display(employee.headOfHouseholdName)}</td>
            </tr>
          )}
      </tbody>
    </table>
  </div>
);

export default connect(state => ({
  employee: selector(getItem(REDUCER_NAME, state), 'employee'),
  prefectures: getSelectOptions(state, 'prefectures'),
  genders: getRadioOptions(state, 'genders')
}))(BasicInfoSectionShow);
