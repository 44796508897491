import React, { useState } from 'react';
import { FormSection } from 'redux-form';
import { EMPLOYEES_LIST } from 'src/constants/EndpointUrls';
import { addUuidToItems, mapUuidToItems } from 'src/reducers/clients/csvFormats';
import Button from 'jbc-front/components/Button';
import { Section, TextField } from 'jbc-front/components/Form';
import SelectLeftRightItems from 'src/features/ClientCsvFormats/components/SelectLeftRightItems';
import styles from 'src/features/ClientCsvFormats/components/ClientCsvFormatForm.scss';
import { SimpleConfirmModal } from 'src/components';
import { Delete } from 'jbc-front/components/icons';

const ClientCsvEmployeeFormatForm = props => {
  const {
    isCreateForm,
    csvAvaiableItems,
    submitting,
    submit,
    handleSubmit,
    initialValues,
    dispatchFormValue,
    clientCsvData,
    onDelete
  } = props;

  const csvAvaiableItemsWithUuid = addUuidToItems(csvAvaiableItems);
  const [state, setState] = useState({
    selectingLeftItems: [],
    rightItems: clientCsvData.currentSelectedFields,
    selectingRightItems: []
  });
  const [isModalOpen, setisModalOpen] = useState(false);
  const setStateData = newData => {
    dispatchFormValue(newData);
    setState(prevState => ({ ...prevState, ...newData }));
  };
  const rightItems = mapUuidToItems(csvAvaiableItemsWithUuid, state.rightItems);
  const leftItems = { ...csvAvaiableItemsWithUuid.employee };
  const selectingLeftItems = state.selectingLeftItems;
  const selectingRightItems = state.selectingRightItems;

  const openModal = () => {
    setisModalOpen(true);
  };

  const closeModal = () => {
    setisModalOpen(false);
  };

  const execDelete = () => {
    const deleteId = initialValues.clientCsvFormat.id;
    onDelete(deleteId);
    closeModal();
  };
  return (
    <form onSubmit={handleSubmit}>
      <Section
        title={`フォーマットの${isCreateForm ? '作成' : '編集'}`}
        icon={!isCreateForm ? <Delete size={20} onClick={openModal} /> : <div />}
      >
        <SimpleConfirmModal
          header={'削除確認'}
          isOpen={isModalOpen}
          hideModal={closeModal}
          body={'本当に削除してよろしいですか？'}
          onClick={execDelete}
          confirmBtnText={'削除'}
        />
        <FormSection name="clientCsvFormat">
          <TextField name="label" label="フォーマット名" required />
        </FormSection>

        <FormSection name="clientCsvFormatFields">
          <SelectLeftRightItems
            {...{ leftItems, selectingLeftItems, rightItems, selectingRightItems }}
            changeParentState={setStateData}
          />
        </FormSection>

        <div className={styles.actionButtons}>
          <span>
            <Button as="a" href={EMPLOYEES_LIST}>
              キャンセル
            </Button>
          </span>
          <span>
            <Button primary onClick={submit} disabled={submitting}>
              保存
            </Button>
          </span>
        </div>
      </Section>
    </form>
  );
};

export default ClientCsvEmployeeFormatForm;
