import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { SETTING_PAYMENT_DATE } from 'src/constants/FormNames';
import { PAYMENT_ON_WEEKEND_CASES } from 'src/constants/Options';
import { Setting } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import { RadioField } from 'jbc-front/components/Form';
import CommonModal from 'jbc-front/components/CommonModal';
import { FormSubmitBtn } from 'src/buttons';
import { settingPaymentDate } from 'src/reducers/clients/payrollRulesGroups';
import styles from './SettingPaymentDate.scss';

const SettingPaymentDateForm = ({ isOpen, handleHide, submitting, submit, handleSubmit }) => (
  <CommonModal isOpen={isOpen} hideModal={handleHide} closeOnClick ariaHideApp={false}>
    <CommonModal.Header hideModal={handleHide}>締め日・支給日の設定</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <RadioField
          name="paymentOnWeekendCase"
          options={PAYMENT_ON_WEEKEND_CASES}
          label="支給日が土日祝の場合"
          required
        />
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <div className={styles.confirm}>
        <Button onClick={handleHide} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="保存" {...{ submitting, submit }} />
      </div>
    </CommonModal.Footer>
  </CommonModal>
);

const SettingPaymentDateRdxForm = reduxForm({
  form: SETTING_PAYMENT_DATE
})(SettingPaymentDateForm);

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    paymentOnWeekendCase: ownProps.paymentOnWeekendCase
  }
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => dispatch(settingPaymentDate(data, ownProps.handleHide))
});

const ConnectedSettingPaymentDateForm = connect(mapStateToProps, mapDispatchToProps)(SettingPaymentDateRdxForm);

class SettingPaymentDate extends React.Component {
  state = {
    modalOpen: false
  };

  openModal = () => this.setState({ ...this.state, modalOpen: true });
  closeModal = () => this.setState({ ...this.state, modalOpen: false });

  settingPaymentOnWeekendCase = payrollRulesGroups => {
    if (!payrollRulesGroups || _.size(payrollRulesGroups) === 0) {
      return 'prev_weekday';
    }
    return _.first(payrollRulesGroups).paymentOnWeekendCase;
  };

  render() {
    const { payrollRulesGroups } = this.props;
    return (
      <React.Fragment>
        <a role="presentation" onClick={this.openModal} className={styles.icon}>
          <Setting size={16} />
        </a>
        <ConnectedSettingPaymentDateForm
          isOpen={this.state.modalOpen}
          handleHide={this.closeModal}
          paymentOnWeekendCase={this.settingPaymentOnWeekendCase(payrollRulesGroups)}
        />
      </React.Fragment>
    );
  }
}

export default SettingPaymentDate;
