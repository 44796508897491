import React from 'react';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { reduxForm, getFormValues, SubmissionError } from 'redux-form';
import { TextField } from 'jbc-front/components/Form';
import { isEmail } from 'src/utils/CustomValidators';
import * as Urls from 'src/constants/EndpointUrls';

import {
  takeEmployeesSearchQueries,
  takeEmployeesSearchQueriesFromLocation,
  FORM_NAME_EMPLOYEES_SEARCH
} from 'src/reducers/searchForm';
import { fetchEmployees } from 'src/reducers/employees/employees';
import { connect } from 'react-redux';
import { concatParamsToUrl } from 'src/utils/Http';
import axios from 'axios';
import compose from 'lodash/fp/compose';
import isUseOtherApp from 'src/utils/JbcId';
import { EMPLOYEE_EMAIL_FORM } from 'src/constants/FormNames';
import { replaceMessages } from 'src/utils/Utils';

const EmailForm = compose(
  connect(state => ({
    searchValues: {
      ...takeEmployeesSearchQueriesFromLocation(state.router.location, true),
      ...getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state)
    }
  })),
  reduxForm({
    form: EMPLOYEE_EMAIL_FORM,
    enableReinitialize: true,
    onSubmit: async (values, dispatch, { hideModal, id, searchValues }) => {
      try {
        const editUrl = concatParamsToUrl(Urls.UPDATE_EMPLOYEE_EMAIL, { id });
        await axios.put(editUrl, { employee: values });
        dispatch(fetchEmployees(takeEmployeesSearchQueries(searchValues)));
        hideModal();
      } catch (exception) {
        const messages = replaceMessages(exception.response.data.errors.messages, ['メールアドレス'], ['']);
        throw new SubmissionError({ email: messages });
      }
    }
  })
)(({ handleSubmit, hideModal, submitting, pristine, invalid, name }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={hideModal}>メールアドレスの登録</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <div className="form_box_main">
          <TextField name="email" label={`${name}さんのメールアドレス`} validate={isEmail} maxLength="255" required />
          {isUseOtherApp && <p>メールアドレスを変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。</p>}
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <CommonModal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <Button primary onClick={handleSubmit} disabled={submitting || pristine || invalid}>
          保存
        </Button>
      </CommonModal.Buttons>
    </CommonModal.Footer>
  </React.Fragment>
));

const EmailModal = ({ hideModal, employee, isOpen }) => (
  <CommonModal isOpen={isOpen} hideModal={hideModal}>
    {isOpen && (
      <EmailForm
        initialValues={{ email: employee.email }}
        name={employee.displayName}
        id={employee.id}
        hideModal={hideModal}
      />
    )}
  </CommonModal>
);

export default EmailModal;
