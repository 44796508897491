import axios from 'axios';
import _ from 'lodash';
import { createAction } from 'redux-actions';
import humps from 'humps';

import * as Urls from 'src/constants/EndpointUrls';
import { DEFAULT_PAGING_PER_PAGE } from 'src/Config';
import { getCurrentUserInfo } from 'src/reducers/users/currentUser';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

export const REDUCER_NAME = 'roleUsers';

export const SET_LIST = 'LIST/SET';
export const FETCH_LIST = 'ROLE_USERS/FETCH';
export const FETCH_LIST_SUCCESS = 'ROLE_USERS/FETCH_SUCCESS';
export const FETCH_LIST_FAILURE = 'ROLE_USERS/FETCH_FAILURE';

export const CHANGE_TO_SHOW_MODE = 'ROLE_USERS/CHANGE_TO_SHOW_MODE';
export const CHANGE_TO_EDIT_MODE = 'ROLE_USERS/CHANGE_TO_EDIT_MODE';

export const UPDATE = 'ROLE_USERS/UPDATE';
export const UPDATE_SUCCESS = 'ROLE_USERS/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'ROLE_USERS/UPDATE_FAILURE';

export const CHANGE_ROLE = 'ROLE_USERS/CHANGE_ROLE';
export const RESET_CHANGES = 'ROLE_USERS/RESET_CHANGES';

// Action Creators
export const setList = createAction(SET_LIST);
export const fetchList = createAction(FETCH_LIST);
export const fetchListSuccess = createAction(FETCH_LIST_SUCCESS);
export const fetchListFailure = createAction(FETCH_LIST_FAILURE);

export const changeToShowMode = createAction(CHANGE_TO_SHOW_MODE);
export const changeToEditMode = createAction(CHANGE_TO_EDIT_MODE);
export const updateList = createAction(UPDATE);
export const updateListSuccess = createAction(UPDATE_SUCCESS);
export const updateListFailure = createAction(UPDATE_FAILURE);

export const changeRole = createAction(CHANGE_ROLE);
export const resetChanges = createAction(RESET_CHANGES);

// Selectors
export const getList = state => state.roleUsers.data;
export const getMode = state => state.roleUsers.mode;
export const getListErrors = state => state.roleUsers.errors;

// Async Action Creators
export const fetchUsers = (queries = {}) => async dispatch => {
  try {
    dispatch(fetchList());
    const response = await axios.get(`${Urls.CLIENT_ROLE_USERS_SEARCH}`, { params: humps.decamelizeKeys(queries) });
    const { totalPages = 0, totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = { data: response.data.payload.users, pageCount: totalPages, totalCount, displayFrom, displayTo };
    dispatch(fetchListSuccess(payload));
  } catch (exception) {
    dispatch(fetchListFailure(exception.response.data.errors.messages));
  }
};

export const updateUsers = () => async (dispatch, getState) => {
  try {
    const updates = getList(getState()).filter(ele => ele.hasChanged);
    dispatch(updateList());
    const httpBody = {
      updates
    };
    const response = await axios.put(Urls.CLIENT_ROLE_USERS_UPDATE, httpBody);
    const payload = { updatedData: response.data.payload.users };
    dispatch(updateListSuccess(payload));
    dispatch(setGlobalSuccesses('権限を変更しました。'));
  } catch (exception) {
    dispatch(updateListFailure(exception.response.data.errors.messages));
  }
};

export const isAllChecked = state => {
  const currentUser = getCurrentUserInfo(state);
  const userList = _.filter(getList(state), user => user.email !== currentUser.email);
  return _.every(userList, { checked: true });
};

export const changeAllUserChecked = newChecked => (dispatch, getState) => {
  const currentUser = getCurrentUserInfo(getState());
  const userList = getList(getState()) || [];
  const newUserList = userList.map(
    user => (user.email !== currentUser.email ? { ...user, checked: newChecked } : { ...user })
  );
  dispatch(setList({ data: newUserList }));
};

export const changeUserChecked = userId => (dispatch, getState) => {
  const userList = getList(getState()) || [];
  const newUserList = userList.map(user => (user.id === userId ? { ...user, checked: !user.checked } : { ...user }));
  dispatch(setList({ data: newUserList }));
};

export const changeCheckedUserPolicy = (data, ownProps) => async (dispatch, getState) => {
  try {
    const { clientRoleId } = data;
    const checkedUser = _.filter(getList(getState()), { checked: true });
    const updates = _.map(checkedUser, user => ({ ...user, client_role_id: clientRoleId }));

    const response = await axios.put(Urls.CLIENT_ROLE_USERS_UPDATE, { updates });
    const payload = { updatedData: response.data.payload.users };
    dispatch(updateListSuccess(payload));
    dispatch(setGlobalSuccesses('権限を変更しました。'));
  } catch (exception) {
    ownProps.cancelBtnClick();
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export const deleteCheckedUser = () => async (dispatch, getState) => {
  try {
    const currentUser = getCurrentUserInfo(getState());
    const toDeletes = _.filter(getList(getState()), item => item.checked && item.email !== currentUser.email);

    await axios.post(Urls.CLIENT_ROLE_USERS_DESTROY_BATCH, { deletes: toDeletes });
    const remains = _.filter(getList(getState()), item => !item.checked);

    dispatch(changeToShowMode());
    dispatch(setList({ data: remains }));
    dispatch(setGlobalSuccesses('選択されたアカウントを削除しました。'));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.message));
  }
};

export const MODES = {
  SHOW: 'SHOW',
  EDIT: 'EDIT'
};

const INITIAL_STATE = {
  data: [],
  mode: MODES.SHOW,
  errors: [],
  loading: false,
  queries: { page: 1, perPage: DEFAULT_PAGING_PER_PAGE },
  list: { pageCount: 0, totalCount: 0, displayFrom: 0, displayTo: 0 }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LIST:
      return { ...state, data: action.payload.data };
    case FETCH_LIST:
      return { ...state, data: [], errors: [], loading: true };
    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        errors: [],
        loading: false,
        list: {
          pageCount: action.payload.pageCount,
          totalCount: action.payload.totalCount,
          displayFrom: action.payload.displayFrom,
          displayTo: action.payload.displayTo
        }
      };
    case FETCH_LIST_FAILURE:
      return { ...state, data: [], list: { pageCount: 0 }, errors: action.payload, loading: false };
    case CHANGE_TO_SHOW_MODE:
      return { ...state, mode: MODES.SHOW, errors: [] };
    case CHANGE_TO_EDIT_MODE:
      return { ...state, mode: MODES.EDIT };
    case UPDATE:
      return { ...state, errors: [], loading: true };
    case UPDATE_SUCCESS:
      return {
        ...state,
        mode: MODES.SHOW,
        errors: [],
        loading: false,
        data: state.data.map(ele => {
          let newEle = Object.assign({}, ele);
          delete newEle.hasChanged;
          delete newEle.prevClientRoleId;
          newEle.checked = false;
          action.payload.updatedData.forEach(updatedEle => {
            if (newEle.id === updatedEle.id) {
              newEle = Object.assign(newEle, updatedEle);
            }
          });
          return newEle;
        })
      };
    case UPDATE_FAILURE:
      return { ...state, errors: action.payload, loading: false };
    case RESET_CHANGES:
      return {
        ...state,
        data: state.data.map(ele => {
          const newEle = Object.assign({}, ele);
          newEle.checked = false;
          if (newEle.hasChanged) {
            newEle.clientRoleId = newEle.prevClientRoleId;
            delete newEle.hasChanged;
            delete newEle.prevClientRoleId;
          }

          return newEle;
        })
      };
    case CHANGE_ROLE:
      return {
        ...state,
        data: state.data.map(ele => {
          const newEle = Object.assign({}, ele);
          if (newEle.id === action.payload.id) {
            if (newEle.hasChanged && newEle.id === newEle.prevClientRoleId) {
              delete newEle.hasChanged;
              delete newEle.prevClientRoleId;
            } else {
              newEle.hasChanged = true;
              newEle.prevClientRoleId = newEle.clientRoleId;
            }
            newEle.clientRoleId = action.payload.clientRoleId;
          }
          return newEle;
        })
      };
    default:
      return state;
  }
};
