import React from 'react';

import styles from './FlatTaxReductionCsvUpload.scss';

const FlatTaxReductionCsvUploadDescription = () => (
  <div className="l-wrap-xl">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>スタッフコード{<span className={styles.required}>必須</span>}</th>
            <td>スタッフコードを基にデータが取り込まれるため、正確に入力してください。</td>
            <td>111</td>
          </tr>
          <tr>
            <th>定額減税対象人数</th>
            <td>半角数字 0〜99</td>
            <td>1</td>
          </tr>
          <tr>
            <th>既減税額</th>
            <td>半角数字 0〜9999999</td>
            <td>12345</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export { FlatTaxReductionCsvUploadDescription };
// ↓ sprint132 以降に消す
export default FlatTaxReductionCsvUploadDescription;
