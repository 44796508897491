import React from 'react';

import Modal from 'jbc-front/components/CommonModal';
import { TextField, SelectField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';

import { maxLength30, isEmail, selectedInviteRole } from 'src/utils/CustomValidators';
import { INVITE_USER_ROLES } from 'src/constants/Options';

import styles from './InviteForm.scss';

class InviteForm extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  render() {
    const { handleSubmit, submitting, hideModal, pristine } = this.props;
    return (
      <div>
        <Modal.Header hideModal={hideModal}>アカウント招待</Modal.Header>
        <Modal.Body className={styles.modalBody} ref={this.ref}>
          <form onSubmit={handleSubmit}>
            <TextField name="email" label="メールアドレス" required validate={isEmail} />
            <TextField name="name" label="名前" required validate={maxLength30} />
            <SelectField
              required
              name="authority"
              label="権限"
              validate={selectedInviteRole}
              options={INVITE_USER_ROLES}
            />
            <dl className={styles.caution}>
              <dt className="u-mb5">注意</dt>
              <dd>アカウント招待は社労士など従業員情報のないアカウントを作成したい場合のみに使用してください。</dd>
              <dd>
                また、共通IDのアカウントや従業員情報は作成されず、あとから紐付けることもできませんのでご注意ください。
              </dd>
            </dl>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Modal.Buttons>
            <Button onClick={hideModal}>キャンセル</Button>
            <Button primary onClick={handleSubmit} disabled={submitting || pristine}>
              招待
            </Button>
          </Modal.Buttons>
        </Modal.Footer>
      </div>
    );
  }
}
export default InviteForm;
