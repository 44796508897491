import React from 'react';
import Button from 'jbc-front/components/Button';
import { csrfToken } from 'src/utils/Utils';
import { YEAR_END_ADJ_START, YEAR_END_ADJ_MAIN_OFFICE } from 'src/constants/EndpointUrls';

import styles from './Start.scss';

const Start = () => (
  <React.Fragment>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">年末調整</h1>
      <p className="m-title-main-note" />
    </div>
    <div className="l-contents-wrap">
      <div className={styles.wrap}>
        <p className={styles.text}>
          ジョブカンの年末調整！<br />
          ジョブカン労務HR・給与計算に登録された情報をもとに年末調整を行うことができます。<br />
          年末調整を依頼されたら従業員は画面の手順に従って情報を入力するだけで書類が作成できます。<br />
          支払金額と控除額を計算し、過不足額の精算や役所提出用書類の作成まで自動化します。
        </p>
        <div className={styles.buttons}>
          <Button primary large widthWide as="a" href={YEAR_END_ADJ_MAIN_OFFICE} disabled={false}>
            事業所情報を確認する
          </Button>
          <div className={styles.start}>
            <form action={YEAR_END_ADJ_START} method="post">
              <input type="hidden" value={csrfToken()} name="authenticity_token" />
              <Button primary large widthWide as="button">
                年末調整を開始する
              </Button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default Start;
