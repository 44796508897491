import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { camelize } from 'humps';

import { ALLOWED_IP_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';

import { getMode, getList, getExtras } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME, updateIps } from 'src/reducers/clients/allowedIps';

import { setGlobalErrors } from 'src/reducers/global';
import EditableList from './EditableList';

const EditableListFormed = reduxForm({
  form: ALLOWED_IP_FORM,
  enableReinitialize: true,
  onSubmitFail: (errors, dispatch) => dispatch(setGlobalErrors('IPアドレスを入力してください。'))
})(EditableList);

const mapStateToProps = state => {
  const selectedTab = getExtras(REDUCER_NAME, state).selectedTab;
  const ipList = getList(REDUCER_NAME, state).filter(ip => camelize(ip.permissionType) === selectedTab);
  return {
    initialValues: { [EDITABLE_LIST_FIELDS_NAME]: ipList },
    mode: getMode(REDUCER_NAME, state),
    listData: ipList,
    selectedTab
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(updateIps(data[EDITABLE_LIST_FIELDS_NAME]));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableListFormed);
