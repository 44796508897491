import React from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { connect } from 'react-redux';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { setGroup } from 'src/reducers/clients/groups';

import { setList } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME as EMPLOYMENT_TYPE } from 'src/reducers/clients/employmentTypes';
import { REDUCER_NAME as POSITION } from 'src/reducers/clients/positions';
import { REDUCER_NAME as OCCUPATION } from 'src/reducers/clients/occupations';
import { isFreePlan } from 'src/reducers/clients/currentClient';
import GroupCsvButtonArea from 'src/features/Groups/containers/GroupCsvButtonAreaContainer';
import ImportCsvJobStatus from 'src/features/Groups/components/ImportCsvJobStatus';
import Groups from './components/Groups';
import EmploymentTypes from './components/EmploymentTypes';
import Positions from './components/Positions';
import Occupations from './components/Occupations';
import styles from './SettingClientDepartment.scss';

const TABS = ['groups', 'employment_types', 'positions', 'occupations'];

class SettingClientDepartment extends React.Component {
  state = {
    tab: 'groups',
    isBaloonOpen: false
  };

  componentDidMount() {
    const { dispatch, values, location } = this.props;

    dispatch(setGroup(values.groups));
    dispatch(setList(EMPLOYMENT_TYPE, values.clientEmploymentTypes));
    dispatch(setList(POSITION, values.positions));
    dispatch(setList(OCCUPATION, values.occupations));
    const tab = _.get(queryString.parse(location.search), 'tab', 'groups');
    if (_.includes(TABS, tab)) this.select(tab);
  }

  select = tab => {
    this.setState({ ...this.state, tab });
  };
  selected = tab => this.state.tab === tab;
  classFor = tab => (this.selected(tab) ? `${styles.tab} ${styles.tabActive}` : `${styles.tab}`);

  render() {
    const { planType } = this.props;

    return (
      <div>
        <div className={styles.head}>
          <div className="l-wrap-xs">
            <h1 className="m-title-main">組織管理の設定</h1>
            <p className="m-title-main-note">グループ、雇用形態、役職、職種の情報の設定を行えます</p>
            {/*             <div className={styles.buttonWrap}> */}
            {/*               <div className={styles.button}> */}
            {/*                 <Button */}
            {/*                   onClick={() => this.setState({ ...this.state, isBaloonOpen: !this.state.isBaloonOpen })} */}
            {/*                   className="ignore-react-onclickoutside" */}
            {/*                   icon={<Link size={17} />} */}
            {/*                   disabled={isFreePlan(planType)} */}
            {/*                   disabledReason={isFreePlan(planType) ? <FreePlanRestriction /> : null} */}
            {/*                   widthAuto */}
            {/*                 > */}
            {/*                   一括連携 */}
            {/*                 </Button> */}
            {/*               </div> */}
            {/*             </div> */}
            <div className={styles.list}>
              <div
                className={this.classFor('employment_types')}
                onClick={() => this.select('employment_types')}
                role="presentation"
              >
                雇用形態
              </div>
              <div
                className={this.classFor('occupations')}
                onClick={() => this.select('occupations')}
                role="presentation"
              >
                職種
              </div>
              <div className={this.classFor('groups')} onClick={() => this.select('groups')} role="presentation">
                グループ
              </div>
              <div className={this.classFor('positions')} onClick={() => this.select('positions')} role="presentation">
                役職
              </div>
            </div>
          </div>
        </div>
        {this.selected('groups') && <ImportCsvJobStatus />}
        <div className="l-wrap-xs l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
              <BreadCrumb.Item label="組織管理の設定" />
            </BreadCrumb>
          </div>
          {this.selected('groups') && <GroupCsvButtonArea />}
          <div>
            {this.selected('groups') && <Groups isFreePlan={isFreePlan(planType)} />}
            {this.selected('employment_types') && <EmploymentTypes isFreePlan={isFreePlan(planType)} />}
            {this.selected('positions') && <Positions isFreePlan={isFreePlan(planType)} />}
            {this.selected('occupations') && <Occupations isFreePlan={isFreePlan(planType)} />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  planType: _.get(state, 'currentClient.item.data.planType')
}))(SettingClientDepartment);
