import React from 'react';

import { reduxForm } from 'redux-form';
import { Section, TextField, CheckboxField, BoxDouble } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { ErrorsSection, TelField, Address } from 'src/components';
import { isEmail } from 'src/utils/CustomValidators';
import styles from './PaidPlanRegistrationForm.scss';

const PaidPlanRegistrationForm = ({ handleSubmit, pristine, submitting, errors }) => (
  <form onSubmit={handleSubmit}>
    <ErrorsSection errors={errors} />
    <Section title="アカウント情報の入力" className="u-mt20">
      <TextField name="userEmail" label="メールアドレス" required validate={isEmail} maxLength="255" />
      <TextField name="userPassword" label="パスワード" type="password" required />
      <TextField name="userPasswordConfirmation" label="パスワード(確認用)" type="password" required />
    </Section>

    <Section title="請求先情報の入力" className="u-mt20">
      <TextField name="clientName" label="事業所名" required maxLength="255" />
      <TextField name="paidPlanApplicationGroup" label="部署名" />

      <BoxDouble>
        <TextField name="userLastName" label="担当者姓" required maxLength="32" />
        <TextField name="userFirstName" label="担当者名" required maxLength="32" />
      </BoxDouble>

      <Address required prefix="billInfo_" withKana={false} />
      <TelField required />

      <CheckboxField
        name="agree_to_tos"
        label="利用規約・プライバシーポリシー・個人情報の取り扱い"
        labelContent={
          <span>
            &nbsp;<a
              className={styles['u-text-link']}
              href="https://all.jobcan.ne.jp/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              ジョブカン基本規約
            </a>および
            <a
              className={styles['u-text-link']}
              href="https://payroll.jobcan.ne.jp/terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              ジョブカン給与計算利用規約
            </a>、
            <a
              className={styles['u-text-link']}
              href="https://all.jobcan.ne.jp/policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              プライバシーポリシー
            </a>、
            <a
              className={styles['u-text-link']}
              href="https://all.jobcan.ne.jp/personalinfo/"
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報の取り扱い
            </a>に同意します
          </span>
        }
      />
      <div className="u-ta-c u-mt30">
        <Button onClick={handleSubmit} primary disabled={pristine || submitting}>
          申し込む
        </Button>
      </div>
    </Section>
  </form>
);

const registrationValidate = values => {
  const errors = {};
  if (
    values.user_password &&
    values.user_password_confirmation &&
    values.user_password !== values.user_password_confirmation
  ) {
    errors.user_password_confirmation = 'が一致しません';
  }
  if (values.agree_to_tos !== true) {
    errors.agree_to_tos = 'に同意してください';
  }
  return errors;
};

export default reduxForm({
  form: 'registrationPaidPlanForm',
  validate: registrationValidate,
  enableReinitialize: true
})(PaidPlanRegistrationForm);
