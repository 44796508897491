import React from 'react';
import SettingPasswordForm from './components/SettingPasswordForm';

const ChangePasswordPage = () => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">パスワード変更</h1>
    </div>
    <div>
      <SettingPasswordForm />
    </div>
  </div>
);

export default ChangePasswordPage;
