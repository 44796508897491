import React from 'react';
import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { setItem } from 'src/reducers/common/itemReducer';
import SettingSideBar from './components/SettingSideBar';
import SimpleProfileShow from './components/SimpleProfileShow';

const EditProfile = ({ dispatch, employee }) => {
  dispatch(setItem(REDUCER_NAME, { employee }));

  return (
    <div className="l-wrap-l">
      <h2 className="m-title-sub">プロフィール</h2>
      <div className="l-flex">
        <div className="l-flex3-sub">
          <SettingSideBar />
        </div>
        <div className="l-flex3-main">
          <SimpleProfileShow employee={employee} />
        </div>
      </div>
    </div>
  );
};

export default connect()(EditProfile);
