import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { getSelectOptions } from 'src/reducers/selectOptions';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import { POLICY_FORM } from 'src/constants/FormNames';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import { changeCheckedUserPolicy, getList } from 'src/reducers/users/roleUsers';
import styles from './ChangePolicyModal.scss';

const RawForm = ({ handleSubmit, submitting, submit, cancelBtnClick, clientRoles, numberOfUser, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <div className={styles.lead}>選択された{numberOfUser}個のアカウントの権限を以下のように変更します。</div>
        <div className={styles.selectWrap}>
          <ExtensionSelectField name="clientRoleId" label="権限" options={clientRoles} required />
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="変更" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let PolicyForm = reduxForm({
  form: POLICY_FORM
})(RawForm);

const mapStateToProps = state => ({
  clientRoles: getSelectOptions(state, 'clientRoles'),
  numberOfUser: _.get(_.filter(getList(state), { checked: true }), 'length')
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: data => dispatch(changeCheckedUserPolicy(data, ownProps))
});

PolicyForm = connect(mapStateToProps, mapDispatchToProp)(PolicyForm);

const ChangePolicyModal = ({ isOpen, hideModal }) => {
  const header = 'アカウント権限一括変更';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <PolicyForm cancelBtnClick={hideModal} header={header} />
    </CommonModal>
  );
};

export default connect()(ChangePolicyModal);
