import React from 'react';

import { formatDate } from 'src/utils/Date';

import styles from './TargetDetailPeriod.scss';

const TargetDetailPeriod = ({ attendanceStartDate, attendanceEndDate, paymentDate }) => (
  <div className={styles.targetPeriod}>
    <div>
      対象期間 {formatDate(attendanceStartDate)} ~ {formatDate(attendanceEndDate)}
    </div>
    <div className={styles.paymentDate}>支給日 {formatDate(paymentDate)}</div>
  </div>
);

export default TargetDetailPeriod;
