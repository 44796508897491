import React from 'react';
import { connect } from 'react-redux';

import { EMPLOYEE_ATTENDANCE_LIST } from 'src/constants/EndpointUrls';
import { formatDate, formatYearMonth } from 'src/utils/Date';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
// Reducer
import {
  setEmployeeAttendancesQueries,
  setEmployeeAttendancesListExtra
} from 'src/reducers/employees/employeeAttendances';
// Form
import EmployeeAttendanceDownloadCsvForm from './components/EmployeeAttendanceDownloadCsvForm';
import DownloadColumnDetail from './components/DownloadColumnDetail';
import AttendanceSyncWithJbcResult from './components/AttendanceSyncWithJbcResult';
import ImportAttendanceCsvJobStatus from './components/ImportAttendanceCsvJobStatus';
import styles from './EmployeeAttendanceDownloadCsvPage.scss';

const ConnectedDownloadCsv = ({ startDate, endDate, attendanceStartDate, attendanceEndDate, paymentDate }) => (
  <div>
    <div className="l-wrap-xs l-contents-wrap">
      <BreadCrumb>
        <BreadCrumb.Item label="勤怠一覧" path={EMPLOYEE_ATTENDANCE_LIST} />
        <BreadCrumb.Item label="勤怠項目CSVアップロード" />
      </BreadCrumb>
    </div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">更新：勤怠情報</h1>
    </div>
    <AttendanceSyncWithJbcResult />
    <ImportAttendanceCsvJobStatus />
    <div className="l-wrap-xs">
      <p>
        以下の月の給与に使用する勤怠項目の数値をCSVファイルで一括更新する事ができます。<br />ジョブカン勤怠管理以外のソフトからエクスポートした勤怠情報を取り込む場合は、勤怠項目名に注意してください。
      </p>
      <div className={styles.target}>
        {formatYearMonth(paymentDate)}
        <div className={styles.period}>
          <span>
            対象期間：{formatDate(attendanceStartDate)}〜{formatDate(attendanceEndDate)}
          </span>
          <span>支給日：{formatDate(paymentDate)}</span>
        </div>
      </div>
    </div>
    <EmployeeAttendanceDownloadCsvForm
      jobInfo={`対象期間：${formatDate(startDate)}〜${formatDate(endDate)} 支給日：${formatDate(paymentDate)}`}
    />
    <div className={styles.detail}>
      <DownloadColumnDetail />
    </div>
  </div>
);

const EmployeeAttendanceDownloadCsvPage = ({ dispatch, values }) => {
  dispatch(setEmployeeAttendancesQueries(values.queries));
  dispatch(setEmployeeAttendancesListExtra({ importJobs: values.importJobs }));

  return <ConnectedDownloadCsv {...values.targetPeriod} />;
};
export default connect()(EmployeeAttendanceDownloadCsvPage);
