import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { REDUCER_NAME, fetchClientAnnounces, getClientAnnounces } from 'src/reducers/client_announces/client_announces';
import {
  getListPageCount,
  getListPage,
  getListPerPage,
  addPageQuery,
  addPerPageQuery,
  getListTotalCount,
  getListDisplayFrom,
  getListDisplayTo,
  getIsLoading
} from 'src/reducers/common/listReducer';
import LoadingView from 'src/components/LoadingView';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { LowerPager } from 'src/components';
import ListGroup from 'jbc-front/components/ListGroup';
import { recordDisplay } from 'src/utils/Utils';
import styles from './ClientAnnounceList.scss';

const ListGroupItem = ({ clientAnnounce }) => {
  let item = null;
  if (clientAnnounce.downloadLink) {
    // 給与CSVの場合は、直接ダウンロードせず、csvインポートページへ遷移する
    const href =
      clientAnnounce.announceableType === 'EmployeePayDownloadCsvJob'
        ? clientAnnounce.link
        : clientAnnounce.downloadLink;
    item = (
      <ListGroup.Item className={styles.list} as="div" key={clientAnnounce.id} href={clientAnnounce.link}>
        <span className={styles.date}>{recordDisplay.dateOrTime(clientAnnounce.excuteDate)}</span>
        <div className={styles.title}>
          {clientAnnounce.title}
          <br />
          <div className={styles.downloadLink}>
            <a href={href}>コチラ</a>からダウンロードできます
          </div>
        </div>
      </ListGroup.Item>
    );
  } else {
    item = (
      <ListGroup.Item as="a" className={styles.list} key={clientAnnounce.id} href={clientAnnounce.link}>
        <span className={styles.date}>{recordDisplay.dateOrTime(clientAnnounce.excuteDate)}</span>
        <div className={styles.title}>{clientAnnounce.title}</div>
        <ListGroup.Icon />
      </ListGroup.Item>
    );
  }
  return item;
};

const ShowListGroup = ({ clientAnnounces }) => (
  <ListGroup>
    <ListGroup.Title>通知一覧</ListGroup.Title>
    {get(clientAnnounces, 'length') > 0 ? (
      clientAnnounces.map(clientAnnounce => <ListGroupItem key={clientAnnounce.id} clientAnnounce={clientAnnounce} />)
    ) : (
      <ListGroup.Item className={styles.empty}>通知はまだありません</ListGroup.Item>
    )}
  </ListGroup>
);

class ClientAnnounceList extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const {
      onChangePerPage,
      onPageChange,
      pageCount,
      initialPage,
      perPage,
      perPages,
      totalCount,
      displayFrom,
      displayTo,
      clientAnnounces,
      isListLoading
    } = this.props;
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">通知一覧</h1>
        </div>
        <LoadingView isLoading={isListLoading}>
          <div className="l-wrap-s">
            <ShowListGroup clientAnnounces={clientAnnounces} />
            <LowerPager
              pageCount={pageCount}
              totalCount={totalCount}
              displayFrom={displayFrom}
              displayTo={displayTo}
              initialPage={initialPage - 1}
              forcePage={initialPage - 1}
              perPage={perPage}
              perPages={perPages}
              onPageChange={e => onPageChange(e.selected)}
              onPerPageChange={e => onChangePerPage(e.target.value)}
            />
          </div>
        </LoadingView>
      </div>
    );
  }
}

export default connect(
  state => ({
    pageCount: getListPageCount(REDUCER_NAME, state),
    totalCount: getListTotalCount(REDUCER_NAME, state),
    displayFrom: getListDisplayFrom(REDUCER_NAME, state),
    displayTo: getListDisplayTo(REDUCER_NAME, state),
    initialPage: getListPage(REDUCER_NAME, state),
    perPage: getListPerPage(REDUCER_NAME, state),
    perPages: getSelectOptions(state, 'perPages'),
    clientAnnounces: getClientAnnounces(state) || [],
    isListLoading: getIsLoading(REDUCER_NAME, state)
  }),
  dispatch => ({
    fetch: () => {
      dispatch(fetchClientAnnounces());
    },
    onPageChange: query => {
      dispatch(addPageQuery(REDUCER_NAME, query + 1));
      dispatch(fetchClientAnnounces());
    },
    onChangePerPage: query => {
      dispatch(addPageQuery(REDUCER_NAME, 1));
      dispatch(addPerPageQuery(REDUCER_NAME, query));
      dispatch(fetchClientAnnounces());
    }
  })
)(ClientAnnounceList);
