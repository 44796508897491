import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { fetchPayReportsPdf, fetchPayReportsZip } from 'src/reducers/employees/employeePays';
import { PAY_REPORTS_DOWNLOAD_FORM } from 'src/constants/FormNames';
import PayReportsCreateModal from '../components/PayReportsDownloadModal';

const PayReportsCreateForm = reduxForm({
  form: PAY_REPORTS_DOWNLOAD_FORM
})(PayReportsCreateModal);

export default connect(
  (state, ownProps) => ({
    isOpen: ownProps.isOpen,
    hideModal: ownProps.hideModal
  }),
  (dispatch, ownProps) => ({
    downloadPdf: () => {
      dispatch(fetchPayReportsPdf(ownProps.downloadId));
    },
    downloadZip: () => {
      dispatch(fetchPayReportsZip(ownProps.downloadId));
    }
  })
)(PayReportsCreateForm);
