import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import ActionButton from 'jbc-front/components/ActionButton';
import { Task } from 'jbc-front/components/icons';

import * as Urls from 'src/constants/EndpointUrls';
import {
  takeBonusesSearchQueries,
  takeBonusesSearchQueriesFromLocation,
  takeBonusFromSearchForm,
  fetchBonusOptions,
  FORM_NAME_BONUSES_SEARCH
} from 'src/reducers/searchForm';
import { fetchBonusFbData, getEmployeeBonusList, REDUCER_NAME } from 'src/reducers/employees/employeeBonus';
import { getExtras } from 'src/reducers/common/listReducer';
import BonusSelectBox from 'src/components/BonusSelectBox';
import SearchDetector from 'src/components/SearchDetector';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { serializeHttpGetParams } from 'src/utils/Http';
import DownloadFbCsvContainer from 'src/features/EmployeeBonus/components/DownloadFbCsvContainer';
import styles from 'src/features/EmployeeBonus/ShowBonusFbDataPage.scss';
import SortableTh from 'src/components/SortableTh';
import { formatDate } from 'src/utils/Date';

const lengthOf = employeePays => {
  if (!employeePays) {
    return 0;
  }
  return employeePays.reduce((sum, subArr) => sum + subArr.length, 0);
};

const EmployeeBonusFbTable = ({ employeeBonuses, totalTransferAmount }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list m-table-fixed">
      <thead>
        <tr>
          <SortableTh field="staffCode" globalClassName="m-table-list-staffcode" formName={FORM_NAME_BONUSES_SEARCH}>
            スタッフコード
          </SortableTh>
          <th width={140}>金融機関名</th>
          <th width={140}>支店名</th>
          <SortableTh width={140} field="name" formName={FORM_NAME_BONUSES_SEARCH}>
            氏名
          </SortableTh>
          <th width={90}>口座番号</th>
          <th width={180}>名義人</th>
          <th width={110}>振込額</th>
        </tr>
      </thead>
      <tbody>
        {employeeBonuses.map(payments =>
          payments.map(payment => (
            <tr key={payment.id}>
              <td>{payment.staffCode}</td>
              <td>{payment.bankName}</td>
              <td>{payment.branchName}</td>
              <td>{payment.employeeFullName}</td>
              <td>{payment.accountNumber}</td>
              <td>{payment.accountHolderKana}</td>
              <td>{payment.transferAmount}</td>
            </tr>
          ))
        )}
        <tr className={styles.total}>
          <td>合計</td>
          <td colSpan={4} />
          <td>{lengthOf(employeeBonuses)}件</td>
          <td>{totalTransferAmount}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

class ShowBonusFbDataPage extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchBonusOptions(true));
  }

  state = {
    isPdfPreview: false
  };

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };

  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };

  _downloadPdf = () => {
    const { clientBonus, formValues = {} } = this.props;
    const { sort = 'staffCode', order = 'asc' } = formValues;
    return `${Urls.DOWNLOAD_BONUS_FB_PDF}?${serializeHttpGetParams({ clientBonusId: clientBonus.value, sort, order })}`;
  };

  get bonusBlank() {
    return this.props.employeeBonuses.length === 0;
  }

  render() {
    const { clientBonus, employeeBonuses, totalTransferAmount, formValues, location, dispatch } = this.props;
    return (
      <div>
        <SearchDetector
          location={location}
          clientBonusId={_.get(formValues, 'clientBonusId')}
          willReceivePropsDetectingJudger={(prevProps, nextProps) =>
            nextProps.clientBonusId !== undefined &&
            (prevProps.clientBonusId === undefined || nextProps.location !== prevProps.location)
          }
          onDetect={() => {
            dispatch(fetchBonusFbData(takeBonusesSearchQueries(formValues)));
          }}
        />
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="賞与一覧" path={Urls.EMPLOYEE_BONUS_LIST} />
              <BreadCrumb.Item label="賞与振込一覧表" />
            </BreadCrumb>
          </div>
          <h1 className="m-title-main">賞与振込一覧表</h1>
          <p className="m-title-main-note">賞与振込前に、対象の従業員の振込先口座や金額を確認できます。</p>

          <div className={styles.head}>
            <div className={styles.select}>
              <BonusSelectBox />

              <div className={styles.datespan}>
                {(clientBonus.applyForTimeFrom || clientBonus.applyForTimeTo) && (
                  <span>
                    対象期間： {formatDate(clientBonus.applyForTimeFrom)} ~ {formatDate(clientBonus.applyForTimeTo)}
                  </span>
                )}
                <span className="u-ml20">支給日： {formatDate(clientBonus.payOn)}</span>
              </div>
            </div>
            <div className={styles.action}>
              <ActionButton
                primary
                onClick={this.showPreview}
                disabled={this.bonusBlank}
                icon={<Task size={15} />}
                className="u-mr10"
              >
                PDF出力
              </ActionButton>
              <DownloadFbCsvContainer
                clientBonus={clientBonus}
                disabled={this.bonusBlank}
                queries={takeBonusesSearchQueries(formValues)}
              />
              <PreviewPdfModalContainer
                header="賞与振込一覧表"
                isOpen={this.state.isPdfPreview}
                hideModal={this.hidePreview}
                url={this._downloadPdf()}
              />
            </div>
          </div>

          <EmployeeBonusFbTable {...{ employeeBonuses, totalTransferAmount }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  formValues: getFormValues(FORM_NAME_BONUSES_SEARCH)(state),
  initialValues: {
    ...getFormValues(FORM_NAME_BONUSES_SEARCH)(state),
    ...takeBonusesSearchQueriesFromLocation(state.router.location)
  },
  location: state.router.location,
  clientBonus: takeBonusFromSearchForm(state) || {},
  employeeBonuses: getEmployeeBonusList(state) || [],
  totalTransferAmount: (getExtras(REDUCER_NAME, state) || {}).totalTransferAmount || 0
});
export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME_BONUSES_SEARCH,
    enableReinitialize: true
  })
)(ShowBonusFbDataPage);
