import React, { Component } from 'react';

import { formatDate } from 'src/utils/Date';
import { getOptionLabel, display } from 'src/utils/Utils';
import ShowMyNumberModalContainer from '../containers/ShowMyNumberModalContainer';

export default class EmployeeTableRowComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { isMyNumberModalOpen: false };

    this.openMyNumberModal = this.openMyNumberModal.bind(this);
    this.closeMyNumberModal = this.closeMyNumberModal.bind(this);
  }

  openMyNumberModal = employeeId => {
    const { initializeModal } = this.props;
    initializeModal(employeeId);
    this.setState({ ...this.state, isMyNumberModalOpen: true });
  };

  closeMyNumberModal = () => {
    const { destructModal } = this.props;
    destructModal();
    this.setState({ ...this.state, isMyNumberModalOpen: false });
  };

  render() {
    const { employee, enrollmentStatuses } = this.props;
    return (
      <React.Fragment>
        <tr onClick={() => this.openMyNumberModal(employee.id)}>
          <td>{employee.staffCode}</td>
          <td>{employee.displayName}</td>
          <td>{display(getOptionLabel(employee.enrollmentStatus)(enrollmentStatuses))}</td>
          <td>{formatDate(employee.joinedOn)}</td>
          <td>{employee.hasMyNumber ? '取得済' : '未取得'}</td>
        </tr>
        <ShowMyNumberModalContainer
          employee={employee}
          isOpen={this.state.isMyNumberModalOpen}
          hideModal={this.closeMyNumberModal}
        />
      </React.Fragment>
    );
  }
}
