import React from 'react';
import { connect } from 'react-redux';

import { INSERT_BATCH_CSV } from 'src/constants/EndpointUrls';
import { getSelectOptions } from 'src/reducers/selectOptions';

import AnyApiRunning from './components/AnyApiRunning';
import FileImportDescription from './components/FileImportDescription';
import CsvUpdateForm from './containers/EmployeeCsvUpdateFormContainer';
import styles from './styles/FileImport.scss';

const UpdateBatchCsv = ({ anyApiRunning }) => (
  <div>
    {anyApiRunning && <AnyApiRunning />}
    <div className="l-title-wrap">
      <h1 className="m-title-main">更新：従業員情報</h1>
      <p className="m-title-main-note">
        登録済みの従業員情報を一括で更新できます。<br />※ 新規で従業員情報を追加したい場合は<a
          href={INSERT_BATCH_CSV}
          className="u-txt-link"
        >
          こちら
        </a>
      </p>
    </div>
    <CsvUpdateForm />
    <div className={styles.description}>
      <FileImportDescription update />
    </div>
  </div>
);

const mapStateToProps = state => ({
  anyApiRunning: getSelectOptions(state, 'anyApiRunning')
});

export default connect(mapStateToProps)(UpdateBatchCsv);
