import React from 'react';
import { connect } from 'react-redux';
import remove from 'lodash/remove';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { setItem } from 'src/reducers/common/itemReducer';
import PayrollDetailShow from './components/payroll/PayrollDetailShow';

const removeItem = (allPayrolls, notDisplay0YenAllowances, notDisplayExcludeTotalAmountAllowances) => {
  if (!allPayrolls || (!notDisplay0YenAllowances && !notDisplayExcludeTotalAmountAllowances)) {
    return allPayrolls;
  }
  return allPayrolls.map(payroll => {
    // 0円の場合に表示しないもの
    remove(
      payroll.employeePayAllowances,
      item => item.value === 0 && notDisplay0YenAllowances.includes(item.clientAllowanceId)
    );
    // 「合計に含めない」にチェックがあり、「明細に記載する」にチェックがないもの
    remove(payroll.employeePayAllowances, item =>
      notDisplayExcludeTotalAmountAllowances.includes(item.clientAllowanceId)
    );
    return payroll;
  });
};

const PayrollDetail = ({ dispatch, values }) => {
  const { allPayrolls, notDisplay0YenAllowances, notDisplayExcludeTotalAmountAllowances, employeePayId } = values;
  const removedPayrolls = removeItem(allPayrolls, notDisplay0YenAllowances, notDisplayExcludeTotalAmountAllowances);
  dispatch(setItem(REDUCER_NAME, { allPayrolls: removedPayrolls }));

  return <PayrollDetailShow employeePayId={employeePayId} />;
};

export default connect()(PayrollDetail);
