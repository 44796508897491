import React from 'react';
import { connect } from 'react-redux';

import { setClientAllowanceItemList } from 'src/reducers/clients/allowanceItems';
import ClientAllowanceItemsComponent from './components/ClientAllowanceItemsComponent';

const ClientAllowanceItemList = ({ dispatch, clientAllowanceItems }) => {
  dispatch(setClientAllowanceItemList(clientAllowanceItems));
  return <ClientAllowanceItemsComponent />;
};

export default connect()(ClientAllowanceItemList);
