import React from 'react';
import { RadioField } from 'jbc-front/components/Form';
import styles from './DownloadCsvEncodingRadioButton.scss';

class DownloadCsvEncodingRadioButton extends React.Component {
  render() {
    return (
      <React.Fragment>
        <RadioField
          name={this.props.name}
          label={this.props.label}
          options={this.props.options}
          required={this.props.required}
        />
        <div className={styles.note}>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。</div>
      </React.Fragment>
    );
  }
}

export default DownloadCsvEncodingRadioButton;
