import React from 'react';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { RollbackActionBtn } from 'src/buttons';

import { TextField, CheckboxField } from 'jbc-front/components/Form';
import { ErrorsSection } from 'src/components';
import styles from './EditBonusModal.scss';

const EditBonusModal = props => {
  const { handleSubmit, errors, submitting, submit, isOpen, hideModal, bonusId, staffName, onRollback } = props;

  return (
    <CommonModal className={styles.modal} ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>{`${staffName}の賞与 編集`}</CommonModal.Header>
      <CommonModal.Body>
        <ErrorsSection errors={errors} />
        <div className={styles.reset}>
          <RollbackActionBtn onClick={() => onRollback(bonusId)} label="手入力前に戻す" />
        </div>
        <form onSubmit={handleSubmit} className={styles.modalForm}>
          <div className={styles.amountBox}>
            <p className={styles.name}>通貨による額</p>
            <TextField name="currencyAmount" maxLength="9" />
            <p className={styles.yen}>円</p>
          </div>
          <div className={styles.amountBox}>
            <p className={styles.name}>現物による額</p>
            <TextField name="genbutsuAmount" maxLength="9" />
            <p className={styles.yen}>円</p>
          </div>
          <CheckboxField name="isOver70YearsOld" label="70歳以上被用者" width={10} />
          <div className={styles.twoBox}>
            <CheckboxField name="isWorkInTwoOrMore" label="二以上勤務" width={10} />
          </div>
          <div className={styles.dayBox}>
            <div className={styles.dayCheckBox}>
              <CheckboxField name="isAddUp" label="同一月内の賞与合算" width={10} />
            </div>
            <p className={styles.name}>初回</p>
            <TextField name="firstPaymentDay" maxLength="2" />
            <p className={styles.day}>日</p>
          </div>
          <div className={styles.buttonBox}>
            <Button onClick={hideModal} className="u-mr20">
              キャンセル
            </Button>
            <Button primary onClick={submit} disabled={submitting}>
              保存
            </Button>
          </div>
        </form>
      </CommonModal.Body>
    </CommonModal>
  );
};

export default EditBonusModal;
