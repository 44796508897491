import React from 'react';

const CheckboxFieldWithoutForm = ({ disabled, option, ...rest }) => (
  <label>
    <input type="checkbox" disabled={disabled} {...rest} className="m-checkbox-input" />
    <span className="m-checkbox-parts">{option}</span>
  </label>
);

export default CheckboxFieldWithoutForm;
