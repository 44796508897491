import React from 'react';
import { connect } from 'react-redux';

import { Alert } from 'jbc-front/components/icons';
import { EMPLOYEE_BONUS_EDIT } from 'src/constants/EndpointUrls';
import { redirectTo, detailUrlWithQueries, getIdsFromArr } from 'src/utils/Http';
import { getOptionLabel, isInTargetDateRange } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';
import MemoList from 'src/features/Memos';
import SortableTh from 'src/components/SortableTh';
import {
  getEmployeeBonusList,
  getEmployeeBonusQueries,
  getSelectingClientBonus
} from 'src/reducers/employees/employeeBonus';
import { FORM_NAME_BONUSES_SEARCH } from 'src/reducers/searchForm';
import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from './EmployeeBonusTable.scss';

const EmployeeBonusRow = ({ onClick, employeeBonusItem, clientEmploymentTypes, selectingClientBonus }) => {
  const { applyForTimeFrom, applyForTimeTo } = selectingClientBonus || {};
  const joinedOnStyle = isInTargetDateRange(applyForTimeFrom, applyForTimeTo, employeeBonusItem.joinedOn) ? (
    <span className={styles.icon}>
      <Alert size={16} />
      <span className={styles.baloon}>賞与計算の対象期間に入社した従業員です。</span>
    </span>
  ) : (
    ''
  );
  const resignedOnStyle = isInTargetDateRange(applyForTimeFrom, applyForTimeTo, employeeBonusItem.resignedOn) ? (
    <span className={styles.icon}>
      <Alert size={16} />
      <span className={styles.baloon}>賞与計算の対象期間に退職した従業員です。</span>
    </span>
  ) : (
    ''
  );

  return (
    <tr onClick={onClick} className="table-hover">
      <td>
        <MemoList employeeId={employeeBonusItem.employeeId} />
      </td>
      <td>{employeeBonusItem.staffCode}</td>
      <td>{employeeBonusItem.employeeDisplayName}</td>
      <td>{getOptionLabel(employeeBonusItem.employmentTypeId)(clientEmploymentTypes)}</td>
      <td>
        <span className={joinedOnStyle ? styles.dateWithIcon : styles.date}>
          {formatDate(employeeBonusItem.joinedOn)}
          {joinedOnStyle}
        </span>
      </td>
      <td>
        <span className={resignedOnStyle ? styles.dateWithIcon : styles.date}>
          {formatDate(employeeBonusItem.resignedOn)}
          {resignedOnStyle}
        </span>
      </td>
    </tr>
  );
};

const EmployeeBonusTable = props => {
  const { employeeBonusList, queries, selectingClientBonus, clientEmploymentTypes } = props;
  let onClickFlag = false;

  return (
    <div className="l-overflow-scroll">
      <table className="m-table-list">
        <thead>
          <tr>
            <th>メモ</th>
            <SortableTh field="staffCode" globalClassName="m-table-list-staffcode" formName={FORM_NAME_BONUSES_SEARCH}>
              スタッフコード
            </SortableTh>
            <SortableTh field="name" formName={FORM_NAME_BONUSES_SEARCH}>
              氏名
            </SortableTh>
            <SortableTh field="employmentTypeId" formName={FORM_NAME_BONUSES_SEARCH}>
              雇用形態
            </SortableTh>
            <SortableTh field="joinedOn" formName={FORM_NAME_BONUSES_SEARCH}>
              入社日
            </SortableTh>
            <SortableTh field="resignedOn" formName={FORM_NAME_BONUSES_SEARCH}>
              退職日
            </SortableTh>
          </tr>
        </thead>
        <tbody>
          {employeeBonusList &&
            employeeBonusList.length > 0 &&
            employeeBonusList.map(item => (
              <EmployeeBonusRow
                onClick={() => {
                  if (!onClickFlag) {
                    onClickFlag = true;
                    redirectTo(
                      detailUrlWithQueries(item.id, queries, EMPLOYEE_BONUS_EDIT, getIdsFromArr(employeeBonusList))
                    );
                  }
                }}
                key={item.id}
                employeeBonusItem={item}
                selectingClientBonus={selectingClientBonus}
                clientEmploymentTypes={clientEmploymentTypes}
              />
            ))}

          {(!employeeBonusList || employeeBonusList.length === 0) && (
            <tr>
              <td colSpan={6}>データがありません。</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default connect(state => ({
  employeeBonusList: getEmployeeBonusList(state),
  queries: getEmployeeBonusQueries(state),
  selectingClientBonus: getSelectingClientBonus(state),
  clientEmploymentTypes: getSelectOptions(state, 'employmentTypes')
}))(EmployeeBonusTable);
