import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { REDUCER_NAME } from 'src/reducers/clients/residentTaxPaymentAddrs';
import { setList } from 'src/reducers/common/editableListReducer';
import ResidentTaxPaymentAddrsForm from './components/ResidentTaxPaymentAddrsForm';

const ClientResidentTaxPaymentAddressesList = ({ dispatch, values }) => {
  dispatch(setList(REDUCER_NAME, { ...values }));

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">住民税納付先</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="住民税納付先" />
          </BreadCrumb>
        </div>
      </div>
      <ResidentTaxPaymentAddrsForm />
    </div>
  );
};

export default connect()(ClientResidentTaxPaymentAddressesList);
