import React from 'react';
import axios from 'axios';

import _ from 'lodash';
import { Close } from 'jbc-front/components/icons';
import Loading from 'src/components/Loading';
import { formatDateWithHour } from 'src/utils/Date';
import { NEW_LINE_CHARACTERS } from 'src/constants/Generals';
import { CONFIRM_MY_NUMBER_NOTIFY_JOB } from 'src/constants/EndpointUrls';
import styles from './NotifyJob.scss';

export default class NotifyJob extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showJobInfo: true
    };
    this.confirmNotifyJob = this.confirmNotifyJob.bind(this);
  }

  // reducersに移行したいが、画面がうまく更新されないためひとまずここに定義する
  async confirmNotifyJob() {
    const { job } = this.props;
    if (job) {
      try {
        await axios.post(CONFIRM_MY_NUMBER_NOTIFY_JOB, { id: job.id, status: job.status });
        this.setState({ ...this.state, showJobInfo: false });
      } catch (e) {
        console.log('error', e);
      }
    }
  }

  wrapClass(job) {
    let className = null;
    if (job.status === 'waiting' || job.status === 'running') {
      className = styles.waitWrap;
    } else if (job.status === 'success') {
      className = styles.successWrap;
    } else if (job.status === 'failed') {
      className = styles.errorWrap;
    }
    return className;
  }

  render() {
    const { job } = this.props;
    if (!this.state.showJobInfo) {
      return null;
    }

    return (
      <div className={`${this.wrapClass(job)}`}>
        {job.status === 'success' && (
          <React.Fragment>
            <div onClick={this.confirmNotifyJob} className={styles.close} role="presentation">
              <Close size={20} />
            </div>
            <div className={styles.inner}>
              <p className={styles.title}>CSVでのマイナンバーデータ更新が完了しました。</p>
              <div className={styles.dates}>
                <dl>
                  <dt>アップロード日時：</dt>
                  <dd>{formatDateWithHour(job.createdAt)}</dd>
                </dl>
                <dl>
                  <dt>完了日時：</dt>
                  <dd>{formatDateWithHour(job.endAt)}</dd>
                </dl>
              </div>
            </div>
          </React.Fragment>
        )}
        {job.status === 'failed' && (
          <React.Fragment>
            <div onClick={this.confirmNotifyJob} className={styles.close} role="presentation">
              <Close size={20} />
            </div>
            <div className={styles.inner}>
              <p className={styles.title}>CSVでマイナンバーデータの登録エラーが発生しました。</p>
              <div className={styles.dates}>
                <dl>
                  <dt>アップロード日時：</dt>
                  <dd>{formatDateWithHour(job.createdAt)}</dd>
                </dl>
                <dl>
                  <dt>エラー日時：</dt>
                  <dd>{formatDateWithHour(job.endAt)}</dd>
                </dl>
              </div>
              <ul className={styles.list}>
                {_.split(job.performErrors, NEW_LINE_CHARACTERS).map(error => <li key={error.id}>{error}</li>)}
              </ul>
            </div>
          </React.Fragment>
        )}
        {(job.status === 'waiting' || job.status === 'running') && (
          <div className={styles.inner}>
            <div className={styles.waiting}>
              <div className={styles.loading}>
                <Loading />
              </div>
              <div className={styles.info}>
                <p className={styles.title}>CSVでマイナンバーデータを登録中です。</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
