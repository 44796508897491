import React from 'react';
import _ from 'lodash';

import { numberWithComma } from 'src/utils/Utils';
import styles from './DeclarationFormPaymentTerm.scss';

class DeclarationFormPaymentTerm extends React.Component {
  constructor(props) {
    super(props);
    const { declarationForm } = props;
    this.state = { ...declarationForm };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  calculation = params => {
    let calcValues = this.calculationForFirstPeriod(params);
    calcValues = this.setValuesForThreePeriod(calcValues);

    this.setState({
      ...params,
      ...calcValues
    });

    return { ...params, ...calcValues };
  };

  // 1回で納付する場合の計算を行う
  calculationForFirstPeriod(params) {
    let calcResult = { ...params };

    // 概算保険料額
    calcResult = {
      ...calcResult,
      paymentTerm1stEstimateInsuranceAmount: calcResult.estimateInsuranceFeeAmountForLaborInsurance,
      paymentTerm2ndEstimateInsuranceAmount: '',
      paymentTerm3rdEstimateInsuranceAmount: ''
    };

    // 充当・返金額
    let appropriationRefundAmount;
    switch (calcResult.paymentApplyIntentionType) {
      case 'only_employment_insurance':
        appropriationRefundAmount = this.calcLaborInsuranceAppropriationAmountByOnlyEmploymentInsurance(calcResult);
        break;
      case 'only_general_contribution':
        appropriationRefundAmount = this.calcLaborInsuranceAppropriationAmountByOnlyGeneralContribution(calcResult);
        break;
      case 'both':
        appropriationRefundAmount = this.calcLaborInsuranceAppropriationAmountByBoth(calcResult);
        break;
      default:
        appropriationRefundAmount = this.calcLaborInsuranceAppropriationAmountByOnlyEmploymentInsurance(calcResult);
    }

    calcResult = {
      ...calcResult,
      paymentTerm1stLaborInsuranceAppropriationAmount: appropriationRefundAmount.laborInsuranceAppropriationAmount,
      paymentTerm1stGeneralContributionAppropriationAmount:
        appropriationRefundAmount.generalContributionAppropriationAmount,
      paymentTerm1stLaborInsuranceDificitAmount: calcResult.paymentDificitAmount,
      paymentTerm2ndLaborInsuranceAppropriationAmount: '',
      paymentTerm3rdLaborInsuranceAppropriationAmount: ''
    };

    // 今期労働保険料
    calcResult = {
      ...calcResult,
      paymentTerm1stLaborInsuranceAmount: this.calc1stInsuranceAmount(calcResult)
    };

    // 一般拠出金
    calcResult = {
      ...calcResult,
      paymentTerm1stGeneralContributionAmount: this.calcGeneralContributionAmount(calcResult)
    };

    // 納付額
    calcResult = {
      ...calcResult,
      paymentTerm1stPaymentAmount: this.calc1stPaymentAmount(calcResult),
      paymentTerm2ndPaymentAmount: '',
      paymentTerm3rdPaymentAmount: ''
    };

    return calcResult;
  }

  setValuesForThreePeriod(calcResult) {
    if (!calcResult.isPaymentInInstallmentsForEstimateInsuranceFee) return calcResult;

    const estimateInsuranceAmount = this.amountTrisection(calcResult.estimateInsuranceFeeAmountForLaborInsurance);
    const appropriationAmount = this.calcAppropriationAmount(calcResult, estimateInsuranceAmount);

    const res = {
      ...calcResult,
      paymentTerm1stEstimateInsuranceAmount: estimateInsuranceAmount.first,
      paymentTerm2ndEstimateInsuranceAmount: estimateInsuranceAmount.second,
      paymentTerm3rdEstimateInsuranceAmount: estimateInsuranceAmount.third,
      paymentTerm1stLaborInsuranceAppropriationAmount: appropriationAmount.first,
      paymentTerm1stGeneralContributionAppropriationAmount: appropriationAmount.general,
      paymentTerm1stLaborInsuranceDificitAmount: calcResult.paymentDificitAmount,
      paymentTerm2ndLaborInsuranceAppropriationAmount: appropriationAmount.second,
      paymentTerm3rdLaborInsuranceAppropriationAmount: appropriationAmount.third,
      paymentTerm2ndPaymentAmount: estimateInsuranceAmount.second - appropriationAmount.second,
      paymentTerm3rdPaymentAmount: estimateInsuranceAmount.third - appropriationAmount.third
    };
    res.paymentTerm1stLaborInsuranceAmount = this.calc1stInsuranceAmount(res);
    res.paymentTerm1stGeneralContributionAmount = this.calcGeneralContributionAmount(res);
    res.paymentTerm1stPaymentAmount = this.calc1stPaymentAmount(res);
    return res;
  }

  // 充当・不足・還付額を算出
  calc1stInsuranceAmount(calcResult) {
    return (
      calcResult.paymentTerm1stEstimateInsuranceAmount -
      calcResult.paymentTerm1stLaborInsuranceAppropriationAmount +
      calcResult.paymentTerm1stLaborInsuranceDificitAmount
    );
  }

  // 充当意思労働保険に充当
  calcLaborInsuranceAppropriationAmountByOnlyEmploymentInsurance(calcResult) {
    return {
      laborInsuranceAppropriationAmount: calcResult.paymentAppropriationAmount,
      generalContributionAppropriationAmount: 0
    };
  }

  // 充当意思一般拠出金に充当
  calcLaborInsuranceAppropriationAmountByOnlyGeneralContribution(calcResult) {
    return {
      laborInsuranceAppropriationAmount: 0,
      generalContributionAppropriationAmount: calcResult.paymentAppropriationAmount
    };
  }

  // 充当意思労働保険・一般拠出金に充当
  calcLaborInsuranceAppropriationAmountByBoth(calcResult) {
    const result = {
      laborInsuranceAppropriationAmount: '',
      generalContributionAppropriationAmount: ''
    };

    if (calcResult.estimateInsuranceFeeAmountForLaborInsurance > calcResult.paymentAppropriationAmount) {
      result.laborInsuranceAppropriationAmount = calcResult.paymentAppropriationAmount;
      result.generalContributionAppropriationAmount = 0;
    } else {
      result.laborInsuranceAppropriationAmount = calcResult.estimateInsuranceFeeAmountForLaborInsurance;
      result.generalContributionAppropriationAmount =
        calcResult.paymentAppropriationAmount - calcResult.estimateInsuranceFeeAmountForLaborInsurance;
    }

    return result;
  }

  // 一般拠出金
  calcGeneralContributionAmount(calcResult) {
    return (
      calcResult.confirmedInsuranceFeeAmountForGeneralContributeion -
      calcResult.paymentTerm1stGeneralContributionAppropriationAmount
    );
  }

  calc1stPaymentAmount(calcResult) {
    return calcResult.paymentTerm1stLaborInsuranceAmount + calcResult.paymentTerm1stGeneralContributionAmount;
  }

  calcAppropriationAmount(calcResult, estimateInsuranceAmount) {
    const result = {
      first: 0,
      general: 0,
      second: 0,
      third: 0
    };
    let diff = 0;

    switch (calcResult.paymentApplyIntentionType) {
      case 'only_employment_insurance':
        result.first =
          estimateInsuranceAmount.first < calcResult.paymentAppropriationAmount
            ? estimateInsuranceAmount.first
            : calcResult.paymentAppropriationAmount;
        diff = calcResult.paymentAppropriationAmount - result.first;
        if (diff > 0) {
          result.second = estimateInsuranceAmount.second < diff ? estimateInsuranceAmount.second : diff;
        }

        diff -= result.second;
        if (diff > 0) {
          result.third = diff;
        }
        break;
      case 'only_general_contribution':
        result.general =
          calcResult.confirmedInsuranceFeeAmountForGeneralContributeion < calcResult.paymentAppropriationAmount
            ? calcResult.confirmedInsuranceFeeAmountForGeneralContributeion
            : calcResult.paymentAppropriationAmount;
        break;
      case 'both':
        result.first =
          estimateInsuranceAmount.first < calcResult.paymentAppropriationAmount
            ? estimateInsuranceAmount.first
            : calcResult.paymentAppropriationAmount;
        diff = calcResult.paymentAppropriationAmount - result.first;
        if (diff <= 0) {
          break;
        }

        result.general =
          calcResult.confirmedInsuranceFeeAmountForGeneralContributeion < diff
            ? calcResult.confirmedInsuranceFeeAmountForGeneralContributeion
            : diff;
        diff -= result.general;
        if (diff <= 0) {
          break;
        }

        result.second = estimateInsuranceAmount.second < diff ? estimateInsuranceAmount.second : diff;
        diff -= result.second;
        if (diff <= 0) {
          break;
        }

        result.third = diff;
        break;
      default:
    }

    return result;
  }

  amountTrisection(value) {
    const oneThird = _.toInteger(value / 3, 10);
    if (Number.isNaN(oneThird)) {
      return {
        first: '',
        second: '',
        third: ''
      };
    }

    return {
      first: value - oneThird * 2,
      second: oneThird,
      third: oneThird
    };
  }

  render() {
    return (
      <div>
        <div>
          <p className="u-mb10">期別納付額</p>
          <div className="l-overflow-scroll">
            <table className={styles.table}>
              <colgroup>
                <col width={240} />
                <col width={180} />
                <col width={180} />
                <col width={180} />
                <col width={180} />
              </colgroup>
              <tbody>
                <tr>
                  <th rowSpan="4">全期または第1期</th>
                  <th>概算保険料額</th>
                  <th>労働保険料充当額</th>
                  <th>不足額</th>
                  <th className={styles.cellBlank} />
                </tr>
                <tr>
                  <td>
                    {numberWithComma(this.state.paymentTerm1stEstimateInsuranceAmount)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                  <td>
                    {numberWithComma(this.state.paymentTerm1stLaborInsuranceAppropriationAmount)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                  <td>
                    {numberWithComma(this.state.paymentTerm1stLaborInsuranceDificitAmount)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                  <td className={styles.cellBlank} />
                </tr>
                <tr>
                  <th>今期労働保険料</th>
                  <th>一般拠出金充当額</th>
                  <th>一般拠出金額</th>
                  <th>今期納付額</th>
                </tr>
                <tr>
                  <td>
                    {numberWithComma(this.state.paymentTerm1stLaborInsuranceAmount)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                  <td>
                    {numberWithComma(this.state.paymentTerm1stGeneralContributionAppropriationAmount)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                  <td>
                    {numberWithComma(this.state.paymentTerm1stGeneralContributionAmount)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                  <td>
                    {numberWithComma(this.state.paymentTerm1stPaymentAmount)}{' '}
                    <span className={styles.currency}>円</span>
                  </td>
                </tr>
                <tr>
                  <th rowSpan="2">第2期</th>
                  <th>概算保険料額</th>
                  <th>労働保険料充当額</th>
                  <th>第2期納付額</th>
                  <th className={styles.cellBlank} />
                </tr>
                <tr>
                  <td>
                    {this.state.isPaymentInInstallmentsForEstimateInsuranceFee && (
                      <React.Fragment>
                        {numberWithComma(this.state.paymentTerm2ndEstimateInsuranceAmount)}{' '}
                        <span className={styles.currency}>円</span>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {this.state.isPaymentInInstallmentsForEstimateInsuranceFee && (
                      <React.Fragment>
                        {numberWithComma(this.state.paymentTerm2ndLaborInsuranceAppropriationAmount)}{' '}
                        <span className={styles.currency}>円</span>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {this.state.isPaymentInInstallmentsForEstimateInsuranceFee && (
                      <React.Fragment>
                        {numberWithComma(this.state.paymentTerm2ndPaymentAmount)}{' '}
                        <span className={styles.currency}>円</span>
                      </React.Fragment>
                    )}
                  </td>
                  <td className={styles.cellBlank} />
                </tr>
                <tr>
                  <th rowSpan="2">第3期</th>
                  <th>概算保険料額</th>
                  <th>労働保険料充当額</th>
                  <th>第3期納付額</th>
                  <th className={styles.cellBlank} />
                </tr>
                <tr>
                  <td>
                    {this.state.isPaymentInInstallmentsForEstimateInsuranceFee && (
                      <React.Fragment>
                        {numberWithComma(this.state.paymentTerm3rdEstimateInsuranceAmount)}{' '}
                        <span className={styles.currency}>円</span>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {this.state.isPaymentInInstallmentsForEstimateInsuranceFee && (
                      <React.Fragment>
                        {numberWithComma(this.state.paymentTerm3rdLaborInsuranceAppropriationAmount)}{' '}
                        <span className={styles.currency}>円</span>
                      </React.Fragment>
                    )}
                  </td>
                  <td>
                    {this.state.isPaymentInInstallmentsForEstimateInsuranceFee && (
                      <React.Fragment>
                        {numberWithComma(this.state.paymentTerm3rdPaymentAmount)}{' '}
                        <span className={styles.currency}>円</span>
                      </React.Fragment>
                    )}
                  </td>
                  <td className={styles.cellBlank} />
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default DeclarationFormPaymentTerm;
