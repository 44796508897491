import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import { LMS_EMPLOYEE_IMPORT_JOB_CONFIRM } from 'src/constants/EndpointUrls';
import { Close } from 'jbc-front/components/icons';
import Loading from 'src/components/Loading';
import { formatDateWithHour } from 'src/utils/Date';
import { NEW_LINE_CHARACTERS } from 'src/constants/Generals';
import styles from './NotifyJob.scss';

export default class NotifyJob extends React.Component {
  state = {
    showJobInfo: true
  };
  confirmNotifyJob = () => {
    const { job } = this.props;
    if (job) {
      axios
        .post(LMS_EMPLOYEE_IMPORT_JOB_CONFIRM, { id: job.id, status: job.status })
        .then(() => {
          this.setState({ ...this.state, showJobInfo: false });
        })
        .catch(error => {
          console.log(error);
        });
    }
  };
  render() {
    const { job } = this.props;

    let wrapClass;
    if (job.status === 'waiting' || job.status === 'in_progress') {
      wrapClass = styles.waitWrap;
    }
    if (job.status === 'success') {
      wrapClass = styles.successWrap;
    }
    if (job.status === 'failed' || job.status === 'partial_success') {
      wrapClass = styles.errorWrap;
    }
    if (!this.state.showJobInfo) {
      return null;
    }
    let serviceName;
    if (job.importFrom === 'lms') {
      serviceName = '労務HR';
    } else if (job.importFrom === 'yea') {
      serviceName = '年末調整';
    }
    return (
      <div className={`${wrapClass}`}>
        {job.status === 'success' && (
          <React.Fragment>
            <div onClick={this.confirmNotifyJob} className={styles.close} role="presentation">
              <Close size={20} />
            </div>
            <div className={styles.inner}>
              <p className={styles.title}>ジョブカン{serviceName}からの従業員データインポートが完了しました。</p>
              <div className={styles.dates}>
                <dl>
                  <dt>開始日時：</dt>
                  <dd>{formatDateWithHour(job.createdAt)}</dd>
                </dl>
                <dl>
                  <dt>完了日時：</dt>
                  <dd>{formatDateWithHour(job.endedAt)}</dd>
                </dl>
              </div>
            </div>
          </React.Fragment>
        )}
        {(job.status === 'failed' || job.status === 'partial_success') && (
          <React.Fragment>
            <div onClick={this.confirmNotifyJob} className={styles.close} role="presentation">
              <Close size={20} />
            </div>
            <div className={styles.inner}>
              <p className={styles.title}>
                ジョブカン{serviceName}からの従業員データインポートエラーが発生しました。
                {job.status === 'partial_success'
                  ? 'なお、以下にエラー表示のない従業員は、正常に登録されています。'
                  : ''}
              </p>
              <div className={styles.dates}>
                <dl>
                  <dt>開始日時：</dt>
                  <dd>{formatDateWithHour(job.createdAt)}</dd>
                </dl>
                <dl>
                  <dt>エラー日時：</dt>
                  <dd>{formatDateWithHour(job.endedAt)}</dd>
                </dl>
              </div>
              <ul className={styles.list}>
                {_.split(job.error, NEW_LINE_CHARACTERS).map(error => <li key={error.id}>{error}</li>)}
              </ul>
            </div>
          </React.Fragment>
        )}
        {(job.status === 'waiting' || job.status === 'in_progress') && (
          <div className={styles.inner}>
            <div className={styles.waiting}>
              <div className={styles.loading}>
                <Loading />
              </div>
              <div className={styles.info}>
                <p className={styles.title}>ジョブカン{serviceName}から従業員データをインポート中です。</p>
                <p className="u-mt10">インポート中は従業員情報の新規登録、更新はできません。</p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
