import axios from 'axios';
import qs from 'qs';
import humps from 'humps';
import _ from 'lodash';
import { createAction } from 'redux-actions';
import { FETCH_JOB_STATUS, FETCH_LATEST_JOB_STATUS, FETCH_PARENT_JOB_STATUS } from 'src/constants/EndpointUrls';
import { setGlobalErrors } from 'src/reducers/global';

const SET_STATUS = 'SET_STATUS/SET_STATUS';
export const setStatus = createAction(SET_STATUS);

export const REPORT_BONUS_JOB_NAME = 'reportEmployeeBonusJob';
export const REDUCER_NAME = 'jobStatus';
export const isJobSuccess = jobState => jobState === 'success';
export const isJobIncomplete = jobState => ['waiting', 'in_progress'].includes(jobState);
export const existIncompleteJob = jobs => (!_.isEmpty(jobs) && jobs.some(job => isJobIncomplete(job.state))) || {};
export const getJob = state => _.get(state, 'JobStatus');

export const fetchJobStatus = searchParams => async (dispatch, getState) => {
  try {
    const query = qs.stringify(humps.decamelizeKeys(searchParams), { arrayFormat: 'brackets' });
    const state = getState();
    const jobStatusHash = state.JobStatus;
    await axios
      .get(`${FETCH_JOB_STATUS}?${query}`)
      .then(response => {
        jobStatusHash[searchParams.job_name] = response.data.payload.jobStatus;
        dispatch(setStatus(jobStatusHash));
      })
      .catch(response => {
        // ステータスが不明なので、ステータスを期待する各処理が動作しないように無効な値をセットする
        jobStatusHash[searchParams.job_name] = {};
        dispatch(setStatus(jobStatusHash));
        if (response.response.status !== 404) {
          dispatch(setGlobalErrors('エラーが発生しました。'));
        }
      });
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。'));
  }
};

export const fetchLatestJobStatus = searchParams => async (dispatch, getState) => {
  try {
    const query = qs.stringify(humps.decamelizeKeys(searchParams), { arrayFormat: 'brackets' });
    const state = getState();
    const jobStatusHash = state.JobStatus;
    await axios
      .get(`${FETCH_LATEST_JOB_STATUS}?${query}`)
      .then(response => {
        jobStatusHash[searchParams.job_name] = response.data.payload.jobStatus;
        dispatch(setStatus(jobStatusHash));
      })
      .catch(response => {
        // ステータスが不明なので、ステータスを期待する各処理が動作しないように無効な値をセットする
        jobStatusHash[searchParams.job_name] = {};
        dispatch(setStatus(jobStatusHash));
        if (response.response.status !== 404) {
          dispatch(setGlobalErrors('エラーが発生しました。'));
        }
      });
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。'));
  }
};

export const fetchParentJobStatus = searchParams => async (dispatch, getState) => {
  try {
    const query = qs.stringify(humps.decamelizeKeys(searchParams), { arrayFormat: 'brackets' });
    const jobStatusHash = getState().JobStatus;
    await axios
      .get(`${FETCH_PARENT_JOB_STATUS}?${query}`)
      .then(response => {
        jobStatusHash[searchParams.job_name] = response.data.payload.jobStatus;
        dispatch(setStatus(jobStatusHash));
      })
      .catch(response => {
        // ステータスが不明なので、ステータスを期待する各処理が動作しないように無効な値をセットする
        jobStatusHash[searchParams.job_name] = {};
        dispatch(setStatus(jobStatusHash));
        if (response.response.status !== 404) {
          dispatch(setGlobalErrors('エラーが発生しました。'));
        }
      });
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。'));
  }
};

export const INITIAL_STATE = {
  reportEmployeeBonusJob: {},
  export_employee_data_job: {},
  confirmClientBonusJob: {},
  exportWageLedgerJob: {},
  importEmployeeCsvJobStatus: {},
  importLaborInsuranceEmployeeDetailsCsvJob: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STATUS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
