import React from 'react';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { REPORT_INDEX } from 'src/constants/EndpointUrls';
import ShowAllowanceDeductionFormConnectionContainer from '../containers/ShowAllowanceDeductionFormConnectionContainer';

class ShowAllowanceDeductions extends React.Component {
  componentDidMount() {
    const { allGroupsAndSubgroups, csvAllowanceDeductionFormats, uniquePayrollRuleGroups } = this.props;
    allGroupsAndSubgroups();
    csvAllowanceDeductionFormats();
    uniquePayrollRuleGroups();
  }

  render() {
    const { match } = this.props;

    return (
      <div className="l-wrap-xl l-contents-wrap">
        <BreadCrumb>
          <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
          <BreadCrumb.Item label="支給・控除等一覧表" />
        </BreadCrumb>
        <div className="l-title-wrap">
          <h1 className="m-title-main">支給・控除等一覧表</h1>
          <p className="m-title-main-note">
            月ごとの勤怠項目・支給項目・控除項目・事業主負担分などの値をまとめて確認できます。<br />
            出力したい項目と従業員（グループ）のフォーマットをあらかじめ設定してください。<br />
            ※従業員の所属するグループについては給与確定時のグループで出力されます。
          </p>
        </div>
        <ShowAllowanceDeductionFormConnectionContainer csvType={'pay'} back={match.path} />
      </div>
    );
  }
}

export default ShowAllowanceDeductions;
