import React from 'react';
import Button from 'jbc-front/components/Button';
import { FileField } from 'jbc-front/components/Form';
import { Upload } from 'jbc-front/components/icons';
import { ErrorsSection } from 'src/components';
import { EXPORT_TEMPLATE_CLIENT_GROUPS_URL } from 'src/constants/EndpointUrls';
import { GROUP_DISABLED_REASON } from 'src/reducers/clients/groups';
import ImportCsvJobStatus from 'src/features/Groups/components/ImportCsvJobStatus';
import FileImportDescription from './FileImportDescription';
import styles from './InsertBatchGroupCsv.scss';

const InsertBatchGroupCsv = ({ handleSubmit, submitting, errors, importing }) => (
  <div>
    <div className="l-title-wrap u-sp-pb60">
      <h1 className="m-title-main">グループ情報の登録</h1>
    </div>
    <div className={styles.jobStatus}>
      <ImportCsvJobStatus />
    </div>
    <div className={styles.wrap}>
      <div className={styles.head}>新規に登録したいグループ情報を記載したファイルを選択してください</div>
      <div className={`u-sp-mb30 ${styles.body}`}>
        <form onSubmit={handleSubmit}>
          <ErrorsSection errors={errors} />
          <div className={styles.box}>
            <p className={styles.text}>
              対応しているファイル形式はCSVとなります<br />
              <a href={EXPORT_TEMPLATE_CLIENT_GROUPS_URL} className={styles.link}>
                サンプルをダウンロード
              </a>
            </p>
            <FileField label="グループのデータファイル" name="groups_csv" className={styles.input} required />
            <Button
              as="button"
              primary
              type="submit"
              disabled={submitting || importing}
              disabledReason={GROUP_DISABLED_REASON}
              icon={<Upload size={16} />}
              widthWide
            >
              アップロード
            </Button>
          </div>
        </form>
      </div>
    </div>
    <FileImportDescription update={false} />
  </div>
);

export default InsertBatchGroupCsv;
