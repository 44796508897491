import { autofill as autofillForm } from 'redux-form';
import { connect } from 'react-redux';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { fetchStandardMonthlyAmounts } from 'src/reducers/employees/employees';
import { getSelectOptions } from 'src/reducers/selectOptions';
import StandardMonthlyAmountTable from '../components/StandardMonthlyAmountTable';

const mapStateToProps = state => ({
  healthStandardMonthlyAmounts: getSelectOptions(state, 'healthStandardMonthlyAmounts'),
  pensionStandardMonthlyAmounts: getSelectOptions(state, 'pensionStandardMonthlyAmounts')
});

const mapDispatchToProps = dispatch => ({
  autofill: (fieldName, fieldValue) => dispatch(autofillForm(EMPLOYEE_FORM, fieldName, fieldValue)),
  standardMonthlyAmounts: selectingTimeValue => dispatch(fetchStandardMonthlyAmounts(selectingTimeValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(StandardMonthlyAmountTable);
