import React from 'react';
import { Field } from 'redux-form';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { arrowRenderer } from 'jbc-front/components/Form';
import styles from './SelectBoxYear.scss';

class SelectBoxYear extends React.Component {
  handleYearChange = (event, newValue) => {
    const { changeYear, wageLedgerYearMonths } = this.props;
    changeYear(newValue, wageLedgerYearMonths);
  };

  render() {
    const { wageLedgerYears } = this.props;
    return (
      <div className={styles.container}>
        <Field
          name="year"
          component={SearchSelectBox}
          options={wageLedgerYears}
          arrowRenderer={arrowRenderer}
          onChange={this.handleYearChange}
        />
      </div>
    );
  }
}
export default SelectBoxYear;
