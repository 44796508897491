import React from 'react';
import { connect } from 'react-redux';
import { REDUCER_NAME, fetchMemosWithEmployeeId, getEmployeeMemo } from 'src/reducers/memos/memos';
import { extractCurrentQueries } from 'src/QueriesStorageApi';
import { addQueries } from 'src/reducers/common/listReducer';
import MemoRow from './MemoRow';
import NewMemo from './NewMemo';
import style from './Memos.scss';

class MemoTable extends React.Component {
  componentDidMount() {
    const queries = extractCurrentQueries(REDUCER_NAME);
    const employeeId = this.props.employeeId;
    this.props.addQueries({ ...queries, employeeId });
    this.props.fetch();
  }

  render() {
    const { employeeId, memos, changeMemoModal, showData } = this.props;

    return (
      <div className={showData ? style.wrap : style.wrapHide}>
        <div className={style.inner}>
          {memos.map(memo => <MemoRow {...{ memo }} key={memo.id} changeMemoModal={changeMemoModal} />)}
          <NewMemo {...{ employeeId, fromMemoTable: true, changeMemoModal }} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  memos: getEmployeeMemo(state, ownProps.employeeId)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  addQueries: queries => {
    dispatch(addQueries(REDUCER_NAME, queries));
  },
  fetch: () => {
    dispatch(fetchMemosWithEmployeeId(ownProps.employeeId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoTable);
