import React from 'react';
import _ from 'lodash';

import { redirectTo } from 'src/utils/Http';
import { HOME_PAGE, DASHBOARD_SHOW, USER_LOGOUT } from 'src/constants/EndpointUrls';
import PayrollLogo from 'jbc-front/components/icons/PayrollLogo';
import JobcanHeader from 'jbc-front/components/JobcanHeader';
import CurrentUserInfo from './CurrentUserInfo';
import { NotVerified } from './NotVerified';
import styles from './Header.scss';

class Header extends React.Component {
  // 共通IDヘッダを表示するか
  // - 従業員なしアカウントは、表示しない
  // - emailがない場合は、ログアウトされているため表示しない
  isShowJbcHeader(currentUser) {
    return !(_.isEmpty(currentUser) || !currentUser.hasEmployee || _.isEmpty(currentUser.email));
  }

  // メール制限の状態を表示するか
  // - クライアントの is_verified が true なら表示しない
  // - emailがない場合は、ログアウトされているため表示しない
  // - 管理者または運用担当者の場合のみ表示する
  isShowClientVerification(currentUser) {
    return (
      !this.props.isVerified &&
      !(_.isEmpty(currentUser) || _.isEmpty(currentUser.email) || !['admin', 'operator'].includes(currentUser.roleType))
    );
  }

  render() {
    const { isAuthorized, currentUser } = this.props;
    return (
      <React.Fragment>
        {this.isShowJbcHeader(currentUser) && (
          <JobcanHeader label="給与" user={currentUser.email} logout={() => redirectTo(USER_LOGOUT)} />
        )}
        <div className={styles.base}>
          <a href={isAuthorized ? DASHBOARD_SHOW : HOME_PAGE}>
            <PayrollLogo className={styles.BrandLogo} />
          </a>
          {this.isShowClientVerification(currentUser) && <NotVerified />}
          {isAuthorized && <CurrentUserInfo currentUser={currentUser} />}
        </div>
      </React.Fragment>
    );
  }
}
export default Header;
