import React from 'react';
import _ from 'lodash';

import * as Urls from 'src/constants/EndpointUrls';
import { REDUCER_NAME } from 'src/reducers/reports/reportWageLedger';
import BreadCrumb from 'jbc-front/components/BreadCrumb';

import DetailSearchForm from 'src/components/DetailSearchForm';

import { FORM_NAME_WAGE_LEDGER_SEARCH, fetchWageLedgerYear } from 'src/reducers/searchForm';
import SearchFormPaginator from 'src/components/SearchFormPaginator';

import ReportWageLedgerTable from '../containers/ReportWageLedgerTable';
import LeftAction from './LeftAction';
import YearSelect from '../containers/YearSelectContainer';
import SelectPeriodForm from '../containers/SelectPeriodForm';

class ReportWageLedgerList extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchWageLedgerYear());
  }

  render() {
    const {
      employees,
      resetValues,
      formValues,
      location,
      downloadParams,
      pdfPreviewUrl,
      checkedEmployees = [],
      uniqueString
    } = this.props;

    const canPrintPdf = _.size(checkedEmployees) < 2;
    const year = _.get(formValues, 'year');

    return (
      <div>
        <div className="l-wrap-xl l-contents-wrap">
          <YearSelect location={location} year={year} formValues={formValues} uniqueString={uniqueString} />
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="書類作成" path={Urls.REPORT_INDEX} />
              <BreadCrumb.Item label="賃金台帳" />
            </BreadCrumb>
          </div>
          <h1 className="m-title-main">賃金台帳</h1>
          <p className="m-title-main-note">
            最後に記載した日から起算して3年間の保管義務があります。<br />また、離職票を発行する場合は「雇用保険被保険者離職証明書」や「出勤簿」と合わせてハローワークに提出します。
          </p>
          <div className="l-wrap-xl">
            <SelectPeriodForm />
            <DetailSearchForm
              menuRender={() => <LeftAction {...{ downloadParams, pdfPreviewUrl, canPrintPdf, uniqueString }} />}
              containsOtherSearch={false}
              formName={FORM_NAME_WAGE_LEDGER_SEARCH}
              resetValues={resetValues}
            />
          </div>
          <div className="u-mt20">
            <ReportWageLedgerTable employees={employees} year={year} />
          </div>
          <SearchFormPaginator formName={FORM_NAME_WAGE_LEDGER_SEARCH} reducerName={REDUCER_NAME} />
        </div>
      </div>
    );
  }
}

export default ReportWageLedgerList;
