import React from 'react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';

import { numberWithComma } from 'src/utils/Utils';
import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import styles from './BonusTableData.scss';
import Row from './Row';

const getBonusAllowanceName = (clientBonusAllowances, clientBonusAllowanceId) =>
  (clientBonusAllowances.find(item => item.id === clientBonusAllowanceId) || {}).name;

const getAllowanceItems = (clientBonusAllowances, allowanceItems) => {
  const items = [];
  allowanceItems.forEach(item => {
    const allowance = clientBonusAllowances.find(
      aItem => aItem.id === item.clientBonusAllowanceId && aItem.allowanceType === 'payment'
    );
    if (allowance) {
      items.push({ ...allowance, ...item });
    }
  });
  return sortBy(items, 'displayOrder');
};

const BonusAllowanceDetail = ({ bonus, clientBonusAllowances }) => (
  <div className={styles.paymentClm}>
    {bonus && (
      <table className="m-table-obvious">
        <thead>
          <tr>
            <th colSpan="2">支給</th>
          </tr>
        </thead>
        <tbody>
          <Row label="支給合計" value={numberWithComma(bonus.totalPaymentAmount)} />
          {getAllowanceItems(clientBonusAllowances, bonus.allowanceItems).map(allowanceItem => (
            <Row
              key={allowanceItem.id}
              label={getBonusAllowanceName(clientBonusAllowances, allowanceItem.clientBonusAllowanceId)}
              value={numberWithComma(allowanceItem.amount)}
            />
          ))}
        </tbody>
      </table>
    )}
  </div>
);

export default connect(state => ({
  clientBonusAllowances: (getItem(REDUCER_NAME, state) || {}).clientBonusAllowances
}))(BonusAllowanceDetail);
