import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import styles from './EditableTextField.scss';

class EditableTextField extends React.Component {
  static defaultProps = {
    type: 'text',
    inputVerticalPadding: 8,
    textAlign: 'right'
  };

  renderInput() {
    const {
      input: inputProps,
      type,
      showValue,
      showMode,
      inputWidth,
      inputHeight,
      inputVerticalPadding,
      customOnBlur,
      customOnChange,
      label,
      disabled
    } = this.props;
    if (showMode === 'SHOW') {
      return showValue;
    }
    // ----↓↓↓↓↓↓ for edit mode
    switch (type) {
      case 'checkbox':
        return (
          <label className="input-label">
            <input
              type={type}
              disabled={disabled}
              className="m-checkbox-input"
              {...inputProps}
              onBlur={event => {
                inputProps.onBlur(event);
                if (customOnBlur !== undefined) {
                  customOnBlur(event);
                }
              }}
              onChange={event => {
                inputProps.onChange(event);
                if (customOnChange !== undefined) {
                  customOnChange(event);
                }
              }}
            />
            <span
              className="m-checkbox-parts"
              style={{
                width: inputWidth,
                height: inputHeight,
                paddingLeft: 22,
                paddingRight: 0
              }}
            >
              {label}
            </span>
          </label>
        );
      default:
        return (
          <input
            {...inputProps}
            className={classNames({ [`${styles.disabled}`]: disabled })}
            type={type}
            disabled={disabled}
            style={{
              width: inputWidth,
              height: inputHeight,
              paddingTop: inputVerticalPadding,
              paddingBottom: inputVerticalPadding
            }}
            onBlur={event => {
              inputProps.onBlur(event);
              if (customOnBlur !== undefined) {
                customOnBlur(event);
              }
            }}
            onChange={event => {
              inputProps.onChange(event);
              if (customOnChange !== undefined) {
                customOnChange(event);
              }
            }}
          />
        );
    }
  }

  render() {
    const { meta, textAlign, width, unit, className } = this.props;
    return (
      <div
        className={classNames(className, {
          [`${styles.container}`]: true,
          [`${styles.error}`]: _.has(meta, 'error') && _.isString(meta.error) && meta.touched
        })}
        style={{ textAlign, width }}
      >
        {this.renderInput()}
        {unit && <span className="unit">{unit}</span>}
      </div>
    );
  }
}

export default EditableTextField;
