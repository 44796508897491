import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions, getRadioOptions } from 'src/reducers/selectOptions';
import selector, { getOptionLabel, display } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';
import tableStyles from '../styles/GeneralTableInfo.scss';
import EmployeeLeaveOfAbsenceItemShow from '../containers/EmployeeLeaveOfAbsenceItemShow';

const BusinessInfoSectionShow = props => {
  const {
    employee,
    groups,
    positions,
    occupations,
    employmentTypes,
    enrollmentStatuses,
    resignedReasons,
    employeeLeaveOfAbsences
  } = props;

  return (
    <div>
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>スタッフコード：</th>
            <td>{display(employee.staffCode)}</td>
          </tr>
          <tr>
            <th>雇用形態：</th>
            <td>{display(getOptionLabel(employee.employmentTypeId)(employmentTypes))}</td>
          </tr>
          <tr>
            <th>役職：</th>
            <td>{display(getOptionLabel(employee.positionId)(positions))}</td>
          </tr>
          <tr>
            <th>職種：</th>
            <td>{display(getOptionLabel(employee.occupationId)(occupations))}</td>
          </tr>
          <tr>
            <th>グループ：</th>
            <td>{display(getOptionLabel(employee.groupId)(groups))}</td>
          </tr>
          <tr>
            <th>在籍状況：</th>
            <td>{display(getOptionLabel(employee.enrollmentStatus)(enrollmentStatuses))}</td>
          </tr>
          <tr>
            <th>入社日：</th>
            <td>{display(formatDate(employee.joinedOn))}</td>
          </tr>
          <tr>
            <th>退職日：</th>
            <td>{display(formatDate(employee.resignedOn))}</td>
          </tr>
          {employee.resignedOn && (
            <tr>
              <th>退職区分：</th>
              <td>{display(getOptionLabel(employee.resignedReason)(resignedReasons))}</td>
            </tr>
          )}
          {!_.isEmpty(employeeLeaveOfAbsences) && <EmployeeLeaveOfAbsenceItemShow {...{ employeeLeaveOfAbsences }} />}
        </tbody>
      </table>
    </div>
  );
};

export default connect(state => ({
  employee: selector(getItem(REDUCER_NAME, state), 'employee'),
  groups: getSelectOptions(state, 'groups'),
  positions: getSelectOptions(state, 'positions'),
  occupations: getSelectOptions(state, 'occupations'),
  employmentTypes: getSelectOptions(state, 'employmentTypes'),
  enrollmentStatuses: getSelectOptions(state, 'enrollmentStatuses'),
  resignedReasons: getRadioOptions(state, 'resignedReasons'),
  employeeLeaveOfAbsences: getSelectOptions(state, 'employeeLeaveOfAbsences')
}))(BusinessInfoSectionShow);
