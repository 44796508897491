import React, { Component } from 'react';
import axios from 'axios';
import _ from 'lodash';

import { Section } from 'jbc-front/components/Form';
import { parse } from 'query-string';
import Cookies from 'js-cookie'; // eslint-disable-line
import moment from 'moment';
import styles from './PaidPlanRegistration.scss';
import PaidPlanRegistrationForm from './PaidPlanRegistrationForm';

class PaidPlanRegistration extends Component {
  state = {
    errors: null
  };

  componentDidMount() {
    const {
      location: { search }
    } = this.props;
    const { accountId: airMarketId } = parse(search);
    if (airMarketId) {
      Cookies.set('StoreId', airMarketId, { expires: 60 });
    }
  }

  handleFormSubmit = async values => {
    const {
      location: { search }
    } = this.props;
    const airMarketId = parse(search).accountId || Cookies.get('StoreId');

    try {
      await axios.post('/registrations/paid_plan', {
        ...values,
        ...(airMarketId ? { air_market_id: airMarketId } : {})
      });
      window.location = `/registrations/paid_plan/completed?email=${encodeURIComponent(values.userEmail)}`;
    } catch (err) {
      this.setState({ errors: _.get(err, 'response.data.errors.messages') });
    }
  };

  render() {
    const now = moment();
    const discountPrice = 300;
    // 現状エキスポのみで使用のため定数で対応
    // https://github.com/d-o-n-u-t-s/payrollservice/issues/6173#issuecomment-551372488
    const billingStartOn = moment(new Date('2020-02-01 00:00:00'));
    const firstBillSend = billingStartOn.clone().add(1, 'month');

    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">有料プラン申し込み</h1>
          <p className="m-title-main-note">下記フォームをご記入ください。</p>
        </div>
        <div className="l-wrap-xs">
          <Section title="有料プラン申し込み後のご請求について">
            <dl className={styles.field}>
              <dt>ご利用料金：</dt>
              <dd>{discountPrice}円／ユーザ</dd>
            </dl>
            <dl className={styles.field}>
              <dt>契約月：</dt>
              <dd>{now.format('YYYY年MM月')}</dd>
            </dl>
            <dl className={styles.field}>
              <dt>初回料金発生月：</dt>
              <dd>{billingStartOn.format('YYYY年MM月')}</dd>
            </dl>
          </Section>

          <div className={styles.priceWrap}>
            <div className={styles.priceBox}>
              <div className={styles.priceBoxTtl}>初期費用・サポート費用</div>
              <div className={styles.priceBoxContent}>
                <span className={styles.priceBoxImp}>0</span>円
              </div>
            </div>
            <div className={`${styles.priceBox} u-mt20`}>
              <div className={styles.priceBoxSingle}>
                <div className={styles.priceBoxTtl}>特別値引き価格</div>
              </div>
              <div className={styles.priceBoxSingle}>
                <div className={styles.priceBoxContent}>
                  <span className={styles.priceBoxImp}>400</span>円/1ユーザ
                  <span className={styles.priceBoxArrow}>→</span>
                  <span className={styles.priceBoxSpecial}>{discountPrice}</span>円/1ユーザ
                </div>
              </div>
              <div className={styles.priceBoxSingle}>
                <div className={styles.priceBoxSubContent}>従業員数：無制限</div>
              </div>
              <div className={styles.priceBoxSingle}>
                <div className={styles.priceBoxSubContent}>ご利用機能：すべての機能</div>
              </div>
              <div className={styles.priceBoxSingle}>
                <div className={styles.priceBoxSubContent}>サポート：あり</div>
              </div>
            </div>
          </div>

          <div className="u-mt40">
            <PaidPlanRegistrationForm
              onSubmit={this.handleFormSubmit}
              initialValues={{
                paid_plan_application_payment_method_type: 'bill'
              }}
              errors={this.state.errors}
            />
          </div>

          <Section title="決済方法" className="u-mt30">
            <dl className={styles.field}>
              <dt>請求書払い：</dt>
              <dd>（銀行・コンビニ）</dd>
            </dl>
            <dl className={styles.field}>
              <dt>請求書発行元：</dt>
              <dd>株式会社ネットプロテクションズ</dd>
            </dl>
            <dl className={styles.field}>
              <dt>初回請求書送付日：</dt>
              <dd>{firstBillSend.format('YYYY年MM月')}上旬</dd>
            </dl>
            <dl className={styles.field}>
              <dt>初回振込期限：</dt>
              <dd>{firstBillSend.format('YYYY年MM月')}月末（※振込期限日は請求書をご確認ください）</dd>
            </dl>
            <ul className={`${styles.notes} u-mt20`}>
              <li>※ 月額ご利用金額が¥2,000以下の場合、最低利用金額(¥2,000)をご請求いたします。</li>
              <li>※ 価格はすべて税抜きです。</li>
              <li>※ 申込後、クレジットカード払いに変更できます。</li>
            </ul>
          </Section>

          <Section className="u-mt30">
            <ul className={styles.notes}>
              <li>※ ご解約は解約希望月の前月末までにご連絡をお願いいたします。</li>
              <li>※ 料金発生月前にご解約いただいた場合、料金は発生いたしません。</li>
              <li>
                ※ 請求書払い（銀行・コンビニ）には、株式会社ネットプロテクションズの「NP掛け払い」を利用しています。
              </li>
            </ul>
            <dl className={styles.glossary}>
              <dt>「NP掛け払い」とは：</dt>
              <dd>法人/個人事業主を対象とした掛け払いサービスです。（月末締め翌月末請求書払い）</dd>
              <dd>請求書は、ご利用された月の翌月第2営業日に株式会社ネットプロテクションズから発行されます。</dd>
              <dd>請求書に記載されている銀行口座または、コンビニの払込票でお支払いください。</dd>
              <dd>銀行振込の際の振込手数料はお客様にてご負担ください。</dd>
              <dd>請求代行のため代金債権は同社へ譲渡されます。</dd>
            </dl>
          </Section>
        </div>
      </div>
    );
  }
}

export default PaidPlanRegistration;
