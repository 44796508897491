// eslint-disable-line
import React from 'react';
import { FormSection } from 'redux-form';
import { connect } from 'react-redux';
import * as validators from 'jbc-front/utils/validators';
import { toAdYearDate } from 'jbc-front/utils/jpYear';
import _ from 'lodash';
import moment from 'moment';
import { TextField, DateField, RadioField, Section } from 'jbc-front/components/Form';
import ActionButton from 'jbc-front/components/ActionButton';
import { isDateStringFormat, joinedOnLimit, isStaffCode } from 'src/utils/CustomValidators';
import selector, { getOptionLabel } from 'src/utils/Utils';
import { getEmployeeFormValues, updateEmployeeForm } from 'src/reducers/employees/employees';
import { getSelectOptions, getRadioOptions } from 'src/reducers/selectOptions';
import { dateSlide, formatDate } from 'src/utils/Date';
import isUseOtherApp from 'src/utils/JbcId';
import EmployeeLeaveOfAbsences from './EmployeeLeaveOfAbsences';

const BusinessInfoSection = props => {
  const {
    employeeId,
    employmentTypes,
    groups,
    positions,
    occupations,
    enrollmentStatuses,
    employeeInsurance,
    resignedOn,
    resignedReasons,
    updateInsuranceAcquisitionOn,
    updateInsuranceExpiredOn
  } = props;

  return (
    <div>
      <FormSection name="employee">
        <TextField label="スタッフコード" type="text" name="staffCode" maxLength="50" validate={isStaffCode} />
        {isUseOtherApp && (
          <p className="u-mb20">スタッフコードを変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。</p>
        )}
        <Section title="人事情報">
          <ActionButton
            as="a"
            rel="noopener noreferrer"
            target="_blank"
            href={`/employees/${employeeId}/personnel_histories`}
            className="u-mb20"
          >
            人事異動履歴へ
          </ActionButton>
          <p className="u-mb20">
            「雇用形態」「役職」「職種」「グループ」は人事異動履歴の情報から自動で表示されます。
            <br />編集する場合は「人事異動履歴へ」ボタンを押してください。
          </p>
          <TextField
            disabled
            label="雇用形態"
            name="employmentTypeId"
            format={value => getOptionLabel(value)(employmentTypes)}
          />
          <TextField disabled label="役職" name="positionId" format={value => getOptionLabel(value)(positions)} />
          <TextField disabled label="職種" name="occupationId" format={value => getOptionLabel(value)(occupations)} />
          <TextField disabled label="グループ" name="groupId" format={value => getOptionLabel(value)(groups)} />
        </Section>
        <TextField
          disabled
          label="在籍状況"
          name="enrollmentStatus"
          format={value => getOptionLabel(value)(enrollmentStatuses)}
        />

        <DateField
          required
          label="入社日"
          viewMode="months"
          name="joinedOn"
          validate={[isDateStringFormat, joinedOnLimit]}
          onChange={updateInsuranceAcquisitionOn}
        />

        <DateField
          label="退職日"
          viewMode="months"
          name="resignedOn"
          validate={isDateStringFormat}
          onBlur={(_fields, expiredOn) => {
            updateInsuranceExpiredOn(expiredOn, employeeInsurance);
          }}
        />

        {resignedOn && <RadioField label="退職区分" name="resignedReason" options={resignedReasons} />}
        <EmployeeLeaveOfAbsences />
      </FormSection>
    </div>
  );
};

const mapStateToProps = state => ({
  groups: getSelectOptions(state, 'groups'),
  positions: getSelectOptions(state, 'positions'),
  occupations: getSelectOptions(state, 'occupations'),
  employmentTypes: getSelectOptions(state, 'employmentTypes'),
  enrollmentStatuses: getSelectOptions(state, 'enrollmentStatuses'),
  resignedReasons: getRadioOptions(state, 'resignedReasons'),
  resignedOn: selector(getEmployeeFormValues(state), 'employee', 'resignedOn'),
  employeeId: selector(getEmployeeFormValues(state), 'employee', 'id'),
  employeeInsurance: selector(getEmployeeFormValues(state), 'employeeInsurance') || {}
});

const mapDispatchToProps = dispatch => ({
  updateInsuranceAcquisitionOn: (_fields, acquisitionOn) => {
    dispatch(updateEmployeeForm('employeeInsurance.healthCertificateAcquisitionOn', acquisitionOn));
    dispatch(updateEmployeeForm('employeeInsurance.pensionCertificateAcquisitionOn', acquisitionOn));
    dispatch(updateEmployeeForm('employeeInsurance.employmentCertificateAcquisitionOn', acquisitionOn));
  },
  updateInsuranceExpiredOn: (expiredOn, employeeInsurance) => {
    if (!employeeInsurance.healthCertificateExpiredOn) {
      dispatch(updateEmployeeForm('employeeInsurance.healthCertificateExpiredOn', formatDate(dateSlide(expiredOn, 1))));
    }
    if (!employeeInsurance.pensionCertificateExpiredOn) {
      dispatch(
        updateEmployeeForm('employeeInsurance.pensionCertificateExpiredOn', formatDate(dateSlide(expiredOn, 1)))
      );
    }
    if (!employeeInsurance.employmentCertificateExpiredOn) {
      dispatch(
        updateEmployeeForm('employeeInsurance.employmentCertificateExpiredOn', formatDate(dateSlide(expiredOn)))
      );
    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessInfoSection);

const sameDayIn = (absence1, absence2) => {
  const startOn = moment(absence1.startOn);

  if (startOn.isSame(absence2.startOn)) {
    return true;
  }
  if (startOn.isAfter(absence2.startOn)) {
    return !absence2.endOn || startOn.isSameOrBefore(absence2.endOn);
  }
  return !absence1.endOn || moment(absence2.startOn).isSameOrBefore(absence1.endOn);
};

const validatePeriods = values => {
  const name = 'employee.employeeLeaveOfAbsences';
  const periods = _.get(values, name);
  const joinedAt = _.get(values, 'employee.joinedOn');
  const resignedAt = _.get(values, 'employee.resignedOn');
  if (!periods) {
    return {};
  }

  const errors = {};
  _.forEach(periods, (period, idx) => {
    const startOn =
      period.startOn && period.startOn.match(validators.jpYearRegExp)
        ? toAdYearDate(period.startOn, false)
        : period.startOn;
    const endOn =
      period.endOn && period.endOn.match(validators.jpYearRegExp) ? toAdYearDate(period.endOn, false) : period.endOn;

    if (!startOn) {
      _.set(errors, `${name}[${idx}].startOn`, 'を入力してください');
    } else if (
      moment(startOn).isValid() &&
      joinedAt &&
      moment(joinedAt).isValid() &&
      moment(startOn).isBefore(joinedAt)
    ) {
      _.set(errors, `${name}[${idx}].startOn`, 'は入社日以降の日付を入力してください');
    }

    if (endOn && moment(endOn).isValid()) {
      if (startOn && moment(startOn).isValid() && moment(startOn).isAfter(endOn)) {
        _.set(errors, `${name}[${idx}].endOn`, 'は開始日以降の日付を入力してください');
      } else if (resignedAt && moment(resignedAt).isValid() && moment(endOn).isAfter(resignedAt)) {
        _.set(errors, `${name}[${idx}].endOn`, 'は退職日以前の日付を入力してください');
      }
    }

    if (startOn && period.reasonType === 'childcare_birth' && moment(startOn).isBefore('2022-10-01')) {
      _.set(
        errors,
        `${name}[${idx}].reasonType`,
        'で出生時育児休業を選択する場合は開始日を2022年10月01日以降に設定してください'
      );
    }
  });

  if (!_.isEmpty(errors)) {
    return errors;
  }

  _.forEach(periods, (period, idx) => {
    const startOn =
      period.startOn && period.startOn.match(validators.jpYearRegExp)
        ? toAdYearDate(period.startOn, false)
        : period.startOn;
    for (let i = 0; i < idx; i += 1) {
      if (startOn) {
        if (sameDayIn(periods[i], period)) {
          _.set(errors, `${name}[${idx}].startOn`, 'は既存の期間と重複します');
          _.set(errors, `${name}[${idx}].endOn`, 'は既存の期間と重複します');
        }
      }
    }
  });
  return errors;
};

export const validate = values => Object.assign({}, validatePeriods(values));
