import React from 'react';
import { connect } from 'react-redux';

import MARITAL_STATUSES from 'src/constants/Options';
import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import selector, { getOptionLabel, display } from 'src/utils/Utils';
import tableStyles from '../styles/GeneralTableInfo.scss';

const MaritalStatusSectionShow = ({ employee }) => (
  <div>
    <table className={tableStyles.mTablePersonal}>
      <tbody>
        <tr>
          <th>配偶者情報:</th>
          <td>{display(getOptionLabel(employee.maritalStatus ? '1' : '0')(MARITAL_STATUSES))}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default connect(state => ({
  employee: selector(getItem(REDUCER_NAME, state), 'employee')
}))(MaritalStatusSectionShow);
