import React from 'react';

import { NumberFields } from 'jbc-front/components/Form';

const PostalCodeField = ({ required, disabled, handleOnChange, prefix = '' }) => (
  <NumberFields
    texts={['〒 ', ' - ']}
    lengths={[3, 4]}
    required={required}
    disabled={disabled}
    label="郵便番号"
    prefix={`${prefix}postalCodePart`}
    exactLength
    seperateChar=""
    onChange={handleOnChange}
  />
);

export default PostalCodeField;
