import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { initialize } from 'redux-form';
import { push } from 'connected-react-router';
import queryString from 'query-string';
import axios from 'axios';

import { MainFormControls } from 'jbc-front/components/SearchForm';
import { setOptions } from 'src/reducers/selectOptions';
import AdditionalSearchFields from 'src/components/AdditionalSearchFields';
import * as Urls from 'src/constants/EndpointUrls';
import styles from 'src/components/DetailSearchForm.scss';

const DEFAULT_SEARCH = {
  order: 'asc',
  sort: 'staffCode'
};

const OMIT_FIELDS = [
  'closing_day',
  'payment_day',
  'payment_month_type',
  'year_month',
  'client_bonus_id',
  'payroll_rules_groups',
  'page',
  'per_page',
  'applicable_office',
  'revision_year'
];

class DetailSearchForm extends React.Component {
  constructor(props) {
    super(props);

    this.loadOptions = this.loadOptions.bind(this);
    this.expandTabAfterLoadingOptions = this.expandTabAfterLoadingOptions.bind(this);

    this.state = {
      isLoadedOptions: false,
      isExpand: false
    };
  }

  loadOptions() {
    const { dispatch } = this.props;
    const promises = [
      axios.get(Urls.OPTIONS_CLIENT_OFFICES),
      axios.get(Urls.OPTIONS_CLIENT_FLATTEN_GROUPS),
      axios.get(Urls.OPTIONS_CLIENT_POSITIONS),
      axios.get(Urls.OPTIONS_CLIENT_EMPLOYMENT_TYPES, { params: { connected: true } }),
      axios.get(Urls.OPTIONS_CLIENT_OCCUPATIONS),
      axios.get(Urls.OPTIONS_CLIENT_PAYROLL_RULE_GROUPS)
    ];
    Promise.all(promises)
      .then(responses => {
        const [
          officeRes,
          groupsRes,
          positionsRes,
          employmentTypesRes,
          occupationsRes,
          payrollRuleGroupsRes
        ] = responses;
        dispatch(
          setOptions({
            offices: officeRes.data.payload.options,
            flattenGroups: groupsRes.data.payload.options,
            positions: positionsRes.data.payload.options,
            connectedEmploymentTypes: employmentTypesRes.data.payload.options,
            occupations: occupationsRes.data.payload.options,
            payrollRuleGroups: payrollRuleGroupsRes.data.payload.options
          })
        );
        this.setState({ isLoadedOptions: true });
        this.expandTabAfterLoadingOptions();
      })
      .catch(error => {
        console.log(error);
      });
  }

  expandTabAfterLoadingOptions() {
    this.setState({ isExpand: true });
  }

  render() {
    const {
      menuRender,
      containsOtherSearch = true,
      containsBonusTarget,
      containAccountTypes,
      containEmployeeEnrollmentStatus,
      formName,
      resetValues,
      containsOfficeSearch,
      containSyncYeaTargets,
      containSaveStatus,
      containIsConfirmedPayroll,
      containIsConfirmed,
      containsSubject,
      containElectronicDeliveryAgreement,
      resetSearch = '',
      location,
      defaultSearch,
      dispatch
    } = this.props;
    const { isLoadedOptions, isExpand } = this.state;
    const omitedSearch = _.omit(queryString.parse(location.search), OMIT_FIELDS);
    return (
      <div className="u-mb20">
        <div className={styles.mainSearchFields}>
          {menuRender()}
          <MainFormControls
            isExpand={isExpand}
            hasConditions={
              location.search !== '' &&
              !_.isEqual(omitedSearch, _.isEmpty(defaultSearch) ? DEFAULT_SEARCH : defaultSearch) &&
              location.search !== `?${resetSearch}`
            }
            onToggleClick={() => {
              if (!isLoadedOptions) this.loadOptions();
              if (isLoadedOptions) this.setState({ isExpand: !isExpand });
            }}
            handleClear={() => {
              dispatch(initialize(formName, resetValues));
              dispatch(push({ ...location, search: resetSearch }));
            }}
          />
        </div>
        {isExpand && (
          <AdditionalSearchFields
            {...{
              containsOtherSearch,
              containsBonusTarget,
              containAccountTypes,
              containEmployeeEnrollmentStatus,
              containsOfficeSearch,
              containsSubject,
              containElectronicDeliveryAgreement,
              containSyncYeaTargets,
              containSaveStatus,
              containIsConfirmedPayroll,
              containIsConfirmed
            }}
          />
        )}
      </div>
    );
  }
}
export default connect(state => ({
  location: state.router.location
}))(DetailSearchForm);
