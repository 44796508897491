import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';

import { ErrorsSection, LoadingWithDescription } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { REPORT_WITHHOLDING_SLIP_CONFIRM_FORM } from 'src/constants/FormNames';
import {
  unconfirmWithholdingSlip,
  getUnconfirmWithholdingSlipErrors,
  clearErrors
} from 'src/reducers/reports/reportWithholdingSlips';

const UnconfirmWithholdingInsideForm = ({ handleSubmit, submitting, submit, cancelBtnClick, errors, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        {submitting && <LoadingWithDescription text="解除中です。少々お待ちください。" />}
        <div>選択した従業員の源泉徴収票を確定解除します。</div>
        <div>確定解除すると、再度確定するまでPDF出力ができなくなり、現在公開中の場合は未公開となります。</div>
        <div>また、更新データがある場合は、確定解除後に再度確定する事で更新できます。</div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="解除" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let UnconfirmWithholdingForm = reduxForm({
  form: REPORT_WITHHOLDING_SLIP_CONFIRM_FORM
})(UnconfirmWithholdingInsideForm);
const mapStateToProp = state => ({
  errors: getUnconfirmWithholdingSlipErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(unconfirmWithholdingSlip(ownProps.employeeId, ownProps.withholdingSlipYear, ownProps.cancelBtnClick)),
  clearErrors: dispatch(clearErrors())
});

UnconfirmWithholdingForm = connect(mapStateToProp, mapDispatchToProp)(UnconfirmWithholdingForm);

class UnconfirmWithholdingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const header = '源泉徴収票の確定';
    const { isOpen, hideModal, withholdingSlipYear, employeeId } = this.props;

    return (
      <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
        <UnconfirmWithholdingForm
          cancelBtnClick={hideModal}
          header={header}
          withholdingSlipYear={withholdingSlipYear}
          employeeId={employeeId}
        />
      </CommonModal>
    );
  }
}

export default UnconfirmWithholdingModal;
