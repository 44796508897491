import { connect } from 'react-redux';
import { REDUCER_NAME } from 'src/reducers/employees/employeeBonus';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import RollbackEmployeeBonusModal from '../components/RollbackEmployeeBonusModal';

const mapStateToProps = state => ({
  errors: getItemErrors(REDUCER_NAME, state).rollbackEmployeeBonus
});

export default connect(mapStateToProps)(RollbackEmployeeBonusModal);
