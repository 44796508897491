import React from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import SortableTh from 'src/components/SortableTh';
import { EMPLOYEE_ATTENDANCE_EDIT } from 'src/constants/EndpointUrls';
import { takePaysSearchQueries, FORM_NAME_ATTENDANCES_SEARCH } from 'src/reducers/searchForm';
import { getList } from 'src/reducers/common/listReducer';
import { REDUCER_NAME } from 'src/reducers/employees/employeeAttendances';
import { redirectTo, detailUrlWithQueries, getIdsFromArr } from 'src/utils/Http';
import EmployeeAttendanceTableRow from '../components/EmployeeAttendanceTableRow';

const EmployeeAttendanceTable = ({ employeeAttendances, queries }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list">
      <thead>
        <tr>
          <th>メモ</th>
          <SortableTh
            field="staffCode"
            globalClassName="m-table-list-staffcode"
            formName={FORM_NAME_ATTENDANCES_SEARCH}
          >
            スタッフコード
          </SortableTh>
          <SortableTh field="name" formName={FORM_NAME_ATTENDANCES_SEARCH}>
            氏名
          </SortableTh>
          <SortableTh field="attendanceUpdatedAt" formName={FORM_NAME_ATTENDANCES_SEARCH}>
            勤怠最終更新
          </SortableTh>
          <th>勤怠連携</th>
        </tr>
      </thead>
      <tbody>
        {(!employeeAttendances || employeeAttendances.length === 0) && (
          <tr>
            <td colSpan={5}>データはありません。</td>
          </tr>
        )}
        {employeeAttendances.map(employeeAttendance => (
          <EmployeeAttendanceTableRow
            key={employeeAttendance.id}
            onClick={() =>
              redirectTo(
                detailUrlWithQueries(
                  employeeAttendance.id,
                  queries,
                  EMPLOYEE_ATTENDANCE_EDIT,
                  getIdsFromArr(employeeAttendances)
                )
              )
            }
            employeeAttendance={employeeAttendance}
          />
        ))}
      </tbody>
    </table>
  </div>
);

const mapStateToProps = state => ({
  employeeAttendances: getList(REDUCER_NAME, state),
  queries: takePaysSearchQueries(getFormValues(FORM_NAME_ATTENDANCES_SEARCH)(state))
});
export default connect(mapStateToProps)(EmployeeAttendanceTable);
