import { combineReducers } from 'redux';
import axios from 'axios';
import { getFormValues, autofill, stopSubmit, startSubmit, change as changeForm } from 'redux-form';
import _ from 'lodash';

import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL, SAVE_THEN_MOVE_TO_EDIT } from 'src/constants/Generals';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import { serializeHttpGetParams, concatParamsToUrl, redirectTo } from 'src/utils/Http';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { newItem, newItemFailure, editItem, editItemFailure, getItem } from 'src/reducers/common/itemReducer';
import {
  getListQueries,
  getListPageCount,
  getList,
  fetchList,
  fetchListSuccess,
  fetchListFailure,
  setList,
  setExtras,
  setQueries
} from 'src/reducers/common/listReducer';
import { takeEmployeesSearchQueries, FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import selector, { isIncluded, scrollToTop } from 'src/utils/Utils';
import { isHonnin, paramsWithFile } from 'src/utils/Form';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { setGlobalSuccesses, setGlobalErrors } from 'src/reducers/global';
import { setOptions } from 'src/reducers/selectOptions';
import { getCurrentUserInfo } from 'src/reducers/users/currentUser';
import { fetchLatestJobStatus, isJobIncomplete } from '../JobStatus';

export const REDUCER_NAME = 'employees';

export const getEmployeeData = state => getItem(REDUCER_NAME, state);

export const getCheckedEmployees = state => {
  const currentUser = getCurrentUserInfo(state);
  return _.filter(
    getList(REDUCER_NAME, state),
    employee => employee.checked && employee.email !== currentUser.email && employee.isDeletable
  );
};

// Form Selector
export const getEmployeeFormValues = state => getFormValues(EMPLOYEE_FORM)(state);

// actions
export const updateEmployeeForm = (field, value) => autofill(EMPLOYEE_FORM, field, value);

// Async Action Creators
export const fetchEmployees = (
  queries,
  callbackWithDispatch = undefined,
  url = Urls.SEARCH_EMPLOYEE_URL
) => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    const response = await axios.get(`${url}`, { params: queries });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.employees,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(MEMO_REDUCER_NAME, response.data.payload.memos));
    if (typeof callbackWithDispatch === 'function') {
      dispatch(callbackWithDispatch(payload.data));
    }
  } catch (exception) {
    let msg = ['一覧の取得に失敗しました'];
    if (!_.isEmpty(exception?.response?.data?.errors)) {
      msg = exception.response.data.errors.messages;
    }
    dispatch(fetchListFailure(REDUCER_NAME, msg));
  }
};

export const fetchImportEmployeeCsvJobStatus = (interval = INITIAL_POLLING_INTERVAL) => async (dispatch, getState) => {
  const params = { job_name: 'importEmployeeCsvJob' };
  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.importEmployeeCsvJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status)) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchImportEmployeeCsvJobStatus(nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
  }
};

export const hideImportEmployeeCsvJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'ImportEmployeeCsvJob'
    });
    dispatch(fetchImportEmployeeCsvJobStatus());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const fetchEmployeeWithExtraCondition = data => async (dispatch, getState) => {
  try {
    // Get parameter from form data
    const { nameCnt, groupIds, employmentTypeIds, occupationIds, payrollRulesGroup, positionIds, noMemo } = data;
    let { memoColorIds } = data;
    if (memoColorIds) {
      memoColorIds = Object.keys(memoColorIds)
        .map(key => (memoColorIds[key] ? key : null))
        .toString();
    }
    // Current query
    const originQueries = getListQueries(REDUCER_NAME, getState());
    // 1ページ目から検索
    const newQueries = {
      ...originQueries,
      nameCnt,
      groupIds,
      employmentTypeIds,
      occupationIds,
      payrollRulesGroup,
      positionIds,
      noMemo,
      memoColorIds,
      page: 1
    };
    dispatch(setQueries(REDUCER_NAME, newQueries));
    // Re fetch employee bonus
    dispatch(fetchEmployees());
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

// New Employee
export const newEmployee = data => async dispatch => {
  try {
    dispatch(newItem(REDUCER_NAME));
    const formattedData = { ...data };

    // ビジネスネームを使用しない場合は削除する
    if (!formattedData.useBusinessName) {
      formattedData.businessLastName = null;
      formattedData.businessFirstName = null;
    }

    const httpBody = { employee: formattedData, employeePayrollRule: data };
    const response = await axios.post(Urls.CREATE_EMPLOYEE_URL, httpBody);

    if (response.data.errors.length > 0) {
      dispatch(editItemFailure(REDUCER_NAME, response.data.errors.messages));
    } else {
      const values = response.data.payload;
      // It redirects immediately after success, so we do not need dispatch here.
      // Uncomment below line in otherwise.
      // dispatch(newSuccessAction(REDUCER_NAME, values.employee));
      const nextUrl =
        data.afterSubmit === SAVE_THEN_MOVE_TO_EDIT
          ? concatParamsToUrl(Urls.EMPLOYEE_EDIT, { id: values.employee.id })
          : Urls.EMPLOYEES_LIST;

      redirectTo(nextUrl);
    }
  } catch (exception) {
    scrollToTop();
    dispatch(stopSubmit(EMPLOYEE_FORM));
    dispatch(newItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

const isValidCommutingItem = item => item && (item.paymentAmount || item.oneWayFare);

// Edit Employee
export const editEmployee = (data, employeeId, showEmployeeUrl) => async dispatch => {
  try {
    dispatch(startSubmit(EMPLOYEE_FORM));
    dispatch(editItem(REDUCER_NAME));
    const formattedData = { ...data };

    // Reformat employeeCommutings data
    formattedData.employeeCommutings = data.employeeCommutings.filter(
      item => isIncluded(data.employee.meansOfCommunication, item.meansOfCommunication) && isValidCommutingItem(item)
    );

    // Reformat employeeAllowances data
    formattedData.employeeAllowances = data.employeeAllowances.filter(
      item => item.clientPayrollRulesGroupId === data.employeePayrollRule.clientPayrollRulesGroupId
    );

    formattedData.employeeUnitPrices = data.employeeUnitPrices.filter(
      item => item.clientPayrollRulesGroupId === data.employeePayrollRule.clientPayrollRulesGroupId
    );

    // 既存データとユーザが変更を加えたデータのみ送信する
    formattedData.employeeStandardMonthlies = data.employeeStandardMonthlies.filter(
      item => item.inputType !== 'clicking'
    );

    // Reformat employeeFamilySupports
    formattedData.employeeFamilySupports = data.familySupport === '1' ? data.employeeFamilySupports : [];

    // Reformat headOfHouseholdName
    if (isHonnin(data.employee.headOfHousehold)) formattedData.employee.headOfHouseholdName = '';

    // Refomat employeeInsurance
    if (formattedData.employeeInsurance.isTargetAgedEmployee && formattedData.employeeInsurance.isAgedEmployee === '') {
      formattedData.employeeInsurance.isAgedEmployee = false;
    }

    // ビジネスネームを使用しない場合は削除する
    if (!formattedData.employee.useBusinessName) {
      formattedData.employee.businessLastName = null;
      formattedData.employee.businessFirstName = null;
    }

    const httpBody = { ...formattedData, employeeLeaveOfAbsences: data.employee.employeeLeaveOfAbsences };
    const editUrl = concatParamsToUrl(Urls.UPDATE_EMPLOYEE_URL, { id: employeeId });
    const response = await axios.put(editUrl, httpBody);

    if (response.data.errors.length > 0) {
      dispatch(editItemFailure(REDUCER_NAME, response.data.errors.messages));
      dispatch(stopSubmit(EMPLOYEE_FORM));
    } else {
      redirectTo(showEmployeeUrl);
    }
  } catch (exception) {
    scrollToTop();
    dispatch(stopSubmit(EMPLOYEE_FORM));
    let errMessages = '従業員情報の更新に失敗しました。';
    if (exception.response.data) {
      errMessages = exception.response.data.errors.messages;
    }
    dispatch(editItemFailure(REDUCER_NAME, errMessages));
  }
};

// Delete Employee
export const deleteEmployee = employeeId => async dispatch => {
  try {
    const deleteUrl = concatParamsToUrl(Urls.EMPLOYEE_DELETE, { id: employeeId });
    await axios.delete(deleteUrl);
    dispatch(setGlobalSuccesses('従業員情報を削除しました。'));
    redirectTo(Urls.EMPLOYEES_LIST);
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const importEmployeeCsv = (data, operation = 'create') => async dispatch => {
  try {
    dispatch(newItem(REDUCER_NAME));

    const params = paramsWithFile(data, ['employee_csv']);
    params.append('operation', operation);
    await axios.post(Urls.IMPORT_EMPLOYEE_CSV, params);
    dispatch(setGlobalSuccesses('ファイルを受け取りました。'));
    await new Promise(r => setTimeout(r, 1000));
    redirectTo(Urls.EMPLOYEES_LIST);
  } catch (exception) {
    scrollToTop();
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const fetchCityCodeByName = (setCityCallback, formName = EMPLOYEE_FORM) => async (dispatch, getState) => {
  try {
    const city = selector(getEmployeeFormValues(getState()), formName, 'city');
    if (!city) {
      if (formName === EMPLOYEE_FORM) {
        dispatch(setGlobalErrors('基本情報で市区町村を入力してください。'));
      } else if (formName === 'residentCard') {
        dispatch(setGlobalErrors('住民票住所で市区町村を入力してください。'));
      }
      return;
    }

    const response = await axios.get(
      `${Urls.SEARCH_CITY_CODE_WITH_NAME_URL}?${serializeHttpGetParams({ query: city })}`
    );
    setCityCallback(response.data.payload.city);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export const fetchStandardMonthlyAmounts = applyFrom => async dispatch => {
  try {
    const res = await axios.get(Urls.SEARCH_STANDARD_MONTHLY_AMOUNTS, { params: { apply_from: applyFrom } });
    dispatch(setOptions({ healthStandardMonthlyAmounts: res.data.payload.standardMonthlyAmounts.health }));
    dispatch(setOptions({ pensionStandardMonthlyAmounts: res.data.payload.standardMonthlyAmounts.pension }));
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const isAllEmployeeChecked = state => {
  const currentUser = getCurrentUserInfo(state);
  const employeeList = _.filter(
    getList(REDUCER_NAME, state),
    employee => employee.email !== currentUser.email && employee.isDeletable
  );
  if (!employeeList.length) {
    return false;
  }
  return _.every(employeeList, { checked: true });
};

export const changeAllEmployeeChecked = newChecked => (dispatch, getState) => {
  const currentUser = getCurrentUserInfo(getState());
  const employeeList = getList(REDUCER_NAME, getState()) || [];
  const newEmployeeList = employeeList.map(
    employee =>
      employee.email !== currentUser.email && employee.isDeletable
        ? { ...employee, checked: newChecked }
        : { ...employee }
  );
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setList(REDUCER_NAME, { data: newEmployeeList, pageCount }));
};

export const changeEmployeeChecked = employeeId => (dispatch, getState) => {
  const employeeList = getList(REDUCER_NAME, getState()) || [];
  const newEmployeeList = employeeList.map(
    employee => (employee.id === employeeId ? { ...employee, checked: !employee.checked } : { ...employee })
  );
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setList(REDUCER_NAME, { data: newEmployeeList, pageCount }));
};

export const deleteCheckedEmployee = ownProps => async (dispatch, getState) => {
  try {
    await axios.post(Urls.EMPLOYEE_DELETE_BATCH, { deletes: getCheckedEmployees(getState()) });

    ownProps.hideModal();
    ownProps.changeToShowMode();
    dispatch(fetchEmployees());
    dispatch(setGlobalSuccesses('選択された従業員を削除しました。'));
  } catch (e) {
    if (_.get(e, 'response.data.errors')) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      dispatch(setGlobalErrors(_.get(e, 'response.data.error')));
    }
  }
};

export const fetchOfficeStationJobs = (interval = INITIAL_POLLING_INTERVAL) => async (dispatch, getState) => {
  const params = {
    job_name: 'officeStationGetEmployeeJob'
  };

  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.officeStationGetEmployeeJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchOfficeStationJobs(nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
  }
};

export const hideOfficeStationJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'officeStationGetEmployeeJob'
    });
    dispatch(fetchOfficeStationJobs());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const syncOfficeStation = data => async (dispatch, getState) => {
  try {
    const state = getState();
    const queries = takeEmployeesSearchQueries(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state));
    await axios.post(Urls.IMPORT_OFFICE_STATION_EMPLOYEE, { ...data, ...queries });
    dispatch(fetchOfficeStationJobs());
  } catch (e) {
    if (_.get(e, 'response.data.errors')) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      dispatch(setGlobalErrors('システムエラーが発生しました。'));
    }
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});

export const getIsNotJoinedHealthAndPension = state => {
  const health = selector(state, 'employeeInsurance', 'isJoinedHealthInsurance');
  const pension = selector(state, 'employeeInsurance', 'isJoinedPensionInsurance');
  return (health === false || health === '0') && (pension === false || pension === '0');
};

export const copyEmployeeAddress = () => async (dispatch, getState) => {
  const employee = getFormValues(EMPLOYEE_FORM)(getState()).employee;
  dispatch(changeForm(EMPLOYEE_FORM, 'residentCard.postalCodePart0', employee.postalCodePart0));
  dispatch(changeForm(EMPLOYEE_FORM, 'residentCard.postalCodePart1', employee.postalCodePart1));
  dispatch(changeForm(EMPLOYEE_FORM, 'residentCard.prefectureId', employee.prefectureId));
  dispatch(changeForm(EMPLOYEE_FORM, 'residentCard.city', employee.city));
  dispatch(changeForm(EMPLOYEE_FORM, 'residentCard.street', employee.street));
  dispatch(changeForm(EMPLOYEE_FORM, 'residentCard.building', employee.building));
  dispatch(changeForm(EMPLOYEE_FORM, 'residentCard.addressKana', employee.addressKana));
};
