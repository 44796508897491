import React from 'react';
import { connect } from 'react-redux';
import { blur, touch, Field, reset } from 'redux-form';
import classNames from 'classnames';
import _ from 'lodash';
import MediaQuery from 'react-responsive';

import ActionButton from 'jbc-front/components/ActionButton';
import { Edit, Strage, MinusCircle } from 'jbc-front/components/icons';
import { concatParamsToUrl } from 'src/utils/Http';
import { EMPLOYEE_SHOW } from 'src/constants/EndpointUrls';

import { ModifyIcon } from 'src/icons';
import { toHourFormat, toFloatFormat } from 'src/utils/Completion';
import { isFloatFormat, isHourFormat, isNumberOfDaysFormat, isNumberOfTimesFormat } from 'src/utils/CustomValidators';
import { ErrorsSection } from 'src/components';
import {
  REDUCER_NAME,
  setActionMode,
  getImportJobs,
  getClientSyncTasks
} from 'src/reducers/employees/employeeAttendances';
import { EMPLOYEE_ATTENDANCE_FORM } from 'src/constants/FormNames';
import { getItemExtra } from 'src/reducers/common/itemReducer';
import * as CssClassNames from 'src/constants/CssClassNames';
import MemoList from 'src/features/Memos';
import AllAttendances from './AllAttendances';
import Confirmed from '../../../components/Confirmed';
import styles from './EmployeeAttendanceForm.scss';

const allowanceItemValidate = unitType => {
  switch (unitType) {
    case 'time':
      return [isHourFormat];
    case 'number_of_days':
      return [isNumberOfDaysFormat];
    case 'number_of_times':
      return [isNumberOfTimesFormat];
    default:
      return [isFloatFormat];
  }
};

const allowanceItemFormat = (unitType, value) => {
  switch (unitType) {
    case 'time':
      return toHourFormat(value);
    default:
      return toFloatFormat(value);
  }
};

const renderValue = ({ input, disabled, meta: { touched, error }, modified }) => (
  <div {...(touched && error ? { className: `invalid ${styles.def}` } : { className: styles.def })}>
    <div className="m-table-obvious-modified">
      <ModifyIcon modified={modified} size={14} />
    </div>
    <input {...input} disabled={disabled} />
  </div>
);

const RowBlank = () => (
  <tr>
    <th>&nbsp;</th>
    <td className="m-table-obvious-blank">&nbsp;</td>
  </tr>
);

const Row = props => {
  const { label, name, validate, onBlur, disabled, modified } = props;
  return (
    <tr>
      <th>{label}</th>
      <td>
        <Field
          component={renderValue}
          type="text"
          name={name}
          validate={validate}
          onBlur={onBlur}
          disabled={disabled}
          modified={modified}
        />
      </td>
    </tr>
  );
};

const EmployeeAttendanceForm = props => {
  const {
    dispatch,
    handleSubmit,
    submit,
    errors,
    pristine,
    submitting,
    employeeId,
    employeeDisplayName,
    employeeAttendanceIsConfirmed,
    allowanceItems,
    actionMode,
    setMode,
    resetForm,
    attendanceStartDate,
    attendanceEndDate,
    paymentDate,
    isEmployeePayConfirmed,
    allAttendances,
    employeeAttendanceId,
    attendanceSyncJobs,
    attendanceImportJobs
  } = props;
  const employeeDetailUrl = concatParamsToUrl(EMPLOYEE_SHOW, { id: employeeId });
  const numberOfDaysLength = allowanceItems.numberOfDays.length;
  const timeLength = allowanceItems.time.length;
  const numberOfTimesLength = allowanceItems.numberOfTimes.length;
  const maxLength = _.max([numberOfDaysLength, timeLength, numberOfTimesLength]);
  const isViewMode = actionMode === 'view';

  const hasRunningJobs =
    !_.isEmpty(attendanceSyncJobs.inProgress) ||
    !_.isEmpty(attendanceSyncJobs.waiting) ||
    !_.isEmpty(attendanceImportJobs.inProgress) ||
    !_.isEmpty(attendanceImportJobs.waiting);
  const disabledEditBtn = isEmployeePayConfirmed || hasRunningJobs || employeeAttendanceIsConfirmed;

  return (
    <form className={classNames(CssClassNames.FORM, CssClassNames.CLIENT_FORM)} onSubmit={handleSubmit}>
      <ErrorsSection errors={errors} />
      <div>
        <div className={styles.name}>
          <div className={styles.memo}>
            <MemoList employeeId={employeeId} size={44} />
          </div>
          <div className={styles.nameFull}>
            <a href={employeeDetailUrl}>{employeeDisplayName}</a>
          </div>
        </div>
        <div className={styles.container}>
          <div className={`${styles.confirmed} ${!employeeAttendanceIsConfirmed && styles.confirmedDisable}`}>
            <Confirmed />
          </div>
          <AllAttendances
            {...{ attendanceStartDate, attendanceEndDate, paymentDate, employeeAttendanceId, allAttendances }}
          />
        </div>
      </div>

      <div className={styles.buttons}>
        <div className={styles.buttonsLeft}>
          {isViewMode && (
            <ActionButton
              onClick={() => setMode('edit')}
              icon={<Edit size={15} />}
              disabled={disabledEditBtn}
              disabledReason={hasRunningJobs ? '勤怠情報を更新中なので編集できません' : null}
            >
              編集
            </ActionButton>
          )}
          {!isViewMode && (
            <React.Fragment>
              <ActionButton
                onClick={submit}
                icon={<Strage size={15} />}
                disabled={pristine || submitting || disabledEditBtn}
                disabledReason={hasRunningJobs ? '勤怠情報を更新中なので編集できません' : null}
              >
                保存
              </ActionButton>
              <ActionButton
                onClick={() => {
                  resetForm();
                  setMode('view');
                }}
                disabled={submitting}
                icon={<MinusCircle size={15} />}
              >
                キャンセル
              </ActionButton>
            </React.Fragment>
          )}
        </div>
      </div>

      <div className={styles.payment}>
        <div className={styles.paymentClm}>
          <table className="m-table-obvious">
            <thead>
              <tr>
                <th colSpan="2">労働日数</th>
              </tr>
            </thead>
            <tbody>
              {allowanceItems.numberOfDays.map(item => (
                <Row
                  key={item.name}
                  label={item.label}
                  name={item.name.toString()}
                  validate={allowanceItemValidate(item.unitType)}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    dispatch(
                      blur(EMPLOYEE_ATTENDANCE_FORM, item.name, allowanceItemFormat(item.unitType, previousValue))
                    );
                    dispatch(touch(EMPLOYEE_ATTENDANCE_FORM, item.name));
                  }}
                  disabled={isViewMode}
                />
              ))}
              <MediaQuery minWidth={737}>
                {_.times(maxLength - numberOfDaysLength, t => <RowBlank key={t} />)}
              </MediaQuery>
            </tbody>
          </table>
        </div>

        <div className={styles.paymentClm}>
          <table className="m-table-obvious">
            <thead>
              <tr>
                <th colSpan="2">労働時間</th>
              </tr>
            </thead>
            <tbody>
              {allowanceItems.time.map(item => (
                <Row
                  key={item.name}
                  label={item.label}
                  name={item.name.toString()}
                  validate={allowanceItemValidate(item.unitType)}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    dispatch(
                      blur(EMPLOYEE_ATTENDANCE_FORM, item.name, allowanceItemFormat(item.unitType, previousValue))
                    );
                    dispatch(touch(EMPLOYEE_ATTENDANCE_FORM, item.name));
                  }}
                  disabled={isViewMode}
                />
              ))}
              <MediaQuery minWidth={737}>{_.times(maxLength - timeLength, t => <RowBlank key={t} />)}</MediaQuery>
            </tbody>
          </table>
        </div>

        <div className={styles.paymentClm}>
          <table className="m-table-obvious">
            <thead>
              <tr>
                <th colSpan="2">回数</th>
              </tr>
            </thead>
            <tbody>
              {allowanceItems.numberOfTimes.map(item => (
                <Row
                  key={item.name}
                  label={item.label}
                  name={item.name.toString()}
                  validate={allowanceItemValidate(item.unitType)}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    dispatch(
                      blur(EMPLOYEE_ATTENDANCE_FORM, item.name, allowanceItemFormat(item.unitType, previousValue))
                    );
                    dispatch(touch(EMPLOYEE_ATTENDANCE_FORM, item.name));
                  }}
                  disabled={isViewMode}
                />
              ))}
              <MediaQuery minWidth={737}>
                {_.times(maxLength - numberOfTimesLength, t => <RowBlank key={t} />)}
              </MediaQuery>
            </tbody>
          </table>
        </div>
      </div>
    </form>
  );
};

export default connect(
  state => ({
    actionMode: getItemExtra(REDUCER_NAME, state).actionMode || 'view',
    attendanceImportJobs: getImportJobs(state) || {},
    attendanceSyncJobs: getClientSyncTasks(state) || {}
  }),
  dispatch => ({
    setMode: mode => dispatch(setActionMode(mode)),
    resetForm: () => dispatch(reset(EMPLOYEE_ATTENDANCE_FORM))
  })
)(EmployeeAttendanceForm);
