/* eslint-disable react/no-array-index-key */

import React from 'react';
import { NEW_LINE_CHARACTERS } from 'src/constants/Generals';
import { Section } from 'jbc-front/components/Form';

const DEFAULT_TITLE = '入力内容に誤りがあります';
const FormErrors = ({ errors, title }) => (
  <React.Fragment>
    {errors &&
      errors.length > 0 && (
        <Section title={title} error>
          <ul>{errors.map(error => error.split(NEW_LINE_CHARACTERS).map(err => <li key={err}>{err}</li>))}</ul>
        </Section>
      )}
  </React.Fragment>
);
FormErrors.defaultProps = {
  title: DEFAULT_TITLE
};
export default FormErrors;
