import React from 'react';
import { connect } from 'react-redux';

import { getProcedureList, completeProcedure } from 'src/reducers/procedures/procedures';
import { Clock, Pulldown } from 'jbc-front/components/icons';
import CompleteTodoModal from './CompleteTodoModal';
import styles from './ProcedureList.scss';

const MONTHLY_CHANGE_NOTIFICATION = 'monthly_change_notification'; // 月額変更届
const ANNUAL_RENEWAL_OF_LABOR_INSURANCE = 'annual_renewal_of_labor_insurance'; // 労働保険の年度更新
const CALCULATION_BASIS_NOTIFICATION = 'calculation_basis_notification'; // 算定基礎届
const WITHHOLDING_SLIP_UNCONFIRM = 'withholding_slip_unconfirm'; // 源泉徴収確定待ち
const WITHHOLDING_SLIP_UPDATED = 'withholding_slip_updated'; // 源泉徴収更新済み給与・賞与あり
const INHABITANT_TAX = 'inhabitant_tax'; // 住民税
const APPLY_BASE_AMOUNT_FOR_CALC = 'apply_base_amount_for_calc'; // 算定基礎額反映

class Procedure extends React.Component {
  state = {
    showModal: false
  };
  openModal = () => {
    this.setState({ ...this.state, showModal: true });
  };
  closeModal = () => {
    this.setState({ ...this.state, showModal: false });
  };
  procedureAction = () => {
    const { procedureWrapper } = this.props;
    switch (procedureWrapper.procedure.procedureType) {
      case ANNUAL_RENEWAL_OF_LABOR_INSURANCE:
        return 'を行ってください。';
      case INHABITANT_TAX:
        return 'を更新してください。';
      case APPLY_BASE_AMOUNT_FOR_CALC:
        return 'を反映してください。';
      case WITHHOLDING_SLIP_UNCONFIRM:
      case WITHHOLDING_SLIP_UPDATED:
        return '';
      default:
        return 'を提出してください。';
    }
  };
  procedureConfirmUrl = () => {
    const procedure = this.props.procedureWrapper.procedure;
    switch (procedure.procedureType) {
      case MONTHLY_CHANGE_NOTIFICATION:
        return `/reports/monthly_revisions?revision_year_month=${procedure.year}_${procedure.month}`;
      case CALCULATION_BASIS_NOTIFICATION:
        return '/reports/notification_of_base_amount_revisions';
      case ANNUAL_RENEWAL_OF_LABOR_INSURANCE:
        return '/reports/labor_insurance_summaries';
      case WITHHOLDING_SLIP_UNCONFIRM:
      case WITHHOLDING_SLIP_UPDATED:
        return '/reports/withholding_slips';
      case APPLY_BASE_AMOUNT_FOR_CALC:
        return '/reports/notification_of_base_amount_revisions';
      default:
        return '/employees';
    }
  };
  isInhabitantTaxProcedure = () => {
    const { procedureWrapper } = this.props;
    return procedureWrapper.procedure.procedureType === INHABITANT_TAX;
  };
  render() {
    const { procedureWrapper } = this.props;
    return (
      <div className={styles.flex}>
        <div className={styles.left}>
          <label>
            <input type="checkbox" onChange={this.openModal} checked={false} className="m-checkbox-input" />
            <div className={`${styles.parts} m-checkbox-parts`}>
              <div className={styles.baloon}>このタスクを完了する</div>
            </div>
          </label>
        </div>
        <div className={styles.right}>
          <span>
            {this.isInhabitantTaxProcedure() ? (
              <span>
                <a href={this.procedureConfirmUrl()} className={styles.link} target="_blank">
                  従業員一覧
                </a>
                から、「住民税」の金額
              </span>
            ) : (
              <a href={this.procedureConfirmUrl()} className={styles.link} target="_blank">
                {procedureWrapper.label}
              </a>
            )}
            <span>{this.procedureAction()}</span>
          </span>
          <CompleteTodoModal
            isOpen={this.state.showModal}
            hideModal={this.closeModal}
            todo={procedureWrapper.procedure}
            completeTodo={completeProcedure}
          />
          {procedureWrapper.deadline && (
            <div className={styles.deadline}>
              <span className={styles.clock}>
                <Clock size={12} />
              </span>
              {procedureWrapper.deadline}
            </div>
          )}
          <div className={styles.arrow}>
            <Pulldown size={10} />
          </div>
        </div>
      </div>
    );
  }
}

const ProcedureList = ({ notCompletedProcedures }) => {
  if (!notCompletedProcedures) {
    return null;
  }
  return (
    <div className={styles.procedurelist}>
      {notCompletedProcedures.map(procedureWrapper => (
        <Procedure procedureWrapper={procedureWrapper} key={procedureWrapper.key} />
      ))}
    </div>
  );
};

export default connect(state => ({
  notCompletedProcedures: getProcedureList(state)
}))(ProcedureList);
