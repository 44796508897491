import { connect } from 'react-redux';

import { getItem, getItemErrors, isLoading } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, editReportHealthInsuranceBonusItem } from 'src/reducers/reports/reportEditHealthInsuranceBonus';

import EditReportHealthInsuranceBonusForm from '../components/EditReportHealthInsuranceBonusForm';

const mapStateToProps = state => ({
  initialValues: getItem(REDUCER_NAME, state),
  errors: getItemErrors(REDUCER_NAME, state),
  loading: isLoading(REDUCER_NAME, state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(editReportHealthInsuranceBonusItem(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReportHealthInsuranceBonusForm);
