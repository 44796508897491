import React from 'react';
import _ from 'lodash';

import Hint from 'jbc-front/components/Hint';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { REDUCER_NAME, fetchRevisions } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import { FormErrors } from 'src/components';
import { REPORT_INDEX } from 'src/constants/EndpointUrls';
import {
  takeNotificationOfBaseAmountRevisionsSearchQueries,
  FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH
} from 'src/reducers/searchForm';
import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import List from '../containers/ListContainer';
import Header from '../containers/HeaderContainer';
import styles from './ShowRevisions.scss';
import ImportCsvJobStatus from '../containers/ImportCsvJobStatusContainer';
import Loading from '../../../components/Loading';

export default class ShowRevisions extends React.Component {
  // エラータイトル文言を取得する
  // undefinedを返す場合は、全てFormErrorsに準じた挙動となる
  getErrorTitle(errors) {
    if (_.isEmpty(errors)) {
      return undefined;
    }
    // エラーが全て給与未確定のものであれば、エラー文言をデータなしに変更する
    const res = errors.every(error => error.match(/^.+支給給与を確定してください。$/));
    return res ? 'データがありません' : undefined;
  }

  render() {
    const {
      initialValues: formValues,
      yearMonth,
      applicableOffice,
      location,
      dispatch,
      isFetchingRevisions,
      fetchPdfJobStatus,
      errors
    } = this.props;

    return (
      <React.Fragment>
        <div className="l-wrap-xl l-contents-wrap">
          <SearchDetector
            location={location}
            yearMonth={yearMonth}
            applicableOffice={applicableOffice}
            willReceivePropsDetectingJudger={(prevProps, nextProps) =>
              nextProps.yearMonth !== undefined &&
              nextProps.applicableOffice !== undefined &&
              (prevProps.yearMonth === undefined ||
                prevProps.applicableOffice === undefined ||
                nextProps.location !== prevProps.location)
            }
            onDetect={() => {
              dispatch(fetchRevisions(takeNotificationOfBaseAmountRevisionsSearchQueries(formValues)));
              fetchPdfJobStatus(yearMonth, applicableOffice);
            }}
          />
          <div>
            <div className="u-mb20">
              <BreadCrumb>
                <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
                <BreadCrumb.Item label="算定基礎届" />
              </BreadCrumb>
            </div>
            <div>
              <div className={styles.header}>
                <h1 className="m-title-main u-mb50">算定基礎届（定時決定）</h1>
                <Hint
                  width={400}
                  text={
                    <p>
                      4～6月に支給した給与情報を7月1日～10日の間に年金事務所（健康保険組合）に報告するための書類です。
                      <br />
                      その3ヶ月に支給された平均の給与を基に、社会保険料を計算するための「標準報酬月額」が決まります。
                      <br />
                      決定後の標準報酬月額は9月分（10月支給分）から1年間適用されます。
                      <br />
                      <br />
                      詳細は
                      <a
                        href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360005964674"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        コチラ
                      </a>
                    </p>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <ImportCsvJobStatus />
        <div className="l-wrap-xl l-contents-wrap">
          <Header />
          <FormErrors title={this.getErrorTitle(errors)} errors={errors} />
          {isFetchingRevisions ? (
            <Loading />
          ) : (
            _.isEmpty(errors) && (
              <div>
                <List applicableOffice={applicableOffice} />
                <SearchFormPaginator
                  formName={FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH}
                  reducerName={REDUCER_NAME}
                />
              </div>
            )
          )}
        </div>
      </React.Fragment>
    );
  }
}
