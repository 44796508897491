import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { fetchBonusReportsPdf, fetchBonusReportsZip } from 'src/reducers/employees/employeeBonus';
import { BONUS_REPORTS_DOWNLOAD_FORM } from 'src/constants/FormNames';
import BonusReportsCreateModal from '../components/BonusReportsDownloadModal';

const BonusReportsCreateForm = reduxForm({
  form: BONUS_REPORTS_DOWNLOAD_FORM
})(BonusReportsCreateModal);

export default connect(null, (dispatch, ownProps) => ({
  downloadPdf: () => {
    dispatch(fetchBonusReportsPdf(ownProps.clientBonusId));
  },
  downloadZip: () => {
    dispatch(fetchBonusReportsZip(ownProps.clientBonusId));
  }
}))(BonusReportsCreateForm);
