import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { updateReportItem, resetReportItem } from 'src/reducers/reports/reportHealthInsuranceBonus';
import { REPORT_HEALTH_INSURANCE_BONUS_FORM } from 'src/constants/FormNames';

import EditBonusModalForm from '../../components/Modal/EditBonusModalForm';

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    bonusId: ownProps.bonus.id,
    currencyAmount: ownProps.bonus.currencyBonus,
    genbutsuAmount: ownProps.bonus.genbutsuBonus,
    isOver70YearsOld: ownProps.bonus.over70 || false,
    isWorkInTwoOrMore: ownProps.bonus.isWorkInTwoOrMore || false,
    isAddUp: ownProps.bonus.isAddUp || false,
    firstPaymentDay: ownProps.bonus.firstPaymentDay,
    lockVersion: ownProps.bonus.reportItemLockVersion || 0
  },
  bonusId: ownProps.bonus.id,
  staffName: ownProps.bonus.name,
  errors: getFormValues(REPORT_HEALTH_INSURANCE_BONUS_FORM)(state)?.errors
});

const mapDispatchToProps = dispatch => ({
  onSubmit: formValue => {
    dispatch(updateReportItem(formValue));
  },
  onRollback: bonusId => {
    dispatch(resetReportItem(bonusId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditBonusModalForm);
