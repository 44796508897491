import { connect } from 'react-redux';
import { fetchDownloadEmployeeCsvJobStatus, getIsCreating } from 'src/reducers/employees/employeesAsync';
import { INITIAL_POLLING_INTERVAL } from 'src/constants/Generals';
import DownloadCsv from '../components/DownloadCsv';

const mapStateToProps = state => ({
  isCreating: getIsCreating(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fetchDownloadEmployeeCsvJobStatus: () => {
    dispatch(
      fetchDownloadEmployeeCsvJobStatus(INITIAL_POLLING_INTERVAL, ownProps.downloadEmployeeCsvJobInfo.uniqueString)
    );
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadCsv);
