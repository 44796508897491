import React from 'react';
import { CLIENT_SETTING_USING_PLAN } from 'src/constants/EndpointUrls';
import styles from './FreePlanRestriction.scss';

const FreePlanRestriction = () => (
  <div>
    無料プランのため利用できません。プランの変更は
    <a href={CLIENT_SETTING_USING_PLAN} className={styles.link}>
      こちら
    </a>
  </div>
);

export default FreePlanRestriction;
