import { connect } from 'react-redux';
import { takeBonusFromSearchForm } from 'src/reducers/searchForm';
import ConfirmBonus from 'src/features/EmployeeBonus/components/ConfirmBonus';
import { setGlobalErrors } from 'src/reducers/global';
import {
  confirmClientBonus,
  unconfirmClientBonus,
  setChangeConfirmBonusErrors
} from 'src/reducers/clients/clientBonus';
import { getJob } from 'src/reducers/JobStatus';
import { getEmployeeBonusList } from 'src/reducers/employees/employeeBonus';
import _ from 'lodash';

function mapStateToProps(state) {
  return {
    currentBonus: takeBonusFromSearchForm(state),
    employeeBonusList: getEmployeeBonusList(state),
    jobStatus: state.JobStatus.reportEmployeeBonusJob,
    runningJob: _.get(getJob(state), 'confirmClientBonusJob'),
    isAvailableOfficeStation: state.currentClient.item.data.isAvailableOfficeStation,
    clientOfficeStationSetting: state.currentClient.item.data.clientOfficeStationSetting
  };
}

function mapDispatchToProps(dispatch) {
  return {
    displayErrors: msg => dispatch(setGlobalErrors(msg)),
    setChangeConfirmBonusErrors: arr => dispatch(setChangeConfirmBonusErrors(arr)),
    unconfirmClientBonus: (id, rows, callBack) => dispatch(unconfirmClientBonus(id, rows, callBack)),
    confirmClientBonus: (id, data, rows, callBack) => dispatch(confirmClientBonus(id, data, rows, callBack))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBonus);
