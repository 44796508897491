import React, { useState, useEffect } from 'react';
import Loading from 'src/components/Loading';
import { isJobIncomplete, isJobSuccess } from 'src/reducers/JobStatus';
import Button from 'jbc-front/components/Button';
import BonusReportsCreateModalContainer from '../containers/BonusReportsCreateModalContainer';
import BonusReportsDownloadModalContainer from '../containers/BonusReportsDownloadModalContainer';
import styles from './DownloadBonusReports.scss';

const DownloadBonusReports = props => {
  const { job, clientBonusId, pollFetchReportJobStatus, isConfirmed } = props;
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

  useEffect(
    () => {
      pollFetchReportJobStatus(clientBonusId);
    },
    [clientBonusId]
  );

  // 作成中
  if (isJobIncomplete(job.status) === true) {
    return (
      <div className={styles.loading}>
        <Button primary widthWide disabled icon={<Loading />}>
          データ作成中
        </Button>
      </div>
    );
  }

  // 作成完了
  if (isJobSuccess(job.status) === true) {
    return (
      <React.Fragment>
        <div className={styles.button}>
          <Button onClick={() => setDownloadModalOpen(true)} primary widthWide>
            明細一括出力
          </Button>
        </div>
        <BonusReportsDownloadModalContainer
          isOpen={isDownloadModalOpen}
          hideModal={() => setDownloadModalOpen(false)}
          clientBonusId={clientBonusId}
        />
      </React.Fragment>
    );
  }

  // 作成
  return (
    <React.Fragment>
      <div className={styles.button}>
        <Button disabled={!isConfirmed} onClick={() => setCreateModalOpen(true)} primary widthWide>
          明細一括作成
        </Button>
      </div>
      <BonusReportsCreateModalContainer
        isOpen={isCreateModalOpen}
        hideModal={() => setCreateModalOpen(false)}
        clientBonusId={clientBonusId}
      />
    </React.Fragment>
  );
};
export default DownloadBonusReports;
