import React from 'react';
import { FormSection, Field } from 'redux-form';
import _ from 'lodash';

import { isMoneyNumber10, isEmployeeNumber5 } from 'src/utils/CustomValidators';
import { numberWithComma } from 'src/utils/Utils';
import EditableTextField from './EditableTextField';
import styles from './MonthlySummary.scss';

class MonthlySummaryEmploymentInsurance extends React.Component {
  constructor(props) {
    super(props);
    const { monthlySummary } = props;
    this.state = { ...monthlySummary };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  calculation(params) {
    const calcValues = {
      totalAccidentInsuranceEmployeeCount: this.calcTotalAccidentInsuranceCount(params),
      totalAccidentInsuranceEmployeePay: this.calcTotalAccidentInsurancePay(params),
      totalEmploymentInsuranceEmployeeCount: this.calcTotalEmploymentInsuranceCount(params),
      totalEmploymentInsuranceEmployeePay: this.calcTotalEmploymentInsurancePay(params)
    };

    this.setState({
      ...params,
      ...calcValues
    });

    return { ...params, ...calcValues };
  }

  // 労災保険及び一般拠出金の合計人数
  calcTotalAccidentInsuranceCount(params) {
    return this.sum([
      params.accidentInsuranceEmployeeCommonCount,
      params.accidentInsuranceEmployeeTreatedCount,
      params.accidentInsuranceEmployeeTemporaryCount
    ]);
  }

  // 労災保険及び一般拠出金の合計賃金
  calcTotalAccidentInsurancePay(params) {
    return this.sum([
      params.accidentInsuranceEmployeeCommonPay,
      params.accidentInsuranceEmployeeTreatedPay,
      params.accidentInsuranceEmployeeTemporaryPay
    ]);
  }

  // 雇用保険の合計人数
  calcTotalEmploymentInsuranceCount(params) {
    return this.sum([params.employmentInsuranceEmployeeCommonCount, params.employmentInsuranceEmployeeTreatedCount]);
  }

  // 雇用保険の合計賃金
  calcTotalEmploymentInsurancePay(params) {
    return this.sum([params.employmentInsuranceEmployeeCommonPay, params.employmentInsuranceEmployeeTreatedPay]);
  }

  sum(list) {
    // すべてが空の場合は空文字を返す
    if (_.every(list, o => !_.isNumber(o))) return '';

    _.filter(list, o => {
      _.toInteger(o);
    });

    return _.reduce(list, (sum, n) => sum + n, 0);
  }

  calcYearlySummary(changedParams) {
    const params = {
      ...this.state,
      ...changedParams
    };

    this.props.calcYearlySummary(this.calculation(params));
  }

  // 雇用保険資格のある従業員の人数変更イベント
  onChangeEmploymentInsuranceEmployeeCommonCount(value) {
    this.calcYearlySummary({ employmentInsuranceEmployeeCommonCount: _.toInteger(value) });
  }

  // 雇用保険資格のある従業員の賃金変更イベント
  onChangeEmploymentInsuranceEmployeeCommonPay(value) {
    this.calcYearlySummary({ employmentInsuranceEmployeeCommonPay: _.toInteger(value) });
  }

  // 雇用保険資格のある役員の人数変更イベント
  onChangeEmploymentInsuranceEmployeeTreatedCount(value) {
    this.calcYearlySummary({ employmentInsuranceEmployeeTreatedCount: _.toInteger(value) });
  }

  // 雇用保険資格のある役員の賃金変更イベント
  onChangeEmploymentInsuranceEmployeeTreatedPay(value) {
    this.calcYearlySummary({ employmentInsuranceEmployeeTreatedPay: _.toInteger(value) });
  }

  // 雇用保険資格のある役員の賃金変更イベント
  onChangeEmploymentInsuranceEmployeeAgedCount(value) {
    this.calcYearlySummary({ employmentInsuranceEmployeeAgedCount: _.toInteger(value) });
  }

  // 雇用保険資格のある役員の賃金変更イベント
  onChangeEmploymentInsuranceEmployeeAgedPay(value) {
    this.calcYearlySummary({ employmentInsuranceEmployeeAgedPay: _.toInteger(value) });
  }

  displaySnap(attr, isInput) {
    const { actionMode, monthlySummary, monthlySummarySnap } = this.props;
    const style = actionMode === 'SHOW' ? styles.snap_show : styles.snap_edit;

    if (monthlySummarySnap === '') {
      return <div className={style}>{`(修正前: ー )`}</div>;
    }
    if (monthlySummary[isInput]) {
      return <div className={style}>{`(修正前: ${numberWithComma(monthlySummarySnap[attr])})`}</div>;
    }
    return null;
  }

  render() {
    const { actionMode, monthlySummary, isLessThan2020 } = this.props;
    return (
      <FormSection name={`monthlySummaries.${monthlySummary.id}`}>
        <tr key={`monthly_summary_${monthlySummary.id}`}>
          <th>
            {monthlySummary.paymentType === 'pay' && monthlySummary.displayMonthlySummaryDate}
            {monthlySummary.paymentType === 'bonus' && `賞与 ${monthlySummary.displayMonthlySummaryDate}`}
          </th>
          <td>
            {monthlySummary.paymentType === 'pay' && (
              <React.Fragment>
                <Field
                  name="employmentInsuranceEmployeeCommonCount"
                  showValue={monthlySummary.employmentInsuranceEmployeeCommonCount}
                  showMode={actionMode}
                  inputWidth={70}
                  validate={[isEmployeeNumber5]}
                  component={EditableTextField}
                  editProps={{ maxLength: 5 }}
                  onChange={e => {
                    this.onChangeEmploymentInsuranceEmployeeCommonCount(e.target.value);
                  }}
                  onBlur={e => {
                    e.preventDefault();
                    this.onChangeEmploymentInsuranceEmployeeCommonCount(e.target.value);
                  }}
                />
                {this.displaySnap(
                  'employmentInsuranceEmployeeCommonCount',
                  'isInputEmploymentInsuranceEmployeeCommonCount'
                )}
              </React.Fragment>
            )}
          </td>
          <td>
            <React.Fragment>
              <Field
                name="employmentInsuranceEmployeeCommonPay"
                showValue={monthlySummary.employmentInsuranceEmployeeCommonPay}
                showMode={actionMode}
                inputWidth={70}
                validate={[isMoneyNumber10]}
                component={EditableTextField}
                editProps={{ maxLength: 10 }}
                onChange={e => {
                  this.onChangeEmploymentInsuranceEmployeeCommonPay(e.target.value);
                }}
                onBlur={e => {
                  e.preventDefault();
                  this.onChangeEmploymentInsuranceEmployeeCommonPay(e.target.value);
                }}
              />
              {this.displaySnap('employmentInsuranceEmployeeCommonPay', 'isInputEmploymentInsuranceEmployeeCommonPay')}
            </React.Fragment>
          </td>
          <td>
            {monthlySummary.paymentType === 'pay' && (
              <React.Fragment>
                <Field
                  name="employmentInsuranceEmployeeTreatedCount"
                  showValue={monthlySummary.employmentInsuranceEmployeeTreatedCount}
                  showMode={actionMode}
                  inputWidth={70}
                  validate={[isEmployeeNumber5]}
                  component={EditableTextField}
                  editProps={{ maxLength: 5 }}
                  onChange={e => {
                    this.onChangeEmploymentInsuranceEmployeeTreatedCount(e.target.value);
                  }}
                  onBlur={e => {
                    e.preventDefault();
                    this.onChangeEmploymentInsuranceEmployeeTreatedCount(e.target.value);
                  }}
                />
                {this.displaySnap(
                  'employmentInsuranceEmployeeTreatedCount',
                  'isInputEmploymentInsuranceEmployeeTreatedCount'
                )}
              </React.Fragment>
            )}
          </td>
          <td>
            <React.Fragment>
              <Field
                name="employmentInsuranceEmployeeTreatedPay"
                showValue={monthlySummary.employmentInsuranceEmployeeTreatedPay}
                showMode={actionMode}
                inputWidth={70}
                validate={[isMoneyNumber10]}
                component={EditableTextField}
                editProps={{ maxLength: 10 }}
                onChange={e => {
                  this.onChangeEmploymentInsuranceEmployeeTreatedPay(e.target.value);
                }}
                onBlur={e => {
                  e.preventDefault();
                  this.onChangeEmploymentInsuranceEmployeeTreatedPay(e.target.value);
                }}
              />
              {this.displaySnap(
                'employmentInsuranceEmployeeTreatedPay',
                'isInputEmploymentInsuranceEmployeeTreatedPay'
              )}
            </React.Fragment>
          </td>
          <td>{numberWithComma(this.state.totalEmploymentInsuranceEmployeeCount)}</td>
          <td>{numberWithComma(this.state.totalEmploymentInsuranceEmployeePay)}</td>
          {isLessThan2020 && (
            <React.Fragment>
              <td>
                {monthlySummary.paymentType === 'pay' && (
                  <React.Fragment>
                    <Field
                      name="employmentInsuranceEmployeeAgedCount"
                      showValue={monthlySummary.employmentInsuranceEmployeeAgedCount}
                      showMode={actionMode}
                      inputWidth={70}
                      validate={[isEmployeeNumber5]}
                      component={EditableTextField}
                      editProps={{ maxLength: 5 }}
                      onChange={e => {
                        this.onChangeEmploymentInsuranceEmployeeAgedCount(e.target.value);
                      }}
                      onBlur={e => {
                        this.onChangeEmploymentInsuranceEmployeeAgedCount(e.target.value);
                      }}
                    />
                    {this.displaySnap(
                      'employmentInsuranceEmployeeAgedCount',
                      'isInputEmploymentInsuranceEmployeeAgedCount'
                    )}
                  </React.Fragment>
                )}
              </td>
              <td>
                <React.Fragment>
                  <Field
                    name="employmentInsuranceEmployeeAgedPay"
                    showValue={monthlySummary.employmentInsuranceEmployeeAgedPay}
                    showMode={actionMode}
                    inputWidth={70}
                    validate={[isMoneyNumber10]}
                    component={EditableTextField}
                    editProps={{ maxLength: 10 }}
                    onChange={e => {
                      this.onChangeEmploymentInsuranceEmployeeAgedPay(e.target.value);
                    }}
                    onBlur={e => {
                      this.onChangeEmploymentInsuranceEmployeeAgedPay(e.target.value);
                    }}
                  />
                  {this.displaySnap('employmentInsuranceEmployeeAgedPay', 'isInputEmploymentInsuranceEmployeeAgedPay')}
                </React.Fragment>
              </td>
            </React.Fragment>
          )}
        </tr>
      </FormSection>
    );
  }
}
export default MonthlySummaryEmploymentInsurance;
