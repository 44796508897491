import { getFormValues, reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import connect from 'react-redux/es/connect/connect';

import { getIsLoading } from 'src/reducers/common/listReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  takeEmployeesSearchQueries,
  takeEmployeesSearchQueriesFromLocationMyNumber,
  takeResetFormQueries,
  FORM_NAME_EMPLOYEES_SEARCH
} from 'src/reducers/searchForm';
import { REDUCER_NAME, fetchEmployees, getLastCsvJobStatus } from 'src/reducers/my_numbers/myNumbers';
import MyNumberList from '../components/MyNumberList';

const mapStateToProps = state => ({
  initialValues: {
    ...takeEmployeesSearchQueriesFromLocationMyNumber(state.router.location, true),
    ...getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state)
  },
  resetValues: takeResetFormQueries(state, takeEmployeesSearchQueriesFromLocationMyNumber, true),
  location: state.router.location,
  isListLoading: getIsLoading(REDUCER_NAME, state),
  formName: FORM_NAME_EMPLOYEES_SEARCH,
  reducerName: REDUCER_NAME,
  anyJobRunning: getSelectOptions(state, 'anyJobRunning')
});

const mapDispatchToProps = dispatch => ({
  searchEmployees: formValues => {
    dispatch(fetchEmployees(takeEmployeesSearchQueries(formValues)));
  },
  getLastCsvJobStatus: notNotifiedJob => {
    dispatch(getLastCsvJobStatus(notNotifiedJob));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_EMPLOYEES_SEARCH,
    enableReinitialize: true
  })
)(MyNumberList);
