import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';

import { IMPORT_FROM_ROUMU } from 'src/constants/FormNames';
import { setGlobalErrors } from 'src/reducers/global';

import ImportForm from '../components/ImportFromLmsModal';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: async () => {
    try {
      await ownProps.onSubmit();
      window.location.reload();
    } catch (err) {
      const msg = _.get(err, 'response.data.errors.messages[0]', '同期に失敗しました');
      dispatch(setGlobalErrors(msg));
    }
  }
});

export default compose(
  connect(null, mapDispatchToProps),
  reduxForm({
    form: IMPORT_FROM_ROUMU
  })
)(ImportForm);
