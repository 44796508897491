import React from 'react';
import { connect } from 'react-redux';
import { FormSection, getFormValues, change as formChange } from 'redux-form';
import get from 'lodash/get';

import { TextField, RadioField, CheckboxField, Label } from 'jbc-front/components/Form';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import styles from './PaymentBankItemSection.scss';

const TRANSFER_TYPES = [
  { value: 'all', label: '全額' },
  { value: 'fixed', label: '固定額' },
  {
    value: 'remain',
    label: (
      <Label
        text="残額"
        noteWidth={500}
        note="「固定額」を設定する場合は、他の振込口座か現金を「残額」として設定してください。計算後、残った額は全て「残額」に設定された方法で支給されます。また、残額を2箇所以上設定する事はできません。"
      />
    )
  }
];

const TransferBankSelect = ({
  employeeBankList,
  clientBanks,
  currentClientBankId,
  setFurikomiSaki,
  index,
  totalEmployeeBanksLength
}) => (
  <div className="u-pc-pb20 u-sp-pb15">
    {setFurikomiSaki(employeeBankList, index, totalEmployeeBanksLength)}
    {clientBanks.length === 0 && <div>振込元:事業所の振込元口座はまだ設定されていません。</div>}
    {clientBanks.length === 1 && (
      <div>振込元 : {get(clientBanks.find(item => item.value === currentClientBankId), 'label')}</div>
    )}
    {clientBanks.length > 1 && (
      <ExtensionSelectField
        widthAuto
        name="clientBankId"
        label="振込元"
        options={clientBanks}
        addBlankOption={false}
        placeholder="振込元を選択してください。"
      />
    )}
  </div>
);

class PaymentBankItemSection extends React.Component {
  setFurikomiSaki = (employeeBankList, index, totalEmployeeBanksLength) => {
    if (employeeBankList.length === 0) {
      return <div>振込先: 口座情報はまだ設定されていません。</div>;
    } else if (totalEmployeeBanksLength === 1 && index === 0) {
      // 口座も支給設定も一件しかない場合はプルダウンを表示せずに強制的に適用する
      const targetPaymentBank = this.props.currentPaymentBanks[0];
      if (targetPaymentBank.employeeBankId == null) {
        this.props.dispatch(
          formChange(
            EMPLOYEE_FORM,
            `${targetPaymentBank.paymentFor}PaymentBanks[0].employeeBankId`,
            employeeBankList[0].value
          )
        );
      }
      return <div>振込先 : {employeeBankList[0].label}</div>;
    }
    return (
      <ExtensionSelectField
        widthAuto
        name="employeeBankId"
        label="振込先"
        options={employeeBankList}
        placeholder="振込先を選択してください。"
      />
    );
  };
  onChangeChecked = () => {
    const { dispatch, formKey } = this.props;

    if (this.getValueOfField('checked')) {
      ['employeeBankId', 'transferType'].forEach(field => {
        dispatch(formChange(EMPLOYEE_FORM, `${formKey}.${field}`, null));
      });
    }
  };

  onChangeTransferType = event => {
    const { dispatch, changeOtherChecked, index } = this.props;
    if (event.target.value === 'all') {
      changeOtherChecked(dispatch, index);
    }
  };

  getValueOfField = field => {
    const { currentFormValues } = this.props;
    return (currentFormValues || {})[field];
  };

  selectedFixed = () => this.getValueOfField('transferType') === 'fixed';

  checked = () => this.getValueOfField('checked');

  clientBankId = () => parseInt(this.getValueOfField('clientBankId'), 10);

  selectableBankList = () => {
    const { currentPaymentBanks, employeeBankList, index } = this.props;
    const isSelectedByOther = item =>
      currentPaymentBanks.find((paymentBank, idx) => paymentBank.employeeBankId === item.value && idx !== index);
    const selectable = employeeBankList.filter(item => !isSelectedByOther(item));
    return selectable;
  };

  render() {
    const { formKey, clientBanks, label, index, employeeBankList } = this.props;
    return (
      <React.Fragment>
        <FormSection name={formKey}>
          <div>
            <div>
              <CheckboxField
                name="checked"
                label={label}
                normalize={value => !!value}
                onChange={this.onChangeChecked}
              />
            </div>
            {this.checked() && (
              <div>
                <RadioField
                  label="金額"
                  name="transferType"
                  options={TRANSFER_TYPES}
                  onChange={event => this.onChangeTransferType(event)}
                />
                <div className={this.selectedFixed() ? styles.fixedAmount : styles.amount}>
                  <TextField
                    name="fixedAmount"
                    label=""
                    yen
                    width={50}
                    validate={isMoneyNumber}
                    disabled={!this.selectedFixed()}
                  />
                  <LabelMapper name="fixedAmount" label="固定額は" />
                </div>
                {label !== '現金' && (
                  <TransferBankSelect
                    totalEmployeeBanksLength={employeeBankList.length}
                    employeeBankList={this.selectableBankList()}
                    clientBanks={clientBanks}
                    currentClientBankId={this.clientBankId()}
                    setFurikomiSaki={this.setFurikomiSaki}
                    index={index}
                  />
                )}
              </div>
            )}
          </div>
        </FormSection>
      </React.Fragment>
    );
  }
}

export default connect((state, ownProps) => ({
  currentPaymentBanks: get(getFormValues(EMPLOYEE_FORM)(state), ownProps.name),
  currentFormValues: get(getFormValues(EMPLOYEE_FORM)(state), ownProps.name)[ownProps.index]
}))(PaymentBankItemSection);
