import React from 'react';
import PropTypes from 'prop-types';

import { Delete } from 'jbc-front/components/icons';
import GeneralConfirmationBtn from './GeneralConfirmationBtn';

const DeleteBtn = ({ showingConfirmBox, confirmText, handleDelete, text, disabled, children, ...rest }) => {
  const innerChild = children || <Delete size={18} />;
  return (
    <GeneralConfirmationBtn
      {...{ showingConfirmBox, confirmText, text, disabled }}
      handleEvent={handleDelete}
      as="a"
      {...rest}
    >
      {innerChild}
    </GeneralConfirmationBtn>
  );
};

DeleteBtn.propTypes = {
  handleDelete: PropTypes.func.isRequired
};

DeleteBtn.defaultProps = {
  showingConfirmBox: true,
  confirmText: 'データを削除しますか？',
  text: '削除'
};

export default DeleteBtn;
