import React, { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

import scrollSpy from 'jbc-front/components/ScrollSpy';
import Button from 'jbc-front/components/Button';
import { Link, Pulldown } from 'jbc-front/components/icons';

import { JBC_LMS_CLIENT_IMPORT } from 'src/constants/EndpointUrls';
import Balloon from 'src/features/Employees/components/Balloon';
import FreePlanRestriction from 'src/components/FreePlanRestriction';
import { isFreePlan } from 'src/reducers/clients/currentClient';
import UnsyncModal from 'src/components/UnsyncModal';

import ImportFromLmsModalContainer from '../containers/ImportFromLmsModalContainer';
import styles from './ShowOffice.scss';

const syncsWithLms = () => Cookies.get('using_jbc_lms') === 'true';

const CoordinationLms = scrollSpy(({ isOnlyMainOffice, planType }) => {
  const [isOfficeCoordinationBalloonOpen, setIsOfficeCoordinationBalloonOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  return (
    <div>
      <div className={styles.button}>
        <Button
          onClick={() => {
            setIsOfficeCoordinationBalloonOpen(!isOfficeCoordinationBalloonOpen);
          }}
          className="ignore-react-onclickoutside"
          icon={<Link size={17} />}
          disabled={isFreePlan(planType)}
          disabledReason={isFreePlan(planType) ? <FreePlanRestriction /> : null}
          widthAuto
        >
          連携
        </Button>
        {isOfficeCoordinationBalloonOpen && (
          <Balloon hide={() => setIsOfficeCoordinationBalloonOpen(false)} right>
            <ul className={styles.links} style={{ cursor: 'pointer' }}>
              <li
                role="presentation"
                onClick={e => {
                  e.stopPropagation();
                  setIsImportModalOpen(true);
                  setIsOfficeCoordinationBalloonOpen(true);
                }}
              >
                <span>
                  <Pulldown size={8} />ジョブカン労務HRから取得
                </span>
              </li>
              <li>
                <a
                  href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360006242673#Contents2"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Pulldown size={8} />連携項目を確認する
                </a>
              </li>
            </ul>
          </Balloon>
        )}
      </div>
      {syncsWithLms() ? (
        <ImportFromLmsModalContainer
          isOpen={isImportModalOpen}
          isOnlyMainOffice={isOnlyMainOffice}
          hideModal={() => {
            setIsImportModalOpen(false);
            setIsOfficeCoordinationBalloonOpen(true);
          }}
          onSubmit={() => axios.post(JBC_LMS_CLIENT_IMPORT)}
        />
      ) : (
        <UnsyncModal
          isOpen={isImportModalOpen}
          hideModal={() => {
            setIsImportModalOpen(false);
            setIsOfficeCoordinationBalloonOpen(true);
          }}
        />
      )}
    </div>
  );
});

export default CoordinationLms;
