import React, { useState } from 'react';
import { connect } from 'react-redux';
import { arrayPush, autofill, FieldArray, reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { EDITABLE_LIST_FIELDS_NAME, REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_FORM } from 'src/constants/FormNames';
import { getExtras } from 'src/reducers/common/listReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  REDUCER_NAME,
  setSummaryTableShowMode,
  isLessThan2020Year,
  updateEmployeeDetails
} from 'src/reducers/reports/reportLaborInsurances';
import * as Urls from 'src/constants/EndpointUrls';
import { EditableListButtons } from 'src/components';
import { concatParamsToUrl } from 'src/utils/Http';
import { scrollToBottom } from 'src/utils/Utils';
import styles from './SummaryList.scss';
import RenderEmployeeDetail from './RenderEmployeeDetail';
import ConfirmEmployeeDetailRollbackModal from './ConfirmEmployeeDetailRollbackModal';

const EmployeeDetailList = props => {
  const {
    onEdit,
    onSave,
    onAddRow,
    employee,
    actionMode,
    laborInsuranceYear,
    errors,
    offices,
    fillOffice,
    isLessThan2020
  } = props;

  const [isRollbackModalOpen, setIsRollbackModalOpen] = useState(false);

  return (
    <div>
      <div className="l-wrap-xl l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item
              label="内訳一覧"
              path={concatParamsToUrl(Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS, { year: laborInsuranceYear })}
            />
            <BreadCrumb.Item label="月別内訳" />
          </BreadCrumb>
        </div>
        <h1 className="m-title-main">{employee.name}の月別内訳</h1>
        <div className="u-mt20">
          {laborInsuranceYear && (
            <div>
              <EditableListButtons
                mode={actionMode}
                errors={errors}
                onEdit={onEdit}
                onSave={onSave}
                onCancel={() => window.location.reload()}
                onAddRow={onAddRow}
                onRollback={() => setIsRollbackModalOpen(true)}
              />
              <ConfirmEmployeeDetailRollbackModal
                isOpen={isRollbackModalOpen}
                hideModal={() => setIsRollbackModalOpen(false)}
                laborInsuranceYear={laborInsuranceYear}
                employee={employee}
              />
              <div className="l-overflow-scroll">
                <table className={`${styles.table} u-mt30`}>
                  {isLessThan2020 ? (
                    <colgroup>
                      <col width={194} />
                      <col width={123} />
                      <col width={123} />
                      <col width={123} />
                      <col width={123} />
                      <col width={123} />
                      <col width={123} />
                    </colgroup>
                  ) : (
                    <colgroup>
                      <col width={194} />
                      <col width={148} />
                      <col width={148} />
                      <col width={148} />
                      <col width={148} />
                      <col width={148} />
                    </colgroup>
                  )}
                  <thead>
                    <tr>
                      <th />
                      <th colSpan="3">労災保険</th>
                      {isLessThan2020 ? <th colSpan="3">雇用保険</th> : <th colSpan="2">雇用保険</th>}
                      {actionMode === 'EDIT' && <th />}
                    </tr>
                    <tr>
                      <td />
                      <td>常用労働者</td>
                      <td>
                        役員で従業員<br />扱いの人
                      </td>
                      <td>臨時労働者</td>
                      <td>
                        雇用保険資格の<br />ある従業員
                      </td>
                      <td>
                        雇用保険資格の<br />ある役員
                      </td>
                      {isLessThan2020 && (
                        <td>
                          免除対象高年齢<br />労働者分
                        </td>
                      )}
                      {actionMode === 'EDIT' && <td />}
                    </tr>
                  </thead>
                  <tbody>
                    <FieldArray
                      name={EDITABLE_LIST_FIELDS_NAME}
                      component={RenderEmployeeDetail}
                      actionMode={actionMode}
                      offices={offices}
                      fillOffice={fillOffice}
                      laborInsuranceYear={laborInsuranceYear}
                      isLessThan2020={isLessThan2020}
                    />
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  actionMode: getExtras(REDUCER_NAME, state).summaryTableShowMode || 'SHOW',
  initialValues: { [EDITABLE_LIST_FIELDS_NAME]: ownProps.employeeDetails },
  offices: getSelectOptions(state, 'offices'),
  errors: getExtras(REDUCER_NAME, state).editEmployeeDetailErrors || [],
  isLessThan2020: isLessThan2020Year(ownProps.laborInsuranceYear)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onEdit: () => {
    dispatch(setSummaryTableShowMode('EDIT'));
  },
  onSave: () => {
    dispatch(updateEmployeeDetails(ownProps.laborInsuranceYear, ownProps.employee.id));
  },
  onAddRow: () => {
    dispatch(
      arrayPush(REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_FORM, EDITABLE_LIST_FIELDS_NAME, {
        officeId: '',
        monthlySummaryDate: '',
        targetType: '',
        accidentInsuranceEmployeeCommonPay: '',
        accidentInsuranceEmployeeTreatedPay: '',
        accidentInsuranceEmployeeTemporaryPay: '',
        employmentInsuranceEmployeeCommonPay: '',
        employmentInsuranceEmployeeTreatedPay: '',
        employmentInsuranceEmployeeAgedPay: ''
      })
    );
    scrollToBottom();
  },
  fillOffice: (field, value) => {
    dispatch(autofill(REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_FORM, `${field}.officeId`, value.id));
  }
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_FORM,
    enableReinitialize: true
  })
)(EmployeeDetailList);
