import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { EMPLOYEE_BONUS_FORM } from 'src/constants/FormNames';
import { deleteEmployeeBonus, updateEmployeeBonus } from 'src/reducers/employees/employeeBonus';
import { sumByAttr } from 'src/utils/Number';
import ActionButtonsSection from './ActionButtonsSection';
import BonusSection from './BonusSection';
import DeductionSection from './DeductionSection';
import SummarySection from './SummarySection';
import NoteSection from './NoteSection';
import styles from './EmployeeBonusForm.scss';

export const isModeEdit = mode => mode === 'edit';
export const isModeView = mode => mode === 'view';
export const isEditable = item => item.allowanceAmountType === 'individual';

const calcTotalDeductionAmount = deductions =>
  deductions.reduce((acc, item) => {
    if (item.formulaOrderType === 'flat_tax_reduction') {
      switch (item.formulaMethodName) {
        case 'flat_tax_reduction_amount':
          // 定額減税額だけはマイナスにする
          return acc - item.amount;
        case 'flat_tax_reduction_remainder_tax':
          // 減額後の所得税額は加算しない
          return acc;
        default:
      }
    }
    return acc + item.amount;
  }, 0);

const RawEmployeeBonusForm = props => {
  const {
    handleSubmit,
    submit,
    mode,
    reset,
    initialValues,
    changeFormMode,
    rollbackInputForm,
    propDeleteEmployeeBonus,
    clientBonusConfirmed,
    transferWithBank,
    submitting
  } = props;
  const { subscriptionAmount, genbutsuAmount, note, id } = initialValues;
  const totalPaymentAmount = sumByAttr(initialValues.payments || [], 'amount');
  const totalDeductionAmount = calcTotalDeductionAmount(initialValues.deductions);
  const confirmed = !!initialValues.isConfirmed;
  return (
    <form onSubmit={handleSubmit}>
      {!confirmed && (
        <ActionButtonsSection
          {...{
            mode,
            reset,
            changeFormMode,
            rollbackInputForm,
            propDeleteEmployeeBonus,
            submit,
            clientBonusConfirmed,
            submitting
          }}
          employeeBonusId={id}
        />
      )}

      <div className={styles.payment}>
        <div className={styles.paymentClm}>
          <BonusSection {...{ mode, totalPaymentAmount }} />
        </div>
        <div className={styles.paymentClm}>
          <DeductionSection {...{ mode, totalDeductionAmount }} />
        </div>
        <div className={styles.paymentClm}>
          <SummarySection {...{ subscriptionAmount, genbutsuAmount, transferWithBank }} />
          <div className="u-mt10">
            <NoteSection {...{ mode, note }} />
          </div>
        </div>
      </div>
    </form>
  );
};

const EmployeeBonusForm = reduxForm({
  enableReinitialize: true,
  form: EMPLOYEE_BONUS_FORM
})(RawEmployeeBonusForm);

export default connect(null, dispatch => ({
  propDeleteEmployeeBonus: data => dispatch(deleteEmployeeBonus(data)),
  onSubmit: data => dispatch(updateEmployeeBonus(data))
}))(EmployeeBonusForm);
