import { combineReducers } from 'redux';
import _ from 'lodash';
import axios from 'axios';
import humps from 'humps';
import { getFormValues, formValueSelector, autofill } from 'redux-form';
import moment from 'moment';

import {
  WAGE_LEDGER_SEARCH,
  WAGE_LEDGER_PDF,
  WAGE_LEDGER,
  WAGE_LEDGER_EXPORT_ITEM_UPDATE,
  WAGE_LEDGER_PREVIEW_PDF,
  WAGE_LEDGER_DOWNLOAD_PDF,
  WAGE_LEDGER_CREATE_PDF,
  WAGE_LEDGER_CSV
} from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { newItemFailure, setItemExtra, getItemExtra } from 'src/reducers/common/itemReducer';
import {
  fetchList,
  fetchListSuccess,
  fetchListFailure,
  getList,
  setList,
  getListPageCount,
  setSelectAllPages,
  getSelectAllPageValue,
  setExtras
} from 'src/reducers/common/listReducer';
import { FORM_NAME_WAGE_LEDGER_SEARCH, takeWageLedgersSearchQueries } from 'src/reducers/searchForm';
import { concatParamsToUrl, redirectTo, serializeHttpGetParams, excludeValuesIfNotDefined } from 'src/utils/Http';
import { setGlobalErrors } from 'src/reducers/global';
import { isJobIncomplete, fetchJobStatus } from 'src/reducers/JobStatus';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';

export const REDUCER_NAME = 'reportWageLedger';
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));
export const getWageLedgerList = state => getList(REDUCER_NAME, state);
export const setWageLedgerList = state => setList(REDUCER_NAME, state);
export const isAllWageLedgerChecked = state => _.every(getWageLedgerList(state) || [], { checked: true });
export const getWageLedgerQueries = state =>
  takeWageLedgersSearchQueries(getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(state) || {});
export const setCheckedEmployees = checkedEmployees => setItemExtra(REDUCER_NAME, { checkedEmployees });
export const getCheckedEmployees = state => getItemExtra(REDUCER_NAME, state).checkedEmployees || [];

export const getDownloadParams = state => {
  const checkedEmp = getCheckedEmployees(state)
    .filter(item => item.checked)
    .map(item => item.id)
    .join(',');
  const selectAllPageValue = getSelectAllPageValue(REDUCER_NAME, state);
  if (_.isEmpty(checkedEmp) && !selectAllPageValue) {
    return {};
  }
  if (selectAllPageValue) {
    const unCheckedEmp = getCheckedEmployees(state)
      .filter(item => !item.checked)
      .map(item => item.id)
      .join(',');
    return { ...getWageLedgerQueries(state), employeeIds: unCheckedEmp, exportAll: 1 };
  }
  const year = _.get(getWageLedgerQueries(state), 'year');
  const yearMonthStart = _.get(getWageLedgerQueries(state), 'year_month_start');
  const yearMonthEnd = _.get(getWageLedgerQueries(state), 'year_month_end');
  const bonus = _.get(getWageLedgerQueries(state), 'bonus');
  const yearSelected = _.get(getWageLedgerQueries(state), 'year_selected');
  return {
    employeeIds: checkedEmp,
    year,
    yearMonthStart,
    yearMonthEnd,
    bonus,
    yearSelected
  };
};

export const downloadCsv = (data, downloadParams) => async dispatch => {
  const encoding = _.get(data, 'encoding');

  try {
    const url = `${WAGE_LEDGER_CSV}?${serializeHttpGetParams({ ...downloadParams, encoding })}`;
    // csv作成時のエラーをキャッチするために一度axiosのgetを実行
    await axios.get(url);
    redirectTo(url);
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const getPdfPreviewUrl = state => {
  const checkedEmp = getCheckedEmployees(state);
  if (!_.isEmpty(checkedEmp)) {
    const year = _.get(getWageLedgerQueries(state), 'year');
    const yearMonthStart = _.get(getWageLedgerQueries(state), 'year_month_start');
    const yearMonthEnd = _.get(getWageLedgerQueries(state), 'year_month_end');
    const bonus = _.get(getWageLedgerQueries(state), 'bonus');
    const yearSelected = _.get(getWageLedgerQueries(state), 'year_selected');
    return `${WAGE_LEDGER_PDF}?employee_ids=${
      checkedEmp[0].id
    }&year=${year}&year_month_start=${yearMonthStart}&year_month_end=${yearMonthEnd}&bonus=${bonus}&year_selected=${yearSelected}`;
  }

  return undefined;
};

export const fetchReportWageLedger = queries => async (dispatch, getState) => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    const excluedQueries = excludeValuesIfNotDefined(queries);
    const response = await axios.get(WAGE_LEDGER_SEARCH, { params: humps.decamelizeKeys(excluedQueries) });

    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const wageList = (() => {
      const checkedEmployees = getCheckedEmployees(getState());
      const selectAllPageValue = getSelectAllPageValue(REDUCER_NAME, getState());

      const shownEmployees = response.data.payload.employees;

      dispatch(
        setCheckedEmployees(
          checkedEmployees.map(checkedEmployee => ({
            ...checkedEmployee,
            checked:
              checkedEmployee.checked && shownEmployees.some(shownEmployee => shownEmployee.id === checkedEmployee.id)
          }))
        )
      );
      return shownEmployees.map(shownEmployee => {
        const employee = checkedEmployees.find(checkedEmployee => checkedEmployee.id === shownEmployee.id);
        if (employee) return { ...shownEmployee, checked: employee.checked };

        return { ...shownEmployee, checked: selectAllPageValue };
      });
    })();

    const payload = {
      data: wageList,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(REDUCER_NAME, { uniqString: response.data.payload.uniqString }));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const changeChecked = id => (dispatch, getState) => {
  const wageList = getWageLedgerList(getState());
  const employee = wageList.find(item => item.id === id);
  const checkedEmployees = getCheckedEmployees(getState())
    .filter(item => item.id !== id)
    .concat({ ...employee, checked: !employee.checked });
  dispatch(setCheckedEmployees(checkedEmployees));

  const wageListCp = [...wageList];
  const index = wageList.findIndex(item => item.id === id);
  wageListCp[index] = { ...employee, checked: !employee.checked };
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setWageLedgerList({ data: wageListCp, pageCount }));
};

export const changeCheckedCurrentPage = newValue => (dispatch, getState) => {
  const wageList = getWageLedgerList(getState()).map(item => ({ ...item, checked: newValue }));
  const employeeIds = wageList.map(item => item.id);
  const checkedEmployees = getCheckedEmployees(getState())
    .filter(item => !employeeIds.includes(item.id))
    .concat(wageList);
  dispatch(setCheckedEmployees(checkedEmployees));

  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setWageLedgerList({ data: wageList, pageCount }));
};

export const changeCheckedAllPage = newValue => dispatch => {
  dispatch(setCheckedEmployees([]));
  dispatch(changeCheckedCurrentPage(newValue));
  dispatch(setSelectAllPages(REDUCER_NAME, { selectAllPageValue: newValue }));
};

export const saveExportItems = data => async dispatch => {
  try {
    if (_.isEmpty(_.get(data, 'exportItems'))) {
      dispatch(setGlobalErrors('項目名を1つ以上選択してください。'));
      return;
    }
    const url = concatParamsToUrl(WAGE_LEDGER_EXPORT_ITEM_UPDATE, { data });
    await axios.post(url, data);

    redirectTo(WAGE_LEDGER);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};
export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});

export const pollFetchExportWageLedgerStatus = (
  formValues,
  uniqueString,
  interval = INITIAL_POLLING_INTERVAL
) => async (dispatch, getState) => {
  try {
    const bonus = _.get(formValues, 'bonus');
    const yearSelected = _.get(formValues, 'yearSelected');
    const year = _.get(formValues, 'year');
    const yearMonthStart = _.get(formValues, 'yearMonthStart');
    const yearMonthEnd = _.get(formValues, 'yearMonthEnd');

    const currentBonus = formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(getState(), 'bonus');
    const currentYearSelected = formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(getState(), 'yearSelected');
    const currentYear = formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(getState(), 'year');
    const currentYearMonthStart = formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(getState(), 'yearMonthStart');
    const currentYearMonthEnd = formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(getState(), 'yearMonthEnd');

    if (
      bonus !== currentBonus ||
      yearSelected !== currentYearSelected ||
      year !== currentYear ||
      yearMonthStart !== currentYearMonthStart ||
      yearMonthEnd !== currentYearMonthEnd
    ) {
      return;
    }

    let variableParams = null;
    if (yearSelected === true && bonus === true) {
      // 年指定かつ賞与含むの場合は、既存と同様
      variableParams = {
        year,
        unique_string: uniqueString
      };
    } else {
      // それ以外の場合は、検索パラメータ指定
      const newUniqueString = `${uniqueString}_${yearSelected}_${bonus}_${yearMonthStart}_${yearMonthEnd}`;
      variableParams = {
        year,
        unique_string: newUniqueString
      };
    }
    const params = {
      job_name: 'exportWageLedgerJob',
      search_params: variableParams
    };
    await dispatch(fetchJobStatus(params));
    const job = getState().JobStatus.exportWageLedgerJob;
    if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
      const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
      setTimeout(() => {
        dispatch(pollFetchExportWageLedgerStatus(formValues, uniqueString, nextInterval));
      }, nextInterval);
    }
  } catch (exception) {
    dispatch(setGlobalErrors('システムエラーが発生しました'));
  }
};

export const createPdf = (params, formValues, uniqueString, hideModal) => async dispatch => {
  try {
    await axios.post(WAGE_LEDGER_CREATE_PDF, params);
    dispatch(pollFetchExportWageLedgerStatus(formValues, uniqueString));
    hideModal();
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

export const fetchDownloadWageLedger = props => async dispatch => {
  try {
    const year = _.get(props, 'year');
    const yearMonthStart = _.get(props, 'yearMonthStart');
    const yearMonthEnd = _.get(props, 'yearMonthEnd');
    const bonus = _.get(props, 'bonus') ? 'true' : 'false';
    const yearSelected = _.get(props, 'yearSelected') ? 'true' : 'false';
    const url = `${WAGE_LEDGER_DOWNLOAD_PDF}?year=${year}&year_month_start=${yearMonthStart}&year_month_end=${yearMonthEnd}&bonus=${bonus}&year_selected=${yearSelected}`;
    redirectTo(url);
  } catch (exception) {
    dispatch(setGlobalErrors('システムエラーが発生しました'));
  }
};

export const fetchPreviewWageLedger = props => async dispatch => {
  try {
    const year = _.get(props, 'year');
    const yearMonthStart = _.get(props, 'yearMonthStart');
    const yearMonthEnd = _.get(props, 'yearMonthEnd');
    const bonus = _.get(props, 'bonus') ? 'true' : 'false';
    const yearSelected = _.get(props, 'yearSelected') ? 'true' : 'false';
    const url = `${WAGE_LEDGER_PREVIEW_PDF}?year=${year}&year_month_start=${yearMonthStart}&year_month_end=${yearMonthEnd}&bonus=${bonus}&year_selected=${yearSelected}`;
    const w = window.open(url, '', 'toolbar=0,menubar=0,location=0');
    if (_.isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print();
      });
    }
  } catch (exception) {
    dispatch(setGlobalErrors('システムエラーが発生しました'));
  }
};

// 開始月の選択により、選択後1年分の情報を作成、設定する
export const setYearMonthEnd = (yearMonth, isYearChange) => async dispatch => {
  const baseDate = moment(`${yearMonth.replace('/', '')}01`);
  const endPeriods = [];
  for (let step = 0; step < 12; step += 1) {
    const date = moment(baseDate).add(step, 'M');
    endPeriods.unshift({ value: date.format('YYYY/MM'), label: date.format('YYYY年MM月 支給') });
  }
  dispatch(autofill(FORM_NAME_WAGE_LEDGER_SEARCH, 'yearMonthEndPeriods', endPeriods));
  // 年指定から期間指定に切り替えた時のデフォルトはその年の1~12月
  // ex.確定月が2月〜の場合だと単純に配列の最後尾を取ると翌年の1月がデフォルトになってしまうので明示的に12月を取得し設定
  if (isYearChange === 'true') {
    const december = endPeriods.find(i => i.value.includes('12'));
    dispatch(autofill(FORM_NAME_WAGE_LEDGER_SEARCH, 'yearMonthEnd', december.value));
  } else {
    dispatch(autofill(FORM_NAME_WAGE_LEDGER_SEARCH, 'yearMonthEnd', endPeriods[0].value));
  }
};
