import React from 'react';
import Button from 'jbc-front/components/Button';
import { BulkDownload, BulkPrint } from 'jbc-front/components/icons';
import CommonModal from 'jbc-front/components/CommonModal';
import styles from './PdfDownloadModal.scss';

const PdfDownloadModal = props => {
  const { isOpen, hideModal, downloadFile, previewFile } = props;
  const header = '賃金台帳一括出力';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <React.Fragment>
        <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
        <CommonModal.Body>
          <form>
            <p>賃金台帳一括データをダウンロード、印刷のどちらかを選択してください。</p>
            <div className={styles.button_box}>
              <Button onClick={downloadFile} className={styles.button_frame}>
                <BulkDownload size={50} />
                <p>データをダウンロードする</p>
              </Button>
              <Button onClick={previewFile} className={styles.button_frame}>
                <BulkPrint size={50} />
                <p>データを印刷する</p>
              </Button>
            </div>
          </form>
        </CommonModal.Body>
      </React.Fragment>
    </CommonModal>
  );
};

export default PdfDownloadModal;
