import React from 'react';
import SortableTree from 'react-sortable-tree';
import { connect } from 'react-redux';
import _ from 'lodash';

import { PlusSquare, DeleteSquare } from 'jbc-front/components/icons';

import {
  mapTree,
  getTree,
  getMode,
  insertNode,
  changeNodeName,
  changeNodeCode,
  deleteNode,
  MODES,
  setTree
} from 'src/reducers/clients/groups';
import styles from './GroupsTree.scss';

const MAX_DEPTH = 5;

const GroupsTree = ({
  tree,
  isEditMode,
  onNodeInsert,
  onNodeNameChange,
  onNodeCodeChange,
  onNodeDelete,
  onTreeChange
}) => {
  const renderTree = mapTree(
    tree,
    ({ node }) => node
  );
  return (
    <div className="l-overflow-scroll">
      <SortableTree
        treeData={renderTree}
        onChange={currentTree =>
          onTreeChange(
            mapTree(currentTree, ({ node: { title, ...node } }) => ({
              ...node,
              canDelete:
                node.group && node.group.employeesCount === 0 && (!node.children || _.every(node.children, 'canDelete'))
            }))
          )
        }
        maxDepth={MAX_DEPTH}
        isVirtualized={false}
        canDrag={isEditMode}
        generateNodeProps={({ path, node }) => ({
          buttons: [
            isEditMode && path.length < MAX_DEPTH && <PlusSquare size={17} onClick={() => onNodeInsert(path)} />,
            isEditMode && (
              <div className={node.canDelete ? styles.groupDelete : styles.groupDeleteNo}>
                <DeleteSquare size={17} onClick={node.canDelete ? () => onNodeDelete(path) : null} />
                {!node.canDelete && (
                  <div className={styles.groupDeleteNoText}>従業員情報で設定されているので削除できません</div>
                )}
              </div>
            )
          ],
          title: node.group ? (
            isEditMode ? (
              <React.Fragment>
                <input
                  className={styles.inputDepartmentCode}
                  maxLength="255"
                  type="text"
                  placeholder="グループコードを入力してください"
                  value={node.group.departmentCode === null ? '' : node.group.departmentCode}
                  onChange={({ target: { value } }) => onNodeCodeChange(path, value)}
                />
                <input
                  className={styles.inputName}
                  maxLength="255"
                  type="text"
                  placeholder="グループ名を入力してください"
                  value={node.group.name}
                  onChange={({ target: { value } }) => onNodeNameChange(path, value)}
                />
              </React.Fragment>
            ) : (
              <div className={styles.groupPreview}>
                {node.group.departmentCode && (
                  <p className={styles.departmentCode}>{node.group.departmentCode}</p>
                )}
                <p className={styles.name}>{node.group.name}</p>
              </div>
            )
          ) : (
            node.title
          )
        })}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  tree: getTree(state),
  isEditMode: getMode(state) === MODES.EDIT
});
const mapDispatchToProps = dispatch => ({
  onNodeInsert: path => {
    dispatch(insertNode(path));
  },
  onNodeNameChange: (path, value) => {
    dispatch(changeNodeName([path, value]));
  },
  onNodeCodeChange: (path, value) => {
    dispatch(changeNodeCode([path, value]));
  },
  onNodeDelete: path => {
    dispatch(deleteNode(path));
  },
  onTreeChange: tree => {
    dispatch(setTree(tree));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(GroupsTree);
