import React from 'react';
import { connect } from 'react-redux';
import remove from 'lodash/remove';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { setItem } from 'src/reducers/common/itemReducer';
import BonusDetailShow from './components/bonus/BonusDetailShow';

const isNotDisplay0Yen = (clientBonusAllowanceId, clientBonusAllowances) => {
  const allowance = clientBonusAllowances.find(item => item.id === clientBonusAllowanceId);
  return !(allowance || {}).isDisplay0YenOnPayslip;
};

const removeZeroYenItem = (allBonus, clientBonusAllowances) => {
  if (!allBonus || !clientBonusAllowances) {
    return allBonus;
  }
  return allBonus.map(bonus => {
    remove(
      bonus.allowanceItems,
      item => item.amount === 0 && isNotDisplay0Yen(item.clientBonusAllowanceId, clientBonusAllowances)
    );
    return bonus;
  });
};

const BonusDetail = ({ dispatch, values }) => {
  const { allBonus, clientBonusAllowances, bonusId } = values;
  const removedZeroBonus = removeZeroYenItem(allBonus, clientBonusAllowances);
  dispatch(setItem(REDUCER_NAME, { ...values, allBonus: removedZeroBonus }));

  return <BonusDetailShow bonusId={bonusId} />;
};

export default connect()(BonusDetail);
