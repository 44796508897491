import React from 'react';
import { connect } from 'react-redux';
import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import selector, { employeeDisplay } from 'src/utils/Utils';
import tableStyles from '../styles/GeneralTableInfo.scss';

const ResidentCardInfoSectionShow = ({ employeeResidentCard, prefectures }) => (
  <div>
    <table className={tableStyles.mTablePersonal}>
      <tbody>
        <tr>
          <th>住所：</th>
          <td>{employeeDisplay.address(employeeResidentCard, prefectures)}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

export default connect(state => ({
  employeeResidentCard: selector(getItem(REDUCER_NAME, state), 'residentCard'),
  prefectures: getSelectOptions(state, 'prefectures')
}))(ResidentCardInfoSectionShow);
