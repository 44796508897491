import React from 'react';
import { reduxForm, Field } from 'redux-form';

import { Section, DateField } from 'jbc-front/components/Form';

import { REPORT_EDIT_HEALTH_INSURANCE_BONUS_FORM } from 'src/constants/FormNames';
import FormErrors from 'src/components/FormErrors';
import { FormSubmitBtn } from 'src/buttons';
import { isDateStringFormat, isRequired } from 'src/utils/CustomValidators';

class FormComponent extends React.Component {
  render() {
    const { handleSubmit, submit, errors, loading } = this.props;
    return (
      <form onSubmit={handleSubmit}>
        <div className="basic_information">
          <FormErrors errors={errors} />
          <Section>
            <Field type="hidden" component="input" name="id" />
            <DateField label="書類提出日" name="submitOn" validate={[isRequired, isDateStringFormat]} />
            <div className="u-ta-c u-mt30">
              <FormSubmitBtn text="保存" {...{ submitting: loading, submit }} />
            </div>
          </Section>
        </div>
      </form>
    );
  }
}

const EditReportHealthInsuranceBonusForm = reduxForm({
  form: REPORT_EDIT_HEALTH_INSURANCE_BONUS_FORM
})(FormComponent);
export default EditReportHealthInsuranceBonusForm;
