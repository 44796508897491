import React from 'react';

import { TextField } from 'jbc-front/components/Form';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import TargetSettings from './TargetSettings';

const AllowancePaymentFixed = () => (
  <div>
    <TextField label="金額" name="allowanceAmount" yen required validate={isMoneyNumber} />
    <TargetSettings allowanceType="payment" />
  </div>
);

export default AllowancePaymentFixed;
