import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { REDUCER_NAME, getIsNotJoinedHealthAndPension } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import selector, { getOptionLabel, numToStr, display, currency } from 'src/utils/Utils';
import { getSelectOptions } from 'src/reducers/selectOptions';
import PayrollRuleInfo from './PayrollRuleInfo';
import tableStyles from '../styles/GeneralTableInfo.scss';
import styles from './PayrollRuleSectionShow.scss';

const EmployeeAllowances = ({ employeeAllowances }) => {
  if (_.isEmpty(employeeAllowances)) {
    return null;
  }
  return (
    <React.Fragment>
      {_.map(employeeAllowances, item => (
        <tr key={item.id}>
          <th>{item.clientAllowanceName || item.name}：</th>
          <td>{currency(numToStr(item.paymentAmount))}</td>
        </tr>
      ))}
    </React.Fragment>
  );
};

const fixedAmount = employeeUnitPrice => _.get(employeeUnitPrice, 'useType') === 'fixed';

const EmployeeUnitPrices = ({ employeeUnitPrices }) => {
  if (_.isEmpty(employeeUnitPrices)) {
    return null;
  }
  return (
    <React.Fragment>
      {_.map(employeeUnitPrices, item => (
        <tr key={item.id}>
          <th>{item.clientUnitPriceName || item.name}：</th>
          <td>{fixedAmount(item) ? `${currency(numToStr(item.amount))}` : '給与規定に従う'}</td>
        </tr>
      ))}
    </React.Fragment>
  );
};

const PayrollRuleSectionShow = ({
  employeePayrollRule,
  baseSalaryNames,
  payrollRulesGroups,
  employeeAllowances,
  employeeUnitPrices,
  isNotJoinedHealthAndPension
}) => (
  <div>
    <table className={tableStyles.mTablePersonal}>
      <tbody>
        <tr>
          <th>給与規定グループ：</th>
          <td>{display(getOptionLabel(employeePayrollRule.clientPayrollRulesGroupId)(payrollRulesGroups))}</td>
        </tr>
        <tr>
          <th />
          <td />
        </tr>
      </tbody>
    </table>

    {employeePayrollRule.clientPayrollRulesGroupId && (
      <PayrollRuleInfo
        payrollRule={payrollRulesGroups.find(p => p.id === employeePayrollRule.clientPayrollRulesGroupId)}
      />
    )}

    <table className={tableStyles.mTablePersonal}>
      <tbody>
        <tr>
          <th />
          <td />
        </tr>
        <tr>
          <th>{getOptionLabel(employeePayrollRule.clientPayrollRulesGroupId)(baseSalaryNames)}：</th>
          <td>{currency(numToStr(employeePayrollRule.baseSalary))}</td>
        </tr>
        <EmployeeAllowances {...{ employeeAllowances }} />
        <EmployeeUnitPrices {...{ employeeUnitPrices }} />
        {!isNotJoinedHealthAndPension && (
          <React.Fragment>
            <tr>
              <th>
                標準報酬月額<br />（健康保険）：
              </th>
              <td>{currency(numToStr(employeePayrollRule.monthlyCompensationHealthInsuranceAmount))}</td>
            </tr>
            <tr>
              <th>
                標準報酬月額<br />（厚生年金保険）：
              </th>
              <td>{currency(numToStr(employeePayrollRule.monthlyRemunerationPensionInsuranceAmount))}</td>
            </tr>
            <tr>
              <th>健康保険料：</th>
              {employeePayrollRule.isHealthInsuranceManualInput ? (
                <td>
                  <span className={styles.insuranceAmount}>
                    従業員負担額：{currency(numToStr(employeePayrollRule.healthInsuranceEmployeeAmount), ' 円/月')}
                  </span>
                  事業主負担額：{currency(numToStr(employeePayrollRule.healthInsuranceEmployerAmount), ' 円/月')}
                </td>
              ) : (
                <td>標準報酬月額を元に自動計算</td>
              )}
            </tr>
            <tr>
              <th>介護保険料：</th>
              {employeePayrollRule.isNursingInsuranceManualInput ? (
                <td>
                  <span className={styles.insuranceAmount}>
                    従業員負担額：{currency(numToStr(employeePayrollRule.nursingInsuranceEmployeeAmount), ' 円/月')}
                  </span>
                  事業主負担額：{currency(numToStr(employeePayrollRule.nursingInsuranceEmployerAmount), ' 円/月')}
                </td>
              ) : (
                <td>標準報酬月額を元に自動計算</td>
              )}
            </tr>
            <tr>
              <th>厚生年金保険料：</th>
              {employeePayrollRule.isPensionInsuranceManualInput ? (
                <td>
                  <span className={styles.insuranceAmount}>
                    従業員負担額：{currency(numToStr(employeePayrollRule.pensionInsuranceEmployeeAmount), ' 円/月')}
                  </span>
                  事業主負担額：{currency(numToStr(employeePayrollRule.pensionInsuranceEmployerAmount), ' 円/月')}
                </td>
              ) : (
                <td>標準報酬月額を元に自動計算</td>
              )}
            </tr>
          </React.Fragment>
        )}
      </tbody>
    </table>
  </div>
);

export default connect(state => ({
  employeePayrollRule: selector(getItem(REDUCER_NAME, state), 'employeePayrollRule') || {},
  baseSalaryNames: getSelectOptions(state, 'baseSalaryNames'),
  employeeAllowances: getSelectOptions(state, 'employeeAllowances'), // 従業員一覧で設定支給・控除項目
  employeeUnitPrices: getSelectOptions(state, 'employeeUnitPrices'), // 単価設定
  payrollRulesGroups: getSelectOptions(state, 'payrollRulesGroups'),
  isNotJoinedHealthAndPension: getIsNotJoinedHealthAndPension(getItem(REDUCER_NAME, state))
}))(PayrollRuleSectionShow);
