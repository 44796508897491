import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import { Pulldown } from 'jbc-front/components/icons';
import { openNewTab } from 'src/utils/Http';
import PayrollFBDataModalContainer from '../containers/PayrollFBDataModalContainer';
import styles from './ActionSection.scss';

const EmployeePaysMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    constructor(props) {
      super(props);

      this.state = {
        isPayrollModalOpen: false
      };

      this.openPayrollModal = this.openPayrollModal.bind(this);
      this.closePayrollModal = this.closePayrollModal.bind(this);
    }

    openPayrollModal() {
      if (this.state.isPayrollModalOpen) return;
      this.setState({ ...this.state, isPayrollModalOpen: true });
    }

    closePayrollModal() {
      if (!this.state.isPayrollModalOpen) return;
      this.setState({ ...this.state, isPayrollModalOpen: false });
    }

    render() {
      const { showPayFbDataUrl, isConfirmed, isOpen } = this.props;

      return (
        <React.Fragment>
          <div className={isOpen ? styles.baloonWrap : styles.hide}>
            <div className={styles.baloonSecond}>
              <ul className={styles.links}>
                <li>
                  <a
                    onClick={() => this.openPayrollModal()}
                    className={isConfirmed ? undefined : styles.disabled}
                    role="presentation"
                  >
                    <Pulldown size={8} />給与振込データ出力
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => openNewTab(showPayFbDataUrl)}
                    className={isConfirmed ? undefined : styles.disabled}
                    role="presentation"
                  >
                    <Pulldown size={8} />給与振込一覧表
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <PayrollFBDataModalContainer isOpen={this.state.isPayrollModalOpen} hideModal={this.closePayrollModal} />
        </React.Fragment>
      );
    }
  }
);

export default EmployeePaysMethod;
