import React from 'react';
import { connect } from 'react-redux';

import { DASHBOARD_SHOW } from 'src/constants/EndpointUrls';
import { authRequest, getAuthorizedState } from 'src/reducers/users/currentUser';
import { redirectTo } from 'src/utils/Http';

import LoginForm from './components/LoginForm';

const Login = ({ isAuthorized, onSubmit }) => {
  if (isAuthorized) {
    redirectTo(DASHBOARD_SHOW);
  }

  return (
    <React.Fragment>
      <LoginForm onSubmit={onSubmit} />
    </React.Fragment>
  );
};

const mapStateToProps = state => ({
  isAuthorized: getAuthorizedState(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: values => {
    dispatch(authRequest(values));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
