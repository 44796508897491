import React from 'react';
import { connect } from 'react-redux';

import { getMode, changeToShowMode, changeToEditMode, updateUsers, resetChanges } from 'src/reducers/users/roleUsers';
import { EditActionBtn, SaveActionBtn, CancelActionBtn } from 'src/buttons';
import styles from './EditableListButtons.scss';

const EditableListButtons = ({ mode, onEdit, onSave, onCancel }) => (
  <div>
    {mode === 'SHOW' ? (
      <div>
        <EditActionBtn onClick={onEdit} />
      </div>
    ) : (
      <div className={styles.action}>
        <SaveActionBtn onClick={onSave} />
        <CancelActionBtn onClick={onCancel} />
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  mode: getMode(state)
});
const mapDispatchToProps = dispatch => ({
  onEdit: () => {
    dispatch(changeToEditMode());
  },
  onSave: () => {
    dispatch(updateUsers());
  },
  onCancel: () => {
    dispatch(changeToShowMode());
    dispatch(resetChanges());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableListButtons);
