import React from 'react';
import _ from 'lodash';

import { formatDate } from 'src/utils/Date';
import { getOptionLabel, display } from 'src/utils/Utils';

const EmployeeLeaveOfAbsenceItemShow = ({ employeeLeaveOfAbsences, leaveOfAbsenceReasonTypes }) => (
  <React.Fragment>
    {_.map(employeeLeaveOfAbsences, (item, index) => (
      <tr key={item.id}>
        <th>{index === 0 ? '休職期間：' : ''}</th>
        <td>
          <div>
            {formatDate(item.startOn)}〜{formatDate(item.endOn)}
          </div>
          <div>{display(getOptionLabel(item.reasonType)(leaveOfAbsenceReasonTypes))}</div>
        </td>
      </tr>
    ))}
  </React.Fragment>
);

export default EmployeeLeaveOfAbsenceItemShow;
