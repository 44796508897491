import { combineReducers } from 'redux';
import { getFormValues, change as changeForm, startSubmit, stopSubmit, blur } from 'redux-form';
import axios from 'axios';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import _ from 'lodash';
import get from 'lodash/get';

import * as Urls from 'src/constants/EndpointUrls';
import { serializeHttpGetParams, concatParamsToUrl, redirectTo } from 'src/utils/Http';
import { paramsWithFile } from 'src/utils/Form';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { setGlobalErrors, setGlobalWarnings } from 'src/reducers/global';
import {
  newItemFailure,
  editItem,
  editItemFailure,
  getItemErrors,
  setItemExtra
} from 'src/reducers/common/itemReducer';
import {
  getListQueries,
  fetchList,
  fetchListSuccess,
  fetchListFailure,
  setExtras,
  getExtras,
  setQueries,
  addQuery
} from 'src/reducers/common/listReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { EMPLOYEE_ATTENDANCE_CSV_FORM, EMPLOYEE_SEARCH_FORM, EMPLOYEE_ATTENDANCE_FORM } from 'src/constants/FormNames';
import { FORM_NAME_ATTENDANCES_SEARCH } from 'src/reducers/searchForm';
import { scrollToTop } from 'src/utils/Utils';

export const REDUCER_NAME = 'employeeAttendances';

export const setEmployeeAttendancesQueries = queries => setQueries(REDUCER_NAME, queries);
export const getEmployeeAttendanceQueries = state => getListQueries(REDUCER_NAME, state);
export const getEmployeeAttendanceListExtras = state => getExtras(REDUCER_NAME, state);
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));
export const getEmployeeAttendanceItemErrors = state => getItemErrors(REDUCER_NAME, state);
export const getClientSyncTasks = state => getExtras(REDUCER_NAME, state).syncTasks;
export const getImportJobs = state => getExtras(REDUCER_NAME, state).importJobs;

// Async Action Creators
export const fetchEmployeeAttendances = query => async dispatch => {
  try {
    dispatch(blur(FORM_NAME_ATTENDANCES_SEARCH, 'uniquePaymentDate', _.get(query, 'uniquePaymentDate'), true));
    dispatch(fetchList(REDUCER_NAME));
    const response = await axios.get(`${Urls.SEARCH_EMPLOYEE_ATTENDANCE_URL}`, { params: query });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.employeeAttendances,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(REDUCER_NAME, response.data.payload.targetPeriod));
    dispatch(setExtras(MEMO_REDUCER_NAME, response.data.payload.memos));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
    dispatch(setExtras(REDUCER_NAME, {}));
  }
};

export const editEmployeeAttendance = (data, employeeAttendanceId) => async dispatch => {
  try {
    dispatch(startSubmit(EMPLOYEE_ATTENDANCE_FORM));
    dispatch(editItem(REDUCER_NAME));
    const httpBody = {
      employeeAttendance: data,
      customizeAllowances: data.customizeAllowances
    };
    const updateAttendanceUrl = concatParamsToUrl(Urls.UPDATE_EMPLOYEE_ATTENDANCE_URL, { id: employeeAttendanceId });
    await axios.put(updateAttendanceUrl, httpBody);
    // Reload to update to the newest data
    // and keep tracking list employee attendance to select next employee
    window.location.reload();
  } catch (exception) {
    scrollToTop();
    dispatch(stopSubmit(EMPLOYEE_ATTENDANCE_FORM));
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const fetchAttendanceWithEmployeeCondition = data => async (dispatch, getState) => {
  try {
    // Get parameter from form data
    const { nameCnt, groupIds, employmentTypeIds, occupationIds, payrollRulesGroup, positionIds, noMemo } = data;
    let { memoColorIds } = data;
    if (memoColorIds) {
      memoColorIds = Object.keys(memoColorIds)
        .map(key => (memoColorIds[key] ? key : null))
        .toString();
    }
    // Current query
    const originQueries = getListQueries(REDUCER_NAME, getState());
    // 1ページ目から検索
    const newQueries = {
      ...originQueries,
      nameCnt,
      groupIds,
      employmentTypeIds,
      occupationIds,
      payrollRulesGroup,
      positionIds,
      noMemo,
      memoColorIds,
      page: 1
    };
    dispatch(setQueries(REDUCER_NAME, newQueries));
    // Re fetch employee bonus
    dispatch(fetchEmployeeAttendances());
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const resetPayrollRulesGroup = newPayrollRulesGroups => (dispatch, getState) => {
  const { payrollRulesGroup } = getListQueries(REDUCER_NAME, getState());
  if (payrollRulesGroup && !newPayrollRulesGroups.split('_').includes(`${payrollRulesGroup}`)) {
    dispatch(addQuery(REDUCER_NAME, 'payrollRulesGroup', undefined));
    dispatch(changeForm(EMPLOYEE_SEARCH_FORM, 'payrollRulesGroup', ''));
  }
};

// default clientPayrollGroup from select option
// when search get client payrollgroup from extras
export const getClientPayrollRulesGroups = state => {
  let clientPayrollRulesGroups = getSelectOptions(state, 'clientPayrollRulesGroups');
  const extras = getEmployeeAttendanceListExtras(state);
  if (!!extras && !!extras.clientPayrollRulesGroups) {
    clientPayrollRulesGroups = extras.clientPayrollRulesGroups;
  }
  return clientPayrollRulesGroups;
};

// send request to server action
// Download template Csv
export const downloadTemplateCsv = () => async (dispatch, getState) => {
  try {
    const encoding = (getFormValues(EMPLOYEE_ATTENDANCE_CSV_FORM)(getState()) || {}).encoding;
    const queries = getEmployeeAttendanceQueries(getState());
    const params = { ...queries, encoding };
    dispatch(clearErrors());
    const response = await fetch(`${Urls.DOWNLOAD_EMPLOYEE_ATTENDANCE_CSV}?${serializeHttpGetParams(params)}`);
    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      if (!response.ok) {
        dispatch(setGlobalErrors(json.errors.messages));
      }
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (exception) {
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

export const fetchImportJobStatus = visibleOnly => async dispatch => {
  try {
    const resp = await axios.get(`${Urls.IMPORT_JOB_STATUS_EMPLOYEE_ATTENDANCES}?visible_only=${visibleOnly}`);
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const fetchSyncJobStatus = displayableOnly => async dispatch => {
  try {
    const resp = await axios.get(`${Urls.SYNC_ATTENDANCE_JOB_STATUS}?displayable_only=${displayableOnly}`);
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

// Upload modified Csv
export const importEmployeeAttendanceCsv = data => async (dispatch, getState) => {
  try {
    const { yearMonth, payrollRulesGroups } = getEmployeeAttendanceQueries(getState());
    const params = paramsWithFile(data, ['employee_attendance_csv']);
    params.append('job_info', data.jobInfo);
    params.append('year_month', yearMonth);
    params.append('payroll_rules_groups', payrollRulesGroups);
    await axios.post(Urls.IMPORT_EMPLOYEE_ATTENDANCE_CSV, params);
    dispatch(clearErrors());
    dispatch(setGlobalWarnings('勤怠データインポートを処理中です。少々お待ちください。'));
    dispatch(fetchImportJobStatus(true));
  } catch (exception) {
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

export const setImportJobInvisible = jobIds => async dispatch => {
  try {
    const resp = await axios.put(Urls.SET_INVISIBLE_IMPORT_JOB_EMPLOYEE_ATTENDANCES, { jobIds });
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
    dispatch(fetchImportJobStatus(true));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const setSyncJobInvisible = taskIds => async dispatch => {
  try {
    const resp = await axios.put(Urls.SET_INVISIBLE_SYNC_JOB_ATTENDANCES, { taskIds });
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
    dispatch(fetchSyncJobStatus(true));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const setEmployeeAttendancesListExtra = newExtra => async dispatch => {
  dispatch(setExtras(REDUCER_NAME, newExtra));
};

export const syncAttendanceWithJbc = (data, callBackSyncDone) => async dispatch => {
  try {
    const resp = await axios.put(Urls.EMPLOYEE_ATTENDANCE_SYNC_WITH_JBC, data);
    callBackSyncDone();
    dispatch(setGlobalWarnings('連携中です。少々お待ちください。'));
    dispatch(setEmployeeAttendancesListExtra({ syncTimesInfo: resp.data.payload.syncTimesInfo }));
  } catch (e) {
    const msg = get(e, ['response', 'data', 'errors', 'messages']) || e.message;
    dispatch(setGlobalErrors(msg));
  }
};

export const setActionMode = actionMode => async dispatch => {
  dispatch(setItemExtra(REDUCER_NAME, { actionMode }));
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
