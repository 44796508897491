import React from 'react';
import { connect } from 'react-redux';

import { setClientBonusAllowancesList } from 'src/reducers/clients/bonusAllowances';
import ClientBonusAllowancesTable from './components/ClientBonusAllowancesTable';

const ClientBonusAllowancesList = ({ dispatch, values }) => {
  dispatch(setClientBonusAllowancesList({ data: values }));
  return <ClientBonusAllowancesTable />;
};

export default connect()(ClientBonusAllowancesList);
