import React from 'react';
import { numberWithComma } from 'src/utils/Utils';

const TransferBank = ({ transfer }) => (
  <tr>
    <th>{transfer.label}</th>
    <td>
      <span className="m-table-obvious-inner">{numberWithComma(transfer.value)}</span>
    </td>
  </tr>
);

const SummarySection = ({ subscriptionAmount, genbutsuAmount, transferWithBank }) => (
  <table className="m-table-obvious">
    <thead>
      <tr>
        <th colSpan="2">集計</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <th>差引支給額</th>
        <td>
          <span className="m-table-obvious-inner">{numberWithComma(subscriptionAmount)}</span>
        </td>
      </tr>
      {transferWithBank.map(item => <TransferBank transfer={item} key={item.id} />)}
      {genbutsuAmount !== 0 && (
        <tr>
          <th>現物支給額</th>
          <td>
            <span className="m-table-obvious-inner">{numberWithComma(genbutsuAmount)}</span>
          </td>
        </tr>
      )}
    </tbody>
  </table>
);

export default SummarySection;
