import React from 'react';
import PropTypes from 'prop-types';

import Button from 'jbc-front/components/Button';

const GeneralBtn = ({ text, icon, ...rest }) => (
  <Button {...rest}>
    {icon}
    {text}
  </Button>
);

GeneralBtn.propTypes = {
  text: PropTypes.string.isRequired
};

export default GeneralBtn;
