import React from 'react';
import { connect } from 'react-redux';

import { REPORT_HEALTH_INSURANCE_BONUS_LIST } from 'src/constants/EndpointUrls';
import { getClientBonusList, completeBonusPaymentNotification } from 'src/reducers/clients/clientBonus';
import { Clock } from 'jbc-front/components/icons';
import CompleteTodoModal from './CompleteTodoModal';
import styles from './ProcedureList.scss';

class BonusPaymentNotification extends React.Component {
  state = {
    showModal: false
  };
  openModal = () => {
    this.setState({ ...this.state, showModal: true });
  };
  closeModal = () => {
    this.setState({ ...this.state, showModal: false });
  };
  bonusPaymentConfirmUrl = () => `${REPORT_HEALTH_INSURANCE_BONUS_LIST}?bonus_id=${this.props.bonus.id}`;
  render() {
    const { bonus } = this.props;
    return (
      <div className={styles.flex}>
        <div className={styles.left}>
          <label>
            <input type="checkbox" onChange={this.openModal} checked={false} className="m-checkbox-input" />
            <div className={`${styles.parts} m-checkbox-parts`}>
              <div className={styles.baloon}>このタスクを完了する</div>
            </div>
          </label>
        </div>
        <div className={styles.right}>
          <span>
            <a href={this.bonusPaymentConfirmUrl()} className={styles.link} target="_blank">
              賞与支払届({bonus.name})
            </a>を提出してください。
          </span>
          <CompleteTodoModal
            isOpen={this.state.showModal}
            hideModal={this.closeModal}
            todo={bonus}
            completeTodo={completeBonusPaymentNotification}
          />
          <div className={styles.deadline}>
            <span className={styles.clock}>
              <Clock size={12} />
            </span>支給日から5日以内
          </div>
        </div>
      </div>
    );
  }
}

const BonusPaymentNotificationList = ({ notCompletedPaymentNotification }) => {
  if (!notCompletedPaymentNotification) {
    return null;
  }
  return (
    <div>{notCompletedPaymentNotification.map(bonus => <BonusPaymentNotification bonus={bonus} key={bonus.id} />)}</div>
  );
};

export default connect(state => ({
  notCompletedPaymentNotification: getClientBonusList(state)
}))(BonusPaymentNotificationList);
