import React from 'react';
import { connect } from 'react-redux';
import { camelize } from 'humps';
import EditableListButtons from './EditableListButtons';
import EditableListFormed from './EditableListFormed';
import { getExtras, getList } from '../../../reducers/common/editableListReducer';
import { REDUCER_NAME } from '../../../reducers/clients/allowedIps';

const AllowedIpListTable = props => {
  const { isIpListEmpty } = props;
  return (
    <React.Fragment>
      <EditableListButtons isIpListEmpty={isIpListEmpty} />
      <EditableListFormed />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const selectedTab = getExtras(REDUCER_NAME, state).selectedTab;
  const ipList = getList(REDUCER_NAME, state).filter(ip => camelize(ip.permissionType) === selectedTab);
  return {
    isIpListEmpty: ipList.length === 0
  };
};

export default connect(mapStateToProps)(AllowedIpListTable);
