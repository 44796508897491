import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import selector from 'src/utils/Utils';
import { getSelectOptions } from 'src/reducers/selectOptions';
import CommutingsSectionShow from '../components/CommutingsSectionShow';

const mapStateToProps = state => ({
  employeeCommutings: selector(getItem(REDUCER_NAME, state), 'employeeCommutings') || [],
  employee: selector(getItem(REDUCER_NAME, state), 'employee') || {},
  paymentDurationUnits: getSelectOptions(state, 'paymentDurationUnits'),
  meansOfCommunications: getSelectOptions(state, 'meansOfCommunications'),
  paymentDurationTypes: getSelectOptions(state, 'paymentDurationTypes')
});

export default connect(mapStateToProps)(CommutingsSectionShow);
