import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_BONUS_ALLOWANCES_LIST, CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { setClientBonusAllowanceItem } from 'src/reducers/clients/bonusAllowances';
import ClientBonusAllowanceForm from './components/ClientBonusAllowanceForm';

const NewClientBonusAllowance = ({ dispatch, values }) => {
  dispatch(setClientBonusAllowanceItem(values));

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">賞与 支給・控除項目 新規登録</h1>
      </div>
      <div className="l-wrap-xs">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="賞与 支給・控除項目一覧" path={CLIENT_BONUS_ALLOWANCES_LIST} />
            <BreadCrumb.Item label="賞与 支給・控除項目 新規登録" />
          </BreadCrumb>
        </div>
        <ClientBonusAllowanceForm />
      </div>
    </div>
  );
};

export default connect()(NewClientBonusAllowance);
