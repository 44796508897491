import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS, CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { CLIENT_UNIT_PRICE_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, createClientUnitPrice, getSubmitting } from 'src/reducers/clients/unitPrices';
import { getItem, setItem, getItemErrors } from 'src/reducers/common/itemReducer';
import { setExtras } from 'src/reducers/common/listReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import ClientUnitPriceForm from './containers/ClientUnitPriceForm';

let NewUnitPriceForm = reduxForm({
  form: CLIENT_UNIT_PRICE_FORM
})(ClientUnitPriceForm);

const initializeClientAllowanceId = clientAllowances =>
  clientAllowances.map(clientAllowance => (clientAllowance.value ? clientAllowance.id : false));

const mapStateToProps = state => ({
  errors: getItemErrors(REDUCER_NAME, state),
  initialValues: {
    ...getItem(REDUCER_NAME, state),
    useAllowanceType: 'this_month',
    clientAllowanceId: initializeClientAllowanceId(getSelectOptions(state, 'clientAllowances'))
  },
  isSubmitting: getSubmitting(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(createClientUnitPrice(data));
  },
  setSubmitting: () => {
    dispatch(setExtras(REDUCER_NAME, { submitting: true }));
  }
});

NewUnitPriceForm = connect(mapStateToProps, mapDispatchToProps)(NewUnitPriceForm);

const NewClientUnitPrice = ({ dispatch, values, match, payrollRulesGroup }) => {
  const { payrollRulesGroupId } = match.params;
  dispatch(setItem(REDUCER_NAME, values));
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">単価新規作成</h1>
      </div>
      <div className="l-wrap-xs">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="給与規定グループ一覧" path={CLIENT_PAYROLL_RULES_GROUP_LIST} />
            <BreadCrumb.Item
              label="支給・控除項目一覧"
              path={`/clients/payroll_rules_groups/${payrollRulesGroupId}/allowances`}
            />
            <BreadCrumb.Item
              label="単価一覧"
              path={`/clients/payroll_rules_groups/${payrollRulesGroupId}/unit_prices`}
            />
            <BreadCrumb.Item label="単価新規作成" />
          </BreadCrumb>
        </div>
        <NewUnitPriceForm payrollRulesGroup={payrollRulesGroup} />
      </div>
    </div>
  );
};

export default connect()(NewClientUnitPrice);
