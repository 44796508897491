import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import compose from 'lodash/fp/compose';
import { UPDATE_SUBJECT_MONTHLY_REVISIONS_FORM } from 'src/constants/FormNames';
import { updateSubject } from 'src/reducers/reports/monthlyRevisions';
import UpdateSubjectForm from '../components/UpdateSubjectModal';

const initSelecting = (isSubject, isForceChangeSubject) => {
  if (isForceChangeSubject) {
    if (isSubject) {
      return 'target';
    }
    return 'non_target';
  }
  return 'auto';
};

const mapStateToProps = (state, ownProps) => {
  const revision = ownProps.revision;
  const formValues = getFormValues(UPDATE_SUBJECT_MONTHLY_REVISIONS_FORM)(state);
  return {
    initialValues: {
      updateHealthInsuranceSubject: initSelecting(revision.isSubject, revision.isForceChangeSubject),
      updatePensionInsuranceSubject: initSelecting(
        revision.isPensionInsuranceSubject,
        revision.isForceChangePensionInsuranceSubject
      )
    },
    errors: formValues ? formValues.errors : []
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => dispatch(updateSubject(data, ownProps.revision))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: UPDATE_SUBJECT_MONTHLY_REVISIONS_FORM,
    enableReinitialize: true
  })
)(UpdateSubjectForm);
