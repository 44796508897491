import React from 'react';
import ActionButton from 'jbc-front/components/ActionButton';
import { Download } from 'jbc-front/components/icons';

import DownloadFbCsvModal from './DownloadFbCsvModal';

class DownloadFbCsvContainer extends React.Component {
  state = {
    isDownloadModalOpen: false
  };

  openDownloadModal = () => {
    if (this.state.isDownloadModalOpen) return;
    this.setState({ ...this.state, isDownloadModalOpen: true });
  };

  closeDownloadModal = () => {
    if (!this.state.isDownloadModalOpen) return;
    this.setState({ ...this.state, isDownloadModalOpen: false });
  };

  render() {
    const { paymentRange, disabled, queries } = this.props;

    return (
      <div style={{ display: '-webkit-inline-box' }}>
        <ActionButton primary onClick={this.openDownloadModal} disabled={disabled} icon={<Download size={15} />}>
          CSVダウンロード
        </ActionButton>
        <DownloadFbCsvModal
          isOpen={this.state.isDownloadModalOpen}
          hideModal={this.closeDownloadModal}
          paymentRange={paymentRange}
          queries={queries}
        />
      </div>
    );
  }
}

export default DownloadFbCsvContainer;
