import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import AllJobStatusContainer from 'src/components/JobStatus/AllJobStatusContainer';
import { fetchImportCsvJobs, hideJobMessage } from 'src/reducers/clients/groups';
import { INITIAL_POLLING_INTERVAL } from 'src/constants/Generals';

const statusText = job => {
  switch (job.status) {
    case 'failed':
      return `グループ情報のCSVインポートが失敗しました。最新の情報を反映させるためにはページを更新してください。`;
    case 'in_progress':
    case 'waiting':
      return `グループ情報のCSVインポートを処理中です。`;
    default:
      return `グループ情報のCSVインポートが完了しました。最新の情報を反映させるためにはページを更新してください。`;
  }
};

const ImportCsvJobStatus = () => {
  const job = useSelector(state => state.JobStatus.importGroupCsvJob);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchImportCsvJobs(INITIAL_POLLING_INTERVAL));
  }, []);

  return (
    <div>
      {!_.isEmpty(job) && (
        <AllJobStatusContainer task={job} statusText={statusText} jobId={job.jobId} hideJobMessage={hideJobMessage} />
      )}
    </div>
  );
};

export default ImportCsvJobStatus;
