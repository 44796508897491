import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { EMPLOYEE_ATTENDANCE_FORM } from 'src/constants/FormNames';
import { EMPLOYEE_ATTENDANCE_EDIT, EMPLOYEE_ATTENDANCE_LIST } from 'src/constants/EndpointUrls';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { REDUCER_NAME, editEmployeeAttendance } from 'src/reducers/employees/employeeAttendances';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { setItem, getItem, mergeItemFields, getItemErrors } from 'src/reducers/common/itemReducer';
import { setExtras } from 'src/reducers/common/listReducer';
import { NextAndPrevRecordButton } from 'src/components';
import AttendanceSyncWithJbcResult from './components/AttendanceSyncWithJbcResult';
import ImportAttendanceCsvJobStatus from './components/ImportAttendanceCsvJobStatus';
import EmployeeAttendanceForm from './components/EmployeeAttendanceForm';
import styles from './EditEmployeeAttendance.scss';

let EditEmployeeAttendanceForm = reduxForm({
  form: EMPLOYEE_ATTENDANCE_FORM
})(EmployeeAttendanceForm);

const mapStateToProps = state => ({
  initialValues: getItem(REDUCER_NAME, state),
  errors: getItemErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(editEmployeeAttendance(data, data.id));
  }
});
EditEmployeeAttendanceForm = connect(mapStateToProps, mapDispatchToProps)(EditEmployeeAttendanceForm);

const EditEmployeeAttendance = ({ dispatch, values }) => {
  dispatch(setItem(REDUCER_NAME, values.employeeAttendance));
  dispatch(mergeItemFields(REDUCER_NAME, { customizeAllowances: values.customizeAllowances }));
  dispatch(setExtras(MEMO_REDUCER_NAME, values.memos));
  const { employeeId, isConfirmed, employeeDisplayName } = values.employeeAttendance;
  const employeeAttendanceId = values.employeeAttendance.id;
  const {
    nextInfo,
    prevInfo,
    isEmployeePayConfirmed,
    attendanceStartDate,
    attendanceEndDate,
    paymentDate,
    allAttendances
  } = values;

  return (
    <div>
      <div className="l-wrap-xl l-contents-wrap">
        <div className={styles.head}>
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="勤怠一覧" path={EMPLOYEE_ATTENDANCE_LIST} />
              <BreadCrumb.Item label="勤怠詳細" />
            </BreadCrumb>
          </div>
          <NextAndPrevRecordButton {...{ nextInfo, prevInfo, urlFormat: EMPLOYEE_ATTENDANCE_EDIT }} />
        </div>
        {!isEmployeePayConfirmed && (
          <div className={styles.hide}>
            {/* Trigger for checking having running job or not to disable/enable edit button */}
            <AttendanceSyncWithJbcResult />
            <ImportAttendanceCsvJobStatus />
          </div>
        )}

        <EditEmployeeAttendanceForm
          {...{
            employeeId,
            employeeDisplayName,
            isEmployeePayConfirmed,
            attendanceStartDate,
            attendanceEndDate,
            paymentDate,
            allAttendances,
            employeeAttendanceId
          }}
          allowanceItems={values.allowanceItems}
          employeeAttendanceIsConfirmed={isConfirmed}
        />
      </div>
    </div>
  );
};

export default connect()(EditEmployeeAttendance);
