import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import { AUDIT_LOG_SHOW, CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import ListGroup from 'jbc-front/components/ListGroup';
import { getListAuditLogs } from 'src/reducers/clients/auditLogs';
import styles from './AuditLogsTable.scss';

const auditLogDetailUrl = auditLogId => concatParamsToUrl(AUDIT_LOG_SHOW, { id: auditLogId });

const AuditLogsTable = ({ auditLogs }) => (
  <React.Fragment>
    <div className="u-mb20">
      <BreadCrumb>
        <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
        <BreadCrumb.Item label="操作履歴" />
      </BreadCrumb>
    </div>
    <div className={styles.list}>
      <ListGroup>
        {(!auditLogs || auditLogs.size === 0) && <ListGroup.Item>データがありません</ListGroup.Item>}
        {auditLogs &&
          auditLogs.map(log => (
            <ListGroup.Item key={log.id} as="a" href={auditLogDetailUrl(log.id)}>
              {log.title} <span className={styles.created}>{moment(log.createdAt).fromNow()}</span>
            </ListGroup.Item>
          ))}
      </ListGroup>
    </div>
  </React.Fragment>
);

export default connect(state => ({
  auditLogs: getListAuditLogs(state)
}))(AuditLogsTable);
