import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { INVITATION_STATUSES } from 'src/constants/Options';
import { EMPLOYEE_SHOW } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { getOptionLabel } from 'src/utils/Utils';
import MemoList from 'src/features/Memos';
import { formatDate } from 'src/utils/Date';
import CheckBoxHint from 'src/components/CheckBoxHint';

import styles from 'src/components/EmployeeTable.scss';

const isSelectable = employee =>
  employee.email && ['not_invited', 'only_enrollment_input'].includes(employee.invitationStatus);

const SelectableCheckbox = ({ selected }) => <input type="checkbox" checked={selected} readOnly />;

const UnselectableChecbox = () => <input type="checkbox" checked={false} disabled />;

const LinkToEmployeeDetailPage = ({ employee }) => (
  <a href={concatParamsToUrl(EMPLOYEE_SHOW, { id: employee.id })}>{employee.displayName}</a>
);

class InvitationRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHint: false,
      hint: null
    };
  }

  handleClick = () => {
    const { checkboxOnClick, disabledMessage } = this.props;
    if (disabledMessage) {
      this.setState({
        showHint: true,
        hint: disabledMessage
      });
    } else {
      checkboxOnClick();
    }
  };

  hideHint = () => {
    this.setState({
      showHint: false
    });
  };

  render() {
    const { employee, selected, groups, openEmailForm } = this.props;
    const selectedClass = selected ? 'm-table-checkbox-on' : 'm-table-checkbox-off';

    return (
      <tr className={styles.tableRow}>
        <td onClickCapture={this.handleClick} className={selectedClass}>
          {this.state.showHint && <CheckBoxHint onClick={this.hideHint}>{this.state.hint}</CheckBoxHint>}
          {isSelectable(employee) ? <SelectableCheckbox {...{ selected }} /> : <UnselectableChecbox />}
        </td>
        <td>
          <MemoList employeeId={employee.id} />
        </td>
        <td>{employee.staffCode}</td>
        <td>
          <LinkToEmployeeDetailPage employee={employee} />
        </td>
        <td>
          <a className="u-txt-link" onClick={openEmailForm} role="link" tabIndex={0}>
            {employee.email ? employee.email : '未入力'}
          </a>
        </td>
        <td>{getOptionLabel(employee.invitationStatus)(INVITATION_STATUSES)}</td>
        <td>{getOptionLabel(employee.groupId)(groups)}</td>
        <td>{formatDate(employee.joinedOn)}</td>
      </tr>
    );
  }
}

export default connect(state => ({
  groups: getSelectOptions(state, 'groups')
}))(InvitationRow);
