import React from 'react';
import styles from './InsertBatchCsv.scss';

const InsertBatchCsvDescription = () => (
  <div className="l-wrap-xl">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              スタッフコード<span className={styles.required}>必須</span>
            </th>
            <td>スタッフコードを基にデータが取り込まれるため、正確に入力してください。</td>
            <td>A001</td>
          </tr>
          <tr>
            <th>姓</th>
            <td>
              従業員の氏名
              <br />
              ※ダウンロード時には出力されますが、更新時には無視されます
            </td>
            <td>山田</td>
          </tr>
          <tr>
            <th>名</th>
            <td>
              従業員の氏名
              <br />
              ※ダウンロード時には出力されますが、更新時には無視されます
            </td>
            <td>太郎</td>
          </tr>
          <tr>
            <th>
              マイナンバー<span className={styles.required}>必須</span>
            </th>
            <td>半角数字12桁</td>
            <td>123412341234</td>
          </tr>
          <tr>
            <th>被扶養者1 姓</th>
            <td>被扶養者の氏名</td>
            <td>山田</td>
          </tr>
          <tr>
            <th>被扶養者1 名</th>
            <td>被扶養者の氏名</td>
            <td>花子</td>
          </tr>
          <tr>
            <th>被扶養者1 生年月日</th>
            <td>半角数字 (yyyy/mm/dd)</td>
            <td>1980/1/1</td>
          </tr>
          <tr>
            <th>被扶養者1 マイナンバー</th>
            <td>半角数字12桁</td>
            <td>123412341234</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default InsertBatchCsvDescription;
