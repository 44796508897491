import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import classNames from 'classnames';
import _ from 'lodash';
import compose from 'lodash/fp/compose';

import { MenuEllipsis, Link, Pulldown } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import {
  getExtras,
  getIsLoading,
  getListConfirmed,
  getListIsConfirmedCount,
  getListTotalCount
} from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  fetchEmployeePays,
  exportToOfficeStation,
  fetchOfficeStationJobs,
  hideOfficeStationJobMessage
} from 'src/reducers/employees/employeePays';
import ActionSection from 'src/features/EmployeePays/components/ActionSection';
import DetailSearchForm from 'src/components/DetailSearchForm';
import { ErrorsSection } from 'src/components';
import { getListErrors } from 'src/reducers/common/editableListReducer';
import { setItemError } from 'src/reducers/common/itemReducer';
import {
  takePaysSearchQueries,
  takePayConfirmedStateFromSearchForm,
  takePaysSearchQueriesFromLocation,
  takePaysAndAttendancesResetFormQueries,
  paysSearchFormSelector,
  fetchUniquePayrollRuleGroups,
  FORM_NAME_PAYS_SEARCH,
  ITEM_NAME_FETCH_UNIQUE_PAYROLL_RULE_GROUPS,
  isLoading
} from 'src/reducers/searchForm';
import PaymentDateSelectBoxes from 'src/components/PaymentDateSelectBoxes';
import IconActionButton from 'src/components/IconActionButton';
import SearchDetector from 'src/components/SearchDetector';
import Confirmed from 'src/components/Confirmed';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import PayTargetDateRange from 'src/containers/PayTargetDateRangeContainer';
import LoadingView from 'src/components/LoadingView';
import ConfirmPayrollContainer from 'src/features/EmployeePays/components/ConfirmPayrollContainer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getJob } from 'src/reducers/JobStatus';
import withSetupNeeded from 'src/components/withSetupNeeded';
import Balloon from 'src/features/Employees/components/Balloon';
import ConfirmationPayJobStatus from './components/ConfirmationPayJobStatus';
import DownloadPayCsvJobStatus from './components/DownloadPayCsvJobStatus';
import ImportPayCsvJobStatus from './components/ImportPayCsvJobStatus';
import EmployeePayTable from './containers/EmployeePayTable';
import styles from './EmployeePayList.scss';
import AllRollbackEmployeePayModal from './components/AllRollbackEmployeePayModal';
import AllJobStatusContainer from '../../components/JobStatus/AllJobStatusContainer';
import ChangePaymentDateModal from '../../components/ChangePaymentDateModal';
import OfficeStationModal from '../../components/OfficeStationModal';
import UnconfirmAllEmployeePayModalContainer from './containers/UnconfirmAllEmployeePayModalContainer';
import Editing from '../../components/Editing';

class EmployeePayList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isUnconfirmAllModalOpen: false,
      isAllRollbackModalOpen: false,
      isPaymentDateModalOpen: false,
      isPayCoordinationBaloonOpen: false,
      isOfficeStationModalOpen: false
    };
  }

  componentDidMount() {
    this.props.dispatch(fetchUniquePayrollRuleGroups(FORM_NAME_PAYS_SEARCH));
    this.props.fetchOfficeStationJobs();
  }

  // 給与の状態更新の条件
  detectingJudgerFnc = (prevProps, nextProps) => {
    const isInitialLoadComplete = nextProps.uniquePaymentDate !== undefined;
    const isInitialLoadNotCompletedBefore = prevProps.uniquePaymentDate === undefined;
    const isChangedSearchCondition = nextProps.location !== prevProps.location;
    // prevProps.uniquePaymentDatesCount > 0 を条件に入れているのは画面初期表示時に2回検索されるのを防ぐため
    const isChangedConfirmStatus =
      prevProps.uniquePaymentDatesCount > 0 &&
      prevProps.isPayConfirmedFromSearchForm !== nextProps.isPayConfirmedFromSearchForm;

    return (
      isInitialLoadComplete && (isInitialLoadNotCompletedBefore || isChangedSearchCondition || isChangedConfirmStatus)
    );
  };

  statusTextOfficesStation = job => {
    const message = (() => {
      switch (job.status) {
        case 'failed':
          return 'オフィスステーション連携で給与データの連携エラーが発生しました。';
        case 'partial_success':
          return 'オフィスステーション連携で給与データの連携エラーが発生しました。なお、以下にエラー表示のない従業員は、正常に連携されています。';
        case 'in_progress':
        case 'waiting':
          return 'オフィスステーション連携で給与データを連携中です。';
        default:
          return 'オフィスステーション連携での給与データ連携が完了しました。';
      }
    })();
    return `${message}`;
  };
  render() {
    const {
      initialValues: formValues,
      uniquePaymentDate,
      location,
      isPayConfirmedFromSearchForm,
      isPayConfirmed,
      resetValues,
      uniquePayrollRuleGroupsCount,
      uniquePaymentDatesCount,
      dispatch,
      extras,
      isListLoading,
      isOptionsLoading,
      errors,
      clearRollbackError,
      totalCount,
      isConfirmedCount,
      containElectronicDeliveryAgreement,
      isAvailableOfficeStation,
      clientOfficeStationSetting,
      callExportToOfficeStation,
      officeStationEntryPayJob
    } = this.props;
    const selectedPayroll = _.get(formValues, 'uniquePayrollRuleGroups');
    const selectedYearMonth = _.get(formValues, 'uniquePaymentDate');

    const isUnconfirmedAll = isConfirmedCount === 0;
    const isConfirmedAll = isConfirmedCount === totalCount;

    // ローディング中やlistが空の時はラベルを表示しない
    const shouldDisplayLabel = uniquePaymentDatesCount !== 0 && !isListLoading && !isOptionsLoading;

    const isOnlyOnePayrollRuleGroup = () => uniquePayrollRuleGroupsCount < 2;
    const headerStyle = () => {
      if (isAvailableOfficeStation && clientOfficeStationSetting) {
        return classNames({
          [styles.selectIsAvailableOfficeStation]: true,
          [styles.centeredSelect]: isOnlyOnePayrollRuleGroup()
        });
      }
      return classNames({
        [styles.select]: true,
        [styles.centeredSelect]: isOnlyOnePayrollRuleGroup()
      });
    };

    return (
      <div>
        <SearchDetector
          location={location}
          uniquePaymentDate={uniquePaymentDate}
          uniquePaymentDatesCount={uniquePaymentDatesCount}
          isPayConfirmedFromSearchForm={isPayConfirmedFromSearchForm}
          willReceivePropsDetectingJudger={this.detectingJudgerFnc}
          onDetect={() => {
            dispatch(fetchEmployeePays(takePaysSearchQueries(formValues)));
          }}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">給与一覧</h1>
        </div>
        <ErrorsSection errors={errors} />
        <LoadingView isLoading={isOptionsLoading}>
          <ConfirmationPayJobStatus />
          <DownloadPayCsvJobStatus />
          <ImportPayCsvJobStatus />
          <AllJobStatusContainer
            task={officeStationEntryPayJob}
            statusText={this.statusTextOfficesStation}
            jobId={_.get(officeStationEntryPayJob, 'jobId')}
            hideJobMessage={hideOfficeStationJobMessage}
          />
          <div className={isAvailableOfficeStation && !isOnlyOnePayrollRuleGroup() ? 'l-wrap-xxl' : 'l-wrap-xl'}>
            <div className={headerStyle()}>
              {shouldDisplayLabel &&
                (isPayConfirmed ? (
                  <div className={styles.stateLabel}>
                    <Confirmed />
                  </div>
                ) : (
                  !isUnconfirmedAll && (
                    <div className={styles.stateLabel}>
                      <Editing />
                    </div>
                  )
                ))}
              <div className={styles.menu}>
                <PaymentDateSelectBoxes formName={FORM_NAME_PAYS_SEARCH} />
                <div className={styles.menuItem}>
                  <IconActionButton
                    icon={<MenuEllipsis size={24} />}
                    options={() => (
                      <ul className={styles.editlist}>
                        {/* Todo: 選択解除実装時に、チェックが一つでもあれば選択解除に切り替えるロジックを追加 */}
                        <li>
                          <a
                            onClick={() => {
                              this.setState({ isUnconfirmAllModalOpen: true });
                            }}
                            role="presentation"
                            className={isPayConfirmed || isUnconfirmedAll ? styles.disabled : ''}
                          >
                            確定解除
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.setState({ isAllRollbackModalOpen: true });
                            }}
                            role="presentation"
                            className={isConfirmedAll ? styles.disabled : ''}
                          >
                            一括で手入力前に戻す
                          </a>
                        </li>
                        <li>
                          <a
                            onClick={() => {
                              this.setState({ isPaymentDateModalOpen: true });
                            }}
                            role="presentation"
                            className={!isUnconfirmedAll || isPayConfirmed ? styles.disabled : ''}
                          >
                            支給日を編集
                          </a>
                        </li>
                      </ul>
                    )}
                  />
                </div>
              </div>
              {isAvailableOfficeStation &&
                clientOfficeStationSetting && (
                  <div className={styles.syncBtn}>
                    <Button
                      className="ignore-react-onclickoutside"
                      icon={<Link size={17} />}
                      widthAuto
                      disabled={!isPayConfirmed}
                      onClick={() => this.setState({ isPayCoordinationBaloonOpen: true })}
                    >
                      連携
                    </Button>
                    {this.state.isPayCoordinationBaloonOpen && (
                      <Balloon hide={() => this.setState({ isPayCoordinationBaloonOpen: false })}>
                        <ul className={styles.links} style={{ cursor: 'pointer' }}>
                          {true && (
                            <li
                              role="presentation"
                              onClick={e => {
                                e.stopPropagation();
                                this.setState({ isOfficeStationModalOpen: true });
                              }}
                            >
                              <span>
                                <Pulldown size={8} />オフィスステーション連携
                              </span>
                            </li>
                          )}
                        </ul>
                      </Balloon>
                    )}
                  </div>
                )}
              <ConfirmPayrollContainer
                selectedPayroll={selectedPayroll}
                selectedYearMonth={selectedYearMonth}
                isConfirmed={isPayConfirmed}
              />
            </div>
            <div className={styles.container}>
              <div className={styles.confirmedCount}>
                確定済: {isConfirmedCount}/{totalCount}
              </div>
              <PayTargetDateRange formName={FORM_NAME_PAYS_SEARCH} releaseDateVisibility={isPayConfirmed} />
            </div>
            <DetailSearchForm
              menuRender={() => <ActionSection payrollRulesGroups={selectedPayroll} yearMonth={selectedYearMonth} />}
              containElectronicDeliveryAgreement={containElectronicDeliveryAgreement}
              formName={FORM_NAME_PAYS_SEARCH}
              resetValues={resetValues}
            />
          </div>
          <LoadingView isLoading={isListLoading} isInitLoading={_.isEmpty(location.search)}>
            <div className={classNames('l-wrap-xl', styles.list)}>
              <EmployeePayTable formName={FORM_NAME_PAYS_SEARCH} {...extras} />
              <SearchFormPaginator formName={FORM_NAME_PAYS_SEARCH} reducerName={REDUCER_NAME} />
            </div>
          </LoadingView>
        </LoadingView>
        <OfficeStationModal
          isOpen={this.state.isOfficeStationModalOpen}
          hideModal={() => {
            this.setState({ ...this.state, isOfficeStationModalOpen: false });
          }}
          header={'オフィスステーション連携'}
          buttonName={'反映'}
          clientOfficeStationSetting={clientOfficeStationSetting}
          onSubmit={callExportToOfficeStation}
          components={
            <div style={{ color: 'red' }}>
              【注意】<br />
              すでにオフィスステーション側で入力した項目が存在する場合は、<br />
              値が削除されるのでご注意ください。
            </div>
          }
        />
        {!isPayConfirmed &&
          this.state.isUnconfirmAllModalOpen && (
            <UnconfirmAllEmployeePayModalContainer
              isOpen={this.state.isUnconfirmAllModalOpen}
              hideModal={() => {
                this.setState({ isUnconfirmAllModalOpen: false });
                clearRollbackError();
              }}
              payrollRulesGroups={selectedPayroll}
              yearMonth={selectedYearMonth}
            />
          )}
        {!isPayConfirmed &&
          this.state.isAllRollbackModalOpen && (
            <AllRollbackEmployeePayModal
              isOpen={this.state.isAllRollbackModalOpen}
              hideModal={() => {
                this.setState({ isAllRollbackModalOpen: false });
                clearRollbackError();
              }}
              payrollRulesGroups={selectedPayroll}
              yearMonth={selectedYearMonth}
            />
          )}
        {!isPayConfirmed &&
          this.state.isPaymentDateModalOpen && (
            <ChangePaymentDateModal
              isOpen={this.state.isPaymentDateModalOpen}
              hideModal={() => {
                this.setState({ isPaymentDateModalOpen: false });
              }}
            />
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...takePaysSearchQueriesFromLocation(FORM_NAME_PAYS_SEARCH, state.router.location, true),
    ...getFormValues(FORM_NAME_PAYS_SEARCH)(state)
  },
  uniquePaymentDate: paysSearchFormSelector(state, 'uniquePaymentDate'),
  uniquePaymentDatesCount: getSelectOptions(state, 'uniquePaymentDates').length,
  resetValues: takePaysAndAttendancesResetFormQueries(FORM_NAME_PAYS_SEARCH, state, true),
  extras: getExtras(REDUCER_NAME, state),
  location: state.router.location,
  isPayConfirmedFromSearchForm: takePayConfirmedStateFromSearchForm(state, FORM_NAME_PAYS_SEARCH),
  uniquePayrollRuleGroupsCount: getSelectOptions(state, 'uniquePayrollRuleGroups').length,
  isListLoading: getIsLoading(REDUCER_NAME, state),
  isOptionsLoading: isLoading(ITEM_NAME_FETCH_UNIQUE_PAYROLL_RULE_GROUPS, state),
  totalCount: getListTotalCount(REDUCER_NAME, state),
  isConfirmedCount: getListIsConfirmedCount(REDUCER_NAME, state),
  isPayConfirmed: getListConfirmed(REDUCER_NAME, state),
  containElectronicDeliveryAgreement: _.get(state, 'currentClient.item.data.isElectronicDeliveryAgreementSetting'),
  officeStationEntryPayJob: _.get(getJob(state), 'officeStationEntryPayJob'),
  isAvailableOfficeStation: state.currentClient.item.data.isAvailableOfficeStation,
  clientOfficeStationSetting: state.currentClient.item.data.clientOfficeStationSetting,
  errors: getListErrors(REDUCER_NAME, state)
});

const mapDispatchToProps = dispatch => ({
  clearRollbackError: () => dispatch(setItemError(REDUCER_NAME, { rollbackEmployeePay: null })),
  fetchOfficeStationJobs: () => {
    dispatch(fetchOfficeStationJobs());
  },
  callExportToOfficeStation: data => {
    dispatch(exportToOfficeStation(data));
  }
});
export default compose(
  withSetupNeeded(' 給与計算を開始するには'),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_PAYS_SEARCH,
    enableReinitialize: true
  })
)(EmployeePayList);
