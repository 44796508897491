import React, { useState } from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { EMPLOYEE_PAY_EDIT, EMPLOYEE_PAY_LIST } from 'src/constants/EndpointUrls';

import SearchDetector from 'src/components/SearchDetector';
import { setItem } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, fetchEmployeePayDetail, getCurrentPayId } from 'src/reducers/employees/employeePays';

import { setExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { NextAndPrevRecordButton } from 'src/components';
import useInitializeData from 'src/hooks/useInitializeData';

import ConfirmationPayJobStatus from './components/ConfirmationPayJobStatus';
import DownloadPayCsvJobStatus from './components/DownloadPayCsvJobStatus';
import ImportPayCsvJobStatus from './components/ImportPayCsvJobStatus';
import EmployeePayFormContainer from './containers/EmployeePayFormContainer';
import styles from './EditEmployeePay.scss';

const EditEmployeePay = ({ dispatch, values, location, currentPayId }) => {
  const [isViewMode, setIsViewMode] = useState(true);

  useInitializeData(() => {
    dispatch(setItem(REDUCER_NAME, values));
    dispatch(setExtras(MEMO_REDUCER_NAME, values.memos));
  });

  const changeViewModeToTrue = () => {
    setIsViewMode(true);
  };

  const changeViewModeToFalse = () => {
    setIsViewMode(false);
  };

  const hashIsInputAllowanceItem = employeePay => ({
    isInputTotalWorkingDays: employeePay.isInputTotalWorkingDays,
    isInputPredeterminedWorkingDays: employeePay.isInputPredeterminedWorkingDays,
    isInputLegalHolidayWorkingDays: employeePay.isInputLegalHolidayWorkingDays,
    isInputAbsenceWorkingDays: employeePay.isInputAbsenceWorkingDays,
    isInputNumberPayDays: employeePay.isInputNumberPayDays,
    isInputNumberDayOffDays: employeePay.isInputNumberDayOffDays,
    isInputPredeterminedWorkingTime: employeePay.isInputPredeterminedWorkingTime,
    isInputTotalWorkingTime: employeePay.isInputTotalWorkingTime,
    isInputActualOvertimeTime: employeePay.isInputActualOvertimeTime,
    isInputOvertimeNightTime: employeePay.isInputOvertimeNightTime,
    isInputOvertimeLawTime: employeePay.isInputOvertimeLawTime,
    isInputLateSoonTime: employeePay.isInputLateSoonTime,
    isInputLegalStandingOvertimeTime: employeePay.isInputLegalStandingOvertimeTime,
    isInputOvertimeOver60HoursTime: employeePay.isInputOvertimeOver60HoursTime,
    isInputWorkedHolidayDays: employeePay.isInputWorkedHolidayDays,
    isInputWorkedHolidayTime: employeePay.isInputWorkedHolidayTime,
    isInputPredeterminedWorkingHolidayDays: employeePay.isInputPredeterminedWorkingHolidayDays,
    isInputPredeterminedWorkingHolidayTime: employeePay.isInputPredeterminedWorkingHolidayTime,
    isInputNumberLateSoonTimes: employeePay.isInputNumberLateSoonTimes,
    isInputOvertimeOver45HoursTime: employeePay.isInputOvertimeOver45HoursTime,
    isInputPayTime: employeePay.isInputPayTime,
    isInputPrescribedWorkingTime: employeePay.isInputPrescribedWorkingTime,
    isInputOvertimeWorkingTime: employeePay.isInputOvertimeWorkingTime
  });

  const { employeeId } = values.employeePay;
  const { nextInfo, prevInfo } = values;

  return (
    <div className="l-wrap-xl l-contents-wrap">
      <SearchDetector
        location={location}
        didMountDetectingJudger={() => true}
        willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
        onDetect={() => {
          // 初期表示時のみvalues.employeePay.idから取得する
          const payId = currentPayId || values.employeePay.id;
          dispatch(fetchEmployeePayDetail(payId));
        }}
      />
      <div className={styles.head}>
        <BreadCrumb>
          <BreadCrumb.Item label="給与一覧" path={EMPLOYEE_PAY_LIST} />
          <BreadCrumb.Item label="給与詳細" />
        </BreadCrumb>
        <NextAndPrevRecordButton {...{ nextInfo, prevInfo, urlFormat: EMPLOYEE_PAY_EDIT }} />
      </div>

      <div className={styles.hide}>
        {/* Trigger for checking having running job or not to disable/enable edit button */}
        <ConfirmationPayJobStatus />
        <DownloadPayCsvJobStatus />
        <ImportPayCsvJobStatus />
      </div>

      <EmployeePayFormContainer
        employeeId={employeeId}
        changeViewModeToTrue={changeViewModeToTrue}
        changeViewModeToFalse={changeViewModeToFalse}
        isViewOnly={isViewMode}
        isInputAllowanceItems={hashIsInputAllowanceItem(values.employeePay)}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  currentPayId: getCurrentPayId(state)
});

export default connect(mapStateToProps)(EditEmployeePay);
