import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { CLIENT_TAX_CAL_METHOD_SETTING_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, updateClientTaxCalMethod } from 'src/reducers/clients/clients';
import { Section } from 'jbc-front/components/Form';
import { setItem, getItem, getItemErrors } from 'src/reducers/common/itemReducer';
import ClientTaxCalMethodSettingForm from './components/ClientTaxCalMethodSettingForm';

let ClientTaxCalMethodSettingSection = reduxForm({
  form: CLIENT_TAX_CAL_METHOD_SETTING_FORM
})(ClientTaxCalMethodSettingForm);

const mapStateToProps = state => ({
  errors: getItemErrors(REDUCER_NAME, state),
  initialValues: { ...getItem(REDUCER_NAME, state) }
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(updateClientTaxCalMethod(data))
});

ClientTaxCalMethodSettingSection = connect(mapStateToProps, mapDispatchToProps)(ClientTaxCalMethodSettingSection);

const ClientTaxCalMethodSetting = ({ dispatch, values }) => {
  dispatch(setItem(REDUCER_NAME, values));
  return (
    <Section title="所得税（源泉徴収税額）の計算方法">
      <ClientTaxCalMethodSettingSection />
    </Section>
  );
};

export default connect()(ClientTaxCalMethodSetting);
