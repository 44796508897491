import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import get from 'lodash/get';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import {
  CLIENT_SETTINGS,
  COORDINATION_SERVICE_LIST,
  CLIENT_ALLOWANCE_ITEMS,
  SETTING_EMPLOYEES_SYNC_ATTENDANCE_SHOW
} from 'src/constants/EndpointUrls';
import { SYNC_JBC_SETTING_FORM } from 'src/constants/FormNames';
import { fetchClientPayrollRuleGroups } from 'src/reducers/fetchSelectOptions';
import { setClientTaskSyncSettingList, saveClientSyncTaskSettings } from 'src/reducers/clients/syncTaskSettings';
import SyncJbcSettingForm from './components/SyncJbcSettingForm';

import styles from './SyncJbcSettings.scss';

const Note = () => (
  <div>
    <p className={styles.notes}>
      ジョブカン勤怠管理とAPI連携するための設定です。<br />
      日時を設定することで毎月自動で勤怠データを取得できたり好きなタイミングで勤怠データを取得することができます。
    </p>
    <div className={styles.innerNotes}>
      <p>
        【ジョブカン勤怠管理の設定】<br />
        ジョブカン勤怠管理側でCSVフォーマットの設定が必要です。<br />
        <a
          href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360002468813"
          rel="noopener noreferrer"
          target="_blank"
        >
          ヘルプ
        </a>を参考に設定をお願いします。
        給与規定グループ毎に勤怠項目が異なる場合は給与規定グループ毎にCSVフォーマットの設定をお願いします。
      </p>
      <ul className="m-list-notes">
        <li>
          ※ジョブカン給与計算が取得する勤怠項目は<a href={CLIENT_ALLOWANCE_ITEMS}>こちら</a>の設定項目となります。ジョブカン勤怠管理で取得しない項目をCSVフォーマットで設定しても取得しません。
        </li>
      </ul>
      <p className="u-mt20">
        【CSVフォーマット】<br />
        給与規定グループ毎にジョブカン勤怠管理で設定したCSVフォーマットを紐付けします。<br />
        紐付けをしないとAPI連携できないのでご注意ください。
      </p>
      <p className="u-mt20">
        【日時での自動連携】<br />
        自動連携にチェックONして日と時間を指定することでその時間に毎月自動で勤怠データを取得します。<br />
        例えば勤怠の締め日が末日の場合は1日 05:00などに設定しておくことで自動で締めたデータを取得することができます。
      </p>
      <ul className="m-list-notes">
        <li>※従業員の数が多いほど取得に時間がかかるので3:00～5:00など時間帯に設定することを推奨します。</li>
      </ul>
      <p className="u-mt20">
        【従業員の連携設定】<br />
        <a href={SETTING_EMPLOYEES_SYNC_ATTENDANCE_SHOW}>
          従業員の勤怠連携設定
        </a>で従業員毎にAPI連携するかどうかの設定ができます。<br />
        デフォルトで有効になっていますので連携を解除したい従業員がいる場合は設定を行って下さい。
      </p>
    </div>
  </div>
);

const SyncJbcSettingReduxForm = reduxForm({
  form: SYNC_JBC_SETTING_FORM,
  enableReinitialize: true
})(SyncJbcSettingForm);

const ConnectedSettingReduxForm = connect(
  state => ({
    initialValues: {
      clientSyncTaskSettings: get(state, ['clientSyncTaskSettings', 'list', 'data']) || []
    },
    errors: []
  }),
  dispatch => ({
    onSubmit: data => dispatch(saveClientSyncTaskSettings(data))
  })
)(SyncJbcSettingReduxForm);

class SyncJbcSettings extends React.Component {
  componentDidMount() {
    const { dispatch, values } = this.props;
    dispatch(fetchClientPayrollRuleGroups());
    dispatch(setClientTaskSyncSettingList(values.clientSyncTaskSettings));
  }

  render() {
    return (
      <React.Fragment>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">ジョブカン勤怠管理との連携設定</h1>
        </div>
        <div className="l-wrap-xl l-contents-wrap">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="サービス連携" path={COORDINATION_SERVICE_LIST} />
            <BreadCrumb.Item label="ジョブカン勤怠管理との連携設定" />
          </BreadCrumb>
          <Note />
          <div className="u-mt20">
            <ConnectedSettingReduxForm />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(SyncJbcSettings);
