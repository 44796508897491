import React from 'react';
import _ from 'lodash';

import { numberWithComma } from 'src/utils/Utils';

class YearlySummaryAccidentInsurance extends React.Component {
  constructor(props) {
    super(props);
    const { monthlySummaries } = props;
    this.state = { ...monthlySummaries };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.yearlySummary, prevProps.yearlySummary)) {
      // 年プルダウンを変更したとき合計系のデータが渡されるので計算済みの結果をそのままセットする
      this.setState({
        accidentInsuranceEmployeeCommonCount: this.props.yearlySummary.accidentInsuranceEmployeeCommonCount,
        accidentInsuranceEmployeeCommonPay: this.props.yearlySummary.accidentInsuranceEmployeeCommonPay,
        accidentInsuranceEmployeeTreatedCount: this.props.yearlySummary.accidentInsuranceEmployeeTreatedCount,
        accidentInsuranceEmployeeTreatedPay: this.props.yearlySummary.accidentInsuranceEmployeeTreatedPay,
        accidentInsuranceEmployeeTemporaryCount: this.props.yearlySummary.accidentInsuranceEmployeeTemporaryCount,
        accidentInsuranceEmployeeTemporaryPay: this.props.yearlySummary.accidentInsuranceEmployeeTemporaryPay,
        totalAccidentInsuranceEmployeeCount: this.props.yearlySummary.totalAccidentInsuranceEmployeeCount,
        totalAccidentInsuranceEmployeePay: this.props.yearlySummary.totalAccidentInsuranceEmployeePay
      });
    }
  }

  calculation(params) {
    const accidentInsuranceEmployeeCommonCountList = _.map(
      _.filter(params, o => _.isNumber(o.accidentInsuranceEmployeeCommonCount)),
      p => p.accidentInsuranceEmployeeCommonCount
    );
    const accidentInsuranceEmployeeCommonPayList = _.map(
      _.filter(params, o => _.isNumber(o.accidentInsuranceEmployeeCommonPay)),
      p => p.accidentInsuranceEmployeeCommonPay
    );
    const accidentInsuranceEmployeeTreatedCountList = _.map(
      _.filter(params, o => _.isNumber(o.accidentInsuranceEmployeeTreatedCount)),
      p => p.accidentInsuranceEmployeeTreatedCount
    );
    const accidentInsuranceEmployeeTreatedPayList = _.map(
      _.filter(params, o => _.isNumber(o.accidentInsuranceEmployeeTreatedPay)),
      p => p.accidentInsuranceEmployeeTreatedPay
    );
    const accidentInsuranceEmployeeTemporaryCountList = _.map(
      _.filter(params, o => _.isNumber(o.accidentInsuranceEmployeeTemporaryCount)),
      p => p.accidentInsuranceEmployeeTemporaryCount
    );
    const accidentInsuranceEmployeeTemporaryPayList = _.map(
      _.filter(params, o => _.isNumber(o.accidentInsuranceEmployeeTemporaryPay)),
      p => p.accidentInsuranceEmployeeTemporaryPay
    );
    const totalAccidentInsuranceEmployeeCountList = _.map(
      _.filter(params, o => _.isNumber(o.totalAccidentInsuranceEmployeeCount)),
      p => p.totalAccidentInsuranceEmployeeCount
    );
    const totalAccidentInsuranceEmployeePayList = _.map(
      _.filter(params, o => _.isNumber(o.totalAccidentInsuranceEmployeePay)),
      p => p.totalAccidentInsuranceEmployeePay
    );

    const calcValues = {
      accidentInsuranceEmployeeCommonCount: _.reduce(
        accidentInsuranceEmployeeCommonCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      accidentInsuranceEmployeeCommonPay: _.reduce(
        accidentInsuranceEmployeeCommonPayList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      accidentInsuranceEmployeeTreatedCount: _.reduce(
        accidentInsuranceEmployeeTreatedCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      accidentInsuranceEmployeeTreatedPay: _.reduce(
        accidentInsuranceEmployeeTreatedPayList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      accidentInsuranceEmployeeTemporaryCount: _.reduce(
        accidentInsuranceEmployeeTemporaryCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      accidentInsuranceEmployeeTemporaryPay: _.reduce(
        accidentInsuranceEmployeeTemporaryPayList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      totalAccidentInsuranceEmployeeCount: _.reduce(
        totalAccidentInsuranceEmployeeCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      totalAccidentInsuranceEmployeePay: _.reduce(
        totalAccidentInsuranceEmployeePayList,
        (sum, n) => sum + _.toInteger(n),
        0
      )
    };

    this.setState({
      ...params,
      ...calcValues
    });
  }

  tableHeader = text => {
    // 改行で文字列を分割
    const lines = text && text.split('\n');

    return (
      <React.Fragment>
        {lines && lines.length > 1 ? lines.map(line => <div key={`sumAmount${line}`}>{line}</div>) : text}
      </React.Fragment>
    );
  };

  render() {
    return (
      <tr>
        <th>{this.tableHeader(this.props.title)}</th>
        <td>{numberWithComma(this.state.accidentInsuranceEmployeeCommonCount)}</td>
        <td>{numberWithComma(this.state.accidentInsuranceEmployeeCommonPay)}</td>
        <td>{numberWithComma(this.state.accidentInsuranceEmployeeTreatedCount)}</td>
        <td>{numberWithComma(this.state.accidentInsuranceEmployeeTreatedPay)}</td>
        <td>{numberWithComma(this.state.accidentInsuranceEmployeeTemporaryCount)}</td>
        <td>{numberWithComma(this.state.accidentInsuranceEmployeeTemporaryPay)}</td>
        <td>{numberWithComma(this.state.totalAccidentInsuranceEmployeeCount)}</td>
        <td>{numberWithComma(this.state.totalAccidentInsuranceEmployeePay)}</td>
      </tr>
    );
  }
}

export default YearlySummaryAccidentInsurance;
