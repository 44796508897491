import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { DASHBOARD_SHOW } from 'src/constants/EndpointUrls';
import { USER_ACCOUNT_EMAIL_FORM } from 'src/constants/FormNames';
import { isEmail } from 'src/utils/CustomValidators';
import { Section, TextField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import { getCurrentUserInfo, editEmail } from 'src/reducers/users/currentUser';
import { redirectTo } from 'src/utils/Http';

const RawSettingEmailForm = ({ handleSubmit, submit, pristine, submitting }) => (
  <form onSubmit={handleSubmit}>
    <Section>
      <TextField label="メールアドレス" name="email" required validate={isEmail} />
      <TextField label="パスワード" name="password" type="password" required />
    </Section>
    <div className="u-ta-c u-mt30">
      <Button onClick={() => redirectTo(DASHBOARD_SHOW)} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn {...{ submit, submitting, pristine }} />
    </div>
  </form>
);

const SettingEmailForm = reduxForm({
  form: USER_ACCOUNT_EMAIL_FORM
})(RawSettingEmailForm);

export default connect(
  state => ({
    email: (getCurrentUserInfo(state) || {}).email
  }),
  dispatch => ({
    onSubmit: data => dispatch(editEmail(data))
  })
)(SettingEmailForm);
