import React from 'react';
import { connect } from 'react-redux';
import { FormSection } from 'redux-form';
import classNames from 'classnames';

import Button from 'jbc-front/components/Button';
import { Section, TextField, SelectField, BoxDouble } from 'jbc-front/components/Form';

import { CLIENT_ROLES_LIST } from 'src/constants/EndpointUrls';
import * as CssClassNames from 'src/constants/CssClassNames';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { FormErrors } from 'src/components';
import { CancelBtn } from 'src/buttons';

const RoleForm = ({ handleSubmit, submit, errors, permitRanges, permitOptions, pristine, submitting }) => (
  <form className={classNames(CssClassNames.FORM, CssClassNames.CLIENT_FORM)} onSubmit={handleSubmit}>
    <FormErrors errors={errors} />

    <Section title="権限追加">
      <TextField required label="権限名" name="name" />

      <BoxDouble>
        <FormSection name="showEmployee">
          <SelectField required label="従業員情報（閲覧）" name="permitRange" options={permitRanges} />
        </FormSection>
        <FormSection name="editEmployee">
          <SelectField required label="従業員情報（編集）" name="permitRange" options={permitRanges} />
        </FormSection>
      </BoxDouble>

      <BoxDouble>
        <FormSection name="showAttendance">
          <SelectField required label="勤怠情報（閲覧）" name="permitRange" options={permitRanges} />
        </FormSection>
        <FormSection name="editAttendance">
          <SelectField required label="勤怠情報（編集）" name="permitRange" options={permitRanges} />
        </FormSection>
      </BoxDouble>

      <BoxDouble>
        <FormSection name="showPay">
          <SelectField required label="給与情報（閲覧）" name="permitRange" options={permitRanges} />
        </FormSection>
        <FormSection name="editPay">
          <SelectField required label="給与情報（編集）" name="permitRange" options={permitRanges} />
        </FormSection>
      </BoxDouble>

      <FormSection name="confirmPay">
        <SelectField required label="給与確定" name="isPermit" options={permitOptions} />
      </FormSection>

      <BoxDouble>
        <FormSection name="showBonus">
          <SelectField required label="賞与情報（閲覧）" name="permitRange" options={permitRanges} />
        </FormSection>
        <FormSection name="editBonus">
          <SelectField required label="賞与情報（編集）" name="permitRange" options={permitRanges} />
        </FormSection>
      </BoxDouble>

      <FormSection name="confirmBonus">
        <SelectField required label="賞与確定" name="isPermit" options={permitOptions} />
      </FormSection>

      <FormSection name="createReport">
        <SelectField required label="書類作成" name="isPermit" options={permitOptions} />
      </FormSection>

      <FormSection name="manageRole">
        <SelectField required label="権限管理" name="isPermit" options={permitOptions} />
      </FormSection>

      <FormSection name="showAuditLog">
        <SelectField required label="操作履歴" name="isPermit" options={permitOptions} />
      </FormSection>

      <BoxDouble>
        <CancelBtn href={CLIENT_ROLES_LIST} />
        <Button onClick={submit} disabled={pristine || submitting} primary>
          保存
        </Button>
      </BoxDouble>
    </Section>
  </form>
);

const mapStateToProps = state => ({
  permitRanges: getSelectOptions(state, 'permitRanges'),
  permitOptions: getSelectOptions(state, 'permitOptions')
});
export default connect(mapStateToProps)(RoleForm);
