import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

// Form
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';

import { EMPLOYEE_BONUS_CSV_FORM } from 'src/constants/FormNames';

// Reducer
import { downloadBonusCsvFile } from 'src/reducers/employees/employeeBonus';

// Other
import { ENCODING_OPTIONS } from 'src/constants/Options';
import { UTF_8 } from 'src/constants/Generals';

const DownloadCsvInsideForm = ({ handleSubmit, submitting, submit, cancelBtnClick, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <DownloadCsvEncodingRadioButton
          name={'encoding'}
          label={'ファイル形式を選択'}
          options={ENCODING_OPTIONS}
          required={false}
        />
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="ダウンロード" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let DownloadCsvForm = reduxForm({
  form: EMPLOYEE_BONUS_CSV_FORM,
  initialValues: { encoding: UTF_8 }
})(DownloadCsvInsideForm);

DownloadCsvForm = connect(undefined, (dispatch, ownProps) => ({
  onSubmit: data => {
    dispatch(downloadBonusCsvFile(ownProps.clientBonusId, ownProps.queries, data));
    ownProps.cancelBtnClick();
  }
}))(DownloadCsvForm);

const DownloadFbCsvModal = ({ isOpen, hideModal, clientBonus, queries }) => {
  const header = `${clientBonus.label}の賞与振込ダウンロード`;

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <DownloadCsvForm cancelBtnClick={hideModal} clientBonusId={clientBonus.value} header={header} queries={queries} />
    </CommonModal>
  );
};

export default DownloadFbCsvModal;
