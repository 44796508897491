import React from 'react';
import _ from 'lodash';
import { TextAreaField, SelectField, Label, CheckboxField, TextField, RadioField } from 'jbc-front/components/Form';
import { updateFormField } from 'src/reducers/clients/allowances';
import { isFormula, isMoneyNumber, maxLength1000 } from 'src/utils/CustomValidators';
import styles from './FormulaSettings.scss';

const NUMBER_DAYS = 'number_of_days';
const TIME = 'time';
const NUMBER_OF_TIMES = 'number_of_times';
const UNIT_PRICE = 'unit_price';
const PAYMENT = 'payment';
const EMPLOYEE = 'employee';

const AllowanceItem = ({ allowanceItems, handleOnClick }) => (
  <React.Fragment>
    {allowanceItems &&
      allowanceItems.map(allowanceItem => (
        <div
          key={allowanceItem.value}
          className={styles.target}
          role="presentation"
          onClick={e => {
            e.preventDefault();
            handleOnClick(allowanceItem.label, false);
          }}
        >
          {allowanceItem.label}
        </div>
      ))}
  </React.Fragment>
);

const CalcOperator = ({ displayOperator, operator, handleOnClick, className }) => (
  <div
    className={className || styles.single}
    role="presentation"
    onClick={e => {
      e.preventDefault();
      handleOnClick(operator, true);
    }}
  >
    {displayOperator}
  </div>
);

const isLimitValueRange = (value, allValues) => {
  const lowerLimit = allValues.lowerLimit;
  if (!(allValues.isUpperLimit && allValues.isLowerLimit)) return undefined;
  if (_.isEmpty(value)) return undefined;
  if (Number(value) > Number(lowerLimit)) return undefined;
  return 'は下限金額より大きな値を入力してください';
};

class FormulaSettings extends React.Component {
  constructor(props) {
    super(props);

    this.updateFormula = this.updateFormula.bind(this);
    this.handleOnSelectAllowanceItemType = this.handleOnSelectAllowanceItemType.bind(this);

    this.state = {
      selectedAllowanceItemType: NUMBER_DAYS
    };
    this.props.dispatch(updateFormField('allowanceItemTypes', NUMBER_DAYS));
  }

  updateFormula = (selectedValue, isOperator) => {
    if (!selectedValue) return;

    const { currentFormula, formulaFieldName } = this.props;
    let additionFormula = selectedValue;

    if (!isOperator && additionFormula && additionFormula.length > 0) {
      additionFormula = `【${additionFormula}】`;
    }

    let newFormula;
    if (currentFormula) {
      // 計算式内にあるカーソルの位置に文字列を挿入する
      // 計算式にフォーカスが当たっていないときは計算式の一番最後に文字列を追加
      const pos = document.querySelector('textarea').selectionStart;
      newFormula = currentFormula.substr(0, pos) + additionFormula + currentFormula.substr(pos, currentFormula.length);
    } else {
      newFormula = additionFormula;
    }

    this.props.dispatch(updateFormField(formulaFieldName, newFormula));
  };

  handleOnSelectAllowanceItemType(e, selected) {
    this.setState({
      ...this.state,
      selectedAllowanceItemType: selected
    });
  }

  render() {
    const {
      allowanceItemTypes,
      allowanceItemsNumberOfDays,
      allowanceItemsTime,
      allowanceItemsNumberOfTimes,
      allowanceItemsPayment,
      allowanceItemsEmployee,
      clientUnitPrices,
      rounding1YenType,
      roundingDigit,
      formulaFieldName,
      checkUpperLimit,
      checkLowerLimit
    } = this.props;

    return (
      <div>
        <div className={styles.createFormula}>
          <div className={styles.selectFormula}>
            <p>項目</p>
            <SelectField
              name="allowanceItemTypes"
              options={allowanceItemTypes}
              addBlankOption={false}
              onChange={(e, selected) => this.handleOnSelectAllowanceItemType(e, selected)}
            />
            <div className={styles.selectFormulaInner}>
              <div>
                {this.state.selectedAllowanceItemType === NUMBER_DAYS && (
                  <AllowanceItem allowanceItems={allowanceItemsNumberOfDays} handleOnClick={this.updateFormula} />
                )}
                {this.state.selectedAllowanceItemType === TIME && (
                  <AllowanceItem allowanceItems={allowanceItemsTime} handleOnClick={this.updateFormula} />
                )}
                {this.state.selectedAllowanceItemType === NUMBER_OF_TIMES && (
                  <AllowanceItem allowanceItems={allowanceItemsNumberOfTimes} handleOnClick={this.updateFormula} />
                )}
                {this.state.selectedAllowanceItemType === UNIT_PRICE && (
                  <AllowanceItem allowanceItems={clientUnitPrices} handleOnClick={this.updateFormula} />
                )}
                {this.state.selectedAllowanceItemType === PAYMENT && (
                  <AllowanceItem allowanceItems={allowanceItemsPayment} handleOnClick={this.updateFormula} />
                )}
                {this.state.selectedAllowanceItemType === EMPLOYEE && (
                  <AllowanceItem allowanceItems={allowanceItemsEmployee} handleOnClick={this.updateFormula} />
                )}
              </div>
            </div>
          </div>
          <div className={styles.symbolFormulaWrap}>
            <div className={styles.symbolFormula}>
              <CalcOperator displayOperator="＋" operator="+" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="−" operator="-" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="×" operator="*" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="÷" operator="/" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="（" operator="(" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="）" operator=")" handleOnClick={this.updateFormula} />
              <CalcOperator
                displayOperator="IF"
                operator="IF(, , )"
                handleOnClick={this.updateFormula}
                className={styles.double}
              />
              <CalcOperator displayOperator="=" operator="=" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="≠" operator="<>" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="<" operator="<" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator=">" operator=">" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="≦" operator="<=" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="≧" operator=">=" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="AND" operator="AND" handleOnClick={this.updateFormula} />
              <CalcOperator displayOperator="OR" operator="OR" handleOnClick={this.updateFormula} />
            </div>
          </div>
          <div className={styles.showFormula}>
            <TextAreaField name={formulaFieldName} label="計算式" required validate={[isFormula, maxLength1000]} />
          </div>
        </div>

        <Label required text="端数処理" />
        <div className={styles.selectRoundingDigit}>
          <SelectField name="roundingDigit" options={roundingDigit} />
        </div>
        <RadioField name="rounding1YenType" options={rounding1YenType} />
        <Label text="上限下限設定" note={<p>上限下限金額は、端数処理後の値で判定します。</p>} />
        <div>
          <CheckboxField name="isUpperLimit" label="上限金額を設定する" normalize={value => !!value} />
        </div>
        {checkUpperLimit && (
          <TextField
            label="上限金額"
            name="upperLimit"
            required={!!checkUpperLimit}
            yen
            validate={[isMoneyNumber, isLimitValueRange]}
            noLabel
          />
        )}
        <div>
          <CheckboxField name="isLowerLimit" label="下限金額を設定する" normalize={value => !!value} />
        </div>
        {checkLowerLimit && (
          <TextField
            label="下限金額"
            name="lowerLimit"
            required={!!checkLowerLimit}
            yen
            validate={[isMoneyNumber]}
            noLabel
          />
        )}
      </div>
    );
  }
}

export default FormulaSettings;
