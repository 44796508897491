import React from 'react';
import { Field } from 'redux-form';
import { arrowRenderer } from 'jbc-front/components/Form';
import MainSearchSelectBox from 'src/components/MainSearchSelectBox';
import styles from './SelectBoxGroups.scss';

class SelectBoxGroups extends React.Component {
  render() {
    const {
      bonusNames,
      applicableOffices,
      changeFormValue,
      fetchReportHealthInsuranceBonusOfficeOptions,
      isOfficeOptionLoading
    } = this.props;
    return (
      <div className={styles.container}>
        <div className={styles.selectBonus}>
          <Field
            name="bonusId"
            component={MainSearchSelectBox}
            options={bonusNames}
            arrowRenderer={arrowRenderer}
            onChange={(e, newValue) => {
              changeFormValue('bonusId', newValue);
              changeFormValue('applicableOffice', applicableOffices[0].value);
              fetchReportHealthInsuranceBonusOfficeOptions(newValue);
            }}
          />
        </div>
        {applicableOffices.length > 1 && (
          <div className={styles.selectOffice}>
            <Field
              name="applicableOffice"
              component={MainSearchSelectBox}
              options={applicableOffices}
              disabled={isOfficeOptionLoading}
              arrowRenderer={arrowRenderer}
            />
          </div>
        )}
      </div>
    );
  }
}

export default SelectBoxGroups;
