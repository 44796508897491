import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { EditActionBtn, SaveActionBtn, CancelActionBtn } from 'src/buttons';
import {
  fetchEmailNotificationSettingList,
  getEmailSettingList,
  setEmailSettingList,
  saveEmailSettingList
} from 'src/reducers/admin_email_notification_settings/admin_email_notification_settings';
import EmailNotificationSettingTable from './components/EmailNotificationSettingTable';
import PaginationContainer from './components/PaginationContainer';

import styles from './AdminEmailNotificationSetting.scss';

const EDIT_MODE = 'EDIT';
const SHOW_MODE = 'SHOW';

class AdminEmailNotificationSettingList extends Component {
  state = {
    mode: SHOW_MODE,
    orgEmailSettingList: []
  };

  isShowMode = () => this.state.mode === SHOW_MODE;

  componentDidMount() {
    this.props.fetchNotificationSettingList();
  }

  toEditMode = () => {
    const { emailSettingList = [] } = this.props;
    this.setState({
      ...this.state,
      mode: EDIT_MODE,
      orgEmailSettingList: _.map(emailSettingList, item => ({ ...item }))
    });
  };

  cancelEditMode = () => {
    const { dispatch } = this.props;
    dispatch(setEmailSettingList(this.state.orgEmailSettingList));
    this.setState({ ...this.state, mode: SHOW_MODE, orgEmailSettingList: [] });
  };

  closeEditMode = () => {
    this.setState({ ...this.state, mode: SHOW_MODE, orgEmailSettingList: [] });
  };

  render() {
    const { saveSettingList } = this.props;
    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">メール通知の設定</h1>
          <p className="m-title-main-note">管理者・運用担当者に送られるメールの受信設定をすることができます</p>
        </div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
              <BreadCrumb.Item label="メール通知の設定" />
            </BreadCrumb>
          </div>
          <div className={styles.description}>
            <p>システムが送信するメールは以下となります。</p>
            <ul className={styles.items}>
              <li>・「勤怠情報」の「ジョブカン勤怠管理からのデータ取得」「勤怠項目のCSVアップロード」が完了</li>
              <li>・「従業員情報」の新規もしくは更新のアップロードが完了したとき</li>
              <li>・「給与」の確定処理が完了したとき、「給与」を一括で手入力前に戻す処理が完了したとき</li>
              <li>・「賞与」の更新・確定処理が完了したとき</li>
              <li>・　支給控除項目のCSVダウンロード・インポートが完了した時</li>
            </ul>
          </div>
          <div>
            {this.isShowMode() ? (
              <div className={styles.action}>
                <EditActionBtn onClick={this.toEditMode} />
              </div>
            ) : (
              <div className={styles.action}>
                <SaveActionBtn onClick={() => saveSettingList(this.closeEditMode)} />
                <CancelActionBtn onClick={this.cancelEditMode} />
              </div>
            )}
          </div>
          <div className="u-mt20">
            <EmailNotificationSettingTable mode={this.state.mode} />
          </div>
          <PaginationContainer closeEditMode={this.closeEditMode} />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    emailSettingList: getEmailSettingList(state)
  }),
  dispatch => ({
    fetchNotificationSettingList: () => {
      dispatch(fetchEmailNotificationSettingList());
    },
    saveSettingList: closeEditMode => {
      dispatch(saveEmailSettingList(closeEditMode));
    },
    dispatch
  })
)(AdminEmailNotificationSettingList);
