import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { takeEmployeesSearchQueries, FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import { copyCsvFormat } from 'src/reducers/clients/csvFormats';
import { downloadEmployeeCsv } from 'src/reducers/employees/employeesAsync';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import { CLIENT_CSV_EMPLOYEE_FORMAT_EDIT } from 'src/constants/EndpointUrls';
import DownloadCsvInsideForm from '../components/DownloadCsvModal';

const defaultQuery = {
  enrollment_status: '2'
};

// システムで定義されたフォーマットは閲覧・編集・コピーできないので判定
const isTemplate = (formats, formValues) => {
  if (_.isEmpty(formats) || _.isEmpty(formValues) || _.isEmpty(formValues.csvFormat)) {
    return true;
  }
  const target = formats.find(format => format.value === formValues.csvFormat);
  return target.isTemplate;
};

const mapStateToProps = state => {
  const queries = takeEmployeesSearchQueries(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state));
  const hasConditions = !_.isEqual(_.pickBy(_.omit(queries, ['order', 'page', 'per_page', 'sort'])), defaultQuery);
  return {
    initialValues: {
      encoding: 'UTF-8',
      target: hasConditions ? 'search' : 'working and leaved',
      csvFormat: 'all'
    },
    csvFormats: getSelectOptions(state, 'exportCsvFormats'),
    selectedFormat: getEmployeeFormValues(state),
    isTemplate: isTemplate(getSelectOptions(state, 'exportCsvFormats'), getEmployeeFormValues(state)),
    queries
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => dispatch(downloadEmployeeCsv(data, ownProps)),
  copyFormat: csvFormatId => dispatch(copyCsvFormat(csvFormatId, CLIENT_CSV_EMPLOYEE_FORMAT_EDIT))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: EMPLOYEE_FORM,
    enableReinitialize: true
  })
)(DownloadCsvInsideForm);
