import { connect } from 'react-redux';
import EgovModalForm from 'src/components/Egov/EgovModalForm';
import { setStatus, postEgov } from 'src/reducers/egov';
import { checkNotificationOfBaseAmountRevisionsSearchFormHasConditions } from 'src/reducers/searchForm';

const mapStateToProps = state => {
  const ignoreQueries = [
    'order',
    'page',
    'per_page',
    'sort',
    'sort_type',
    'filter_type',
    'applicable_office',
    'revision_year'
  ];
  const hasConditions = checkNotificationOfBaseAmountRevisionsSearchFormHasConditions(state, ignoreQueries);

  return {
    initialValues: { egovTarget: hasConditions ? 'searched_employee' : 'all_employee' },
    isLoading: state.egov.loading,
    dataCheckErrors: state.egov.dataCheckErrors,
    postErrors: state.egov.postErrors
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => {
    dispatch(setStatus({ loading: true, dataCheckErrors: [], postErrors: [] }));
    dispatch(postEgov(ownProps.type, ownProps.postParams, data, ownProps.hideModal));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EgovModalForm);
