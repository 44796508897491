import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  getCurrentUserInfo,
  isRoleTypeAdmin,
  isRoleTypeOperator,
  isRoleTypeEmployee
} from 'src/reducers/users/currentUser';
import { isFreePlan } from 'src/reducers/clients/currentClient';
import Sidebar from 'jbc-front/components/Sidebar';
import {
  Dashboard,
  Employee,
  MyNumber,
  Setting,
  Procedure,
  Bonus,
  Calculator,
  Kintai,
  Close,
  Hamburger,
  SelectTable,
  Adjustment
} from 'jbc-front/components/icons';
import { FlaxTaxReductionIcon } from 'src/icons';
import * as Urls from 'src/constants/EndpointUrls';
import onClickOutside from 'react-onclickoutside';
import Cookies from 'js-cookie';
import _ from 'lodash';

import styles from './SideMenu.scss';

const SidebarMenu = ({ children, icon, title, ...rest }) => {
  const Icon = icon;
  return (
    <div className={styles.naviItemWrapOpened}>
      <div {...rest} className={styles.naviMenuOpened}>
        <div className={styles.naviMenuClickArea}>
          <Icon size={20} />
          {title}
        </div>
        {children}
      </div>
    </div>
  );
};

const AdminSideMenu = ({ isOpen, offset, adminFlg, planType }) => (
  <Sidebar offset={offset} isOpen={isOpen}>
    <Sidebar.Item as="a" href={Urls.DASHBOARD_SHOW} icon={Dashboard}>
      ダッシュボード
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.EMPLOYEE_ATTENDANCE_LIST} icon={Kintai}>
      勤怠
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.EMPLOYEE_PAY_LIST} icon={Calculator}>
      給与
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.EMPLOYEE_BONUS_LIST} icon={Bonus}>
      賞与
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.REPORT_INDEX} icon={Procedure}>
      書類作成
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.EMPLOYEES_LIST} icon={Employee}>
      従業員一覧
    </Sidebar.Item>
    {adminFlg && (
      <Sidebar.Item as="a" href={Urls.MY_NUMBER_LIST} icon={MyNumber}>
        マイナンバー
      </Sidebar.Item>
    )}
    {!isFreePlan(planType) && (
      <Sidebar.Item as="a" href={Urls.YEAR_END_ADJ_START} target="_blank" icon={Adjustment}>
        年末調整
      </Sidebar.Item>
    )}
    <Sidebar.Item as="a" href={Urls.CLIENT_SETTINGS} icon={Setting}>
      設定
    </Sidebar.Item>
    <SidebarMenu icon={FlaxTaxReductionIcon} title="2024年定額減税">
      <Sidebar.MenuItem as="a" href={Urls.FLAT_TAX_REDUCTION_EMPLOYEE_LIST}>
        減税対象人数登録
      </Sidebar.MenuItem>
      <Sidebar.MenuItem as="a" href={Urls.FLAT_TAX_REDUCTION_AMOUNT_LIST}>
        既減税額登録
      </Sidebar.MenuItem>
      <Sidebar.MenuItem as="a" href={Urls.FLAT_TAX_REDUCTION_CONFIRMATION_LIST}>
        確定処理
      </Sidebar.MenuItem>
    </SidebarMenu>
  </Sidebar>
);

const EmployeeSideMenu = ({ isOpen, offset }) => (
  <Sidebar offset={offset} isOpen={isOpen}>
    <Sidebar.Item as="a" href={Urls.DASHBOARD_SHOW} icon={Dashboard}>
      ダッシュボード
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.EMPLOYEE_MY_PAYSLIPS} icon={Calculator}>
      給与
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.EMPLOYEE_MY_BONUS} icon={Bonus}>
      賞与
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.EMPLOYEE_MY_INCOME_TAX_WITHHOLDING} icon={SelectTable}>
      源泉徴収票
    </Sidebar.Item>
    <Sidebar.Item as="a" href={Urls.USER_PROFILE_EDIT} icon={Setting}>
      設定
    </Sidebar.Item>
  </Sidebar>
);

class _SideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSidebarOpen: false
    };
  }

  handleClickOutside = () => {
    this.HideMenu();
  };

  handleTap = () => {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen
    });
  };

  HideMenu = () => {
    this.setState({
      isSidebarOpen: false
    });
  };

  render() {
    const { currentUser, planType } = this.props;
    const offset = _.get(currentUser, 'email') && Cookies.get('using_jbc_payroll') ? 80 : 56;

    return (
      <div className={styles.container}>
        {(isRoleTypeAdmin(currentUser.roleType) || isRoleTypeOperator(currentUser.roleType)) && (
          <AdminSideMenu
            isOpen={this.state.isSidebarOpen}
            offset={offset}
            adminFlg={isRoleTypeAdmin(currentUser.roleType)}
            planType={planType}
          />
        )}
        {isRoleTypeEmployee(currentUser.roleType) && (
          <EmployeeSideMenu isOpen={this.state.isSidebarOpen} offset={offset} />
        )}
        <div onClick={this.handleTap} className={styles.menuBtn} role="button" tabIndex="0">
          {this.state.isSidebarOpen ? <Close size={20} /> : <Hamburger size={20} />}
        </div>
      </div>
    );
  }
}

const SideMenu = onClickOutside(_SideMenu);

export default connect(state => ({
  currentUser: getCurrentUserInfo(state) || {},
  planType: _.get(state, 'currentClient.item.data.planType')
}))(SideMenu);
