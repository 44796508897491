import React from 'react';
import onClickOutside from 'react-onclickoutside';
import styles from './IconActionButton.scss';

class IconActionButton extends React.Component {
  state = {
    isBaloonOpen: false
  };

  handleClickOutside = () => {
    this.setState({ isBaloonOpen: false });
  };

  render() {
    const { icon, options: Options } = this.props;

    return (
      <div className={styles.buttonWrap}>
        <div
          className={styles.button}
          onClick={() => this.setState({ isBaloonOpen: !this.state.isBaloonOpen })}
          role="presentation"
        >
          {icon}
        </div>
        {Options &&
          this.state.isBaloonOpen && (
            <div className={styles.baloonWrap}>
              <div className={styles.baloon}>
                <Options />
              </div>
            </div>
          )}
      </div>
    );
  }
}
export default onClickOutside(IconActionButton);
