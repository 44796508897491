import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import Contract from 'jbc-front/components/icons/Contract';

const CollapseActionBtn = ({ onClick, label = '全て閉じる', ...rest }) => (
  <ActionButton as="button" icon={<Contract />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default CollapseActionBtn;
