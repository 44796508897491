import React from 'react';
import Loading from 'src/components/Loading';
import styles from './AnyJobRunning.scss';

const AnyJobRunning = () => (
  <div className={styles.wrap}>
    <div className={styles.inner}>
      <div className={styles.waiting}>
        <div className={styles.loading}>
          <Loading />
        </div>
        <div className={styles.info}>
          <p className={styles.title}>CSVで従業員データを登録中です。</p>
          <p className="u-mt10">登録中は従業員情報の新規登録、更新はできません。</p>
        </div>
      </div>
    </div>
  </div>
);

export default AnyJobRunning;
