import React, { useState } from 'react';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { renderRadioField as RadioField } from 'jbc-front/components/Form';
import _ from 'lodash';
import { INHABITANT_TAX_TABLE_PDF_LAYOUT_TYPES } from 'src/constants/Options';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import styles from './SelectInhabitantTaxTablePDFModal.scss';

const SelectInhabitantTaxTablePDFModal = ({ isOpen, hideModal, employeePayWithInhabitants, url, selectedLabel }) => {
  const header = '住民税振込一覧表PDF出力';
  const [layoutType, setLayoutType] = useState('by_employee');
  const [isShowPreview, setIsShowPreview] = useState(false);
  const showPdfUrl = `${url}&layout_type=${layoutType}`;
  return (
    <CommonModal className={styles.modalWidth} ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>
        <div className={styles.modalHeader}>
          <span>{header}</span>
          <span>{selectedLabel}</span>
        </div>
      </CommonModal.Header>

      <CommonModal.Body>
        <p className={styles.lead}>表示形式を選択してください </p>
        <RadioField
          input={{ value: layoutType, onChange: e => setLayoutType(e.target.value) }}
          meta={{}}
          options={INHABITANT_TAX_TABLE_PDF_LAYOUT_TYPES}
        />
      </CommonModal.Body>

      <CommonModal.Footer>
        <Button onClick={hideModal} className="u-mr20">
          キャンセル
        </Button>

        <ActionButton
          primary
          className={styles.overrideActionButton}
          onClick={() => setIsShowPreview(true)}
          disabled={_.isEmpty(employeePayWithInhabitants)}
        >
          作成する
        </ActionButton>

        <PreviewPdfModalContainer
          header="住民税振込一覧表"
          isOpen={isShowPreview}
          hideModal={() => setIsShowPreview(false)}
          url={showPdfUrl}
        />
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default SelectInhabitantTaxTablePDFModal;
