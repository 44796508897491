import { clearFields } from 'redux-form';
import axios from 'axios';
import contentDisposition from 'content-disposition';
import saveAs from 'file-saver';
import humps from 'humps';

import * as Urls from 'src/constants/EndpointUrls';
import { FLAT_TAX_REDUCTION_CSV_UPLOAD_FORM } from 'src/constants/FormNames';
import { paramsWithFile } from 'src/utils/Form';
import { scrollToTop } from 'src/utils/Utils';
import { serializeHttpGetParams } from 'src/utils/Http';
import { setGlobalErrors, setGlobalWarnings } from '../global';

export const EMPLOYEE_MODE = 'employee';
export const AMOUNT_MODE = 'amount';
export const CONFIRMED_MODE = 'confirmed';

// eslint-disable-next-line import/prefer-default-export
export const downloadCsv = async (dispatch, encoding, target, query, mode) => {
  let url = '';
  switch (mode) {
    case CONFIRMED_MODE:
      url = Urls.DOWNLOAD_CONFIRMED_FLAT_TAX_REDUCTION_CSV;
      break;
    case AMOUNT_MODE:
      url = Urls.DOWNLOAD_AMOUNT_FLAT_TAX_REDUCTION_CSV;
      break;
    default:
      url = Urls.DOWNLOAD_EMPLOYEE_FLAT_TAX_REDUCTION_CSV;
      break;
  }

  const params = target === 'all' ? {} : query;
  await fetch(`${url}?${serializeHttpGetParams({ ...params, encoding })}`)
    .then(response => {
      if (response.ok) {
        response.blob().then(blob => {
          const disposition = contentDisposition.parse(response.headers.get('content-disposition') || '');
          saveAs(blob, disposition.parameters.filename);
        });
      } else {
        // サーバーエラー
        response.json().then(json => {
          dispatch(setGlobalErrors(json.errors.messages));
        });
      }
    })
    .catch(exception => {
      // 通信エラー
      // eslint-disable-next-line no-console
      console.warn(exception.message);
      dispatch(setGlobalErrors(['通信エラーが発生しました。', '通信状態の良い環境で再度お試しください。']));
    });
};

export const uploadCsv = (data, setResult) => async dispatch => {
  setResult({});
  const params = paramsWithFile(data, ['flat_tax_reduction_csv']);
  axios
    .post(Urls.IMPORT_FLAT_TAX_REDUCTION_CSV_URL, params)
    .then(async response => {
      const result = { ...humps.camelizeKeys(response.data) };
      setResult(result);
    })
    .catch(error => {
      const result = { ...humps.camelizeKeys(error.response.data) };
      setResult(result);
    });

  dispatch(clearFields(FLAT_TAX_REDUCTION_CSV_UPLOAD_FORM, false, false, 'flat_tax_reduction_csv'));
  dispatch(setGlobalWarnings(['インポートしています。', 'しばらくお待ちください。']));
  scrollToTop();
};

export const uploadConfirmCsv = (data, setResult) => async dispatch => {
  setResult({});
  const params = paramsWithFile(data, ['flat_tax_reduction_confirm_csv']);
  axios
    .post(Urls.IMPORT_FLAT_TAX_REDUCTION_CONFIRM_CSV_URL, params)
    .then(async response => {
      const result = { ...humps.camelizeKeys(response.data) };
      setResult(result);
    })
    .catch(error => {
      const result = { ...humps.camelizeKeys(error.response.data) };
      setResult(result);
    });

  dispatch(clearFields(FLAT_TAX_REDUCTION_CSV_UPLOAD_FORM, false, false, 'flat_tax_reduction_confirm_csv'));
  dispatch(setGlobalWarnings(['インポートしています。', 'しばらくお待ちください。']));
  scrollToTop();
};
