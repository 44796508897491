import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';
import { Field } from 'redux-form';
import Select from 'react-select';
import { SearchSelectBox, GroupsSelectBox, MultiTagFilteringFieldRow } from 'jbc-front/components/SearchForm';
import { RadioField } from 'jbc-front/components/Form';
import jbcStyles from 'jbc-front/components/SearchForm.scss';

import { convertNumberToStrOfValueInArray, convertArrayIntoJbcStyle } from 'src/utils/Utils';
import styles from 'src/components/AdditionalSearchFields.scss';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  EMPLOYMENT_STATUSES,
  ROLL_ACCOUNT_TYPE,
  NOTIFICATION_OF_BASE_AMOUNT_REVISION_FILTER_TYPES,
  ELECTRONIC_DELIVERY_AGREEMENT
} from 'src/constants/Options';

const TARGET_EMPLOYEE_BONUS = [
  { value: 'all', label: 'すべて' },
  { value: 'checked_on', label: '対象者のみ' },
  { value: 'checked_off', label: '非対象者のみ' }
];

export const groupFullName = ({ label, parentDepartmentId }, groups) =>
  parentDepartmentId && groups[parentDepartmentId]
    ? `${groupFullName(groups[parentDepartmentId], groups)}->${label}`
    : label;

const MemoColors = ({ input: inputProps, list }) => (
  <div className={styles.memoColors}>
    {list.map(item => (
      <label key={item.id} className={classNames('input-label', styles.memeLabel)}>
        <input
          type="checkbox"
          className="m-checkbox-input"
          checked={inputProps.value.indexOf(item.id) >= 0}
          onChange={() => {
            const newValues =
              inputProps.value.indexOf(item.id) >= 0
                ? inputProps.value.filter(ele => ele !== item.id)
                : [...inputProps.value, item.id];
            inputProps.onChange(newValues);
            inputProps.onBlur(newValues);
          }}
        />
        <span className={classNames('m-checkbox-parts', styles.memeCheckbox)}>
          <span style={{ color: item.name }}>●</span>
        </span>
      </label>
    ))}
  </div>
);

export class OfficesSelectBox extends React.Component {
  render() {
    const { input: inputProps, options } = this.props;
    return (
      <div style={{ width: '400px' }}>
        <Select
          multi
          options={options}
          closeOnSelect={false}
          clearableValue={false}
          simpleValue
          placeholder="事業所選択"
          name={inputProps.name}
          value={inputProps.value}
          onChange={value => {
            inputProps.onChange(value);
            inputProps.onBlur(value);
          }}
        />
      </div>
    );
  }
}

const AdditionalSearchFields = ({
  offices = [],
  groups = [],
  positions = [],
  employmentTypes = [],
  occupations = [],
  payrollRuleGroups = [],
  otherSearchOptions = [],
  syncYeaOptions = [],
  saveStatusOptions = [],
  isConfirmedPayrollOptions = [],
  isConfirmedOptions = [],
  memoColors = [],
  containsOtherSearch = true,
  containsBonusTarget,
  containAccountTypes,
  containEmployeeEnrollmentStatus,
  containsOfficeSearch,
  containsSubject,
  containSyncYeaTargets,
  containSaveStatus,
  containIsConfirmedPayroll,
  containIsConfirmed,
  containElectronicDeliveryAgreement
}) => (
  <div className={jbcStyles.details}>
    <div className={styles.flex}>
      <div className={styles.left}>
        {containsSubject && (
          <div className={jbcStyles.detailsRow}>
            <div className={jbcStyles.detailsHeader}>ステータス</div>
            <div className={jbcStyles.detailsBody}>
              <div className={jbcStyles.detailsBodyItems}>
                <Field
                  name="filterType"
                  component={SearchSelectBox}
                  width={400}
                  placeholder="ステータス選択"
                  options={NOTIFICATION_OF_BASE_AMOUNT_REVISION_FILTER_TYPES.map(option => ({
                    value: option.value,
                    label: option.label
                  }))}
                />
              </div>
            </div>
          </div>
        )}
        {containsOfficeSearch &&
          offices.length !== 1 && (
            <div className={jbcStyles.detailsRow}>
              <div className={jbcStyles.detailsHeader}>事業所</div>
              <div className={jbcStyles.detailsBody}>
                <div className={jbcStyles.detailsBodyItems}>
                  <Field
                    name="offices"
                    component={OfficesSelectBox}
                    options={offices.map(office => ({
                      value: office.value,
                      label: office.label
                    }))}
                  />
                </div>
              </div>
            </div>
          )}
        <div className={jbcStyles.detailsRow}>
          <div className={jbcStyles.detailsHeader}>グループ</div>
          <div className={jbcStyles.detailsBody}>
            <div className={classNames(jbcStyles.detailsBodyItems, styles.selectedGroupItem)}>
              <Field
                name="groups"
                component={GroupsSelectBox}
                options={groups.map(group => ({
                  value: `${group.value}`,
                  label: groupFullName(group, _.keyBy(groups, 'value')),
                  parent_value: `${group.parentDepartmentId}`
                }))}
              />
            </div>
          </div>
        </div>
        <MultiTagFilteringFieldRow header="役職" fieldName="positions" list={convertArrayIntoJbcStyle(positions)} />
        <MultiTagFilteringFieldRow
          header="雇用形態"
          fieldName="employmentTypes"
          list={convertArrayIntoJbcStyle(employmentTypes)}
        />
        <MultiTagFilteringFieldRow header="職種" fieldName="occupations" list={convertArrayIntoJbcStyle(occupations)} />
        {containElectronicDeliveryAgreement && (
          <MultiTagFilteringFieldRow
            header="電子交付"
            fieldName="isElectronicDeliveryAgreement"
            list={convertArrayIntoJbcStyle(ELECTRONIC_DELIVERY_AGREEMENT)}
          />
        )}
        <div className={jbcStyles.detailsRow}>
          <div className={jbcStyles.detailsHeader}>給与規定</div>
          <div className={jbcStyles.detailsBody}>
            <div className={jbcStyles.detailsBodyItems}>
              <Field
                name="payrollRuleGroup"
                component={SearchSelectBox}
                options={convertNumberToStrOfValueInArray(payrollRuleGroups)}
                width={400}
                clearable
                placeholder="給与規定グループ選択"
              />
            </div>
          </div>
        </div>
        {containsOtherSearch && (
          <div className={jbcStyles.detailsRow}>
            <div className={jbcStyles.detailsHeader}>その他の条件</div>
            <div className={jbcStyles.detailsBody}>
              <div className={jbcStyles.detailsBodyItems}>
                <Field
                  name="otherSearch"
                  component={SearchSelectBox}
                  options={otherSearchOptions}
                  width={400}
                  clearable
                  placeholder="条件選択"
                />
              </div>
            </div>
          </div>
        )}
        {containsBonusTarget && (
          <div className={jbcStyles.detailsRow}>
            <div className={jbcStyles.detailsHeader}>賞与支給対象者</div>
            <div className={jbcStyles.detailsBody}>
              <RadioField name="bonusTarget" label="" options={TARGET_EMPLOYEE_BONUS} />
            </div>
          </div>
        )}
        {containEmployeeEnrollmentStatus && (
          <MultiTagFilteringFieldRow
            header="在籍状況"
            fieldName="enrollmentStatuses"
            list={convertArrayIntoJbcStyle(EMPLOYMENT_STATUSES)}
          />
        )}
        {containAccountTypes && (
          <MultiTagFilteringFieldRow
            header={
              <div className={styles.multiLineHeader}>
                従業員情報の<br />
                有無
              </div>
            }
            fieldName="accountType"
            list={convertArrayIntoJbcStyle(ROLL_ACCOUNT_TYPE)}
          />
        )}
        {containSyncYeaTargets && (
          <div className={jbcStyles.detailsRow}>
            <div className={jbcStyles.detailsHeader}>連携状態</div>
            <div className={jbcStyles.detailsBody}>
              <div className={jbcStyles.detailsBodyItems}>
                <Field
                  name="syncYea"
                  component={SearchSelectBox}
                  options={syncYeaOptions}
                  width={400}
                  clearable
                  placeholder="連携状態選択"
                />
              </div>
            </div>
          </div>
        )}
        {containSaveStatus && (
          <div className={jbcStyles.detailsRow}>
            <div className={jbcStyles.detailsHeader}>保存状況</div>
            <div className={jbcStyles.detailsBody}>
              <div className={jbcStyles.detailsBodyItems}>
                <Field
                  name="saveStatus"
                  component={SearchSelectBox}
                  options={saveStatusOptions}
                  width={400}
                  clearable
                  placeholder="保存状況を選択"
                />
              </div>
            </div>
          </div>
        )}
        {containIsConfirmedPayroll && (
          <div className={jbcStyles.detailsRow}>
            <div className={jbcStyles.detailsHeader}>給与確定状況</div>
            <div className={jbcStyles.detailsBody}>
              <div className={jbcStyles.detailsBodyItems}>
                <Field
                  name="isConfirmedPayroll"
                  component={SearchSelectBox}
                  options={isConfirmedPayrollOptions}
                  width={400}
                  clearable
                  placeholder="給与確定状況を選択"
                />
              </div>
            </div>
          </div>
        )}
        {containIsConfirmed && (
          <div className={jbcStyles.detailsRow}>
            <div className={jbcStyles.detailsHeader}>確定状況</div>
            <div className={jbcStyles.detailsBody}>
              <div className={jbcStyles.detailsBodyItems}>
                <Field
                  name="isConfirmed"
                  component={SearchSelectBox}
                  options={isConfirmedOptions}
                  width={400}
                  clearable
                  placeholder="確定状況を選択"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.right}>
        <div>メモの色</div>
        <Field name="memoColors" component={MemoColors} list={convertArrayIntoJbcStyle(memoColors)} />
        <Field
          name="noMemo"
          component={({ input: inputProps }) => (
            <div>
              <label className="input-label">
                <input
                  type="checkbox"
                  className="m-checkbox-input"
                  {...inputProps}
                  checked={inputProps.value === '1'}
                  onChange={() => {
                    const newValues = inputProps.value === '1' ? '0' : '1';
                    inputProps.onChange(newValues);
                    inputProps.onBlur(newValues);
                  }}
                />
                <span className="m-checkbox-parts">メモ無し</span>
              </label>
            </div>
          )}
        />
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  offices: getSelectOptions(state, 'offices'),
  groups: getSelectOptions(state, 'flattenGroups'),
  positions: getSelectOptions(state, 'positions'),
  employmentTypes: getSelectOptions(state, 'connectedEmploymentTypes'),
  occupations: getSelectOptions(state, 'occupations'),
  payrollRuleGroups: getSelectOptions(state, 'payrollRuleGroups'),
  otherSearchOptions: getSelectOptions(state, 'otherSearchOptions'),
  syncYeaOptions: getSelectOptions(state, 'syncYeaOptions'),
  saveStatusOptions: getSelectOptions(state, 'saveStatusOptions'),
  isConfirmedPayrollOptions: getSelectOptions(state, 'isConfirmedPayrollOptions'),
  isConfirmedOptions: getSelectOptions(state, 'isConfirmedOptions'),
  memoColors: getSelectOptions(state, 'memoColors')
});
export default connect(mapStateToProps)(withRouter(AdditionalSearchFields));
