import React from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';

import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { isMoneyNumber10 } from 'src/utils/CustomValidators';
import { Delete } from 'jbc-front/components/icons';
import { generateWarekiYearMonth } from '../../../reducers/selectOptions';
import EditableTextField from './EditableTextField';
import styles from './RenderEmployeeDetail.scss';

const RenderEmployeeDetail = props => {
  const { fields, actionMode, offices, laborInsuranceYear, isLessThan2020 } = props;
  const yearMonthOptions = generateWarekiYearMonth(parseInt(laborInsuranceYear, 10));
  const targetTypeOptions = [{ label: '給与', value: 'EmployeePaymentMonth' }, { label: '賞与', value: 'ClientBonus' }];

  const tableHeader = (employeeDetail, detail) => {
    if (actionMode === 'SHOW') {
      if (employeeDetail.targetType === 'EmployeePaymentMonth') {
        return (
          <React.Fragment>
            <div>{employeeDetail.displayMonthlySummaryDate}</div>
            <div>{employeeDetail.manageName}</div>
          </React.Fragment>
        );
      } else if (employeeDetail.targetType === 'ClientBonus') {
        return (
          <React.Fragment>
            <div>{`賞与 ${employeeDetail.displayMonthlySummaryDate}`}</div>
            <div>{employeeDetail.manageName}</div>
          </React.Fragment>
        );
      }
    } else if (actionMode === 'EDIT') {
      if (_.isUndefined(employeeDetail.targetId) || employeeDetail.targetId === null) {
        return (
          <React.Fragment>
            <div className={styles.select}>
              <Field
                component={SearchSelectBox}
                name={`${detail}.targetType`}
                options={targetTypeOptions}
                width={60}
                placeholder={'種別'}
              />
              <Field
                component={SearchSelectBox}
                name={`${detail}.monthlySummaryDate`}
                options={yearMonthOptions}
                width={120}
                placeholder={'年月'}
              />
            </div>
            <div>
              <Field component={SearchSelectBox} name={`${detail}.officeId`} options={offices} placeholder={'事業所'} />
            </div>
          </React.Fragment>
        );
      }
      if (employeeDetail.targetType === 'EmployeePaymentMonth') {
        return (
          <React.Fragment>
            <div>{employeeDetail.displayMonthlySummaryDate}</div>
            <div>
              <Field component={SearchSelectBox} name={`${detail}.officeId`} options={offices} placeholder={'事業所'} />
            </div>
          </React.Fragment>
        );
      } else if (employeeDetail.targetType === 'ClientBonus') {
        return (
          <React.Fragment>
            <div>{`賞与 ${employeeDetail.displayMonthlySummaryDate}`}</div>
            <div>
              <Field component={SearchSelectBox} name={`${detail}.officeId`} options={offices} placeholder={'事業所'} />
            </div>
          </React.Fragment>
        );
      }
    }
    return null;
  };

  return fields.map((detail, index) => {
    const employeeDetail = fields.get(index);
    return (
      <tr key={employeeDetail.id}>
        <th>{tableHeader(employeeDetail, detail)}</th>
        <td>
          <Field
            name={`${detail}.accidentInsuranceEmployeeCommonPay`}
            showValue={employeeDetail.accidentInsuranceEmployeeCommonPay}
            showMode={actionMode}
            inputWidth={100}
            editProps={{ maxLength: 10 }}
            validate={[isMoneyNumber10]}
            component={EditableTextField}
          />
        </td>
        <td>
          <Field
            name={`${detail}.accidentInsuranceEmployeeTreatedPay`}
            showValue={employeeDetail.accidentInsuranceEmployeeTreatedPay}
            showMode={actionMode}
            inputWidth={70}
            editProps={{ maxLength: 10 }}
            validate={[isMoneyNumber10]}
            component={EditableTextField}
          />
        </td>
        <td>
          <Field
            name={`${detail}.accidentInsuranceEmployeeTemporaryPay`}
            showValue={employeeDetail.accidentInsuranceEmployeeTemporaryPay}
            showMode={actionMode}
            inputWidth={70}
            editProps={{ maxLength: 10 }}
            validate={[isMoneyNumber10]}
            component={EditableTextField}
          />
        </td>
        <td>
          <Field
            name={`${detail}.employmentInsuranceEmployeeCommonPay`}
            showValue={employeeDetail.employmentInsuranceEmployeeCommonPay}
            showMode={actionMode}
            inputWidth={70}
            editProps={{ maxLength: 10 }}
            validate={[isMoneyNumber10]}
            component={EditableTextField}
          />
        </td>
        <td>
          <Field
            name={`${detail}.employmentInsuranceEmployeeTreatedPay`}
            showValue={employeeDetail.employmentInsuranceEmployeeTreatedPay}
            showMode={actionMode}
            inputWidth={70}
            editProps={{ maxLength: 10 }}
            validate={[isMoneyNumber10]}
            component={EditableTextField}
          />
        </td>
        {isLessThan2020 && (
          <td>
            <Field
              name={`${detail}.employmentInsuranceEmployeeAgedPay`}
              showValue={employeeDetail.employmentInsuranceEmployeeAgedPay}
              showMode={actionMode}
              inputWidth={70}
              editProps={{ maxLength: 10 }}
              validate={[isMoneyNumber10]}
              component={EditableTextField}
            />
          </td>
        )}
        {actionMode === 'EDIT' && (
          <td>
            {employeeDetail.targetId === null || _.isUndefined(employeeDetail.targetId) ? (
              <div className={styles.delete}>
                <Delete
                  size={20}
                  onClick={e => {
                    e.preventDefault();
                    fields.remove(index);
                  }}
                />
              </div>
            ) : (
              <div className={styles.nodelete}>
                <Delete size={20} />
                <div className={styles.nodeleteText}>自動生成された行は削除できません</div>
              </div>
            )}
          </td>
        )}
      </tr>
    );
  });
};

export default RenderEmployeeDetail;
