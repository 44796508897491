import React from 'react';
import { Field } from 'redux-form';
import { FilteringTag } from 'jbc-front/components/SearchForm';

import { MY_NUMBER_STATUSES } from 'src/constants/Options';
import { convertArrayIntoJbcStyle } from 'src/utils/Utils';
import styles from 'jbc-front/components/SearchForm.scss';

export const SearchMyNumberStatusField = ({ input: inputProps, myNumberStatuses }) => (
  <div className={styles.searchEmploymentStatusField}>
    {myNumberStatuses.map(item => (
      <FilteringTag
        key={item.id}
        selected={item.id === inputProps.value}
        onClick={() => {
          const newValue = item.id;
          inputProps.onChange(newValue);
          inputProps.onBlur(newValue);
        }}
      >
        {item.name}
      </FilteringTag>
    ))}
  </div>
);

const FilterMyNumberStatusBtnList = () => (
  <Field
    name="myNumberStatus"
    component={SearchMyNumberStatusField}
    myNumberStatuses={convertArrayIntoJbcStyle(MY_NUMBER_STATUSES)}
  />
);

export default FilterMyNumberStatusBtnList;
