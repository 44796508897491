import React from 'react';
import { FormSection } from 'redux-form';
import _ from 'lodash';
import classNames from 'classnames';

import { TextField, BoxDouble, RadioField } from 'jbc-front/components/Form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import ActionButton from 'jbc-front/components/ActionButton';
import { Map, MapMarker } from 'jbc-front/components/icons';

import { INHABITANT_TAX_COLLECTION_METHODS } from 'src/constants/Options';

import { isRequiredNotNegativeMoneyNumber } from 'src/utils/CustomValidators';
import CitySearchModal from './CitySearchModal';
import styles from './InhabitantTaxSection.scss';

const ShowCity = ({ city }) => (
  <div className={styles.cityBodyWrapper}>
    <dl>
      <dt>市区町村コード</dt>
      <dd>{city && city.organizationCode}</dd>
    </dl>
    <dl>
      <dt>市区町村名</dt>
      <dd>{city && city.nameWithPrefecture}</dd>
    </dl>
  </div>
);

export default class InhabitantTaxSection extends React.Component {
  constructor(props) {
    super(props);

    this.openSearchPayeeCityModal = this.openSearchPayeeCityModal.bind(this);
    this.closeSearchPayeeCityModal = this.closeSearchPayeeCityModal.bind(this);
    this.openSearchSubmissCityModal = this.openSearchSubmissCityModal.bind(this);
    this.closeSearchSubmissCityModal = this.closeSearchSubmissCityModal.bind(this);

    this.state = {
      isSearchPayeeCityModalOpen: false,
      isSearchSubmissCityModalOpen: false
    };
  }

  openSearchPayeeCityModal() {
    if (this.state.isSearchPayeeCityModalOpen) return;
    this.setState({ ...this.state, isSearchPayeeCityModalOpen: true });
  }

  closeSearchPayeeCityModal() {
    if (!this.state.isSearchPayeeCityModalOpen) return;
    this.setState({ ...this.state, isSearchPayeeCityModalOpen: false });
  }

  openSearchSubmissCityModal() {
    if (this.state.isSearchSubmissCityModalOpen) return;
    this.setState({ ...this.state, isSearchSubmissCityModalOpen: true });
  }

  closeSearchSubmissCityModal() {
    if (!this.state.isSearchSubmissCityModalOpen) return;
    this.setState({ ...this.state, isSearchSubmissCityModalOpen: false });
  }

  render() {
    const {
      handleSelectPayeeCity,
      handleSelectSubmissCity,
      setCityCodeByName,
      collectionMethod,
      selectedPayeeCity,
      selectedSubmissCity
    } = this.props;

    return (
      <FormSection name="employeeInhabitantTax">
        <div className={styles.cityHead}>
          <div className={styles.cityTitle}>納付先市区町村名</div>
        </div>
        <div className={styles.cityBody}>
          <div className={styles.cityAction}>
            <ActionButton onClick={this.openSearchPayeeCityModal} icon={<Map size={16} />} className="u-mr5">
              市区町村を選択
            </ActionButton>
            {/* https://github.com/d-o-n-u-t-s/payrollservice/pull/11288 */}
            {/* TODO: sprint114マージ後に対応。ActionButtonに`icon={<ResidenceRegistration size={16} />`を設定する */}
            <ActionButton onClick={() => setCityCodeByName(handleSelectPayeeCity, 'residentCard')} className="u-mr5">
              住民票住所から取得
            </ActionButton>
            <ActionButton onClick={() => setCityCodeByName(handleSelectPayeeCity)} icon={<MapMarker size={16} />}>
              現住所から取得
            </ActionButton>
          </div>
          <ShowCity city={selectedPayeeCity} />
          <CitySearchModal
            isOpen={this.state.isSearchPayeeCityModalOpen}
            hideModal={this.closeSearchPayeeCityModal}
            onSelectCity={handleSelectPayeeCity}
            selectedCity={selectedPayeeCity}
          />
        </div>

        <div className={`${styles.cityHead} u-mt20`}>
          <div className={styles.cityTitle}>給与支払報告書提出先</div>
        </div>
        <div className={styles.cityBody}>
          <div className={styles.cityAction}>
            <ActionButton onClick={this.openSearchSubmissCityModal} icon={<Map size={16} />} className="u-mr5">
              市区町村を選択
            </ActionButton>
            <ActionButton onClick={() => setCityCodeByName(handleSelectSubmissCity, 'residentCard')} className="u-mr5">
              住民票住所から取得
            </ActionButton>
            <ActionButton onClick={() => setCityCodeByName(handleSelectSubmissCity)} icon={<MapMarker size={16} />}>
              現住所から取得
            </ActionButton>
          </div>
          <ShowCity city={selectedSubmissCity} />
          <CitySearchModal
            isOpen={this.state.isSearchSubmissCityModalOpen}
            hideModal={this.closeSearchSubmissCityModal}
            onSelectCity={handleSelectSubmissCity}
            selectedCity={selectedSubmissCity}
          />
        </div>
        <BoxDouble>
          <TextField name="reportSubmissionPrefectureId" type="hidden" />
          <TextField name="reportSubmissionCityId" type="hidden" />
        </BoxDouble>

        <RadioField label="住民税徴収方法" name="collectionMethod" options={INHABITANT_TAX_COLLECTION_METHODS} />
        {collectionMethod === 'special' && this.renderTaxAmountsMonth(this.props.taxAmounts)}
      </FormSection>
    );
  }

  // 住民税額の描画
  renderTaxAmountsMonth = taxAmounts => {
    const leftMonthAmounts = [6, 7, 8, 9, 10, 11].map(month => _.find(taxAmounts, ta => ta.month === month));
    const rightMonthAmounts = [12, 1, 2, 3, 4, 5].map(month => _.find(taxAmounts, ta => ta.month === month));

    return (
      <div>
        <div>住民税額設定</div>
        <div className={styles.amount}>
          <div className={styles.select}>年税額</div>
          <div className={classNames(styles.taxAmountNegativeMargin, styles.text)}>
            <TextField
              name={`annualTaxAmount`}
              noLabel
              label="年税額"
              type="text"
              yen
              maxLength="9"
              validate={[isRequiredNotNegativeMoneyNumber]}
              onChange={e => this.props.calcEachMonthTaxAmount(e.target.value)}
            />
          </div>
        </div>
        <div>
          <div className={styles.taxAmounts}>
            <LabelMapper name="taxAmounts" label="月別設定の" />
            <LabelMapper name="amount" label="金額" />
            <div className={styles.taxAmountsColumn}>{leftMonthAmounts.map(amount => this.renderAmount(amount))}</div>
            <div className={classNames(styles.taxAmountsColumn, 'u-ml40')}>
              {rightMonthAmounts.map(amount => this.renderAmount(amount))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  renderAmount = taxAmount => (
    <div key={taxAmount.month} className={styles.taxAmountsCell}>
      <div className={styles.taxAmountsLabel}>{taxAmount.month}月</div>
      <div className={classNames(styles.taxAmountsInput, styles.taxAmountNegativeMargin)}>
        <TextField
          key={`${taxAmount.month}`}
          noLabel
          label={`${taxAmount.month}月の金額`}
          name={`taxAmounts[${taxAmount.month - 1}].amount`}
          type="text"
          yen
          maxLength="9"
          validate={[isRequiredNotNegativeMoneyNumber]}
          onChange={e => this.props.calcAnnualTaxAmount(taxAmount.month, e.target.value)}
        />
      </div>
    </div>
  );
}
