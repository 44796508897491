import React from 'react';
import SortableTh from 'src/components/SortableTh';
import { redirectTo } from 'src/utils/Http';
import { REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_EDIT } from 'src/constants/EndpointUrls';
import { FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import styles from 'src/components/EmployeeTable.scss';
import EmployeeTableRow from '../containers/EmployeeTableRowContainer';

const EmployeeTable = ({ employees, laborInsuranceYear, isMainOfficeOnly }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list">
      <thead>
        <tr>
          <th>メモ</th>
          <SortableTh field="staffCode" globalClassName="m-table-list-staffcode" formName={FORM_NAME_EMPLOYEES_SEARCH}>
            スタッフコード
          </SortableTh>
          <SortableTh field="name" globalClassName={styles.name} formName={FORM_NAME_EMPLOYEES_SEARCH}>
            氏名
          </SortableTh>
          {!isMainOfficeOnly && (
            <SortableTh
              field="officeManageName"
              globalClassName={styles.officeManageName}
              formName={FORM_NAME_EMPLOYEES_SEARCH}
            >
              事業所
            </SortableTh>
          )}

          <th>労災区分</th>
          <th>雇用保険区分</th>
        </tr>
      </thead>
      <tbody>
        {(!employees || employees.length === 0) && (
          <tr>
            <td colSpan={7}>データがありません。</td>
          </tr>
        )}

        {employees.map(employee => (
          <EmployeeTableRow
            key={employee.id}
            onClick={() => {
              const url = REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_EDIT.replace(':year', laborInsuranceYear).replace(
                ':employee_id',
                employee.id
              );
              redirectTo(url);
            }}
            {...{ employee }}
          />
        ))}
      </tbody>
    </table>
  </div>
);

export default EmployeeTable;
