import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { REPORT_CSV_DOWNLOAD_MODAL_FORM } from 'src/constants/FormNames';
import { checkNotificationOfBaseAmountRevisionsSearchFormHasConditions } from 'src/reducers/searchForm';
import CsvDownloadModal from './CsvDownloadModal';

const validateCsvDownloadModalForm = values => {
  const errors = {};

  if (values.serialNumber && !/^\d{3}$/.test(values.serialNumber)) {
    errors.serialNumber = 'は001〜999までの数字を入力してください。';
  }
  return errors;
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => {
    dispatch(ownProps.downloadCsv(data, ownProps.searchKey));
    dispatch(ownProps.hideModal);
  }
});

const mapStateToProps = state => {
  const ignoreQueries = [
    'order',
    'page',
    'per_page',
    'sort',
    'sort_type',
    'filter_type',
    'applicable_office',
    'revision_year'
  ];
  const hasConditions = checkNotificationOfBaseAmountRevisionsSearchFormHasConditions(state, ignoreQueries);

  return {
    initialValues: {
      paymentFor: 'pension_office',
      csvTarget: hasConditions ? 'searched_employee' : 'all_employee'
    }
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: REPORT_CSV_DOWNLOAD_MODAL_FORM,
    validate: validateCsvDownloadModalForm
  })
)(CsvDownloadModal);
