import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import compose from 'lodash/fp/compose';

import { getList } from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  getDownloadParams,
  getPdfPreviewUrl,
  getCheckedEmployees
} from 'src/reducers/reports/reportWageLedger';

import {
  FORM_NAME_WAGE_LEDGER_SEARCH,
  takeWageLedgersSearchQueriesFromLocation,
  takeWageLedgersResetFormQueries
} from 'src/reducers/searchForm';

import ReportWageLedgerList from './components/ReportWageLedgerList';

const mapStateToProps = state => ({
  employees: getList(REDUCER_NAME, state),
  formValues: getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(state),
  initialValues: {
    bonus: true,
    yearSelected: true,
    ...getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(state),
    ...takeWageLedgersSearchQueriesFromLocation(state.router.location)
  },
  location: state.router.location,
  resetValues: takeWageLedgersResetFormQueries(state, true),
  downloadParams: getDownloadParams(state),
  pdfPreviewUrl: getPdfPreviewUrl(state),
  checkedEmployees: getCheckedEmployees(state)
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME_WAGE_LEDGER_SEARCH,
    enableReinitialize: true
  })
)(ReportWageLedgerList);
