import React from 'react';
import moment from 'moment';
import get from 'lodash/get';
import take from 'lodash/take';
import ListGroup from 'jbc-front/components/ListGroup';
import { recordDisplay } from 'src/utils/Utils';
import styles from './SystemAnnounces.scss';

const MAX_DISPLAY = 5;
const NEW_LABEL_LIMIT = 7;

export const showDateNewLine = date => date.replace(/年/, '年\n');

const SystemAnnounces = ({ systemAnnounces }) => (
  <div className="u-mt30">
    <ListGroup>
      <ListGroup.Title>お知らせ</ListGroup.Title>
      {get(systemAnnounces, 'length') > 0 ? (
        take(systemAnnounces, MAX_DISPLAY).map(systemAnnounce => {
          const today = moment();
          const publishAt = moment(systemAnnounce.publishAt);
          const dateDiff = today.diff(publishAt, 'days');
          return (
            <ListGroup.Item
              className={styles.list}
              key={systemAnnounce.id}
              {...(systemAnnounce.systemAnnounceType === 'link'
                ? { href: systemAnnounce.link, target: '_blank', as: 'a' }
                : { href: `/system_announces/${systemAnnounce.id}`, as: 'a' })}
            >
              {dateDiff <= NEW_LABEL_LIMIT && <span className={styles.new} />}
              <span className={styles.date}>{showDateNewLine(recordDisplay.dateOrTime(systemAnnounce.publishAt))}</span>
              <div className={dateDiff <= NEW_LABEL_LIMIT ? styles.newAnnounceTitle : styles.title}>
                {systemAnnounce.title}
              </div>
              <ListGroup.Icon />
            </ListGroup.Item>
          );
        })
      ) : (
        <ListGroup.Item className={styles.empty}>お知らせはまだありません</ListGroup.Item>
      )}
    </ListGroup>
    {get(systemAnnounces, 'length') > MAX_DISPLAY && (
      <div className={styles.more}>
        <a href="/system_announces" className={styles.more}>
          すべて見る
        </a>
      </div>
    )}
  </div>
);

export default SystemAnnounces;
