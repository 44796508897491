import React from 'react';

import styles from './PayrollTableData.scss';

const Row = props => {
  const { label, value } = props;
  return (
    <tr>
      <th>{label}</th>
      <td>
        <div className={styles.def}>
          <input type="text" value={value} disabled />
        </div>
      </td>
    </tr>
  );
};

export default Row;
