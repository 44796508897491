import React, { useEffect } from 'react';
import _ from 'lodash';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import AllowanceDeductionForm from 'src/components/AllowanceDeductionForm';

const ShowAllowanceDeductionForm = props => {
  const { formValues, fetchAllowancesDeductionsJobStatus, resetPolling, formChange } = props;

  useEffect(
    () => {
      const uniqueString = [formValues.clientBonusId, formValues.formatId].join('_');
      formChange('uniqueString', uniqueString);
    },
    [formValues.clientBonusId, formValues.formatId]
  );

  useEffect(
    () => {
      const { clientBonusId, formatId } = formValues;
      if (_.isUndefined(clientBonusId) || _.isUndefined(formatId)) {
        return;
      }
      resetPolling();
      fetchAllowancesDeductionsJobStatus(formValues.uniqueString);
    },
    [formValues.uniqueString]
  );

  const mainSelectField = () => (
    <ExtensionSelectField
      name="clientBonusId"
      options={props.clientBonuses.filter(bonus => bonus.isConfirmed)}
      required
      label="賞与"
    />
  );
  return <AllowanceDeductionForm mainSelectField={mainSelectField} {...props} />;
};

export default ShowAllowanceDeductionForm;
