import React from 'react';
import { change as formChange, FormSection } from 'redux-form';
import { forEach, isEmpty } from 'lodash';

import { WAGE_LEDGER } from 'src/constants/EndpointUrls';
import { WAGE_LEDGER_EXPORT_ITEM_FORM } from 'src/constants/FormNames';
import Button from 'jbc-front/components/Button';
import { Section } from 'jbc-front/components/Form';
import SelectLeftRightItems from 'src/features/ClientCsvFormats/components/SelectLeftRightItems';
import styles from './ExportItemForm.scss';

const addUuidToItems = wageLedgerExportItems => {
  const result = {};
  let offset = 1;
  forEach(wageLedgerExportItems, (items, label) => {
    result[label] = items.map((item, itemIndex) => ({ ...item, uuid: itemIndex + offset }));
    offset += items.length;
  });

  return result;
};

const findUuidByAttrs = (item, hashItems) => {
  let uuid; // undefined

  forEach(hashItems, items => {
    const found = items.find(i => i.group === item.group && i.label === item.label);
    if (found) {
      uuid = found.uuid;
      return false;
    }
    return true;
  });

  return uuid;
};

const mapUuidToItems = (itemsWithUuid, anonymousItems) => {
  if (isEmpty(itemsWithUuid)) return [];

  const itemsWithoutUuid = anonymousItems
    .filter(item => !findUuidByAttrs(item, itemsWithUuid))
    .toSorted((a, b) => a.name.localeCompare(b.name));

  return anonymousItems.map(item => {
    const uuid = findUuidByAttrs(item, itemsWithUuid);
    if (uuid) {
      return { ...item, uuid };
    }
    // itemsWithUuidのUUIDは必ず正なので、itemsWithUuidにない項目には、負のUUIDを振っています。
    return { ...item, uuid: -itemsWithoutUuid.indexOf(item) - 1 };
  });
};

class WageLedgerExportItemForm extends React.Component {
  constructor(props) {
    super(props);

    const { currentExportItems } = this.props;
    this.state = {
      selectingLeftItems: [],
      rightItems: currentExportItems,
      selectingRightItems: []
    };
  }

  setStateData = newData => {
    this.dispatchFormValue(newData);
    this.setState(prevState => ({ ...prevState, ...newData }));
  };

  addPosition = items => [...items.map((item, index) => ({ ...item, position: index }))];

  dispatchFormValue = data => {
    if (Object.keys(data).includes('rightItems')) {
      this.props.dispatch(formChange(WAGE_LEDGER_EXPORT_ITEM_FORM, 'exportItems', this.addPosition(data.rightItems)));
    }
  };

  render() {
    const { wageLedgerExportItems, submitting, submit, pristine, handleSubmit } = this.props;
    const { selectingLeftItems, selectingRightItems } = this.state;

    let { rightItems } = this.state;
    const exportItemWithUuid = addUuidToItems(wageLedgerExportItems);
    rightItems = mapUuidToItems(exportItemWithUuid, rightItems);
    const leftItems = exportItemWithUuid;

    return (
      <form onSubmit={handleSubmit}>
        <Section>
          <FormSection name="exportItems">
            <SelectLeftRightItems
              {...{ leftItems, selectingLeftItems, rightItems, selectingRightItems }}
              changeParentState={this.setStateData}
              showNote={false}
            />
          </FormSection>

          <div className={styles.actionButtons}>
            <span>
              <Button as="a" href={WAGE_LEDGER}>
                キャンセル
              </Button>
            </span>
            <span>
              <Button primary onClick={submit} disabled={submitting || pristine}>
                保存
              </Button>
            </span>
          </div>
        </Section>
      </form>
    );
  }
}

export default WageLedgerExportItemForm;
