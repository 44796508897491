import React from 'react';
import { connect } from 'react-redux';
import { Field, blur, touch } from 'redux-form';
import _ from 'lodash';

import { REPORT_LABOR_INSURANCE_DECLARATION_FORM } from 'src/constants/FormNames';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { emptyIntegerFormat } from 'src/utils/Completion';
import { numberWithComma } from 'src/utils/Utils';
import EditableTextField from './EditableTextField';
import Hatching from './DeclarationFormInsuranceFeeHatching';
import Hatching2021 from './DeclarationFormInsuranceHatching2021';
import styles from './DeclarationFormConfirmedInsuranceFee.scss';

class DeclarationFormEstimateInsuranceFee extends React.Component {
  constructor(props) {
    super(props);
    const { declarationForm } = props;
    this.state = { ...declarationForm };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  calculation(params) {
    // 労災保険額
    const estimateInsuranceFeeAmountForAccidentInsurance = _.toInteger(
      params.estimateInsuranceFeeBaseForAccidentInsurance * params.estimateInsuranceFeeRateForAccidentInsurance,
      10
    );

    // 保険料算定対象者分算定基礎額(2020年より前の時に表示される雇用保険の保険料算定基礎額の見込額)
    const estimateInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance =
      _.toInteger(params.estimateInsuranceFeeBaseForAppliedEmploymentInsurance, 10) -
      _.toInteger(params.estimateInsuranceFeeBaseForAgedEmploymentInsurance, 10);

    // 保険料算定対象者分保険料額(2020年より前の時に表示される雇用保険の概算保険料額)
    const estimateInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance = _.toInteger(
      estimateInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance *
        params.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance,
      10
    );

    // 雇用保険分 保険料（2020年以降の時に表示される雇用保険の概算保険料額)
    const is2021 = params.laborInsuranceYear.slice(0, 4) === '2021';
    const estimateInsuranceFeeAmountAfter2020 = is2021
      ? params.sumEstimateEmploymentInsurance
      : _.toInteger(
          params.estimateInsuranceFeeBaseForAppliedEmploymentInsurance *
            params.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance,
          10
        );

    // 労働保険料
    const estimateInsuranceFeeAmountForLaborInsurance =
      estimateInsuranceFeeAmountForAccidentInsurance +
      (is2021
        ? estimateInsuranceFeeAmountAfter2020
        : estimateInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance);

    const calcValues = {
      estimateInsuranceFeeAmountForAccidentInsurance,
      estimateInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance,
      estimateInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance,
      estimateInsuranceFeeAmountAfter2020,
      estimateInsuranceFeeAmountForLaborInsurance
    };

    this.setState({
      ...params,
      ...calcValues
    });

    return { ...params, ...calcValues };
  }

  // 労災保険の算定基礎額変更イベント
  onChangeEstimateInsuranceFeeBaseForAccidentInsurance(value) {
    const params = {
      ...this.state,
      estimateInsuranceFeeBaseForAccidentInsurance: value
    };
    this.props.allCalculations(params);
  }

  // 高年齢労働者算出基礎変更イベント
  onChangeEstimateInsuranceFeeBaseForAppliedEmploymentInsurance(value) {
    const params = {
      ...this.state,
      estimateInsuranceFeeBaseForAppliedEmploymentInsurance: value
    };
    this.props.allCalculations(params);
  }

  // 高年齢労働者算出基礎変更イベント
  onChangeEstimateInsuranceFeeBaseForAgedEmploymentInsurance(value) {
    const params = {
      ...this.state,
      estimateInsuranceFeeBaseForAgedEmploymentInsurance: value
    };
    this.props.allCalculations(params);
  }

  render() {
    const { dispatch, actionMode, declarationForm, isLessThan2020, is2021 } = this.props;
    return (
      <div style={{ position: 'relative' }}>
        <p className="u-mb10">概算・増加概算 保険料算定内訳</p>
        <Hatching
          isDisplay={
            actionMode === 'SHOW' &&
            declarationForm.estimateInsuranceFeeBaseForAccidentInsurance ===
              declarationForm.estimateInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance
          }
          isLessThan2020={isLessThan2020}
        />
        {is2021 && <Hatching2021 isDisplay={actionMode === 'EDIT'} />}
        <div className="l-overflow-scroll">
          <table className={styles.table}>
            <colgroup>
              <col width={60} />
              <col width={180} />
              <col width={240} />
              <col width={240} />
              <col width={240} />
            </colgroup>
            <thead>
              <tr>
                <th colSpan="2">区分</th>
                <th>保険料算定基礎額の見込額</th>
                <th>保険料率</th>
                <th>概算保険料額</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colSpan="2">労働保険料</th>
                <td />
                <td>{!is2021 && <span>1000分の{declarationForm.estimateInsuranceFeeRateForLaborInsurance}</span>}</td>
                <td>
                  {numberWithComma(this.state.estimateInsuranceFeeAmountForLaborInsurance)}{' '}
                  <span className={styles.currency}>円</span>
                </td>
              </tr>
              <tr>
                <th colSpan="2">労災保険分</th>
                <td>
                  <Field
                    name="estimateInsuranceFeeBaseForAccidentInsurance"
                    showValue={declarationForm.estimateInsuranceFeeBaseForAccidentInsurance}
                    showMode={actionMode}
                    inputWidth={70}
                    validate={[isMoneyNumber]}
                    component={EditableTextField}
                    onChange={e => {
                      this.onChangeEstimateInsuranceFeeBaseForAccidentInsurance(e.target.value);
                    }}
                    onBlur={(e, newValue, previousValue) => {
                      e.preventDefault();
                      dispatch(
                        blur(
                          `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                          `estimateInsuranceFeeBaseForAccidentInsurance`,
                          emptyIntegerFormat(previousValue)
                        )
                      );
                      dispatch(
                        touch(
                          `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                          `estimateInsuranceFeeBaseForAccidentInsurance`
                        )
                      );
                      this.onChangeEstimateInsuranceFeeBaseForAccidentInsurance(e.target.value);
                    }}
                  />
                  <span className={styles.currency}>千円</span>
                </td>
                <td>1000分の{declarationForm.estimateInsuranceFeeRateForAccidentInsurance}</td>
                <td>
                  {numberWithComma(this.state.estimateInsuranceFeeAmountForAccidentInsurance)}{' '}
                  <span className={styles.currency}>円</span>
                </td>
              </tr>
              {isLessThan2020 ? (
                <React.Fragment>
                  <tr>
                    <th rowSpan="3" className={styles.vertical}>
                      雇用保険分
                    </th>
                    <th>雇用保険法適用者分</th>
                    <td>
                      <Field
                        name="estimateInsuranceFeeBaseForAppliedEmploymentInsurance"
                        showValue={declarationForm.estimateInsuranceFeeBaseForAppliedEmploymentInsurance}
                        showMode={actionMode}
                        inputWidth={70}
                        validate={[isMoneyNumber]}
                        component={EditableTextField}
                        onChange={e => {
                          this.onChangeEstimateInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                        }}
                        onBlur={(e, newValue, previousValue) => {
                          e.preventDefault();
                          dispatch(
                            blur(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `estimateInsuranceFeeBaseForAppliedEmploymentInsurance`,
                              emptyIntegerFormat(previousValue)
                            )
                          );
                          dispatch(
                            touch(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `estimateInsuranceFeeBaseForAppliedEmploymentInsurance`
                            )
                          );
                          this.onChangeEstimateInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                        }}
                      />
                      <span className={styles.currency}>千円</span>
                    </td>
                    <td colSpan="2">-</td>
                  </tr>
                  <tr>
                    <th>高年齢労働者分</th>
                    <td>
                      <Field
                        name="estimateInsuranceFeeBaseForAgedEmploymentInsurance"
                        showValue={declarationForm.estimateInsuranceFeeBaseForAgedEmploymentInsurance}
                        showMode={actionMode}
                        inputWidth={70}
                        validate={[isMoneyNumber]}
                        component={EditableTextField}
                        onChange={e => {
                          this.onChangeEstimateInsuranceFeeBaseForAgedEmploymentInsurance(e.target.value);
                        }}
                        onBlur={(e, newValue, previousValue) => {
                          e.preventDefault();
                          dispatch(
                            blur(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `estimateInsuranceFeeBaseForAgedEmploymentInsurance`,
                              emptyIntegerFormat(previousValue)
                            )
                          );
                          dispatch(
                            touch(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `estimateInsuranceFeeBaseForAgedEmploymentInsurance`
                            )
                          );
                          this.onChangeEstimateInsuranceFeeBaseForAgedEmploymentInsurance(e.target.value);
                        }}
                      />
                      <span className={styles.currency}>千円</span>
                    </td>
                    <td colSpan="2">-</td>
                  </tr>
                  <tr>
                    <th>保険料算定対象者分</th>
                    <td>
                      {numberWithComma(this.state.estimateInsuranceFeeBaseForCalculationTargetOfEmploymentInsurance)}{' '}
                      <span className={styles.currency}>千円</span>
                    </td>
                    <td>1000分の{declarationForm.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance}</td>
                    <td>
                      {numberWithComma(this.state.estimateInsuranceFeeAmountForCalculationTargetOfEmploymentInsurance)}{' '}
                      <span className={styles.currency}>円</span>
                    </td>
                  </tr>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <tr>
                    <th colSpan="2" className={styles.vertical}>
                      雇用保険分
                    </th>
                    <td>
                      <Field
                        name="estimateInsuranceFeeBaseForAppliedEmploymentInsurance"
                        showValue={declarationForm.estimateInsuranceFeeBaseForAppliedEmploymentInsurance}
                        showMode={is2021 ? 'SHOW' : actionMode}
                        inputWidth={70}
                        validate={[isMoneyNumber]}
                        component={EditableTextField}
                        onChange={e => {
                          this.onChangeEstimateInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                        }}
                        onBlur={(e, newValue, previousValue) => {
                          e.preventDefault();
                          dispatch(
                            blur(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `estimateInsuranceFeeBaseForAppliedEmploymentInsurance`,
                              emptyIntegerFormat(previousValue)
                            )
                          );
                          dispatch(
                            touch(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                              `estimateInsuranceFeeBaseForAppliedEmploymentInsurance`
                            )
                          );
                          this.onChangeEstimateInsuranceFeeBaseForAppliedEmploymentInsurance(e.target.value);
                        }}
                      />
                      <span className={styles.currency}>千円</span>
                    </td>
                    <td>
                      {!is2021 && (
                        <span>
                          1000分の{declarationForm.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance}
                        </span>
                      )}
                    </td>
                    <td>
                      {numberWithComma(this.state.estimateInsuranceFeeAmountAfter2020)}{' '}
                      <span className={styles.currency}>円</span>
                    </td>
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default connect()(DeclarationFormEstimateInsuranceFee);
