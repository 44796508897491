import React from 'react';
import { connect } from 'react-redux';

import { EMPLOYEE_SHOW, EMPLOYEE_BONUS_EDIT } from 'src/constants/EndpointUrls';
import { Locked } from 'src/icons';
import { SearchReactSelect } from 'src/components';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';
import { setGlobalWarnings } from 'src/reducers/global';
import { formatDate } from 'src/utils/Date';
import MemoList from 'src/features/Memos';
import styles from './ActionSection.scss';

const buildTimeString = employeeBonus => {
  let str = '';
  if (employeeBonus.applyForTimeFrom) {
    str += `対象期間 ${formatDate(employeeBonus.applyForTimeFrom)} 〜 ${formatDate(employeeBonus.applyForTimeTo)}`;
  }
  return (
    <React.Fragment>
      <div>{str}</div>
      <div className={styles.paymentDate}>支給日 {formatDate(employeeBonus.payOn)}</div>
    </React.Fragment>
  );
};

const ActionSection = props => {
  const { allEmployeeBonus, selectingEmployeeBonus } = props;
  const employeeDetailUrl = concatParamsToUrl(EMPLOYEE_SHOW, { id: selectingEmployeeBonus.employeeId });
  const changeSelectingEmployeeBonus = selected => {
    if (selectingEmployeeBonus.id === selected.value) return;

    const buildUrl = EMPLOYEE_BONUS_EDIT + window.location.search;
    const employeeBonusShowUrl = concatParamsToUrl(buildUrl, { id: selected.value });
    redirectTo(employeeBonusShowUrl);
  };

  return (
    <div>
      <div className={styles.name}>
        <div className={styles.memo}>
          <MemoList employeeId={selectingEmployeeBonus.employeeId} size={44} />
        </div>
        <div className={styles.nameFull}>
          <a href={employeeDetailUrl}>{selectingEmployeeBonus.employeeDisplayName}</a>
        </div>
      </div>

      <div className={styles.month}>
        <div className={styles.monthFilters}>
          {selectingEmployeeBonus.isConfirmed && (
            <span>
              <Locked />
            </span>
          )}
          <span>
            <SearchReactSelect
              value={selectingEmployeeBonus.id}
              options={allEmployeeBonus}
              onChange={changeSelectingEmployeeBonus}
              width={350}
              size="large"
              cache={false}
            />
          </span>
        </div>
        <div className={styles.monthCaption}>{buildTimeString(selectingEmployeeBonus)}</div>
      </div>
    </div>
  );
};

export default connect(null, dispatch => ({
  displayWarnings: msg => dispatch(setGlobalWarnings(msg))
}))(ActionSection);
