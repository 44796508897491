import React, { useState } from 'react';
import { Hamburger } from 'jbc-front/components/icons';
import { CheckboxFieldWithoutForm } from 'src/components';
import { FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import SortableTh from 'src/components/SortableTh';
import EmployeeTableRow from '../containers/EmployeeTableRowContainer';
import TargetEmployeeCoordinationMethod from '../containers/TargetEmployeeCoordinationMethodContainer';
import styles from './EmployeeTable.scss';
import ChangedContext from '../ChangedContext';

const EmployeeTable = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { employees } = props;
  return (
    <div className="l-overflow-scroll">
      <table className="m-table-list">
        <thead>
          <tr>
            <th width={40}>
              <div className={styles.wrap}>
                <ChangedContext.Consumer>
                  {({ change, isSelected }) => {
                    const checked = employees.every(employee => employee.yeaNonTarget || isSelected(employee));
                    return (
                      <div
                        className={`theadCheckbox${checked ? 'On' : 'Off'}`}
                        onClick={() =>
                          change(
                            Object.assign(
                              {},
                              ...employees
                                .filter(employee => !employee.yeaNonTarget)
                                .map(employee => ({ [employee.id]: !checked }))
                            )
                          )
                        }
                        role="checkbox"
                        aria-checked={checked}
                        tabIndex={-1}
                      >
                        <CheckboxFieldWithoutForm checked={checked} readOnly />
                      </div>
                    );
                  }}
                </ChangedContext.Consumer>
                <div className={styles.button}>
                  <Hamburger
                    size={12}
                    className={`${styles.menu} ignore-react-onclickoutside`}
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                  />
                  {isMenuOpen && <TargetEmployeeCoordinationMethod hide={() => setIsMenuOpen(false)} />}
                </div>
              </div>
            </th>
            <th>メモ</th>
            <SortableTh
              field="staffCode"
              globalClassName="m-table-list-staffcode"
              formName={FORM_NAME_EMPLOYEES_SEARCH}
            >
              スタッフコード
            </SortableTh>
            <SortableTh field="name" globalClassName={styles.name} formName={FORM_NAME_EMPLOYEES_SEARCH}>
              氏名
            </SortableTh>
            <SortableTh
              field="employmentTypeId"
              globalClassName={styles.employmentType}
              formName={FORM_NAME_EMPLOYEES_SEARCH}
            >
              雇用形態
            </SortableTh>
            <SortableTh field="groupId" globalClassName={styles.groupPath} formName={FORM_NAME_EMPLOYEES_SEARCH}>
              グループ
            </SortableTh>
            <SortableTh field="positionId" globalClassName={styles.positionName} formName={FORM_NAME_EMPLOYEES_SEARCH}>
              役職
            </SortableTh>
            <SortableTh field="joinedOn" globalClassName={styles.joinedOn} formName={FORM_NAME_EMPLOYEES_SEARCH}>
              入社日
            </SortableTh>
          </tr>
        </thead>
        <tbody>
          {(!employees || employees.length === 0) && (
            <tr>
              <td colSpan={8}>データがありません。</td>
            </tr>
          )}

          {employees.map(employee => <EmployeeTableRow key={employee.id} {...{ employee }} />)}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;
