import { connect } from 'react-redux';
import { unconfirmAll } from '../../../reducers/employees/employeePays';
import UnconfirmAllEmployeePayModal from '../components/UnconfirmAllEmployeePayModal';

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => {
    dispatch(unconfirmAll(ownProps.payrollRulesGroups, ownProps.yearMonth));
    if (typeof ownProps.hideModal === 'function') {
      ownProps.hideModal();
    }
  }
});

export default connect(null, mapDispatchToProps)(UnconfirmAllEmployeePayModal);
