import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import Button from 'jbc-front/components/Button';

import { reduxForm, getFormValues } from 'redux-form';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { FileField } from 'jbc-front/components/Form';
import { Download, Upload } from 'jbc-front/components/icons';
import { ErrorsSection } from 'src/components';
import { TEMPLATE_EMPLOYEES_CSV } from 'src/constants/EndpointUrls';
import isUseOtherApp from 'src/utils/JbcId';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import BusinessNameConfirmationModal from 'src/features/Employees/components/BusinessNameConfirmationModal';

// Reducer
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, importEmployeeCsv } from 'src/reducers/employees/employees';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';

import { ENCODING_OPTIONS } from 'src/constants/Options';
import styles from '../styles/FileImport.scss';

const FormContainer = reduxForm({
  form: EMPLOYEE_FORM,
  enableReinitialize: true
})(({ submitting, pristine, handleSubmit, errors, selectedEncoding }) => {
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>アップロード手順</div>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <ErrorsSection errors={errors} />

          <dl className={styles.label}>
            <dt className={styles.number}>1</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>ファイルの出力形式を選択してダウンロードしたCSVファイルをご利用ください。</p>
              <div className="u-mt30">
                <DownloadCsvEncodingRadioButton
                  name={'encoding'}
                  label={null}
                  options={ENCODING_OPTIONS}
                  required={false}
                />
              </div>
              <Button
                widthWide
                onClick={() =>
                  redirectTo(`${TEMPLATE_EMPLOYEES_CSV}?${serializeHttpGetParams({ encoding: selectedEncoding })}`)
                }
                icon={<Download size={16} />}
              >
                サンプルデータをダウンロード
              </Button>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>2</dt>
            <dd className={styles.detail}>
              下の説明を参考にしながら、ダウンロードしたCSVファイルに、登録したい従業員の情報を入れてください。
              <div className={styles.warning}>
                <span className={styles.warningTitle}>【注意】</span>
                <ul className={styles.warningList}>
                  <li>
                    {isUseOtherApp && <span>①</span>}
                    以下の項目は<span className={styles.cautionText}>入力必須</span>になります。
                    <ul className={styles.requireList}>
                      <li>・姓</li>
                      <li>・名</li>
                      <li>・入社日</li>
                      <li>・（基本給、</li>
                      <li>　　今年の年間収入見積額、</li>
                      <li>　　今年の年間所得見積額、</li>
                      <li>　　給与規定グループで設定した支給・控除項目、</li>
                      <li>　　給与規定グループで設定した単価を設定する場合）</li>
                      <li>　　・給与規定グループ</li>
                      <li>・（通勤手当の情報を登録する場合）</li>
                      <li>　　・通勤手段</li>
                      <li>　　・選択した通勤手段において設定可能な項目すべて</li>
                      <li>・（被扶養者の情報を登録する場合）</li>
                      <li>　　・被扶養者 続柄</li>
                      <li>　　・被扶養者 姓</li>
                      <li>　　・被扶養者 名</li>
                      <li>　　・被扶養者 生年月日</li>
                      <li>・（支給設定（給与）銀行振込1 金額を登録する場合）</li>
                      <li>　　・支給設定（給与）銀行振込1 振込先</li>
                      <li>　　・支給設定（給与）銀行振込1 振込元</li>
                      <li>・（支給設定（賞与）銀行振込1 金額を登録する場合）</li>
                      <li>　　・支給設定（賞与）銀行振込1 振込先</li>
                      <li>　　・支給設定（賞与）銀行振込1 振込元</li>
                      <li>・（休職期間を登録する場合）</li>
                      <li>　　・休職開始日</li>
                      <li>　　・休職終了日</li>
                      <li>　　・休職理由</li>
                      <li>　　・固定的賃金を0とする</li>
                    </ul>
                  </li>
                  {isUseOtherApp && (
                    <li>
                      ②登録するユーザが、ジョブカン他サービスにも登録されている場合、
                      <span className={styles.cautionText}>
                        入力された姓名・スタッフコード・メールアドレスはご利用中の
                        ジョブカン他サービスにも自動反映されます。
                        <br />
                        また、ビジネスネーム姓名に入力された場合、ビジネスネーム姓名が他サービスの姓名に自動反映されます。
                        <br />
                        （但しジョブカン労務HR上でビジネスネームが設定済みの従業員に関しては、
                        ビジネスネーム姓名に対して自動反映されます）
                      </span>
                    </li>
                  )}
                </ul>
              </div>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>3</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
              <FileField name="employee_csv" className={styles.input} />
            </dd>
          </dl>
          <Button primary icon={<Upload size={16} />} widthWide onClick={() => setIsOpenConfirmationModal(true)}>
            アップロード
          </Button>
        </form>
      </div>
      {isOpenConfirmationModal && (
        <BusinessNameConfirmationModal
          hideModal={() => setIsOpenConfirmationModal(false)}
          isOpen
          isCsv
          {...{ handleSubmit, submitting, pristine }}
        />
      )}
    </div>
  );
});

const mapStateToProps = state => {
  const initialValues = { encoding: 'UTF-8' };
  return {
    errors: getItemErrors(REDUCER_NAME, state),
    selectedEncoding: _.get(getFormValues(EMPLOYEE_FORM)(state), 'encoding'),
    initialValues
  };
};

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(importEmployeeCsv(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(FormContainer);
