import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { DATE_FORMAT } from 'src/constants/Generals';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { MEMO_COLOR_OPTIONS, deleteMemo } from 'src/reducers/memos/memos';
import { EditSquare, DeleteSquare } from 'jbc-front/components/icons';
import MemoDataModal from './MemoDataModal';
import DeleteMemoModal from './DeleteMemoModal';
import style from './Memos.scss';

class MemoRow extends React.Component {
  state = {
    isEditMemoModalOpen: false,
    isDeleteMemoModalOpen: false
  };

  openEditMemoModal = e => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.isEditMemoModalOpen) return;
    this.props.changeMemoModal();
    this.setState({ ...this.state, isEditMemoModalOpen: true });
  };

  closeEditMemoModal = () => {
    if (!this.state.isEditMemoModalOpen) return;
    this.props.changeMemoModal();
    this.setState({ ...this.state, isEditMemoModalOpen: false });
  };

  openDeleteMemoModal = e => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.isDeleteMemoModalOpen) return;
    this.props.changeMemoModal();
    this.setState({ ...this.state, isDeleteMemoModalOpen: true });
  };

  closeDeleteMemoModal = () => {
    if (!this.state.isDeleteMemoModalOpen) return;
    this.props.changeMemoModal();
    this.setState({ ...this.state, isDeleteMemoModalOpen: false });
  };

  render() {
    const { memo, memoColors } = this.props;
    const memoColor = memoColors.find(color => color.value === memo.memoColorId.toString());

    return (
      <div
        className={style.item}
        onClick={e => {
          e.stopPropagation();
        }}
        role="presentation"
      >
        <div className={style.action} style={{ backgroundColor: memoColor.label }}>
          <span className={style.icon}>
            <EditSquare onClick={this.openEditMemoModal} size={18} />
          </span>
          <span className={style.icon}>
            <DeleteSquare onClick={this.openDeleteMemoModal} size={18} />
          </span>
        </div>
        <div className={style.detail} style={{ backgroundColor: memoColor.subColor }}>
          <div className={style.note}>{memo.note}</div>
          <div className={style.date}>
            <span>アラート予定日: {memo.alertDate ? moment(memo.alertDate).format(DATE_FORMAT) : '未設定'}</span>
          </div>
          <div className={style.date}>
            <span>最終更新日: {moment(memo.updatedAt).format(DATE_FORMAT)}</span>
          </div>
        </div>
        <MemoDataModal
          isOpen={this.state.isEditMemoModalOpen}
          hideModal={this.closeEditMemoModal}
          initialValues={memo}
        />
        <DeleteMemoModal
          isOpen={this.state.isDeleteMemoModalOpen}
          hideModal={this.closeDeleteMemoModal}
          removeMemo={this.props.removeMemo}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  memoColors: getSelectOptions(state, MEMO_COLOR_OPTIONS)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  removeMemo: () => {
    dispatch(deleteMemo(ownProps.memo.id, ownProps.memo.employeeId));
    ownProps.changeMemoModal();
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MemoRow);
