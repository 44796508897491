import React from 'react';
import { FormSection } from 'redux-form';

import HealthInsuranceSection from './HealthInsuranceSection';
import PensionInsuranceSection from './PensionInsuranceSection';
import EmploymentInsuranceSection from './EmploymentInsuranceSection';
import GeneralInsuranceInfoSection from './GeneralInsuranceInfoSection';
import AccidentInsuranceSection from './AccidentInsuranceSection';

const InsuranceSection = () => (
  <div>
    <FormSection name="employeeInsurance">
      <h3 className="u-mb5">＜社会保険＞</h3>
      <GeneralInsuranceInfoSection />

      <h3>＜健康保険＞</h3>
      <HealthInsuranceSection />

      <h3>＜厚生年金保険＞</h3>
      <PensionInsuranceSection />

      <h3>＜雇用保険＞</h3>
      <EmploymentInsuranceSection />

      <h3>＜労災保険＞</h3>
      <AccidentInsuranceSection />
    </FormSection>
  </div>
);

export default InsuranceSection;
