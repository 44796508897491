import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getFormSyncErrors } from 'redux-form';
import LazyLoad from 'react-lazyload';

import {
  NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES,
  NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES_WITH_ALERT
} from 'src/constants/Options';
import ActionButton from 'jbc-front/components/ActionButton';
import { CheckCircle, Alert } from 'jbc-front/components/icons';
import { RadioField } from 'jbc-front/components/Form';

import Balloon from 'src/components/Balloon';
import { EditActionBtn, CancelActionBtn, SaveActionBtn } from 'src/buttons';
import { SimpleConfirmModal } from 'src/components';
import { EMPLOYEE_SHOW } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { changeSubjectRevision, getImporting } from 'src/reducers/reports/notificationOfBaseAmountRevisions';

import styles from './RevisionItemHeader.scss';

class RevisionItemHeader extends React.Component {
  state = {
    isChangeSubjectModalOpen: false
  };

  openChangeSubjectModal = () => {
    this.setState({ ...this.state, isChangeSubjectModalOpen: true });
  };
  closeChangeSubjectModal = () => {
    this.setState({ ...this.state, isChangeSubjectModalOpen: false });
  };

  render() {
    const {
      revision,
      formRevision,
      showMode,
      changeToEditMode,
      cancel,
      save,
      reset,
      changeSubject,
      importing,
      errorsOnSave
    } = this.props;
    const subjectLabel = showMode === 'SHOW' ? revision.subjectLabel : formRevision.subjectLabel;
    const isConfirmedAlert = revision.isConfirmedAlert || _.isNull(revision.alertMessage);
    const disabledReason = '処理中です。完了までしばらくお待ちください。';
    const forceSubjectTypesForHealth =
      revision.isChangeMonthlyRevisionInJuly &&
      ['health_and_pension', 'only_health'].includes(revision.monthlyRevisionTargetInsuranceType)
        ? NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES_WITH_ALERT
        : NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES;
    const forceSubjectTypesForPension =
      revision.isChangeMonthlyRevisionInJuly &&
      ['health_and_pension', 'only_pension'].includes(revision.monthlyRevisionTargetInsuranceType)
        ? NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES_WITH_ALERT
        : NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES;
    return (
      <div className={styles.container}>
        <div className={styles.labels}>
          <div className={styles.item}>
            スタッフコード{'　'}: <span className={styles.code}>{revision.staffCode}</span>
          </div>
          <div className={styles.item}>
            氏名{'　'}:{' '}
            <span className={styles.name}>
              <a href={concatParamsToUrl(EMPLOYEE_SHOW, { id: revision.employeeId })}>
                {revision.lastName} {revision.firstName}
              </a>
            </span>
          </div>
          {!isConfirmedAlert && (
            <div className={styles.alertWrapper}>
              <div className={styles.alert}>
                <Alert size={22} color={'#F6A623'} />
                <Balloon className={styles.balloon}>
                  <pre>{revision.alertMessage}</pre>
                </Balloon>
              </div>
            </div>
          )}
          <div className={styles.subject}>
            <ActionButton
              className={(styles.subjectButton, subjectLabel === '対象外' ? styles.isNotSubject : styles.isSubject)}
              onClick={() => this.openChangeSubjectModal()}
              icon={<CheckCircle size={16} />}
              as="button"
            >
              {subjectLabel}
            </ActionButton>
          </div>
        </div>
        <LazyLoad>
          {showMode === 'SHOW' ? (
            <div className={styles.action}>
              <EditActionBtn disabled={importing} disabledReason={disabledReason} onClick={changeToEditMode} />
            </div>
          ) : (
            <div className={styles.action}>
              <ActionButton onClick={reset} as="button" className="u-mr10">
                手入力前に戻す
              </ActionButton>
              <SaveActionBtn onClick={() => save(errorsOnSave)} className="u-mr10" />
              <CancelActionBtn onClick={cancel} />
            </div>
          )}
        </LazyLoad>
        <LazyLoad>
          {this.state.isChangeSubjectModalOpen && (
            <SimpleConfirmModal
              isOpen={this.state.isChangeSubjectModalOpen}
              hideModal={this.closeChangeSubjectModal}
              header={'対象判定変更'}
              body={
                <React.Fragment>
                  <div>
                    <div>対象・対象外への強制変更や、自動判定に戻すことが可能です。</div>
                  </div>
                  <br />
                  <div className={styles.itemBox}>
                    <p>健康保険</p>
                    <RadioField name="healthInsuranceForceSubjectType" options={forceSubjectTypesForHealth} />
                  </div>
                  <div className={styles.itemBox}>
                    <p>厚生年金</p>
                    <RadioField name="pensionInsuranceForceSubjectType" options={forceSubjectTypesForPension} />
                  </div>
                </React.Fragment>
              }
              onClick={() => changeSubject(revision.id)}
              confirmBtnText={'保存'}
            />
          )}
        </LazyLoad>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  importing: getImporting(state),
  errorsOnSave: _.get(getFormSyncErrors('revisions')(state), ['revisions', ownProps.revision.id])
});
const mapDispatchToProps = dispatch => ({
  changeSubject: revisionId => {
    dispatch(changeSubjectRevision(revisionId));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(RevisionItemHeader);
