import React from 'react';
import { Card, Container } from 'src/components/SelectCard/SelectCard';
import { FileImport, Edit } from 'jbc-front/components/icons';
import { EMPLOYEE_NEW, INSERT_BATCH_CSV, UPDATE_BATCH_CSV } from 'src/constants/EndpointUrls';

const ChooseRegisterMethod = () => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">従業員登録</h1>
      <p className="m-title-main-note">登録する方法を選んでくだい</p>
    </div>
    <Container>
      <Card icon={Edit} to={EMPLOYEE_NEW}>
        新規登録<br />直接入力する
      </Card>
      <Card icon={FileImport} to={INSERT_BATCH_CSV} key="import">
        新規登録<br />ファイルからインポート
      </Card>
      <Card icon={FileImport} to={UPDATE_BATCH_CSV} key="update">
        更新<br />ファイルからインポート
      </Card>
    </Container>
  </div>
);

export default ChooseRegisterMethod;
