import React from 'react';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import { PAYROLL_FB_DATA_FORM } from 'src/constants/FormNames';
import { DateField, RadioField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { PAYROLL_TRANSFER_TYPES } from 'src/constants/Options';
import FbDataLineFeedRadioButton from 'src/components/FbDataLineFeedRadioButton';
import { ErrorsSection } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import styles from './PayrollFBDataModal.scss';

const PayrollFBDataInsideForm = ({
  errors,
  submitting,
  pristine,
  submit,
  isOpen,
  hideModal,
  paymentRange,
  onModalHide
}) => {
  const yearMonth = _.get(paymentRange, 'yearMonth', '_').split('_');
  const header = `給与振込データ出力  ＜${yearMonth[0]}年${yearMonth[1]}月支給分＞`;
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, onModalHide }}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form className={styles.form}>
          <ErrorsSection errors={errors} />

          <p className="u-mb40">インターネットバンキングを通じて振込業務を行うためのFBデータを出力できます。</p>

          <div className={styles.wrap}>
            <DateField required name="transfer_date" label="振込日" />
            <RadioField required name="transfer_type" options={PAYROLL_TRANSFER_TYPES} label="種別" />
            <FbDataLineFeedRadioButton />
          </div>

          <ul className={styles.notes}>
            <li>※ 口座情報が未登録の従業員は、データ出力できません。</li>
            <li>※ 振込支給金額が０円以下の従業員は、データ出力できません。</li>
          </ul>
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={hideModal} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="出力" {...{ submitting, pristine, submit }} />
      </CommonModal.Footer>
    </CommonModal>
  );
};

const PayrollFBDataForm = reduxForm({
  form: PAYROLL_FB_DATA_FORM
})(PayrollFBDataInsideForm);

const FbDataModal = ({ paymentRange, errors, onModalHide, handleSubmit, isOpen, hideModal }) => (
  <PayrollFBDataForm
    isOpen={isOpen}
    hideModal={hideModal}
    onSubmit={values => handleSubmit(values, paymentRange)}
    paymentRange={paymentRange}
    errors={errors}
    onModalHide={onModalHide}
  />
);

export default FbDataModal;
