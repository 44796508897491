import { connect } from 'react-redux';
import { getFormValues, getFormSyncErrors } from 'redux-form';
import _ from 'lodash';
import { getImporting } from 'src/reducers/reports/monthlyRevisions';
import RevisionItemHeader from '../components/RevisionItemHeader';

const mapStateToProps = (state, ownProps) => ({
  formRevision: getFormValues('revisions')(state).revisions[ownProps.revision.id],
  importing: getImporting(state),
  errors: revision => _.get(getFormSyncErrors('revisions')(state), ['revisions', revision.id])
});

export default connect(mapStateToProps)(RevisionItemHeader);
