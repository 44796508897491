import React from 'react';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';

const BonusReportsCreateModal = props => {
  const { isOpen, hideModal, submitting, setDownloadJob } = props;
  const header = '明細一括作成';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <React.Fragment>
        <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
        <CommonModal.Body>
          <form>
            <p>
              明細を一括でダウンロード、印刷するためのデータを作成します。
              <br />
              <br />
              作成するデータ量により時間がかかる場合があります。
              <br />
              問題がなければ作成するボタンを押してください。
            </p>
          </form>
        </CommonModal.Body>
        <CommonModal.Footer>
          <Button onClick={hideModal} className="u-mr20">
            キャンセル
          </Button>
          <FormSubmitBtn text="作成する" {...{ submitting, submit: setDownloadJob }} />
        </CommonModal.Footer>
      </React.Fragment>
    </CommonModal>
  );
};

export default BonusReportsCreateModal;
