import React from 'react';
import { FormSection } from 'redux-form';
import { display } from 'src/utils/Utils';
import { CheckboxField } from 'jbc-front/components/Form';
import tableStyles from '../styles/GeneralTableInfo.scss';
import styles from './ElectronicDeliveryAgreement.scss';

const ElectronicDeliveryAgreement = props => {
  const { employee, employeeElectronicDeliveryAgreementDetail } = props;

  return (
    <div>
      <FormSection name="employee">
        <table className={tableStyles.mTablePersonal}>
          <tbody>
            {employeeElectronicDeliveryAgreementDetail.agreementType === 'self' ? (
              <tr>
                <th>同意状況：</th>
                <td>{employee.isElectronicDeliveryAgreement ? '同意済' : '未同意'}</td>
              </tr>
            ) : (
              <tr>
                <td className={styles.checktd}>
                  <CheckboxField name="isElectronicDeliveryAgreement" label="同意済" />
                </td>
              </tr>
            )}
            <tr>
              <th>同意日：</th>
              <td>{display(employeeElectronicDeliveryAgreementDetail.agreementDate)}</td>
            </tr>
            <tr>
              <th>操作者：</th>
              <td>{display(employeeElectronicDeliveryAgreementDetail.displayUserName)}</td>
            </tr>
          </tbody>
        </table>
      </FormSection>
    </div>
  );
};

export default ElectronicDeliveryAgreement;
