import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  SEARCH_FLAT_TAX_REDUCTION_EMPLOYEE_URL,
  UPDATE_FLAT_TAX_REDUCTION_EMPLOYEE_URL
} from 'src/constants/EndpointUrls';
import classNames from 'classnames';
import _ from 'lodash';
import { reduxForm, getFormValues } from 'redux-form';

import Button from 'jbc-front/components/Button';

import { ErrorsSection } from 'src/components';
import DetailSearchForm from 'src/components/DetailSearchForm';
import LoadingView from 'src/components/LoadingView';
import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import EnrollmentStatusBtnList from 'src/features/Employees/components/EnrollmentStatusBtnList';
import { getIsLoading, getList, getErrors } from 'src/reducers/common/listReducer';
import { EMPLOYEE_MODE } from 'src/reducers/flat_tax_reductions/csv';
import { REDUCER_NAME, fetchEmployees, getCheckedEmployees } from 'src/reducers/flat_tax_reductions/employees';
import {
  takeEmployeesSearchQueriesForSaveStatuses,
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesResetFormQueries,
  FORM_NAME_FLAT_TAX_REDUCTION_SEARCH
} from 'src/reducers/searchForm';

import styles from './EmployeeList.scss';
import SaveEmployeesModal from './components/SaveEmployeesModal';
import EmployeeTable from './components/EmployeeTable';
import { DownloadFlatTaxReductionCsvButton } from './components/DownloadFlatTaxReductionCsvButton';
import { UploadFlatTaxReductionCsvButton } from './components/UploadFlatTaxCsvReductionButton';

let InnerEmployeeList = ({ initialValues: formValues, location }) => {
  const errors = useSelector(state => getErrors(REDUCER_NAME, state)) || [{}];
  const employees = useSelector(state => getList(REDUCER_NAME, state));
  const tmpResetValues = useSelector(state => takeEmployeesResetFormQueries(state, true));
  const resetValues = _.merge({}, tmpResetValues, { employees });
  const isListLoading = useSelector(state => getIsLoading(REDUCER_NAME, state));
  const checkedEmployees = useSelector(state => getCheckedEmployees(state)) || [];
  const dispatch = useDispatch();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const employeeSearchQueries = takeEmployeesSearchQueriesForSaveStatuses(formValues);

  return (
    <div>
      <SearchDetector
        location={location}
        didMountDetectingJudger={() => true}
        willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
        onDetect={() => {
          dispatch(fetchEmployees(employeeSearchQueries, null, SEARCH_FLAT_TAX_REDUCTION_EMPLOYEE_URL));
        }}
      />
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">定額減税対象人数一覧</h1>
      </div>
      <p className={styles.notes}>
        2024年定額減税の月次減税額算出のための減税対象人数を登録します。
        <br />
        減税対象人数欄には「本人＋同一生計配偶者＋扶養親族の人数」の合計値を入力してください。
        <br />
        ※一覧には以下のすべての要件を満たしたスタッフが表示されます。
        <br />
        ①入社日が2024年6月1日以前
        <br />
        ②退職日が2024年6月1日以降
        <br />
        ③税区分情報＞税額表区分が「甲欄」
        <br />
        ④税区分情報＞非居住者区分が「対象外」
      </p>
      <ErrorsSection errors={errors} />
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.buttonArea}>
            <div className={styles.buttonDl}>
              <DownloadFlatTaxReductionCsvButton
                text="減税対象人数ダウンロード"
                header="定額減税対象人数ダウンロード"
                mode={EMPLOYEE_MODE}
                query={employeeSearchQueries}
              />
              <UploadFlatTaxReductionCsvButton text="減税対象人数インポート" mode={EMPLOYEE_MODE} />
            </div>
            <div className={styles.button}>
              <Button
                className={classNames(styles.balloon)}
                primary
                disabled={!checkedEmployees.length}
                disabledReason={
                  <div style={{ textAlign: 'left' }}>
                    従業員が選択されていません。
                    <br />
                    保存する場合、チェックボックスにチェックを入れてください。
                  </div>
                }
                onClick={() => setIsSaveModalOpen(true)}
              >
                保存
              </Button>
            </div>
          </div>
          <DetailSearchForm
            menuRender={() => <EnrollmentStatusBtnList />}
            containsOtherSearch={false}
            formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            containsOfficeSearch
            containSaveStatus
            resetValues={resetValues}
          />
        </div>
        <LoadingView isLoading={isListLoading}>
          <div className={classNames('l-wrap-xl', styles.list)}>
            <EmployeeTable employees={employees} mode={'employee'} />
            <SearchFormPaginator formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH} reducerName={REDUCER_NAME} />
          </div>
        </LoadingView>
      </div>
      <SaveEmployeesModal
        isOpen={isSaveModalOpen}
        hideModal={() => {
          setIsSaveModalOpen(false);
        }}
        title={'減税対象人数'}
        url={UPDATE_FLAT_TAX_REDUCTION_EMPLOYEE_URL}
      />
    </div>
  );
};

InnerEmployeeList = reduxForm({
  form: FORM_NAME_FLAT_TAX_REDUCTION_SEARCH,
  enableReinitialize: true
})(InnerEmployeeList);

/* eslint-disable import/prefer-default-export */
const EmployeeList = props => {
  const employeesSearchFormValues = useSelector(state => getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(state));
  const location = useSelector(state => state.router.location);
  const employees = useSelector(state => getList(REDUCER_NAME, state));
  const initialValues = {
    ...takeEmployeesSearchQueriesFromLocation(location, true),
    ...employeesSearchFormValues,
    employees
  };

  return <InnerEmployeeList {...props} location={location} initialValues={initialValues} />;
};

export { EmployeeList as FlatTaxReductionEmployees };
/* eslint-enable import/prefer-default-export */
