import { connect } from 'react-redux';
import { getPdfJob, getIsFetchCompleted } from 'src/reducers/reports/notificationOfBaseAmountRevisionAsync';
import PdfModalBase from '../components/PdfModalBase';

const mapStateToProps = (state, ownProps) => ({
  pdfJob: getPdfJob(state, ownProps.revisionYear, ownProps.applicableOffice),
  isFetchCompleted: getIsFetchCompleted(state)
});

export default connect(mapStateToProps)(PdfModalBase);
