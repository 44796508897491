import React from 'react';

import { Panel, Item, Headding } from 'src/components/PrimaryPanel';
import BasicInfoSectionShow from 'src/features/Employees/components/BasicInfoSectionShow';
import BusinessInfoSectionShow from 'src/features/Employees/components/BusinessInfoSectionShow';
import { USER_PROFILE_EDIT } from 'src/constants/EndpointUrls';
import SettingElectronicDeliveryForm from '../containers/SettingElectronicDeliveryContainer';

const SimpleProfileShow = props => (
  <div>
    <Panel>
      <Headding>基本情報</Headding>
      <Item>
        <BasicInfoSectionShow />
      </Item>
    </Panel>

    <Panel>
      <Headding>業務情報</Headding>
      <Item>
        <BusinessInfoSectionShow />
      </Item>
    </Panel>

    <SettingElectronicDeliveryForm employee={props.employee} redirectPath={USER_PROFILE_EDIT} />
  </div>
);

export default SimpleProfileShow;
