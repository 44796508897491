import { addNotification } from 'reapop';
import isArray from 'lodash/isArray';

export const SYSTEM_ERROR_STRING = [
  'システムエラーが発生しました。',
  'お手数ですがサポート窓口までお問い合わせください。'
];

// reapopは\nで改行できないので変換する。
// 呼び出し元の.join('<br/>')で解決するための根本対応: https://github.com/d-o-n-u-t-s/payrollservice/issues/11466
const replaceNewlinesWithBreaks = stringsArray => stringsArray.map(str => str.replace(/\\n/g, '<br />'));

export const setGlobalErrors = errors =>
  addNotification({
    message: isArray(errors) ? replaceNewlinesWithBreaks(errors).join('<br/>') : errors,
    position: 'tc',
    status: 'error',
    dismissAfter: 5000,
    allowHTML: isArray(errors)
  });

export const setGlobalWarnings = warnings =>
  addNotification({
    message: isArray(warnings) ? replaceNewlinesWithBreaks(warnings).join('<br/>') : warnings,
    position: 'tc',
    status: 'warning',
    dismissAfter: 5000,
    allowHTML: isArray(warnings)
  });

export const setGlobalSuccesses = successes =>
  addNotification({
    message: isArray(successes) ? replaceNewlinesWithBreaks(successes).join('<br/>') : successes,
    position: 'tc',
    status: 'success',
    dismissAfter: 5000,
    allowHTML: isArray(successes)
  });

export const dispatchSessionMessages = (dispatch, sessionMessages) => {
  if (isArray(sessionMessages)) {
    return dispatch(setGlobalWarnings(sessionMessages));
  }

  return Object.keys(sessionMessages).forEach(errorType => {
    const msg = sessionMessages[errorType];

    switch (errorType) {
      case 'error':
        dispatch(setGlobalErrors(msg));
        break;
      case 'success':
        dispatch(setGlobalSuccesses(msg));
        break;
      case 'warning':
        dispatch(setGlobalWarnings(msg));
        break;
      default:
        dispatch(setGlobalWarnings(msg));
        break;
    }
  });
};
