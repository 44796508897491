import axios from 'axios';
import _ from 'lodash';
import { combineReducers } from 'redux';
import { getFormValues, autofill, stopSubmit } from 'redux-form';

import * as Urls from 'src/constants/EndpointUrls';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { listReducer, itemReducer } from 'src/reducers/common';
import { getItem } from 'src/reducers/common/itemReducer';
import {
  getListPageCount,
  getList,
  fetchList,
  fetchListSuccess,
  fetchListSuccessWithoutPagingInfo,
  fetchListFailure,
  updateListFailure,
  setList,
  setExtras
} from 'src/reducers/common/listReducer';
import { FORM_NAME_FLAT_TAX_REDUCTION_SEARCH } from 'src/reducers/searchForm';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { setGlobalSuccesses } from 'src/reducers/global';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { concatParamsToUrl } from 'src/utils/Http';
import { scrollToTop } from 'src/utils/Utils';

export const REDUCER_NAME = 'employees';

export const getEmployeeData = state => getItem(REDUCER_NAME, state);

export const getCheckedEmployees = state => _.filter(getList(REDUCER_NAME, state), employee => employee.checked);

// Form Selector
export const getEmployeeFormValues = state => getFormValues(EMPLOYEE_FORM)(state);

// actions
export const updateEmployeeForm = (field, value) => autofill(EMPLOYEE_FORM, field, value);

// Async Action Creators
export const fetchEmployees = (
  queries,
  callbackWithDispatch = undefined,
  url = Urls.SEARCH_FLAT_TAX_REDUCTION_EMPLOYEE_URL
) => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    const response = await axios.get(`${url}`, { params: queries });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.employees,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(MEMO_REDUCER_NAME, response.data.payload.memos));
    if (typeof callbackWithDispatch === 'function') {
      dispatch(callbackWithDispatch(payload.data));
    }
  } catch (exception) {
    let msg = ['一覧の取得に失敗しました'];
    if (!_.isEmpty(exception?.response?.data?.errors)) {
      msg = exception.response.data.errors.messages;
    }
    dispatch(fetchListFailure(REDUCER_NAME, msg));
  }
};

// テキストボックスの減税対象人数、既減税額を表示用の方にコピーする
const getUpdatedEmployeeList = (employees, callbackFnc = () => true) => {
  const newEmployeeList = employees.map(
    employee =>
      employee.checked && callbackFnc(employee)
        ? {
            ...employee,
            reduceTargetCountDisp: employee.reduceTargetCount,
            lastSavedReduceTargetCountAt: Date.now(),
            reduceAmountDisp: employee.reduceAmount,
            lastSavedReduceAmountAt: Date.now()
          }
        : employee
  );

  return newEmployeeList;
};

// Edit Employee
export const editEmployee = (ownProps, url) => async (dispatch, getState) => {
  ownProps.hideModal();

  const employees = getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(getState()).employees;
  const checkedEmployees = employees.filter(employee => employee.checked);
  const editUrl = concatParamsToUrl(url, {});
  const httpBody = { employee: checkedEmployees };

  try {
    const response = await axios.put(editUrl, httpBody);

    if (response.data?.errors?.length > 0) {
      dispatch(updateListFailure(REDUCER_NAME, response.data.errors.messages.flat()));
      dispatch(stopSubmit(EMPLOYEE_FORM));
    } else {
      const newEmployeeList = getUpdatedEmployeeList(employees);
      dispatch(fetchListSuccessWithoutPagingInfo(REDUCER_NAME, { data: newEmployeeList }));
      dispatch(setGlobalSuccesses('保存しました'));
    }
  } catch (exception) {
    scrollToTop();
    dispatch(stopSubmit(EMPLOYEE_FORM));
    let errMessages = ['更新に失敗しました。'];
    if (exception.response?.data) {
      errMessages = exception.response.data.errors.messages.flat();
    }
    dispatch(updateListFailure(REDUCER_NAME, errMessages));

    if (exception?.response?.data?.errors?.errorEmployeeIds) {
      const callbackFnc = employee => !_.includes(exception?.response?.data?.errors?.errorEmployeeIds, employee.id);
      const newEmployeeList = getUpdatedEmployeeList(employees, callbackFnc);
      const pageCount = getListPageCount(REDUCER_NAME, getState());
      dispatch(setList(REDUCER_NAME, { data: newEmployeeList, pageCount }));
    }
  }
};

function getUpdatedConfirmationList(employees, employeeReductions) {
  return employees.map(employee => {
    const reduction = employeeReductions.find(r => r.employeeId === employee.id);
    if (!reduction) return employee;

    return {
      ...employee,
      confirmUser: reduction.confirmUser,
      unconfirmUser: reduction.unconfirmUser,
      isConfirmed: reduction.isConfirmed,
      lastConfirmAt: reduction.lastConfirmAt,
      lastUnconfirmAt: reduction.lastUnconfirmAt,
      checked: employee.hasConfirmedPaymentOrBonusSince ? false : employee.checked
    };
  });
}

const employeeConfirmation = async (props, dispatch, getState, url, successMessage) => {
  props.hideModal();

  const employees = getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(getState()).employees;
  const checkedEmployees = employees.filter(employee => employee.checked);
  const editUrl = concatParamsToUrl(url, {});
  const httpBody = { employee: checkedEmployees };

  try {
    const response = await axios.put(editUrl, httpBody);
    const employeeFlatTaxReductions = response.data.payload.employeeFlatTaxReductions;

    if (response.data?.errors?.length > 0) {
      dispatch(updateListFailure(REDUCER_NAME, response.data.errors.messages.flat()));
      dispatch(stopSubmit(EMPLOYEE_FORM));
    } else {
      // 確定情報を最新にする
      const newConfirmationList = getUpdatedConfirmationList(employees, employeeFlatTaxReductions);
      dispatch(fetchListSuccessWithoutPagingInfo(REDUCER_NAME, { data: newConfirmationList }));
      dispatch(setGlobalSuccesses(successMessage));
    }
  } catch (exception) {
    scrollToTop();
    dispatch(stopSubmit(EMPLOYEE_FORM));
    let errMessages = ['確定・確定解除処理に失敗しました。'];
    if (exception.response?.data) {
      errMessages = exception.response.data.errors.messages.flat();
    }
    dispatch(updateListFailure(REDUCER_NAME, errMessages));

    if (exception?.response?.data?.errors?.errorEmployeeIds) {
      const employeeFlatTaxReductions = exception?.response?.data?.errors?.employeeFlatTaxReductions;
      const newConfirmationList = getUpdatedConfirmationList(employees, employeeFlatTaxReductions);
      const pageCount = getListPageCount(REDUCER_NAME, getState());
      dispatch(setList(REDUCER_NAME, { data: newConfirmationList, pageCount }));
    }
  }
};

export const confirmEmployee = ownProps => (dispatch, getState) => {
  employeeConfirmation(ownProps, dispatch, getState, Urls.CONFIRM_FLAT_TAX_REDUCTION_URL, '確定しました');
};

export const unconfirmEmployee = ownProps => (dispatch, getState) => {
  employeeConfirmation(ownProps, dispatch, getState, Urls.UNCONFIRM_FLAT_TAX_REDUCTION_URL, '確定解除しました');
};

export const isAllEmployeeChecked = state => {
  const employeeList = _.filter(
    getList(REDUCER_NAME, state),
    employee =>
      // 定額減税が未確定なものに絞る
      !employee.isConfirmed
  );
  if (!employeeList.length) {
    return false;
  }
  return _.every(employeeList, { checked: true });
};

export const isAllEmployeeCheckedForConfirmationList = state => {
  const employeeList = _.filter(
    getList(REDUCER_NAME, state),
    employee =>
      // 2024年6月1日以降支給の給与や賞与が未確定なものに絞る
      !employee.hasConfirmedPaymentOrBonusSince
  );
  if (!employeeList.length) {
    return false;
  }
  return _.every(employeeList, { checked: true });
};

export const changeAllEmployeeChecked = newChecked => (dispatch, getState) => {
  const employeeList = getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(getState()).employees || [];
  const newEmployeeList = employeeList.map(
    employee => (!employee.isConfirmed ? { ...employee, checked: newChecked } : { ...employee })
  );
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setList(REDUCER_NAME, { data: newEmployeeList, pageCount }));
};

export const changeAllEmployeeCheckedForConfirmationList = newChecked => (dispatch, getState) => {
  const employeeList = getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(getState()).employees || [];
  // 2024年6月1日以降支給の給与や賞与が未確定なもの、かつ定額減税が未確定のもののチェック状態を変更
  const newEmployeeList = employeeList.map(
    employee =>
      !employee.hasConfirmedPaymentOrBonusSince || !employee.isConfirmed
        ? { ...employee, checked: newChecked }
        : { ...employee }
  );
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setList(REDUCER_NAME, { data: newEmployeeList, pageCount }));
};

export const changeEmployeeChecked = employeeId => (dispatch, getState) => {
  const employeeList = getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(getState()).employees || [];
  const newEmployeeList = employeeList.map(
    employee =>
      employee.id === employeeId && !employee.isConfirmed
        ? { ...employee, checked: !employee.checked }
        : { ...employee }
  );
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setList(REDUCER_NAME, { data: newEmployeeList, pageCount }));
};

export const changeEmployeeCheckedForConfirmationList = employeeId => (dispatch, getState) => {
  const employeeList = getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(getState()).employees || [];
  // 2024年6月1日以降支給の給与や賞与が未確定なもの、かつ定額減税が未確定のもののチェック状態を変更
  const newEmployeeList = employeeList.map(
    employee =>
      employee.id === employeeId && (!employee.hasConfirmedPaymentOrBonusSince || !employee.isConfirmed)
        ? { ...employee, checked: !employee.checked }
        : { ...employee }
  );
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  dispatch(setList(REDUCER_NAME, { data: newEmployeeList, pageCount }));
};

export const employees = combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
