import React from 'react';
import { Field, FormSection } from 'redux-form';
import _ from 'lodash';
import Decimal from 'decimal.js';
import { isMoneyNumber, isNumber, isInsuranceRateFormat } from 'src/utils/CustomValidators';
import { numberWithComma } from 'src/utils/Utils';
import { CheckboxField } from 'jbc-front/components/Form';
import EditableTextField from './EditableTextField';
import styles from './LaborInsuranceDeclarationForm2021.scss';

const replaceBlankToZero = value => {
  if (_.isNil(value) || value === '') {
    return 0;
  }

  return value;
};

const replaceMoney = value => {
  const replaced = replaceBlankToZero(value);
  if (isNumber(replaced)) {
    // 数値じゃない場合は0を返す
    return 0;
  }
  return replaced;
};

const replaceRate = value => {
  const replaced = replaceBlankToZero(value);
  if (_.toInteger(replaced) === 0) {
    return 0;
  }
  return replaced;
};

const isEdit = (actionMode, formVal) => {
  if (_.isNil(formVal)) {
    return 'SHOW';
  }
  const isAutoCalc = formVal.isAutoCalcEstimateInsurance;
  if (actionMode === 'SHOW') {
    return actionMode;
  } else if (isAutoCalc) {
    // 自動計算するにチェックがある場合は編集できない
    return 'SHOW';
  }
  return 'EDIT';
};

const calculation = (updateCalcResult, formVal, changedValue) => {
  let param = {
    estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm: replaceMoney(
      formVal.estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm
    ),
    estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm: replaceMoney(
      formVal.estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm
    ),
    estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance: replaceRate(
      formVal.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance
    ),
    estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm: replaceRate(
      formVal.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm
    )
  };
  // 変更された値を反映させる
  param = Object.assign(param, changedValue);

  // 前期: 概算保険額
  const estimateEmploymentInsuranceFirstTerm = new Decimal(
    param.estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm
  )
    .mul(new Decimal(param.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance))
    .toNumber();
  // 後期: 概算保険額
  const estimateEmploymentInsuranceLatterTerm = new Decimal(
    param.estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm
  )
    .mul(new Decimal(param.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm))
    .toNumber();
  // 合計: 保険料算定基礎額
  const sumEstimateInsuranceFeeBaseForEmploymentInsurance =
    _.toInteger(param.estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm) +
    _.toInteger(param.estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm);
  // 合計: 概算保険額
  const sumEstimateEmploymentInsurance = _.toInteger(
    new Decimal(estimateEmploymentInsuranceFirstTerm).add(new Decimal(estimateEmploymentInsuranceLatterTerm)).toNumber()
  );

  updateCalcResult(
    'laborInsuranceDeclarationForm.estimateEmploymentInsuranceFirstTerm',
    estimateEmploymentInsuranceFirstTerm
  );
  updateCalcResult(
    'laborInsuranceDeclarationForm.estimateEmploymentInsuranceLatterTerm',
    estimateEmploymentInsuranceLatterTerm
  );
  updateCalcResult(
    'laborInsuranceDeclarationForm.sumEstimateInsuranceFeeBaseForEmploymentInsurance',
    sumEstimateInsuranceFeeBaseForEmploymentInsurance
  );
  updateCalcResult('laborInsuranceDeclarationForm.sumEstimateEmploymentInsurance', sumEstimateEmploymentInsurance);
};

const LaborInsuranceDeclarationForm2021 = ({ formVal, actionMode, updateCalcResult, blurMoney, blurRate }) => (
  <FormSection name={`laborInsuranceDeclarationForm`}>
    <div style={{ position: 'relative' }}>
      <div className="l-overflow-scroll">
        {actionMode === 'EDIT' && (
          <CheckboxField
            name="isAutoCalcEstimateInsurance"
            label="保存時に概算保険料(雇用保険分)算定内訳を自動計算する"
          />
        )}
        <table className={styles.laborInsuranceDeclarationFormTable}>
          <colgroup>
            <col width={220} />
            <col width={175} />
            <col width={230} />
            <col width={230} />
            <col width={230} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan="2" />
              <th colSpan="3">概算保険料(雇用保険分)算定内訳</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td rowSpan="2" colSpan="2">
                ①区分(適用期間)
              </td>
              <td colSpan="6">算定期間 令和4年4月1日から令和5年3月31日</td>
            </tr>
            <tr>
              <td colSpan="1">②保険料算定基礎額</td>
              <td colSpan="1">③保険料率</td>
              <td colSpan="1">④概算保険額</td>
            </tr>
            <tr>
              <td rowSpan="1" colSpan="1">
                令和4年4月1日<br />〜<br />令和4年9月30日
              </td>
              <td rowSpan="1" colSpan="1">
                雇用保険分
              </td>
              <td rowSpan="1" colSpan="1" className={styles.tdAmount}>
                <Field
                  name="estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm"
                  showValue={_.isNil(formVal) ? '' : formVal.estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm}
                  showMode={isEdit(actionMode, formVal)}
                  inputWidth={230}
                  validate={isMoneyNumber}
                  component={EditableTextField}
                  editProps={{ maxLength: 9 }}
                  onChange={e => {
                    calculation(updateCalcResult, formVal, {
                      estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm: replaceMoney(e.target.value)
                    });
                  }}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    blurMoney(
                      'laborInsuranceDeclarationForm.estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm',
                      previousValue
                    );
                    calculation(updateCalcResult, formVal, {
                      estimateInsuranceFeeBaseForEmploymentInsuranceFirstTerm: replaceMoney(newValue)
                    });
                  }}
                />
                <span className={styles.currency}>千円</span>
              </td>
              <td rowSpan="1" colSpan="1" className={styles.tdRate}>
                <div className={styles.employmentRate}>
                  1000分の
                  <Field
                    name="estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance"
                    showValue={
                      _.isNil(formVal) ? '' : formVal.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance
                    }
                    showMode={isEdit(actionMode, formVal)}
                    inputWidth={230}
                    component={EditableTextField}
                    editProps={{ maxLength: 7 }}
                    validate={[isInsuranceRateFormat]}
                    onChange={e => {
                      calculation(updateCalcResult, formVal, {
                        estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance: replaceRate(e.target.value)
                      });
                    }}
                    onBlur={(e, newValue, previousValue) => {
                      e.preventDefault();
                      blurRate(
                        'laborInsuranceDeclarationForm.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance',
                        previousValue
                      );
                      calculation(updateCalcResult, formVal, {
                        estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance: replaceRate(newValue)
                      });
                    }}
                  />
                </div>
              </td>
              <td rowSpan="1" colSpan="1">
                {_.isNil(formVal) ? '' : numberWithComma(formVal.estimateEmploymentInsuranceFirstTerm)}
                <span className={styles.currency}>円</span>
              </td>
            </tr>
            <tr>
              <td rowSpan="1" colSpan="1">
                令和4年10月1日<br />〜<br />令和5年3月31日
              </td>
              <td rowSpan="1" colSpan="1">
                雇用保険分
              </td>
              <td rowSpan="1" colSpan="1" className={styles.tdAmount}>
                <Field
                  name="estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm"
                  showValue={_.isNil(formVal) ? '' : formVal.estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm}
                  showMode={isEdit(actionMode, formVal)}
                  inputWidth={230}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                  editProps={{ maxLength: 9 }}
                  onChange={e => {
                    calculation(updateCalcResult, formVal, {
                      estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm: replaceMoney(e.target.value)
                    });
                  }}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    blurMoney(
                      'laborInsuranceDeclarationForm.estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm',
                      previousValue
                    );
                    calculation(updateCalcResult, formVal, {
                      estimateInsuranceFeeBaseForEmploymentInsuranceLatterTerm: replaceMoney(newValue)
                    });
                  }}
                />
                <span className={styles.currency}>千円</span>
              </td>
              <td rowSpan="1" colSpan="1" className={styles.tdRate}>
                <div className={styles.employmentRate}>
                  1000分の
                  <Field
                    name="estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm"
                    showValue={
                      _.isNil(formVal)
                        ? ''
                        : formVal.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm
                    }
                    showMode={isEdit(actionMode, formVal)}
                    inputWidth={230}
                    component={EditableTextField}
                    editProps={{ maxLength: 7 }}
                    validate={[isInsuranceRateFormat]}
                    onChange={e => {
                      calculation(updateCalcResult, formVal, {
                        estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm: replaceRate(
                          e.target.value
                        )
                      });
                    }}
                    onBlur={(e, newValue, previousValue) => {
                      e.preventDefault();
                      blurRate(
                        'laborInsuranceDeclarationForm.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm',
                        previousValue
                      );
                      calculation(updateCalcResult, formVal, {
                        estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsuranceLatterTerm: replaceRate(
                          newValue
                        )
                      });
                    }}
                  />
                </div>
              </td>
              <td rowSpan="1" colSpan="1">
                {_.isNil(formVal) ? '' : numberWithComma(formVal.estimateEmploymentInsuranceLatterTerm)}
                <span className={styles.currency}>円</span>
              </td>
            </tr>
            <tr>
              <td rowSpan="1" colSpan="1">
                合計
              </td>
              <td rowSpan="1" colSpan="1">
                雇用保険分
              </td>
              <td rowSpan="1" colSpan="1">
                {_.isNil(formVal) ? '' : numberWithComma(formVal.sumEstimateInsuranceFeeBaseForEmploymentInsurance)}
                <span className={styles.currency}>千円</span>
              </td>
              <td rowSpan="1" colSpan="1" />
              <td rowSpan="1" colSpan="1">
                {_.isNil(formVal) ? '' : numberWithComma(formVal.sumEstimateEmploymentInsurance)}
                <span className={styles.currency}>円</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </FormSection>
);

export default LaborInsuranceDeclarationForm2021;
