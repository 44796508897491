import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { ROLE_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, newRole } from 'src/reducers/clients/roles';
import { setItem, getItem, getItemErrors } from 'src/reducers/common/itemReducer';

import RoleForm from './containers/RoleForm';

let NewRoleForm = reduxForm({
  form: ROLE_FORM
})(RoleForm);

const mapStateToProps = state => ({
  isNewForm: true,
  initialValues: getItem(REDUCER_NAME, state),
  errors: getItemErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(newRole(data));
  }
});
NewRoleForm = connect(mapStateToProps, mapDispatchToProps)(NewRoleForm);

const CopyRole = ({ dispatch, role }) => {
  dispatch(setItem(REDUCER_NAME, role));
  return (
    <div>
      <NewRoleForm />
    </div>
  );
};

export default connect()(CopyRole);
