import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { setBonusReportDownloadJob } from 'src/reducers/employees/employeeBonus';
import { BONUS_REPORTS_CREATE_FORM } from 'src/constants/FormNames';
import BonusReportsCreateModal from '../components/BonusReportsCreateModal';

const BonusReportsCreateForm = reduxForm({
  form: BONUS_REPORTS_CREATE_FORM
})(BonusReportsCreateModal);

export default connect(null, (dispatch, ownProps) => ({
  setDownloadJob: () => {
    dispatch(setBonusReportDownloadJob(ownProps.clientBonusId, ownProps.hideModal));
  }
}))(BonusReportsCreateForm);
