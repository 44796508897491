import React, { useEffect } from 'react';

import classNames from 'classnames';
import _ from 'lodash';
import { FormSection } from 'redux-form';

import { TextField, RadioField, Label, SelectField } from 'jbc-front/components/Form';

import { OFFICE_FORM } from 'src/constants/FormNames';
import { HEALTH_INSURANCE_ROUNDING_TYPES } from 'src/constants/Options';
import { isNumber, maxLength8, maxLength4, exactLength5 } from 'src/utils/CustomValidators';
import { sumFloatNumberFixed5, toFloatString } from 'src/utils/Utils';
import SocialInsurancePeriodSetting from '../../containers/edit/SocialInsurancePeriodSettingContainer';
import RateTable from './RateTable';
import HealthInsuranceRateTable from './HealthInsuranceRateTable';
import styles from './InsuranceSection.scss';
import PensionInsuranceCodeField from './PensionInsuranceCodeField';

const upperLowUpdateFields = [
  'healthInsurance.insuranceType',
  'healthInsurance.roundingType',
  'healthInsurance.insuranceCode',
  'healthInsurance.rateForEmployee',
  'healthInsurance.rateForEmployer',
  'healthInsurance.nursingInsuranceRateForEmployee',
  'healthInsurance.nursingInsuranceRateForEmployer',
  'healthInsurance.officeId',
  'healthInsurance.socialInsuranceOfficeId',
  'healthInsurance.healthInsuranceRoundingTypeForEmployee',
  'healthInsurance.nursingInsuranceRoundingTypeForEmployee',
  'healthInsurance.roundingTypeForEmployee',
  'healthInsurance.isCurrentMonthCollection'
];
const lowerLowUpdateFields = [
  'pensionInsurance.rateForEmployee',
  'pensionInsurance.rateForEmployer',
  'pensionInsurance.childcareRateForEmployee',
  'pensionInsurance.childcareRateForEmployer',
  'pensionInsurance.insuranceCode1',
  'pensionInsurance.insuranceCode2',
  'pensionInsurance.insuranceCode',
  'pensionInsurance.insuranceNumber',
  'pensionInsurance.roundingTypeForEmployee',
  'pensionInsurance.isInput'
];

const RoundingTypeComponent = ({ insuranceType }) => {
  if (insuranceType === 'society') {
    return <RadioField required label="端数処理対象" name="roundingType" options={HEALTH_INSURANCE_ROUNDING_TYPES} />;
  }
  return null;
};

const SocialInsuranceSection = props => {
  const {
    healthInsurance,
    pensionInsurance,
    insuranceTypes,
    fetchSocialInsuranceRates,
    changeFormHealthInsuranceRate,
    changeFormPensionInsuranceRate,
    settingStartDate,
    prefectureId,
    isInitialized,
    isMainOffice,
    referenceOffices,
    healthInsuranceSelf,
    masterHealthInsurances,
    refChange,
    isCurrentMonthCollection,
    changeFormPensionInsuranceRateIsInput
  } = props;
  const {
    insuranceType,
    nursingInsuranceRateForEmployee,
    nursingInsuranceRateForEmployer,
    socialInsuranceOfficeId
  } = healthInsurance;
  const { isInput: pensionManualInput, childcareRateForEmployee, childcareRateForEmployer } = pensionInsurance;

  const totalizeRate = (employeeRate, employerRate) => toFloatString(sumFloatNumberFixed5(employeeRate, employerRate));

  const totalHealthInsuranceRate = totalizeRate(healthInsurance.rateForEmployee, healthInsurance.rateForEmployer);
  const totalNursingInsuranceRate = totalizeRate(nursingInsuranceRateForEmployee, nursingInsuranceRateForEmployer);

  const totalPensionInsuranceRate = totalizeRate(pensionInsurance.rateForEmployee, pensionInsurance.rateForEmployer);
  const totalChildCareInsuranceRate = totalizeRate(childcareRateForEmployee, childcareRateForEmployer);

  useEffect(
    () => {
      changeFormHealthInsuranceRate(insuranceType, settingStartDate);
    },
    [masterHealthInsurances]
  );

  useEffect(
    () => {
      const label = referenceOffices.find(option => option.value === socialInsuranceOfficeId)?.label;
      // 本社、または（現在編集中の事業所）を選択した場合は今選択している都道府県の料率を取得して設定する
      if (isMainOffice || label === '（現在編集中の事業所）')
        fetchSocialInsuranceRates(insuranceType, settingStartDate, prefectureId);
    },
    [insuranceType, prefectureId, settingStartDate, socialInsuranceOfficeId]
  );

  const handleInsuranceTypeChange = (_e, value) => {
    fetchSocialInsuranceRates(value, settingStartDate, prefectureId);
  };

  const officeContactInfoChange = (_e, value) => {
    // 編集中の事業所の保険情報が登録済の場合
    if (value !== 'self') refChange(settingStartDate, value);
  };

  const fetchHhandleInsuranceTyp = type => {
    const item = insuranceTypes.find(element => element.value === type);
    if (_.isEmpty(item)) {
      return '';
    }
    return item.label;
  };

  return (
    <div>
      <FormSection name="socialInsurance">
        {!isInitialized && (
          <SocialInsurancePeriodSetting
            formName={OFFICE_FORM}
            formSectionName={'socialInsurance'}
            fieldName="settingStartDate"
            upperLowUpdateFields={upperLowUpdateFields}
            lowerLowUpdateFields={lowerLowUpdateFields}
            upperLowLabel="健保"
            lowerLowLabel="厚年"
            insuranceType="socialInsurance"
            payTextFormat="YYYY年M月支給分に適用"
            bonusTextFormat="YYYY年M月支給分に適用"
            healthInsuranceSelf={isMainOffice || healthInsuranceSelf}
            isCurrentMonthCollection={isCurrentMonthCollection}
          />
        )}
      </FormSection>

      {!isMainOffice && (
        <FormSection name="healthInsurance">
          <SelectField
            label="参照する事業所情報"
            name="socialInsuranceOfficeId"
            addBlankOption={false}
            options={referenceOffices}
            onChange={officeContactInfoChange}
          />
        </FormSection>
      )}
      <div className={healthInsuranceSelf || isMainOffice ? styles.formSection : styles.applicableOffice}>
        {!healthInsuranceSelf && !isMainOffice ? (
          <React.Fragment>
            <FormSection name="healthInsurance">
              <table>
                <tbody>
                  <tr>
                    <td colSpan={6} className={styles.Detail}>
                      <table className={styles.table}>
                        <tbody>
                          <tr>
                            <th>事業所名：</th>
                            <td>{healthInsurance.contactInfoName}</td>
                          </tr>
                          <tr>
                            <th>住所：</th>
                            <td>{healthInsurance.contactInfoAddress}</td>
                          </tr>
                          <tr>
                            <th>事業主（代表者）氏名：</th>
                            <td>{healthInsurance.contactInfoRepresentativeName}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className={styles.refHeading}>健康保険</p>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={6} className={styles.Detail}>
                      <table className={styles.table}>
                        <tbody>
                          <tr>
                            <th>健康保険タイプ：</th>
                            <td>{fetchHhandleInsuranceTyp(healthInsurance.insuranceType)}</td>
                          </tr>
                          <tr>
                            <th>健康保険記号：</th>
                            <td>{healthInsurance.insuranceCode}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className={styles.refStyle}>
                {insuranceType &&
                  insuranceType !== 'not_entry' && (
                    <React.Fragment>
                      <Label text="保険料率" />
                      <HealthInsuranceRateTable
                        firstRowParams={{
                          label: '健康保険',
                          disableEmployee: true,
                          disableEmployer: true,
                          totalRate: totalHealthInsuranceRate
                        }}
                        secondRowParams={{
                          label: '介護保険',
                          disableEmployee: true,
                          disableEmployer: true,
                          namePrefix: 'nursingInsurance',

                          totalRate: totalNursingInsuranceRate
                        }}
                        isShowSecondRow
                        healthInsurance={healthInsurance}
                        disableRoundType={!healthInsuranceSelf && !isMainOffice}
                      />
                    </React.Fragment>
                  )}
              </div>
              <br />
              <p className={styles.refHeading}>厚生年金保険</p>
              <table>
                <tbody>
                  <tr>
                    <td colSpan={6} className={styles.Detail}>
                      <table className={styles.table}>
                        <tbody>
                          <tr>
                            <th>厚生年金保険：</th>
                            <td>{pensionInsurance.insuranceCode}</td>
                          </tr>
                          <tr>
                            <th>厚生年金保険番号：</th>
                            <td>{pensionInsurance.insuranceNumber}</td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </FormSection>
            <FormSection name="pensionInsurance">
              <div className={styles.refStyle}>
                <Label text="保険料率" />
                <RateTable
                  firstRowParams={{
                    label: '厚生年金保険',
                    disableEmployee: true,
                    disableEmployer: true,
                    displayRoundType: false,
                    disableRoundType: true,
                    cloumnName: 'roundingTypeForEmployee',
                    totalRate: totalPensionInsuranceRate
                  }}
                  secondRowParams={{
                    label: '子ども・子育て拠出金',
                    namePrefix: 'childcare',
                    disableEmployee: true,
                    disableEmployer: true,
                    displayRoundType: true,
                    disableRoundType: true,
                    cloumnName: 'roundingTypeForEmployee',
                    totalRate: totalChildCareInsuranceRate
                  }}
                  isShowSecondRow
                  roundingTypeForEmployee={pensionInsurance.roundingTypeForEmployee}
                />
              </div>
            </FormSection>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <FormSection name="healthInsurance" className={styles.formSection}>
              <RadioField
                required
                label="健康保険タイプ"
                name="insuranceType"
                options={insuranceTypes}
                onChange={handleInsuranceTypeChange}
              />
              <TextField
                label="健康保険記号（事業所整理記号）"
                name="insuranceCode"
                validate={[isNumber, insuranceType === 'society' ? maxLength8 : maxLength4]}
                maxLength={insuranceType === 'society' ? '8' : '4'}
                note="健康保険証に「記号」として表記されている数字です。協会けんぽは7~8桁、関東ITソフトウェア健康保険組合やその他の組合健保の場合は4桁になります。"
                noteWidth={310}
              />
              <RoundingTypeComponent insuranceType={insuranceType} />
              {insuranceType &&
                insuranceType !== 'not_entry' && (
                  <React.Fragment>
                    <Label text="保険料率" />
                    <HealthInsuranceRateTable
                      firstRowParams={{
                        label: '健康保険',
                        disableEmployee: insuranceType !== 'other_association',
                        disableEmployer: insuranceType !== 'other_association',
                        totalRate: totalHealthInsuranceRate
                      }}
                      secondRowParams={{
                        label: '介護保険',
                        disableEmployee: insuranceType !== 'other_association',
                        disableEmployer: insuranceType !== 'other_association',
                        namePrefix: 'nursingInsurance',
                        totalRate: totalNursingInsuranceRate
                      }}
                      isShowSecondRow
                      healthInsurance={healthInsurance}
                      disableRoundType={!healthInsuranceSelf && !isMainOffice}
                    />
                  </React.Fragment>
                )}
            </FormSection>
          </React.Fragment>
        )}
      </div>

      {(healthInsuranceSelf || isMainOffice) && (
        <FormSection name="pensionInsurance" className={styles.formSection}>
          <p className={styles.heading}>厚生年金保険</p>
          <React.Fragment>
            <PensionInsuranceCodeField />
            <TextField
              label="厚生年金保険番号（事業所番号）"
              type="text"
              name="insuranceNumber"
              validate={[exactLength5, isNumber]}
              maxLength="5"
              note="年金事務所から付与される5桁の数字です。「適用通知書」や「保険料納入告知額・領収済額通知書」などに記載されています。"
            />
            <div style={{ display: 'flex' }}>
              <Label text="保険料率" />
              {!isInitialized && (
                <label className={classNames('input-label', styles.checkBox)}>
                  <input
                    type="checkbox"
                    className="m-checkbox-input"
                    onChange={() => {
                      changeFormPensionInsuranceRateIsInput(!pensionManualInput);
                      // 手入力がtrue→falseの時マスタから値を更新
                      // false→true historyを優先
                      // onChangeの時点ではpensionManualInputは変化する前の値なので呼び出す時に反転させる
                      changeFormPensionInsuranceRate(!pensionManualInput, settingStartDate);
                    }}
                    checked={pensionManualInput}
                  />
                  <span className="m-checkbox-parts" style={{ paddingLeft: 20 }}>
                    料率を手入力する
                  </span>
                </label>
              )}
            </div>
            <RateTable
              firstRowParams={{
                label: '厚生年金保険',
                disableEmployee: !pensionManualInput,
                disableEmployer: !pensionManualInput,
                displayRoundType: false,
                disableRoundType: true,
                cloumnName: 'roundingTypeForEmployee',
                totalRate: totalPensionInsuranceRate
              }}
              secondRowParams={{
                label: '子ども・子育て拠出金',
                namePrefix: 'childcare',
                disableEmployee: true,
                disableEmployer: !pensionManualInput,
                displayRoundType: true,
                disableRoundType: false,
                cloumnName: 'roundingTypeForEmployee',
                totalRate: totalChildCareInsuranceRate
              }}
              isShowSecondRow
              roundingTypeForEmployee={pensionInsurance.roundingTypeForEmployee}
            />
          </React.Fragment>
        </FormSection>
      )}
    </div>
  );
};

export default SocialInsuranceSection;
