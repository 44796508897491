import React, { useEffect } from 'react';
import _ from 'lodash';
import AllJobStatusContainer from 'src/components/JobStatus/AllJobStatusContainer';
import { hideJobMessage } from 'src/reducers/reports/monthlyRevisions';

const formatDate = revisionYearMonth => {
  if (_.isEmpty(revisionYearMonth)) {
    return '';
  }
  const year = revisionYearMonth.substr(0, 4);
  const month = revisionYearMonth.substr(5, 2);
  return `${year}年${month}月`;
};

const statusText = job => {
  const date = formatDate(job.revisionYearMonth);
  switch (job.status) {
    case 'failed':
      return `${date}の月額変更届CSVインポートが失敗しました。最新の情報を反映させるためにはページを更新してください。`;
    case 'in_progress':
    case 'waiting':
      return `${date}の月額変更届CSVインポートを処理中です。`;
    default:
      return `${date}の月額変更届CSVインポートが完了しました。最新の情報を反映させるためにはページを更新してください。`;
  }
};

const ImportCsvJobStatus = props => {
  const job = props.job;

  useEffect(() => {
    props.fetchVisibleImportCsvJobs();
  }, []);

  return (
    <div>
      {!_.isEmpty(job) && (
        <AllJobStatusContainer task={job} statusText={statusText} jobId={job.jobId} hideJobMessage={hideJobMessage} />
      )}
    </div>
  );
};

export default ImportCsvJobStatus;
