import React from 'react';
import { connect } from 'react-redux';

import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { SortableHead } from 'src/features/ReportWithholdingSlips';
import { REDUCER_NAME, fetchReportWithholdingSlips } from 'src/reducers/reports/reportWithholdingSlips';
import { REPORT_WITHHOLDING_SLIP } from 'src/constants/EndpointUrls';
import ReportWithholdingSlipsTableRow from '../components/ReportWithholdingSlipsTableRow';
import ConfirmWithholdingModal from '../components/ConfirmWithholdingModal';
import UnconfirmWithholdingModal from '../components/UnconfirmWithholdingModal';

class ReportWithholdingSlipsTable extends React.Component {
  state = {
    isIndividualPdfPreview: false,
    isConfirmModalOpen: false,
    isUnconfirmModalOpen: false
  };

  openConfirmModal = (withholdingSlipYear, employeeId, canRelease, releaseDate, changedPayOrBonus) => {
    this.setState({
      ...this.state,
      isConfirmModalOpen: true,
      withholdingSlipYear,
      employeeId,
      canRelease,
      releaseDate,
      changedPayOrBonus
    });
  };
  closeConfirmModal = () => {
    this.setState({ ...this.state, isConfirmModalOpen: false });
  };

  openUnconfirmModal = (withholdingSlipYear, employeeId) => {
    this.setState({ ...this.state, isUnconfirmModalOpen: true, withholdingSlipYear, employeeId });
  };
  closeUnconfirmModal = () => {
    this.setState({ ...this.state, isUnconfirmModalOpen: false });
  };

  showIndividualPreview = (withholdingSlipYear, employeeId) => {
    this.setState({ ...this.state, isIndividualPdfPreview: true, withholdingSlipYear, employeeId });
  };
  hideIndividualPreview = () => {
    this.setState({ ...this.state, isIndividualPdfPreview: false });
  };

  individualPdfUrl = (withholdingSlipYear, employeeId) =>
    `${REPORT_WITHHOLDING_SLIP}?pdf[withholding_slip_year]=${withholdingSlipYear}&pdf[employee_id]=${employeeId}`;
  individualPdfDownloadUrl = (withholdingSlipYear, employeeId) =>
    `${REPORT_WITHHOLDING_SLIP}?pdf[withholding_slip_year]=${withholdingSlipYear}&pdf[employee_id]=${employeeId}`;

  render() {
    const { queries, employees } = this.props;
    return (
      <div className="l-overflow-scroll">
        <table className="m-table-list">
          <thead>
            <tr>
              <th>
                <SortableHead
                  sortField="staffCode"
                  queries={queries}
                  label="スタッフコード"
                  afterSortChange={fetchReportWithholdingSlips}
                  reducerName={REDUCER_NAME}
                />
              </th>
              <th>
                <SortableHead
                  sortField="name"
                  queries={queries}
                  label="氏名"
                  afterSortChange={fetchReportWithholdingSlips}
                  reducerName={REDUCER_NAME}
                />
              </th>
              <th>
                <SortableHead
                  sortField="joinedOn"
                  queries={queries}
                  label="入社日"
                  afterSortChange={fetchReportWithholdingSlips}
                  reducerName={REDUCER_NAME}
                />
              </th>
              <th>
                <SortableHead
                  sortField="resignedOn"
                  queries={queries}
                  label="退社日"
                  afterSortChange={fetchReportWithholdingSlips}
                  reducerName={REDUCER_NAME}
                />
              </th>
              <th>
                <SortableHead
                  sortField="withholdingSlipConfirmed"
                  queries={queries}
                  label="源泉徴収票の確定・確定解除"
                  afterSortChange={fetchReportWithholdingSlips}
                  reducerName={REDUCER_NAME}
                />
              </th>
              <th>
                <SortableHead
                  sortField="myPageVisible"
                  queries={queries}
                  label="マイページ公開"
                  afterSortChange={fetchReportWithholdingSlips}
                  reducerName={REDUCER_NAME}
                />
              </th>
              <th>PDF出力</th>
            </tr>
          </thead>
          <tbody>
            {employees.length === 0 && (
              <tr>
                <td colSpan="7">データがありません。</td>
              </tr>
            )}
            {employees.length > 0 &&
              employees.map(employee => (
                <ReportWithholdingSlipsTableRow
                  key={employee.id}
                  {...employee}
                  withholdingSlipYear={queries.withholdingSlipYear}
                  onClickConfirm={() =>
                    this.openConfirmModal(
                      queries.withholdingSlipYear,
                      employee.id,
                      employee.canRelease,
                      employee.releaseDate,
                      employee.changedPayOrBonus
                    )
                  }
                  onClickUnconfirm={() => this.openUnconfirmModal(queries.withholdingSlipYear, employee.id)}
                  onClickPdf={() => this.showIndividualPreview(queries.withholdingSlipYear, employee.id)}
                />
              ))}
          </tbody>
        </table>
        {this.state.isIndividualPdfPreview && (
          <PreviewPdfModalContainer
            header="源泉徴収票"
            isOpen={this.state.isIndividualPdfPreview}
            hideModal={this.hideIndividualPreview}
            url={this.individualPdfUrl(this.state.withholdingSlipYear, this.state.employeeId)}
            downloadUrl={this.individualPdfDownloadUrl(this.state.withholdingSlipYear, this.state.employeeId)}
          />
        )}
        {this.state.isConfirmModalOpen && (
          <ConfirmWithholdingModal
            isOpen={this.state.isConfirmModalOpen}
            hideModal={this.closeConfirmModal}
            withholdingSlipYear={this.state.withholdingSlipYear}
            employeeId={this.state.employeeId}
            canRelease={this.state.canRelease}
            releaseDate={this.state.releaseDate}
            changedPayOrBonus={this.state.changed_pay_or_bonus}
          />
        )}
        {this.state.isUnconfirmModalOpen && (
          <UnconfirmWithholdingModal
            isOpen={this.state.isUnconfirmModalOpen}
            hideModal={this.closeUnconfirmModal}
            withholdingSlipYear={this.state.withholdingSlipYear}
            employeeId={this.state.employeeId}
          />
        )}
      </div>
    );
  }
}

export default connect()(ReportWithholdingSlipsTable);
