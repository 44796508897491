import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'jbc-front/components/Button';
import { UTF_8, WINDOWS_31J } from 'src/constants/Generals';
import SimpleConfirmModal from 'src/components/SimpleConfirmModal';
import { downloadCsv } from 'src/reducers/flat_tax_reductions/csv';

import styles from './DownloadFlatTaxReductionCsvButton.scss';

const ENCODE_OPTIONS = [{ value: UTF_8, label: 'UTF-8(推奨)' }, { value: WINDOWS_31J, label: 'Shift_JIS' }];
const DOWNLOAD_TARGET = [{ value: 'search', label: '検索絞込中の従業員' }, { value: 'all', label: '全従業員' }];

const ModalBody = ({ encoding, setEncoding, target, setTarget }) => (
  <>
    <div className={styles.label}>ファイル形式を選択</div>
    <div style={{ 'margin-bottom': '20px' }}>
      {ENCODE_OPTIONS.map(option => (
        <label className={styles.inputLabel}>
          <input
            className="m-radio-input"
            type="radio"
            value={option.value}
            checked={encoding === option.value}
            onChange={e => setEncoding(e.target.value)}
          />
          <span className="m-radio-parts">{option.label}</span>
        </label>
      ))}
    </div>
    <div className={styles.note}>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。</div>

    <div className={styles.label}>ダウンロード対象選択</div>
    <div style={{ 'margin-bottom': '20px' }}>
      {DOWNLOAD_TARGET.map(option => (
        <label className={styles.inputLabel}>
          <input
            className="m-radio-input"
            type="radio"
            value={option.value}
            checked={target === option.value}
            onChange={e => setTarget(e.target.value)}
          />
          <span className="m-radio-parts">{option.label}</span>
        </label>
      ))}
    </div>
    <div className={styles.note}>
      検索絞込中の従業員：一覧で検索絞込した従業員の定額減税情報をダウンロードします。<br />
      全従業員：定額減税対象の全従業員の情報をダウンロードします。
    </div>
  </>
);

const DownloadFlatTaxReductionCsvButton = ({ text = '', header = text, mode, query }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [encoding, setEncoding] = useState(UTF_8);
  const [target, setTarget] = useState('search');
  const dispatch = useDispatch();
  const onClick = () => {
    downloadCsv(dispatch, encoding, target, query, mode);
    setIsOpen(false);
  };

  return (
    <>
      <Button as={'button'} style={{ 'margin-right': '20px' }} radius disabled={false} onClick={() => setIsOpen(true)}>
        {text || header}
      </Button>
      {isOpen && (
        <SimpleConfirmModal
          header={header}
          body={<ModalBody {...{ encoding, setEncoding, target, setTarget }} />}
          isOpen={isOpen}
          hideModal={() => setIsOpen(false)}
          onClick={onClick}
          confirmBtnText={'ダウンロード'}
        />
      )}
    </>
  );
};

export { DownloadFlatTaxReductionCsvButton };
// ↓ sprint132 以降に消す
export default DownloadFlatTaxReductionCsvButton;
