import React from 'react';

import { isEmpty, groupBy, sumBy } from 'lodash';
import { Section } from 'jbc-front/components/Form';
import { getOptionLabel, numToStr, display, currency } from 'src/utils/Utils';
import DefaultValue from './DefaultValue';
import CommutingByCarShow from '../containers/CommutingByCarShowContainer';
import tableStyles from '../styles/GeneralTableInfo.scss';

const calculateTotalAmount = (payments, key, multiplier = 1) => {
  if (!payments) return 0;
  return sumBy(payments, item => (item[key] || 0) * multiplier);
};
const formatAmount = amount => currency(display(numToStr(amount)));

const CommutingByTrainShow = ({ commutingItem, paymentDurationUnits, paymentDurationTypes }) => (
  <div>
    <Section title="電車">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>乗駅：</th>
            <td>{display(commutingItem.firstStop)}</td>
          </tr>
          <tr>
            <th>降駅：</th>
            <td>{display(commutingItem.lastStop)}</td>
          </tr>
          <tr>
            <th>経由地：</th>
            <td>{display(commutingItem.middleStop)}</td>
          </tr>
          <tr>
            <th>支給単位：</th>
            <td>{display(getOptionLabel(commutingItem.paymentDurationUnit)(paymentDurationUnits))}</td>
          </tr>
          <tr>
            <th>支給月：</th>
            <td>{display(getOptionLabel(commutingItem.paymentDurationType)(paymentDurationTypes))}</td>
          </tr>
          <tr>
            <th>片道運賃：</th>
            <td>{currency(numToStr(commutingItem.oneWayFare))}</td>
          </tr>
          <tr>
            <th>定期代：</th>
            <td>{currency(numToStr(commutingItem.paymentAmount))}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const CommutingByBusShow = ({ commutingItem, paymentDurationUnits, paymentDurationTypes }) => (
  <div>
    <Section title="バス">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>乗車停留所：</th>
            <td>{display(commutingItem.firstStop)}</td>
          </tr>
          <tr>
            <th>降車停留所：</th>
            <td>{display(commutingItem.lastStop)}</td>
          </tr>
          <tr>
            <th>経由地：</th>
            <td>{display(commutingItem.middleStop)}</td>
          </tr>
          <tr>
            <th>支給単位：</th>
            <td>{display(getOptionLabel(commutingItem.paymentDurationUnit)(paymentDurationUnits))}</td>
          </tr>
          <tr>
            <th>支給月：</th>
            <td>{display(getOptionLabel(commutingItem.paymentDurationType)(paymentDurationTypes))}</td>
          </tr>
          <tr>
            <th>片道運賃：</th>
            <td>{currency(numToStr(commutingItem.oneWayFare))}</td>
          </tr>
          <tr>
            <th>定期代：</th>
            <td>{currency(numToStr(commutingItem.paymentAmount))}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const CommutingItem = ({ commutingItem, paymentDurationUnits, paymentDurationTypes }) => (
  <div>
    {commutingItem.meansOfCommunication === 'train' && (
      <CommutingByTrainShow {...{ commutingItem, paymentDurationUnits, paymentDurationTypes }} />
    )}
    {commutingItem.meansOfCommunication === 'bus' && (
      <CommutingByBusShow {...{ commutingItem, paymentDurationUnits, paymentDurationTypes }} />
    )}
    {commutingItem.meansOfCommunication === 'car' && (
      <CommutingByCarShow {...{ commutingItem, paymentDurationUnits, paymentDurationTypes }} />
    )}
  </div>
);

const CommutingsSectionShow = ({
  employee,
  employeeCommutings,
  paymentDurationUnits,
  meansOfCommunications,
  paymentDurationTypes
}) => {
  const groupedPaymentDurationUnit = groupBy(employeeCommutings, 'paymentDurationUnit');

  if (employee.meansOfCommunication)
    return (
      <div>
        <div>
          <table className={tableStyles.mTablePersonal}>
            <tbody>
              <tr>
                <th>通勤手段：</th>
                <td>{display(getOptionLabel(employee.meansOfCommunication)(meansOfCommunications))}</td>
              </tr>
              {employee.meansOfCommunication !== 'walking' && (
                <tr>
                  <th>支給額合計：</th>
                  <td>
                    {!isEmpty(groupedPaymentDurationUnit) ? (
                      <>
                        {groupedPaymentDurationUnit.per_month && (
                          <div>
                            1ヶ月：{' '}
                            {formatAmount(calculateTotalAmount(groupedPaymentDurationUnit.per_month, 'paymentAmount'))}
                          </div>
                        )}
                        {groupedPaymentDurationUnit.per_3months && (
                          <div>
                            3ヶ月：{' '}
                            {formatAmount(
                              calculateTotalAmount(groupedPaymentDurationUnit.per_3months, 'paymentAmount')
                            )}
                          </div>
                        )}
                        {groupedPaymentDurationUnit.per_6months && (
                          <div>
                            6ヶ月：{' '}
                            {formatAmount(
                              calculateTotalAmount(groupedPaymentDurationUnit.per_6months, 'paymentAmount')
                            )}
                          </div>
                        )}
                        {groupedPaymentDurationUnit.per_working_days && (
                          <div>
                            出勤日数に応じて毎月支給：{' '}
                            {formatAmount(
                              calculateTotalAmount(
                                groupedPaymentDurationUnit.per_working_days.filter(
                                  payment => payment.meansOfCommunication === 'car'
                                ),
                                'paymentAmount'
                              ) +
                                calculateTotalAmount(
                                  groupedPaymentDurationUnit.per_working_days.filter(
                                    payment => payment.meansOfCommunication !== 'car'
                                  ),
                                  'oneWayFare',
                                  2
                                )
                            )}×労働日数
                          </div>
                        )}
                      </>
                    ) : (
                      <>{display(0)}</>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {employee.meansOfCommunication !== 'walking' && (
            <div className="u-mt30">
              {employeeCommutings.map(item => (
                <CommutingItem
                  commutingItem={item}
                  paymentDurationUnits={paymentDurationUnits}
                  paymentDurationTypes={paymentDurationTypes}
                  key={item.id}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  return <DefaultValue />;
};

export default CommutingsSectionShow;
