import React from 'react';

import { EMPLOYEE_ATTENDANCE_EDIT } from 'src/constants/EndpointUrls';
import { SearchReactSelect } from 'src/components';
import { redirectTo, concatParamsToUrl } from 'src/utils/Http';
import TargetDetailPeriod from './TargetDetailPeriod';
import styles from './AllAttendances.scss';

const redirectToPayDetail = atdId => {
  const buildUrl = EMPLOYEE_ATTENDANCE_EDIT + window.location.search;
  redirectTo(concatParamsToUrl(buildUrl, { id: atdId }));
};

const AllAttendances = props => {
  const { allAttendances = [], employeeAttendanceId, attendanceStartDate, attendanceEndDate, paymentDate } = props;

  return (
    <div>
      <div className={styles.center}>
        <SearchReactSelect
          value={employeeAttendanceId}
          options={allAttendances}
          onChange={attendance => redirectToPayDetail(attendance.value)}
          width={350}
          size="large"
          cache={false}
        />
      </div>
      <TargetDetailPeriod {...{ attendanceStartDate, attendanceEndDate, paymentDate }} />
    </div>
  );
};

export default AllAttendances;
