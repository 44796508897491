import { connect } from 'react-redux';
import { fetchCsvAvailableFields } from 'src/reducers/fetchSelectOptions';
import { setItem } from 'src/reducers/common/itemReducer';
import compose from 'lodash/fp/compose';
import { REDUCER_NAME } from 'src/reducers/clients/csvFormats';
import ClientCsvEmployeeFormatEdit from '../ClientCsvEmployeeFormatEdit';

const mapDispatchToProps = (dispatch, ownProps) => ({
  init: () => {
    const values = ownProps.values;
    dispatch(setItem(REDUCER_NAME, values));
    dispatch(fetchCsvAvailableFields());
  }
});

export default compose(connect(null, mapDispatchToProps))(ClientCsvEmployeeFormatEdit);
