import React from 'react';
import { connect } from 'react-redux';
import { Field } from 'redux-form';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { arrowRenderer } from 'jbc-front/components/Form';

import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from 'src/components/BonusSelectBox.scss';

class BonusSelectBox extends React.Component {
  render() {
    const { bonuses } = this.props;

    const confirmedLabeledBonuses = bonuses.map(bonus => {
      const label =
        bonus.isConfirmed === true ? (
          <span>
            <span>{bonus.label}</span>
            <span id="confirmed-label">※確定済</span>
          </span>
        ) : (
          bonus.label
        );
      return { ...bonus, value: bonus.value, label };
    });

    return (
      <div className={styles.container}>
        <div className={styles.BonusGroupsWrapper}>
          <Field
            name="clientBonusId"
            component={SearchSelectBox}
            options={confirmedLabeledBonuses}
            arrowRenderer={arrowRenderer}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bonuses: getSelectOptions(state, 'bonuses')
});
export default connect(mapStateToProps)(BonusSelectBox);
