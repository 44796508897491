import React, { useEffect } from 'react';
import { FormSection, FieldArray } from 'redux-form';
import moment from 'moment';
import axios from 'axios';
import _ from 'lodash';
import sortBy from 'lodash/sortBy';

import { ArrowDoublePrev, ArrowDoubleNext } from 'jbc-front/components/icons';
import { BoxDouble, TextField, Label } from 'jbc-front/components/Form';
import * as Urls from 'src/constants/EndpointUrls';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import image from 'src/features/Employees/images/img-insurance-amount.png';
import styles from './EmployeeStandardMonthliesSection.scss';
import StandardMonthlyAmountTable from '../containers/StandardMonthlyAmountTableContainer';
import ManualInsuranceSettingSection from '../containers/ManualInsuranceSettingSectionContainer';

export const MONTHS_COLORS = [
  '#61d4e2',
  '#40a1ff',
  '#92d944',
  '#84f1ff',
  '#85c3ff',
  '#c5f492',
  '#29a6b5',
  '#2e7fce',
  '#65a61c',
  '#108391',
  '#0d5499',
  '#147f00'
];
const DATE_FORMAT = 'YYYY-MM-DD';
const MONTHS = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const copyFields = [
  'healthInsuranceGrade',
  'isLastGradeOfHealthInsurance',
  'isLastGradeOfPensionInsurance',
  'monthlyCompensationHealthInsuranceAmount',
  'monthlyRemunerationPensionInsuranceAmount',
  'pensionInsuranceGrade',
  'isHealthInsuranceManualInput',
  'healthInsuranceEmployeeAmount',
  'healthInsuranceEmployerAmount',
  'isNursingInsuranceManualInput',
  'nursingInsuranceEmployeeAmount',
  'nursingInsuranceEmployerAmount',
  'isPensionInsuranceManualInput',
  'pensionInsuranceEmployeeAmount',
  'pensionInsuranceEmployerAmount'
];
const isSelectingTime = (selectingTime, time) => selectingTime === time;
const isValidStandard = standard =>
  !!(
    (standard.monthlyCompensationHealthInsuranceAmount && standard.monthlyRemunerationPensionInsuranceAmount) ||
    (standard.isHealthInsuranceManualInput === 'true' &&
      standard.isNursingInsuranceManualInput === 'true' &&
      standard.isPensionInsuranceManualInput === 'true')
  );
const getLastestStandard = (fields, time) => {
  const fieldsClone = [...fields.getAll()];
  // Find the closest standard in fields that having settingStartDate <= given time
  // Example: fields = [{settingStartDate: '2015-03-01'}, {settingStartDate: '2015-07-01'}]
  // and time = "2015-05-01" then {settingStartDate: '2015-03-01'} is returned
  return sortBy(fieldsClone, a => a.settingStartDate) // sort fields by settingStartDate asc
    .reverse() // reverse fields by settingStartDate desc
    .find(i => i.settingStartDate <= time); // get first standard that having settingStartDate <= given time
};

const isSame = (standard1, standard2) => {
  const diffFields = [
    'monthlyCompensationHealthInsuranceAmount',
    'monthlyRemunerationPensionInsuranceAmount',
    'isHealthInsuranceManualInput',
    'healthInsuranceEmployeeAmount',
    'healthInsuranceEmployerAmount',
    'isNursingInsuranceManualInput',
    'nursingInsuranceEmployeeAmount',
    'nursingInsuranceEmployerAmount',
    'isPensionInsuranceManualInput',
    'pensionInsuranceEmployeeAmount',
    'pensionInsuranceEmployerAmount'
  ];
  return diffFields.every(field => standard1[field] == standard2[field]); // eslint-disable-line eqeqeq
};

const isExistingInFields = (fields, time) =>
  !!fields
    .getAll()
    .map(standard => standard.settingStartDate === time)
    .find(item => !!item);

const pushIfNotExist = (fields, time) => {
  if (!isExistingInFields(fields, time)) {
    const latestStandard = getLastestStandard(fields, time) || {
      isHealthInsuranceManualInput: 'false',
      isNursingInsuranceManualInput: 'false',
      isPensionInsuranceManualInput: 'false'
    };
    const latestStandardClone = {};
    copyFields.map(attr => {
      latestStandardClone[attr] = latestStandard[attr];
      return true;
    });
    const cloneStandard = {
      ...latestStandardClone,
      settingStartDate: time,
      isNew: true,
      inputType: 'clicking'
    };
    fields.push(cloneStandard);
  }
};

const removeInputTypeClickingStandard = fields =>
  fields.map((__, index, _fields) => {
    const item = _fields.get(index);
    if (!!item.isNew && item.inputType === 'clicking') {
      _fields.remove(index);
    }
    return true;
  });

const selectingTime = (year, month) =>
  moment()
    .year(year)
    .month(month)
    .startOf('month')
    .format(DATE_FORMAT);

const MonthDivTag = ({
  selectingYear,
  selectingMonth,
  month,
  fields,
  changeSelectingMonth,
  colorCount,
  employeeId,
  standardMonthlyAmounts
}) => {
  let tagClass = styles.tag;
  let setClass;
  const time = selectingTime(selectingYear, month - 1);
  const selectingTimeValue = selectingTime(selectingYear, selectingMonth - 1);
  const hasLatestStandardStyle = {
    position: 'absolute',
    content: '',
    bottom: '-8px',
    left: '-1px',
    borderRadius: '4px',
    width: '32px',
    height: '3px',
    backgroundColor: MONTHS_COLORS[colorCount % MONTHS_COLORS.length]
  };

  if (isSelectingTime(selectingTimeValue, time)) {
    tagClass = styles.tagSelected;
  }
  // Timeの前に入力済みの項目がある
  const latestStandard = getLastestStandard(fields, time) || {};
  if (isValidStandard(latestStandard)) {
    setClass = styles.tagSetted;
  } else {
    setClass = styles.tagNotset;
  }

  return (
    <div
      role="presentation"
      className={+month === +selectingMonth ? `${tagClass} ${setClass}` : `${tagClass} ${setClass} u-cur-pointer`}
      onClick={() => {
        // Click to other month div
        if (+month !== +selectingMonth) {
          changeSelectingMonth(month, employeeId);
          pushIfNotExist(fields, time);
          removeInputTypeClickingStandard(fields);
        }
        standardMonthlyAmounts(selectingTime(selectingYear, month - 1));
      }}
    >
      {+month}
      <span className={styles.tagMonth}>月</span>
      {isValidStandard(latestStandard) && <span style={hasLatestStandardStyle} />}
    </div>
  );
};

const EmployeeStandardMonthlies = props => {
  const {
    fields,
    selectingMonth,
    selectingYear,
    changeSelectingMonth,
    changeSelectingYear,
    minimumYear,
    standardMonthlyAmounts,
    employeeId
  } = props;
  const canSelectPrevYear = selectingYear > minimumYear;
  const colorCounts = [];
  const settings = sortBy(fields.getAll(), setting => setting.settingStartDate);
  settings.forEach(setting => {
    if (isValidStandard(setting) && colorCounts.findIndex(oldSetting => isSame(oldSetting, setting)) < 0) {
      colorCounts.push(setting);
    }
  });
  // 選択された月と以降の月の標準報酬月額情報をすべて自動入力する
  // 従業員詳細情報＞標準報酬月額の自動入力が仕様と異なる #2301
  const fillAllStandardAfterCurrentSettingTime = (currentFields, autofill, settingStartDate) => attrs => {
    _.forEach(currentFields, (_item, idx) => {
      if (currentFields.get(idx).settingStartDate < settingStartDate) {
        return;
      }
      _.forEach(attrs, (value, key) => {
        autofill(`employeeStandardMonthlies[${idx}].${key}`, value);
      });
    });
  };

  useEffect(() => {
    const time = selectingTime(selectingYear, selectingMonth - 1);
    pushIfNotExist(fields, time);
  }, []);

  return (
    <div className="u-mt20">
      <div className={styles.select}>
        <div
          className={styles.prev}
          role="presentation"
          disabled={!canSelectPrevYear}
          onClick={() => {
            const time = selectingTime(selectingYear - 1, selectingMonth - 1);
            removeInputTypeClickingStandard(fields);
            pushIfNotExist(fields, time);
            changeSelectingYear(selectingYear - 1, employeeId);
          }}
        >
          {canSelectPrevYear && <ArrowDoublePrev size={12} />}
        </div>
        <div className={styles.year}>{selectingYear}</div>
        <div className={styles.month}>
          {MONTHS.map(month => {
            const yearMonth = moment()
              .year(selectingYear)
              .month(month)
              .date(1);
            const latest = _.findLast(settings, setting => yearMonth.isAfter(setting.settingStartDate, 'month'));
            let colorCount = 0;
            if (latest) {
              colorCount = colorCounts.findIndex(insurance => insurance && isSame(insurance, latest));
            }

            return (
              <MonthDivTag
                key={month}
                selectingYear={selectingYear}
                selectingMonth={selectingMonth}
                month={month}
                fields={fields}
                colorCount={colorCount}
                changeSelectingMonth={changeSelectingMonth}
                employeeId={employeeId}
                standardMonthlyAmounts={standardMonthlyAmounts}
              />
            );
          })}
        </div>
        <div
          className={styles.next}
          role="presentation"
          onClick={() => {
            const time = selectingTime(selectingYear + 1, selectingMonth - 1);
            removeInputTypeClickingStandard(fields);
            pushIfNotExist(fields, time);
            changeSelectingYear(selectingYear + 1, employeeId);
          }}
        >
          <ArrowDoubleNext size={12} />
        </div>
      </div>
      <p className={styles.selectCaption}>標準報酬月額が同じ月は同色で表示されます</p>
      <div className={styles.selectSection}>
        {fields.map((item, index) => {
          const standard = fields.get(index);
          const selectingTimeValue = selectingTime(selectingYear, selectingMonth - 1);
          const showHideClassName = standard.settingStartDate === selectingTimeValue ? null : styles.hide;

          return (
            <div className={showHideClassName} key={item}>
              <FormSection name={item}>
                <StandardMonthlyAmountTable
                  selectingTimeValue={selectingTimeValue}
                  standardItem={item}
                  index={index}
                  settingStartDate={standard.settingStartDate}
                  fields={fields}
                  syncFields={copyFields}
                  fillAllStandardAfterCurrentSettingTime={fillAllStandardAfterCurrentSettingTime}
                />

                <div className="u-mt30">
                  <BoxDouble>
                    <TextField
                      label="標準報酬月額（健康保険）"
                      name="monthlyCompensationHealthInsuranceAmount"
                      validate={isMoneyNumber}
                      disabled
                    />

                    <TextField
                      label="標準報酬月額（厚生年金保険）"
                      name="monthlyRemunerationPensionInsuranceAmount"
                      validate={isMoneyNumber}
                      disabled
                    />
                  </BoxDouble>
                </div>

                <ManualInsuranceSettingSection
                  selectingTimeValue={selectingTimeValue}
                  item={item}
                  index={index}
                  settingStartDate={standard.settingStartDate}
                  fields={fields}
                  syncFields={copyFields}
                  fillAllStandardAfterCurrentSettingTime={fillAllStandardAfterCurrentSettingTime}
                />
              </FormSection>
            </div>
          );
        })}
      </div>
    </div>
  );
};

class EmployeeStandardMonthliesSection extends React.Component {
  constructor(props) {
    super(props);
    const { standardYear, standardMonth } = this.props;

    this.state = {
      selectingMonth: standardMonth || moment().month() + 1,
      selectingYear: standardYear || moment().year(),
      paymentDate: ''
    };
  }

  componentDidMount() {
    axios
      .get(Urls.EMPLOYEE_SEARCH_PAYMENT_DATE, {
        params: {
          id: this.props.employeeId,
          year: this.state.selectingYear,
          month: this.state.selectingMonth
        }
      })
      .then(response => {
        this.setState({
          ...this.state,
          selectingMonth: this.state.selectingMonth,
          selectingYear: this.state.selectingYear,
          paymentDate: response.data.payload.paymentDate
        });
      })
      .catch(error => console.log('error', error));
  }

  changeSelectingMonth = async (month, employeeId) => {
    await axios
      .get(Urls.EMPLOYEE_SEARCH_PAYMENT_DATE, {
        params: {
          id: employeeId,
          year: this.state.selectingYear,
          month
        }
      })
      .then(response => {
        this.setState({ ...this.state, selectingMonth: month, paymentDate: response.data.payload.paymentDate });
      })
      .catch(error => console.log('error', error));
  };

  changeSelectingYear = async (year, employeeId) => {
    await axios
      .get(Urls.EMPLOYEE_SEARCH_PAYMENT_DATE, {
        params: {
          id: employeeId,
          year,
          month: this.state.selectingMonth
        }
      })
      .then(response => {
        this.setState({ ...this.state, selectingYear: year, paymentDate: response.data.payload.paymentDate });
      })
      .catch(error => console.log('error', error));
  };

  buildTagText = time => {
    const timeToText = moment(time).format('YYYY年M月');

    const paymentTimeText = this.state.paymentDate;
    return `${timeToText}（${paymentTimeText}）`;
  };

  render() {
    const { selectingYear, selectingMonth } = this.state;
    const { standardMonthlyMinimumYear, isNotJoinedHealthAndPension, removeAfterSettingDate } = this.props;
    const selectingTimeArrowStyle = {
      position: 'absolute',
      content: '',
      bottom: '-6px',
      left: '50%',
      width: '10px',
      height: '10px',
      backgroundColor: '#e6e6e6',
      transform: 'rotate(45deg)'
    };
    selectingTimeArrowStyle.left = +selectingMonth * 38 - 30;

    const minimumYear = standardMonthlyMinimumYear;

    return (
      <div>
        <div className="u-mt20">
          <Label
            text="標準報酬月額"
            noteWidth={370}
            note={
              <div>
                <ul className={styles.icons}>
                  <li>
                    <span className={styles.tagWrap}>
                      <span className={styles.tagSelected}>
                        4<span className={styles.tagMonth}>月</span>
                      </span>
                    </span>
                    <span className={styles.tagCaption}>選択中の月</span>
                  </li>
                  <li>
                    <span className={styles.tagSetted}>
                      4<span className={styles.tagMonth}>月</span>
                    </span>
                    <span className={styles.tagCaption}>入力済みの月</span>
                  </li>
                  <li>
                    <span className={styles.tagNotset}>
                      4<span className={styles.tagMonth}>月</span>
                    </span>
                    <span className={styles.tagCaption}>未入力の月</span>
                  </li>
                </ul>
                <div className={styles.exampleWrap}>
                  <div className={styles.exampleImg}>
                    <img src={image} alt="標準報酬月額イメージ" />
                  </div>
                  <p className={styles.exampleText}>
                    標準報酬月額が同じ月は同色で表示されます<br />画像例:
                    9月（10月支給分）から新しい標準報酬月額となります。
                  </p>
                </div>
              </div>
            }
          />
        </div>
        {isNotJoinedHealthAndPension ? (
          <p>健康保険・厚生年金保険が未加入です。</p>
        ) : (
          <div>
            <div className="u-mt20">
              <div className={styles.selectingTime}>
                {this.buildTagText(selectingTime(selectingYear, selectingMonth - 1))}
                <span className={styles.selectingTimeArrow} style={selectingTimeArrowStyle} />
              </div>
            </div>
            <FieldArray
              name="employeeStandardMonthlies"
              component={EmployeeStandardMonthlies}
              removeAfterSettingDate={removeAfterSettingDate}
              selectingMonth={this.state.selectingMonth}
              selectingYear={this.state.selectingYear}
              changeSelectingMonth={this.changeSelectingMonth}
              changeSelectingYear={this.changeSelectingYear}
              minimumYear={minimumYear}
              employeeId={this.props.employeeId}
              standardMonthlyAmounts={this.props.standardMonthlyAmounts}
            />
          </div>
        )}
      </div>
    );
  }
}

export default EmployeeStandardMonthliesSection;
