import React from 'react';
import { connect } from 'react-redux';
import { arrayMove } from 'react-sortable-hoc';

import {
  getClientAllowanceItemList,
  setClientAllowanceItemList,
  saveChangedDisplayOrder
} from 'src/reducers/clients/allowanceItems';
import { CLIENT_ALLOWANCE_ITEMS, CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import ActionButton from 'jbc-front/components/ActionButton';
import { MinusCircle, Strage } from 'jbc-front/components/icons';
import SortableAllowanceItemTable from './SortableAllowanceItemTable';
import styles from './SortableAllowanceItemsComponent.scss';

class SortableAllowanceItemsComponent extends React.Component {
  onSortEnd = ({ oldIndex, newIndex }) => {
    const { allowanceItems } = this.props;
    this.props.setAllowanceItemList(arrayMove([...allowanceItems], oldIndex, newIndex));
  };

  render() {
    const { allowanceItems, saveDisplayOrder } = this.props;

    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">勤怠項目並べ替え</h1>
        </div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
              <BreadCrumb.Item label="勤怠項目並べ替え" />
            </BreadCrumb>
          </div>
          <div className={styles.action}>
            <ActionButton onClick={saveDisplayOrder} icon={<Strage size={15} />}>
              保存
            </ActionButton>
            <ActionButton as="a" href={CLIENT_ALLOWANCE_ITEMS} icon={<MinusCircle size={15} />}>
              キャンセル
            </ActionButton>
          </div>
          <SortableAllowanceItemTable
            allowanceItems={allowanceItems}
            onSortEnd={this.onSortEnd}
            helperClass={styles.dragging}
          />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    allowanceItems: getClientAllowanceItemList(state)
  }),
  dispatch => ({
    setAllowanceItemList: allowanceItems => {
      dispatch(setClientAllowanceItemList(allowanceItems));
    },
    saveDisplayOrder: () => {
      dispatch(saveChangedDisplayOrder());
    }
  })
)(SortableAllowanceItemsComponent);
