import { connect } from 'react-redux';
import { getAllowanceFormValues } from 'src/reducers/clients/allowances';
import { getSelectOptions } from 'src/reducers/selectOptions';
import selector from 'src/utils/Utils';
import FormulaSettings from '../components/FormulaSettings';

const getFormulaFieldName = state => {
  const payrollType = selector(getAllowanceFormValues(state), 'payrollType');
  let formulaFieldName = '';

  switch (payrollType) {
    case 'monthly':
      formulaFieldName = 'formulaMonthly';
      break;
    case 'daily':
      formulaFieldName = 'formulaDaily';
      break;
    case 'hourly':
      formulaFieldName = 'formulaHourly';
      break;
    default:
      throw new Error('payrollType must be one of ClientPayrollRulesGroup.payroll_types');
  }
  return formulaFieldName;
};

// 編集中の項目はプルダウンに表示しない
const getSelectablePaymentItems = state => {
  const id = selector(getAllowanceFormValues(state), 'id');
  return getSelectOptions(state, 'allowanceItemsPayment').filter(x => x.value !== id);
};

const mapStateToProps = state => ({
  allowanceItemTypes: getSelectOptions(state, 'allowanceItemTypes'),
  allowanceItemsNumberOfDays: getSelectOptions(state, 'allowanceItemsNumberOfDays'),
  allowanceItemsTime: getSelectOptions(state, 'allowanceItemsTime'),
  allowanceItemsNumberOfTimes: getSelectOptions(state, 'allowanceItemsNumberOfTimes'),
  allowanceItemsPayment: getSelectablePaymentItems(state),
  allowanceItemsEmployee: getSelectOptions(state, 'allowanceItemsEmployee'),
  clientUnitPrices: getSelectOptions(state, 'clientUnitPrices'),
  rounding1YenType: getSelectOptions(state, 'clientAllowanceRounding'),
  roundingDigit: getSelectOptions(state, 'clientAllowanceRoundingDigit'),
  currentFormula: selector(getAllowanceFormValues(state), getFormulaFieldName(state)) || '',
  checkUpperLimit: selector(getAllowanceFormValues(state), 'isUpperLimit') || false,
  checkLowerLimit: selector(getAllowanceFormValues(state), 'isLowerLimit') || false,
  formulaFieldName: getFormulaFieldName(state)
});

export default connect(mapStateToProps)(FormulaSettings);
