import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { OFFICE_FORM } from 'src/constants/FormNames';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { setExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME, editOffice, isMainOffice } from 'src/reducers/offices/offices';

import EditOffice from '../components/EditOffice';

const EditOfficeWrapper = props => {
  const { initialValues, dispatch } = props;
  useMemo(() => dispatch(setExtras(REDUCER_NAME, initialValues.payload)));

  return <EditOffice {...props} />;
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    office: ownProps.values.office,
    clientLabourConsultant: ownProps.values.clientLabourConsultant,
    clientBanks: ownProps.values.clientBanks,
    socialInsurance: { settingStartDate: moment().format('YYYY-MM-DD') },
    healthInsurance: ownProps.values.insurances.health.current,
    pensionInsurance: ownProps.values.insurances.pension.current,
    labourInsurance: { settingStartDate: moment().format('YYYY-MM-DD') },
    labourInsuranceContactInfo: ownProps.values.insurances.accident.current.contactInfo,
    employmentInsurance: ownProps.values.insurances.employment.current,
    accidentInsurance: ownProps.values.insurances.accident.current,
    mainOfficeContactInfo: ownProps.values.mainOffice,
    payload: ownProps.values
  },
  isInitialized: ownProps.values.isInitialized,
  isOnlyMainOffice: ownProps.values.isOnlyMainOffice,
  isMainOffice: isMainOffice(ownProps.values.office.officeType),
  errors: getItemErrors(REDUCER_NAME, state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(editOffice(data))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: OFFICE_FORM
  })
)(EditOfficeWrapper);
