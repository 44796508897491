import { connect } from 'react-redux';
import _ from 'lodash';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { pollFetchPayReportJobStatus, REDUCER_NAME } from 'src/reducers/employees/employeePays';
import { getExtras } from 'src/reducers/common/listReducer';
import DownloadPayReports from '../components/DownloadPayReports';

const mapStateToProps = (state, ownProps) => ({
  downloadObj:
    _.get(_.find(getSelectOptions(state, 'uniquePaymentDates'), { value: ownProps.yearMonth }), 'downloadObj') || {},
  isReportJobPolling: getExtras(REDUCER_NAME, state).isReportJobPolling
});

const mapDispatchToProps = dispatch => ({
  pollFetchReportJobStatus: (downloadObj, yearMonth) => {
    dispatch(pollFetchPayReportJobStatus(downloadObj, yearMonth));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadPayReports);
