import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { CLIENT_ALLOWANCE_ITEM_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, createAllowanceItem } from 'src/reducers/clients/allowanceItems';
import { getItem, setItem, getItemErrors } from 'src/reducers/common/itemReducer';
import { arrayToCheckBoxObject } from 'src/utils/Utils';
import { CLIENT_SETTINGS, CLIENT_ALLOWANCE_ITEMS } from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import AllowanceItemEditingForm from './components/AllowanceItemEditingForm';

let NewAllowanceItemForm = reduxForm({
  form: CLIENT_ALLOWANCE_ITEM_FORM
})(AllowanceItemEditingForm);

const mapStateToProps = (state, ownProps) => ({
  errors: getItemErrors(REDUCER_NAME, state),
  initialValues: {
    ...getItem(REDUCER_NAME, state),
    payrollRulesGroups: ownProps.formatedPayrollGroup
  }
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(createAllowanceItem(data));
  }
});

NewAllowanceItemForm = connect(mapStateToProps, mapDispatchToProps)(NewAllowanceItemForm);

const NewClientAllowanceItem = ({ dispatch, values }) => {
  const { clientAllowanceItem, checkedOnPayrollRulesGroups } = values;
  dispatch(setItem(REDUCER_NAME, clientAllowanceItem));

  const formatedPayrollGroup = arrayToCheckBoxObject(checkedOnPayrollRulesGroups);
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">勤怠項目設定 追加登録</h1>
      </div>
      <div className="l-wrap-xs">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="勤怠項目設定一覧" path={CLIENT_ALLOWANCE_ITEMS} />
            <BreadCrumb.Item label="勤怠項目設定 追加登録" />
          </BreadCrumb>
        </div>
        <NewAllowanceItemForm formatedPayrollGroup={formatedPayrollGroup} />
      </div>
    </div>
  );
};

export default connect()(NewClientAllowanceItem);
