import Button from 'jbc-front/components/Button';
import React, { useState } from 'react';
import EgovBalloon from 'src/components/Egov/EgovBaloon';
import styles from './EgovButton.scss';

const EgovButton = props => {
  const { isLmsActive, isCsvDataExist, openCsvModal, openEgovModal } = props;
  const [isEgovBaloonOpen, clickEgovBaloon] = useState(false);

  return (
    <div className={styles.wrap}>
      <Button
        className={styles.eGovButton}
        primary
        widthWide
        disabled={!isCsvDataExist}
        onClick={() => clickEgovBaloon(true)}
      >
        電子・電子媒体申請
      </Button>
      {isEgovBaloonOpen && (
        <EgovBalloon
          hide={() => clickEgovBaloon(false)}
          openCsvModal={openCsvModal}
          isLmsActive={isLmsActive}
          openEgovModal={openEgovModal}
        />
      )}
    </div>
  );
};

export default EgovButton;
