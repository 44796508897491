import React from 'react';

import tableStyles from 'src/features/Employees/styles/GeneralTableInfo.scss';
import { Item } from 'src/components/PrimaryPanel';
import { Dl, Dd, Dt } from 'src/components/SecondaryPanel';
import { HYPHEN_CHARACTER } from 'src/constants/Generals';

const LabourInsuranceSection = ({ accidentInsurance, employmentInsurance }) => (
  <div>
    <Item className={tableStyles.mTablePersonal}>
      <Dl>
        <Dt>労働保険番号:</Dt>
        <Dd>{accidentInsurance.joinedInsuranceCode ? accidentInsurance.joinedInsuranceCode : HYPHEN_CHARACTER}</Dd>
      </Dl>
      <Dl>
        <Dt>労災保険の業種:</Dt>
        <Dd>{accidentInsurance.insuranceTypeStr}</Dd>
      </Dl>
      <Dl>
        <Dt>雇用保険番号:</Dt>
        <Dd>{employmentInsurance.joinedInsuranceCode ? employmentInsurance.joinedInsuranceCode : HYPHEN_CHARACTER}</Dd>
      </Dl>
      <Dl>
        <Dt>管轄のハローワーク:</Dt>
        <Dd>{employmentInsurance.governmentOffice}</Dd>
      </Dl>
      <Dl>
        <Dt>雇用保険の業種:</Dt>
        <Dd>{employmentInsurance.insuranceTypeStr}</Dd>
      </Dl>
    </Item>
  </div>
);

export default LabourInsuranceSection;
