import React from 'react';
import { autofill, Field } from 'redux-form';
import _ from 'lodash';
import PayjpCheckout from 'jbc-front/components/PayjpCheckout';
import { connect } from 'react-redux';

import { Error } from 'jbc-front/components/Form';
import { isRequired } from 'src/utils/CustomValidators';
import { setGlobalErrors } from 'src/reducers/global';
import styles from './EditClientPaidPlan.scss';

export const paymentMethodTypes = [
  { label: '請求書払い(銀行・コンビニ)', value: 'bill' },
  { label: 'クレジットカード支払い', value: 'credit_card' }
];

export const CardInput = connect()(({ dispatch, disabled, formName, cardToken }) => (
  <div className={disabled ? `${styles.disablePointer} u-mb20` : 'u-mb20'}>
    <PayjpCheckout
      apiKey={process.env.PAYJP_KEY}
      submitText="カード情報を入力する"
      text="カード情報を入力する"
      onCreated={({ id, card }) => {
        dispatch(autofill(formName, 'creditInfo.cardToken', id));
        dispatch(autofill(formName, 'creditInfo._cardInfo', card));
      }}
      onFailed={(errorCode, errorResponse) => {
        dispatch(setGlobalErrors(_.get(errorResponse, 'message') || `エラーが発生しました(code: ${errorCode})`));
      }}
      previousToken={cardToken}
    />
    <Field name="cardToken" validate={isRequired} label="カード情報" component={Error} />
  </div>
));
