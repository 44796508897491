import React from 'react';
import { reduxForm } from 'redux-form';
import { Panel, Headding } from 'src/components/PrimaryPanel';
import { ErrorsSection } from 'src/components';
import { CheckboxField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { PROFILE_FORM } from 'src/constants/FormNames';

import styles from './SettingElectronicDelivery.scss';

const SettingElectronicDelivery = ({
  submitting,
  onSubmit,
  isElectronicDeliveryAgreement,
  formValues,
  isElectronicDeliveryAgreementSetting,
  isFlex
}) => {
  // 同意機能がOFFの場合はフォームを表示しない
  // ※従業員情報がないuserの場合はプロフィール画面を表示しないので考慮しない
  if (!isElectronicDeliveryAgreementSetting) {
    return <div />;
  }
  // チェックがある時だけ保存押せる
  const checked = formValues ? !formValues.isElectronicDeliveryAgreement : false;
  return (
    <div>
      <ErrorsSection errors={formValues ? formValues.errors : []} />
      <div className={styles.main}>
        <Panel>
          <Headding>給与所得等明細書の電子交付に関する同意</Headding>
          <div className={isFlex ? styles.flexbox : styles.box}>
            <div className={styles.checkbox}>
              <CheckboxField
                name="isElectronicDeliveryAgreement"
                label="ジョブカン上で給与・賞与明細・源泉徴収票を受け取ることに同意します。"
                description="ジョブカン上で給与・賞与明細・源泉徴収票を受け取る場合は、こちらの同意が必須となります。"
                disabled={isElectronicDeliveryAgreement}
              />
            </div>
            <div className={isFlex ? styles.flexbutton : styles.button}>
              <Button
                primary
                text="保存"
                onClick={() => {
                  onSubmit();
                }}
                disabled={submitting || checked || isElectronicDeliveryAgreement}
              >
                保存
              </Button>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

const SettingElectronicDeliveryForm = reduxForm({
  form: PROFILE_FORM,
  enableReinitialize: true
})(SettingElectronicDelivery);

export default SettingElectronicDeliveryForm;
