import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Panel, Headding, Item } from 'src/components/PrimaryPanel';
import { Edit } from 'jbc-front/components/icons';
import { redirectTo } from 'src/utils/Http';
import { CHANGE_EMAIL_PAGE_URL, CHANGE_PASSWORD_PAGE_URL } from 'src/constants/EndpointUrls';

class SettingAccountForm extends Component {
  render() {
    const { email } = this.props;

    function EmailHeader() {
      return (
        <div role="presentation" onClick={() => redirectTo(CHANGE_EMAIL_PAGE_URL)} className="u-cur-pointer">
          <Headding icon={<Edit icon={16} />}>メールアドレス</Headding>
        </div>
      );
    }

    return (
      <div>
        <Panel>
          <EmailHeader />
          <Item>{email}</Item>
        </Panel>
        <Panel className="u-cur-pointer">
          <div role="presentation" onClick={() => redirectTo(CHANGE_PASSWORD_PAGE_URL)} className="u-cur-pointer">
            <Headding icon={<Edit icon={16} />}>パスワード</Headding>
          </div>
          <Item>********</Item>
        </Panel>
      </div>
    );
  }
}

// export default SettingAccountForm;
export default connect()(SettingAccountForm);
