import React from 'react';
import { connect } from 'react-redux';

import { CLIENT_CSV_FORMAT_EDIT } from 'src/constants/EndpointUrls';
import { CSV_FORMAT_TYPES } from 'src/constants/Options';
import { getOptionLabel } from 'src/utils/Utils';
import { Edit, Copy, Delete } from 'jbc-front/components/icons';
import { concatParamsToUrl } from 'src/utils/Http';
import { copyCsvFormat, deleteCsvFormat } from 'src/reducers/clients/csvFormats';
import { SimpleConfirmModal } from 'src/components';
import styles from './ClientCsvFormatsTable.scss';

const formatEditUrl = csvFormat => concatParamsToUrl(CLIENT_CSV_FORMAT_EDIT, { id: csvFormat.id });

class Row extends React.Component {
  state = {
    isModalOpen: false
  };

  openModal = () => this.setState({ isModalOpen: true });
  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    const { csvFormat, copyFormat, deleteFormat } = this.props;
    return (
      <tr>
        <td>{getOptionLabel(csvFormat.formatType)(CSV_FORMAT_TYPES)}</td>
        <td>{csvFormat.label}</td>
        <td className={styles.icons}>
          <a href={formatEditUrl(csvFormat)}>
            <Edit size={20} />
          </a>
          <a role="button" tabIndex="-1" onClick={() => copyFormat(csvFormat.id)}>
            <Copy size={20} />
          </a>
          <a role="button" tabIndex="-2" onClick={this.openModal}>
            <Delete size={20} />
          </a>
        </td>
        <SimpleConfirmModal
          header={'削除確認'}
          isOpen={this.state.isModalOpen}
          hideModal={this.closeModal}
          body={'本当に削除してよろしいですか？'}
          onClick={() => {
            deleteFormat(csvFormat.id);
            this.closeModal();
          }}
          confirmBtnText={'削除'}
        />
      </tr>
    );
  }
}

const ClientCsvFormatsTable = ({ formats, copyFormat, deleteFormat }) => (
  <div className="l-overflow-scroll u-mt20">
    <table className="m-table-item m-table-fixed">
      <thead>
        <tr>
          <th width="340">フォーマットの種類</th>
          <th width="460">フォーマット名</th>
          <th width="160">編集</th>
        </tr>
      </thead>
      <tbody>
        {formats &&
          formats.length > 0 &&
          formats.map(csvFormat => (
            <Row key={csvFormat.id} csvFormat={csvFormat} copyFormat={copyFormat} deleteFormat={deleteFormat} />
          ))}
        {(!formats || formats.length === 0) && (
          <tr>
            <td colSpan={3}>データはありません。</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

export default connect(
  state => ({
    formats: state.clientCsvFormats.list.data
  }),
  dispatch => ({
    copyFormat: csvFormatId => dispatch(copyCsvFormat(csvFormatId)),
    deleteFormat: csvFormatId => dispatch(deleteCsvFormat(csvFormatId))
  })
)(ClientCsvFormatsTable);
