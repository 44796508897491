import React from 'react';
import { formatDate, formatDateWithHour } from 'src/utils/Date';
import styles from './TimeInfo.scss';

const TimeInfo = ({ clientBonus }) => (
  <div className={styles.wrap}>
    {clientBonus.applyForTimeFrom && (
      <span className="u-mr15">
        対象期間: {formatDate(clientBonus.applyForTimeFrom)} ~ {formatDate(clientBonus.applyForTimeTo)}
      </span>
    )}
    <span>支給日: {formatDate(clientBonus.payOn)}</span>
    {clientBonus.isConfirmed && <span> 公開日: {formatDateWithHour(clientBonus.releaseDate)}</span>}
  </div>
);

export default TimeInfo;
