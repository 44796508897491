import { connect } from 'react-redux';
import _ from 'lodash';
import { reduxForm, getFormValues } from 'redux-form';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, uploadCsv } from 'src/reducers/my_numbers/myNumbers';
import { INSERT_BATCH_MY_NUMBER_CSV_FORM } from 'src/constants/FormNames';
import InsertBatchCsv from '../components/InsertBatchCsv';

const InsertBatchCsvForm = reduxForm({
  form: INSERT_BATCH_MY_NUMBER_CSV_FORM,
  enableReinitialize: true
})(InsertBatchCsv);

export default connect(
  state => ({
    errors: getItemErrors(REDUCER_NAME, state),
    selectedEncoding: _.get(getFormValues(INSERT_BATCH_MY_NUMBER_CSV_FORM)(state), 'encoding'),
    initialValues: { encoding: 'UTF-8' },
    anyJobRunning: getSelectOptions(state, 'anyJobRunning')
  }),
  dispatch => ({
    onSubmit: data => dispatch(uploadCsv(data))
  })
)(InsertBatchCsvForm);
