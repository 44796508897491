import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';

import Modal, { withModal } from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';

import { inviteAccount } from 'src/reducers/clients/accounts';
import { INVITE_ACCOUNT_FORM } from 'src/constants/FormNames';

import InviteForm from '../components/InviteForm';

const ModalForm = compose(
  connect(),
  reduxForm({
    form: INVITE_ACCOUNT_FORM,
    onSubmit: async (values, dispatch, { hideModal }) => {
      hideModal();
      dispatch(inviteAccount(values));
    },
    initialValues: {
      authority: 'admin'
    }
  })
)(InviteForm);

const Invite = ({ isModalOpen, showModal, hideModal }) => (
  <React.Fragment>
    <Button primary onClick={showModal} widthAuto>
      アカウント招待
    </Button>
    <Modal isOpen={isModalOpen} hideModal={hideModal} ariaHideApp={false}>
      {isModalOpen && <ModalForm hideModal={hideModal} />}
    </Modal>
  </React.Fragment>
);
export default withModal(Invite);
