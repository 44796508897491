import React, { Component } from 'react';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import { changeCheckedAllPage } from 'src/reducers/reports/reportWageLedger';

import styles from './ReportWageLedgerTableMethod.scss';

const ReportWageLedgerTableMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      const { changeAllCheckedTo } = this.props;

      return (
        <div className={styles.baloonWrap}>
          <div className={styles.baloon}>
            <ul className={styles.links}>
              <li>
                <a onClick={() => changeAllCheckedTo(true)} role="presentation">
                  全ページの従業員を選択
                </a>
              </li>
              <li>
                <a onClick={() => changeAllCheckedTo(false)} role="presentation">
                  全ページの従業員を解除
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }
);

export default connect(null, dispatch => ({
  changeAllCheckedTo: newValue => dispatch(changeCheckedAllPage(newValue))
}))(ReportWageLedgerTableMethod);
