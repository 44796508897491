import React from 'react';
import { useSelector } from 'react-redux';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Hamburger } from 'jbc-front/components/icons';

import { getExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME } from 'src/reducers/clients/allowances';
import LoadingView from 'src/components/LoadingView';

import styles from './SortableAllowancesTable.scss';

const AllowanceRow = SortableElement(({ allowance }) => (
  <tr className={styles.line}>
    <td className={styles.icon}>
      <Hamburger size={17} />
    </td>
    <td className={allowance.isInDisuse ? styles.through : ''}>{allowance.name}</td>
    <td className={allowance.isInDisuse ? styles.through : ''}>{allowance.displayFormulaDescription}</td>
  </tr>
));

const SortableAllowancesTable = SortableContainer(({ allowances }) => {
  const isListLoading = useSelector(state => getExtras(REDUCER_NAME, state).isLoading);
  return (
    <div className="l-overflow-scroll">
      <LoadingView isLoading={isListLoading}>
        <table className="m-table-list">
          <colgroup>
            <col width={50} />
            <col width={200} />
            <col width={500} />
          </colgroup>
          <thead>
            <tr>
              <th />
              <th>項目名</th>
              <th>金額</th>
            </tr>
          </thead>
          <tbody>
            {(!allowances || allowances.length === 0) && (
              <tr>
                <td colSpan={3}>データがありません。</td>
              </tr>
            )}
            {allowances &&
              allowances.map((allowance, index) => (
                <AllowanceRow key={allowance.id} index={index} allowance={allowance} />
              ))}
          </tbody>
        </table>
      </LoadingView>
    </div>
  );
});
export default SortableAllowancesTable;
