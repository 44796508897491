import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { Label, Section } from 'jbc-front/components/Form';
import { getItem } from 'src/reducers/common/itemReducer';
import selector, { numToStr, getOptionLabel, employeeDisplay, display, currency } from 'src/utils/Utils';
import { getSelectOptions, getRadioOptions } from 'src/reducers/selectOptions';
import { Checked } from 'src/icons';
import tableStyles from '../styles/GeneralTableInfo.scss';

const SeparatedOversea = ({ employeeFamilySupport }) => (
  <div>
    <table className={tableStyles.mTablePersonal}>
      <tbody>
        <tr>
          <th />
          <td />
        </tr>
        <tr>
          <th>非居住者：</th>
          <td>{employeeFamilySupport.isNonResident ? '対象' : '対象外'}</td>
        </tr>
        {employeeFamilySupport.relationshipType === 'other' && (
          <tr>
            <th>留学：</th>
            <td>{employeeFamilySupport.isStudyAbroad ? '対象' : '対象外'}</td>
          </tr>
        )}
        <tr>
          <th>国外居住親族への送金額：</th>
          <td>{currency(numToStr(employeeFamilySupport.overseaSendingMoneyAmount))}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

const addressFor = (support, employee, prefectures) => {
  switch (support.residentType) {
    case 'together':
      return employeeDisplay.address(employee, prefectures);
    case 'separated_domestic':
      return employeeDisplay.address(support, prefectures);
    case 'separated_oversea':
      return _.get(support, 'overseaAddress');
    default:
      return '';
  }
};

let FamilySupportItem = props => {
  const {
    employeeFamilySupport,
    index,
    relationshipTypes,
    genders,
    residentTypes,
    disabledPersonTypes,
    employee,
    prefectures
  } = props;

  return (
    <div>
      <Section title={`扶養情報 ${index + 1}`}>
        <table className={tableStyles.mTablePersonal}>
          <tbody>
            <tr>
              <th>続柄：</th>
              <td>
                {employeeFamilySupport.relationshipType === 'other'
                  ? employeeFamilySupport.relationshipDetail
                  : getOptionLabel(employeeFamilySupport.relationshipType)(relationshipTypes)}
              </td>
            </tr>
            <tr>
              <th>氏名：</th>
              <td>{employeeDisplay.fullName(employeeFamilySupport, true)}</td>
            </tr>
            <tr>
              <th>生年月日：</th>
              <td>{employeeDisplay.birthday(employeeFamilySupport)}</td>
            </tr>
            <tr>
              <th>性別：</th>
              <td>{display(getOptionLabel(employeeFamilySupport.gender)(genders))}</td>
            </tr>
            <tr>
              <th>同居・別居：</th>
              <td>{display(getOptionLabel(employeeFamilySupport.residentType)(residentTypes))}</td>
            </tr>
            <tr>
              <th>住所：</th>
              <td>{display(addressFor(employeeFamilySupport, employee, prefectures))}</td>
            </tr>
          </tbody>
        </table>

        {employeeFamilySupport.residentType === 'separated_oversea' && (
          <SeparatedOversea {...{ employeeFamilySupport }} />
        )}

        <table className={tableStyles.mTablePersonal}>
          <tbody>
            <tr>
              <th />
              <td />
            </tr>
            <tr>
              <th>年間収入（1月～12月）：</th>
              <td>{currency(numToStr(employeeFamilySupport.yearlyIncome))}</td>
            </tr>
            <tr>
              <th>年間所得（1月～12月）：</th>
              <td>{currency(numToStr(employeeFamilySupport.yearlyEarning))}</td>
            </tr>
            <tr>
              <th>障害者区分：</th>
              <td>{getOptionLabel(employeeFamilySupport.disabledPersonType)(disabledPersonTypes)}</td>
            </tr>
            <tr>
              <th>税の扶養対象：</th>
              <td>
                <Checked checked={employeeFamilySupport.isTaxDependency} />
              </td>
            </tr>
          </tbody>
        </table>
      </Section>
    </div>
  );
};

FamilySupportItem = connect(state => ({
  relationshipTypes: getRadioOptions(state, 'relationshipTypes'),
  prefectures: getSelectOptions(state, 'prefectures'),
  employee: selector(getItem(REDUCER_NAME, state), 'employee'),
  genders: getRadioOptions(state, 'genders'),
  residentTypes: getRadioOptions(state, 'residentTypes'),
  disabledPersonTypes: getRadioOptions(state, 'disabledPersonTypes')
}))(FamilySupportItem);

const FamilySupportsSectionShow = ({ employeeFamilySupports, countDependents }) => (
  <div>
    <table className={tableStyles.mTablePersonal}>
      <tbody>
        <tr>
          <th>
            <Label
              text="扶養親族等の数"
              noteWidth={500}
              note="「扶養親族等の数」は、「扶養情報」と、「税区分」のひとり親・寡婦/障害/勤労学生をもとに自動計算されます。
          （「税の扶養対象」でチェックが外れている扶養情報はカウントされません）
          詳細の計算方法は国税庁のサイトをご覧ください。"
            />
          </th>
          <td>{countDependents}人</td>
        </tr>
      </tbody>
    </table>
    <div className="u-mt30">
      {employeeFamilySupports.map((item, index) => (
        <FamilySupportItem employeeFamilySupport={item} key={item.id} index={index} />
      ))}
    </div>
  </div>
);

export default connect(state => ({
  employeeFamilySupports: selector(getItem(REDUCER_NAME, state), 'employeeFamilySupports') || [],
  countDependents: selector(getItem(REDUCER_NAME, state), 'countDependents')
}))(FamilySupportsSectionShow);
