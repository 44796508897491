import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import { POLICY_FORM } from 'src/constants/FormNames';
import { getList, deleteCheckedUser } from 'src/reducers/users/roleUsers';
import styles from './DeletePolicyModal.scss';

const RawForm = ({ handleSubmit, submitting, submit, cancelBtnClick, header, numberOfUser }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <div>
          選択された{numberOfUser}個のアカウントを<span className={styles.important}>削除します</span>。
        </div>
        <ul className={styles.notes}>
          <li>
            ※アカウントを削除しても従業員情報は削除されません。
          </li>
        </ul>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="削除" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let PolicyForm = reduxForm({
  form: POLICY_FORM
})(RawForm);

PolicyForm = connect(
  state => ({
    numberOfUser: _.get(_.filter(getList(state), { checked: true }), 'length')
  }),
  dispatch => ({
    onSubmit: () => dispatch(deleteCheckedUser())
  })
)(PolicyForm);

const DeletePolicyModal = ({ isOpen, hideModal }) => {
  const header = 'アカウント削除';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <PolicyForm cancelBtnClick={hideModal} header={header} />
    </CommonModal>
  );
};

export default connect()(DeletePolicyModal);
