import React from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import { numberWithComma } from 'src/utils/Utils';
import styles from './EditableTextField.scss';

class EditableTextField extends React.Component {
  static defaultProps = {
    type: 'text'
  };

  render() {
    const {
      input: inputProps,
      meta,
      type,
      showValue,
      showMode,
      unit,
      customOnBlur,
      customOnChange,
      editProps
    } = this.props;
    return (
      <div
        className={classnames({
          [`${styles.container}`]: true,
          [`${styles.error}`]: _.has(meta, 'error') && _.isString(meta.error)
        })}
      >
        {showMode === 'SHOW' ? (
          <input value={numberWithComma(showValue)} className={styles.show} disabled />
        ) : (
          <input
            type={type}
            {...inputProps}
            {...editProps}
            onBlur={event => {
              inputProps.onBlur(event);
              if (customOnBlur !== undefined) {
                customOnBlur(event);
              }
            }}
            onChange={event => {
              inputProps.onChange(event);
              if (customOnChange !== undefined) {
                customOnChange(event);
              }
            }}
          />
        )}
        {unit && <span className="unit"> {unit}</span>}
      </div>
    );
  }
}

export default EditableTextField;
