import React from 'react';
import 'whatwg-fetch';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { RadioField } from 'jbc-front/components/Form';
import ErrorsSection from 'src/components/ErrorsSection';
import styles from './UpdateSubjectModal.scss';

const TARGET = [
  { value: 'auto', label: '自動判定' },
  { value: 'target', label: '対象' },
  { value: 'non_target', label: '対象外' }
];

const UpdateSubjectForm = props => {
  const { submit, submitting, hideModal, isOpen, handleSubmit, pristine, errors } = props;
  const header = '対象判定変更';
  return (
    <CommonModal className={styles.modal} ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit} className={styles.modalForm}>
          <ErrorsSection errors={errors} />
          <p className={styles.description}>対象・対象外への強制変更や、自動判定に戻すことが可能です。</p>
          <br />
          <div className={styles.itemBox}>
            <p>健康保険</p>
            <RadioField name="updateHealthInsuranceSubject" options={TARGET} />
          </div>
          <div className={styles.itemBox}>
            <p>厚生年金</p>
            <RadioField name="updatePensionInsuranceSubject" options={TARGET} />
          </div>
          <div className={styles.buttonBox}>
            <Button onClick={hideModal} className="u-mr20">
              キャンセル
            </Button>
            <Button primary onClick={submit} disabled={submitting}>
              保存
            </Button>
          </div>
        </form>
      </CommonModal.Body>
    </CommonModal>
  );
};

export default UpdateSubjectForm;
