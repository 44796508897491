import { connect } from 'react-redux';
import _ from 'lodash';
import { reduxForm, getFormValues } from 'redux-form';
import compose from 'lodash/fp/compose';
import { REDUCER_NAME, fetchInhabitantTaxData } from 'src/reducers/employees/employeePays';
import { getList, getExtras, getIsLoading } from 'src/reducers/common/listReducer';
import {
  takeDownloadInhabitantTaxFbDataUrl,
  fetchUniquePayrollRuleGroups,
  fetchGroupedPaymentMonths,
  FORM_NAME_PAYS_SEARCH,
  isLoading,
  ITEM_NAME_FETCH_UNIQUE_PAYMENT_DATES,
  ITEM_NAME_FETCH_GROUPED_PAYMENT_MONTHS
} from 'src/reducers/searchForm';
import ShowInhabitantTaxFbPage from '../components/ShowInhabitantTaxFbPage';

const mapStateToProps = (state, ownProps) => {
  // 給与一覧から遷移してきた場合、初期表示は「締日支給日別」になる
  // referrerが取得できない場合があるので、query stringの有無で判定
  let isFromLocationEmployeePays = false;
  if (!_.isEmpty(state.router.location.search)) {
    isFromLocationEmployeePays = true;
  }
  return {
    isListLoading: getIsLoading(REDUCER_NAME, state) || false,
    employeePayWithInhabitants: getList(REDUCER_NAME, state) || [],
    targetPeriod: _.get(getExtras(REDUCER_NAME, state), 'targetPeriod'),
    inhabitantTaxFBPdfUrl: payrollGroupSelectorType =>
      takeDownloadInhabitantTaxFbDataUrl(state, payrollGroupSelectorType),
    isLoadingFetchUniquePaymentDates: isLoading(ITEM_NAME_FETCH_UNIQUE_PAYMENT_DATES, state),
    isLoadingFetchGroupedPaymentMonths: isLoading(ITEM_NAME_FETCH_GROUPED_PAYMENT_MONTHS, state),
    isClosingAndPaymentDateOnlyOne: ownProps.values.isClosingAndPaymentDateOnlyOne,
    formValues: getFormValues(FORM_NAME_PAYS_SEARCH)(state),
    isFromLocationEmployeePays
  };
};

const mapDispatchToProps = dispatch => ({
  fetchUniquePayrollRuleGroups: () => {
    dispatch(fetchUniquePayrollRuleGroups(FORM_NAME_PAYS_SEARCH, true));
  },
  fetchGroupedPaymentMonths: () => {
    dispatch(fetchGroupedPaymentMonths());
  },
  fetchInhabitantTaxData: (formValues, isFetchGrouped) => {
    dispatch(fetchInhabitantTaxData(formValues, isFetchGrouped));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_PAYS_SEARCH,
    enableReinitialize: true
  })
)(ShowInhabitantTaxFbPage);
