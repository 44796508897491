import React from 'react';

import { formatDate, formatDateWithHour } from 'src/utils/Date';
import styles from './TargetDateWithReleaseDate.scss';

const TargetDateWithReleaseDate = ({
  startDate,
  endDate,
  paymentDate,
  attendanceStartDate,
  attendanceEndDate,
  confirmed = null,
  releaseDate = null
}) => {
  const showReleaseDate = confirmed ? (
    <span className={styles.release}>公開日：{formatDateWithHour(releaseDate)}</span>
  ) : (
    ''
  );

  const attendanceTime =
    attendanceStartDate && startDate !== attendanceStartDate ? (
      <span className="u-ml20">
        （勤怠対象期間: {formatDate(attendanceStartDate)} ~ {formatDate(attendanceEndDate)}）
      </span>
    ) : (
      ''
    );

  return (
    <React.Fragment>
      <span>
        対象期間：{formatDate(startDate)} ~ {formatDate(endDate)}
      </span>
      {attendanceTime}
      <span className="u-ml20">支給日：{formatDate(paymentDate)}</span>
      {showReleaseDate}
    </React.Fragment>
  );
};

export default TargetDateWithReleaseDate;
