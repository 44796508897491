import React from 'react';

import * as Urls from 'src/constants/EndpointUrls';
import { Bonus, Calculator } from 'jbc-front/components/icons';
import ListGroup from 'jbc-front/components/ListGroup';
// import ProcedureStatuses from './ProcedureStatuses';
import EmployeeAnnounces from './EmployeeAnnounces';
import styles from './EmployeeDashboard.scss';

const EmployeeDashboard = ({ employeeAnnounces }) => (
  <div className="l-flex">
    <div className="l-flex1-sub u-pc-pt50 u-sp-pt30">
      <ListGroup>
        <ListGroup.Title>確認一覧</ListGroup.Title>

        <ListGroup.Item className={styles.list} as={'a'} href={Urls.EMPLOYEE_MY_PAYSLIPS}>
          <div className={styles.icon}>
            <Calculator size={40} />
          </div>
          <div>
            <p className={styles.name}>給与</p>
            <p className={styles.text}>給与明細を確認できます。</p>
          </div>
        </ListGroup.Item>
        <ListGroup.Item className={styles.list} as={'a'} href={Urls.EMPLOYEE_MY_BONUS}>
          <div className={styles.icon}>
            <Bonus size={40} />
          </div>
          <div>
            <p className={styles.name}>賞与</p>
            <p className={styles.text}>賞与明細を確認できます。</p>
          </div>
        </ListGroup.Item>
        {/*         <ListGroup.Item className={styles.list} as={'a'} href={Urls.EMPLOYEE_MY_INCOME_TAX_WITHHOLDING}> */}
        {/*           <div className={styles.icon}> */}
        {/*             <Scale size={40} /> */}
        {/*           </div> */}
        {/*           <div> */}
        {/*             <p className={styles.name}>源泉徴収票</p> */}
        {/*             <p className={styles.text}>源泉徴収票を確認できます。</p> */}
        {/*           </div> */}
        {/*         </ListGroup.Item> */}
      </ListGroup>
    </div>
    <div className="l-flex1-main u-pc-pt50 u-sp-pt20">
      <ListGroup>
        <EmployeeAnnounces employeeAnnounces={employeeAnnounces} />
      </ListGroup>
    </div>
  </div>
);

export default EmployeeDashboard;
