import React from 'react';

import scrollSpy, { AnchorTarget } from 'jbc-front/components/ScrollSpy';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import Button from 'jbc-front/components/Button';
import { Edit } from 'jbc-front/components/icons';

import { CLIENT_SETTINGS, OFFICE_NEW } from 'src/constants/EndpointUrls';
import { Panel, Headding } from 'src/components/PrimaryPanel';

import OfficeManageSection from '../containers/show/OfficeManageSectionContainer';
import OfficeSection from '../containers/show/OfficeSectionContainer';
import SocialInsuranceSection from '../containers/show/SocialInsuranceSectionContainer';
import LabourInsuranceSection from '../containers/show/LabourInsuranceSectionContainer';
import LabourConsultantSection from '../containers/show/LabourConsultantSectionContainer';
import BanksSection from '../containers/show/BankSectionContainer';
import SideNavigation from './SideNavigation';
import CoordinationLms from './CoordinationLms';
import styles from './ShowOffice.scss';

const OfficeView = scrollSpy(({ editUrl, isOnlyMainOffice, planType, isMainOffice }) => (
  <div className="l-wrap-xl">
    <div className="l-title-wrap">
      <h1 className="m-title-main">事業所情報</h1>
    </div>
    <div>
      <div className="u-mb20">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="事業所情報" />
        </BreadCrumb>
      </div>

      <div className={styles.buttonWrap}>
        {isOnlyMainOffice && (
          <React.Fragment>
            <div className={styles.button}>
              <CoordinationLms isOnlyMainOffice planType={planType} />
            </div>
            <div className={styles.button}>
              <Button href={OFFICE_NEW} as="a" primary widthAuto>
                新規事業所登録
              </Button>
            </div>
          </React.Fragment>
        )}
      </div>

      <div className="l-flex">
        <div className="l-flex6-sub">
          <SideNavigation isOnlyMainOffice={isOnlyMainOffice} isMainOffice={isMainOffice} />
        </div>
        <div className="l-flex6-main">
          {!isOnlyMainOffice && (
            <React.Fragment>
              <AnchorTarget name="OfficeManageInformation" />
              <Panel>
                <a href={editUrl}>
                  <Headding icon={<Edit icon={16} />}>事業所管理情報</Headding>
                </a>
                <OfficeManageSection />
              </Panel>
            </React.Fragment>
          )}

          <AnchorTarget name="OfficeInformation" />
          <Panel>
            <a href={editUrl}>
              <Headding icon={<Edit icon={16} />}>事業所情報</Headding>
            </a>
            <OfficeSection />
          </Panel>

          <AnchorTarget name={'SocialInsuranceInformation'} />
          <Panel>
            <a href={editUrl}>
              <Headding icon={<Edit icon={16} />}>社会保険情報 (健康保険・厚生年金保険)</Headding>
            </a>
            <SocialInsuranceSection />
          </Panel>

          <AnchorTarget name={'LabourInsuranceInformation'} />
          <Panel>
            <a href={editUrl}>
              <Headding icon={<Edit icon={16} />}>労働保険情報 (労災保険・雇用保険)</Headding>
            </a>
            <LabourInsuranceSection />
          </Panel>

          {isMainOffice && (
            <React.Fragment>
              <AnchorTarget name="LabourConsultantInformation" />
              <Panel>
                <a href={editUrl}>
                  <Headding icon={<Edit icon={16} />}>社労士情報</Headding>
                </a>
                <LabourConsultantSection />
              </Panel>

              <AnchorTarget name="BankInformation" />
              <Panel>
                <a href={editUrl}>
                  <Headding icon={<Edit icon={16} />}>振込元口座情報</Headding>
                </a>
                <BanksSection />
              </Panel>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  </div>
));

export default OfficeView;
