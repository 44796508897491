import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { REDUCER_NAME, fetchAllowanceList } from 'src/reducers/clients/allowances';
import {
  CLIENT_ALLOWANCE_NEW,
  CLIENT_UNIT_PRICES_LIST,
  CLIENT_SETTINGS,
  CLIENT_PAYROLL_RULES_GROUP_LIST,
  CLIENT_ALLOWANCE_CHANGE_DISPLAY_ORDERS
} from 'src/constants/EndpointUrls';
import Tag from 'jbc-front/components/Tag';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import ActionButton from 'jbc-front/components/ActionButton';
import { Plus, Drugging } from 'jbc-front/components/icons';
import { concatParamsToUrl } from 'src/utils/Http';
import { getExtras, getList, setExtras } from 'src/reducers/common/listReducer';
import { getRadioOptions } from 'src/reducers/selectOptions';

import AllowancesTable from './components/AllowancesTable';
import styles from './ClientAllowancesList.scss';

const newAllowanceUrl = payrollGroupId =>
  concatParamsToUrl(CLIENT_ALLOWANCE_NEW, { payrollRulesGroupId: payrollGroupId });
const unitPricesListUrl = payrollRulesGroupId => concatParamsToUrl(CLIENT_UNIT_PRICES_LIST, { payrollRulesGroupId });
const changeDisplayOrderUrl = payrollRulesGroupId =>
  concatParamsToUrl(CLIENT_ALLOWANCE_CHANGE_DISPLAY_ORDERS, { payrollRulesGroupId });

const SelectAllowanceType = ({ allowanceTypes, selectedAllowanceType, handleOnClick }) => (
  <div className={styles.filter}>
    {allowanceTypes.map(allowanceType => (
      <Tag
        key={allowanceType.value}
        active={allowanceType.value === selectedAllowanceType}
        onClick={e => {
          e.preventDefault();
          handleOnClick(allowanceType);
        }}
      >
        {allowanceType.label}
      </Tag>
    ))}
  </div>
);

const ClientAllowancesList = props => {
  const dispatch = useDispatch();
  const { payrollRulesGroup } = props;
  const payrollRulesGroupId = payrollRulesGroup.id;

  const selectedAllowanceType = useSelector(state => getExtras(REDUCER_NAME, state)).selectedAllowanceType;
  const setSelectedAllowanceType = allowanceType => {
    dispatch(setExtras(REDUCER_NAME, { selectedAllowanceType: allowanceType }));
  };

  useEffect(() => {
    dispatch(fetchAllowanceList(payrollRulesGroupId));
    if (!selectedAllowanceType) {
      setSelectedAllowanceType('payment');
    }
  }, []);

  const allowanceTypes = useSelector(state => getRadioOptions(state, 'allowanceTypes'));
  const allowances = useSelector(state => getList(REDUCER_NAME, state));

  const getDisplayAllowances = () => allowances.filter(item => item.allowanceType === selectedAllowanceType);
  const handleOnSelectAllowanceType = allowanceType => {
    setSelectedAllowanceType(allowanceType.value);
  };

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">支給・控除項目一覧</h1>
        <div className="m-title-main-note">{payrollRulesGroup.name}</div>
      </div>
      <div className="l-wrap-s l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="給与規定グループ一覧" path={CLIENT_PAYROLL_RULES_GROUP_LIST} />
            <BreadCrumb.Item label="支給・控除項目一覧" />
          </BreadCrumb>
        </div>
        <div className={styles.head}>
          <div>
            <SelectAllowanceType
              allowanceTypes={allowanceTypes}
              selectedAllowanceType={selectedAllowanceType}
              handleOnClick={handleOnSelectAllowanceType}
            />
          </div>
          <div>
            <Link to={newAllowanceUrl(payrollRulesGroupId)}>
              <ActionButton as="a" icon={<Plus size={10} />}>
                項目を追加
              </ActionButton>
            </Link>
            <Link to={changeDisplayOrderUrl(payrollRulesGroupId)}>
              <ActionButton as="a" className="u-ml10" icon={<Drugging size={10} />}>
                項目並べ替え
              </ActionButton>
            </Link>
            <ActionButton as="a" href={unitPricesListUrl(payrollRulesGroupId)} className="u-ml10">
              単価設定
            </ActionButton>
          </div>
        </div>
        <AllowancesTable allowances={getDisplayAllowances()} />
      </div>
    </div>
  );
};

export default ClientAllowancesList;
