import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { getList } from 'src/reducers/common/listReducer';
import { REDUCER_NAME } from 'src/reducers/my_numbers/myNumbers';
import { takeEmployeesSearchQueries, FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import EmployeeTable from '../components/EmployeeTable';

const mapStateToProps = state => ({
  employees: getList(REDUCER_NAME, state),
  queries: takeEmployeesSearchQueries(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state))
});

export default connect(mapStateToProps)(EmployeeTable);
