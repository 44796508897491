import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { EMPLOYMENT_TYPE_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';

import { getMode, getList } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME, updateEmploymentTypes } from 'src/reducers/clients/employmentTypes';

import { SortableList } from 'src/components';

const EditableListFormed = reduxForm({
  form: EMPLOYMENT_TYPE_FORM
})(SortableList);

const mapStateToProps = state => ({
  initialValues: { [EDITABLE_LIST_FIELDS_NAME]: getList(REDUCER_NAME, state) },
  mode: getMode(REDUCER_NAME, state),
  listData: getList(REDUCER_NAME, state),
  fieldName: 'statusName',
  fieldCode: 'employmentTypeCode'
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    const EmploymentTypes = data[EDITABLE_LIST_FIELDS_NAME];
    const SortedEmploymentTypes = EmploymentTypes.map((item, index) => ({ ...item, orderNumber: index }));
    dispatch(updateEmploymentTypes(SortedEmploymentTypes));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableListFormed);
