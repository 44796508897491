import React from 'react';
import { connect } from 'react-redux';
import { getFormValues, FormSection, change as formChange } from 'redux-form';
import * as Urls from 'src/constants/EndpointUrls';

import { CSV_FORMAT_TYPES } from 'src/constants/Options';
import { CLIENT_CSV_FORMAT_FORM } from 'src/constants/FormNames';
import { addUuidToItems, mapUuidToItems } from 'src/reducers/clients/csvFormats';
import { fetchWageLedgerExportItems } from 'src/reducers/fetchSelectOptions';
import { getSelectOptions } from 'src/reducers/selectOptions';
import Button from 'jbc-front/components/Button';
import { Section, TextField, RadioField } from 'jbc-front/components/Form';
import SelectLeftRightItems from './SelectLeftRightItems';
import SelectGroupItems from './SelectGroupItems';
import styles from './ClientCsvFormatForm.scss';

function toHalfUnicodeChar(str) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xfee0));
}

function sortByWageledgerItems(items, wageLedgerItems) {
  if (
    typeof wageLedgerItems !== 'object' ||
    Object.keys(wageLedgerItems).length <= 0 ||
    typeof items !== 'object' ||
    Object.keys(items).length <= 0
  ) {
    return items;
  }

  const sortedItems = {};
  Object.entries(items).forEach(([label, array]) => {
    if (!Object.prototype.hasOwnProperty.call(wageLedgerItems, label)) {
      return; // continue
    }

    const wageLedgerArray = wageLedgerItems[label];

    let sortedArray = wageLedgerArray.flatMap(wageLegerItem => {
      const matchingItems = array.filter(
        item => toHalfUnicodeChar(item.label) === toHalfUnicodeChar(wageLegerItem.label)
      );
      return matchingItems.length > 0 ? matchingItems : null;
    });

    const excludedArray = array.filter(item => !sortedArray.includes(item));
    sortedArray = sortedArray.filter(item => item);
    sortedArray.push(...excludedArray);

    sortedItems[label] = sortedArray;
  });

  return sortedItems;
}
class ClientCsvFormatForm extends React.Component {
  constructor(props) {
    super(props);
    const { clientCsvData } = this.props;

    this.state = {
      selectingLeftItems: [],
      rightItems: clientCsvData.currentSelectedFields,
      selectingRightItems: [],
      selectingGroups: clientCsvData.currentSelectedGroups
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchWageLedgerExportItems());
  }

  setStateData = newData => {
    this.dispatchFormValue(newData);
    this.setState(prevState => ({ ...prevState, ...newData }));
  };

  addPosition = items => [...items.map((item, index) => ({ ...item, position: index }))];

  dispatchFormValue = data => {
    if (Object.keys(data).includes('rightItems')) {
      this.props.dispatch(
        formChange(CLIENT_CSV_FORMAT_FORM, 'clientCsvFormatFields', this.addPosition(data.rightItems))
      );
    }
    if (Object.keys(data).includes('selectingGroups')) {
      this.props.dispatch(formChange(CLIENT_CSV_FORMAT_FORM, 'clientCsvFormatGroups', [...data.selectingGroups]));
    }
  };

  render() {
    const {
      isCreateForm,
      formatType,
      csvAvaiableItems,
      allGroups,
      submitting,
      submit,
      pristine,
      handleSubmit,
      goBackPath,
      wageLedgerItems
    } = this.props;
    const { selectingLeftItems, selectingRightItems, selectingGroups } = this.state;

    let { rightItems } = this.state;

    /*
     * All items are merged from varying tables,
     * so we add unique id to each item to ensure no item has same id
     * and use this uuid to discriminate from others
     */
    const csvAvaiableItemsWithUuid = addUuidToItems(csvAvaiableItems);
    rightItems = mapUuidToItems(csvAvaiableItemsWithUuid, rightItems);

    let leftItems =
      formatType === 'bonus'
        ? { ...csvAvaiableItemsWithUuid[formatType] }
        : { ...csvAvaiableItemsWithUuid.attendance, ...csvAvaiableItemsWithUuid[formatType] };
    leftItems = sortByWageledgerItems(leftItems, wageLedgerItems);

    const deductionsPayPath = Urls.SHOW_ALLOWANCE_DEDUCTIONS_PAY;
    const deductionsBonusPath = Urls.SHOW_ALLOWANCE_DEDUCTIONS_BONUS;

    let redirectUrl;
    if (goBackPath === deductionsPayPath || goBackPath === deductionsBonusPath) {
      redirectUrl = goBackPath;
    } else {
      redirectUrl = Urls.CLIENT_CSV_FORMATS_LIST;
    }

    return (
      <form onSubmit={handleSubmit}>
        <Section title={`フォーマットの${isCreateForm ? '作成' : '編集'}`}>
          <FormSection name="clientCsvFormat">
            <RadioField name="formatType" label="フォーマットの種類" options={CSV_FORMAT_TYPES} required />
            <TextField name="label" label="フォーマット名" required />
          </FormSection>

          <FormSection name="clientCsvFormatFields">
            <SelectLeftRightItems
              {...{ leftItems, selectingLeftItems, rightItems, selectingRightItems }}
              changeParentState={this.setStateData}
            />
          </FormSection>

          <FormSection name="clientCsvFormatGroups">
            <SelectGroupItems {...{ allGroups, selectingGroups }} changeParentState={this.setStateData} />
          </FormSection>

          <div className={styles.actionButtons}>
            <span>
              <Button as="a" href={redirectUrl}>
                キャンセル
              </Button>
            </span>
            <span>
              <Button primary onClick={submit} disabled={submitting || pristine}>
                保存
              </Button>
            </span>
          </div>
        </Section>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  formatType: getFormValues(CLIENT_CSV_FORMAT_FORM)(state).clientCsvFormat.formatType,
  wageLedgerItems: getSelectOptions(state, 'wageLedgerExportItems')
});

export default connect(mapStateToProps)(ClientCsvFormatForm);
