import React from 'react';
import _ from 'lodash';
import styles from './PayrollTableData.scss';
import Row from './Row';

const PayrollAttendanceDetail = ({ payroll }) => (
  <div className={styles.paymentClm}>
    {payroll && (
      <table className="m-table-obvious">
        <thead>
          <tr>
            <th colSpan="2">勤怠</th>
          </tr>
        </thead>
        <tbody>
          {_.get(payroll, 'attendanceItems', []).map(item => (
            <Row key={item.id} label={item.name} value={item.value} />
          ))}
        </tbody>
      </table>
    )}
  </div>
);

export default PayrollAttendanceDetail;
