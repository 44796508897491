import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { CLIENT_SETTING_USING_PLAN } from 'src/constants/EndpointUrls';
import Button from 'jbc-front/components/Button';
import { isTrialPlan, isFreePlan } from 'src/reducers/clients/currentClient';
import styles from './OfferUpdateToPaidPlan.scss';

const OfferUpdateToPaidPlan = ({ planType, trialFinishAt }) => (
  <div>
    {isTrialPlan(planType) && (
      <div className={styles.plan}>
        <div className={styles.planInner}>
          <div>
            <p className={styles.planText}>
              お試しプラン 残り{moment(trialFinishAt).diff(moment().subtract(1, 'days'), 'days')}日
            </p>
            <p className={styles.planInquiry}>
              電話でのお問い合わせはこちら <span className={styles.planPhonenumber}>050-3201-4837</span>
              <br />受付時間：10:00〜17:00 (土日祝除く)
            </p>
          </div>
          <a href={CLIENT_SETTING_USING_PLAN} className={styles.button}>
            <Button primary>有料プランへの申込はこちら</Button>
          </a>
        </div>
      </div>
    )}
    {isFreePlan(planType) && (
      <div className={styles.plan}>
        <div className={styles.planInner}>
          <div>
            <p className={styles.planText}>現在、無料プランをご利用中です</p>
            <p className="u-mt5">※ 無料プランは機能制限がございます</p>
          </div>
          <a href={CLIENT_SETTING_USING_PLAN} className={styles.button}>
            <Button primary>有料プランへの申込はこちら</Button>
          </a>
        </div>
      </div>
    )}
  </div>
);

export default connect(state => ({
  planType: state.currentClient.item.data.planType,
  trialFinishAt: state.currentClient.item.data.trialFinishAt
}))(OfferUpdateToPaidPlan);
