import React from 'react';
import LazyLoad from 'react-lazyload';
import RevisionItem from '../containers/RevisionItemContainer';

const List = ({ revisions, applicableOffice }) => (
  <div>
    {revisions.map(revision => (
      <LazyLoad>
        <RevisionItem key={`${revision.id}`} revision={revision} applicableOffice={applicableOffice} />
      </LazyLoad>
    ))}
  </div>
);

export default List;
