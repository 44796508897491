import React from 'react';
import { ModifyIcon } from 'src/icons';
import styles from './EditableTextField.scss';

export const EditableTextField = ({ input, disabled, meta: { touched, error }, modified }) => (
  <div {...(touched && error ? { className: `invalid ${styles.def}` } : { className: styles.def })}>
    <div className="m-table-obvious-modified">
      <ModifyIcon modified={modified} size={14} />
    </div>
    <input {...input} disabled={disabled} />
  </div>
);

export default EditableTextField;
