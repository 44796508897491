import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';

import { LoadingWithDescription } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { CONFIRM_EMPLOYEE_DETAIL_ROLLBACK_FORM } from 'src/constants/FormNames';
import { rollbackEmployeeDetails } from 'src/reducers/reports/reportLaborInsurances';
import compose from 'lodash/fp/compose';

const ConfirmEmployeeDetailRollbackForm = ({
  laborInsuranceYear,
  isOpen,
  handleSubmit,
  hideModal,
  submitting,
  submit
}) => (
  <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, laborInsuranceYear }}>
    <CommonModal.Header hideModal={hideModal}>手入力前に戻す</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        {submitting && <LoadingWithDescription text="「手入力前に戻す」を実行中です。しばらくお待ちください。" />}
        {!submitting && (
          <div>
            月別内訳を手入力前に戻します。<br />
            編集したデータは失われますがよろしいですか。
          </div>
        )}
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="手入力前に戻す" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </CommonModal>
);

const mapStateToProps = null;
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => {
    dispatch(rollbackEmployeeDetails(ownProps.laborInsuranceYear, ownProps.employee.id));
  }
});

const ConfirmEmployeeDetailRollbackModal = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: CONFIRM_EMPLOYEE_DETAIL_ROLLBACK_FORM })
)(ConfirmEmployeeDetailRollbackForm);

export default ConfirmEmployeeDetailRollbackModal;
