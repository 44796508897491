import React, { useEffect, useState } from 'react';
import { arrayMove } from 'react-sortable-hoc';

import Tag from 'jbc-front/components/Tag';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import ActionButton from 'jbc-front/components/ActionButton';
import { MinusCircle, Strage } from 'jbc-front/components/icons';

import { CLIENT_SETTINGS, CLIENT_BONUS_ALLOWANCES_LIST } from 'src/constants/EndpointUrls';

import SortableAllowancesTable from './SortableAllowancesTable';

import styles from './ChangeBonusAllowanceDisplayOrder.scss';

const SelectAllowanceType = ({ allowanceTypes, selectedAllowanceType, handleOnClick }) => (
  <div className={styles.filter}>
    {allowanceTypes.map(allowanceType => (
      <Tag
        key={allowanceType.value}
        active={allowanceType.value === selectedAllowanceType}
        onClick={e => {
          e.preventDefault();
          handleOnClick(allowanceType);
        }}
      >
        {allowanceType.label}
      </Tag>
    ))}
  </div>
);

// 支給/控除項目並び替え画面TOP
const ChangeBonusAllowanceDisplayOrder = props => {
  const displayAllowances = {
    payment: [],
    deduction: []
  };
  const [selectedAllowanceType, setSelectedAllowanceType] = useState('payment');

  useEffect(
    () => {
      const { values, setAllowances } = props;
      setAllowances(values);
    },
    [props.values]
  );

  // 表示用の支給控除項目を取得する
  const getDisplayAllowances = currentType => displayAllowances[currentType];

  // 並び替え完了のイベントハンドラ
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const { setAllowances } = props;
    const currentList = getDisplayAllowances(selectedAllowanceType);
    displayAllowances[selectedAllowanceType] = arrayMove([...currentList], oldIndex, newIndex);
    // 支給項目, 控除項目の順で保存する
    setAllowances([...displayAllowances.payment, ...displayAllowances.deduction]);
  };

  // 支給項目・控除項目の切り替えイベントのハンドラ
  const handleOnSelectAllowanceType = allowanceType => {
    setSelectedAllowanceType(allowanceType.value);
  };

  // 支給控除項目を支給と控除のリストに分割する
  const prepareDisplayAllowances = () => {
    const { clientBonusAllowances } = props;
    displayAllowances.payment = clientBonusAllowances.filter(allowance => allowance.allowanceType === 'payment');
    displayAllowances.deduction = clientBonusAllowances.filter(allowance => allowance.allowanceType === 'deduction');
  };

  const { bonusAllowanceTypes, saveDisplayOrder } = props;
  prepareDisplayAllowances();
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">支給・控除項目並べ替え</h1>
      </div>
      <div className="l-wrap-s l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="賞与規定" path={CLIENT_BONUS_ALLOWANCES_LIST} />
            <BreadCrumb.Item label="支給・控除項目並べ替え" />
          </BreadCrumb>
        </div>
        <div className={styles.head}>
          <div>
            <SelectAllowanceType
              allowanceTypes={bonusAllowanceTypes}
              selectedAllowanceType={selectedAllowanceType}
              handleOnClick={handleOnSelectAllowanceType}
            />
          </div>
          <div>
            <ActionButton onClick={saveDisplayOrder} icon={<Strage size={15} />}>
              保存
            </ActionButton>
            <ActionButton
              className="u-ml10"
              as="a"
              href={CLIENT_BONUS_ALLOWANCES_LIST}
              icon={<MinusCircle size={15} />}
            >
              キャンセル
            </ActionButton>
          </div>
        </div>
        <SortableAllowancesTable
          onSortEnd={onSortEnd}
          allowances={getDisplayAllowances(selectedAllowanceType)}
          helperClass={styles.dragging}
        />
      </div>
    </div>
  );
};

export default ChangeBonusAllowanceDisplayOrder;
