import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import {
  getEmailSettingList,
  changeItemCheck
} from 'src/reducers/admin_email_notification_settings/admin_email_notification_settings';

const EDIT_MODE = 'EDIT';

const CheckBoxComponent = ({ item, field, editing, checkItem }) => (
  <td>
    {editing ? (
      <label className="input-label">
        <input
          type="checkbox"
          checked={_.get(item, field)}
          onChange={() => checkItem(item, field)}
          className="m-checkbox-input"
        />
        <span className="m-checkbox-parts">受信する</span>
      </label>
    ) : _.get(item, field) ? (
      '受信する'
    ) : (
      '受信しない'
    )}
  </td>
);

const Row = ({ item, editing, checkItem }) => (
  <tr>
    <td style={{ wordWrap: 'break-word' }}>{item.email}</td>
    <td style={{ wordWrap: 'break-word' }}>{item.name}</td>
    <CheckBoxComponent {...{ item, editing, checkItem }} field="canReceiveAttendanceMail" />
    <CheckBoxComponent {...{ item, editing, checkItem }} field="canReceiveEmployeeMail" />
    <CheckBoxComponent {...{ item, editing, checkItem }} field="canReceiveConfirmPayMail" />
    <CheckBoxComponent {...{ item, editing, checkItem }} field="canReceiveDownloadAllowanceMail" />
  </tr>
);

class EmailNotificationSettingTable extends Component {
  render() {
    const { emailSettingList, mode, checkItem } = this.props;
    const editing = mode === EDIT_MODE;

    return (
      <div className="l-overflow-scroll">
        <table className="m-table-list m-table-fixed">
          <colgroup>
            <col width={190} />
            <col width={190} />
            <col width={140} />
            <col width={140} />
            <col width={140} />
            <col width={140} />
          </colgroup>
          <thead>
            <tr>
              <th>メールアドレス</th>
              <th>名前</th>
              <th>勤怠情報</th>
              <th>従業員情報</th>
              <th>給与・賞与処理</th>
              <th>支給控除項目</th>
            </tr>
          </thead>
          <tbody>{_.map(emailSettingList, item => <Row {...{ item, editing, checkItem }} key={item.id} />)}</tbody>
        </table>
      </div>
    );
  }
}

export default connect(
  state => ({
    emailSettingList: getEmailSettingList(state) || []
  }),
  dispatch => ({
    checkItem: (item, field) => dispatch(changeItemCheck(item, field))
  })
)(EmailNotificationSettingTable);
