import axios from 'axios';
import { combineReducers } from 'redux';

import * as Urls from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from '../createNamedWrapperReducer';
import { itemReducer } from '../common';
import { newItemFailure, editItem, editItemFailure } from '../common/itemReducer';

export const REDUCER_NAME = 'commutingFeeSettings';

export const editCommutingFeeSetting = data => async dispatch => {
  try {
    dispatch(editItem(REDUCER_NAME));
    const httpBody = {
      clientCommutingFeeSetting: data
    };
    const response = await axios.post(Urls.CLIENT_COMMUTING_FEE_SETTINGS_CREATE, httpBody);
    if (response.data.errors.length > 0) {
      dispatch(newItemFailure(REDUCER_NAME, response.data.errors.messages));
    } else {
      window.location.reload(true);
    }
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
