import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';

import * as Urls from 'src/constants/EndpointUrls';
import { Dl, Dd, Dt } from 'src/components/SecondaryPanel/SecondaryPanel';
import { Panel, Headding, Item } from 'src/components/PrimaryPanel/PrimaryPanel';
import { recordDisplay } from 'src/utils/Utils';
import { setAuditLogItem, getAuditLogItem } from 'src/reducers/clients/auditLogs';
import BreadCrumb from 'jbc-front/components/BreadCrumb';

const change = value => {
  if (_.isString(value)) {
    return value;
  }
  if (_.isObject(value) && !_.isUndefined(value.filename)) {
    return value.filename || 'null';
  }
  return JSON.stringify(value);
};

let AuditLogDetail = ({ auditLog }) => (
  <div>
    <Panel>
      <Headding>更新情報</Headding>
      <Item>
        <Dl>
          <Dt>アカウント</Dt>
          <Dd>{recordDisplay(auditLog.actedUserEmail)}</Dd>
          <Dt>ip</Dt>
          <Dd>{recordDisplay(auditLog.ip)}</Dd>
          <Dt>操作種別</Dt>
          <Dd>{recordDisplay(auditLog.operationLabel)}</Dd>
          <Dt>操作対象</Dt>
          <Dd>{recordDisplay(auditLog.employeeDisplayName)}</Dd>
          <Dt>日時</Dt>
          <Dd>{recordDisplay(auditLog.createdAt && moment(auditLog.createdAt).format('LLL'))}</Dd>
        </Dl>
      </Item>
    </Panel>
    <h3 className="m-title-small">更新内容</h3>
    {auditLog.dataChanges && (
      <div className="l-overflow-scroll">
        <table className="m-table-list m-table-fixed">
          <thead>
            <tr>
              <th style={{ width: '30%' }}>項目</th>
              <th style={{ width: '35%' }}>変更前</th>
              <th style={{ width: '35%' }}>変更後</th>
            </tr>
          </thead>
          <tbody>
            {_.map(auditLog.dataChanges, ({ label, history: [before, after] }) => (
              <tr key={label}>
                <td className="u-break-all">{label}</td>
                <td className="u-break-all">{change(before)}</td>
                <td className="u-break-all">{change(after)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    )}
  </div>
);

AuditLogDetail = connect(state => ({
  auditLog: getAuditLogItem(state)
}))(AuditLogDetail);

const AuditLogShow = ({ dispatch, auditLog }) => {
  dispatch(setAuditLogItem(auditLog));
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">操作履歴</h1>
      </div>
      <div className="l-wrap-s l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={Urls.CLIENT_SETTINGS} />
            <BreadCrumb.Item label="操作履歴" path={Urls.AUDIT_LOGS_LIST} />
            <BreadCrumb.Item label="操作履歴詳細" />
          </BreadCrumb>
        </div>
        <AuditLogDetail />
      </div>
    </div>
  );
};

export default connect()(AuditLogShow);
