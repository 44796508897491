import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS, CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { REDUCER_NAME } from 'src/reducers/clients/unitPrices';
import { setList } from 'src/reducers/common/listReducer';
import ClientUnitPriceTable from './containers/ClientUnitPriceTable';

const ClientUnitPricesList = ({ dispatch, clientUnitPrices, payrollRulesGroup = {}, match }) => {
  const { payrollRulesGroupId } = match.params;
  dispatch(setList(REDUCER_NAME, { data: clientUnitPrices }));
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">単価一覧</h1>
        <div className="m-title-main-note">{payrollRulesGroup.name}</div>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="給与規定グループ一覧" path={CLIENT_PAYROLL_RULES_GROUP_LIST} />
            <BreadCrumb.Item
              label="支給・控除項目一覧"
              path={`/clients/payroll_rules_groups/${payrollRulesGroupId}/allowances`}
            />
            <BreadCrumb.Item label="単価一覧" />
          </BreadCrumb>
        </div>
        <ClientUnitPriceTable payrollRulesGroupId={payrollRulesGroupId} />
      </div>
    </div>
  );
};

export default connect()(ClientUnitPricesList);
