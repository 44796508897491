import React from 'react';
import { PAY_FOR_RETIREMENT } from 'src/constants/Options';
import { MonthField, DateField, SelectField, RadioField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import FbDataLineFeedRadioButton from 'src/components/FbDataLineFeedRadioButton';
import { INHABITANT_TAX_FB_DATA_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import InhabitantTaxOfRetirementSection from './InhabitantTaxOfRetirementSection';
import styles from './InhabitantTaxFBDataModal.scss';

const InhabitantTaxFBDataModal = ({
  isOpen,
  errors,
  submitting,
  submit,
  clientBanks,
  hideModal,
  payForRetirement,
  onModalHide,
  selectedLabel,
  isUseLineFeed,
  onBankChange
}) => {
  const header = '住民税振込FBデータ出力';
  const changeTransferFromBankId = (e, newValue) => {
    onBankChange(newValue);
  };
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, onModalHide }}>
      <CommonModal.Header hideModal={hideModal}>
        <div className={styles.modalHeader}>
          <span>{header}</span>
          <span>{selectedLabel}</span>
        </div>
      </CommonModal.Header>
      <CommonModal.Body>
        <form className={styles.inner}>
          <ErrorsSection errors={errors} />

          <p className={styles.lead}>
            インターネットバンキングを通じて住民税を納付する場合はこちらからFBデータを出力できます。
          </p>
          <ul className={styles.notes}>
            <li>※退職金にかかる住民税も一緒に納付する場合は「退職金の住民税支払い」を「あり」にしてください。</li>
          </ul>

          <div className={styles.dateBox}>
            <div>
              <MonthField required name="paymentOnMonth" label="納付対象月" />
            </div>
            <div>
              <DateField required name="paymentDueDate" label="納付期限" />
            </div>
          </div>

          <SelectField
            required
            name="transferFromBankId"
            label="支払口座"
            options={clientBanks}
            onChange={changeTransferFromBankId}
          />
          {clientBanks &&
            clientBanks.length === 0 && (
              <h3 className={styles.error}>
                支払口座情報がまだ登録されていません。事業所情報設定画面からご登録ください。
              </h3>
            )}

          <RadioField
            name="outputOrdinanceDesignatedCity"
            options={PAY_FOR_RETIREMENT}
            label="市区町村名の政令指定都市の出力"
            note={
              <p>
                納付先が政令指定都市の場合、「市」の出力が不要の場合があります。<br />
                振込元の銀行の指定に合わせてご選択ください。<br />
                「なし」の場合「市」が省略されて「区」のみで出力されます。
              </p>
            }
          />
          <RadioField name="payForRetirement" options={PAY_FOR_RETIREMENT} label="退職金の住民税支払い" />
          {!!+payForRetirement && <InhabitantTaxOfRetirementSection />}
          <FbDataLineFeedRadioButton formName={INHABITANT_TAX_FB_DATA_FORM} isUseLineFeed={isUseLineFeed} />
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={hideModal} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="出力" {...{ submitting, submit }} />
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default InhabitantTaxFBDataModal;
