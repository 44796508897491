import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { WAGE_LEDGER_EXPORT_ITEM_FORM } from 'src/constants/FormNames';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { REPORT_INDEX, WAGE_LEDGER } from 'src/constants/EndpointUrls';
import { fetchWageLedgerExportItems } from 'src/reducers/fetchSelectOptions';
import { saveExportItems } from 'src/reducers/reports/reportWageLedger';
import WageLedgerExportItemForm from './containers/WageLedgerExportItemForm';

const RdxWageLedgerExportItemForm = reduxForm({
  form: WAGE_LEDGER_EXPORT_ITEM_FORM
})(WageLedgerExportItemForm);

const WageLedgerExportItemContainer = connect(
  state => ({
    wageLedgerExportItems: getSelectOptions(state, 'wageLedgerExportItems') || []
  }),
  dispatch => ({
    onSubmit: data => dispatch(saveExportItems(data))
  })
)(RdxWageLedgerExportItemForm);

class WageLedgerExportItemList extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(fetchWageLedgerExportItems());
  }

  render() {
    const { currentExportItems } = this.props;

    return (
      <div className="l-wrap-xl l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
            <BreadCrumb.Item label="賃金台帳" path={WAGE_LEDGER} />
            <BreadCrumb.Item label="出力項目選択" />
          </BreadCrumb>
          <div className="l-title-wrap">
            <h1 className="m-title-main">賃金台帳　出力項目選択</h1>
          </div>
          <div>
            <WageLedgerExportItemContainer currentExportItems={currentExportItems} />
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(WageLedgerExportItemList);
