import { combineReducers } from 'redux';
import axios from 'axios';
import humps from 'humps';

import * as Urls from 'src/constants/EndpointUrls';
import { excludeValuesIfNotDefined } from 'src/utils/Http';
import {
  getList,
  getListQueries,
  fetchList,
  fetchListSuccess,
  fetchListFailure
} from 'src/reducers/common/listReducer';
import { setItem, getItem } from 'src/reducers/common/itemReducer';
import createNamedWrapperReducer from '../createNamedWrapperReducer';
import { listReducer, itemReducer } from '../common';

export const REDUCER_NAME = 'auditLogs';
export const getListAuditLogs = state => getList(REDUCER_NAME, state);
export const setAuditLogItem = data => setItem(REDUCER_NAME, data);
export const getAuditLogItem = state => getItem(REDUCER_NAME, state);

export const fetchAuditLogs = () => async (dispatch, getState) => {
  try {
    dispatch(fetchList(REDUCER_NAME));

    let queries = getListQueries(REDUCER_NAME, getState());
    queries = excludeValuesIfNotDefined(queries);
    const params = { ...queries };

    const response = await axios.get(Urls.AUDIT_LOGS_SEARCH, { params: humps.decamelizeKeys(params) });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.auditLogs,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };

    dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
