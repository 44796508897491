import React from 'react';
import _ from 'lodash';
import queryString from 'query-string';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { EMPLOYEE_BONUS_LIST } from 'src/constants/EndpointUrls';
import ShowAllowanceDeductionFormConnectionContainer from '../containers/ShowAllowanceDeductionFormConnectionContainer';

class ShowAllowanceDeductions extends React.Component {
  componentDidMount() {
    const { allGroupsAndSubgroups, csvAllowanceDeductionFormats, allClientBonus } = this.props;

    allGroupsAndSubgroups(true);
    csvAllowanceDeductionFormats();
    allClientBonus(true);
  }

  render() {
    const { match, location } = this.props;
    const clientBonusId = _.get(queryString.parse(location.search), 'client_bonus_id');

    return (
      <div className="l-wrap-xl l-contents-wrap">
        <BreadCrumb>
          <BreadCrumb.Item label="賞与一覧" path={EMPLOYEE_BONUS_LIST} />
          <BreadCrumb.Item label="支給・控除等一覧表" />
        </BreadCrumb>
        <div className="l-title-wrap">
          <h1 className="m-title-main">支給・控除等一覧表</h1>
          <p className="m-title-main-note">
            支給した賞与ごとの支給項目・控除項目・事業主負担分の値などをまとめて確認できます。<br />
            出力したい項目と従業員（グループ）のフォーマットをあらかじめ設定してください。<br />
            ※従業員の所属するグループについては賞与確定時のグループで出力されます。
          </p>
        </div>
        <ShowAllowanceDeductionFormConnectionContainer
          csvType={'bonus'}
          back={match.path}
          clientBonusId={clientBonusId}
        />
      </div>
    );
  }
}

export default ShowAllowanceDeductions;
