import React from 'react';
import { redirectTo, detailUrlWithQueries } from 'src/utils/Http';
import { ArrowDoubleNext, ArrowDoublePrev } from 'jbc-front/components/icons';
import styles from './NextAndPrevRecordButton.scss';

const NextAndPrevRecordButton = ({ nextInfo, prevInfo, urlFormat }) => {
  const { nextId, nextEmployeeName, nextQueries } = nextInfo || {};
  const { prevId, prevEmployeeName, prevQueries } = prevInfo || {};
  const nextRecordUrl = detailUrlWithQueries(nextId, nextQueries, urlFormat);
  const prevRecordUrl = detailUrlWithQueries(prevId, prevQueries, urlFormat);

  return (
    <div className={styles.wrap}>
      {prevRecordUrl && (
        <div className={styles.prev} onClick={() => redirectTo(prevRecordUrl)} role="button" tabIndex="0">
          <ArrowDoublePrev size={15} />
          {prevEmployeeName}
        </div>
      )}
      {prevRecordUrl && nextRecordUrl && <div className={styles.bar}>｜</div>}
      {nextRecordUrl && (
        <div className={styles.next} onClick={() => redirectTo(nextRecordUrl)} role="button" tabIndex="0">
          {nextEmployeeName}
          <ArrowDoubleNext size={15} />
        </div>
      )}
    </div>
  );
};

export default NextAndPrevRecordButton;
