import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import CommonModal from 'jbc-front/components/CommonModal';
import { DateField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { isDateStringFormat, validatePaymentDateRange } from 'src/utils/CustomValidators';
import { CHANGE_PAYMENT_DATE_MODAL_FORM } from 'src/constants/FormNames';
import { getExtras, getList } from 'src/reducers/common/listReducer';
import ErrorsSection from 'src/components/ErrorsSection';
import { REDUCER_NAME, changePaymentDate, getChangePaymentDateErrors } from 'src/reducers/employees/employeePays';
import styles from './ChangePaymentDateModal.scss';

const PaymentDateModal = props => {
  const { isOpen, invalid, errors, hideModal, handleSubmit, submitting, pristine, submit } = props;

  return (
    <CommonModal isOpen={isOpen} hideModal={hideModal} closeOnClick ariaHideApp={false}>
      <CommonModal.Header hideModal={hideModal}>支給日編集</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit} className="changePaymentDate">
          <ErrorsSection errors={errors} />
          <DateField
            name="paymentDate"
            label="支給日"
            validate={[isDateStringFormat, validatePaymentDateRange]}
            required
          />
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <div className={styles.confirm}>
          <Button onClick={hideModal} className="u-mr20">
            キャンセル
          </Button>
          <Button primary text="保存" onClick={submit} disabled={submitting || pristine || invalid}>
            保存
          </Button>
        </div>
      </CommonModal.Footer>
    </CommonModal>
  );
};

const mapStateToProps = state => ({
  initialValues: {
    ...getExtras(REDUCER_NAME, state),
    paymentMonthIds: [...new Set(getList(REDUCER_NAME, state).map(item => item.employeePaymentMonthId))]
  },
  errors: getChangePaymentDateErrors(state)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => {
    dispatch(changePaymentDate(data, ownProps.hideModal));
  }
});
const ChangePaymentDateModal = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: CHANGE_PAYMENT_DATE_MODAL_FORM,
    enableReinitialize: true
  })
)(PaymentDateModal);

export default ChangePaymentDateModal;
