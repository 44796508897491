import React from 'react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';

import Button from 'jbc-front/components/Button';
import { Glass } from 'jbc-front/components/icons';

import ReactPaginate from 'react-paginate';
import AngleLeft from 'react-icons/lib/fa/angle-double-left';
import AngleRight from 'react-icons/lib/fa/angle-double-right';
import styles from './CitySearchModal.scss';

const CitiesTable = ({ cities, onSelectCity }) => (
  <div className="l-overflow-scroll">
    <ul className={styles.list}>
      {cities.map(city => (
        <li role="presentation" key={city.id} className={styles.listInner} onClick={() => onSelectCity(city)}>
          {city.organizationCode}：{city.nameWithPrefecture}
        </li>
      ))}
    </ul>
  </div>
);

class CitySearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.onInputChange = debounce(this._handleSearch, 500);
  }

  onPageChange = page => {
    this._handleSearch(page + 1);
  };

  _handleSearch = (page = 1) => {
    this.props.searchCities(this.textInput.value, page);
  };

  selectCity = city => {
    this.props.onSelectCity(city);
    this.props.hideModal();
  };

  removeSelectedCity = () => {
    this.props.onSelectCity(undefined);
    this.props.hideModal();
  };

  render() {
    const { cities, searchCount, currentPage, totalPages, selectedCity } = this.props;

    return (
      <div className={styles.wrap}>
        {/* Seach area */}
        <div className={styles.search}>
          <div className={styles.inputText}>
            <input
              type="text"
              placeholder="市区町村コード・市区町村名で検索"
              ref={input => {
                this.textInput = input;
              }}
              onChange={this.onInputChange}
            />
            <span className={styles.icon}>
              <Glass size={16} />
            </span>
          </div>
          <div className={styles.action}>
            {!isEmpty(selectedCity) && <Button onClick={() => this.removeSelectedCity()}>選択を解除する</Button>}
          </div>
        </div>

        <div className={styles.result}>{searchCount} 件の検索結果</div>

        {/* Cities area */}
        {cities.length && <CitiesTable cities={cities} onSelectCity={this.selectCity} />}

        {/* Pagination area */}
        <div className={styles.pager}>
          <ReactPaginate
            pageCount={totalPages}
            pageRangeDisplayed={4}
            marginPagesDisplayed={2}
            initialPage={currentPage - 1}
            forcePage={currentPage - 1}
            onPageChange={e => this.onPageChange(e.selected)}
            containerClassName="pagination"
            activeClassName="active"
            disableInitialCallback
            previousLabel={<AngleLeft style={{ verticalAlign: 'baseline' }} />}
            nextLabel={<AngleRight style={{ verticalAlign: 'baseline' }} />}
          />
        </div>
      </div>
    );
  }
}

export default CitySearchForm;
