import React from 'react';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import { CheckboxField, TextField, BoxDouble } from 'jbc-front/components/Form';
import styles from './BusinessNameForm.scss';

const BusinessNameForm = () => {
  const isUseBusinessName = useSelector(state =>
    get(getEmployeeFormValues(state), ['employee', 'useBusinessName'], false)
  );

  return (
    <div>
      <CheckboxField name="useBusinessName" label="ビジネスネームを表示名、ジョブカン他サービスとの連携名に使用する" />
      <p className={styles.note}>チェックがない場合は上記姓名がご利用中のジョブカン他サービスに自動反映されます</p>
      {isUseBusinessName && (
        <BoxDouble>
          <TextField label="ビジネスネーム (姓)" required type="text" name="businessLastName" maxLength="32" />
          <TextField label="ビジネスネーム (名)" required type="text" name="businessFirstName" maxLength="32" />
        </BoxDouble>
      )}
    </div>
  );
};

export default BusinessNameForm;
