import React from 'react';
import { connect } from 'react-redux';

import { SearchReactSelect } from 'src/components';
import { formatDate } from 'src/utils/Date';

import { EMPLOYEE_PAY_EDIT } from 'src/constants/EndpointUrls';
import { setCurrentPayId, changeLocationPathname } from 'src/reducers/employees/employeePays';

import styles from './AllPays.scss';

const buildTimeString = employeePay => {
  let str = '';
  if (employeePay.targetStartDate) {
    str += `対象期間 ${formatDate(employeePay.targetStartDate)} 〜 ${formatDate(employeePay.targetEndDate)}`;
  }
  if (employeePay.attendanceTargetStartDate && employeePay.attendanceTargetStartDate !== employeePay.targetStartDate) {
    str += `（勤怠対象期間: ${formatDate(employeePay.attendanceTargetStartDate)} ~ ${formatDate(
      employeePay.attendanceTargetEndDate
    )}）`;
  }
  return (
    <React.Fragment>
      <div>{str}</div>
      <div className={styles.paymentDate}>支給日{formatDate(employeePay.paymentDate)}</div>
    </React.Fragment>
  );
};

const AllPays = ({ allEmployeePays = [], employeePay, onChange }) => (
  <div>
    <div className={styles.center}>
      <SearchReactSelect
        value={employeePay.id}
        options={allEmployeePays.map(pay => {
          const label = pay.isConfirmed ? (
            <span>
              <span>{pay.labelWithClosing}</span>
              <span id="confirmed-label">※確定済</span>
            </span>
          ) : (
            pay.labelWithClosing
          );
          return {
            value: pay.id,
            label
          };
        })}
        onChange={pay => onChange(pay.value)}
        width={350}
        size="large"
        cache={false}
      />
    </div>
    <div className={styles.month}>
      <div className={styles.monthCaption}>{buildTimeString(employeePay)}</div>
    </div>
  </div>
);

const mapDispatchToProps = dispatch => ({
  onChange: payId => {
    dispatch(changeLocationPathname(EMPLOYEE_PAY_EDIT, { id: payId }));
    dispatch(setCurrentPayId(payId));
  }
});

export default connect(null, mapDispatchToProps)(AllPays);
