import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import { uploadCsv, getImportStatus } from 'src/reducers/clients/groups';
import { GROUP_CSV_IMPORT_FORM } from 'src/constants/FormNames';
import InsertBatchGroupCsv from '../components/InsertBatchGroupCsv';

const mapStateToProps = state => ({
  importing: getImportStatus(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(uploadCsv(data))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: GROUP_CSV_IMPORT_FORM,
    enableReinitialize: true
  })
)(InsertBatchGroupCsv);
