import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

import { Copy, Delete } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';

import { concatParamsToUrl } from 'src/utils/Http';
import * as Urls from 'src/constants/EndpointUrls';
import { PAYROLL_RULES_GROUP_PAYMENT_ON_WEEKEND_CASE_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { getDeletePayrollRulesGroupError, clearErrors } from 'src/reducers/clients/payrollRulesGroups';
import styles from './PayrollRulesGroupTableRow.scss';

const payrollRuleEditUrl = id => concatParamsToUrl(Urls.CLIENT_PAYROLL_RULES_GROUP_EDIT, { id });
const allowancesListUrl = payrollRulesGroupId =>
  concatParamsToUrl(Urls.CLIENT_ALLOWANCES_LIST, { payrollRulesGroupId });
const copyPayrollRulesGroupUrl = id => concatParamsToUrl(Urls.COPY_CLIENT_PAYROLL_RULES_GROUP_URL, { id });

const ConfirmationBoxBodyInsideForm = ({ onConfirmClick, onCancelClick, isOpen }) => {
  const errors = useSelector(state => getDeletePayrollRulesGroupError(state));
  const dispatch = useDispatch();
  const cancelHandler = () => {
    dispatch(clearErrors());
    onCancelClick();
  };

  return (
    <CommonModal isOpen={isOpen} hideModal={cancelHandler} ariaHideApp={false} closeOnClick>
      <CommonModal.Header hideModal={cancelHandler}>削除確認</CommonModal.Header>
      <CommonModal.Body>
        <ErrorsSection errors={errors} />
        給与規定グループを削除しますか？
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={cancelHandler} className="u-mr20">
          いいえ
        </Button>
        <Button onClick={onConfirmClick} primary>
          はい
        </Button>
      </CommonModal.Footer>
    </CommonModal>
  );
};

const ConfirmationBoxBody = reduxForm({
  form: PAYROLL_RULES_GROUP_PAYMENT_ON_WEEKEND_CASE_FORM
})(ConfirmationBoxBodyInsideForm);

class PayrollRulesGroupTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.setModalIsOpen = this.setModalIsOpen.bind(this);
    this.showConfirmBox = this.showConfirmBox.bind(this);
    this.hideConfirmBox = this.hideConfirmBox.bind(this);

    this.state = {
      isOpen: false
    };
  }

  setModalIsOpen(isOpen) {
    this.setState({
      ...this.state,
      isOpen
    });
  }

  showConfirmBox() {
    this.setModalIsOpen(true);
  }

  hideConfirmBox() {
    this.setModalIsOpen(false);
  }

  handleEvent = () => {
    const { id, onDelete } = this.props;
    onDelete(id);
  };

  render() {
    const { id, name, closingLabel, paymentLabel, employeesCount, hasConfirmedPays } = this.props;

    return (
      <tr>
        <td className={styles.rule}>
          <a href={payrollRuleEditUrl(id)}>{name}</a>
        </td>
        <td>
          {closingLabel} / {paymentLabel}
        </td>
        <td className={styles.allowance}>
          <a href={allowancesListUrl(id)}>支給・控除設定</a>
        </td>
        <td>
          <div className={styles.icons}>
            <div className={styles.noteContainer}>
              <a href={copyPayrollRulesGroupUrl(id)}>
                <Copy size={18} />
              </a>
              <div className={styles.note}>コピーして作成</div>
            </div>
            {employeesCount === 0 && !hasConfirmedPays && <Delete size={18} onClick={() => this.showConfirmBox()} />}
            <ConfirmationBoxBody
              isOpen={this.state.isOpen}
              onConfirmClick={this.handleEvent}
              onCancelClick={this.hideConfirmBox}
            />
          </div>
        </td>
      </tr>
    );
  }
}

export default PayrollRulesGroupTableRow;
