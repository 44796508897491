import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { setList } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME } from 'src/reducers/clients/occupations';
import { getSelectOptions } from 'src/reducers/selectOptions';

import EditableListButtons from './containers/EditableListButtons';
import EditableListFormed from './containers/EditableListFormed';

const OccupationsList = ({ occupations, dispatch, anyApiRunning }) => {
  dispatch(setList(REDUCER_NAME, occupations));

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">職種設定</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="職種設定" />
          </BreadCrumb>
        </div>
        <EditableListButtons anyApiRunning={anyApiRunning} />
        <EditableListFormed type="職種" />
      </div>
    </div>
  );
};

export default connect(state => ({
  anyApiRunning: getSelectOptions(state, 'anyApiRunning')
}))(OccupationsList);
