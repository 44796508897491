import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, FieldArray, change } from 'redux-form';

import { INHABITANT_TAX_FB_DATA_FORM } from 'src/constants/FormNames';
import { CHANGED } from 'src/constants/Options';
import ActionButton from 'jbc-front/components/ActionButton';
import { SelectField, RadioField, TextField } from 'jbc-front/components/Form';
import { PlusSquare, Delete } from 'jbc-front/components/icons';
import { isNumber, minValue1, isRequired, maxLength5, maxLength10, maxLength12 } from 'src/utils/CustomValidators';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { RequiredLabel } from 'src/components';
import styles from './InhabitantTaxOfRetirementSection.scss';

const InhabitantTaxLabel = ({ input: { value } }) => <label>{value}</label>;

const InhabitantTaxOfRetirementItem = ({ remove, residentTaxPayments, formKey }) => {
  const dispatch = useDispatch();

  // テキストボックスを書き換えた時のイベントハンドラ
  const handlePriceChange = targetLabelName => event => {
    let label = event.target.value;
    label =
      /\D/.test(label) || Number(label) <= 0
        ? '' // エラー文言が表示されるので、空文字を設定
        : `${Number(label).toLocaleString()} 円`;
    dispatch(change(INHABITANT_TAX_FB_DATA_FORM, `${formKey}.${targetLabelName}`, label));
  };

  return (
    <tr>
      <td>
        <SelectField
          noLabel
          name={`${formKey}.clientResidentTaxPaymentId`}
          options={residentTaxPayments}
          validate={isRequired}
        />
      </td>
      <td>
        <TextField
          noLabel
          name={`${formKey}.numberEmployees`}
          maxLength="5"
          validate={[isRequired, isNumber, minValue1, maxLength5]}
        />
      </td>
      <td>
        <TextField
          noLabel
          name={`${formKey}.paymentAmount`}
          maxLength="12"
          validate={[isRequired, isNumber, minValue1, maxLength12]}
          onChange={handlePriceChange('paymentAmountPreview')}
        />
        <Field name={`${formKey}.paymentAmountPreview`} component={InhabitantTaxLabel} />
      </td>
      <td>
        <TextField
          noLabel
          name={`${formKey}.cityTaxAmount`}
          maxLength="10"
          validate={[isRequired, isNumber, minValue1, maxLength10]}
          onChange={handlePriceChange('cityTaxAmountPreview')}
        />
        <Field name={`${formKey}.cityTaxAmountPreview`} component={InhabitantTaxLabel} />
      </td>
      <td>
        <TextField
          noLabel
          name={`${formKey}.prefectureTaxAmount`}
          maxLength="10"
          validate={[isRequired, isNumber, minValue1, maxLength10]}
          onChange={handlePriceChange('prefectureTaxAmountPreview')}
        />
        <Field name={`${formKey}.prefectureTaxAmountPreview`} component={InhabitantTaxLabel} />
      </td>
      <td>
        <RadioField noLabel name={`${formKey}.changed`} options={CHANGED} validate={isRequired} />
      </td>
      <td>
        <ActionButton onClick={remove}>
          <Delete />
        </ActionButton>
      </td>
    </tr>
  );
};

const InhabitantTaxOfRetirements = ({ fields }) => {
  const residentTaxPayments = useSelector(state => getSelectOptions(state, 'residentTaxPayments'));
  return (
    <div className={styles.wrap}>
      <table className={`${styles.table} m-table-list`}>
        <colgroup>
          <col width={200} />
          <col width={110} />
          <col width={164} />
          <col width={140} />
          <col width={140} />
          <col width={71} />
          <col width={50} />
        </colgroup>
        <thead>
          <tr>
            <th>市区町村 {fields.length > 0 && <RequiredLabel />}</th>
            <th>人員 {fields.length > 0 && <RequiredLabel />}</th>
            <th>支払い金額 {fields.length > 0 && <RequiredLabel />}</th>
            <th>市町村民税 {fields.length > 0 && <RequiredLabel />}</th>
            <th>道府県民税 {fields.length > 0 && <RequiredLabel />}</th>
            <th>異動 {fields.length > 0 && <RequiredLabel />}</th>
            <th>削除</th>
          </tr>
        </thead>
        <tbody>
          {residentTaxPayments && residentTaxPayments.length > 0 ? (
            fields.map((item, index) => (
              <InhabitantTaxOfRetirementItem
                key={item}
                formKey={item}
                remove={() => fields.remove(index)}
                residentTaxPayments={residentTaxPayments}
              />
            ))
          ) : (
            <tr>
              <td colSpan={7}>
                <div>
                  住民税納付先市区町村の情報がありません。各従業員の住民税を設定して、住民税納付先市区町村を設定してください。
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {residentTaxPayments &&
        residentTaxPayments.length > 0 && (
          <ActionButton
            icon={<PlusSquare size={11} />}
            className="u-mt10"
            onClick={() => fields.push({ changed: '0' })}
          >
            追加
          </ActionButton>
        )}
    </div>
  );
};

const InhabitantTaxOfRetirementSection = () => (
  <FieldArray name="inhabitantTaxRetirements" component={InhabitantTaxOfRetirements} />
);

export default InhabitantTaxOfRetirementSection;
