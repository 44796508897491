import React, { Component } from 'react';
import classNames from 'classnames';
import styles from './Balloon.scss';

/**
 * 吹き出しを表示するためのコンポーネント
 * 表示位置を変更する際はballoonPositionに値を渡してください（デフォルトは上に表示されます）
 * top: 上
 * bottom: 下
 * 左右は使用用途がないため定義していので、必要であれば適宜追加して下さい
 *
 * 表示がずれた場合は、classNameに渡すstyleのtopとleftを適宜調整してください
 * 横幅はmin-widthのみ指定してあるので、固定したい場合はwidthを設定してください
 * 問題があれば修正をお願いします
 */
class Balloon extends Component {
  render() {
    const { children, className } = this.props;
    const position = () => {
      const { balloonPosition, arrowPosition } = this.props;
      switch (balloonPosition) {
        case 'top':
          switch (arrowPosition) {
            case 'left':
              return styles.balloonPositionTopAllowLeft;
            case 'center':
              return styles.balloonPositionTopAllowCenter;
            default:
              return styles.balloonPositionTopAllowLeft;
          }
        case 'bottom':
          return styles.balloonPositionBottom;
        default:
          return styles.balloonPositionTopAllowLeft;
      }
    };

    return (
      <div className={classNames(position(), className)}>
        <div className={classNames(styles.balloon)}>{children}</div>
      </div>
    );
  }
}

export default Balloon;
