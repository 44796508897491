import React from 'react';
import { FormSection, FieldArray } from 'redux-form';
import classNames from 'classnames';

import scrollSpy, { AnchorTarget } from 'jbc-front/components/ScrollSpy';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import Button from 'jbc-front/components/Button';
import { Section } from 'jbc-front/components/Form';

import { CLIENT_SETTINGS, OFFICE_SHOW } from 'src/constants/EndpointUrls';
import * as CssClassNames from 'src/constants/CssClassNames';
import FormErrors from 'src/components/FormErrors';
import { concatParamsToUrl } from 'src/utils/Http';

import OfficeManageSection from './edit/OfficeManageSection';
import OfficeSection from '../containers/edit/OfficeSectionContainer';
import LabourConsultantSection from '../containers/edit/LabourConsultantSectionContainer';
import BanksSection from './edit/BanksSection';
import SocialInsuranceSection from '../containers/edit/SocialInsuranceSectionContainer';
import LabourInsuranceSection from '../containers/edit/LabourInsuranceSectionContainer';
import LabourInsuranceContactInfoSection from '../containers/edit/LabourInsuranceContactInfoSectionContainer';
import SideNavigation from './SideNavigation';
import styles from './EditOffice.scss';

const EditOffice = scrollSpy(props => {
  const { handleSubmit, submit, isOnlyMainOffice, errors, pristine, submitting, isInitialized, isMainOffice } = props;
  const showUrl = concatParamsToUrl(OFFICE_SHOW, { id: props.initialValues.office.id });
  return (
    <div className="l-wrap-xl l-contents-wrap">
      <div className="u-mb20">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="事業所情報" path={showUrl} />
          <BreadCrumb.Item label="事業所情報編集" />
        </BreadCrumb>
      </div>
      <div className="l-wrap-xl">
        <div className="l-title-wrap">
          <h1 className="m-title-main">事業所情報編集</h1>
        </div>
      </div>
      <div className="l-flex">
        <div className="l-flex6-sub">
          <SideNavigation
            submittng={submitting}
            pristine={pristine}
            submit={submit}
            isOnlyMainOffice={isOnlyMainOffice}
            isMainOffice={isMainOffice}
            isEdit
          />
        </div>
        <div className="l-flex6-main">
          <form
            className={classNames(CssClassNames.FORM, CssClassNames.CLIENT_FORM, styles.form)}
            onSubmit={handleSubmit}
          >
            <FormErrors errors={errors} />

            {!isOnlyMainOffice && (
              <React.Fragment>
                <AnchorTarget name="OfficeManageInformation" />
                <Section title="事業所管理情報">
                  <FormSection name="office">
                    <OfficeManageSection />
                  </FormSection>
                </Section>
              </React.Fragment>
            )}

            <AnchorTarget name="OfficeInformation" />
            <Section title="事業所情報">
              <FormSection name="office">
                <OfficeSection />
              </FormSection>
            </Section>

            <AnchorTarget name="SocialInsuranceInformation" />
            <Section title="社会保険情報（健康保険・厚生年金保険）">
              <SocialInsuranceSection isInitialized={isInitialized} isMainOffice={isMainOffice} />
            </Section>

            <AnchorTarget name="LabourInsuranceInformation" />
            <Section title="労働保険情報（労災保険・雇用保険）">
              {isMainOffice && <LabourInsuranceContactInfoSection />}
              <LabourInsuranceSection isInitialized={isInitialized} isMainOffice={isMainOffice} />
            </Section>

            {isMainOffice && (
              <React.Fragment>
                <AnchorTarget name="LabourConsultantInformation" />
                <Section title="社労士情報">
                  <FormSection name="clientLabourConsultant">
                    <LabourConsultantSection />
                  </FormSection>
                </Section>

                <AnchorTarget name="BankInformation" />
                <Section title="振込元口座情報">
                  <div>
                    <FieldArray name="clientBanks" component={BanksSection} />
                  </div>
                </Section>
              </React.Fragment>
            )}

            <div className="u-ta-c u-mt20">
              <Button as="a" href={showUrl} className="u-mr20">
                キャンセル
              </Button>
              <Button disabled={pristine || submitting} onClick={submit} primary>
                保存
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default EditOffice;
