import React from 'react';
import { reduxForm } from 'redux-form';

import Button from 'jbc-front/components/Button';
import { Section, TextField } from 'jbc-front/components/Form';

import { CLIENT_COMMUTING_FEE_SETTING_FORM } from 'src/constants/FormNames';

import { isMoneyNumber } from 'src/utils/CustomValidators';
import { FormErrors } from 'src/components';

const ClientCommutingFeeSettingForm = ({ errors, submit, invalid, pristine, submitting, handleSubmit }) => (
  <div>
    <form onSubmit={handleSubmit}>
      <FormErrors errors={errors} />

      <Section>
        <p className="u-mb10">通勤手段「電車」「バス」の支給される通勤手当に上限を設定する事ができます。</p>
        <p className="u-mb10">
          給与で支給される通勤手当は、従業員情報に基いて自動計算されます。<br />こちらの設定を行うと、支給する通勤手当の金額が、上限設定の値を超えた場合に、上限設定の値に変更されます。
        </p>
        <p className="u-mb10">
          上限の設定は支給単位「1ヶ月」「3ヶ月」「6ヶ月」でそれぞれ設定でき、「出勤日数に応じて毎月支給」の場合は「1ヶ月」の上限が適用されます。
        </p>
        <ul className="m-list-notes u-mb20">
          <li>※通勤手段が「電車」「バス」のみ対象となり、「車」は適用されません。</li>
          <li>※支給方法が「現物」の場合は適用されません。</li>
        </ul>

        <TextField label="1ヶ月" name="maximumAmountPerMonth" yen validate={isMoneyNumber} />
        <TextField label="3ヶ月" name="maximumAmountPer3Months" yen validate={isMoneyNumber} />
        <TextField label="6ヶ月" name="maximumAmountPer6Months" yen validate={isMoneyNumber} />
        <div className="u-ta-c u-mt40">
          <Button disabled={pristine || submitting || invalid} onClick={submit} primary>
            保存
          </Button>
        </div>
      </Section>
    </form>
  </div>
);

export default reduxForm({
  form: CLIENT_COMMUTING_FEE_SETTING_FORM
})(ClientCommutingFeeSettingForm);
