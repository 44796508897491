import React from 'react';
import { connect } from 'react-redux';

import { Section } from 'jbc-front/components/Form';
import { getOptionLabel, display } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { Checked } from 'src/icons';
import tableStyles from '../styles/GeneralTableInfo.scss';

const GeneralInsuranceInfoSection = ({ employeeInsurance, insuredPersonTypes }) => (
  <div>
    <Section title="被保険者区分">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>被保険者区分：</th>
            <td>{display(getOptionLabel(employeeInsurance.insuredPersonType)(insuredPersonTypes))}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const GeneralInsuranceInfoSectionShow = connect(state => ({
  insuredPersonTypes: getSelectOptions(state, 'insuredPersonTypes')
}))(GeneralInsuranceInfoSection);

const HealthInsuranceSectionShow = ({ employeeInsurance }) => (
  <div>
    <Section title="健康保険">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>加入状況：</th>
            <td>{display(employeeInsurance.isJoinedHealthInsurance ? '加入' : '未加入')}</td>
          </tr>
          <tr>
            <th>健康保険被保険者整理番号：</th>
            <td>{display(employeeInsurance.healthInsuranceInsuredPersonnelNumber)}</td>
          </tr>
          <tr>
            <th>資格取得日：</th>
            <td>{display(formatDate(employeeInsurance.healthCertificateAcquisitionOn))}</td>
          </tr>
          <tr>
            <th>資格喪失日：</th>
            <td>{display(formatDate(employeeInsurance.healthCertificateExpiredOn))}</td>
          </tr>
          <tr>
            <th>介護保険料免除対象者：</th>
            <td>
              <Checked checked={employeeInsurance.isNursingInsuranceExemption} />
            </td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const PensionInsuranceSectionShow = ({ employeeInsurance, hasTargetHealthInsuranceAssociations }) => (
  <div>
    <Section title="厚生年金保険">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>加入状況：</th>
            <td>{display(employeeInsurance.isJoinedPensionInsurance ? '加入' : '未加入')}</td>
          </tr>
          <tr>
            <th>基礎年金番号：</th>
            <td>{display(employeeInsurance.pensionBasicNumber)}</td>
          </tr>
          {hasTargetHealthInsuranceAssociations && (
            <tr>
              <th>厚生年金被保険者整理番号：</th>
              <td>{display(employeeInsurance.pensionInsuranceInsuredNumber)}</td>
            </tr>
          )}
          <tr>
            <th>資格取得日：</th>
            <td>{display(formatDate(employeeInsurance.pensionCertificateAcquisitionOn))}</td>
          </tr>
          <tr>
            <th>資格喪失日：</th>
            <td>{display(formatDate(employeeInsurance.pensionCertificateExpiredOn))}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const EmploymentInsuranceSectionShow = ({ employeeInsurance, employeeClassifications }) => (
  <div>
    <Section title="雇用保険">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>加入状況：</th>
            <td>{display(employeeInsurance.isJoinedEmploymentInsurance ? '加入' : '未加入')}</td>
          </tr>
          <tr>
            <th>従業員区分：</th>
            <td>{display(getOptionLabel(employeeInsurance.employeeClassification)(employeeClassifications))}</td>
          </tr>
          <tr>
            <th>雇用保険 被保険者番号：</th>
            <td>{display(employeeInsurance.employmentInsuranceInsuredNumber)}</td>
          </tr>
          <tr>
            <th>資格取得日：</th>
            <td>{display(formatDate(employeeInsurance.employmentCertificateAcquisitionOn))}</td>
          </tr>
          <tr>
            <th>離職等年月日：</th>
            <td>{display(formatDate(employeeInsurance.employmentCertificateExpiredOn))}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const AccidentInsuranceSectionShow = ({ employeeInsurance }) => (
  <div>
    <Section title="労災保険">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>労災保険料の計算対象：</th>
            <td>{display(employeeInsurance.isAccidentInsuranceCalc ? '対象' : '対象外')}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const InsuranceSectionShow = ({ employeeInsurance, hasTargetHealthInsuranceAssociations, employeeClassifications }) => (
  <div>
    <GeneralInsuranceInfoSectionShow {...{ employeeInsurance }} />
    <HealthInsuranceSectionShow {...{ employeeInsurance }} />
    <PensionInsuranceSectionShow {...{ employeeInsurance, hasTargetHealthInsuranceAssociations }} />
    <EmploymentInsuranceSectionShow {...{ employeeInsurance, employeeClassifications }} />
    <AccidentInsuranceSectionShow {...{ employeeInsurance }} />
  </div>
);

export default InsuranceSectionShow;
