import React from 'react';
import ReactPaginate from 'react-paginate';
import PropTypes from 'prop-types';
import AngleLeft from 'react-icons/lib/fa/angle-double-left';
import AngleRight from 'react-icons/lib/fa/angle-double-right';

import styles from './LowerPager.scss';

const LowerPager = ({
  pageCount,
  initialPage,
  forcePage,
  perPage,
  perPages,
  onPageChange,
  onPerPageChange,
  totalCount = 0,
  displayFrom,
  displayTo,
  isLoading
}) => (
  <div className={styles.wrap}>
    <select className={styles.selectPagingParSize} onChange={onPerPageChange} value={perPage}>
      {perPages.map(page => (
        <option key={page.value} value={page.value}>
          {page.label}
        </option>
      ))}
    </select>
    <div className={styles.container}>
      <div className={isLoading ? styles.linkDisable : null}>
        <ReactPaginate
          pageCount={pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={1}
          initialPage={initialPage}
          forcePage={forcePage}
          onPageChange={onPageChange}
          containerClassName="pagination"
          activeClassName="active"
          disableInitialCallback
          previousLabel={<AngleLeft style={{ verticalAlign: 'baseline' }} />}
          nextLabel={<AngleRight style={{ verticalAlign: 'baseline' }} />}
        />
      </div>
      <div className={styles.displayPageEntries}>
        <span>
          {totalCount}件中{displayFrom}-{displayTo}件を表示
        </span>
      </div>
    </div>
  </div>
);
LowerPager.propTypes = {
  pageCount: PropTypes.number.isRequired,
  initialPage: PropTypes.number.isRequired,
  perPage: PropTypes.number.isRequired,
  perPages: PropTypes.array.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onPerPageChange: PropTypes.func.isRequired
};
export default LowerPager;
