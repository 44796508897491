import React from 'react';
import { connect } from 'react-redux';

import { setClientAllowanceItemList } from 'src/reducers/clients/allowanceItems';
import SortableAllowanceItemsComponent from './components/SortableAllowanceItemsComponent';

const ChangeAllowanceItemDisplayOrder = ({ dispatch, clientAllowanceItems }) => {
  dispatch(setClientAllowanceItemList(clientAllowanceItems));
  return <SortableAllowanceItemsComponent />;
};

export default connect()(ChangeAllowanceItemDisplayOrder);
