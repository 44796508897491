import React from 'react';
import { connect } from 'react-redux';
import { parse } from 'query-string';
import { USER_LOGIN } from 'src/constants/EndpointUrls';

import SetNewPasswordForm from './components/SetNewPasswordForm';

class SetNewPassword extends React.Component {
  state = {
    isValidToken: true
  };
  setInvalidToken = () => {
    this.setState({ ...this.state, isValidToken: false });
  };
  render() {
    const {
      location: { search }
    } = this.props;
    const searchData = parse(search);
    return (
      <div className="login">
        {this.state.isValidToken && (
          <SetNewPasswordForm
            resetPasswordToken={searchData.reset_password_token}
            setInvalidToken={this.setInvalidToken}
          />
        )}
        {!this.state.isValidToken && (
          <div>
            <div className="l-title-wrap">
              <h1 className="m-title-main">招待承諾画面</h1>
            </div>
            <div className="l-wrap-xs u-pt100">
              <div className="l-box-message">
                <p>
                  このURLは登録が完了しているか、有効期限が過ぎています。<br />
                  <a href={USER_LOGIN} className="u-txt-link">
                    ログインはこちらから
                  </a>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default connect()(SetNewPassword);
