import React from 'react';

import { EditActionBtn, SaveActionBtn, CancelActionBtn, AddActionBtn, RollbackActionBtn } from 'src/buttons';

import FormErrors from './FormErrors';
import styles from './EditableListButtons.scss';

const EditableListButtons = ({ mode, errors, onEdit, onSave, onCancel, onAddRow, onRollback, anyApiRunning }) => (
  <div>
    {mode === 'SHOW' ? (
      <div>
        <EditActionBtn onClick={onEdit} disabled={anyApiRunning} />
      </div>
    ) : (
      <div className={styles.action}>
        <SaveActionBtn onClick={onSave} />
        <CancelActionBtn onClick={onCancel} />
        <AddActionBtn onClick={onAddRow} label="行を追加" />
        {onRollback && <RollbackActionBtn onClick={onRollback} label="手入力前に戻す" />}
      </div>
    )}
    <FormErrors errors={errors} />
  </div>
);

export default EditableListButtons;
