import React from 'react';
import { connect } from 'react-redux';
import { autofill } from 'redux-form';
import { CLIENT_ALLOWANCE_ITEM_FORM } from 'src/constants/FormNames';

import classNames from 'classnames';
import selector from 'src/utils/Utils';

import { getSelectOptions } from 'src/reducers/selectOptions';

import { CLIENT_ALLOWANCE_ITEMS } from 'src/constants/EndpointUrls';
import { FormSubmitBtn, CancelBtn } from 'src/buttons';
import { ErrorsSection, SimpleConfirmModal } from 'src/components';

import { TextField, SelectField, RadioField, CheckboxField, Label, Section } from 'jbc-front/components/Form';
import * as CssClassNames from 'src/constants/CssClassNames';
import { getAllowanceItemFormValues } from 'src/reducers/clients/allowanceItems';

import styles from './AllowanceItemEditingForm.scss';

const UNIT_TYPE_OPTIONS = [
  { value: 'number_of_days', label: '日数' },
  { value: 'time', label: '時間' },
  { value: 'number_of_times', label: '回数' }
];

const PAYSLIP_DISPLAY_TYPES = [
  { value: 'display_positive_number', label: '0の場合明細に表示しない' },
  { value: 'always', label: '常に明細に表示' },
  { value: 'non_display', label: '明細に表示しない' }
];

const JOBCAN_ITEM_LIST = '/coordinations/sync_jbc_settings';
const DESCRIPTION_URL = 'https://jobcan-payroll.zendesk.com/hc/ja/articles/360006184814';

const PayrollRulesGroupCheckBoxList = ({ clientPayrollRulesGroups, isEditablePayrollRulesGroup }) => (
  <div>
    <Label text="適用給与規定グループ" />
    <p className={styles.note}>チェックオフの場合、勤怠データを取り込むことができません</p>
    <div className={styles.boxlistWrap}>
      {clientPayrollRulesGroups.map(group => (
        <CheckboxField
          key={group.value}
          name={`payrollRulesGroups["${group.value}"]`}
          label={group.label}
          width={10}
          disabled={!isEditablePayrollRulesGroup}
        />
      ))}
    </div>
  </div>
);

class AllowanceItemEditingForm extends React.Component {
  state = {
    unitTypeModalOpen: false
  };

  openModal = () => {
    this.setState({ ...this.state, unitTypeModalOpen: true });
  };

  closeModal = () => {
    this.setState({ ...this.state, unitTypeModalOpen: false });
  };

  selectableJobcanOptions = () => {
    const { selectingUnitType, selectableJobcanRelatedColumn } = this.props;
    return selectableJobcanRelatedColumn.filter(item => item.unitType === selectingUnitType);
  };

  isPredeterminedWorkingTime = itemCode => itemCode === 'predetermined_working_time';

  render() {
    const {
      submitting,
      submit,
      handleSubmit,
      errors,
      clientPayrollRulesGroups,
      unitTypeChangeable,
      resetJobcanAttendanceRelatedColumnName,
      isCooperateWithJobcan,
      isEditablePayrollRulesGroup,
      isEditableJobcanAttendanceRelation,
      initialValues,
      selectingUnitType,
      id,
      orgUnitType,
      officeStationMappingDayItems,
      officeStationMappingTimeItems,
      isSyncOfficeStation,
      isAvailableOfficeStation,
      clientOfficeStationSetting
    } = this.props;
    const { itemCode, originalName } = initialValues;
    const submitAction = id && selectingUnitType !== orgUnitType ? this.openModal : submit;
    const officeStationMappingAttendanceItems = (() => {
      // 単位が日数か時間の場合のみオフィスステーションと連携可能
      switch (selectingUnitType) {
        case 'number_of_days':
          return officeStationMappingDayItems;
        case 'time':
          return officeStationMappingTimeItems;
        default:
          return null;
      }
    })();

    return (
      <div>
        <Section>
          <form className={classNames(CssClassNames.FORM, CssClassNames.EMPLOYEE_FORM)} onSubmit={handleSubmit}>
            <div>
              <ErrorsSection errors={errors} />
              <TextField
                name="name"
                label="項目名"
                required
                maxLength="30"
                note={
                  <div>
                    給与明細に表示される名称です。
                    項目名を変更すると、「給与明細」「賃金台帳」「支給・控除等一覧表」での項目名がすべて変更されます。
                    ※項目名の変更のみで、データは残ります
                  </div>
                }
              />
              {itemCode && <p className={styles.payslipNote}>※元の項目名：{originalName}</p>}

              <RadioField
                label="単位"
                name="unitType"
                options={UNIT_TYPE_OPTIONS}
                onChange={resetJobcanAttendanceRelatedColumnName}
                disabled={!unitTypeChangeable}
              />
              <SelectField name="payslipDisplayType" label="明細表示" options={PAYSLIP_DISPLAY_TYPES} />
              <p className={styles.payslipNote}>給与明細に表示するかどうかの条件を設定できます</p>
              <PayrollRulesGroupCheckBoxList
                clientPayrollRulesGroups={clientPayrollRulesGroups}
                isEditablePayrollRulesGroup={isEditablePayrollRulesGroup}
              />

              <Label text="ジョブカン連携" />

              {!this.isPredeterminedWorkingTime(itemCode) && (
                <React.Fragment>
                  <CheckboxField
                    name="isCooperateWithJobcanAttendance"
                    label="ジョブカン勤怠管理と連携する"
                    onChange={resetJobcanAttendanceRelatedColumnName}
                    disabled={!isEditableJobcanAttendanceRelation}
                    noteWidth={340}
                    note={
                      <div>
                        <ul className={styles.listOrder}>
                          <li>
                            ※１）ジョブカン勤怠管理との連携は<a href={JOBCAN_ITEM_LIST}>コチラ</a>で別途行ってください。
                          </li>
                          <li>
                            ※２）各項目の説明については<a href={DESCRIPTION_URL}>コチラ</a>をご覧ください。
                          </li>
                        </ul>
                      </div>
                    }
                  />
                  {isCooperateWithJobcan && (
                    <SelectField
                      name="jobcanAttendanceRelatedColumnName"
                      width={450}
                      options={this.selectableJobcanOptions()}
                      disabled={!isEditableJobcanAttendanceRelation}
                    />
                  )}
                </React.Fragment>
              )}
              {this.isPredeterminedWorkingTime(itemCode) && (
                <p className="u-ml20">
                  ジョブカン連携時は勤怠管理から取得した「所定労働日数（当月）」×給与規定の「1日の所定労働時間」の値が自動で入ります。
                </p>
              )}
              {isAvailableOfficeStation &&
                clientOfficeStationSetting &&
                officeStationMappingAttendanceItems && (
                  <div>
                    <CheckboxField
                      name="isSyncOfficeStation"
                      label="オフィスステーションと連携する"
                      normalize={value => !!value}
                    />
                    {isSyncOfficeStation && (
                      <SelectField
                        name="officeStationItemName"
                        addBlankOption={false}
                        options={officeStationMappingAttendanceItems}
                      />
                    )}
                  </div>
                )}
            </div>
          </form>
        </Section>
        <div className="u-ta-c">
          <CancelBtn href={CLIENT_ALLOWANCE_ITEMS} className="u-mr20" />
          <FormSubmitBtn text="保存" {...{ submitting }} submit={submitAction} />
        </div>
        <SimpleConfirmModal
          isOpen={this.state.unitTypeModalOpen}
          hideModal={this.closeModal}
          body={'単位を変更すると入力中のデータは削除されますがよろしいですか。'}
          onClick={() => {
            submit();
            this.closeModal();
          }}
          confirmBtnText={'保存'}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    clientPayrollRulesGroups: getSelectOptions(state, 'clientPayrollRulesGroups'),
    unitTypeChangeable: selector(getAllowanceItemFormValues(state), 'unitTypeChangeable'),
    selectingUnitType: selector(getAllowanceItemFormValues(state), 'unitType'),
    id: selector(getAllowanceItemFormValues(state), 'id'),
    selectableJobcanRelatedColumn: getSelectOptions(state, 'selectableJobcanRelatedColumn'),
    isCooperateWithJobcan: selector(getAllowanceItemFormValues(state), 'isCooperateWithJobcanAttendance'),
    isEditablePayrollRulesGroup: selector(getAllowanceItemFormValues(state), 'isEditablePayrollRulesGroup'),
    isEditableJobcanAttendanceRelation: selector(
      getAllowanceItemFormValues(state),
      'isEditableJobcanAttendanceRelation'
    ),
    officeStationMappingDayItems: getSelectOptions(state, 'officeStationMappingDayItems'),
    officeStationMappingTimeItems: getSelectOptions(state, 'officeStationMappingTimeItems'),
    isSyncOfficeStation: selector(getAllowanceItemFormValues(state), 'isSyncOfficeStation'),
    isAvailableOfficeStation: state.currentClient.item.data.isAvailableOfficeStation,
    clientOfficeStationSetting: state.currentClient.item.data.clientOfficeStationSetting
  }),
  dispatch => ({
    resetJobcanAttendanceRelatedColumnName: () => {
      dispatch(autofill(CLIENT_ALLOWANCE_ITEM_FORM, 'jobcanAttendanceRelatedColumnName', ''));
    }
  })
)(AllowanceItemEditingForm);
