import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';

import ActionButton from 'jbc-front/components/ActionButton';
import { MinusCircle } from 'jbc-front/components/icons';

import { EditActionBtn, SaveActionBtn } from 'src/buttons';
import { FormErrors } from 'src/components';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { REPORT_LABOR_INSURANCE_DECLARATION_FORM } from 'src/constants/FormNames';
import { REPORT_LABOR_INSURANCE_DECLARATIONS_PDF } from 'src/constants/EndpointUrls';
import { getItem, getItemExtra } from 'src/reducers/common/itemReducer';
import {
  REDUCER_NAME,
  editDeclarationForm,
  setDeclarationFormShowMode,
  clearDeclarationFormErrors,
  isLessThan2020Year,
  is2021Year,
  is2022Year
} from 'src/reducers/reports/reportLaborInsurances';
import DeclarationFormWorkerCount from './DeclarationFormWorkerCount';
import DeclarationFormConfirmedInsuranceFee from './DeclarationFormConfirmedInsuranceFee';
import DeclarationFormEstimateInsuranceFee from './DeclarationFormEstimateInsuranceFee';
import DeclarationFormPaymentDeclaredInsurance from '../containers/DeclarationFormPaymentDeclaredInsuranceContainer';
import DeclarationFormPaymentTerm from './DeclarationFormPaymentTerm';
import LaborInsuranceRateModal from './LaborInsuranceRateModal';
import ConfirmRecalcurateDeclarationFormModal from './ConfirmRecalcurateDeclarationFormModal';
import styles from './DeclarationForm.scss';

class DeclarationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPdfPreview: false,
      isUpdateInsuranceRateModalOpen: false,
      isRecalcurateDeclarationFormModalOpen: false
    };

    this.openInsuranceRateModal = this.openInsuranceRateModal.bind(this);
    this.closeInsuranceRateModal = this.closeInsuranceRateModal.bind(this);
    this.openRecalcurateDeclarationFormModal = this.openRecalcurateDeclarationFormModal.bind(this);
    this.closeRecalcurateDeclarationFormModal = this.closeRecalcurateDeclarationFormModal.bind(this);
  }

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };
  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };

  openInsuranceRateModal() {
    if (this.state.isUpdateInsuranceRateModalOpen) return;
    this.setState({ ...this.state, isUpdateInsuranceRateModalOpen: true });
  }

  closeInsuranceRateModal() {
    if (!this.state.isUpdateInsuranceRateModalOpen) return;
    this.setState({ ...this.state, isUpdateInsuranceRateModalOpen: false });
  }

  openRecalcurateDeclarationFormModal() {
    if (this.state.isRecalcurateDeclarationFormModalOpen) return;
    this.setState({ ...this.state, isRecalcurateDeclarationFormModalOpen: true });
  }

  closeRecalcurateDeclarationFormModal() {
    if (!this.state.isRecalcurateDeclarationFormModalOpen) return;
    this.setState({ ...this.state, isRecalcurateDeclarationFormModalOpen: false });
  }

  pdfUrl = laborInsuranceYear =>
    `${REPORT_LABOR_INSURANCE_DECLARATIONS_PDF}?&pdf[labor_insurance_year]=${laborInsuranceYear}`;
  pdfDownloadUrl = laborInsuranceYear =>
    `${REPORT_LABOR_INSURANCE_DECLARATIONS_PDF}?pdf[type]=zip&pdf[labor_insurance_year]=${laborInsuranceYear}`;

  allCalculations = params => {
    const calcResult1 = this.child1.calculation(params);
    const calcResult2 = this.child2.calculation(calcResult1);
    const calcResult3 = this.child3.calculation(calcResult2);
    this.child4.calculation(calcResult3);
  };

  rollbackDeclarationForm() {
    this.allCalculations(this.props.declarationForm);
  }

  render() {
    const {
      pristine,
      submitting,
      actionMode,
      laborInsuranceYear,
      applicableOffice,
      declarationForm,
      reset,
      save,
      changeToEditMode,
      changeToViewMode,
      clearErrorMessages,
      errors,
      isLessThan2020,
      is2021,
      is2022
    } = this.props;
    return (
      <div className="u-mt20">
        {!laborInsuranceYear && (
          <div className={styles.empty_messages}>
            <div>登録済みの給与・賞与データがありません。</div>
          </div>
        )}
        {laborInsuranceYear &&
          !declarationForm && (
            <div className={styles.empty_messages}>
              <div>{laborInsuranceYear}年度に登録済みの給与・賞与データがありません。</div>
              <div>{laborInsuranceYear + 1}年3月締めの給与を確定させると表示されます。</div>
            </div>
          )}
        {declarationForm && (
          <div>
            <div>
              <FormErrors errors={errors} />
              <div className={styles.action}>
                <div>
                  {actionMode === 'SHOW' ? (
                    <React.Fragment>
                      <EditActionBtn
                        onClick={() => {
                          changeToEditMode();
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <SaveActionBtn as="div" onClick={save} disabled={pristine || submitting} className="u-mr10" />
                      <ActionButton
                        onClick={() => {
                          reset();
                          this.rollbackDeclarationForm();
                          clearErrorMessages();
                          changeToViewMode();
                        }}
                        icon={<MinusCircle size={15} />}
                        className="u-mr10"
                      >
                        キャンセル
                      </ActionButton>
                      <ActionButton onClick={this.openRecalcurateDeclarationFormModal} className="u-mr10">
                        再計算
                      </ActionButton>
                      <ActionButton onClick={this.openInsuranceRateModal}>保険料率の入力</ActionButton>
                    </React.Fragment>
                  )}
                </div>
                {/* リリース後に実装
                 <ActionButton primary icon={<Task size={16} />} disabled={actionMode !== 'SHOW'} onClick={this.showPreview}>
                  PDF出力
                </ActionButton>
                */}
              </div>
            </div>
            <div>
              <div className={styles.wrap}>
                <DeclarationFormWorkerCount
                  actionMode={actionMode}
                  laborInsuranceYear={laborInsuranceYear}
                  declarationForm={declarationForm}
                  isLessThan2020={isLessThan2020}
                />
              </div>
              <div className={styles.wrap}>
                <DeclarationFormConfirmedInsuranceFee
                  actionMode={actionMode}
                  declarationForm={declarationForm}
                  isLessThan2020={isLessThan2020}
                  onRef={ref => {
                    this.child1 = ref;
                  }}
                  allCalculations={this.allCalculations}
                  is2022={is2022}
                />
              </div>
              <div className={styles.wrap}>
                <DeclarationFormEstimateInsuranceFee
                  actionMode={actionMode}
                  declarationForm={declarationForm}
                  isLessThan2020={isLessThan2020}
                  onRef={ref => {
                    this.child2 = ref;
                  }}
                  allCalculations={this.allCalculations}
                  is2021={is2021}
                />
              </div>
              <div className={styles.wrap}>
                <DeclarationFormPaymentDeclaredInsurance
                  actionMode={actionMode}
                  declarationForm={declarationForm}
                  onRef={ref => {
                    this.child3 = ref;
                  }}
                  allCalculations={this.allCalculations}
                />
              </div>
              <div className={styles.wrap}>
                <DeclarationFormPaymentTerm
                  actionMode={actionMode}
                  declarationForm={declarationForm}
                  onRef={ref => {
                    this.child4 = ref;
                  }}
                  allCalculations={this.allCalculations}
                />
              </div>
            </div>
          </div>
        )}
        <PreviewPdfModalContainer
          header="申告書"
          isOpen={this.state.isPdfPreview}
          hideModal={this.hidePreview}
          url={this.pdfUrl(laborInsuranceYear)}
          downloadUrl={this.pdfDownloadUrl(laborInsuranceYear)}
        />
        {this.state.isUpdateInsuranceRateModalOpen && (
          <LaborInsuranceRateModal
            isOpen={this.state.isUpdateInsuranceRateModalOpen}
            hideModal={this.closeInsuranceRateModal}
            laborInsuranceYear={laborInsuranceYear}
            applicableOffice={applicableOffice}
            confirmedAccidentInsuranceRate={declarationForm.confirmedInsuranceFeeRateForAccidentInsurance}
            confirmedEmploymentInsuranceRate={
              declarationForm.confirmedInsuranceFeeRateForCalculationTargetOfEmploymentInsurance
            }
            estimateAccidentInsuranceRate={declarationForm.estimateInsuranceFeeRateForAccidentInsurance}
            estimateEmploymentInsuranceRate={
              declarationForm.estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance
            }
            allCalculations={this.allCalculations}
            is2021={is2021}
            is2022={is2022}
          />
        )}
        {this.state.isRecalcurateDeclarationFormModalOpen && (
          <ConfirmRecalcurateDeclarationFormModal
            isOpen={this.state.isRecalcurateDeclarationFormModalOpen}
            hideModal={this.closeRecalcurateDeclarationFormModal}
            laborInsuranceYear={laborInsuranceYear}
            applicableOffice={applicableOffice}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  actionMode: getItemExtra(REDUCER_NAME, state).declarationFormActionMode || 'SHOW',
  declarationForm: getItem(REDUCER_NAME, state),
  initialValues: getItem(REDUCER_NAME, state),
  errors: getItemExtra(REDUCER_NAME, state).editDeclarationFormErrors || [],
  isLessThan2020: isLessThan2020Year(ownProps.laborInsuranceYear),
  is2021: is2021Year(ownProps.laborInsuranceYear),
  is2022: is2022Year(ownProps.laborInsuranceYear)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  save: () => {
    dispatch(editDeclarationForm(ownProps.laborInsuranceYear, ownProps.applicableOffice));
  },
  changeToEditMode: () => {
    dispatch(setDeclarationFormShowMode('EDIT'));
  },
  changeToViewMode: () => {
    dispatch(setDeclarationFormShowMode('SHOW'));
  },
  clearErrorMessages: () => {
    dispatch(clearDeclarationFormErrors());
  }
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: REPORT_LABOR_INSURANCE_DECLARATION_FORM,
    enableReinitialize: true
  })
)(DeclarationForm);
