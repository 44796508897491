import React from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import Cookies from 'js-cookie';
import moment from 'moment';
import { NOTIFICATION_OF_BASE_AMOUNT_REVISION_TARGET_TYPE } from 'src/reducers/egov';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { arrowRenderer } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { Upload } from 'jbc-front/components/icons';

import { NOTIFICATION_OF_BASE_AMOUNT_REVISION_SORT_TYPES } from 'src/constants/Options';
import {
  EMPLOYEE_PAY_LIST,
  NOTIFICATION_OF_BASE_AMOUNT_REVISION_INSERT_BATCH_CSV
} from 'src/constants/EndpointUrls';
import MainSearchSelectBox from 'src/components/MainSearchSelectBox';
import LoadingView from 'src/components/LoadingView';
import { ErrorsSection } from 'src/components';
import DetailSearchForm from 'src/components/DetailSearchForm';
import GeneralBtn from 'src/buttons/GeneralBtn';
import CsvDownloadModalForm from 'src/components/Modal/Report/SocialInsurance/CsvDownloadModalForm';
import { downloadCsv } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import EgovModalContainer from 'src/containers/EgovModalContainer';
import { FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH } from 'src/reducers/searchForm';
import styles from './Header.scss';
import ConfirmRevisionModal from '../containers/Modal/ConfirmRevisionModalContainer';
import NotConfirmedRevisionModal from '../components/Modal/NotConfirmedRevisionModal';
import PdfModalBaseContainer from '../containers/PdfModalBaseContainer';
import EgovButton from '../../../components/Modal/Report/SocialInsurance/EgovButton';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPdfPreview: false,
      isConfirmModalOpen: false,
      isCsvModalOpen: false,
      isEgovModalOpen: false
    };
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.openNotConfirmedModal = this.openNotConfirmedModal.bind(this);
    this.closeNotConfirmedModal = this.closeNotConfirmedModal.bind(this);
    this.openCsvModal = this.openCsvModal.bind(this);
    this.closeCsvModal = this.closeCsvModal.bind(this);
    this.openEgovModalOpen = this.openEgovModalOpen.bind(this);
    this.closeEgovModalOpen = this.closeEgovModalOpen.bind(this);
  }

  componentDidMount() {
    this.props.fetchNotificationOfBaseAmountOptions();
  }

  shouldComponentUpdate(nextProps) {
    // 年度を変更した際にデータ取得前に一瞬headerがレンダリングされるのを防止している
    // 本来loadingの画像を出すなどで対応すべきなので余裕があれば修正する
    return this.props.yearMonth === nextProps.yearMonth;
  }

  handleYearChange = () => {
    this.props.changeFormValue('applicableOffice', this.props.applicableOffices[0].value);
    this.props.fetchNotificationOfBaseAmountOfficeOptions();
  };

  openConfirmModal() {
    if (this.state.isConfirmModalOpen) return;
    this.setState({ ...this.state, isConfirmModalOpen: true });
  }

  closeConfirmModal() {
    if (!this.state.isConfirmModalOpen) return;
    this.setState({ ...this.state, isConfirmModalOpen: false });
  }

  openNotConfirmedModal() {
    if (this.state.isNotConfirmedModalOpen) return;
    this.setState({ ...this.state, isNotConfirmedModalOpen: true });
  }

  closeNotConfirmedModal() {
    if (!this.state.isNotConfirmedModalOpen) return;
    this.setState({ ...this.state, isNotConfirmedModalOpen: false });
  }

  openCsvModal() {
    if (this.state.isCsvModalOpen) return;
    this.setState({ ...this.state, isCsvModalOpen: true });
  }

  closeCsvModal() {
    if (!this.state.isCsvModalOpen) return;
    this.setState({ ...this.state, isCsvModalOpen: false });
  }

  openEgovModalOpen() {
    if (this.state.isEgovModalOpen) return;
    this.setState({ ...this.state, isEgovModalOpen: true });
  }

  closeEgovModalOpen() {
    if (!this.state.isEgovModalOpen) return;
    this.setState({ ...this.state, isEgovModalOpen: false });
  }

  showPreview = () => {
    this.setState({ isPdfPreview: true });
  };

  hidePreview = () => {
    this.setState({ isPdfPreview: false });
  };

  NoContent = () => (
    <div className={styles.noContent}>
      <div className={styles.noContentText}>
        <p className={styles.noContentTextPrimary}>表示できる算定基礎届がありません。 </p>
        <p className={styles.noContentTextPrimary}>連続した4ヶ月分の給与を確定させると表示されます。</p>
        <p className={styles.noContentTextSecondary}>
          ※算定基礎届に関する詳細は
          <a
            className="u-txt-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360002145953-%E9%9A%8F%E6%99%82%E6%94%B9%E5%AE%9A%E3%82%92%E8%A1%8C%E3%81%86-%E6%9C%88%E9%A1%8D%E5%A4%89%E6%9B%B4%E5%B1%8A%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6-"
          >
            ヘルプページ
          </a>
          をご覧ください
        </p>
      </div>
      <div className={styles.noContentButton}>
        <GeneralBtn as="a" text="給与一覧へ" href={EMPLOYEE_PAY_LIST} />
      </div>
    </div>
  );

  sortRenderValue = option => <div>{option.label} 順</div>;

  render() {
    const {
      revisionYear,
      applicableOffices,
      yearMonth,
      applicableOffice,
      resetValues,
      extras,
      hasInvalidMonthsErrors,
      revisionErrors,
      isOptionsLoading,
      filterAlertTargets,
      unconfirmedAlertCount,
      targetHealthInsurance,
      importing
    } = this.props;
    const isNoData = _.isEmpty(revisionYear);
    const isWithLms = Cookies.get('using_jbc_lms') === 'true';
    const isAllConfirmedAlert =
      _.isUndefined(unconfirmedAlertCount) || (!_.isUndefined(unconfirmedAlertCount) && unconfirmedAlertCount === 0);
    const hasTarget = isNoAlert => {
      if (isNoAlert && extras.targetCount > 0) {
        return true;
      }
      return false;
    };
    const importUrl = year =>
      `${NOTIFICATION_OF_BASE_AMOUNT_REVISION_INSERT_BATCH_CSV}?revision_year=${year}&applicable_office=${applicableOffice}`;
    const disabledReason = '処理中です。完了までしばらくお待ちください。';
    const lastReflectedAt = _.get(revisionYear.find(option => option.value === yearMonth), 'lastReflectedAt');
    const isCurrentYear =
      moment()
        .year()
        .toString() === yearMonth;
    const hasNotRevisedEmployee = extras.applicableRevisions?.some(revision => revision.notConfirmedReason);
    return (
      <LoadingView isLoading={isOptionsLoading}>
        <div className="l-wrap-xl">
          {isNoData && <this.NoContent />}
          {!isNoData && (
            <React.Fragment>
              <div className={styles.container}>
                <div className={styles.select}>
                  <Field
                    name="yearMonth"
                    component={MainSearchSelectBox}
                    options={revisionYear}
                    onSelect={this.handleYearChange}
                    arrowRenderer={arrowRenderer}
                    width={120}
                  />
                </div>
                {applicableOffices.length > 1 &&
                  !hasInvalidMonthsErrors && (
                    <div className={styles.selectOffice}>
                      <Field
                        name="applicableOffice"
                        component={MainSearchSelectBox}
                        options={applicableOffices}
                        arrowRenderer={arrowRenderer}
                      />
                    </div>
                  )}
                <div className={styles.csv}>
                  {!hasInvalidMonthsErrors && (
                    <ActionButton
                      primary
                      href={importUrl(yearMonth, applicableOffice)}
                      as="a"
                      icon={<Upload size={16} />}
                    >
                      CSVインポート
                    </ActionButton>
                  )}
                </div>
              </div>
              {!hasInvalidMonthsErrors && (
                <div>
                  <ErrorsSection errors={revisionErrors} />
                  {!isAllConfirmedAlert && (
                    <div className={styles.alertBox}>
                      <p>
                        {`正確に判定できていない可能性のある対象者が${unconfirmedAlertCount}名います`}
                        <br />
                        対象者の氏名横のアラートマークより詳細を確認してください
                      </p>
                      <Button primary onClick={filterAlertTargets}>
                        対象者を表示
                      </Button>
                    </div>
                  )}
                  <div className={styles.column}>
                    <div>
                      <ul className="m-list-notes">
                        <li>
                          ※9月分以降の標準報酬月額が変更されます。(実際の徴収月は設定により異なります)
                          {isCurrentYear && (
                            <span>
                              (前回実行日：{lastReflectedAt ? moment(lastReflectedAt).format('YYYY年M月D日') : 'ー'}）
                            </span>
                          )}
                        </li>
                      </ul>
                      <div className={styles.target}>
                        対象者：{extras.targetCount}名
                        {isCurrentYear && <span>（うち未反映：{extras.unreflectedTargetCount}名）</span>}
                        {hasNotRevisedEmployee && (
                          <>
                            <br />
                            ※7〜9月の間に標準報酬月額が変更されたため
                            <span role="link" className={styles.link} onClick={this.openNotConfirmedModal} tabIndex={0}>
                              反映されなかった従業員
                            </span>
                            がいます。
                          </>
                        )}
                      </div>
                    </div>
                    <Button
                      primary
                      onClick={this.openConfirmModal}
                      disabled={!hasTarget(isAllConfirmedAlert) || importing}
                      disabledReason={importing ? disabledReason : ''}
                    >
                      標準報酬月額改定
                    </Button>
                  </div>
                  <div>
                    <DetailSearchForm
                      menuRender={() => (
                        <div className={styles.actionSelectBoxes}>
                          <div className={styles.actionSelectBoxesChild}>
                            <Field
                              name="sortType"
                              component={SearchSelectBox}
                              valueRenderer={this.sortRenderValue}
                              options={NOTIFICATION_OF_BASE_AMOUNT_REVISION_SORT_TYPES}
                            />
                          </div>
                          <PdfModalBaseContainer
                            revisionYear={yearMonth}
                            applicableOffice={applicableOffice}
                            isAllConfirmedAlert={isAllConfirmedAlert}
                            isTargetExist={hasTarget(isAllConfirmedAlert)}
                          />
                          <div className={styles.actionButtons}>
                            <EgovButton
                              isCsvDataExist={hasTarget(isAllConfirmedAlert)}
                              openCsvModal={this.openCsvModal}
                              isLmsActive={isWithLms}
                              openEgovModal={this.openEgovModalOpen}
                            />
                          </div>
                        </div>
                      )}
                      containsOtherSearch={false}
                      containsOfficeSearch
                      containsSubject
                      formName={FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH}
                      resetValues={resetValues}
                      resetSearch={`applicable_office=${applicableOffice}&revision_year=${yearMonth}`}
                      defaultSearch={{
                        filter_type: 'only_targets',
                        sort_type: 'staff_code'
                      }}
                    />
                  </div>

                  {this.state.isConfirmModalOpen && (
                    <ConfirmRevisionModal
                      isOpen={this.state.isConfirmModalOpen}
                      hideModal={this.closeConfirmModal}
                      yearMonth={yearMonth}
                      applicableOffice={applicableOffice}
                    />
                  )}
                  {this.state.isNotConfirmedModalOpen && (
                    <NotConfirmedRevisionModal
                      isOpen={this.state.isNotConfirmedModalOpen}
                      hideModal={this.closeNotConfirmedModal}
                      yearMonth={yearMonth}
                      applicableOffice={applicableOffice}
                    />
                  )}
                  {this.state.isCsvModalOpen && (
                    <CsvDownloadModalForm
                      isOpen={this.state.isCsvModalOpen}
                      searchKey={{ revisionYear: yearMonth, applicableOffice }}
                      insuranceType={targetHealthInsurance.insuranceType}
                      hideModal={this.closeCsvModal}
                      modalTitle={'算定基礎届CSVダウンロード'}
                      downloadCsv={downloadCsv}
                      withSubmitAt
                      withEmployeeSearch
                    />
                  )}
                  {this.state.isEgovModalOpen && (
                    <EgovModalContainer
                      isOpen={this.state.isEgovModalOpen}
                      hideModal={this.closeEgovModalOpen}
                      postParams={{ revisionYear: yearMonth, applicableOffice }}
                      type={NOTIFICATION_OF_BASE_AMOUNT_REVISION_TARGET_TYPE}
                      withEmployeeSearch
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          )}
        </div>
      </LoadingView>
    );
  }
}
