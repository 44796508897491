import { connect } from 'react-redux';
import selector from 'src/utils/Utils';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  getEmployeeFormValues,
  fetchStandardMonthlyAmounts,
  getIsNotJoinedHealthAndPension
} from 'src/reducers/employees/employees';
import EmployeeStandardMonthliesSection from '../components/EmployeeStandardMonthliesSection';

const mapStateToProps = state => ({
  standardMonthlyMinimumYear: getSelectOptions(state, 'standardMonthlyMinimumYear'),
  employeeId: selector(getEmployeeFormValues(state), 'employee', 'id'),
  isNotJoinedHealthAndPension: getIsNotJoinedHealthAndPension(getEmployeeFormValues(state))
});

const mapDispatchToProps = dispatch => ({
  standardMonthlyAmounts: selectingTimeValue => dispatch(fetchStandardMonthlyAmounts(selectingTimeValue))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeStandardMonthliesSection);
