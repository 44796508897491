import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import isEmpty from 'lodash/isEmpty';
import classNames from 'classnames';
import compose from 'lodash/fp/compose';

import { Alert } from 'jbc-front/components/icons';

import {
  REDUCER_NAME,
  fetchEmployeeAttendances,
  setEmployeeAttendancesListExtra
} from 'src/reducers/employees/employeeAttendances';
import ActionSection from 'src/features/EmployeeAttendances/components/ActionSection';
import DetailSearchForm from 'src/components/DetailSearchForm';
import Balloon from 'src/components/Balloon';
import {
  takePaysSearchQueries,
  takePayConfirmedStateFromSearchForm,
  takePaysSearchQueriesFromLocation,
  takePaysAndAttendancesResetFormQueries,
  attendancesSearchFormSelector,
  fetchUniquePayrollRuleGroups,
  FORM_NAME_ATTENDANCES_SEARCH,
  ITEM_NAME_FETCH_UNIQUE_PAYROLL_RULE_GROUPS,
  isLoading
} from 'src/reducers/searchForm';
import PaymentDateSelectBoxes from 'src/components/PaymentDateSelectBoxes';
import PayTargetDateRange from 'src/containers/PayTargetDateRangeContainer';
import SearchDetector from 'src/components/SearchDetector';
import Confirmed from 'src/components/Confirmed';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import { getSelectOptions } from 'src/reducers/selectOptions';
import withSetupNeeded from 'src/components/withSetupNeeded';
import LoadingView from 'src/components/LoadingView';
import EmployeeAttendanceTable from './containers/EmployeeAttendanceTable';
import AttendanceSyncWithJbcResult from './components/AttendanceSyncWithJbcResult';
import ImportAttendanceCsvJobStatus from './components/ImportAttendanceCsvJobStatus';
import styles from './EmployeeAttendanceList.scss';
import { getIsLoading } from '../../reducers/common/listReducer';

const CONFIRMED_ALERT_MESSAGE = '給与を確定しています。編集をする場合は給与一覧から確定解除を行ってください。';
const ConfirmedAlertIcon = () => (
  <div className={styles.confirmedAlert}>
    <Balloon className={styles.balloon}>{CONFIRMED_ALERT_MESSAGE}</Balloon>
    <Alert size={22} color={'#666'} />
  </div>
);

class InnerEmployeeAttendanceList extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchUniquePayrollRuleGroups(FORM_NAME_ATTENDANCES_SEARCH));
  }

  render() {
    const {
      initialValues: formValues,
      uniquePaymentDate,
      location,
      isPayConfirmed,
      resetValues,
      uniquePayrollRuleGroupsCount,
      dispatch,
      isOptionsLoading,
      isListLoading
    } = this.props;

    return (
      <div>
        <SearchDetector
          location={location}
          uniquePaymentDate={uniquePaymentDate}
          willReceivePropsDetectingJudger={(prevProps, nextProps) =>
            nextProps.uniquePaymentDate !== undefined &&
            (prevProps.uniquePaymentDate === undefined || nextProps.location !== prevProps.location)
          }
          onDetect={() => {
            dispatch(fetchEmployeeAttendances(takePaysSearchQueries(formValues)));
          }}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">勤怠一覧</h1>
        </div>
        <LoadingView isLoading={isOptionsLoading}>
          <AttendanceSyncWithJbcResult />
          <ImportAttendanceCsvJobStatus />
          <div className="l-wrap-xl">
            <div
              className={classNames({
                [styles.select]: true,
                [styles.centeredSelect]: uniquePayrollRuleGroupsCount < 2
              })}
            >
              {isPayConfirmed && (
                <div className={styles.confirmed}>
                  <ConfirmedAlertIcon />
                  <Confirmed />
                </div>
              )}
              <PaymentDateSelectBoxes formName={FORM_NAME_ATTENDANCES_SEARCH} />
            </div>
            <PayTargetDateRange formName={FORM_NAME_ATTENDANCES_SEARCH} releaseDateVisibility={false} />
            <DetailSearchForm
              containsOtherSearch={false}
              menuRender={() => <ActionSection isPayConfirmed={isPayConfirmed} />}
              formName={FORM_NAME_ATTENDANCES_SEARCH}
              resetValues={resetValues}
            />
          </div>
          <LoadingView isLoading={isListLoading} isInitLoading={isEmpty(location.search)}>
            <div className={classNames('l-wrap-xl', styles.list)}>
              <EmployeeAttendanceTable />
              <SearchFormPaginator formName={FORM_NAME_ATTENDANCES_SEARCH} reducerName={REDUCER_NAME} />
            </div>
          </LoadingView>
        </LoadingView>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...takePaysSearchQueriesFromLocation(FORM_NAME_ATTENDANCES_SEARCH, state.router.location, true),
    ...getFormValues(FORM_NAME_ATTENDANCES_SEARCH)(state)
  },
  uniquePaymentDate: attendancesSearchFormSelector(state, 'uniquePaymentDate'),
  resetValues: takePaysAndAttendancesResetFormQueries(FORM_NAME_ATTENDANCES_SEARCH, state, true),
  location: state.router.location,
  isPayConfirmed: takePayConfirmedStateFromSearchForm(state, FORM_NAME_ATTENDANCES_SEARCH),
  uniquePayrollRuleGroupsCount: getSelectOptions(state, 'uniquePayrollRuleGroups').length,
  isOptionsLoading: isLoading(ITEM_NAME_FETCH_UNIQUE_PAYROLL_RULE_GROUPS, state),
  isListLoading: getIsLoading(REDUCER_NAME, state)
});

const EmployeeAttendanceList = compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME_ATTENDANCES_SEARCH,
    enableReinitialize: true
  })
)(InnerEmployeeAttendanceList);

class AttendanceList extends React.Component {
  componentDidMount() {
    const { dispatch, syncTaskSettings = {}, syncTimesInfo = {} } = this.props;
    dispatch(setEmployeeAttendancesListExtra({ syncTaskSettings, syncTimesInfo }));
  }

  render() {
    return <EmployeeAttendanceList />;
  }
}

export default compose(withSetupNeeded('勤怠データを取り込むためには'), connect())(AttendanceList);
