import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { ErrorsSection } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { CLIENT_BONUS_FORM } from 'src/constants/FormNames';
import { deleteClientBonus, REDUCER_NAME } from 'src/reducers/clients/clientBonus';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { getSelectingClientBonus } from 'src/reducers/employees/employeeBonus';

const UnconfirmBonusInsideForm = ({ submitting, submit, cancelBtnClick, errors, clientBonus, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form>
        <ErrorsSection errors={errors} />
        <div className="u-mb20">
          {clientBonus.name} ({clientBonus.payOnLabel}) を削除します。
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="削除" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let UnconfirmBonusForm = reduxForm({
  form: CLIENT_BONUS_FORM
})(UnconfirmBonusInsideForm);

const mapStateToProp = state => ({
  errors: getItemErrors(REDUCER_NAME, state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(deleteClientBonus(ownProps.clientBonus.value))
});

UnconfirmBonusForm = connect(mapStateToProp, mapDispatchToProp)(UnconfirmBonusForm);

const DeleteClientBonusModal = ({ isOpen, hideModal, clientBonus }) => {
  const header = '賞与の削除';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <UnconfirmBonusForm cancelBtnClick={hideModal} clientBonus={clientBonus} header={header} />
    </CommonModal>
  );
};

const mapStateToProps = state => ({
  clientBonus: getSelectingClientBonus(state)
});

export default connect(mapStateToProps)(DeleteClientBonusModal);
