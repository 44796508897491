/* eslint-disable react/no-array-index-key */

import React from 'react';
import { Field } from 'redux-form';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';

import { Delete, Drugging } from 'jbc-front/components/icons';

import FCTextWrapper from './FCTextWrapper';
import styles from './SortableListFields.scss';

const DragHandle = SortableHandle(() => (
  <span className={styles.handle}>
    <Drugging />
  </span>
));

const EditableListField = SortableElement(({ name, idx, fields, field, fieldName, fieldCode, mode }) => (
  <div>
    {mode === 'SHOW' ? (
      <div className={styles.list}>
        <p className={styles.input}>
          {field[fieldCode]} {field[fieldName]}
        </p>
      </div>
    ) : (
      <div className={styles.list}>
        <DragHandle />
        <div className={styles.data}>
          <div className={styles.inputDepartmentCode}>
            <Field type="text" maxLength="255" name={`${name}.${fieldCode}`} component={FCTextWrapper} />
          </div>
          <div className={styles.inputName}>
            <Field type="text" maxLength="255" name={`${name}.${fieldName}`} component={FCTextWrapper} />
          </div>
        </div>
        {field.employeesCount === undefined || field.employeesCount === 0 ? (
          <div className={styles.delete}>
            <Delete
              size={20}
              onClick={() => {
                fields.remove(idx);
              }}
            />
          </div>
        ) : (
          <div className={styles.nodelete}>
            <Delete size={20} />
            <div className={styles.nodeleteText}>従業員情報で設定されているので削除できません</div>
          </div>
        )}
      </div>
    )}
  </div>
));

const EditableListFields = SortableContainer(({ fields, fieldName, fieldCode, mode, type }) => (
  <div>
    <div className={styles.head}>{type}名</div>
    {fields.map((name, index) => {
      const field = fields.get(index);
      return (
        <EditableListField
          key={name}
          index={index}
          idx={index}
          name={name}
          fields={fields}
          field={field}
          fieldName={fieldName}
          fieldCode={fieldCode}
          mode={mode}
        />
      );
    })}
  </div>
));

const SortableListFields = ({ fields, fieldName, fieldCode, mode, type }) => (
  <EditableListFields
    fields={fields}
    fieldName={fieldName}
    fieldCode={fieldCode}
    mode={mode}
    type={type}
    distance={1}
    onSortEnd={({ oldIndex, newIndex }) => {
      fields.move(oldIndex, newIndex);
    }}
  />
);

export default SortableListFields;
