import React, { Component } from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import MemoList from 'src/features/Memos';
import { getOptionLabel, getGroupFullPathName } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';
import Balloon from 'src/components/Balloon';
import { Alert } from 'jbc-front/components/icons';
import CheckBoxHint from 'src/components/CheckBoxHint';

import styles from 'src/components/EmployeeTable.scss';

const ALERT_MESSAGE_BASE = 'が設定されていません。未設定の場合、給与計算・賞与計算を行う事ができません。';

const EmployeeAlertIcon = ({ alertMessage } = this.props) => (
  <tr className={styles.alertWrapper}>
    <td className={styles.alert}>
      <Balloon className={styles.balloon}>{alertMessage}</Balloon>
      <Alert size={22} color={'#666'} />
    </td>
  </tr>
);

export default class EmployeeTableRow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHint: false,
      hint: this.checkDisabledMessage()
    };
  }
  // 必須項目が足りないときのエラーメッセージを作成する
  // 必須項目をすべて満たすときはnullを返す
  createAlertMessage(employee) {
    if (!_.isEmpty(employee.joinedOn) && employee.hasPayrollRule) {
      return null;
    }
    const errorItems = [];
    if (_.isEmpty(employee.joinedOn)) {
      errorItems.push('入社日');
    }
    if (!employee.hasPayrollRule) {
      errorItems.push('給与規定グループ');
    }
    return `${errorItems.join('・')}${ALERT_MESSAGE_BASE}`;
  }
  checkDisabledMessage = () => {
    const { employee, currentUser } = this.props;
    if (!employee || !currentUser) {
      return null;
    }
    if (employee.email === currentUser.email) {
      return '自分を削除する事はできません。';
    } else if (!employee.isDeletable) {
      return '給与または賞与が確定済みです。';
    }
    return null;
  };
  handleClick = () => {
    const { employee, onChangeEmployeeChecked } = this.props;
    if (this.state.hint) {
      this.setState({
        ...this.state,
        showHint: true
      });
    } else {
      onChangeEmployeeChecked(employee.id);
    }
  };
  hideHint = () => {
    this.setState({
      ...this.state,
      showHint: false
    });
  };

  render() {
    const { employee, isMainOfficeOnly, clientEmploymentTypes, groups, positions, mode, onClick } = this.props;
    const { showHint, hint } = this.state;
    const checked = _.get(employee, 'checked');
    const checkedClass = checked ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
    const disabledClass = hint ? ' u-cur-notallowed' : ' u-cur-pointer';
    const alertMessage = this.createAlertMessage(employee);
    return (
      <React.Fragment>
        {alertMessage && <EmployeeAlertIcon alertMessage={alertMessage} />}
        <tr
          onClick={onClick}
          className={classNames('table-hover', styles.tableRow, { [styles.tableRowAlert]: alertMessage != null })}
        >
          {mode === 'EDIT' && (
            <td
              onClick={e => {
                e.stopPropagation();
                this.handleClick();
              }}
              className={checkedClass + disabledClass}
              role="presentation"
            >
              {showHint && <CheckBoxHint onClick={this.hideHint}>{hint}</CheckBoxHint>}
            </td>
          )}
          <td>
            <MemoList employeeId={employee.id} />
          </td>
          <td>{employee.staffCode}</td>
          <td>{employee.displayName}</td>
          {!isMainOfficeOnly && <td>{employee.officeManageName}</td>}
          <td>{getOptionLabel(employee.employmentTypeId)(clientEmploymentTypes)}</td>
          <td>{!!employee.groupId && <div>{getGroupFullPathName(groups, employee.groupId)}</div>}</td>
          <td>{getOptionLabel(employee.positionId)(positions)}</td>
          <td>{formatDate(employee.joinedOn)}</td>
        </tr>
      </React.Fragment>
    );
  }
}
