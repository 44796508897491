import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { getConfirmJobs, getDownloadPayCsvJobs, getImportPayCsvJobs } from 'src/reducers/employees/employeePays';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getExtras } from 'src/reducers/common/listReducer';
import ConfirmPayrollGroupModal from './ConfirmPayrollGroupModal';
import UnconfirmPayrollGroupModal from './UnconfirmPayrollGroupModal';
import styles from './ConfirmPayrollContainer.scss';

class ConfirmPayrollContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isConfirmModalOpen: false,
      isUnconfirmModalOpen: false
    };
    this.openConfirmModal = this.openConfirmModal.bind(this);
    this.closeConfirmModal = this.closeConfirmModal.bind(this);
    this.openUnconfirmModal = this.openUnconfirmModal.bind(this);
    this.closeUnconfirmModal = this.closeUnconfirmModal.bind(this);
  }

  openConfirmModal() {
    if (this.state.isConfirmModalOpen) return;
    this.setState({ ...this.state, isConfirmModalOpen: true });
  }

  closeConfirmModal() {
    if (!this.state.isConfirmModalOpen) return;
    this.setState({ ...this.state, isConfirmModalOpen: false });
  }

  openUnconfirmModal() {
    if (this.state.isUnconfirmModalOpen) return;
    this.setState({ ...this.state, isUnconfirmModalOpen: true });
  }

  closeUnconfirmModal() {
    if (!this.state.isUnconfirmModalOpen) return;
    this.setState({ ...this.state, isUnconfirmModalOpen: false });
  }

  isPayReportsCreating() {
    return _.includes(['waiting', 'running'], _.get(this.props.downloadObj, 'status'));
  }

  render() {
    const {
      selectedPayroll,
      selectedYearMonth,
      isConfirmed,
      confirmPayJobs,
      downloadPayCsvJobs,
      importPayCsvJobs,
      uniquePaymentDates,
      isAvailableOfficeStation
    } = this.props;

    const yearMonthLabel = _.get(_.find(uniquePaymentDates, { value: selectedYearMonth }), 'label');
    const hasRunningPayJobs =
      !_.isEmpty(confirmPayJobs.inProgress) ||
      !_.isEmpty(confirmPayJobs.waiting) ||
      !_.isEmpty(downloadPayCsvJobs.inProgress) ||
      !_.isEmpty(downloadPayCsvJobs.waiting) ||
      !_.isEmpty(importPayCsvJobs.inProgress) ||
      !_.isEmpty(importPayCsvJobs.waiting);

    // const confirmBtnDisabled = isConfirmed || hasRunningPayJobs;
    const disabledReason = hasRunningPayJobs ? '処理中です。完了までしばらくお待ちください。' : null;

    if (!selectedPayroll || !selectedYearMonth) {
      return <Button primary className={styles.button} />;
    }

    let confirmBtn;
    if (isConfirmed) {
      confirmBtn = (
        <Button
          disabled={this.isPayReportsCreating()}
          primary
          onClick={this.openUnconfirmModal}
          className={isAvailableOfficeStation ? styles.smallButton : styles.button}
        >
          給与修正
        </Button>
      );
    } else {
      confirmBtn = (
        <Button
          primary
          disabled={hasRunningPayJobs}
          disabledReason={disabledReason}
          onClick={this.openConfirmModal}
          className={isAvailableOfficeStation ? styles.smallButton : styles.button}
        >
          確定
        </Button>
      );
    }

    return (
      <div>
        {confirmBtn}
        <ConfirmPayrollGroupModal
          isOpen={this.state.isConfirmModalOpen}
          hideModal={this.closeConfirmModal}
          {...{ selectedPayroll, selectedYearMonth }}
        />
        <UnconfirmPayrollGroupModal
          isOpen={this.state.isUnconfirmModalOpen}
          hideModal={this.closeUnconfirmModal}
          selectedPayroll={selectedPayroll}
          yearMonthLabel={yearMonthLabel}
        />
      </div>
    );
  }
}

export default connect(state => ({
  confirmPayJobs: getConfirmJobs(state) || {},
  downloadPayCsvJobs: getDownloadPayCsvJobs(state) || {},
  importPayCsvJobs: getImportPayCsvJobs(state) || {},
  uniquePaymentDates: getSelectOptions(state, 'uniquePaymentDates'),
  downloadObj: getExtras('employeePays', state),
  isAvailableOfficeStation: state.currentClient.item.data.isAvailableOfficeStation
}))(ConfirmPayrollContainer);
