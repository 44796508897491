import _ from 'lodash';
import humps from 'humps';

export const paramsWithFile = (params, files, isSnakeKye) => {
  const formData = _(params)
    .omit(files)
    .reduce((result, value, key) => {
      let paramsKey = key;
      if (isSnakeKye === true) {
        paramsKey = humps.decamelize(key);
      }
      if (_.isArray(value)) {
        if (value.length > 0) {
          value.forEach(v => result.append(`${paramsKey}[]`, v));
        } else {
          result.append(paramsKey, null);
        }
      } else {
        result.append(paramsKey, value);
      }

      return result;
    }, new window.FormData());

  files.forEach(file => {
    let fileKey = file;
    if (isSnakeKye === true) {
      fileKey = humps.decamelize(file);
    }
    if (typeof params[file] !== 'undefined' && _.isEmpty(params[file])) {
      formData.append(`remove_${fileKey}`, true);
    } else if (_.isArray(params[file])) {
      params[file].forEach(fileInstance => formData.append(`${fileKey}[]`, fileInstance));
    } else if (params[file] && !params[file].url) {
      formData.append(fileKey, params[file]);
    }
  });

  return formData;
};

const isPostalCodeChanged = (target, value) =>
  target.name &&
  target.form &&
  ((target.name.match(/.*postalCodePart0/) && value.length === 3) ||
    (target.name.match(/.*postalCodePart1/) && value.length === 4));

const extractPostalCodeValue = target => {
  const prefix = target.name.match(/(.*postalCodePart)\d/)[1];
  const postalCode0 = target.form.elements[`${prefix}0`].value;
  const postalCode1 = target.form.elements[`${prefix}1`].value;

  return `${postalCode0}${postalCode1}`;
};

export const handlePostalCodeChange = event => (inputText, callback) => {
  const target = event.target;

  if (isPostalCodeChanged(target, inputText)) {
    const postcode = extractPostalCodeValue(target);

    if (postcode.match(/\d{7}/)) {
      return callback(postcode);
    }
  }

  return undefined;
};

export const detectButton = (func, buttonValue) => data => func({ ...data, afterSubmit: buttonValue });
export const isHonnin = value => value === '本人';
export const defaultCommutingItem = (type = 'train') => {
  switch (type) {
    case 'train':
      return {
        meansOfCommunication: type,
        oneWayFare: 0,
        paymentAmount: 0
      };

    case 'car':
      return {
        meansOfCommunication: type,
        commutingDistance: 'lessthan_2km',
        paymentAmount: 0
      };

    case 'bus':
      return {
        meansOfCommunication: type,
        oneWayFare: 0,
        paymentAmount: 0
      };

    default:
      return { meansOfCommunication: type };
  }
};

export const get24HoursOptions = () => _.range(24).map(hour => ({ value: `${hour}`, label: `${hour}時` }));

export const takeFormErrors = (formErrors, fieldMap) => {
  const messages = [];

  if (formErrors === undefined) {
    return messages;
  }

  const keyPairs = _.toPairs(formErrors).filter(pair => Object.prototype.hasOwnProperty.call(fieldMap, pair[0]));
  return keyPairs.map(([key, value]) => `${fieldMap[key]}${value}`);
};
