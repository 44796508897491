import React from 'react';
import { FormSection } from 'redux-form';

import { TextField, BoxDouble, RadioField, Section } from 'jbc-front/components/Form';
import { DeleteSquare } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import { bankKana, maxLength3, maxLength4, maxLength7, maxLength10, isNumber } from 'src/utils/CustomValidators';
import BankSearchModalWrapper from 'src/components/BankSearchModalWrapper';
import styles from './BankItemSection.scss';

const BankItemSection = props => {
  const { showModal, isModalOpen: isOpen, hideModal, fillBankParams } = props;
  const { formKey, depositTypes, index, remove, removable } = props;

  return (
    <div>
      <Section
        title={`口座情報 ${index + 1}`}
        icon={
          removable ? (
            <div className={styles.delete}>
              <DeleteSquare onClick={remove} />
            </div>
          ) : (
            <div className={styles.nodelete}>
              <DeleteSquare />
              <div className={styles.nodeleteText}>従業員情報で支給設定されているので削除できません</div>
            </div>
          )
        }
      >
        <FormSection name={formKey}>
          <ActionButton onClick={showModal} className="u-mb20">
            銀行コード検索
          </ActionButton>

          <BankSearchModalWrapper
            isOpen={isOpen}
            hideModal={hideModal}
            onSelect={(bank, branch) => {
              fillBankParams(bank, branch);
            }}
          />

          <BoxDouble>
            <TextField label="銀行コード" name="bankCode" validate={[isNumber, maxLength4]} maxLength="4" />
            <TextField label="銀行名" name="bankName" maxLength="256" />
          </BoxDouble>
          <BoxDouble>
            <TextField label="支店コード" name="branchCode" validate={[isNumber, maxLength3]} maxLength="3" />
            <TextField label="支店名" name="branchName" maxLength="256" />
          </BoxDouble>

          <RadioField label="預金種別" name="depositType" options={depositTypes} />

          <BoxDouble>
            <TextField
              label="口座番号"
              type="text"
              name="accountNumber"
              validate={[isNumber, maxLength7]}
              maxLength="7"
            />
            <TextField label="口座名義（カナ）" type="text" name="accountHolderKana" validate={[bankKana]} />
          </BoxDouble>

          <TextField
            label="会社コード（委託者コード）- 給与振込用"
            name="consignorCodePay"
            maxLength="10"
            validate={[isNumber, maxLength10]}
          />
          <TextField
            label="会社コード（委託者コード）- 総合振込用"
            name="consignorCodeTotal"
            maxLength="10"
            validate={[isNumber, maxLength10]}
          />
          <TextField
            label="会社コード（委託者コード）- 住民税振込用"
            name="consignorCodeInhabitantTax"
            maxLength="10"
            validate={[isNumber, maxLength10]}
          />
        </FormSection>
      </Section>
    </div>
  );
};

export default BankItemSection;
