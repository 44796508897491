import React from 'react';
import { connect } from 'react-redux';

import { getClientAllowanceItemList, deleteAllowanceItem } from 'src/reducers/clients/allowanceItems';
import {
  CLIENT_ALLOWANCE_ITEM_NEW,
  CLIENT_SETTINGS,
  CLIENT_ALLOWANCE_ITEM_CHANGE_DISPLAY_ORDERS
} from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import ActionButton from 'jbc-front/components/ActionButton';
import { Plus, Drugging } from 'jbc-front/components/icons';
import AllowanceItemsTable from './AllowanceItemsTable';
import styles from './ClientAllowanceItemsComponent.scss';

class ClientAllowanceItemsComponent extends React.Component {
  render() {
    const { allowanceItems, handleDeleteAllowanceItem } = this.props;

    return (
      <div>
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">勤怠項目設定一覧</h1>
        </div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
              <BreadCrumb.Item label="勤怠項目設定一覧" />
            </BreadCrumb>
          </div>
          <div className={styles.action}>
            <ActionButton as="a" href={CLIENT_ALLOWANCE_ITEM_NEW} icon={<Plus size={10} />}>
              項目を追加
            </ActionButton>
            <ActionButton as="a" href={CLIENT_ALLOWANCE_ITEM_CHANGE_DISPLAY_ORDERS} icon={<Drugging size={10} />}>
              項目並べ替え
            </ActionButton>
          </div>
          <AllowanceItemsTable allowanceItems={allowanceItems} handleDeleteRow={handleDeleteAllowanceItem} />
        </div>
      </div>
    );
  }
}

export default connect(
  state => ({
    allowanceItems: getClientAllowanceItemList(state)
  }),
  dispatch => ({
    handleDeleteAllowanceItem: allowanceItem => dispatch(deleteAllowanceItem(allowanceItem))
  })
)(ClientAllowanceItemsComponent);
