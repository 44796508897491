import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { CLIENT_UNIT_PRICES_LIST } from 'src/constants/EndpointUrls';
import * as CssClassNames from 'src/constants/CssClassNames';
import { Label, Section, TextField, SelectField, RadioField, CheckboxField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { getSelectOptions, getRadioOptions } from 'src/reducers/selectOptions';

import { FormErrors } from 'src/components';
import { CancelBtn } from 'src/buttons';
import selector, { getOptionLabel } from 'src/utils/Utils';
import { concatParamsToUrl } from 'src/utils/Http';
import { getUnitPriceFormValues } from 'src/reducers/clients/unitPrices';

const unitPriceListUrl = payrollRulesGroupId => concatParamsToUrl(CLIENT_UNIT_PRICES_LIST, { payrollRulesGroupId });

const ClientUnitPriceForm = props => {
  const {
    isSubmitting,
    setSubmitting,
    submit,
    pristine,
    handleSubmit,
    invalid,
    errors,
    standardUnitTypes,
    standardUnitTypeDescriptions,
    clientAllowances,
    fractionRoundingConditionTypes,
    fractionRoundingMethodTypes,
    useAllowanceTypes,
    payrollRulesGroupId,
    payrollRulesGroup,
    selectedStandardUnitType,
    selectedFractionRoundingMethodType
  } = props;

  return (
    <form className={classNames(CssClassNames.FORM, CssClassNames.CLIENT_FORM)} onSubmit={handleSubmit}>
      <FormErrors errors={errors} />

      <Section>
        <TextField required label="単価名" name="name" />

        {payrollRulesGroup.payrollType === 'monthly' && (
          <RadioField required label="参照する給与データ" name="useAllowanceType" options={useAllowanceTypes} />
        )}

        <RadioField required label="単価の基準となる時間/日数" name="standardUnitType" options={standardUnitTypes} />
        <Label text={getOptionLabel(selectedStandardUnitType)(standardUnitTypeDescriptions)} />

        <Label required text="対象項目（※マイナス計算項目）" />
        {clientAllowances.map((clientAllowance, index) => (
          <CheckboxField
            key={`client_allowance_${clientAllowance.id}`}
            name={`clientAllowanceId[${index}]`}
            label={clientAllowance.isReduced ? `${clientAllowance.label}※` : clientAllowance.label}
            normalize={value => value && clientAllowance.id}
          />
        ))}

        <Label required text="端数処理" />
        <RadioField name="fractionRoundingMethodType" options={fractionRoundingMethodTypes} />
        {selectedFractionRoundingMethodType !== 'not_perform' && (
          <SelectField name="fractionRoundingConditionType" options={fractionRoundingConditionTypes} />
        )}
      </Section>
      <div className="u-ta-c">
        <CancelBtn href={unitPriceListUrl(payrollRulesGroupId)} className="u-mr20" />
        <Button
          primary
          text="保存"
          onClick={() => {
            setSubmitting();
            submit();
          }}
          disabled={invalid || isSubmitting || pristine}
        >
          保存
        </Button>
      </div>
    </form>
  );
};

const mapStateToProps = state => ({
  standardUnitTypes: getRadioOptions(state, 'standardUnitTypes'),
  standardUnitTypeDescriptions: getRadioOptions(state, 'standardUnitTypeDescriptions'),
  clientAllowances: getSelectOptions(state, 'clientAllowances'),
  fractionRoundingConditionTypes: getSelectOptions(state, 'clientUnitPriceFractionRoundingConditionTypes'),
  fractionRoundingMethodTypes: getSelectOptions(state, 'clientUnitPriceFractionRoundingMethodTypes'),
  useAllowanceTypes: getSelectOptions(state, 'clientUnitPriceUseAllowanceTypes'),
  payrollRulesGroupId: selector(getUnitPriceFormValues(state), 'clientPayrollRulesGroupId'),
  selectedStandardUnitType: selector(getUnitPriceFormValues(state), 'standardUnitType'),
  selectedFractionRoundingMethodType: selector(getUnitPriceFormValues(state), 'fractionRoundingMethodType')
});
export default connect(mapStateToProps)(ClientUnitPriceForm);
