import React from 'react';
import Button from 'jbc-front/components/Button';
import {
  SOCIAL_INSURANCE_NOTIFICATION_SUBMISSION_TYPES,
  SOCIAL_INSURANCE_NOTIFICATION_SORT_TYPES
} from 'src/constants/Options';
import CommonModal from 'jbc-front/components/CommonModal';
import { RadioField } from 'jbc-front/components/Form';
import { ErrorsSection } from 'src/components';
import styles from './PdfModal.scss';

const PdfModal = props => {
  const { handleSubmit, errors, submitting, submit, isOpen, hideModal, insuranceType } = props;

  return (
    <CommonModal className={styles.modal} ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>賞与支払届PDF出力</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit} className={styles.modalForm}>
          <ErrorsSection errors={errors} />
          <div className={['it', 'other_association'].includes(insuranceType) ? styles.itemBox : styles.none}>
            <p>提出先</p>
            <RadioField name="submitTo" options={SOCIAL_INSURANCE_NOTIFICATION_SUBMISSION_TYPES} />
          </div>
          <div className={styles.itemBox}>
            <p>表示順</p>
            <RadioField name="orderBy" options={SOCIAL_INSURANCE_NOTIFICATION_SORT_TYPES} />
          </div>
          <div className={styles.buttonBox}>
            <Button onClick={hideModal} className="u-mr20">
              キャンセル
            </Button>
            <Button primary onClick={submit} disabled={submitting}>
              作成する
            </Button>
          </div>
        </form>
      </CommonModal.Body>
    </CommonModal>
  );
};

export default PdfModal;
