import _ from 'lodash';

const JOIN_TYPES = ['common', 'treated_person'];

const filterSelectOption = (isJoinedEmploymentInsurance, employeeClassifications) => {
  if (isJoinedEmploymentInsurance === true || isJoinedEmploymentInsurance === '1') {
    return _.filter(employeeClassifications, item => JOIN_TYPES.includes(item.value));
  }
  return _.filter(employeeClassifications, item => !JOIN_TYPES.includes(item.value));
};

export default filterSelectOption;
