/* eslint react/no-array-index-key: 0  */
import React from 'react';
import _ from 'lodash';
import { formatDateWithHour } from 'src/utils/Date';
import { NEW_LINE_CHARACTERS } from 'src/constants/Generals';
import styles from './JobStatus.scss';

const NotSyncedEmployees = ({ task }) => (
  <React.Fragment>
    {(!_.isEmpty(task.staffCodeUnmatchedEmployees) ||
      !_.isEmpty(task.nameUnmatchedEmployees) ||
      !_.isEmpty(task.canNotSyncEmployees) ||
      !_.isEmpty(task.attendanceConfirmedEmployees)) && (
      <div className={styles.canNotSyncEmployee}>
        <p>以下の方は勤怠情報を取得できませんでした。</p>
        {!_.isEmpty(task.staffCodeUnmatchedEmployees) && (
          <div className={styles.detail}>
            <p>
              ジョブカン勤怠管理と給与計算で登録されている「スタッフコード」が一致しているかどうかを確認してください。
            </p>
            <ul>
              {task.staffCodeUnmatchedEmployees
                .split(NEW_LINE_CHARACTERS)
                .map((employee, idx) => <li key={idx}>{employee}</li>)}
            </ul>
          </div>
        )}
        {!_.isEmpty(task.nameUnmatchedEmployees) && (
          <div className={styles.detail}>
            <p>ジョブカン勤怠管理と給与計算で登録されている「姓」「名」が一致しているかどうかを確認してください。</p>
            <ul>
              {task.nameUnmatchedEmployees
                .split(NEW_LINE_CHARACTERS)
                .map((employee, idx) => <li key={idx}>{employee}</li>)}
            </ul>
          </div>
        )}
        {!_.isEmpty(task.canNotSyncEmployees) && (
          <div className={styles.detail}>
            <p>
              ジョブカン勤怠管理と給与計算で登録されている「スタッフコード」が一致しているか、<br />
              連携フォーマットは正しい設定か、該当従業員の勤怠データが正しく登録されているかをご確認ください。詳細は
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900006719243"
              >
                こちら
              </a>をご覧ください。
            </p>
            <ul>
              {task.canNotSyncEmployees
                .split(NEW_LINE_CHARACTERS)
                .map((employee, idx) => <li key={idx}>{employee}</li>)}
            </ul>
          </div>
        )}
        {!_.isEmpty(task.attendanceConfirmedEmployees) && (
          <div className={styles.detail}>
            <p>給与が確定済の場合は勤怠情報を連携できません</p>
            <ul>
              {task.attendanceConfirmedEmployees
                .split(NEW_LINE_CHARACTERS)
                .map((employee, idx) => <li key={idx}>{employee}</li>)}
            </ul>
          </div>
        )}
        <p className={styles.detail}>
          勤怠管理と連携しない場合は「ジョブカン勤怠管理と連携＞従業員の勤怠連携設定」から対象者のチェックを外してください。
        </p>
      </div>
    )}
  </React.Fragment>
);

const JobSuccess = ({ statusText, jobInfo, startAt, endAt, syncEmployeeTask }) => (
  <li>
    <p className={styles.title}>
      {jobInfo}
      {statusText}
    </p>
    <div className={styles.dates}>
      <dl>
        <dt>実行日時：</dt>
        <dd>{formatDateWithHour(startAt)}</dd>
      </dl>
      <dl>
        <dt>完了日時：</dt>
        <dd>{formatDateWithHour(endAt)}</dd>
      </dl>
    </div>
    {!_.isEmpty(syncEmployeeTask) && <NotSyncedEmployees task={syncEmployeeTask} />}
  </li>
);

export default JobSuccess;
