import { combineReducers } from 'redux';
import remove from 'lodash/remove';
import axios from 'axios';

import * as Urls from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { setList, getList } from 'src/reducers/common/listReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

export const REDUCER_NAME = 'procedures';

export const setProcedureList = data => setList(REDUCER_NAME, { data });
export const getProcedureList = state => getList(REDUCER_NAME, state);

const MONTHLY_CHANGE_NOTIFICATION = 'monthly_change_notification';

const isSameProcedure = (procedure1, procedure2) => {
  if (procedure1.procedureType !== procedure2.procedureType) {
    return false;
  }
  if (procedure1.procedureType !== MONTHLY_CHANGE_NOTIFICATION) {
    return procedure1.year === procedure2.year;
  }

  return procedure1.year === procedure2.year && procedure1.month === procedure2.month;
};

export const completeProcedure = (procedure, onSuccessCallback) => async (dispatch, getState) => {
  try {
    await axios.post(Urls.CREATE_COMPLETED_PROCEDURE_URL, { completed_procedure: procedure });
    const procedureList = getProcedureList(getState());
    remove(procedureList, item => isSameProcedure(item.procedure, procedure));

    dispatch(setProcedureList([...procedureList]));
    onSuccessCallback();

    dispatch(setGlobalSuccesses('Todoを完了しました。'));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
