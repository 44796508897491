import React from 'react';
import Pulldown from 'jbc-front/components/icons/Pulldown';
import styles from './SortableTh.scss';

// JBC-frontはヘッダが２段になることは想定されていないので、他に２段のケースができるまで独自に追加したモジュールを使う
export class SortableTh extends React.Component {
  static defaultProps = {
    currentSortType: ''
  };

  handleClick = () => {
    const { field, currentSortType, onChange } = this.props;
    const [sort, order] = currentSortType.split('__');
    const newOrder = sort !== field || order === 'desc' ? 'asc' : 'desc';
    onChange(`${field}__${newOrder}`);
  };

  render() {
    const { field, globalClassName, children, currentSortType } = this.props;
    const className = globalClassName ? `${styles.sortableTh} ${globalClassName}` : styles.sortableTh;
    const [sort, order] = currentSortType.split('__');
    const showOrder = field === sort && (order === 'asc' || order === 'desc');
    let pulldownStyle = '';
    if (showOrder && order === 'desc') {
      pulldownStyle = styles.sortableThIconDesc;
    } else if (showOrder && order === 'asc') {
      pulldownStyle = styles.sortableThIconAsc;
    }
    return (
      <th onClick={this.handleClick} className={className}>
        {children}
        {pulldownStyle !== '' && <Pulldown size={7} className={pulldownStyle} />}
      </th>
    );
  }
}

export default SortableTh;
