import React from 'react';
import { EMPLOYMENT_INSURANCE_USED_CONTACT_INFOS } from 'src/constants/Options';
import { FormSection } from 'redux-form';
import { OFFICE_FORM } from 'src/constants/FormNames';
import { RadioField, SelectField, NumberFields, BoxDouble, Label } from 'jbc-front/components/Form';
import { sumFloatNumberFixed5, toFloatString } from 'src/utils/Utils';
import _ from 'lodash';
import { addReferenceOfficeItem } from 'src/reducers/offices/insurance';
import LabourInsurancePeriodSetting from '../../containers/edit/LabourInsurancePeriodSettingContainer';
import EmploymentInsuranceContactInfoSection from '../../containers/edit/EmploymentInsuranceContactInfoSectionContainer';
import RateTable from './RateTable';
import styles from './InsuranceSection.scss';

const upperLowUpdateFields = [
  'accidentInsurance.rateForEmployee',
  'accidentInsurance.rateForEmployer',
  'accidentInsurance.isInput',
  'accidentInsurance.insuranceType',
  'accidentInsurance.insuranceCode1',
  'accidentInsurance.insuranceCode2',
  'accidentInsurance.insuranceCode3',
  'accidentInsurance.insuranceCode4',
  'accidentInsurance.insuranceCode5',
  'accidentInsurance.officeId',
  'accidentInsurance.occupationAccidentInsuranceOfficeId',
  'accidentInsurance.insuranceCode'
];
const lowerLowUpdateFields = [
  'employmentInsurance.rateForEmployee',
  'employmentInsurance.rateForEmployer',
  'employmentInsurance.insuranceType',
  'employmentInsurance.governmentOfficePrefectureId',
  'employmentInsurance.governmentOfficeId',
  'employmentInsurance.insuranceCode1',
  'employmentInsurance.insuranceCode2',
  'employmentInsurance.insuranceCode3',
  'employmentInsurance.labourInsuranceCode1',
  'employmentInsurance.labourInsuranceCode2',
  'employmentInsurance.labourInsuranceCode3',
  'employmentInsurance.labourInsuranceCode4',
  'employmentInsurance.labourInsuranceCode5',
  'employmentInsurance.officeId',
  'employmentInsurance.employmentInsuranceOfficeId',
  'employmentInsurance.insuranceCode',
  'employmentInsurance.labourInsuranceCode',
  'employmentInsurance.roundingTypeForEmployee'
];

const LabourInsuranceSection = props => {
  const {
    employmentInsurance,
    accidentInsurance,
    accidentInsuranceTypes,
    employmentInsuranceTypes,
    prefectures,
    helloWorks,
    fetchAccidentInsuranceRates,
    fetchEmploymentInsuranceRates,
    settingStartDate,
    changeIsInput,
    isInitialized,
    isMainOffice,
    accidentInsuranceSelf,
    employmentInsuranceSelf,
    refAccidentInsuranceChange,
    refEmploymentInsuranceChange,
    referenceAccidentOffices,
    referenceEmploymentOffices,
    allReferenceAccidentOffices,
    allReferenceEmploymentOffices
  } = props;
  const { insuranceType, isInput } = accidentInsurance;
  const { governmentOfficePrefectureId } = employmentInsurance;

  const totalizeRate = (employeeRate, employerRate) => toFloatString(sumFloatNumberFixed5(employeeRate, employerRate));
  const totalPremiumRate = totalizeRate(accidentInsurance.rateForEmployee, accidentInsurance.rateForEmployer);
  const totalEmploymentRate = totalizeRate(employmentInsurance.rateForEmployee, employmentInsurance.rateForEmployer);
  const changeSelectCalendarMonth = () => {
    // 労働保険情報はプルダウンが2つあるのでそれぞれ処理を実行する
    // 引数がない場合は処理しない
    if (
      !(
        _.isEmpty(referenceAccidentOffices) ||
        _.isEmpty(allReferenceAccidentOffices) ||
        _.isNull(accidentInsurance.occupationAccidentInsuranceOfficeId)
      )
    ) {
      addReferenceOfficeItem(
        referenceAccidentOffices,
        allReferenceAccidentOffices,
        accidentInsurance.occupationAccidentInsuranceOfficeId
      );
    }

    // 引数がない場合は処理しない
    if (
      !(
        _.isEmpty(referenceEmploymentOffices) ||
        _.isEmpty(allReferenceEmploymentOffices) ||
        _.isNull(employmentInsurance.employmentInsuranceOfficeId)
      )
    ) {
      addReferenceOfficeItem(
        referenceEmploymentOffices,
        allReferenceEmploymentOffices,
        employmentInsurance.employmentInsuranceOfficeId
      );
    }
  };

  const handleAccidentInsuranceTypeChange = (_e, value) => {
    const newType = parseInt(value, 10);
    // 現在選択している値と異なるときのみ更新
    if (insuranceType !== newType) {
      // 業種を変えたら手入力フラグは初期化する
      changeIsInput(false);
      fetchAccidentInsuranceRates(value, settingStartDate);
    }
  };

  const handleEmploymentInsuranceTypeChange = (_e, value) => {
    const newType = parseInt(value, 10);
    // 現在選択している値と異なるときのみ更新
    if (insuranceType !== newType) {
      fetchEmploymentInsuranceRates(value, settingStartDate);
    }
  };

  const handleIsInputChange = (_e, value) => {
    if (value === false) {
      fetchAccidentInsuranceRates(insuranceType, settingStartDate);
    }
  };

  const accidentOfficeContactInfoChange = (_e, value) => {
    refAccidentInsuranceChange(settingStartDate, value);
  };

  const employmentOfficeContactInfoChange = (_e, value) => {
    refEmploymentInsuranceChange(settingStartDate, value);
  };

  const filterHelloWork = prefectureId => helloWorks[prefectureId] || [];

  const fetchAccidentInsuranceType = id => {
    const item = accidentInsuranceTypes.find(element => element.id === id);
    if (_.isEmpty(item)) {
      return '';
    }
    return item.businessType;
  };

  const fetchHelloWork = (prefectureId, id) => {
    if (_.isEmpty(helloWorks[prefectureId])) {
      return '';
    }
    return helloWorks[prefectureId].find(item => item.id === id).name;
  };

  const fetchEmploymentInsuranceType = type => {
    const item = employmentInsuranceTypes.find(element => element.value === type);
    if (_.isEmpty(item)) {
      return '';
    }
    return item.label;
  };

  return (
    <div>
      <FormSection name="labourInsurance">
        {!isInitialized && (
          <LabourInsurancePeriodSetting
            formName={OFFICE_FORM}
            formSectionName="labourInsurance"
            fieldName="settingStartDate"
            upperLowUpdateFields={upperLowUpdateFields}
            lowerLowUpdateFields={lowerLowUpdateFields}
            upperLowLabel="労災"
            lowerLowLabel="雇用"
            insuranceType="labourInsurance"
            payTextFormat="YYYY年M月締めの支給分に適用"
            bonusTextFormat="YYYY年M月支給分に適用"
            onChange={changeSelectCalendarMonth()}
          />
        )}
      </FormSection>
      <FormSection name="accidentInsurance" className={styles.formSection}>
        <p className={styles.heading}>労災保険</p>
        {!isMainOffice && (
          <React.Fragment>
            <SelectField
              label="参照する事業所情報"
              name="occupationAccidentInsuranceOfficeId"
              addBlankOption={false}
              options={referenceAccidentOffices}
              onChange={accidentOfficeContactInfoChange}
            />
          </React.Fragment>
        )}
        {!accidentInsuranceSelf && !isMainOffice ? (
          <div className={styles.applicableOffice}>
            <table>
              <tbody>
                <tr>
                  <td colSpan={6} className={styles.Detail}>
                    <table className={styles.table}>
                      <tbody>
                        <tr>
                          <th>事業所名：</th>
                          <td>{accidentInsurance.contactInfoName}</td>
                        </tr>
                        <tr>
                          <th>住所：</th>
                          <td>{accidentInsurance.contactInfoAddress}</td>
                        </tr>
                        <tr>
                          <th>労働保険番号：</th>
                          <td>{accidentInsurance.insuranceCode}</td>
                        </tr>
                        <tr>
                          <th>業種：</th>
                          <td>{fetchAccidentInsuranceType(accidentInsurance.insuranceType)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.refStyle}>
              <RateTable
                firstRowParams={{
                  label: '労災保険',
                  disableEmployee: true,
                  disableEmployer: true,
                  totalRate: totalPremiumRate
                }}
              />
            </div>
          </div>
        ) : (
          <React.Fragment>
            <NumberFields
              label="労働保険番号"
              texts={['', ' - ']}
              lengths={[2, 1, 2, 6, 3]}
              indexOffset={1}
              seperateChar=""
              exactLength
              prefix="insuranceCode"
            />
            <SelectField
              required
              label="業種"
              note={
                <p>
                  詳細については
                  <a
                    href="https://www.mhlw.go.jp/stf/seisakunitsuite/bunya/koyou_roudou/roudoukijun/rousai/rousaihoken06/rousai_hokenritsu_kaitei.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    厚生労働省HP
                  </a>
                  をご覧ください。
                </p>
              }
              noteWidth={280}
              name="insuranceType"
              options={accidentInsuranceTypes}
              onChange={handleAccidentInsuranceTypeChange}
            />
            <Label text="保険料率" />
            {insuranceType && (
              <div className={styles.taxRate}>
                <RadioField
                  name="isInput"
                  options={[{ value: false, label: '基本料率' }, { value: true, label: '直接入力' }]}
                  normalize={value => value === 'true'}
                  onChange={handleIsInputChange}
                />
              </div>
            )}
            <RateTable
              firstRowParams={{
                label: '労災保険',
                disableEmployee: true,
                disableEmployer: !isInput,
                totalRate: totalPremiumRate
              }}
            />
          </React.Fragment>
        )}
      </FormSection>
      <FormSection name="employmentInsurance" className={styles.formSection}>
        <p className={styles.heading}>雇用保険</p>
        {!isMainOffice && (
          <React.Fragment>
            <SelectField
              label="参照する事業所情報"
              name="employmentInsuranceOfficeId"
              addBlankOption={false}
              options={referenceEmploymentOffices}
              onChange={employmentOfficeContactInfoChange}
            />
          </React.Fragment>
        )}

        {!employmentInsuranceSelf && !isMainOffice ? (
          <div className={styles.applicableOffice}>
            <table>
              <tbody>
                <tr>
                  <td colSpan={6} className={styles.Detail}>
                    <table className={styles.table}>
                      <tbody>
                        <tr>
                          <th>事業所名：</th>
                          <td>{employmentInsurance.contactInfoName}</td>
                        </tr>
                        <tr>
                          <th>住所：</th>
                          <td>{employmentInsurance.contactInfoAddress}</td>
                        </tr>
                        <tr>
                          <th>労働保険番号：</th>
                          <td>{employmentInsurance.labourInsuranceCode}</td>
                        </tr>
                        <tr>
                          <th>雇用保険番号：</th>
                          <td>{employmentInsurance.insuranceCode}</td>
                        </tr>
                        <tr>
                          <th>管轄のハローワーク：</th>
                          <td>
                            {fetchHelloWork(
                              employmentInsurance.governmentOfficePrefectureId,
                              employmentInsurance.governmentOfficeId
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>業種：</th>
                          <td>{fetchEmploymentInsuranceType(employmentInsurance.insuranceType)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className={styles.refStyle}>
              <RateTable
                firstRowParams={{
                  label: '雇用保険',
                  disableEmployee: true,
                  disableEmployer: true,
                  displayRoundType: true,
                  disableRoundType: true,
                  cloumnName: 'roundingTypeForEmployee',
                  totalRate: totalEmploymentRate
                }}
                roundingTypeForEmployee={employmentInsurance.roundingTypeForEmployee}
              />
            </div>
          </div>
        ) : (
          <React.Fragment>
            {accidentInsurance.useContactInfo === 'manual' && (
              <React.Fragment>
                <RadioField isDirectionColumn name="useContactInfo" options={EMPLOYMENT_INSURANCE_USED_CONTACT_INFOS} />
                {employmentInsurance.useContactInfo === 'manual' && (
                  <FormSection name="contactInfo">
                    <EmploymentInsuranceContactInfoSection />
                  </FormSection>
                )}
              </React.Fragment>
            )}
            {accidentInsurance.useContactInfo === 'manual' &&
              employmentInsurance.useContactInfo === 'manual' && (
                <NumberFields
                  label="労働保険番号"
                  texts={['', ' - ']}
                  lengths={[2, 1, 2, 6, 3]}
                  indexOffset={1}
                  seperateChar=""
                  exactLength
                  prefix="labourInsuranceCode"
                />
              )}
            <NumberFields
              label="雇用保険番号（事業所番号）"
              texts={['', ' - ']}
              lengths={[4, 6, 1]}
              indexOffset={1}
              seperateChar=""
              exactLength
              prefix="insuranceCode"
            />
            <div>
              <Label text="管轄のハローワーク" />
              <BoxDouble>
                <SelectField name="governmentOfficePrefectureId" options={prefectures} />
                <SelectField name="governmentOfficeId" options={filterHelloWork(governmentOfficePrefectureId)} />
              </BoxDouble>
            </div>
            <RadioField
              required
              label="業種"
              name="insuranceType"
              options={employmentInsuranceTypes}
              onChange={handleEmploymentInsuranceTypeChange}
            />
            <RateTable
              firstRowParams={{
                label: '雇用保険',
                disableEmployee: true,
                disableEmployer: true,
                displayRoundType: true,
                disableRoundType: false,
                cloumnName: 'roundingTypeForEmployee',
                totalRate: totalEmploymentRate
              }}
              roundingTypeForEmployee={employmentInsurance.roundingTypeForEmployee}
            />
          </React.Fragment>
        )}
      </FormSection>
    </div>
  );
};

export default LabourInsuranceSection;
