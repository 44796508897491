import React, { useEffect } from 'react';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import _ from 'lodash';

import { setProcedureList } from 'src/reducers/procedures/procedures';
import { setGlobalErrors } from 'src/reducers/global';
import { setClientBonusList } from 'src/reducers/clients/clientBonus';
import { USER_LOGIN } from 'src/constants/EndpointUrls';
import { redirectTo } from 'src/utils/Http';
import { isRoleTypeAdmin, isRoleTypeEmployee, isRoleTypeOperator } from 'src/reducers/users/currentUser';
import ErrorPage from 'src/components/ErrorPage';
import AdminDashboard from './AdminDashboard';
import EmployeeDashboard from './EmployeeDashboard';

const Dashboard = ({ dispatch, currentUser, planType, values }) => {
  const {
    systemAnnounces,
    todayMemos,
    notCompletedSetting,
    notCompletedProcedures,
    notCompletedBonusPaymentNotification,
    employeeAnnounces,
    downloadPath,
    unverifiedAdminIp
  } = values;

  useEffect(() => {
    if (unverifiedAdminIp) {
      dispatch(
        setGlobalErrors([
          `接続元IPアドレスが管理者向け機能で許可されていないため、従業員向けダッシュボードを表示しています[${unverifiedAdminIp}]`
        ])
      );
    }
    const loadData = async () => {
      if (!_.isNull(downloadPath)) {
        const headers = { 'X-Requested-With': 'XMLHttpRequest' };
        const response = await fetch(downloadPath, { headers });
        if (!response.ok) {
          const json = await response.json();
          dispatch(setGlobalErrors(json.errors.messages));
        } else {
          const responseBlob = await response.blob();
          const blob = new Blob([responseBlob], { type: 'text/csv' });
          const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
          saveAs(blob, disposition.parameters.filename);
        }
      }
    };
    loadData();
  }, []);

  dispatch(setProcedureList(notCompletedProcedures));
  dispatch(setClientBonusList({ data: notCompletedBonusPaymentNotification }));

  if (!currentUser.isAuthorized) {
    redirectTo(USER_LOGIN);
  }

  return (
    <div>
      {unverifiedAdminIp || isRoleTypeEmployee(currentUser.roleType) ? (
        <EmployeeDashboard employeeAnnounces={employeeAnnounces} />
      ) : isRoleTypeAdmin(currentUser.roleType) || isRoleTypeOperator(currentUser.roleType) ? (
        <AdminDashboard
          {...{
            systemAnnounces,
            notCompletedSetting,
            todayMemos,
            planType,
            employeeAnnounces
          }}
        />
      ) : (
        <ErrorPage />
      )}
    </div>
  );
};
export default Dashboard;
