import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector, isInvalid, touch, untouch, clearFields, reset, Field } from 'redux-form';
import compose from 'lodash/fp/compose';
import axios from 'axios';
import _ from 'lodash';
import classNames from 'classnames';
import CommonModal from 'jbc-front/components/CommonModal';
import {
  TextField,
  DateField,
  Label,
  DateSpanFields,
  renderInlineDatePickerField,
  Error
} from 'jbc-front/components/Form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import Button from 'jbc-front/components/Button';
import jbcFormStyles from 'jbc-front/components/Form.scss';
import * as validators from 'jbc-front/utils/validators';

import * as Urls from 'src/constants/EndpointUrls';
import { maxLength50, itemsChecked } from 'src/utils/CustomValidators';
import { getSelectOptions, setOptions } from 'src/reducers/selectOptions';
import { getItem, setItem } from 'src/reducers/common/itemReducer';
import {
  getClientBonusItemErrors,
  fetchBonus,
  newBonus,
  editBonus,
  REDUCER_NAME,
  clearErrors
} from 'src/reducers/clients/clientBonus';
import ErrorsSection from 'src/components/ErrorsSection';
import styles from './BonusFormModal.scss';

const dateFieldProps = {
  normalize: value => (_.isObject(value) && value.format ? value.format('YYYY/MM/DD') : value || ''),
  format: value => (_.isString(value) ? value.replace(/^(\d{4})-(\d{2})-(\d{2}).*$/, '$1/$2/$3') : value || ''),
  validate: validators.date
};

const EmploymentTypes = ({ input: inputProps, meta, list }) => (
  <div className={jbcFormStyles.boxWrap}>
    <div className={styles.checkboxes}>
      {list.map(({ value, label }) => (
        <label key={value} className={classNames('input-label')}>
          <input
            type="checkbox"
            className="m-checkbox-input"
            checked={inputProps.value.indexOf(value) >= 0}
            onChange={() => {
              const newValues =
                inputProps.value.indexOf(value) >= 0
                  ? inputProps.value.filter(ele => ele !== value)
                  : [...inputProps.value, value];
              inputProps.onChange(newValues);
              inputProps.onBlur(newValues);
            }}
          />
          <span className="m-checkbox-parts">{label}</span>
        </label>
      ))}
    </div>
    <Error meta={meta} label="支給対象者" />
  </div>
);

const applyForResignedFromValidate = (value, values) => {
  if (_.isEmpty(values.applyForResignedTo)) {
    return validators.date(value);
  }
  let errorMessage;
  [validators.required, validators.date].some(func => {
    errorMessage = func(value);
    return errorMessage !== undefined;
  });
  return errorMessage;
};

const ApplyForResignedRange = ({ applyForResigned }) => (
  <div className={styles.applyForResignedRange}>
    <Field
      component={renderInlineDatePickerField}
      type="text"
      name="applyForResignedFrom"
      validate={applyForResignedFromValidate}
      width={210}
      {...{ inputProps: { disabled: !applyForResigned } }}
      {...dateFieldProps}
    />
    &nbsp;〜&nbsp;
    <Field
      component={renderInlineDatePickerField}
      type="text"
      name="applyForResignedTo"
      validate={validators.date}
      width={210}
      {...{ inputProps: { disabled: !applyForResigned } }}
      {...dateFieldProps}
    />
    <Field
      name="applyForResignedFrom"
      component={Error}
      label="対象期間の開始日"
      validate={applyForResignedFromValidate}
    />
    <LabelMapper name="applyForResignedFrom" label="対象期間の開始日" />
    <Field name="applyForResignedTo" component={Error} label="対象期間の終了日" validate={validators.date} />
    <LabelMapper name="applyForResignedTo" label="対象期間の終了日" />
  </div>
);

const convertToPostData = formValues => ({
  clientBonus: {
    ..._.omit(formValues, ['apply_for_start_on', 'apply_for_end_on', 'employmentTypes']),
    apply_for_time_from: formValues.apply_for_start_on,
    apply_for_time_to: formValues.apply_for_end_on
  },
  clientEmploymentTypes: formValues.employmentTypes.join('_')
});

const convertToFormValues = clientBonus =>
  _.isEmpty(clientBonus)
    ? {}
    : {
        ..._.omit(clientBonus, ['applyForTimeFrom', 'applyForTimeTo', 'clientEmploymentTypes']),
        apply_for_start_on: clientBonus.applyForTimeFrom,
        apply_for_end_on: clientBonus.applyForTimeTo,
        employmentTypes: clientBonus.clientEmploymentTypes.map(item => item.id)
      };

class FormModal extends React.Component {
  componentDidMount() {
    const { clientBonusId, dispatch } = this.props;

    axios.get(Urls.OPTIONS_CLIENT_EMPLOYMENT_TYPES).then(response => {
      dispatch(
        setOptions({
          employmentTypes: response.data.payload.options
        })
      );
    });

    if (clientBonusId) {
      dispatch(fetchBonus(clientBonusId));
    } else {
      dispatch(setItem(REDUCER_NAME, {}));
    }
  }

  handleHide = () => {
    const { hideModal, dispatch } = this.props;
    dispatch(reset('clientBonusForm'));
    dispatch(setItem(REDUCER_NAME, {}));
    dispatch(clearErrors());
    hideModal();
  };

  render() {
    const { isEditForm, isOpen } = this.props;
    const {
      employmentTypes,
      applyForResigned,
      invalid,
      errors,
      handleSubmit,
      submitting,
      pristine,
      submit
    } = this.props;
    return (
      <CommonModal isOpen={isOpen} hideModal={this.handleHide} closeOnClick ariaHideApp={false}>
        <CommonModal.Header hideModal={this.handleHide}>
          {isEditForm ? '賞与情報編集' : '賞与新規作成'}
        </CommonModal.Header>
        <CommonModal.Body>
          <form onSubmit={handleSubmit}>
            <div style={{ paddingLeft: 15, paddingRight: 15 }}>
              <ErrorsSection errors={errors} />

              <TextField name="name" label="賞与名" required validate={maxLength50} />

              <DateSpanFields
                label="対象期間"
                prefix="apply_for"
                noteWidth="200"
                note={
                  <div>
                    対象期間を設定した場合、雇用保険料の徴収や所得税計算などに影響があります。 詳細は<a href="https://jobcan-payroll.zendesk.com/hc/ja/articles/900004160926">
                      こちら
                    </a>
                  </div>
                }
              />

              <DateField name="payOn" label="支給日" required />

              <Label text="支給対象者" required />
            </div>
            <div style={{ paddingLeft: 30 }}>
              <Field
                name="employmentTypes"
                component={EmploymentTypes}
                list={employmentTypes}
                validate={itemsChecked}
              />
              <div>
                <div style={{ marginBottom: 4 }}>
                  <Field
                    name="applyForResigned"
                    component={({ input: inputProps, meta }) => (
                      <label className="input-label">
                        <input
                          type="checkbox"
                          className="m-checkbox-input"
                          checked={inputProps.value}
                          onChange={() => {
                            const newValues = !inputProps.value;
                            inputProps.onChange(newValues);
                            inputProps.onBlur(newValues);
                            if (newValues) {
                              meta.dispatch(touch('clientBonusForm', 'applyForResignedFrom', 'applyForResignedTo'));
                            } else {
                              meta.dispatch(
                                clearFields(
                                  'clientBonusForm',
                                  false,
                                  false,
                                  'applyForResignedFrom',
                                  'applyForResignedTo'
                                )
                              );
                              meta.dispatch(untouch('clientBonusForm', 'applyForResignedFrom', 'applyForResignedTo'));
                            }
                          }}
                        />
                        <span className="m-checkbox-parts">支給日より前に退職した従業員にも支給</span>
                      </label>
                    )}
                  />
                </div>
                <div style={{ paddingLeft: 26 }}>
                  <ApplyForResignedRange applyForResigned={applyForResigned} />
                  <div>までに退職した従業員</div>
                </div>
              </div>
            </div>
          </form>
        </CommonModal.Body>
        <CommonModal.Footer>
          <div className={styles.confirm}>
            <Button onClick={this.handleHide} className="u-mr20">
              キャンセル
            </Button>
            <Button
              primary
              text="保存"
              onClick={() => {
                submit();
              }}
              disabled={submitting || pristine || invalid}
            >
              保存
            </Button>
          </div>
        </CommonModal.Footer>
      </CommonModal>
    );
  }
}

const BonusFormModal = compose(
  connect(
    state => ({
      initialValues: convertToFormValues(getItem(REDUCER_NAME, state)),
      employmentTypes: getSelectOptions(state, 'employmentTypes'),
      applyForResigned: formValueSelector('clientBonusForm')(state, 'applyForResigned'),
      invalid: isInvalid('clientBonusForm')(state),
      errors: getClientBonusItemErrors(state)
    }),
    (dispatch, ownProps) => ({
      onSubmit: data => {
        const { isEditForm, clientBonusId } = ownProps;
        if (isEditForm) {
          dispatch(editBonus(clientBonusId, convertToPostData(data), ownProps.hideModal));
        } else {
          dispatch(newBonus(convertToPostData(data), ownProps.hideModal));
        }
      }
    })
  ),
  reduxForm({
    form: 'clientBonusForm',
    enableReinitialize: true
  })
)(FormModal);

export default BonusFormModal;
