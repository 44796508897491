import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';

import ActionButton from 'jbc-front/components/ActionButton';
import { Task, MinusCircle } from 'jbc-front/components/icons';
import Hint from 'jbc-front/components/Hint';
import { FormErrors } from 'src/components';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { EditActionBtn, SaveActionBtn } from 'src/buttons';
import { REPORT_LABOR_INSURANCE_SUMMARY_FORM } from 'src/constants/FormNames';
import { REPORT_LABOR_INSURANCE_SUMMARIES_PDF } from 'src/constants/EndpointUrls';
import { getList, getExtras } from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  editMonthlySummary,
  setSummaryTableShowMode,
  transformMonthlySummariesToFormValues,
  clearMonthlySummaryErrors,
  isLessThan2020Year,
  is2021Year,
  is2022Year,
  extractFirstTermMonthlySummaries,
  extractLatterTermMonthlySummaries
} from 'src/reducers/reports/reportLaborInsurances';
import MonthlySummaryAccidentInsurance from './MonthlySummaryAccidentInsurance';
import MonthlySummaryEmploymentInsurance from './MonthlySummaryEmploymentInsurance';
import YearlySummaryAccidentInsurance from './YearlySummaryAccidentInsurance';
import YearlySummaryEmploymentInsurance from './YearlySummaryEmploymentInsurance';
import ConfirmRecalcurateSummaryListModal from './ConfirmRecalcurateSummaryListModal';
import LaborInsuranceDeclarationForm2021Container from '../containers/LaborInsuranceDeclarationForm2021Container';
import LaborInsuranceAccidentDeclarationForm2022Container from '../containers/LaborInsuranceAccidentDeclarationForm2022Container';
import LaborInsuranceEmploymentDeclarationForm2022Container from '../containers/LaborInsuranceEmploymentDeclarationForm2022Container';
import styles from './SummaryList.scss';

class SummaryList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPdfPreview: false,
      monthlySummaries: this.props.monthlySummaries,
      isRecalcurateSummaryListModalOpen: false
    };
    this.monthlySumAccidentInsuranceChild = {};
    this.monthlySumEmpInsuranceChild = {};

    this.openRecalcurateSummaryListModal = this.openRecalcurateSummaryListModal.bind(this);
    this.closeRecalcurateSummaryListModal = this.closeRecalcurateSummaryListModal.bind(this);
  }

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };
  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };

  calcYearlySummary = params => {
    let newMonthlySummaries = this.state.monthlySummaries;
    if (!newMonthlySummaries) newMonthlySummaries = this.props.monthlySummaries;

    newMonthlySummaries = _.map(newMonthlySummaries, o => {
      if (params && o.id === params.id) return params;
      return o;
    });

    this.setState({
      ...this.state,
      monthlySummaries: newMonthlySummaries
    });
    this.yearlySumAccidentInsuranceChild.calculation(newMonthlySummaries);
    this.yearlySumEmpInsuranceChild.calculation(newMonthlySummaries);

    // 以降は2022年度のみの処理
    if (this.props.is2022 === false) return;

    const year = this.props.laborInsuranceYear;
    this.firstTermAccidentChild.calculation(extractFirstTermMonthlySummaries(newMonthlySummaries, year));
    this.latterTermAccidentChild.calculation(extractLatterTermMonthlySummaries(newMonthlySummaries, year));
    this.firstTermEmploymentChild.calculation(extractFirstTermMonthlySummaries(newMonthlySummaries, year));
    this.latterTermEmploymentChild.calculation(extractLatterTermMonthlySummaries(newMonthlySummaries, year));
  };

  rollbackMonthlySummaries() {
    this.props.monthlySummaries.forEach(monthlySummary => {
      this.monthlySumAccidentInsuranceChild[monthlySummary.id].calculation(monthlySummary);
      this.monthlySumEmpInsuranceChild[monthlySummary.id].calculation(monthlySummary);
    });
    this.yearlySumAccidentInsuranceChild.calculation(this.props.monthlySummaries);
    this.yearlySumEmpInsuranceChild.calculation(this.props.monthlySummaries);
    this.setState({
      ...this.state,
      monthlySummaries: this.props.monthlySummaries
    });
  }

  openRecalcurateSummaryListModal() {
    if (this.state.isRecalcurateSummaryListModalOpen) return;
    this.setState({ ...this.state, isRecalcurateSummaryListModalOpen: true });
  }

  closeRecalcurateSummaryListModal() {
    if (!this.state.isRecalcurateSummaryListModalOpen) return;
    this.setState({ ...this.state, isRecalcurateSummaryListModalOpen: false });
  }

  pdfUrl = (laborInsuranceYear, applicableOffice) =>
    `${REPORT_LABOR_INSURANCE_SUMMARIES_PDF}?&pdf[labor_insurance_year]=${laborInsuranceYear}&pdf[applicable_office]=${applicableOffice}`;

  pdfDownloadUrl = (laborInsuranceYear, applicableOffice) =>
    `${REPORT_LABOR_INSURANCE_SUMMARIES_PDF}?pdf[type]=zip&pdf[labor_insurance_year]=${laborInsuranceYear}&pdf[applicable_office]=${applicableOffice}`;

  render() {
    const {
      pristine,
      submitting,
      actionMode,
      laborInsuranceYear,
      applicableOffice,
      monthlySummaries,
      monthlySummariesFirstTerm,
      monthlySummariesLatterTerm,
      summaryToSnaps,
      yearlySummary,
      reset,
      save,
      changeToEditMode,
      changeToViewMode,
      clearErrorMessages,
      errors,
      isLessThan2020,
      is2021,
      is2022
    } = this.props;
    return (
      <div className="u-mt20">
        {!laborInsuranceYear && (
          <div className={styles.empty_messages}>
            <div>登録済みの給与・賞与データがありません。</div>
          </div>
        )}
        {laborInsuranceYear &&
          (!monthlySummaries || monthlySummaries.length === 0) && (
            <div className={styles.empty_messages}>
              <div>{laborInsuranceYear}年度に登録済みの給与・賞与データがありません。</div>
              <div>{laborInsuranceYear + 1}年3月締めの給与を確定させると表示されます。</div>
            </div>
          )}
        {monthlySummaries &&
          monthlySummaries.length > 0 && (
            <div>
              <FormErrors errors={errors} />
              <div className={styles.action}>
                <div>
                  {actionMode === 'SHOW' ? (
                    <React.Fragment>
                      <EditActionBtn
                        onClick={() => {
                          changeToEditMode();
                        }}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <SaveActionBtn as="div" onClick={save} disabled={pristine || submitting} className="u-mr10" />
                      <ActionButton
                        onClick={() => {
                          reset();
                          this.rollbackMonthlySummaries();
                          clearErrorMessages();
                          changeToViewMode();
                        }}
                        className="u-mr10"
                        icon={<MinusCircle size={15} />}
                      >
                        キャンセル
                      </ActionButton>
                      <ActionButton onClick={this.openRecalcurateSummaryListModal} className="u-mr10">
                        手入力前に戻す
                      </ActionButton>
                    </React.Fragment>
                  )}
                </div>
                <div>
                  <ActionButton
                    primary
                    icon={<Task size={16} />}
                    disabled={actionMode !== 'SHOW'}
                    onClick={this.showPreview}
                  >
                    PDF出力
                  </ActionButton>
                </div>
              </div>
              <div className="l-overflow-scroll">
                <table className={styles.table}>
                  <colgroup>
                    <col width={133} />
                    <col width={84} />
                    <col width={123} />
                    <col width={84} />
                    <col width={123} />
                    <col width={84} />
                    <col width={123} />
                    <col width={84} />
                    <col width={123} />
                  </colgroup>
                  <thead>
                    <tr>
                      <th />
                      <th colSpan="8">
                        労災保険および一般拠出金（対象者数及び賃金）
                        <Hint text={<p>カッコ内の数値は、修正前の数値を表示しています。</p>} />
                      </th>
                    </tr>
                    <tr>
                      <td rowSpan="2" />
                      <td colSpan="2">常用労働者</td>
                      <td colSpan="2">役員で従業員扱いの人</td>
                      <td colSpan="2">臨時労働者</td>
                      <td colSpan="2">合計</td>
                    </tr>
                    <tr>
                      <td>人数</td>
                      <td>賃金</td>
                      <td>人数</td>
                      <td>賃金</td>
                      <td>人数</td>
                      <td>賃金</td>
                      <td>人数</td>
                      <td>賃金</td>
                    </tr>
                  </thead>
                  <tbody>
                    {(!monthlySummaries || monthlySummaries.length === 0) && (
                      <tr>
                        <td colSpan={9}>データがありません。</td>
                      </tr>
                    )}
                    {monthlySummaries &&
                      monthlySummaries.length > 0 && (
                        <React.Fragment>
                          {is2022 ? (
                            <React.Fragment>
                              {monthlySummariesFirstTerm.map(monthlySummary => (
                                <MonthlySummaryAccidentInsurance
                                  key={`${monthlySummary.id}`}
                                  actionMode={actionMode}
                                  monthlySummary={monthlySummary}
                                  monthlySummarySnap={summaryToSnaps.find(s => s.id === monthlySummary.id).snap}
                                  monthlySummaries={monthlySummariesFirstTerm}
                                  calcYearlySummary={this.calcYearlySummary}
                                  onRef={ref => {
                                    this.monthlySumAccidentInsuranceChild[monthlySummary.id] = ref;
                                  }}
                                />
                              ))}
                              <YearlySummaryAccidentInsurance
                                monthlySummaries={monthlySummariesFirstTerm}
                                yearlySummary={yearlySummary}
                                onRef={ref => {
                                  this.firstTermAccidentChild = ref;
                                }}
                                title={'令和4年度\n前期計'}
                              />
                              {monthlySummariesLatterTerm.map(monthlySummary => (
                                <MonthlySummaryAccidentInsurance
                                  key={`${monthlySummary.id}`}
                                  actionMode={actionMode}
                                  monthlySummary={monthlySummary}
                                  monthlySummarySnap={summaryToSnaps.find(s => s.id === monthlySummary.id).snap}
                                  monthlySummaries={monthlySummariesLatterTerm}
                                  calcYearlySummary={this.calcYearlySummary}
                                  onRef={ref => {
                                    this.monthlySumAccidentInsuranceChild[monthlySummary.id] = ref;
                                  }}
                                />
                              ))}
                              <YearlySummaryAccidentInsurance
                                monthlySummaries={monthlySummariesLatterTerm}
                                yearlySummary={yearlySummary}
                                onRef={ref => {
                                  this.latterTermAccidentChild = ref;
                                }}
                                title={'令和4年度\n後期計'}
                              />
                            </React.Fragment>
                          ) : (
                            monthlySummaries.map(monthlySummary => (
                              <MonthlySummaryAccidentInsurance
                                key={`${monthlySummary.id}`}
                                actionMode={actionMode}
                                monthlySummary={monthlySummary}
                                monthlySummarySnap={summaryToSnaps.find(s => s.id === monthlySummary.id).snap}
                                monthlySummaries={monthlySummaries}
                                calcYearlySummary={this.calcYearlySummary}
                                onRef={ref => {
                                  this.monthlySumAccidentInsuranceChild[monthlySummary.id] = ref;
                                }}
                              />
                            ))
                          )}
                        </React.Fragment>
                      )}
                    {monthlySummaries &&
                      monthlySummaries.length > 0 && (
                        <YearlySummaryAccidentInsurance
                          monthlySummaries={monthlySummaries}
                          yearlySummary={yearlySummary}
                          onRef={ref => {
                            this.yearlySumAccidentInsuranceChild = ref;
                          }}
                          title={'合計'}
                        />
                      )}
                  </tbody>
                </table>
                {is2022 && (
                  <React.Fragment>
                    <br />
                    <LaborInsuranceAccidentDeclarationForm2022Container actionMode={actionMode} />
                  </React.Fragment>
                )}
                <table className={`${styles.table} u-mt30`}>
                  {isLessThan2020 ? (
                    <colgroup>
                      <col width={133} />
                      <col width={84} />
                      <col width={123} />
                      <col width={84} />
                      <col width={123} />
                      <col width={84} />
                      <col width={123} />
                      <col width={84} />
                      <col width={123} />
                    </colgroup>
                  ) : (
                    <colgroup>
                      <col width={133} />
                      <col width={113} />
                      <col width={160} />
                      <col width={113} />
                      <col width={160} />
                      <col width={113} />
                      <col width={160} />
                    </colgroup>
                  )}
                  <thead>
                    <tr>
                      <th />
                      <th colSpan="8">
                        雇用保険（対象者数及び賃金）
                        <Hint text={<p>カッコ内の数値は、修正前の数値を表示しています。</p>} />
                      </th>
                    </tr>
                    <tr>
                      <td rowSpan="2" />
                      <td colSpan="2">雇用保険資格のある従業員</td>
                      <td colSpan="2">雇用保険資格のある役員</td>
                      <td colSpan="2">合計</td>
                      {isLessThan2020 && <td colSpan="2">免除対象高年齢労働者分</td>}
                    </tr>
                    <tr>
                      <td>人数</td>
                      <td>賃金</td>
                      <td>人数</td>
                      <td>賃金</td>
                      <td>人数</td>
                      <td>賃金</td>
                      {isLessThan2020 && (
                        <React.Fragment>
                          <td>人数</td>
                          <td>賃金</td>
                        </React.Fragment>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {(!monthlySummaries || monthlySummaries.length === 0) && (
                      <tr>
                        <td colSpan={9}>データがありません。</td>
                      </tr>
                    )}
                    {monthlySummaries &&
                      monthlySummaries.length > 0 && (
                        <React.Fragment>
                          {is2022 ? (
                            <React.Fragment>
                              {monthlySummariesFirstTerm.map(monthlySummary => (
                                <MonthlySummaryEmploymentInsurance
                                  key={`${monthlySummary.id}`}
                                  actionMode={actionMode}
                                  monthlySummary={monthlySummary}
                                  monthlySummarySnap={summaryToSnaps.find(s => s.id === monthlySummary.id).snap}
                                  monthlySummaries={monthlySummariesFirstTerm}
                                  calcYearlySummary={this.calcYearlySummary}
                                  isLessThan2020={isLessThan2020}
                                  onRef={ref => {
                                    this.monthlySumEmpInsuranceChild[monthlySummary.id] = ref;
                                  }}
                                />
                              ))}
                              <YearlySummaryEmploymentInsurance
                                monthlySummaries={monthlySummariesFirstTerm}
                                yearlySummary={yearlySummary}
                                isLessThan2020={isLessThan2020}
                                onRef={ref => {
                                  this.firstTermEmploymentChild = ref;
                                }}
                                title={'令和4年度\n前期計'}
                              />
                              {monthlySummariesLatterTerm.map(monthlySummary => (
                                <MonthlySummaryEmploymentInsurance
                                  key={`${monthlySummary.id}`}
                                  actionMode={actionMode}
                                  monthlySummary={monthlySummary}
                                  monthlySummarySnap={summaryToSnaps.find(s => s.id === monthlySummary.id).snap}
                                  monthlySummaries={monthlySummariesLatterTerm}
                                  calcYearlySummary={this.calcYearlySummary}
                                  isLessThan2020={isLessThan2020}
                                  onRef={ref => {
                                    this.monthlySumEmpInsuranceChild[monthlySummary.id] = ref;
                                  }}
                                />
                              ))}
                              <YearlySummaryEmploymentInsurance
                                monthlySummaries={monthlySummariesLatterTerm}
                                yearlySummary={yearlySummary}
                                isLessThan2020={isLessThan2020}
                                onRef={ref => {
                                  this.latterTermEmploymentChild = ref;
                                }}
                                title={'令和4年度\n後期計'}
                              />
                            </React.Fragment>
                          ) : (
                            monthlySummaries.map(monthlySummary => (
                              <MonthlySummaryEmploymentInsurance
                                key={`${monthlySummary.id}`}
                                actionMode={actionMode}
                                monthlySummary={monthlySummary}
                                monthlySummarySnap={summaryToSnaps.find(s => s.id === monthlySummary.id).snap}
                                monthlySummaries={monthlySummaries}
                                calcYearlySummary={this.calcYearlySummary}
                                onRef={ref => {
                                  this.monthlySumEmpInsuranceChild[monthlySummary.id] = ref;
                                }}
                              />
                            ))
                          )}
                        </React.Fragment>
                      )}
                    {monthlySummaries &&
                      monthlySummaries.length > 0 && (
                        <YearlySummaryEmploymentInsurance
                          monthlySummaries={monthlySummaries}
                          yearlySummary={yearlySummary}
                          isLessThan2020={isLessThan2020}
                          onRef={ref => {
                            this.yearlySumEmpInsuranceChild = ref;
                          }}
                          title={'合計'}
                        />
                      )}
                  </tbody>
                </table>
              </div>
              <PreviewPdfModalContainer
                header="算定基礎賃金集計表"
                isOpen={this.state.isPdfPreview}
                hideModal={this.hidePreview}
                url={this.pdfUrl(laborInsuranceYear, applicableOffice)}
                downloadUrl={this.pdfDownloadUrl(laborInsuranceYear, applicableOffice)}
              />
              {this.state.isRecalcurateSummaryListModalOpen && (
                <ConfirmRecalcurateSummaryListModal
                  isOpen={this.state.isRecalcurateSummaryListModalOpen}
                  hideModal={this.closeRecalcurateSummaryListModal}
                  laborInsuranceYear={laborInsuranceYear}
                  applicableOffice={applicableOffice}
                />
              )}
              <br />
              {is2021 && <LaborInsuranceDeclarationForm2021Container actionMode={actionMode} />}
              {is2022 && <LaborInsuranceEmploymentDeclarationForm2022Container actionMode={actionMode} />}
            </div>
          )}
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  actionMode: getExtras(REDUCER_NAME, state).summaryTableShowMode || 'SHOW',
  monthlySummaries: getList(REDUCER_NAME, state).monthlySummaries,
  monthlySummariesFirstTerm: extractFirstTermMonthlySummaries(
    getList(REDUCER_NAME, state).monthlySummaries,
    ownProps.laborInsuranceYear
  ),
  monthlySummariesLatterTerm: extractLatterTermMonthlySummaries(
    getList(REDUCER_NAME, state).monthlySummaries,
    ownProps.laborInsuranceYear
  ),
  summaryToSnaps: getList(REDUCER_NAME, state).summaryToSnaps,
  yearlySummary: getList(REDUCER_NAME, state).yearlySummary,
  initialValues: transformMonthlySummariesToFormValues(state),
  errors: getExtras(REDUCER_NAME, state).editMonthlySummaryErrors || [],
  isLessThan2020: isLessThan2020Year(ownProps.laborInsuranceYear),
  is2021: is2021Year(ownProps.laborInsuranceYear),
  is2022: is2022Year(ownProps.laborInsuranceYear)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  save: () => {
    dispatch(editMonthlySummary(ownProps.laborInsuranceYear, ownProps.applicableOffice));
  },
  changeToEditMode: () => {
    dispatch(setSummaryTableShowMode('EDIT'));
  },
  changeToViewMode: () => {
    dispatch(setSummaryTableShowMode('SHOW'));
  },
  clearErrorMessages: () => {
    dispatch(clearMonthlySummaryErrors());
  }
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: REPORT_LABOR_INSURANCE_SUMMARY_FORM,
    enableReinitialize: true
  })
)(SummaryList);
