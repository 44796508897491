import React, { useState } from 'react';
import _ from 'lodash';
import dateFormat from 'dateformat';

import { formatDateWithHour } from 'src/utils/Date';
import { Label } from 'jbc-front/components/Form';
import ChangePayReleaseDateForm from 'src/features/EmployeePays/components/ChangePayReleaseDateForm';
import styles from './PayTargetDateRange.scss';

const PayTargetDateRange = props => {
  const [changeReleaseDateModalOpen, setChangeReleaseDateModalOpen] = useState(false);

  const hideModal = () => setChangeReleaseDateModalOpen(false);
  const showModal = () => setChangeReleaseDateModalOpen(true);

  const { paymentRange, releaseDateVisibility = false } = props;

  const targetStartDate = _.has(paymentRange, 'targetStartDate')
    ? dateFormat(paymentRange.targetStartDate, 'yyyy/mm/dd')
    : '';
  const targetEndDate = _.has(paymentRange, 'targetEndDate')
    ? dateFormat(paymentRange.targetEndDate, 'yyyy/mm/dd')
    : '';
  const paymentDate = _.has(paymentRange, 'value') ? paymentRange.value.replace(/_/g, '/') : '';
  const releaseDate = _.has(paymentRange, 'releaseDate') ? formatDateWithHour(paymentRange.releaseDate) : '';
  const sendNotifyToEmployee = !!_.get(paymentRange, 'sendNotifyToEmployee');

  const attendanceTargetStartDate = _.has(paymentRange, 'attendanceTargetStartDate')
    ? dateFormat(paymentRange.attendanceTargetStartDate, 'yyyy/mm/dd')
    : '';
  const attendanceTargetEndDate = _.has(paymentRange, 'attendanceTargetEndDate')
    ? dateFormat(paymentRange.attendanceTargetEndDate, 'yyyy/mm/dd')
    : '';

  const diffRange = targetStartDate !== attendanceTargetStartDate;

  return (
    <div className={styles.container}>
      <div>
        対象期間 {targetStartDate} 〜 {targetEndDate}
      </div>
      {diffRange && (
        <React.Fragment>
          <div>
            （勤怠対象期間 {attendanceTargetStartDate} ~ {attendanceTargetEndDate}）
          </div>
          <div className={styles.hintIcon}>
            <Label
              note={
                <p>
                  月給者の残業代や勤怠控除や、日給・時給者の給与は前月の勤怠から計算されます。<br />対象期間と勤怠対象期間が同一の場合、勤怠対象期間は表示されません。
                </p>
              }
            />
          </div>
        </React.Fragment>
      )}
      <div className={styles.paymentDate}>支給日 {paymentDate}</div>
      {releaseDateVisibility && (
        <React.Fragment>
          <div className={`${styles.releaseDate} ${styles.canClick}`} onClick={showModal} role="presentation">
            公開日&nbsp;{releaseDate}
          </div>
          <ChangePayReleaseDateForm
            releaseDate={releaseDate}
            sendNotifyToEmployee={sendNotifyToEmployee}
            isOpen={changeReleaseDateModalOpen}
            hideModal={hideModal}
          />
        </React.Fragment>
      )}
    </div>
  );
};

export default PayTargetDateRange;
