import React from 'react';

import { NumberFields } from 'jbc-front/components/Form';

const TelField = ({ required, disabled }) => (
  <NumberFields
    texts={['', ' - ']}
    lengths={[4, 4, 4]}
    required={required}
    disabled={disabled}
    label="電話番号"
    seperateChar=""
    prefix="telPart"
  />
);

export default TelField;
