import { connect } from 'react-redux';
import { fetchLaborInsuranceImportCsvJobs } from 'src/reducers/reports/reportLaborInsurances';
import ImportCsvJobStatus from '../components/LaborInsuranceImportCsvJobStatus';

const mapStateToProps = state => ({
  importCsvJobs: state.JobStatus.import_labor_insurance_employee_details_csv_job
});

const mapDispatchToProps = dispatch => ({
  fetchImportCsvJobs: () => dispatch(fetchLaborInsuranceImportCsvJobs())
});

// https://github.com/d-o-n-u-t-s/payrollservice/pull/10936
// TODO: useDispatch, useSelectorを使って書き換える
export default connect(mapStateToProps, mapDispatchToProps)(ImportCsvJobStatus);
