import React from 'react';
import ActionButton from 'jbc-front/components/ActionButton';
import { Revert, Delete } from 'jbc-front/components/icons';
import ChangePolicyModal from './ChangePolicyModal';
import DeletePolicyModal from './DeletePolicyModal';
import styles from './EditPolicy.scss';

export default class EditPolicy extends React.Component {
  state = {
    isChangeModalOpen: false,
    isDeleteModalOpen: false
  };

  render() {
    return (
      <div className={styles.wrap}>
        <ActionButton
          onClick={() => this.setState({ ...this.state, isChangeModalOpen: true })}
          icon={<Revert size={16} />}
        >
          チェックを入れたアカウントの権限を一括変更
        </ActionButton>
        <ChangePolicyModal
          isOpen={this.state.isChangeModalOpen}
          hideModal={() => {
            this.setState({ ...this.state, isChangeModalOpen: false });
          }}
        />
        <ActionButton
          onClick={() => this.setState({ ...this.state, isDeleteModalOpen: true })}
          icon={<Delete size={16} />}
        >
          チェックを入れたアカウントを削除
        </ActionButton>
        <DeletePolicyModal
          isOpen={this.state.isDeleteModalOpen}
          hideModal={() => {
            this.setState({ ...this.state, isDeleteModalOpen: false });
          }}
        />
      </div>
    );
  }
}
