import _ from 'lodash';
import validator from 'validator';
import moment from 'moment';
import * as Messages from 'src/constants/ValidationMessages';
import { toFloatString } from 'src/utils/Utils';

export const isRequired = value => (value ? undefined : Messages.ERROR_MSG_IS_REQUIRED);
export const isRequiredWithPrefix = value => (value ? undefined : Messages.ERROR_MSG_IS_REQUIRED_WITH_PREFIX);

export const isNumber = value => {
  if (!value) return undefined;
  const converted = String(value);
  if (!converted) return undefined;
  return validator.isNumeric(converted) ? undefined : Messages.ERROR_MSG_IS_NUMERIC;
};

export const checkNumberLength = length => value => {
  if (!value) return undefined;
  const converted = String(value);
  if (!converted) return undefined;
  const message = _.template(Messages.ERROR_MSG_IS_NUMERIC_LENGTH);
  return validator.isNumeric(converted) && converted.length <= length ? undefined : message({ length });
};

export const isMoneyNumber = checkNumberLength(9);
export const isMoneyNumber10 = checkNumberLength(10);
export const isEmployeeNumber5 = checkNumberLength(5);

export const isPostalCode = value => {
  if (!value) return undefined;

  if (!/^\d{3}-\d{3,4}$/.test(value)) {
    return Messages.ERROR_MSG_IS_POSTAL_CODE;
  }
  return undefined;
};

export const isHHMMFormat = value => {
  if (!value) return undefined;

  if (!/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)) {
    return Messages.ERROR_MSG_IS_HHMM;
  }
  return undefined;
};

export const isHourFormat = value => {
  if (!value) return undefined;

  if (!/^\d{1,3}:[0-5][0-9]$/.test(value)) {
    return Messages.ERROR_MSG_IS_HOUR;
  }
  return undefined;
};

export const isNumberOfDaysFormat = value => {
  if (!value) return undefined;

  if (!/^\d{1,3}\.\d{2,4}$/.test(value)) {
    return Messages.ERROR_MSG_IS_DATE;
  }
  return undefined;
};

export const isNumberOfTimesFormat = value => {
  if (!value) return undefined;

  if (!/^-?\d{1,9}(\.\d{0,2})?$/.test(value)) {
    return Messages.ERROR_MSG_IS_DATE;
  }
  return undefined;
};

export const isFloatFormat = value => {
  if (!value) return undefined;

  if (!/^\d{1,3}\.\d{2}$/.test(value)) {
    return Messages.ERROR_MSG_IS_DATE;
  }
  return undefined;
};

export const isInsuranceRateFormat = value => {
  if (!value) return undefined;

  if (!/^\d{1,3}\.\d{1,3}$/.test(value)) {
    return Messages.ERROR_MSG_IS_INSRUANCE_RATE;
  }
  return undefined;
};

export const isAccidentInsuranceRateFormat = value => {
  const val = toFloatString(value);
  if (!val) return undefined;
  if (!/^\d{0,3}(\.\d{0,4})?$/.test(val)) {
    return Messages.ERROR_MSG_IS_INSRUANCE_RATE;
  }
  return undefined;
};

// Format: YYYY/MM/DD or YYYY-MM-DD
export const isDateStringFormat = value => {
  if (!value) return undefined;

  if (!/^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(value)) {
    return Messages.ERROR_MSG_IS_DATE_STRING;
  }
  return undefined;
};

export const joinedOnLimit = value => {
  if (!value) return undefined;

  const joinedOn = new Date(value);
  const limitDate = new Date();
  limitDate.setFullYear(limitDate.getFullYear() + 30);
  if (joinedOn > limitDate) {
    const year = limitDate.getFullYear();
    const month = limitDate.getMonth() + 1;
    const date = limitDate.getDate();
    return `には${year}年${month}月${date}日以前の値を入力してください`;
  }
  return undefined;
};

export const validatePaymentDateRange = (value, allValues) => {
  if (!value) return undefined;
  const paymentDate = moment(value);
  const tenDaysAgo = moment(allValues.planPaymentDate).subtract(11, 'd');
  const tenDaysSince = moment(allValues.planPaymentDate).add(11, 'd');

  return paymentDate.isBetween(tenDaysAgo, tenDaysSince) ? undefined : 'は設定した支給日の前後10日間で変更してください';
};

export const maxLength = max => value => (value && value.length > max ? Messages.MAX_LENGTH(max) : undefined);

export const maxLength3 = maxLength(3);
export const maxLength4 = maxLength(4);
export const maxLength5 = maxLength(5);
export const maxLength6 = maxLength(6);
export const maxLength7 = maxLength(7);
export const maxLength8 = maxLength(8);
export const maxLength9 = maxLength(9);
export const maxLength10 = maxLength(10);
export const maxLength12 = maxLength(12);
export const maxLength15 = maxLength(15);
export const maxLength20 = maxLength(20);
export const maxLength30 = maxLength(30);
export const maxLength37 = maxLength(37);
export const maxLength50 = maxLength(50);
export const maxLength255 = maxLength(255);
export const maxLength1000 = maxLength(1000);

export const exactLength = length => value =>
  !value || value.length === length ? undefined : Messages.EXACT_LENGTH(length);
export const exactLength5 = exactLength(5);

export const minLength = min => value => (value && value.length < min ? Messages.MIN_LENGTH(min) : undefined);
export const minLength2 = minLength(2);
export const minLength6 = minLength(6);
export const minValue = min => value => (value && value < min ? Messages.MIN_VALUE(min) : undefined);
export const minValue0 = minValue(0);
export const minValue1 = minValue(1);
export const minValue18 = minValue(18);
export const isEmail = value =>
  value && !/^([^@\s]+)@((?:[a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,})$/i.test(value) ? Messages.INVALID_EMAIL : undefined;
export const isStaffCode = value => (value && /[^a-zA-Z0-9_-]/i.test(value) ? Messages.INVALID_STAFF_CODE : undefined);
export const alphaNumeric = value => (value && /[^a-zA-Z0-9 ]/i.test(value) ? Messages.ONLY_ALPHANUMERIC : undefined);
export const phoneNumber = value => (value && !/^0[0-9]{9,12}$/i.test(value) ? Messages.INVALID_PHONE : undefined);
export const digitFloatingNumber = value =>
  value && !/^[\d]{1,10}\.?[\d]{0,10}$/i.test(value) ? Messages.ONLY_DIGIT_FLOATING_NUMBER : undefined;
export const insuranceRate = value =>
  value && !/^[\d]{1,10}\.?[\d]{0,10}$/i.test(value) ? Messages.INVALID_INSURANCE_RATE : undefined;
export const zenkakuKatakana = value =>
  value && !value.match(/^[\u30A0-\u30FF\x20\u3000]+$/) ? Messages.ZENKAKU_KATAKANA : undefined;
export const zenkakuKatakanaLoose = value =>
  value && value.match(/[\u4E00-\u9FFF\u3005-\u3006\u3040-\u309f]/)
    ? 'を全角カタカナとスペースで入力してください'
    : undefined;
// 英数字、カタカナ(半角・全角)、括弧(半角・全角)のみ許可
// railsのバリデーションと合わせているため改修するときは、一緒に修正すること
// see: app/validators/bank_kana_validator.rb
export const bankKana = value =>
  value && !value.match('^[ｦ-ﾟァ-ヴ-ー－ 　()（）0-9a-zA-Z０-９ａ-ｚＡ-Ｚ.．/／]+$')
    ? Messages.ERROR_MSG_BANK_KANA
    : undefined;
// http://www.houjin-bangou.nta.go.jp/documents/checkdigit.pdf
const validCorporateNumber = value => {
  if (!value.match(/^\d{13}$/)) {
    return false;
  }
  const digits = _.map(value, digit => +digit);
  const realDigits = _(digits).tail();
  const oddSum = realDigits.filter((val, index) => index % 2 === 1).sum();
  const evenSum = realDigits.filter((val, index) => index % 2 === 0).sum();
  const checkDigit = 9 - (evenSum * 2 + oddSum) % 9;
  return digits[0] === checkDigit;
};

export const isCorporateNumber = value => (value && !validCorporateNumber(value) ? Messages.CORP_NUMBER : undefined);

// 住民税納付先：指定番号
// https://github.com/d-o-n-u-t-s/payrollservice/issues/5104
export const designationNumber = value =>
  value && !value.match(/^[a-zA-Z0-9ｦ-ﾝﾞﾟ\--]*$/) ? Messages.ERROR_MSG_DESIGNATION_NUMBER : undefined;

const OPEN_CHARS = ['(', '【'];
const CLOSE_CHARS = [')', '】'];
const OP_CHARS = ['+', '-', '*', '/'];
const NEED_CHECKING_CHARS = OPEN_CHARS.concat(CLOSE_CHARS).concat(OP_CHARS);
const PREV_CHARS = {
  ')': ['('],
  '】': ['【']
};
const isOpenChar = char => OPEN_CHARS.includes(char);
const isCloseChar = char => CLOSE_CHARS.includes(char);
const isOperationChar = char => OP_CHARS.includes(char);
const validOpenChar = (closeChar, char) => (PREV_CHARS[closeChar] || []).includes(char);
const isSpace = char => char === ' ';
const isNormalChar = char => !NEED_CHECKING_CHARS.includes(char);
const allNormalChars = chars => !chars.find(char => !isNormalChar(char));

const validFormula = value => {
  const chars = value.split('').filter(char => !isSpace(char));

  if (allNormalChars(chars)) return false;

  const openChars = [];
  let currentChar;
  let prevChar;

  for (let i = 0; i < chars.length; i += 1) {
    currentChar = chars[i];

    if (isOpenChar(currentChar)) {
      if (isCloseChar(prevChar)) return false;
      openChars.push(currentChar);
    } else if (isCloseChar(currentChar)) {
      const openChar = openChars.pop();
      if (!validOpenChar(currentChar, openChar)) return false;
    } else if (isOperationChar(currentChar)) {
      if (isOperationChar(prevChar)) return false;
    }

    prevChar = currentChar;
  }

  return openChars.length === 0;
};

export const isFormula = value => (value && !validFormula(value) ? Messages.INVALID_FORMULA : undefined);

export const itemsChecked = value =>
  Array.isArray(value) && value.length > 0 ? undefined : Messages.REQUIRED_TO_CHECK;
export const isYYYYMMDDFormat = value =>
  value && !/^\d{4}[/-]\d{1,2}[/-]\d{1,2}$/.test(value) ? Messages.INVALID_YYYYMMDD : undefined;

export const isBasicDaysFormat = value => {
  if (!value) return undefined;
  const converted = Number(value);
  if (!converted) return undefined;
  return converted >= 0 && converted <= 31 ? undefined : Messages.ERROR_MSG_IS_BASIC_DAYS;
};

export const selectedInviteRole = value =>
  ['admin', 'operator'].includes(value) ? undefined : Messages.ERROR_MSG_IS_SELECTED;

export const isRequiredNotNegativeMoneyNumber = value => {
  if (value === '' || (validator.isNumeric(String(value)) && value < 0))
    return Messages.ERROR_MSG_NOT_NEGATIVE_MONY_NUMBER;
  return isMoneyNumber(value);
};

export const maxLengthWithLabel = (max, label) => value => {
  if (value && value.length > max) return `${label}${Messages.MAX_LENGTH(max)}`;
  return undefined;
};

export const eGovSerialNumber = value => {
  if (value && value === '000') return Messages.EGOV_SERIAL_NUMBER_FORMAT;
  return value && !/^\d{3}$/.test(value) ? Messages.EGOV_SERIAL_NUMBER_FORMAT : undefined;
};

export const kanaOrAlphanumeric = value =>
  value && !/^[ァ-ヶ0-9a-zA-Z]*$/.test(value) ? 'をカタカナまたは半角英数で入力してください' : undefined;

export const isRequiredPensionInsuranceCode = fieldName => (value, values) => {
  const otherValue = values?.pensionInsurance?.[fieldName];
  return !value && otherValue ? 'を入力してください' : undefined;
};
