import React from 'react';
import { connect } from 'react-redux';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { setList, setExtras, getExtras, getMode } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME } from 'src/reducers/clients/allowedIps';
import useInitializeData from 'src/hooks/useInitializeData';
import AllowedIpListTable from './components/AllowedIpListTable';
import styles from './AllowedIpList.scss';

const AllowedIpList = props => {
  const { selectedTab, mode, dispatch } = props;
  const setSelectedTab = tab => {
    dispatch(setExtras(REDUCER_NAME, { selectedTab: tab }));
  };
  useInitializeData(() => {
    const { values } = props;
    dispatch(setList(REDUCER_NAME, values));
    dispatch(setExtras(REDUCER_NAME, { selectedTab: 'forAdmin' }));
  });

  const selected = tab => selectedTab === tab;
  const classFor = tab => (selected(tab) ? `${styles.tab} ${styles.tabActive}` : `${styles.tab}`);

  const switchSelectedTab = tab => {
    if (mode === 'SHOW') {
      setSelectedTab(tab);
    }
  };

  return (
    <div>
      <div className={styles.head}>
        <div className="l-wrap-xs">
          <h1 className="m-title-main">IPアドレス制限</h1>
          <p className="m-title-main-note">登録した IPアドレス以外からはアクセスできなくなります</p>

          <div className={styles.list}>
            <div
              className={classFor('forAdmin')}
              role="button"
              onClick={() => switchSelectedTab('forAdmin')}
              tabIndex={0}
            >
              管理者向け機能
            </div>
            <div
              className={classFor('forEmployee')}
              role="button"
              onClick={() => switchSelectedTab('forEmployee')}
              tabIndex={0}
            >
              従業員向け機能
            </div>
          </div>
        </div>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="IPアドレス制限" />
          </BreadCrumb>
        </div>
        <div>
          <AllowedIpListTable />
        </div>
      </div>
    </div>
  );
};

export default connect(state => ({
  selectedTab: getExtras(REDUCER_NAME, state).selectedTab,
  mode: getMode(REDUCER_NAME, state)
}))(AllowedIpList);
