import React from 'react';
import PropTypes from 'prop-types';

import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import CommonModal from 'jbc-front/components/CommonModal';

class GeneralConfirmationBtn extends React.Component {
  constructor(props) {
    super(props);

    this.setModalIsOpen = this.setModalIsOpen.bind(this);
    this.showConfirmBox = this.showConfirmBox.bind(this);
    this.hideConfirmBox = this.hideConfirmBox.bind(this);
    this.executeHandle = this.executeHandle.bind(this);

    this.state = {
      isOpen: false,
      submitting: false
    };
  }

  setModalIsOpen(isOpen) {
    this.setState({
      ...this.state,
      isOpen
    });
  }

  showConfirmBox() {
    this.setModalIsOpen(true);
  }

  hideConfirmBox() {
    this.setModalIsOpen(false);
  }

  executeHandle = (showingConfirmBox, handleEvent) => {
    if (showingConfirmBox) {
      this.showConfirmBox();
    } else {
      handleEvent();
    }
  };

  render() {
    const {
      showingConfirmBox,
      header,
      confirmText,
      handleEvent,
      widthAuto,
      text,
      disabled,
      Icon,
      children,
      as,
      ...rest
    } = this.props;
    const As = as || ActionButton;

    return (
      <React.Fragment>
        <As onClick={() => this.executeHandle(showingConfirmBox, handleEvent)} disabled={disabled} {...rest}>
          {children}
        </As>
        <CommonModal header={header} isOpen={this.state.isOpen} hideModal={this.hideConfirmBox} ariaHideApp={false}>
          <CommonModal.Header hideModal={this.hideConfirmBox}>{header}</CommonModal.Header>
          <CommonModal.Body>{confirmText}</CommonModal.Body>
          <CommonModal.Footer>
            <Button onClick={this.hideConfirmBox} className="u-mr20">
              キャンセル
            </Button>
            <Button
              onClick={() => {
                const ret = handleEvent();
                if (ret instanceof Promise) {
                  this.setState({ submitting: true });
                  ret.then(() => {
                    this.setState({ submitting: false });
                    this.hideConfirmBox();
                  });
                } else {
                  this.hideConfirmBox();
                }
              }}
              primary
            >
              {text}
            </Button>
          </CommonModal.Footer>
        </CommonModal>
      </React.Fragment>
    );
  }
}

GeneralConfirmationBtn.propTypes = {
  showingConfirmBox: PropTypes.bool,
  confirmText: PropTypes.string.isRequired,
  handleEvent: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

GeneralConfirmationBtn.defaultProps = {
  showingConfirmBox: true,
  disabled: false,
  header: '操作確認'
};

export default GeneralConfirmationBtn;
