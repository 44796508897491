import React from 'react';
import Loading from './Loading';

const LoadingWithDescription = ({ text }) => (
  <div>
    <Loading type="spin" color="#3498db" />
    <h4 style={{ textAlign: 'center', marginBottom: '5px' }}>{text}</h4>
  </div>
);

export default LoadingWithDescription;
