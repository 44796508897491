import React from 'react';
import ClientCsvEmployeeFormatFormContainer from './containers/ClientCsvEmployeeFormatFormContainer';

class ClientCsvEmployeeFormatNew extends React.Component {
  constructor(props) {
    super(props);
    props.init();
  }

  render() {
    return (
      <div className="l-wrap-xl l-contents-wrap">
        <div className="l-title-wrap">
          <h1 className="m-title-main">CSVフォーマット作成</h1>
        </div>
        <ClientCsvEmployeeFormatFormContainer isCreateForm />
      </div>
    );
  }
}

export default ClientCsvEmployeeFormatNew;
