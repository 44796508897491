import React from 'react';
import styles from './SecondaryPanel.scss';

export const Headding = ({ children, icon, size }) => (
  <div className={styles.heading}>
    <h2 className={styles.tittle}>{children}</h2>
    {icon && <icon size={size} className={styles.iconRight} />}
  </div>
);

export const SubHeadding = ({ children }) => <div className={styles.subHeading}>{children}</div>;

export const Item = ({ children }) => <div className={styles.item}>{children}</div>;

export const Panel = ({ children }) => <div className={styles.base}>{children}</div>;

export const Dl = ({ children }) => <dl className={styles.itemWrap}>{children}</dl>;
export const Dt = ({ children }) => <dt className={styles.itemName}>{children}</dt>;

export const Dd = ({ children }) => <dd className={styles.itemValue}>{children}</dd>;
