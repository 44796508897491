import React from 'react';
import _ from 'lodash';

import SearchDetector from 'src/components/SearchDetector';

const YearSelect = props => {
  const { year, formValues, location, searchReportWageLedger } = props;

  return (
    <SearchDetector
      location={location}
      year={year}
      willReceivePropsDetectingJudger={(prevProps, nextProps) =>
        !_.isEmpty(nextProps.year) && (_.isEmpty(prevProps.year) || nextProps.location !== prevProps.location)
      }
      onDetect={() => {
        searchReportWageLedger(formValues);
      }}
    />
  );
};

export default YearSelect;
