import axios from 'axios';
import { combineReducers } from 'redux';

import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';

import createNamedWrapperReducer from '../createNamedWrapperReducer';
import { itemReducer, listReducer } from '../common';
import {
  newItem,
  newItemFailure,
  editItem,
  editItemFailure,
  deleteItem,
  deleteItemFailure
} from '../common/itemReducer';

export const REDUCER_NAME = 'roles';

// Async Action Creators
export const newRole = data => async dispatch => {
  try {
    dispatch(newItem(REDUCER_NAME));
    const httpBody = {
      role: data
    };
    await axios.post(Urls.CLIENT_ROLES_CREATE, httpBody);
    redirectTo(Urls.CLIENT_ROLES_LIST);
  } catch (exception) {
    dispatch(newItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editRole = data => async dispatch => {
  try {
    dispatch(editItem(REDUCER_NAME));
    const httpBody = {
      role: data
    };
    await axios.put(concatParamsToUrl(Urls.CLIENT_ROLES_UPDATE, { id: data.id }), httpBody);
    redirectTo(Urls.CLIENT_ROLES_LIST);
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const delRole = roleId => async dispatch => {
  try {
    dispatch(deleteItem(REDUCER_NAME));
    await axios.delete(concatParamsToUrl(Urls.CLIENT_ROLES_DELETE, { id: roleId }));
    redirectTo(Urls.CLIENT_ROLES_LIST);
  } catch (exception) {
    dispatch(deleteItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME),
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME)
});
