import React from 'react';

import SameAccidentAndEmployment from './LabourInsuranceSections/SameAccidentAndEmployment';
import SameMainOffice from './LabourInsuranceSections/SameMainOffice';
import SeparateAccidentAndEmployment from './LabourInsuranceSections/SeparateAccidentAndEmployment';

const LabourInsuranceSection = ({ accidentInsurance, employmentInsurance }) => {
  if (accidentInsurance.useContactInfo === 'main_office') {
    return <SameMainOffice accidentInsurance={accidentInsurance} employmentInsurance={employmentInsurance} />;
  } else if (employmentInsurance.useContactInfo === 'labour_insurance_office') {
    return (
      <SameAccidentAndEmployment accidentInsurance={accidentInsurance} employmentInsurance={employmentInsurance} />
    );
  }
  return (
    <SeparateAccidentAndEmployment accidentInsurance={accidentInsurance} employmentInsurance={employmentInsurance} />
  );
};

export default LabourInsuranceSection;
