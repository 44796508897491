import { connect } from 'react-redux';

import { getSelectOptions } from 'src/reducers/selectOptions';
import CommutingByCarShow from '../components/CommutingByCarShow';

const mapStateToProps = state => ({
  commutingDistances: getSelectOptions(state, 'commutingDistances'),
  vehicleTypes: getSelectOptions(state, 'vehicleTypes'),
  paymentDurationUnits: getSelectOptions(state, 'paymentDurationUnits'),
  paymentDurationTypes: getSelectOptions(state, 'paymentDurationTypes')
});

export default connect(mapStateToProps)(CommutingByCarShow);
