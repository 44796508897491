import React from 'react';
import { blur, touch, FieldArray, FormSection } from 'redux-form';
import { connect } from 'react-redux';

import { LabelMapper } from 'jbc-front/components/FormErrors';
import { CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { TextField, RadioField } from 'jbc-front/components/Form';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import selector, { fetchValueByIndexAndField } from 'src/utils/Utils';
import { emptyIntegerFormat } from 'src/utils/Completion';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import _ from 'lodash';
import styles from './EmployeeUnitPricesSection.scss';

const EmployeeUnitPriceItem = ({
  formKey,
  itemInfo,
  employeeUnitPriceUseTypes,
  selectedEmployeeUnitPriceUseType,
  dispatch
}) => (
  <React.Fragment>
    <FormSection name={formKey}>
      <div>{itemInfo.clientUnitPriceName}</div>
      <div>
        <RadioField name="useType" options={employeeUnitPriceUseTypes} />
      </div>
      {selectedEmployeeUnitPriceUseType === 'fixed' && (
        <div className={styles.yenfield}>
          <TextField
            name="amount"
            yen
            validate={isMoneyNumber}
            onBlur={(e, newValue, previousValue) => {
              e.preventDefault();
              dispatch(blur(EMPLOYEE_FORM, `${formKey}.amount`, emptyIntegerFormat(previousValue)));
              dispatch(touch(EMPLOYEE_FORM, `${formKey}.amount`));
            }}
          />
          <LabelMapper name="amount" label="固定額は" />
        </div>
      )}
    </FormSection>
  </React.Fragment>
);

let EmployeeUnitPrices = ({
  fields,
  selectedPayrollGroupId,
  employeeUnitPriceUseTypes,
  fetchValueByIndex,
  dispatch
}) => (
  <div className={styles.wrap}>
    {fields.map((item, index) => {
      const employeeUnitPrice = fields.get(index);
      if (employeeUnitPrice.clientPayrollRulesGroupId === selectedPayrollGroupId) {
        return (
          <EmployeeUnitPriceItem
            formKey={item}
            key={item}
            index={index}
            itemInfo={fields.get(index)}
            employeeUnitPriceUseTypes={employeeUnitPriceUseTypes}
            selectedEmployeeUnitPriceUseType={fetchValueByIndex('useType')(index)}
            dispatch={dispatch}
          />
        );
      }
      return undefined;
    })}
    {_.filter(fields.getAll(), { clientPayrollRulesGroupId: selectedPayrollGroupId }).length === 0 && (
      <p>
        設定可能な項目が存在しません。<a href={CLIENT_PAYROLL_RULES_GROUP_LIST} className="u-txt-link">
          給与規定
        </a>の単価設定で単価を登録すると表示されます。
      </p>
    )}
  </div>
);

EmployeeUnitPrices = connect(state => ({
  selectedPayrollGroupId: selector(getEmployeeFormValues(state), 'employeePayrollRule', 'clientPayrollRulesGroupId'),
  employeeUnitPriceUseTypes: getSelectOptions(state, 'employeeUnitPriceUseTypes'),
  fetchValueByIndex: fetchValueByIndexAndField(selector(getEmployeeFormValues(state), 'employeeUnitPrices'))
}))(EmployeeUnitPrices);

const EmployeeUnitPricesSection = () => (
  <div>
    <h4>単価設定</h4>
    <FieldArray name="employeeUnitPrices" component={EmployeeUnitPrices} />
  </div>
);

export default EmployeeUnitPricesSection;
