import React from 'react';
import { connect } from 'react-redux';

import { getList } from 'src/reducers/common/listReducer';
import { REDUCER_NAME, delPayrollRulesGroup } from 'src/reducers/clients/payrollRulesGroups';

import PayrollRulesGroupTableRow from '../components/PayrollRulesGroupTableRow';
import SettingPaymentDate from '../components/SettingPaymentDate';

const PayrollRulesGroupTable = ({ payrollRulesGroups, onDelete }) => (
  <table className="m-table-list">
    <thead>
      <tr>
        <th>給与規定グループ名</th>
        <th>
          締め日 / 支給日
          {payrollRulesGroups &&
            payrollRulesGroups.length > 0 && <SettingPaymentDate payrollRulesGroups={payrollRulesGroups} />}
        </th>
        <th>支給・控除設定</th>
        <th />
      </tr>
    </thead>
    <tbody>
      {(!payrollRulesGroups || payrollRulesGroups.length === 0) && (
        <tr>
          <td colSpan={4}>データがありません。</td>
        </tr>
      )}
      {payrollRulesGroups.map(payrollRulesGroup => (
        <PayrollRulesGroupTableRow key={payrollRulesGroup.id} {...payrollRulesGroup} onDelete={onDelete} />
      ))}
    </tbody>
  </table>
);

const mapStateToProps = state => ({
  payrollRulesGroups: getList(REDUCER_NAME, state)
});

const mapDispatchToProps = dispatch => ({
  onDelete: payrollRulesGroupId => {
    dispatch(delPayrollRulesGroup(payrollRulesGroupId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PayrollRulesGroupTable);
