import React from 'react';

import Button from 'jbc-front/components/Button';
import { redirectTo } from 'src/utils/Http';
import { FLAT_TAX_REDUCTION_UPLOAD_CSV } from 'src/constants/EndpointUrls';
import { EMPLOYEE_MODE } from 'src/reducers/flat_tax_reductions/csv';

const UploadFlatTaxReductionCsvButton = ({ text = '', mode = EMPLOYEE_MODE }) => {
  const url = `${FLAT_TAX_REDUCTION_UPLOAD_CSV}?mode=${mode}`;
  return (
    <Button
      as={'button'}
      radius
      disabled={false}
      onClick={() => {
        redirectTo(url);
      }}
    >
      {text}
    </Button>
  );
};

export { UploadFlatTaxReductionCsvButton };
// ↓ sprint132 以降に消す
export default UploadFlatTaxReductionCsvButton;
