import React from 'react';
import onClickOutside from 'react-onclickoutside';
import styles from './TargetEmployeeCoordinationMethod.scss';
import ChangedContext from '../ChangedContext';

const TargetEmployeeCoordinationMethod = props => {
  TargetEmployeeCoordinationMethod.handleClickOutside = () => props.hide();
  return (
    <div className={styles.baloonWrap}>
      <div className={styles.baloon}>
        <ChangedContext.Consumer>
          {({ changeAll }) => (
            <ul className={styles.links}>
              <li>
                <a
                  onClick={() => {
                    changeAll(true);
                    props.hide();
                  }}
                  role="presentation"
                >
                  全ページの従業員を選択
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    changeAll(false);
                    props.hide();
                  }}
                  role="presentation"
                >
                  全ページの従業員を解除
                </a>
              </li>
            </ul>
          )}
        </ChangedContext.Consumer>
      </div>
    </div>
  );
};

const clickOutsideConfig = {
  handleClickOutside: () => TargetEmployeeCoordinationMethod.handleClickOutside
};

export default onClickOutside(TargetEmployeeCoordinationMethod, clickOutsideConfig);
