import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_PAYROLL_RULES_GROUP_NEW, CLIENT_SETTINGS, CLIENT_ALLOWANCE_ITEMS } from 'src/constants/EndpointUrls';
import { REDUCER_NAME } from 'src/reducers/clients/payrollRulesGroups';
import { setList } from 'src/reducers/common/listReducer';
import { LinkBtn } from 'src/buttons';
import { AddFile } from 'jbc-front/components/icons';

import PayrollRulesGroupTable from './containers/PayrollRulesGroupTable';
import styles from './PayrollRulesGroupsList.scss';

const PayrollRulesGroupsList = ({ clientPayrollRulesGroups, dispatch }) => {
  dispatch(setList(REDUCER_NAME, { data: clientPayrollRulesGroups, pageCount: 1 }));

  return (
    <div>
      <div className="l-main-title-wrap">
        <h2 className="m-title-main">給与規定グループ一覧</h2>
      </div>
      <div className="l-wrap-s l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="給与規定グループ一覧" />
          </BreadCrumb>
        </div>
        <div>
          <div className={styles.description}>
            <p>以下の項目が設定可能です。</p>
            <div className={styles.itemsWrap}>
              <ul className={styles.items}>
                <li>・締め日</li>
                <li>・支給日</li>
                <li>・1日の所定労働時間</li>
                <li>・1ヶ月平均の所定労働日数</li>
                <li>・1ヶ月平均の所定労働時間</li>
                <li>・給与明細に使用する月</li>
                <li>・給与形態（月給/日給/時給）</li>
                <li>・支給項目</li>
                <li>・控除項目</li>
                <li>・単価設定</li>
              </ul>
              <ul className={styles.notes}>
                <li>
                  ※支給項目・控除項目では、<a href={CLIENT_ALLOWANCE_ITEMS} className="u-txt-link">
                    勤怠項目設定
                  </a>で設定した勤怠項目を使って計算式を作成できます。
                </li>
              </ul>
            </div>
            <p>
              社員ごとに異なる場合は複数の給与規定グループを作成する事もできます。<br />
              詳しい設定手順に関しては
              <a
                href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360002075234"
                rel="noopener noreferrer"
                target="_blank"
                className="u-txt-link"
              >
                ヘルプ
              </a>をご覧ください。
            </p>
          </div>
          <div className={styles.buttons}>
            <div className={styles.create}>
              <LinkBtn text="新規作成" href={CLIENT_PAYROLL_RULES_GROUP_NEW} icon={<AddFile size={28} />} />
            </div>
          </div>
          <div className="l-overflow-scroll u-mt10">
            <PayrollRulesGroupTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect()(PayrollRulesGroupsList);
