import React, { Component } from 'react';
import ActionButton from 'jbc-front/components/ActionButton';

import {
  CLIENT_EMPLOYMENT_TYPES,
  CLIENT_GROUPS,
  CLIENT_POSITIONS,
  CLIENT_OCCUPATIONS,
  CLIENT_PAYROLL_RULES_GROUP_LIST,
  LIST_OFFICES_URL
} from 'src/constants/EndpointUrls';
import styles from '../styles/FileImport.scss';

class ShowMore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMoreOpen: false
    };
  }

  handleClickOpen = () => {
    this.setState({
      isMoreOpen: true
    });
  };

  handleClickClose = () => {
    this.setState({
      isMoreOpen: false
    });
  };

  render() {
    const { children } = this.props;

    return (
      <React.Fragment>
        {this.state.isMoreOpen ? (
          <React.Fragment>
            {children}
            <div className={styles.more} onClick={this.handleClickClose} role="presentation">
              隠す
            </div>
          </React.Fragment>
        ) : (
          <div className={styles.more} onClick={this.handleClickOpen} role="presentation">
            もっと見る
          </div>
        )}
      </React.Fragment>
    );
  }
}

const FileImportDescription = props => {
  const { update } = props;

  return (
    <div className="l-wrap-xl">
      <div className="l-overflow-scroll">
        <table className="m-table-description">
          <thead>
            <tr>
              <th className="m-table-description-item-name">データ項目名</th>
              <th>説明文</th>
              <th className="m-table-description-example">例</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>スタッフコード{update && <span className={styles.required}>必須</span>}</th>
              <td>
                {update
                  ? 'スタッフコードを基にデータが取り込まれるため、正確に入力してください。'
                  : '半角英数字50桁以内'}
              </td>
              <td>111</td>
            </tr>
            <tr>
              <th>給与規定グループ</th>
              <td>
                <ActionButton as="a" href={CLIENT_PAYROLL_RULES_GROUP_LIST}>
                  給与規定グループの確認
                </ActionButton>
                <p className="u-mt10">給与規定グループに入っている以下の内容は従業員情報からはインポートできません。</p>
                <ul>
                  <li>・給与形態</li>
                  <li>・1日の所定労働時間</li>
                  <li>・1ヶ月の平均所定労働日数</li>
                  <li>・締め日</li>
                  <li>・支給日</li>
                </ul>
              </td>
              <td>正社員用</td>
            </tr>
            <tr>
              <th>
                入社日<span className={styles.required}>必須</span>
              </th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2010/4/1</td>
            </tr>
            <tr>
              <th>
                姓<span className={styles.required}>必須</span>
              </th>
              <td>-</td>
              <td>山田</td>
            </tr>
            <tr>
              <th>
                名<span className={styles.required}>必須</span>
              </th>
              <td>-</td>
              <td>太郎</td>
            </tr>
            <tr>
              <th>姓 カナ</th>
              <td>全角カタカナ</td>
              <td>ヤマダ</td>
            </tr>
            <tr>
              <th>名 カナ</th>
              <td>全角カタカナ</td>
              <td>タロウ</td>
            </tr>
            <tr>
              <th>ビジネスネーム 姓</th>
              <td>
                この項目に入力してCSVファイルをアップロードした場合、ご利用されている
                他サービスの姓にビジネスネーム姓が自動反映されます。
                <br />
                （但しジョブカン労務HR上でビジネスネームが設定済みの従業員に関しては、
                ビジネスネーム姓に対して自動反映されます）
              </td>
              <td>佐々木</td>
            </tr>
            <tr>
              <th>ビジネスネーム 名</th>
              <td>
                この項目に入力してCSVファイルをアップロードした場合、ご利用されている
                他サービスの姓にビジネスネーム名が自動反映されます。
                <br />
                （但しジョブカン労務HR上でビジネスネームが設定済みの従業員に関しては、
                ビジネスネーム名に対して自動反映されます）
              </td>
              <td>太郎</td>
            </tr>
            <tr>
              <th>生年月日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>1980/1/1</td>
            </tr>
            <tr>
              <th>性別</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・男性</li>
                  <li>・女性</li>
                </ul>
              </td>
              <td>男性</td>
            </tr>
            <tr>
              <th>メールアドレス</th>
              <td>メールアドレスを変更する場合、マイページログイン用アカウントも変更されますのでご注意ください</td>
              <td>yamada@jobcan.ne.jp</td>
            </tr>
            <tr>
              <th>郵便番号</th>
              <td>半角数字7桁（XXX-XXXX）</td>
              <td>999-9999</td>
            </tr>
            <tr>
              <th>住所 都道府県</th>
              <td>-</td>
              <td>東京都</td>
            </tr>
            <tr>
              <th>住所 市区町村</th>
              <td>-</td>
              <td>渋谷区</td>
            </tr>
            <tr>
              <th>住所 丁目番地</th>
              <td>-</td>
              <td>9-99-99</td>
            </tr>
            <tr>
              <th>住所 建物名・部屋番号</th>
              <td>-</td>
              <td>ジョブカンアパート</td>
            </tr>
            <tr>
              <th>住所 カナ</th>
              <td>全角カタカナ</td>
              <td>トウキョウトシブヤク</td>
            </tr>
            <tr>
              <th>電話番号</th>
              <td>半角数字12桁以内（XXXX-XXXX-XXXX）</td>
              <td>0000-0000-0000</td>
            </tr>
            <tr>
              <th>世帯主の続柄</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・本人</li>
                  <li>・夫</li>
                  <li>・妻</li>
                </ul>
                <ShowMore>
                  <ul>
                    <li>・実父</li>
                    <li>・実母</li>
                    <li>・義父</li>
                    <li>・義母</li>
                    <li>・長男</li>
                    <li>・長女</li>
                    <li>・兄</li>
                    <li>・姉</li>
                    <li>・弟</li>
                    <li>・妹</li>
                    <li>・祖父</li>
                    <li>・祖母</li>
                    <li>・叔父</li>
                    <li>・叔母</li>
                  </ul>
                </ShowMore>
              </td>
              <td>本人</td>
            </tr>
            <tr>
              <th>世帯主の氏名</th>
              <td>-</td>
              <td>山田 太郎</td>
            </tr>
            <tr>
              <th>住民票 郵便番号</th>
              <td>半角数字7桁（XXX-XXXX）</td>
              <td>999-9999</td>
            </tr>
            <tr>
              <th>住民票 都道府県</th>
              <td>-</td>
              <td>東京都</td>
            </tr>
            <tr>
              <th>住民票 市区町村</th>
              <td>-</td>
              <td>渋谷区</td>
            </tr>
            <tr>
              <th>住民票 丁目番地</th>
              <td>-</td>
              <td>9-99-99</td>
            </tr>
            <tr>
              <th>住民票 建物名・部屋番号</th>
              <td>-</td>
              <td>ジョブカンアパート</td>
            </tr>
            <tr>
              <th>住民票 カナ</th>
              <td>全角カタカナ</td>
              <td>トウキョウトシブヤク</td>
            </tr>
            <tr>
              <th>事業所管理名</th>
              <td>
                <ActionButton as="a" href={LIST_OFFICES_URL}>
                  事業所管理名の確認
                </ActionButton>
              </td>
              <td>本社</td>
            </tr>
            <tr>
              <th>事業所コード</th>
              <td>
                <ActionButton as="a" href={LIST_OFFICES_URL}>
                  事業所コードの確認
                </ActionButton>
              </td>
              <td>main</td>
            </tr>
            <tr>
              <th>雇用形態</th>
              <td>
                <ActionButton as="a" href={CLIENT_EMPLOYMENT_TYPES}>
                  雇用形態の確認
                </ActionButton>
              </td>
              <td>正社員</td>
            </tr>
            <tr>
              <th>役職</th>
              <td>
                <ActionButton as="a" href={CLIENT_POSITIONS}>
                  役職の確認
                </ActionButton>
              </td>
              <td>部長</td>
            </tr>
            <tr>
              <th>職種</th>
              <td>
                <ActionButton as="a" href={CLIENT_OCCUPATIONS}>
                  職種の確認
                </ActionButton>
              </td>
              <td>営業</td>
            </tr>
            <tr>
              <th>グループ</th>
              <td>
                グループは5階層まで設定できます
                <ActionButton as="a" href={CLIENT_GROUPS} className="u-mt10">
                  グループの確認
                </ActionButton>
              </td>
              <td>
                営業部-{'>'}第一グループ-{'>'}第一チーム
              </td>
            </tr>
            <tr>
              <th>異動日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2011/4/1</td>
            </tr>
            <tr>
              <th>退職日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2018/3/31</td>
            </tr>
            <tr>
              <th>退職区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・一般</li>
                  <li>・障害</li>
                  <li>・死亡</li>
                </ul>
              </td>
              <td>一般</td>
            </tr>
            <tr>
              <th>休職開始日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2015/12/1</td>
            </tr>
            <tr>
              <th>休職終了日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2016/3/31</td>
            </tr>
            <tr>
              <th>休職理由</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・私傷病休職</li>
                  <li>・産前産後休業</li>
                  <li>・育児休業</li>
                  <li>・出生時育児休業</li>
                  <li>・介護休業</li>
                  <li>・その他</li>
                </ul>
              </td>
              <td>介護休業</td>
            </tr>
            <tr>
              <th>固定的賃金を0とする</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・0とする</li>
                  <li>・0としない</li>
                </ul>
                空欄の場合は、「0としない」を選択したとみなします
              </td>
              <td>0としない</td>
            </tr>
            <tr>
              <th>基本給</th>
              <td>
                半角数字9桁以内
                <br />
                ※給与規定グループが未指定の場合は取り込まれません。
              </td>
              <td>100000</td>
            </tr>
            <tr>
              <th>今年の年間収入見積額</th>
              <td>
                半角数字9桁以内
                <br />
                ※給与規定グループが未指定の場合は取り込まれません。
              </td>
              <td>3000000</td>
            </tr>
            <tr>
              <th>今年の年間所得見積額</th>
              <td>
                半角数字9桁以内
                <br />
                源泉控除配偶者の計算に使用します。
                <br />
                ※給与規定グループが未指定の場合は取り込まれません。
              </td>
              <td>1920000</td>
            </tr>
            <tr>
              <th>給与規定グループで設定した支給・控除項目</th>
              <td>
                半角数字9桁以内
                <br />
                給与規定の「支給・控除項目設定＞金額設定」で「従業員一覧で金額を設定」とした支給項目・控除項目の値を登録できます。
                <br />
                ※給与規定グループが未指定の場合は取り込まれません。
              </td>
              <td>20000</td>
            </tr>
            <tr>
              <th>給与規定グループで設定した単価</th>
              <td>
                半角数字9桁以内
                <br />
                「割増基礎単価」「欠勤控除単価」などの単価は通常給与規定の設定に基いて計算されますが、単価の金額を従業員別に指定したい場合はこちらで金額を入力します。
                <br />
                単価の金額を登録した従業員は、給与規定による自動計算が無効になります。
                <br />
                通常通り給与規定の設定に基いて計算する場合は「給与規定に従う」と入力して下さい。
                <br />
                ※給与規定グループが未指定の場合は取り込まれません。
              </td>
              <td>
                ・1500
                <br />
                ・給与規定に従う
              </td>
            </tr>
            <tr>
              <th>標準報酬月額適用月</th>
              <td>
                yyyy年mm月
                <br />
                当月徴収に設定されていない場合は、翌月に支給される給与から適用されます。
                <br />
                また、「標準報酬月額（健康保険）」「標準報酬月額（厚生年金保険）を更新する場合はこちらの入力が必須になり、入力した月以降のすべての標準報酬月額が上書きされます。
              </td>
              <td>2017年9月</td>
            </tr>
            <tr>
              <th>標準報酬月額（健康保険）</th>
              <td>半角数字</td>
              <td>98000</td>
            </tr>
            <tr>
              <th>標準報酬月額（厚生年金保険）</th>
              <td>半角数字</td>
              <td>98000</td>
            </tr>
            <tr>
              <th>
                健康保険料（従業員負担額）
                <br />
                健康保険料（事業主負担額）
                <br />
                介護保険料（従業員負担額）
                <br />
                介護保険料（事業主負担額）
                <br />
                厚生年金保険料（従業員負担額）
                <br />
                厚生年金保険料（事業主負担額）
              </th>
              <td>
                半角数字9桁以内
                <br />
                健康保険料、介護保険料、厚生年金保険料を固定金額に設定する場合に使用します。
                <br />
                従業員負担、会社負担共に列が存在しないか空欄の場合は「標準報酬月額に従って自動計算」に設定されます。
              </td>
              <td>
                10000
                <br />
                10000
                <br />
                0
                <br />
                0
                <br />
                20000
                <br />
                20000
              </td>
            </tr>
            <tr>
              <th>通勤手段</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・電車</li>
                  <li>・バス</li>
                  <li>・車・二輪車等</li>
                  <li>・徒歩</li>
                </ul>
                <ShowMore>
                  <ul>
                    <li>・電車＋バス</li>
                    <li>・電車＋車・二輪車等</li>
                  </ul>
                </ShowMore>
              </td>
              <td>電車</td>
            </tr>
            <tr>
              <th>電車1 乗駅</th>
              <td>-</td>
              <td>渋谷</td>
            </tr>
            <tr>
              <th>電車1 降駅</th>
              <td>-</td>
              <td>新宿</td>
            </tr>
            <tr>
              <th>電車1 経由地</th>
              <td>-</td>
              <td>代々木</td>
            </tr>
            <tr>
              <th>電車1 支給単位</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・1ヶ月</li>
                  <li>・3ヶ月</li>
                  <li>・6ヶ月</li>
                  <li>・出勤日数に応じて毎月支給</li>
                </ul>
              </td>
              <td>1ヶ月</td>
            </tr>
            <tr>
              <th>電車1 支給月</th>
              <td>
                「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                <ul>
                  <li>【3ヶ月】</li>
                  <li>・1月/4月/7月/10月</li>
                  <li>・2月/5月/8月/11月</li>
                  <li>・3月/6月/9月/12月</li>
                  <li>【6ヶ月の場合】</li>
                  <li>・1月/7月</li>
                  <li>・2月/8月</li>
                  <li>・3月/9月</li>
                  <li>・4月/10月</li>
                  <li>・5月/11月</li>
                  <li>・6月/12月</li>
                </ul>
                支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
              </td>
              <td />
            </tr>
            <tr>
              <th>電車1 片道運賃</th>
              <td>半角数字9桁以内</td>
              <td>140</td>
            </tr>
            <tr>
              <th>電車1 定期代</th>
              <td>半角数字9桁以内</td>
              <td>3880</td>
            </tr>
            <tr>
              <th>電車2 乗駅</th>
              <td>-</td>
              <td>渋谷</td>
            </tr>
            <tr>
              <th>電車2 降駅</th>
              <td>-</td>
              <td>新宿</td>
            </tr>
            <tr>
              <th>電車2 経由地</th>
              <td>-</td>
              <td>代々木</td>
            </tr>
            <tr>
              <th>電車2 支給単位</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・1ヶ月</li>
                  <li>・3ヶ月</li>
                  <li>・6ヶ月</li>
                  <li>・出勤日数に応じて毎月支給</li>
                </ul>
              </td>
              <td>1ヶ月</td>
            </tr>
            <tr>
              <th>電車2 支給月</th>
              <td>
                「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                <ul>
                  <li>【3ヶ月】</li>
                  <li>・1月/4月/7月/10月</li>
                  <li>・2月/5月/8月/11月</li>
                  <li>・3月/6月/9月/12月</li>
                  <li>【6ヶ月の場合】</li>
                  <li>・1月/7月</li>
                  <li>・2月/8月</li>
                  <li>・3月/9月</li>
                  <li>・4月/10月</li>
                  <li>・5月/11月</li>
                  <li>・6月/12月</li>
                </ul>
                支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
              </td>
              <td />
            </tr>
            <tr>
              <th>電車2 片道運賃</th>
              <td>半角数字9桁以内</td>
              <td>140</td>
            </tr>
            <tr>
              <th>電車2 定期代</th>
              <td>半角数字9桁以内</td>
              <td>3880</td>
            </tr>
            <tr>
              <th>バス1 乗車停留所</th>
              <td>-</td>
              <td>蔵前二丁目</td>
            </tr>
            <tr>
              <th>バス1 降車停留所</th>
              <td>-</td>
              <td>東神田</td>
            </tr>
            <tr>
              <th>バス1 経由地</th>
              <td>-</td>
              <td>浅草橋</td>
            </tr>
            <tr>
              <th>バス1 支給単位</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・1ヶ月</li>
                  <li>・3ヶ月</li>
                  <li>・6ヶ月</li>
                  <li>・出勤日数に応じて毎月支給</li>
                </ul>
              </td>
              <td>1ヶ月</td>
            </tr>
            <tr>
              <th>バス1 支給月</th>
              <td>
                「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                <ul>
                  <li>【3ヶ月】</li>
                  <li>・1月/4月/7月/10月</li>
                  <li>・2月/5月/8月/11月</li>
                  <li>・3月/6月/9月/12月</li>
                  <li>【6ヶ月の場合】</li>
                  <li>・1月/7月</li>
                  <li>・2月/8月</li>
                  <li>・3月/9月</li>
                  <li>・4月/10月</li>
                  <li>・5月/11月</li>
                  <li>・6月/12月</li>
                </ul>
                支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
              </td>
              <td />
            </tr>
            <tr>
              <th>バス1 片道運賃</th>
              <td>半角数字9桁以内</td>
              <td>210</td>
            </tr>
            <tr>
              <th>バス1 定期代</th>
              <td>半角数字9桁以内</td>
              <td>10000</td>
            </tr>
            <tr>
              <th>バス2 乗車停留所</th>
              <td>-</td>
              <td>蔵前二丁目</td>
            </tr>
            <tr>
              <th>バス2 降車停留所</th>
              <td>-</td>
              <td>東神田</td>
            </tr>
            <tr>
              <th>バス2 経由地</th>
              <td>-</td>
              <td>浅草橋</td>
            </tr>
            <tr>
              <th>バス2 支給単位</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・1ヶ月</li>
                  <li>・3ヶ月</li>
                  <li>・6ヶ月</li>
                  <li>・出勤日数に応じて毎月支給</li>
                </ul>
              </td>
              <td>1ヶ月</td>
            </tr>
            <tr>
              <th>バス2 支給月</th>
              <td>
                「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                <ul>
                  <li>【3ヶ月】</li>
                  <li>・1月/4月/7月/10月</li>
                  <li>・2月/5月/8月/11月</li>
                  <li>・3月/6月/9月/12月</li>
                  <li>【6ヶ月の場合】</li>
                  <li>・1月/7月</li>
                  <li>・2月/8月</li>
                  <li>・3月/9月</li>
                  <li>・4月/10月</li>
                  <li>・5月/11月</li>
                  <li>・6月/12月</li>
                </ul>
                支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
              </td>
              <td />
            </tr>
            <tr>
              <th>バス2 片道運賃</th>
              <td>半角数字9桁以内</td>
              <td>210</td>
            </tr>
            <tr>
              <th>バス2 定期代</th>
              <td>半角数字9桁以内</td>
              <td>10000</td>
            </tr>
            <tr>
              <th>車・二輪車等 車両種別</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・車</li>
                  <li>・バイク</li>
                  <li>・原付</li>
                  <li>・自転車</li>
                </ul>
              </td>
              <td>車</td>
            </tr>
            <tr>
              <th>車・二輪車等 出発地</th>
              <td>-</td>
              <td>自宅</td>
            </tr>
            <tr>
              <th>車・二輪車等 到着地</th>
              <td>-</td>
              <td>本社</td>
            </tr>
            <tr>
              <th>車・二輪車等 片道の通勤距離</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・2km未満</li>
                  <li>・2km～10km</li>
                  <li>・10km～15km</li>
                </ul>
                <ShowMore>
                  <ul>
                    <li>・15km～25km</li>
                    <li>・25km～35km</li>
                    <li>・35km～45km</li>
                    <li>・45km～55km</li>
                    <li>・55km以上</li>
                  </ul>
                </ShowMore>
              </td>
              <td>2km～10km</td>
            </tr>
            <tr>
              <th>車・二輪車等 支給単位</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・1ヶ月</li>
                  <li>・3ヶ月</li>
                  <li>・6ヶ月</li>
                  <li>・出勤日数に応じて毎月支給</li>
                </ul>
              </td>
              <td>1ヶ月</td>
            </tr>
            <tr>
              <th>車・二輪車等 支給月</th>
              <td>
                「支給単位」が「3ヶ月」「6ヶ月」の場合のみ、支給したい月を以下のように入力してください。
                <ul>
                  <li>【3ヶ月】</li>
                  <li>・1月/4月/7月/10月</li>
                  <li>・2月/5月/8月/11月</li>
                  <li>・3月/6月/9月/12月</li>
                  <li>【6ヶ月の場合】</li>
                  <li>・1月/7月</li>
                  <li>・2月/8月</li>
                  <li>・3月/9月</li>
                  <li>・4月/10月</li>
                  <li>・5月/11月</li>
                  <li>・6月/12月</li>
                </ul>
                支給単位が「1ヶ月」「出勤日数に応じて毎月支給」の場合、空欄のままでお願いします。
              </td>
              <td />
            </tr>
            <tr>
              <th>車・二輪車等 支給額</th>
              <td>半角数字9桁以内</td>
              <td>30000</td>
            </tr>
            <tr>
              <th>口座情報1～6 銀行名</th>
              <td>
                空欄の場合、銀行コードに従って自動で入力されます
                <br />
                ※「口座情報4」以降を登録する場合は列を追加してください。以下項目も同様です。
              </td>
              <td>ジョブカン銀行</td>
            </tr>
            <tr>
              <th>口座情報1～6 銀行コード</th>
              <td>半角数字4桁以内</td>
              <td>1111</td>
            </tr>
            <tr>
              <th>口座情報1～6 支店名</th>
              <td>空欄の場合、支店コードに従って自動で入力されます</td>
              <td>ジョブカン支店</td>
            </tr>
            <tr>
              <th>口座情報1～6 支店コード</th>
              <td>半角数字3桁以内</td>
              <td>111</td>
            </tr>
            <tr>
              <th>口座情報1～6 預金種別</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・普通</li>
                  <li>・当座</li>
                </ul>
              </td>
              <td>普通</td>
            </tr>
            <tr>
              <th>口座情報1～6 口座番号</th>
              <td>半角数字7桁以内</td>
              <td>1111111</td>
            </tr>
            <tr>
              <th>口座情報1～6 口座名義 カナ</th>
              <td>カナ30文字以内</td>
              <td>ヤマダ　タロウ</td>
            </tr>
            <tr>
              <th>支給設定（給与）銀行振込1～6 金額</th>
              <td>
                下記より一つ選択ください
                <br />
                ・全額
                <br />
                ・残額
                <br />
                ・任意の半角数字（固定額）
                <br />
                <br />
                「固定額」として設定する場合は半角数字のみ入力して下さい。また、「固定額」を設定する場合は、必ず別の振込先・現金を「残額」として設定して下さい。
                <br />
                <br />
                例）口座情報1に「100,000円」口座情報2に残り全てを支給するように設定する場合
                <br />
                ・支給設定（給与）銀行振込1 金額：100000
                <br />
                ・支給設定（給与）銀行振込1 振込先：口座情報1
                <br />
                ・支給設定（給与）銀行振込1 振込元：口座情報1
                <br />
                ・支給設定（給与）銀行振込2 金額：残額
                <br />
                ・支給設定（給与）銀行振込2 振込先：口座情報2
                <br />
                ・支給設定（給与）銀行振込2 振込元：口座情報1
                <br />
                <br />
                ※「支給設定（給与）銀行振込4」以降を登録する場合は列を追加してください。以下項目も同様です。
              </td>
              <td>全額</td>
            </tr>
            <tr>
              <th>支給設定（給与）銀行振込1～6 振込先</th>
              <td>
                登録口座より一つ選択ください
                <br />
                ・口座情報1～6
              </td>
              <td>口座情報1</td>
            </tr>
            <tr>
              <th>支給設定（給与）銀行振込1～3 振込元</th>
              <td>
                登録口座より一つ選択ください
                <br />
                ・口座情報1～3
              </td>
              <td>口座情報1</td>
            </tr>
            <tr>
              <th>支給設定（給与）現金 金額</th>
              <td>
                下記より一つ選択ください
                <br />
                ・全額
                <br />
                ・残額
                <br />
                ・任意の半角数字（固定額）
                <br />
              </td>
              <td />
            </tr>
            <tr>
              <th>支給設定（賞与）銀行振込1～6 金額</th>
              <td>
                下記より一つ選択ください
                <br />
                ・全額
                <br />
                ・残額
                <br />
                ・任意の半角数字（固定額）
                <br />
                <br />
                ※「支給設定（賞与）銀行振込4」以降を登録する場合は列を追加してください。以下項目も同様です。
              </td>
              <td>残額</td>
            </tr>
            <tr>
              <th>支給設定（賞与）銀行振込1～6 振込先</th>
              <td>
                登録口座より一つ選択ください
                <br />
                ・口座情報1～6
              </td>
              <td>口座情報1</td>
            </tr>
            <tr>
              <th>支給設定（賞与）銀行振込1～3 振込元</th>
              <td>
                登録口座より一つ選択ください
                <br />
                ・口座情報1～3
              </td>
              <td>口座情報1</td>
            </tr>
            <tr>
              <th>支給設定（賞与）現金 金額</th>
              <td>
                下記より一つ選択ください
                <br />
                ・全額
                <br />
                ・残額
                <br />
                ・任意の半角数字（固定額）
                <br />
              </td>
              <td />
            </tr>
            <tr>
              <th>本人 非居住者区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象外</li>
                  <li>・非居住者</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>本人 所得税計算</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・税率20.42%で計算</li>
                  <li>・所得税を計算しない</li>
                </ul>
                ※ 「本人 非居住者区分」が「非居住者」の場合にのみ設定できます。
              </td>
              <td>税率20.42%で計算</td>
            </tr>
            <tr>
              <th>本人 税額表区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・甲欄</li>
                  <li>・乙欄</li>
                  <li>・所得税を計算しない</li>
                </ul>
              </td>
              <td>甲欄</td>
            </tr>
            <tr>
              <th>本人 ひとり親・寡婦区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象外</li>
                  <li>・ひとり親</li>
                  <li>・寡婦</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>本人 寡婦（夫）区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象外</li>
                  <li>・寡婦（夫）</li>
                  <li>・特別寡婦</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>本人 障害者区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象外</li>
                  <li>・一般障害者</li>
                  <li>・特別障害者</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>本人 勤労学生区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象外</li>
                  <li>・勤労学生</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>本人 災害者区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象外</li>
                  <li>・災害者</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>本人 外国人区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・日本人</li>
                  <li>・外国人</li>
                </ul>
              </td>
              <td>日本人</td>
            </tr>
            <tr>
              <th>納付先市区町村</th>
              <td>空欄の場合、市区町村コードに従って自動で入力されます</td>
              <td>東京都渋谷区</td>
            </tr>
            <tr>
              <th>納付先市区町村（市区町村コード）</th>
              <td>半角数字6桁以内</td>
              <td>131130</td>
            </tr>
            <tr>
              <th>給与支払報告書提出先</th>
              <td>空欄の場合、給与支払報告書提出先コードに従って自動で入力されます</td>
              <td>東京都渋谷区</td>
            </tr>
            <tr>
              <th>給与支払報告書提出先（市区町村コード）</th>
              <td>半角数字6桁以内</td>
              <td>131130</td>
            </tr>
            <tr>
              <th>住民税徴収方法</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・特別徴収</li>
                  <li>・普通徴収</li>
                </ul>
              </td>
              <td>特別徴収</td>
            </tr>
            <tr>
              <th>年税額</th>
              <td>
                半角数字9桁以内
                <br />
                年税額のみが入力されている場合、12等分した金額が各月の徴収税額に自動反映されます。
                <br />
                ※端数は6月の徴収額に加算されます。(1200円未満の場合は6月に一括で徴収となります)
              </td>
              <td>300000</td>
            </tr>
            <tr>
              <th>
                徴収税額 6月分
                <br />
                徴収税額 7月分
                <br />
                徴収税額 8月分
                <br />
                徴収税額 9月分
                <br />
                徴収税額 10月分
                <br />
                徴収税額 11月分
                <br />
                徴収税額 12月分
                <br />
                徴収税額 1月分
                <br />
                徴収税額 2月分
                <br />
                徴収税額 3月分
                <br />
                徴収税額 4月分
                <br />
                徴収税額 5月分
              </th>
              <td>
                半角数字9桁以内
                <br />
                12ヶ月分の合計金額が年税額に設定されます。
                <br />
                <br />
                以下の内容でインポートを行うとエラーになります。
                <ul>
                  <li>・各月の徴収税額が一部のみ入力されている場合</li>
                  <li>・年税額にも金額が入力されていて合計金額と一致しない場合</li>
                </ul>
              </td>
              <td>25000</td>
            </tr>
            <tr>
              <th>被保険者区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・一般の被保険者</li>
                  <li>・パート扱いする者</li>
                  <li>・短時間労働者</li>
                </ul>
              </td>
              <td>一般の被保険者</td>
            </tr>
            <tr>
              <th>健康保険 加入状況</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・加入</li>
                  <li>・未加入</li>
                </ul>
              </td>
              <td>加入</td>
            </tr>
            <tr>
              <th>健康保険被保険者整理番号</th>
              <td>半角数字7桁以内</td>
              <td>111</td>
            </tr>
            <tr>
              <th>健康保険 資格取得日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2010/4/1</td>
            </tr>
            <tr>
              <th>健康保険 資格喪失日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2018/4/1</td>
            </tr>
            <tr>
              <th>介護保険料免除対象者</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象</li>
                  <li>・対象外</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>厚生年金保険 加入状況</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・加入</li>
                  <li>・未加入</li>
                </ul>
              </td>
              <td>加入</td>
            </tr>
            <tr>
              <th>基礎年金番号</th>
              <td>半角数字10桁（XXXX-XXXXXX）</td>
              <td>1111-111111</td>
            </tr>
            <tr>
              <th>厚生年金保険被保険者整理番号</th>
              <td>
                <ul>
                  <li>
                    半角数字6桁以内
                    <br />
                    ※協会けんぽの場合は不要です。
                    <br />
                    健康保険被保険者整理番号のみご登録ください。
                  </li>
                </ul>
              </td>
              <td>111</td>
            </tr>
            <tr>
              <th>厚生年金保険 資格取得日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2010/4/1</td>
            </tr>
            <tr>
              <th>厚生年金保険 資格喪失日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2018/4/1</td>
            </tr>
            <tr>
              <th>雇用保険 加入状況</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・加入</li>
                  <li>・未加入</li>
                </ul>
              </td>
              <td>加入</td>
            </tr>
            <tr>
              <th>雇用保険 従業員区分</th>
              <td>
                下記より一つ選択ください<br />
                「雇用保険 加入状況」が「加入」の場合
                <ul>
                  <li>・従業員（常用）</li>
                  <li>・役員兼従業員</li>
                </ul>
                「雇用保険 加入状況」が「未加入」の場合
                <ul>
                  <li>・従業員（臨時）</li>
                  <li>・役員または計算対象外</li>
                </ul>
              </td>
              <td>従業員（常用）</td>
            </tr>
            <tr>
              <th>雇用保険 被保険者番号</th>
              <td>半角数字11桁（XXXX-XXXXXX-X）</td>
              <td>1111-111111-1</td>
            </tr>
            <tr>
              <th>雇用保険 資格取得日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2010/4/1</td>
            </tr>
            <tr>
              <th>雇用保険 離職等年月日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>2018/3/31</td>
            </tr>
            <tr>
              <th>労災保険 計算対象</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象</li>
                  <li>・対象外</li>
                </ul>
              </td>
              <td>対象</td>
            </tr>
            <tr>
              <th>従たる給与についての扶養控除等申告書の提出</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・TRUE</li>
                  <li>・FALSE</li>
                </ul>
                ※提出ありの場合は「TRUE」、提出なしの場合は「FALSE」をご選択ください。
              </td>
              <td>TRUE</td>
            </tr>
            <tr>
              <th>配偶者の有無</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・有</li>
                  <li>・無</li>
                </ul>
              </td>
              <td>有</td>
            </tr>
            <tr>
              <th>被扶養者1 続柄</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・配偶者</li>
                  <li>・実父</li>
                  <li>・実母</li>
                </ul>
                <ShowMore>
                  <ul>
                    <li>・義父</li>
                    <li>・義母</li>
                    <li>・長男</li>
                    <li>・長女</li>
                    <li>・次男</li>
                    <li>・次女</li>
                    <li>・三男</li>
                    <li>・三女</li>
                    <li>・兄</li>
                    <li>・姉</li>
                    <li>・弟</li>
                    <li>・妹</li>
                    <li>・祖父</li>
                    <li>・祖母</li>
                    <li>・曽祖父母</li>
                    <li>・孫息子</li>
                    <li>・孫娘</li>
                    <li>・叔父</li>
                    <li>・叔母</li>
                    <li>・甥</li>
                    <li>・姪</li>
                  </ul>
                </ShowMore>
              </td>
              <td>配偶者</td>
            </tr>
            <tr>
              <th>被扶養者1 姓</th>
              <td>-</td>
              <td>山田</td>
            </tr>
            <tr>
              <th>被扶養者1 名</th>
              <td>-</td>
              <td>花子</td>
            </tr>
            <tr>
              <th>被扶養者1 姓カナ</th>
              <td>全角カタカナ</td>
              <td>ヤマダ</td>
            </tr>
            <tr>
              <th>被扶養者1 名カナ</th>
              <td>全角カタカナ</td>
              <td>ハナコ</td>
            </tr>
            <tr>
              <th>被扶養者1 生年月日</th>
              <td>半角数字 (yyyy/mm/dd)</td>
              <td>1980/1/1</td>
            </tr>
            <tr>
              <th>被扶養者1 性別</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・男性</li>
                  <li>・女性</li>
                </ul>
              </td>
              <td>女性</td>
            </tr>
            <tr>
              <th>被扶養者1 同居・別居</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・同居</li>
                  <li>・別居（国内）</li>
                  <li>・別居（国外）</li>
                </ul>
              </td>
              <td>同居</td>
            </tr>
            <tr>
              <th>被扶養者1 郵便番号 </th>
              <td>半角数字7桁（XXX-XXXX）</td>
              <td>999-9999</td>
            </tr>
            <tr>
              <th>被扶養者1 都道府県 </th>
              <td>-</td>
              <td>東京都</td>
            </tr>
            <tr>
              <th>被扶養者1 市区町村 </th>
              <td>-</td>
              <td>渋谷区</td>
            </tr>
            <tr>
              <th>被扶養者1 丁目番地 </th>
              <td>-</td>
              <td>9-99-99</td>
            </tr>
            <tr>
              <th>被扶養者1 建物名・部屋番号 </th>
              <td>-</td>
              <td>ジョブカンアパート</td>
            </tr>
            <tr>
              <th>被扶養者1 海外住所</th>
              <td>半角英数字</td>
              <td>9999PennsylvaniaAvenueN.W. Washington,DC20004</td>
            </tr>
            <tr>
              <th>被扶養者1 非居住者</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象</li>
                  <li>・対象外</li>
                </ul>
              </td>
              <td>対象</td>
            </tr>
            <tr>
              <th>被扶養者1 留学</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象</li>
                  <li>・対象外</li>
                </ul>
              </td>
              <td>対象</td>
            </tr>
            <tr>
              <th>被扶養者1 年間収入（1月～12月）</th>
              <td>半角数字9桁以内</td>
              <td>1030000</td>
            </tr>
            <tr>
              <th>被扶養者1 年間所得（1月～12月）</th>
              <td>半角数字9桁以内</td>
              <td>380000</td>
            </tr>
            <tr>
              <th>被扶養者1 税の扶養対象</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象</li>
                  <li>・対象外</li>
                </ul>
              </td>
              <td>対象</td>
            </tr>
            <tr>
              <th>被扶養者1 障害者区分</th>
              <td>
                下記より一つ選択ください
                <ul>
                  <li>・対象外</li>
                  <li>・一般障害者</li>
                  <li>・特別障害者</li>
                </ul>
              </td>
              <td>対象外</td>
            </tr>
            <tr>
              <th>同意状況</th>
              <td>
                電子交付の同意状況を一括で変更したい場合は下記より一つ選択して値を入力して下さい
                <ul>
                  <li>・同意済</li>
                  <li>・未同意</li>
                </ul>
                ※既に従業員本人によって同意されている場合は同意、未同意の変更が無視されます。<br />
                ※エクスポート時に出力された同意日と操作者はインポート時には無視されます。
              </td>
              <td>同意済</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default FileImportDescription;
