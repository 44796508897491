import React from 'react';
import { Field } from 'redux-form';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { arrowRenderer } from 'jbc-front/components/Form';
import styles from './SelectBoxYearMonth.scss';

class SelectBoxYearMonth extends React.Component {
  handleStartDateChange = (event, newValue) => {
    const { calcYearMonthEnd } = this.props;
    calcYearMonthEnd(newValue);
  };

  render() {
    const { wageLedgerYearMonths, formValues } = this.props;

    return (
      <div className={styles.box}>
        <div className={styles.container}>
          <Field
            name="yearMonthStart"
            component={SearchSelectBox}
            options={wageLedgerYearMonths}
            arrowRenderer={arrowRenderer}
            onChange={this.handleStartDateChange}
          />
        </div>
        から
        <div className={styles.container}>
          <Field
            name="yearMonthEnd"
            component={SearchSelectBox}
            options={formValues.yearMonthEndPeriods}
            arrowRenderer={arrowRenderer}
          />
        </div>
      </div>
    );
  }
}
export default SelectBoxYearMonth;
