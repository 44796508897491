import React from 'react';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { Download } from 'jbc-front/components/icons';
import { ErrorsSection, LoadingWithDescription } from 'src/components';
import { NOTIFICATION_OF_BASE_AMOUNT_REVISION_CONFIRM_CSV } from 'src/constants/EndpointUrls';
import { FormSubmitBtn } from 'src/buttons';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import styles from './ConfirmRevisionModal.scss';

const applyYm = yearMonth => `${yearMonth.split('_')[0]}年9月`;

class ConfirmRevisionModal extends React.Component {
  isShownPensionInsuranceInsuredNumber = insuranceType => !['society', 'not_entry'].includes(insuranceType);

  render() {
    const header = '標準報酬月額改定';
    const {
      applicableRevisions,
      insuranceType,
      isOpen,
      hideModal,
      yearMonth,
      applicableOffice,
      handleSubmit,
      submitting,
      submit,
      errors,
      sortType
    } = this.props;

    return (
      <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, yearMonth }}>
        <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
        <CommonModal.Body>
          <form onSubmit={handleSubmit}>
            <ErrorsSection errors={errors} />
            {submitting && <LoadingWithDescription text="確定中です。少々お待ちください。" />}
            <div className={styles.csv}>
              <ActionButton
                onClick={() =>
                  redirectTo(
                    `${NOTIFICATION_OF_BASE_AMOUNT_REVISION_CONFIRM_CSV}?${serializeHttpGetParams({
                      revision_year: yearMonth,
                      applicable_office: applicableOffice,
                      sort_type: sortType
                    })}`
                  )
                }
                icon={<Download size={15} />}
              >
                CSVダウンロード
              </ActionButton>
            </div>
            <div>
              <table className="m-table-item">
                <thead>
                  <tr>
                    <th>スタッフコード</th>
                    <th>健康保険被保険者整理番号</th>
                    {this.isShownPensionInsuranceInsuredNumber(insuranceType) && <th>厚生年金被保険者整理番号</th>}
                    <th>氏名</th>
                    <th>現在</th>
                    <th>{applyYm(yearMonth)}〜</th>
                  </tr>
                </thead>
                <tbody>
                  {applicableRevisions &&
                    applicableRevisions.map(revision => (
                      <tr key={`revision_tr_${revision.id}`}>
                        <td>{revision.staffCode}</td>
                        <td>{revision.healthInsuranceInsuredPersonnelNumber}</td>
                        {this.isShownPensionInsuranceInsuredNumber(insuranceType) && (
                          <td>{revision.pensionInsuranceInsuredNumber}</td>
                        )}
                        <td>{`${revision.lastName} ${revision.firstName}`}</td>
                        <td>
                          <div>{revision.beforeMonthlyCompensationHealthInsuranceAmount}</div>
                          <div>{revision.beforeMonthlyRemunerationPensionInsuranceAmount}</div>
                        </td>
                        <td>
                          <div>{revision.afterMonthlyCompensationHealthInsuranceAmount}</div>
                          <div> {revision.afterMonthlyRemunerationPensionInsuranceAmount}</div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <ul className={styles.notes}>
              <li>※ {applyYm(yearMonth)}分以降の標準報酬月額が上記のように改定されます。本当によろしいですか？</li>
            </ul>
          </form>
        </CommonModal.Body>
        <CommonModal.Footer>
          <Button onClick={hideModal} className="u-mr20">
            キャンセル
          </Button>
          <FormSubmitBtn text="確定" {...{ submitting, submit }} />
        </CommonModal.Footer>
      </CommonModal>
    );
  }
}

export default ConfirmRevisionModal;
