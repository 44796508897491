import axios from 'axios';
import { EMPLOYEE_MY_DATA_EMPLOYEE_ELECTRONIC_DELIVERY_AGREEMENT } from 'src/constants/EndpointUrls';

export const executeIfAgreement = async (callback, argument) => {
  const response = await axios.get(EMPLOYEE_MY_DATA_EMPLOYEE_ELECTRONIC_DELIVERY_AGREEMENT);
  if (!response.data.isAgree) {
    // 未同意の場合はサーバー側で同意用のページに誘導するのでリロードする
    window.location.reload();
    return;
  }
  // 同意していたら実行する処理
  if (callback && typeof callback === 'function') {
    callback(argument);
  }
};

// lintでPrefer default exportに引っかかるので追加
export default function() {}
