import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { CheckboxFieldWithoutForm } from 'src/components';
import { getSelectOptions } from 'src/reducers/selectOptions';
import MemoList from 'src/features/Memos';
import { getOptionLabel, getGroupFullPathName } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';
import styles from 'src/components/EmployeeTable.scss';

import ChangedContext from '../ChangedContext';

const EmployeeTableRow = props => {
  const { employee, clientEmploymentTypes, groups, positions } = props;

  return (
    <tr className={classNames('table-hover', styles.tableRow)}>
      <ChangedContext.Consumer>
        {({ isSelected, change }) => {
          const checked = isSelected(employee);
          return (
            <td
              className={`m-table-checkbox-${checked ? 'on' : 'off'} table-hover`}
              onClickCapture={() => change({ [employee.id]: !checked })}
            >
              <CheckboxFieldWithoutForm checked={checked} readOnly />
            </td>
          );
        }}
      </ChangedContext.Consumer>
      <td>
        <MemoList employeeId={employee.id} />
      </td>
      <td>{employee.staffCode}</td>
      <td>{employee.displayName}</td>
      <td>{getOptionLabel(employee.employmentTypeId)(clientEmploymentTypes)}</td>
      <td>{!!employee.groupId && <div>{getGroupFullPathName(groups, employee.groupId)}</div>}</td>
      <td>{getOptionLabel(employee.positionId)(positions)}</td>
      <td>{formatDate(employee.joinedOn)}</td>
    </tr>
  );
};

export default connect(state => ({
  clientEmploymentTypes: getSelectOptions(state, 'employmentTypes'),
  groups: getSelectOptions(state, 'groups'),
  positions: getSelectOptions(state, 'employeePositions')
}))(EmployeeTableRow);
