import React from 'react';

import ResetPasswordForm from './components/ResetPasswordForm';

const SendResetPasswordEmail = () => (
  <div className="login">
    <ResetPasswordForm />
  </div>
);

export default SendResetPasswordEmail;
