import React from 'react';
import styles from './PrimaryPanel.scss';

export const Headding = ({ children, icon }) => (
  <div className={styles.heading}>
    <div className={styles.tittle}>{children}</div>
    {icon && <span className={styles.leadIcon}>{icon}</span>}
  </div>
);

export const Panel = ({ children }) => <div className={styles.base}>{children}</div>;

export const Lead = ({ children, icon }) => (
  <div className={styles.lead}>
    icon && <span className={styles.leadIcon}>{icon}</span>
    {children}
  </div>
);

export const Icon = ({ children }) => <div className={styles.iconMemo}>{children}</div>;

export const Item = ({ children }) => (
  <div className={styles.body}>
    <div className={styles.inner}>{children}</div>
  </div>
);
