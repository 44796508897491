import React from 'react';
import { Field } from 'redux-form';
import _ from 'lodash';
import Hint from 'jbc-front/components/Hint';

import classNames from 'classnames';
import { numberWithComma } from 'src/utils/Utils';
import { pad } from 'src/utils/Number';
import { formatDate, formatYearMonth } from 'src/utils/Date';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import Checked from 'src/icons/Checked';
import EditableTextField from '../Util/EditableTextField';
import EditableSelectField from '../Util/EditableSelectField';
import RevisionGrade from './RevisionGrade';
import RevisionGradeAmount from './RevisionGradeAmount';
import styles from './RevisionItem.scss';

const ContentTop = props => {
  const {
    revision,
    showMode,
    recalculate,
    notifyValueChanges,
    setChangedAmountByAmountChangedAt,
    changeToManualCalcMode,
    fetchValue,
    validateRetroactiveAmount,
    selectableMonths,
    lastRevisionYearMonths,
    setRetroactiveEnabledAllTrue
  } = props;

  const targetLastRevisionYearMonths = () => {
    if (_.isEmpty(revision) || _.isEmpty(lastRevisionYearMonths)) {
      return [];
    }
    const employeeId = revision.employeeId;
    const target = lastRevisionYearMonths.find(data => data.employeeId === employeeId);
    return _.isEmpty(target) ? [] : target.months;
  };

  return (
    <div className={styles.contentTop}>
      <div className={styles.firstDivision}>
        <div className={classNames(styles.insuredPersonNumber, styles.boxHeader)}>健康保険被保険者整理番号</div>
        <div>{revision.healthInsuranceInsuredPersonnelNumber}</div>
        <div className={classNames(styles.birthday, styles.boxHeader)}>生年月日</div>
        <div>{formatDate(revision.birthday)}</div>
        <div className={classNames(styles.revisionYearMonth, styles.boxHeader)}>改定年月</div>
        <div>{`${fetchValue('revisionYear')}/09`}</div>
      </div>
      <div className={styles.secondDivision}>
        <div className={classNames(styles.beforeHealthInsurance, styles.boxHeader)}>従前の健保</div>
        <div>
          <RevisionGrade isJoined={revision.isJoinedHealthInsurance} grade={revision.beforeHealthInsuranceGrade} />
        </div>
        <div className={styles.amount}>
          <RevisionGradeAmount
            isJoined={revision.isJoinedHealthInsurance}
            amount={revision.beforeMonthlyCompensationHealthInsuranceAmount}
          />
        </div>
        <div className={classNames(styles.beforePensionInsurance, styles.boxHeader)}>従前の厚年</div>
        <div>
          <RevisionGrade isJoined={revision.isJoinedPensionInsurance} grade={revision.beforePensionInsuranceGrade} />
        </div>
        <div className={styles.amount}>
          <RevisionGradeAmount
            isJoined={revision.isJoinedPensionInsurance}
            amount={revision.beforeMonthlyRemunerationPensionInsuranceAmount}
          />
        </div>
        <div className={classNames(styles.beforeRevisionDate, styles.boxHeader)}>従前改定月</div>
        <div>
          <Field
            name="lastRevisionYearMonth"
            showValue={formatYearMonth(fetchValue('lastRevisionYearMonth'))}
            showMode={showMode}
            textAlign="center"
            options={targetLastRevisionYearMonths()}
            width={100}
            component={EditableSelectField}
            addBlankOption
          />
        </div>
        <div className={classNames(styles.changeAmount, styles.boxHeader)}>
          昇（降）給
          <Hint
            width={231}
            text={
              <p>
                昇給（降給）した場合は、「昇（降）給」に1ヶ月の合計差額を入力して下さい。（毎月の給与を基に自動計算されます）
              </p>
            }
          />
        </div>
        <div>
          <div className={styles.amountChangedAt}>
            <Field
              name="amountChangedAt"
              showValue={formatYearMonth(revision.amountChangedAt)}
              showMode={showMode}
              textAlign="center"
              options={selectableMonths([4, 5, 6])}
              Width={100}
              component={EditableSelectField}
              onChange={(_event, value) => {
                setChangedAmountByAmountChangedAt(value);
              }}
              addBlankOption
            />
          </div>
          <div className={styles.amount}>
            <Field
              name="changedAmount"
              showValue={numberWithComma(revision.changedAmount)}
              showMode={showMode}
              inputWidth={70}
              unit="円"
              customOnChange={notifyValueChanges}
              customOnBlur={() => {
                changeToManualCalcMode('changedAmount');
                recalculate();
              }}
              validate={[isMoneyNumber]}
              component={EditableTextField}
            />
          </div>
        </div>
        <div className={classNames(styles.retroactiveAmount, styles.boxHeader)}>
          遡及支払額
          <Hint
            width={281}
            text={
              <p>
                4月、5月、6月の各月に受けた報酬月額のなかに、さかのぼって昇給したことなどによる数ヵ月分以上の昇給差額や、
                3月分以前の遅払分が含まれている場合に、その額を記入します。<br />
                （例）3月に2万円昇給し、3月の昇給分が4月に支給された場合→遡及支払額は2万円になります
              </p>
            }
          />
        </div>
        <div className={styles.retroactiveAmountTotal}>
          <div className={styles.checkBoxGroup}>
            {showMode === 'SHOW' && (
              <span className={styles.checked}>
                <Checked checked={fetchValue('retroactiveEnabled')} defaultValue="" />
              </span>
            )}
            <Field
              name="retroactiveEnabled"
              label="遡及支払あり"
              showValue={fetchValue('retroactiveEnabled') ? '遡及支払あり' : '遡及支払なし'}
              showMode={showMode}
              inputWidth={96}
              inputHeight={16}
              textAlign="left"
              style={{ 'flex-grow': 1 }}
              width={118}
              type="checkbox"
              customOnChange={event => {
                notifyValueChanges();
                if (event.target.checked) {
                  // チェックがONになった時に4,5,6月のチェックを入れる
                  // 他の項目の編集で自動計算が動いた時にすぐにフラグが落ちることを防ぐため
                  setRetroactiveEnabledAllTrue();
                }
                recalculate();
              }}
              component={EditableTextField}
            />
          </div>
          <div className={styles.totalAmount}>
            <p>合計</p>
            <Field
              name="totalRetroactiveAmount"
              disabled={!fetchValue('retroactiveEnabled')}
              showValue={numberWithComma(fetchValue('totalRetroactiveAmount'))}
              showMode={showMode}
              inputWidth={70}
              width="initial"
              unit="円"
              customOnChange={notifyValueChanges}
              customOnBlur={() => {
                changeToManualCalcMode('totalRetroactiveAmount');
                recalculate();
              }}
              validate={[isMoneyNumber, validateRetroactiveAmount]}
              component={EditableTextField}
            />
          </div>
        </div>
        <div className={styles.retroactiveAmountMonths}>
          <div className={styles.checkBoxGroup}>
            {showMode === 'SHOW' && (
              <span className={styles.checked}>
                <Checked checked={fetchValue('firstMonthRetroactiveEnabled')} defaultValue="" />
              </span>
            )}
            <Field
              name="firstMonthRetroactiveEnabled"
              disabled={!fetchValue('retroactiveEnabled')}
              label="4月"
              showValue="4月"
              showMode={showMode}
              inputWidth={80}
              inputHeight={16}
              textAlign="left"
              style={{ 'flex-grow': 1 }}
              type="checkbox"
              customOnChange={() => {
                notifyValueChanges();
                recalculate();
              }}
              component={EditableTextField}
            />
          </div>
          <div className={styles.checkBoxGroup}>
            {showMode === 'SHOW' && (
              <span className={styles.checked}>
                <Checked checked={fetchValue('secondMonthRetroactiveEnabled')} defaultValue="" />
              </span>
            )}
            <Field
              name="secondMonthRetroactiveEnabled"
              disabled={!fetchValue('retroactiveEnabled')}
              label="5月"
              showValue="5月"
              showMode={showMode}
              inputWidth={80}
              inputHeight={16}
              textAlign="left"
              style={{ 'flex-grow': 1 }}
              type="checkbox"
              customOnChange={() => {
                notifyValueChanges();
                recalculate();
              }}
              component={EditableTextField}
            />
          </div>
          <div className={styles.checkBoxGroup}>
            {showMode === 'SHOW' && (
              <span className={styles.checked}>
                <Checked checked={fetchValue('thirdMonthRetroactiveEnabled')} defaultValue="" />
              </span>
            )}
            <Field
              name="thirdMonthRetroactiveEnabled"
              disabled={!fetchValue('retroactiveEnabled')}
              showValue="6月"
              label="6月"
              showMode={showMode}
              inputWidth={80}
              inputHeight={16}
              textAlign="left"
              style={{ 'flex-grow': 1 }}
              type="checkbox"
              customOnChange={() => {
                notifyValueChanges();
                recalculate();
              }}
              component={EditableTextField}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentTop;
