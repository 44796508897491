import React from 'react';
import SortableTh from 'src/components/SortableTh';
import { detailUrlWithQueries, getIdsFromArr, redirectTo } from 'src/utils/Http';
import { EMPLOYEE_SHOW } from 'src/constants/EndpointUrls';
import { FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import styles from 'src/components/EmployeeTable.scss';
import EmployeeTableRow from '../containers/EmployeeTableRowContainer';

const EmployeeTable = ({
  employees,
  queries,
  mode,
  isMainOfficeOnly,
  allEmployeeChecked,
  onChangeAllEmployeeChecked
}) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list">
      <thead>
        <tr>
          {mode === 'EDIT' && (
            <th
              onClick={() => onChangeAllEmployeeChecked(!allEmployeeChecked)}
              className={allEmployeeChecked ? 'm-table-checkbox-on' : 'm-table-checkbox-off'}
            />
          )}
          <th>メモ</th>
          <SortableTh field="staffCode" globalClassName="m-table-list-staffcode" formName={FORM_NAME_EMPLOYEES_SEARCH}>
            スタッフコード
          </SortableTh>
          <SortableTh field="name" globalClassName={styles.name} formName={FORM_NAME_EMPLOYEES_SEARCH}>
            氏名
          </SortableTh>
          {!isMainOfficeOnly && (
            <SortableTh
              field="officeManageName"
              globalClassName={styles.officeManageName}
              formName={FORM_NAME_EMPLOYEES_SEARCH}
            >
              事業所
            </SortableTh>
          )}
          <SortableTh
            field="employmentTypeId"
            globalClassName={styles.employmentType}
            formName={FORM_NAME_EMPLOYEES_SEARCH}
          >
            雇用形態
          </SortableTh>
          <SortableTh field="groupId" globalClassName={styles.groupPath} formName={FORM_NAME_EMPLOYEES_SEARCH}>
            グループ
          </SortableTh>
          <SortableTh field="positionId" globalClassName={styles.positionName} formName={FORM_NAME_EMPLOYEES_SEARCH}>
            役職
          </SortableTh>
          <SortableTh field="joinedOn" globalClassName={styles.joinedOn} formName={FORM_NAME_EMPLOYEES_SEARCH}>
            入社日
          </SortableTh>
        </tr>
      </thead>
      <tbody>
        {(!employees || employees.length === 0) && (
          <tr>
            <td colSpan={8}>データがありません。</td>
          </tr>
        )}

        {employees.map(employee => (
          <EmployeeTableRow
            key={employee.id}
            mode={mode}
            onClick={() =>
              redirectTo(detailUrlWithQueries(employee.id, queries, EMPLOYEE_SHOW, getIdsFromArr(employees)))
            }
            {...{ employee }}
          />
        ))}
      </tbody>
    </table>
  </div>
);

export default EmployeeTable;
