import { change as formChange } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';

import { getSelectOptions } from 'src/reducers/selectOptions';
import { getExtras, getErrors } from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  fixBeforeMonthlyInsuranceAmounts,
  getStaledRevisions,
  getTargetRevisions,
  getTargetHealthInsurance,
  getImporting
} from 'src/reducers/reports/monthlyRevisions';
import {
  isLoading,
  revisionMonthsSearchFormSelector,
  ITEM_NAME_FETCH_REVISION_MONTH_OPTIONS,
  FORM_NAME_REVISION_MONTHS_SEARCH,
  fetchRevisionMonthOfficeOptions,
  fetchRevisionMonthSyncOptions
} from 'src/reducers/searchForm';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import Header from '../components/Header';

const mapStateToProps = state => ({
  revisionYearMonths: getSelectOptions(state, 'revisionYearMonths'),
  applicableOffices: getSelectOptions(state, 'applicableOffices'),
  yearMonth: revisionMonthsSearchFormSelector(state, 'yearMonth'),
  applicableOffice: revisionMonthsSearchFormSelector(state, 'applicableOffice'),
  extras: getExtras(REDUCER_NAME, state),
  hasErrors: !_.isEmpty(getErrors(REDUCER_NAME, state)),
  errors: getItemErrors(REDUCER_NAME, state),
  isOptionsLoading: isLoading(ITEM_NAME_FETCH_REVISION_MONTH_OPTIONS, state),
  staledRevisions: getStaledRevisions(state),
  alertHealthInsuranceManualInputRevisions: _.get(
    getExtras(REDUCER_NAME, state),
    'alertHealthInsuranceManualInputRevisions'
  ),
  alertPensionInsuranceManualInputRevisions: _.get(
    getExtras(REDUCER_NAME, state),
    'alertPensionInsuranceManualInputRevisions'
  ),
  targetHealthInsurance: getTargetHealthInsurance(state),
  targetCount: getTargetRevisions(state) ? getTargetRevisions(state).length : [],
  importing: getImporting(state)
});

const mapDispatchToProps = dispatch => ({
  fetchRevisionMonthSyncOptions: () => {
    dispatch(fetchRevisionMonthSyncOptions());
  },
  fixBeforeMonthlyInsuranceAmounts: revisionIds => {
    dispatch(fixBeforeMonthlyInsuranceAmounts(revisionIds));
  },
  fetchRevisionMonthOfficeOptions: () => {
    dispatch(fetchRevisionMonthOfficeOptions());
  },
  changeFormValue: (field, value) => {
    dispatch(formChange(FORM_NAME_REVISION_MONTHS_SEARCH, field, value));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
