import React from 'react';
import { connect } from 'react-redux';

import { CheckboxFieldWithoutForm } from 'src/components';
import { Hamburger } from 'jbc-front/components/icons';
import { FORM_NAME_WAGE_LEDGER_SEARCH } from 'src/reducers/searchForm';
import SortableTh from 'src/components/SortableTh';
import { isAllWageLedgerChecked, changeCheckedCurrentPage } from 'src/reducers/reports/reportWageLedger';
import ReportWageLedgerTableRow from './ReportWageLedgerTableRow';
import ReportWageLedgerTableMethod from './ReportWageLedgerTableMethod';
import styles from './ReportWageLedgerTable.scss';

class ReportWageLedgerTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false
    };
  }

  render() {
    const { employees, year, isAllChecked, changeCheckedCurrentPageTo } = this.props;

    return (
      <div className="l-overflow-scroll">
        <table className="m-table-list">
          <thead>
            <tr>
              <th width={40}>
                <div className={styles.wrap}>
                  <div
                    role="presentation"
                    onClick={() => changeCheckedCurrentPageTo(!isAllChecked)}
                    className={isAllChecked ? 'theadCheckboxOn' : 'theadCheckboxOff'}
                  >
                    <CheckboxFieldWithoutForm checked={isAllChecked} readOnly={'readonly'} />
                  </div>
                  <div>
                    <div className={styles.button}>
                      <Hamburger
                        size={12}
                        className={`${styles.menu} ignore-react-onclickoutside`}
                        onClick={() => this.setState({ isMenuOpen: !this.state.isMenuOpen })}
                      />
                      {this.state.isMenuOpen && (
                        <ReportWageLedgerTableMethod hide={() => this.setState({ isMenuOpen: false })} />
                      )}
                    </div>
                  </div>
                </div>
              </th>
              <SortableTh
                field="staffCode"
                globalClassName="m-table-list-staffcode"
                formName={FORM_NAME_WAGE_LEDGER_SEARCH}
              >
                スタッフコード
              </SortableTh>
              <SortableTh field="name" globalClassName={styles.name} formName={FORM_NAME_WAGE_LEDGER_SEARCH}>
                氏名
              </SortableTh>
              <SortableTh
                field="employmentTypeId"
                globalClassName={styles.employmentType}
                formName={FORM_NAME_WAGE_LEDGER_SEARCH}
              >
                雇用形態
              </SortableTh>
              <SortableTh field="groupId" globalClassName={styles.groupPath} formName={FORM_NAME_WAGE_LEDGER_SEARCH}>
                グループ
              </SortableTh>
              <SortableTh field="joinedOn" globalClassName={styles.joinedOn} formName={FORM_NAME_WAGE_LEDGER_SEARCH}>
                入社日
              </SortableTh>
              <SortableTh
                field="resignedOn"
                globalClassName={styles.resignedOn}
                formName={FORM_NAME_WAGE_LEDGER_SEARCH}
              >
                退職日
              </SortableTh>
              <th className={styles.pdf}>PDF</th>
            </tr>
          </thead>
          <tbody>
            {employees &&
              employees.map(employee => <ReportWageLedgerTableRow key={employee.id} {...employee} year={year} />)}
            {(!employees || employees.length === 0) && (
              <tr>
                <td colSpan={8}>データがありません。</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default connect(
  state => ({
    isAllChecked: isAllWageLedgerChecked(state)
  }),
  dispatch => ({
    changeCheckedCurrentPageTo: newValue => dispatch(changeCheckedCurrentPage(newValue))
  })
)(ReportWageLedgerTable);
