import { connect } from 'react-redux';
import { arrayPush as arrayPushForm, reset as resetForm, submit as submitForm } from 'redux-form';

import { OCCUPATION_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';

import { getMode, getListErrors, changeToEditMode, changeToShowMode } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME } from 'src/reducers/clients/occupations';

import { EditableListButtons } from 'src/components';

const mapStateToProps = state => ({
  mode: getMode(REDUCER_NAME, state),
  errors: getListErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  onEdit: () => {
    dispatch(changeToEditMode(REDUCER_NAME));
  },
  onSave: () => {
    dispatch(submitForm(OCCUPATION_FORM));
  },
  onCancel: () => {
    dispatch(resetForm(OCCUPATION_FORM));
    dispatch(changeToShowMode(REDUCER_NAME));
  },
  onAddRow: () => {
    dispatch(arrayPushForm(OCCUPATION_FORM, EDITABLE_LIST_FIELDS_NAME, { name: '' }));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableListButtons);
