import React, { useState } from 'react';

import { AllJobStatus } from 'src/components/JobStatus/AllJobStatus';
import { CONFIRMED_MODE } from 'src/reducers/flat_tax_reductions/csv';
import { FlatTaxReductionCsvUploadDescription } from './components/FlatTaxReductionCsvUploadDescription';
import { FlatTaxReductionCsvUploadConfirmDescription } from './components/FlatTaxReductionCsvUploadConfirmDescription';
import { FlatTaxReductionCsvUploadForm } from './components/FlatTaxReductionCsvUploadForm';
import { FlatTaxReductionCsvUploadConfirmForm } from './components/FlatTaxReductionCsvUploadConfirmForm';

import styles from './components/FlatTaxReductionCsvUpload.scss';

const UpdateFlatTaxReductionCsv = ({ values: { mode } }) => {
  const [result, setResult] = useState({});
  const hideJobMessage = () => setResult({});

  const statusText = ({ status, fileName }) => {
    if (status === 'success') {
      return `をインポートしました。(${fileName})`;
    }
    return `のインポート中にエラーが発生しました。なお、以下にエラー表示のない従業員は、正常に登録されています。(${fileName})`;
  };

  return (
    <div>
      <AllJobStatus statusText={statusText} hideJobMessage={hideJobMessage} task={result} />
      {mode === CONFIRMED_MODE ? (
        <>
          <div className="l-title-wrap">
            <h1 className="m-title-main">更新：確定状況</h1>
            <p className="m-title-main-note">確定状況を一括で更新できます</p>
          </div>
          <FlatTaxReductionCsvUploadConfirmForm setResult={setResult} />
          <div className={styles.description}>
            <FlatTaxReductionCsvUploadConfirmDescription />
          </div>
        </>
      ) : (
        <>
          <div className="l-title-wrap">
            <h1 className="m-title-main">更新：定額減税対象人数・既減税額</h1>
            <p className="m-title-main-note">登録済みの定額減税対象人数を一括で更新できます</p>
          </div>
          <FlatTaxReductionCsvUploadForm setResult={setResult} mode={mode} />
          <div className={styles.description}>
            <FlatTaxReductionCsvUploadDescription />
          </div>
        </>
      )}
    </div>
  );
};

export { UpdateFlatTaxReductionCsv };
export default UpdateFlatTaxReductionCsv;
