import React, { useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import { change as formChange } from 'redux-form';
import _ from 'lodash';

import Hint from 'jbc-front/components/Hint';
import { ArrowDoublePrev, ArrowDoubleNext } from 'jbc-front/components/icons';
import { MONTHS_COLORS } from 'src/features/Employees/components/EmployeeStandardMonthliesSection';
import { CheckboxFieldWithoutForm } from 'src/components';
import image from 'src/features/Employees/images/img-insurance-amount.png';
import styles from './InsurancePeriodSetting.scss';
import { findLatestInsurances } from '../utils/Utils';

// propsにminSelectableYearが渡されなかった場合のデフォルト
const defaultMinimumYear = 2014;
const allMonths = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const formatYearMonth = (year, month, format) =>
  moment()
    .year(year)
    .month(month)
    .format(format);

const calYearMonth = (year, month) => formatYearMonth(year, month, 'YYYYMM');

/**
 * ユーザーの保険設定の中から指定した日付に適用されている設定値を返す
 * NOTE: このメソッドは、insurancesに最低1つの値が設定されていることを前提に作られている
 * 2019/01時点では、ユーザー未設定状態では、日付は指定させないため当該コンポーネントが描画されることはない
 * 設定以後は、システム上の最古日に設定値が作成されるため、日付がヒットしないケースも存在しない
 * ※ 上記前提条件が崩れる場合は、当該メソッドの構成を見直すこと
 * @param yearMonth
 * @param insurances
 * @returns {*}
 */
//

const isSame = ([, [a]], [, [b]]) => {
  const diffFields = [
    'isCurrentMonthCollection',
    'rateForEmployee',
    'rateForEmployer',
    'nursingInsuranceRateForEmployee',
    'nursingInsuranceRateForEmployer',
    'premiumRateForEmployee',
    'premiumRateForEmployer',
    'insuranceCode',
    'insuranceNumber',
    'labourInsuranceCode',
    'isInput'
  ];
  // デフォルト利率変更月はinsuranceTypeが入っていない対応
  if (a.insuranceType !== undefined && b.insuranceType !== undefined) {
    diffFields.push('insuranceType');
  }
  return diffFields.every(field => {
    if (['insuranceCode', 'labourInsuranceCode'].includes(field)) {
      // fieldが存在する場合（数値がついていない）は処理を行わない
      if (!a[field]) {
        let num = 1;

        // aとb両方を評価する。片方だけだと一方の値がnullの場合に比較されなくなるため。
        while (a[`${field}${num}`] || b[`${field}${num}`]) {
          if (_.toString(a[`${field}${num}`]) !== _.toString(b[`${field}${num}`])) {
            return false;
          }
          // 処理後にカウントアップする。最初にカウントアップするとnum=1の時の比較ができないため。
          num += 1;
        }
      }
    }
    return _.toString(a[field]) === _.toString(b[field]);
  });
};

/**
 * カレンダーの各月部分のコンポーネント
 * @param month 描画する月
 * @param onClick クリックイベント
 * @param isSelectedMonth 選択された月かどうか
 * @param colorCount 対象月までの同一の表示色数
 * @returns {*}
 * @constructor
 */
const MonthDiv = ({ month, onClick, selectedMonth, upperLowColorCount = 0, lowerLowColorCount = 0 }) => {
  const isSelectedMonth = selectedMonth === month;
  let tagClass = null;
  if (isSelectedMonth) {
    tagClass = styles.tagSelected;
  } else if (month > selectedMonth) {
    tagClass = styles.tagAfterSelected;
  } else {
    tagClass = styles.tagBeforeSelected;
  }

  return (
    <div className={classNames(tagClass, 'u-cur-pointer')} role="presentation" onClick={() => onClick(month)}>
      {month}
      <span className={styles.tagMonth}>月</span>
      <span
        style={{ backgroundColor: MONTHS_COLORS[upperLowColorCount % MONTHS_COLORS.length] }}
        className={styles.monthUnderBarUpperLow}
      />
      <span
        style={{ backgroundColor: MONTHS_COLORS[lowerLowColorCount % MONTHS_COLORS.length] }}
        className={styles.monthUnderBarLowerLow}
      />
    </div>
  );
};

const InsurancePeriodSetting = ({
  upperLowInsurances,
  lowerLowInsurances,
  upperLowUpdateFields,
  lowerLowUpdateFields,
  upperLowLabel,
  lowerLowLabel,
  formName,
  formSectionName,
  insuranceType,
  minSelectableYear,
  fieldName,
  payTextFormat,
  bonusTextFormat,
  dispatch,
  isCurrentMonthCollection,
  healthInsuranceSelf
}) => {
  const [selectedYear, setSelectedYear] = useState(moment().year());
  const [selectedMonth, setSelectingMonth] = useState(moment().month() + 1);

  /**
   * formの設定開始日データを更新する
   * @param year 設定対象の年
   * @param month 設定対象の月
   */
  const changeSettingStartDate = (year, month) => {
    const value = formatYearMonth(year, month - 1, 'YYYY-MM-01');
    dispatch(formChange(formName, `${formSectionName}.${fieldName}`, value));
  };

  /**
   * updateFieldsの各項目に値を設定する
   * @param fields
   * @param insurance
   */
  const eachFormChange = (fields, insurance) => {
    fields.forEach(field => {
      const key = field.split('.')[1];
      dispatch(formChange(formName, field, insurance[key]));
    });
  };

  /**
   * formの料率データを更新する
   * @param year 更新対象の年
   * @param month 更新対象の月
   */
  const updateInsuranceRate = (year, month) => {
    const yearMonth = calYearMonth(year, month - 1);
    const upperLowLatestInsurance = findLatestInsurances(yearMonth, upperLowInsurances);
    const lowerLowLatestInsurance = findLatestInsurances(yearMonth, lowerLowInsurances);

    if (upperLowLatestInsurance) {
      eachFormChange(upperLowUpdateFields, upperLowLatestInsurance);
    }
    if (lowerLowLatestInsurance) {
      eachFormChange(lowerLowUpdateFields, lowerLowLatestInsurance);
    }
  };

  const changeMonthHandler = newMonth => {
    setSelectingMonth(newMonth);
    changeSettingStartDate(selectedYear, newMonth);
    updateInsuranceRate(selectedYear, newMonth);
  };

  const changeYearHandler = newYear => {
    setSelectedYear(newYear);
    changeSettingStartDate(newYear, selectedMonth);
    updateInsuranceRate(newYear, selectedMonth);
  };

  const buildPeriodTextPay = () => {
    let month = selectedMonth;

    // 労災保険or当月徴収の場合
    if (insuranceType === 'labourInsurance' || !!isCurrentMonthCollection) {
      month = +month - 1;
    }

    return formatYearMonth(selectedYear, month, payTextFormat);
  };

  const buildPeriodTextBonus = () => {
    const month = +selectedMonth - 1;

    return formatYearMonth(selectedYear, month, bonusTextFormat);
  };

  const minYear = minSelectableYear || defaultMinimumYear;
  const canSelectPrevYear = selectedYear > minYear;

  const upperLowColorCounts = [];
  const lowerLowColorCounts = [];
  const upperLowSortedInsurances = _.sortBy(Object.entries(upperLowInsurances), ([key]) => key);
  const lowerLowSortedInsurances = _.sortBy(Object.entries(lowerLowInsurances), ([key]) => key);

  upperLowSortedInsurances.forEach(insurance => {
    if (upperLowColorCounts.findIndex(oldInsurance => isSame(oldInsurance, insurance)) < 0) {
      upperLowColorCounts.push(insurance);
    }
  });

  lowerLowSortedInsurances.forEach(insurance => {
    if (lowerLowColorCounts.findIndex(oldInsurance => isSame(oldInsurance, insurance)) < 0) {
      lowerLowColorCounts.push(insurance);
    }
  });

  return (
    <React.Fragment>
      <div className={styles.selectMonth}>
        <p className={styles.heading}>月を選択</p>
        <Hint
          text={
            <div className={styles.exampleWrap}>
              <div className={styles.exampleImg}>
                <img src={image} alt="標準報酬月額イメージ" />
              </div>
              <p className={styles.exampleText}>保険料率が同じ月は同色で表示されます</p>
            </div>
          }
        />
      </div>
      <div className={styles.selectYearMonthField}>
        <div className={styles.selectYear}>
          {canSelectPrevYear && (
            <div className={styles.prev} role="presentation" onClick={() => changeYearHandler(selectedYear - 1)}>
              <ArrowDoublePrev size={7} />
            </div>
          )}
          {!canSelectPrevYear && <div className={styles.prevDisable} />}
          <div className={styles.year}>{selectedYear}</div>
          <div className={styles.next} role="presentation" onClick={() => changeYearHandler(selectedYear + 1)}>
            <ArrowDoubleNext size={7} />
          </div>
        </div>
        <div className={styles.select}>
          <div className={styles.labels}>
            <p>{upperLowLabel}</p>
            <p>{lowerLowLabel}</p>
          </div>
          <div className={styles.month} role="presentation">
            {allMonths.map(month => {
              const yearMonth = calYearMonth(selectedYear, month);

              const upperLowLatest = _.findLast(upperLowSortedInsurances, ([key]) => key < yearMonth);
              const lowerLowLatest = _.findLast(lowerLowSortedInsurances, ([key]) => key < yearMonth);
              let upperLowColorCount = 0;
              let lowerLowColorCount = 0;
              if (upperLowLatest) {
                upperLowColorCount = upperLowColorCounts.findIndex(
                  insurance => insurance && isSame(insurance, upperLowLatest)
                );
              }
              if (lowerLowLatest) {
                lowerLowColorCount = lowerLowColorCounts.findIndex(
                  insurance => insurance && isSame(insurance, lowerLowLatest)
                );
              }
              return (
                <MonthDiv
                  key={month}
                  month={month}
                  onClick={changeMonthHandler}
                  upperLowColorCount={upperLowColorCount}
                  lowerLowColorCount={lowerLowColorCount}
                  selectedMonth={selectedMonth}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.payment}>
        {insuranceType !== 'labourInsurance' && (
          <dl className={styles.isCurrentMonthCollection}>
            <CheckboxFieldWithoutForm
              checked={isCurrentMonthCollection}
              disabled={!healthInsuranceSelf}
              option="当月徴収にする"
              onChange={() => {
                // FormSectionがSocialinsuranceOfficeなので、healthInsuranceを明示的に指定する
                dispatch(formChange(formName, `healthInsurance.isCurrentMonthCollection`, !isCurrentMonthCollection));
              }}
            />
            <div className={styles.isCurrentMonthCollectionHint}>
              <Hint
                text={
                  <div className={styles.exampleWrap}>
                    <p className={styles.exampleText}>
                      チェックを入れない場合は翌月徴収になります。詳しくは
                      <a href="https://jobcan-payroll.zendesk.com/hc/ja/articles/16473099420057">こちら</a>
                      のヘルプページをご確認下さい。
                    </p>
                  </div>
                }
              />
            </div>
          </dl>
        )}
        <dl>
          <dt>給与：</dt>
          <dd>{buildPeriodTextPay()}</dd>
        </dl>
        <dl>
          <dt>賞与：</dt>
          <dd>{buildPeriodTextBonus()}</dd>
        </dl>
      </div>
    </React.Fragment>
  );
};

export default InsurancePeriodSetting;
