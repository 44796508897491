import React from 'react';

import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';

const ImportForm = ({ handleSubmit, submitting, submit, hideModal, isOpen, onSubmit, isOnlyMainOffice }) => {
  const header = 'ジョブカン労務HRから事業所情報を取得';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, onSubmit }}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          {isOnlyMainOffice ? (
            <div>連携項目がすべて上書きされます</div>
          ) : (
            <div>
              連携項目がすべて上書きされます<br />
              本社以外の事業所の項目に関しては連携されません。
            </div>
          )}
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={hideModal} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="取得" {...{ submitting, submit }} />
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default ImportForm;
