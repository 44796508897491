export const getInputTypeName = inputType => {
  switch (inputType) {
    case 'directly':
      return '直接編集済';
    case 'csv_upload':
      return 'CSVアップロード';
    case 'first_insert':
      return '未入力';
    case 'sync_with_jobcan':
      return 'ジョブカン勤怠管理更新';
    case 'unconfirm':
      return '確定解除';
    case 'ext_api':
      return '外部システム連携';
    default:
      return '不明';
  }
};

export default getInputTypeName;
