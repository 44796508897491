import React from 'react';
import Edditing from 'jbc-front/components/icons/Edditing';
import style from './ModifyIcon.scss';

const ModifyIcon = ({ modified, ...rest }) => {
  if (!modified) {
    return null;
  }

  return (
    <span className={style.container}>
      <Edditing {...rest} />
    </span>
  );
};

export default ModifyIcon;
