import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import Revert from 'jbc-front/components/icons/Revert';

const RollbackActionBtn = ({ onClick, label = '手入力前に戻す', ...rest }) => (
  <ActionButton as="button" icon={<Revert size={15} />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default RollbackActionBtn;
