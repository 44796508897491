import React from 'react';
import { FieldArray, FormSection, Field } from 'redux-form';

import { numberWithComma } from 'src/utils/Utils';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { Edditing } from 'jbc-front/components/icons';
import { isModeEdit } from './EmployeeBonusForm';
import { EditableTextField } from './EditableTextField';
import styles from './BonusSection.scss';

const Payments = props => {
  const { fields, mode, totalPaymentAmount } = props;
  return (
    <tbody>
      <tr>
        <th>支給合計</th>
        <td>
          <span className="m-table-obvious-inner">{numberWithComma(totalPaymentAmount)}</span>
        </td>
      </tr>
      {fields.map((item, index) => {
        const payment = fields.get(index);

        return (
          <tr key={payment.id}>
            <th>{payment.name}</th>
            <td>
              <FormSection name={item} key={payment.id}>
                {!isModeEdit(mode) && (
                  <div className="m-table-obvious-inner">
                    <div className="m-table-obvious-modified">
                      {payment.inputType === 'manual' ? (
                        <span className={styles.icon}>
                          <Edditing size={14} />
                          <span className={styles.baloon}>
                            「手入力」「CSVインポート」「外部システム連携」のいずれかにより修正された項目です。<br />
                            自動計算に戻したい場合は、編集画面から「手入力前に戻す」を押してください。
                          </span>
                        </span>
                      ) : null}
                    </div>
                    {numberWithComma(payment.amount)}
                  </div>
                )}
                {isModeEdit(mode) && (
                  <Field
                    name="amount"
                    type="text"
                    component={EditableTextField}
                    validate={isMoneyNumber}
                    disabled={!isModeEdit(mode)}
                    modified={payment.inputType === 'manual'}
                  />
                )}
              </FormSection>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

const BonusSection = ({ mode, totalPaymentAmount }) => (
  <table className="m-table-obvious">
    <thead>
      <tr>
        <th colSpan="2">支給</th>
      </tr>
    </thead>
    <FieldArray
      name="payments"
      component={Payments}
      mode={mode}
      totalPaymentAmount={totalPaymentAmount}
      rerenderOnEveryChange
    />
  </table>
);

export default BonusSection;
