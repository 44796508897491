import React from 'react';
import { USER_LOGIN } from 'src/constants/EndpointUrls';

const InvalidToken = () => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">招待承諾画面</h1>
    </div>
    <div className="l-wrap-xs u-pt100">
      <div className="l-box-message">
        <p>
          このURLは登録が完了しているか、有効期限が過ぎています。<br />
          <a href={USER_LOGIN} className="u-txt-link">
            ログインはこちらから
          </a>
        </p>
      </div>
    </div>
  </div>
);

export default InvalidToken;
