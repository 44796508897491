import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { setPayReportDownloadJob } from 'src/reducers/employees/employeePays';
import { PAY_REPORTS_CREATE_FORM } from 'src/constants/FormNames';
import PayReportsCreateModal from '../components/PayReportsCreateModal';

const PayReportsCreateForm = reduxForm({
  form: PAY_REPORTS_CREATE_FORM
})(PayReportsCreateModal);

export default connect(
  (state, ownProps) => ({
    isOpen: ownProps.isOpen,
    hideModal: ownProps.hideModal
  }),
  (dispatch, ownProps) => ({
    setDownloadJob: () => {
      dispatch(setPayReportDownloadJob(ownProps.payrollRulesGroups, ownProps.paymentDate, ownProps.hideModal));
    }
  })
)(PayReportsCreateForm);
