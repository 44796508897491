import React from 'react';
import _ from 'lodash';

import { Glass, Pulldown, ArrowDoubleNext, ArrowDoublePrev, Close } from 'jbc-front/components/icons';
import styles from './SelectLeftRightItems.scss';

const select = (ids, arr) => ids.map(id => _.find(arr, ['uuid', id]));
const arrayMove = (arr, sub, reverse) => {
  const result = [...arr];
  if (reverse) {
    result.reverse();
  }
  let top = true;
  for (let i = 0; i < result.length; i += 1) {
    if (sub.includes(result[i])) {
      if (!top) {
        const t = result[i - 1];
        result[i - 1] = result[i];
        result[i] = t;
      }
    } else {
      top = false;
    }
  }
  if (reverse) {
    result.reverse();
  }
  return result;
};

const Search = ({ onChange, value }) => (
  <div className={styles.searchWrap}>
    <input
      className={styles.search}
      placeholder="キーワード入力"
      value={value}
      onChange={e => onChange(e.target.value)}
      type="text"
    />
    <span className={styles.searchIcon}>
      <Glass size={16} />
    </span>
    {value && (
      <span className={styles.searchClear} onClick={() => onChange('')} role="button" tabIndex="-1">
        <Close size={16} />
      </span>
    )}
  </div>
);

const Group = ({ groupLabel, onClick }) => (
  <div role="button" tabIndex="-1" className={styles.listTitle} onClick={onClick}>
    {groupLabel}
  </div>
);

const GroupItem = ({ className, field, onClick }) => (
  <div role="button" tabIndex="-1" className={className} onClick={onClick}>
    {field.label}
  </div>
);

class SelectLeftRightItems extends React.Component {
  state = {
    searchLeft: '',
    searchRight: ''
  };

  isSelectingItemLeft = id => this.props.selectingLeftItems.find(fieldId => fieldId === id);
  isSelectedItemLeft = id => this.props.rightItems.find(item => item.uuid === id);
  isNoLeftItemsSelected = () => !this.props.selectingLeftItems || this.props.selectingLeftItems.length === 0;
  isNoRightItemsSelected = () => !this.props.selectingRightItems || this.props.selectingRightItems.length === 0;
  isSelectingItemRight = id => this.props.selectingRightItems.find(fieldId => fieldId === id);

  flattenLeftItems = () => _.flatMap(this.props.leftItems, item => item);
  leftItemsFromIds = fieldIds => this.flattenLeftItems().filter(field => fieldIds.find(id => id === field.uuid));
  formatLeftItemToRightItem = leftItem => ({ ...leftItem });

  selectAllLeftItems = () => {
    const allLeftItemsWithSearch = this.itemsWithSearch(this.flattenLeftItems(), this.state.searchLeft);
    this.props.changeParentState({ selectingLeftItems: allLeftItemsWithSearch.map(field => field.uuid) });
  };
  unselectAllLeftItems = () => {
    const leftItemsWithSearch = this.itemsWithSearch(this.flattenLeftItems(), this.state.searchLeft);
    const remainingLeftItemIds = this.props.selectingLeftItems.filter(
      id => !leftItemsWithSearch.find(item => item.uuid === id)
    );
    this.props.changeParentState({ selectingLeftItems: remainingLeftItemIds });
  };

  selectLeftItem = fieldId =>
    this.props.changeParentState({ selectingLeftItems: [...this.props.selectingLeftItems, fieldId] });
  unselectLeftItem = fieldId =>
    this.props.changeParentState({ selectingLeftItems: this.props.selectingLeftItems.filter(id => id !== fieldId) });

  selectAllItemsInGroup = groupLabel => {
    const groupItems = this.itemsWithSearch(this.props.leftItems[groupLabel], this.state.searchLeft);
    const notSelectedItems = groupItems.filter(item => !this.isSelectedItemLeft(item.uuid));
    this.props.changeParentState({
      selectingLeftItems: _.uniq([...this.props.selectingLeftItems, ...notSelectedItems.map(item => item.uuid)])
    });
  };
  unselectAllItemsInGroup = groupLabel => {
    const groupItems = this.itemsWithSearch(this.props.leftItems[groupLabel], this.state.searchLeft);
    const notInGroupItems = this.props.selectingLeftItems.filter(id => !groupItems.find(field => field.uuid === id));
    this.props.changeParentState({ selectingLeftItems: notInGroupItems });
  };

  addRightItems = rightItems =>
    this.props.changeParentState({
      rightItems: _.uniqBy([...this.props.rightItems, ...rightItems], item => item.uuid)
    });
  setRightItems = rightItems => this.props.changeParentState({ rightItems: [...rightItems] });

  selectRightItem = rightItemId =>
    this.props.changeParentState({ selectingRightItems: [...this.props.selectingRightItems, rightItemId] });
  unselectRightItem = rightItemId =>
    this.props.changeParentState({
      selectingRightItems: this.props.selectingRightItems.filter(item => item !== rightItemId)
    });

  selectAllRightItems = () => {
    const rightItemsWithSearch = this.itemsWithSearch(this.props.rightItems, this.state.searchRight);
    this.props.changeParentState({ selectingRightItems: _.uniq([...rightItemsWithSearch.map(item => item.uuid)]) });
  };
  unselectAllRightItems = () => {
    const rightItemsWithSearch = this.itemsWithSearch(this.props.rightItems, this.state.searchRight);
    const remainingRightItemIds = this.props.selectingRightItems.filter(
      id => !rightItemsWithSearch.find(item => item.uuid === id)
    );
    this.props.changeParentState({ selectingRightItems: remainingRightItemIds });
  };

  notSelectedRightItems = () =>
    this.props.rightItems.filter(item => !this.props.selectingRightItems.find(id => id === item.uuid));
  itemsWithSearch = (items, searchString = '') => {
    const searchStr = searchString || '';
    return items.filter(item => item.label.indexOf(searchStr) >= 0);
  };

  render() {
    const { leftItems, rightItems, selectingRightItems } = this.props;
    const { searchLeft, searchRight } = this.state;
    const rightItemIds = rightItems.map(item => item.uuid);

    return (
      <div className={styles.multiListContainer}>
        <div>
          <div className={styles.title}>選択対象項目</div>
          <div className={styles.multiList}>
            <Search value={searchLeft} onChange={value => this.setState({ searchLeft: value })} />
            <div className={styles.listWithGroup}>
              {_.map(_.keys(leftItems), groupLabel => {
                let fields = leftItems[groupLabel] || [];

                if (searchLeft) {
                  fields = this.itemsWithSearch(fields, searchLeft);
                }

                const notTarget = field => this.isSelectingItemLeft(field.uuid) || this.isSelectedItemLeft(field.uuid);
                const isAllItemsInGroupSelected = fields.every(notTarget);
                let onGroupClick;
                if (isAllItemsInGroupSelected) {
                  onGroupClick = () => this.unselectAllItemsInGroup(groupLabel);
                } else {
                  onGroupClick = () => this.selectAllItemsInGroup(groupLabel);
                }

                return (
                  <React.Fragment key={groupLabel}>
                    <Group key={groupLabel} groupLabel={groupLabel} onClick={onGroupClick} />

                    {fields.map(field => {
                      let className = styles.itemDisabled;
                      let onClick;
                      if (this.isSelectedItemLeft(field.uuid));
                      else {
                        const selecting = this.isSelectingItemLeft(field.uuid);

                        if (selecting) {
                          className = styles.itemSelected;
                          onClick = () => this.unselectLeftItem(field.uuid);
                        } else {
                          className = styles.item;
                          onClick = () => this.selectLeftItem(field.uuid);
                        }
                      }

                      return <GroupItem key={field.uuid} field={field} onClick={onClick} className={className} />;
                    })}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
          <div className={styles.selectButtons}>
            <a className={styles.selectButton} onClick={this.unselectAllLeftItems} role="button" tabIndex="-1">
              選択解除
            </a>
            <a className={styles.selectButton} onClick={this.selectAllLeftItems} role="button" tabIndex="-1">
              全項目選択
            </a>
          </div>
        </div>

        <div className={styles.buttonsBetween}>
          <a
            role="button"
            tabIndex="-1"
            className={styles.buttonBetween}
            onClick={() => {
              if (!this.isNoLeftItemsSelected()) {
                const selectingLeftItemsFromIds = this.leftItemsFromIds(this.props.selectingLeftItems);
                const newRightItems = selectingLeftItemsFromIds.map(this.formatLeftItemToRightItem);
                this.addRightItems(newRightItems);
                this.unselectAllLeftItems();
              }
            }}
          >
            <Pulldown className={styles.selectRight} size={15} />
          </a>
          <a
            role="button"
            tabIndex="-1"
            className={styles.buttonBetween}
            onClick={() => {
              if (!this.isNoRightItemsSelected()) {
                this.setRightItems(this.notSelectedRightItems());
                this.unselectAllRightItems();
              }
            }}
          >
            <Pulldown className={styles.selectLeft} size={15} />
          </a>
        </div>

        <div>
          <div className={styles.title}>選択済み項目</div>
          <div className={styles.multiListWithOrder}>
            <div className={styles.withNote}>
              <div className={styles.multiList}>
                <Search value={searchRight} onChange={value => this.setState({ searchRight: value })} />
                <div className={styles.list}>
                  {this.itemsWithSearch(rightItems, searchRight).map(field => {
                    const selecting = this.isSelectingItemRight(field.uuid);
                    const className = selecting ? styles.itemSelected : styles.item;
                    const onClick = selecting
                      ? () => this.unselectRightItem(field.uuid)
                      : () => this.selectRightItem(field.uuid);

                    return (
                      <GroupItem key={`right${field.uuid}`} className={className} field={field} onClick={onClick} />
                    );
                  })}
                </div>
              </div>
              <div className={styles.selectButtons}>
                <a role="button" tabIndex="-1" className={styles.selectButton} onClick={this.unselectAllRightItems}>
                  選択解除
                </a>
                <a role="button" tabIndex="-1" className={styles.selectButton} onClick={this.selectAllRightItems}>
                  全項目選択
                </a>
              </div>
            </div>
            <div className={styles.buttonsOrder}>
              <div>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    const top = _.intersection(rightItemIds, selectingRightItems);
                    const newRightItems = select([...top, ..._.difference(rightItemIds, top)], rightItems);
                    this.setRightItems(newRightItems);
                  }}
                >
                  <ArrowDoublePrev className={styles.orderTop} size={10} />
                </a>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    const newRightItems = select(arrayMove(rightItemIds, selectingRightItems), rightItems);
                    this.setRightItems(newRightItems);
                  }}
                >
                  <Pulldown className={styles.orderPrev} size={10} />
                </a>
              </div>
              <div>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    const newRightItems = select(arrayMove(rightItemIds, selectingRightItems, true), rightItems);
                    this.setRightItems(newRightItems);
                  }}
                >
                  <Pulldown size={10} />
                </a>
                <a
                  className={searchRight ? styles.orderButtonDisabled : styles.orderButton}
                  role="button"
                  tabIndex="-1"
                  onClick={() => {
                    const bottom = _.intersection(rightItemIds, selectingRightItems);
                    const newRightItems = select([..._.difference(rightItemIds, bottom), ...bottom], rightItems);
                    this.setRightItems(newRightItems);
                  }}
                >
                  <ArrowDoubleNext className={styles.orderBottom} size={10} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SelectLeftRightItems;
