import { createAction } from 'redux-actions';
import _ from 'lodash';

import { DEFAULT_PAGING_PER_PAGE } from 'src/Config';
import { namedPayloadCreator, namedMetaCreator } from 'src/utils/Reducer';

// Actions
export const SET_LIST = 'LIST/SET';
export const SET_EXTRAS = 'LIST/SET_EXTRAS';
export const SET_SELECT_ALL_PAGES = 'LIST/SET_SELECT_ALL_PAGES';

export const FETCH_LIST = 'LIST/FETCH';
export const FETCH_LIST_SUCCESS = 'LIST/FETCH_SUCCESS';
export const FETCH_LIST_SUCCESS_WITHOUT_PAGING_INFO = 'LIST/FETCH_SUCCESS_WITHOUT_PAGING_INFO';
export const FETCH_LIST_FAILURE = 'LIST/FETCH_FAILURE';

export const UPDATE_LIST_FAILURE = 'LIST/UPDATE_FAILURE';
export const UPDATE_ONE = 'LIST/UPDATE_ONE';

export const SET_QUERIES = 'LIST/SET_QUERIES';
export const ADD_QUERY = 'LIST/ADD_QUERY';

// Action Creators
export const setList = createAction(SET_LIST, namedPayloadCreator, namedMetaCreator);
export const setExtras = createAction(SET_EXTRAS, namedPayloadCreator, namedMetaCreator);
export const setSelectAllPages = createAction(SET_SELECT_ALL_PAGES, namedPayloadCreator, namedMetaCreator);

export const fetchList = createAction(FETCH_LIST, namedPayloadCreator, namedMetaCreator);
export const fetchListSuccess = createAction(FETCH_LIST_SUCCESS, namedPayloadCreator, namedMetaCreator);
export const fetchListSuccessWithoutPagingInfo = createAction(
  FETCH_LIST_SUCCESS_WITHOUT_PAGING_INFO,
  namedPayloadCreator,
  namedMetaCreator
);
export const fetchListFailure = createAction(FETCH_LIST_FAILURE, namedPayloadCreator, namedMetaCreator);

export const updateListFailure = createAction(UPDATE_LIST_FAILURE, namedPayloadCreator, namedMetaCreator);
export const updateOne = createAction(UPDATE_ONE, namedPayloadCreator, namedMetaCreator);

export const setQueries = createAction(SET_QUERIES, namedPayloadCreator, namedMetaCreator);
export const addQueryByObject = createAction(ADD_QUERY, namedPayloadCreator, namedMetaCreator);
export const addQuery = (reducerName, key, value) => addQueryByObject(reducerName, { [key]: value });
export const addQueries = (reducerName, values) => addQueryByObject(reducerName, values);
export const addTextQuery = (reducerName, value) => addQueryByObject(reducerName, { query: value });
export const addPageQuery = (reducerName, value) => addQueryByObject(reducerName, { page: +value });
export const addPerPageQuery = (reducerName, value) => addQueryByObject(reducerName, { perPage: +value });

// Selectors
export const getList = (reducerName, state) => state[reducerName].list.data;
export const getExtras = (reducerName, state) => state[reducerName].list.extras;
export const getListQueries = (reducerName, state) => state[reducerName].list.queries;
export const getListPageCount = (reducerName, state) => state[reducerName].list.pageCount;
export const getListTotalCount = (reducerName, state) => state[reducerName].list.totalCount;
export const getListDisplayFrom = (reducerName, state) => state[reducerName].list.displayFrom;
export const getListDisplayTo = (reducerName, state) => state[reducerName].list.displayTo;
export const getListIsConfirmedCount = (reducerName, state) => state[reducerName].list.isConfirmedCount;
export const getListConfirmed = (reducerName, state) => state[reducerName].list.confirmed;
export const getListPage = (reducerName, state) => state[reducerName].list.queries.page;
export const getListPerPage = (reducerName, state) => state[reducerName].list.queries.perPage;
export const getErrors = (reducerName, state) => state[reducerName].list.errors;
export const getSelectAllPages = (reducerName, state) => (state[reducerName].list || {}).isSelectAllPages;
export const getSelectAllPageValue = (reducerName, state) => (state[reducerName].list || {}).selectAllPageValue;
export const getIsLoading = (reducerName, state) => state[reducerName].list.loading;

export const INITIAL_STATE = {
  data: [],
  extras: {},
  errors: [],
  loading: false,
  pageCount: 0,
  totalCount: 0,
  displayFrom: 0,
  displayTo: 0,
  queries: { page: 1, perPage: DEFAULT_PAGING_PER_PAGE },
  isSelectAllPages: false,
  selectAllPageValue: false,
  isConfirmedCount: 0,
  confirmed: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LIST:
      return { ...state, data: action.payload.data, pageCount: action.payload.pageCount };
    case SET_EXTRAS: {
      const currentExtras = { ...state.extras };
      return { ...state, extras: { ...currentExtras, ...action.payload } };
    }
    case SET_SELECT_ALL_PAGES:
      return {
        ...state,
        isSelectAllPages: action.payload.isSelectAllPages,
        selectAllPageValue: action.payload.selectAllPageValue
      };
    case FETCH_LIST:
      return { ...state, errors: [], loading: true };
    case FETCH_LIST_SUCCESS: {
      const {
        totalCount = 0,
        displayFrom = 0,
        displayTo = 0,
        isConfirmedCount = 0,
        confirmed = false
      } = action.payload;
      return {
        ...state,
        data: action.payload.data,
        pageCount: action.payload.pageCount,
        errors: [],
        loading: false,
        totalCount,
        displayFrom,
        displayTo,
        isConfirmedCount,
        confirmed
      };
    }
    case FETCH_LIST_SUCCESS_WITHOUT_PAGING_INFO: {
      return {
        ...state,
        data: action.payload.data,
        errors: [],
        loading: false
      };
    }
    case FETCH_LIST_FAILURE:
      return { ...state, data: [], pageCount: 0, errors: action.payload, loading: false };
    case UPDATE_LIST_FAILURE:
      return { ...state, errors: action.payload, loading: false };
    case UPDATE_ONE:
      return { ...state, data: state.data.map(item => (item.id === action.payload.id ? action.payload.item : item)) };
    case SET_QUERIES: {
      const queries = !_.isEmpty(action.payload) ? action.payload : INITIAL_STATE.queries;
      return { ...state, queries };
    }
    case ADD_QUERY:
      return { ...state, queries: { ...state.queries, ...action.payload } };
    default:
      return state;
  }
};
