import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import { GROUP_CSV_DOWNLOAD_MODAL_FORM } from 'src/constants/FormNames';
import GroupCsvDownloadModalForm from '../components/GroupCsvDownloadModal';

export default compose(
  reduxForm({
    form: GROUP_CSV_DOWNLOAD_MODAL_FORM,
    enableReinitialize: true,
    initialValues: { encoding: 'UTF-8' }
  })
)(GroupCsvDownloadModalForm);
