import React from 'react';
import { reduxForm } from 'redux-form';
import { FormSubmitBtn } from 'src/buttons';
import { BONUS_TRANSFER_TYPES } from 'src/constants/Options';
import { DOWNLOAD_BONUS_FB_FORM } from 'src/constants/FormNames';
import { DateField, RadioField } from 'jbc-front/components/Form';
import FbDataLineFeedRadioButton from 'src/components/FbDataLineFeedRadioButton';
import { ErrorsSection } from 'src/components';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import styles from './DownloadBonusFbModal.scss';

const DownloadBonusInsideForm = ({ handleSubmit, errors, submitting, pristine, submit, hideModal, isOpen }) => (
  <CommonModal ariaHideApp={false} widthAuto {...{ isOpen, hideModal }}>
    <CommonModal.Header hideModal={hideModal}>賞与振込データ出力</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit} className={styles.form}>
        <ErrorsSection errors={errors} />

        <p className="u-mb40">インターネットバンキングを通じて振込業務を行うためのFBデータを出力できます。</p>
        <DateField required name="transferDate" label="振込日" />
        <RadioField required name="transferType" label="種別" options={BONUS_TRANSFER_TYPES} />
        <FbDataLineFeedRadioButton />
        <ul className={styles.notes}>
          <li>※ 口座情報が未登録の従業員は、データ出力できません。</li>
          <li>※ 振込支給金額が０円以下の従業員は、データ出力できません。</li>
        </ul>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="出力" {...{ submitting, pristine, submit }} />
    </CommonModal.Footer>
  </CommonModal>
);

const DownloadBonusForm = reduxForm({
  form: DOWNLOAD_BONUS_FB_FORM
})(DownloadBonusInsideForm);

export default DownloadBonusForm;
