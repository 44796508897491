import { connect } from 'react-redux';
import get from 'lodash/get';
import { setItem } from 'src/reducers/common/itemReducer';
import { setExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME, deleteEmployee } from 'src/reducers/employees/employees';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { fetchEmployeeDetails } from 'src/reducers/fetchSelectOptions';
import ShowEmployee from '../ShowEmployee';

const mapStateToProps = (state, ownProps) => ({
  values: ownProps.values,
  isElectronicDeliveryAgreementSetting: get(state, 'currentClient.item.data.isElectronicDeliveryAgreementSetting')
});

const mapDispatchToProps = dispatch => ({
  setItem: values => {
    dispatch(setItem(REDUCER_NAME, values));
  },
  setExtras: values => {
    dispatch(setExtras(MEMO_REDUCER_NAME, values.memos));
  },
  fetchEmployeeDetails: () => {
    dispatch(fetchEmployeeDetails());
  },
  deleteEmployee: employeeId => {
    dispatch(deleteEmployee(employeeId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ShowEmployee);
