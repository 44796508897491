import React from 'react';
import GroupsButtons from 'src/features/Groups/containers/GroupsButtons';
import GroupsTree from 'src/features/Groups/containers/GroupsTree';

export default class Groups extends React.Component {
  render() {
    return (
      <div>
        <GroupsButtons />
        <GroupsTree />
      </div>
    );
  }
}
