import React from 'react';
import { FormSection } from 'redux-form';
import classNames from 'classnames';

import scrollSpy, { AnchorTarget } from 'jbc-front/components/ScrollSpy';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import Button from 'jbc-front/components/Button';
import { Section } from 'jbc-front/components/Form';

import { CLIENT_SETTINGS, LIST_OFFICES_URL } from 'src/constants/EndpointUrls';
import * as CssClassNames from 'src/constants/CssClassNames';
import FormErrors from 'src/components/FormErrors';

import OfficeManageSection from './edit/OfficeManageSection';
import OfficeSection from '../containers/edit/OfficeSectionContainer';
import SocialInsuranceSection from '../containers/edit/SocialInsuranceSectionContainer';
import LabourInsuranceSection from '../containers/edit/LabourInsuranceSectionContainer';
import styles from './NewOffice.scss';

const NewOffice = scrollSpy(props => {
  const { handleSubmit, submit, errors, pristine, submitting, isInitialized } = props;
  return (
    <div className="l-wrap-xl l-contents-wrap">
      <div className="u-mb20">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="情報登録" />
        </BreadCrumb>
      </div>
      <div className="l-wrap-xl">
        <div className="l-title-wrap">
          <h1 className="m-title-main">事業所情報の入力</h1>
        </div>
      </div>
      <div className="l-flex">
        <div className="l-flex6-main">
          <form
            className={classNames(CssClassNames.FORM, CssClassNames.CLIENT_FORM, styles.form)}
            onSubmit={handleSubmit}
          >
            <FormErrors errors={errors} />

            <React.Fragment>
              <AnchorTarget name="OfficeManageInformation" />
              <Section title="事業所管理情報">
                <FormSection name="office">
                  <OfficeManageSection />
                </FormSection>
              </Section>
            </React.Fragment>

            <AnchorTarget name="OfficeInformation" />
            <Section title="事業所情報">
              <FormSection name="office">
                <OfficeSection />
              </FormSection>
            </Section>

            <AnchorTarget name="SocialInsuranceInformation" />
            <Section title="社会保険情報（健康保険・厚生年金保険）">
              <SocialInsuranceSection isInitialized={isInitialized} />
            </Section>

            <AnchorTarget name="LabourInsuranceInformation" />
            <Section title="労働保険情報（労災保険・雇用保険）">
              <LabourInsuranceSection isInitialized={isInitialized} />
            </Section>

            <div className="u-ta-c u-mt20">
              <Button as="a" href={LIST_OFFICES_URL} className="u-mr20">
                キャンセル
              </Button>
              <Button disabled={pristine || submitting} onClick={submit} primary>
                保存
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default NewOffice;
