import { connect } from 'react-redux';
import { fetchImportCsvJobs } from 'src/reducers/reports/monthlyRevisions';
import { INITIAL_POLLING_INTERVAL } from 'src/constants/Generals';
import ImportCsvJobStatus from '../components/ImportCsvJobStatus';

const mapStateToProps = state => ({
  job: state.JobStatus.importMonthlyRevisionCsvJob
});

const mapDispatchToProps = dispatch => ({
  fetchVisibleImportCsvJobs: () => dispatch(fetchImportCsvJobs(INITIAL_POLLING_INTERVAL))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportCsvJobStatus);
