import React from 'react';
import { FormSection, change as formChange } from 'redux-form';
import { connect } from 'react-redux';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { TextField } from 'jbc-front/components/Form';
import { calEstimatedAnnualEarnings as calAnnualEarnings } from 'jbc-front/utils/calculation';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import selector, { getOptionLabel } from 'src/utils/Utils';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import { getSelectOptions } from 'src/reducers/selectOptions';
import PayrollRuleInfo from './PayrollRuleInfo';

const fetchpayrollRuleInfo = (payrollRulesGroups, clientPayrollRulesGroupId) =>
  payrollRulesGroups.find(p => p.id === clientPayrollRulesGroupId) || {};

const PayrollRuleSection = ({
  payrollRulesGroups,
  baseSalaryNames,
  selectedClientPayrollRulesGroupId,
  onChangeYearlyIncome
}) => (
  <div>
    <FormSection name="employeePayrollRule">
      <ExtensionSelectField
        required
        label="給与規定グループ"
        name="clientPayrollRulesGroupId"
        options={payrollRulesGroups}
      />

      {selectedClientPayrollRulesGroupId && (
        <PayrollRuleInfo payrollRule={fetchpayrollRuleInfo(payrollRulesGroups, selectedClientPayrollRulesGroupId)} />
      )}
      <TextField
        label={getOptionLabel(selectedClientPayrollRulesGroupId)(baseSalaryNames)}
        type="text"
        name="baseSalary"
        validate={isMoneyNumber}
        yen
      />

      <TextField
        label="今年の年間収入見積額"
        name="yearlyIncome"
        yen
        validate={isMoneyNumber}
        onChange={onChangeYearlyIncome}
        note={
          <p>
            「今年の年間収入見積額」を入力すると、「今年の年間所得見積額」に自動で値が入ります。（詳しい計算方法は
            <a
              href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1410.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              国税庁HP
            </a>
            をご覧ください。）
          </p>
        }
      />
      <TextField
        label="今年の年間所得見積額"
        name="yearlyEarning"
        yen
        validate={isMoneyNumber}
        note={
          <p>
            「源泉控除対象配偶者」の判定に使用します。<br />
            従業員本人と生計を一にする配偶者の合計所得金額が95万円以下で、かつ従業員本人の年間所得が900万円以下である事が要件です。<br />
            「源泉控除対象配偶者」に該当すると、毎月天引きする所得税を計算する際に、扶養親族等を1人加算します。<br />
            （配偶者控除についての詳細は
            <a
              href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/shotoku/1191.htm"
              target="_blank"
              rel="noopener noreferrer"
            >
              国税庁HP
            </a>
            をご覧ください。）
          </p>
        }
      />
    </FormSection>
  </div>
);

export default connect(
  state => ({
    payrollRulesGroups: getSelectOptions(state, 'payrollRulesGroups'),
    baseSalaryNames: getSelectOptions(state, 'baseSalaryNames'),
    selectedClientPayrollRulesGroupId: selector(
      getEmployeeFormValues(state),
      'employeePayrollRule',
      'clientPayrollRulesGroupId'
    )
  }),
  dispatch => ({
    onChangeYearlyIncome: (e, value) => {
      const yearlyEarning = calAnnualEarnings(value);
      dispatch(formChange(EMPLOYEE_FORM, 'employeePayrollRule.yearlyEarning', yearlyEarning));
    }
  })
)(PayrollRuleSection);
