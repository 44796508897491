import { connect } from 'react-redux';
import { setPdfJob } from 'src/reducers/reports/notificationOfBaseAmountRevisionAsync';
import { getTargetHealthInsurance } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import PdfCreateModal from '../components/PdfCreateModal';

const mapStateToProps = state => ({
  targetHealthInsurance: getTargetHealthInsurance(state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => {
    dispatch(setPdfJob(ownProps.revisionYear, ownProps.applicableOffice, ownProps.hideModal));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PdfCreateModal);
