import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { change, getFormValues } from 'redux-form';
import { CheckboxField, RadioField, Label } from 'jbc-front/components/Form';
import { getRadioOptions } from 'src/reducers/selectOptions';
import { CLIENT_ALLOWANCE_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME } from 'src/reducers/clients/allowances';
import { getItemExtra, setItem, getItem } from 'src/reducers/common/itemReducer';

import styles from './TargetSettings.scss';

const TargetSettings = props => {
  const { allowanceType } = props;

  const allowanceAmountType = useSelector(state => getFormValues(CLIENT_ALLOWANCE_FORM)(state).allowanceAmountType);
  const useAsSocialInsurances = useSelector(state => getRadioOptions(state, 'useAsSocialInsurances'));
  const formulaOrderType = useSelector(state => getFormValues(CLIENT_ALLOWANCE_FORM)(state).formulaOrderType);
  const useYearEndAdjustment = useSelector(state => getFormValues(CLIENT_ALLOWANCE_FORM)(state).useYearEndAdjustment);
  const clientAllowance = useSelector(state => getItem(REDUCER_NAME, state));
  const isExcludeTotalAmount = clientAllowance.isExcludeTotalAmount;
  const isDefaultDeductionItem = useSelector(
    state => getItemExtra(REDUCER_NAME, state).isDefaultDeductionItem || false
  );

  const dispatch = useDispatch();

  const setExcludeTotalAmountProps = isChecked => {
    if (!isChecked) {
      dispatch(setItem(REDUCER_NAME, { ...clientAllowance, isExcludeTotalAmount: false }));
      return;
    }

    dispatch(change(CLIENT_ALLOWANCE_FORM, 'isTaxableTarget', false));
    dispatch(change(CLIENT_ALLOWANCE_FORM, 'isCompensationTarget', false));
    dispatch(change(CLIENT_ALLOWANCE_FORM, 'isTotalWageIncluded', false));
    dispatch(change(CLIENT_ALLOWANCE_FORM, 'isFixedWage', false));
    dispatch(change(CLIENT_ALLOWANCE_FORM, 'isRetroactive', false));
    dispatch(change(CLIENT_ALLOWANCE_FORM, 'isReduced', false));
    dispatch(change(CLIENT_ALLOWANCE_FORM, 'isInKindPayment', false));
    dispatch(change(CLIENT_ALLOWANCE_FORM, 'useAsSocialInsurance', 'not_cover'));

    dispatch(setItem(REDUCER_NAME, { ...clientAllowance, isExcludeTotalAmount: true }));
  };

  // 「基本給」「通勤手当」以外のとき表示する項目
  const isShownNotForBaseSaralyAndCommuting = formulaOrderType !== 'base_salary' && formulaOrderType !== 'commuting';
  // 「通勤手当」以外のとき表示する項目
  const isShownNotForCommuting = formulaOrderType !== 'commuting';
  // 支給項目
  const isPaymentAllowance = allowanceType === 'payment';
  // 控除項目
  const isDeductionAllowance = allowanceType === 'deduction';
  // 年末調整精算用の控除項目
  const isYearEndAdjustment = isDeductionAllowance && useYearEndAdjustment;

  return (
    <div>
      <Label text="詳細設定" />
      {allowanceAmountType === 'formula' &&
        !isDefaultDeductionItem && (
          <div>
            <CheckboxField
              name="isNegativePermitted"
              label="計算の結果、金額がマイナスになった場合は0円とする"
              normalize={value => !!value}
            />
          </div>
        )}

      {isShownNotForCommuting &&
        !isDefaultDeductionItem && (
          <div>
            <div>
              <CheckboxField
                name="isExcludeTotalAmount"
                label="合計に含めない"
                normalize={value => !!value}
                onChange={e => setExcludeTotalAmountProps(e.target.checked)}
              />
            </div>

            {isExcludeTotalAmount && (
              <div className={styles.child_indent}>
                <CheckboxField
                  name="isDisplayExcludeTotalAmount"
                  label="従業員向けの明細画面やPDFにも表示する"
                  normalize={value => !!value}
                />
              </div>
            )}
          </div>
        )}

      {isPaymentAllowance && (
        <div>
          {formulaOrderType !== 'commuting' ? (
            <div>
              <CheckboxField name="isTaxableTarget" label="所得税の計算対象" normalize={value => !!value} />
            </div>
          ) : (
            <div className="u-mb20">
              <p>※通勤手当の所得税は自動で非課税枠を算出し、「課税通勤手当」「非課税通勤手当」に割り振られます</p>
            </div>
          )}
          <div>
            <CheckboxField name="isCompensationTarget" label="社会保険の計算対象" normalize={value => !!value} />
          </div>
          <div>
            <CheckboxField
              name="isFixedWage"
              label="固定的賃金"
              normalize={value => !!value}
              note={<div>チェックを入れた項目の金額の変動によって、月額変更の対象になるか自動的に判定されます。</div>}
            />
          </div>
          <div>
            <CheckboxField name="isTotalWageIncluded" label="労働保険の計算対象" normalize={value => !!value} />
          </div>
          {isShownNotForBaseSaralyAndCommuting && (
            <React.Fragment>
              <div>
                <CheckboxField
                  name="isRetroactive"
                  label="遡及支払"
                  normalize={value => !!value}
                  note={
                    <div>
                      過去にさかのぼって支給された金額とするかを選択します。設定された項目は、月額変更届・算定基礎届を作成する際に、遡及支払額として集計されます。
                    </div>
                  }
                />
              </div>
              <div>
                <CheckboxField
                  name="isReduced"
                  label="マイナス計算項目"
                  normalize={value => !!value}
                  disabled={isExcludeTotalAmount}
                  note={
                    <div>
                      明細上「支給」項目に表示されますが、支給額からマイナス計算したい場合はチェックを入れてください。（一般的に欠勤控除や遅刻早退控除などはコチラに分類されます。）
                    </div>
                  }
                />
              </div>
            </React.Fragment>
          )}
          <div>
            <CheckboxField name="isInKindPayment" label="現物支給" normalize={value => !!value} />
          </div>
        </div>
      )}

      {isDeductionAllowance &&
        (isYearEndAdjustment || !isDefaultDeductionItem) && (
          <div>
            <div>
              <RadioField
                name="useAsSocialInsurance"
                label="社会保険料として扱う"
                options={useAsSocialInsurances}
                disabled={isYearEndAdjustment}
              />
            </div>
          </div>
        )}

      <div>
        <CheckboxField name="notDisplay0YenOnPayslip" label="0円の場合明細に表示しない" normalize={value => !!value} />
      </div>

      {isShownNotForBaseSaralyAndCommuting &&
        // 年末調整精算用もしくはデフォルトの控除項目以外の場合に表示
        (isYearEndAdjustment || !isDefaultDeductionItem) && (
          <div>
            <CheckboxField
              name="isInDisuse"
              label="この項目を使用しない（非表示設定）"
              normalize={value => !!value}
              note={
                <div>
                  チェックを入れると、以下のように変更されます。
                  <ul className={styles.list}>
                    <li>・毎月の給与明細から表示されなくなります。</li>
                    <li>・支給・控除項目CSVアップロードで取り込めなくなります。</li>
                  </ul>
                </div>
              }
            />
          </div>
        )}
    </div>
  );
};

export default TargetSettings;
