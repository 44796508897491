import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import classNames from 'classnames';

import { EMPLOYEE_PAY_CSV_FORM } from 'src/constants/FormNames';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import { UTF_8 } from 'src/constants/Generals';
import { FileField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import {
  downloadTemplateCsv,
  importEmployeePayCsv,
  getDownloadPayCsvJobs,
  getImportPayCsvJobs,
  getCompletedDownloadPayCsvJob,
  setCompletedDownloadPayCsvJob
} from 'src/reducers/employees/employeePays';
import Download from 'src/icons/Download';
import { formatDateWithHour } from 'src/utils/Date';
import { redirectTo } from 'src/utils/Http';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';

import styles from './EmployeePayDownloadCsvForm.scss';

export class EmployeePayCsvInsideForm extends Component {
  // 最新の完了済み給与csvのjobを取得する
  // 処理中のjobsとすでに完了済みjobを比較し、最も古いjobを返す
  // 完了済みジョブがない場合はnullを返す
  findLatestCompletedPayPayCsvJob(jobInfo, downloadPayCsvJobs, completedJob) {
    if ((_.isEmpty(downloadPayCsvJobs) || _.isEmpty(jobInfo)) && _.isEmpty(completedJob)) {
      return null;
    }
    let successes = _.get(downloadPayCsvJobs, 'success', []);
    successes = _.orderBy(successes, ['id'], ['desc']);
    const job = _.find(successes, o => o.jobInfo === jobInfo && !_.isEmpty(o.downloadLink));
    const completedJobs = _.compact([job, completedJob]);
    return completedJobs.length > 0 ? _.maxBy(completedJobs, j => j.id) : null;
  }

  // 最終更新日時の文字列を取得
  getLastCompletedDate(payCsvJob) {
    if (_.isEmpty(payCsvJob) || _.isEmpty(payCsvJob.endAt)) {
      return null;
    }
    return formatDateWithHour(payCsvJob.endAt);
  }

  downloadCsv(downloadPath) {
    if (_.isEmpty(downloadPath)) {
      return;
    }
    redirectTo(downloadPath);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      submit,
      createCsv,
      downloadPayCsvJobs,
      importPayCsvJobs,
      jobInfo,
      completedDownloadPayCsvJob,
      updateCompletedDownloadPayCsvJob
    } = this.props;
    const latestCompletedJob = this.findLatestCompletedPayPayCsvJob(
      jobInfo,
      downloadPayCsvJobs,
      completedDownloadPayCsvJob
    );
    if (latestCompletedJob) {
      updateCompletedDownloadPayCsvJob(latestCompletedJob);
    }
    const hasDownloadInprogressJobs =
      !_.isEmpty(downloadPayCsvJobs.waiting) || !_.isEmpty(downloadPayCsvJobs.inProgress);
    const hasImportInprogressJobs = !_.isEmpty(importPayCsvJobs.waiting) || !_.isEmpty(importPayCsvJobs.inProgress);
    const disabledCreateBtn = hasDownloadInprogressJobs || hasImportInprogressJobs;
    const disabledDownloadBtn = _.isEmpty(latestCompletedJob);
    const disabledUploadBtn = hasDownloadInprogressJobs || hasImportInprogressJobs;
    const DisabledReason = (
      <ul>
        <li>現在データを作成中です。ダウンロードまでしばらくお待ち下さい。</li>
        <li>作成完了したらメールをお送りするのでそちらからもダウンロードできます。</li>
      </ul>
    );
    const lastCompletedDate = this.getLastCompletedDate(latestCompletedJob);

    return (
      <div className={styles.wrap}>
        <div className={styles.head}>アップロード手順</div>
        <div className={styles.body}>
          <form onSubmit={handleSubmit}>
            <dl className={styles.label}>
              <dt className={styles.number}>1</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>給与データCSVを作成します。</p>
                <p className={styles.caution}>
                  【注意】<br />
                  作成後に給与情報を変更した場合は必ず再度「給与データCSVを作成」を押し、CSVデータを最新にしてください。
                </p>
                <div className="u-mt30">
                  <DownloadCsvEncodingRadioButton
                    name={'encoding'}
                    label={null}
                    options={ENCODING_OPTIONS}
                    required={false}
                  />
                </div>
                <Button
                  onClick={() => createCsv()}
                  widthWide
                  disabled={disabledCreateBtn}
                  disabledReason={DisabledReason}
                >
                  給与データCSVを作成
                </Button>
                {lastCompletedDate && (
                  <p className={classNames('u-mt15', styles.lastUpdate)}>
                    給与データCSV作成最終更新日時：{lastCompletedDate}
                  </p>
                )}
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>2</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>1で作成した給与データCSVファイルをダウンロードしてください。</p>
                <Button
                  onClick={() => {
                    this.downloadCsv(_.get(latestCompletedJob, 'downloadLink', null));
                  }}
                  widthWide
                  disabled={disabledDownloadBtn}
                >
                  <span className={styles.downloadIcon}>
                    <Download size={14} />
                  </span>
                  給与データCSVをダウンロード
                </Button>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>3</dt>
              <dd className={styles.detail}>
                下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したい支給・控除項目の値を入れてください。
                <p className={styles.caution}>
                  各種保険料など、項目を自動計算させたい場合は、その支給・控除項目を空白にするか、列ごと削除してから取り込んでください。<br />
                  値を入力して取り込んだ場合は、自動計算されません。<br />
                  ※取り込み後に自動計算に戻したい場合は、各従業員の給与編集画面から「手入力前に戻す」を押してください。<br />
                  <br />
                  また、アップロードが不要な従業員がいる場合は、該当するデータを行ごと削除してからアップロードすることも可能です。
                </p>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>4</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>完成したファイルを選択し、アップロードして下さい。</p>
                <FileField label="支給・控除項目のデータファイル" name="employee_pay_csv" accepted="csv" required />
              </dd>
            </dl>
            <FormSubmitBtn
              text="アップロード"
              {...{ submitting, pristine, submit }}
              widthWide
              disabled={disabledUploadBtn}
            />
          </form>
        </div>
      </div>
    );
  }
}

const EmployeePayDownloadCsvForm = reduxForm({
  form: EMPLOYEE_PAY_CSV_FORM
})(EmployeePayCsvInsideForm);
const mapStateToProp = (state, ownProps) => ({
  initialValues: { encoding: UTF_8, jobInfo: ownProps.jobInfo },
  downloadPayCsvJobs: getDownloadPayCsvJobs(state) || {},
  importPayCsvJobs: getImportPayCsvJobs(state) || {},
  completedDownloadPayCsvJob: getCompletedDownloadPayCsvJob(state) || null
});
const mapDispatchToProp = dispatch => ({
  onSubmit: data => dispatch(importEmployeePayCsv(data)),
  createCsv: () => dispatch(downloadTemplateCsv()),
  updateCompletedDownloadPayCsvJob: job => {
    dispatch(setCompletedDownloadPayCsvJob(job));
  }
});

export default connect(mapStateToProp, mapDispatchToProp)(EmployeePayDownloadCsvForm);
