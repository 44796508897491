import React from 'react';

import { Section } from 'jbc-front/components/Form';
import { getOptionLabel, numToStr, display, currency } from 'src/utils/Utils';
import tableStyles from '../styles/GeneralTableInfo.scss';

const CommutingByCarShow = ({
  commutingItem,
  commutingDistances,
  vehicleTypes,
  paymentDurationUnits,
  paymentDurationTypes
}) => (
  <div>
    <Section title="車・二輪車等">
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>車両種別：</th>
            <td>{display(getOptionLabel(commutingItem.vehicleType)(vehicleTypes))}</td>
          </tr>
          <tr>
            <th>出発地：</th>
            <td>{display(commutingItem.firstStop)}</td>
          </tr>
          <tr>
            <th>到着地：</th>
            <td>{display(commutingItem.lastStop)}</td>
          </tr>
          <tr>
            <th>片道の通勤距離：</th>
            <td>{display(getOptionLabel(commutingItem.commutingDistance)(commutingDistances))}</td>
          </tr>
          <tr>
            <th>支給単位：</th>
            <td>{display(getOptionLabel(commutingItem.paymentDurationUnit)(paymentDurationUnits))}</td>
          </tr>
          <tr>
            <th>支給月：</th>
            <td>{display(getOptionLabel(commutingItem.paymentDurationType)(paymentDurationTypes))}</td>
          </tr>
          <tr>
            <th>支給額：</th>
            <td>{currency(numToStr(commutingItem.paymentAmount))}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

export default CommutingByCarShow;
