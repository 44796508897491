import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import Button from 'jbc-front/components/Button';
import { checkNotificationOfBaseAmountRevisionsSearchFormHasConditions } from 'src/reducers/searchForm';
import {
  SOCIAL_INSURANCE_NOTIFICATION_SUBMISSION_TYPES,
  SOCIAL_INSURANCE_NOTIFICATION_SORT_TYPES
} from 'src/constants/Options';
import { PAY_REPORTS_CREATE_FORM } from 'src/constants/FormNames';
import CommonModal from 'jbc-front/components/CommonModal';
import { RadioField, DateField } from 'jbc-front/components/Form';
import styles from './PdfCreateModal.scss';

const PayReportsCreateModal = props => {
  const { isOpen, hideModal, submitting, handleSubmit, submit, targetHealthInsurance } = props;
  const insuranceType = () => {
    if (targetHealthInsurance) {
      return ['it', 'other_association'].includes(targetHealthInsurance.insuranceType);
    }
    return false;
  };
  const pdfTargetOptions = [
    { value: 'all_employee', label: '全従業員' },
    { value: 'searched_employee', label: '検索絞込中の従業員' }
  ];

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>算定基礎届PDFダウンロード</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit} className={styles.modalForm}>
          <div className={insuranceType() ? styles.itemBox : styles.none}>
            <p>提出先</p>
            <RadioField name="submitTo" options={SOCIAL_INSURANCE_NOTIFICATION_SUBMISSION_TYPES} />
          </div>
          <div className={styles.itemBox}>
            <p>表示順</p>
            <RadioField name="orderBy" options={SOCIAL_INSURANCE_NOTIFICATION_SORT_TYPES} />
          </div>
          <div className={styles.itemBox}>
            <p>対象選択</p>
            <RadioField name="pdfTarget" options={pdfTargetOptions} />
          </div>
          <div className={styles.note}>
            対象選択：「検索絞込中の従業員」を選んだ場合もステータスによる絞り込み条件は無視されます
          </div>
          <div className={styles.itemBox}>
            <p>提出日</p>
            <div className={styles.dateForm}>
              <DateField name="submitAt" />
            </div>
          </div>
          <div className={styles.buttonBox}>
            <Button onClick={hideModal} className="u-mr20">
              キャンセル
            </Button>
            <Button onClick={() => submit()} primary disabled={submitting}>
              作成する
            </Button>
          </div>
        </form>
      </CommonModal.Body>
    </CommonModal>
  );
};

const mapStateToProps = state => {
  const ignoreQueries = [
    'order',
    'page',
    'per_page',
    'sort',
    'sort_type',
    'filter_type',
    'applicable_office',
    'revision_year'
  ];
  const hasConditions = checkNotificationOfBaseAmountRevisionsSearchFormHasConditions(state, ignoreQueries);

  return {
    initialValues: {
      submitTo: 'pension_office',
      orderBy: 'insured_personnel_number',
      pdfTarget: hasConditions ? 'searched_employee' : 'all_employee'
    }
  };
};

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: PAY_REPORTS_CREATE_FORM,
    enableReinitialize: true
  })
)(PayReportsCreateModal);
