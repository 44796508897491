import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/offices/offices';
import { getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import selector from 'src/utils/Utils';

import LabourConsultantSection from '../../components/show/LabourConsultantSection';

const mapStateToProps = state => ({
  clientLabourConsultant: selector(getItem(REDUCER_NAME, state), 'clientLabourConsultant'),
  submitters: getSelectOptions(state, 'submitters') || [],
  prefectures: getSelectOptions(state, 'prefectures')
});

export default connect(mapStateToProps)(LabourConsultantSection);
