import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { EMPLOYEE_MY_DATA_DOWNLOAD_PAY } from 'src/constants/EndpointUrls';
import SearchReactSelect from 'src/components/SearchReactSelect';

import ActionButton from 'jbc-front/components/ActionButton';
import { Task } from 'jbc-front/components/icons';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { executeIfAgreement } from 'src/reducers/my_data/myData';
import styles from './PayrollActionSection.scss';

class PayrollActionSection extends React.Component {
  state = {
    isPdfPreview: false
  };

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };

  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };

  render() {
    const { selectingPayrollId, allPayrolls, payroll, changeSelectingEmployeePayroll, client } = this.props;

    const showPdfPreview = () => {
      executeIfAgreement(this.showPreview);
    };

    const changeSelecting = value => {
      executeIfAgreement(changeSelectingEmployeePayroll, value);
    };

    return (
      <div>
        <div className={styles.wrap}>
          <div className={styles.action}>
            <SearchReactSelect
              value={selectingPayrollId}
              options={allPayrolls}
              onChange={selected => changeSelecting(selected.value)}
              size="large"
              cache={false}
            />
            <ActionButton primary icon={<Task size={15} />} onClick={showPdfPreview} className={styles.button}>
              PDF出力
            </ActionButton>
            <PreviewPdfModalContainer
              header="給与明細"
              isOpen={this.state.isPdfPreview}
              hideModal={this.hidePreview}
              isMyPage
              url={`${EMPLOYEE_MY_DATA_DOWNLOAD_PAY}?id=${selectingPayrollId}`}
            />
          </div>
          <div className={styles.summary}>
            <div className={styles.title}>{moment(payroll.displayYearmonthOnSlip).format('YYYY年MM月')}給与明細</div>
            <div className={styles.date}>支給日: {moment(payroll.paymentDate).format('YYYY年MM月DD日')}</div>
            <dl className={styles.user}>
              <dt>スタッフコード</dt>
              <dd>: {payroll.staffCode}</dd>
            </dl>
            <dl className={styles.user}>
              <dt>氏名</dt>
              <dd>: {payroll.fullName}</dd>
            </dl>
            <div className={styles.client}>{client.name}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  client: state.currentClient.item.data
}))(PayrollActionSection);
