import { connect } from 'react-redux';
import { autofill } from 'redux-form';

import { getRadioOptions } from 'src/reducers/selectOptions';
import { OFFICE_FORM } from 'src/constants/FormNames';
import { withModal } from 'jbc-front/components/CommonModal';

import BankItemSection from '../../components/edit/BankItemSection';

const mapStateToProps = state => ({
  depositTypes: getRadioOptions(state, 'depositTypes')
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  fillBankParams: (bank, branch) => {
    dispatch(autofill(OFFICE_FORM, `${ownProps.formKey}.bankCode`, bank.bankCode));
    dispatch(autofill(OFFICE_FORM, `${ownProps.formKey}.bankName`, bank.name));
    dispatch(autofill(OFFICE_FORM, `${ownProps.formKey}.branchCode`, branch.branchCode));
    dispatch(autofill(OFFICE_FORM, `${ownProps.formKey}.branchName`, branch.name));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(withModal(BankItemSection));
