import React from 'react';
import { Field } from 'redux-form';
import Hint from 'jbc-front/components/Hint';
import { numberWithComma } from 'src/utils/Utils';
import { isMoneyNumber, isMoneyNumber10, isNumber, isBasicDaysFormat } from 'src/utils/CustomValidators';
import styles from './RevisionItem.scss';
import EditableTextField from '../Util/EditableTextField';
import EditableSelectField from '../Util/EditableSelectField';

const ContentMiddle = props => {
  const {
    revision,
    showMode,
    notifyValueChanges,
    recalculate,
    changeToManualCalcMode,
    displayMonthAmount,
    insuredPersonTypes,
    fetchRevision
  } = props;

  const showInsuredPersonType = monthInsuredPersonType => {
    if (insuredPersonTypes && monthInsuredPersonType) {
      return insuredPersonTypes.find(element => element.value === monthInsuredPersonType).label;
    }
    return '';
  };

  const blankValue = '―　';
  const firstMonthAmount = displayMonthAmount('firstMonthAmount');
  const secondMonthAmount = displayMonthAmount('secondMonthAmount');
  const thirdMonthAmount = displayMonthAmount('thirdMonthAmount');
  // 合計額が3か月とも「－」かどうかの判定
  const isAllHyphen = [firstMonthAmount, secondMonthAmount, thirdMonthAmount].every(item => item === blankValue);

  // 3ヶ月の総計と平均額は編集モードにならないので再計算結果が反映されるformの値をみるかどうかモードによって切り替える
  // 3ヶ月の総計
  const totalAmount = isAllHyphen ? blankValue : numberWithComma(fetchRevision().totalAmount);
  // 平均額
  const amountAverage = isAllHyphen ? blankValue : numberWithComma(fetchRevision().amountAverage);
  // 修正平均額(金額が0ならハイフン表示にする)
  const modifiedAverageAmount =
    revision.modifiedAverageAmount === 0 ? blankValue : numberWithComma(revision.modifiedAverageAmount);

  return (
    <div className={styles.contentMiddle}>
      <div className={styles.boxHeader}>支払基礎日数</div>
      <div className={styles.boxHeader}>被保険者区分</div>
      <div className={styles.boxHeader}>通貨による額</div>
      <div className={styles.boxHeader}>現物による額</div>
      <div className={styles.boxHeader}>合計</div>
      <div className={styles.boxHeader}>遡及支払額</div>
      <div className={styles.boxHeader}>月合計額</div>
      <div className={styles.boxHeader} />
      <div>4月</div>
      <div>
        <Field
          name="firstMonthBasicDays"
          showValue={parseInt(revision.firstMonthBasicDays, 10)}
          textAlign="center"
          showMode={showMode}
          inputWidth={32}
          unit="日"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isNumber, isBasicDaysFormat]}
          component={EditableTextField}
        />
      </div>
      <div>
        <Field
          name="firstMonthInsuredPersonType"
          showValue={showInsuredPersonType(revision.firstMonthInsuredPersonType)}
          showMode={showMode}
          textAlign="center"
          options={insuredPersonTypes}
          width={140}
          component={EditableSelectField}
          addBlankOption={false}
        />
      </div>
      <div className={styles.amount}>
        <Field
          name="firstMonthCurrencyAmount"
          showValue={numberWithComma(revision.firstMonthCurrencyAmount)}
          showMode={showMode}
          inputWidth={70}
          unit="円"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isMoneyNumber]}
          component={EditableTextField}
        />
      </div>
      <div className={styles.amount}>
        <Field
          name="firstMonthGenbutsuAmount"
          showValue={numberWithComma(revision.firstMonthGenbutsuAmount)}
          showMode={showMode}
          inputWidth={70}
          unit="円"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isMoneyNumber]}
          component={EditableTextField}
        />
      </div>
      <div className={styles.amount}>
        (<EditableTextField
          showValue={numberWithComma(revision.firstMonthRetroactiveAmount)}
          showMode="SHOW"
          width={90}
          unit="円"
        />)
      </div>
      <div className={styles.amount}>
        <EditableTextField showValue={firstMonthAmount} showMode="SHOW" unit="円" />
      </div>
      <div>3ヶ月の総計</div>
      <div className={styles.amount}>
        {totalAmount}
        <span className="unit">円</span>
      </div>
      <div>5月</div>
      <div>
        <Field
          name="secondMonthBasicDays"
          showValue={parseInt(revision.secondMonthBasicDays, 10)}
          textAlign="center"
          showMode={showMode}
          inputWidth={32}
          unit="日"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isNumber, isBasicDaysFormat]}
          component={EditableTextField}
        />
      </div>
      <div>
        <Field
          name="secondMonthInsuredPersonType"
          showValue={showInsuredPersonType(revision.secondMonthInsuredPersonType)}
          showMode={showMode}
          textAlign="center"
          options={insuredPersonTypes}
          width={140}
          component={EditableSelectField}
          addBlankOption={false}
        />
      </div>
      <div className={styles.amount}>
        <Field
          name="secondMonthCurrencyAmount"
          showValue={numberWithComma(revision.secondMonthCurrencyAmount)}
          showMode={showMode}
          inputWidth={70}
          unit="円"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isMoneyNumber]}
          component={EditableTextField}
        />
      </div>
      <div className={styles.amount}>
        <Field
          name="secondMonthGenbutsuAmount"
          showValue={numberWithComma(revision.secondMonthGenbutsuAmount)}
          showMode={showMode}
          inputWidth={70}
          unit="円"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isMoneyNumber]}
          component={EditableTextField}
        />
      </div>
      <div className={styles.amount}>
        (<EditableTextField
          showValue={numberWithComma(revision.secondMonthRetroactiveAmount)}
          showMode="SHOW"
          width={90}
          unit="円"
        />)
      </div>
      <div className={styles.amount}>
        <EditableTextField showValue={secondMonthAmount} showMode="SHOW" unit="円" />
      </div>
      <div>平均額</div>
      <div className={styles.amount}>
        {amountAverage}
        <span className="unit">円</span>
      </div>
      <div>6月</div>
      <div>
        <Field
          name="thirdMonthBasicDays"
          showValue={parseInt(revision.thirdMonthBasicDays, 10)}
          textAlign="center"
          showMode={showMode}
          inputWidth={32}
          unit="日"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isNumber, isBasicDaysFormat]}
          component={EditableTextField}
        />
      </div>
      <div>
        <Field
          name="thirdMonthInsuredPersonType"
          showValue={showInsuredPersonType(revision.thirdMonthInsuredPersonType)}
          showMode={showMode}
          textAlign="center"
          options={insuredPersonTypes}
          width={140}
          component={EditableSelectField}
          addBlankOption={false}
        />
      </div>
      <div className={styles.amount}>
        <Field
          name="thirdMonthCurrencyAmount"
          showValue={numberWithComma(revision.thirdMonthCurrencyAmount)}
          showMode={showMode}
          inputWidth={70}
          unit="円"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isMoneyNumber]}
          component={EditableTextField}
        />
      </div>
      <div className={styles.amount}>
        <Field
          name="thirdMonthGenbutsuAmount"
          showValue={numberWithComma(revision.thirdMonthGenbutsuAmount)}
          showMode={showMode}
          inputWidth={70}
          unit="円"
          customOnChange={notifyValueChanges}
          customOnBlur={recalculate}
          validate={[isMoneyNumber]}
          component={EditableTextField}
        />
      </div>
      <div className={styles.amount}>
        (<EditableTextField
          showValue={numberWithComma(revision.thirdMonthRetroactiveAmount)}
          showMode="SHOW"
          width={90}
          unit="円"
        />)
      </div>
      <div className={styles.amount}>
        <EditableTextField showValue={thirdMonthAmount} showMode="SHOW" unit="円" />
      </div>
      <div>
        修正平均額
        <Hint
          width={281}
          text={
            <p>
              修正平均は対象期間より前に払う筈だった給与を、何らかの理由で遡って支払った場合に使用し、実質の平均額に当たります。
              （遡及支払額が入力されている場合は自動計算されます）<br />
              <br />
              新しい標準報酬月額は、修正平均を基に算出されます。
            </p>
          }
        />
      </div>
      <div className={styles.amount}>
        <Field
          name="modifiedAverageAmount"
          showValue={modifiedAverageAmount}
          showMode={showMode}
          inputWidth={70}
          unit="円"
          customOnChange={notifyValueChanges}
          customOnBlur={() => {
            changeToManualCalcMode('modifiedAverageAmount');
            recalculate();
          }}
          validate={[isMoneyNumber10]}
          component={EditableTextField}
        />
      </div>
    </div>
  );
};

export default ContentMiddle;
