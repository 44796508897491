import React from 'react';
import PropTypes from 'prop-types';

const RevisionGrade = props => {
  const { isJoined, grade } = props;
  return isJoined ? (
    <React.Fragment>
      {grade}
      <span className="unit">等級</span>
    </React.Fragment>
  ) : (
    '―'
  );
};

RevisionGrade.propTypes = {
  grade: PropTypes.number,
  isJoined: PropTypes.bool
};

RevisionGrade.defaultProps = {
  grade: 0,
  isJoined: false
};

export default RevisionGrade;
