import React from 'react';
import _ from 'lodash';

import { numberWithComma } from 'src/utils/Utils';

class YearlySummaryEmploymentInsurance extends React.Component {
  constructor(props) {
    super(props);
    const { monthlySummaries } = props;
    this.state = { ...monthlySummaries };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.yearlySummary, prevProps.yearlySummary)) {
      // 年プルダウンを変更したとき合計系のデータが渡されるので計算済みの結果をそのままセットする
      this.setState({
        employmentInsuranceEmployeeCommonCount: this.props.yearlySummary.employmentInsuranceEmployeeCommonCount,
        employmentInsuranceEmployeeCommonPay: this.props.yearlySummary.employmentInsuranceEmployeeCommonPay,
        employmentInsuranceEmployeeTreatedCount: this.props.yearlySummary.employmentInsuranceEmployeeTreatedCount,
        employmentInsuranceEmployeeTreatedPay: this.props.yearlySummary.employmentInsuranceEmployeeTreatedPay,
        totalEmploymentInsuranceEmployeeCount: this.props.yearlySummary.totalEmploymentInsuranceEmployeeCount,
        totalEmploymentInsuranceEmployeePay: this.props.yearlySummary.totalEmploymentInsuranceEmployeePay,
        employmentInsuranceEmployeeAgedCount: this.props.yearlySummary.employmentInsuranceEmployeeAgedCount,
        employmentInsuranceEmployeeAgedPay: this.props.yearlySummary.employmentInsuranceEmployeeAgedPay
      });
    }
  }

  calculation(params) {
    const employmentInsuranceEmployeeCommonCountList = _.map(
      _.filter(params, o => _.isNumber(o.employmentInsuranceEmployeeCommonCount)),
      p => p.employmentInsuranceEmployeeCommonCount
    );
    const employmentInsuranceEmployeeCommonPayList = _.map(
      _.filter(params, o => _.isNumber(o.employmentInsuranceEmployeeCommonPay)),
      p => p.employmentInsuranceEmployeeCommonPay
    );
    const employmentInsuranceEmployeeTreatedCountList = _.map(
      _.filter(params, o => _.isNumber(o.employmentInsuranceEmployeeTreatedCount)),
      p => p.employmentInsuranceEmployeeTreatedCount
    );
    const employmentInsuranceEmployeeTreatedPayList = _.map(
      _.filter(params, o => _.isNumber(o.employmentInsuranceEmployeeTreatedPay)),
      p => p.employmentInsuranceEmployeeTreatedPay
    );
    const totalEmploymentInsuranceEmployeeCountList = _.map(
      _.filter(params, o => _.isNumber(o.totalEmploymentInsuranceEmployeeCount)),
      p => p.totalEmploymentInsuranceEmployeeCount
    );
    const totalEmploymentInsuranceEmployeePayList = _.map(
      _.filter(params, o => _.isNumber(o.totalEmploymentInsuranceEmployeePay)),
      p => p.totalEmploymentInsuranceEmployeePay
    );
    const employmentInsuranceEmployeeAgedCountList = _.map(
      _.filter(params, o => _.isNumber(o.employmentInsuranceEmployeeAgedCount)),
      p => p.employmentInsuranceEmployeeAgedCount
    );
    const employmentInsuranceEmployeeAgedPayList = _.map(
      _.filter(params, o => _.isNumber(o.employmentInsuranceEmployeeAgedPay)),
      p => p.employmentInsuranceEmployeeAgedPay
    );

    const calcValues = {
      employmentInsuranceEmployeeCommonCount: _.reduce(
        employmentInsuranceEmployeeCommonCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      employmentInsuranceEmployeeCommonPay: _.reduce(
        employmentInsuranceEmployeeCommonPayList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      employmentInsuranceEmployeeTreatedCount: _.reduce(
        employmentInsuranceEmployeeTreatedCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      employmentInsuranceEmployeeTreatedPay: _.reduce(
        employmentInsuranceEmployeeTreatedPayList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      totalEmploymentInsuranceEmployeeCount: _.reduce(
        totalEmploymentInsuranceEmployeeCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      totalEmploymentInsuranceEmployeePay: _.reduce(
        totalEmploymentInsuranceEmployeePayList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      employmentInsuranceEmployeeAgedCount: _.reduce(
        employmentInsuranceEmployeeAgedCountList,
        (sum, n) => sum + _.toInteger(n),
        0
      ),
      employmentInsuranceEmployeeAgedPay: _.reduce(
        employmentInsuranceEmployeeAgedPayList,
        (sum, n) => sum + _.toInteger(n),
        0
      )
    };

    this.setState({
      ...params,
      ...calcValues
    });
  }

  tableHeader = text => {
    // 改行で文字列を分割
    const lines = text && text.split('\n');

    return (
      <React.Fragment>
        {lines && lines.length > 1 ? lines.map(line => <div key={`sumAmount${line}`}>{line}</div>) : text}
      </React.Fragment>
    );
  };

  render() {
    const { isLessThan2020 } = this.props;
    return (
      <tr>
        <th>{this.tableHeader(this.props.title)}</th>
        <td>{numberWithComma(this.state.employmentInsuranceEmployeeCommonCount)}</td>
        <td>{numberWithComma(this.state.employmentInsuranceEmployeeCommonPay)}</td>
        <td>{numberWithComma(this.state.employmentInsuranceEmployeeTreatedCount)}</td>
        <td>{numberWithComma(this.state.employmentInsuranceEmployeeTreatedPay)}</td>
        <td>{numberWithComma(this.state.totalEmploymentInsuranceEmployeeCount)}</td>
        <td>{numberWithComma(this.state.totalEmploymentInsuranceEmployeePay)}</td>
        {isLessThan2020 && (
          <React.Fragment>
            <td>{numberWithComma(this.state.employmentInsuranceEmployeeAgedCount)}</td>
            <td>{numberWithComma(this.state.employmentInsuranceEmployeeAgedPay)}</td>
          </React.Fragment>
        )}
      </tr>
    );
  }
}

export default YearlySummaryEmploymentInsurance;
