import React from 'react';
import { useDispatch } from 'react-redux';

import { reduxForm } from 'redux-form';

import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';

import { FormSubmitBtn } from 'src/buttons';
import { editEmployee } from 'src/reducers/flat_tax_reductions/employees';

import styles from './SaveEmployeesModal.scss';

const SaveEmployeesModal = ({ isOpen, hideModal, handleSubmit, submitting, submit, title }) => (
  <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
    <CommonModal.Header hideModal={hideModal}>{title}保存</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        選択したスタッフの{title}を保存します。
        <br />
        保存すると確定処理画面にて月次減税額を確定することができます。
        <br />
        <br />
        <span className={styles.importantWarning}>【注意】</span>
        <br />
        <span className={styles.importantWarning}>
          確定処理画面で確定処理をしている場合、{title}の編集はできません。
          <br />
          確定処理後に{title}を変更したい場合は確定処理を解除してから
          <br />
          編集してください。
        </span>
        <br />
        <br />
        保存しますか？
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <CommonModal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <FormSubmitBtn text="実行" {...{ submitting, submit }} />
      </CommonModal.Buttons>
    </CommonModal.Footer>
  </CommonModal>
);

const BatchDeleteEmployeeForm = reduxForm({
  form: 'BATCH_DELETE_EMPLOYEE_FORM'
})(SaveEmployeesModal);

const SaveEmployeesModalOuter = props => {
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(editEmployee(props, props.url));
  };
  return <BatchDeleteEmployeeForm {...props} {...{ onSubmit }} />;
};

export default SaveEmployeesModalOuter;
