import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import classNames from 'classnames';
import { get } from 'lodash';

import { TextField } from 'jbc-front/components/Form';
import MemoList from 'src/features/Memos';
import { changeEmployeeChecked } from 'src/reducers/flat_tax_reductions/employees';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { numberWithComma, getOptionLabel, getGroupFullPathName } from 'src/utils/Utils';

import styles from 'src/components/EmployeeTable.scss';

const confirmedAlertMessage =
  '確定処理一覧で確定済みの為、保存できません。修正する場合は確定処理一覧で解除してください';

const EmployeeTableRow = ({ index, employee, mode }) => {
  const clientEmploymentTypes = useSelector(state => getSelectOptions(state, 'employmentTypes'));
  const groups = useSelector(state => getSelectOptions(state, 'groups'));
  const positions = useSelector(state => getSelectOptions(state, 'employeePositions'));

  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(changeEmployeeChecked(employee.id));
  };

  const checked = get(employee, 'checked');
  const checkedClass = checked ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
  const disabledClass = ' u-cur-pointer';
  const isConfirmed = employee.isConfirmed;
  return (
    <React.Fragment>
      <tr className={classNames('table-hover', styles.tableRow)}>
        <td
          onClick={e => {
            e.stopPropagation();
            handleClick();
          }}
          className={classNames(
            checkedClass,
            disabledClass,
            { [styles.tooltip]: isConfirmed },
            { [styles.disableCheckBox]: isConfirmed }
          )}
          role="presentation"
        >
          <span className={styles.descriptionTop}>{confirmedAlertMessage}</span>
        </td>
        <td>
          <MemoList employeeId={employee.id} />
        </td>
        <td>{employee.staffCode}</td>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td>{getOptionLabel(employee.employmentTypeId)(clientEmploymentTypes)}</td>
        <td>{!!employee.groupId && <div>{getGroupFullPathName(groups, employee.groupId)}</div>}</td>
        <td>{getOptionLabel(employee.positionId)(positions)}</td>
        {mode === 'employee' && (
          <>
            <td className={isConfirmed ? styles.tooltip : ''}>
              <div className={styles.reduceTargetCount}>
                <TextField
                  name={`employees[${index}].reduceTargetCount`}
                  type="text"
                  maxLength="2"
                  disabled={isConfirmed}
                />
                <span>人</span>
              </div>
              <span className={styles.descriptionTop}>{confirmedAlertMessage}</span>
            </td>
            <td>{employee.lastSavedReduceTargetCountAt ? `${employee.reduceTargetCountDisp}人` : '-'}</td>
          </>
        )}
        {mode === 'amount' && (
          <>
            <td className={isConfirmed ? styles.tooltip : ''}>
              <div className={styles.reduceAmount}>
                <TextField name={`employees[${index}].reduceAmount`} type="text" maxLength="7" disabled={isConfirmed} />
                <span>円</span>
              </div>
              <span className={styles.descriptionTop}>{confirmedAlertMessage}</span>
            </td>
            <td>{employee.lastSavedReduceAmountAt ? `${numberWithComma(employee.reduceAmountDisp)}円` : '-'}</td>
          </>
        )}
      </tr>
    </React.Fragment>
  );
};

export default EmployeeTableRow;
