import React, { useState } from 'react';
import _ from 'lodash';

import ActionButton from 'jbc-front/components/ActionButton';
import { Download } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import * as Urls from 'src/constants/EndpointUrls';

import DownloadCsvModal from '../containers/DownloadCsvModal';
import styles from '../ReportWageLedgerList.scss';
import ExportButton from '../containers/ExportButtonContainer';

const LeftAction = props => {
  const [isCsvModalOpen, setCsvModalOpen] = useState(false);
  const { downloadParams, isCreating, isCreated, uniqueString } = props;

  return (
    <div className={styles.action}>
      <div className={styles.actionRight}>
        <div className={styles.exportList}>
          <Button as="a" href={Urls.WAGE_LEDGER_EXPORT_ITEM_LIST} widthWide className={styles.exportListButton}>
            出力項目
          </Button>
        </div>
        <ExportButton
          searchParams={downloadParams}
          isCreating={isCreating}
          isCreated={isCreated}
          uniqueString={uniqueString}
        />
        <ActionButton
          disabled={_.isEmpty(downloadParams)}
          primary
          onClick={() => setCsvModalOpen(true)}
          icon={<Download size={16} />}
        >
          CSVダウンロード
        </ActionButton>
      </div>
      <DownloadCsvModal
        isOpen={isCsvModalOpen}
        hideModal={() => setCsvModalOpen(false)}
        downloadParams={downloadParams}
      />
    </div>
  );
};
export default LeftAction;
