import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';

import {
  REDUCER_NAME,
  fetchEmployeeAnnounces,
  getEmployeeAnnounces
} from 'src/reducers/employee_announces/employee_announces';
import {
  getListPageCount,
  getListPage,
  getListPerPage,
  addPageQuery,
  addPerPageQuery,
  getListTotalCount,
  getListDisplayFrom,
  getListDisplayTo,
  getIsLoading
} from 'src/reducers/common/listReducer';
import LoadingView from 'src/components/LoadingView';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { LowerPager } from 'src/components';
import ListGroup from 'jbc-front/components/ListGroup';
import { recordDisplay } from 'src/utils/Utils';
import styles from './EmployeeAnnounceList.scss';

const ShowListGroup = ({ employeeAnnounces }) => (
  <ListGroup>
    <ListGroup.Title>管理者からの通知</ListGroup.Title>
    {get(employeeAnnounces, 'length') > 0 ? (
      employeeAnnounces.map(employeeAnnounce => (
        <ListGroup.Item
          className={styles.list}
          key={employeeAnnounce.id}
          {...{ href: `${employeeAnnounce.link}`, target: '_blank', as: 'a' }}
        >
          <span className={styles.date}>{recordDisplay.dateOrTime(employeeAnnounce.announceTime)}</span>
          <div className={styles.title}>{employeeAnnounce.body}</div>
          <ListGroup.Icon />
        </ListGroup.Item>
      ))
    ) : (
      <ListGroup.Item className={styles.empty}>お知らせはまだありません</ListGroup.Item>
    )}
  </ListGroup>
);
class EmployeeAnnounceList extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const {
      onChangePerPage,
      onPageChange,
      pageCount,
      initialPage,
      perPage,
      perPages,
      totalCount,
      displayFrom,
      displayTo,
      employeeAnnounces,
      isListLoading
    } = this.props;
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">管理者からの通知</h1>
        </div>
        <LoadingView isLoading={isListLoading}>
          <div className="l-wrap-s">
            <ShowListGroup employeeAnnounces={employeeAnnounces} />
            <LowerPager
              pageCount={pageCount}
              totalCount={totalCount}
              displayFrom={displayFrom}
              displayTo={displayTo}
              initialPage={initialPage - 1}
              forcePage={initialPage - 1}
              perPage={perPage}
              perPages={perPages}
              onPageChange={e => onPageChange(e.selected)}
              onPerPageChange={e => onChangePerPage(e.target.value)}
            />
          </div>
        </LoadingView>
      </div>
    );
  }
}

export default connect(
  state => ({
    pageCount: getListPageCount(REDUCER_NAME, state),
    totalCount: getListTotalCount(REDUCER_NAME, state),
    displayFrom: getListDisplayFrom(REDUCER_NAME, state),
    displayTo: getListDisplayTo(REDUCER_NAME, state),
    initialPage: getListPage(REDUCER_NAME, state),
    perPage: getListPerPage(REDUCER_NAME, state),
    perPages: getSelectOptions(state, 'perPages'),
    employeeAnnounces: getEmployeeAnnounces(state) || [],
    isListLoading: getIsLoading(REDUCER_NAME, state)
  }),
  dispatch => ({
    fetch: () => {
      dispatch(fetchEmployeeAnnounces());
    },
    onPageChange: query => {
      dispatch(addPageQuery(REDUCER_NAME, query + 1));
      dispatch(fetchEmployeeAnnounces());
    },
    onChangePerPage: query => {
      dispatch(addPageQuery(REDUCER_NAME, 1));
      dispatch(addPerPageQuery(REDUCER_NAME, query));
      dispatch(fetchEmployeeAnnounces());
    }
  })
)(EmployeeAnnounceList);
