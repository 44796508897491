import { connect } from 'react-redux';
import _ from 'lodash';
import { getRadioOptions } from 'src/reducers/selectOptions';
import { REDUCER_NAME } from '../../../reducers/employees/employees';
import { getItem } from '../../../reducers/common/itemReducer';
import selector from '../../../utils/Utils';
import InsuranceSectionShow from '../components/InsuranceSectionShow';

const mapStateToProps = state => ({
  employeeInsurance: selector(getItem(REDUCER_NAME, state), 'employeeInsurance') || {},
  hasTargetHealthInsuranceAssociations: _.get(getItem(REDUCER_NAME, state), 'hasTargetHealthInsuranceAssociations'),
  employeeClassifications: getRadioOptions(state, 'employeeClassifications')
});

export default connect(mapStateToProps)(InsuranceSectionShow);
