import React, { useState } from 'react';
import Button from 'jbc-front/components/Button';
import { INSERT_BATCH_CLIENT_GROUPS_URL, UPDATE_BATCH_CLIENT_GROUPS_URL } from 'src/constants/EndpointUrls';
import Balloon from 'src/features/Employees/components/Balloon';
import GroupCsvDownloadModalContainer from 'src/features/Groups/containers/GroupCsvDownloadModalContainer';
import { GROUP_DISABLED_REASON } from 'src/reducers/clients/groups';
import { Pulldown } from 'jbc-front/components/icons';
import styles from './GroupCsvButtonArea.scss';

const GroupCsvButtonArea = ({ importing }) => {
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isBaloonOpen, setIsBaloonOpen] = useState(false);

  return (
    <div className={styles.buttonArea}>
      <Button widthAuto className="ignore-react-onclickoutside" onClick={() => setIsDownloadModalOpen(true)}>
        グループ情報ダウンロード
      </Button>
      <GroupCsvDownloadModalContainer isOpen={isDownloadModalOpen} hideModal={() => setIsDownloadModalOpen(false)} />

      <div className={styles.button}>
        <Button
          widthAuto
          className="ignore-react-onclickoutside"
          onClick={() => setIsBaloonOpen(true)}
          disabled={importing}
          disabledReason={GROUP_DISABLED_REASON}
        >
          グループ情報一括登録
        </Button>
        {isBaloonOpen &&
          !importing && (
            <Balloon hide={() => setIsBaloonOpen(false)}>
              <ul className={styles.links}>
                <li>
                  <a href={INSERT_BATCH_CLIENT_GROUPS_URL}>
                    <Pulldown size={8} />新規登録（CSVインポート）
                  </a>
                </li>
                <li>
                  <a href={UPDATE_BATCH_CLIENT_GROUPS_URL}>
                    <Pulldown size={8} />更新（CSVインポート）
                  </a>
                </li>
              </ul>
            </Balloon>
          )}
      </div>
    </div>
  );
};

export default GroupCsvButtonArea;
