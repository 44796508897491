import React from 'react';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import { ENCODING_OPTIONS, REVISION_FILTER_TYPES_IMPORT } from 'src/constants/Options';
import { IMPORT_REVISIONS_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { Download, Upload } from 'jbc-front/components/icons';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import Button from 'jbc-front/components/Button';
import {
  NOTIFICATION_OF_BASE_AMOUNT_REVISION_EXPORT,
  NOTIFICATION_OF_BASE_AMOUNT_REVISION_LIST
} from 'src/constants/EndpointUrls';
import { RadioField, FileField } from 'jbc-front/components/Form';

import styles from './ImportRevisions.scss';
import FileImportDescription from './FileImportDescription';
import ImportCsvJobStatus from '../containers/ImportCsvJobStatusContainer';

const disabledReason = '処理中です。完了までしばらくお待ちください。';
const ImportRevisions = ({
  submitting,
  handleSubmit,
  selectedEncoding,
  selectedTarget,
  errors,
  revisionYear,
  applicableOffice,
  dispRevisionYear,
  importing
}) => (
  <div>
    <div className="l-wrap-xs l-contents-wrap">
      <BreadCrumb>
        <BreadCrumb.Item label="算定基礎届" path={NOTIFICATION_OF_BASE_AMOUNT_REVISION_LIST} />
        <BreadCrumb.Item label="算定基礎届の更新" />
      </BreadCrumb>
    </div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">算定基礎届の更新</h1>
    </div>
    <ImportCsvJobStatus />
    <div className={classNames('l-wrap-xs')}>
      <ErrorsSection errors={errors} />
      <div className={styles.period}>
        <b>提出年：{dispRevisionYear}</b>
      </div>
    </div>
    <div className={styles.wrap}>
      <div className={styles.head}>アップロード手順</div>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <dl className={styles.label}>
            <dt className={styles.number}>1</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>算定基礎届データCSVファイルをダウンロードしてください。</p>
              <b>ファイル形式を選択</b>
              <div className="u-mt30">
                <DownloadCsvEncodingRadioButton
                  name={'encoding'}
                  label={null}
                  options={ENCODING_OPTIONS}
                  required={false}
                />
              </div>
              <b>ダウンロード対象選択</b>
              <div className="u-mt30">
                <RadioField name={'target'} label={null} options={REVISION_FILTER_TYPES_IMPORT} required={false} />
              </div>
              <Button
                widthWide
                icon={<Download size={16} />}
                onClick={() =>
                  redirectTo(
                    `${NOTIFICATION_OF_BASE_AMOUNT_REVISION_EXPORT}?${serializeHttpGetParams({
                      revisionYear,
                      applicableOffice,
                      encoding: selectedEncoding,
                      target: selectedTarget
                    })}`
                  )
                }
              >
                算定基礎届データCSVをダウンロード
              </Button>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>2</dt>
            <dd className={styles.detail}>
              下の説明を参考にしながら、ダウンロードしたCSVファイルの内容を変更したい値に修正してください。
              <div className={styles.warning}>
                <ul className={styles.warningList}>
                  <li>
                    <span className={styles.requiredText}>
                      <b>各項目の更新結果により定時決定の対象、対象外の判定が変化します。</b>
                    </span>
                    <br />
                    <p>
                      ※取り込み後に自動計算結果に戻したい場合は、各従業員の算定基礎届の情報編集画面から「手入力前に戻す」を押してください。
                    </p>
                    <br />
                    <p>
                      また、データ更新が不要な従業員がいる場合は、該当従業員の行を削除してからアップロードすることも可能です。
                    </p>
                  </li>
                </ul>
              </div>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>3</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
              <FileField
                label="算定基礎届項目のデータファイル"
                name="revisions_csv"
                className={styles.input}
                required
              />
            </dd>
          </dl>
          <Button
            as="button"
            primary
            type="submit"
            disabled={submitting || importing}
            disabledReason={disabledReason}
            icon={<Upload size={16} />}
            widthWide
          >
            アップロード
          </Button>
        </form>
      </div>
    </div>
    <div className={styles.description}>
      <FileImportDescription />
    </div>
  </div>
);

const ImportRevisionsForm = reduxForm({
  form: IMPORT_REVISIONS_FORM,
  enableReinitialize: true
})(ImportRevisions);

export default ImportRevisionsForm;
