import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';
import moment from 'moment';
import { INHABITANT_TAX_FB_DATA_FORM } from 'src/constants/FormNames';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  downloadInhabitantTaxFBData,
  getDownloadInhabitantTaxFBDataErrors,
  clearDownloadInhabitantTaxFBDataErrors,
  assignBankInfo
} from 'src/reducers/employees/employeePays';
import InhabitantTaxFBDataModal from '../components/InhabitantTaxFBDataModal';

const mapStateToProps = (state, ownProps) => {
  const { formValues, targetPeriod, payrollGroupSelectorType } = ownProps;
  const clientBanks = getSelectOptions(state, 'clientBanks');
  let initialValues = {
    payForRetirement: 0,
    outputOrdinanceDesignatedCity: clientBanks[0].outputOrdinanceDesignatedCity,
    paymentOnMonth: moment(targetPeriod.planPaymentDate).format('YYYY/MM'),
    paymentDueDate: moment(targetPeriod.dueDate).format('YYYY/MM/DD'),
    line_feed: clientBanks[0].lineFeed,
    end_of_file_line_feed: clientBanks[0].endOfFileLineFeed
  };
  // 支払口座
  if (!_.isEmpty(clientBanks)) {
    initialValues.transferFromBankId = clientBanks[0].value;
  }

  // 一覧で選択されているセレクトボックスの値
  let extraValues = {};
  let selectedLabel = null;
  if (payrollGroupSelectorType === 'all') {
    const groupedPaymentMonths = getSelectOptions(state, 'groupedPaymentMonths');
    extraValues = _.pick(formValues, 'groupedPaymentMonths');
    selectedLabel = _.find(groupedPaymentMonths, pm => pm.value === formValues.groupedPaymentMonths).label;
  } else {
    const uniquePaymentDates = getSelectOptions(state, 'uniquePaymentDates');
    extraValues = _.pick(formValues, 'uniquePayrollRuleGroups', 'uniquePaymentDate');
    selectedLabel = _.find(uniquePaymentDates, pd => pd.value === formValues.uniquePaymentDate).label.concat('分');
  }
  initialValues = Object.assign(initialValues, extraValues);

  const modalFormValues = getFormValues(INHABITANT_TAX_FB_DATA_FORM)(state);
  return {
    errors: getDownloadInhabitantTaxFBDataErrors(state),
    payForRetirement: _.isUndefined(modalFormValues) ? 0 : modalFormValues.payForRetirement,
    payrollGroupSelectorType,
    initialValues,
    selectedLabel,
    clientBanks,
    isUseLineFeed: _.isUndefined(modalFormValues) ? false : modalFormValues.line_feed !== 'none'
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onModalHide: () => dispatch(clearDownloadInhabitantTaxFBDataErrors()),
  onSubmit: data => dispatch(downloadInhabitantTaxFBData(data, ownProps.payrollGroupSelectorType)),
  onBankChange: bankId => dispatch(assignBankInfo(bankId))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: INHABITANT_TAX_FB_DATA_FORM
  })
)(InhabitantTaxFBDataModal);
