import React from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { useSelector } from 'react-redux';
import { TextField } from 'jbc-front/components/Form';
import isEmpty from 'lodash/isEmpty';
import { OFFICE_STATION_MODAL_FORM } from 'src/constants/FormNames';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import styles from './OfficeStationModal.scss';

const renderField = field => (
  <div>
    {/* パスワードマネージャ用の対応 参考：https://yuzu441.hateblo.jp/entry/2020/11/16/190229 */}
    <input name="loginId" defaultValue={field.loginId} style={{ display: 'none' }} autoComplete="username" />
    <input {...field.input} type="password" name="password" autoComplete="new-password" />
  </div>
);

const OfficeStationModal = ({
  isOpen,
  hideModal,
  handleSubmit,
  submit,
  submitting,
  pristine,
  clientOfficeStationSetting,
  header,
  components,
  buttonName
}) => {
  const formValues = useSelector(state => getFormValues(OFFICE_STATION_MODAL_FORM)(state));
  const isInputPassword = isEmpty(formValues?.password);

  const stationId = clientOfficeStationSetting?.stationId;
  const officeName = clientOfficeStationSetting?.officeName;
  const loginId = clientOfficeStationSetting?.loginId;

  return (
    <CommonModal isOpen={isOpen} hideModal={hideModal} ariaHideApp={false} closeOnClick>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          <div className={styles.flex}>
            <span className={styles.labelNoInput}>StationID</span>
            <div className={styles.inputItem}>
              <p>{stationId}</p>
            </div>
          </div>
          <div className={styles.flex}>
            <span className={styles.labelNoInput}>会社名 / 屋号</span>
            <div className={styles.inputItem}>
              <p>{officeName}</p>
            </div>
          </div>
          <div className={styles.flex}>
            <span className={styles.labelNoInput}>ログインID</span>
            <div className={styles.inputItem}>
              <p>{loginId}</p>
            </div>
          </div>
          <div className={styles.flex}>
            <span className={styles.label}>パスワード</span>
            <div className={styles.inputItem}>
              <TextField name="password" noLabel type="password" loginId={loginId} component={renderField} />
            </div>
          </div>
          {components}
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <div>
          <Button onClick={hideModal} className="u-mr20">
            キャンセル
          </Button>
          <Button
            primary
            onClick={() => {
              submit();
              hideModal();
            }}
            disabled={submitting || pristine || isInputPassword}
          >
            {buttonName}
          </Button>
        </div>
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default reduxForm({
  form: OFFICE_STATION_MODAL_FORM,
  enableReinitialize: true
})(OfficeStationModal);
