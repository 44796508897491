import React from 'react';
import { connect } from 'react-redux';

import { setList } from 'src/reducers/common/listReducer';
import { REDUCER_NAME, delRole } from 'src/reducers/clients/roles';

import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';

const RolesList = ({ roles, setRoleList, onDelete }) => {
  setRoleList(roles);
  return (
    <div>
      <a href={Urls.CLIENT_ROLES_NEW}>新規作成</a>
      <ul>
        {roles.map(role => (
          <li key={role.id}>
            <a href={concatParamsToUrl(Urls.CLIENT_ROLES_EDIT, { id: role.id })}>{role.name}</a>
            <a href={concatParamsToUrl(Urls.CLIENT_ROLES_COPY, { id: role.id })}>[[コピー]]</a>
            {role.isDeletable && (
              <a
                href={concatParamsToUrl(Urls.CLIENT_ROLES_DELETE, { id: role.id })}
                onClick={e => {
                  e.preventDefault();
                  onDelete(role.id);
                }}
              >
                [[削除]]
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default connect(null, dispatch => ({
  setRoleList: roles => {
    dispatch(setList(REDUCER_NAME, roles));
  },
  onDelete: roleId => {
    dispatch(delRole(roleId));
  }
}))(RolesList);
