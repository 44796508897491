import { combineReducers } from 'redux';
import axios from 'axios';
import humps from 'humps';
import { getFormValues, getFormSyncErrors, change as formChange, formValueSelector } from 'redux-form';

import { createAction } from 'redux-actions';
import _ from 'lodash';
import qs from 'qs';

import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { editItem, editItemFailure, getItemErrors, setItemError, newItem } from 'src/reducers/common/itemReducer';
import {
  fetchList,
  fetchListSuccess,
  fetchListFailure,
  updateOne,
  getExtras,
  setExtras,
  getIsLoading
} from 'src/reducers/common/listReducer';
import { setGlobalSuccesses, setGlobalErrors } from 'src/reducers/global';
import { paramsWithFile } from 'src/utils/Form';
import { fetchLatestJobStatus, isJobIncomplete } from 'src/reducers/JobStatus';
import { MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import {
  fetchNotificationOfBaseAmountMonthOptions,
  FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH,
  takeNotificationOfBaseAmountRevisionsSearchQueries
} from '../searchForm';

export const REDUCER_NAME = 'notificationOfBaseAmountRevisions';

export const UI_SET = 'REVISIONS_UI/SET';
export const UI_CHANGE_SHOW_MODE = 'REVISIONS_UI/CHANGE_SHOW_MODE';
export const UI_CHANGE_CALC_MODE = 'REVISIONS_UI/UI_CHANGE_CALC_MODE';
export const UI_ERRORS_ON_SAVE = 'REVISIONS_UI/UI_ERRORS_ON_SAVE';
export const UI_NOTIFY_VALUE_CHANGES = 'REVISIONS_UI/UI_NOTIFY_VALUE_CHANGES';
export const UI_RESET_VALUE_CHANGES = 'REVISIONS_UI/UI_RESET_VALUE_CHANGES';

export const uiSet = createAction(UI_SET);
export const uiChangeShowMode = createAction(UI_CHANGE_SHOW_MODE);
export const uiChangeCalcMode = createAction(UI_CHANGE_CALC_MODE);
export const uiSetErrorsOnSave = createAction(UI_ERRORS_ON_SAVE);
export const uiNotifyValueChanges = createAction(UI_NOTIFY_VALUE_CHANGES);
export const uiResetValueChanges = createAction(UI_RESET_VALUE_CHANGES);
export const INIT_CALC_MODE = {
  modifiedAverageAmount: 'AUTO',
  changedAmount: 'AUTO',
  retroactiveAmount: 'AUTO',
  isShortTimeWorker: 'AUTO',
  isPartTimer: 'AUTO'
};

export const getUiShowMode = (state, revision) => _.get(state, `monthlyRevisions.ui.revisions.${revision.id}.showMode`);
export const getUiCalcMode = (state, revision) =>
  _.get(state, `notificationOfBaseAmountRevisions.ui.revisions.${revision.id}.calcMode`);
export const getErrorsOnSave = (state, revision) =>
  _.get(state, `notificationOfBaseAmountRevisions.ui.revisions.${revision.id}.errors`);
export const getReadyForRecalculating = (state, revision) =>
  _.get(state, `monthlyRevisions.ui.revisions.${revision.id}.readyForRecalculating`);
export const getChangedYearMonths = state => _.get(state, 'monthlyRevisions.ui.changedYearMonths');
export const getLastRevisionYearMonths = state => _.get(getExtras(REDUCER_NAME, state), 'lastRevisionYearMonths');
export const getUnconfirmedAlertCount = state => _.get(getExtras(REDUCER_NAME, state), 'unconfirmedAlertCount');
export const getTargetHealthInsurance = state => _.get(getExtras(REDUCER_NAME, state), 'targetHealthInsurance');
export const getLoadingRevisions = state => getIsLoading(REDUCER_NAME, state) || false;
export const getRevisionErrors = state => getItemErrors(REDUCER_NAME, state) || [{}];

export const transformRevisionsToFormValues = (reducerName, state) =>
  state[reducerName].list.data.reduce(
    (pre, revision) => ({ revisions: { ...pre.revisions, [revision.id]: revision } }),
    { revisions: {} }
  );

export const getConfirmRevisionErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return (errors[0] || {}).changeNotificationOfBaseAmountErrors || [];
};

// Async Action Creators
export const fetchRevisions = queries => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));

    const response = await axios.get(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_SEARCH, {
      params: humps.decamelizeKeys(queries)
    });
    const payload = {
      data: response.data.payload.revisions,
      applicableRevisions: response.data.payload.applicableRevisions,
      pageCount: response.data.payload.totalPages,
      totalCount: response.data.payload.totalCount,
      targetStartDate: response.data.payload.targetStartDate,
      targetEndDate: response.data.payload.targetEndDate
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(REDUCER_NAME, { changeStandardMonthlyDate: response.data.payload.changeStandardMonthlyDate }));
    dispatch(setExtras(REDUCER_NAME, { targetPayDate: response.data.payload.targetPayDate }));
    dispatch(setExtras(REDUCER_NAME, { targetCount: response.data.payload.targetCount }));
    dispatch(setExtras(REDUCER_NAME, { unreflectedTargetCount: response.data.payload.unreflectedTargetCount }));
    dispatch(setExtras(REDUCER_NAME, { lastRevisionYearMonths: response.data.payload.lastRevisionYearMonths }));
    dispatch(setExtras(REDUCER_NAME, { unconfirmedAlertCount: response.data.payload.unconfirmedAlertCount }));
    dispatch(setExtras(REDUCER_NAME, { applicableRevisions: response.data.payload.applicableRevisions }));
    dispatch(setExtras(REDUCER_NAME, { targetHealthInsurance: response.data.payload.targetHealthInsurance }));
    dispatch(
      uiSet({ revisions: response.data.payload.revisions, changedYearMonths: response.data.payload.changedYearMonths })
    );
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editRevision = (revisionId, applicableOffice) => async (dispatch, getState) => {
  try {
    const state = getState();
    const revision = _.get(getFormValues('revisions')(state), `revisions.${revisionId}`);

    // エラーがあるならスキップ
    if (_.get(getFormSyncErrors('revisions')(state), `revisions.${revisionId}`)) {
      return;
    }

    // 被保険者区分がnullの時、編集画面上では「一般の被保険者」が表示されているが、
    // 実際にセレクトボックスを選択しないとnullのままリクエストが送信され、データが更新されないのでここで設定する
    const defaultInsuredPersonType = 'general';
    if (!revision.firstMonthInsuredPersonType) {
      revision.firstMonthInsuredPersonType = defaultInsuredPersonType;
    }

    if (!revision.secondMonthInsuredPersonType) {
      revision.secondMonthInsuredPersonType = defaultInsuredPersonType;
    }

    if (!revision.thirdMonthInsuredPersonType) {
      revision.thirdMonthInsuredPersonType = defaultInsuredPersonType;
    }

    const httpBody = { revision: { ...revision, applicableOffice } };

    const updatePayUrl = concatParamsToUrl(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_UPDATE, { id: revision.id });
    const response = await axios.put(updatePayUrl, httpBody);

    dispatch(formChange('revisions', `revisions.${revisionId}`, response.data.payload.revision));
    dispatch(updateOne(REDUCER_NAME, { id: revisionId, item: response.data.payload.revision }));
    dispatch(uiChangeShowMode({ revision: response.data.payload.revision, showMode: 'SHOW' }));
    dispatch(uiSetErrorsOnSave({ revision: response.data.payload.revision, errors: {} }));

    _.forIn(INIT_CALC_MODE, (value, name) => {
      dispatch(uiChangeCalcMode({ revision, name, calcMode: 'AUTO' }));
    });
    dispatch(uiResetValueChanges({ revision }));
    dispatch(setExtras(REDUCER_NAME, { unconfirmedAlertCount: response.data.payload.unconfirmedAlertCount }));

    // 保存後に再検索してstateを新しい状態にする
    const formValues = getFormValues(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(state);
    dispatch(fetchRevisions(takeNotificationOfBaseAmountRevisionsSearchQueries(formValues)));
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const recalculateRevision = (revisionId, applicableOffice) => async (dispatch, getState) => {
  try {
    const state = getState();
    const revision = _.get(getFormValues('revisions')(state), `revisions.${revisionId}`);

    // エラーがあるならスキップ
    if (_.get(getFormSyncErrors('revisions')(state), `revisions.${revisionId}`)) {
      return;
    }

    if (!getReadyForRecalculating(state, revision)) {
      return;
    }

    // modifiedAverageAmount は変更されていなければ送信しない。
    if (getUiCalcMode(state, revision).modifiedAverageAmount === 'AUTO') {
      delete revision.modifiedAverageAmount;
    }
    if (getUiCalcMode(state, revision).isShortTimeWorker === 'AUTO') {
      delete revision.isShortTimeWorker;
    }
    if (getUiCalcMode(state, revision).isPartTimer === 'AUTO') {
      delete revision.isPartTimer;
    }

    const httpBody = { revisionYear: revision.revisionYear, applicableOffice, revision };

    const updatePayUrl = concatParamsToUrl(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_RECALCULATE, { id: revision.id });
    const response = await axios.post(updatePayUrl, httpBody);

    dispatch(formChange('revisions', `revisions.${revisionId}`, response.data.payload.revision));

    dispatch(uiResetValueChanges({ revision }));
  } catch (exception) {
    let msg = ['自動計算に失敗しました'];
    if (!_.isEmpty(exception?.response?.data?.errors)) {
      msg = exception.response.data.errors.messages;
    }
    dispatch(editItemFailure(REDUCER_NAME, msg));
  }
};

export const resetRevision = (revisionId, applicableOffice) => async (dispatch, getState) => {
  try {
    const state = getState();
    const revision = _.get(getFormValues('revisions')(state), `revisions.${revisionId}`);

    const updatePayUrl = concatParamsToUrl(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_RESET, {
      id: revisionId
    });

    const httpBody = { revisionYear: revision.revisionYear, applicableOffice };

    const response = await axios.post(updatePayUrl, httpBody);

    dispatch(formChange('revisions', `revisions.${revisionId}`, response.data.payload.revision));

    _.forIn(INIT_CALC_MODE, (value, name) => {
      dispatch(uiChangeCalcMode({ revision, name, calcMode: 'AUTO' }));
    });
    dispatch(uiResetValueChanges({ revision }));
    dispatch(setExtras(REDUCER_NAME, { unconfirmedAlertCount: response.data.payload.unconfirmedAlertCount }));
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const changeSubjectRevision = revisionId => async (dispatch, getState) => {
  try {
    const state = getState();
    const revision = _.get(getFormValues('revisions')(state), `revisions.${revisionId}`);

    const toggleSubjectUrl = concatParamsToUrl(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_TOGGLE_SUBJECT, {
      id: revisionId
    });

    const httpBody = {
      healthInsuranceForceSubjectType: revision.healthInsuranceForceSubjectType,
      pensionInsuranceForceSubjectType: revision.pensionInsuranceForceSubjectType
    };

    await axios.post(toggleSubjectUrl, httpBody);

    window.location.reload();
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const changeNotificationOfBaseAmountConfirm = (revisionYear, applicableOffice, callBack) => async (
  dispatch,
  getState
) => {
  const revisionIds = [];
  const revisions = getFormValues('revisions')(getState()).revisions;
  _.forEach(revisions, revision => {
    if (revision.displaySubjectFlag) revisionIds.push(revision.id);
  });

  try {
    const httpBody = humps.decamelizeKeys({ revisionYear, applicableOffice, revisionIds });
    const response = await axios.put(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_CONFIRM, httpBody);
    if (response.status === 200) {
      dispatch(setGlobalSuccesses('標準報酬月額を改定しました'));
    } else {
      dispatch(setGlobalErrors('標準報酬月額の改定処理が失敗しました'));
    }

    const formValues = getFormValues(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(getState());
    dispatch(fetchRevisions(takeNotificationOfBaseAmountRevisionsSearchQueries(formValues)));
    await dispatch(fetchNotificationOfBaseAmountMonthOptions());
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  } finally {
    callBack();
  }
};

export const downloadCsv = (data, searchKey, callBack) => async (dispatch, getState) => {
  try {
    const insuranceType = getTargetHealthInsurance(getState()).insuranceType;

    dispatch(editItem(REDUCER_NAME));
    if (data.csvTarget === 'searched_employee') {
      let searchParams = formValueSelector(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(
        getState(),
        'q',
        'offices',
        'groups',
        'employmentTypes',
        'occupations',
        'positions',
        'payrollRuleGroup',
        'memoColors',
        'noMemo'
      );
      searchParams = takeNotificationOfBaseAmountRevisionsSearchQueries(searchParams);
      Object.assign(searchKey, searchParams);
    }
    const params = {
      payment_for: ['it', 'other_association'].includes(insuranceType) ? data.paymentFor : 'no_classification',
      serial_number: data.serialNumber,
      submit_at: data.submitAt,
      ...searchKey
    };
    const query = qs.stringify(humps.decamelizeKeys(params), { arrayFormat: 'brackets' });
    const url = `${Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_CSV}?${query}`;
    // csv作成時のエラーをキャッチするために一度axiosのgetを実行している
    await axios.get(url);
    redirectTo(url);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  } finally {
    callBack();
  }
};

const uiReducer = (state = { revisions: {}, changedYearMonths: [] }, action) => {
  switch (action.type) {
    case UI_SET:
      return {
        changedYear: action.payload.changedYear,
        revisions: action.payload.revisions.reduce(
          (pre, revision) => ({
            ...pre,
            [revision.id]: { showMode: 'SHOW', calcMode: { ...INIT_CALC_MODE }, readyForRecalculating: false }
          }),
          {}
        )
      };
    case UI_CHANGE_SHOW_MODE:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: {
            ...state.revisions[action.payload.revision.id],
            showMode: action.payload.showMode
          }
        }
      };
    case UI_CHANGE_CALC_MODE:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: {
            ...state.revisions[action.payload.revision.id],
            calcMode: {
              ...state.revisions[action.payload.revision.id].calcMode,
              [action.payload.name]: action.payload.calcMode
            }
          }
        }
      };
    case UI_ERRORS_ON_SAVE:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: {
            ...state.revisions[action.payload.revision.id],
            errors: action.payload.errors
          }
        }
      };
    case UI_NOTIFY_VALUE_CHANGES:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: { ...state.revisions[action.payload.revision.id], readyForRecalculating: true }
        }
      };
    case UI_RESET_VALUE_CHANGES:
      return {
        ...state,
        revisions: {
          ...state.revisions,
          [action.payload.revision.id]: { ...state.revisions[action.payload.revision.id], readyForRecalculating: false }
        }
      };
    default:
      return state;
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME),
  ui: uiReducer
});

export const uploadCsv = (data = 'create') => async dispatch => {
  try {
    dispatch(newItem(REDUCER_NAME));
    const params = paramsWithFile(data, ['revisions_csv']);
    await axios.post(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_IMPORT, params);
    dispatch(setGlobalSuccesses('ファイルを受け取りました。'));
    redirectTo(Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_LIST);
  } catch (e) {
    let msg = 'CSVのアップロードに失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages;
    }
    dispatch(setItemError(REDUCER_NAME, msg));
  }
};

export const getImportCsvJobs = state => getExtras(REDUCER_NAME, state).importCsvJobs;

export const getImporting = state => getExtras(REDUCER_NAME, state).importing;

export const fetchImportCsvJobs = interval => async (dispatch, getState) => {
  const params = {
    job_name: 'importNotificationOfBaseAmountRevisionCsvJob'
  };

  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.importNotificationOfBaseAmountRevisionCsvJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchImportCsvJobs(nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
  }
};

export const hideJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'importNotificationOfBaseAmountRevisionCsvJob'
    });
    dispatch(fetchImportCsvJobs());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};
