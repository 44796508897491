import React from 'react';
import { EditSquare } from 'jbc-front/components/icons';
import { CLIENT_ALLOWANCE_ITEM_EDIT } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import styles from './AllowanceItemsTable.scss';

const allowanceItemEditUrl = allowanceItem =>
  concatParamsToUrl(CLIENT_ALLOWANCE_ITEM_EDIT, { allowanceItemId: allowanceItem.id });

const AllowanceItemRow = ({ allowanceItem }) => (
  <tr>
    <th>{allowanceItem.name}</th>
    <td>{allowanceItem.unitTypeFormatedValue}</td>
    <td>{allowanceItem.payslipDisplayTypeFormatedValue}</td>
    <td>{allowanceItem.numberOfPayrollRulesGroups}</td>
    <td>
      <div className={styles.buttons}>
        <span>
          <a href={allowanceItemEditUrl(allowanceItem)}>
            <EditSquare size={20} />
          </a>
        </span>
      </div>
    </td>
  </tr>
);

const AllowanceItemsTable = ({ allowanceItems, handleDeleteRow }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-item m-table-fixed">
      <thead>
        <tr>
          <th width={300}>項目名</th>
          <th width={100}>単位</th>
          <th width={300}>給与明細での表示</th>
          <th width={170}>適用給与規定グループ</th>
          <th width={70}>編集</th>
        </tr>
      </thead>
      <tbody>
        {(!allowanceItems || allowanceItems.length === 0) && (
          <tr>
            <td colSpan={5}>データがありません。</td>
          </tr>
        )}
        {allowanceItems &&
          allowanceItems.map((allowanceItem, index) => (
            <AllowanceItemRow
              key={allowanceItem.id}
              index={index}
              allowanceItem={allowanceItem}
              handleDeleteRow={handleDeleteRow}
            />
          ))}
      </tbody>
    </table>
  </div>
);

export default AllowanceItemsTable;
