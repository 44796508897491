import { connect } from 'react-redux';
import { CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM } from 'src/constants/FormNames';
import { fetchAllGroupsAndSubgroups, fetchCsvAllowanceDeductionFormats } from 'src/reducers/fetchSelectOptions';
import { fetchUniquePayrollRuleGroups } from 'src/reducers/searchForm';
import ShowAllowanceDeductions from '../components/ShowAllowanceDeductions';

const mapDispatchToProps = dispatch => ({
  allGroupsAndSubgroups: () => dispatch(fetchAllGroupsAndSubgroups(true)),
  csvAllowanceDeductionFormats: () => dispatch(fetchCsvAllowanceDeductionFormats()),
  uniquePayrollRuleGroups: () => dispatch(fetchUniquePayrollRuleGroups(CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM, true))
});

export default connect(null, mapDispatchToProps)(ShowAllowanceDeductions);
