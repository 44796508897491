import React from 'react';
import styles from './LaborInsuranceDownloadColumnDetail.scss';

const LaborInsuranceDownloadColumnDetail = () => (
  <div className="l-wrap-l">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              スタッフコード<span className={styles.required}>必須</span>
            </th>
            <td>
              半角英数字10桁以内<br />
              ※更新用キーとの組み合わせが正しいかチェックするために必要となります。更新はされません。
            </td>
            <td>111</td>
          </tr>
          <tr>
            <th>更新用キー</th>
            <td>
              半角数字<br />
              ※更新対象の行を特定するために用いられます。列がない場合もしくは空欄の場合には新規追加行とみなされます。
            </td>
            <td>11111</td>
          </tr>
          <tr>
            <th>氏名</th>
            <td>
              スタッフコードによって判別しているため、空白でも更新可能です。<br />
              ※ダウンロード専用項目のため更新不可
            </td>
            <td>ジョブカン 太郎</td>
          </tr>
          <tr>
            <th>種別</th>
            <td>
              下記より一つ選択してください。<br />
              ・給与<br />
              ・賞与<br />
            </td>
            <td>給与</td>
          </tr>
          <tr>
            <th>月度</th>
            <td>
              対象年月 (yyyy/mm)<br />
              インポート画面で表示されている年と異なる場合はエラーとなります。
            </td>
            <td>2022/05</td>
          </tr>
          <tr>
            <th>事業所管理名</th>
            <td>所属事業所名</td>
            <td>本社</td>
          </tr>
          <tr>
            <th>労災保険 常用労働者</th>
            <td>半角数字10桁以内</td>
            <td>10000</td>
          </tr>
          <tr>
            <th>労災保険 役員で労働者扱いの人</th>
            <td>半角数字10桁以内</td>
            <td>10000</td>
          </tr>
          <tr>
            <th>労災保険 臨時労働者</th>
            <td>半角数字10桁以内</td>
            <td>10000</td>
          </tr>
          <tr>
            <th>雇用保険資格のある従業員</th>
            <td>半角数字10桁以内</td>
            <td>10000</td>
          </tr>
          <tr>
            <th>雇用保険資格のある役員</th>
            <td>半角数字10桁以内</td>
            <td>10000</td>
          </tr>
          <tr>
            <th>免除対象高年齢労働者</th>
            <td>
              半角数字10桁以内<br />
              ※2019年度以前の内訳データの場合のみ、出力・更新されます。
            </td>
            <td>10000</td>
          </tr>
          <tr>
            <th>手入力有無</th>
            <td>
              手入力された行には「有」が出力されます。<br />
              ※ダウンロード専用項目のため更新不可
            </td>
            <td>有</td>
          </tr>
          <tr>
            <th>削除可否</th>
            <td>
              「可」「不可」の値をとり、手動で追加された行のみ削除可能となります。<br />
              ※ダウンロード専用項目のため更新不可
            </td>
            <td>可</td>
          </tr>
          <tr>
            <th>データ操作</th>
            <td>
              下記よりひとつ選択してください。<br />
              ・「削除」: 指定された行を削除します。削除不可な行に対して指定された場合はエラーになります。<br />
              ・「手入力前に戻す」:
              自動計算の状態に戻します。手動で追加された行に対して指定された場合はエラーになります。<br />
              ※操作を行わない場合は空欄にしてください。
            </td>
            <td>削除</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default LaborInsuranceDownloadColumnDetail;
