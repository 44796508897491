import React from 'react';
import { FormSection } from 'redux-form';

import { Section, DateField, BoxDouble, SelectField, CheckboxField } from 'jbc-front/components/Form';
import { DeleteSquare } from 'jbc-front/components/icons';
import { isDateStringFormat } from 'src/utils/CustomValidators';

const EmployeeLeaveOfAbsenceItem = ({ formKey, remove, leaveOfAbsenceReasonTypes }) => (
  <div>
    <FormSection name={formKey}>
      <Section title="休職期間" icon={<DeleteSquare onClick={remove} />}>
        <BoxDouble>
          <DateField label="休職開始日" name="startOn" validate={isDateStringFormat} />
          <DateField label="休職終了日" name="endOn" validate={isDateStringFormat} />
        </BoxDouble>
        <SelectField label="休職理由" name="reasonType" options={leaveOfAbsenceReasonTypes} />
        <CheckboxField
          name="isExclusionPayment"
          label="給与計算の対象期間が全てこの休職期間に含まれる場合、固定的賃金を0とする。"
        />
      </Section>
    </FormSection>
  </div>
);

export default EmployeeLeaveOfAbsenceItem;
