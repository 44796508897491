import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import { getList } from 'src/reducers/common/listReducer';
import { REDUCER_NAME, transformRevisionsToFormValues } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import List from '../components/List';

export default compose(
  connect(state => ({
    revisions: getList(REDUCER_NAME, state),
    initialValues: transformRevisionsToFormValues(REDUCER_NAME, state)
  })),
  reduxForm({
    form: 'revisions',
    enableReinitialize: true
  })
)(List);
