import React from 'react';
import PropTypes from 'prop-types';

import { Strage } from 'jbc-front/components/icons';
import GeneralConfirmationBtn from './GeneralConfirmationBtn';

const ConfirmBtn = ({ showingConfirmBox, confirmText, handleConfirm, text, disabled, children, ...rest }) => {
  const innerChild = children || <Strage size={18} />;
  return (
    <GeneralConfirmationBtn
      {...{ showingConfirmBox, confirmText, text, disabled }}
      handleEvent={handleConfirm}
      as="a"
      {...rest}
    >
      {innerChild}
    </GeneralConfirmationBtn>
  );
};

ConfirmBtn.propTypes = {
  handleConfirm: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  confirmText: PropTypes.string.isRequired
};

ConfirmBtn.defaultProps = {
  showingConfirmBox: true
};

export default ConfirmBtn;
