import axios from 'axios';

import { EMPLOYEE_INVITATION_CREATE } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { setGlobalErrors, setGlobalWarnings, setGlobalSuccesses } from 'src/reducers/global';

const inviteToDashboard = employees => async dispatch => {
  const requests = employees.map(employee => {
    const inviteUrl = concatParamsToUrl(EMPLOYEE_INVITATION_CREATE, { employee_id: employee.id });
    return axios
      .post(inviteUrl)
      .then(() => employee.id) // Request success
      .catch(exception => {
        // 送信結果としてreturnされた件数を見ているため、ワンライナーだが`{}`をあえて付けている
        // (dispatchは、戻り値を戻すため)
        dispatch(setGlobalErrors(exception.response.data.errors.messages));
      });
  });

  Promise.all(requests)
    .then(results => {
      // 送信失敗した場合はresultsにundefinedが入るので、それをfilterで取り除いた要素数を送信成功した回数とする
      const successCount = results.filter(el => el).length;
      if (employees.length === successCount) {
        dispatch(setGlobalSuccesses('従業員にメールを送りました。'));
      } else if (successCount > 0) {
        dispatch(setGlobalWarnings('一部従業員へのメール送信に失敗しました。'));
      }
    })
    .catch(exception => console.log('exception in inviteToDashboard', exception));
};

export default inviteToDashboard;
