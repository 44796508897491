import React from 'react';
import { connect } from 'react-redux';

import { getAllowanceFormValues } from 'src/reducers/clients/allowances';
import selector from 'src/utils/Utils';

import AllowancePaymentFixed from './AllowancePaymentFixed';
import AllowancePaymentFormula from './AllowancePaymentFormula';
import AllowancePaymentIndividual from './AllowancePaymentIndividual';

const AllowancePayment = ({ selectedAllowanceAmountType, payrollRulesGroup }) => (
  <div>
    {selectedAllowanceAmountType === 'fixed' && <AllowancePaymentFixed />}
    {selectedAllowanceAmountType === 'formula' && <AllowancePaymentFormula />}
    {selectedAllowanceAmountType === 'individual' && (
      <AllowancePaymentIndividual payrollRulesGroup={payrollRulesGroup} />
    )}
  </div>
);

export default connect(state => ({
  selectedAllowanceAmountType: selector(getAllowanceFormValues(state), 'allowanceAmountType')
}))(AllowancePayment);
