import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';

import { getLoadingRevisions, REDUCER_NAME } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import { getErrors } from 'src/reducers/common/listReducer';
import {
  takeNotificationOfBaseAmountSearchQueriesFromLocation,
  notificationOfBaseAmountMonthsSearchFormSelector,
  FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH
} from 'src/reducers/searchForm';
import ShowRevisions from '../components/ShowRevisions';
import { fetchPdfJobStatus } from '../../../reducers/reports/notificationOfBaseAmountRevisionAsync';
import { INITIAL_POLLING_INTERVAL } from '../../../constants/Generals';

const mapStateToProps = state => ({
  initialValues: {
    ...takeNotificationOfBaseAmountSearchQueriesFromLocation(state.router.location),
    ...getFormValues(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(state)
  },
  yearMonth: notificationOfBaseAmountMonthsSearchFormSelector(state, 'yearMonth'),
  applicableOffice: notificationOfBaseAmountMonthsSearchFormSelector(state, 'applicableOffice'),
  location: state.router.location,
  isFetchingRevisions: getLoadingRevisions(state),
  errors: getErrors(REDUCER_NAME, state)
});

const mapDispatchToProps = dispatch => ({
  fetchPdfJobStatus: (revisionYear, applicableOffice) => {
    dispatch(fetchPdfJobStatus(INITIAL_POLLING_INTERVAL, revisionYear, applicableOffice));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH,
    enableReinitialize: true
  })
)(ShowRevisions);
