import React from 'react';
import RateTable from './RateTable';

const HealthInsuranceRateTable = props => {
  const { firstRowParams, secondRowParams, isShowSecondRow, healthInsurance, disableRoundType } = props;

  const setParams = (rowParams, display, cloumnName) => {
    const params = rowParams;
    params.displayRoundType = display;
    // 本社ではない && 参照する事業所が自分のものではない場合はdesable
    params.disableRoundType = disableRoundType;
    params.cloumnName = cloumnName;
    return params;
  };
  return (
    <React.Fragment>
      {/* 保険タイプが「協会けんぽ」で端数処理対象が「健康保険＋介護保険の合計」の場合はテーブルまとめる */}
      {healthInsurance.insuranceType === 'society' && healthInsurance.roundingType === 'total' ? (
        <RateTable
          firstRowParams={setParams(firstRowParams, false, 'roundingTypeForEmployee')}
          secondRowParams={setParams(secondRowParams, true, 'roundingTypeForEmployee')}
          isShowSecondRow={isShowSecondRow}
          roundingTypeForEmployee={healthInsurance.roundingTypeForEmployee}
        />
      ) : (
        <React.Fragment>
          <RateTable
            firstRowParams={setParams(firstRowParams, true, 'healthInsuranceRoundingTypeForEmployee')}
            roundingTypeForEmployee={healthInsurance.healthInsuranceRoundingTypeForEmployee}
          />
          <br />
          <RateTable
            firstRowParams={setParams(secondRowParams, true, 'nursingInsuranceRoundingTypeForEmployee')}
            roundingTypeForEmployee={healthInsurance.nursingInsuranceRoundingTypeForEmployee}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default HealthInsuranceRateTable;
