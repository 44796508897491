import React from 'react';
import { Field, blur, touch } from 'redux-form';

import { RadioField } from 'jbc-front/components/Form';

import { PAYMENT_IN_INSTALLMENT_FOR_ESTIMATE_INSURANCE_FEE } from 'src/constants/Options';

import { REPORT_LABOR_INSURANCE_DECLARATION_FORM } from 'src/constants/FormNames';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { emptyIntegerFormat } from 'src/utils/Completion';
import { numberWithComma, getOptionLabel } from 'src/utils/Utils';
import EditableTextField from './EditableTextField';
import EditableSelectField from './EditableSelectField';
import styles from './DeclarationFormPaymentDeclaredInsurance.scss';

class DeclarationFormPaymentDeclaredInsurance extends React.Component {
  constructor(props) {
    super(props);
    const { declarationForm } = props;
    this.state = { ...declarationForm };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  calculation(params) {
    // 充当・還付額
    const appropriationRefundAmount = this.calcAppropriationAndRefund(params);

    // 不足額
    const paymentDificitAmount = this.calcDificitAmount(params);

    const calcValues = {
      paymentDificitAmount,
      paymentAppropriationAmount: appropriationRefundAmount.appropriationAmount,
      paymentRefundAmount: appropriationRefundAmount.refundAmount
    };

    this.setState({
      ...params,
      ...calcValues
    });

    return { ...params, ...calcValues };
  }

  calcDificitAmount(params) {
    const diff = params.confirmedInsuranceFeeAmountForLaborInsurance - params.paymentDeclaredInsuranceAmount;

    if (diff < 0) return 0;
    return diff;
  }

  calcAppropriationAndRefund(params) {
    if (params.confirmedInsuranceFeeAmountForLaborInsurance >= params.paymentDeclaredInsuranceAmount)
      return { appropriationAmount: 0, refundAmount: 0 };

    switch (params.paymentApplyIntentionType) {
      case 'only_employment_insurance':
        return this.calcPaymentAppropriationAmountByOnlyEmploymentInsurance(params);
      case 'only_general_contribution':
        return this.calcPaymentAppropriationAmountByOnlyGeneralContribution(params);
      case 'both':
        return this.calcPaymentAppropriationAmountByBoth(params);
      default:
        return this.calcPaymentAppropriationAmountByOnlyEmploymentInsurance(params);
    }
  }

  calcPaymentAppropriationAmountByOnlyEmploymentInsurance(params) {
    const diff = params.paymentDeclaredInsuranceAmount - params.confirmedInsuranceFeeAmountForLaborInsurance;

    if (diff > params.estimateInsuranceFeeAmountForLaborInsurance) {
      return {
        appropriationAmount: params.estimateInsuranceFeeAmountForLaborInsurance,
        refundAmount: diff - params.estimateInsuranceFeeAmountForLaborInsurance
      };
    }

    return {
      appropriationAmount: diff,
      refundAmount: 0
    };
  }

  calcPaymentAppropriationAmountByOnlyGeneralContribution(params) {
    const diff = params.paymentDeclaredInsuranceAmount - params.confirmedInsuranceFeeAmountForLaborInsurance;

    if (diff > params.confirmedInsuranceFeeAmountForGeneralContributeion) {
      return {
        appropriationAmount: params.confirmedInsuranceFeeAmountForGeneralContributeion,
        refundAmount: diff - params.confirmedInsuranceFeeAmountForGeneralContributeion
      };
    }

    return {
      appropriationAmount: diff,
      refundAmount: 0
    };
  }

  calcPaymentAppropriationAmountByBoth(params) {
    const diff = params.paymentDeclaredInsuranceAmount - params.confirmedInsuranceFeeAmountForLaborInsurance;

    if (
      diff >
      params.estimateInsuranceFeeAmountForLaborInsurance + params.confirmedInsuranceFeeAmountForGeneralContributeion
    ) {
      return {
        appropriationAmount:
          params.estimateInsuranceFeeAmountForLaborInsurance +
          params.confirmedInsuranceFeeAmountForGeneralContributeion,
        refundAmount:
          diff -
          params.estimateInsuranceFeeAmountForLaborInsurance -
          params.confirmedInsuranceFeeAmountForGeneralContributeion
      };
    }

    return {
      appropriationAmount: diff,
      refundAmount: 0
    };
  }

  // 高年齢労働者算出基礎変更イベント
  onChangePaymentDeclaredInsuranceAmount(value) {
    const params = {
      ...this.state,
      paymentDeclaredInsuranceAmount: value
    };
    this.props.allCalculations(params);
  }

  // 充当意思変更イベント
  onChangePaymentApplyIntentionType(value) {
    const params = {
      ...this.state,
      paymentApplyIntentionType: value
    };
    this.props.allCalculations(params);
  }

  // 延納の申請変更イベント
  OnChangeIsPaymentInInstallmentsForEstimateInsuranceFee(value) {
    const params = {
      ...this.state,
      isPaymentInInstallmentsForEstimateInsuranceFee: value === '1'
    };
    this.props.allCalculations(params);
  }

  render() {
    const { dispatch, actionMode, declarationForm, laborInsurancePaymentApplyIntentionTypes } = this.props;
    return (
      <div>
        <div>
          <p className="u-mb10">
            今回の年度更新で納付する額の計算<span className={styles.supplement}>
              上記の内容から今回納付する額を計算
            </span>
          </p>
          <div className="l-overflow-scroll">
            <table className={styles.table}>
              <colgroup>
                <col width={60} />
                <col width={180} />
                <col width={240} />
                <col width={240} />
                <col width={240} />
              </colgroup>
              <tbody>
                <tr>
                  <th colSpan="2">申告済概算保険料額</th>
                  <td colSpan="3">
                    <Field
                      name="paymentDeclaredInsuranceAmount"
                      showValue={declarationForm.paymentDeclaredInsuranceAmount}
                      showMode={actionMode}
                      inputWidth={150}
                      validate={[isMoneyNumber]}
                      component={EditableTextField}
                      onChange={e => {
                        this.onChangePaymentDeclaredInsuranceAmount(e.target.value);
                      }}
                      onBlur={(e, newValue, previousValue) => {
                        e.preventDefault();
                        dispatch(
                          blur(
                            `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                            `paymentDeclaredInsuranceAmount`,
                            emptyIntegerFormat(previousValue)
                          )
                        );
                        dispatch(touch(`${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`, `paymentDeclaredInsuranceAmount`));
                        this.onChangePaymentDeclaredInsuranceAmount(e.target.value);
                      }}
                    />
                    <span className={styles.currency}>円</span>
                  </td>
                </tr>
                <tr>
                  <th rowSpan="2" className={styles.vertical}>
                    差引額
                  </th>
                  <th>充当額</th>
                  <td>
                    {numberWithComma(this.state.paymentAppropriationAmount)} <span className={styles.currency}>円</span>
                  </td>
                  <th>不足額</th>
                  <td>
                    {numberWithComma(this.state.paymentDificitAmount)} <span className={styles.currency}>円</span>
                  </td>
                </tr>
                <tr>
                  <th>還付額</th>
                  <td colSpan="3">
                    {numberWithComma(this.state.paymentRefundAmount)} <span className={styles.currency}>円</span>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">充当意思</th>
                  <td colSpan="3">
                    <div className={styles.selectWrap}>
                      <Field
                        name="paymentApplyIntentionType"
                        showValue={getOptionLabel(declarationForm.paymentApplyIntentionType)(
                          laborInsurancePaymentApplyIntentionTypes
                        )}
                        showMode={actionMode}
                        options={laborInsurancePaymentApplyIntentionTypes}
                        inputWidth={70}
                        component={EditableSelectField}
                        onChange={e => {
                          this.onChangePaymentApplyIntentionType(e.target.value);
                        }}
                        onBlur={e => {
                          this.onChangePaymentApplyIntentionType(e.target.value);
                        }}
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th colSpan="2">延納の申請（納付回数）</th>
                  <td colSpan="3">
                    {actionMode === 'EDIT' && (
                      <div className={styles.radioWrap}>
                        <RadioField
                          name="isPaymentInInstallmentsForEstimateInsuranceFee"
                          options={PAYMENT_IN_INSTALLMENT_FOR_ESTIMATE_INSURANCE_FEE}
                          onChange={e => {
                            this.OnChangeIsPaymentInInstallmentsForEstimateInsuranceFee(e.target.value);
                          }}
                        />
                      </div>
                    )}
                    {actionMode === 'SHOW' &&
                      !this.state.isPaymentInInstallmentsForEstimateInsuranceFee && <div>1回</div>}
                    {actionMode === 'SHOW' &&
                      this.state.isPaymentInInstallmentsForEstimateInsuranceFee && <div>3回</div>}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export default DeclarationFormPaymentDeclaredInsurance;
