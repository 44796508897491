import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import { UTF_8 } from 'src/constants/Generals';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { INCOME_TAX_COLLECTION_DATA_CSV } from 'src/constants/EndpointUrls';
import { INCOME_TAX_COLLECTION_DATA_FORM } from 'src/constants/FormNames';
import DownloadCsvModal from '../components/DownloadCsvModal';

const downloadCsv = (data, ownProps) => {
  const { encoding } = data;
  const { selectedYear } = ownProps;
  const params = { encoding, year: selectedYear };
  redirectTo(`${INCOME_TAX_COLLECTION_DATA_CSV}?${serializeHttpGetParams(params)}`);
};

const mapStateToProps = () => ({
  initialValues: { encoding: UTF_8 }
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => {
    downloadCsv(data, ownProps);
    ownProps.hideModal();
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: INCOME_TAX_COLLECTION_DATA_FORM,
    enableReinitialize: true
  })
)(DownloadCsvModal);
