import React from 'react';
import Select from 'react-select';
import { ERROR_MSG_NO_DATA } from 'src/constants/ValidationMessages';

class EditableSelectField extends React.Component {
  renderField(props) {
    const {
      input: inputProps,
      width,
      showValue,
      showMode,
      options,
      placeholder,
      disabled,
      addBlankOption,
      ...rest
    } = props;
    const renderOptions = addBlankOption ? [{ label: '\u200b', value: null }, ...options] : options;
    if (showMode === 'SHOW') {
      return <div>{showValue}</div>;
    } else if (showMode === 'EDIT') {
      return (
        // 共通コンポーネントのSelectFieldを大きすぎるため、CSSで調整する手間を省くため独自定義
        <Select
          style={{ width }}
          {...inputProps}
          {...rest}
          clearable={false}
          isSearchable={false}
          simpleValue
          value={inputProps.value || (renderOptions && renderOptions[0].value)}
          options={renderOptions}
          disabled={disabled}
          placeholder={'-'}
          onBlur={() => inputProps.onBlur(inputProps.value)}
          noResultsText={ERROR_MSG_NO_DATA}
          noOptionsMessage={ERROR_MSG_NO_DATA}
        />
      );
    }
    return null;
  }
  render() {
    return <div>{this.renderField(this.props)}</div>;
  }
}

export default EditableSelectField;
