import React, { useState, useEffect, useRef } from 'react';
import { usePdf } from '@mikecousins/react-pdf';
import isFunction from 'lodash/isFunction';
import Modal from 'react-modal';
import ActionButton from 'jbc-front/components/ActionButton';
import { Download, Print, Close, MinusSquare, PlusSquare, Pulldown, Edit } from 'jbc-front/components/icons';
import { Loading } from 'src/components';
import { redirectTo } from 'src/utils/Http';
import { executeIfAgreement } from 'src/reducers/my_data/myData';
import styles from './PreviewPdf.scss';

const MIN_ZOOM = 1;
const MAX_ZOOM = 5;
const ZOOM_STEP = 0.25;
const DEFAULT_ZOOM = 1.25;

const LoadingComponent = () => (
  <div className={styles.loading}>
    <Loading type="spin" color="#e3e3e3" />
  </div>
);

const PreviewPdfModal = props => {
  const { isOpen, hideModal, url, downloadUrl, header, canPrintPdf = true, editUrl = null, isMyPage = false } = props;
  // 各種値が無い状態で呼ばれると不適切なPDFを作成しようとしてerrorになってしまう
  // それを避けるためにisOpenがfalseの場合はnullで返す
  if (!isOpen) return null;

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [scale, setScale] = useState(DEFAULT_ZOOM);
  const [error, setError] = useState(false);
  const dlUrl = downloadUrl || url;
  const printPdf = () => {
    const w = window.open(`${url}&disposition=inline`, '', 'toolbar=0,menubar=0,location=0');
    if (isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print();
      });
    }
  };
  const printPdfMyPage = () => {
    executeIfAgreement(printPdf);
  };
  const redirectToEdit = () => {
    if (editUrl) {
      redirectTo(editUrl);
    }
  };
  const downloadFile = () => {
    redirectTo(dlUrl);
  };
  const setErrorTrue = () => {
    setError(true);
  };
  // @mikecousins/react-pdfにはエラーハンドリングの方法がない。
  // サーバーからエラーが返ってきた場合はpromiseRejectionEventが起きることがわかっているのでsetErrorを行う
  // それ以外のケースは今の所わかっていない
  window.addEventListener('unhandledrejection', setErrorTrue);
  const canvasEl = useRef(null);
  const [loading, numPages] = usePdf({
    file: url,
    onDocumentComplete: totalPages,
    page,
    scale,
    canvasEl,
    cMapUrl: 'node_modules/pdfjs-dist/cmaps'
  });
  useEffect(
    () => {
      setTotalPages(numPages);
    },
    [numPages]
  );

  return (
    <Modal className={styles.modal} isOpen={isOpen} overlayClassName={styles.overlay} contentLabel="Modal">
      <div className={styles.header}>
        <div className={styles.title}>{header}</div>
        <div className={styles.tools}>
          {editUrl && (
            <ActionButton className={styles.buttonEdit} onClick={redirectToEdit} icon={<Edit size={15} />}>
              編集
            </ActionButton>
          )}
          {canPrintPdf && (
            <ActionButton
              primary
              className={styles.button}
              onClick={isMyPage ? printPdfMyPage : printPdf}
              icon={<Print size={15} />}
            >
              印刷
            </ActionButton>
          )}
          <ActionButton primary className={styles.button} onClick={downloadFile} icon={<Download size={15} />}>
            ダウンロード
          </ActionButton>
          <Close className={styles.close} onClick={hideModal} />
        </div>
      </div>
      <div className={styles.body}>
        {error && (
          <p className={styles.error}>
            PDFの読み込みに失敗しました。<br />
            通信状態の良い環境で再度お試しください。<br />
            改善されない場合は、お手数ですがサポート窓口までお問い合わせください。
          </p>
        )}
        {!error && loading && <LoadingComponent />}
        {!error && <div className={styles.pdf}>{<canvas ref={canvasEl} />}</div>}
      </div>
      <div className={styles.controller}>
        <span
          className={styles.zoom}
          onClick={() => scale > MIN_ZOOM && setScale(scale - ZOOM_STEP)}
          role="presentation"
        >
          <MinusSquare size={20} />
        </span>
        <span className={styles.scale}>{scale * 100}％</span>
        <span
          className={styles.zoom}
          onClick={() => scale < MAX_ZOOM && setScale(scale + ZOOM_STEP)}
          role="presentation"
        >
          <PlusSquare size={20} />
        </span>
        <span className={styles.pagePrev} onClick={() => page > 1 && setPage(page - 1)} role="presentation">
          <Pulldown size={12} />
        </span>
        <span className={styles.pageNumber}>
          {page} / {totalPages}
        </span>
        <span className={styles.pageNext} onClick={() => page < totalPages && setPage(page + 1)} role="presentation">
          <Pulldown size={12} />
        </span>
      </div>
    </Modal>
  );
};

export default PreviewPdfModal;
