import React from 'react';
import { reduxForm } from 'redux-form';
import { Panel } from 'src/components/PrimaryPanel';
import { CheckboxField } from 'jbc-front/components/Form';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { ErrorsSection } from 'src/components';
import { CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM } from 'src/constants/FormNames';

import styles from './ElectronicDeliveryAgreementSetting.scss';

const ElectronicDeliveryAgreementSetting = ({ submitting, onSubmit, formValues }) => (
  <React.Fragment>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">給与・賞与・源泉徴収票電子交付設定</h1>
    </div>
    <div className="l-wrap-xs l-contents-wrap">
      <div className="u-mb20">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="給与・賞与・源泉徴収票電子交付設定" />
        </BreadCrumb>
      </div>
      <Panel>
        <div className={styles.description}>
          <p>
            下記チェックボックスにチェックを入れることにより、所得税法第231条第2項により明細書の電子交付時に必要とされる、
            従業員の同意管理機能を利用することができます。<br />
            この機能を利用した場合、未同意の従業員が明細書を表示しようとした際には同意を求める画面が表示され、
            一度同意操作を行って頂くと明細が表示されるようになります。<br />
            また同意操作や同意状態の確認を、従業員自身のマイページや従業員管理ページから行うことができるようになります。<br />
            機能の詳細は<a
              className="u-txt-link"
              target="_blank"
              rel="noopener noreferrer"
              href="https://jobcan-payroll.zendesk.com/hc/ja/articles/13741271205145"
            >
              ヘルプページ
            </a>をご覧ください。
          </p>
        </div>
      </Panel>
      <ErrorsSection errors={formValues ? formValues.errors : []} />
      <div className={styles.main}>
        <div className={styles.flexbox}>
          <div className={styles.checkbox}>
            <CheckboxField
              name="isElectronicDeliveryAgreementSetting"
              label="電子交付の同意機能をONにする"
              onChange={() => {
                onSubmit();
              }}
              disabled={submitting}
            />
          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
);

export default reduxForm({
  form: CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM,
  enableReinitialize: true
})(ElectronicDeliveryAgreementSetting);
