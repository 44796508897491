import { connect } from 'react-redux';
import DownloadBonusReports from 'src/features/EmployeeBonus/components/DownloadBonusReports';
import { pollFetchReportJobStatus } from 'src/reducers/employees/employeeBonus';

const mapStateToProps = state => ({
  job: state.JobStatus.reportEmployeeBonusJob
});

const mapDispatchToProps = dispatch => ({
  pollFetchReportJobStatus: clientBonusId => {
    dispatch(pollFetchReportJobStatus(clientBonusId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadBonusReports);
