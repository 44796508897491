import React from 'react';
import ListGroup from 'jbc-front/components/ListGroup';
import { AnchorLink } from 'jbc-front/components/ScrollSpy';

const Anchor = ({ current, name, children }) => (
  <ListGroup.Item as={AnchorLink} name={name}>
    {current === name ? <strong>{children}</strong> : children}
    {current === name && <ListGroup.Icon />}
  </ListGroup.Item>
);

export default Anchor;
