// TODO 各componentはJobの新設計移行後に分割する

import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { Close } from 'jbc-front/components/icons';
import { formatDateWithHour } from 'src/utils/Date';
import Loading from 'src/components/Loading';
import _ from 'lodash';
import styles from './JobStatus.scss';

export const AllJobStatus = ({ task, taskStyle, statusText, isDisplayErrorLink, errorHandler, hideJobMessage }) => {
  const Success = ({ job, cssStyles, text, closeButton }) => (
    <React.Fragment>
      <div className={cssStyles.close} role="presentation" onClick={() => closeButton(job.id)}>
        <Close size={20} />
      </div>
      <ul className={cssStyles.list}>
        <JobSuccess key={job.id} statusText={text(job)} {...job} />
      </ul>
    </React.Fragment>
  );

  const Failed = ({ job, cssStyles, text, closeButton }) => (
    <React.Fragment>
      <div className={cssStyles.close} role="presentation" onClick={() => closeButton(job.id)}>
        <Close size={20} />
      </div>
      <ul className={cssStyles.list}>
        <JobFailed
          key={job.id}
          statusText={text(job)}
          isDisplayErrorLink={isDisplayErrorLink}
          errorHandler={errorHandler}
          {...job}
        />
      </ul>
    </React.Fragment>
  );

  const InProgress = ({ job, cssStyles, text }) => (
    <React.Fragment>
      <ul className={cssStyles.list}>
        <JobInProgress key={job.id} statusText={text(job)} {...job} />
      </ul>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {!isEmpty(task) && (
        <div className={taskStyle(task, styles)}>
          <div className={styles.inner}>
            {task.status === 'success' && (
              <Success job={task} cssStyles={styles} text={statusText} closeButton={hideJobMessage} />
            )}
            {(task.status === 'failed' || task.status === 'partial_success') && (
              <Failed
                job={task}
                cssStyles={styles}
                text={statusText}
                isDisplayErrorLink={isDisplayErrorLink}
                errorHandler={errorHandler}
                closeButton={hideJobMessage}
              />
            )}
            {(task.status === 'in_progress' || task.status === 'waiting') && (
              <InProgress job={task} cssStyles={styles} text={statusText} />
            )}
          </div>
        </div>
      )}
      {isEmpty(task) && null}
    </React.Fragment>
  );
};

AllJobStatus.defaultProps = {
  taskStyle: (job, cssStyles) => {
    switch (job.status) {
      case 'partial_success':
      case 'failed':
        return cssStyles.tasksFailed;
      case 'in_progress':
      case 'waiting':
        return cssStyles.tasksInprogress;
      default:
        return cssStyles.tasksSuccess;
    }
  }
};

export const JobSuccess = ({ statusText, name, startedAt, endedAt }) => (
  <li>
    <p className={styles.title}>
      {name}
      {statusText}
    </p>
    <div className={styles.dates}>
      <dl>
        <dt>実行日時：</dt>
        <dd>{formatDateWithHour(startedAt)}</dd>
      </dl>
      <dl>
        <dt>完了日時：</dt>
        <dd>{formatDateWithHour(endedAt)}</dd>
      </dl>
    </div>
  </li>
);

export const JobFailed = ({ name, startedAt, endedAt, messages, statusText, isDisplayErrorLink, errorHandler }) => (
  <li>
    <p className={styles.title}>
      {name}
      {statusText}
      {isDisplayErrorLink && (
        <span className={styles.underline} onClick={errorHandler} role="button" tabIndex="0">
          エラー対象行のCSV出力
        </span>
      )}
    </p>
    <div className={styles.dates}>
      <dl>
        <dt>実行日時：</dt>
        <dd>{formatDateWithHour(startedAt)}</dd>
      </dl>
      <dl>
        <dt>完了日時：</dt>
        <dd>{formatDateWithHour(endedAt)}</dd>
      </dl>
    </div>
    <ul className={styles.error}>
      {_.isEmpty(messages) ? (
        <li>再度実行してもエラーが解消されない場合は、お手数ですがサポート窓口までお問い合わせください。</li>
      ) : (
        JSON.parse(messages).map(msg => <li key={msg}>{msg}</li>)
      )}
    </ul>
  </li>
);
export const JobInProgress = ({ statusText, name, startedAt, progress }) => (
  <li>
    <div className={styles.waiting}>
      <div className={styles.loading}>
        <Loading />
      </div>
      <div className={styles.info}>
        <p className={styles.title}>
          {name}
          {statusText}
        </p>
        {_.isNumber(progress) && <p className={styles.title}>{`進捗：${progress}％`}</p>}
        <div className={styles.dates}>
          <dl>
            <dt>実行日時：</dt>
            <dd>{formatDateWithHour(startedAt)}</dd>
          </dl>
        </div>
      </div>
    </div>
  </li>
);
