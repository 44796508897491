import React from 'react';

import { SelectField } from 'jbc-front/components/Form';

class ExtensionSelectField extends React.Component {
  prepareAddBlankOption(options, required = false) {
    // requiredが指定されていてもいなくてもリストが空なら、空行を入れないように制御する
    if (options === undefined || options === null || options.length <= 0) {
      return false;
    }
    return !required;
  }
  render() {
    const { options, required } = this.props;
    return <SelectField {...this.props} addBlankOption={this.prepareAddBlankOption(options, required)} />;
  }
}

export default ExtensionSelectField;
