import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { CheckboxField, Label } from 'jbc-front/components/Form';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getAllowanceFormValues } from 'src/reducers/clients/allowances';
import styles from './BaseSalaryFormulaSettings.scss';

const displayClientAllowanceItemNames = (clientAllowanceItems, checkedClientAllowanceItems) => {
  const checkedClientAllowanceItemIds = _.filter(checkedClientAllowanceItems);
  return _.map(
    _.filter(clientAllowanceItems, item => _.includes(checkedClientAllowanceItemIds, item.id)),
    item => item.label
  );
};

const displayBasicSalaryCalculation = checkedClientAllowanceItemNames => {
  const first = checkedClientAllowanceItemNames.length === 0 ? null : _.pullAt(checkedClientAllowanceItemNames, [0]);
  return (
    <div className={styles.wrap}>
      <div className={styles.tagBase}>基本給</div>
      <span className={styles.symbol}>×</span>
      {checkedClientAllowanceItemNames.length > 0 && <span className={styles.symbol}>(</span>}
      {first && <div className={styles.tagOption}>{first}</div>}
      {checkedClientAllowanceItemNames.map(name => (
        <React.Fragment key={`checkedClientAllowanceItemNamesArea${name}`}>
          <span className={styles.symbol}>+</span>
          <div className={styles.tagOption}>{name}</div>
        </React.Fragment>
      ))}
      {checkedClientAllowanceItemNames.length > 0 && <span className={styles.symbol}>)</span>}
    </div>
  );
};

const BaseSalaryFormulaSettings = ({ clientAllowanceItems, checkedClientAllowanceItemNames }) => (
  <div className="u-mt40">
    <Label required text="明細の計算方法" />
    <div className={styles.notes}>
      <Label text="チェックを入れた勤怠項目の合計時間で計算されます" />
    </div>
    <Label text={displayBasicSalaryCalculation(checkedClientAllowanceItemNames)} />
    <div>
      {clientAllowanceItems.map((clientAllowanceItem, index) => (
        <CheckboxField
          key={`client_allowance_item_${clientAllowanceItem.id}`}
          name={`clientAllowanceItemId[${index}]`}
          label={clientAllowanceItem.label}
          normalize={value => value && clientAllowanceItem.id}
        />
      ))}
    </div>
  </div>
);

const mapStateToProps = state => ({
  clientAllowanceItems: getSelectOptions(state, 'clientAllowanceItems'),
  checkedClientAllowanceItemNames: displayClientAllowanceItemNames(
    getSelectOptions(state, 'clientAllowanceItems'),
    getAllowanceFormValues(state).clientAllowanceItemId
  )
});

export default connect(mapStateToProps)(BaseSalaryFormulaSettings);
