import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import Edit from 'jbc-front/components/icons/Edit';

const EditActionBtn = ({ onClick, label = '編集', ...rest }) => (
  <ActionButton as="button" icon={<Edit />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default EditActionBtn;
