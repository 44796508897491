import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';
import Hint from 'jbc-front/components/Hint';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { REDUCER_NAME, fetchRevisions, getLoadingRevisions } from 'src/reducers/reports/monthlyRevisions';
import { FormErrors } from 'src/components';
import { getErrors } from 'src/reducers/common/listReducer';
import { REPORT_INDEX } from 'src/constants/EndpointUrls';
import {
  takeRevisionsSearchQueries,
  takeRevisionsSearchQueriesFromLocation,
  revisionMonthsSearchFormSelector,
  FORM_NAME_REVISION_MONTHS_SEARCH
} from 'src/reducers/searchForm';
import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import List from 'src/features/MonthlyRevisions/components/List';
import HeaderContainer from './containers/HeaderContainer';
import styles from './MonthlyRevisionsList.scss';
import ImportCsvJobStatus from './containers/ImportCsvJobStatusContainer';
import Loading from '../../components/Loading';

class MonthlyRevisionsList extends React.Component {
  // エラータイトル文言を取得する
  // undefinedを返す場合は、全てFormErrorsに準じた挙動となる
  getErrorTitle(errors) {
    if (_.isEmpty(errors)) {
      return undefined;
    }
    // エラーが全て給与未確定のものであれば、エラー文言をデータなしに変更する
    const res = errors.every(error => error.match(/^.+支給給与を確定してください。.*$/));
    return res ? 'データがありません' : undefined;
  }

  render() {
    const {
      initialValues: formValues,
      yearMonth,
      applicableOffice,
      location,
      isFetchingRevisions,
      errors,
      dispatch
    } = this.props;
    return (
      <div>
        <div className="l-wrap-xxl l-contents-wrap">
          <SearchDetector
            location={location}
            yearMonth={yearMonth}
            applicableOffice={applicableOffice}
            willReceivePropsDetectingJudger={(prevProps, nextProps) =>
              nextProps.yearMonth !== undefined &&
              nextProps.applicableOffice !== undefined &&
              (prevProps.yearMonth === undefined ||
                prevProps.applicableOffice === undefined ||
                nextProps.location !== prevProps.location)
            }
            onDetect={() => {
              dispatch(fetchRevisions(takeRevisionsSearchQueries(formValues)));
            }}
          />
        </div>
        <div className="l-wrap-xxl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
              <BreadCrumb.Item label="月額変更届" />
            </BreadCrumb>
          </div>
          <div>
            <div className={styles.header}>
              <h1 className="m-title-main u-mb50">月額変更届（随時改定）</h1>
              <Hint
                width={400}
                text={
                  <p>
                    昇給や降給、給与形態の変更などによって毎月定額の支給項目（固定的賃金）が大幅に変動した場合に提出が必要になります。<br />
                    残業代など、毎月金額が変動する支給項目（非固定的賃金）が大幅に動いてても対象にはなりません。<br />
                    <br />
                    詳細は<a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360002145953"
                    >
                      コチラ
                    </a>
                  </p>
                }
              />
            </div>
          </div>
        </div>
        <ImportCsvJobStatus />
        <div className="l-wrap-xxl l-contents-wrap">
          <HeaderContainer />
          <FormErrors title={this.getErrorTitle(errors)} errors={errors} />
          {isFetchingRevisions ? (
            <Loading />
          ) : (
            _.isEmpty(errors) && (
              <div>
                <List formValues={formValues} />
                <SearchFormPaginator formName={FORM_NAME_REVISION_MONTHS_SEARCH} reducerName={REDUCER_NAME} />
              </div>
            )
          )}
        </div>
      </div>
    );
  }
}

export default compose(
  connect(state => ({
    initialValues: {
      ...takeRevisionsSearchQueriesFromLocation(state.router.location),
      ...getFormValues(FORM_NAME_REVISION_MONTHS_SEARCH)(state)
    },
    yearMonth: revisionMonthsSearchFormSelector(state, 'yearMonth'),
    applicableOffice: revisionMonthsSearchFormSelector(state, 'applicableOffice'),
    location: state.router.location,
    isFetchingRevisions: getLoadingRevisions(state),
    errors: getErrors(REDUCER_NAME, state)
  })),
  reduxForm({
    form: FORM_NAME_REVISION_MONTHS_SEARCH,
    enableReinitialize: true
  })
)(MonthlyRevisionsList);
