import { connect } from 'react-redux';
import { getSelectOptions } from 'src/reducers/selectOptions';
import LabourConsultantSection from '../../components/edit/LabourConsultantSection';

const mapStateToProps = state => ({
  prefectures: getSelectOptions(state, 'prefectures'),
  submitters: getSelectOptions(state, 'submitters')
});

export default connect(mapStateToProps)(LabourConsultantSection);
