import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import { autofill } from 'redux-form';

import { CLIENT_CSV_FORMAT_NEW, CLIENT_CSV_FORMAT_EDIT } from 'src/constants/EndpointUrls';
import {
  DEDUCTION_ALLOWANCE_EXPORT_TYPES,
  DEDUCTION_ALLOWANCE_EXPORT_DIRECTIONS,
  ENCODING_OPTIONS
} from 'src/constants/Options';
import { CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM } from 'src/constants/FormNames';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import { Edit, Copy, PlusCircle, Download } from 'jbc-front/components/icons';
import { Label, Section, RadioField } from 'jbc-front/components/Form';
import Balloon from 'src/components/Balloon';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import { Panel, Item, Headding } from 'src/components/PrimaryPanel';
import { getGroupFullPathName } from 'src/utils/Utils';
import { redirectTo, concatParamsToUrl } from 'src/utils/Http';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import styles from './AllowanceDeductionForm.scss';

const urlWithBack = (url, back) => `${url}?back=${back}`;
const editUrl = formatId => concatParamsToUrl(CLIENT_CSV_FORMAT_EDIT, { id: formatId });

const FormatGroups = ({ formatId, csvFormats, groups }) => {
  const format = csvFormats.find(fmt => fmt.id === formatId);
  const groupIds = format.groups;

  return (
    <div className={styles.selectedGroup}>
      <Label text="選択中のグループ" />
      <div className={styles.selectedGroupList}>
        {groups.filter(group => groupIds.includes(group.id)).map(group => (
          <div key={group.id} className={styles.selectedGroupItem}>
            {getGroupFullPathName(groups, group.id, ' > ')}
          </div>
        ))}
      </div>
    </div>
  );
};

const AllowanceDeductionForm = props => {
  const {
    submit,
    submitting,
    pristine,
    formValues,
    mainSelectField,
    groups,
    csvFormats,
    csvType,
    back,
    copyFormat,
    dispatch,
    isCreating
  } = props;

  const csvFormat = csvFormats.find(fmt => fmt.id === formValues.formatId);
  const csvFormatsOptions = csvFormats.filter(format => format.formatType === csvType);

  const exportTypes =
    _.isUndefined(formValues.formatId) || (csvFormat && !_.isEmpty(_.get(csvFormat, 'groups')))
      ? DEDUCTION_ALLOWANCE_EXPORT_TYPES
      : _.filter(DEDUCTION_ALLOWANCE_EXPORT_TYPES, { value: 'each_employee' });

  return (
    <form>
      <div className="l-wrap-xs">
        <Panel>
          <Headding>支給・控除等一覧表</Headding>
          <Item>
            {mainSelectField()}
            <div className="u-mt20">
              <Section title="項目の選択" small>
                <div className={styles.formatSeclection}>
                  <div className={styles.formatSelextbox}>
                    <ExtensionSelectField
                      label="フォーマット"
                      name="formatId"
                      options={csvFormatsOptions}
                      required
                      onChange={() => {
                        dispatch(autofill(CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM, 'exportType', 'each_employee'));
                      }}
                    />
                  </div>
                  <div className={styles.formatActionButton}>
                    <ActionButton
                      icon={<Edit size={16} />}
                      onClick={() => redirectTo(urlWithBack(editUrl(formValues.formatId), back))}
                      disabled={!formValues.formatId}
                    >
                      編集
                    </ActionButton>

                    <ActionButton
                      icon={<Copy size={16} />}
                      disabled={!formValues.formatId}
                      onClick={() => copyFormat(formValues.formatId)}
                    >
                      コピー
                    </ActionButton>

                    <ActionButton
                      as="a"
                      href={urlWithBack(CLIENT_CSV_FORMAT_NEW, back)}
                      icon={<PlusCircle size={16} />}
                    >
                      新規作成
                    </ActionButton>
                  </div>
                </div>

                {formValues.formatId && (
                  <FormatGroups formatId={formValues.formatId} csvFormats={csvFormats} groups={groups} />
                )}
              </Section>

              <Section title="出力形式の選択" small>
                <div className={styles.exportItemWrap}>
                  <RadioField name="exportType" options={exportTypes} required />
                </div>
                <div className={styles.exportItemLine}>
                  <RadioField name="exportDirection" options={DEDUCTION_ALLOWANCE_EXPORT_DIRECTIONS} required />
                </div>
                <div className={styles.exportItemWrap}>
                  <DownloadCsvEncodingRadioButton
                    name={'exportTextCode'}
                    label={'文字コードを選択'}
                    options={ENCODING_OPTIONS}
                    required
                  />
                </div>
              </Section>
            </div>
          </Item>

          <div className={classNames('u-ta-c', 'u-pb30', styles.downloadBtn)}>
            {isCreating && (
              <Balloon balloonPosition={'top'} arrowPosition={'center'} className={styles.downloadBtnBalloon}>
                作成に時間がかかるため、完了しましたらメールにてお送りいたします。
              </Balloon>
            )}
            <Button
              primary
              onClick={submit}
              disabled={submitting || pristine || isCreating}
              icon={<Download size={16} />}
            >
              CSVダウンロード
            </Button>
          </div>
        </Panel>
      </div>
    </form>
  );
};

export default AllowanceDeductionForm;
