import React from 'react';
import { ErrorsSection } from 'src/components';
import { Download, Upload } from 'jbc-front/components/icons';
import { FileField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { TEMPLATE_MY_NUMBER_CSV } from 'src/constants/EndpointUrls';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';

import InsertBatchCsvDescription from './InsertBatchCsvDescription';
import AnyJobRunning from './AnyJobRunning';
import styles from './InsertBatchCsv.scss';

export default class InsertBatchCsv extends React.Component {
  render() {
    const { submitting, handleSubmit, selectedEncoding, anyJobRunning, errors } = this.props;

    return (
      <div>
        {anyJobRunning && <AnyJobRunning />}
        <div className="l-title-wrap">
          <h1 className="m-title-main">マイナンバーの一括登録</h1>
          <p className="m-title-main-note">従業員と被扶養者のマイナンバーを一括で登録できます。</p>
        </div>
        <div className={styles.wrap}>
          <div className={styles.head}>アップロード手順</div>
          <div className={styles.body}>
            <form onSubmit={handleSubmit}>
              <ErrorsSection errors={errors} />
              <dl className={styles.label}>
                <dt className={styles.number}>1</dt>
                <dd className={styles.detail}>
                  <p className={styles.text}>
                    ファイルの出力形式を選択してダウンロードしたCSVファイルをご利用ください。
                  </p>
                  <div className="u-mt30">
                    <DownloadCsvEncodingRadioButton
                      name={'encoding'}
                      label={null}
                      options={ENCODING_OPTIONS}
                      required={false}
                    />
                  </div>
                  <Button
                    widthWide
                    icon={<Download size={16} />}
                    onClick={() =>
                      redirectTo(`${TEMPLATE_MY_NUMBER_CSV}?${serializeHttpGetParams({ encoding: selectedEncoding })}`)
                    }
                  >
                    サンプルデータをダウンロード
                  </Button>
                </dd>
              </dl>

              <dl className={styles.label}>
                <dt className={styles.number}>2</dt>
                <dd className={styles.detail}>
                  下の説明を参考にしながら、ダウンロードしたCSVファイルに、登録したい従業員の情報を入れてください。
                  <div className={styles.warning}>
                    <span className={styles.warningTitle}>【注意】</span>
                    <ul className={styles.warningList}>
                      <li>
                        以下の項目は<span className={styles.requiredText}>入力必須</span>になります。
                        <ul className={styles.requireList}>
                          <li>・スタッフコード</li>
                          <li>・（被扶養者の情報を登録する場合）被扶養者 姓</li>
                          <li>・（被扶養者の情報を登録する場合）被扶養者 名</li>
                          <li>・（被扶養者の情報を登録する場合）被扶養者 生年月日</li>
                          <li>・（被扶養者の情報を登録する場合）被扶養者 マイナンバー</li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </dd>
              </dl>

              <dl className={styles.label}>
                <dt className={styles.number}>3</dt>
                <dd className={styles.detail}>
                  <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
                  <FileField name="my_number_csv" className={styles.input} />
                </dd>
              </dl>
              <Button as="button" primary type="submit" disabled={submitting} icon={<Upload size={16} />} widthWide>
                アップロード
              </Button>
            </form>
          </div>
        </div>
        <div className={styles.description}>
          <InsertBatchCsvDescription />
        </div>
      </div>
    );
  }
}
