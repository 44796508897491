import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { REDUCER_NAME, fetchTodayMemos } from 'src/reducers/memos/memos';
import {
  getListPageCount,
  getListPage,
  getListPerPage,
  addPageQuery,
  addPerPageQuery,
  getListTotalCount,
  getListDisplayFrom,
  getListDisplayTo,
  getList,
  getIsLoading
} from 'src/reducers/common/listReducer';
import LoadingView from 'src/components/LoadingView';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { LowerPager } from 'src/components';
import ListGroup from 'jbc-front/components/ListGroup';
import { recordDisplay } from 'src/utils/Utils';
import styles from './AlertsList.scss';

const MemoColor = ({ memoColorId, memoColors }) => {
  const color = memoColors.find(item => item.value === memoColorId.toString()).label;
  return <span style={{ backgroundColor: color }} className={styles.circle} />;
};

const ShowListGroup = ({ memos, memoColors }) => (
  <ListGroup>
    <ListGroup.Title>従業員のメモ</ListGroup.Title>
    {get(memos, 'length') > 0 ? (
      memos.map(memo => (
        <ListGroup.Item
          className={styles.list}
          key={memo.id}
          {...{ href: `/employees/${memo.employeeId}`, target: '_blank', as: 'a' }}
        >
          <div className={styles.date}>{recordDisplay.currentOrPrevYear(memo.alertDate)}</div>
          <div className={styles.detail}>
            <div>{memo.employeeDisplayName}</div>
            <div>{memo.note}</div>
          </div>
          <div className={styles.memocolor}>
            <MemoColor memoColorId={memo.memoColorId} memoColors={memoColors} />
          </div>
        </ListGroup.Item>
      ))
    ) : (
      <ListGroup.Item>従業員のメモはありません</ListGroup.Item>
    )}
  </ListGroup>
);
class AlertsList extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const {
      onChangePerPage,
      onPageChange,
      pageCount,
      initialPage,
      perPage,
      perPages,
      totalCount,
      displayFrom,
      displayTo,
      memos,
      memoColors,
      isListLoading
    } = this.props;
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">従業員のメモ一覧</h1>
        </div>
        <LoadingView isLoading={isListLoading}>
          <div className="l-wrap-s">
            <ShowListGroup memos={memos} memoColors={memoColors} />
            <LowerPager
              pageCount={pageCount}
              totalCount={totalCount}
              displayFrom={displayFrom}
              displayTo={displayTo}
              initialPage={initialPage - 1}
              forcePage={initialPage - 1}
              perPage={perPage}
              perPages={perPages}
              onPageChange={e => onPageChange(e.selected)}
              onPerPageChange={e => onChangePerPage(e.target.value)}
            />
          </div>
        </LoadingView>
      </div>
    );
  }
}

export default connect(
  state => ({
    pageCount: getListPageCount(REDUCER_NAME, state),
    totalCount: getListTotalCount(REDUCER_NAME, state),
    displayFrom: getListDisplayFrom(REDUCER_NAME, state),
    displayTo: getListDisplayTo(REDUCER_NAME, state),
    initialPage: getListPage(REDUCER_NAME, state),
    perPage: getListPerPage(REDUCER_NAME, state),
    perPages: getSelectOptions(state, 'perPages'),
    memoColors: getSelectOptions(state, 'memoColors'),
    memos: getList(REDUCER_NAME, state) || [],
    isListLoading: getIsLoading(REDUCER_NAME, state)
  }),
  dispatch => ({
    fetch: () => {
      dispatch(fetchTodayMemos());
    },
    onPageChange: query => {
      dispatch(addPageQuery(REDUCER_NAME, query + 1));
      dispatch(fetchTodayMemos());
    },
    onChangePerPage: query => {
      dispatch(addPageQuery(REDUCER_NAME, 1));
      dispatch(addPerPageQuery(REDUCER_NAME, query));
      dispatch(fetchTodayMemos());
    }
  })
)(AlertsList);
