import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import BonusAllowanceDetail from './BonusAllowanceDetail';
import BonusDeductionDetail from './BonusDeductionDetail';
import BonusSummaryDetail from './BonusSummaryDetail';
import BonusActionSection from './BonusActionSection';
import styles from './BonusTableData.scss';

class BonusDetailShow extends React.Component {
  constructor(props) {
    super(props);

    this.changeSelectingEmployeeBonus = this.changeSelectingEmployeeBonus.bind(this);

    const defaultBonusId = parseInt(this.props.bonusId, 10) || get(this.props.allBonus, [0, 'id']);
    this.state = {
      selectingBonusId: defaultBonusId
    };
  }

  changeSelectingEmployeeBonus(value) {
    this.setState({
      ...this.state,
      selectingBonusId: value
    });
  }

  render() {
    const { allBonus = [] } = this.props;
    const selectingBonus = allBonus.find(item => item.id === this.state.selectingBonusId);
    // 賞与がない時の画面
    if (_.isEmpty(allBonus)) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">登録済みの賞与データがありません</p>
            <p className="u-ta-c">ご不明な点は管理者までお問い合わせください</p>
          </div>
        </div>
      );
    }
    return (
      <div className="l-wrap-xl l-contents-wrap">
        <BonusActionSection
          selectingBonusId={this.state.selectingBonusId}
          bonus={selectingBonus}
          allBonus={allBonus}
          changeSelectingEmployeeBonus={this.changeSelectingEmployeeBonus}
        />

        <div className={styles.payment}>
          <BonusAllowanceDetail bonus={selectingBonus} />
          <BonusDeductionDetail bonus={selectingBonus} />
          <BonusSummaryDetail bonus={selectingBonus} />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  allBonus: (getItem(REDUCER_NAME, state) || {}).allBonus
}))(BonusDetailShow);
