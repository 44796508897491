import { combineReducers } from 'redux';
import { getFormValues } from 'redux-form';
import axios from 'axios';
import humps from 'humps';
import _ from 'lodash';

import * as Urls from 'src/constants/EndpointUrls';
import { excludeValuesIfNotDefined } from 'src/utils/Http';
import { saveCurrentQueries } from 'src/QueriesStorageApi';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { newItemFailure, editItemFailure, getItemErrors } from 'src/reducers/common/itemReducer';
import { getListQueries, fetchList, fetchListSuccess, fetchListFailure } from 'src/reducers/common/listReducer';
import { REPORT_WITHHOLDING_SLIP_CONFIRM_FORM } from 'src/constants/FormNames';

export const REDUCER_NAME = 'reportWithholdingSlips';
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));

export const getWithholdingSlipFormValues = state => getFormValues(REPORT_WITHHOLDING_SLIP_CONFIRM_FORM)(state);

export const getConfirmWithholdingSlipErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return errors;
};

export const getUnconfirmWithholdingSlipErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return errors;
};

// Async Action Creators
export const fetchReportWithholdingSlips = () => async (dispatch, getState) => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    let queries = getListQueries(REDUCER_NAME, getState());
    queries = excludeValuesIfNotDefined(queries);
    saveCurrentQueries(queries, REDUCER_NAME);

    const response = await axios.get(Urls.REPORT_WITHHOLDING_SLIP_SEARCH, {
      params: humps.decamelizeKeys(queries)
    });
    const payload = {
      data: response.data.payload.employees,
      pageCount: response.data.payload.totalPages,
      totalCount: response.data.payload.totalCount,
      displayFrom: response.data.payload.displayFrom,
      displayTo: response.data.payload.displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const confirmWithholdingSlip = (employeeId, withholdingSlipYear, callback) => async (dispatch, getState) => {
  try {
    const state = getState();
    const form = getFormValues(REPORT_WITHHOLDING_SLIP_CONFIRM_FORM)(state);
    const releaseDate = _.get(form, 'releaseDate');
    const releaseHour = _.get(form, 'releaseHour');

    const httpBody = humps.decamelizeKeys({
      employeeId,
      withholdingSlipYear,
      canRelease: _.get(form, `canRelease`),
      releaseDate: `${releaseDate} ${releaseHour}:00:00`,
      sendNotifyToEmployee: _.get(form, `sendNotifyToEmployee`)
    });
    await axios.put(Urls.REPORT_WITHHOLDING_SLIP_CONFIRM, httpBody);
    dispatch(fetchReportWithholdingSlips());
    callback();
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const unconfirmWithholdingSlip = (employeeId, withholdingSlipYear, callback) => async dispatch => {
  try {
    const httpBody = humps.decamelizeKeys({
      employeeId,
      withholdingSlipYear
    });
    await axios.put(Urls.REPORT_WITHHOLDING_SLIP_UNCONFIRM, httpBody);
    dispatch(fetchReportWithholdingSlips());
    callback();
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
