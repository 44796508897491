import { change } from 'redux-form';
import { connect } from 'react-redux';
import { FORM_NAME_WAGE_LEDGER_SEARCH } from 'src/reducers/searchForm';
import SelectPeriodForm from '../components/SelectPeriodForm';

const mapDispatchToProps = dispatch => ({
  changePeriodSelector: newValue => {
    dispatch(change(FORM_NAME_WAGE_LEDGER_SEARCH, 'yearSelected', newValue));
  }
});

export default connect(null, mapDispatchToProps)(SelectPeriodForm);
