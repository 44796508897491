import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { DateField, CheckboxField, RadioField, SelectField } from 'jbc-front/components/Form';

import { RELEASE_STATUSES } from 'src/constants/Options';
import { get24HoursOptions } from 'src/utils/Form';
import selector from 'src/utils/Utils';
import { isYYYYMMDDFormat } from 'src/utils/CustomValidators';
import { ErrorsSection, LoadingWithDescription } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { REPORT_WITHHOLDING_SLIP_CONFIRM_FORM } from 'src/constants/FormNames';
import {
  confirmWithholdingSlip,
  getConfirmWithholdingSlipErrors,
  getWithholdingSlipFormValues,
  clearErrors
} from 'src/reducers/reports/reportWithholdingSlips';
import styles from './ConfirmWithholdingModal.scss';

const ConfirmWithholdingInsideForm = ({
  handleSubmit,
  submitting,
  submit,
  cancelBtnClick,
  selectedCanRelease,
  errors,
  header
}) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        {submitting && <LoadingWithDescription text="確定中です。少々お待ちください。" />}
        <div>確定済の給与・賞与データを基に、選択した従業員の源泉徴収票を確定します。</div>
        <div className="u-mt30">
          <RadioField name="canRelease" options={RELEASE_STATUSES} />
        </div>
        {_.toInteger(selectedCanRelease) === 1 && (
          <div className={styles.selectWrap}>
            <p className="u-mb15">従業員への源泉徴収票公開日時</p>
            <DateField required name="releaseDate" label="公開日" validate={isYYYYMMDDFormat} />
            <SelectField label="公開時刻" name="releaseHour" options={get24HoursOptions()} width={100} required />
            <div>
              <CheckboxField name="sendNotifyToEmployee" label="公開時に通知メールも送信" />
            </div>
          </div>
        )}
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="確定" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let ConfirmWithholdingForm = reduxForm({
  form: REPORT_WITHHOLDING_SLIP_CONFIRM_FORM
})(ConfirmWithholdingInsideForm);
const mapStateToProp = (state, ownProps) => ({
  initialValues: {
    canRelease: ownProps.canRelease
  },
  selectedCanRelease: selector(getWithholdingSlipFormValues(state), 'canRelease'),
  errors: getConfirmWithholdingSlipErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(confirmWithholdingSlip(ownProps.employeeId, ownProps.withholdingSlipYear, ownProps.cancelBtnClick)),
  clearErrors: dispatch(clearErrors())
});

ConfirmWithholdingForm = connect(mapStateToProp, mapDispatchToProp)(ConfirmWithholdingForm);

class ConfirmWithholdingModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const header = '源泉徴収票の確定';
    const { isOpen, hideModal, withholdingSlipYear, employeeId, canRelease } = this.props;

    return (
      <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
        <ConfirmWithholdingForm
          cancelBtnClick={hideModal}
          header={header}
          withholdingSlipYear={withholdingSlipYear}
          employeeId={employeeId}
          canRelease={canRelease}
        />
      </CommonModal>
    );
  }
}

export default ConfirmWithholdingModal;
