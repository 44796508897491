import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import compose from 'lodash/fp/compose';
import _ from 'lodash';
import { OFFICE_FORM } from 'src/constants/FormNames';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, newOffice } from 'src/reducers/offices/offices';
import moment from 'moment';
import { setExtras } from 'src/reducers/common/listReducer';
import NewOffice from '../components/NewOffice';

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    office: ownProps.values.office,
    clientLabourConsultant: ownProps.values.clientLabourConsultant,
    clientBanks: ownProps.values.clientBanks,
    socialInsurance: { settingStartDate: moment().format('YYYY-MM-DD') },
    healthInsurance: _.merge(ownProps.values.insurances.health.current, { socialInsuranceOfficeId: 'self' }),
    pensionInsurance: ownProps.values.insurances.pension.current,
    labourInsurance: { settingStartDate: moment().format('YYYY-MM-DD') },
    labourInsuranceContactInfo: ownProps.values.insurances.accident.current.contactInfo,
    employmentInsurance: _.merge(ownProps.values.insurances.employment.current, {
      employmentInsuranceOfficeId: 'self'
    }),
    accidentInsurance: _.merge(ownProps.values.insurances.accident.current, {
      occupationAccidentInsuranceOfficeId: 'self'
    }),
    mainOfficeContactInfo: ownProps.values.mainOffice
  },
  isInitialized: ownProps.values.isInitialized,
  errors: getItemErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = (dispatch, ownProps) => {
  dispatch(setExtras(REDUCER_NAME, ownProps.values));

  return {
    onSubmit: data => dispatch(newOffice(data))
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: OFFICE_FORM
  })
)(NewOffice);
