import React from 'react';
import { connect } from 'react-redux';
import { FormSection, FieldArray } from 'redux-form';
import _ from 'lodash';

import { TextField, BoxDouble, Section } from 'jbc-front/components/Form';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import { PlusSquare, DeleteSquare } from 'jbc-front/components/icons';
import { isMoneyNumber, isRequiredNotNegativeMoneyNumber } from 'src/utils/CustomValidators';
import selector, { fetchValueByIndexAndField, isIncluded, isEqual } from 'src/utils/Utils';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { defaultCommutingItem } from 'src/utils/Form';
import { CLIENT_COMMUTING_FEE_SETTINGS } from 'src/constants/EndpointUrls';
import styles from './CommutingsSection.scss';

const getFormEmployeeData = state => getEmployeeFormValues(state).employee;
const getSelectedPaymentDurationUnit = (state, key) =>
  _.get(getEmployeeFormValues(state), `${key}.paymentDurationUnit`);
const getSelectedMeansOfCommunication = state => getFormEmployeeData(state).meansOfCommunication;

const isCommutingByTrain = commutingBy => isIncluded(commutingBy, 'train');
const isCommutingByBus = commutingBy => isIncluded(commutingBy, 'bus');
const isCommutingByCar = commutingBy => isIncluded(commutingBy, 'car');

const isMonthlyPayment = paymentDurationType => isEqual(paymentDurationType, 'per_month');
const isEach3MonthsPayment = paymentDurationType => isEqual(paymentDurationType, 'per_3months');
const isEach6MonthsPayment = paymentDurationType => isEqual(paymentDurationType, 'per_6months');
const isWorkingDaysPayment = paymentDurationType => isEqual(paymentDurationType, 'per_working_days');

// Check app/models/concerns/payment_duration_concern.rb for more details
const monthlyPaymentOptions = paymentDurationTypes => paymentDurationTypes.filter(p => p.value === 'monthly');
const each3MonthsPaymentOptions = paymentDurationTypes =>
  paymentDurationTypes.filter(p => [2, 3, 4].includes(p.numberValue));
const each6MonthsPaymentOptions = paymentDurationTypes =>
  paymentDurationTypes.filter(p => [5, 6, 7, 8, 9, 10].includes(p.numberValue));
const paymentOptions = allOptions => paymentDurationUnit => {
  if (isMonthlyPayment(paymentDurationUnit)) {
    return monthlyPaymentOptions(allOptions);
  } else if (isEach3MonthsPayment(paymentDurationUnit)) {
    return each3MonthsPaymentOptions(allOptions);
  } else if (isEach6MonthsPayment(paymentDurationUnit)) {
    return each6MonthsPaymentOptions(allOptions);
  }
  return [];
};
const isShowPaymentOptions = paymentDurationUnit =>
  isEach3MonthsPayment(paymentDurationUnit) || isEach6MonthsPayment(paymentDurationUnit);
const PaymentDurationUnitNote = () => (
  <div>
    <ul className={styles.durationList}>
      <li>
        ◆1ヶ月<br />
        「（電車・バスの場合）定期代」「（車の場合）支給額」の合計が毎月支給されます。
      </li>
      <li>
        ◆3ヶ月<br />
        「 （電車・バスの場合）定期代」「 （車の場合）支給額」の合計が3ヶ月ごとに支給されます。
      </li>
      <li>
        ◆6ヶ月<br />
        「 （電車・バスの場合）定期代」「 （車の場合）支給額」の合計が6ヶ月ごとに支給されます。
      </li>
      <li>
        ◆出勤日数に応じて毎月支給<br />
        「 （電車・バスの場合）片道運賃×2×出勤日数」「 （車の場合）支給額×出勤日数」の合計が毎月支給されます。
      </li>
    </ul>
    <ul className="m-list-notes">
      <li>※課税・非課税の金額は、税制に基いて自動で計算されます。</li>
      <li>
        ※<a href={CLIENT_COMMUTING_FEE_SETTINGS} className={styles.link}>
          通勤手当の上限設定
        </a>で設定された上限値を超過する場合は、上限値に自動で変更されます。
      </li>
    </ul>
  </div>
);
const CommutingByTrain = ({ selectedPaymentDurationUnit, paymentDurationUnits, paymentDurationTypes }) => (
  <div>
    <BoxDouble>
      <TextField label="乗駅" name="firstStop" type="text" />
      <TextField label="降駅" name="lastStop" type="text" />
    </BoxDouble>
    <TextField label="経由地" name="middleStop" type="text" />
    <ExtensionSelectField
      label="支給単位"
      name="paymentDurationUnit"
      options={paymentDurationUnits}
      note={<PaymentDurationUnitNote />}
    />
    {isShowPaymentOptions(selectedPaymentDurationUnit) && (
      <ExtensionSelectField
        label="支給月"
        name="paymentDurationType"
        options={paymentOptions(paymentDurationTypes)(selectedPaymentDurationUnit)}
      />
    )}
    <BoxDouble>
      <TextField
        label="片道運賃"
        name="oneWayFare"
        type="text"
        yen
        validate={[isMoneyNumber, isRequiredNotNegativeMoneyNumber]}
        disabled={!isWorkingDaysPayment(selectedPaymentDurationUnit)}
        note="支給単位が「1ヶ月」「3ヶ月」「6ヶ月」の場合は、給与計算時に「定期代」の値を使用し、「片道運賃」の値は使用しません。片道運賃を使用して通勤手当を支給したい場合は支給単位を「出勤日数に応じて毎月支給」に変更してください。"
      />
      <TextField
        label="定期代"
        name="paymentAmount"
        type="text"
        yen
        validate={[isMoneyNumber, isRequiredNotNegativeMoneyNumber]}
        disabled={isWorkingDaysPayment(selectedPaymentDurationUnit)}
        note="支給単位が「出勤日数に応じて毎月支給」の場合は、給与計算時に「片道運賃」の値を使用し、「定期代」の値は使用しません。"
      />
    </BoxDouble>
  </div>
);

const CommutingByBus = ({ selectedPaymentDurationUnit, paymentDurationUnits, paymentDurationTypes }) => (
  <div>
    <BoxDouble>
      <TextField label="乗車停留所" name="firstStop" type="text" />
      <TextField label="降車停留所" name="lastStop" type="text" />
    </BoxDouble>
    <TextField label="経由地" name="middleStop" type="text" />
    <ExtensionSelectField
      label="支給単位"
      name="paymentDurationUnit"
      options={paymentDurationUnits}
      note={<PaymentDurationUnitNote />}
    />
    {isShowPaymentOptions(selectedPaymentDurationUnit) && (
      <ExtensionSelectField
        label="支給月"
        name="paymentDurationType"
        options={paymentOptions(paymentDurationTypes)(selectedPaymentDurationUnit)}
      />
    )}
    <BoxDouble>
      <TextField
        label="片道運賃"
        name="oneWayFare"
        type="text"
        yen
        validate={[isMoneyNumber, isRequiredNotNegativeMoneyNumber]}
        disabled={!isWorkingDaysPayment(selectedPaymentDurationUnit)}
        note="支給単位が「1ヶ月」「3ヶ月」「6ヶ月」の場合は、給与計算時に「定期代」の値を使用し、「片道運賃」の値は使用しません。片道運賃を使用して通勤手当を支給したい場合は支給単位を「出勤日数に応じて毎月支給」に変更してください。"
      />
      <TextField
        label="定期代"
        name="paymentAmount"
        type="text"
        yen
        validate={[isMoneyNumber, isRequiredNotNegativeMoneyNumber]}
        disabled={isWorkingDaysPayment(selectedPaymentDurationUnit)}
        note="支給単位が「出勤日数に応じて毎月支給」の場合は、給与計算時に「片道運賃」の値を使用し、「定期代」の値は使用しません。"
      />
    </BoxDouble>
  </div>
);

const CommutingByCar = ({
  selectedPaymentDurationUnit,
  paymentDurationUnits,
  paymentDurationTypes,
  commutingDistances,
  vehicleTypes
}) => (
  <div>
    <ExtensionSelectField label="車両種別" name="vehicleType" addBlankOption options={vehicleTypes} />
    <BoxDouble>
      <TextField label="出発地" name="firstStop" type="text" />
      <TextField label="到着地" name="lastStop" type="text" />
    </BoxDouble>
    <ExtensionSelectField label="片道の通勤距離" name="commutingDistance" options={commutingDistances} />
    <ExtensionSelectField
      label="支給単位"
      name="paymentDurationUnit"
      options={paymentDurationUnits}
      note={<PaymentDurationUnitNote />}
    />
    {isShowPaymentOptions(selectedPaymentDurationUnit) && (
      <ExtensionSelectField
        label="支給月"
        name="paymentDurationType"
        options={paymentOptions(paymentDurationTypes)(selectedPaymentDurationUnit)}
      />
    )}
    <TextField
      label="支給額"
      name="paymentAmount"
      type="text"
      yen
      validate={[isMoneyNumber, isRequiredNotNegativeMoneyNumber]}
    />
  </div>
);

let CommutingItem = ({
  formKey,
  meansOfCommunication,
  remove,
  selectedPaymentDurationUnit,
  paymentDurationUnits,
  paymentDurationTypes,
  commutingDistances,
  vehicleTypes
}) => {
  const commonProps = { formKey, selectedPaymentDurationUnit, paymentDurationUnits, paymentDurationTypes };
  const carProps = { commutingDistances, vehicleTypes };

  return (
    <div>
      {isCommutingByTrain(meansOfCommunication) && <span className="text bold">電車</span>}
      {isCommutingByBus(meansOfCommunication) && <span className="text bold">バス</span>}
      {isCommutingByCar(meansOfCommunication) && <span className="text bold">車・二輪車等</span>}
      <Section title="経路" icon={<DeleteSquare onClick={remove} />}>
        <FormSection name={formKey}>
          {isCommutingByTrain(meansOfCommunication) && <CommutingByTrain {...commonProps} />}
          {isCommutingByBus(meansOfCommunication) && <CommutingByBus {...commonProps} />}
          {isCommutingByCar(meansOfCommunication) && <CommutingByCar {...commonProps} {...carProps} />}
        </FormSection>
      </Section>
    </div>
  );
};
// TODO: connect廃止してuseSelectorにする
/* eslint-disable react-redux/connect-prefer-named-arguments */
/* eslint-disable react-redux/prefer-separate-component-file */
CommutingItem = connect((state, ownProps) => ({
  selectedPaymentDurationUnit: getSelectedPaymentDurationUnit(state, ownProps.formKey),
  paymentDurationUnits: getSelectOptions(state, 'paymentDurationUnits'),
  paymentDurationTypes: getSelectOptions(state, 'paymentDurationTypes'),
  commutingDistances: getSelectOptions(state, 'commutingDistances'),
  vehicleTypes: getSelectOptions(state, 'vehicleTypes')
}))(CommutingItem);
/* eslint-enable react-redux/prefer-separate-component-file */
/* eslint-enable react-redux/connect-prefer-named-arguments */
const CommutingProxy = ({ type, fields, items }) => (
  <div>
    {type !== 'walking' &&
      items &&
      items.length > 0 &&
      items.map(item => (
        <CommutingItem
          formKey={item.name}
          keyIndex={item.name}
          key={item.name}
          remove={() => {
            fields.remove(item.index);
            if (fields.getAll().filter(field => field.meansOfCommunication === type).length === 1) {
              fields.push(defaultCommutingItem(type));
            }
          }}
          {...item}
        />
      ))}

    {type !== 'walking' &&
      type !== 'car' && (
        <div className="u-ta-r">
          <div
            role="button"
            tabIndex="0"
            onClick={() => fields.push(defaultCommutingItem(type))}
            className="u-txt-addinfo"
          >
            <PlusSquare size={20} className="u-txt-addinfo-icon" />経路を追加
          </div>
        </div>
      )}
  </div>
);

let Commutings = props => {
  const { fields, selectedMeansOfCommunication, fetchValueByIndex } = props;

  let displayItems = fields
    .map((commutingItem, index) => {
      const meansOfCommunication = fetchValueByIndex('meansOfCommunication')(index);

      if (isIncluded(selectedMeansOfCommunication, meansOfCommunication)) {
        return { name: commutingItem, meansOfCommunication, index };
      }
      return undefined;
    })
    .filter(item => !!item);

  // 電車＋車・電車＋バスの場合は１つ目を必ず電車にする
  if (displayItems.length > 1) {
    displayItems = _.orderBy(displayItems, ['meansOfCommunication'], ['desc']);
  }

  const itemsGroupedByMeansOfCommunication = _.groupBy(displayItems, 'meansOfCommunication');
  const keys = Object.keys(itemsGroupedByMeansOfCommunication);

  return (
    <div>
      {keys &&
        keys.length > 0 &&
        keys.map(meansOfCommunication => (
          <CommutingProxy
            key={meansOfCommunication}
            type={meansOfCommunication}
            fields={fields}
            selectedMeansOfCommunication={selectedMeansOfCommunication}
            items={itemsGroupedByMeansOfCommunication[meansOfCommunication]}
          />
        ))}
    </div>
  );
};

// TODO: connect廃止してuseSelectorにする
/* eslint-disable react-redux/connect-prefer-named-arguments */
/* eslint-disable react-redux/prefer-separate-component-file */
Commutings = connect(state => ({
  selectedMeansOfCommunication: getSelectedMeansOfCommunication(state),
  fetchValueByIndex: fetchValueByIndexAndField(selector(getEmployeeFormValues(state), 'employeeCommutings'))
}))(Commutings);
/* eslint-enable react-redux/prefer-separate-component-file */
/* eslint-enable react-redux/connect-prefer-named-arguments */

let CommutingSelections = props => {
  const { meansOfCommunications } = props;

  return (
    <div>
      <ExtensionSelectField label="通勤手段" name="meansOfCommunication" options={meansOfCommunications} />
    </div>
  );
};

const mapStateToProps = state => ({
  meansOfCommunications: getSelectOptions(state, 'meansOfCommunications')
});
// TODO: connect廃止してuseSelectorにする
/* eslint-disable react-redux/prefer-separate-component-file */
CommutingSelections = connect(mapStateToProps)(CommutingSelections);
/* eslint-enable react-redux/prefer-separate-component-file */

const CommutingsSection = () => (
  <div>
    <FormSection name="employee" component={CommutingSelections} />
    <FieldArray name="employeeCommutings" component={Commutings} />
  </div>
);
export default connect()(CommutingsSection);
