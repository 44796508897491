import React from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { TextField } from 'jbc-front/components/Form';
import { OFFICE_STATION_MODAL_FORM } from 'src/constants/FormNames';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import saveOfficeStationSetting from 'src/reducers/settings/external_coordinations';
import styles from './OfficeStationModal.scss';

const OfficeStationModal = ({ isOpen, hideModal, handleSubmit, submit, submitting, pristine }) => {
  const formValues = useSelector(state => getFormValues(OFFICE_STATION_MODAL_FORM)(state));
  const isInputForm = isEmpty(formValues?.stationId && formValues?.password && formValues?.loginId); // 全部入力されたらボタン押下可能にする

  return (
    <CommonModal isOpen={isOpen} hideModal={hideModal} ariaHideApp={false} closeOnClick>
      <CommonModal.Header hideModal={hideModal}>オフィスステーション連携情報</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          <div className={styles.flex}>
            <span className={styles.label}>StationID</span>
            <div className={styles.inputItem}>
              <TextField name="stationId" noLabel />
            </div>
          </div>
          <div className={styles.flex}>
            <span className={styles.label}>ログインID</span>
            <div className={styles.inputItem}>
              <TextField name="loginId" noLabel />
            </div>
          </div>
          <div className={styles.flex}>
            <span className={styles.label}>パスワード</span>
            <div className={styles.inputItem}>
              <TextField name="password" noLabel type="password" />
            </div>
          </div>
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <div>
          <Button onClick={hideModal} className="u-mr20">
            キャンセル
          </Button>
          <Button
            primary
            onClick={() => {
              submit();
            }}
            disabled={submitting || pristine || isInputForm}
          >
            接続テストして保存
          </Button>
        </div>
      </CommonModal.Footer>
    </CommonModal>
  );
};

const OfficeStationModalForm = reduxForm({
  form: OFFICE_STATION_MODAL_FORM,
  enableReinitialize: true
})(OfficeStationModal);

const OfficeStationModalFormWrapper = props => {
  const stationId = get(props, 'officeStationSetting.stationId');
  const loginId = get(props, 'officeStationSetting.loginId');
  const lockVersion = get(props, 'officeStationSetting.lockVersion');
  const initialValues = {
    stationId,
    loginId,
    lockVersion
  };
  const dispatch = useDispatch();
  const onSubmit = data => dispatch(saveOfficeStationSetting(data));
  return <OfficeStationModalForm {...props} initialValues={initialValues} onSubmit={onSubmit} />;
};

export default OfficeStationModalFormWrapper;
