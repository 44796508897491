import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS, CLIENT_CSV_FORMAT_NEW } from 'src/constants/EndpointUrls';
import { REDUCER_NAME } from 'src/reducers/clients/csvFormats';
import { setList } from 'src/reducers/common/listReducer';
import { Plus } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import ClientCsvFormatsTable from './components/ClientCsvFormatsTable';

const ClientCsvFormatList = ({ dispatch, clientCsvFormats }) => {
  dispatch(setList(REDUCER_NAME, { data: clientCsvFormats }));

  return (
    <div className="l-wrap-xl l-contents-wrap">
      <div className="u-mb20">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="CSVフォーマット一覧" />
        </BreadCrumb>
      </div>
      <div>
        <h1 className="m-title-main u-mb50">CSVフォーマット一覧</h1>
        <ActionButton as="a" href={CLIENT_CSV_FORMAT_NEW} icon={<Plus size={10} />}>
          新規作成
        </ActionButton>
      </div>
      <ClientCsvFormatsTable />
    </div>
  );
};

export default connect()(ClientCsvFormatList);
