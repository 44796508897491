import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import ChangeRow from 'jbc-front/components/icons/ChangeRow';

const AddActionBtn = ({ onClick, label = '追加', ...rest }) => (
  <ActionButton as="button" icon={<ChangeRow />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default AddActionBtn;
