import React, { useState, useEffect, useRef } from 'react';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import Balloon from 'src/components/Balloon';
import { isJobSuccess } from 'src/reducers/JobStatus';
import DownloadCsvModalContainer from '../containers/DownloadCsvModalContainer';
import styles from './DownloadCsv.scss';

const DownloadCsv = props => {
  const { isCreating } = props;
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const isInitialFetched = useRef(false);

  useEffect(() => {
    if (!isInitialFetched.current) {
      if (!_.isEmpty(props.downloadEmployeeCsvJobInfo) && !isJobSuccess(props.downloadEmployeeCsvJobInfo.status)) {
        props.fetchDownloadEmployeeCsvJobStatus();
      }
      isInitialFetched.current = true;
    }
  });

  return (
    <div className={styles.employeesDownload}>
      {isCreating && (
        <Balloon className={styles.employeesDownloadBalloon}>
          作成に時間がかかるため、完了しましたらメールにてお送りいたします。
        </Balloon>
      )}
      <Button radius onClick={() => setIsDownloadModalOpen(true)} disabled={isCreating}>
        従業員データダウンロード
      </Button>
      <DownloadCsvModalContainer isOpen={isDownloadModalOpen} hideModal={() => setIsDownloadModalOpen(false)} />
    </div>
  );
};

export default DownloadCsv;
