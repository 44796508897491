import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { TextField, CheckboxField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { PayrollLogo } from 'jbc-front/components/icons';

import { LOGIN_FORM } from 'src/constants/FormNames';
import { USER_RESET_PASSWORD_NEW, OAUTH_JBC_LOGIN } from 'src/constants/EndpointUrls';
import { ErrorsSection } from 'src/components';
import { getLoginMessages } from 'src/reducers/users/currentUser';

import styles from './LoginForm.scss';

const LoginForm = props => {
  const { handleSubmit, submit, submitting, errors } = props;
  const [shouldShowCidInput, handleClickCollapse] = useState(false);
  const WHAT_IS_JBC_SHARE_ID_LINK =
    'https://jobcan-id.zendesk.com/hc/ja/articles/900000789243';

  return (
    <React.Fragment>
      <div className={styles.loginWrap}>
        <form onSubmit={handleSubmit} className={styles.loginBox}>
          <h1 className={styles.loginLogo}>
            <PayrollLogo size={200} />
          </h1>
          <p className={styles.loginTitle}>ログイン</p>
          <ErrorsSection errors={errors} />
          <div className={styles.loginField}>
            <div className={styles.loginCollapseWrap} style={shouldShowCidInput ? { height: '170px' } : {}}>
              <TextField name="email" required label="メールアドレス" />
              {shouldShowCidInput && <TextField name="client_code" label="会社ID" />}
              <a
                role="button"
                tabIndex={-1}
                className={`${styles.loginCid} u-txt-link`}
                onClick={() => handleClickCollapse(!shouldShowCidInput)}
              >
                {shouldShowCidInput ? '会社IDの入力を閉じる' : '複数の会社に登録されていますか？'}
              </a>
            </div>
            <TextField name="password" required label="パスワード" type="password" />
            <CheckboxField name="rememberMe" label="ログインを記憶" />
            <div className={styles.loginButton}>
              <Button primary widthWide onClick={submit} disabled={submitting}>
                ログイン
              </Button>
            </div>
            <a href={USER_RESET_PASSWORD_NEW} className={styles.loginForgot}>
              パスワードをお忘れですか？
            </a>
          </div>
          <div className={styles.commonLoginField}>
            <Button as="a" success widthWide href={OAUTH_JBC_LOGIN}>
              ジョブカン共通IDでログイン
            </Button>
            <a href={WHAT_IS_JBC_SHARE_ID_LINK} className={styles.loginForgot}>
              ジョブカン共通IDとは？
            </a>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
};

const LoginFormConnected = reduxForm({
  form: LOGIN_FORM
})(LoginForm);

export default connect(state => ({
  errors: getLoginMessages(state) || []
}))(LoginFormConnected);
