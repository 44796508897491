import React, { useState } from 'react';
import { debounce } from 'lodash';
import axios from 'axios';

import { TextField, SelectField, BoxDouble } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { Copy } from 'jbc-front/components/icons';

import * as Urls from 'src/constants/EndpointUrls';
import { isCorporateNumber, isNumber, zenkakuKatakanaLoose } from 'src/utils/CustomValidators';
import { PostalCodeField, TelField } from 'src/components';
import { handlePostalCodeChange } from 'src/utils/Form';
import styles from './Common.scss';

const OfficeSection = props => {
  const {
    prefectures,
    formChange,
    formTouch,
    complementAddress,
    socialInsuranceSettingStartDate,
    healthInsuranceType,
    fetchSocialInsuranceRates,
    formSectionName = 'office',
    copyMainOffice,
    isMainOffice
  } = props;

  const [taxOffices, setTaxOffices] = useState(props.taxOffices);

  const fetchTaxOffices = async prefectureId => {
    try {
      if (prefectureId) {
        const response = await axios.get(Urls.SEARCH_TAX_OFFICES_URL, { params: { prefecture_id: prefectureId } });
        const payload = response.data.payload;
        setTaxOffices(payload.taxOffices);
        formChange(`${formSectionName}.taxOfficeId`, payload.taxOffices[0].id);
      } else {
        formChange(`${formSectionName}.taxOfficeId`, null);
      }
    } catch (exception) {
      setTaxOffices([]);
    }
  };

  const notifyPostalCodeChanged = async code => {
    try {
      const response = await axios.get(Urls.SEARCH_POSTAL_CODE_URL, { params: { code } });
      const payload = response.data.payload;

      complementAddress(payload.postalInfo.prefectureId, payload.postalInfo.city, payload.postalInfo.street);

      if (healthInsuranceType === 'society' && formSectionName === 'office') {
        fetchSocialInsuranceRates(
          healthInsuranceType,
          socialInsuranceSettingStartDate,
          payload.postalInfo.prefectureId
        );
      }

      // カナ住所
      const { prefectureKana, cityKana, streetKana } = payload.postalInfo;
      if (formSectionName === 'employmentInsurance') {
        formChange(`${formSectionName}.contactInfo.addressKana`, `${prefectureKana}${cityKana}${streetKana}`);
      } else {
        formChange(`${formSectionName}.addressKana`, `${prefectureKana}${cityKana}${streetKana}`);
      }
    } catch (exception) {
      complementAddress('', '', '');
      formTouch(`${formSectionName}.postalCode`);
    }
  };

  const handlePostalCodeChanged = (e, inputText) =>
    handlePostalCodeChange(e)(inputText, debounce(notifyPostalCodeChanged, 500));

  const handleTaxOfficePrefectureIdChange = (e, inputText) => {
    fetchTaxOffices(inputText);
  };

  const handleInsuranceTypeChange = (_e, value) => {
    if (healthInsuranceType !== 'society' || formSectionName !== 'office') {
      return;
    }
    fetchSocialInsuranceRates(healthInsuranceType, socialInsuranceSettingStartDate, value);
  };
  return (
    <div>
      {!isMainOffice && (
        <div className={styles.buttonWrap}>
          <div className={styles.button}>
            <Button onClick={copyMainOffice} icon={<Copy size={17} />} widthAuto>
              本社の事業所情報をコピーする
            </Button>
          </div>
        </div>
      )}
      <TextField required label="事業所名" name="name" maxLength="255" />

      <TextField label="事業所名（カナ）" name="nameKana" validate={zenkakuKatakanaLoose} />

      {isMainOffice &&
        formSectionName === 'office' && (
          <React.Fragment>
            <TextField
              label="法人番号"
              name="corporateNumber"
              maxLength="13"
              validate={[isCorporateNumber, isNumber]}
            />
          </React.Fragment>
        )}

      <PostalCodeField handleOnChange={handlePostalCodeChanged} />

      <BoxDouble>
        <SelectField label="都道府県" name="prefectureId" options={prefectures} onChange={handleInsuranceTypeChange} />
        <TextField label="市区町村" name="city" />
      </BoxDouble>

      <BoxDouble>
        <TextField label="丁目・番地" name="street" />
        <TextField label="建物名・部屋番号" name="building" />
      </BoxDouble>

      <TextField label="住所（カナ）" name="addressKana" validate={zenkakuKatakanaLoose} />

      <TelField />

      <p className={styles.heading}>事業主（代表者）</p>
      <TextField label="役職名" name="position" />
      <BoxDouble>
        <TextField label="姓" name="representativeLastName" maxLength="32" />
        <TextField label="名" name="representativeFirstName" maxLength="32" />
      </BoxDouble>

      {isMainOffice &&
        formSectionName === 'office' && (
          <React.Fragment>
            <p className={styles.heading}>管轄の税務署</p>
            <BoxDouble>
              <SelectField
                label="都道府県"
                name="taxOfficePrefectureId"
                options={prefectures}
                onChange={handleTaxOfficePrefectureIdChange}
              />
              <SelectField label="税務署名" name="taxOfficeId" options={taxOffices} />
            </BoxDouble>
          </React.Fragment>
        )}
    </div>
  );
};

export default OfficeSection;
