import axios from 'axios';
import _ from 'lodash';
import { combineReducers } from 'redux';
import humps from 'humps';
import { change as changeForm } from 'redux-form';

import { OFFICE_FORM } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { itemReducer, listReducer } from 'src/reducers/common';
import { newItemFailure } from 'src/reducers/common/itemReducer';
import { setExtras, getExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME as OFFICE_REDUCER_NAME } from 'src/reducers/offices/offices';
import { findLatestInsurances } from '../../utils/Utils';

export const REDUCER_NAME = 'insurance';

// 手入力であればヒストリの値を、違えばマスタの値を表示する
export const changeFormPensionInsuranceRate = (pensionManualInput, settingStartDate) => async (dispatch, getState) => {
  try {
    if (pensionManualInput) {
      // 厚生年金
      const histories = getState().offices.list.extras.insurances.pension.history;
      if (histories === null) {
        return;
      }
      const startDate = settingStartDate.replaceAll('-', '').substring(0, 6);
      const insuranceHistory = findLatestInsurances(startDate, histories);
      // historyの値を常に表示する
      dispatch(changeForm(OFFICE_FORM, 'pensionInsurance.rateForEmployee', insuranceHistory.rateForEmployee));
      dispatch(changeForm(OFFICE_FORM, 'pensionInsurance.rateForEmployer', insuranceHistory.rateForEmployer));
      dispatch(
        changeForm(OFFICE_FORM, 'pensionInsurance.childcareRateForEmployee', insuranceHistory.childcareRateForEmployee)
      );
      dispatch(
        changeForm(OFFICE_FORM, 'pensionInsurance.childcareRateForEmployer', insuranceHistory.childcareRateForEmployer)
      );
    } else {
      const masterPensionInsurances = getState().offices.list.extras.insurances.pension.master;

      if (masterPensionInsurances) {
        // マスタの値を常に表示する
        dispatch(changeForm(OFFICE_FORM, 'pensionInsurance.rateForEmployee', masterPensionInsurances.rateForEmployee));
        dispatch(changeForm(OFFICE_FORM, 'pensionInsurance.rateForEmployer', masterPensionInsurances.rateForEmployer));
        dispatch(
          changeForm(
            OFFICE_FORM,
            'pensionInsurance.childcareRateForEmployee',
            masterPensionInsurances.childcareRateForEmployee
          )
        );
        dispatch(
          changeForm(
            OFFICE_FORM,
            'pensionInsurance.childcareRateForEmployer',
            masterPensionInsurances.childcareRateForEmployer
          )
        );
      }
    }
  } catch (exception) {
    dispatch(newItemFailure(REDUCER_NAME, '料率が取得できませんでした。'));
  }
};

export const changeFormHealthInsuranceRate = (insuranceType, settingStartDate) => async (dispatch, getState) => {
  try {
    const insurances = getExtras(OFFICE_REDUCER_NAME, getState()).insurances;
    if (_.isNull(insurances.health.current.roundingType)) {
      dispatch(changeForm(OFFICE_FORM, 'healthInsurance.roundingType', 'total'));
    }

    // 健康保険タイプが協会けんぽかITS健保であればマスタを参照する
    if (insuranceType === 'society' || insuranceType === 'it') {
      const healthInsuranceMaster = insurances.health.master;
      dispatch(changeForm(OFFICE_FORM, 'healthInsurance.rateForEmployee', healthInsuranceMaster.rateForEmployee));
      dispatch(changeForm(OFFICE_FORM, 'healthInsurance.rateForEmployer', healthInsuranceMaster.rateForEmployer));
      dispatch(
        changeForm(
          OFFICE_FORM,
          'healthInsurance.nursingInsuranceRateForEmployee',
          healthInsuranceMaster.nursingInsuranceRateForEmployee
        )
      );
      dispatch(
        changeForm(
          OFFICE_FORM,
          'healthInsurance.nursingInsuranceRateForEmployer',
          healthInsuranceMaster.nursingInsuranceRateForEmployer
        )
      );
    }

    // その他組合健保であればhistoryを参照する
    if (insuranceType === 'other_association') {
      const healthInsuranceHistories = insurances.health.history;
      const startDate = settingStartDate.replaceAll('-', '').substring(0, 6);
      const insuranceHistory = healthInsuranceHistories
        ? findLatestInsurances(startDate, healthInsuranceHistories)
        : null;
      dispatch(
        changeForm(
          OFFICE_FORM,
          'healthInsurance.rateForEmployee',
          insuranceHistory && insuranceHistory.insuranceType === 'other_association'
            ? insuranceHistory.rateForEmployee
            : null
        )
      );
      dispatch(
        changeForm(
          OFFICE_FORM,
          'healthInsurance.rateForEmployer',
          insuranceHistory && insuranceHistory.insuranceType === 'other_association'
            ? insuranceHistory.rateForEmployer
            : null
        )
      );
      dispatch(
        changeForm(
          OFFICE_FORM,
          'healthInsurance.nursingInsuranceRateForEmployee',
          insuranceHistory && insuranceHistory.insuranceType === 'other_association'
            ? insuranceHistory.nursingInsuranceRateForEmployee
            : null
        )
      );
      dispatch(
        changeForm(
          OFFICE_FORM,
          'healthInsurance.nursingInsuranceRateForEmployer',
          insuranceHistory && insuranceHistory.insuranceType === 'other_association'
            ? insuranceHistory.nursingInsuranceRateForEmployer
            : null
        )
      );
    }
  } catch (exception) {
    dispatch(newItemFailure(REDUCER_NAME, '料率が取得できませんでした。'));
  }
};

export const fetchSocialInsuranceRates = (insuranceType, settingStartDate, prefectureId) => async (
  dispatch,
  getState
) => {
  try {
    const response = await axios.get(Urls.FETCH_SOCIAL_INSURANCE_RATES, {
      params: humps.decamelizeKeys({ insuranceType, settingStartDate, prefectureId })
    });
    const responseData = response.data;

    // 健康保険と厚生年金のマスタの値をextrasに保持する
    const extras = getState().offices.list.extras;

    extras.insurances.health.master = {
      rateForEmployee: responseData.healthInsuranceSalary ? responseData.healthInsuranceSalary.rateForEmployee : null,
      rateForEmployer: responseData.healthInsuranceSalary ? responseData.healthInsuranceSalary.rateForEmployer : null,
      nursingInsuranceRateForEmployee: responseData.nursingInsuranceSalary
        ? responseData.nursingInsuranceSalary.rateForEmployee
        : null,
      nursingInsuranceRateForEmployer: responseData.nursingInsuranceSalary
        ? responseData.nursingInsuranceSalary.rateForEmployer
        : null
    };

    extras.insurances.pension.master = {
      rateForEmployee: responseData.pensionInsuranceSalary.rateForEmployee,
      rateForEmployer: responseData.pensionInsuranceSalary.rateForEmployer,
      childcareRateForEmployee: responseData.pensionInsuranceChildcare.rateForEmployee,
      childcareRateForEmployer: responseData.pensionInsuranceChildcare.rateForEmployer
    };
    dispatch(setExtras(REDUCER_NAME, extras));
  } catch (exception) {
    dispatch(newItemFailure(REDUCER_NAME, '料率が取得できませんでした。'));
  }
};

export const fetchAccidentInsuranceRates = (insuranceType, settingStartDate) => async dispatch => {
  const response = await axios.get(Urls.FETCH_ACCIDENT_INSURANCE_RATES, {
    params: humps.decamelizeKeys({ insuranceType, settingStartDate })
  });
  const data = response.data;

  dispatch(changeForm(OFFICE_FORM, 'accidentInsurance.rateForEmployee', _.isNull(data) ? null : data.rateForEmployee));
  dispatch(changeForm(OFFICE_FORM, 'accidentInsurance.rateForEmployer', _.isNull(data) ? null : data.rateForEmployer));
};

export const fetchEmploymentInsuranceRates = (insuranceType, settingStartDate) => async dispatch => {
  const response = await axios.get(Urls.FETCH_EMPLOYMENT_INSURANCE_RATES, {
    params: humps.decamelizeKeys({ insuranceType, settingStartDate })
  });
  const data = response.data;

  dispatch(
    changeForm(OFFICE_FORM, 'employmentInsurance.rateForEmployee', _.isNull(data) ? null : data.rateForEmployee)
  );
  dispatch(
    changeForm(OFFICE_FORM, 'employmentInsurance.rateForEmployer', _.isNull(data) ? null : data.rateForEmployer)
  );
};

export default combineReducers({
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME),
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME)
});

// 自社かどうかの判定
export const isSelf = (officeId, insuranceId, insuranceOfficeId) => {
  if (insuranceId === 'self') {
    return true;
  }

  if (officeId === insuranceOfficeId) {
    return true;
  }

  return false;
};

export const fetchReferenceOffice = (insuranceType, settingStartDate, insuranceOfficeId) => async dispatch => {
  try {
    const response = await axios.get(Urls.FETCH_REFERENCE_OFFICE_URL, {
      params: humps.decamelizeKeys({ insuranceType, settingStartDate, insuranceOfficeId })
    });
    if (insuranceType === 'social_insurance') {
      dispatch(changeForm(OFFICE_FORM, 'healthInsurance', response.data.healthInsurance));
      dispatch(changeForm(OFFICE_FORM, 'pensionInsurance', response.data.pensionInsurance));
    } else if (insuranceType === 'employment_insurance') {
      dispatch(changeForm(OFFICE_FORM, 'employmentInsurance', response.data.employmentInsurance));
    } else {
      dispatch(changeForm(OFFICE_FORM, 'accidentInsurance', response.data.accidentInsurance));
    }
  } catch (exception) {
    dispatch(newItemFailure(REDUCER_NAME, '保険情報が取得できませんでした。'));
  }
};

// カレンダー上で選択されている「参照する事業所」がプルダウンの要素の中に含まれているか確認する.含まれていない場合は表示用に先頭に該当のデータを追加する
// 例えば、A事業所の202204で別の事業所を参照するように更新するときに、A事業所を参照するB事業所があったとする。
// B事業所は参照は以下の通り。直近の202203ではA事業所を参照していないのでチェックエラーにならずに更新できる。
// ただ、B事業所は202202時点でA事業所を参照していて、プルダウンで選択できるのは本社と同様に他の事業所を1個も参照していない事業所なので、
// A事業所は参照できる事業所に含まれていない。そのままだとB事業所は202202を画面で見たときにプルダウンに事業所名が表示できないのでここで追加する
// 〜事業所Bの参照データ〜
// 対象月,社会,労災,雇用
// 202203,B,B,C
// 202202,A,C,B
export const addReferenceOfficeItem = (referenceOffices, allReferenceOffices, referenceId) => {
  // カレンダー切り替えた時にこの関数で追加されたデータがあるかもしれないので初期化
  // 追加されるデータは先頭に1個なので先頭のデータのみ確認して削除
  if (referenceOffices[0].add) {
    referenceOffices.shift();
  }
  const item = referenceOffices.find(ele => ele.value === referenceId);
  // 取得できた場合は追加しないのでreturn
  if (!_.isEmpty(item)) {
    return;
  }
  const addItem = allReferenceOffices.find(ele => ele.value === referenceId);
  if (!_.isEmpty(addItem)) {
    addItem.add = true;
    referenceOffices.unshift(addItem);
  }
};
