import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DASHBOARD_SHOW } from 'src/constants/EndpointUrls';
import { setGlobalErrors } from 'src/reducers/global';

const SUPPORT_LINK = 'https://jobcan-payroll.zendesk.com/hc/ja/requests/new';

const ERROR_DISPLAY_MAPPING = {
  not_found: {
    title: 'ページを表示できません',
    description: 'アクセスしようとしたページはアクセス権限がないか、削除・変更された可能性があります。'
  },
  internal_server_error: {
    title: 'ページを表示できません',
    description: (
      <span>
        しばらく時間をおいてもご覧いただけない場合、お手数ですが<a href={SUPPORT_LINK}>サポート</a>までお問い合わせください。
      </span>
    )
  },
  bad_request: {
    title: 'ページを表示できません',
    description: 'アクセスしようとしたページはアクセス権限がないか、削除・変更された可能性があります。'
  },
  forbidden: {
    title: 'ページを表示できません',
    description: 'アクセスしようとしたページはアクセス権限がないか、削除・変更された可能性があります。'
  },
  unprocessable_entity: {
    title: 'ページを表示できません',
    description: (
      <span>
        繰り返し同様の操作をしても改善されない場合、お手数ですが<a href={SUPPORT_LINK}>サポート</a>までお問い合わせください。
      </span>
    )
  }
};

const ErrorPage = ({ errorStatus, errorMessage, errorFlashMessage, dispatch }) => {
  useEffect(() => {
    if (errorFlashMessage) {
      dispatch(setGlobalErrors(errorFlashMessage));
    }
  }, []);
  const error = _.get(ERROR_DISPLAY_MAPPING, errorStatus) || ERROR_DISPLAY_MAPPING.not_found;
  if (errorMessage) {
    error.description = errorMessage;
  }
  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">{error.title}</h1>
      </div>
      <div className="l-wrap-xs u-pt100">
        <div className="l-box-message">
          <p>
            {error.description}
            <br />
            <br />
            <a href={DASHBOARD_SHOW} className="u-txt-link">
              ダッシュボードへ
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

// Todo: sprint114リリース後にhooksに書き換える
export default connect()(ErrorPage);
