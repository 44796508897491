import {
  removeNodeAtPath,
  changeNodeAtPath,
  defaultGetNodeKey,
  addNodeUnderParent,
  map as _mapTree
} from 'react-sortable-tree';
import { createAction } from 'redux-actions';
import { getFormValues } from 'redux-form';
import _ from 'lodash';
import axios from 'axios';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { paramsWithFile } from 'src/utils/Form';
import { setGlobalSuccesses, setGlobalErrors } from 'src/reducers/global';
import { fetchLatestJobStatus, isJobIncomplete } from 'src/reducers/JobStatus';
import { MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import { GROUP_CSV_DOWNLOAD_MODAL_FORM } from 'src/constants/FormNames';

import * as Urls from 'src/constants/EndpointUrls';

export const REDUCER_NAME = 'groups';

// Actions
export const SET_GROUP = 'GROUPS/SET_GROUP';
export const SET_TREE = 'GROUPS/SET_TREE';
export const CHANGE_TO_SHOW_MODE = 'GROUPS/CHANGE_TO_SHOW_MODE';
export const CHANGE_TO_EDIT_MODE = 'GROUPS/CHANGE_TO_EDIT_MODE';

export const EXPAND_ALL = 'GROUPS/EXPAND_ALL';
export const COLLAPSE_ALL = 'GROUPS/COLLAPSE_ALL';

export const ADD_NODE = 'GROUPS/ADD_NODE';
export const INSERT_NODE = 'GROUPS/INSERT_NODE';
export const CHANGE_NAME = 'GROUPS/CHANGE_NAME';
export const CHANGE_CODE = 'GROUPS/CHANGE_CODE';
export const DELETE_NODE = 'GROUPS/DELETE_NODE';

export const UPDATE = 'GROUPS/UPDATE';
export const UPDATE_SUCCESS = 'GROUPS/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'GROUPS/UPDATE_FAILURE';

export const UPDATE_IMPORT_STATUS = 'GROUPS/UPDATE_IMPORT_STATUS';

// Action Creators
export const setGroup = createAction(SET_GROUP);
export const setTree = createAction(SET_TREE);

export const changeToShowMode = createAction(CHANGE_TO_SHOW_MODE);
export const changeToEditMode = createAction(CHANGE_TO_EDIT_MODE);

export const expandAll = createAction(EXPAND_ALL);
export const collapseAll = createAction(COLLAPSE_ALL);

export const addNone = createAction(ADD_NODE);
export const insertNode = createAction(INSERT_NODE);
export const changeNodeName = createAction(CHANGE_NAME);
export const changeNodeCode = createAction(CHANGE_CODE);
export const deleteNode = createAction(DELETE_NODE);

export const updateList = createAction(UPDATE);
export const updateListSuccess = createAction(UPDATE_SUCCESS);
export const updateListFailure = createAction(UPDATE_FAILURE);

export const setImportStatus = createAction(UPDATE_IMPORT_STATUS);

// Selectors
export const getTree = state => state.groups.tree;
export const getMode = state => state.groups.mode;
export const getErrors = state => state.groups.errors;
export const getImportStatus = state => state.groups.importing;

export const GROUP_DISABLED_REASON = '情報の一括更新を行っているため一部操作ができません';

// Async Action Creators
export const updateAllGroupNodes = () => async (dispatch, getState) => {
  try {
    dispatch(updateList());
    const httpBody = {
      groups: getTree(getState())
    };
    const response = await axios.post(Urls.CREATE_CLIENT_GROUPS_URL, httpBody);
    const values = response.data.payload;
    dispatch(updateListSuccess(values.clientDepartments));
  } catch (exception) {
    dispatch(updateListFailure(exception.response.data.errors.messages));
  }
};

export const exportCsv = () => async (dispatch, getState) => {
  const encoding = getFormValues(GROUP_CSV_DOWNLOAD_MODAL_FORM)(getState()).encoding;
  const params = { encoding };
  const downloadUrl = `${Urls.EXPORT_CLIENT_GROUPS_URL}?${serializeHttpGetParams(params)}`;
  try {
    const response = await fetch(downloadUrl);

    // Content-Typeがapplication/jsonになるのは以下のケース
    // - エラー発生時
    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.errors.messages);
      }
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const uploadCsv = (data, operation = 'create') => async dispatch => {
  try {
    const params = paramsWithFile(data, ['groups_csv']);
    params.append('operation', operation);
    await axios.post(Urls.IMPORT_CLIENT_GROUPS_URL, params);
    dispatch(setGlobalSuccesses('ファイルを受け取りました。'));
    redirectTo(`${Urls.COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=groups`);
  } catch (e) {
    let msg = 'CSVのアップロードに失敗しました';
    if (!_.isEmpty(e.response.data.errors)) {
      msg = e.response.data.errors.messages;
    }
    dispatch(setGlobalErrors(msg));
  }
};

export const fetchImportCsvJobs = interval => async (dispatch, getState) => {
  const params = {
    job_name: 'importGroupCsvJob',
    search_params: { visible: true }
  };
  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.importGroupCsvJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchImportCsvJobs(nextInterval));
    }, nextInterval);
    dispatch(setImportStatus(true));
  } else {
    dispatch(setImportStatus(false));
  }
};

export const hideJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'importGroupCsvJob'
    });
    dispatch(fetchImportCsvJobs());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const MODES = {
  SHOW: 'SHOW',
  EDIT: 'EDIT'
};

const INITIAL_STATE = {
  tree: [],
  originTree: [],
  mode: MODES.SHOW,
  errors: [],
  loading: false,
  importing: false
};

export const mapTree = (treeData, callback) =>
  _mapTree({ treeData, getNodeKey: defaultGetNodeKey, ignoreCollapsed: false, callback });

const makeTree = groups => {
  const childrenOf = _(groups)
    .sortBy('orderNumber')
    .groupBy('parentDepartmentId')
    .value();

  const make = id => {
    const next = childrenOf[id];
    if (!next) {
      return null;
    }

    return next.map(group => {
      const children = make(group.id);

      return {
        group,
        expanded: true,
        _key: _.uniqueId(),
        children,
        canDelete: group.employeesCount === 0 && (!children || _.every(children, 'canDelete'))
      };
    });
  };
  return make('null');
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_GROUP: {
      const tree = makeTree(action.payload);

      return { ...state, tree, originTree: tree };
    }
    case SET_TREE: {
      const tree = action.payload;
      return { ...state, tree };
    }
    case CHANGE_TO_SHOW_MODE:
      return { ...state, mode: MODES.SHOW, tree: state.originTree, errors: [] };
    case CHANGE_TO_EDIT_MODE:
      return { ...state, mode: MODES.EDIT };
    case UPDATE:
      return { ...state, errors: [], loading: true };
    case UPDATE_SUCCESS: {
      const tree = makeTree(action.payload);
      return { ...state, tree, originTree: tree, mode: MODES.SHOW, errors: [], loading: false };
    }
    case UPDATE_FAILURE:
      return { ...state, errors: action.payload, loading: false };
    case EXPAND_ALL:
      return { ...state, tree: mapTree(state.tree, ({ node }) => ({ ...node, expanded: true })) };
    case COLLAPSE_ALL:
      return { ...state, tree: mapTree(state.tree, ({ node }) => ({ ...node, expanded: false })) };
    case ADD_NODE: {
      const newNode = {
        group: { name: '', departmentCode: '', employeesCount: 0 },
        expanded: true,
        _key: _.uniqueId(),
        canDelete: true
      };
      return { ...state, tree: [...(state.tree || []), newNode] };
    }
    case INSERT_NODE: {
      const newNode = {
        group: { name: '', departmentCode: '', employeesCount: 0 },
        expanded: true,
        _key: _.uniqueId(),
        canDelete: true
      };
      return {
        ...state,
        tree: addNodeUnderParent({
          treeData: state.tree,
          newNode,
          parentKey: _.last(action.payload),
          expandParent: true,
          getNodeKey: defaultGetNodeKey
        }).treeData
      };
    }
    case CHANGE_NAME: {
      const [path, value] = action.payload;
      return {
        ...state,
        tree: changeNodeAtPath({
          treeData: state.tree,
          path,
          getNodeKey: defaultGetNodeKey,
          newNode: ({ node }) => ({ ...node, group: { ...node.group, name: value } })
        })
      };
    }
    case CHANGE_CODE: {
      const [path, value] = action.payload;
      return {
        ...state,
        tree: changeNodeAtPath({
          treeData: state.tree,
          path,
          getNodeKey: defaultGetNodeKey,
          newNode: ({ node }) => ({ ...node, group: { ...node.group, departmentCode: value } })
        })
      };
    }
    case DELETE_NODE:
      return {
        ...state,
        tree: removeNodeAtPath({
          treeData: state.tree,
          path: action.payload,
          getNodeKey: defaultGetNodeKey
        })
      };
    case UPDATE_IMPORT_STATUS:
      return { ...state, importing: action.payload };
    default:
      return state;
  }
};
