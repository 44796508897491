import React from 'react';

import tableStyles from 'src/features/Employees/styles/GeneralTableInfo.scss';
import { Item } from 'src/components/PrimaryPanel';
import { Dl, Dd, Dt } from 'src/components/SecondaryPanel';
import { HYPHEN_CHARACTER } from 'src/constants/Generals';

const SocialInsuranceSection = ({ healthInsurance, pensionInsurance }) => (
  <div>
    <Item className={tableStyles.mTablePersonal}>
      <Dl>
        <Dt>健康保険記号:</Dt>
        <Dd>{healthInsurance.insuranceCode}</Dd>
      </Dl>
      <Dl>
        <Dt>健康保険タイプ:</Dt>
        <Dd>{healthInsurance.insuranceTypeStr}</Dd>
      </Dl>
      <Dl>
        <Dt>厚生年金保険記号:</Dt>
        <Dd>{pensionInsurance.joinedInsuranceCode ? pensionInsurance.joinedInsuranceCode : HYPHEN_CHARACTER}</Dd>
      </Dl>
      <Dl>
        <Dt>厚生年金保険番号:</Dt>
        <Dd>{pensionInsurance.insuranceNumber}</Dd>
      </Dl>
    </Item>
  </div>
);

export default SocialInsuranceSection;
