import React from 'react';

import BreadCrumb from 'jbc-front/components/BreadCrumb';

import { REPORT_INDEX, REPORT_HEALTH_INSURANCE_BONUS_LIST } from 'src/constants/EndpointUrls';
import EditReportHealthInsuranceBonusForm from '../containers/EditReportHealthInsuranceBonusForm';

class EditReportHealthInsuranceBonus extends React.Component {
  componentDidMount() {
    this.props.setFormValuesToState();
  }
  render() {
    return (
      <div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
              <BreadCrumb.Item label="賞与支払届" path={REPORT_HEALTH_INSURANCE_BONUS_LIST} />
              <BreadCrumb.Item label="賞与支払届編集" />
            </BreadCrumb>
          </div>
          <div className="l-title-wrap">
            <h1 className="m-title-main">賞与支払届</h1>
          </div>
          <EditReportHealthInsuranceBonusForm />
        </div>
      </div>
    );
  }
}

export default EditReportHealthInsuranceBonus;
