import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { reduxForm } from 'redux-form';

import { ErrorsSection, LoadingWithDescription } from 'src/components';
import Button from 'jbc-front/components/Button';
import { getExtras } from 'src/reducers/common/listReducer';
import { DateField, CheckboxField, SelectField } from 'jbc-front/components/Form';
import CommonModal from 'jbc-front/components/CommonModal';
import { FormSubmitBtn } from 'src/buttons';
import { CONFIRM_PAYROLL_GROUP } from 'src/constants/FormNames';
import {
  REDUCER_NAME,
  confirmEmployeePays,
  getConfirmPayrollGroupErrors,
  getEmployeePayListExtras,
  clearErrors
} from 'src/reducers/employees/employeePays';
import { get24HoursOptions } from 'src/utils/Form';
import { isYYYYMMDDFormat } from 'src/utils/CustomValidators';
import ConfirmDate from './ConfirmDate';
import styles from './ConfirmPayrollGroupModal.scss';

const DEFAULT_RELEASE_HOUR = '9';

const genInitialValue = extras => {
  const { paymentDate } = extras || {};
  if (isEmpty(paymentDate)) {
    return {};
  }
  return { releaseDate: paymentDate, releaseHour: DEFAULT_RELEASE_HOUR };
};

const ConfirmPayrollGroupInsideForm = ({
  handleSubmit,
  submitting,
  submit,
  cancelBtnClick,
  selectedYearMonth,
  extras,
  errors,
  header
}) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        {submitting && (
          <div className="u-mb20">
            <LoadingWithDescription text="確定中です。少々お待ちください。" />
          </div>
        )}
        <ConfirmDate yearMonth={selectedYearMonth.label} paymentDate={extras.paymentDate} />
        <div className={styles.selectWrap}>
          <DateField required name="releaseDate" label="従業員への明細公開時刻" validate={isYYYYMMDDFormat} />
          <SelectField label="" name="releaseHour" options={get24HoursOptions()} width={100} required />
        </div>
        <div>
          <CheckboxField name="sendNotifyToEmployee" label="公開時に通知メールも送信" />
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="確定" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let ConfirmPayrollGroupForm = reduxForm({
  form: CONFIRM_PAYROLL_GROUP
})(ConfirmPayrollGroupInsideForm);
const mapStateToProp = state => ({
  initialValues: genInitialValue(getEmployeePayListExtras(state)),
  extras: getExtras(REDUCER_NAME, state),
  errors: getConfirmPayrollGroupErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  clearErrors: dispatch(clearErrors()),
  onSubmit: data => {
    dispatch(confirmEmployeePays(data, ownProps.selectedPayroll));
    if (typeof ownProps.hideModal === 'function') {
      ownProps.hideModal();
    }
  }
});

ConfirmPayrollGroupForm = connect(mapStateToProp, mapDispatchToProp)(ConfirmPayrollGroupForm);

const ConfirmPayrollGroupModal = ({ isOpen, hideModal, selectedPayroll, selectedYearMonth }) => {
  const header = '給与確定';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <ConfirmPayrollGroupForm
        cancelBtnClick={hideModal}
        hideModal={hideModal}
        {...{ selectedPayroll, selectedYearMonth }}
        header={header}
      />
    </CommonModal>
  );
};

export default ConfirmPayrollGroupModal;
