import { connect } from 'react-redux';

import { getItem, getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, editCommutingFeeSetting } from 'src/reducers/clients/commutingFeeSettings';

import ClientCommutingFeeSettingForm from '../components/ClientCommutingFeeSettingForm';

const mapStateToProps = state => ({
  initialValues: getItem(REDUCER_NAME, state),
  errors: getItemErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(editCommutingFeeSetting(data));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ClientCommutingFeeSettingForm);
