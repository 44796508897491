import { connect } from 'react-redux';
import _ from 'lodash';
import { getFormValues } from 'redux-form';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, uploadCsv, getImporting } from 'src/reducers/reports/monthlyRevisions';
import { IMPORT_MONTHLY_REVISIONS_FORM } from 'src/constants/FormNames';
import ImportMonthlyRevisions from '../ImportMonthlyRevisions';

const mapStateToProps = (state, ownProps) => ({
  errors: getItemErrors(REDUCER_NAME, state),
  revisionYearMonth: ownProps.revisionYearMonth,
  applicableOffice: ownProps.applicableOffice,
  dispRevisionYearMonth: ownProps.dispRevisionYearMonth,
  dispPeriod: ownProps.dispPeriod,
  selectedEncoding: _.get(getFormValues(IMPORT_MONTHLY_REVISIONS_FORM)(state), 'encoding'),
  selectedTarget: _.get(getFormValues(IMPORT_MONTHLY_REVISIONS_FORM)(state), 'target'),
  initialValues: {
    encoding: 'UTF-8',
    target: 'only_targets',
    revision_year_month: ownProps.revisionYearMonth,
    applicable_office: ownProps.applicableOffice
  },
  importing: getImporting(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(uploadCsv(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportMonthlyRevisions);
