import React from 'react';
import { connect } from 'react-redux';

import { setGroup } from 'src/reducers/clients/groups';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { getSelectOptions } from 'src/reducers/selectOptions';
import GroupsButtons from './containers/GroupsButtons';
import GroupsTree from './containers/GroupsTree';

const Groups = ({ groups, dispatch, anyApiRunning }) => {
  dispatch(setGroup(groups));
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">グループ設定</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="グループ設定" />
          </BreadCrumb>
        </div>
        <GroupsButtons anyApiRunning={anyApiRunning} />
        <GroupsTree />
      </div>
    </div>
  );
};

export default connect(state => ({
  anyApiRunning: getSelectOptions(state, 'anyApiRunning')
}))(Groups);
