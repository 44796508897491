import React from 'react';

import { Check, Alert } from 'jbc-front/components/icons';
import { formatDate, formatDateWithHour } from 'src/utils/Date';
import { getInputTypeName } from 'src/utils/Enum';
import MemoList from 'src/features/Memos';
import { isInTargetDateRange } from 'src/utils/Utils';
import styles from './EmployeePayTableRow.scss';

const JoinedOnStyle = () => (
  <span className={styles.icon}>
    <Alert size={16} />
    <span className={styles.baloon}>給与計算の対象期間に入社した従業員です。</span>
  </span>
);

const ResignedOnStyle = () => (
  <span className={styles.icon}>
    <Alert size={16} />
    <span className={styles.baloon}>給与計算の対象期間に退職した従業員です。</span>
  </span>
);

class EmployeePayTableRow extends React.Component {
  state = {
    clickThisRow: false
  };

  onClickHandler = () => {
    if (!this.state.clickThisRow) {
      this.state.clickThisRow = true;
      this.props.onClick();
    }
  };

  render() {
    const {
      employeeId,
      staffCode,
      employeeDisplayName,
      isSyncAttendance,
      inputType,
      lastConfirmAt,
      lastEditAt,
      employeeJoinedOn,
      employeeResignedOn,
      paymentRange,
      attendanceStartDate,
      attendanceEndDate,
      confirmUser,
      editUser
    } = this.props;

    const { targetStartDate, targetEndDate } = paymentRange || {};
    const hasResignedStyle =
      isInTargetDateRange(targetStartDate, targetEndDate, employeeResignedOn) ||
      isInTargetDateRange(attendanceStartDate, attendanceEndDate, employeeResignedOn);
    const hasJoinedStyle = isInTargetDateRange(targetStartDate, targetEndDate, employeeJoinedOn);

    return (
      <tr onClick={() => this.onClickHandler()} className="table-hover">
        <td>
          <MemoList employeeId={employeeId} />
        </td>
        <td>{staffCode}</td>
        <td>{employeeDisplayName}</td>
        <td>{isSyncAttendance ? <Check size={16} className={styles.check} /> : ''}</td>
        <td>
          {formatDateWithHour(lastEditAt)}
          {lastEditAt && (
            <React.Fragment>
              <p>（{getInputTypeName(inputType)}）</p>
              {editUser && <p>{editUser.employeeDisplayName}</p>}
            </React.Fragment>
          )}
        </td>
        <td>
          {formatDateWithHour(lastConfirmAt)}
          {lastConfirmAt && confirmUser && <p>{confirmUser.employeeDisplayName}</p>}
        </td>
        <td>
          <span className={hasJoinedStyle ? styles.dateWithIcon : styles.date}>
            {formatDate(employeeJoinedOn)}
            {hasJoinedStyle && <JoinedOnStyle />}
          </span>
        </td>
        <td>
          <span className={hasResignedStyle ? styles.dateWithIcon : styles.date}>
            {formatDate(employeeResignedOn)}
            {hasResignedStyle && <ResignedOnStyle />}
          </span>
        </td>
      </tr>
    );
  }
}

export default EmployeePayTableRow;
