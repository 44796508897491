import React from 'react';

import { Section } from 'jbc-front/components/Form';
import EmployeeAndSalarySetting from './EmployeeAndSalarySetting';
import ProcedureList from './ProcedureList';
import BonusPaymentNotificationList from './BonusPaymentNotificationList';
import styles from './TodoList.scss';

const TodoList = () => (
  <Section title="Todo リスト" className={styles.section}>
    <EmployeeAndSalarySetting />
    <ProcedureList />
    <BonusPaymentNotificationList />
  </Section>
);

export default TodoList;
