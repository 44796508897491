import { connect } from 'react-redux';
import _ from 'lodash';
import { getFormValues } from 'redux-form';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, uploadCsv, getImporting } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import { IMPORT_REVISIONS_FORM } from 'src/constants/FormNames';
import ImportRevisions from '../components/ImportRevisions';

const mapStateToProps = (state, ownProps) => ({
  errors: getItemErrors(REDUCER_NAME, state),
  yearMonth: ownProps.revisionYear,
  dispRevisionYearMonth: ownProps.dispRevisionYear,
  selectedEncoding: _.get(getFormValues(IMPORT_REVISIONS_FORM)(state), 'encoding'),
  selectedTarget: _.get(getFormValues(IMPORT_REVISIONS_FORM)(state), 'target'),
  initialValues: { encoding: 'UTF-8', target: 'only_targets', revision_year: ownProps.revisionYear },
  importing: getImporting(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(uploadCsv(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportRevisions);
