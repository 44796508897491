import { combineReducers } from 'redux';
import axios from 'axios';
import * as Urls from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { newItemFailure } from 'src/reducers/common/itemReducer';
import { fetchListSuccess, fetchListFailure } from 'src/reducers/common/listReducer';

export const REDUCER_NAME = 'reportIncomeTaxCollectionData';
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));

// Async Action Creators
export const fetchIncomeTaxCollectionData = year => async dispatch => {
  try {
    // リアルタイムでの集計処理なのでレスポンスが遅いことが考えられる
    // 画面側でローディングの表示を出すために検索の最初にundefinedで初期化
    const init = {
      data: {
        incomeTaxCollectionData: undefined
      }
    };
    dispatch(fetchListSuccess(REDUCER_NAME, init));
    const response = await axios.get(Urls.INCOME_TAX_COLLECTION_DATA_SEARCH, {
      params: { year }
    });
    const payload = {
      data: {
        incomeTaxCollectionData: response.data.payload.incomeTaxCollectionData
      }
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
