import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { CheckboxField } from 'jbc-front/components/Form';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';

import { LoadingWithDescription } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { CONFIRM_REAGGREGATE_SUMMARY_FORM } from 'src/constants/FormNames';
import { REVERSE_COUNT_PAY_AMOUNT_FLG_YEAR, reaggregateSummary } from 'src/reducers/reports/reportLaborInsurances';
import compose from 'lodash/fp/compose';

const ConfirmReaggregateSummaryForm = ({ laborInsuranceYear, isOpen, handleSubmit, hideModal, submitting, submit }) => (
  <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, laborInsuranceYear }}>
    <CommonModal.Header hideModal={hideModal}>再集計（{laborInsuranceYear}年度）</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        {submitting && <LoadingWithDescription text="「再集計」を実行中です。しばらくお待ちください。" />}
        {!submitting && (
          <div>
            編集した内訳データや事業所設定における参照先事業所の変更を反映して再集計を行います。<br />
            <br />
            ※ 手入力された集計値は上書きされません。<br />
            ただし事業所設定の変更により、対象年度末に参照されなくなった適用事業所の集計データは、<br />
            手入力に関わらず削除されます。<br />
            <br />
            ※ 人数の集計方法を変更する場合は以下のオプションにチェックを入れて下さい。<br />
            <br />
            {laborInsuranceYear >= REVERSE_COUNT_PAY_AMOUNT_FLG_YEAR ? (
              <CheckboxField name="shouldExcludeNoPayEmployee" label="支給が無い従業員は人数に算入しない" />
            ) : (
              <CheckboxField name="shouldIncludeNoPayEmployee" label="支給が無い従業員も人数に算入する" />
            )}
          </div>
        )}
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="再集計する" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </CommonModal>
);

const mapStateToProps = null;
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: formData => {
    dispatch(reaggregateSummary(ownProps.laborInsuranceYear, formData));
  }
});

const ConfirmReaggregateSummaryModal = compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: CONFIRM_REAGGREGATE_SUMMARY_FORM })
)(ConfirmReaggregateSummaryForm);

export default ConfirmReaggregateSummaryModal;
