import React from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';
import { isNil } from 'lodash';

import { formatDateWithHour } from 'src/utils/Date';
import MemoList from 'src/features/Memos';
import { changeEmployeeCheckedForConfirmationList } from 'src/reducers/flat_tax_reductions/employees';
import { numberWithComma } from 'src/utils/Utils';

import styles from './ConfirmationTableRow.scss';

const ConfirmationTableRow = ({ employee }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(changeEmployeeCheckedForConfirmationList(employee.id));
  };

  const checked = employee.checked;
  const checkedClass = checked ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
  const disabledClass = ' u-cur-pointer';
  const hasConfirmedPaymentOrBonusSince = employee.hasConfirmedPaymentOrBonusSince;
  const lastConfirmAt = employee.lastConfirmAt;
  const lastUnconfirmAt = employee.lastUnconfirmAt;
  const isConfirmed = employee.isConfirmed;
  const confirmUser = employee.confirmUser;
  const unconfirmUser = employee.unconfirmUser;
  const isDisableCheckbox = hasConfirmedPaymentOrBonusSince && isConfirmed;
  return (
    <React.Fragment>
      <tr className={classNames('table-hover', styles.tableRow)}>
        <td
          onClick={e => {
            e.stopPropagation();

            handleClick();
          }}
          className={classNames(
            checkedClass,
            disabledClass,
            isDisableCheckbox ? styles.tooltip : '',
            isDisableCheckbox ? styles.disableCheckBox : ''
          )}
          role="presentation"
        >
          <span className={styles.descriptionTop}>
            確定済みの2024年6月以降の給与・賞与データがある為、確定解除できません。修正が必要な場合は、給与・賞与データを確定解除してください
          </span>
        </td>
        <td>
          <MemoList employeeId={employee.id} />
        </td>
        <td>{hasConfirmedPaymentOrBonusSince ? '有り' : '無し'}</td>
        <td>{employee.staffCode}</td>
        <td>
          {employee.lastName} {employee.firstName}
        </td>
        <td>{!isNil(employee.reduceTargetCount) ? `${numberWithComma(employee.reduceTargetCount)}人` : '-'}</td>
        <td>{!isNil(employee.reduceAmountUnit) ? `${numberWithComma(employee.reduceAmountUnit)}円` : '-'}</td>
        <td>{!isNil(employee.monthlyReduceAmount) ? `${numberWithComma(employee.monthlyReduceAmount)}円` : '-'}</td>
        <td>{!isNil(employee.reduceAmount) ? `${numberWithComma(employee.reduceAmount)}円` : '-'}</td>
        <td>{!isNil(employee.restReduceAmount) ? `${numberWithComma(employee.restReduceAmount)}円` : '-'}</td>
        <td>
          {isConfirmed &&
            lastConfirmAt && (
              <>
                {formatDateWithHour(lastConfirmAt)}
                <br />
                未確定→確定済
                <br />
                {lastConfirmAt && confirmUser && <p>{`${confirmUser.lastName} ${confirmUser.firstName}`}</p>}
              </>
            )}
          {!isConfirmed &&
            lastUnconfirmAt && (
              <>
                {formatDateWithHour(lastUnconfirmAt)}
                <br />
                確定済→未確定
                <br />
                {lastUnconfirmAt && unconfirmUser && <p>{`${unconfirmUser.lastName} ${unconfirmUser.firstName}`}</p>}
              </>
            )}
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ConfirmationTableRow;
