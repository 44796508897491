import React from 'react';
import { connect } from 'react-redux';
import {
  getListPageCount,
  getListPage,
  getListPerPage,
  getListTotalCount,
  getListDisplayFrom,
  getListDisplayTo,
  addPerPageQuery
} from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  fetchEmailNotificationSettingList
} from 'src/reducers/admin_email_notification_settings/admin_email_notification_settings';
import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from './PaginationContainer.scss';

const PaginationContainer = ({ onPerPageChange, perPage, perPages, totalCount, displayFrom, displayTo }) => (
  <div className={styles.wrap}>
    <div className={styles.flex}>
      <span className={styles.displayPageEntries}>
        {totalCount}件中{displayFrom}-{displayTo}件を表示
      </span>
      <select className={styles.selectPagingParSize} onChange={onPerPageChange} value={perPage}>
        {perPages.map(page => (
          <option key={page.value} value={page.value}>
            {page.label}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export default connect(
  state => ({
    pageCount: getListPageCount(REDUCER_NAME, state),
    totalCount: getListTotalCount(REDUCER_NAME, state),
    displayFrom: getListDisplayFrom(REDUCER_NAME, state),
    displayTo: getListDisplayTo(REDUCER_NAME, state),
    initialPage: getListPage(REDUCER_NAME, state),
    perPage: getListPerPage(REDUCER_NAME, state),
    perPages: getSelectOptions(state, 'perPages')
  }),
  (dispatch, ownProps) => ({
    onPerPageChange: e => {
      const perPage = e.target.value;
      ownProps.closeEditMode();
      dispatch(addPerPageQuery(REDUCER_NAME, perPage));
      dispatch(fetchEmailNotificationSettingList(perPage));
    }
  })
)(PaginationContainer);
