import React from 'react';
import { Field } from 'redux-form';
import { SearchEmploymentStatusField } from 'jbc-front/components/SearchForm';

import { EMPLOYMENT_STATUSES } from 'src/constants/Options';
import { convertArrayIntoJbcStyle } from 'src/utils/Utils';

const FilterEnrollmentStatusBtnList = () => (
  <Field
    name="employmentType"
    component={SearchEmploymentStatusField}
    employmentStatuses={convertArrayIntoJbcStyle(EMPLOYMENT_STATUSES)}
    onEmploymentStatusChange={() => {}}
  />
);

export default FilterEnrollmentStatusBtnList;
