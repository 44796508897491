import { connect } from 'react-redux';

import { REDUCER_NAME, fetchAuditLogs } from 'src/reducers/clients/auditLogs';
import {
  getListPageCount,
  getListPage,
  getListPerPage,
  addPageQuery,
  addPerPageQuery,
  getListTotalCount,
  getListDisplayFrom,
  getListDisplayTo,
  getIsLoading
} from 'src/reducers/common/listReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import AuditLogsList from '../components/AuditLogsList';

const mapStateToProps = state => ({
  pageCount: getListPageCount(REDUCER_NAME, state),
  totalCount: getListTotalCount(REDUCER_NAME, state),
  displayFrom: getListDisplayFrom(REDUCER_NAME, state),
  displayTo: getListDisplayTo(REDUCER_NAME, state),
  initialPage: getListPage(REDUCER_NAME, state),
  perPage: getListPerPage(REDUCER_NAME, state),
  perPages: getSelectOptions(state, 'perPages'),
  isLoading: getIsLoading(REDUCER_NAME, state)
});

const mapDispatchToProps = dispatch => ({
  fetch: () => {
    dispatch(fetchAuditLogs());
  },
  onPageChange: query => {
    dispatch(addPageQuery(REDUCER_NAME, query + 1));
    dispatch(fetchAuditLogs());
  },
  onChangePerPage: query => {
    dispatch(addPageQuery(REDUCER_NAME, 1));
    dispatch(addPerPageQuery(REDUCER_NAME, query));
    dispatch(fetchAuditLogs());
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuditLogsList);
