import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues, Field } from 'redux-form';
import compose from 'lodash/fp/compose';

import { fetchUsers, getListErrors, REDUCER_NAME } from 'src/reducers/users/roleUsers';
import { getSelectOptions } from 'src/reducers/selectOptions';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { SearchEmploymentStatusField } from 'jbc-front/components/SearchForm';

import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import DetailSearchForm from 'src/components/DetailSearchForm';
import {
  FORM_NAME_ROLE_USERS_SEARCH,
  takeClientRoleUsersResetFormQueries,
  takeClientRoleUsersSearchQueriesFromLocation,
  takeClientRoleUsersSearchQueries
} from 'src/reducers/searchForm';
import { convertArrayIntoJbcStyle } from 'src/utils/Utils';
import { DUMMY_ID_CLIENT_ROLE_ALL } from 'src/utils/fieldConverters';

import EditableList from './containers/EditableList';
import EditableListButtons from './containers/EditableListButtons';
import Invite from './containers/Invite';
import FormErrors from '../../components/FormErrors';
import styles from './RoleUsersList.scss';

class RoleUsersList extends React.Component {
  // 権限フィルタのid, nameの配列を取得する
  static getRoleButtonValues(clientRoles) {
    // サーバーから取得する権限には「すべて」は含まれないためフロント制御用の選択項目として独自で追加する
    return [{ id: DUMMY_ID_CLIENT_ROLE_ALL, name: 'すべて' }, ...convertArrayIntoJbcStyle(clientRoles)];
  }

  render() {
    const { initialValues: formValues, location, resetValues, dispatch, clientRoles, errors } = this.props;
    return (
      <div>
        <SearchDetector
          location={location}
          didMountDetectingJudger={() => true}
          willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
          onDetect={() => {
            dispatch(fetchUsers(takeClientRoleUsersSearchQueries(formValues)));
          }}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">権限管理設定</h1>
          <p className="m-title-main-note">運用担当者はマイナンバー、権限管理設定の操作権限のない管理者です</p>
        </div>
        <div className="l-wrap-xl l-contents-wrap">
          <div>
            <BreadCrumb>
              <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
              <BreadCrumb.Item label="権限管理設定" />
            </BreadCrumb>
          </div>
          <FormErrors errors={errors} />
          <div className={styles.menuButtons}>
            <Invite />
          </div>

          <DetailSearchForm
            containAccountTypes
            containEmployeeEnrollmentStatus
            containsOtherSearch={false}
            formName={FORM_NAME_ROLE_USERS_SEARCH}
            resetValues={resetValues}
            menuRender={() => (
              <Field
                name="clientRoles"
                component={SearchEmploymentStatusField}
                employmentStatuses={RoleUsersList.getRoleButtonValues(clientRoles)}
                onEmploymentStatusChange={() => {}}
              />
            )}
          />
          <div className={styles.wrap}>
            <EditableListButtons />
            <div className={styles.paginator}>
              <SearchFormPaginator formName={FORM_NAME_ROLE_USERS_SEARCH} reducerName={REDUCER_NAME} noPaginator />
            </div>
          </div>
          <div className="l-overflow-scroll u-mt10">
            <EditableList />
          </div>
          <SearchFormPaginator formName={FORM_NAME_ROLE_USERS_SEARCH} reducerName={REDUCER_NAME} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...takeClientRoleUsersSearchQueriesFromLocation(state.router.location),
    ...getFormValues(FORM_NAME_ROLE_USERS_SEARCH)(state)
  },
  resetValues: takeClientRoleUsersResetFormQueries(state, true),
  location: state.router.location,
  clientRoles: getSelectOptions(state, 'clientRoles'),
  errors: getListErrors(state)
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME_ROLE_USERS_SEARCH,
    enableReinitialize: true
  })
)(RoleUsersList);
