import React, { Component } from 'react';

import onClickOutside from 'react-onclickoutside';
import image from './images/csv-update-help.png';
import styles from './HelpUpdateBatchCsv.scss';

const HelpUpdateBatchCsv = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      return (
        <div className={styles.help}>
          <div className={styles.image}>
            <img src={image} alt="" />
          </div>
          <br />
          従業員データは従業員一覧画面からダウンロードできます
        </div>
      );
    }
  }
);

export default HelpUpdateBatchCsv;
