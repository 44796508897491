import React from 'react';
import styles from './AccountRegistrationCompleteNotify.scss';

const AccountRegistrationCompleteNotify = ({ email }) => (
  <div>
    <div className="l-wrap-xs u-pt100">
      <div className={styles.main}>
        <p className="m-title-sub u-pt0 u-ta-c">ジョブカン給与計算の登録が完了しました</p>
        <p className="u-mb20">登録したメールアドレス（{email}）に認証のURLを送信しましたのでご確認ください。</p>
        <p>
          しばらく経ってもメールが届かない場合は、迷惑メールフォルダ等を確認するか、再度アカウント登録をお願いします
        </p>
      </div>
    </div>
  </div>
);

export default AccountRegistrationCompleteNotify;
