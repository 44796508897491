import { combineReducers } from 'redux';
import { change, getFormValues, startSubmit, stopSubmit } from 'redux-form';
import axios from 'axios';
import isString from 'lodash/isString';
import _ from 'lodash';

import { CLIENT_BONUS_FORM, CONFIRM_CLIENT_BONUS, UNCONFIRM_CLIENT_BONUS } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { getItemErrors, setItem, setItemError } from 'src/reducers/common/itemReducer';
import { setList, getList } from 'src/reducers/common/listReducer';
import { setGlobalErrors, setGlobalSuccesses, setGlobalWarnings } from 'src/reducers/global';
import { fetchBonusOptions, FORM_NAME_BONUSES_SEARCH } from 'src/reducers/searchForm';
import { formatDate } from 'src/utils/Date';
import { fetchLatestJobStatus, isJobIncomplete } from 'src/reducers/JobStatus';
import { setExtras } from 'src/reducers/common/editableListReducer';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import { listReducer, itemReducer } from '../common';
import {
  fetchEmployeeBonus,
  getEmployeeBonusQueries,
  fetchConfirmJobStatus,
  fetchUpsertJobStatus,
  fetchReportJobStatus
} from '../employees/employeeBonus';

export const REDUCER_NAME = 'clientBonus';

export const getClientBonusItemErrors = state => (getItemErrors(REDUCER_NAME, state) || []).filter(isString);

export const getClientBonusFormValues = state => getFormValues(CLIENT_BONUS_FORM)(state);
export const getClientBonusList = state => getList(REDUCER_NAME, state);
export const setClientBonusList = data => setList(REDUCER_NAME, data);
export const clearErrors = () => dispatch => dispatch(setItemError(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(setItemError(REDUCER_NAME, errors));
export const setChangeConfirmBonusErrors = errors => dispatch =>
  dispatch(setItemError(REDUCER_NAME, [{ changeConfirmBonusErrors: errors }]));
export const getChangeConfirmBonusErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return (errors[0] || {}).changeConfirmBonusErrors || [];
};

// execute if callback is function
const executeCallBack = callback => {
  if (typeof callback === 'function') {
    callback();
  }
};

export const fetchBonus = id => async dispatch => {
  try {
    axios.get(concatParamsToUrl(Urls.CLIENT_BONUS_UPDATE, { id })).then(response => {
      const clientBonus = response.data.payload.clientBonus;
      const { applyForResignedFrom, applyForResignedTo } = response.data.payload.clientBonus;
      dispatch(
        setItem(REDUCER_NAME, {
          ...clientBonus,
          applyForResignedFrom: formatDate(applyForResignedFrom),
          applyForResignedTo: formatDate(applyForResignedTo)
        })
      );
    });
  } catch (exception) {
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

export const newBonus = (values, hideModal) => async (dispatch, getState) => {
  try {
    dispatch(startSubmit(CLIENT_BONUS_FORM));
    const res = await axios.post(Urls.CLIENT_BONUS_CREATE, values);
    dispatch(stopSubmit(CLIENT_BONUS_FORM));
    executeCallBack(hideModal);
    dispatch(fetchUpsertJobStatus());
    dispatch(change(FORM_NAME_BONUSES_SEARCH, 'clientBonusId', res.data.payload.jobStatus.bonusId.toString()));
    dispatch(fetchBonusOptions());
    dispatch(fetchEmployeeBonus(getEmployeeBonusQueries(getState())));
    dispatch(setGlobalWarnings('賞与を作成中です。しばらくお待ちください。'));
  } catch (exception) {
    dispatch(stopSubmit(CLIENT_BONUS_FORM));
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

export const editBonus = (id, values, hideModal) => async (dispatch, getState) => {
  try {
    dispatch(startSubmit(CLIENT_BONUS_FORM));
    const res = await axios.put(concatParamsToUrl(Urls.CLIENT_BONUS_UPDATE, { id }), values);
    dispatch(stopSubmit(CLIENT_BONUS_FORM));
    dispatch(fetchUpsertJobStatus());
    executeCallBack(hideModal);
    dispatch(change(FORM_NAME_BONUSES_SEARCH, 'clientBonusId', res.data.payload.jobStatus.bonusId.toString()));
    dispatch(fetchBonusOptions());
    dispatch(fetchEmployeeBonus(getEmployeeBonusQueries(getState())));
    dispatch(setGlobalWarnings('賞与を更新中です。しばらくお待ちください。'));
  } catch (exception) {
    dispatch(stopSubmit(CLIENT_BONUS_FORM));
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

// @param data:
// releaseDate: 公開日
// sendNotifyToEmployee: 従業員にメールを送るかどうかのフラッグ
export const confirmClientBonus = (clientBonusId, data, onSuccessCallBack, closeModalCallBack) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(clearErrors());
    dispatch(startSubmit(CONFIRM_CLIENT_BONUS));

    const { releaseDate = '', releaseHour = '' } = data;
    const params = { ...data, releaseDate: releaseDate.concat(' ').concat(releaseHour) };
    const confirmUrl = concatParamsToUrl(Urls.CLIENT_BONUS_CONFIRM, { id: clientBonusId });
    await axios.put(confirmUrl, params);

    dispatch(stopSubmit(CONFIRM_CLIENT_BONUS));
    executeCallBack(onSuccessCallBack);
    executeCallBack(closeModalCallBack);
    // start status polling
    dispatch(fetchConfirmJobStatus());
    // Re fetch bonus
    dispatch(fetchBonusOptions());
    dispatch(fetchEmployeeBonus(getEmployeeBonusQueries(getState())));
    dispatch(setGlobalWarnings('賞与確定を処理中です。しばらくお待ちください。'));
  } catch (exception) {
    dispatch(stopSubmit(CONFIRM_CLIENT_BONUS));
    dispatch(setChangeConfirmBonusErrors(exception.response.data.errors.messages));
  }
};

export const unconfirmClientBonus = (clientBonusId, onSuccessCallBack, closeModalCallBack) => async (
  dispatch,
  getState
) => {
  try {
    dispatch(clearErrors());
    dispatch(startSubmit(UNCONFIRM_CLIENT_BONUS));

    const unconfirmUrl = concatParamsToUrl(Urls.CLIENT_BONUS_UNCONFIRM, { id: clientBonusId });
    await axios.put(unconfirmUrl);

    dispatch(stopSubmit(UNCONFIRM_CLIENT_BONUS));
    executeCallBack(onSuccessCallBack);
    executeCallBack(closeModalCallBack);
    dispatch(fetchReportJobStatus(clientBonusId));
    // Re fetch bonus
    dispatch(fetchBonusOptions());
    dispatch(fetchEmployeeBonus(getEmployeeBonusQueries(getState())));
    dispatch(setGlobalSuccesses('賞与の確定を解除しました。'));
  } catch (exception) {
    dispatch(stopSubmit(UNCONFIRM_CLIENT_BONUS));
    dispatch(setChangeConfirmBonusErrors(exception.response.data.errors.messages));
  }
};

export const deleteClientBonus = clientBonusId => async dispatch => {
  try {
    const deleteUrl = concatParamsToUrl(Urls.CLIENT_BONUS_DELETE, { id: clientBonusId });
    await axios.delete(deleteUrl);

    redirectTo(Urls.EMPLOYEE_BONUS_LIST);
  } catch (exception) {
    if (exception.response.status === 409) {
      dispatch(setGlobalErrors(exception.response.data.errors.messages));
    } else {
      dispatch(setErrors(exception.response.data.errors.messages));
    }
  }
};

export const completeBonusPaymentNotification = (bonus, onSuccessCallback) => async (dispatch, getState) => {
  try {
    const url = concatParamsToUrl(Urls.COMPLETE_BONUS_PAYMENT_NOTIFICATION_URL, { id: bonus.id });
    await axios.put(url);
    const bonusList = getClientBonusList(getState());
    dispatch(setClientBonusList({ data: [...bonusList.filter(item => item.id !== bonus.id)] }));
    onSuccessCallback();
    dispatch(setGlobalSuccesses('Todoを完了しました。'));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export const fetchOfficeStationJobs = (interval = INITIAL_POLLING_INTERVAL) => async (dispatch, getState) => {
  const params = {
    job_name: 'officeStationEntryBonusJob'
  };
  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.officeStationEntryBonusJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchOfficeStationJobs(nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
  }
};

export const hideOfficeStationJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'officeStationEntryBonusJob'
    });
    dispatch(fetchOfficeStationJobs());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const exportToOfficeStation = (data, clientBonusId) => async dispatch => {
  try {
    const url = concatParamsToUrl(Urls.EXPORT_BONUS_TO_OFFICE_STATION, { id: clientBonusId });
    await axios.post(url, { ...data });
    dispatch(fetchOfficeStationJobs());
  } catch (e) {
    if (_.get(e, 'response.data.errors')) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      dispatch(setGlobalErrors('システムエラーが発生しました。'));
    }
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
