import { combineReducers } from 'redux';
import { getFormValues, startSubmit, stopSubmit } from 'redux-form';
import remove from 'lodash/remove';
import axios from 'axios';

import * as Urls from 'src/constants/EndpointUrls';
import { CLIENT_ALLOWANCE_ITEM_FORM } from 'src/constants/FormNames';

import { getList, setList } from 'src/reducers/common/listReducer';
import { setItemError } from 'src/reducers/common/itemReducer';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

import { redirectTo, concatParamsToUrl } from 'src/utils/Http';
import { checkedTrueObjToArr } from 'src/utils/Utils';
import { listReducer, itemReducer } from '../common';
import createNamedWrapperReducer from '../createNamedWrapperReducer';

export const REDUCER_NAME = 'clientAllowanceItems';

export const setClientAllowanceItemList = data => setList(REDUCER_NAME, { data });
export const getClientAllowanceItemList = state => getList(REDUCER_NAME, state);
export const getAllowanceItemFormValues = state => getFormValues(CLIENT_ALLOWANCE_ITEM_FORM)(state);

const toUpdateDisplayOrderFormat = allowanceItems =>
  allowanceItems.map((item, index) => ({ id: item.id, displayOrder: index }));

export const saveChangedDisplayOrder = () => async (dispatch, getState) => {
  try {
    const allowanceItems = getClientAllowanceItemList(getState());
    const formatedAllowanceItems = toUpdateDisplayOrderFormat(allowanceItems);

    await axios.post(Urls.CLIENT_ALLOWANCE_ITEM_UPDATE_DISPLAY_ORDER, { allowanceItems: formatedAllowanceItems });
    dispatch(setGlobalSuccesses('並べ替えが成功しました'));
    redirectTo(Urls.CLIENT_ALLOWANCE_ITEMS);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export const createAllowanceItem = data => async dispatch => {
  try {
    const { payrollRulesGroups, ...clientAllowanceItem } = data;
    const payrollRulesGroupIds = checkedTrueObjToArr(payrollRulesGroups);
    const params = { payrollRulesGroupIds, clientAllowanceItem };
    dispatch(startSubmit(CLIENT_ALLOWANCE_ITEM_FORM));
    const response = await axios.post(Urls.CLIENT_ALLOWANCE_ITEMS, params);

    if (response.data.errors.messages.length > 0) {
      dispatch(stopSubmit(CLIENT_ALLOWANCE_ITEM_FORM));
      dispatch(setItemError(REDUCER_NAME, response.data.errors.messages));
    } else {
      redirectTo(Urls.CLIENT_ALLOWANCE_ITEMS);
    }
  } catch (exception) {
    dispatch(stopSubmit(CLIENT_ALLOWANCE_ITEM_FORM));
    dispatch(setItemError(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editAllowanceItem = data => async dispatch => {
  try {
    const { payrollRulesGroups, ...clientAllowanceItem } = data;
    const payrollRulesGroupIds = checkedTrueObjToArr(payrollRulesGroups);
    const params = { payrollRulesGroupIds, clientAllowanceItem };
    const editUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCE_ITEM_UPDATE, { allowanceItemId: data.id });

    const response = await axios.put(editUrl, params);
    if (response.data.errors.messages.length > 0) {
      dispatch(setItemError(REDUCER_NAME, response.data.errors.messages));
    } else {
      redirectTo(Urls.CLIENT_ALLOWANCE_ITEMS);
    }
  } catch (exception) {
    dispatch(setItemError(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const deleteAllowanceItem = allowanceItem => async (dispatch, getState) => {
  try {
    const deleteAllowanceUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCE_ITEM_DELETE, {
      allowanceItemId: allowanceItem.id
    });
    await axios.delete(deleteAllowanceUrl);

    const allowanceItemList = [...getClientAllowanceItemList(getState())];
    remove(allowanceItemList, item => item.id === allowanceItem.id);

    dispatch(setClientAllowanceItemList(allowanceItemList));
    dispatch(setGlobalSuccesses('削除しました'));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
