import React from 'react';

import { formatDateWithHour } from 'src/utils/Date';
import { getInputTypeName } from 'src/utils/Enum';
import MemoList from 'src/features/Memos';
import { Check } from 'jbc-front/components/icons';
import styles from './EmployeeAttendanceTableRow.scss';

const isInputedData = employeeAttendance => employeeAttendance.inputType !== 'first_insert';

const EmployeeAttendanceTableRow = ({ employeeAttendance, onClick }) => (
  <tr onClick={onClick} className="table-hover">
    <td>
      <MemoList employeeId={employeeAttendance.employeeId} />
    </td>
    <td>{employeeAttendance.staffCode}</td>
    <td>{employeeAttendance.employeeDisplayName}</td>
    <td>
      {isInputedData(employeeAttendance) && formatDateWithHour(employeeAttendance.lastSyncAt)}
      {<p>（{getInputTypeName(employeeAttendance.inputType)}）</p>}
    </td>
    <td>{employeeAttendance.isSyncAttendance ? <Check size={16} className={styles.check} /> : ''}</td>
  </tr>
);

export default EmployeeAttendanceTableRow;
