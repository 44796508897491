import React from 'react';
import { useSelector } from 'react-redux';
import { formValueSelector } from 'redux-form';

import CommonModal from 'jbc-front/components/CommonModal';
import ActionButton from 'jbc-front/components/ActionButton';
import { Download } from 'jbc-front/components/icons';
import { REDUCER_NAME } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import { NOTIFICATION_OF_BASE_AMOUNT_REVISION_NOT_CONFIRMED_CSV } from 'src/constants/EndpointUrls';
import { FormSubmitBtn } from 'src/buttons';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH } from 'src/reducers/searchForm';
import { getExtras } from 'src/reducers/common/listReducer';
import styles from './NotConfirmedRevisionModal.scss';

const NotConfirmedRevisionModal = props => {
  const applicableRevisions = useSelector(state => getExtras(REDUCER_NAME, state)).applicableRevisions;
  const sortType = useSelector(state =>
    formValueSelector(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(state, 'sortType')
  );
  const notRevisedRevisions = applicableRevisions.filter(revision => revision.notConfirmedReason);

  const header = '月額改定対象外の従業員一覧';
  const { isOpen, hideModal, yearMonth, applicableOffice } = props;

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, yearMonth }}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form>
          <div className={styles.csv}>
            <ActionButton
              onClick={() =>
                redirectTo(
                  `${NOTIFICATION_OF_BASE_AMOUNT_REVISION_NOT_CONFIRMED_CSV}?${serializeHttpGetParams({
                    revision_year: yearMonth,
                    applicable_office: applicableOffice,
                    sort_type: sortType
                  })}`
                )
              }
              icon={<Download size={15} />}
            >
              CSVダウンロード
            </ActionButton>
          </div>
          <div>
            <table className="m-table-item">
              <thead>
                <tr>
                  <th>スタッフコード</th>
                  <th>氏名</th>
                  <th>対象外理由</th>
                </tr>
              </thead>
              <tbody>
                {notRevisedRevisions &&
                  notRevisedRevisions.map(revision => (
                    <tr className={styles.row} key={`revision_tr_${revision.id}`}>
                      <td>{revision.staffCode}</td>
                      <td>{`${revision.lastName} ${revision.firstName}`}</td>
                      <td>{`${revision.notConfirmedReason}`}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <FormSubmitBtn text="確認" onClick={hideModal} className="u-mr20" />
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default NotConfirmedRevisionModal;
