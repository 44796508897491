import { change as formChange } from 'redux-form';
import { connect } from 'react-redux';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import selector from 'src/utils/Utils';
import { isNumber } from 'src/utils/Number';

import { getEmployeeFormValues, fetchCityCodeByName } from 'src/reducers/employees/employees';

import InhabitantTaxSection from '../components/InhabitantTaxSection';
import { calcEachMonthTaxAmount, calcAnnualTaxAmount } from '../inhabitantTaxCommon';

const mapStateToProps = state => ({
  collectionMethod: selector(getEmployeeFormValues(state), 'employeeInhabitantTax', 'collectionMethod'),
  selectedPayeeCity: selector(getEmployeeFormValues(state), 'employeeInhabitantTax', 'payeeCity'),
  selectedSubmissCity: selector(getEmployeeFormValues(state), 'employeeInhabitantTax', 'reportSubmissionCity'),
  taxAmounts: selector(getEmployeeFormValues(state), 'employeeInhabitantTax', 'taxAmounts')
});

const mapDispatchToProps = dispatch => ({
  handleSelectPayeeCity: city => {
    const { prefectureId = '', id = '' } = city || {};
    dispatch(formChange(EMPLOYEE_FORM, 'employeeInhabitantTax.payeePrefectureId', prefectureId));
    dispatch(formChange(EMPLOYEE_FORM, 'employeeInhabitantTax.payeeCityId', id));
    dispatch(formChange(EMPLOYEE_FORM, 'employeeInhabitantTax.payeeCity', city || {}));
  },
  handleSelectSubmissCity: city => {
    const { prefectureId = '', id = '' } = city || {};
    dispatch(formChange(EMPLOYEE_FORM, 'employeeInhabitantTax.reportSubmissionPrefectureId', prefectureId));
    dispatch(formChange(EMPLOYEE_FORM, 'employeeInhabitantTax.reportSubmissionCityId', id));
    dispatch(formChange(EMPLOYEE_FORM, 'employeeInhabitantTax.reportSubmissionCity', city || {}));
  },
  calcEachMonthTaxAmount: annualTaxAmount => {
    if (!isNumber(annualTaxAmount)) return;
    if (annualTaxAmount < 0) return;

    const eachMonthAmount = calcEachMonthTaxAmount(annualTaxAmount);

    [...Array(12)].forEach((_, i) => {
      dispatch(formChange(EMPLOYEE_FORM, `employeeInhabitantTax.taxAmounts[${i}].amount`, eachMonthAmount[i]));
    });
  },
  calcAnnualTaxAmount: (month, taxAmount) => {
    if (!isNumber(taxAmount)) return;
    if (taxAmount < 0) return;

    dispatch(formChange(EMPLOYEE_FORM, `employeeInhabitantTax.taxAmounts[${month - 1}].amount`, taxAmount));
    dispatch(calcAnnualTaxAmount());
  },
  setCityCodeByName: (setCityCallback, formName) => {
    dispatch(fetchCityCodeByName(setCityCallback, formName));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(InhabitantTaxSection);
