import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, FieldArray, reset as resetForm } from 'redux-form';

import { RESIDENT_TAX_PAYMENT_ADDRS_FORM } from 'src/constants/FormNames';
import { designationNumber, maxLength15 } from 'src/utils/CustomValidators';
import { ErrorsSection } from 'src/components';
import { Section, TextField } from 'jbc-front/components/Form';
import Delete from 'jbc-front/components/icons/Delete';
import { Edit, Strage, MinusCircle } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import Button from 'jbc-front/components/Button';
import { CLIENT_RESIDENT_TAX_PAYMENTS_INSERT_BATCH_CSV } from 'src/constants/EndpointUrls';
import {
  getListTaxPaymentAddresses,
  getListTaxPaymentAddressesErrors,
  updateResidentTaxPaymentAddrs
} from 'src/reducers/clients/residentTaxPaymentAddrs';
import styles from './ResidentTaxPaymentAddrsForm.scss';

const ResidentTaxPaymentAddrRows = ({ fields, mode, removeRow, employeeInhabitantTaxCityIds }) => (
  <tbody>
    {fields.length > 0 &&
      fields.map((item, index) => {
        const data = fields.get(index);
        const disabled = employeeInhabitantTaxCityIds.includes(data.cityId);
        return (
          <tr key={item}>
            <td>{data.organizationCode}</td>
            <td>{data.label}</td>
            <td>
              {mode === 'edit' ? (
                <TextField
                  name={`${item}.obligorDesignationNumber`}
                  maxLength="15"
                  validate={[designationNumber, maxLength15]}
                />
              ) : (
                data.obligorDesignationNumber
              )}
            </td>
            <td>
              {mode === 'edit' && (
                <ActionButton className={styles.default} onClick={() => removeRow(fields, index)} disabled={disabled}>
                  <div className={styles.noteContainer}>
                    {disabled && (
                      <div className={styles.note}>
                        従業員情報に登録されている<br />市区町村は削除できません
                      </div>
                    )}
                    <Delete size={20} />
                  </div>
                </ActionButton>
              )}
            </td>
          </tr>
        );
      })}

    {fields.length === 0 && (
      <tr>
        <td colSpan={4}>データがありません。</td>
      </tr>
    )}
  </tbody>
);

const ResidentTaxPaymentAddrsTable = ({ mode, removeRow, employeeInhabitantTaxCityIds }) => (
  <div className="l-overflow-scroll">
    <table className={`${styles.table} m-table-list m-table-fixed`}>
      <thead>
        <tr>
          <th width={95}>団体コード</th>
          <th width={170}>市区町村名</th>
          <th width={213}>指定番号</th>
          <th width={60} />
        </tr>
      </thead>
      <FieldArray
        name="residentTaxPaymentAddrs"
        component={ResidentTaxPaymentAddrRows}
        props={{ mode, removeRow, employeeInhabitantTaxCityIds }}
      />
    </table>
  </div>
);

class ResidentTaxPaymentAddrs extends React.Component {
  constructor(props) {
    super(props);
    this.changeMode = this.changeMode.bind(this);

    this.state = { mode: 'view' };
  }

  changeMode(mode) {
    this.setState({
      ...this.state,
      mode
    });
  }

  removeRow(fields, index) {
    fields.remove(index);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      pristine,
      submit,
      errors,
      initialValues,
      employeeInhabitantTaxCityIds
    } = this.props;
    const recentSaved = !!(initialValues.residentTaxPaymentAddrs[0] || {}).recentSaved;
    const havingData = initialValues.residentTaxPaymentAddrs && initialValues.residentTaxPaymentAddrs.length > 0;

    if (recentSaved) {
      setTimeout(() => {
        (initialValues.residentTaxPaymentAddrs[0] || {}).recentSaved = false;
        this.changeMode('view');
      }, 300);
    }

    return (
      <Section title="住民税納付先市区町村" className={styles.box}>
        <div className="u-mb30">
          「特別徴収税額の決定・変更通知書」に記載されている「指定番号」を入力してください。<br />
          従業員情報の「住民税＞納付先市区町村名」にて新しい市区町村が登録されると、自動で一覧に追加されます。<br />
        </div>
        <form onSubmit={handleSubmit}>
          <ErrorsSection errors={errors} />
          {havingData &&
            this.state.mode === 'view' && (
              <div className={styles.action}>
                <ActionButton onClick={() => this.changeMode('edit')} icon={<Edit size={16} />}>
                  編集
                </ActionButton>
                <div className={styles.button}>
                  <Button as="a" href={CLIENT_RESIDENT_TAX_PAYMENTS_INSERT_BATCH_CSV} primary widthAuto>
                    指定番号一括登録
                  </Button>
                </div>
              </div>
            )}

          {havingData &&
            this.state.mode === 'edit' && (
              <div className={styles.action}>
                <ActionButton disabled={submitting || pristine} onClick={submit} icon={<Strage size={16} />}>
                  保存
                </ActionButton>
                <ActionButton
                  onClick={() => {
                    this.props.rollbackForm();
                    this.changeMode('view');
                  }}
                  icon={<MinusCircle size={16} />}
                >
                  キャンセル
                </ActionButton>
              </div>
            )}
          <ResidentTaxPaymentAddrsTable
            mode={this.state.mode}
            removeRow={this.removeRow}
            employeeInhabitantTaxCityIds={employeeInhabitantTaxCityIds}
          />
        </form>
      </Section>
    );
  }
}

const ResidentTaxPaymentAddrsForm = reduxForm({
  form: RESIDENT_TAX_PAYMENT_ADDRS_FORM,
  enableReinitialize: true
})(ResidentTaxPaymentAddrs);

const mapStateToProps = state => ({
  initialValues: {
    residentTaxPaymentAddrs: _.get(getListTaxPaymentAddresses(state), 'clientResidentTaxPaymentAddresses')
  },
  employeeInhabitantTaxCityIds: _.get(getListTaxPaymentAddresses(state), 'employeeInhabitantTaxCityIds'),
  errors: getListTaxPaymentAddressesErrors(state)
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(updateResidentTaxPaymentAddrs(data)),
  rollbackForm: () => dispatch(resetForm(RESIDENT_TAX_PAYMENT_ADDRS_FORM))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResidentTaxPaymentAddrsForm);
