import React, { Component } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { FileField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';

import { EMPLOYEE_BONUS_CSV_FORM } from 'src/constants/FormNames';
import { scrollToTop } from 'src/utils/Utils';

import { FormSubmitBtn } from 'src/buttons';
import {
  downloadTemplateCsv,
  importEmployeeBonusCsv,
  getEmployeeBonusItemErrors
} from 'src/reducers/employees/employeeBonus';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import { UTF_8 } from 'src/constants/Generals';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';

import styles from './EmployeeBonusDownloadCsvForm.scss';

class EmployeeBonusCsvInsideForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null
    };
  }

  /* eslint-disable react/no-did-update-set-state */
  componentDidUpdate(prevProps) {
    if (this.props.errors !== prevProps.errors) {
      this.setState({
        errors: this.props.errors
      });
      scrollToTop();
    }
    this.props.updateState(this.state);
  }
  /* eslint-enable react/no-did-update-set-state */

  render() {
    const { handleSubmit, submitting, pristine, submit, downloadCsv } = this.props;

    return (
      <div className={styles.wrap}>
        <div className={styles.head}>アップロード手順</div>
        <div className={styles.body}>
          <form onSubmit={handleSubmit}>
            <dl className={styles.label}>
              <dt className={styles.number}>1</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>ファイルの出力形式を選択してダウンロードしたCSVファイルをご利用ください。</p>
                <DownloadCsvEncodingRadioButton
                  name={'encoding'}
                  label={null}
                  options={ENCODING_OPTIONS}
                  required={false}
                />
                <Button onClick={() => downloadCsv()} widthWide>
                  賞与データをダウンロード
                </Button>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>2</dt>
              <dd className={styles.detail}>
                下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したい支給・控除項目の値を入れてください。
                <p className={styles.caution}>
                  各種保険料など、項目を自動計算させたい場合は、その支給・控除項目を空白にするか、列ごと削除してから取り込んでください。<br />
                  値を入力して取り込んだ場合は、自動計算されません。<br />
                  ※取り込み後に自動計算に戻したい場合は、各従業員の賞与編集画面から「手入力前に戻す」を押してください。<br />
                  <br />
                  また、アップロードが不要な従業員がいる場合は、該当するデータを行ごと削除してからアップロードすることも可能です。
                </p>
              </dd>
            </dl>

            <dl className={styles.label}>
              <dt className={styles.number}>3</dt>
              <dd className={styles.detail}>
                <p className={styles.text}>完成したファイルを選択し、アップロードして下さい。</p>
                <FileField label="支給・控除項目のデータファイル" name="employee_bonus_csv" accepted="csv" required />
              </dd>
            </dl>

            <FormSubmitBtn text="アップロード" {...{ submitting, pristine, submit }} widthWide />
          </form>
        </div>
      </div>
    );
  }
}

const EmployeeBonusDownloadCsvForm = reduxForm({
  form: EMPLOYEE_BONUS_CSV_FORM
})(EmployeeBonusCsvInsideForm);
const mapStateToProp = state => ({
  initialValues: { encoding: UTF_8 },
  errors: getEmployeeBonusItemErrors(state)
});
const mapDispatchToProp = dispatch => ({
  onSubmit: data => dispatch(importEmployeeBonusCsv(data)),
  downloadCsv: () => dispatch(downloadTemplateCsv())
});

export default connect(mapStateToProp, mapDispatchToProp)(EmployeeBonusDownloadCsvForm);
