import React from 'react';

import { formatDateWithHour } from 'src/utils/Date';
import Loading from 'src/components/Loading';
import styles from './JobStatus.scss';

const JobInprogress = ({ statusText, jobInfo, startAt }) => (
  <li>
    <div className={styles.waiting}>
      <div className={styles.loading}>
        <Loading />
      </div>
      <div className={styles.info}>
        <p className={styles.title}>
          {jobInfo}
          {statusText}
        </p>
        <div className={styles.dates}>
          <dl>
            <dt>実行日時：</dt>
            <dd>{formatDateWithHour(startAt)}</dd>
          </dl>
        </div>
      </div>
    </div>
  </li>
);

export default JobInprogress;
