import { combineReducers } from 'redux';
import axios from 'axios';
import _ from 'lodash';

import * as Urls from 'src/constants/EndpointUrls';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import { redirectTo } from 'src/utils/Http';
import { setList, getList } from 'src/reducers/common/listReducer';
import { listReducer } from 'src/reducers/common';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';

export const REDUCER_NAME = 'clientSyncTaskSettings';

export const setClientTaskSyncSettingList = data => setList(REDUCER_NAME, { data });
export const getClientTaskSyncSettingList = state => getList(state, REDUCER_NAME);

export const saveClientSyncTaskSettings = data => async dispatch => {
  try {
    await axios.put(Urls.COORDINATION_JBC_SETTINGS_UPDATE, data);
    dispatch(setGlobalSuccesses('連携設定を保存しました。'));
    redirectTo(Urls.COORDINATION_SERVICE_LIST);
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']) || e.message;
    dispatch(setGlobalErrors(msgs));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME)
});
