import { connect } from 'react-redux';
import _ from 'lodash';
import { getFormValues } from 'redux-form';
import { uploadCsv, getListTaxPaymentAddressesErrors } from 'src/reducers/clients/residentTaxPaymentAddrs';
import { RESIDENT_TAX_PAYMENT_CSV_IMPORT_FORM } from 'src/constants/FormNames';
import InsertBatchCsv from '../components/InsertBatchCsv';

const mapStateToProps = state => ({
  errors: getListTaxPaymentAddressesErrors(state),
  selectedEncoding: _.get(getFormValues(RESIDENT_TAX_PAYMENT_CSV_IMPORT_FORM)(state), 'encoding')
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(uploadCsv(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(InsertBatchCsv);
