import { combineReducers } from 'redux';
import _ from 'lodash';
import axios from 'axios';
import humps from 'humps';

import * as Urls from 'src/constants/EndpointUrls';
import { getList, setList, fetchList, fetchListSuccess, fetchListFailure } from 'src/reducers/common/listReducer';
import { setGlobalErrors } from 'src/reducers/global';
import createNamedWrapperReducer from '../createNamedWrapperReducer';
import { listReducer, itemReducer } from '../common';

export const REDUCER_NAME = 'adminEmailNotificationSettings';
export const getEmailSettingList = state => getList(REDUCER_NAME, state);
export const setEmailSettingList = emailSettingList => setList(REDUCER_NAME, { data: emailSettingList, pageCount: 1 });

export const fetchEmailNotificationSettingList = (perPage = 10) => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));

    const response = await axios.get(Urls.SEARCH_EMAIL_NOTIFICATION_SETTING, {
      params: humps.decamelizeKeys({ perPage })
    });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.emailSettings,
      pageCount: 1,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const changeItemCheck = (toChangeItem, field) => (dispatch, getState) => {
  const oldList = getEmailSettingList(getState());
  const newList = _.map(oldList, item => {
    if (item.id !== toChangeItem.id) return item;
    return { ...item, ..._.set(item, field, !_.get(item, field)) };
  });
  dispatch(setEmailSettingList(newList));
};

export const saveEmailSettingList = closeEditMode => async (dispatch, getState) => {
  try {
    const adminEmailNotificationSetting = getEmailSettingList(getState());
    const response = await axios.put(Urls.UPDATE_EMAIL_NOTIFICATION_SETTING, { adminEmailNotificationSetting });
    dispatch(setEmailSettingList(response.data.payload.emailSettings));
    closeEditMode();
  } catch (exception) {
    dispatch(setGlobalErrors('エラーが発生しました。ブラウザをリロードして再度お試しください。'));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
