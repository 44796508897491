import React, { Component, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm } from 'redux-form';

import onClickOutside from 'react-onclickoutside';

import Button from 'jbc-front/components/Button';
import { FileField } from 'jbc-front/components/Form';
import { Question, Upload } from 'jbc-front/components/icons';

import { FLAT_TAX_REDUCTION_EMPLOYEE_LIST } from 'src/constants/EndpointUrls';
import { FLAT_TAX_REDUCTION_CSV_UPLOAD_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { AMOUNT_MODE, uploadCsv } from 'src/reducers/flat_tax_reductions/csv';

import styles from './FlatTaxReductionCsvUpload.scss';
import image from '../images/upload_csv_help.png';

const HelpUpdateCsv = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      return (
        <div className={styles.help}>
          <div className={styles.image}>
            <img src={image} alt="" />
          </div>
          <br />
          CSVデータは定額減税対象人数一覧画面からダウンロードできます
        </div>
      );
    }
  }
);

const FileImportUpdate = ({ submitting, handleSubmit, errors, mode }) => {
  const [showHelp, setShowHelp] = useState(false);
  const isAmountMode = mode === AMOUNT_MODE;

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>アップロード手順</div>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <ErrorsSection errors={errors} />

          <dl className={styles.label}>
            <dt className={styles.number}>1</dt>
            <dd className={styles.detail}>
              <div>
                {isAmountMode ? '既減税額' : '定額減税対象人数'}を<a
                  href={FLAT_TAX_REDUCTION_EMPLOYEE_LIST}
                  className="u-txt-link"
                  target="_blank"
                >
                  こちら
                </a>
                <span
                  className={styles.helpWrap}
                  onClick={() => {
                    setShowHelp(!showHelp);
                  }}
                  role="presentation"
                >
                  <Question />
                  {showHelp && (
                    <HelpUpdateCsv
                      hide={() => {
                        setShowHelp(false);
                      }}
                    />
                  )}
                </span>
                からダウンロードしてください。
              </div>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>2</dt>
            <dd className={styles.detail}>
              下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したい従業員の情報を入れてください。
              <div className={styles.warning}>
                <span className={styles.warningTitle}>【注意】</span>
                <ul className={styles.warningList}>
                  <li>
                    ①以下の項目は<span className={styles.requiredText}>入力必須</span>になります。
                    <ul className={styles.requireList}>
                      <li>・スタッフコード</li>
                    </ul>
                  </li>
                  <li>
                    ②スタッフコードが一致する従業員情報を更新しますので<span className={styles.requiredText}>
                      正しいスタッフコードをご記入ください
                    </span>。
                    <br />
                    減税対象人数ダウンロードよりダウンロードしたファイルには
                    「姓」「名」列が表示されていますが、インポートする際にはいずれの列がなくとも情報は取り込まれます。
                  </li>
                  <li>③定額減税対象人数は半角数字で0~99の値で入力してください。</li>
                  <li>④既減税額は半角数字で0~9999999の値で入力してください。</li>
                </ul>
              </div>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>3</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
              <FileField
                name="flat_tax_reduction_csv"
                className={styles.input}
                label="減税対象者・既減税額のデータファイル"
                accepted="csv"
                required
              />
            </dd>
          </dl>

          <Button as="button" primary type="submit" disabled={submitting} icon={<Upload size={16} />} widthWide>
            アップロード
          </Button>
        </form>
      </div>
    </div>
  );
};

const FlatTaxReductionCsvUploadForm = reduxForm({
  form: FLAT_TAX_REDUCTION_CSV_UPLOAD_FORM,
  enableReinitialize: true
})(FileImportUpdate);

const FlatTaxReductionCsvUploadFormWrapper = ({ setResult, mode }) => {
  const dispatch = useDispatch();
  const onSubmit = data => dispatch(uploadCsv(data, setResult));

  return <FlatTaxReductionCsvUploadForm onSubmit={onSubmit} mode={mode} />;
};

export { FlatTaxReductionCsvUploadFormWrapper as FlatTaxReductionCsvUploadForm };
// ↓ sprint132 以降に消す
export default FlatTaxReductionCsvUploadFormWrapper;
