import _ from 'lodash';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import { REDUCER_NAME, fetchIncomeTaxCollectionData } from 'src/reducers/reports/reportIncomeTaxCollectionData';
import { getList } from 'src/reducers/common/listReducer';
import IncomeTaxCollectionData from '../IncomeTaxCollectionData';

const incomeTaxCollectionData = state => {
  const list = getList(REDUCER_NAME, state).incomeTaxCollectionData;
  if (_.isEmpty(list)) {
    return [];
  }
  return list;
};

const mapStateToProps = (state, ownProps) => ({
  selectableYears: ownProps.selectableYears,
  incomeTaxCollectionData: incomeTaxCollectionData(state),
  loading: _.isUndefined(getList(REDUCER_NAME, state).incomeTaxCollectionData)
});

const mapDispatchToProps = dispatch => ({
  search: year => {
    dispatch(fetchIncomeTaxCollectionData(year));
  }
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(IncomeTaxCollectionData);
