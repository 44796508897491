import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import {
  setInvisibleConfimPayJob,
  getConfirmJobs,
  pollFetchVisibleConfirmPayJobs
} from 'src/reducers/employees/employeePays';
import { Close } from 'jbc-front/components/icons';
import { JobSuccess, JobFailed, JobInprogress } from 'src/components/JobStatus';
import styles from './ConfirmationPayJobStatus.scss';

const TaskResult = ({ tasks, status, hideJobNotifications }) => {
  let statusTextHash = {
    confirm: 'の給与を確定しました。',
    all_rollback: 'の給与を一括で手入力前に戻しました。'
  };
  let tasksStyle = styles.tasksSuccess;

  if (status === 'failed') {
    statusTextHash = {
      confirm: 'の給与を確定できませんでした。',
      all_rollback: 'の給与を一括で手入力前に戻せませんでした。'
    };
    tasksStyle = styles.tasksFailed;
  } else if (status === 'inProgress' || status === 'waiting') {
    statusTextHash = {
      confirm: 'の給与を確定中です。',
      all_rollback: 'の給与を一括で手入力前に戻しています。'
    };
    tasksStyle = styles.tasksInprogress;
  }

  return (
    <React.Fragment>
      {tasks &&
        tasks.length > 0 && (
          <div className={tasksStyle}>
            <div className={styles.inner}>
              {status === 'success' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideJobNotifications(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => (
                      <JobSuccess key={task.id} {...task} statusText={statusTextHash[task.jobType]} />
                    ))}
                  </ul>
                </React.Fragment>
              )}
              {status === 'failed' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideJobNotifications(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobFailed key={task.id} {...task} statusText={statusTextHash[task.jobType]} />)}
                  </ul>
                </React.Fragment>
              )}
              {(status === 'inProgress' || status === 'waiting') && (
                <React.Fragment>
                  <ul className={styles.list}>
                    {tasks.map(task => (
                      <JobInprogress key={task.id} {...task} statusText={statusTextHash[task.jobType]} />
                    ))}
                  </ul>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      {isEmpty(tasks) && null}
    </React.Fragment>
  );
};

class ConfirmationPayJobStatus extends React.Component {
  componentDidMount() {
    this.props.pollFetchVisibleConfirmPayJobs();
  }

  render() {
    const { confirmJobs = {}, hideJobNotifications } = this.props;

    return (
      <React.Fragment>
        {isEmpty(confirmJobs) ? null : (
          <div>
            {Object.keys(confirmJobs).map(status => (
              <TaskResult
                key={status}
                tasks={confirmJobs[status]}
                status={status}
                hideJobNotifications={hideJobNotifications}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    confirmJobs: getConfirmJobs(state) || {}
  }),
  dispatch => ({
    pollFetchVisibleConfirmPayJobs: () => dispatch(pollFetchVisibleConfirmPayJobs()),
    hideJobNotifications: tasks => {
      const taskIds = tasks.map(t => t.id).join('_');
      if (taskIds.length > 0) {
        dispatch(setInvisibleConfimPayJob(taskIds));
      }
    }
  })
)(ConfirmationPayJobStatus);
