import React from 'react';
import { useSelector } from 'react-redux';
import LoadingView from 'src/components/LoadingView';
import { getExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME } from 'src/reducers/clients/allowances';
import AllowanceRow from './AllowanceRow';
import styles from './AllowancesTable.scss';

const AllowancesTable = ({ allowances }) => {
  const isListLoading = useSelector(state => getExtras(REDUCER_NAME, state).isLoading);
  return (
    <LoadingView isLoading={isListLoading}>
      <div className="l-overflow-scroll">
        <table className="m-table-list">
          <colgroup>
            <col width={200} />
            <col width={500} />
            <col width={100} />
          </colgroup>
          <thead>
            <tr>
              <th>項目名</th>
              <th>金額</th>
              <th className={styles.action} />
            </tr>
          </thead>
          <tbody>
            {(!allowances || allowances.length === 0) && (
              <tr>
                <td colSpan={3}>データがありません。</td>
              </tr>
            )}
            {allowances && allowances.map(allowance => <AllowanceRow key={allowance.id} allowance={allowance} />)}
          </tbody>
        </table>
      </div>
    </LoadingView>
  );
};

export default AllowancesTable;
