import React from 'react';
import { MemoActionBtn } from 'src/buttons';
import ActionButton from 'jbc-front/components/ActionButton';
import style from './Memos.scss';
import MemoDataModal from './MemoDataModal';

class NewMemo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMemoModalOpen: false,
      initValue: {
        employeeId: this.props.employeeId,
        memoColorId: 1
      }
    };

    this.openMemoModal = this.openMemoModal.bind(this);
    this.closeMemoModal = this.closeMemoModal.bind(this);
  }

  openMemoModal(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.isMemoModalOpen) return;
    this.props.changeMemoModal();
    this.setState({ ...this.state, isMemoModalOpen: true });
  }

  closeMemoModal() {
    if (!this.state.isMemoModalOpen) return;
    this.props.changeMemoModal();
    this.setState({ ...this.state, isMemoModalOpen: false });
  }

  render() {
    const { fromMemoTable, size } = this.props;
    const memoBtn = fromMemoTable ? (
      <ActionButton onClick={this.openMemoModal}>新規作成</ActionButton>
    ) : (
      <MemoActionBtn onClick={this.openMemoModal} size={size} />
    );
    return (
      <div
        className={style.newBtn}
        onClick={e => {
          e.stopPropagation();
        }}
        role="presentation"
      >
        {memoBtn}
        <MemoDataModal
          isOpen={this.state.isMemoModalOpen}
          hideModal={this.closeMemoModal}
          initialValues={this.state.initValue}
        />
      </div>
    );
  }
}

export default NewMemo;
