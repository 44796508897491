import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import _ from 'lodash';

import PdfFile from 'jbc-front/components/icons/PdfFile';
import FbData from 'jbc-front/components/icons/FbData';
import ActionButton from 'jbc-front/components/ActionButton';
import BreadCrumb from 'jbc-front/components/BreadCrumb';

import * as Urls from 'src/constants/EndpointUrls';
import { FORM_NAME_PAYS_SEARCH } from 'src/reducers/searchForm';
import PaymentDateSelectBoxes from 'src/components/PaymentDateSelectBoxes';
import PaymentYearMonthSelectBoxes from 'src/components/PaymentYearMonthSelectBoxes';
import { formatDate } from 'src/utils/Date';
import PayWithInhabitantTableContainer from '../containers/PayWithInhabitantTableContainer';
import ShowInhabitantTaxTablePDFModalContainer from '../containers/SelectInhabitantTaxTablePDFModalContainer';
import InhabitantTaxFBDataModalContainer from '../containers/InhabitantTaxFBDataModalContainer';
import styles from './ShowInhabitantTaxFbPage.scss';

const ShowInhabitantTaxFbPage = props => {
  const {
    employeePayWithInhabitants,
    targetPeriod,
    inhabitantTaxFBPdfUrl,
    fetchUniquePayrollRuleGroups,
    fetchGroupedPaymentMonths,
    fetchInhabitantTaxData,
    formValues,
    isFromLocationEmployeePays,
    isClosingAndPaymentDateOnlyOne,
    isLoadingFetchUniquePaymentDates,
    isLoadingFetchGroupedPaymentMonths,
    isListLoading
  } = props;
  const [isShowPreview, setIsShowPreview] = useState(false);
  const [isFbDataModalOpen, setIsFbDataModalOpen] = useState(false);
  const [payrollGroupSelectorType, setPayrollGroupSelectorType] = useState('each_payroll_rules_group');
  const [isSelectPDFModalOpen, setIsSelectPDFModalOpen] = useState(false);

  // 全給与の締日支給日が同一の場合は全社合計・締日支給日の選択はさせない
  const isShowPayrollGroupSelector = !isClosingAndPaymentDateOnlyOne;

  const ShowPaymentDateAndDueDate = ({ period }) => (
    <span style={isClosingAndPaymentDateOnlyOne ? {} : { marginLeft: '275px' }} className={styles.period}>
      納付期限：{!_.isEmpty(period) && !isListLoading ? formatDate(period.dueDate) : ''}
    </span>
  );

  // 全社合計と締日支給日別の切り替え
  const handleChangePayrollGroupSelector = newValue => {
    if (newValue === payrollGroupSelectorType) {
      return;
    }
    setPayrollGroupSelectorType(newValue);
    if (newValue === 'all') {
      fetchGroupedPaymentMonths();
    } else {
      fetchUniquePayrollRuleGroups();
    }
  };

  // 初期表示時
  useEffect(() => {
    if (isClosingAndPaymentDateOnlyOne || isFromLocationEmployeePays) {
      setPayrollGroupSelectorType('each_payroll_rules_group');
      fetchUniquePayrollRuleGroups();
    } else {
      setPayrollGroupSelectorType('all');
      fetchGroupedPaymentMonths();
    }
  }, []);

  // 全社合計の月毎のセレクトボックスと支給日別のセレクトボックスを変更した際の処理
  useEffect(
    () => {
      if (_.isUndefined(formValues)) {
        return;
      }
      if (payrollGroupSelectorType === 'all') {
        if (_.isUndefined(formValues.groupedPaymentMonths) || isLoadingFetchGroupedPaymentMonths) {
          return;
        }
        fetchInhabitantTaxData(formValues, true);
      } else {
        if (
          _.isUndefined(formValues.uniquePaymentDate) ||
          _.isUndefined(formValues.uniquePayrollRuleGroups) ||
          isLoadingFetchUniquePaymentDates
        ) {
          return;
        }
        fetchInhabitantTaxData(formValues, false);
      }
    },
    [formValues, isLoadingFetchUniquePaymentDates, isLoadingFetchGroupedPaymentMonths]
  );

  return (
    <div className="l-wrap-xl l-contents-wrap">
      <BreadCrumb>
        <BreadCrumb.Item label="給与一覧" path={Urls.EMPLOYEE_PAY_LIST} />
        <BreadCrumb.Item label="住民税振込一覧表" />
      </BreadCrumb>
      <div className="l-title-wrap">
        <h1 className="m-title-main">住民税振込一覧表</h1>
        <p className="m-title-main-note">住民税振込前に、住民税振込額や納付先市区町村を確認できます。</p>
        <ul className={styles.notes}>
          <li>
            ※納付先市区町村については、
            <a href={Urls.EMPLOYEES_LIST} className="u-txt-link">
              従業員情報
            </a>
            の、「住民税＞納付先市区町村名」で設定された内容が反映されます。
          </li>
          <li>
            ※指定番号については、
            <a href={Urls.CLIENT_RESIDENT_TAX_PAYMENTS_LIST} className="u-txt-link">
              住民税納付先
            </a>
            で設定された内容が反映されます。
          </li>
          <li>※確定している給与情報を参照します。修正する場合は、給与確定解除した後に、再確定をお願いします。</li>
        </ul>
      </div>
      {isShowPayrollGroupSelector && (
        <div>
          <ul className={styles.payrollGroupSelector}>
            <li>
              <button
                className={payrollGroupSelectorType === 'all' ? styles.payrollGroupSelectorActive : ''}
                onClick={() => handleChangePayrollGroupSelector('all')}
              >
                全社合計
              </button>
            </li>
            <li>
              <button
                className={
                  payrollGroupSelectorType === 'each_payroll_rules_group' ? styles.payrollGroupSelectorActive : ''
                }
                onClick={() => handleChangePayrollGroupSelector('each_payroll_rules_group')}
              >
                締日支給日別
              </button>
            </li>
          </ul>
        </div>
      )}
      <div className={styles.head}>
        <div className={styles.selectBox}>
          {(!isShowPayrollGroupSelector || payrollGroupSelectorType === 'each_payroll_rules_group') && (
            <PaymentDateSelectBoxes formName={FORM_NAME_PAYS_SEARCH} onlyConfirmed />
          )}
          {isShowPayrollGroupSelector &&
            payrollGroupSelectorType === 'all' && (
              <PaymentYearMonthSelectBoxes formName={FORM_NAME_PAYS_SEARCH} onlyConfirmed />
            )}
        </div>
        <div className={styles.buttonBox}>
          <div>
            <ActionButton
              primary
              icon={<PdfFile size={18} />}
              className={styles.buttonPdf}
              onClick={() => setIsSelectPDFModalOpen(true)}
              disabled={_.isEmpty(employeePayWithInhabitants)}
            >
              PDF出力
            </ActionButton>
            {isSelectPDFModalOpen && (
              <ShowInhabitantTaxTablePDFModalContainer
                payrollGroupSelectorType={payrollGroupSelectorType}
                formValues={formValues}
                isOpen={isSelectPDFModalOpen}
                hideModal={() => setIsSelectPDFModalOpen(false)}
                isShowPreview={isShowPreview}
                setIsShowPreview={setIsShowPreview}
                employeePayWithInhabitants={employeePayWithInhabitants}
                url={inhabitantTaxFBPdfUrl(payrollGroupSelectorType)}
              />
            )}
          </div>
          <div>
            <ActionButton
              primary
              icon={<FbData height={20} width={18} />}
              widthAuto
              className={classnames('ignore-react-onclickoutside', styles.buttonFbData)}
              onClick={() => setIsFbDataModalOpen(true)}
              disabled={_.isEmpty(employeePayWithInhabitants)}
            >
              振込FBデータ出力
            </ActionButton>
            {isFbDataModalOpen && (
              <InhabitantTaxFBDataModalContainer
                isOpen={isFbDataModalOpen}
                hideModal={() => setIsFbDataModalOpen(false)}
                targetPeriod={targetPeriod}
                payrollGroupSelectorType={payrollGroupSelectorType}
                formValues={formValues}
              />
            )}
          </div>
        </div>
      </div>
      <ShowPaymentDateAndDueDate period={targetPeriod} />
      <PayWithInhabitantTableContainer isListLoading={isListLoading} {...{ employeePayWithInhabitants }} />
    </div>
  );
};

export default ShowInhabitantTaxFbPage;
