import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { connectRouter } from 'connected-react-router';
import { reducer as notificationsReducer } from 'reapop';

import { reducer as formErrorsReducer } from 'jbc-front/components/FormErrors';
import selectOptions from './selectOptions';
import * as clientReducers from './clients';
import * as officeReducers from './offices';
import * as userReducers from './users';
import * as employeeReducers from './employees';
import * as reportReducers from './reports';
import { memos } from './memos';
import systemAnnounces from './system_announces/system_announces';
import employeeAnnounces from './employee_announces/employee_announces';
import clientAnnounces from './client_announces/client_announces';
import procedures from './procedures/procedures';
import adminEmailNotificationSettings from './admin_email_notification_settings/admin_email_notification_settings';
import myNumbers from './my_numbers/myNumbers';
import searchForm from './searchForm';
import JobStatus from './JobStatus';
import egov from './egov';

const masterDataReducer = (state = {}, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

const rootReducer = history =>
  combineReducers({
    selectOptions,
    notifications: notificationsReducer(),
    ...employeeReducers,
    ...clientReducers,
    ...officeReducers,
    ...userReducers,
    ...reportReducers,
    memos,
    systemAnnounces,
    employeeAnnounces,
    clientAnnounces,
    procedures,
    adminEmailNotificationSettings,
    myNumbers,
    master: masterDataReducer,
    form: formReducer,
    router: connectRouter(history),
    formErrors: formErrorsReducer,
    searchForm,
    JobStatus,
    egov
  });

export default rootReducer;
