import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import humps from 'humps';

// Form
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import { EMPLOYEE_PAY_CSV_FORM } from 'src/constants/FormNames';

// Utils
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import * as Urls from 'src/constants/EndpointUrls';

import { ENCODING_OPTIONS } from 'src/constants/Options';
import { UTF_8 } from 'src/constants/Generals';

const downloadPayFbCsv = (data, paymentRange, queries) => {
  const { encoding } = data;
  const { closingDay, paymentDay, paymentMonthType, sort = 'staffCode', order = 'asc' } = humps.camelizeKeys(queries);
  const { yearMonth, payrollRulesGroups } = paymentRange;
  const params = { encoding, closingDay, paymentDay, paymentMonthType, yearMonth, payrollRulesGroups, sort, order };
  redirectTo(`${Urls.DOWNLOAD_PAY_FB_CSV}?${serializeHttpGetParams(params)}`);
};

const DownloadCsvInsideForm = ({ handleSubmit, submitting, submit, cancelBtnClick, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <DownloadCsvEncodingRadioButton
          name={'encoding'}
          label={'ファイル形式を選択'}
          options={ENCODING_OPTIONS}
          required={false}
        />
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="ダウンロード" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let DownloadCsvForm = reduxForm({
  form: EMPLOYEE_PAY_CSV_FORM,
  initialValues: { encoding: UTF_8 }
})(DownloadCsvInsideForm);

DownloadCsvForm = connect(undefined, (dispatch, ownProps) => ({
  onSubmit: data => {
    downloadPayFbCsv(data, ownProps.paymentRange, ownProps.queries);
    ownProps.cancelBtnClick();
  }
}))(DownloadCsvForm);

const DownloadFbCsvModal = ({ isOpen, hideModal, paymentRange, queries }) => {
  const header = '給与振込ダウンロード';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <DownloadCsvForm cancelBtnClick={hideModal} {...{ header, paymentRange, queries }} />
    </CommonModal>
  );
};

export default DownloadFbCsvModal;
