import React from 'react';
import get from 'lodash/get';
import take from 'lodash/take';
import { connect } from 'react-redux';
import truncate from 'lodash/truncate';

import { ALERTS_LIST } from 'src/constants/EndpointUrls';
import ListGroup from 'jbc-front/components/ListGroup';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { recordDisplay } from 'src/utils/Utils';
import { showDateNewLine } from './SystemAnnounces';
import styles from './MemoListToday.scss';

const MAX_NOTE_LENGTH = 33;
const MAX_MEMO_NUMBER = 5;

const MemoColor = ({ memoColorId, memoColors }) => {
  const color = memoColors.find(item => item.value === memoColorId.toString()).label;
  return <span style={{ backgroundColor: color }} className={styles.circle} />;
};

const MemoListToday = ({ todayMemos, memoColors }) => (
  <div>
    <ListGroup>
      <ListGroup.Title>従業員のメモ</ListGroup.Title>
      {get(todayMemos, 'length') > 0 ? (
        take(todayMemos, MAX_MEMO_NUMBER).map(memo => (
          <ListGroup.Item
            className={styles.list}
            key={memo.id}
            {...{ href: `/employees/${memo.employeeId}`, target: '_blank', as: 'a' }}
          >
            <div className={styles.date}>{showDateNewLine(recordDisplay.currentOrPrevYear(memo.alertDate))}</div>
            <div className={styles.detail}>
              <div>{memo.employeeDisplayName}</div>
              <div>{truncate(memo.note, { length: MAX_NOTE_LENGTH, omission: '...' })}</div>
            </div>
            <div className={styles.memocolor}>
              <MemoColor memoColorId={memo.memoColorId} memoColors={memoColors} />
            </div>
          </ListGroup.Item>
        ))
      ) : (
        <ListGroup.Item className={styles.empty}>従業員のメモはありません</ListGroup.Item>
      )}
    </ListGroup>
    {get(todayMemos, 'length') > MAX_MEMO_NUMBER && (
      <div className={styles.more}>
        <a href={ALERTS_LIST} className={styles.more} target="_blank">
          すべて見る
        </a>
      </div>
    )}
  </div>
);

export default connect(state => ({
  memoColors: getSelectOptions(state, 'memoColors')
}))(MemoListToday);
