import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { getList, getMode, isAllChecked, changeAllUserChecked } from 'src/reducers/users/roleUsers';
import { FORM_NAME_ROLE_USERS_SEARCH } from 'src/reducers/searchForm';

import SortableTh from 'src/components/SortableTh';

import EditableField from './EditableFieldContainer';
import EditPolicy from './EditPolicy';

import styles from './EditableList.scss';

const EditableList = ({ users, mode, allChecked, onChangeAllChecked }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list m-table-fixed">
      <thead>
        <tr>
          {mode === 'EDIT' && (
            <th
              onClick={() => onChangeAllChecked(!allChecked)}
              className={allChecked ? 'm-table-checkbox-on' : 'm-table-checkbox-off'}
            >
              <input type="checkbox" readOnly checked={allChecked} />
            </th>
          )}
          <SortableTh field="email" formName={FORM_NAME_ROLE_USERS_SEARCH}>
            メールアドレス
          </SortableTh>
          <th className={styles.staffCode}>スタッフコード</th>
          <SortableTh field="name" formName={FORM_NAME_ROLE_USERS_SEARCH}>
            名前
          </SortableTh>
          <th className={styles.role}>権限</th>
        </tr>
      </thead>
      <tbody>{users.map(user => <EditableField key={`${user.id}_${user.clientRoleId}`} user={user} />)}</tbody>
    </table>
    {_.some(users, { checked: true }) && (
      <div className="u-mt10">
        <EditPolicy />
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  users: getList(state),
  allChecked: isAllChecked(state),
  mode: getMode(state)
});
const mapDispatchToProps = dispatch => ({
  onChangeAllChecked: newChecked => {
    dispatch(changeAllUserChecked(newChecked));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableList);
