import React from 'react';
import { connect } from 'react-redux';

import { TextField } from 'jbc-front/components/Form';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { getItemExtra } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME } from 'src/reducers/clients/allowances';
import TargetSettings from './TargetSettings';

const AllowanceDeductionFixed = ({ isDefaultDeductionItem }) => (
  <div>
    {!isDefaultDeductionItem && <TextField label="金額" name="allowanceAmount" yen required validate={isMoneyNumber} />}
    <TargetSettings allowanceType="deduction" />
  </div>
);

export default connect(state => ({
  isDefaultDeductionItem: getItemExtra(REDUCER_NAME, state).isDefaultDeductionItem || false
}))(AllowanceDeductionFixed);
