import React from 'react';
import { Field } from 'redux-form';
import Paginator from 'jbc-front/components/Paginator';
import { SearchSelectBox, SearchResult } from 'jbc-front/components/SearchForm';

import { LIMITS } from 'src/reducers/searchForm';
import styles from 'src/components/PaginatorWrapper.scss';

const PaginatorWrapper = ({ limit, count, pageCount, isLoading, page, onClick, noPaginator, noLimit }) => {
  const styleName = noLimit ? styles.flexEnd : styles.flex;

  return (
    <React.Fragment>
      <div className={styles.container}>
        {!noPaginator &&
          pageCount > 1 && (
            <Paginator current={page} totalResult={count} rowsPerPage={limit} pageLinks={7} onClick={onClick} />
          )}
        <div className={styleName}>
          <SearchResult total={count} page={page} limit={limit} isLoading={isLoading} failureMessage="" />
          {!noLimit &&
            !isLoading &&
            pageCount > 0 && <Field name="limit" component={SearchSelectBox} options={LIMITS} width={130} />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaginatorWrapper;
