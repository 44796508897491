import { combineReducers } from 'redux';
import axios from 'axios';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';

import { CLIENT_BONUS_ALLOWANCE_FORM } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import { listReducer, itemReducer } from '../common';
import { setList, getList } from '../common/listReducer';
import { setItem, getItem, editItemFailure, getItemErrors, setItemExtra, getItemExtra } from '../common/itemReducer';
import createNamedWrapperReducer from '../createNamedWrapperReducer';

export const REDUCER_NAME = 'clientBonusAllowances';

export const setClientBonusAllowancesList = data => setList(REDUCER_NAME, data);
export const getClientAllowancesBonusList = state => getList(REDUCER_NAME, state);
export const setClientBonusAllowanceItem = data => setItem(REDUCER_NAME, data);
export const getClientBonusAllowanceItem = state => getItem(REDUCER_NAME, state);
export const getAllowanceFormValues = state => getFormValues(CLIENT_BONUS_ALLOWANCE_FORM)(state);
export const getClientBonusAllowanceErrors = state => getItemErrors(REDUCER_NAME, state);
export const setClientBonusExtras = data => setItemExtra(REDUCER_NAME, data);
export const getClientBonusExtras = state => getItemExtra(REDUCER_NAME, state);

export const saveBonusAllowance = data => async dispatch => {
  try {
    const params = { clientBonusAllowance: data };
    const isCreate = !data.id;

    if (isCreate) {
      await axios.post(Urls.CLIENT_BONUS_ALLOWANCE_CREATE, params);
    } else {
      const updateUrl = concatParamsToUrl(Urls.CLIENT_BONUS_ALLOWANCE_UPDATE, { id: data.id });
      await axios.put(updateUrl, params);
    }
    redirectTo(Urls.CLIENT_BONUS_ALLOWANCES_LIST);
  } catch (e) {
    dispatch(editItemFailure(REDUCER_NAME, e.response.data.errors.messages));
  }
};

const toDisplayOrderParams = bonusAllowances =>
  bonusAllowances.map((item, index) => ({ id: item.id, displayOrder: index }));

export const saveDisplayOrder = () => async (dispatch, getState) => {
  try {
    const bonusAllowances = getClientAllowancesBonusList(getState());
    const requestParams = toDisplayOrderParams(bonusAllowances);
    await axios.post(Urls.CLIENT_BONUS_ALLOWANCE_UPDATE_DISPLAY_ORDERS, { allowances: requestParams });
    dispatch(setGlobalSuccesses('並べ替えが成功しました'));
    redirectTo(Urls.CLIENT_BONUS_ALLOWANCES_LIST);
  } catch (exception) {
    const errorMsg = get(exception, 'response.data.errors.messages', '並べ替えに失敗しました');
    dispatch(setGlobalErrors(errorMsg));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
