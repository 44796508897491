import axios from 'axios';
import { combineReducers } from 'redux';

import * as Urls from 'src/constants/EndpointUrls';

import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { itemReducer } from 'src/reducers/common';
import { setGlobalSuccesses } from 'src/reducers/global';
import { editItem, editItemFailure } from 'src/reducers/common/itemReducer';

export const REDUCER_NAME = 'clients';

export const updateClientTaxCalMethod = data => async dispatch => {
  try {
    dispatch(editItem(REDUCER_NAME));
    await axios.put(Urls.CLIENT_TAX_CAL_METHOD_SETTING_UPDATE, { client: data });
    dispatch(setGlobalSuccesses('所得税（源泉徴収税額）の計算方法を保存しました。'));
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
