import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';

import { getListTotalCount, getListPageCount } from 'src/reducers/common/listReducer';
import PaginatorWrapper from 'src/components/PaginatorWrapper';

export default connect(
  (state, ownProps) => ({
    count: getListTotalCount(ownProps.reducerName, state),
    pageCount: getListPageCount(ownProps.reducerName, state),
    page: formValueSelector(ownProps.formName)(state, 'page'),
    limit: formValueSelector(ownProps.formName)(state, 'limit')
  }),
  (dispatch, ownProps) => ({
    onClick: page => {
      dispatch(change(ownProps.formName, 'page', page));
    }
  })
)(PaginatorWrapper);
