import React from 'react';
import BonusAllowanceRow from './BonusAllowanceRow';

const BonusAllowancesTable = ({ allowances, handleDeleteRow }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list m-table-fixed">
      <colgroup>
        <col width={200} />
        <col width={500} />
        <col width={100} />
      </colgroup>
      <thead>
        <tr>
          <th>項目名</th>
          <th>金額</th>
          <th width="100" />
        </tr>
      </thead>
      <tbody>
        {(!allowances || allowances.length === 0) && (
          <tr>
            <td colSpan={2}>データがありません。</td>
          </tr>
        )}

        {allowances &&
          allowances.length > 0 &&
          allowances.map(allowance => (
            <BonusAllowanceRow key={allowance.id} allowance={allowance} handleDeleteRow={handleDeleteRow} />
          ))}
      </tbody>
    </table>
  </div>
);

export default BonusAllowancesTable;
