import { connect } from 'react-redux';
import {
  EMPLOYEE_MY_BONUS,
  EMPLOYEE_MY_PAYSLIPS,
  EMPLOYEE_MY_INCOME_TAX_WITHHOLDING,
  DASHBOARD_SHOW
} from 'src/constants/EndpointUrls';
import ElectronicDeliveryAgreement from '../components/electronic_delivery_agreement';

// チェックして保存した後に遷移するパス
const redirectPath = redirectAction => {
  switch (redirectAction) {
    case 'bonus':
    case 'download_bonus':
      return EMPLOYEE_MY_BONUS;
    case 'payslips':
    case 'download_pay':
      return EMPLOYEE_MY_PAYSLIPS;
    case 'withholding_slips':
    case 'withholding_slips_pdf':
      return EMPLOYEE_MY_INCOME_TAX_WITHHOLDING;
    default:
      return DASHBOARD_SHOW;
  }
};

const mapStateToProps = (state, ownProps) => ({
  employee: ownProps.values.employee,
  redirectPath: redirectPath(ownProps.values.redirectAction)
});

export default connect(mapStateToProps, null)(ElectronicDeliveryAgreement);
