import React from 'react';
import { connect } from 'react-redux';

import { Label, RadioField, SelectField } from 'jbc-front/components/Form';
import { getSelectOptions } from 'src/reducers/selectOptions';
import selector from 'src/utils/Utils';
import { getAllowanceFormValues } from 'src/reducers/clients/allowances';
import TargetSettings from './TargetSettings';
import BaseSalaryFormulaSettings from './BaseSalaryFormulaSettings';
import styles from './AllowancePaymentIndividual.scss';

const AllowancePaymentIndividual = props => {
  const {
    selectedFormulaOrderType,
    rounding1YenType,
    roundingDigit,
    payrollRulesGroup,
    formulaOrderType,
    numberOfDaysItems
  } = props;
  // 通勤手当の場合
  const isCommuting = formulaOrderType === 'commuting';
  return (
    <div>
      {isCommuting && (
        <React.Fragment>
          <Label
            text="出勤日数として使用する勤怠項目"
            note={
              <div>通勤手当の支給設定で、「出勤日数に応じて毎月支給」を選択された従業員の支給額計算に使用されます</div>
            }
          />
          <div className={styles.selectNumberOfDays}>
            <SelectField name="clientAllowanceItemIdForCommuting" options={numberOfDaysItems} addBlankOption={false} />
          </div>
        </React.Fragment>
      )}
      <TargetSettings allowanceType="payment" />
      {(payrollRulesGroup.payrollType === 'daily' || payrollRulesGroup.payrollType === 'hourly') &&
        formulaOrderType === 'base_salary' && <BaseSalaryFormulaSettings />}
      {selectedFormulaOrderType === 'base_salary' && (
        <div>
          <Label required text="端数処理" />
          <div className={styles.selectRoundingDigit}>
            <SelectField name="roundingDigit" options={roundingDigit} />
          </div>
          <RadioField name="rounding1YenType" options={rounding1YenType} />
        </div>
      )}
    </div>
  );
};

export default connect(state => ({
  selectedFormulaOrderType: selector(getAllowanceFormValues(state), 'formulaOrderType'),
  rounding1YenType: getSelectOptions(state, 'clientAllowanceRounding'),
  roundingDigit: getSelectOptions(state, 'clientAllowanceRoundingDigit'),
  numberOfDaysItems: getSelectOptions(state, 'unitTypeNumberOfDaysItems'),
  formulaOrderType: selector(getAllowanceFormValues(state), 'formulaOrderType')
}))(AllowancePaymentIndividual);
