import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SEARCH_FLAT_TAX_REDUCTION_AMOUNT_URL, UPDATE_FLAT_TAX_REDUCTION_AMOUNT_URL } from 'src/constants/EndpointUrls';
import classNames from 'classnames';
import _ from 'lodash';
import { reduxForm, getFormValues } from 'redux-form';

import Button from 'jbc-front/components/Button';

import { ErrorsSection } from 'src/components';
import DetailSearchForm from 'src/components/DetailSearchForm';
import LoadingView from 'src/components/LoadingView';
import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import EnrollmentStatusBtnList from 'src/features/Employees/components/EnrollmentStatusBtnList';
import { getIsLoading, getList, getErrors } from 'src/reducers/common/listReducer';
import { AMOUNT_MODE } from 'src/reducers/flat_tax_reductions/csv';
import { REDUCER_NAME, fetchEmployees, getCheckedEmployees } from 'src/reducers/flat_tax_reductions/employees';
import {
  takeEmployeesSearchQueriesForSaveStatuses,
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesResetFormQueries,
  FORM_NAME_FLAT_TAX_REDUCTION_SEARCH
} from 'src/reducers/searchForm';

import styles from './AmountList.scss';
import SaveEmployeesModal from './components/SaveEmployeesModal';
import EmployeeTable from './components/EmployeeTable';
import { DownloadFlatTaxReductionCsvButton } from './components/DownloadFlatTaxReductionCsvButton';
import { UploadFlatTaxReductionCsvButton } from './components/UploadFlatTaxCsvReductionButton';

const EmployeeList = ({ initialValues: formValues, location }) => {
  const errors = useSelector(state => getErrors(REDUCER_NAME, state)) || [{}];
  const employees = useSelector(state => getList(REDUCER_NAME, state));
  const tmpResetValues = useSelector(state => takeEmployeesResetFormQueries(state, true));
  const resetValues = _.merge({}, tmpResetValues, { employees });
  const isListLoading = useSelector(state => getIsLoading(REDUCER_NAME, state));
  const checkedEmployees = useSelector(state => getCheckedEmployees(state)) || [];
  const dispatch = useDispatch();
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);

  const employeeSearchQueries = takeEmployeesSearchQueriesForSaveStatuses(formValues);

  return (
    <div>
      <SearchDetector
        location={location}
        didMountDetectingJudger={() => true}
        willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
        onDetect={() => {
          dispatch(fetchEmployees(employeeSearchQueries, null, SEARCH_FLAT_TAX_REDUCTION_AMOUNT_URL));
        }}
      />
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">既減税額一覧</h1>
      </div>
      <p className={styles.notes}>
        2024年定額減税の月次減税額算出のための既減税額を登録します。
        <br />
        既減税額を登録した場合、他の給与計算ソフトで定額減税の計算を開始している場合等であっても
        <br />
        正しく減税額の計算を行うことが可能になります。
        <br />
        <br />
        以下のすべての要件を満たしている場合は既減税額を登録してください。
        <br />
        ・すでにジョブカン給与計算以外の給与計算ソフトで定額減税の計算を開始している
        <br />
        ・ジョブカン給与計算導入後に過去の給与データを登録しない
        <br />
        <br />
        一覧に表示されるスタッフは、定額減税対象人数一覧で保存されたスタッフです。
      </p>
      <ErrorsSection errors={errors} />
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.buttonArea}>
            <div className={styles.buttonDl}>
              <DownloadFlatTaxReductionCsvButton
                text="既減税額ダウンロード"
                mode={AMOUNT_MODE}
                query={employeeSearchQueries}
              />
              <UploadFlatTaxReductionCsvButton text="既減税額インポート" mode={AMOUNT_MODE} />
            </div>
            <div className={styles.button}>
              <Button
                className={classNames(styles.balloon)}
                primary
                disabled={!checkedEmployees.length}
                disabledReason={
                  <div style={{ textAlign: 'left' }}>
                    従業員が選択されていません。
                    <br />
                    保存する場合、チェックボックスにチェックを入れてください。
                  </div>
                }
                onClick={() => setIsSaveModalOpen(true)}
              >
                保存
              </Button>
            </div>
          </div>
          <DetailSearchForm
            menuRender={() => <EnrollmentStatusBtnList />}
            containsOtherSearch={false}
            formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            containsOfficeSearch
            containSaveStatus
            resetValues={resetValues}
          />
        </div>
        <LoadingView isLoading={isListLoading}>
          <div className={classNames('l-wrap-xl', styles.list)}>
            <EmployeeTable employees={employees} mode={'amount'} />
            <SearchFormPaginator formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH} reducerName={REDUCER_NAME} />
          </div>
        </LoadingView>
      </div>
      <SaveEmployeesModal
        isOpen={isSaveModalOpen}
        hideModal={() => {
          setIsSaveModalOpen(false);
        }}
        title={'既減税額'}
        url={UPDATE_FLAT_TAX_REDUCTION_AMOUNT_URL}
      />
    </div>
  );
};

const EmployeeListForm = reduxForm({
  form: FORM_NAME_FLAT_TAX_REDUCTION_SEARCH,
  enableReinitialize: true
})(EmployeeList);

/* eslint-disable import/prefer-default-export */
export const FlatTaxReductionAmounts = props => {
  const employeesSearchFormValues = useSelector(state => getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(state));
  const location = useSelector(state => state.router.location);
  const employees = useSelector(state => getList(REDUCER_NAME, state));
  const initialValues = {
    ...takeEmployeesSearchQueriesFromLocation(location, true),
    ...employeesSearchFormValues,
    employees
  };

  return <EmployeeListForm {...props} location={location} initialValues={initialValues} />;
};
/* eslint-enable import/prefer-default-export */
