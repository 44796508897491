import { connect } from 'react-redux';
import { rollbackEmployeePay, REDUCER_NAME } from 'src/reducers/employees/employeePays';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import RollbackEmployeePayModal from '../components/RollbackEmployeePayModal';

const mapStateToProps = state => ({
  errors: getItemErrors(REDUCER_NAME, state).rollbackEmployeePay
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: () => {
    ownProps.hideModal();
    dispatch(rollbackEmployeePay(ownProps.employeePayId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(RollbackEmployeePayModal);
