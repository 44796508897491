import React from 'react';
import compose from 'lodash/fp/compose';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { REDUCER_NAME, fetchEmployees } from 'src/reducers/employees/employees';
import { setGlobalErrors } from 'src/reducers/global';

import SearchDetector from 'src/components/SearchDetector';
import DetailSearchForm from 'src/components/DetailSearchForm';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import {
  takeEmployeesSearchQueries,
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesResetFormQueries,
  FORM_NAME_EMPLOYEES_SEARCH
} from 'src/reducers/searchForm';
import { ConfirmBtn } from 'src/buttons';
import { Strage } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import FilterEnrollmentStatusBtnList from 'src/features/Employees/components/EnrollmentStatusBtnList';
import axios from 'axios';
import { JBC_LMS_EMPLOYEES_TARGETS, EMPLOYEES_LIST } from 'src/constants/EndpointUrls';

import EmployeeTable from './components/EmployeeTable';
import ChangedContext from './ChangedContext';

class ChooseCoordinationEmployee extends React.Component {
  change = changes => {
    this.setState(({ changed }) => ({
      changed: {
        ...changed,
        ...changes
      }
    }));
  };

  isSelected = employee => {
    const { changed, changedAll } = this.state;
    if (changed[employee.id] !== undefined) {
      return changed[employee.id];
    }
    if (changedAll !== null) {
      return changedAll;
    }
    return employee.syncsWithLms;
  };

  changeAll = value => {
    this.setState({
      changed: {},
      changedAll: value
    });
  };

  state = {
    changed: {},
    changedAll: null
  };

  saveTargets = searchParams => {
    const { changedAll, changed } = this.state;
    return axios
      .put(JBC_LMS_EMPLOYEES_TARGETS, {
        selected: changed,
        selected_all: changedAll,
        searchParams
      })
      .then(() => {
        window.location = EMPLOYEES_LIST;
      });
  };

  render() {
    const { initialValues: formValues, location, resetValues, dispatch } = this.props;

    return (
      <div>
        <ChangedContext.Provider
          value={{ ...this.state, change: this.change, isSelected: this.isSelected, changeAll: this.changeAll }}
        >
          <SearchDetector
            location={location}
            didMountDetectingJudger={() => true}
            willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
            onDetect={() => {
              dispatch(fetchEmployees(takeEmployeesSearchQueries(formValues)));
            }}
          />
          <div className="l-main-title-wrap">
            <h2 className="m-title-main">従業員の労務連携設定</h2>
          </div>
          <div className="l-contents-wrap">
            <div style={{ textAlign: 'center' }}>
              <h2>ジョブカン労務HRと連携する従業員を設定します。</h2>
              <h2>選択された従業員のみ情報連携が可能になります。</h2>
            </div>
            <div className="l-wrap-xl">
              <DetailSearchForm
                menuRender={() => (
                  <div>
                    <FilterEnrollmentStatusBtnList />
                  </div>
                )}
                containsOtherSearch={false}
                formName={FORM_NAME_EMPLOYEES_SEARCH}
                resetValues={resetValues}
              />
              <div className="u-mb15">
                <ConfirmBtn
                  widthAuto
                  text="対象者を保存"
                  confirmText="チェックされた対象者を保存してもよろしいですか"
                  handleConfirm={() => {
                    this.saveTargets(takeEmployeesSearchQueries(formValues));
                  }}
                  as={ActionButton}
                  icon={<Strage size={16} />}
                >
                  対象者を保存
                </ConfirmBtn>
              </div>
              <EmployeeTable />

              <SearchFormPaginator formName={FORM_NAME_EMPLOYEES_SEARCH} reducerName={REDUCER_NAME} />
            </div>
          </div>
        </ChangedContext.Provider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...takeEmployeesSearchQueriesFromLocation(state.router.location, true),
    ...getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state)
  },
  resetValues: takeEmployeesResetFormQueries(state, true),
  location: state.router.location
});
const mapDispatchToProps = dispatch => ({
  displayErrorInvitation: msgs => dispatch(setGlobalErrors(msgs))
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_EMPLOYEES_SEARCH,
    enableReinitialize: true
  })
)(ChooseCoordinationEmployee);
