import moment from 'moment';

export const dateSlide = (baseDate, days) => (baseDate ? moment(baseDate).add(days, 'days') : null);

export const formatDate = date => (date ? moment(date).format('YYYY/MM/DD') : date);
export const formatDateJa = date => (date ? moment(date).format('YYYY年MM月DD日') : date);
export const formatYearMonth = date => (date ? moment(date).format('YYYY/MM') : date);
export const formatYear = date => (date ? moment(date).format('YYYY') : date);
export const formatMonth = date => (date ? moment(date).format('M') : date);
export const formatDateWithHour = date => (date ? moment(date).format('YYYY/MM/DD HH:mm') : date);
export const formatDateHourJa = date => (date ? moment(date).format('YYYY年MM月DD日 HH:mm') : date);

export default formatDate;
