import { connect } from 'react-redux';
import PdfModalForm from '../../components/Modal/PdfModalForm';

const initialValues = { submitTo: 'pension_office', orderBy: 'insured_personnel_number' };
const mapStateToProps = (state, ownProps) => ({
  initialValues,
  onSubmit: data => {
    ownProps.showPreview(ownProps.bonusId, ownProps.applicableOffice, data.submitTo, data.orderBy);
  }
});

export default connect(mapStateToProps)(PdfModalForm);
