import React, { Component } from 'react';
import { connect } from 'react-redux';

import { EMPLOYEE_BONUS_LIST, UPDATE_BATCH_CSV } from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
// Reducer
import { setEmployeeBonusQueries } from 'src/reducers/employees/employeeBonus';
// Utils
import { formatDate } from 'src/utils/Date';
import { ErrorsSection } from 'src/components';
// Form
import EmployeeBonusDownloadCsvForm from './components/EmployeeBonusDownloadCsvForm';
import DownloadColumnDetail from './components/DownloadColumnDetail';

import styles from './DownloadCsvPage.scss';

class ConnectedDownloadCsv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null
    };
    this.updateState = this.updateState.bind(this);
  }

  updateState(state) {
    this.setState(state);
  }

  render() {
    const { clientBonus } = this.props;

    return (
      <div>
        <div className="l-wrap-xs l-contents-wrap">
          <BreadCrumb>
            <BreadCrumb.Item label="賞与一覧" path={EMPLOYEE_BONUS_LIST} />
            <BreadCrumb.Item label="支給・控除項目の更新" />
          </BreadCrumb>
        </div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">支給・控除項目の更新</h1>
        </div>
        <div className="l-wrap-xs">
          <ErrorsSection errors={this.state.errors} />
          <p>
            以下の月の賞与の支給項目・控除項目の数値をCSVファイルで一括で更新する事ができます。<br />毎月変動する支給項目などをExcelなどで管理している場合に便利です。
          </p>
          <ul className="u-mt10">
            <li>
              ※ 従業員マスタの値は変更されません。従業員マスタごと変更したい場合は
              <a href={UPDATE_BATCH_CSV} className="u-txt-link">
                コチラ
              </a>
            </li>
          </ul>
          <div className={styles.target}>
            賞与名: {clientBonus.name}
            {(clientBonus.applyForTimeFrom || clientBonus.applyForTimeTo) && (
              <div className={styles.period}>
                <span>
                  対象期間: {formatDate(clientBonus.applyForTimeFrom)} ~ {formatDate(clientBonus.applyForTimeTo)}
                </span>
              </div>
            )}
          </div>
        </div>
        <EmployeeBonusDownloadCsvForm updateState={this.updateState} />
        <div className={styles.detail}>
          <DownloadColumnDetail />
        </div>
      </div>
    );
  }
}

const DownloadCsvPage = ({ dispatch, values }) => {
  const clientBonus = values.clientBonus;
  dispatch(setEmployeeBonusQueries(values.queries));
  return <ConnectedDownloadCsv clientBonus={clientBonus} />;
};
export default connect()(DownloadCsvPage);
