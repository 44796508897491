import React from 'react';
import { connect } from 'react-redux';
import { Field, blur, touch } from 'redux-form';
import classNames from 'classnames';

import { REPORT_LABOR_INSURANCE_DECLARATION_FORM } from 'src/constants/FormNames';
import { isNumber } from 'src/utils/CustomValidators';
import { emptyIntegerFormat } from 'src/utils/Completion';
import EditableTextField from './EditableTextField';
import styles from './DeclarationFormWorkerCount.scss';

const DeclarationFormWorkerCount = ({ dispatch, actionMode, laborInsuranceYear, declarationForm, isLessThan2020 }) => (
  <div>
    <p className="u-mb10">
      労働者の数<span className={styles.supplement}>
        （{laborInsuranceYear}年4月〜{laborInsuranceYear + 1}年3月までの平均労働者数）
      </span>
    </p>
    <div className={classNames(isLessThan2020 ? styles.wrapLess2020 : styles.wrap)}>
      <table className={styles.table}>
        <colgroup>
          <col width={260} />
          <col width={260} />
          {isLessThan2020 && <col width={260} />}
        </colgroup>
        <thead>
          <tr>
            <th>常時使用労働者数</th>
            <th>雇用保険被保険者数</th>
            {laborInsuranceYear < 2020 && <th>免除対象高年齢労働者</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <Field
                name="workerCountEmployeeCommon"
                showValue={declarationForm.workerCountEmployeeCommon}
                showMode={actionMode}
                validate={[isNumber]}
                component={EditableTextField}
                onBlur={(e, newValue, previousValue) => {
                  e.preventDefault();
                  dispatch(
                    blur(
                      `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                      `workerCountEmployeeCommon`,
                      emptyIntegerFormat(previousValue)
                    )
                  );
                  dispatch(touch(`${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`, `workerCountEmployeeCommon`));
                }}
              />
            </td>
            <td>
              <Field
                name="workerCountEmploymentInsuranceInsured"
                showValue={declarationForm.workerCountEmploymentInsuranceInsured}
                showMode={actionMode}
                validate={[isNumber]}
                component={EditableTextField}
                onBlur={(e, newValue, previousValue) => {
                  e.preventDefault();
                  dispatch(
                    blur(
                      `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                      `workerCountEmploymentInsuranceInsured`,
                      emptyIntegerFormat(previousValue)
                    )
                  );
                  dispatch(
                    touch(`${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`, `workerCountEmploymentInsuranceInsured`)
                  );
                }}
              />
            </td>
            {laborInsuranceYear < 2020 && (
              <td>
                <Field
                  name="workerCountEmployeeAged"
                  showValue={declarationForm.workerCountEmployeeAged}
                  showMode={actionMode}
                  validate={[isNumber]}
                  component={EditableTextField}
                  onBlur={(e, newValue, previousValue) => {
                    e.preventDefault();
                    dispatch(
                      blur(
                        `${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`,
                        `workerCountEmployeeAged`,
                        emptyIntegerFormat(previousValue)
                      )
                    );
                    dispatch(touch(`${REPORT_LABOR_INSURANCE_DECLARATION_FORM}`, `workerCountEmployeeAged`));
                  }}
                />
              </td>
            )}
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default connect()(DeclarationFormWorkerCount);
