import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import { SortableTh } from 'jbc-front/components/SearchForm';

import { getPageLimit } from 'src/reducers/searchForm';

export default connect(
  (state, ownProps) => ({
    currentSortType: `${formValueSelector(ownProps.formName)(state, 'sort')}__${formValueSelector(ownProps.formName)(
      state,
      'order'
    )}`
  }),
  (dispatch, ownProps) => ({
    onChange: sortType => {
      const [sort, order] = sortType.split('__');
      dispatch(change(ownProps.formName, 'sort', sort));
      dispatch(change(ownProps.formName, 'order', order));
      dispatch(change(ownProps.formName, 'limit', getPageLimit(ownProps.formName)));
    }
  })
)(SortableTh);
