import React from 'react';

import { numberWithComma } from 'src/utils/Utils';
import styles from './PayrollTableData.scss';
import Row from './Row';

const getDeductionItems = payroll => payroll.employeePayAllowances.filter(item => item.allowanceType === 'deduction');

const PayrollDeductionDetail = ({ payroll }) => (
  <div className={styles.paymentClm}>
    {payroll && (
      <table className="m-table-obvious">
        <thead>
          <tr>
            <th colSpan="2">控除</th>
          </tr>
        </thead>
        <tbody>
          <Row label="控除合計" value={numberWithComma(payroll.deductionAmount)} />
          {getDeductionItems(payroll).map(deductionItem => (
            <Row key={deductionItem.id} label={deductionItem.name} value={numberWithComma(deductionItem.value)} />
          ))}
        </tbody>
      </table>
    )}
  </div>
);

export default PayrollDeductionDetail;
