import React from 'react';

import tableStyles from 'src/features/Employees/styles/GeneralTableInfo.scss';
import { Item } from 'src/components/PrimaryPanel';
import { Dl, Dd, Dt } from 'src/components/SecondaryPanel';

const OfficeManageSection = ({ office }) => (
  <div>
    <Item className={tableStyles.mTablePersonal}>
      <Dl>
        <Dt>事業所コード:</Dt>
        <Dd>{office.manageCode}</Dd>
      </Dl>
      <Dl>
        <Dt>事業所管理名:</Dt>
        <Dd>{office.manageName}</Dd>
      </Dl>
    </Item>
  </div>
);

export default OfficeManageSection;
