import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import { Edit, Strage, MinusCircle, Task, Revert } from 'jbc-front/components/icons';

import { BONUS_DETAIL_PDF } from 'src/constants/EndpointUrls';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';

import RollbackEmployeeBonusModalContainer from '../containers/RollbackEmployeeBonusModalContainer';

import { isModeEdit, isModeView } from './EmployeeBonusForm';
import styles from './ActionButtonsSection.scss';

class ActionButtonsSection extends React.Component {
  state = {
    isPdfPreview: false,
    isRollbackModalOpen: false
  };

  showRollbackModal = () => {
    this.setState({ isRollbackModalOpen: true });
  };
  hideRollbackModal = () => {
    this.setState({ isRollbackModalOpen: false });
  };

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };
  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };

  render() {
    const {
      employeeBonusId,
      mode,
      submit,
      reset,
      changeFormMode,
      rollbackInputForm,
      clientBonusConfirmed,
      submitting
    } = this.props;

    return (
      <div className={styles.buttons}>
        <div className={styles.buttonsLeft}>
          {isModeEdit(mode) && (
            <div className={styles.buttonsLeft}>
              <ActionButton onClick={submit} icon={<Strage size={14} />} disabled={submitting}>
                保存
              </ActionButton>
              <ActionButton
                onClick={() => {
                  reset();
                  changeFormMode('view');
                }}
                icon={<MinusCircle size={14} />}
              >
                キャンセル
              </ActionButton>
            </div>
          )}
          {isModeView(mode) && (
            <ActionButton
              onClick={() => changeFormMode('edit')}
              icon={<Edit size={14} />}
              disabled={clientBonusConfirmed}
            >
              編集
            </ActionButton>
          )}
        </div>
        <div className={styles.buttonsRight}>
          {isModeEdit(mode) && (
            <span>
              <ActionButton icon={<Revert size={14} />} onClick={this.showRollbackModal}>
                手入力前に戻す
              </ActionButton>
              <RollbackEmployeeBonusModalContainer
                isOpen={this.state.isRollbackModalOpen}
                hideModal={this.hideRollbackModal}
                onSubmit={() => {
                  this.hideRollbackModal();
                  rollbackInputForm(employeeBonusId);
                }}
              />
            </span>
          )}
          <ActionButton primary icon={<Task size={15} />} onClick={this.showPreview} disabled={!clientBonusConfirmed}>
            PDF出力
          </ActionButton>
          <PreviewPdfModalContainer
            header="賞与明細"
            isOpen={this.state.isPdfPreview}
            hideModal={this.hidePreview}
            url={`${BONUS_DETAIL_PDF}?id=${employeeBonusId}`}
          />
        </div>
      </div>
    );
  }
}

export default ActionButtonsSection;
