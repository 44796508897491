import React from 'react';

import styles from './FlatTaxReductionCsvUpload.scss';

const FlatTaxReductionCsvUploadConfirmDescription = () => (
  <div className="l-wrap-xl">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>スタッフコード{<span className={styles.required}>必須</span>}</th>
            <td>スタッフコードを基にデータが取り込まれるため、正確に入力してください。</td>
            <td>111</td>
          </tr>
          <tr>
            <th>ステータスコード{<span className={styles.required}>必須</span>}</th>
            <td>半角数字で0もしくは1の値 (0:未確定, 1:確定)</td>
            <td>1</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export { FlatTaxReductionCsvUploadConfirmDescription };
// ↓ sprint132 以降に消す
export default FlatTaxReductionCsvUploadConfirmDescription;
