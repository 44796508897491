import React, { Component } from 'react';

import EditableListButtons from 'src/features/Occupations/containers/EditableListButtons';
import EditableListFormed from 'src/features/Occupations/containers/EditableListFormed';

export default class Occupations extends Component {
  render() {
    return (
      <div>
        <EditableListButtons />
        <EditableListFormed type="職種" />
      </div>
    );
  }
}
