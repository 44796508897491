import { connect } from 'react-redux';
import _ from 'lodash';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getCurrentUserInfo } from 'src/reducers/users/currentUser';
import { changeEmployeeChecked } from 'src/reducers/employees/employees';
import EmployeeTableRow from '../components/EmployeeTableRow';

const mapStateToProps = state => ({
  clientEmploymentTypes: getSelectOptions(state, 'employmentTypes'),
  groups: getSelectOptions(state, 'groups'),
  positions: getSelectOptions(state, 'employeePositions'),
  currentUser: getCurrentUserInfo(state),
  isMainOfficeOnly: _.get(state, 'currentClient.item.data.isMainOfficeOnly')
});
const mapDispatchToProps = dispatch => ({
  onChangeEmployeeChecked: employeeId => {
    dispatch(changeEmployeeChecked(employeeId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTableRow);
