import React from 'react';
import compose from 'lodash/fp/compose';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import { REDUCER_NAME, fetchEmployees } from 'src/reducers/employees/employees';
import inviteToDashboard from 'src/reducers/employees/invitations';
import { setGlobalErrors } from 'src/reducers/global';
import Button from 'jbc-front/components/Button';
import Modal from 'jbc-front/components/Modal';

import SearchDetector from 'src/components/SearchDetector';
import DetailSearchForm from 'src/components/DetailSearchForm';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import {
  takeEmployeesSearchQueries,
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesResetFormQueries,
  FORM_NAME_EMPLOYEES_SEARCH
} from 'src/reducers/searchForm';
import FilterEnrollmentStatusBtnList from 'src/features/Employees/components/EnrollmentStatusBtnList';

import InvitationTable from './components/InvitationTable';

const isValid = employee => employee.email && employee.invitationStatus !== 'registered';

class InvitationToDashboardList extends React.Component {
  state = {
    showConfirmModal: false,
    selectedEmployees: [],
    isSelectAll: false
  };

  hideConfirmModal = () => {
    this.setState({ ...this.state, showConfirmModal: false });
  };

  handleInviteClick = () => {
    if (this.state.selectedEmployees.length === 0) {
      this.props.displayErrorInvitation('従業員を選択してください。');
    } else {
      this.setState({ ...this.state, showConfirmModal: true });
    }
  };

  sendInvitation = () => {
    const { dispatch } = this.props;
    dispatch(inviteToDashboard(this.state.selectedEmployees));
    this.hideConfirmModal();
  };

  toggleSelectEmployee = employee => {
    let selectedEmployees = [...this.state.selectedEmployees, employee];

    if (this.state.selectedEmployees.find(item => item.id === employee.id)) {
      selectedEmployees = this.state.selectedEmployees.filter(item => item.id !== employee.id);
    }

    this.setState({ ...this.state, selectedEmployees });
  };

  toggleSelectAllEmployee = (employees = []) => {
    // （メール設定かつnot registered）従業員だけ選択します。
    let selectedEmployees = employees.filter(employee => isValid(employee));
    if (this.state.isSelectAll) {
      selectedEmployees = [];
    }

    this.setState({ ...this.state, selectedEmployees, isSelectAll: !this.state.isSelectAll });
  };

  render() {
    const { initialValues: formValues, location, resetValues, dispatch } = this.props;

    return (
      <div>
        <SearchDetector
          location={location}
          didMountDetectingJudger={() => true}
          willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
          onDetect={() => {
            dispatch(fetchEmployees(takeEmployeesSearchQueries(formValues)));
          }}
        />
        <div className="l-main-title-wrap">
          <h2 className="m-title-main">従業員招待</h2>
        </div>
        <div className="l-contents-wrap">
          <div className="l-wrap-xl">
            <DetailSearchForm
              menuRender={() => <FilterEnrollmentStatusBtnList />}
              containsOtherSearch={false}
              formName={FORM_NAME_EMPLOYEES_SEARCH}
              resetValues={resetValues}
            />
            <InvitationTable
              handleSelectClick={this.toggleSelectEmployee}
              handleSelectAllClick={this.toggleSelectAllEmployee}
              {...this.state}
            />
            <div className="u-mt20 u-ta-c">
              <Button primary onClick={this.handleInviteClick}>
                招待メールを送信
              </Button>
            </div>
            <Modal
              header="本人に招待メールを送る"
              body={<p>選択された従業員宛に、招待メールを送信しますか？</p>}
              cancel="キャンセル"
              submit="送信"
              onSubmit={this.sendInvitation}
              isOpen={this.state.showConfirmModal}
              hideModal={this.hideConfirmModal}
            />

            <SearchFormPaginator formName={FORM_NAME_EMPLOYEES_SEARCH} reducerName={REDUCER_NAME} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...takeEmployeesSearchQueriesFromLocation(state.router.location, true),
    ...getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state)
  },
  resetValues: takeEmployeesResetFormQueries(state, true),
  location: state.router.location
});
const mapDispatchToProps = dispatch => ({
  displayErrorInvitation: msgs => dispatch(setGlobalErrors(msgs))
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_EMPLOYEES_SEARCH,
    enableReinitialize: true
  })
)(InvitationToDashboardList);
