import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import compose from 'lodash/fp/compose';

import {
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesResetFormQueries,
  FORM_NAME_EMPLOYEES_SEARCH
} from 'src/reducers/searchForm';
import { saveEmployeeAttendanceSyncSetting } from 'src/reducers/settings/coordinations';
import SettingEmployeeSync from './components/SettingEmployeeSync';

const mapStateToProps = state => ({
  initialValues: {
    ...takeEmployeesSearchQueriesFromLocation(state.router.location, true),
    ...getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state)
  },
  resetValues: takeEmployeesResetFormQueries(state, true),
  location: state.router.location,
  title: '従業員の勤怠連携設定'
});

const mapDispatchToProps = dispatch => ({
  saveEmployeeSyncSetting: () => dispatch(saveEmployeeAttendanceSyncSetting())
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_EMPLOYEES_SEARCH,
    enableReinitialize: true
  })
)(SettingEmployeeSync);
