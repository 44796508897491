import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import styles from './Balloon.scss';

const Balloon = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      const { children, right } = this.props;
      const baloonPosition = right ? styles.baloonRightWrap : styles.baloonWrap;
      const baloonArrowPosition = right ? styles.baloonRight : styles.baloon;

      return (
        <div className={baloonPosition}>
          <div className={baloonArrowPosition}>{children}</div>
        </div>
      );
    }
  }
);

export default Balloon;
