import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { fetchZipPdf, fetchPdf, setPdfJob } from 'src/reducers/reports/notificationOfBaseAmountRevisionAsync';
import { PAY_REPORTS_DOWNLOAD_FORM } from 'src/constants/FormNames';
import PdfCreateModal from '../components/PdfDownloadModal';

const PdfCreateForm = reduxForm({
  form: PAY_REPORTS_DOWNLOAD_FORM
})(PdfCreateModal);

const mapDispatchToProps = (dispatch, ownProps) => ({
  downloadPdf: () => {
    dispatch(fetchPdf(ownProps.revisionYear, ownProps.applicableOffice));
  },
  downloadZip: () => {
    dispatch(fetchZipPdf(ownProps.revisionYear, ownProps.applicableOffice));
  },
  setPdfJob: () => {
    dispatch(setPdfJob(ownProps.revisionYear, ownProps.applicableOffice, ownProps.hideModal, true));
  }
});
export default connect(null, mapDispatchToProps)(PdfCreateForm);
