import React from 'react';
import ListGroup from 'jbc-front/components/ListGroup';
import { Adjustment } from 'jbc-front/components/icons';
import { YEAR_END_ADJ_START } from 'src/constants/EndpointUrls';
import { isFreePlan } from 'src/reducers/clients/currentClient';

import OfferUpdateToPaidPlan from './OfferUpdateToPaidPlan';
import MemoListToday from './MemoListToday';
import TodoList from './TodoList';
import SystemAnnounces from './SystemAnnounces';
import Setup from './Setup';
import styles from './AdminDashboard.scss';
import EmployeeAnnounces from './EmployeeAnnounces';

const AdminDashboard = props => {
  const { systemAnnounces, notCompletedSetting, todayMemos, planType, employeeAnnounces } = props;
  return (
    <div className="l-flex">
      <Setup notCompletedSetting={notCompletedSetting} />
      <div className="l-flex1-sub u-pc-pt50 u-sp-pt30">
        {false &&
          !isFreePlan(planType) && (
            <div className={styles.group}>
              <ListGroup>
                <ListGroup.Item as="a" href={YEAR_END_ADJ_START} target="_blank">
                  <div className={styles.icon}>
                    <Adjustment />
                  </div>
                  <div>
                    <p className={styles.name}>令和5年年末調整</p>
                    <p className={styles.text}>年末調整を行います</p>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </div>
          )}
        <TodoList />
      </div>
      <div className="l-flex1-main u-pc-pt50 u-sp-pt20">
        <OfferUpdateToPaidPlan />
        <MemoListToday todayMemos={todayMemos} />
        <SystemAnnounces systemAnnounces={systemAnnounces} />
        <EmployeeAnnounces employeeAnnounces={employeeAnnounces} />
      </div>
    </div>
  );
};

export default AdminDashboard;
