import { combineReducers } from 'redux';
import { formValueSelector, autofill, getFormValues } from 'redux-form';
import { push } from 'connected-react-router';
import axios from 'axios';
import humps from 'humps';
import _ from 'lodash';
import queryString from 'query-string';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';

import * as Urls from 'src/constants/EndpointUrls';
import {
  REPORT_LABOR_INSURANCE_SUMMARY_FORM,
  REPORT_LABOR_INSURANCE_DECLARATION_FORM,
  REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM,
  REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_FORM,
  REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_CSV_FORM
} from 'src/constants/FormNames';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import { concatParamsToUrl, excludeValuesIfNotDefined, serializeHttpGetParams, redirectTo } from 'src/utils/Http';
import { paramsWithFile } from 'src/utils/Form';
import { scrollToTop } from 'src/utils/Utils';
import { saveCurrentQueries } from 'src/QueriesStorageApi';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { fetchParentJobStatus, existIncompleteJob } from 'src/reducers/JobStatus';
import { listReducer, itemReducer } from 'src/reducers/common';
import {
  newItemFailure,
  fetchItem,
  fetchItemSuccess,
  fetchItemFailure,
  setItem,
  setItemExtra,
  getItem,
  getItemErrors
} from 'src/reducers/common/itemReducer';
import {
  getListQueries,
  fetchList,
  fetchListSuccess,
  fetchListFailure,
  getExtras,
  setExtras,
  addQuery
} from 'src/reducers/common/listReducer';
import { getSelectOptions, setOptions } from '../selectOptions';
import { setGlobalErrors } from '../global';

export const REDUCER_NAME = 'reportLaborInsurances';
// チェックボックスにチェックを入れた時のtrue、falseの値が入れ替わる年度
export const REVERSE_COUNT_PAY_AMOUNT_FLG_YEAR = 2023;
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));

export const setSummaryTableShowMode = actionMode => async dispatch => {
  dispatch(setExtras(REDUCER_NAME, { summaryTableShowMode: actionMode }));
};

export const setDeclarationFormShowMode = actionMode => async dispatch => {
  dispatch(setItemExtra(REDUCER_NAME, { declarationFormActionMode: actionMode }));
};
export const clearMonthlySummaryErrors = () => async dispatch => {
  dispatch(setExtras(REDUCER_NAME, { editMonthlySummaryErrors: [] }));
};
export const clearDeclarationFormErrors = () => async dispatch => {
  dispatch(setItemExtra(REDUCER_NAME, { editDeclarationFormErrors: [] }));
};
export const setAllFormShowMode = () => async dispatch => {
  dispatch(setSummaryTableShowMode('SHOW'));
  dispatch(setDeclarationFormShowMode('SHOW'));
};
export const clearAllFormErrors = () => async dispatch => {
  dispatch(clearMonthlySummaryErrors());
  dispatch(clearDeclarationFormErrors());
};
export const transformMonthlySummariesToFormValues = state => {
  const values =
    state[REDUCER_NAME].list.data.monthlySummaries &&
    state[REDUCER_NAME].list.data.monthlySummaries.reduce(
      (pre, monthlySummary) => ({ monthlySummaries: { ...pre.monthlySummaries, [monthlySummary.id]: monthlySummary } }),
      { monthlySummaries: {} }
    );
  if (values && state[REDUCER_NAME].item.data) {
    values.laborInsuranceDeclarationForm = state[REDUCER_NAME].item.data;
  }
  return values;
};

// Async Action Creators
export const fetchReportLaborInsurances = () => async (dispatch, getState) => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    let queries = getListQueries(REDUCER_NAME, getState());
    queries = excludeValuesIfNotDefined(queries);
    saveCurrentQueries(queries, REDUCER_NAME);

    const response = await axios.get(Urls.REPORT_LABOR_INSURANCE_SUMMARIES_SEARCH, {
      params: humps.decamelizeKeys(queries)
    });
    const payload = {
      data: {
        monthlySummaries: response.data.payload.monthlySummaries,
        summaryToSnaps: response.data.payload.summaryToSnaps,
        yearlySummary: response.data.payload.yearlySummary
      }
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setAllFormShowMode());
    dispatch(clearAllFormErrors());
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editMonthlySummary = (laborInsuranceYear, applicableOffice) => async (dispatch, getState) => {
  try {
    const state = getState();
    const monthlySummaries = _.get(getFormValues(REPORT_LABOR_INSURANCE_SUMMARY_FORM)(state), 'monthlySummaries');
    const laborInsuranceDeclarationForm = _.get(
      getFormValues(REPORT_LABOR_INSURANCE_SUMMARY_FORM)(state),
      'laborInsuranceDeclarationForm'
    );

    const httpBody = {
      laborInsuranceYear,
      applicableOffice,
      monthlySummaries,
      laborInsuranceDeclarationForm
    };
    await axios.put(Urls.REPORT_LABOR_INSURANCE_SUMMARIES_UPDATE_SUMMARIES, httpBody);
    window.location.reload();
  } catch (exception) {
    dispatch(setExtras(REDUCER_NAME, { editMonthlySummaryErrors: exception.response.data.errors.messages }));
  }
};

// Async Action Creators
export const fetchReportLaborInsuranceDeclarationForm = () => async (dispatch, getState) => {
  try {
    dispatch(fetchItem(REDUCER_NAME));
    let queries = getListQueries(REDUCER_NAME, getState());
    queries = excludeValuesIfNotDefined(queries);
    saveCurrentQueries(queries, REDUCER_NAME);

    const response = await axios.get(Urls.REPORT_LABOR_INSURANCE_DECLARATIONS_SEARCH, {
      params: humps.decamelizeKeys(queries)
    });
    dispatch(fetchItemSuccess(REDUCER_NAME, response.data.payload.declarationForm));
  } catch (exception) {
    dispatch(fetchItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editDeclarationForm = (laborInsuranceYear, applicableOffice) => async (dispatch, getState) => {
  try {
    const state = getState();
    const declarationForm = getFormValues(REPORT_LABOR_INSURANCE_DECLARATION_FORM)(state);

    const httpBody = {
      laborInsuranceYear,
      applicableOffice,
      declarationForm
    };
    await axios.put(Urls.REPORT_LABOR_INSURANCE_DECLARATIONS_UPDATE_DECLARATIONS, httpBody);

    window.location.reload();
  } catch (exception) {
    dispatch(setItemExtra(REDUCER_NAME, { editDeclarationFormErrors: exception.response.data.errors.messages }));
  }
};

export const updateLaborInsuranceRates = ownProps => async (dispatch, getState) => {
  try {
    const state = getState();
    const insuranceRates = getFormValues(REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM)(state);

    const params = {
      labor_insurance_year: ownProps.laborInsuranceYear,
      applicable_office: ownProps.applicableOffice,
      declaration_insurance_rate: humps.decamelizeKeys(insuranceRates)
    };
    const updateUrl = concatParamsToUrl(Urls.REPORT_LABOR_INSURANCE_DECLARATIONS_UPDATE_INSURANCE_RATES, { params });
    await axios.put(updateUrl, params);

    const newInsuranceRate = {
      confirmedInsuranceFeeRateForAccidentInsurance: insuranceRates.confirmedAccidentInsuranceRate,
      confirmedInsuranceFeeRateForCalculationTargetOfEmploymentInsurance:
        insuranceRates.confirmedEmploymentInsuranceRate,
      confirmedInsuranceFeeRateForAgedEmploymentInsurance: insuranceRates.confirmedEmploymentInsuranceRate,
      confirmedInsuranceFeeRateForLaborInsurance:
        _.toInteger(insuranceRates.confirmedAccidentInsuranceRate) +
        _.toInteger(insuranceRates.confirmedEmploymentInsuranceRate),
      estimateInsuranceFeeRateForAccidentInsurance: insuranceRates.estimateAccidentInsuranceRate,
      estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance: insuranceRates.estimateEmploymentInsuranceRate,
      estimateInsuranceFeeRateForLaborInsurance:
        _.toInteger(insuranceRates.estimateAccidentInsuranceRate) +
        _.toInteger(insuranceRates.estimateEmploymentInsuranceRate)
    };

    const newParams = { ...getItem(REDUCER_NAME, state), ...newInsuranceRate };
    dispatch(setItem(REDUCER_NAME, newParams));
    ownProps.allCalculations(newParams);
    ownProps.cancelBtnClick();
  } catch (exception) {
    dispatch(setItemExtra(REDUCER_NAME, { updateLaborInsuranceRateErrors: exception.response.data.errors.messages }));
  }
};

export const clearLaborInsuranceRateErrors = () => async dispatch => {
  dispatch(setItemExtra(REDUCER_NAME, { updateLaborInsuranceRateErrors: [] }));
};

export const getLaborInsuranceRateErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return (errors[0] || {}).updateLaborInsuranceRateErrors || [];
};

export const reaggregateSummary = (laborInsuranceYear, formData) => async dispatch => {
  try {
    let shouldIncludeNoPayEmployee;
    if (laborInsuranceYear >= REVERSE_COUNT_PAY_AMOUNT_FLG_YEAR) {
      shouldIncludeNoPayEmployee = !formData.shouldExcludeNoPayEmployee;
    } else {
      shouldIncludeNoPayEmployee = !!formData.shouldIncludeNoPayEmployee;
    }

    const params = {
      labor_insurance_year: laborInsuranceYear,
      shouldIncludeNoPayEmployee
    };
    const reaggregateUrl = concatParamsToUrl(Urls.REPORT_LABOR_INSURANCE_SUMMARIES_REAGGREGATE, { params });
    await axios.put(reaggregateUrl, params);
    window.location.reload();
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
    await new Promise(r => setTimeout(r, 1000));
    window.location.reload();
  }
};

export const recalculateReportLaborInsuranceDeclarationForm = (
  laborInsuranceYear,
  applicableOffice
) => async dispatch => {
  try {
    const params = { labor_insurance_year: laborInsuranceYear, applicable_office: applicableOffice };
    const recalculateUrl = concatParamsToUrl(Urls.REPORT_LABOR_INSURANCE_DECLARATIONS_RECALCULATE, { params });
    await axios.put(recalculateUrl, params);
    window.location.reload();
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
    await new Promise(r => setTimeout(r, 500));
  }
};

export const recalculateReportLaborInsuranceSummaryList = (laborInsuranceYear, applicableOffice) => async dispatch => {
  try {
    const params = { labor_insurance_year: laborInsuranceYear, applicable_office: applicableOffice };
    const recalculateUrl = concatParamsToUrl(Urls.REPORT_LABOR_INSURANCE_SUMMARIES_RECALCULATE, { params });
    await axios.put(recalculateUrl, params);
    window.location.reload();
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
    await new Promise(r => setTimeout(r, 500));
    window.location.reload();
  }
};

export const getRecalculateReportLaborInsuranceDeclarationFormErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return (errors[0] || {}).recalculateReportLaborInsuranceDeclarationFormErrors || [];
};

export const getRecalculateReportLaborInsuranceSummaryListErrors = state => {
  const errors = getItemErrors(REDUCER_NAME, state) || [{}];
  return (errors[0] || {}).recalculateReportLaborInsuranceSummaryListErrors || [];
};

export const changeUrl = summaryType => async (dispatch, getState) => {
  const state = getState();
  const url = summaryType === '2' ? Urls.REPORT_LABOR_INSURANCE_DECLARATIONS : Urls.REPORT_LABOR_INSURANCE_SUMMARIES;
  const laborInsuranceYear = getListQueries(REDUCER_NAME, getState()).laborInsuranceYear;
  const applicableOffice = getListQueries(REDUCER_NAME, getState()).applicableOffice;
  let params = { labor_insurance_year: laborInsuranceYear };
  if (applicableOffice !== '') {
    params = { ...params, applicable_office: applicableOffice };
  }
  const search = queryString.stringify(params);
  const location = { ...state.router.location, pathname: url };
  dispatch(push({ ...location, search }));
};

export const fetchApplicableOfficesOfLaborInsuranceYear = () => async (dispatch, getState) => {
  const year = getListQueries(REDUCER_NAME, getState()).laborInsuranceYear;
  const response = await axios.get(Urls.OPTIONS_APPLICABLE_OFFICES_OF_LABOR_INSURANCE, {
    params: { ...{ year } }
  });
  dispatch(setOptions({ applicableOffices: response.data.payload.options }));
  const officeValue = response.data.payload.options.length > 0 ? response.data.payload.options[0].value : '';
  dispatch(addQuery(REDUCER_NAME, 'applicableOffice', officeValue));
};

export const fetchReportLaborInsuranceData = (query, summaryType) => async dispatch => {
  await dispatch(addQuery(REDUCER_NAME, 'laborInsuranceYear', query));
  await dispatch(fetchApplicableOfficesOfLaborInsuranceYear());
  dispatch(changeUrl(summaryType));
  dispatch(fetchReportLaborInsurances());
  dispatch(fetchReportLaborInsuranceDeclarationForm());
};

export const setCachedOffice = cachedOffice => async (dispatch, getState) => {
  const applicableOffices = getSelectOptions(getState(), 'applicableOffices').map(office => office.value);
  const office = getListQueries(REDUCER_NAME, getState()).applicableOffice;
  if (office === '' || !applicableOffices.includes(cachedOffice)) return;

  dispatch(addQuery(REDUCER_NAME, 'applicableOffice', cachedOffice));
};

export const fetchInit = queries => async dispatch => {
  await dispatch(fetchApplicableOfficesOfLaborInsuranceYear());
  await dispatch(setCachedOffice(queries.applicableOffice));
  dispatch(fetchReportLaborInsurances());
  dispatch(fetchReportLaborInsuranceDeclarationForm());
};

export const updateEmployeeDetails = (year, employeeId) => async (dispatch, getState) => {
  try {
    const employeeDetails = formValueSelector(REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_FORM)(getState(), 'fields');
    const url = concatParamsToUrl(Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_EDIT, { year, employee_id: employeeId });
    await axios.put(url, { employeeDetails });
    window.location.reload();
  } catch (exception) {
    dispatch(setExtras(REDUCER_NAME, { editEmployeeDetailErrors: exception.response.data.errors.messages }));
  }
};

export const rollbackEmployeeDetails = (year, employeeId) => async dispatch => {
  try {
    const url = concatParamsToUrl(Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_ROLLBACK, {
      year,
      employee_id: employeeId
    });
    await axios.put(url);
    window.location.reload();
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
    await new Promise(r => setTimeout(r, 500));
    window.location.reload();
  }
};

// 月別内訳データの更新画面: CSVファイルをダウンロード
export const downloadTemplateCsv = () => async (dispatch, getState) => {
  try {
    const encoding = formValueSelector(REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_CSV_FORM)(getState(), 'encoding');
    const csvTarget = formValueSelector(REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_CSV_FORM)(getState(), 'csvTarget');
    const queries = queryString.parse(getState().router.location.search);
    let params = {};
    if (csvTarget === 'searched_employee') {
      params = { encoding, ...queries };
    } else {
      params = { encoding, laborInsuranceYear: queries.labor_insurance_year };
    }
    dispatch(clearErrors());
    const response = await fetch(
      `${Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_CSV}?${serializeHttpGetParams(params)}`
    );
    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      if (!response.ok) {
        dispatch(setGlobalErrors(json.errors.messages));
      }
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (exception) {
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

export const getImportCsvJobs = state => getExtras(REDUCER_NAME, state).importCsvJobs;

export const fetchLaborInsuranceImportCsvJobs = (interval = INITIAL_POLLING_INTERVAL) => async (dispatch, getState) => {
  try {
    await dispatch(fetchParentJobStatus({ job_name: 'import_labor_insurance_employee_details_csv_job' }));
    const jobs = getState().JobStatus.import_labor_insurance_employee_details_csv_job;
    if (existIncompleteJob(jobs)) {
      const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
      setTimeout(() => {
        dispatch(fetchLaborInsuranceImportCsvJobs(nextInterval));
      }, nextInterval);
    }
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const setInvisibleImportCsvJob = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'importLaborInsuranceEmployeeDetailsCsvJob'
    });
    dispatch(fetchLaborInsuranceImportCsvJobs());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

// 月別内訳データの更新画面: CSVファイルをインポートする
export const importLaborInsuranceCsv = data => async (dispatch, getState) => {
  try {
    const params = paramsWithFile(data, ['labor_insurance_csv']);
    const laborInsuranceYear = getState().router.location.query.labor_insurance_year;
    params.append('labor_insurance_year', laborInsuranceYear);
    await axios.post(Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_IMPORT_LABOR_INSURANCE_CSV, params);
    dispatch(fetchLaborInsuranceImportCsvJobs());
    dispatch(clearErrors());
  } catch (exception) {
    dispatch(setErrors(exception.response.data.errors.messages));
  }
  scrollToTop();
};

// 月別内訳データの更新画面: CSVファイルをダウンロード
export const downloadImportErrorOfLaborInsuranceCsv = job => async dispatch => {
  try {
    const params = { job_id: job.jobId };
    dispatch(clearErrors());

    redirectTo(
      `${Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_IMPORT_ERROR_OF_LABOR_INSURANCE_CSV}?${serializeHttpGetParams(
        params
      )}`
    );
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});

export const isLessThan2020Year = year => year < 2020;
export const is2021Year = year => year === 2021;
export const is2022Year = year => year === 2022;
export const updateLaborInsuranceSummaryForm = (field, value) =>
  autofill(REPORT_LABOR_INSURANCE_SUMMARY_FORM, field, value);

export const extractFirstTermMonthlySummaries = (monthlySummaries, year) =>
  monthlySummaries &&
  monthlySummaries.filter(summary => new Date(summary.monthlySummaryDate) <= new Date(year, 9 - 1, 31));

export const extractLatterTermMonthlySummaries = (monthlySummaries, year) =>
  monthlySummaries &&
  monthlySummaries.filter(summary => new Date(summary.monthlySummaryDate) > new Date(year, 9 - 1, 31));

const extractDecimal = num => {
  const decimalPart = (num % 1).toFixed(2);
  return parseFloat(decimalPart);
};

// 2022年度労災保険分の④確定保険料（その２）の端数を切り上げるかどうかの判定
//  ①保険料算定基礎額の労災保険分（前期）と雇用保険分（前期）、労災保険分（後期）と雇用保険分（後期）の額がそれぞれ同額であり、
//  かつ③確定保険料額（その１）の労災保険分と雇用保険分の各々の小数点以下を足した結果、1円以上になる場合に端数を切り上げる
export const shouldCeilDecimalPoint = param => {
  if (
    Number(param.confirmedInsuranceFeeBaseForAccidentInsuranceFirstTerm) !==
    Number(param.confirmedInsuranceFeeBaseForEmpInsuranceFirstTerm)
  ) {
    return false;
  }
  if (
    Number(param.confirmedInsuranceFeeBaseForAccidentInsuranceLatterTerm) !==
    Number(param.confirmedInsuranceFeeBaseForEmpInsuranceLatterTerm)
  ) {
    return false;
  }
  const sumAccidentInsuranceDecimal = extractDecimal(param.sumConfirmedAccidentInsurance);
  const sumEmploymentInsuranceDecimal = extractDecimal(param.sumConfirmedEmploymentInsurance);

  if (sumAccidentInsuranceDecimal + sumEmploymentInsuranceDecimal < 1) return false;

  return true;
};
export const roundSumConfirmedAccidentInsurance = param =>
  shouldCeilDecimalPoint(param)
    ? Math.ceil(param.sumConfirmedAccidentInsurance)
    : _.toInteger(param.sumConfirmedAccidentInsurance);
