import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Button from 'jbc-front/components/Button';
import compose from 'lodash/fp/compose';
import CommonModal from 'jbc-front/components/CommonModal';
import { OFFICE_FORM } from 'src/constants/FormNames';
import { deleteOffice } from 'src/reducers/offices/offices';

const DeleteOfficeConfirmModal = ({ hideModal, isOpen, office, dispatch }) => {
  const onSubmit = () => {
    dispatch(deleteOffice(office.id));
    hideModal();
  };

  return (
    <React.Fragment>
      <CommonModal hideModal={hideModal} isOpen={isOpen} ariaHideApp>
        <CommonModal.Header hideModal={hideModal}>削除の確認</CommonModal.Header>
        <CommonModal.Body>
          <div>適用事業所を削除しますか？</div>
        </CommonModal.Body>
        <CommonModal.Footer>
          <Button onClick={hideModal} className="u-mr20">
            いいえ
          </Button>
          <Button primary onClick={onSubmit} className="u-mr20">
            はい
          </Button>
        </CommonModal.Footer>
      </CommonModal>
    </React.Fragment>
  );
};

export default compose(connect(), reduxForm({ form: OFFICE_FORM }))(DeleteOfficeConfirmModal);
