import React from 'react';
import { reduxForm } from 'redux-form';
import classNames from 'classnames';
import { ENCODING_OPTIONS, REVISION_FILTER_TYPES_IMPORT } from 'src/constants/Options';
import { IMPORT_MONTHLY_REVISIONS_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { Download, Upload } from 'jbc-front/components/icons';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import Button from 'jbc-front/components/Button';
import { MONTHLY_REVISION_EXPORT, MONTHLY_REVISION_LIST } from 'src/constants/EndpointUrls';
import { RadioField, FileField } from 'jbc-front/components/Form';

import styles from './ImportMonthlyRevisions.scss';
import FileImportDescription from './components/FileImportDescription';
import ImportCsvJobStatus from './containers/ImportCsvJobStatusContainer';

const disabledReason = '処理中です。完了までしばらくお待ちください。';
const ImportMonthlyRevisions = ({
  submitting,
  handleSubmit,
  selectedEncoding,
  selectedTarget,
  errors,
  revisionYearMonth,
  applicableOffice,
  dispRevisionYearMonth,
  dispPeriod,
  importing
}) => (
  <div>
    <div className="l-wrap-xs l-contents-wrap">
      <BreadCrumb>
        <BreadCrumb.Item label="月額変更届" path={MONTHLY_REVISION_LIST} />
        <BreadCrumb.Item label="月額変更届の更新" />
      </BreadCrumb>
    </div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">月額変更届の更新</h1>
    </div>
    <ImportCsvJobStatus />
    <div className={classNames('l-wrap-xs', styles.description)}>
      <ErrorsSection errors={errors} />
      <p>
        給与の月額変更届けの数値をCSVファイルで一括で更新することができます。<br />
        月割額の報酬月額への上乗せが必要な場合等にご活用ください。
      </p>
      <br />
      <div className={styles.period}>
        <b>改定年月：{dispRevisionYearMonth}</b>
        <br />
        <b>対象期間：{dispPeriod}</b>
      </div>
    </div>
    <div className={styles.wrap}>
      <div className={styles.head}>アップロード手順</div>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <dl className={styles.label}>
            <dt className={styles.number}>1</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>月額変更届データCSVファイルをダウンロードしてください。</p>
              <b>ファイル形式を選択</b>
              <div className="u-mt30">
                <DownloadCsvEncodingRadioButton
                  name={'encoding'}
                  label={null}
                  options={ENCODING_OPTIONS}
                  required={false}
                />
              </div>
              <b>ダウンロード対象選択</b>
              <div className="u-mt30">
                <RadioField name={'target'} label={null} options={REVISION_FILTER_TYPES_IMPORT} required={false} />
              </div>
              <Button
                widthWide
                icon={<Download size={16} />}
                onClick={() =>
                  redirectTo(
                    `${MONTHLY_REVISION_EXPORT}?${serializeHttpGetParams({
                      revisionYearMonth,
                      applicableOffice,
                      encoding: selectedEncoding,
                      target: selectedTarget
                    })}`
                  )
                }
              >
                月額変更届データCSVをダウンロード
              </Button>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>2</dt>
            <dd className={styles.detail}>
              下の説明を参考にしながら、ダウンロードしたCSVファイルに、変更したい月額変更届の値を入れてください。
              <div className={styles.warning}>
                <ul className={styles.warningList}>
                  <li>
                    <span className={styles.requiredText}>
                      <b>
                        各項目の更新結果により随時改定の対象、対象外の判定が変化します。
                        <br />
                        ※取り込み後に給与計算結果で自動判定された状態に戻したい場合は、各従業員の月額変更届編集画面から「手入力前に戻す」を押してください。
                        <br />
                        <br />
                        また、データアップロードが不要な従業員がいる場合は、該当するデータごと削除してからアップロードすることも可能です。
                      </b>
                    </span>
                  </li>
                </ul>
              </div>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>3</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
              <FileField
                label="月額変更届項目のデータファイル"
                name="monthly_revisions_csv"
                className={styles.input}
                required
              />
            </dd>
          </dl>
          <Button
            as="button"
            primary
            type="submit"
            disabled={submitting || importing}
            disabledReason={disabledReason}
            icon={<Upload size={16} />}
            widthWide
          >
            アップロード
          </Button>
        </form>
      </div>
    </div>
    <div className={styles.description}>
      <FileImportDescription />
    </div>
  </div>
);

const ImportMonthlyRevisionsForm = reduxForm({
  form: IMPORT_MONTHLY_REVISIONS_FORM,
  enableReinitialize: true
})(ImportMonthlyRevisions);

export default ImportMonthlyRevisionsForm;
