import React, { Component } from 'react';
import { Section } from 'jbc-front/components/Form';
import { Request, Tel } from 'jbc-front/components/icons';
import styles from './PaidPlanCompleted.scss';

class PaidPlanCompleted extends Component {
  render() {
    const { email } = this.props;
    return (
      <div>
        <div className="l-wrap-xs l-contents-wrap">
          <div className={styles.main}>
            <p className="m-title-sub u-pt0 u-ta-c">お申し込みいただきありがとうございます。</p>
            <p className="u-mb20">登録したメールアドレス（{email}）に認証のURLを送信しましたのでご確認下さい。</p>
            <p>
              しばらく経ってもメールが届かない場合は、迷惑メールフォルダ等を確認するか、弊社サポート窓口までお問い合わせ願います。
            </p>
          </div>
          <Section title="利用プラン" className="u-mt40">
            <div className={styles.plan}>有料プラン</div>
            <div className={styles.payments}>
              <p className={styles.paymentsTitle}>支払い方法</p>
              <div className={styles.paymentsMethod}>請求書払い</div>
            </div>
          </Section>
          <div className={styles.billings}>
            <p className={styles.billingsTitle}>請求情報</p>
            <div className="l-overflow-scroll">
              <table className="m-table-list">
                <thead>
                  <tr>
                    <th>年/月</th>
                    <th>単価</th>
                    <th>ユーザー数</th>
                    <th>合計額（税抜き）</th>
                    <th>合計額（税込み）</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colSpan="5">請求情報の履歴はありません</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className={styles.support}>
            <div className={styles.supportTitle}>ジョブカン給与計算 サポート窓口</div>
            <ul className={styles.contacts}>
              <li>
                <Request size={16} />
                <a href="mailto:info@lms.jobcan.ne.jp">info@payroll.jobcan.ne.jp</a>
              </li>
              <li>
                <Tel size={14} />
                <span>050-3201-4837</span>
              </li>
            </ul>
          </div>
        </div>

        {/* <script
          type="text/j className={styles.payments}avascript"
          src="//www.googleadservices.com/pagead/conversion.js"
        />
        <script type="text/javascript" src="https://s.yimg.jp/images/listing/tool/cv/conversion.js" />
        <script type="text/javascript" charSet="UTF-8" src="https://b90.yahoo.co.jp/conv.js" /> */}
      </div>
    );
  }
}

export default PaidPlanCompleted;
