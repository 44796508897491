import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import PayrollAllowanceDetail from './PayrollAllowanceDetail';
import PayrollDeductionDetail from './PayrollDeductionDetail';
import PayrollSummaryDetail from './PayrollSummaryDetail';
import PayrollAttendanceDetail from './PayrollAttendanceDetail';
import PayrollActionSection from './PayrollActionSection';
import styles from './PayrollTableData.scss';

class PayrollDetailShow extends React.Component {
  constructor(props) {
    super(props);

    this.changeSelectingEmployeePayroll = this.changeSelectingEmployeePayroll.bind(this);

    const defaultPayrollId = parseInt(this.props.employeePayId, 10) || get(this.props.allPayrolls, [0, 'id']);
    this.state = {
      selectingPayrollId: defaultPayrollId
    };
  }

  changeSelectingEmployeePayroll(value) {
    this.setState({
      ...this.state,
      selectingPayrollId: value
    });
  }

  render() {
    const { allPayrolls = [] } = this.props;
    const selectingPayroll = allPayrolls.find(item => item.id === this.state.selectingPayrollId);
    // 給与がない時の画面
    if (_.isEmpty(allPayrolls)) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">登録済みの給与データがありません</p>
            <p className="u-ta-c">ご不明な点は管理者までお問い合わせください</p>
          </div>
        </div>
      );
    }
    return (
      <div className="l-wrap-xl l-contents-wrap">
        <PayrollActionSection
          selectingPayrollId={this.state.selectingPayrollId}
          payroll={selectingPayroll}
          allPayrolls={allPayrolls}
          changeSelectingEmployeePayroll={this.changeSelectingEmployeePayroll}
        />

        <div className={styles.payment}>
          <PayrollAttendanceDetail payroll={selectingPayroll} />
          <PayrollAllowanceDetail payroll={selectingPayroll} />
          <PayrollDeductionDetail payroll={selectingPayroll} />
          <PayrollSummaryDetail payroll={selectingPayroll} />
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  allPayrolls: (getItem(REDUCER_NAME, state) || {}).allPayrolls
}))(PayrollDetailShow);
