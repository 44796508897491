import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import * as Urls from 'src/constants/EndpointUrls';
import { Pulldown } from 'jbc-front/components/icons';
import { getCurrentUserInfo, isRoleTypeOperator } from 'src/reducers/users/currentUser';

import styles from './Settings.scss';

const CoordinationServiceList = () => (
  <React.Fragment>
    <a href={Urls.COORDINATION_SERVICE_LIST}>
      <dl className={styles.definition}>
        <dt>
          <span className={styles.icon}>
            <Pulldown size={13} />
          </span>サービス連携
        </dt>
        <dd>ジョブカンシリーズとの連携設定ができます</dd>
      </dl>
    </a>
  </React.Fragment>
);

class Settings extends React.Component {
  state = {
    isModalOpen: false
  };

  render() {
    const { planType, currentUser, isAvailableOfficeStation } = this.props;

    return (
      <div>
        <div className="l-main-title-wrap">
          <h2 className="m-title-main">設定</h2>
        </div>
        <div className="l-wrap-l l-contents-wrap">
          <div className={styles.main}>
            <section className={styles.section}>
              <h3 className={styles.category}>事業所情報</h3>
              <ul className={styles.list}>
                <li>
                  <a href={Urls.LIST_OFFICES_URL}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>事業所情報
                      </dt>
                      <dd>事業所情報を設定できます</dd>
                    </dl>
                  </a>
                </li>
              </ul>
            </section>
            <section className={styles.section}>
              <h3 className={styles.category}>給与賞与設定</h3>
              <ul className={styles.list}>
                <li>
                  <a href={Urls.CLIENT_ALLOWANCE_ITEMS}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>勤怠項目
                      </dt>
                      <dd>勤怠項目の設定ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.CLIENT_PAYROLL_RULES_GROUP_LIST}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>給与規定
                      </dt>
                      <dd>給与規定の設定ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.CLIENT_BONUS_ALLOWANCES_LIST}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>賞与規定
                      </dt>
                      <dd>賞与規定の設定ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.CLIENT_RESIDENT_TAX_PAYMENTS_LIST}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>住民税納付先
                      </dt>
                      <dd>住民税納付先の設定ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.CLIENT_CSV_FORMATS_LIST}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>支給・控除等一覧表フォーマット設定
                      </dt>
                      <dd>給与・賞与の支給・控除等一覧表のフォーマットの作成・変更ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.CLIENT_COMMUTING_FEE_SETTINGS}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>通勤手当の上限設定
                      </dt>
                      <dd>通勤手当の上限の設定ができます</dd>
                    </dl>
                  </a>
                </li>
              </ul>
            </section>
            <section className={styles.section}>
              <h3 className={styles.category}>組織管理</h3>
              <ul className={styles.list}>
                <li>
                  <a href={`${Urls.COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=employment_types`}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>雇用形態
                      </dt>
                      <dd>雇用形態の設定ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={`${Urls.COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=occupations`}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>職種
                      </dt>
                      <dd>職種の設定ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={`${Urls.COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=groups`}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>グループ
                      </dt>
                      <dd>グループの設定ができます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={`${Urls.COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=positions`}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>役職
                      </dt>
                      <dd>役職の設定ができます</dd>
                    </dl>
                  </a>
                </li>
              </ul>
              {/* <div className={styles.button}>
                <ActionButton
                  icon={<Link size={15} />}
                  onClick={() => this.setState({ ...this.state, isModalOpen: true })}
                >
                  組織管理の連携設定
                </ActionButton>
                <CoordinationWithRoumuModal
                  isOpen={this.state.isModalOpen}
                  hideModal={() => this.setState({ ...this.state, isModalOpen: false })}
                />
              </div> */}
            </section>
            <section className={styles.section}>
              <h3 className={styles.category}>サービス設定</h3>
              <ul className={styles.list}>
                <li>
                  <a href={Urls.CLIENT_SETTING_USING_PLAN}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>利用プラン
                      </dt>
                      <dd>現在のプランと請求情報を確認できます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <CoordinationServiceList planType={planType} />
                </li>
                <li>
                  {isRoleTypeOperator(currentUser.roleType) ? (
                    <div className={styles.div_prohibition}>
                      <dl className={styles.prohibition}>
                        <dt>
                          <span className={styles.icon}>
                            <Pulldown size={13} />
                          </span>権限管理設定
                        </dt>
                        <dd>権限の変更ができます</dd>
                      </dl>
                    </div>
                  ) : (
                    <a href={Urls.CLIENT_ROLE_USERS_LIST}>
                      <dl className={styles.definition}>
                        <dt>
                          <span className={styles.icon}>
                            <Pulldown size={13} />
                          </span>権限管理設定
                        </dt>
                        <dd>権限の変更ができます</dd>
                      </dl>
                    </a>
                  )}
                </li>
                <li>
                  <a href={Urls.EMAIL_NOTIFICATION_SETTING}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>通知先設定
                      </dt>
                      <dd>勤怠のデータ連携や給与確定のメール通知先を設定できます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.AUDIT_LOGS_LIST}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>操作履歴
                      </dt>
                      <dd>変更履歴を確認できます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.ALLOWED_IP_SETTING}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>IPアドレス制限
                      </dt>
                      <dd>接続元として制限するIPアドレスを設定できます</dd>
                    </dl>
                  </a>
                </li>
                <li>
                  <a href={Urls.CLIENT_ELECTTONIC_DELIVERY_AGREEMENT_SETTING}>
                    <dl className={styles.definition}>
                      <dt>
                        <span className={styles.icon}>
                          <Pulldown size={13} />
                        </span>給与・賞与・源泉徴収票電子交付設定
                      </dt>
                      <dd>電子交付の同意機能のON/OFFを設定できます</dd>
                    </dl>
                  </a>
                </li>
                {isAvailableOfficeStation && (
                  <li>
                    <a href={Urls.EXTERNAL_COORDINATION_SERVICE_LIST}>
                      <dl className={styles.definition}>
                        <dt>
                          <span className={styles.icon}>
                            <Pulldown size={13} />
                          </span>外部サービス連携
                        </dt>
                        <dd>外部サービスと連携する場合の設定を行えます</dd>
                      </dl>
                    </a>
                  </li>
                )}
              </ul>
            </section>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  planType: _.get(state, 'currentClient.item.data.planType'),
  currentUser: getCurrentUserInfo(state) || {}
}))(Settings);
