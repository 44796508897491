import React from 'react';

import * as ExUrls from 'src/constants/ExternalUrls';
import styles from './Footer.scss';

const Footer = () => (
  <div className={styles.container}>
    <div className={styles.Links}>
      <a href={ExUrls.ABOUT_JOBCAN_PAYROLL} rel="noopener noreferrer" target="_blank">
        ジョブカン給与計算について
      </a>
      <a href={ExUrls.ZENDESK_HELP} rel="noopener noreferrer" target="_blank">
        ヘルプ
      </a>
      <a href={ExUrls.TERMS_OF_SERVICE} rel="noopener noreferrer" target="_blank">
        利用規約
      </a>
      <a href={ExUrls.PRIVACY_POLICY} rel="noopener noreferrer" target="_blank">
        プライバシーポリシー
      </a>
      <a href={ExUrls.INFORMATION_SECURITY_POLICY} rel="noopener noreferrer" target="_blank">
        情報セキュリティ基本方針
      </a>
    </div>
    <div className={styles.CopyRight}>© 2018 DONUTS Co. Ltd.</div>
  </div>
);

export default Footer;
