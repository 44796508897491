import { connect } from 'react-redux';
import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import selector from 'src/utils/Utils';
import BanksSectionShow from '../components/BanksSectionShow';

const mapStateToProps = state => ({
  employeeBanks: selector(getItem(REDUCER_NAME, state), 'employeeBanks') || [],
  depositTypes: getSelectOptions(state, 'depositTypes')
});
export default connect(mapStateToProps)(BanksSectionShow);
