import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, startSubmit } from 'redux-form';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';

import { getItemErrors, setItem } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, newEmployee } from 'src/reducers/employees/employees';
import { getSelectOptions } from 'src/reducers/selectOptions';

import AnyApiRunning from './components/AnyApiRunning';
import EmployeeCreatingForm from './components/EmployeeCreatingForm';

const mapStateToProps = state => ({
  isNewForm: true,
  errors: getItemErrors(REDUCER_NAME, state),
  initialValues: { ...(state.employees.item.data || {}), baseSalary: 0 }
});

const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    dispatch(startSubmit(EMPLOYEE_FORM));
    dispatch(newEmployee(data));
  }
});

const NewEmployeeForm = connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: EMPLOYEE_FORM
  })(EmployeeCreatingForm)
);

const NewEmployee = ({ anyApiRunning, dispatch, employee }) => {
  dispatch(setItem(REDUCER_NAME, employee));

  return (
    <div>
      {anyApiRunning && <AnyApiRunning />}
      <div className="l-title-wrap">
        <h1 className="m-title-main">従業員情報登録</h1>
      </div>
      <NewEmployeeForm {...{ anyApiRunning }} />
    </div>
  );
};
export default connect(state => ({
  anyApiRunning: getSelectOptions(state, 'anyApiRunning')
}))(NewEmployee);
