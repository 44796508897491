import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import { CONFIRM_NOTIFICATION_OF_BASE_AMOUNT_REVISION_FORM } from 'src/constants/FormNames';
import { getList, getExtras } from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  getTargetHealthInsurance,
  changeNotificationOfBaseAmountConfirm,
  getConfirmRevisionErrors
} from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import { FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH } from 'src/reducers/searchForm';
import ConfirmRevisionInsideModal from '../../components/Modal/ConfirmRevisionModal';

const mapStateToProp = state => ({
  revisions: getList(REDUCER_NAME, state),
  applicableRevisions: getExtras(REDUCER_NAME, state).applicableRevisions || [],
  insuranceType: getTargetHealthInsurance(state).insuranceType,
  errors: getConfirmRevisionErrors(state),
  sortType: formValueSelector(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(state, 'sortType')
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () =>
    dispatch(changeNotificationOfBaseAmountConfirm(ownProps.yearMonth, ownProps.applicableOffice, ownProps.hideModal))
});

const ConfirmRevisionForm = reduxForm({
  form: CONFIRM_NOTIFICATION_OF_BASE_AMOUNT_REVISION_FORM
})(ConfirmRevisionInsideModal);

export default connect(mapStateToProp, mapDispatchToProp)(ConfirmRevisionForm);
