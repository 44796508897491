import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { ErrorsSection, LoadingWithDescription } from 'src/components';

import { FormSubmitBtn } from 'src/buttons';
import { UNCONFIRM_PAYROLL_GROUP } from 'src/constants/FormNames';
import { unconfirmEmployeePays, getConfirmPayrollGroupErrors } from 'src/reducers/employees/employeePays';
import styles from './UnconfirmPayrollGroupModal.scss';

const UnconfirmPayrollGroupInsideForm = ({ handleSubmit, submitting, submit, cancelBtnClick, errors }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>給与修正</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        {submitting && <LoadingWithDescription text="解除中です。少々お待ちください。" />}
        <p className={styles.lead}>
          給与データを編集可能な状態に変更します。
          <br />
          現在一覧に存在する給与データは変更されませんが、
          <br />
          確定後にこの締日支給日の給与規定グループが設定された従業員の給与データは追加されます。
        </p>
        <div className={styles.caution}>
          <p>【注意】</p>
          <p>給与修正を行うと給与に関連する以下の情報が削除されます。</p>
          <div className="u-mt15">
            <ul>
              <li>・給与明細</li>
              <li>・給与振込一覧表</li>
              <li>・住民税一覧表</li>
              <li>・支給・控除等一覧表</li>
              <li>・賃金台帳</li>
              <li>・月額変更届</li>
              <li>・算定基礎届</li>
              <li>・算定基礎賃金集計表</li>
              <li>・労働保険申告書</li>
              <li>・所得税徴収高集計資料</li>
            </ul>
          </div>
        </div>
        <div className="u-mt15">
          <p className={styles.lead}>給与修正しますか？</p>
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="実行" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let UnconfirmPayrollGroupForm = reduxForm({
  form: UNCONFIRM_PAYROLL_GROUP
})(UnconfirmPayrollGroupInsideForm);
const mapStateToProp = state => ({
  errors: getConfirmPayrollGroupErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: data => {
    dispatch(unconfirmEmployeePays(data, ownProps.selectedPayroll));
    if (typeof ownProps.hideModal === 'function') {
      ownProps.hideModal();
    }
  }
});

UnconfirmPayrollGroupForm = connect(mapStateToProp, mapDispatchToProp)(UnconfirmPayrollGroupForm);

const UnconfirmPayrollGroupModal = ({ isOpen, hideModal, selectedPayroll, yearMonthLabel }) => {
  const header = '給与確定';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <UnconfirmPayrollGroupForm
        hideModal={hideModal}
        cancelBtnClick={hideModal}
        selectedPayroll={selectedPayroll}
        yearMonthLabel={yearMonthLabel}
        header={header}
      />
    </CommonModal>
  );
};

export default UnconfirmPayrollGroupModal;
