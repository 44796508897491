import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';

import * as Urls from 'src/constants/EndpointUrls';
import Button from 'jbc-front/components/Button';
import { downloadAccountingPayCsv, getClientPayrollRulesGroups } from 'src/reducers/employees/employeePays';
import { setGlobalWarnings } from 'src/reducers/global';
import { openNewTab } from 'src/utils/Http';

import {
  takePayConfirmedStateFromSearchForm,
  takePaysQueriedUrl,
  takePaysFbDataUrl,
  takeInhabitantTaxFbDataUrl,
  getPayTitle,
  FORM_NAME_PAYS_SEARCH
} from 'src/reducers/searchForm';
import EmployeePaysMethod from './EmployeePaysMethod';
import AllowanceDeductionsMethod from './AllowanceDeductionsMethod';
import DownloadPayReports from '../containers/DownloadPayReportsContainer';
import styles from './ActionSection.scss';

class ActionSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPayrollBaloonOpen: false,
      isInhabitantTaxOpen: false,
      isAllowanceDeductionsOpen: false
    };
  }

  render() {
    const {
      payrollRulesGroups,
      yearMonth,
      downloadTemplateCsvPageUrl,
      showPayFbDataUrl,
      inhabitantTaxFBPageUrl,
      downloadZipUrl,
      isConfirmed,
      downloadAccountingPay
    } = this.props;

    return (
      <div className={styles.wrap}>
        <div className={styles.button}>
          <Button
            widthAuto
            className="ignore-react-onclickoutside"
            onClick={() =>
              this.setState({
                isAllowanceDeductionsOpen: !this.state.isAllowanceDeductionsOpen,
                isPayrollBaloonOpen: false,
                isInhabitantTaxOpen: false
              })
            }
          >
            支給控除項目
          </Button>
          {this.state.isAllowanceDeductionsOpen && (
            <AllowanceDeductionsMethod
              hide={() => this.setState({ isAllowanceDeductionsOpen: false })}
              downloadTemplateCsvPageUrl={downloadTemplateCsvPageUrl}
              isConfirmed={isConfirmed}
              downloadAccountingPay={downloadAccountingPay}
            />
          )}
        </div>
        <div className={styles.button}>
          <Button
            widthAuto
            className="ignore-react-onclickoutside"
            disabled={!isConfirmed}
            onClick={() =>
              this.setState({
                isPayrollBaloonOpen: !this.state.isPayrollBaloonOpen,
                isInhabitantTaxOpen: false,
                isAllowanceDeductionsOpen: false
              })
            }
          >
            給与振込
          </Button>
          <EmployeePaysMethod
            hide={() => this.setState({ isPayrollBaloonOpen: false })}
            showPayFbDataUrl={showPayFbDataUrl}
            isConfirmed={isConfirmed}
            isOpen={this.state.isPayrollBaloonOpen}
          />
        </div>
        <div className={styles.button}>
          <Button
            widthAuto
            className="ignore-react-onclickoutside"
            disabled={!isConfirmed}
            onClick={() => {
              openNewTab(inhabitantTaxFBPageUrl);
            }}
          >
            住民税振込
          </Button>
        </div>
        <DownloadPayReports
          {...{
            payrollRulesGroups,
            yearMonth,
            downloadZipUrl,
            isConfirmed
          }}
        />
      </div>
    );
  }
}

export default connect(
  state => {
    const extras = { jobInfo: getPayTitle(getFormValues(FORM_NAME_PAYS_SEARCH)(state), state) };
    const formQueries = getFormValues(FORM_NAME_PAYS_SEARCH)(state);

    return {
      downloadTemplateCsvPageUrl: takePaysQueriedUrl(Urls.EMPLOYEE_PAY_DOWNLOAD_CSV_PAGE, formQueries, false, extras),
      downloadZipUrl: takePaysQueriedUrl(Urls.PAY_DETAIL_PDF, formQueries, true, extras),
      clientPayrollRulesGroups: getClientPayrollRulesGroups(state),
      showPayFbDataUrl: takePaysFbDataUrl(state),
      inhabitantTaxFBPageUrl: takeInhabitantTaxFbDataUrl(state),
      isConfirmed: takePayConfirmedStateFromSearchForm(state, FORM_NAME_PAYS_SEARCH)
    };
  },
  dispatch => ({
    displayWarnings: msg => dispatch(setGlobalWarnings(msg)),
    downloadAccountingPay: () => dispatch(downloadAccountingPayCsv())
  })
)(ActionSection);
