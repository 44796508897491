import React from 'react';
import { Field } from 'redux-form';
import classNames from 'classnames';
import _ from 'lodash';
import Hint from 'jbc-front/components/Hint';
import LazyLoad from 'react-lazyload';

import { numberWithComma } from 'src/utils/Utils';
import { formatYearMonth, formatMonth, formatDate } from 'src/utils/Date';
import { isMoneyNumber, isNumber, isBasicDaysFormat, maxLength37 } from 'src/utils/CustomValidators';
import Checked from 'src/icons/Checked';

import EditableTextField from './EditableTextField';
import EditableSelectField from './EditableSelectField';
import EditableRadioField from './EditableRadioField';

import styles from './RevisionItemBody.scss';

const NOTE_70_OVER = '1.70歳以上の被用者月額変更';
const NOTE_WORK_IN_2_OR_MORE = '2.二以上勤務';
const NOTE_SHORT_TIME_WORKER = '3.短時間労働者（特定適用事業所等）';
const NOTE_REASON_OF_RAISE_REDUCTION = '4.昇給・降給の理由';
const NOTE_ONLY_INSURANCE = '5.健康保険のみ月額変更（70歳到達時の契約変更等）';
const NOTE_OTHER = '6.その他';

// RevisionItemBody内に定義するとレンダーが無限に実行されてしまうので外側に置く
// https://github.com/redux-form/redux-form/issues/2629#issuecomment-388309567
const validateRetroactiveAmount = (value, allValues, formProps, name) => {
  const revision = allValues.revisions[name.split('.')[1]];
  if (Number(value) === 0 && revision.retroactiveEnabled) {
    return 'がある場合は、金額を入力してください';
  }
  return undefined;
};

const RevisionItemBody = props => {
  const { revision, formRevision, changedYearMonths, lastRevisionYearMonths, fetchValue } = props;

  const showMode = () => props.showMode(revision);
  const recalculate = () => props.recalculate(revision);
  const changeToManualCalcMode = name => props.changeToManualCalcMode(revision, name);
  const notifyValueChanges = () => props.notifyValueChanges(revision);
  const setFormField = (name, val) => props.setFormField(revision, name, val);

  const isRetroactiveShowDisable = showMode() === 'SHOW' && !formRevision.retroactiveEnabled;
  const isHiddenNote = !fetchValue('isOtherNote') || (showMode() === 'SHOW' && _.isEmpty(fetchValue('note')));
  const isHiddenReason =
    !fetchValue('isReasonOfRaiseReduction') ||
    (showMode() === 'SHOW' && _.isEmpty(fetchValue('reasonOfRaiseReduction')));

  // redux-formは文字列で値が返ってくるのでnormalizeする
  const normalizeBoolean = value => {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  };

  const targetLastRevisionYearMonths = () => {
    if (_.isEmpty(revision) || _.isEmpty(lastRevisionYearMonths)) {
      return [];
    }
    const employeeId = revision.employeeId;
    const target = lastRevisionYearMonths.find(data => data.employeeId === employeeId);
    return _.isEmpty(target) ? [] : target.months;
  };

  return (
    <div className={`${styles.wrap} l-overflow-scroll`}>
      <LazyLoad>
        <table className={classNames(styles.revisionsTable, styles.tableTop)}>
          <tbody>
            <tr className={styles.rowFieldHeader}>
              <th className={classNames(styles.columnM)}>健康保険被保険者整理番号</th>
              <th className={classNames(styles.columnM)}>生年月日</th>
              <th className={classNames(styles.columnL)}>改定年月</th>
              <th>固定的賃金の変動あり</th>
            </tr>
            <tr className={styles.rowContent}>
              <td className={'u-ta-c'}>{revision.healthInsuranceInsuredPersonnelNumber}</td>
              <td className={'u-ta-c'}>{formatDate(revision.birthday)}</td>
              <td className={'u-ta-c'}>{formatYearMonth(revision.revisionYearMonth)}</td>
              <td>
                <Field
                  name="isFixedAmountChanged"
                  showValue={fetchValue('isFixedAmountChanged') ? 'あり' : 'なし'}
                  textAlign="center"
                  showMode={showMode()}
                  inputWidth={16}
                  inputHeight={16}
                  checked={fetchValue('isFixedAmountChanged')}
                  onChange={e => {
                    setFormField('isFixedAmountChanged', e.target.value);
                    notifyValueChanges();
                    recalculate();
                  }}
                  normalize={normalizeBoolean}
                  component={EditableRadioField}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </LazyLoad>
      <LazyLoad>
        <table className={styles.revisionsTable}>
          <tbody>
            <tr className={styles.rowFieldHeader}>
              <th colSpan="2" className={styles.columnM}>
                従前の健保
              </th>
              <th colSpan="2" className={classNames(styles.columnM)}>
                従前の厚年
              </th>
              <th style={{ width: 148 }}>従前改定月</th>
              <th style={{ width: 132 }}>
                <div className={styles.titleWithHint}>
                  <span>昇（降）給</span>
                  <Hint
                    text={
                      <p>
                        昇給（降給）した場合は、「昇（降）給」に1ヶ月の合計差額を入力して下さい。（毎月の給与を基に自動計算されます）
                      </p>
                    }
                  />
                </div>
              </th>
              <th>
                <div className={styles.titleWithHint}>
                  <span>遡及支払額</span>
                  <Hint
                    text={
                      <p>
                        さかのぼって昇給し、差額を支給した場合はその金額を入力します。<br />
                        （例）8月に2万円昇給し、8月の昇給分が9月に支給された場合→遡及支払額は2万円になります
                      </p>
                    }
                  />
                </div>
              </th>
            </tr>
            <tr className={styles.rowContent}>
              <td className={classNames('u-ta-c', styles.columnLeft)}>
                {fetchValue('beforeHealthInsuranceGrade')}
                <span className="unit">等級</span>
              </td>
              <td className={classNames('u-ta-r', styles.columnRight)}>
                {numberWithComma(fetchValue('beforeMonthlyCompensationHealthInsuranceAmount'))}
                <span className="unit">円</span>
              </td>
              <td className={classNames('u-ta-c', styles.columnLeft)}>
                {fetchValue('beforePensionInsuranceGrade')}
                <span className="unit">等級</span>
              </td>
              <td className={classNames('u-ta-r', styles.columnRight)}>
                {numberWithComma(fetchValue('beforeMonthlyRemunerationPensionInsuranceAmount'))}
                <span className="unit">円</span>
              </td>
              <td>
                <div className={styles.lastRevisionYearMonth}>
                  <Field
                    name="lastRevisionYearMonth"
                    showValue={formatYearMonth(fetchValue('lastRevisionYearMonth'))}
                    showMode={showMode()}
                    textAlign="center"
                    options={targetLastRevisionYearMonths()}
                    width={100}
                    component={EditableSelectField}
                  />
                </div>
              </td>
              <td>
                <div className={styles.changeAmount}>
                  {formatMonth(revision.changedYearMonth)}月
                  <Field
                    name="changedAmount"
                    showValue={numberWithComma(revision.changedAmount)}
                    showMode={showMode()}
                    inputWidth={70}
                    unit="円"
                    customOnChange={() => notifyValueChanges()}
                    customOnBlur={() => {
                      changeToManualCalcMode('changedAmount');
                      recalculate();
                    }}
                    validate={[isMoneyNumber]}
                    component={EditableTextField}
                  />
                </div>
              </td>
              <td className="u-ta-r">
                <div className={styles.retroactive}>
                  {showMode() === 'SHOW' && (
                    <div
                      className={classNames({
                        [`${styles.retroactiveShowDisable}`]: isRetroactiveShowDisable
                      })}
                    >
                      <span className={styles.checked}>
                        <Checked checked={fetchValue('retroactiveEnabled')} defaultValue="" />
                      </span>
                      {fetchValue('retroactiveEnabled') ? '遡及支払あり' : '遡及支払なし'}
                    </div>
                  )}
                  {showMode() === 'EDIT' && (
                    <Field
                      name="retroactiveEnabled"
                      showValue=""
                      label="遡及支払あり"
                      showMode={showMode()}
                      inputWidth={96}
                      inputHeight={16}
                      textAlign="left"
                      style={{ 'flex-grow': 1 }}
                      width={118}
                      type="checkbox"
                      customOnChange={() => {
                        notifyValueChanges();
                        recalculate();
                      }}
                      component={EditableTextField}
                    />
                  )}
                  {!isRetroactiveShowDisable && (
                    <Field
                      name="retroactiveYearMonth"
                      disabled={!fetchValue('retroactiveEnabled')}
                      showValue={formatYearMonth(fetchValue('retroactiveYearMonth'))}
                      showMode={showMode()}
                      options={changedYearMonths}
                      width={100}
                      onChange={(e, val) => {
                        setFormField('retroactiveYearMonth', val);
                        notifyValueChanges();
                        recalculate();
                      }}
                      component={EditableSelectField}
                    />
                  )}
                  {!isRetroactiveShowDisable && (
                    <Field
                      name="retroactiveAmount"
                      disabled={!fetchValue('retroactiveEnabled')}
                      showValue={numberWithComma(fetchValue('retroactiveAmount'))}
                      showMode={showMode()}
                      inputWidth={70}
                      width="initial"
                      unit="円"
                      customOnChange={() => notifyValueChanges()}
                      customOnBlur={() => {
                        changeToManualCalcMode('retroactiveAmount');
                        recalculate();
                      }}
                      validate={[isMoneyNumber, validateRetroactiveAmount]}
                      component={EditableTextField}
                    />
                  )}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </LazyLoad>
      <LazyLoad>
        <table className={styles.revisionsTable}>
          <tbody>
            <tr className={styles.rowContentL}>
              <td rowSpan="3" className={styles.rowFieldHeaderVertical}>
                支払基礎日数
              </td>
              <td className={styles.columnLeft}>
                <EditableTextField
                  showValue={formatMonth(revision.firstYearMonth)}
                  textAlign="center"
                  showMode="SHOW"
                  unit="月"
                />
              </td>
              <td style={{ width: 85 }}>
                <Field
                  name="firstMonthBasicDays"
                  showValue={parseInt(revision.firstMonthBasicDays, 10)}
                  textAlign="center"
                  showMode={showMode()}
                  inputWidth={32}
                  unit="日"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isNumber, isBasicDaysFormat]}
                  component={EditableTextField}
                />
              </td>
              <td rowSpan="3" className={styles.rowFieldHeaderVertical}>
                通貨による額
              </td>
              <td className={styles.columnM}>
                <Field
                  name="firstMonthCurrencyAmount"
                  showValue={numberWithComma(revision.firstMonthCurrencyAmount)}
                  showMode={showMode()}
                  inputWidth={70}
                  unit="円"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                />
              </td>
              <td rowSpan="3" className={styles.rowFieldHeaderVertical}>
                現物による額
              </td>
              <td className={styles.columnM}>
                <Field
                  name="firstMonthGenbutsuAmount"
                  showValue={numberWithComma(revision.firstMonthGenbutsuAmount)}
                  showMode={showMode()}
                  inputWidth={70}
                  unit="円"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                />
              </td>
              <td rowSpan="3" className={styles.rowFieldHeaderVertical}>
                合計
              </td>
              <td className={styles.columnM}>
                <EditableTextField
                  showValue={numberWithComma(fetchValue('firstMonthAmount'))}
                  showMode="SHOW"
                  unit="円"
                />
              </td>
              <td className={styles.boxTd}>
                <div className={styles.subHeader}>3ヶ月の総計</div>
                <div className={styles.tdContent}>
                  {numberWithComma(fetchValue('totalAmount'))}
                  <span className="unit">円</span>
                </div>
              </td>
            </tr>

            <tr className={styles.rowContentL}>
              <td>
                <EditableTextField
                  showValue={formatMonth(revision.secondYearMonth)}
                  textAlign="center"
                  showMode="SHOW"
                  unit="月"
                />
              </td>
              <td>
                <Field
                  name="secondMonthBasicDays"
                  showValue={parseInt(revision.secondMonthBasicDays, 10)}
                  textAlign="center"
                  showMode={showMode()}
                  inputWidth={32}
                  unit="日"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isNumber, isBasicDaysFormat]}
                  component={EditableTextField}
                />
              </td>
              <td>
                <Field
                  name="secondMonthCurrencyAmount"
                  showValue={numberWithComma(revision.secondMonthCurrencyAmount)}
                  showMode={showMode()}
                  inputWidth={70}
                  unit="円"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                />
              </td>
              <td>
                <Field
                  name="secondMonthGenbutsuAmount"
                  showValue={numberWithComma(revision.secondMonthGenbutsuAmount)}
                  showMode={showMode()}
                  inputWidth={70}
                  unit="円"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                />
              </td>
              <td>
                <EditableTextField
                  showValue={numberWithComma(fetchValue('secondMonthAmount'))}
                  showMode="SHOW"
                  unit="円"
                />
              </td>
              <td className={styles.boxTd}>
                <div className={styles.subHeader}>平均額</div>
                <div className={styles.tdContent}>
                  {numberWithComma(fetchValue('amountAverage'))}
                  <span className="unit">円</span>
                </div>
              </td>
            </tr>

            <tr className={styles.rowContentL}>
              <td>
                <EditableTextField
                  showValue={formatMonth(revision.thirdYearMonth)}
                  textAlign="center"
                  showMode="SHOW"
                  unit="月"
                />
              </td>
              <td>
                <Field
                  name="thirdMonthBasicDays"
                  showValue={parseInt(revision.thirdMonthBasicDays, 10)}
                  textAlign="center"
                  showMode={showMode()}
                  inputWidth={32}
                  unit="日"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isNumber, isBasicDaysFormat]}
                  component={EditableTextField}
                />
              </td>
              <td>
                <Field
                  name="thirdMonthCurrencyAmount"
                  showValue={numberWithComma(revision.thirdMonthCurrencyAmount)}
                  showMode={showMode()}
                  inputWidth={70}
                  unit="円"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                />
              </td>
              <td>
                <Field
                  name="thirdMonthGenbutsuAmount"
                  showValue={numberWithComma(revision.thirdMonthGenbutsuAmount)}
                  showMode={showMode()}
                  inputWidth={70}
                  unit="円"
                  customOnChange={() => notifyValueChanges()}
                  customOnBlur={() => recalculate()}
                  validate={[isMoneyNumber]}
                  component={EditableTextField}
                />
              </td>
              <td>
                <EditableTextField
                  showValue={numberWithComma(fetchValue('thirdMonthAmount'))}
                  showMode="SHOW"
                  unit="円"
                />
              </td>
              <td className={styles.boxTd}>
                <div className={styles.subHeader}>
                  <div className={styles.titleWithHint}>
                    <span>修正平均額</span>
                    <Hint
                      text={
                        <p>
                          修正平均額は対象期間より前に払う筈だった給与を、何らかの理由で遡って支払った場合に使用し、実質の平均額に当たります。
                          （遡及支払額が入力されている場合は自動計算されます）<br />
                          <br />
                          新しい標準報酬月額は、修正平均額を基に算出されます。
                        </p>
                      }
                    />
                  </div>
                </div>
                <div className={styles.tdContent}>
                  <Field
                    name="modifiedAverageAmount"
                    showValue={numberWithComma(revision.modifiedAverageAmount)}
                    showMode={showMode()}
                    inputWidth={70}
                    unit="円"
                    customOnChange={() => notifyValueChanges()}
                    customOnBlur={() => {
                      changeToManualCalcMode('modifiedAverageAmount');
                      recalculate();
                    }}
                    validate={[isMoneyNumber]}
                    component={EditableTextField}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </LazyLoad>
      <table className={styles.revisionsTable}>
        <tbody>
          <tr className={styles.rowFieldHeader}>
            <td colSpan="2" className={styles.columnM}>
              改定後の健保
            </td>
            <td colSpan="2" className={styles.columnM}>
              改定後の厚年
            </td>
            <td>備考</td>
          </tr>
          <tr className={styles.rowContentL}>
            <td className={classNames('u-ta-c', styles.columnLeft)}>
              {fetchValue('afterHealthInsuranceGrade')}
              <span className="unit">等級</span>
            </td>
            <td className={classNames('u-ta-r', styles.columnRight)}>
              {numberWithComma(fetchValue('afterMonthlyCompensationHealthInsuranceAmount'))}
              <span className="unit">円</span>
            </td>
            <td className={classNames('u-ta-c', styles.columnLeft)}>
              {fetchValue('afterPensionInsuranceGrade')}
              <span className="unit">等級</span>
            </td>
            <td className={classNames('u-ta-r', styles.columnRight)}>
              {numberWithComma(fetchValue('afterMonthlyRemunerationPensionInsuranceAmount'))}
              <span className="unit">円</span>
            </td>
            <td>
              <LazyLoad>
                <div
                  className={classNames(styles.noteContainer, {
                    [styles.noteContainerShow]: showMode() === 'SHOW'
                  })}
                >
                  <Field
                    name="isOver70YearsOld"
                    className={classNames(styles.noteCheck, {
                      [styles.disabled]: showMode() === 'SHOW' && !fetchValue('isOver70YearsOld')
                    })}
                    width="initial"
                    label={NOTE_70_OVER}
                    showValue={NOTE_70_OVER}
                    showMode={showMode()}
                    textAlign="left"
                    type="checkbox"
                    component={EditableTextField}
                  />
                  <Field
                    name="isWorkInTwoOrMore"
                    className={classNames(styles.noteCheck, {
                      [styles.disabled]: showMode() === 'SHOW' && !fetchValue('isWorkInTwoOrMore')
                    })}
                    width="initial"
                    label={NOTE_WORK_IN_2_OR_MORE}
                    showValue={NOTE_WORK_IN_2_OR_MORE}
                    showMode={showMode()}
                    textAlign="left"
                    type="checkbox"
                    component={EditableTextField}
                  />
                  <Field
                    name="isShortTimeWorker"
                    className={classNames(styles.noteCheck, {
                      [styles.disabled]: showMode() === 'SHOW' && !fetchValue('isShortTimeWorker')
                    })}
                    width="initial"
                    label={NOTE_SHORT_TIME_WORKER}
                    showValue={NOTE_SHORT_TIME_WORKER}
                    showMode={showMode()}
                    textAlign="left"
                    type="checkbox"
                    component={EditableTextField}
                  />
                </div>
                <div
                  className={classNames(styles.noteContainer, {
                    [styles.noteContainerShow]: showMode() === 'SHOW'
                  })}
                >
                  <Field
                    name="isReasonOfRaiseReduction"
                    className={classNames(styles.noteCheck, {
                      [styles.noteItem1]: fetchValue('isReasonOfRaiseReduction'),
                      [styles.disabled]: showMode() === 'SHOW' && !fetchValue('isReasonOfRaiseReduction')
                    })}
                    width="initial"
                    label={NOTE_REASON_OF_RAISE_REDUCTION}
                    showValue={`${NOTE_REASON_OF_RAISE_REDUCTION}${
                      _.isEmpty(fetchValue('reasonOfRaiseReduction')) ? '' : '：'
                    }`}
                    showMode={showMode()}
                    textAlign="left"
                    type="checkbox"
                    component={EditableTextField}
                  />
                  {!isHiddenReason && (
                    <Field
                      name="reasonOfRaiseReduction"
                      className={classNames(styles.noteText, {
                        [styles.noteTextPreview]: showMode() === 'SHOW'
                      })}
                      inputHeight={32}
                      showValue={fetchValue('reasonOfRaiseReduction')}
                      showMode={showMode()}
                      textAlign="left"
                      component={EditableTextField}
                      validate={[maxLength37]}
                    />
                  )}
                  <Field
                    name="isHealthInsuranceOnly"
                    className={classNames(styles.noteCheck, {
                      [styles.disabled]: showMode() === 'SHOW' && !fetchValue('isHealthInsuranceOnly')
                    })}
                    width="initial"
                    label={NOTE_ONLY_INSURANCE}
                    showValue={NOTE_ONLY_INSURANCE}
                    showMode={showMode()}
                    textAlign="left"
                    type="checkbox"
                    component={EditableTextField}
                  />
                  <Field
                    name="isOtherNote"
                    className={classNames(styles.noteCheck, {
                      [styles.noteItem1]: fetchValue('isOtherNote'),
                      [styles.disabled]: showMode() === 'SHOW' && !fetchValue('isOtherNote')
                    })}
                    width="initial"
                    label={NOTE_OTHER}
                    showValue={`${NOTE_OTHER}${_.isEmpty(fetchValue('note')) ? '' : '：'}`}
                    showMode={showMode()}
                    textAlign="left"
                    type="checkbox"
                    component={EditableTextField}
                  />
                  {!isHiddenNote && (
                    <Field
                      name="note"
                      className={classNames(styles.noteText, {
                        [styles.noteTextPreview]: showMode() === 'SHOW'
                      })}
                      inputHeight={32}
                      showValue={fetchValue('note')}
                      showMode={showMode()}
                      textAlign="left"
                      component={EditableTextField}
                      validate={[maxLength37]}
                    />
                  )}
                </div>
              </LazyLoad>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default RevisionItemBody;
