import React from 'react';
import styles from './FileImportDescription.scss';

const FileImportDescription = () => (
  <div className="l-wrap-xl">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              スタッフコード<span className={styles.required}>必須</span>
            </th>
            <td>スタッフコードを基にデータが取り込まれるため、正確に入力してください。</td>
            <td>111</td>
          </tr>
          <tr>
            <th>姓</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>山田</td>
          </tr>
          <tr>
            <th>名</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>太郎</td>
          </tr>
          <tr>
            <th>健康保険被保険者整理番号</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>11111</td>
          </tr>
          <tr>
            <th>厚生年金保険被保険者整理番号</th>
            <td>
              ※ダウンロード専用項目のため更新不可
              <br />
              ※事業所の健康保険タイプが「ITS健保」「その他の組合健保」の場合のみ出力されます。
            </td>
            <td>11111</td>
          </tr>
          <tr>
            <th>健保対象区分</th>
            <td>
              「対象」もしくは「対象外」を入力してください。
              <br />
              ※「健保対象判定方法」が「手動」の場合のみ反映され、「自動」の場合は無視されます
            </td>
            <td>対象</td>
          </tr>
          <tr>
            <th>健保対象判定方法</th>
            <td>
              「自動」もしくは「手動」を入力してください。
              <br />
              ※「手動」にした場合は「健保対象区分」列の値で判定を強制的に変更します
              <br />
              ※「自動」にした場合は「健保対象区分」の値を無視し、再度自動判定を行います
            </td>
            <td>自動</td>
          </tr>
          <tr>
            <th>厚年対象区分</th>
            <td>
              「対象」もしくは「対象外」を入力してください。
              <br />
              ※「厚年対象判定方法」が「手動」の場合のみ反映され、「自動」の場合は無視されます
            </td>
            <td>対象</td>
          </tr>
          <tr>
            <th>厚年対象判定方法</th>
            <td>
              「自動」もしくは「手動」を入力してください。
              <br />
              ※「手動」にした場合は「厚年対象区分」列の値で判定を強制的に変更します
              <br />
              ※「自動」にした場合は「厚年対象区分」の値を無視し、再度自動判定を行います
            </td>
            <td>自動</td>
          </tr>
          <tr>
            <th>
              提出年<span className={styles.required}>必須</span>
            </th>
            <td>
              yyyy
              <br />
              インポート画面で表示されている年と異なる場合はエラーとなります。
            </td>
            <td>2021</td>
          </tr>
          <tr>
            <th>従前改定月</th>
            <td>yyyy年mm月</td>
            <td>2020年06月</td>
          </tr>
          <tr>
            <th>昇（降）給月</th>
            <td>
              yyyy年mm月<br />
              昇給（降給）した場合は、当該月を入力してください。
            </td>
            <td>2020年05月</td>
          </tr>
          <tr>
            <th>昇（降）給差額</th>
            <td>
              半角数字9桁以内<br />
              昇給（降給）した場合は、「昇（降）給」に1ヶ月の合計差額を入力して下さい。（毎月の給与を基に自動計算されます）
            </td>
            <td>10000</td>
          </tr>
          <tr>
            <th>遡及支払額</th>
            <td>半角数字9桁以内</td>
            <td>10000</td>
          </tr>
          <tr>
            <th>4月の遡及支払有無</th>
            <td>
              下記より一つ選択ください
              <ul>
                <li>・有</li>
                <li>・無</li>
              </ul>
            </td>
            <td>有</td>
          </tr>
          <tr>
            <th>5月の遡及支払有無</th>
            <td>-</td>
            <td>有</td>
          </tr>
          <tr>
            <th>6月の遡及支払有無</th>
            <td>-</td>
            <td>有</td>
          </tr>
          <tr>
            <th>支払基礎日数1</th>
            <td>半角数字2桁以内</td>
            <td>30</td>
          </tr>
          <tr>
            <th>支払基礎日数2</th>
            <td>-</td>
            <td>30</td>
          </tr>
          <tr>
            <th>支払基礎日数3</th>
            <td>-</td>
            <td>30</td>
          </tr>
          <tr>
            <th>被保険者区分1</th>
            <td>
              以下から被保険者区分を選択して入力してください。
              <ul>
                <li>・一般の被保険者</li>
                <li>・パート扱いする者</li>
                <li>・短時間労働者</li>
              </ul>
            </td>
            <td>一般の被保険者</td>
          </tr>
          <tr>
            <th>被保険者区分2</th>
            <td>-</td>
            <td>一般の被保険者</td>
          </tr>
          <tr>
            <th>被保険者区分3</th>
            <td>-</td>
            <td>一般の被保険者</td>
          </tr>
          <tr>
            <th>通貨による額1</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>通貨による額2</th>
            <td>-</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>通貨による額3</th>
            <td>-</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>現物による額1</th>
            <td>半角数字9桁以内</td>
            <td>1000</td>
          </tr>
          <tr>
            <th>現物による額2</th>
            <td>-</td>
            <td>1000</td>
          </tr>
          <tr>
            <th>現物による額3</th>
            <td>-</td>
            <td>1000</td>
          </tr>
          <tr>
            <th>修正平均額</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>備考: 70歳以上の被用者算定</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: 70歳以上の被用者算定 - 算定基礎月</th>
            <td>5もしくは6を入力してください。(5が入力された場合は5月と6月が出力されます)</td>
            <td>5</td>
          </tr>
          <tr>
            <th>備考: 二以上勤務</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: 月額変更予定</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: 月額変更予定 - 対象保険種別</th>
            <td>
              「健保・厚年」、「健保のみ」、「厚年のみ」のいずれかを入力してください。
              <br />
              ※「備考: 月額変更予定」が「該当」の場合に設定必須
            </td>
            <td>健保・厚年</td>
          </tr>
          <tr>
            <th>備考: 途中入社</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: 病休・育休・休職等</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: 短時間労働者（特定適用事業所等）</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: パート</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: 年間平均</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: その他</th>
            <td>該当する場合は「該当」と入力し、該当しない場合は空欄としてください。</td>
            <td>該当</td>
          </tr>
          <tr>
            <th>備考: その他 - 詳細</th>
            <td>※「備考: その他」が「該当」の場合は入力必須</td>
            <td>令和5年4月21日より育児休業</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default FileImportDescription;
