import React from 'react';
import { connect } from 'react-redux';

import { getEmployeeAttendanceListExtras } from 'src/reducers/employees/employeeAttendances';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import styles from './SyncWithJbcModal.scss';

const SyncWithJbcModal = ({ isOpen, handleHide, targetPeriodText, handleSync, syncTimesInfo = {} }) => {
  const { syncTimesToday = 0, maxSyncTimePerDay = 10 } = syncTimesInfo;

  return (
    <CommonModal isOpen={isOpen} hideModal={handleHide} closeOnClick ariaHideApp={false}>
      <CommonModal.Header hideModal={handleHide}>ジョブカン勤怠管理から勤怠データを取得</CommonModal.Header>
      <CommonModal.Body>
        <div className={styles.wrap}>
          <div className={styles.targetPeriodText}>{targetPeriodText}</div>
          <div className={styles.syncTimes}>取得可能な回数&nbsp;{maxSyncTimePerDay - syncTimesToday}回</div>
          {syncTimesToday >= maxSyncTimePerDay ? (
            <div className="u-mt30">
              <p>機能が制限されました。取得は1日{maxSyncTimePerDay}回までです。</p>
              <p>毎日0時に取得回数はリセットされます。</p>
              <p className="u-mt20">本日中に制限を解除したい場合はサポートまでご連絡ください</p>
            </div>
          ) : (
            <div className="u-mt30">
              <p>以下、注意点がございます。</p>
              <ul className="m-list-notes u-mt10">
                <li>
                  ・勤怠データの取得は連携済みの従業員のみとなります。まだ連携していない従業員がいる場合は従業員一覧から連携をおこなってください
                </li>
                <li>・取得には時間を要する場合がございます。</li>
                <li>・取得は1日{maxSyncTimePerDay}回までです。毎日0時に取得回数はリセットされます。</li>
              </ul>
            </div>
          )}
        </div>
      </CommonModal.Body>
      <CommonModal.Footer>
        {syncTimesToday < maxSyncTimePerDay ? (
          <div className={styles.confirm}>
            <Button onClick={handleHide} className="u-mr20">
              キャンセル
            </Button>
            <Button onClick={handleSync} primary>
              取得
            </Button>
          </div>
        ) : (
          <div>
            <Button onClick={handleHide}>閉じる</Button>
          </div>
        )}
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default connect(state => ({
  syncTimesInfo: (getEmployeeAttendanceListExtras(state) || {}).syncTimesInfo
}))(SyncWithJbcModal);
