// Home
export const HOME_PAGE = '/';

// Registration
export const ACCOUNT_REGISTRATION_NEW = '/registrations/new';
export const ACCOUNT_REGISTRATION_CREATE = '/registrations';
export const ACCOUNT_REGISTRATION_COMPLETED_NOTIFY = '/registrations/completed_notify';

// OAuth
export const OAUTH_JBC_LOGIN = '/oauth/jbc/login';

// User
export const USER_LOGIN = '/users/sign_in';
export const USER_LOGIN_TEMP = '/users/login';
export const USER_LOGOUT = '/users/sign_out';
export const USER_REGISTER_URL = '/users/sign_up';

export const USER_RESET_PASSWORD_NEW = '/users/password/new';
export const USER_RESET_PASSWORD_CREATE = '/users/password';
export const USER_RESET_PASSWORD_EDIT = '/users/password/edit';
export const USER_RESET_PASSWORD_UPDATE = '/users/password';
export const USER_RESET_PASSWORD_INVALID_TOKEN = '/users/passwords/invalid_token';
export const USER_INVITATION_CONFIRMATION = '/users/invitation_confirmation';

export const USER_ACCOUNT_EDIT = '/users/account/edit';
export const USER_CHANGE_EMAIL = '/users/account/change_email';
export const USER_CHANGE_PASSWORD = '/users/account/change_password';
export const USER_INVITE_TO_DASHBOARDS = '/users/account/invite_to_dashboards';
export const USER_PROFILE_UPDATE_ELECTTONIC_DELIVERY_AGREEMENT =
  '/users/profile/update_is_electronic_delivery_agreement';
export const USER_PROFILE_EDIT = '/users/profile/edit';
export const USER_PROFILE_UPDATE = '/users/profile';

// Only View
export const CHANGE_EMAIL_PAGE_URL = '/users/account/change_email_page';
export const CHANGE_PASSWORD_PAGE_URL = '/users/account/change_password_page';
// Search Master Data
export const SEARCH_POSTAL_CODE_URL = '/master/postal';
export const SEARCH_BANKS_URL = '/master/banks';
export const SEARCH_BANKS_WITH_PAGE_URL = '/master/banks_with_page';
export const SEARCH_BANK_BRANCHES_URL = '/master/bank_branches';
export const SEARCH_BANK_BRANCHES_WITH_PAGE_URL = '/master/bank_branches_with_page';
export const SEARCH_TAX_OFFICES_URL = '/master/tax_offices';
export const SEARCH_CITY_URL_WITH_PREFECTURE = '/master/fetch_cities';
export const SEARCH_CITY_CODE_WITH_NAME_URL = '/master/fetch_city_by_name';
export const SEARCH_STANDARD_MONTHLY_AMOUNTS = '/master/standard_monthly_amounts';

// Dashboard
export const DASHBOARD_SHOW = '/dashboards';

// 従業員のデータ
export const EMPLOYEE_MY_BONUS = '/employees/my_data/bonus'; // 賞与
export const EMPLOYEE_MY_DATA_DOWNLOAD_BONUS = '/employees/my_data/download_bonus'; // 賞与明細PDF
export const EMPLOYEE_MY_PAYSLIPS = '/employees/my_data/payslips'; // 給与
export const EMPLOYEE_MY_DATA_DOWNLOAD_PAY = '/employees/my_data/download_pay'; // 給与明細PDF
export const EMPLOYEE_MY_INCOME_TAX_WITHHOLDING = '/employees/my_data/withholding_slips'; // 源泉徴収票
export const EMPLOYEE_MY_DOWNLOAD_INCOME_TAX_WITHHOLDING = '/employees/my_data/withholding_slips_pdf'; // 源泉徴収票PDF
export const EMPLOYEE_MY_DATA_ELECTRONIC_DELIVERY_AGREEMENT = '/employees/my_data/electronic_delivery_agreement'; // 電子交付の設定
export const EMPLOYEE_MY_DATA_EMPLOYEE_ELECTRONIC_DELIVERY_AGREEMENT =
  '/employees/my_data/employee_electronic_delivery_agreement'; // 従業員が電子交付に同意しているかどうか

// Employee - 従業員
export const EMPLOYEES_LIST = '/employees';
export const EMPLOYEE_NEW = '/employees/new';
export const CREATE_EMPLOYEE_URL = '/employees';
export const EMPLOYEE_SHOW = '/employees/:id';
export const EMPLOYEE_EDIT = '/employees/:id/edit';
export const UPDATE_EMPLOYEE_URL = '/employees/:id';
export const UPDATE_EMPLOYEE_EMAIL = '/employees/:id/update_email';
export const SEARCH_EMPLOYEE_URL = '/employees/search';
export const EMPLOYEE_DELETE = '/employees/:id';
export const EMPLOYEE_DELETE_BATCH = '/employees/delete_batch';
export const CHOOSE_REGISTER_METHOD = '/employees/choose_register_method';
export const INSERT_BATCH_CSV = '/employees/insert_batch_csv';
export const UPDATE_BATCH_CSV = '/employees/update_batch_csv';
export const IMPORT_EMPLOYEE_CSV = '/employees/import_employee_csv';
export const EXPORT_EMPLOYEES_CSV = '/employees/export_employees_csv';
export const TEMPLATE_EMPLOYEES_CSV = '/employees/template_csv';
export const EMPLOYEE_SEARCH_PAYMENT_DATE = '/employees/search_payment_date';
export const IMPORT_OFFICE_STATION_EMPLOYEE = '/employees/import_from_office_station';

// Employee Invitation - 従業員を招待する
export const EMPLOYEE_INVITATIONS_LIST = '/employees/invite_to_dashboards';
export const EMPLOYEE_INVITATIONS_SEARCH = '/employees/invite_to_dashboards/search';
export const EMPLOYEE_INVITATION_CREATE = '/employees/:employee_id/invite_to_dashboards';

// Employee Attendance - 従業員の勤怠情報
export const EMPLOYEE_ATTENDANCE_LIST = '/employees/attendances';
export const NEW_EMPLOYEE_ATTENDANCE_URL = '/employees/attendances/new';
export const CREATE_EMPLOYEE_ATTENDANCE_URL = '/employees/attendances';
export const EMPLOYEE_ATTENDANCE_EDIT = '/employees/attendances/:id/edit';
export const UPDATE_EMPLOYEE_ATTENDANCE_URL = '/employees/attendances/:id';
export const SEARCH_EMPLOYEE_ATTENDANCE_URL = '/employees/attendances/search';
export const EMPLOYEE_ATTENDANCE_DOWNLOAD_CSV_PAGE = '/employees/attendances/download_csv_page';
export const DOWNLOAD_EMPLOYEE_ATTENDANCE_CSV = '/employees/attendances/download_employee_attendance_csv'; // 給与情報ダウンロード
export const IMPORT_EMPLOYEE_ATTENDANCE_CSV = '/employees/attendances/import_employee_attendance_csv';
export const EMPLOYEE_ATTENDANCE_SYNC_WITH_JBC = '/employees/attendances/sync_with_jbc';
export const SYNC_ATTENDANCE_JOB_STATUS = '/employees/attendances/sync_task_status';
export const SET_INVISIBLE_SYNC_JOB_ATTENDANCES = '/employees/attendances/complete_sync_task_notify';
export const IMPORT_JOB_STATUS_EMPLOYEE_ATTENDANCES = '/employees/attendances/import_job_status';
export const SET_INVISIBLE_IMPORT_JOB_EMPLOYEE_ATTENDANCES = '/employees/attendances/set_invisible_import_job';

// Employees Personnel Histories - 従業員の人事異動履歴
export const EMPLOYEES_PERSONNEL_HISTORIES = '/employees/:id/personnel_histories';

// JOB status
export const FETCH_JOB_STATUS = '/clients/fetch_job_status';
export const FETCH_LATEST_JOB_STATUS = '/clients/fetch_latest_job_status';
export const FETCH_PARENT_JOB_STATUS = '/clients/fetch_latest_job_statuses';
export const HIDE_JOB_MESSAGE = '/clients/hide_job_message';

// Employee Pays - 従業員の給与
export const EMPLOYEE_PAY_LIST = '/employees/pays';
export const EMPLOYEE_PAY_EDIT = '/employees/pays/:id/edit';
export const EMPLOYEE_CONFIRM_STATUS = '/employees/pays/confirm_job_status';
export const EMPLOYEE_CONFIRM_SET_CONFIRM_JOB_INVISIBLE = '/employees/pays/set_confirm_job_invisible';
export const UPDATE_EMPLOYEE_PAY_URL = '/employees/pays/:id';
export const SEARCH_EMPLOYEE_PAY_URL = '/employees/pays/search';
export const SEARCH_DETAIL_EMPLOYEE_PAY_URL = '/employees/pays/:id/search_detail';
export const EMPLOYEE_PAY_CONFIRM = '/employees/pays/confirm'; // 給与確定。一個づつ又はIDsの配列
export const EMPLOYEE_PAY_UNCONFIRM = '/employees/pays/unconfirm'; // 給与確定を解除。一個づつ又はIDsの配列
export const EMPLOYEE_PAY_CONFIRM_ONE = '/employees/pays/confirm_one'; // 給与個別確定
export const EMPLOYEE_PAY_UNCONFIRM_ONE = '/employees/pays/unconfirm_one'; // 給与個別確定解除
export const EMPLOYEE_PAY_UNCONFIRM_ALL = '/employees/pays/unconfirm_all'; // 給与個別確定全件解除
export const EMPLOYEE_PAY_BATCHING_CONFIRM = '/employees/pays/condition_confirm'; // 検索結果に依存して確定
export const EMPLOYEE_PAY_ROLLBACK = '/employees/pays/rollback'; // 手入力前に戻す
export const EMPLOYEE_PAY_ALL_ROLLBACK = '/employees/pays/all_rollback'; // 手入力前に戻す
export const EMPLOYEE_PAY_CHANGE_PAYMENT_DATE = '/employees/pays/change_payment_date'; // 支給日を変更する
export const DOWNLOAD_PAYROLL_FB_DATA = '/employees/pays/download_payroll_fb_data'; // 給与振込データ出力
export const DOWNLOAD_INHABITANT_TAX_FB_DATA = '/employees/pays/download_inhabitant_tax_fb_data'; // 住民税振込データ出力
export const SHOW_INHABITANT_TAX_FB_PAGE = '/employees/pays/show_inhabitant_tax_fb_page'; // 住民税一覧表
export const SEARCH_INHABITANT_TAX_DATA = '/employees/pays/search_inhabitant_tax_data'; // 住民税一覧表検索
export const DOWNLOAD_INHABITANT_PDF = '/employees/pays/download_inhabitant_tax_pdf'; // 住民税一覧表PDFダウンロード
export const EMPLOYEE_PAY_LOCK = '/employees/pays/lock'; // 個別給与確定。
export const EMPLOYEE_PAY_UNLOCK = '/employees/pays/unlock'; // 個別給与確定を削除。
export const EMPLOYEE_PAY_DOWNLOAD_CSV_PAGE = '/employees/pays/download_csv_page';
export const DOWNLOAD_EMPLOYEE_PAY_CSV = '/employees/pays/download_employee_pay_csv'; // 給与情報ダウンロード
export const DOWNLOAD_ACCOUNTING_PAY_CSV = '/employees/pays/download_accounting_csv';
export const FETCH_DOWNLOAD_PAY_CSV_JOB_STATUS = '/employees/pays/fetch_download_csv_job_status';
export const FETCH_COMPLETED_DOWNLOAD_PAY_CSV_JOB_STATUS = '/employees/pays/fetch_completed_download_csv_job_status';
export const SET_DOWNLOAD_PAY_CSV_JOB_INVISIBLE = '/employees/pays/set_download_csv_job_invisible';
export const SHOW_PAY_FB_DATA_PAGE_URL = '/employees/pays/show_pay_fb_data_page'; // 給与振込一覧表
export const SEARCH_PAY_FB_DATA = '/employees/pays/search_pay_fb_data'; // 給与振込検索
export const DOWNLOAD_PAY_FB_CSV = '/employees/pays/download_pay_fb_csv'; // 給与振込FB CSV ダウンロード
export const DOWNLOAD_PAY_FB_PDF = '/employees/pays/download_pay_fb_pdf'; // 給与振込FB PDF ダウンロード
export const IMPORT_EMPLOYEE_PAY_CSV = '/employees/pays/import_employee_pay_csv';
export const FETCH_IMPORT_CSV_JOB_STATUS = '/employees/pays/fetch_import_csv_job_status';
export const SET_IMPORT_CSV_JOB_INVISIBLE = '/employees/pays/set_import_csv_job_invisible';
export const PAY_DETAIL_PDF = '/employees/pays/download_detail_pdf'; // 賞与明細PDF出力
export const SHOW_ALLOWANCE_DEDUCTIONS_PAY = '/employees/pays/show_allowance_deductions'; // 支給・控除等一覧表
export const EXPORT_ALLOWANCE_DEDUCTIONS_PAY = '/employees/pays/export_allowance_deduction_csv'; // 支給・控除等一覧表をCSV出力する
export const CHANGE_RELEASE_DATE_EMPLOYEE_PAYS = '/employees/pays/change_release_date'; // 公開日を変更
export const CREATE_EMPLOYEE_PAY_REPORTS = '/clients/employee_pay_reports'; // 明細一括作成
export const BULK_DOWNLOAD_EMPLOYEE_PAY_REPORTS = '/clients/employee_pay_reports/:downloadId/fetch_zip_file'; // 明細一括ダウンロード
export const BULK_PRINT_EMPLOYEE_PAY_REPORTS = '/clients/employee_pay_reports/:downloadId/fetch_pdf_file'; // 明細一括印刷
export const EXPORT_PAY_TO_OFFICE_STATION = '/employees/pays/export_to_office_station'; // 給与をオフィスステーションへ連携

export const EMPLOYEE_BONUS_CONFIRM = '/employees/bonus/:id/confirm';
export const EMPLOYEE_BONUS_UNCONFIRM = '/employees/bonus/:id/unconfirm';
export const EMPLOYEE_BONUS_LIST = '/employees/bonus';
export const EMPLOYEE_BONUS_SEARCH = '/employees/bonus/search';
export const EMPLOYEE_BONUS_NEW = '/employees/bonus/new';
export const EMPLOYEE_BONUS_CREATE = '/employees/bonus';
export const EMPLOYEE_BONUS_EDIT = '/employees/bonus/:id/edit';
export const EMPLOYEE_BONUS_UPDATE = '/employees/bonus/:id';
export const EMPLOYEE_BONUS_REVERT = '/employees/bonus/:id/revert';
export const EMPLOYEE_BONUS_DELETE = '/employees/bonus/:id';
export const EMPLOYEE_BONUS_BATCH_DELETE = '/employees/bonus/batch_destroy';
export const DOWNLOAD_BONUS_FB_DATA = '/employees/bonus/download_bonus_fb_data';
export const EMPLOYEE_BONUS_DOWNLOAD_CSV_PAGE_URL = '/employees/bonus/download_csv_page';
export const DOWNLOAD_BONUS_TEMPLATE_CSV = '/employees/bonus/download_template_csv';
export const DOWNLOAD_ACCOUNTING_BONUS_CSV = '/employees/bonus/download_accounting_csv';
export const IMPORT_EMPLOYEE_BONUS_CSV = '/employees/bonus/import_employee_bonus_csv';
export const SAVE_BONUS_CHECKED_STATE = '/employees/bonus/save_bonus_checked_state';
export const SEARCH_BONUS_FB_DATA = '/employees/bonus/search_bonus_fb_data'; // 賞与振込検索
export const DOWNLOAD_BONUS_FB_CSV = '/employees/bonus/download_bonus_fb_csv'; // 賞与振込FB CSV ダウンロード
export const DOWNLOAD_BONUS_FB_PDF = '/employees/bonus/download_bonus_fb_pdf'; // 賞与振込FB PDF ダウンロード
export const SHOW_BONUS_FB_DATA_PAGE_URL = '/employees/bonus/show_bonus_fb_data_page'; // 賞与振込一覧表
export const BONUS_DETAIL_PDF = '/employees/bonus/download_detail_pdf'; // 賞与明細PDF出力
export const TARGET_EMPLOYEE_BONUS = '/employees/bonus/target_employee_bonus'; // 賞与対象者編集
export const CREATE_EMPLOYEE_BONUS_REPORTS = '/clients/employee_bonus_reports'; // 明細一括作成
export const BULK_DOWNLOAD_EMPLOYEE_BONUS_REPORTS = '/clients/employee_bonus_reports/fetch_zip_file'; // 明細一括ダウンロード
export const BULK_PRINT_EMPLOYEE_BONUS_REPORTS = '/clients/employee_bonus_reports/fetch_pdf_file'; // 明細一括印刷

// Office - 事業所
export const LIST_OFFICES_URL = '/clients/offices';
export const OFFICE_NEW = '/clients/offices/new';
export const CREATE_OFFICE_URL = '/clients/offices';
export const OFFICE_EDIT = '/clients/offices/:id/edit/';
export const UPDATE_OFFICE_URL = '/clients/offices/:id';
export const DELETE_OFFICE_URL = '/clients/offices/:id';
export const OFFICE_SHOW = '/clients/offices/:id';
export const FETCH_REFERENCE_OFFICE_URL = '/clients/offices/fetch_reference_office';

// Client Bonus - 賞与新規作成
export const CLIENT_BONUS_CREATE = '/clients/bonus';
export const CLIENT_BONUS_UPDATE = '/clients/bonus/:id';
export const CLIENT_BONUS_CONFIRM = '/clients/bonus/:id/confirm';
export const CLIENT_BONUS_UNCONFIRM = '/clients/bonus/:id/unconfirm';
export const CLIENT_BONUS_DELETE = '/clients/bonus/:id';
export const COMPLETE_BONUS_PAYMENT_NOTIFICATION_URL = '/clients/bonus/:id/complete_payment_notification';
export const SHOW_ALLOWANCE_DEDUCTIONS_BONUS = '/clients/bonus/show_allowance_deductions'; // 支給・控除等一覧表
export const EXPORT_ALLOWANCE_DEDUCTIONS_BONUS = '/clients/bonus/export_allowance_deduction_csv'; // 支給・控除等一覧表をCSV出力する
export const CHANGE_RELEASE_DATE_CLIENT_BONUS = '/clients/bonus/:id/change_release_date';
export const EXPORT_BONUS_TO_OFFICE_STATION = '/clients/bonus/:id/export_to_office_station'; // 賞与をオフィスステーションへ連携

// Client Bonus Allowances - 賞与支給・控除項目
export const CLIENT_BONUS_ALLOWANCES_LIST = '/clients/bonus_allowances';
export const CLIENT_BONUS_ALLOWANCE_NEW = '/clients/bonus_allowances/new';
export const CLIENT_BONUS_ALLOWANCE_CREATE = '/clients/bonus_allowances';
export const CLIENT_BONUS_ALLOWANCE_EDIT = '/clients/bonus_allowances/:id/edit';
export const CLIENT_BONUS_ALLOWANCE_UPDATE = '/clients/bonus_allowances/:id';
export const CLIENT_BONUS_ALLOWANCE_DELETE = '/clients/bonus_allowances/:id';
export const CLIENT_BONUS_ALLOWANCE_CHANGE_DISPLAY_ORDERS = '/clients/bonus_allowances/change_display_order';
export const CLIENT_BONUS_ALLOWANCE_UPDATE_DISPLAY_ORDERS = '/clients/bonus_allowances/update_display_order';

// Client Payroll Rules Group - 給与規定グループ
export const CLIENT_PAYROLL_RULES_GROUP_LIST = '/clients/payroll_rules_groups';
export const CLIENT_PAYROLL_RULES_GROUP_NEW = '/clients/payroll_rules_groups/new';
export const COPY_CLIENT_PAYROLL_RULES_GROUP_URL = '/clients/payroll_rules_groups/:id/copy';
export const CREATE_CLIENT_PAYROLL_RULES_GROUP_URL = '/clients/payroll_rules_groups';
export const CLIENT_PAYROLL_RULES_GROUP_EDIT = '/clients/payroll_rules_groups/:id/edit';
export const UPDATE_CLIENT_PAYROLL_RULES_GROUP_URL = '/clients/payroll_rules_groups/:id';
export const SETTING_PAYMENT_DATES_CLIENT_PAYROLL_RULES_GROUPS = '/clients/payroll_rules_groups/setting_payment_dates';

// Client Allowances - 支給・控除項目
export const CLIENT_ALLOWANCES_LIST = '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances';
export const CLIENT_ALLOWANCE_NEW = '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances/new';
export const CLIENT_ALLOWANCE_CREATE = '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances';
export const CLIENT_ALLOWANCE_EDIT = '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances/:allowanceId/edit';
export const CLIENT_ALLOWANCE_UPDATE = '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances/:allowanceId';
export const CLIENT_ALLOWANCE_DELETE = '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances/:allowanceId';
export const CLIENT_ALLOWANCE_CHANGE_DISPLAY_ORDERS =
  '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances/change_display_order';
export const CLIENT_ALLOWANCE_UPDATE_DISPLAY_ORDERS =
  '/clients/payroll_rules_groups/:payrollRulesGroupId/allowances/update_display_order';

// Client Unit Prices - 支給・控除の単価設定
export const CLIENT_UNIT_PRICES_LIST = '/clients/payroll_rules_groups/:payrollRulesGroupId/unit_prices';
export const CLIENT_UNIT_PRICE_NEW = '/clients/payroll_rules_groups/:payrollRulesGroupId/unit_prices/new';
export const CLIENT_UNIT_PRICE_CREATE = '/clients/payroll_rules_groups/:payrollRulesGroupId/unit_prices';
export const CLIENT_UNIT_PRICE_EDIT = '/clients/payroll_rules_groups/:payrollRulesGroupId/unit_prices/:id/edit';
export const CLIENT_UNIT_PRICE_UPDATE = '/clients/payroll_rules_groups/:payrollRulesGroupId/unit_prices/:id';
export const CLIENT_UNIT_PRICE_DELETE = '/clients/payroll_rules_groups/:payrollRulesGroupId/unit_prices/:id';

// Client Employment Types - 雇用形態
export const CLIENT_EMPLOYMENT_TYPES = '/clients/employment_types';
export const CREATE_CLIENT_EMPLOYMENT_TYPES_URL = '/clients/employment_types';

// Client Groups - グループ
export const CLIENT_GROUPS = '/clients/groups';
export const CREATE_CLIENT_GROUPS_URL = '/clients/groups';
export const EXPORT_CLIENT_GROUPS_URL = '/clients/groups/export_group_csv';
export const EXPORT_TEMPLATE_CLIENT_GROUPS_URL = '/clients/groups/template_group_csv';
export const INSERT_BATCH_CLIENT_GROUPS_URL = '/clients/groups/insert_batch_group_csv';
export const UPDATE_BATCH_CLIENT_GROUPS_URL = '/clients/groups/update_batch_group_csv';
export const IMPORT_CLIENT_GROUPS_URL = '/clients/groups/import_group_csv';

// Client Positions - 職種
export const CLIENT_POSITIONS = '/clients/positions';
export const CREATE_CLIENT_POSITIONS_URL = '/clients/positions';

// Client Occupations - 役職
export const CLIENT_OCCUPATIONS = '/clients/occupations';
export const CREATE_CLIENT_OCCUPATIONS_URL = '/clients/occupations';

// Client Commuting Fee Settings - 交通費上限
export const CLIENT_COMMUTING_FEE_SETTINGS = '/clients/commuting_fee_settings';
export const CLIENT_COMMUTING_FEE_SETTINGS_CREATE = '/clients/commuting_fee_settings';

// Insurances - 保険全般
export const FETCH_SOCIAL_INSURANCE_RATES = '/insurances/social_insurance_rates';
export const FETCH_ACCIDENT_INSURANCE_RATES = '/insurances/accident_insurance_rates';
export const FETCH_EMPLOYMENT_INSURANCE_RATES = '/insurances/employment_insurance_rates';

// Client Settings - 設定一覧
export const CLIENT_SETTINGS = '/clients/settings';
// Client Setting Using Plan - 利用プラン
export const CLIENT_SETTING_USING_PLAN = '/clients/using_plan';
export const EDIT_CLIENT_SETTING_USING_PLAN = '/clients/using_plan/edit';

// Client Settings - 電子交付同意設定
export const CLIENT_ELECTTONIC_DELIVERY_AGREEMENT_SETTING = '/clients/electronic_delivery_agreement_setting';
export const CLIENT_UPDATE_ELECTTONIC_DELIVERY_AGREEMENT_SETTING =
  '/clients/update_is_electronic_delivery_agreement_setting';

// 勤怠項目設定
export const CLIENT_ALLOWANCE_ITEMS = '/clients/allowance_items';
export const CLIENT_ALLOWANCE_ITEM_NEW = '/clients/allowance_items/new';
export const CLIENT_ALLOWANCE_ITEM_EDIT = '/clients/allowance_items/:allowanceItemId/edit';
export const CLIENT_ALLOWANCE_ITEM_UPDATE = '/clients/allowance_items/:allowanceItemId';
export const CLIENT_ALLOWANCE_ITEM_DELETE = '/clients/allowance_items/:allowanceItemId';
export const CLIENT_ALLOWANCE_ITEM_CHANGE_DISPLAY_ORDERS = '/clients/allowance_items/change_display_order';
export const CLIENT_ALLOWANCE_ITEM_UPDATE_DISPLAY_ORDER = '/clients/allowance_items/update_display_order';

// CSVフォーマット設定
export const CLIENT_CSV_FORMATS_LIST = '/clients/csv_formats';
export const CLIENT_CSV_FORMAT_NEW = '/clients/csv_formats/new';
export const CLIENT_CSV_FORMAT_CREATE = '/clients/csv_formats';
export const CLIENT_CSV_FORMAT_EDIT = '/clients/csv_formats/:id/edit';
export const CLIENT_CSV_FORMAT_UPDATE = '/clients/csv_formats/:id';
export const CLIENT_CSV_FORMAT_COPY = '/clients/csv_formats/:id/copy';
export const CLIENT_CSV_EMPLOYEE_FORMAT_NEW = '/clients/csv_employee_formats/new';
export const CLIENT_CSV_EMPLOYEE_FORMAT_EDIT = '/clients/csv_employee_formats/:id/edit';

// 操作履歴
export const AUDIT_LOGS_LIST = '/clients/audit_logs';
export const AUDIT_LOGS_SEARCH = '/clients/audit_logs/search';
export const AUDIT_LOG_SHOW = '/clients/audit_logs/:id';

// Client Tax Calculation Method Setting - 所得税（源泉徴収税額）の計算方法
export const CLIENT_TAX_CAL_METHOD_SETTING = '/clients/tax_cal_method';
export const CLIENT_TAX_CAL_METHOD_SETTING_UPDATE = '/clients/tax_cal_method';

// client_resident_tax_payments - 住民税納付先市区町村
export const CLIENT_RESIDENT_TAX_PAYMENTS_LIST = '/clients/resident_tax_payments';
export const CLIENT_RESIDENT_TAX_PAYMENTS_UPDATE = '/clients/resident_tax_payments';
export const CLIENT_RESIDENT_TAX_PAYMENTS_INSERT_BATCH_CSV = '/clients/resident_tax_payments/insert_batch_csv';
export const CLIENT_RESIDENT_TAX_PAYMENTS_TEMPLATE_CSV = '/clients/resident_tax_payments/template_csv';
export const CLIENT_RESIDENT_TAX_PAYMENTS_IMPORT_CSV = '/clients/resident_tax_payments/import_csv';

// Memo
export const CREATE_MEMO_URL = '/memos';
export const UPDATE_MEMO_URL = '/memos/:id';
export const DELETE_MEMO = '/memos/:id';
export const SEARCH_MEMO_URL = '/memos/search';

// 書類系
export const REPORT_INDEX = '/reports';
// 健康保険・厚生年金保険 被保険者賞与支払届
export const REPORT_HEALTH_INSURANCE_BONUS_LIST = '/reports/health_insurance_bonus';
export const REPORT_HEALTH_INSURANCE_BONUS_SEARCH = '/reports/health_insurance_bonus/search';
export const REPORT_HEALTH_INSURANCE_BONUS = '/reports/health_insurance_bonus/pdf';
export const REPORT_HEALTH_INSURANCE_BONUS_CSV = '/reports/health_insurance_bonus/csv';
export const REPORT_HEALTH_INSURANCE_BONUS_EGOV = '/reports/health_insurance_bonus/e_gov';
export const REPORT_HEALTH_INSURANCE_BONUS_REPORT_ITEM_UPDATE = '/reports/health_insurance_bonus/update_report_item';
export const REPORT_HEALTH_INSURANCE_BONUS_REPORT_ITEM_RESET = '/reports/health_insurance_bonus/reset_report_item';
// 健康保険・厚生年金保険 被保険者賞与支払届編集
export const REPORT_HEALTH_INSURANCE_BONUS_ITEM_EDIT = '/reports/health_insurance_bonus/:id/item/edit';
export const REPORT_HEALTH_INSURANCE_BONUS_ITEM_UPDATE = '/reports/health_insurance_bonus/item/update';
// 月額変更届
export const REPORT_MONTHLY_REVISIONS = '/reports/monthly_revisions';
export const REPORT_MONTHLY_REVISIONS_PDF = '/reports/employee_standard_monthly_revisions/pdf';
export const REPORT_MONTHLY_REVISIONS_CSV = '/reports/employee_standard_monthly_revisions/csv';
export const REPORT_MONTHLY_REVISION_EGOV = '/reports/employee_standard_monthly_revisions/e_gov';
// 算定基礎届
export const REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS = '/reports/notification_of_base_amount_revisions';
export const CREATE_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF = '/reports/notification_of_base_amount_revision_pdf';
export const RECREATE_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF =
  '/reports/notification_of_base_amount_revision_pdf/:revision_year/recreate';
export const REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF_FETCH_STATUS =
  '/reports/notification_of_base_amount_revision_pdf/:revision_year/fetch_status';
export const REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF_FETCH_ZIP =
  '/reports/notification_of_base_amount_revision_pdf/:revision_year/fetch_zip_file';
export const REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF_FETCH_PDF =
  '/reports/notification_of_base_amount_revision_pdf/:revision_year/fetch_pdf_file';
// 所得税徴収高集計資料
export const INCOME_TAX_COLLECTION_DATA = '/reports/income_tax_collection_data';
export const INCOME_TAX_COLLECTION_DATA_SEARCH = '/reports/income_tax_collection_data/search';
export const INCOME_TAX_COLLECTION_DATA_CSV = '/reports/income_tax_collection_data/csv';

// 年度更新
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS = '/reports/labor_insurance_employee_details/:year';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_SEARCH = '/reports/labor_insurance_employee_details/search';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_DOWNLOAD_CSV_PAGE =
  '/reports/labor_insurance_employee_details/download_csv_page';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_CSV = '/reports/labor_insurance_employee_details/csv';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_IMPORT_LABOR_INSURANCE_CSV =
  '/reports/labor_insurance_employee_details/import_csv';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_IMPORT_ERROR_OF_LABOR_INSURANCE_CSV =
  '/reports/labor_insurance_employee_details/error_csv_of_import';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_EDIT =
  '/reports/labor_insurance_employee_details/:year/:employee_id';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_ROLLBACK =
  '/reports/labor_insurance_employee_details/:year/:employee_id/rollback';
export const REPORT_LABOR_INSURANCE_SUMMARIES = '/reports/labor_insurance_summaries';
export const REPORT_LABOR_INSURANCE_SUMMARIES_SEARCH = '/reports/labor_insurance_summaries/search';
export const REPORT_LABOR_INSURANCE_SUMMARIES_UPDATE_SUMMARIES = '/reports/labor_insurance_summaries/update_summaries';
export const REPORT_LABOR_INSURANCE_SUMMARIES_REAGGREGATE = '/reports/labor_insurance_summaries/reaggregate';
export const REPORT_LABOR_INSURANCE_SUMMARIES_RECALCULATE = '/reports/labor_insurance_summaries/recalculate';
export const REPORT_LABOR_INSURANCE_SUMMARIES_PDF = '/reports/labor_insurance_summaries/pdf';

export const REPORT_LABOR_INSURANCE_DECLARATIONS = '/reports/labor_insurance_declarations';
export const REPORT_LABOR_INSURANCE_DECLARATIONS_SEARCH = '/reports/labor_insurance_declarations/search';
export const REPORT_LABOR_INSURANCE_DECLARATIONS_UPDATE_DECLARATIONS =
  '/reports/labor_insurance_declarations/update_declarations';
export const REPORT_LABOR_INSURANCE_DECLARATIONS_UPDATE_INSURANCE_RATES =
  '/reports/labor_insurance_declarations/update_insurance_rates';
export const REPORT_LABOR_INSURANCE_DECLARATIONS_RECALCULATE = '/reports/labor_insurance_declarations/recalculate';
export const REPORT_LABOR_INSURANCE_DECLARATIONS_PDF = '/reports/labor_insurance_declarations/pdf';

// 健康保険・厚生年金保険 被保険者賞与支払届
export const REPORT_NOTIFICATION_OF_BASIC_AMOUNT_FOR_CALCULATION_OF_SOCIAL_INSURANCES =
  '/reports/notification_of_basic_amount_for_calculation_of_social_insurances/pdf';
// 賃金台帳
export const WAGE_LEDGER = '/reports/wage_ledgers';
export const WAGE_LEDGER_SEARCH = '/reports/wage_ledgers/search';
export const WAGE_LEDGER_CSV = '/reports/wage_ledgers/csv';
export const WAGE_LEDGER_PDF = '/reports/wage_ledgers/pdf';
export const WAGE_LEDGER_PERSONAL_PDF = '/reports/wage_ledgers/personal_pdf';
export const WAGE_LEDGER_CREATE_PDF = '/reports/wage_ledgers/async_create_pdf';
export const WAGE_LEDGER_DOWNLOAD_PDF = '/reports/wage_ledgers/download_pdf';
export const WAGE_LEDGER_PREVIEW_PDF = '/reports/wage_ledgers/preview_pdf';
// 出力項目選択
export const WAGE_LEDGER_EXPORT_ITEM_LIST = '/clients/wage_ledger_export_items';
export const WAGE_LEDGER_EXPORT_ITEM_UPDATE = '/clients/wage_ledger_export_items/update_batch';
// 源泉徴収票
export const REPORT_WITHHOLDING_SLIP_LIST = '/reports/withholding_slips';
export const REPORT_WITHHOLDING_SLIP_SEARCH = '/reports/withholding_slips/search';
export const REPORT_WITHHOLDING_SLIP = '/reports/withholding_slips/pdf';
export const REPORT_WITHHOLDING_SLIP_CONFIRM = '/reports/withholding_slips/confirm';
export const REPORT_WITHHOLDING_SLIP_UNCONFIRM = '/reports/withholding_slips/unconfirm';
export const REPORT_WITHHOLDING_SLIP_FIND = '/reports/withholding_slips/find';

// monthly_revisions - 月変
export const MONTHLY_REVISION_LIST = '/reports/monthly_revisions';
export const MONTHLY_REVISION_SEARCH = '/reports/monthly_revisions/search';
export const MONTHLY_REVISION_RECALCULATE = '/reports/monthly_revisions/:id/recalculate';
export const MONTHLY_REVISION_RESET = '/reports/monthly_revisions/:id/reset';
export const MONTHLY_REVISION_FIX_BEFORE_MONTHLY_INSURANCE_AMOUNTS =
  '/reports/monthly_revisions/fix_before_monthly_insurance_amounts';
export const MONTHLY_REVISION_EDIT = '/reports/monthly_revisions/:id/edit';
export const MONTHLY_REVISION_UPDATE = '/reports/monthly_revisions/:id';
export const MONTHLY_REVISION_CONFIRM = '/reports/monthly_revisions/confirm_revisions';
export const MONTHLY_REVISION_INSERT_BATCH_CSV = '/reports/monthly_revisions/insert_batch_csv';
export const MONTHLY_REVISION_IMPORT = '/reports/monthly_revisions/import';
export const MONTHLY_REVISION_EXPORT = '/reports/monthly_revisions/export';
export const MONTHLY_REVISION_UPDATE_SUBJECT = '/reports/monthly_revisions/:id/update_subject';

// monthly_revisions - 算定基礎
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_LIST = '/reports/notification_of_base_amount_revisions';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_SEARCH = '/reports/notification_of_base_amount_revisions/search';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_RECALCULATE =
  '/reports/notification_of_base_amount_revisions/:id/recalculate';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_RESET = '/reports/notification_of_base_amount_revisions/:id/reset';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_TOGGLE_SUBJECT =
  '/reports/notification_of_base_amount_revisions/:id/toggle_subject';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_UPDATE = '/reports/notification_of_base_amount_revisions/:id';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_CONFIRM =
  '/reports/notification_of_base_amount_revisions/confirm_revisions';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_CONFIRM_CSV =
  '/reports/notification_of_base_amount_revisions/confirm_revisions_csv';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_NOT_CONFIRMED_CSV =
  '/reports/notification_of_base_amount_revisions/not_confirmed_revisions_csv';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_CSV = '/reports/notification_of_base_amount_revisions/csv';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_EGOV = '/reports/notification_of_base_amount_revisions/e_gov';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_INSERT_BATCH_CSV =
  '/reports/notification_of_base_amount_revisions/insert_batch_csv';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_IMPORT = '/reports/notification_of_base_amount_revisions/import';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_EXPORT = '/reports/notification_of_base_amount_revisions/export';

// 2024定額減税
export const FLAT_TAX_REDUCTION_LEDGER_CSV = '/reports/flat_tax_reduction_ledgers/csv';

// 権限
export const CLIENT_ROLES_LIST = '/clients/roles';
export const CLIENT_ROLES_NEW = '/clients/roles/new';
export const CLIENT_ROLES_CREATE = '/clients/roles';
export const CLIENT_ROLES_EDIT = '/clients/roles/:id/edit';
export const CLIENT_ROLES_UPDATE = '/clients/roles/:id';
export const CLIENT_ROLES_DELETE = '/clients/roles/:id';
export const CLIENT_ROLES_COPY = '/clients/roles/:id/copy';
export const CLIENT_ROLE_USERS_LIST = '/clients/role_users';
export const CLIENT_ROLE_USERS_UPDATE = '/clients/role_users';
export const CLIENT_ROLE_USERS_DESTROY_BATCH = '/clients/role_users/destroy_batch';
export const CLIENT_ROLE_USERS_SEARCH = '/clients/role_users/search';
// お知らせ
export const SYSTEM_ANNOUNCE_LIST = '/system_announces';
export const SHOW_SYSTEM_ANNOUNCE = '/system_announces/:id';
export const SEARCH_SYSTEM_ANNOUNCE = '/system_announces/search';
// 管理者からの通知
export const EMPLOYEE_ANNOUNCE_LIST = '/employees/my_data/announces';
export const SEARCH_EMPLOYEE_ANNOUNCE = '/employees/my_data/search_employee_announce';
// Options
export const GET_INIT_OPTIONS = '/options';
export const OPTIONS_CLIENT_OFFICES = '/options?type=offices';
export const OPTIONS_CLIENT_FLATTEN_GROUPS = '/options?type=flatten_groups';
export const OPTIONS_CLIENT_POSITIONS = '/options?type=positions';
export const OPTIONS_CLIENT_EMPLOYMENT_TYPES = '/options?type=employment_types';
export const OPTIONS_CLIENT_OCCUPATIONS = '/options?type=occupations';
export const OPTIONS_CLIENT_PAYROLL_RULE_GROUPS = '/options?type=payroll_rule_groups';
export const OPTIONS_CLIENT_UNIQUE_PAYROLL_RULE_GROUPS = '/options?type=unique_payroll_rule_groups';
export const OPTIONS_CLIENT_GROUPED_PAYMENT_MONTHS = '/options?type=grouped_payment_months';
export const OPTIONS_CLIENT_UNIQUE_PAYMENT_DATE = '/options?type=unique_payment_dates';
export const OPTIONS_CLIENT_BONUSES = '/options?type=bonuses';
export const OPTIONS_CLIENT_REVISION_MONTHS = '/options?type=revision_months';
export const OPTIONS_APPLICABLE_OFFICES_OF_MONTHLY_REVISION = '/options?type=applicable_offices_of_monthly_revision';
export const OPTIONS_CLIENT_NOTIFICATION_OF_BASE_AMOUNT_MONTHS = '/options?type=notification_of_base_amount_months';
export const OPTIONS_APPLICABLE_OFFICES_OF_NOTIFICATION_OF_BASE_AMOUNT =
  '/options?type=applicable_offices_of_notification_of_base_amount';
export const OPTIONS_CLIENT_WAGE_LEDGER_YEARS = '/options?type=wage_ledger_years';
export const OPTIONS_APPLICABLE_OFFICES_OF_LABOR_INSURANCE = '/options?type=applicable_offices_of_labor_insurance';
export const OPTIONS_APPLICABLE_OFFICES_OF_HEALTH_INSURANCE_BONUS =
  '/options?type=applicable_offices_of_health_insurance_bonus';

// アラート
export const CREATE_COMPLETED_PROCEDURE_URL = '/clients/completed_procedures';
// 通知
export const ALERTS_LIST = '/memos/alerts';
export const SEARCH_TODAY_ALERTS = '/memos/search_today_alerts';
// 連携
export const CONFIRM_ROUMU_COORDINATION_COLUMN = '/coordinations/confirm_roumu_column'; // 労務と連携項目確認
export const COORDINATION_SERVICE_LIST = '/coordinations'; // 連携サービス一覧
export const COORDINATION_JBC_SETTINGS = '/coordinations/sync_jbc_settings'; // Jobcan勤怠と連携の設定
export const COORDINATION_JBC_SETTINGS_UPDATE = '/coordinations/sync_jbc_settings'; // Jobcan勤怠と連携の設定
export const COORDINATION_LMS_SETTINGS = '/coordinations/sync_lms_settings'; // Jobcan労務HRと連携の設定
export const SETTING_EMPLOYEES_SYNC_ATTENDANCE_SHOW = '/coordinations/setting_employees_sync_attendance'; // 従業員の勤怠連携の有り無しの設定
export const SETTING_EMPLOYEES_SYNC_ATTENDANCE_UPDATE = '/coordinations/setting_employees_sync_attendance'; // 従業員の勤怠連携の有り無しの設定
export const CONFIRM_CLIENT_COORDINATION_COLUMN = '/coordinations/confirm_client_column';
export const COORDINATIONS_SETTING_CLIENT_DEPARTMENT = '/coordinations/setting_client_department';
export const EXTERNAL_COORDINATION_SERVICE_LIST = '/external_coordinations';
export const EXTERNAL_COORDINATION_OFFICE_STATION_SETTING = '/external_coordinations/setting_office_station';
export const EXTERNAL_COORDINATION_DELETE_OFFICE_STATION_SETTING =
  '/external_coordinations/delete_office_station_setting';

export const CONFIRM_NOTIFY_JOB = '/clients/confirm_notify_job';
export const EMPLOYEE_CSV_JOB_STATUS = '/clients/csv_job_status';
export const CLIENT_ANNOUNCES = '/clients/announces';
export const CLIENT_ANNOUNCES_SEARCH = '/clients/announces/search';

export const CHOOSE_COORDINATION_EMPLOYEE = '/employees/choose_coordination';
export const CHOOSE_COORDINATION_EMPLOYEE_YEA = '/employees/choose_coordination_yea';
export const JBC_LMS_CLIENT_IMPORT = '/jbc/lms/client/import';
export const JBC_LMS_EMPLOYEES_IMPORT = '/jbc/lms/employees/import';
export const JBC_LMS_EMPLOYEES_TARGETS = '/jbc/lms/employees/targets';
export const JBC_YEA_EMPLOYEES_TARGETS = '/jbc/yea/employees/targets';

export const EMAIL_NOTIFICATION_SETTING = '/clients/admin_email_notification_setting';
export const SEARCH_EMAIL_NOTIFICATION_SETTING = '/clients/admin_email_notification_setting/search';
export const UPDATE_EMAIL_NOTIFICATION_SETTING = '/clients/admin_email_notification_setting';
export const LMS_EMPLOYEE_IMPORT_JOB = '/clients/lms_employee_import_job';
export const LMS_EMPLOYEE_IMPORT_JOB_CONFIRM = '/clients/lms_employee_import_job/confirm';

export const ALLOWED_IP_SETTING = '/clients/allowed_ips';

export const YEAR_END_ADJ_START = '/year_end_adj/start';
export const YEAR_END_ADJ_MAIN_OFFICE = '/year_end_adj/main_office';

// Flat Tax Reductions - 定額減税
export const FLAT_TAX_REDUCTION_EMPLOYEE_LIST = '/flat_tax_reductions/employees';
export const SEARCH_FLAT_TAX_REDUCTION_EMPLOYEE_URL = '/flat_tax_reductions/employees/search';
export const UPDATE_FLAT_TAX_REDUCTION_EMPLOYEE_URL = '/flat_tax_reductions/employees/:id';
export const FLAT_TAX_REDUCTION_AMOUNT_LIST = '/flat_tax_reductions/amounts';
export const SEARCH_FLAT_TAX_REDUCTION_AMOUNT_URL = '/flat_tax_reductions/amounts/search';
export const UPDATE_FLAT_TAX_REDUCTION_AMOUNT_URL = '/flat_tax_reductions/amounts/:id';
export const FLAT_TAX_REDUCTION_CONFIRMATION_LIST = '/flat_tax_reductions/confirmations';
export const SEARCH_FLAT_TAX_REDUCTION_CONFIRMATION_URL = '/flat_tax_reductions/confirmations/search';
export const CONFIRM_FLAT_TAX_REDUCTION_URL = '/flat_tax_reductions/confirmations/confirm';
export const UNCONFIRM_FLAT_TAX_REDUCTION_URL = '/flat_tax_reductions/confirmations/unconfirm';
export const DOWNLOAD_EMPLOYEE_FLAT_TAX_REDUCTION_CSV = '/flat_tax_reductions/download_employee_csv';
export const DOWNLOAD_AMOUNT_FLAT_TAX_REDUCTION_CSV = '/flat_tax_reductions/download_amount_csv';
export const DOWNLOAD_CONFIRMED_FLAT_TAX_REDUCTION_CSV = '/flat_tax_reductions/download_confirmed_csv';
export const FLAT_TAX_REDUCTION_UPLOAD_CSV = '/flat_tax_reductions/upload_csv';
export const IMPORT_FLAT_TAX_REDUCTION_CSV_URL = '/flat_tax_reductions/upload_employee_csv';
export const IMPORT_FLAT_TAX_REDUCTION_CONFIRM_CSV_URL = '/flat_tax_reductions/upload_confirm_csv';

// マイナンバー
export const MY_NUMBER_LIST = '/my_numbers';
export const SEARCH_MY_NUMBER_URL = '/my_numbers/search';
export const INSERT_BATCH_MY_NUMBER_CSV = '/my_numbers/insert_batch_csv';
export const TEMPLATE_MY_NUMBER_CSV = '/my_numbers/template_csv';
export const IMPORT_MY_NUMBER_CSV = '/my_numbers/import_csv';
export const SHOW_DETAIL = '/my_numbers/show_detail';
export const FETCH_MY_NUMBER = '/my_numbers/fetch_my_number';
export const MY_NUMBER_JOB_STATUS = '/my_numbers/csv_job_status';
export const CONFIRM_MY_NUMBER_NOTIFY_JOB = '/my_numbers/confirm_notify_job';
