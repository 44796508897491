import React from 'react';
import get from 'lodash/get';
import take from 'lodash/take';
import ListGroup from 'jbc-front/components/ListGroup';
import { recordDisplay } from 'src/utils/Utils';
import styles from './SystemAnnounces.scss';

const MAX_DISPLAY = 5;

export const showDateNewLine = date => date.replace(/年/, '年\n');

const EmployeeAnnounces = ({ employeeAnnounces }) => (
  <div className="u-mt30">
    <ListGroup>
      <ListGroup.Title>管理者からの通知</ListGroup.Title>
      {get(employeeAnnounces, 'length') > 0 ? (
        take(employeeAnnounces, MAX_DISPLAY).map(employeeAnnounce => (
          <ListGroup.Item
            className={styles.list}
            key={employeeAnnounce.id}
            {...{ href: `${employeeAnnounce.link}`, target: '_blank', as: 'a' }}
          >
            <span className={styles.date}>
              {showDateNewLine(recordDisplay.dateOrTime(employeeAnnounce.announceTime))}
            </span>
            <div className={styles.title}>{employeeAnnounce.body}</div>
            <ListGroup.Icon />
          </ListGroup.Item>
        ))
      ) : (
        <ListGroup.Item className={styles.empty}>お知らせはまだありません</ListGroup.Item>
      )}
    </ListGroup>
    {get(employeeAnnounces, 'length') > MAX_DISPLAY && (
      <div className={styles.more}>
        <a href="/employees/my_data/announces" className={styles.more}>
          すべて見る
        </a>
      </div>
    )}
  </div>
);

export default EmployeeAnnounces;
