import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import Delete from 'jbc-front/components/icons/Delete';

const DeleteActionBtn = ({ onClick, label = '削除', ...rest }) => (
  <ActionButton as="button" icon={<Delete />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default DeleteActionBtn;
