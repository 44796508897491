import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import Cookies from 'js-cookie';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import SearchDetector from 'src/components/SearchDetector';
import { concatParamsToUrl } from 'src/utils/Http';
import { FormErrors } from 'src/components';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getList, getExtras, getIsLoading } from 'src/reducers/common/listReducer';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import {
  REDUCER_NAME,
  fetchReportHealthInsuranceBonus,
  downloadCsv
} from 'src/reducers/reports/reportHealthInsuranceBonus';
import {
  REPORT_HEALTH_INSURANCE_BONUS,
  REPORT_INDEX,
  REPORT_HEALTH_INSURANCE_BONUS_ITEM_EDIT
} from 'src/constants/EndpointUrls';
import ActionButton from 'jbc-front/components/ActionButton';
import Button from 'jbc-front/components/Button';
import { Task } from 'jbc-front/components/icons';
import compose from 'lodash/fp/compose';
import { reduxForm, getFormValues, change as changeForm } from 'redux-form';
import {
  FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH,
  isLoading,
  ITEM_NAME_FETCH_REPORT_HEALTH_INSURANCE_BONUS_OFFICE_OPTIONS,
  takeReportHealthInsuranceBonusQueriesFromLocation,
  takeReportHealthInsuranceBonusSearchQueries
} from 'src/reducers/searchForm';
import { HEALTH_INSURANCE_BONUS_TARGET_TYPE } from 'src/reducers/egov';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import EgovModalContainer from 'src/containers/EgovModalContainer';
import EgovBaloon from 'src/components/Egov/EgovBaloon';
import LoadingView from 'src/components/LoadingView';
import CsvDownloadModalForm from 'src/components/Modal/Report/SocialInsurance/CsvDownloadModalForm';
import ReportHealthInsuranceBonusTable from './components/ReportHealthInsuranceBonusTable';
import PdfModalContainer from './containers/Modal/PdfModalContainer';
import SelectBoxGroups from './containers/SelectBoxGroupsContainer';
import styles from './ReportHealthInsuranceBonusList.scss';

class ReportHealthInsuranceBonusList extends React.Component {
  constructor(props) {
    super(props);

    this.openCsvModal = this.openCsvModal.bind(this);
    this.closeCsvModal = this.closeCsvModal.bind(this);
    this.openEgovModal = this.openEgovModal.bind(this);
    this.closeEgovModal = this.closeEgovModal.bind(this);
  }

  state = {
    isPdfModalOpen: false,
    isPdfPreview: false,
    isCsvModalOpen: false,
    isEgovBaloonOpen: false,
    isEgovModalOpen: false,
    isWithLms: Cookies.get('using_jbc_lms') === 'true'
  };

  componentDidMount() {
    const { location, bonusNames, applicableOffices, dispatch } = this.props;
    const queries = takeReportHealthInsuranceBonusQueriesFromLocation(location);
    const {
      bonusId = _.get(bonusNames, [0, 'value']),
      applicableOffice = _.get(applicableOffices, [0, 'value'])
    } = queries;
    dispatch(changeForm(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH, 'bonusId', bonusId));
    dispatch(changeForm(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH, 'applicableOffice', applicableOffice));
    dispatch(changeForm(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH, 'limit', 100));
    dispatch(changeForm(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH, 'page', 1));
  }

  openEgovModal = () => {
    if (this.state.isEgovModalOpen) return;
    this.setState({ ...this.state, isEgovModalOpen: true });
  };
  closeEgovModal = () => {
    if (!this.state.isEgovModalOpen) return;
    this.setState({ ...this.state, isEgovModalOpen: false });
  };
  openEgovBaloon = () => {
    if (this.state.isEgovBaloonOpen) return;
    this.setState({ ...this.state, isEgovBaloonOpen: true });
  };
  closeEgovBaloon = () => {
    if (!this.state.isEgovBaloonOpen) return;
    this.setState({ ...this.state, isEgovBaloonOpen: false });
  };
  openPdfModal = () => {
    if (this.state.isPdfModalOpen) return;
    this.setState({ ...this.state, isPdfModalOpen: true });
  };
  closePdfModal = () => {
    if (!this.state.isPdfModalOpen) return;
    this.setState({ ...this.state, isPdfModalOpen: false });
  };
  showPreview = (bonusId, applicableOffice, submitTo, orderBy) => {
    this.setState({
      ...this.state,
      isPdfModalOpen: false,
      isPdfPreview: true,
      pdfUrl: `${REPORT_HEALTH_INSURANCE_BONUS}?pdf[bonus_id]=${bonusId}&pdf[applicable_office]=${applicableOffice}&pdf[type]=combine_pdf&pdf[submit_to]=${submitTo}&pdf[order_by]=${orderBy}`
    });
  };
  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };
  openCsvModal = () => {
    if (this.state.isCsvModalOpen) return;
    this.setState({ ...this.state, isCsvModalOpen: true });
  };
  closeCsvModal = () => {
    if (!this.state.isCsvModalOpen) return;
    this.setState({ ...this.state, isCsvModalOpen: false });
  };
  healthInsuranceType = applicableOffice => {
    const data = this.props.applicableOffices.find(h => h.value === applicableOffice);
    return data.insuranceType;
  };

  render() {
    const {
      location,
      employeeBonus,
      errors,
      formValues,
      bonusNames,
      applicableOffices,
      isOfficeOptionLoading,
      isListLoading,
      dispatch
    } = this.props;
    const bonusId = _.get(formValues, 'bonusId');
    const applicableOffice = _.get(formValues, 'applicableOffice');

    return (
      <div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
              <BreadCrumb.Item label="賞与支払届" />
            </BreadCrumb>
          </div>
          <h1 className="m-title-main">賞与支払届</h1>
          <p className={styles.notes}>
            賞与の社会保険料納付のために必要な書類です。提出した賞与支払届を基に標準賞与額が決まり、保険料額が通知されます。賞与支給日から5日以内に年金事務所に提出してください。
            <br />
            ※協会けんぽ以外の組合に加入している場合は、健康保険組合への提出も必要です。
          </p>
          <div className={styles.search}>
            <SelectBoxGroups
              bonusNames={bonusNames}
              applicableOffices={applicableOffices}
              isOfficeOptionLoading={isOfficeOptionLoading}
            />
          </div>
          <SearchDetector
            bonusId={bonusId}
            applicableOffice={applicableOffice}
            location={location}
            willReceivePropsDetectingJudger={(prevProps, nextProps) =>
              nextProps.bonusId !== undefined &&
              nextProps.applicableOffice !== undefined &&
              (prevProps.bonusId === undefined ||
                prevProps.applicableOffice === undefined ||
                nextProps.location !== prevProps.location)
            }
            onDetect={() => {
              dispatch(fetchReportHealthInsuranceBonus(takeReportHealthInsuranceBonusSearchQueries(formValues)));
            }}
          />
          {!_.isEmpty(errors) && (
            <div>
              <div className={styles.error}>
                <FormErrors errors={errors} />
              </div>
            </div>
          )}
          <div className={styles.head}>
            <div className={styles.action}>
              <ActionButton
                primary
                icon={<Task size={16} />}
                onClick={this.openPdfModal}
                disabled={_.isEmpty(employeeBonus) || isOfficeOptionLoading || isListLoading}
                className={styles.pdfExportButton}
              >
                PDF出力
              </ActionButton>
              <div className={styles.wrap}>
                <Button
                  className={styles.eGovButton}
                  primary
                  widthWide
                  disabled={false}
                  onClick={this.openEgovBaloon}
                >
                  電子・電子媒体申請
                </Button>
                {this.state.isEgovBaloonOpen && (
                  <EgovBaloon
                    hide={this.closeEgovBaloon}
                    openCsvModal={this.openCsvModal}
                    isLmsActive={this.state.isWithLms}
                    openEgovModal={this.openEgovModal}
                  />
                )}
              </div>
              <PreviewPdfModalContainer
                header="被保険者賞与支払届"
                isOpen={this.state.isPdfPreview}
                hideModal={this.hidePreview}
                url={this.state.pdfUrl}
                editUrl={concatParamsToUrl(REPORT_HEALTH_INSURANCE_BONUS_ITEM_EDIT, {
                  id: bonusId
                })}
              />
              {this.state.isPdfModalOpen && (
                <PdfModalContainer
                  isOpen={this.state.isPdfModalOpen}
                  bonusId={bonusId}
                  applicableOffice={applicableOffice}
                  insuranceType={this.healthInsuranceType(applicableOffice)}
                  showPreview={this.showPreview}
                  hideModal={this.closePdfModal}
                />
              )}
              {this.state.isCsvModalOpen && (
                <CsvDownloadModalForm
                  isOpen={this.state.isCsvModalOpen}
                  searchKey={{ bonusId, applicableOffice }}
                  insuranceType={this.healthInsuranceType(applicableOffice)}
                  hideModal={this.closeCsvModal}
                  modalTitle={'賞与支払届CSVダウンロード'}
                  downloadCsv={downloadCsv}
                  withSubmitAt={false}
                  withEmployeeSearch={false}
                />
              )}
              {this.state.isEgovModalOpen && (
                <EgovModalContainer
                  isOpen={this.state.isEgovModalOpen}
                  hideModal={this.closeEgovModal}
                  postParams={{ bonusId, applicableOffice }}
                  type={HEALTH_INSURANCE_BONUS_TARGET_TYPE}
                  withEmployeeSearch={false}
                />
              )}
            </div>
          </div>
          <LoadingView isLoading={isListLoading}>
            <div className={styles.reportHealthInsuranceBonusTable}>
              <ReportHealthInsuranceBonusTable employeeBonus={employeeBonus} />
            </div>
            <SearchFormPaginator
              formName={FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH}
              reducerName={REDUCER_NAME}
              isLoading={isListLoading}
            />
          </LoadingView>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: state.router.location,
  extras: getExtras(REDUCER_NAME, state),
  bonusNames: getSelectOptions(state, 'bonusNames'),
  applicableOffices: getSelectOptions(state, 'applicableOffices'),
  employeeBonus: getList(REDUCER_NAME, state),
  errors: getItemErrors(REDUCER_NAME, state),
  formValues: getFormValues(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH)(state),
  isOfficeOptionLoading: isLoading(ITEM_NAME_FETCH_REPORT_HEALTH_INSURANCE_BONUS_OFFICE_OPTIONS, state),
  isListLoading: getIsLoading(REDUCER_NAME, state)
});
export default compose(connect(mapStateToProps), reduxForm({ form: FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH }))(
  ReportHealthInsuranceBonusList
);
