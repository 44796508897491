import React from 'react';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';

import styles from 'src/components/Egov/EgovModal.scss';

const BusinessNameConfirmationModal = ({
  handleSubmit,
  submitting,
  pristine,
  hideModal,
  isOpen = false,
  isCsv = false
}) => (
  <CommonModal className={styles.modal} ariaHideApp={false} {...{ isOpen, hideModal }}>
    <CommonModal.Header hideModal={hideModal}>{isCsv ? 'アップロードの確認' : '更新の確認'}</CommonModal.Header>
    <form onSubmit={handleSubmit} className={styles.modalForm}>
      <CommonModal.Body>
        <React.Fragment>
          {isCsv ? (
            <p className={styles.cautionText}>
              ビジネスネームを含むCSVファイルをアップロードした場合、ご利用いただいているジョブカンの
              他サービスの従業員情報＞姓名に該当する項目がアップロードしたビジネスネームの姓名に書き換わります。
              <br />
              結果として、法令帳票等においても姓名ではなくビジネスネームが表示される可能性があります。
              <br />
              （但しジョブカン労務HR上でビジネスネームが設定済みの従業員に関しては、ビジネスネーム姓名に対して
              自動反映され、法定帳票等には姓名が表示されます）
              <br />
              ビジネスネームを含むCSVファイルをアップロードする場合は上記事項をご承知おきください。
            </p>
          ) : (
            <p className={styles.cautionText}>
              ビジネスネームの項目を保存します。保存した場合は、ご利用いただいているジョブカンの
              他サービスの従業員情報＞姓名に該当する項目にビジネスネームの情報が自動反映されます。
              <br />
              結果として、法令帳票等においても姓名ではなくビジネスネームが表示される可能性があります。
              <br />
              （但し、ジョブカン労務HR上でビジネスネームが設定済みの従業員に関しては、ビジネスネーム姓名に
              対して自動反映され、法定帳票等には姓名が表示されます）
              <br />
              保存してよろしいですか？
            </p>
          )}
          <div className={styles.buttonBox}>
            <Button onClick={hideModal} className="u-mr20">
              キャンセル
            </Button>
            <Button as="button" primary type="submit" disabled={submitting || pristine}>
              {isCsv ? 'アップロード' : '保存'}
            </Button>
          </div>
        </React.Fragment>
      </CommonModal.Body>
    </form>
  </CommonModal>
);

export default BusinessNameConfirmationModal;
