import React from 'react';

import CommonModal from 'jbc-front/components/CommonModal';
import styles from './EmployeePopUp.scss';

const PaySetupDescriptionModal = ({ isOpen, hideModal }) => {
  const header = '計算に必要な従業員情報項目';
  return (
    <CommonModal hideModal={hideModal} isOpen={isOpen} ariaHideApp={false}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <div>
          <dl className={styles.information}>
            <dt>基本情報</dt>
            <dd>・ 姓（必須）</dd>
            <dd>・ 名（必須）</dd>
            <dd>・ 生年月日</dd>
          </dl>

          <dl className={styles.information}>
            <dt>業務情報</dt>
            <dd>・ 入社日（必須）</dd>
          </dl>

          <dl className={styles.information}>
            <dt>給与</dt>
            <dd>・ 給与規定グループ（必須）</dd>
            <dd>・ 基本給</dd>
            <dd>・ 単価</dd>
            <dd>・ 標準報酬月額</dd>
          </dl>

          <dl className={styles.information}>
            <dt>通勤手当</dt>
            <dd>・ 支給単位</dd>
            <dd>・ 定期代</dd>
            <dd>・ 支給額</dd>
          </dl>

          <dl className={styles.information}>
            <dt>扶養情報 ※被扶養者がいる場合のみ</dt>
            <dd>・ 姓（必須）</dd>
            <dd>・ 名（必須）</dd>
            <dd>・ 続柄（必須）</dd>
            <dd>・ 生年月日（必須）</dd>
          </dl>

          <dl className={styles.information}>
            <dt>住民税</dt>
            <dd>・ 住民税金額</dd>
          </dl>

          <dl className={styles.information}>
            <dt>社会保険</dt>
            <dd>・ 健康保険の加入状況と資格取得日</dd>
            <dd>・ 厚生年金保険の加入状況と資格取得日</dd>
            <dd>・ 雇用保険の加入状況と資格取得日</dd>
          </dl>
        </div>
      </CommonModal.Body>
    </CommonModal>
  );
};

class EmployeePopUp extends React.Component {
  state = {
    isModalOpen: false
  };

  openModal = e => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ ...this.state, isModalOpen: false });
  };
  render() {
    return (
      <React.Fragment>
        <span onClick={this.openModal} role="presentation" className={styles.link}>
          こちら
        </span>
        <PaySetupDescriptionModal isOpen={this.state.isModalOpen} hideModal={this.closeModal} />
      </React.Fragment>
    );
  }
}

export default EmployeePopUp;
