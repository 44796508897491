import { BoxDouble, RadioField, TextField } from 'jbc-front/components/Form';
import React from 'react';
import { emptyIntegerFormat } from 'src/utils/Completion';
import _ from 'lodash';

const SETTING_TYPE = { AUTO: 'false', MANUAL: 'true' };

const INSURANCE_SETTING_OPTION = [
  { label: '保険料額表を使用する', value: SETTING_TYPE.AUTO },
  { label: '手入力で設定する', value: SETTING_TYPE.MANUAL }
];

const InsuranceSetting = ({ title, attr, standardMonthly, onManualInputChange, onAmountBlur }) => (
  <div>
    <h4>{title}</h4>
    <RadioField name={attr.manualInput} options={INSURANCE_SETTING_OPTION} onChange={onManualInputChange(attr)} />
    {_.get(standardMonthly, attr.manualInput) === SETTING_TYPE.MANUAL && (
      <div>
        <BoxDouble>
          <TextField
            name={attr.employeeAmount}
            label="従業員負担額"
            yen
            maxLength="9"
            onBlur={onAmountBlur(attr.employeeAmount)}
          />
          <TextField
            name={attr.employerAmount}
            label="事業主負担額"
            yen
            maxLength="9"
            onBlur={onAmountBlur(attr.employerAmount)}
          />
        </BoxDouble>
      </div>
    )}
  </div>
);

const ManualInsuranceSettingSection = ({
  settingStartDate,
  fields,
  syncFields,
  fillAllStandardAfterCurrentSettingTime,
  item,
  index,
  autofill,
  focus
}) => {
  const standardMonthly = fields.get(index);
  const fillAllStandardAfterCurrent = fillAllStandardAfterCurrentSettingTime(fields, autofill, settingStartDate);
  const onManualInputChange = attr => (e, newValue) => {
    autofill(`${item}.inputType`, 'selecting');
    standardMonthly[attr.manualInput] = newValue;
    if (newValue === SETTING_TYPE.AUTO) {
      standardMonthly[attr.employeeAmount] = null;
      standardMonthly[attr.employerAmount] = null;
    } else {
      standardMonthly[attr.employeeAmount] = 0;
      standardMonthly[attr.employerAmount] = 0;
    }
    fillAllStandardAfterCurrent(_.pick(standardMonthly, syncFields));
    focus(`${item}.${attr}.${newValue}`); // 差分検出させる
  };
  const onAmountBlur = attrName => (e, newValue) => {
    e.preventDefault();
    const formattedValue = emptyIntegerFormat(newValue);
    autofill(`${item}.inputType`, 'selecting');
    standardMonthly[attrName] = formattedValue;
    fillAllStandardAfterCurrent(_.pick(standardMonthly, syncFields));
  };

  return (
    <div>
      <InsuranceSetting
        title="健康保険料"
        attr={{
          manualInput: 'isHealthInsuranceManualInput',
          employeeAmount: 'healthInsuranceEmployeeAmount',
          employerAmount: 'healthInsuranceEmployerAmount'
        }}
        standardMonthly={standardMonthly}
        onManualInputChange={onManualInputChange}
        onAmountBlur={onAmountBlur}
      />
      <InsuranceSetting
        title="介護保険料"
        attr={{
          manualInput: 'isNursingInsuranceManualInput',
          employeeAmount: 'nursingInsuranceEmployeeAmount',
          employerAmount: 'nursingInsuranceEmployerAmount'
        }}
        standardMonthly={standardMonthly}
        onManualInputChange={onManualInputChange}
        onAmountBlur={onAmountBlur}
      />
      <InsuranceSetting
        title="厚生年金保険料"
        attr={{
          manualInput: 'isPensionInsuranceManualInput',
          employeeAmount: 'pensionInsuranceEmployeeAmount',
          employerAmount: 'pensionInsuranceEmployerAmount'
        }}
        standardMonthly={standardMonthly}
        onManualInputChange={onManualInputChange}
        onAmountBlur={onAmountBlur}
      />
    </div>
  );
};

export default ManualInsuranceSettingSection;
