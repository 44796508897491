import React from 'react';

import { numberWithComma } from 'src/utils/Utils';
import styles from './PayrollTableData.scss';
import Row from './Row';

const getAllowanceItems = payroll => payroll.employeePayAllowances.filter(item => item.allowanceType === 'payment');

const PayrollAllowanceDetail = ({ payroll }) => (
  <div className={styles.paymentClm}>
    {payroll && (
      <table className="m-table-obvious">
        <thead>
          <tr>
            <th colSpan="2">支給</th>
          </tr>
        </thead>
        <tbody>
          <Row label="支給合計" value={numberWithComma(payroll.paymentAmount)} />
          {getAllowanceItems(payroll).map(allowanceItem => (
            <Row key={allowanceItem.id} label={allowanceItem.name} value={numberWithComma(allowanceItem.value)} />
          ))}
        </tbody>
      </table>
    )}
  </div>
);

export default PayrollAllowanceDetail;
