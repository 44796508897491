import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { SEARCH_FLAT_TAX_REDUCTION_CONFIRMATION_URL } from 'src/constants/EndpointUrls';
import classNames from 'classnames';
import _ from 'lodash';
import { reduxForm, getFormValues } from 'redux-form';

import Button from 'jbc-front/components/Button';

import { ErrorsSection } from 'src/components';
import DetailSearchForm from 'src/components/DetailSearchForm';
import LoadingView from 'src/components/LoadingView';
import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import EnrollmentStatusBtnList from 'src/features/Employees/components/EnrollmentStatusBtnList';
import { getIsLoading, getList, getErrors } from 'src/reducers/common/listReducer';
import { CONFIRMED_MODE } from 'src/reducers/flat_tax_reductions/csv';
import { REDUCER_NAME, fetchEmployees, getCheckedEmployees } from 'src/reducers/flat_tax_reductions/employees';
import {
  takeEmployeesSearchQueriesWithIsConfirmed,
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesResetFormQueries,
  FORM_NAME_FLAT_TAX_REDUCTION_SEARCH
} from 'src/reducers/searchForm';

import styles from './ConfirmationList.scss';
import ConfirmationModal from './components/ConfirmationModal';
import ConfirmationTable from './components/ConfirmationTable';
import { DownloadFlatTaxReductionCsvButton } from './components/DownloadFlatTaxReductionCsvButton';
import { UploadFlatTaxReductionCsvButton } from './components/UploadFlatTaxCsvReductionButton';

const EmployeeList = ({ initialValues: formValues, location }) => {
  const errors = useSelector(state => getErrors(REDUCER_NAME, state)) || [{}];
  const resetValues = useSelector(state => takeEmployeesResetFormQueries(state, true));
  const isListLoading = useSelector(state => getIsLoading(REDUCER_NAME, state));
  const employees = useSelector(state => getList(REDUCER_NAME, state));
  const checkedEmployees = useSelector(state => getCheckedEmployees(state)) || [];
  const overReduceLimitEmployees = checkedEmployees.filter(employee => employee.isOverLimitReduceAmount);
  const isAllNotConfirmEmployee =
    !_.isEmpty(checkedEmployees) && checkedEmployees.every(employee => !employee.isConfirmed);
  const isAllConfirmedEmployee =
    !_.isEmpty(checkedEmployees) &&
    checkedEmployees.every(employee => employee.isConfirmed && !employee.hasConfirmedPaymentOrBonusSince);
  const isMixedConfirmationStatus =
    checkedEmployees.some(employee => employee.isConfirmed) && checkedEmployees.some(employee => !employee.isConfirmed);
  const mode = () => {
    if (isAllNotConfirmEmployee) {
      return 'confirm';
    } else if (isAllConfirmedEmployee) {
      return 'unconfirm';
    }

    return null;
  };
  const isDisplayConfirmationButton = isAllNotConfirmEmployee || isAllConfirmedEmployee;
  const dispatch = useDispatch();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const employeeSearchQueries = takeEmployeesSearchQueriesWithIsConfirmed(formValues);

  return (
    <div>
      <SearchDetector
        location={location}
        didMountDetectingJudger={() => true}
        willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
        onDetect={() => {
          dispatch(fetchEmployees(employeeSearchQueries, null, SEARCH_FLAT_TAX_REDUCTION_CONFIRMATION_URL));
        }}
      />
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">確定処理一覧</h1>
      </div>
      <p className={styles.notes}>
        2024年定額減税のための給与・賞与から控除する金額（減税額残）を確定します。
        <br />
        ※この一覧は定額減税対象人数を保存した従業員が表示されます。
        <br />
        ※2024年6月1日以降に支給日がある給与・賞与に1つでも確定済のものがあるときは確定解除できません。
        <br />
        <br />
        「確定」もしくは「確定解除」ができる条件は
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href="https://jobcan-payroll.zendesk.com/hc/ja/articles/31222172215065"
        >
          こちら
        </a>
        をご参照ください
      </p>
      <ErrorsSection errors={errors} />
      <div className="l-contents-wrap">
        <div className="l-wrap-xl">
          <div className={styles.buttonArea}>
            <div className={styles.buttonDl}>
              <DownloadFlatTaxReductionCsvButton
                text="確定状況ダウンロード"
                mode={CONFIRMED_MODE}
                query={employeeSearchQueries}
              />
              <UploadFlatTaxReductionCsvButton text="確定状況インポート" mode={CONFIRMED_MODE} />
            </div>
            {isDisplayConfirmationButton && (
              <div className={styles.button}>
                <Button
                  className={classNames(styles.balloon)}
                  primary
                  disabled={!checkedEmployees.length}
                  disabledReason={
                    <div style={{ textAlign: 'left' }}>
                      従業員が選択されていません。
                      <br />
                      保存する場合、チェックボックスにチェックを入れてください。
                    </div>
                  }
                  onClick={() => setIsConfirmationModalOpen(true)}
                >
                  {mode() === 'confirm' ? '確定処理' : '確定解除'}
                </Button>
              </div>
            )}
            {isMixedConfirmationStatus && (
              <div className={styles.button}>
                <Button
                  className={classNames(styles.balloon)}
                  primary
                  disabled
                  disabledReason={
                    <div style={{ textAlign: 'left' }}>
                      確定・未確定データの両方にチェックが入っている為、
                      <br />
                      操作ができません
                    </div>
                  }
                  onClick={() => setIsConfirmationModalOpen(true)}
                >
                  操作不可
                </Button>
              </div>
            )}
          </div>
          <DetailSearchForm
            menuRender={() => <EnrollmentStatusBtnList />}
            containsOtherSearch={false}
            formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            containsOfficeSearch
            containIsConfirmedPayroll
            containIsConfirmed
            resetValues={resetValues}
          />
        </div>
        <LoadingView isLoading={isListLoading}>
          <div className={classNames('l-wrap-xl', styles.list)}>
            <ConfirmationTable employees={employees} />
            <SearchFormPaginator formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH} reducerName={REDUCER_NAME} />
          </div>
        </LoadingView>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        hideModal={() => {
          setIsConfirmationModalOpen(false);
        }}
        mode={mode()}
        overReduceLimitEmployees={overReduceLimitEmployees}
      />
    </div>
  );
};

const EmployeeListForm = reduxForm({
  form: FORM_NAME_FLAT_TAX_REDUCTION_SEARCH,
  enableReinitialize: true
})(EmployeeList);

/* eslint-disable import/prefer-default-export */
export const FlatTaxReductionConfirmations = props => {
  const employeesSearchFormValues = useSelector(state => getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(state));
  const location = useSelector(state => state.router.location);
  const employees = useSelector(state => getList(REDUCER_NAME, state));
  const initialValues = {
    ...takeEmployeesSearchQueriesFromLocation(location, true),
    ...employeesSearchFormValues,
    employees
  };

  return <EmployeeListForm {...props} location={location} initialValues={initialValues} />;
};
/* eslint-enable import/prefer-default-export */
