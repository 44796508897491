import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { setImportJobInvisible, fetchImportJobStatus, getImportJobs } from 'src/reducers/employees/employeeAttendances';
import { Close } from 'jbc-front/components/icons';
import { JobFailed, JobInprogress, JobSuccess } from 'src/components/JobStatus';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import styles from './ImportAttendanceCsvJobStatus.scss';

const ImportJobResult = ({ tasks, status, hideJobNotifications }) => {
  let statusText = 'の勤怠CSVアップロードが完了しました。';
  let tasksStyle = styles.tasksSuccess;

  if (status === 'failed') {
    statusText = 'の勤怠CSVアップロードが失敗しました。';
    tasksStyle = styles.tasksFailed;
  } else if (status === 'inProgress' || status === 'waiting') {
    statusText = 'の勤怠CSVアップロードを処理中です。';
    tasksStyle = styles.tasksInprogress;
  }

  return (
    <React.Fragment>
      {!isEmpty(tasks) &&
        tasks.length > 0 && (
          <div className={tasksStyle}>
            <div className={styles.inner}>
              {status === 'success' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideJobNotifications(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobSuccess key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
              {status === 'failed' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideJobNotifications(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobFailed key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
              {(status === 'inProgress' || status === 'waiting') && (
                <React.Fragment>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobInprogress key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      {isEmpty(tasks) && null}
    </React.Fragment>
  );
};

class ImportAttendanceCsvJobStatus extends React.Component {
  componentDidMount() {
    this.props.fetchVisibleImportJobs();
    this.pollFetchVisibleImportJobs(INITIAL_POLLING_INTERVAL);
  }

  pollFetchVisibleImportJobs = interval => {
    const { importJobs, fetchVisibleImportJobs } = this.props;
    let newInterval = interval;
    if (importJobs && (Object.keys(importJobs).includes('inProgress') || Object.keys(importJobs).includes('waiting'))) {
      fetchVisibleImportJobs();
      if (newInterval < MAX_POLLING_INTERVAL) {
        newInterval *= 2;
      }
    }
    setTimeout(this.pollFetchVisibleImportJobs, newInterval, newInterval);
  };

  render() {
    const { importJobs = {}, hideJobNotifications } = this.props;
    const sortCondition = (_status1, status2) => {
      if (status2 === 'inProgress' || status2 === 'waiting') return -1; // In progress tasks are on bottom
      if (status2 === 'success') return -1; // Successful tasks are on top
      return 0; // Failed tasks are in middle
    };
    return (
      <React.Fragment>
        {isEmpty(importJobs) ? null : (
          <div>
            {Object.keys(importJobs)
              .sort(sortCondition)
              .map(status => (
                <ImportJobResult
                  key={status}
                  tasks={importJobs[status]}
                  status={status}
                  hideJobNotifications={hideJobNotifications}
                />
              ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    importJobs: getImportJobs(state)
  }),
  dispatch => ({
    hideJobNotifications: jobs => {
      const jobIds = jobs.map(j => j.id).join('_');
      if (jobIds.length > 0) {
        dispatch(setImportJobInvisible(jobIds));
      }
    },
    fetchVisibleImportJobs: () => dispatch(fetchImportJobStatus(true))
  })
)(ImportAttendanceCsvJobStatus);
