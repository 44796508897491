import React, { Component } from 'react';
import styles from './CheckBoxHint.scss';

class CheckBoxHint extends Component {
  componentDidMount() {
    document.addEventListener('click', this.handleClick, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, true);
  }

  handleClick = () => {
    this.props.onClick();
  };
  render() {
    return (
      <div className={styles.hint}>
        <div className={styles.hintShape} />
        <div className={styles.hintMessage}>{this.props.children}</div>
      </div>
    );
  }
}

export default CheckBoxHint;
