import React from 'react';

import Edit from 'jbc-front/components/icons/Edit';
import GeneralBtn from './GeneralBtn';

const EditBtn = ({ text = '編集画面へ', link, ...rest }) => (
  <GeneralBtn as="a" text={text} href={link} icon={<Edit />} {...rest} />
);

export default EditBtn;
