import React, { useState } from 'react';
import get from 'lodash/get';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { Link, UnLink, Setting, Delete } from 'jbc-front/components/icons';
import OfficeStationModal from './components/OfficeStationModal';
import OfficeStationDeleteModal from './components/OfficeStationDeleteModal';
import styles from './ExternalServiceList.scss';

const ExternalServiceList = props => {
  const [isOfficeStationModalOpen, setIsOfficeStationModalOpen] = useState(false);
  const [isOfficeStationDeleteModalOpen, setIsOfficeStationDeleteModalOpen] = useState(false);
  const stationId = get(props, 'officeStationSetting.stationId');
  const officeName = get(props, 'officeStationSetting.officeName');
  const loginId = get(props, 'officeStationSetting.loginId');

  return (
    <React.Fragment>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">外部サービス連携</h1>
        <p className="m-title-main-note">外部サービスとの連携設定を行います</p>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="外部サービス連携" />
        </BreadCrumb>
        {true && (
          <React.Fragment>
            <div className="l-overflow-scroll">
              <table className={styles.table}>
                <thead>
                  <tr>
                    <td>サービス名</td>
                    <td>連携内容</td>
                    <td>ステータス</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>オフィスステーション</td>
                    <td>
                      <p>取得</p>
                      <p>・従業員情報</p>
                      <p>送信</p>
                      <p>・給与データ</p>
                      <p>・賞与データ</p>
                    </td>
                    <td>
                      {props.officeStationSetting ? (
                        <div>
                          <div className={styles.linked}>
                            <Link size={23} />連携済
                            <div role="presentation" className={styles.setting}>
                              <Setting
                                size={18}
                                onClick={() => {
                                  setIsOfficeStationModalOpen(true);
                                }}
                              />
                              <Delete
                                size={18}
                                onClick={() => {
                                  setIsOfficeStationDeleteModalOpen(true);
                                }}
                              />
                            </div>
                          </div>
                          <p className={styles.info}>StationID: {stationId}</p>
                          <p className={styles.info}>会社名 / 屋号: {officeName}</p>
                          <p className={styles.info}>ログインID: {loginId}</p>
                        </div>
                      ) : (
                        <div className={styles.nolink}>
                          <UnLink size={23} />未連携
                          <div
                            role="presentation"
                            className={styles.setting}
                            onClick={() => {
                              setIsOfficeStationModalOpen(true);
                            }}
                          >
                            <Setting size={18} />
                          </div>
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {isOfficeStationModalOpen && (
              <OfficeStationModal
                officeStationSetting={props.officeStationSetting}
                isOpen={isOfficeStationModalOpen}
                hideModal={() => {
                  setIsOfficeStationModalOpen(false);
                }}
              />
            )}
            {isOfficeStationDeleteModalOpen && (
              <OfficeStationDeleteModal
                officeStationSetting={props.officeStationSetting}
                isOpen={isOfficeStationDeleteModalOpen}
                hideModal={() => {
                  setIsOfficeStationDeleteModalOpen(false);
                }}
              />
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ExternalServiceList;
