import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getFormValues, blur, touch, change } from 'redux-form';
import classNames from 'classnames';

import Button from 'jbc-front/components/Button';
import { Section, TextField, SelectField, RadioField } from 'jbc-front/components/Form';

import { PAYROLL_RULES_GROUP_FORM } from 'src/constants/FormNames';
import { CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { isHourFormat, isFloatFormat } from 'src/utils/CustomValidators';
import { toHourFormat, toFloatFormat } from 'src/utils/Completion';
import { convertTimeToMinutes, formatMinutesToTime } from 'src/utils/Number';
import * as CssClassNames from 'src/constants/CssClassNames';
import { getSelectOptions, getRadioOptions, getLabeledDays } from 'src/reducers/selectOptions';
import { FormErrors } from 'src/components';
import { CancelBtn } from 'src/buttons';

const PayrollRulesGroupForm = props => {
  const { handleSubmit, submit, errors, pristine, submitting, isCopyForm } = props;

  const dispatch = useDispatch();

  const payrollTypes = useSelector(state => getSelectOptions(state, 'payrollTypes'));
  const paymentMonthTypes = useSelector(state => getSelectOptions(state, 'paymentMonthTypes'));
  const displayMonthNameTypes = useSelector(state => getRadioOptions(state, 'displayMonthNameTypes'));
  const days = useSelector(state => getLabeledDays(state));
  const { prescribedWorkingHoursPerDay, avgRegularWorkingDaysPerMonth, hasConfirmedPays, employeesCount } = useSelector(
    state => getFormValues(PAYROLL_RULES_GROUP_FORM)(state)
  );

  const complementAvgRegularWorkingHoursPerMonth = (hoursPerDay, daysPerMonth) => {
    const avgRegularWorkingHoursPerMonth = formatMinutesToTime(convertTimeToMinutes(hoursPerDay) * daysPerMonth);
    dispatch(change(PAYROLL_RULES_GROUP_FORM, 'avgRegularWorkingHoursPerMonth', avgRegularWorkingHoursPerMonth));
  };
  const isNotChange = () => hasConfirmedPays || employeesCount > 0;

  return (
    <form className={classNames(CssClassNames.FORM, CssClassNames.CLIENT_FORM)} onSubmit={handleSubmit}>
      <FormErrors errors={errors} />
      <Section>
        <TextField required label="給与規定グループ名" name="name" />
        <RadioField required label="給与形態" name="payrollType" options={payrollTypes} />
        <TextField
          label="1日の所定労働時間"
          name="prescribedWorkingHoursPerDay"
          validate={[isHourFormat]}
          onChange={(e, newValue) => {
            complementAvgRegularWorkingHoursPerMonth(toHourFormat(newValue), avgRegularWorkingDaysPerMonth);
          }}
          onBlur={(e, newValue, previousValue) => {
            e.preventDefault();
            dispatch(blur(PAYROLL_RULES_GROUP_FORM, 'prescribedWorkingHoursPerDay', toHourFormat(previousValue)));
            dispatch(touch(PAYROLL_RULES_GROUP_FORM, 'prescribedWorkingHoursPerDay'));
          }}
        />

        <TextField
          label="1ヶ月の平均所定労働日数"
          name="avgRegularWorkingDaysPerMonth"
          validate={[isFloatFormat]}
          onChange={(e, newValue) => {
            complementAvgRegularWorkingHoursPerMonth(prescribedWorkingHoursPerDay, toFloatFormat(newValue));
          }}
          onBlur={(e, newValue, previousValue) => {
            e.preventDefault();
            dispatch(blur(PAYROLL_RULES_GROUP_FORM, 'avgRegularWorkingDaysPerMonth', toFloatFormat(previousValue)));
            dispatch(touch(PAYROLL_RULES_GROUP_FORM, 'avgRegularWorkingDaysPerMonth'));
          }}
        />

        <TextField
          label="1ヶ月の平均所定労働時間"
          name="avgRegularWorkingHoursPerMonth"
          validate={[isHourFormat]}
          onBlur={(e, newValue, previousValue) => {
            e.preventDefault();
            dispatch(blur(PAYROLL_RULES_GROUP_FORM, 'avgRegularWorkingHoursPerMonth', toHourFormat(previousValue)));
            dispatch(touch(PAYROLL_RULES_GROUP_FORM, 'avgRegularWorkingHoursPerMonth'));
          }}
        />

        <SelectField
          required
          label="締め日"
          name="closingDay"
          options={days}
          disabled={isNotChange()}
          note={
            <p>
              給与規定に従業員や確定済みの給与が紐づいている場合は、
              締め日、支給日（月）、支給日（日）の変更はできません。
            </p>
          }
        />
        <RadioField
          required
          label="支給日（月）"
          name="paymentMonthType"
          options={paymentMonthTypes}
          disabled={isNotChange()}
        />
        <SelectField required label="支給日（日）" name="paymentDay" options={days} disabled={isNotChange()} />
        <RadioField
          required
          label="給与明細に表示する月"
          name="displayMonthNameType"
          options={displayMonthNameTypes}
          note={
            <p>
              「給与明細」に表示する【YYYY年MM月 給与明細】の月を設定できます。<br />
              例えば、「2018年6月末締め/7月15日支給」の給与は以下のように表示されます。<br />
              「支給日の月」を選択した場合→【2018年7月 給与明細】<br />
              「支給日の前月」を選択した場合→【2018年6月 給与明細】
            </p>
          }
          noteWidth={360}
        />
      </Section>
      <div className="u-ta-c">
        <CancelBtn href={CLIENT_PAYROLL_RULES_GROUP_LIST} className="u-mr20" />
        <Button onClick={submit} disabled={(!isCopyForm && pristine) || submitting} primary>
          保存
        </Button>
      </div>
    </form>
  );
};

export default PayrollRulesGroupForm;
