import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SortableTh from 'src/components/SortableTh';
import {
  isAllEmployeeCheckedForConfirmationList,
  changeAllEmployeeCheckedForConfirmationList
} from 'src/reducers/flat_tax_reductions/employees';
import { FORM_NAME_FLAT_TAX_REDUCTION_SEARCH } from 'src/reducers/searchForm';
import styles from './ConfirmationTable.scss';
import ConfirmationTableRow from './ConfirmationTableRow';

const ConfirmationTable = ({ employees }) => {
  const allEmployeeChecked = useSelector(state => isAllEmployeeCheckedForConfirmationList(state));
  const dispatch = useDispatch();
  const onChangeAllEmployeeChecked = () => {
    dispatch(changeAllEmployeeCheckedForConfirmationList(!allEmployeeChecked));
  };

  return (
    <div className="l-overflow-scroll">
      <table className="m-table-list">
        <thead>
          <tr>
            <th
              onClick={onChangeAllEmployeeChecked}
              className={allEmployeeChecked ? 'm-table-checkbox-on' : 'm-table-checkbox-off'}
            />
            <th>メモ</th>
            <SortableTh
              field="hasConfirmedPaymentOrBonusSince"
              globalClassName="styles.hasConfirmedPaymentOrBonusSince"
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              給与確定
              <br />
              状況
            </SortableTh>
            <SortableTh
              field="staffCode"
              globalClassName="m-table-list-staffcode"
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              スタッフコード
            </SortableTh>
            <SortableTh field="name" globalClassName={styles.name} formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}>
              氏名
            </SortableTh>
            <th
              field="reduceTargetCount"
              className={styles.reduceTargetCount}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              減税対象
              <br />
              人数
            </th>
            <th
              field="reduceAmountUnit"
              className={styles.reduceAmountUnit}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              減税額単価
            </th>
            <th
              field="monthlyReduceAmount"
              className={styles.monthlyReduceAmount}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              月次減税額
            </th>
            <th field="reduceAmount" className={styles.reduceAmount} formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}>
              既減税額
            </th>
            <th
              field="restReduceAmount"
              className={styles.restReduceAmount}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              減税額残
            </th>
            <SortableTh
              field="confirmationInfo"
              globalClassName={styles.confirmationInfo}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              確定情報
            </SortableTh>
          </tr>
        </thead>
        <tbody>
          {(!employees || employees.length === 0) && (
            <tr>
              <td colSpan={11}>データがありません。</td>
            </tr>
          )}

          {employees.map((employee, index) => <ConfirmationTableRow key={employee.id} employee={employees[index]} />)}
        </tbody>
      </table>
    </div>
  );
};

export default ConfirmationTable;
