import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import selector from 'src/utils/Utils';
import ElectronicDeliveryAgreementShow from '../components/ElectronicDeliveryAgreementShow';

const mapStateToProps = state => ({
  employeeElectronicDeliveryAgreementDetail:
    selector(getItem(REDUCER_NAME, state), 'employeeElectronicDeliveryAgreementDetail') || [],
  employee: selector(getItem(REDUCER_NAME, state), 'employee') || {}
});

export default connect(mapStateToProps)(ElectronicDeliveryAgreementShow);
