import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { SearchReactSelect, LowerPager } from 'src/components';
import { extractCurrentQueries } from 'src/QueriesStorageApi';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  getListPageCount,
  getListPage,
  getListPerPage,
  getListQueries,
  getList,
  addQuery,
  addQueries,
  addPageQuery,
  addPerPageQuery,
  getListTotalCount,
  getListDisplayFrom,
  getListDisplayTo
} from 'src/reducers/common/listReducer';
import { REDUCER_NAME, fetchReportWithholdingSlips } from 'src/reducers/reports/reportWithholdingSlips';
import { REPORT_WITHHOLDING_SLIP, REPORT_INDEX } from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import ReportWithholdingSlipsTable from './containers/ReportWithholdingSlipsTable';
import styles from './ReportWithholdingSlipList.scss';

class ReportWithholdingSlipList extends React.Component {
  state = {
    isPdfPreview: false
  };

  componentDidMount() {
    const { withholdingSlipYears } = this.props;
    const queriesCache = extractCurrentQueries(REDUCER_NAME);
    const { withholdingSlipYear = _.get(withholdingSlipYears, [0, 'value']) } = queriesCache;

    const defaultYear = this.displayYear(withholdingSlipYears, withholdingSlipYear);
    const queries = { ...queriesCache, withholdingSlipYear: defaultYear };
    if (!defaultYear || !_.find(withholdingSlipYears, o => o.value === defaultYear)) {
      delete queries.withholdingSlipYear;
    }

    this.props.addQueries(queries);

    this.props.fetch();
  }

  displayYear = (withholdingSlipYears, cacheWithholdingSlipYear) => {
    if (!withholdingSlipYears) return null;

    // 今年の選択肢があれば今年をデフォルトの年とする
    let year = _.find(withholdingSlipYears, o => o.value === moment().year());
    if (year) return year.value;

    // キャッシュされている年があればそれをデフォルトの表示とする
    year = _.find(withholdingSlipYears, o => o.value === cacheWithholdingSlipYear);
    if (year) return year.value;

    // 今年もキャッシュもなければ年プルダウンの1つ目をデフォルトとする
    year = _.first(withholdingSlipYears);
    if (year) return year.value;

    return null;
  };

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };
  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };

  pdfUrl = (perPage, page, withholdingSlipYear) =>
    `${REPORT_WITHHOLDING_SLIP}?pdf[per_page]=${perPage}&pdf[page]=${page}&pdf[withholding_slip_year]=${withholdingSlipYear}`;
  pdfDownloadUrl = withholdingSlipYear =>
    `${REPORT_WITHHOLDING_SLIP}?pdf[type]=zip&pdf[withholding_slip_year]=${withholdingSlipYear}`;

  render() {
    const {
      queries,
      employees,
      withholdingSlipYears,
      pageCount,
      initialPage,
      perPage,
      perPages,
      onPageChange,
      onChangePerPage,
      onChangeWithholdingSlipYear,
      totalCount,
      displayFrom,
      displayTo
    } = this.props;
    return (
      <div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
              <BreadCrumb.Item label="退職者の源泉徴収票" />
            </BreadCrumb>
          </div>
          <h1 className="m-title-main">源泉徴収票</h1>
          <p className="m-title-main-note" />
          <div className={styles.action}>
            {withholdingSlipYears.length > 0 && (
              <SearchReactSelect
                options={withholdingSlipYears}
                onChange={selected => {
                  onChangeWithholdingSlipYear(selected.value);
                }}
                value={queries.withholdingSlipYear}
                width={130}
                size="large"
              />
            )}
          </div>
          <ReportWithholdingSlipsTable queries={queries} employees={employees} />
          <LowerPager
            pageCount={pageCount}
            initialPage={initialPage - 1}
            forcePage={initialPage - 1}
            perPage={perPage}
            perPages={perPages}
            onPageChange={e => onPageChange(e.selected)}
            onPerPageChange={e => onChangePerPage(e.target.value)}
            totalCount={totalCount}
            displayFrom={displayFrom}
            displayTo={displayTo}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employees: getList(REDUCER_NAME, state),
  withholdingSlipYears: getSelectOptions(state, 'withholdingSlipYears'),
  initialPage: getListPage(REDUCER_NAME, state),
  pageCount: getListPageCount(REDUCER_NAME, state),
  perPage: getListPerPage(REDUCER_NAME, state),
  perPages: getSelectOptions(state, 'perPages'),
  queries: getListQueries(REDUCER_NAME, state),
  totalCount: getListTotalCount(REDUCER_NAME, state),
  displayFrom: getListDisplayFrom(REDUCER_NAME, state),
  displayTo: getListDisplayTo(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  addQueries: queries => {
    dispatch(addQueries(REDUCER_NAME, queries));
  },
  fetch: () => {
    dispatch(fetchReportWithholdingSlips());
  },
  onChangeWithholdingSlipYear: query => {
    dispatch(addQuery(REDUCER_NAME, 'withholdingSlipYear', query));
    dispatch(addPageQuery(REDUCER_NAME, 1));
    dispatch(fetchReportWithholdingSlips());
  },
  onPageChange: query => {
    dispatch(addPageQuery(REDUCER_NAME, query + 1));
    dispatch(fetchReportWithholdingSlips());
  },
  onChangePerPage: query => {
    dispatch(addPerPageQuery(REDUCER_NAME, query));
    dispatch(addPageQuery(REDUCER_NAME, 1));
    dispatch(fetchReportWithholdingSlips());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportWithholdingSlipList);
