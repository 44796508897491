import React from 'react';
import { useDispatch } from 'react-redux';

import { reduxForm } from 'redux-form';

import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';

import { FormSubmitBtn } from 'src/buttons';
import { confirmEmployee, unconfirmEmployee } from 'src/reducers/flat_tax_reductions/employees';

import styles from './ConfirmationModal.scss';

const ConfirmationModal = ({ isOpen, hideModal, handleSubmit, submitting, submit, mode, overReduceLimitEmployees }) => (
  <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
    <CommonModal.Header hideModal={hideModal}>{mode === 'confirm' ? '確定処理' : '確定解除'}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        {mode === 'confirm' ? (
          <>
            選択したスタッフについて給与・賞与から控除する金額（減税額残）を確定します。
            <br />
            <br />
            {overReduceLimitEmployees && (
              <>
                <span className={styles.importantWarning}>【確認】</span>
                <br />
                <span className={styles.importantWarning}>
                  以下スタッフについて既減税額が月次減税額を超過しています。
                  <br />
                  この場合、減税額残は「0円」となり、定額減税は行いません。
                  <br />
                  <br />
                  {overReduceLimitEmployees.map(employee => (
                    <div key={employee.id}>
                      {employee.staffCode
                        ? `${employee.staffCode} ${employee.lastName} ${employee.firstName}`
                        : `${employee.lastName} ${employee.firstName}`}
                      <br />
                    </div>
                  ))}
                </span>
                <br />
              </>
            )}
            <span className={styles.importantWarning}>【注意】</span>
            <br />
            <span className={styles.importantWarning}>
              確定処理後に減税対象人数・既減税額を変更したい場合は確定解除してから
              <br />
              編集してください。
              <br />
              2024年6月1日以後に支給日のある給与・賞与に1つでも確定済のものがある場合、
              <br />
              確定解除はできません。
              <br />
              この場合、前述の給与・賞与の確定を解除してから確定処理一覧の確定解除を
              <br />
              行ってください。
            </span>
            <br />
            <br />
            確定しますか？
          </>
        ) : (
          <>
            選択したスタッフについて確定解除します。
            <br />
            <br />
            <span className={styles.importantWarning}>【注意】</span>
            <br />
            <span className={styles.importantWarning}>
              給与・賞与の修正を行う場合、修正を行う給与・賞与の支給日以後の給与・賞与の
              <br />
              すべての確定を解除しないと定額減税額が正しく計算されない場合があります。
            </span>
            <br />
            <br />
            確定解除しますか？
          </>
        )}
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <CommonModal.Buttons>
        <Button onClick={hideModal}>キャンセル</Button>
        <FormSubmitBtn text="実行" {...{ submitting, submit }} />
      </CommonModal.Buttons>
    </CommonModal.Footer>
  </CommonModal>
);

const BatchDeleteEmployeeForm = reduxForm({
  form: 'BATCH_DELETE_EMPLOYEE_FORM'
})(ConfirmationModal);

const ConfirmationModalOuter = props => {
  const dispatch = useDispatch();
  const onSubmit = () => {
    if (props.mode === 'confirm') {
      dispatch(confirmEmployee(props));
    } else if (props.mode === 'unconfirm') {
      dispatch(unconfirmEmployee(props));
    }
  };
  return <BatchDeleteEmployeeForm {...props} {...{ onSubmit }} />;
};

export default ConfirmationModalOuter;
