import React from 'react';

import TargetSettings from './TargetSettings';

const AllowanceDeductionIndividual = () => (
  <div>
    <TargetSettings allowanceType="deduction" />
  </div>
);

export default AllowanceDeductionIndividual;
