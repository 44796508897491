import React, { useState } from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { TextField, Section } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';

import { RESET_PASSWORD_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { getLoginMessages } from 'src/reducers/users/currentUser';
import { sendResetPasswordEmail } from 'src/reducers/users/resetPassword';
import { isEmail } from 'src/utils/CustomValidators';

const RawResetPasswordForm = props => {
  const { handleSubmit, submit, pristine, submitting, errors } = props;
  const [shouldShowCidInput, handleClickCollapse] = useState(false);
  return (
    <form onSubmit={handleSubmit}>
      <div className="l-title-wrap">
        <h1 className="m-title-main">メールアドレスを入力してください</h1>
      </div>
      <div>
        <ErrorsSection errors={errors} />
        <Section>
          <TextField name="email" label="メールアドレス" required validate={isEmail} />
          {shouldShowCidInput && <TextField name="client_code" label="会社ID" />}
          <a
            role="button"
            tabIndex={-1}
            className={`u-txt-link`}
            onClick={() => handleClickCollapse(!shouldShowCidInput)}
          >
            {shouldShowCidInput ? '会社IDの入力を閉じる' : '複数の会社に登録されていますか？'}
          </a>
        </Section>
        <div className="u-ta-c u-mt30">
          <Button primary onClick={submit} disabled={pristine || submitting}>
            送信
          </Button>
        </div>
      </div>
    </form>
  );
};

const ResetPasswordForm = reduxForm({
  form: RESET_PASSWORD_FORM
})(RawResetPasswordForm);

export default connect(
  state => ({
    errors: getLoginMessages(state) || []
  }),
  dispatch => ({
    onSubmit: data => dispatch(sendResetPasswordEmail(data))
  })
)(ResetPasswordForm);
