import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import Button from 'jbc-front/components/Button';
import { PdfFile } from 'jbc-front/components/icons';
import Loading from 'src/components/Loading';
import { isJobIncomplete, isJobSuccess } from 'src/reducers/JobStatus';
import PdfCreateModalContainer from '../containers/PdfCreateModalContainer';
import PdfDownloadModalContainer from '../containers/PdfDownloadModalContainer';
import PdfExportMethod from './PdfExportMethod';
import styles from '../ReportWageLedgerList.scss';

const ExportButton = props => {
  const { searchParams, uniqueString, job, formValues, pollFetchExportWageLedgerStatus } = props;
  const [isPdfExportOpen, setPdfExportOpen] = useState(false);
  const [isCreateModalOpen, setCreateModalOpen] = useState(false);
  const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);

  const bonus = _.get(formValues, 'bonus');
  const yearSelected = _.get(formValues, 'yearSelected');
  const year = _.get(formValues, 'year');
  const yearMonthStart = _.get(formValues, 'yearMonthStart');
  const yearMonthEnd = _.get(formValues, 'yearMonthEnd');

  useEffect(
    () => {
      pollFetchExportWageLedgerStatus(formValues, uniqueString);
    },
    [bonus, yearSelected, year, yearMonthStart, yearMonthEnd]
  );

  return (
    <div className={styles.wrap}>
      <div className={styles.loading}>
        {isJobIncomplete(job.status) === true && (
          <Button primary widthWide disabled icon={<Loading />} className={styles.loadingButton}>
            データ作成中
          </Button>
        )}
        {isJobIncomplete(job.status) === false && (
          <React.Fragment>
            <Button
              primary
              onClick={() => setPdfExportOpen(true)}
              className={styles.createButton}
              widthWide
              icon={<PdfFile size={16} />}
            >
              PDF一括出力
            </Button>
            {isPdfExportOpen && (
              <PdfExportMethod
                hide={() => setPdfExportOpen(false)}
                searchParams={searchParams}
                isJobSuccess={isJobSuccess(job.status) === true}
                openDownloadModal={() => setDownloadModalOpen(true)}
                openCreateModal={() => setCreateModalOpen(true)}
              />
            )}
            <PdfCreateModalContainer
              isOpen={isCreateModalOpen}
              hideModal={() => setCreateModalOpen(false)}
              params={searchParams}
              formValues={formValues}
              uniqueString={uniqueString}
            />
            <PdfDownloadModalContainer
              isOpen={isDownloadModalOpen}
              hideModal={() => setDownloadModalOpen(false)}
              year={year}
              yearMonthStart={yearMonthStart}
              yearMonthEnd={yearMonthEnd}
              bonus={bonus}
              yearSelected={yearSelected}
            />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ExportButton;
