import { combineReducers } from 'redux';
import _ from 'lodash';
import { getFormValues } from 'redux-form';
import axios from 'axios';

import { CLIENT_UNIT_PRICE_FORM } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';
import { scrollToTop } from 'src/utils/Utils';
import { getExtras, setExtras } from 'src/reducers/common/listReducer';
import createNamedWrapperReducer from '../createNamedWrapperReducer';
import { listReducer, itemReducer } from '../common';
import { newItemFailure, editItemFailure, deleteItem, deleteItemFailure, getItemErrors } from '../common/itemReducer';

export const REDUCER_NAME = 'clientUnitPrices';

export const getUnitPriceFormValues = state => getFormValues(CLIENT_UNIT_PRICE_FORM)(state);
export const getUnitPriceListErrors = state => getItemErrors(REDUCER_NAME, state);
export const getSubmitting = state => _.get(getExtras(REDUCER_NAME, state), 'submitting') || false;

export const createClientUnitPrice = data => async dispatch => {
  try {
    const createUrl = concatParamsToUrl(Urls.CLIENT_UNIT_PRICE_CREATE, {
      payrollRulesGroupId: data.clientPayrollRulesGroupId
    });
    const response = await axios.post(createUrl, {
      client_unit_price: data,
      client_unit_price_allowance: { client_allowance_id: data.clientAllowanceId }
    });
    if (response.data.errors.messages.length > 0) {
      dispatch(newItemFailure(REDUCER_NAME, response.data.errors.messages));
    } else {
      redirectTo(
        concatParamsToUrl(Urls.CLIENT_UNIT_PRICES_LIST, { payrollRulesGroupId: data.clientPayrollRulesGroupId })
      );
    }
  } catch (exception) {
    scrollToTop();
    dispatch(newItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
    dispatch(setExtras(REDUCER_NAME, { submitting: false }));
  }
};

export const editClientUnitPrice = data => async dispatch => {
  try {
    const editUrl = concatParamsToUrl(Urls.CLIENT_UNIT_PRICE_UPDATE, {
      payrollRulesGroupId: data.clientPayrollRulesGroupId,
      id: data.id
    });
    const response = await axios.put(editUrl, {
      client_unit_price: data,
      client_unit_price_allowance: { client_allowance_id: data.clientAllowanceId }
    });
    if (response.data.errors.messages.length > 0) {
      dispatch(editItemFailure(REDUCER_NAME, response.data.errors.messages));
    } else {
      redirectTo(
        concatParamsToUrl(Urls.CLIENT_UNIT_PRICES_LIST, { payrollRulesGroupId: data.clientPayrollRulesGroupId })
      );
    }
  } catch (exception) {
    scrollToTop();
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
    dispatch(setExtras(REDUCER_NAME, { submitting: false }));
  }
};

export const deleteUnitPrice = (payrollId, unitPriceId) => async dispatch => {
  try {
    dispatch(deleteItem(REDUCER_NAME));
    await axios.delete(
      concatParamsToUrl(Urls.CLIENT_UNIT_PRICE_DELETE, { payrollRulesGroupId: payrollId, id: unitPriceId })
    );
    redirectTo(concatParamsToUrl(Urls.CLIENT_UNIT_PRICES_LIST, { payrollRulesGroupId: payrollId }));
  } catch (exception) {
    dispatch(deleteItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
