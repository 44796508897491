import React, { Component } from 'react';
import _ from 'lodash';
import Loading from 'src/components/Loading';
import ActionButton from 'jbc-front/components/ActionButton';

import { Task, PdfFile } from 'jbc-front/components/icons';
import PdfCreateModalContainer from '../containers/PdfCreateModalContainer';
import PdfDownloadModalContainer from '../containers/PdfDownloadModalContainer';
import styles from './PdfModalBase.scss';

export default class PdfModalBase extends Component {
  constructor(props) {
    super(props);

    this.state = { createModalOpen: false, downloadModalOpen: false };
  }

  openCreateModal = () => {
    this.setState({ ...this.state, createModalOpen: true });
  };
  closeCreateModal = () => {
    this.setState({ ...this.state, createModalOpen: false });
  };

  openDownloadModal = () => {
    this.setState({ ...this.state, downloadModalOpen: true });
  };
  closeDownloadModal = () => {
    this.setState({ ...this.state, downloadModalOpen: false });
  };
  // 「データを再作成する」が押下されたら既存のモーダルを閉じて新規作成用のモーダルを開く
  closeDownloadModalOpenCreateModal = () => {
    this.setState({ ...this.state, downloadModalOpen: false, createModalOpen: true });
  };

  openModal = () => {
    // ファイルが作成済の場合はダウンロード用のモーダルを開く
    // 未作成の場合は作成用のモーダルを開く
    if (this.isFileCreated()) {
      this.openDownloadModal();
    } else {
      this.openCreateModal();
    }
  };

  isFileCreating = () => _.includes(['waiting', 'running'], _.get(this.props.pdfJob, 'status'));
  isFileCreated = () => _.get(this.props.pdfJob, 'status') === 'success';

  render() {
    const PayReportModal = () => {
      if (this.isFileCreated() && !this.state.createModalOpen) {
        return (
          <PdfDownloadModalContainer
            isOpen={this.state.downloadModalOpen}
            hideModal={this.closeDownloadModal}
            recreate={this.closeDownloadModalOpenCreateModal}
            revisionYear={this.props.revisionYear}
            applicableOffice={this.props.applicableOffice}
          />
        );
      }
      return (
        <PdfCreateModalContainer
          isOpen={this.state.createModalOpen}
          hideModal={this.closeCreateModal}
          revisionYear={this.props.revisionYear}
          applicableOffice={this.props.applicableOffice}
        />
      );
    };

    if (this.isFileCreating()) {
      return (
        <div className={styles.actionButtons}>
          <div className={styles.actionButtonsChild}>
            <div className={styles.loading}>
              <ActionButton primary disabled icon={<Loading size={16} />}>
                データ作成中
              </ActionButton>
            </div>
          </div>
        </div>
      );
    }

    if (!this.props.isFetchCompleted) {
      return (
        <div className={styles.actionButtons}>
          <div className={styles.actionButtonsChild}>
            <div className={styles.loading}>
              <ActionButton primary disabled icon={<Loading size={16} />} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className={styles.actionButtons}>
          <div className={styles.actionButtonsChild}>
            <ActionButton
              onClick={this.openModal}
              primary
              disabled={this.props.isAllConfirmedAlert && this.props.isTargetExist ? '' : 'disabled'}
              icon={this.isFileCreated() ? <PdfFile size={16} /> : <Task />}
            >
              {this.isFileCreated() ? 'PDF一括出力' : 'PDF一括作成'}
            </ActionButton>
          </div>
        </div>
        <PayReportModal />
      </React.Fragment>
    );
  }
}
