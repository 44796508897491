import React from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import _ from 'lodash';
import LazyLoad from 'react-lazyload';
import { numberWithComma } from 'src/utils/Utils';
import { pad } from 'src/utils/Number';
import ContentTop from './RevisionItem/ContentTop';
import ContentMiddle from './RevisionItem/ContentMiddle';
import ContentBottom from './RevisionItem/ContentBottom';
import styles from './RevisionItemBody.scss';

export class RevisionItemBody extends React.Component {
  constructor(props) {
    super(props);
    this.fetchValue = this.fetchValue.bind(this);
    this.displayMonthAmount = this.displayMonthAmount.bind(this);
    this.selectableMonths = this.selectableMonths.bind(this);
    this.fetchRevision = this.fetchRevision.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { dispatch, formRevision } = this.props;

    // 届出の入力値に変更があった場合
    if (!_.isEqual(formRevision, prevProps.formRevision)) {
      // 「手入力前に戻す」操作の場合は何もしない
      if (!_.isEqual(formRevision.isResetInput, prevProps.formRevision.isResetInput)) return;

      dispatch(change('revisions', `revisions.${formRevision.id}.isResetInput`, false));
    }
  }

  fetchValue(key) {
    const { revision, formRevision, showMode } = this.props;
    return showMode === 'SHOW' ? revision[key] : formRevision[key];
  }

  fetchRevision() {
    const { revision, formRevision, showMode } = this.props;
    return showMode === 'SHOW' ? revision : formRevision;
  }

  // key: permits 「xxxMonthAmount」(ex. firstMonthAmount)
  displayMonthAmount(key) {
    const month = key.match(/(.+)Amount/)[1];
    const revision = this.fetchRevision();
    if (revision[`${month}BasicDaysCheck`]) {
      return numberWithComma(this.fetchValue(key));
    }
    return '―　';
  }

  // 同一年しか対応していないので使用する際には注意すること
  selectableMonths(months) {
    return _.map(months, m => ({
      label: `${this.fetchValue('revisionYear')}-${pad(m, 2)}`,
      value: `${this.fetchValue('revisionYear')}-${pad(m, 2)}-01`
    }));
  }

  // redux-formは文字列で値が返ってくるのでnormalizeする
  normalizeBoolean(value) {
    if (value === 'true') {
      return true;
    }
    if (value === 'false') {
      return false;
    }
    return value;
  }

  displayGender(gender) {
    let word = '';
    if (gender === 'male') {
      word = '1. 男';
    } else if (gender === 'female') {
      word = '2. 女';
    }
    return word;
  }

  validateRetroactiveAmount(value, allValues, formProps, name) {
    const revision = allValues.revisions[name.split('.')[1]];
    if (Number(value) === 0 && revision.retroactiveEnabled) {
      return 'がある場合は、金額を入力してください';
    }
    return undefined;
  }

  render() {
    return (
      <div className={`${styles.wrap} l-overflow-scroll`}>
        <LazyLoad>
          <ContentTop
            {...{
              ...this.props,
              fetchValue: this.fetchValue,
              validateRetroactiveAmount: this.validateRetroactiveAmount,
              normalizeBoolean: this.normalizeBoolean,
              selectableMonths: this.selectableMonths
            }}
          />
        </LazyLoad>
        <LazyLoad>
          <ContentMiddle
            {...{
              ...this.props,
              fetchValue: this.fetchValue,
              displayMonthAmount: this.displayMonthAmount,
              fetchRevision: this.fetchRevision
            }}
          />
        </LazyLoad>
        <LazyLoad>
          <ContentBottom
            {...{
              ...this.props,
              fetchValue: this.fetchValue,
              selectableMonths: this.selectableMonths
            }}
          />
        </LazyLoad>
      </div>
    );
  }
}
// TODO: connectやめてuseDispatchを使う。
export default connect()(RevisionItemBody);
