import { connect } from 'react-redux';
import { getDownloadBonusFbErrors, downloadBonusFb } from 'src/reducers/employees/employeeBonus';
import DownloadBonusForm from '../components/DownloadBonusFbModal';

const mapStateToProps = state => ({
  errors: getDownloadBonusFbErrors(state)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => dispatch(downloadBonusFb(data, ownProps.queries))
});

export default connect(mapStateToProps, mapDispatchToProps)(DownloadBonusForm);
