import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { formatDateJa } from 'src/utils/Date';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { ErrorsSection, LoadingWithDescription } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import { UNCONFIRM_CLIENT_BONUS } from 'src/constants/FormNames';
import { getChangeConfirmBonusErrors } from 'src/reducers/clients/clientBonus';
import { takeBonusFromSearchForm } from 'src/reducers/searchForm';
import styles from './UnconfirmBonusModal.scss';

const formatBonusName = bonus => `${bonus.name}（${formatDateJa(bonus.payOn)}支給）`;

const UnconfirmBonusInsideForm = ({ submitting, submit, cancelBtnClick, errors, selectedClientBonus, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form>
        <ErrorsSection errors={errors} />
        {submitting && (
          <div className="u-mb20">
            <LoadingWithDescription text="解除中です。少々お待ちください。" />
          </div>
        )}
        <div className="u-mb20">{formatBonusName(selectedClientBonus)}賞与の確定を解除します。</div>
        <div className={styles.caution}>
          <p>【注意】</p>
          <p>確定を解除すると以下の情報に変更があります。</p>
          <div className="u-mt15">
            ①{formatBonusName(selectedClientBonus)}賞与に関連する以下の情報が削除されます。
            <ul>
              <li>・賞与明細</li>
              <li>・賞与振込一覧表</li>
              <li>・支給・控除等一覧表</li>
              <li>・賃金台帳</li>
              <li>・賞与支払届</li>
              <li>・算定基礎賃金集計表</li>
              <li>・労働保険申告書</li>
              <li>・源泉徴収票</li>
            </ul>
          </div>
          <div className="u-mt15">
            ②支給・控除項目は現在の情報を基に自動計算されます。ただし、手入力した値やCSVで更新した値は変更されません。
          </div>
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="解除" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let UnconfirmBonusForm = reduxForm({
  form: UNCONFIRM_CLIENT_BONUS
})(UnconfirmBonusInsideForm);
const mapStateToProp = state => ({
  errors: getChangeConfirmBonusErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: data => ownProps.confirmClientBonus(ownProps.selectedClientBonus.value, data, ownProps.cancelBtnClick)
});

UnconfirmBonusForm = connect(mapStateToProp, mapDispatchToProp)(UnconfirmBonusForm);

const UnconfirmBonusModal = ({ isOpen, hideModal, confirmClientBonus, selectedClientBonus }) => {
  const header = '賞与確定';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <UnconfirmBonusForm cancelBtnClick={hideModal} {...{ confirmClientBonus, selectedClientBonus }} header={header} />
    </CommonModal>
  );
};

const mapStateToProps = state => ({
  selectedClientBonus: takeBonusFromSearchForm(state)
});
export default connect(mapStateToProps)(UnconfirmBonusModal);
