import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';
import compose from 'lodash/fp/compose';

import { CHANGE_BONUS_RELEASE_DATE_FORM } from 'src/constants/FormNames';
import { DateField, SelectField, CheckboxField } from 'jbc-front/components/Form';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import { isYYYYMMDDFormat } from 'src/utils/CustomValidators';
import { get24HoursOptions } from 'src/utils/Form';
import { updateBonusReleaseDate } from 'src/reducers/employees/employeeBonus';
import styles from './ChangeBonusReleaseDateForm.scss';

const ChangeReleaseDateFormInner = ({ isOpen, hideModal, submitting, submit }) => (
  <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
    <CommonModal.Header hideModal={hideModal}>公開日設定</CommonModal.Header>
    <CommonModal.Body>
      <form>
        <div className={styles.body}>
          <DateField required name="releaseDate" label="従業員への明細公開時刻" validate={isYYYYMMDDFormat} />
          <div style={{ width: '120px' }}>
            <SelectField required noLabel name="releaseHour" options={get24HoursOptions()} width={100} />
          </div>
          <div>
            <CheckboxField name="sendNotifyToEmployee" label="明細公開と同時に通知メールを従業員に送信する" />
          </div>
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="確定" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </CommonModal>
);

const mapStateToProps = (state, ownProps) => {
  const releaseDateTime = _.get(ownProps, ['selectedClientBonus', 'releaseDate']);
  const releaseDate = releaseDateTime ? moment(releaseDateTime).format('YYYY-MM-DD') : null;
  const releaseHour = releaseDateTime ? moment(releaseDateTime).format('H') : null;
  const sendNotifyToEmployee = _.get(ownProps, ['selectedClientBonus', 'sendNotifyToEmployee']);
  const clientBonusId = _.get(ownProps, ['selectedClientBonus', 'value']);

  return {
    initialValues: { releaseDate, releaseHour, sendNotifyToEmployee, clientBonusId },
    ...ownProps
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => dispatch(updateBonusReleaseDate(data, ownProps.hideModal))
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: CHANGE_BONUS_RELEASE_DATE_FORM,
    enableReinitialize: true
  })
)(ChangeReleaseDateFormInner);
