import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { OFFICE_FORM } from 'src/constants/FormNames';

import EmploymentInsuranceContactInfoSection from '../../components/edit/EmploymentInsuranceContactInfoSection';

const mapStateToProps = state => ({
  office: getFormValues(OFFICE_FORM)(state).office,
  employmentInsurance: getFormValues(OFFICE_FORM)(state).employmentInsurance
});

export default connect(mapStateToProps)(EmploymentInsuranceContactInfoSection);
