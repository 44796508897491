import React from 'react';
import Select from 'react-select';
import { ERROR_MSG_NO_DATA } from 'src/constants/ValidationMessages';

import styles from './EditableSelectField.scss';

class EditableSelectField extends React.Component {
  renderField(props) {
    const { input: inputProps, width, showValue, showMode, options, disabled, ...rest } = props;
    if (showMode === 'SHOW') {
      return <div>{showValue}</div>;
    } else if (showMode === 'EDIT') {
      return (
        // 共通コンポーネントのSelectFieldを大きすぎるため、CSSで調整する手間を省くため独自定義
        <Select
          style={{ width }}
          {...inputProps}
          {...rest}
          clearable={false}
          isSearchable={false}
          simpleValue
          value={inputProps.value || (options && options[0].value)}
          options={options}
          disabled={disabled}
          onBlur={() => inputProps.onBlur(inputProps.value)}
          noResultsText={ERROR_MSG_NO_DATA}
          noOptionsMessage={ERROR_MSG_NO_DATA}
        />
      );
    }
    return null;
  }
  render() {
    const { width } = this.props;
    return (
      <div className={styles.container} style={{ width }}>
        {this.renderField(this.props)}
      </div>
    );
  }
}

export default EditableSelectField;
