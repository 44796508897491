import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { arrayMove } from 'react-sortable-hoc';

import Tag from 'jbc-front/components/Tag';
import ActionButton from 'jbc-front/components/ActionButton';
import { MinusCircle, Strage } from 'jbc-front/components/icons';

import { CLIENT_SETTINGS, CLIENT_PAYROLL_RULES_GROUP_LIST, CLIENT_ALLOWANCES_LIST } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';

import { REDUCER_NAME, fetchAllowanceList, saveDisplayOrder } from 'src/reducers/clients/allowances';
import { getRadioOptions } from 'src/reducers/selectOptions';
import { getExtras, getList, setExtras, setList } from 'src/reducers/common/listReducer';

import SortableAllowancesTable from './components/SortableAllowancesTable';
import styles from './ChangeAllowanceDisplayOrder.scss';

const allowancesUrl = payrollGroupId =>
  concatParamsToUrl(CLIENT_ALLOWANCES_LIST, { payrollRulesGroupId: payrollGroupId });

const SelectAllowanceType = ({ allowanceTypes, selectedAllowanceType, handleOnClick }) => (
  <div className={styles.filter}>
    {allowanceTypes.map(allowanceType => (
      <Tag
        key={allowanceType.value}
        active={allowanceType.value === selectedAllowanceType}
        onClick={e => {
          e.preventDefault();
          handleOnClick(allowanceType);
        }}
      >
        {allowanceType.label}
      </Tag>
    ))}
  </div>
);

// 支給/控除項目並び替え画面TOP
const ChangeAllowanceDisplayOrder = props => {
  const { payrollRulesGroup } = props;
  const allowanceTypes = useSelector(state => getRadioOptions(state, 'allowanceTypes'));

  const payrollRulesGroupId = payrollRulesGroup.id;
  const dispatch = useDispatch();

  const allowances = useSelector(state => getList(REDUCER_NAME, state));

  const selectedAllowanceType = useSelector(state => getExtras(REDUCER_NAME, state)).selectedAllowanceType;
  const setSelectedAllowanceType = allowanceType => {
    dispatch(setExtras(REDUCER_NAME, { selectedAllowanceType: allowanceType }));
  };

  useEffect(() => {
    dispatch(fetchAllowanceList(payrollRulesGroupId));
    if (!selectedAllowanceType) {
      setSelectedAllowanceType('payment');
    }
  }, []);

  // 表示用の支給控除項目を取得する
  const getDisplayAllowances = currentType => allowances.filter(allowance => allowance.allowanceType === currentType);

  // 並び替え完了のイベントハンドラ
  const onSortEnd = ({ oldIndex, newIndex }) => {
    const currentList = {
      payment: getDisplayAllowances('payment'),
      deduction: getDisplayAllowances('deduction')
    };

    const incomingList = {
      ...currentList,
      [selectedAllowanceType]: arrayMove([...currentList[selectedAllowanceType]], oldIndex, newIndex)
    };
    // 支給項目, 控除項目の順で保存する
    dispatch(setList(REDUCER_NAME, { data: [...incomingList.payment, ...incomingList.deduction] }));
  };

  // 支給項目・控除項目の切り替えイベントのハンドラ
  const handleOnSelectAllowanceType = allowanceType => {
    setSelectedAllowanceType(allowanceType.value);
  };

  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">支給・控除項目並べ替え</h1>
        <div className="m-title-main-note">{payrollRulesGroup.name}</div>
      </div>
      <div className="l-wrap-s l-contents-wrap">
        <div className="l-breadcrumb u-mb20">
          <a className="l-breadcrumb-link" href={CLIENT_SETTINGS}>
            設定
          </a>
          <a className="l-breadcrumb-link" href={CLIENT_PAYROLL_RULES_GROUP_LIST}>
            給与規定グループ一覧
          </a>
          <Link to={`/clients/payroll_rules_groups/${payrollRulesGroupId}/allowances`} className="l-breadcrumb-link">
            支給・控除項目一覧
          </Link>
          <span className="l-breadcrumb-here">支給・控除項目並べ替え</span>
        </div>
        <div className={styles.head}>
          <div>
            <SelectAllowanceType
              allowanceTypes={allowanceTypes}
              selectedAllowanceType={selectedAllowanceType}
              handleOnClick={handleOnSelectAllowanceType}
            />
          </div>
          <div>
            <ActionButton onClick={() => dispatch(saveDisplayOrder(payrollRulesGroupId))} icon={<Strage size={15} />}>
              保存
            </ActionButton>
            <Link to={allowancesUrl(payrollRulesGroupId)}>
              <ActionButton className="u-ml10" as="a" icon={<MinusCircle size={15} />}>
                キャンセル
              </ActionButton>
            </Link>
          </div>
        </div>
        <SortableAllowancesTable
          onSortEnd={onSortEnd}
          allowances={getDisplayAllowances(selectedAllowanceType)}
          helperClass={styles.dragging}
        />
      </div>
    </div>
  );
};

export default ChangeAllowanceDisplayOrder;
