import React from 'react';
import ActionButton from 'jbc-front/components/ActionButton';
import { CLIENT_ALLOWANCE_ITEMS, CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import styles from './FileImport.scss';

const DownloadColumnDetail = () => (
  <div className="l-wrap-l">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              スタッフコード<span className={styles.required}>必須</span>
            </th>
            <td>スタッフコードを基にデータが取り込まれるため、正確に入力してください。</td>
            <td>111</td>
          </tr>
          <tr>
            <th>姓</th>
            <td>スタッフコードによって判別しているため、空白でも更新可能です。</td>
            <td>山田</td>
          </tr>
          <tr>
            <th>名</th>
            <td>スタッフコードによって判別しているため、空白でも更新可能です。</td>
            <td>太郎</td>
          </tr>
          <tr>
            <th>支給日</th>
            <td>
              yyyy/mm/dd
              <p className="u-mt10">
                画面で表示されている「支給日」と入力された「支給日」が異なる場合はエラーになります。
              </p>
            </td>
            <td>2018/01/25</td>
          </tr>
          <tr>
            <th>給与規定グループ名</th>
            <td>
              <ActionButton as="a" href={CLIENT_PAYROLL_RULES_GROUP_LIST}>
                給与規定グループの確認
              </ActionButton>
              <p className="u-mt10">
                取り込み可能な勤怠項目は、<a href={CLIENT_ALLOWANCE_ITEMS} className="u-txt-link">
                  勤怠項目設定
                </a>＞適用給与規定グループによって異なります。
              </p>
            </td>
            <td>正社員用</td>
          </tr>
          <tr>
            <th>「単位：日数」の勤怠項目</th>
            <td>
              0.0000（小数点第4位までの数値）
              <ul className="m-list-notes u-mt10">
                <li>※小数点以下未入力の場合補完されます。</li>
                <li>※データが空の場合は上書きされません。0を入れたい場合は「0」や「0.0000」を入れてください。</li>
              </ul>
              <p className="u-mt10">＜該当する勤怠項目＞</p>
              <ul className="m-list-notes">
                <li>・所定労働日数（当月）</li>
                <li>・総労働日数</li>
                <li>・欠勤日数</li>
                <li>・有休日数</li>
                <li>＋</li>
                <li>
                  ・ユーザーが<a href={CLIENT_ALLOWANCE_ITEMS} className="u-txt-link">
                    勤怠項目設定
                  </a>で「単位：日数」と設定した勤怠項目
                </li>
              </ul>
            </td>
            <td>22.0000</td>
          </tr>
          <tr>
            <th>「単位：時間」の勤怠項目</th>
            <td>
              hh:mm
              <ul className="m-list-notes u-mt10">
                <li>※「8」のように整数を入力した場合、「8:00」のようにmm部分が補完されます。</li>
                <li>※データが空の場合は上書きされません。0を入れたい場合は「0」や「0:00」を入れてください。</li>
              </ul>
              <p className="u-mt10">＜該当する勤怠項目＞</p>
              <ul className="m-list-notes">
                <li>・所定労働時間（当月）</li>
                <li>・所定内労働時間</li>
                <li>・所定外労働時間</li>
                <li>・総労働時間</li>
                <li>・休日労働時間</li>
                <li>・遅刻早退時間</li>
                <li>・残業時間</li>
                <li>・深夜時間</li>
                <li>・有休時間</li>
                <li>＋</li>
                <li>
                  ・ユーザーが<a href={CLIENT_ALLOWANCE_ITEMS} className="u-txt-link">
                    勤怠項目設定
                  </a>で「単位：時間」と設定した勤怠項目
                </li>
              </ul>
            </td>
            <td>8:45</td>
          </tr>
          <tr>
            <th>「単位：回数」の勤怠項目</th>
            <td>
              0.00（小数点第2位までの数値）
              <ul className="m-list-notes u-mt10">
                <li>※小数点以下未入力の場合補完されます</li>
                <li>※データが空の場合は上書きされません。0を入れたい場合は「0」や「0.00」を入れてください。</li>
              </ul>
              <p className="u-mt10">＜該当する勤怠項目＞</p>
              <ul>
                <li>
                  ・ユーザーが<a href={CLIENT_ALLOWANCE_ITEMS} className="u-txt-link">
                    勤怠項目設定
                  </a>で「単位：回数」と設定した勤怠項目
                </li>
              </ul>
            </td>
            <td>1.00</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className={styles.notes}>
      <ul className="m-list-notes u-mt10">
        <li>
          ※あらかじめ使用したい勤怠項目について、<a href={CLIENT_ALLOWANCE_ITEMS} className="u-txt-link">
            勤怠項目設定
          </a>から設定してください。適用給与規定グループのチェックがOFFになっている項目はアップロードできません。
        </li>
        <li>
          ※以下の勤怠項目は給与規定グループによって設定されているので、こちらの画面からは更新できません。以下の項目を変更したい場合は<a
            href={CLIENT_PAYROLL_RULES_GROUP_LIST}
            className="u-txt-link"
          >
            給与規定
          </a>から設定してください。
          <ul>
            <li>・1日の所定労働時間</li>
            <li>・1ヶ月の平均所定労働日数</li>
            <li>・1ヶ月の平均所定労働時間</li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
);

export default DownloadColumnDetail;
