import React from 'react';

import { SearchSelectBox } from 'jbc-front/components/SearchForm';

import styles from './MainSearchSelectBox.scss';

const MainSearchSelectBox = props => (
  <div className={styles.container}>
    <SearchSelectBox {...props} />
  </div>
);

export default MainSearchSelectBox;
