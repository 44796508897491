import React from 'react';

import { NEW_LINE_CHARACTERS } from 'src/constants/Generals';
import { Section } from 'jbc-front/components/Form';
import styles from './ErrorsSection.scss';

const ErrorItem = ({ error }) => <li className={styles.error}>{error}</li>;

class ErrorsSection extends React.Component {
  render() {
    const { errors, title = '下記エラー内容をご確認ください。' } = this.props;
    return (
      <div>
        {errors &&
          errors.length > 0 && (
            <Section title={title} error>
              <ul>
                {errors.map(error => error.split(NEW_LINE_CHARACTERS).map(err => <ErrorItem key={err} error={err} />))}
              </ul>
            </Section>
          )}
      </div>
    );
  }
}

export default ErrorsSection;
