import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

// このuseRefは ref.current に値を保持するために使用。ref.currentの変更で再レンダリングは走らない。
// useEffect内の処理は再レンダリング後に走るので、SearchDetectorが発火して再検索するまでの間は保持している前の値を返す。
function usePrevious(value) {
  const ref = useRef();
  useEffect(
    () => {
      ref.current = value;
    },
    [value]
  );
  return ref.current;
}

const SearchDetector = props => {
  const { didMountDetectingJudger, willReceivePropsDetectingJudger } = props;
  const prevProps = usePrevious(props);

  const detect = () => {
    const { onDetect, ...rest } = _.omit(props, [
      'dispatch',
      'didMountDetectingJudger',
      'willReceivePropsDetectingJudger'
    ]);
    onDetect(rest);
  };

  useEffect(() => {
    if (didMountDetectingJudger !== undefined && didMountDetectingJudger(props)) {
      detect(props);
    }
  }, []);

  useEffect(
    () => {
      if (prevProps && willReceivePropsDetectingJudger(prevProps, props)) {
        detect(props);
      }
    },
    [props]
  );

  return null;
};

export default connect()(SearchDetector);
