import React from 'react';

import { Item } from 'src/components/PrimaryPanel';
import { Dl, Dd, Dt } from 'src/components/SecondaryPanel';

const OfficeSection = ({ office, isMainOffice }) => (
  <Item>
    <Dl>
      <Dt>事業所名:</Dt>
      <Dd>{office.name}</Dd>
    </Dl>
    <Dl>
      <Dt>事業所名（カナ）:</Dt>
      <Dd>{office.nameKana}</Dd>
    </Dl>
    {isMainOffice && (
      <Dl>
        <Dt>法人番号:</Dt>
        <Dd>{office.corporateNumber}</Dd>
      </Dl>
    )}
    <Dl>
      <Dt>住所:</Dt>
      <Dd>{office.address}</Dd>
    </Dl>
    <Dl>
      <Dt>住所（カナ）:</Dt>
      <Dd>{office.addressKana}</Dd>
    </Dl>
    <Dl>
      <Dt>電話番号:</Dt>
      <Dd>{office.tel}</Dd>
    </Dl>
    <Dl>
      <Dt>事業主（代表者）:</Dt>
      <Dd>{office.representativeName}</Dd>
    </Dl>
    {isMainOffice && (
      <Dl>
        <Dt>管轄の税務署名:</Dt>
        <Dd>{office.taxOfficeName}</Dd>
      </Dl>
    )}
  </Item>
);

export default OfficeSection;
