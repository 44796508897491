import { connect } from 'react-redux';

import { REDUCER_NAME, isMainOffice } from 'src/reducers/offices/offices';
import { getItem } from 'src/reducers/common/itemReducer';
import selector from 'src/utils/Utils';
import OfficeSection from '../../components/show/OfficeSection';

const mapStateToProps = state => ({
  office: selector(getItem(REDUCER_NAME, state), 'office') || {},
  isMainOffice: isMainOffice(
    selector(getItem(REDUCER_NAME, state), 'office') ? selector(getItem(REDUCER_NAME, state), 'office').officeType : ''
  )
});
export default connect(mapStateToProps)(OfficeSection);
