import axios from 'axios';
import get from 'lodash/get';

import * as Urls from 'src/constants/EndpointUrls';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

const saveOfficeStationSetting = data => async dispatch => {
  try {
    await axios.post(Urls.EXTERNAL_COORDINATION_OFFICE_STATION_SETTING, data);
    dispatch(setGlobalSuccesses('設定を保存しました。'));
    window.location.reload();
  } catch (e) {
    if (get(e, 'response.data.errors')) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      dispatch(setGlobalErrors('システムエラーが発生しました。'));
    }
  }
};

export const deleteOfficeStationSetting = () => async dispatch => {
  try {
    await axios.delete(Urls.EXTERNAL_COORDINATION_DELETE_OFFICE_STATION_SETTING);
    dispatch(setGlobalSuccesses('連携を解除しました。'));
    window.location.reload();
  } catch (e) {
    if (get(e, 'response.data.errors')) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      dispatch(setGlobalErrors('システムエラーが発生しました。'));
    }
  }
};

export default saveOfficeStationSetting;
