import React, { Component } from 'react';
import japanese from 'japanese';
import { FormSection, change as formChange, touch as formTouch } from 'redux-form';
import { connect } from 'react-redux';
import { debounce } from 'lodash';
import axios from 'axios';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { SEARCH_POSTAL_CODE_URL } from 'src/constants/EndpointUrls';
import {
  TextFieldWithKana,
  TextField,
  DateField,
  RadioField,
  BoxDouble,
  CreatableField
} from 'jbc-front/components/Form';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import BusinessNameForm from 'src/features/Employees/components/BusinessNameForm';
import { isEmail, isDateStringFormat, zenkakuKatakana } from 'src/utils/CustomValidators';
import selector from 'src/utils/Utils';
import { handlePostalCodeChange, isHonnin } from 'src/utils/Form';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import { getSelectOptions, getRadioOptions } from 'src/reducers/selectOptions';
import { PostalCodeField, TelField } from 'src/components';
import isUseOtherApp from 'src/utils/JbcId';
import styles from './BasicInfoSection.scss';

class BasicInfoSection extends Component {
  constructor(props) {
    super(props);

    this._postalQueryChange = this._handlePostalQueryChange.bind(this);
    this._householdChange = this._handleHouseholdChange.bind(this);
    this._handleFirstNameKanaChange = this._handleFirstNameKanaChange.bind(this);
    this._handleLastNameKanaChange = this._handleLastNameKanaChange.bind(this);

    this.searchPostal = debounce(this.searchPostal, 500);
    this.searchPostal = this.searchPostal.bind(this);

    const { headOfHousehold } = this.props;
    this.state = { headOfHousehold };
  }

  getAddressKana(payload) {
    const { prefectureKana, cityKana, streetKana } = payload;
    return `${prefectureKana}${cityKana}${streetKana}`;
  }

  _handlePostalQueryChange(event, inputText) {
    return handlePostalCodeChange(event)(inputText, this.searchPostal);
  }

  _handleHouseholdChange(e, value) {
    this.setState({ headOfHousehold: value });
  }

  _handleFirstNameKanaChange(value) {
    const firstNameKana = japanese.katakanize(value);
    this.updateField('employee.firstNameKana', firstNameKana);
  }

  _handleLastNameKanaChange(value) {
    const lastNameKana = japanese.katakanize(value);
    this.updateField('employee.lastNameKana', lastNameKana);
  }

  updateField(fieldName, value) {
    const { dispatch } = this.props;
    dispatch(formChange(EMPLOYEE_FORM, fieldName, value));
  }

  async searchPostal(inputText) {
    try {
      const response = await axios.get(SEARCH_POSTAL_CODE_URL, { params: { code: inputText } });
      const payload = response.data.payload.postalInfo;

      ['prefectureId', 'city', 'street'].forEach(field => this.changeFieldValue(`employee.${field}`, payload[field]));
      this.changeFieldValue('employee.addressKana', this.getAddressKana(payload));
    } catch (exception) {
      this.props.dispatch(formTouch(EMPLOYEE_FORM, 'employee.postalCode'));
    }
  }

  changeFieldValue(fieldName, fieldValue) {
    this.props.dispatch(formChange(EMPLOYEE_FORM, fieldName, fieldValue));
  }

  isDisplayHouseholdName() {
    return this.state.headOfHousehold && !isHonnin(this.state.headOfHousehold);
  }

  render() {
    const { prefectures, headOfHouseholds, genders } = this.props;

    return (
      <div>
        <FormSection name="employee">
          <BoxDouble>
            <TextFieldWithKana
              required
              label="姓"
              type="text"
              name="lastName"
              maxLength="32"
              onUpdateKana={this._handleLastNameKanaChange}
            />
            <TextFieldWithKana
              required
              label="名"
              type="text"
              name="firstName"
              maxLength="32"
              onUpdateKana={this._handleFirstNameKanaChange}
            />
            {isUseOtherApp && (
              <p className="u-mb20">姓名を変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。</p>
            )}
          </BoxDouble>

          <BoxDouble>
            <TextField label="姓（カナ）" type="text" name="lastNameKana" validate={zenkakuKatakana} />
            <TextField label="名（カナ）" type="text" name="firstNameKana" validate={zenkakuKatakana} />
          </BoxDouble>

          <BusinessNameForm />

          <DateField label="生年月日" viewMode="years" name="birthday" validate={isDateStringFormat} />

          <RadioField label="性別" name="gender" options={genders} />

          <div className={styles.wrap}>
            <TextField label="メールアドレス" type="text" name="email" validate={isEmail} maxLength="255" />
            {isUseOtherApp && <p>メールアドレスを変更する場合、ご利用中のジョブカン他サービスにも自動反映されます。</p>}
          </div>

          <PostalCodeField handleOnChange={this._postalQueryChange} />

          <BoxDouble>
            <ExtensionSelectField label="都道府県" name="prefectureId" options={prefectures} />
            <TextField label="市区町村" name="city" type="text" />
          </BoxDouble>

          <BoxDouble>
            <TextField label="丁目番地号" name="street" type="text" />
            <TextField label="建物名" name="building" type="text" />
          </BoxDouble>

          <TextField label="住所カナ" name="addressKana" type="text" />

          <TelField />

          <CreatableField
            label="世帯主の続柄"
            name="headOfHousehold"
            options={headOfHouseholds}
            onChange={this._householdChange}
          />

          {this.isDisplayHouseholdName() && <TextField label="世帯主の氏名" name="headOfHouseholdName" />}
        </FormSection>
      </div>
    );
  }
}

export default connect(state => ({
  prefectures: getSelectOptions(state, 'prefectures'),
  headOfHouseholds: getSelectOptions(state, 'headOfHouseholds'),
  genders: getRadioOptions(state, 'genders'),
  headOfHousehold: selector(getEmployeeFormValues(state), 'employee', 'headOfHousehold')
}))(BasicInfoSection);
