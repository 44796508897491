import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/offices/offices';
import { getExtras } from 'src/reducers/common/listReducer';
import { InsurancePeriodSetting } from 'src/components';

const mapStateToProps = state => {
  const insurances = getExtras(REDUCER_NAME, state).insurances;

  return {
    upperLowInsurances: insurances.accident.history,
    lowerLowInsurances: insurances.employment.history,
    minSelectableYear: insurances.minSelectableYear
  };
};

export default connect(mapStateToProps)(InsurancePeriodSetting);
