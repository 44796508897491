import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import RevisionItemBody from '../components/RevisionItemBody';

const fetchValue = (key, revision, showMode, formRevision) => (showMode === 'SHOW' ? revision[key] : formRevision[key]);

const mapStateToProps = (state, ownProps) => ({
  formRevision: getFormValues('revisions')(state).revisions[ownProps.revision.id],
  fetchValue: key =>
    fetchValue(
      key,
      ownProps.revision,
      ownProps.showMode,
      getFormValues('revisions')(state).revisions[ownProps.revision.id]
    )
});

export default connect(mapStateToProps)(RevisionItemBody);
