import React from 'react';
import { TextField } from 'jbc-front/components/Form';
import { alphaNumeric, maxLength10, maxLength20 } from 'src/utils/CustomValidators';
import styles from './OfficeManageSection.scss';

const OfficeSection = () => (
  <div>
    <TextField label="事業所コード" name="manageCode" validate={[alphaNumeric, maxLength10]} />
    <p className={styles.memo}>事業所管理に使用されるコードです。半角英数10文字以内で入力してください。</p>
    <TextField label="事業所管理名" name="manageName" validate={maxLength20} required />
    <p className={styles.memo}>事業所設定や通知に示される名称です。20文字以内で入力してください。</p>
  </div>
);

export default OfficeSection;
