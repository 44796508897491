import React, { Component } from 'react';
import classNames from 'classnames';
import MemoList from 'src/features/Memos';

export default class EmployeeTableRow extends Component {
  render() {
    const { employee, onClick, isMainOfficeOnly } = this.props;
    return (
      <React.Fragment>
        <tr onClick={onClick} className={classNames('table-hover')}>
          <td>
            <MemoList employeeId={employee.id} />
          </td>
          <td>{employee.staffCode}</td>
          <td>
            {employee.lastName} {employee.firstName}
          </td>
          {!isMainOfficeOnly && <td>{employee.officeManageName}</td>}
          <td>{employee.employeeClassificationName}</td>
          <td>{employee.employeeInsuranceKbn}</td>
        </tr>
      </React.Fragment>
    );
  }
}
