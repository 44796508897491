import axios from 'axios';
import { startSubmit, change as formChange } from 'redux-form';
import { CLIENT_UPDATE_ELECTTONIC_DELIVERY_AGREEMENT_SETTING } from 'src/constants/EndpointUrls';
import { setGlobalSuccesses } from 'src/reducers/global';
import { CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM } from 'src/constants/FormNames';

export const updateElectronicDeliveryAgreementSetting = lockVersion => async dispatch => {
  try {
    dispatch(startSubmit(CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM));
    await axios.put(CLIENT_UPDATE_ELECTTONIC_DELIVERY_AGREEMENT_SETTING, { lock_version: lockVersion });
    dispatch(setGlobalSuccesses('保存しました'));
    // flushのためsleep入れる
    await new Promise(r => setTimeout(r, 1000));
    // 画面でlock_version再取得のためリロード
    window.location.reload();
  } catch (e) {
    // 排他以外でエラーになる可能性がないのでリロードメッセージ追加しておく
    const messages = [...e.response.data.errors.messages, 'ブラウザをリロードして再度お試しください。'];
    dispatch(formChange(CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM, 'errors', messages));
  }
};

// lintでPrefer default exportに引っかかるので追加
export default function() {}
