import { combineReducers } from 'redux';
import { push } from 'connected-react-router';
import {
  getFormValues,
  formValueSelector,
  initialize as initializeForm,
  change as changeForm,
  startSubmit,
  stopSubmit,
  blur
} from 'redux-form';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import _ from 'lodash';
import axios from 'axios';
import humps from 'humps';
import moment from 'moment';
import qs from 'qs';

import * as Urls from 'src/constants/EndpointUrls';
import { serializeHttpGetParams, concatParamsToUrl, redirectTo } from 'src/utils/Http';
import { paramsWithFile } from 'src/utils/Form';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import {
  newItemFailure,
  editItemFailure,
  getItemErrors,
  setItemError,
  setItemExtra,
  getItemExtra,
  getItem,
  setItem,
  mergeItemFields
} from 'src/reducers/common/itemReducer';
import {
  getListQueries,
  fetchList,
  fetchListSuccess,
  fetchListFailure,
  setExtras,
  getExtras,
  setQueries,
  addQuery
} from 'src/reducers/common/listReducer';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { setOptions, getSelectOptions } from 'src/reducers/selectOptions';
import { fetchLatestJobStatus, isJobIncomplete } from 'src/reducers/JobStatus';
import { setGlobalErrors, setGlobalSuccesses, setGlobalWarnings } from 'src/reducers/global';

import {
  EMPLOYEE_PAY_CSV_FORM,
  EMPLOYEE_SEARCH_FORM,
  EMPLOYEE_PAY_FORM,
  INHABITANT_TAX_FB_DATA_FORM,
  PAYS_SEARCH_FORM
} from 'src/constants/FormNames';
import {
  takePaysSearchQueries,
  takePaymentRangeFormSearchForm,
  FORM_NAME_PAYS_SEARCH,
  fetchUniquePaymentDates
} from 'src/reducers/searchForm';
import { scrollToTop } from 'src/utils/Utils';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';

export const REDUCER_NAME = 'employeePays';
export const getEmployeePayId = state => getItem(REDUCER_NAME, state).employeePay.id;
export const getEmployeePayIsConfirmed = state => getItem(REDUCER_NAME, state).employeePay.isConfirmed;
export const getPaymentMonthConfirmed = state => getItem(REDUCER_NAME, state).paymentMonthConfirmed;
export const getAllowanceItems = state => getItem(REDUCER_NAME, state).allowanceItems;
export const getAllEmployeePays = state => getItem(REDUCER_NAME, state).allEmployeePays;
export const setCurrentPayId = payId => setItemExtra(REDUCER_NAME, { CurrentPayId: payId });
export const getCurrentPayId = state => getItemExtra(REDUCER_NAME, state).CurrentPayId;
export const setEmployeePaysQueries = queries => setQueries(REDUCER_NAME, queries);
export const getEmployeePayQueries = state => getListQueries(REDUCER_NAME, state);
export const getEmployeePayListExtras = state => getExtras(REDUCER_NAME, state);
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));
export const getEmployeePayItemErrors = state => getItemErrors(REDUCER_NAME, state);
export const getEmployeePayCsvFormValues = state => getFormValues(EMPLOYEE_PAY_CSV_FORM)(state);

export const getDownloadPayrollFBDataErrors = state => getItemExtra(REDUCER_NAME, state).payrollFBErrors;
export const getDownloadInhabitantTaxFBDataErrors = state => getItemExtra(REDUCER_NAME, state).inhabitantTaxFBErrors;
export const getConfirmPayrollGroupErrors = state => getItemExtra(REDUCER_NAME, state).changePayrollGroupErrors;
export const getChangePaymentDateErrors = state => getItemErrors(REDUCER_NAME, state).changePaymentDate;

export const setDownloadPayrollFBDataErrors = payrollFBErrors => setItemExtra(REDUCER_NAME, { payrollFBErrors });
export const setDownloadInhabitantTaxFBDataErrors = inhabitantTaxFBErrors =>
  setItemExtra(REDUCER_NAME, { inhabitantTaxFBErrors });
export const clearDownloadPayrollFBDataErrors = () => setItemExtra(REDUCER_NAME, { payrollFBErrors: '' });
export const clearDownloadInhabitantTaxFBDataErrors = () => setItemExtra(REDUCER_NAME, { inhabitantTaxFBErrors: '' });
export const setConfirmPayrollGroupErrors = changePayrollGroupErrors =>
  setItemExtra(REDUCER_NAME, { changePayrollGroupErrors });
export const getConfirmJobs = state => getExtras(REDUCER_NAME, state).confirmJobs;

// Async Action Creators
export const fetchEmployeePays = queries => async dispatch => {
  try {
    dispatch(blur(FORM_NAME_PAYS_SEARCH, 'uniquePaymentDate', _.get(queries, 'uniquePaymentDate'), true));
    dispatch(fetchList(REDUCER_NAME));

    const response = await axios.get(`${Urls.SEARCH_EMPLOYEE_PAY_URL}`, { params: queries });
    const {
      totalCount = 0,
      displayFrom = 0,
      displayTo = 0,
      isConfirmedCount = 0,
      confirmed = false
    } = response.data.payload;
    const payload = {
      data: response.data.payload.employeePays,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo,
      isConfirmedCount,
      confirmed
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(REDUCER_NAME, response.data.payload.targetPeriod));
    dispatch(setExtras(MEMO_REDUCER_NAME, response.data.payload.memos));
  } catch (exception) {
    let msg = ['一覧の取得に失敗しました'];
    if (!_.isEmpty(exception.response) && !_.isEmpty(exception.response.data.errors)) {
      msg = exception.response.data.errors.messages;
    }
    dispatch(fetchListFailure(REDUCER_NAME, msg));
    dispatch(setExtras(REDUCER_NAME, {}));
  }
};

export const fetchEmployeePayDetail = payId => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));

    const requestUrl = concatParamsToUrl(Urls.SEARCH_DETAIL_EMPLOYEE_PAY_URL, { id: payId });
    const response = await axios.get(requestUrl);
    const responseValues = response.data.payload.values;

    // 前回とレスポンスの中身が同一である場合、stateの中身を変えておかないと初期化後のredux-formの値の反映がうまくいかなくなる。
    dispatch(setItem(REDUCER_NAME, responseValues));
    // 現在redux-formにセットされている値を初期化する
    dispatch(initializeForm('employeePay'));
    dispatch(
      mergeItemFields(REDUCER_NAME, {
        ...responseValues,
        employeePay: { ...responseValues.employeePay, customizeAllowances: responseValues.customizeAllowances }
      })
    );
    dispatch(fetchListSuccess(REDUCER_NAME, {}));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
    dispatch(setItemError(REDUCER_NAME, exception.response.data.errors.messages)); // 給与詳細ではitemの方を見るのでitemにもerrorを設定
    dispatch(setExtras(REDUCER_NAME, {}));
  }
};

export const fetchPayWithEmployeeCondition = data => async (dispatch, getState) => {
  try {
    // Get parameter from form data
    const {
      nameCnt,
      groupIds,
      employmentTypeIds,
      occupationIds,
      payrollRulesGroup,
      positionIds,
      noMemo,
      otherSearch
    } = data;
    let { memoColorIds } = data;
    if (memoColorIds) {
      memoColorIds = Object.keys(memoColorIds)
        .map(key => (memoColorIds[key] ? key : null))
        .toString();
    }
    // Current query
    const originQueries = getListQueries(REDUCER_NAME, getState());
    // 1ページ目から検索
    const newQueries = {
      ...originQueries,
      nameCnt,
      groupIds,
      employmentTypeIds,
      occupationIds,
      payrollRulesGroup,
      positionIds,
      noMemo,
      memoColorIds,
      otherSearch,
      page: 1
    };
    dispatch(setQueries(REDUCER_NAME, newQueries));
    // Re fetch employee bonus
    dispatch(fetchEmployeePays());
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editEmployeePay = (data, employeePayId) => async dispatch => {
  try {
    const httpBody = {
      ...data,
      customizeAllowances: data.employeePay.customizeAllowances
    };

    dispatch(startSubmit(EMPLOYEE_PAY_FORM));
    const updatePayUrl = concatParamsToUrl(Urls.UPDATE_EMPLOYEE_PAY_URL, { id: employeePayId });
    await axios.put(updatePayUrl, httpBody);
    // const editPayUrl = concatParamsToUrl(Urls.EMPLOYEE_PAY_EDIT, { id: employeePayId });
    // // TODO 編集画面に遷移させているが本来は詳細画面に遷移させる
    // redirectTo(editPayUrl);

    // Reload to update to the newest data
    // and keep tracking list employee pays to select next employee
    window.location.reload();
  } catch (exception) {
    scrollToTop();
    dispatch(stopSubmit(EMPLOYEE_PAY_FORM));
    dispatch(setItemError(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const downloadPayrollFBData = params => async dispatch => {
  try {
    dispatch(clearDownloadPayrollFBDataErrors());

    const query = qs.stringify(humps.decamelizeKeys({ ...params }), { arrayFormat: 'brackets' });
    // ここは一見すると2回同じ処理を叩いてるように見えるが、redirectTo(中身はlocation.href)だとerrorをキャッチできないため、
    // 先に1回xhrでリクエストしている
    const url = `${Urls.DOWNLOAD_PAYROLL_FB_DATA}?${query}`;
    await axios.get(url);
    redirectTo(url);
  } catch (e) {
    const errs = _.get(e, ['response', 'data', 'errors', 'messages']) || [e.message];
    dispatch(setDownloadPayrollFBDataErrors(errs));
  }
};

export const downloadInhabitantTaxFBData = (params, payrollGroupSelectorType) => async (dispatch, getState) => {
  try {
    dispatch(clearDownloadInhabitantTaxFBDataErrors());
    let data = { ...params };
    const { payForRetirement, ...rest } = params;
    if (!+payForRetirement) data = rest;

    if (payrollGroupSelectorType === 'each_payroll_rules_group') {
      data.yearMonth = data.uniquePaymentDate;
      data.payrollRulesGroups = data.uniquePayrollRuleGroups;
      const obj = _.find(
        getSelectOptions(getState(), 'uniquePayrollRuleGroups'),
        item => item.value === data.uniquePayrollRuleGroups
      );
      data.closingDay = obj.closingDay;
      data.paymentDay = obj.paymentDay;
      data.paymentMonthType = obj.paymentMonthType;
    }

    const query = qs.stringify(humps.decamelizeKeys(data), { arrayFormat: 'brackets' });
    const url = `${Urls.DOWNLOAD_INHABITANT_TAX_FB_DATA}?${query}`;
    const response = await fetch(url);
    if (!response.ok) {
      const json = await response.json();
      throw new Error(json.errors.messages);
    }
    // SelectOptionsの設定を画面で選択していたものに書き換える
    const banks = getSelectOptions(getState(), 'clientBanks');
    const bank = banks.find(element => element.value === Number(data.transferFromBankId));
    bank.outputOrdinanceDesignatedCity = data.outputOrdinanceDesignatedCity;
    bank.lineFeed = data.line_feed;
    bank.endOfFileLineFeed = data.end_of_file_line_feed;
    dispatch(
      setOptions({
        clientBanks: banks
      })
    );
    const responseBlob = await response.blob();
    const blob = new Blob([responseBlob], { type: 'text/csv' });
    const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
    saveAs(blob, disposition.parameters.filename);
  } catch (e) {
    // Errorオブジェクトの引数は文字列。配列を渡すとカンマ区切りの文字列に変換されるので再度変換
    const errs = e.message.split(',');
    dispatch(setDownloadInhabitantTaxFBDataErrors(errs));
  }
};

export const assignBankInfo = bankId => async (dispatch, getState) => {
  const bank = getSelectOptions(getState(), 'clientBanks').find(element => element.value === Number(bankId));
  dispatch(
    changeForm(INHABITANT_TAX_FB_DATA_FORM, 'outputOrdinanceDesignatedCity', bank.outputOrdinanceDesignatedCity)
  );
  dispatch(changeForm(INHABITANT_TAX_FB_DATA_FORM, 'line_feed', bank.lineFeed));
  dispatch(changeForm(INHABITANT_TAX_FB_DATA_FORM, 'end_of_file_line_feed', bank.endOfFileLineFeed));
};

// default clientPayrollGroup from select option
// when search get client payrollgroup from extras
export const getClientPayrollRulesGroups = state => {
  let clientPayrollRulesGroups = getSelectOptions(state, 'clientPayrollRulesGroups');
  const extras = getEmployeePayListExtras(state);
  if (!!extras && !!extras.clientPayrollRulesGroups) {
    clientPayrollRulesGroups = extras.clientPayrollRulesGroups;
  }
  return clientPayrollRulesGroups;
};

export const changeLock = (employeePayId, currentConfirmed) => async dispatch => {
  try {
    const changeLockUrl = currentConfirmed ? Urls.EMPLOYEE_PAY_UNLOCK : Urls.EMPLOYEE_PAY_LOCK;
    await axios.put(changeLockUrl, { id: employeePayId });

    redirectTo(concatParamsToUrl(Urls.EMPLOYEE_PAY_EDIT, { id: employeePayId }));
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

const fetchJobInfo = (state, params) => {
  const uniquePayrollRuleGroups = getSelectOptions(state, 'uniquePayrollRuleGroups');
  const uniquePaymentDates = getSelectOptions(state, 'uniquePaymentDates');
  const { payrollRulesGroups, yearMonth } = params;

  const payrollRuleGroup = uniquePayrollRuleGroups.find(i => i.value === payrollRulesGroups);
  const paymentDate = uniquePaymentDates.find(i => i.value === yearMonth);

  return `${payrollRuleGroup.label} ${paymentDate.label}`;
};

export const fetchConfirmationPayStatus = (visibleOnly = false) => async dispatch => {
  try {
    const resp = await axios.get(`${Urls.EMPLOYEE_CONFIRM_STATUS}?visible_only=${visibleOnly}`);
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const pollFetchVisibleConfirmPayJobs = (interval = INITIAL_POLLING_INTERVAL) => async (dispatch, getState) => {
  await dispatch(fetchConfirmationPayStatus(true));
  const confirmJobs = getConfirmJobs(getState()) || {};
  if (
    confirmJobs &&
    (Object.keys(confirmJobs).includes('inProgress') || Object.keys(confirmJobs).includes('waiting'))
  ) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(pollFetchVisibleConfirmPayJobs(nextInterval));
    }, nextInterval);
  } else {
    const payrollRulesGroups = formValueSelector(FORM_NAME_PAYS_SEARCH)(getState(), 'uniquePayrollRuleGroups');
    dispatch(fetchUniquePaymentDates(FORM_NAME_PAYS_SEARCH, payrollRulesGroups, false));
  }
};

const generateEmployeePayParams = (data, currentState) => {
  const { releaseDate = '', releaseHour = '' } = data;
  const queries = takePaymentRangeFormSearchForm(currentState, FORM_NAME_PAYS_SEARCH);

  return {
    ...queries,
    ...data,
    releaseDate: releaseDate.concat(' ').concat(releaseHour),
    jobInfo: fetchJobInfo(currentState, { ...queries, ...data })
  };
};

export const confirmEmployeePays = data => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const params = generateEmployeePayParams(data, currentState);
    await axios.put(Urls.EMPLOYEE_PAY_CONFIRM, { ...params, jobInfo: fetchJobInfo(currentState, params) });
    dispatch(setGlobalWarnings('給与確定を処理中です。しばらくお待ちください。'));
    dispatch(pollFetchVisibleConfirmPayJobs());
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const unconfirmEmployeePays = data => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const params = generateEmployeePayParams(data, currentState);
    await axios.put(Urls.EMPLOYEE_PAY_UNCONFIRM, { ...params, jobInfo: fetchJobInfo(currentState, params) });
    dispatch(setGlobalSuccesses('給与データの編集が可能になりました'));
    dispatch(pollFetchVisibleConfirmPayJobs());
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const confirmOne = empId => async dispatch => {
  try {
    await axios.put(Urls.EMPLOYEE_PAY_CONFIRM_ONE, {
      employee_pays: empId
    });
    dispatch(setGlobalSuccesses('給与を確定しました。'));
    dispatch(fetchEmployeePayDetail(empId));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const unconfirmOne = empId => async dispatch => {
  try {
    await axios.put(Urls.EMPLOYEE_PAY_UNCONFIRM_ONE, {
      employee_pays: empId
    });
    dispatch(setGlobalSuccesses('給与の確定を解除しました。'));
    dispatch(fetchEmployeePayDetail(empId));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const unconfirmAll = (payrollRulesGroups, yearMonth) => async (dispatch, getState) => {
  try {
    const obj = _.find(
      getSelectOptions(getState(), 'uniquePayrollRuleGroups'),
      item => item.value === payrollRulesGroups
    );
    await axios.put(Urls.EMPLOYEE_PAY_UNCONFIRM_ALL, {
      payroll_rules_groups: payrollRulesGroups,
      year_month: yearMonth,
      closingDay: obj.closingDay,
      paymentDay: obj.paymentDay,
      paymentMonthType: obj.paymentMonthType
    });
    dispatch(setGlobalSuccesses('給与の確定を解除しました。'));
    await new Promise(resolve => setTimeout(resolve, 2000));
    window.location.reload();
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const setInvisibleConfimPayJob = confirmPayJobIds => async dispatch => {
  try {
    const resp = await axios.put(Urls.EMPLOYEE_CONFIRM_SET_CONFIRM_JOB_INVISIBLE, { jobIds: confirmPayJobIds });
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
    dispatch(fetchConfirmationPayStatus(true));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const setConfirmJobsExtras = confirmJobs => dispatch => {
  dispatch(setExtras(REDUCER_NAME, { confirmJobs }));
};

export const rollbackEmployeePay = employeePayId => async dispatch => {
  try {
    const rollbackEmployeePayUrl = Urls.EMPLOYEE_PAY_ROLLBACK;
    await axios.put(rollbackEmployeePayUrl, { id: employeePayId });

    dispatch(fetchEmployeePayDetail(employeePayId));
  } catch (exception) {
    const msgs = _.get(exception, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const allRollbackEmployeePay = (payrollRulesGroups, yearMonth) => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const obj = getSelectOptions(currentState, 'uniquePayrollRuleGroups').find(
      item => item.value === payrollRulesGroups
    );
    const params = {
      payrollRulesGroups,
      yearMonth,
      closingDay: obj.closingDay,
      paymentDay: obj.paymentDay,
      paymentMonthType: obj.paymentMonthType
    };
    const allRollbackEmployeePayUrl = Urls.EMPLOYEE_PAY_ALL_ROLLBACK;
    await axios.put(allRollbackEmployeePayUrl, { ...params, jobInfo: fetchJobInfo(currentState, params) });

    window.location.reload();
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, { rollbackEmployeePay: exception.response.data.errors.messages }));
  }
};

export const changePaymentDate = (data, hideModal) => async (dispatch, getState) => {
  try {
    const paymentDate = data.paymentDate;
    const currentPaymentDate = getExtras(REDUCER_NAME, getState()).paymentDate;

    const currentState = getState();
    const queries = takePaymentRangeFormSearchForm(currentState, FORM_NAME_PAYS_SEARCH);
    const { payrollRulesGroups = '', closingDay, paymentDay, paymentMonthType } = queries;

    const changeParams = {
      paymentDate,
      currentPaymentDate,
      closingDay,
      paymentDay,
      paymentMonthType
    };
    const changePaymentDateEmployeePayUrl = Urls.EMPLOYEE_PAY_CHANGE_PAYMENT_DATE;
    await axios.put(changePaymentDateEmployeePayUrl, { changeParams });

    const date = moment(data.paymentDate).format('YYYY_MM_DD');
    dispatch(changeForm(FORM_NAME_PAYS_SEARCH, 'uniquePaymentDate', date));
    dispatch(fetchUniquePaymentDates(FORM_NAME_PAYS_SEARCH, payrollRulesGroups, false, false));
    hideModal();
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, { changePaymentDate: exception.response.data.errors.messages }));
  }
};

export const resetPayrollRulesGroup = newPayrollRulesGroups => (dispatch, getState) => {
  const { payrollRulesGroup } = takePaymentRangeFormSearchForm(getState(), FORM_NAME_PAYS_SEARCH);
  if (payrollRulesGroup && !newPayrollRulesGroups.split('_').includes(`${payrollRulesGroup}`)) {
    dispatch(addQuery(REDUCER_NAME, 'payrollRulesGroup', undefined));
    dispatch(changeForm(EMPLOYEE_SEARCH_FORM, 'payrollRulesGroup', ''));
  }
};

// 給与振込一覧表
export const getShowPayFbDataUrl = state => {
  const queries = takePaymentRangeFormSearchForm(state, FORM_NAME_PAYS_SEARCH);
  const params = serializeHttpGetParams(queries);
  const payFbDataUrl = `${Urls.SHOW_PAY_FB_DATA_PAGE_URL}?${params}`;
  return payFbDataUrl;
};

export const fetchPayFbData = queries => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    dispatch(setExtras(REDUCER_NAME, { totalTransferAmount: 0 }));

    const params = serializeHttpGetParams({
      payroll_rules_groups: queries.payroll_rules_groups,
      year_month: queries.year_month,
      closing_day: queries.closing_day,
      payment_day: queries.payment_day,
      payment_month_type: queries.payment_month_type,
      sort: _.get(queries, 'sort', 'staffCode'),
      order: _.get(queries, 'order', 'asc')
    });
    const fetchUrl = `${Urls.SEARCH_PAY_FB_DATA}?${params}`;
    const response = await axios.get(fetchUrl);

    const payload = { data: response.data.payload.employeePays, pageCount: 0 }; // ページは使わない
    const targetPeriod = response.data.payload.targetPeriod;
    const totalTransferAmount = response.data.payload.totalTransferAmount;

    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(REDUCER_NAME, { ...targetPeriod, totalTransferAmount }));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export const fetchInhabitantTaxData = (formValues, isFetchGrouped) => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    let params = null;
    if (isFetchGrouped) {
      params = {
        grouped_payment_months: formValues.groupedPaymentMonths
      };
    } else {
      params = takePaysSearchQueries(formValues);
    }

    const fetchUrl = `${Urls.SEARCH_INHABITANT_TAX_DATA}?${serializeHttpGetParams(params)}`;
    const response = await axios.get(fetchUrl);

    const employeePays = response.data.payload.employeePays;
    const payload = { data: employeePays, pageCount: 0 }; // ページは使わない

    const { totalAmounts, totalEmployees, targetPeriod, totalResigned, cityIds } = response.data.payload;
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(REDUCER_NAME, { totalAmounts, totalEmployees, targetPeriod, totalResigned, cityIds }));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const getDownloadPayCsvJobs = state => getExtras(REDUCER_NAME, state).downloadPayCsvJobs;

export const fetchDownloadPayCsvJobs = visibleOnly => async dispatch => {
  try {
    const resp = await axios.get(`${Urls.FETCH_DOWNLOAD_PAY_CSV_JOB_STATUS}?visible_only=${visibleOnly}`);
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const getCompletedDownloadPayCsvJob = state => getExtras(REDUCER_NAME, state).completedDownloadPayCsvJob;

export const setCompletedDownloadPayCsvJob = job => async dispatch => {
  dispatch(setExtras(REDUCER_NAME, { completedDownloadPayCsvJob: job }));
};

export const fetchCompletedDownloadPayCsvJob = queries => async dispatch => {
  try {
    const resp = await axios.get(Urls.FETCH_COMPLETED_DOWNLOAD_PAY_CSV_JOB_STATUS, {
      params: humps.decamelizeKeys(queries)
    });
    dispatch(setCompletedDownloadPayCsvJob(resp.data.payload.downloadPayCsvJob));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const setDownloadPayCsvJobInvisible = taskIds => async dispatch => {
  try {
    const resp = await axios.put(Urls.SET_DOWNLOAD_PAY_CSV_JOB_INVISIBLE, { jobIds: taskIds });
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
    dispatch(fetchDownloadPayCsvJobs(true));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

// 会計連携用CSVダウンロード
export const downloadAccountingPayCsv = () => async (dispatch, getState) => {
  try {
    const yearMonth = formValueSelector(PAYS_SEARCH_FORM)(getState(), 'uniquePaymentDate');
    const payrollRulesGroups = formValueSelector(PAYS_SEARCH_FORM)(getState(), 'uniquePayrollRuleGroups');
    const params = { yearMonth, payrollRulesGroups };
    redirectTo(`${Urls.DOWNLOAD_ACCOUNTING_PAY_CSV}?${serializeHttpGetParams(params)}`);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

// send request to server action
// Download template Csv
export const downloadTemplateCsv = () => async (dispatch, getState) => {
  try {
    const formValues = getFormValues(EMPLOYEE_PAY_CSV_FORM)(getState()) || {};
    const queries = getEmployeePayQueries(getState());
    const params = { ...queries, ...formValues };
    dispatch(clearErrors());
    const resp = await axios.post(Urls.DOWNLOAD_EMPLOYEE_PAY_CSV, params);
    if (!_.isEmpty(resp.data) && !!_.get(resp, ['data', 'payload', 'downloadUrl'])) {
      redirectTo(resp.data.payload.downloadUrl);
    } else {
      dispatch(setGlobalWarnings('給与の支給・控除項目のデータファイルを処理中です。しばらくお待ちください。'));
      dispatch(fetchDownloadPayCsvJobs(true));
    }
  } catch (exception) {
    const errors = _.get(exception, ['response', 'data', 'errors', 'messages']) || exception.message;
    dispatch(setGlobalErrors(errors));
  }
};

export const getImportPayCsvJobs = state => getExtras(REDUCER_NAME, state).importPayCsvJobs;

export const fetchImportPayCsvJobs = visibleOnly => async dispatch => {
  try {
    const resp = await axios.get(`${Urls.FETCH_IMPORT_CSV_JOB_STATUS}?visible_only=${visibleOnly}`);
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

export const setImportPayCsvJobInvisible = taskIds => async dispatch => {
  try {
    const resp = await axios.put(Urls.SET_IMPORT_CSV_JOB_INVISIBLE, { jobIds: taskIds });
    dispatch(setExtras(REDUCER_NAME, { ...resp.data.payload }));
    dispatch(fetchImportPayCsvJobs(true));
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(msgs)) {
      dispatch(setGlobalErrors(msgs));
    }
  }
};

// Upload modified Csv
export const importEmployeePayCsv = data => async (dispatch, getState) => {
  try {
    const { yearMonth, payrollRulesGroups } = getEmployeePayQueries(getState());
    const params = paramsWithFile(data, ['employee_pay_csv']);
    params.append('year_month', yearMonth);
    params.append('payroll_rules_groups', payrollRulesGroups);
    params.append('job_info', data.jobInfo);
    await axios.post(Urls.IMPORT_EMPLOYEE_PAY_CSV, params);
    dispatch(clearErrors());
    dispatch(setGlobalWarnings('給与データをインポート中です。しばらくお待ちください。'));
    dispatch(fetchImportPayCsvJobs(true));
  } catch (exception) {
    scrollToTop();
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

// 公開日を変更
export const updatePayReleaseDate = (data, callbackOnSuccess) => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const queries = takePaymentRangeFormSearchForm(currentState, FORM_NAME_PAYS_SEARCH);
    const { payrollRulesGroups = '' } = queries;
    const { releaseDate = '', releaseHour = '' } = data;

    if (_.isEmpty(releaseDate) || _.isEmpty(releaseHour)) {
      dispatch(setGlobalErrors('公開日または通知メールを確認してください'));
      return;
    }

    const params = {
      ...queries,
      ...data,
      releaseDate: `${releaseDate} ${releaseHour}`
    };
    await axios.put(Urls.CHANGE_RELEASE_DATE_EMPLOYEE_PAYS, params);

    dispatch(setGlobalSuccesses('公開日を変更しました。'));
    dispatch(fetchUniquePaymentDates(FORM_NAME_PAYS_SEARCH, payrollRulesGroups, false, false));
    if (typeof callbackOnSuccess === 'function') callbackOnSuccess();
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']) || e.message;
    dispatch(setGlobalErrors(msgs));
  }
};

// PDF一括印刷
// 一括作成で作成されたものを印刷する
export const fetchPayReportsPdf = downloadId => async dispatch => {
  try {
    const url = concatParamsToUrl(Urls.BULK_PRINT_EMPLOYEE_PAY_REPORTS, { downloadId });
    const w = window.open(url, '', 'toolbar=0,menubar=0,location=0');
    if (_.isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print();
      });
    }
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。ページをリロードしてください。'));
  }
};

// PDF一括ダウンロード
// 一括作成で作成されたものをダウンロードする
export const fetchPayReportsZip = downloadId => async dispatch => {
  try {
    const url = concatParamsToUrl(Urls.BULK_DOWNLOAD_EMPLOYEE_PAY_REPORTS, { downloadId });
    redirectTo(url);
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。ページをリロードしてください。'));
  }
};

export const fetchPayReportJobStatus = (objId, yearMonth) => async (dispatch, getState) => {
  try {
    const uniquePaymentDates = getSelectOptions(getState(), 'uniquePaymentDates');
    const response = await axios.get(`/clients/employee_pay_reports/${objId}/fetch_status`);
    const downloadObj = response.data.payload.downloadObj;
    const newPaymentDates = _.map(uniquePaymentDates, item => {
      if (item.value !== yearMonth) return item;
      return { ...item, downloadObj };
    });
    dispatch(setOptions({ uniquePaymentDates: newPaymentDates }));
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。ページをリロードしてください。'));
  }
};

export const pollFetchPayReportJobStatus = (downloadObj, yearMonth, interval = INITIAL_POLLING_INTERVAL) => async (
  dispatch,
  getState
) => {
  await dispatch(fetchPayReportJobStatus(downloadObj.id, yearMonth));
  const obj = _.find(getSelectOptions(getState(), 'uniquePaymentDates'), item => item.value === yearMonth).downloadObj;
  const isReportJobPolling = { ...getExtras(REDUCER_NAME, getState()).isReportJobPolling } || {};
  if (!_.isEmpty(obj) && (obj.status === 'waiting' || obj.status === 'running')) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(pollFetchPayReportJobStatus(obj, yearMonth, nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { isReportJobPolling: { ...isReportJobPolling, [obj.id]: true } }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { isReportJobPolling: { ...isReportJobPolling, [obj.id]: false } }));
  }
};

// PDF一括作成
export const setPayReportDownloadJob = (payrollRulesGroups, yearMonth, hideModal) => async (dispatch, getState) => {
  try {
    const obj = _.find(
      getSelectOptions(getState(), 'uniquePayrollRuleGroups'),
      item => item.value === payrollRulesGroups
    );
    const params = {
      closingDay: obj.closingDay,
      paymentDay: obj.paymentDay,
      paymentMonthType: obj.paymentMonthType,
      paymentDate: yearMonth
    };
    const response = await axios.post(Urls.CREATE_EMPLOYEE_PAY_REPORTS, params);
    const objId = response.data.payload.downloadObj.id;
    dispatch(fetchPayReportJobStatus(objId, yearMonth));
    hideModal();
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。ページをリロードしてください。'));
  }
};

// URLのpathname変更
export const changeLocationPathname = (url, params) => async (dispatch, getState) => {
  const state = getState();

  dispatch(push({ ...state.router.location, pathname: concatParamsToUrl(url, params) }));
};

export const fetchOfficeStationJobs = (interval = INITIAL_POLLING_INTERVAL) => async (dispatch, getState) => {
  const params = {
    job_name: 'officeStationEntryPayJob'
  };

  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.officeStationEntryPayJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchOfficeStationJobs(nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
  }
};

export const hideOfficeStationJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'officeStationEntryPayJob'
    });
    dispatch(fetchOfficeStationJobs());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const exportToOfficeStation = data => async (dispatch, getState) => {
  try {
    const state = getState();
    const queries = takePaymentRangeFormSearchForm(state, FORM_NAME_PAYS_SEARCH);
    const obj = _.find(
      getSelectOptions(state, 'uniquePayrollRuleGroups'),
      item => item.value === queries.payrollRulesGroups
    );
    const params = {
      ...queries,
      ...data,
      closingDay: obj.closingDay,
      paymentDay: obj.paymentDay,
      paymentMonthType: obj.paymentMonthType
    };
    await axios.post(Urls.EXPORT_PAY_TO_OFFICE_STATION, { ...params });
    dispatch(fetchOfficeStationJobs());
  } catch (e) {
    if (_.get(e, 'response.data.errors')) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      dispatch(setGlobalErrors('システムエラーが発生しました。'));
    }
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
