import React from 'react';
import { Section } from 'jbc-front/components/Form';
import { getOptionLabel, display } from 'src/utils/Utils';
import DefaultValue from './DefaultValue';
import tableStyles from '../styles/GeneralTableInfo.scss';

const BankItem = ({ bankItem, index, depositTypes }) => (
  <div>
    <Section title={`口座情報 ${index + 1}`}>
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          {bankItem.isUseToTransfer && (
            <tr>
              <th>振込口座に使用する</th>
              <td />
            </tr>
          )}
          <tr>
            <th>銀行：</th>
            <td>{display(bankItem.bankName)}</td>
          </tr>
          <tr>
            <th>銀行コード：</th>
            <td>{display(bankItem.bankCode)}</td>
          </tr>
          <tr>
            <th>支店：</th>
            <td>{display(bankItem.branchName)}</td>
          </tr>
          <tr>
            <th>支店コード：</th>
            <td>{display(bankItem.branchCode)}</td>
          </tr>
          <tr>
            <th>預金種別：</th>
            <td>{display(getOptionLabel(bankItem.depositType)(depositTypes))}</td>
          </tr>
          <tr>
            <th>口座番号：</th>
            <td>{display(bankItem.accountNumber)}</td>
          </tr>
          <tr>
            <th>口座名義（カナ）：</th>
            <td>{display(bankItem.accountHolderKana)}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const BanksSectionShow = ({ employeeBanks, depositTypes }) => {
  if (employeeBanks && employeeBanks.length > 0)
    return (
      <div>
        {employeeBanks.map((item, index) => (
          <BankItem bankItem={item} key={item.id} index={index} depositTypes={depositTypes} />
        ))}
      </div>
    );
  return <DefaultValue />;
};

export default BanksSectionShow;
