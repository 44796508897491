import React from 'react';

import FormulaSettings from '../containers/FormulaSettingsContainer';
import TargetSettings from './TargetSettings';

const AllowancePaymentFormula = () => (
  <div>
    <FormulaSettings />
    <TargetSettings allowanceType="payment" />
  </div>
);

export default AllowancePaymentFormula;
