import React from 'react';

import AccountRegistrationForm from './components/AccountRegistrationForm';
import styles from './components/registration.scss';

const NewAccountRegistration = () => (
  <div>
    <div className="l-title-wrap">
      <h1 className="m-title-main">アカウント登録</h1>
      <p className={styles.lead}>
        「試しに利用したい方」「すぐに導入したい方」<br />すべての機能を利用できる無料アカウントの登録をご利用ください
      </p>
      <p className={styles.notes}>
        ※ キャンペーン内容に関しましては、アカウント発行後別途メールにてご案内差し上げます。
      </p>
    </div>
    <div className={styles.columnWrap}>
      <div className={styles.columnFlow}>
        <h2 className="m-title-sub u-ta-c u-pt0">無料アカウント登録の流れ</h2>
        <div className={styles.flowWrap}>
          <dl className={styles.flowItem}>
            <dt className={styles.flowHead}>1. アカウント登録</dt>
            <dd className={styles.flowBody}>登録後、メールにてログイン情報を送ります</dd>
          </dl>
          <dl className={styles.flowItem}>
            <dt className={styles.flowHead}>2. 初期設定</dt>
            <dd className={styles.flowBody}>必要な情報を入力します</dd>
          </dl>
          <dl className={styles.flowItem}>
            <dt className={styles.flowHead}>3. 30日間の利用</dt>
            <dd className={styles.flowBody}>すべての機能を30日間ご利用できます</dd>
          </dl>
          <dl className={styles.flowItem}>
            <dt className={styles.flowHead}>4. 期間終了後</dt>
            <dd className={styles.flowBody}>
              30日経過後は自動で無料プランに移行します。引き続きすべての機能をご利用いただける場合は有料プランの申込をお願いします。
            </dd>
          </dl>
        </div>
      </div>
      <div className={styles.columnForm}>
        <AccountRegistrationForm />
      </div>
    </div>
  </div>
);

export default NewAccountRegistration;
