import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import _ from 'lodash';

import { Pulldown } from 'jbc-front/components/icons';
import styles from './PdfExportMethod.scss';

const PdfExportMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };
    constructor(props) {
      super(props);

      this.state = {};
    }

    render() {
      const { isJobSuccess, searchParams } = this.props;
      const existSearchParams = !_.isEmpty(searchParams);

      return (
        <React.Fragment>
          <div className={styles.baloonWrap}>
            <div className={styles.baloon}>
              <ul className={styles.links}>
                <li>
                  <a
                    disabled={!existSearchParams}
                    className={existSearchParams ? null : styles.disabled}
                    onClick={() => {
                      this.props.openCreateModal();
                    }}
                    role="presentation"
                  >
                    <Pulldown size={8} />PDFデータを作成する
                  </a>
                </li>
                <li>
                  <a
                    disabled={!isJobSuccess}
                    className={isJobSuccess ? null : styles.disabled}
                    onClick={() => {
                      this.props.openDownloadModal();
                    }}
                    role="presentation"
                  >
                    <Pulldown size={8} />前回作成したファイルを出力する
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
);

export default PdfExportMethod;
