import React from 'react';
import { connect } from 'react-redux';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import { setItem } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME } from 'src/reducers/clients/commutingFeeSettings';

import EditClientCommutingFeeSettingForm from './containers/EditClientCommutingFeeSettingForm';

const ClientCommutingFeeSetting = ({ clientCommutingFeeSetting, dispatch }) => {
  if (clientCommutingFeeSetting) {
    dispatch(setItem(REDUCER_NAME, clientCommutingFeeSetting));
  }
  return (
    <div>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">通勤手当の上限設定</h1>
      </div>
      <div className="l-wrap-xs l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
            <BreadCrumb.Item label="通勤手当の上限設定" />
          </BreadCrumb>
        </div>
        <EditClientCommutingFeeSettingForm />
      </div>
    </div>
  );
};

export default connect()(ClientCommutingFeeSetting);
