import React from 'react';
import Button from 'jbc-front/components/Button';
import { BulkDownload, BulkPrint, BulkRecreate } from 'jbc-front/components/icons';
import CommonModal from 'jbc-front/components/CommonModal';
import styles from './PdfDownloadModal.scss';

const PdfDownloadModal = props => {
  const { isOpen, hideModal, downloadPdf, downloadZip, recreate } = props;
  const header = 'PDF出力';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <React.Fragment>
        <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
        <CommonModal.Body>
          <form>
            <p>算定基礎届データをダウンロード、印刷、再作成 のいずれかを選択してください。</p>
            <div className={styles.button_box}>
              <Button onClick={downloadZip} className={styles.button_frame}>
                <BulkDownload size={40} />
                <p>データをダウンロードする</p>
              </Button>
              <Button onClick={downloadPdf} className={styles.button_frame}>
                <BulkPrint size={40} />
                <p>データを印刷する</p>
              </Button>
              <Button onClick={recreate} className={styles.button_frame}>
                <BulkRecreate size={40} />
                <p>データを再作成する</p>
              </Button>
            </div>
          </form>
        </CommonModal.Body>
      </React.Fragment>
    </CommonModal>
  );
};

export default PdfDownloadModal;
