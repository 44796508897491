import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { JOIN_INSURANCE } from 'src/constants/Options';
import { DateField, BoxDouble, RadioField, NumberFields, TextField } from 'jbc-front/components/Form';
import { maxLength6, isDateStringFormat, isNumber } from 'src/utils/CustomValidators';
import { REDUCER_NAME, getEmployeeFormValues } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import selector from 'src/utils/Utils';

const PensionNumber = ({ required }) => (
  <NumberFields
    texts={['', ' - ']}
    lengths={[4, 6]}
    required={required}
    label="基礎年金番号"
    seperateChar=""
    indexOffset={1}
    prefix="pensionBasicNumber"
    exactLength
  />
);

const PensionInsuranceInsuredNumber = ({ required }) => (
  <TextField
    required={required}
    type="text"
    label="厚生年金保険被保険者整理番号"
    validate={[isNumber, maxLength6]}
    maxLength="6"
    name="pensionInsuranceInsuredNumber"
  />
);

const PensionInsuranceSection = ({ isJoinedPensionInsurance, hasTargetHealthInsuranceAssociations }) => (
  <div>
    <RadioField name="isJoinedPensionInsurance" options={JOIN_INSURANCE} />
    {(isJoinedPensionInsurance === true || isJoinedPensionInsurance === '1') && (
      <div>
        <PensionNumber />
        {hasTargetHealthInsuranceAssociations && <PensionInsuranceInsuredNumber />}
        <BoxDouble>
          <DateField
            required
            label="厚生年金保険の資格取得日"
            name="pensionCertificateAcquisitionOn"
            validate={isDateStringFormat}
          />
          <DateField
            label="厚生年金保険の資格喪失日"
            name="pensionCertificateExpiredOn"
            validate={isDateStringFormat}
          />
        </BoxDouble>
      </div>
    )}
  </div>
);

const mapStateToProps = state => ({
  isJoinedPensionInsurance: selector(getEmployeeFormValues(state), 'employeeInsurance', 'isJoinedPensionInsurance'),
  hasTargetHealthInsuranceAssociations: _.get(getItem(REDUCER_NAME, state), 'hasTargetHealthInsuranceAssociations')
});

export default connect(mapStateToProps)(PensionInsuranceSection);
