import React from 'react';
import get from 'lodash/get';
import { connect } from 'react-redux';

import { REDUCER_NAME, fetchSystemAnnounces, getSystemAnnounces } from 'src/reducers/system_announces/system_announces';
import {
  getListPageCount,
  getListPage,
  getListPerPage,
  addPageQuery,
  addPerPageQuery,
  getListTotalCount,
  getListDisplayFrom,
  getListDisplayTo,
  getIsLoading
} from 'src/reducers/common/listReducer';
import LoadingView from 'src/components/LoadingView';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { LowerPager } from 'src/components';
import ListGroup from 'jbc-front/components/ListGroup';
import { recordDisplay } from 'src/utils/Utils';
import styles from './SystemAnnounceList.scss';

const ShowListGroup = ({ systemAnnounces }) => (
  <ListGroup>
    <ListGroup.Title>お知らせ</ListGroup.Title>
    {get(systemAnnounces, 'length') > 0 ? (
      systemAnnounces.map(systemAnnounce => (
        <ListGroup.Item
          className={styles.list}
          key={systemAnnounce.id}
          {...(systemAnnounce.systemAnnounceType === 'link'
            ? { href: systemAnnounce.link, target: '_blank', as: 'a' }
            : { href: `/system_announces/${systemAnnounce.id}`, as: 'a' })}
        >
          <div className={styles.date}>{recordDisplay.dateOrTime(systemAnnounce.publishAt)}</div>
          <div className={styles.title}>{systemAnnounce.title}</div>
          <ListGroup.Icon />
        </ListGroup.Item>
      ))
    ) : (
      <ListGroup.Item>お知らせはまだありません</ListGroup.Item>
    )}
  </ListGroup>
);
class SystemAnnounceList extends React.Component {
  componentDidMount() {
    this.props.fetch();
  }

  render() {
    const {
      onChangePerPage,
      onPageChange,
      pageCount,
      initialPage,
      perPage,
      perPages,
      totalCount,
      displayFrom,
      displayTo,
      systemAnnounces,
      isListLoading
    } = this.props;
    return (
      <div>
        <div className="l-title-wrap">
          <h1 className="m-title-main">お知らせ</h1>
        </div>
        <LoadingView isLoading={isListLoading}>
          <div className="l-wrap-s">
            <ShowListGroup systemAnnounces={systemAnnounces} />
            <LowerPager
              pageCount={pageCount}
              totalCount={totalCount}
              displayFrom={displayFrom}
              displayTo={displayTo}
              initialPage={initialPage - 1}
              forcePage={initialPage - 1}
              perPage={perPage}
              perPages={perPages}
              onPageChange={e => onPageChange(e.selected)}
              onPerPageChange={e => onChangePerPage(e.target.value)}
            />
          </div>
        </LoadingView>
      </div>
    );
  }
}

export default connect(
  state => ({
    pageCount: getListPageCount(REDUCER_NAME, state),
    totalCount: getListTotalCount(REDUCER_NAME, state),
    displayFrom: getListDisplayFrom(REDUCER_NAME, state),
    displayTo: getListDisplayTo(REDUCER_NAME, state),
    initialPage: getListPage(REDUCER_NAME, state),
    perPage: getListPerPage(REDUCER_NAME, state),
    perPages: getSelectOptions(state, 'perPages'),
    systemAnnounces: getSystemAnnounces(state) || [],
    isListLoading: getIsLoading(REDUCER_NAME, state)
  }),
  dispatch => ({
    fetch: () => {
      dispatch(fetchSystemAnnounces());
    },
    onPageChange: query => {
      dispatch(addPageQuery(REDUCER_NAME, query + 1));
      dispatch(fetchSystemAnnounces());
    },
    onChangePerPage: query => {
      dispatch(addPageQuery(REDUCER_NAME, 1));
      dispatch(addPerPageQuery(REDUCER_NAME, query));
      dispatch(fetchSystemAnnounces());
    }
  })
)(SystemAnnounceList);
