import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import { PROCEDURE_FORM } from 'src/constants/FormNames';

const TodoRawForm = ({ handleSubmit, submitting, submit, cancelBtnClick, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>一度完了にすると元に戻す事ができませんがよろしいですか。</form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="完了" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let TodoForm = reduxForm({
  form: PROCEDURE_FORM
})(TodoRawForm);

const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(ownProps.completeTodo(ownProps.todo, ownProps.cancelBtnClick))
});

TodoForm = connect(undefined, mapDispatchToProp)(TodoForm);

const CompleteTodoModal = ({ isOpen, hideModal, todo, completeTodo }) => {
  const header = 'Todoリスト';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <TodoForm cancelBtnClick={hideModal} todo={todo} completeTodo={completeTodo} header={header} />
    </CommonModal>
  );
};

export default CompleteTodoModal;
