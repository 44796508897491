import React from 'react';
import axios from 'axios';
import { reduxForm, formValueSelector, FormSection } from 'redux-form';
import { Section, TextField, RadioField } from 'jbc-front/components/Form';
import compose from 'lodash/fp/compose';
import Modal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import CreditCardInfo from 'jbc-front/components/CreditCardInfo';
import { connect } from 'react-redux';
import moment from 'moment';
import { isEmail } from 'src/utils/CustomValidators';
import { setGlobalErrors } from 'src/reducers/global';
import { CardInput } from './CardInputForm';
import styles from './EditClientPaidPlan.scss';

const formName = 'PaymentMethodChange';
const selector = formValueSelector(formName);

export const paymentMethodTypes = [{ label: 'クレジットカード支払い', value: 'credit_card' }];

const PaymentMethodForm = ({ submitting, handleSubmit, cardInfo, hideModal, dispatch }) => {
  const changeFrom = moment();
  return (
    <React.Fragment>
      <Modal.Body>
        <p className={styles.caution}>
          【注意】<br />
          ジョブカン会計有料プランをご契約中のお客様につきましては、クレジットカード支払いに変更されますと
          ジョブカン会計有料プランお申し込みの特典である5ユーザーまで無料利用が適用されません。
        </p>
        <Section title="決済方法">
          <RadioField name="paymentMethodType" options={paymentMethodTypes} />
          請求書払いをご希望の方はサポート窓口までお問い合わせください。
        </Section>
        <Section title="決済情報">
          <FormSection name="creditInfo">
            <CardInput dispatch={dispatch} formName={formName} />
            {cardInfo && (
              <div>
                <div>カード情報</div>
                <CreditCardInfo cardInfo={cardInfo} className={styles.cardInfo} />
              </div>
            )}
            <TextField name="employeeEmail" label="メールアドレス" validate={isEmail} required />
            変更された決済情報は{changeFrom.format('YYYY年M月')}利用分（{changeFrom
              .clone()
              .add(1, 'month')
              .format('YYYY年M月')}ご請求）から反映されます。
          </FormSection>
        </Section>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Buttons>
          <Button onClick={hideModal} disabled={submitting}>
            キャンセル
          </Button>
          <Button primary onClick={handleSubmit} disabled={submitting}>
            登録する
          </Button>
        </Modal.Buttons>
      </Modal.Footer>
    </React.Fragment>
  );
};

export default compose(
  reduxForm({
    form: formName,
    enableReinitialize: true,
    onSubmit: async (values, dispatch, { hideModal }) => {
      try {
        await axios.post('/clients/change_payment_method', values);
        hideModal();
      } catch (e) {
        dispatch(setGlobalErrors(e.message));
      }
    }
  }),
  connect(state => ({
    cardInfo: selector(state, 'credit_info._card_info')
  }))
)(PaymentMethodForm);
