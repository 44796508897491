import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { OFFICE_FORM } from 'src/constants/FormNames';
import LabourInsuranceContactInfoSection from '../../components/edit/LabourInsuranceContactInfoSection';

const mapStateToProps = state => ({
  office: getFormValues(OFFICE_FORM)(state).office,
  labourInsuranceContactInfo: getFormValues(OFFICE_FORM)(state).labourInsuranceContactInfo,
  useContactInfo: getFormValues(OFFICE_FORM)(state).accidentInsurance.useContactInfo
});

export default connect(mapStateToProps)(LabourInsuranceContactInfoSection);
