import { connect } from 'react-redux';
import { change, touch, getFormValues } from 'redux-form';

import { OFFICE_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, copy, isMainOffice } from 'src/reducers/offices/offices';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { fetchSocialInsuranceRates } from 'src/reducers/offices/insurance';
import { getExtras } from 'src/reducers/common/listReducer';

import OfficeSection from '../../components/edit/OfficeSection';

const mapStateToProps = state => ({
  taxOffices: getExtras(REDUCER_NAME, state).taxOffices || [],
  prefectures: getSelectOptions(state, 'prefectures'),
  socialInsuranceSettingStartDate: getFormValues(OFFICE_FORM)(state).socialInsurance.settingStartDate,
  healthInsuranceType: getFormValues(OFFICE_FORM)(state).healthInsurance.insuranceType,
  isMainOffice: isMainOffice(getFormValues(OFFICE_FORM)(state).office.officeType)
});
const mapDispatchToProps = (dispatch, ownProps) => {
  const formSectionName = ownProps.formSectionName || 'office';
  return {
    complementAddress: (prefectureId, city, street) => {
      if (formSectionName === 'employmentInsurance') {
        dispatch(change(OFFICE_FORM, `${formSectionName}.contactInfo.prefectureId`, prefectureId));
        dispatch(change(OFFICE_FORM, `${formSectionName}.contactInfo.city`, city));
        dispatch(change(OFFICE_FORM, `${formSectionName}.contactInfo.street`, street));
      } else {
        dispatch(change(OFFICE_FORM, `${formSectionName}.prefectureId`, prefectureId));
        dispatch(change(OFFICE_FORM, `${formSectionName}.city`, city));
        dispatch(change(OFFICE_FORM, `${formSectionName}.street`, street));
      }
    },
    formChange: (key, value) => {
      dispatch(change(OFFICE_FORM, key, value));
    },
    formTouch: key => {
      touch(OFFICE_FORM, key);
    },
    fetchSocialInsuranceRates: (healthInsuranceType, settingStartDate, prefectureId) => {
      dispatch(fetchSocialInsuranceRates(healthInsuranceType, settingStartDate, prefectureId));
    },
    copyMainOffice: () => {
      dispatch(copy());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OfficeSection);
