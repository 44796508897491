import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { CheckboxFieldWithoutForm } from 'src/components';
import { getSelectOptions } from 'src/reducers/selectOptions';
import MemoList from 'src/features/Memos';
import { getOptionLabel, getGroupFullPathName } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';
import CheckBoxHint from 'src/components/CheckBoxHint';
import styles from 'src/components/EmployeeTable.scss';

import ChangedContext from '../ChangedContext';

class EmployeeTableRow extends React.Component {
  state = {
    showHint: false
  };

  handleClick = () => {
    this.setState({
      ...this.state,
      showHint: true
    });
  };

  hideHint = () => {
    this.setState({
      ...this.state,
      showHint: false
    });
  };

  render() {
    const { employee, clientEmploymentTypes, groups, positions } = this.props;

    return (
      <tr className={classNames('table-hover', styles.tableRow)}>
        <ChangedContext.Consumer>
          {({ isSelected, change }) => {
            const checked = isSelected(employee);
            return (
              <td
                className={`m-table-checkbox-${checked ? 'on' : 'off'} table-hover`}
                onClickCapture={() => change({ [employee.id]: !checked })}
              >
                {this.state.showHint && (
                  <CheckBoxHint onClick={this.hideHint}>スタッフコードを入力してください</CheckBoxHint>
                )}
                <CheckboxFieldWithoutForm checked={checked} readOnly />
              </td>
            );
          }}
        </ChangedContext.Consumer>
        <td>
          <MemoList employeeId={employee.id} />
        </td>
        <td>{employee.staffCode}</td>
        <td>{employee.displayName}</td>
        <td>{getOptionLabel(employee.employmentTypeId)(clientEmploymentTypes)}</td>
        <td>{!!employee.groupId && <div>{getGroupFullPathName(groups, employee.groupId)}</div>}</td>
        <td>{getOptionLabel(employee.positionId)(positions)}</td>
        <td>{formatDate(employee.joinedOn)}</td>
      </tr>
    );
  }
}

export default connect(state => ({
  clientEmploymentTypes: getSelectOptions(state, 'employmentTypes'),
  groups: getSelectOptions(state, 'groups'),
  positions: getSelectOptions(state, 'employeePositions')
}))(EmployeeTableRow);
