import React from 'react';
import { FormSection, change as formChange, autofill } from 'redux-form';
import { TextField, BoxDouble, RadioField, Section } from 'jbc-front/components/Form';
import { DeleteSquare } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';
import { bankKana, maxLength3, maxLength4, maxLength7, isNumber } from 'src/utils/CustomValidators';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import BankSearchModalWrapper from 'src/components/BankSearchModalWrapper';

class BankItemSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchBankModalOpen: false,
      selectedBank: undefined
    };
  }

  // 支給口座設定はリセットされます。
  onDeleteBank = () => {
    const { dispatch, remove, bonusPaymentBanks, payPaymentBanks } = this.props;
    if (bonusPaymentBanks) {
      const unSelectBank = bonusPaymentBanks.map(item => ({ ...item, employeeBankId: undefined }));
      dispatch(formChange(EMPLOYEE_FORM, 'bonusPaymentBanks', unSelectBank));
    }
    if (payPaymentBanks) {
      const unSelectBank = payPaymentBanks.map(item => ({ ...item, employeeBankId: undefined }));
      dispatch(formChange(EMPLOYEE_FORM, 'payPaymentBanks', unSelectBank));
    }
    remove();
  };

  render() {
    const { showModal, isModalOpen: isOpen, hideModal } = this.props;
    const { formKey, depositTypes, index, children, dispatch } = this.props;

    return (
      <div>
        <Section title={`口座情報 ${index + 1}`} icon={<DeleteSquare onClick={this.onDeleteBank} />}>
          <FormSection name={formKey}>
            <ActionButton onClick={showModal} className="u-mb20">
              銀行コード検索
            </ActionButton>

            <BankSearchModalWrapper
              isOpen={isOpen}
              hideModal={hideModal}
              onSelect={(bank, branch) => {
                this.setState({
                  isSearchBankModalOpen: false
                });
                dispatch(autofill(EMPLOYEE_FORM, `${formKey}.bankCode`, bank.bankCode));
                dispatch(autofill(EMPLOYEE_FORM, `${formKey}.bankName`, bank.name));
                dispatch(autofill(EMPLOYEE_FORM, `${formKey}.branchCode`, branch.branchCode));
                dispatch(autofill(EMPLOYEE_FORM, `${formKey}.branchName`, branch.name));
              }}
            />

            <BoxDouble>
              <TextField label="銀行コード" name="bankCode" validate={[isNumber, maxLength4]} maxLength="4" />
              <TextField label="銀行名" name="bankName" maxLength="256" />
            </BoxDouble>
            <BoxDouble>
              <TextField label="支店コード" name="branchCode" validate={[isNumber, maxLength3]} maxLength="3" />
              <TextField label="支店名" name="branchName" maxLength="256" />
            </BoxDouble>

            <RadioField label="預金種別" name="depositType" options={depositTypes} />

            <BoxDouble>
              <TextField
                label="口座番号"
                type="text"
                name="accountNumber"
                validate={[isNumber, maxLength7]}
                maxLength="7"
              />
              <TextField label="口座名義（カナ）" type="text" name="accountHolderKana" validate={[bankKana]} />
            </BoxDouble>

            {children}
          </FormSection>
        </Section>
      </div>
    );
  }
}

export default BankItemSection;
