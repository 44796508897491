import React from 'react';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import { Hamburger } from 'jbc-front/components/icons';

import styles from './SortableAllowancesTable.scss';

const AllowanceRow = SortableElement(({ allowance }) => (
  <tr className={styles.line}>
    <td className={styles.icon}>
      <Hamburger size={17} />
    </td>
    <td className={allowance.isInDisuse ? styles.through : ''}>{allowance.name}</td>
    <td className={allowance.isInDisuse ? styles.through : ''}>{allowance.displayFormulaDescription}</td>
  </tr>
));

const SortableAllowancesTable = SortableContainer(({ allowances }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list">
      <colgroup>
        <col width={50} />
        <col width={200} />
        <col width={500} />
      </colgroup>
      <thead>
        <tr>
          <th />
          <th>項目名</th>
          <th>金額</th>
        </tr>
      </thead>
      <tbody>
        {allowances.length ? (
          allowances.map((allowance, index) => <AllowanceRow key={allowance.id} index={index} allowance={allowance} />)
        ) : (
          <tr>
            <td colSpan={3}>データがありません。</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
));
export default SortableAllowancesTable;
