import React from 'react';
import { useDispatch } from 'react-redux';
import CommonModal from 'jbc-front/components/CommonModal';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import Button from 'jbc-front/components/Button';
import { Download } from 'jbc-front/components/icons';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import { exportCsv } from 'src/reducers/clients/groups';

const GroupCsvDownloadModal = ({ handleSubmit, isOpen, hideModal }) => {
  const dispatch = useDispatch();

  return (
    <CommonModal isOpen={isOpen} hideModal={hideModal} ariaHideApp={false} closeOnClick>
      <CommonModal.Header hideModal={hideModal}>グループ情報のダウンロード</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          <DownloadCsvEncodingRadioButton
            name={'encoding'}
            label={'ファイル形式を選択'}
            options={ENCODING_OPTIONS}
            required={false}
          />
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={hideModal} className="u-mr20">
          キャンセル
        </Button>
        <Button primary icon={<Download size={16} />} onClick={() => dispatch(exportCsv())}>
          ダウンロード
        </Button>
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default GroupCsvDownloadModal;
