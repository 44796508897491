import React from 'react';
import { connect } from 'react-redux';

import { getAllowanceFormValues } from 'src/reducers/clients/allowances';
import selector from 'src/utils/Utils';

import AllowanceDeductionFixed from './AllowanceDeductionFixed';
import AllowanceDeductionFormula from './AllowanceDeductionFormula';
import AllowanceDeductionIndividual from './AllowanceDeductionIndividual';

const AllowanceDeduction = ({ selectedAllowanceAmountType }) => (
  <div>
    {selectedAllowanceAmountType === 'fixed' && <AllowanceDeductionFixed />}
    {selectedAllowanceAmountType === 'formula' && <AllowanceDeductionFormula />}
    {selectedAllowanceAmountType === 'individual' && <AllowanceDeductionIndividual />}
  </div>
);

export default connect(state => ({
  selectedAllowanceAmountType: selector(getAllowanceFormValues(state), 'allowanceAmountType')
}))(AllowanceDeduction);
