import { connect } from 'react-redux';
import { arrayPush as arrayPushForm, reset as resetForm, submit as submitForm } from 'redux-form';

import { ALLOWED_IP_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';

import { getMode, getListErrors, changeToEditMode, changeToShowMode } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME } from 'src/reducers/clients/allowedIps';

import { EditableListButtons } from 'src/components';

const mapStateToProps = state => ({
  mode: getMode(REDUCER_NAME, state),
  errors: getListErrors(REDUCER_NAME, state)
});
const mapDispatchToProps = (dispatch, ownProps) => ({
  onEdit: () => {
    if (ownProps.isIpListEmpty) {
      dispatch(
        arrayPushForm(ALLOWED_IP_FORM, EDITABLE_LIST_FIELDS_NAME, {
          ip: '',
          notes: ''
        })
      );
    }
    dispatch(changeToEditMode(REDUCER_NAME));
  },
  onSave: () => {
    dispatch(submitForm(ALLOWED_IP_FORM));
  },
  onCancel: () => {
    dispatch(resetForm(ALLOWED_IP_FORM));
    dispatch(changeToShowMode(REDUCER_NAME));
  },
  onAddRow: () => {
    dispatch(
      arrayPushForm(ALLOWED_IP_FORM, EDITABLE_LIST_FIELDS_NAME, {
        ip: '',
        notes: ''
      })
    );
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableListButtons);
