export const pad = (num, size) => {
  let s = `${num}`;
  while (s.length < size) s = `0${s}`;
  return s;
};

export const convertTimeToMinutes = time => {
  const splitted = time.split(':');
  if (splitted.length < 2) {
    return 0;
  }
  return parseInt(splitted[0], 10) * 60 + parseInt(splitted[1], 10);
};

export const sumByAttr = (objs, attr) => {
  if (Array.isArray(objs)) {
    return objs.reduce((prevVal, obj) => prevVal + +obj[attr], 0);
  }
  return Object.keys(objs).reduce((prevVal, key) => prevVal + +objs[key][attr], 0);
};

export const formatMinutesToTime = minutesOfSum => {
  const rounded = Math.floor(minutesOfSum);
  const h = parseInt(rounded / 60, 10);
  const m = rounded - h * 60;
  return `${h}:${pad(m, 2)}`;
};

export const roundingTo = (decimal, value) => Math.floor(value / decimal) * decimal;

export const isNumber = value => !Number.isNaN(Number(value));

export default pad;
