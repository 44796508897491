import { connect } from 'react-redux';
import { change as changeForm, getFormValues } from 'redux-form';
import { OFFICE_FORM } from 'src/constants/FormNames';
import {
  fetchAccidentInsuranceRates,
  fetchEmploymentInsuranceRates,
  fetchReferenceOffice,
  isSelf
} from 'src/reducers/offices/insurance';

import { getSelectOptions } from 'src/reducers/selectOptions';
import { getExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME } from 'src/reducers/offices/offices';
import LabourInsuranceSection from '../../components/edit/LabourInsuranceSection';

const mapStateToProps = state => ({
  accidentInsuranceTypes: getSelectOptions(state, 'accidentInsurances'),
  employmentInsuranceTypes: getSelectOptions(state, 'businessTypes'),
  prefectures: getSelectOptions(state, 'prefectures'),
  helloWorks: getSelectOptions(state, 'helloWorks'),
  employmentInsurance: getFormValues(OFFICE_FORM)(state).employmentInsurance,
  accidentInsurance: getFormValues(OFFICE_FORM)(state).accidentInsurance,
  settingStartDate: getFormValues(OFFICE_FORM)(state).labourInsurance.settingStartDate,
  referenceAccidentOffices: getExtras(REDUCER_NAME, state).referenceOffices.accident,
  referenceEmploymentOffices: getExtras(REDUCER_NAME, state).referenceOffices.employment,
  allReferenceAccidentOffices: getExtras(REDUCER_NAME, state).allReferenceOffices.accident,
  allReferenceEmploymentOffices: getExtras(REDUCER_NAME, state).allReferenceOffices.employment,
  accidentInsuranceSelf: isSelf(
    getFormValues(OFFICE_FORM)(state).office.id,
    getFormValues(OFFICE_FORM)(state).accidentInsurance.occupationAccidentInsuranceOfficeId,
    getFormValues(OFFICE_FORM)(state).accidentInsurance.officeId
  ),
  employmentInsuranceSelf: isSelf(
    getFormValues(OFFICE_FORM)(state).office.id,
    getFormValues(OFFICE_FORM)(state).employmentInsurance.employmentInsuranceOfficeId,
    getFormValues(OFFICE_FORM)(state).employmentInsurance.officeId
  )
});

const mapDispatchToProps = dispatch => ({
  fetchAccidentInsuranceRates: (insuranceType, settingStartDate) => {
    dispatch(fetchAccidentInsuranceRates(insuranceType, settingStartDate));
  },
  fetchEmploymentInsuranceRates: (insuranceType, settingStartDate) => {
    dispatch(fetchEmploymentInsuranceRates(insuranceType, settingStartDate));
  },
  changeIsInput: value => {
    dispatch(changeForm(OFFICE_FORM, 'isInput', value));
  },
  refAccidentInsuranceChange: (settingStartDate, officeId) => {
    dispatch(fetchReferenceOffice('accident_insurance', settingStartDate, officeId));
  },
  refEmploymentInsuranceChange: (settingStartDate, officeId) => {
    dispatch(fetchReferenceOffice('employment_insurance', settingStartDate, officeId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LabourInsuranceSection);
