import React from 'react';
import { FieldArray } from 'redux-form';

import { PlusSquare } from 'jbc-front/components/icons';
import EmployeeLeaveOfAbsenceItem from '../containers/EmployeeLeaveOfAbsenceItem';

const RenderEmployeeLeaveOfAbsences = ({ fields, meta: { error, submitFailed } }) => (
  <div>
    {submitFailed && error && <span>{error}</span>}

    {fields.map((item, index) => (
      <EmployeeLeaveOfAbsenceItem key={item} formKey={item} remove={() => fields.remove(index)} />
    ))}
    <div className="u-ta-r u-mt20 u-mb20">
      <div role="button" tabIndex="0" onClick={() => fields.push({})} className="u-txt-addinfo">
        <PlusSquare size={20} className="u-txt-addinfo-icon" />休職期間追加
      </div>
    </div>
  </div>
);

const EmployeeLeaveOfAbsences = () => (
  <div>
    <FieldArray name="employeeLeaveOfAbsences" component={RenderEmployeeLeaveOfAbsences} />
  </div>
);

export default EmployeeLeaveOfAbsences;
