import { UTF_8, WINDOWS_31J } from 'src/constants/Generals';
import _ from 'lodash';

export const JOIN_INSURANCE = [{ value: '1', label: '加入' }, { value: '0', label: '未加入' }];
export const IS_ACCIDENT_INSURANCE_CALC = [{ value: '1', label: '対象' }, { value: '0', label: '対象外' }];

export const IS_SHORT_TIME_EMPLOYEE = [{ label: '短時間労働者', value: '1' }];

export const IS_PART = [{ label: 'パート', value: '1' }];

export const IS_JOINED_ACCIDENT_INSURANCE = [{ value: '1', label: '対象' }];

export const INVITATION_STATUSES = [
  { value: 'not_invited', label: '未招待' },
  { value: 'invited', label: '招待済' },
  { value: 'only_enrollment_input', label: '登録入力のみ' },
  { value: 'registered', label: '登録済' }
];

export const PAYROLL_TRANSFER_TYPES = [{ value: 11, label: '給与振込' }, { value: 21, label: '総合振込' }];

export const BONUS_TRANSFER_TYPES = [{ value: 12, label: '賞与振込' }, { value: 21, label: '総合振込' }];

export const CHANGED = [{ value: 0, label: '無' }, { value: 1, label: '有' }];
export const PAY_FOR_RETIREMENT = [{ value: 0, label: 'なし' }, { value: 1, label: 'あり' }];

export const IS_USE_TO_TRANSER = [{ value: 1, label: '振込口座に使用する?' }];

const MARITAL_STATUSES = [{ value: '1', label: '有' }, { value: '0', label: '無' }];

export const ENCODING_OPTIONS = [{ label: 'UTF-8(推奨)', value: UTF_8 }, { label: 'Shift-JIS', value: WINDOWS_31J }];

export const FB_DATA_LINE_FEED_OPTIONS = [
  { label: 'CR+LF', value: 'crlf' },
  { label: 'CR', value: 'cr' },
  { label: 'LF', value: 'lf' },
  { label: 'なし', value: 'none' }
];

export const FB_DATA_END_OF_FILE_LINE_FEED = [{ value: '0', label: 'なし' }, { value: '1', label: 'あり' }];

export const SYNC_WITH_JBC_KINTAI = [{ value: 0, label: '連携無し' }, { value: 1, label: '連携有り' }];

export const DEDUCTION_ALLOWANCE_EXPORT_TYPES = [
  { value: 'each_employee', label: '従業員ごとに出力' },
  { value: 'each_group', label: 'グループごとの合計を出力' }
];

export const DEDUCTION_ALLOWANCE_EXPORT_DIRECTIONS = [
  { value: 'horizontal', label: '選択項目を縦に並べて表示（氏名が横に並びます）' },
  { value: 'vertical', label: '選択項目を横に並べて表示（氏名が縦に並びます）' }
];

export const CSV_FORMAT_TYPES = [
  { value: 'pay', label: '給与の支給・控除項目フォーマット' },
  { value: 'bonus', label: '賞与の支給・控除項目フォーマット' }
];

export const REVISION_SORT_TYPES = [
  { value: 'staff_code', label: 'スタッフコード' },
  { value: 'target', label: '対象者優先' },
  { value: 'insured_personnel_number', label: '被保険者整理番号' }
];

export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_SORT_TYPES = [
  { value: 'staff_code', label: 'スタッフコード' },
  { value: 'insured_personnel_number', label: '被保険者整理番号' }
];

export const REVISION_FILTER_TYPES = [
  { value: 'only_targets', label: '対象のみ' },
  { value: 'only_health_insurance_targets', label: '健保のみ対象' },
  { value: 'only_pension_insurance_targets', label: '厚年のみ対象' },
  { value: 'exclude_targets', label: '対象外のみ' },
  { value: 'all', label: 'すべて' }
];

export const REVISION_FILTER_TYPES_IMPORT = [
  { value: 'only_targets', label: '対象者のみ' },
  { value: 'all', label: 'すべて' }
];

export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_FILTER_TYPES = [
  { value: 'only_targets', label: '対象' },
  { value: 'only_health_targets', label: '健保のみ対象' },
  { value: 'only_pension_targets', label: '厚年のみ対象' },
  { value: 'exclude_targets', label: '対象外' },
  { value: 'alert_targets', label: 'エラーのある対象' },
  { value: 'all', label: 'すべて' }
];

export const EMPLOYMENT_STATUSES = [
  { value: '0', label: 'すべて' },
  { value: '2', label: '在職中' },
  { value: '3', label: '休職中' },
  { value: '4', label: '退職済' },
  { value: '1', label: '内定' }
];

export const ROLL_ACCOUNT_TYPE = [{ value: '1', label: '従業員情報あり' }, { value: '2', label: '従業員情報なし' }];

export const LABOR_INSURANCE_SUMMARY_TYPES = [
  { value: '1', label: '算定基礎賃金集計表' },
  { value: '2', label: '労働保険申告書' }
];

export const PAYMENT_IN_INSTALLMENT_FOR_ESTIMATE_INSURANCE_FEE = [
  { value: '0', label: '1回' },
  { value: '1', label: '3回' }
];

export const PAYMENT_ON_WEEKEND_CASES = [
  { value: 'prev_weekday', label: '設定日前の平日を支給日とする（金曜日等）' },
  { value: 'next_weekday', label: '設定日後の平日を支給日とする（月曜日等）' },
  { value: 'not_change', label: '変更しない' }
];

export const INHABITANT_TAX_COLLECTION_METHODS = [
  { value: 'special', label: '特別徴収' },
  { value: 'normal', label: '普通徴収' }
];

export const INHABITANT_TAX_TABLE_PDF_LAYOUT_TYPES = [
  { value: 'by_employee', label: '従業員ごとの金額を表示する' },
  { value: 'by_municipality', label: '市区町村計で表示する' }
];

export const RELEASE_STATUSES = [{ value: '1', label: '公開' }, { value: '0', label: '未公開' }];

export const HH_00_HOURS = _.times(24, hour => {
  const hh = hour > 9 ? hour : '0'.concat(hour);
  return { value: `${hh}:00`, label: `${hh}:00` };
});

export const REVISION_OPTIONS = [{ value: true, label: 'あり' }, { value: false, label: 'なし' }];

export const MY_NUMBER_STATUSES = [
  { value: '0', label: 'すべて' },
  { value: '1', label: '取得済' },
  { value: '2', label: '未取得' }
];

export const SOCIAL_INSURANCE_NOTIFICATION_SUBMISSION_TYPES = [
  { value: 'pension_office', label: '年金事務所' },
  { value: 'health_insurance_assoc', label: '健康保険組合' }
];

export const SOCIAL_INSURANCE_NOTIFICATION_SORT_TYPES = [
  { value: 'insured_personnel_number', label: '被保険者整理番号' },
  { value: 'staff_code', label: 'スタッフコード' }
];

export const HEALTH_INSURANCE_ROUNDING_TYPES = [
  { value: 'total', label: '健康保険＋介護保険の合計' },
  { value: 'respectively', label: '健康保険・介護保険ごと' }
];

export const EMPLOYMENT_INSURANCE_USED_CONTACT_INFOS = [
  { value: 'main_office', label: '事業所情報を利用する' },
  { value: 'labour_insurance_office', label: '労働保険の事業所情報を利用する' },
  { value: 'manual', label: '雇用保険の事業所情報を別途入力する' }
];

export const INVITE_USER_ROLES = [{ value: 'admin', label: '管理者' }, { value: 'operator', label: '運用担当者' }];

export const ROUNDING_TYPES = [
  { value: 'round_half_down', label: '五捨六入' },
  { value: 'rounding_off', label: '四捨五入' },
  { value: 'truncation', label: '切り捨て' },
  { value: 'rounding_up', label: '切り上げ' }
];

export const ELECTRONIC_DELIVERY_AGREEMENT = [{ value: 1, label: '同意済' }, { value: 0, label: '未同意' }];

export const NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES = [
  { value: 'auto', label: '自動判定' },
  { value: 'applicable', label: '対象' },
  { value: 'not_applicable', label: '対象外' }
];

export const NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES_WITH_ALERT = NOTIFICATION_OF_BASE_AMOUNT_FORCE_SUBJECT_TYPES.map(
  item => {
    if (item.value === 'applicable') {
      return {
        ...item,
        disabled: true,
        note: '月額変更予定なので対象にできません',
        noteWidth: 200
      };
    }
    return item;
  }
);

export const MONTHLY_REVISION_TARGET_INSURANCE_TYPES = [
  { value: 'health_and_pension', label: '健保・厚年' },
  { value: 'only_health', label: '健保のみ' },
  { value: 'only_pension', label: '厚年のみ' }
];

export default MARITAL_STATUSES;
