import React from 'react';
import classnames from 'classnames';
import ActionButton from 'jbc-front/components/ActionButton';
import { LIST_OFFICES_URL, COORDINATIONS_SETTING_CLIENT_DEPARTMENT } from 'src/constants/EndpointUrls';
import styles from '../PersonnelHistoriesList.scss';

const ConfirmButtons = () => (
  <div className={classnames(styles.action, styles.btn_group)}>
    <ActionButton as="a" className={styles.link_btn} href={LIST_OFFICES_URL}>
      事業所の確認
    </ActionButton>
    <ActionButton as="a" className={styles.link_btn} href={`${COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=group`}>
      グループの確認
    </ActionButton>
    <ActionButton
      as="a"
      className={styles.link_btn}
      href={`${COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=occupations`}
    >
      職種の確認
    </ActionButton>
    <ActionButton as="a" className={styles.link_btn} href={`${COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=positions`}>
      役職の確認
    </ActionButton>
    <ActionButton
      as="a"
      className={styles.link_btn}
      href={`${COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=employment_types`}
    >
      雇用形態の確認
    </ActionButton>
  </div>
);
export default ConfirmButtons;
