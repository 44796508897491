import React from 'react';
import japanese from 'japanese';
import { connect } from 'react-redux';
import { Field, getFormValues, change as changeForm } from 'redux-form';
import { AlertTriangle } from 'jbc-front/components/icons';
import classNames from 'classnames';

import { SAVE_THEN_MOVE_TO_EDIT, SAVE_THEN_BACK_TO_LIST } from 'src/constants/Generals';
import { CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import {
  DateField,
  TextField,
  TextFieldWithKana,
  RadioField,
  Section,
  BoxDouble,
  SelectField
} from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import ActionButton from 'jbc-front/components/ActionButton';
import * as CssClassNames from 'src/constants/CssClassNames';
import { isDateStringFormat, joinedOnLimit, isEmail, zenkakuKatakana, isStaffCode } from 'src/utils/CustomValidators';
import { detectButton } from 'src/utils/Form';
import { getRadioOptions, getSelectOptions } from 'src/reducers/selectOptions';
import selector from 'src/utils/Utils';
import { ErrorsSection } from 'src/components';
import isUseOtherApp from 'src/utils/JbcId';
import PayrollRuleInfo from './PayrollRuleInfo';
import styles from './EmployeeCreatingForm.scss';

const EmployeeCreatingForm = props => {
  const {
    handleSubmit,
    onSubmit,
    pristine,
    errors,
    genders,
    submitting,
    payrollRulesGroups,
    selectedPayrollRulesGroupId,
    handleFirstNameKanaChange,
    handleLastNameKanaChange,
    anyApiRunning
  } = props;

  return (
    <form className={classNames(CssClassNames.FORM, CssClassNames.EMPLOYEE_FORM)} onSubmit={handleSubmit}>
      <Section title="基本情報">
        <ErrorsSection errors={errors} />

        <DateField
          required
          label="入社日"
          viewMode="months"
          name="joinedOn"
          validate={[isDateStringFormat, joinedOnLimit]}
        />
        <BoxDouble>
          <TextFieldWithKana
            required
            label="姓"
            type="text"
            name="lastName"
            maxLength="32"
            onUpdateKana={handleLastNameKanaChange}
          />
          <TextFieldWithKana
            required
            label="名"
            type="text"
            name="firstName"
            maxLength="32"
            onUpdateKana={handleFirstNameKanaChange}
          />
        </BoxDouble>
        <BoxDouble>
          <TextField label="姓（カナ）" type="text" name="lastNameKana" validate={zenkakuKatakana} />
          <TextField label="名（カナ）" type="text" name="firstNameKana" validate={zenkakuKatakana} />
        </BoxDouble>

        <DateField label="生年月日" viewMode="years" name="birthday" validate={isDateStringFormat} />

        <RadioField label="性別" name="gender" options={genders} />

        <TextField label="メールアドレス" type="text" name="email" validate={isEmail} maxLength="255" />

        <TextField label="スタッフコード" type="text" name="staffCode" maxLength="50" validate={isStaffCode} />

        <SelectField label="給与規定グループ" required options={payrollRulesGroups} name="clientPayrollRulesGroupId" />
        <ActionButton as="a" href={CLIENT_PAYROLL_RULES_GROUP_LIST} target="_blank">
          給与規定グループ追加画面へ
        </ActionButton>

        <PayrollRuleInfo payrollRule={payrollRulesGroups.find(item => item.id === selectedPayrollRulesGroupId)} />

        <Field type="hidden" component="input" name="baseSalary" />
      </Section>
      <div className="u-ta-c u-mt30">
        {isUseOtherApp && (
          <div className={styles.cation}>
            <AlertTriangle size={35} />
            <p>
              従業員がジョブカン他サービスに既に登録されている場合、今回入力された<br />
              姓名、スタッフコードは、ご利用中のジョブカン他サービスにも自動反映されます。
            </p>
          </div>
        )}
        <Button
          primary
          disabled={submitting || pristine || anyApiRunning}
          onClick={handleSubmit(detectButton(onSubmit, SAVE_THEN_BACK_TO_LIST))}
          className="u-mr20"
        >
          保存して一覧に戻る
        </Button>
        <Button
          primary
          disabled={submitting || pristine || anyApiRunning}
          onClick={handleSubmit(detectButton(onSubmit, SAVE_THEN_MOVE_TO_EDIT))}
        >
          保存して詳細情報の入力へ
        </Button>
      </div>
    </form>
  );
};

export default connect(
  state => ({
    genders: getRadioOptions(state, 'genders'),
    payrollRulesGroups: getSelectOptions(state, 'payrollRulesGroups'),
    selectedPayrollRulesGroupId: selector(getFormValues(EMPLOYEE_FORM)(state), 'clientPayrollRulesGroupId')
  }),
  dispatch => ({
    handleLastNameKanaChange: value => {
      const lastNameKana = japanese.katakanize(value);
      dispatch(changeForm(EMPLOYEE_FORM, 'lastNameKana', lastNameKana));
    },
    handleFirstNameKanaChange: value => {
      const firstNameKana = japanese.katakanize(value);
      dispatch(changeForm(EMPLOYEE_FORM, 'firstNameKana', firstNameKana));
    }
  })
)(EmployeeCreatingForm);
