import React from 'react';
import { FieldArray, FormSection } from 'redux-form';
import { connect } from 'react-redux';

import { CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { TextField } from 'jbc-front/components/Form';
import { LabelMapper } from 'jbc-front/components/FormErrors';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import selector from 'src/utils/Utils';
import _ from 'lodash';
import styles from './EmployeeAllowancesSection.scss';

const EmployeeAllowanceItem = ({ formKey, itemInfo }) => (
  <React.Fragment>
    <FormSection name={formKey}>
      <div>{itemInfo.clientAllowanceName}</div>
      <div>
        <TextField name="allowanceAmount" yen validate={isMoneyNumber} />
        <LabelMapper name="allowanceAmount" label="金額は" />
      </div>
    </FormSection>
  </React.Fragment>
);

let EmployeeAllowances = ({ fields, selectedPayrollGroupId }) => (
  <div className={styles.wrap}>
    {fields.map((item, index) => {
      const employeeAllowance = fields.get(index);
      if (employeeAllowance.clientPayrollRulesGroupId === selectedPayrollGroupId) {
        return <EmployeeAllowanceItem formKey={item} key={item} itemInfo={employeeAllowance} />;
      }
      return undefined;
    })}
    {_.filter(fields.getAll(), { clientPayrollRulesGroupId: selectedPayrollGroupId }).length === 0 && (
      <p>
        設定可能な項目が存在しません。<a href={CLIENT_PAYROLL_RULES_GROUP_LIST} className="u-txt-link">
          給与規定
        </a>の支給・控除項目で「金額設定：従業員一覧で金額を設定」で登録すると表示されます。
      </p>
    )}
  </div>
);

EmployeeAllowances = connect(state => ({
  selectedPayrollGroupId: selector(getEmployeeFormValues(state), 'employeePayrollRule', 'clientPayrollRulesGroupId')
}))(EmployeeAllowances);

const EmployeeAllowancesSection = () => (
  <div>
    <h4>支給・控除項目設定</h4>
    <FieldArray name="employeeAllowances" component={EmployeeAllowances} />
  </div>
);

export default EmployeeAllowancesSection;
