import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import compose from 'lodash/fp/compose';
import DateTime from 'react-datetime';
import Select from 'react-select';
import { find, set, isEmpty } from 'lodash';
import { reduxForm, Field, autofill as autofillForm } from 'redux-form';
import { recordDisplay } from 'src/utils/Utils';
import { dateFieldProps } from 'jbc-front/components/Form';
import { Delete } from 'jbc-front/components/icons';
import { getList, getExtras } from 'src/reducers/common/listReducer';
import { EMPLOYEE_PERSONNEL_HISTORY_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';
import { REDUCER_NAME } from 'src/reducers/employees/employeePersonnelHistories';

import styles from '../PersonnelHistoriesList.scss';

const toSelectOption = options => [
  { value: '', label: recordDisplay() },
  ...options.map(option => ({ value: option, label: option }))
];

const autofill = autofillForm.bind(null, EMPLOYEE_PERSONNEL_HISTORY_FORM);

const renderSelectTd = ({ input, meta: { touched, error }, options }) => {
  let selectOptions = options;
  if (input.value && !options.includes(input.value)) {
    selectOptions = [input.value, ...options];
  }
  return (
    <td {...(touched && error ? { className: `invalid ${styles.select}` } : { className: styles.select })}>
      <Select
        name={input.name}
        value={input.value}
        onChange={input.onChange}
        onBlur={() => input.onBlur(input.value)}
        options={toSelectOption(selectOptions)}
        placeholder={recordDisplay()}
        simpleValue
        clearable={false}
      />
    </td>
  );
};

const renderDateTimeTd = ({ input, disabled, meta: { touched, error }, ...rest }) => (
  <td {...(touched && error ? { className: `invalid ${styles.date}` } : { className: styles.date })}>
    {disabled ? (
      <input disabled value={input.value} />
    ) : (
      <DateTime
        value={input.value}
        onChange={param => input.onChange(param)}
        onBlur={() => {
          input.onBlur(input.value);
        }}
        dateFormat={'YYYY/MM/DD'}
        timeFormat={false}
        {...rest}
      />
    )}
  </td>
);

const RenderHistories = ({ fields, options, setId, isEditing }) => (
  <React.Fragment>
    <div className="l-overflow-scroll">
      <table className={styles.historyTable}>
        <thead>
          <tr>
            <th style={{ width: '100px' }}>異動日</th>
            {options.offices && options.offices.length > 1 && <th>事業所</th>}
            <th>雇用形態</th>
            <th>役職</th>
            <th>職種</th>
            <th>グループ</th>
            {isEditing && <th style={{ width: '50px', backgroundColor: '#FAFAFA' }} />}
          </tr>
        </thead>
        <tbody>
          {fields.map((history, index) => {
            const field = fields.get(index);
            return (
              <tr key={field.id}>
                {isEditing ? (
                  <React.Fragment>
                    <Field component={renderDateTimeTd} name={`${history}.startedOn`} {...dateFieldProps} />
                    {options.offices &&
                      options.offices.length > 1 && (
                        <Field
                          component={renderSelectTd}
                          name={`${history}.officeManageName`}
                          options={options.offices.map(value => value.manageName)}
                          onChange={setId(`fields.${index}`, 'officeId', options.offices, 'manageName')}
                        />
                      )}
                    <Field
                      component={renderSelectTd}
                      name={`${history}.originEmploymentTypeName`}
                      options={options.employmentTypes.map(value => value.statusName)}
                      onChange={setId(`fields.${index}`, 'employmentTypeId', options.employmentTypes, 'statusName')}
                    />
                    <Field
                      component={renderSelectTd}
                      name={`${history}.originPositionName`}
                      options={options.positions.map(value => value.name)}
                      onChange={setId(`fields.${index}`, 'positionId', options.positions)}
                    />
                    <Field
                      component={renderSelectTd}
                      name={`${history}.originOccupationName`}
                      options={options.occupations.map(value => value.name)}
                      onChange={setId(`fields.${index}`, 'occupationId', options.occupations)}
                    />
                    <Field
                      component={renderSelectTd}
                      name={`${history}.originGroupName`}
                      options={options.groups.map(value => value.name)}
                      onChange={setId(`fields.${index}`, 'groupId', options.groups)}
                    />
                    <td>
                      {index !== 0 && (
                        <Delete
                          size={20}
                          onClick={e => {
                            e.preventDefault();
                            fields.remove(index);
                          }}
                          style={{ color: '#aaa' }}
                        />
                      )}
                    </td>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <td>{recordDisplay(field.startedOn)}</td>
                    {options.offices && options.offices.length > 1 && <td>{recordDisplay(field.officeManageName)}</td>}
                    <td>{recordDisplay(field.originEmploymentTypeName)}</td>
                    <td>{recordDisplay(field.originPositionName)}</td>
                    <td>{recordDisplay(field.originOccupationName)}</td>
                    <td>{recordDisplay(field.originGroupName)}</td>
                  </React.Fragment>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </React.Fragment>
);

const validate = values => {
  if (!values.fields) {
    return undefined;
  }
  const errors = {};
  values.fields.forEach((history, index) => {
    if (history.startedOn) {
      const startedOn = moment(history.startedOn, 'YYYY/MM/DD');
      if (!startedOn.isValid) {
        set(errors, `fields.${index}.startedOn`, '異動日のデータ形式が正しくありません');
      }
      for (let i = 0; i < index; i += 1) {
        if (values.fields[i].startedOn && moment(values.fields[i].startedOn, 'YYYY/MM/DD').isSame(startedOn, 'day')) {
          set(errors, `fields.${index}.startedOn`, '異動日が重複しています');
          break;
        }
      }
    } else {
      set(errors, `fields.${index}.startedOn`, '異動日を入力してください');
    }
    if (!history.officeId) {
      set(errors, `fields.${index}.officeManageName`, '事業所を選択してください');
    }
  });
  return isEmpty(errors) ? undefined : errors;
};

const mapStateToProps = state => ({
  initialValues: { [EDITABLE_LIST_FIELDS_NAME]: getList(REDUCER_NAME, state) },
  options: getExtras(REDUCER_NAME, state)
});

const mapDispatchToProps = dispatch => ({
  setId: (index, field, data, column = 'name') => (e, value) => {
    const current = value && find(data, [column, value]);
    if (current) {
      dispatch(autofill(`${index}.${field}`, current.id));
    } else if (!value) {
      dispatch(autofill(`${index}.${field}`, null));
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: EMPLOYEE_PERSONNEL_HISTORY_FORM,
    enableReinitialize: true,
    validate
  })
)(RenderHistories);
