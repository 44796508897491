import React, { Component } from 'react';
import { connect } from 'react-redux';
import onClickOutside from 'react-onclickoutside';
import {
  selectAllPages,
  setAllBonusCheckedTo,
  setIsNeedSavingConfirmation
} from 'src/reducers/employees/employeeBonus';

import styles from './TargetEmployeeBonusTableMethod.scss';

const TargetEmployeeBonusTableMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      const { dispatch } = this.props;

      return (
        <div className={styles.baloonWrap}>
          <div className={styles.baloon}>
            <ul className={styles.links}>
              <li>
                <a
                  onClick={() => {
                    dispatch(setIsNeedSavingConfirmation(true));
                    dispatch(setAllBonusCheckedTo(true));
                    dispatch(selectAllPages({ isSelectAllPages: true, selectAllPageValue: true }));
                  }}
                  role="presentation"
                >
                  全ページの従業員を選択
                </a>
              </li>
              <li>
                <a
                  onClick={() => {
                    dispatch(setIsNeedSavingConfirmation(true));
                    dispatch(setAllBonusCheckedTo(false));
                    dispatch(selectAllPages({ isSelectAllPages: true, selectAllPageValue: false }));
                  }}
                  role="presentation"
                >
                  全ページの従業員を解除
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }
);

export default connect()(TargetEmployeeBonusTableMethod);
