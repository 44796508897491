import React from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';

import { MEMO_FORM } from 'src/constants/FormNames';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { TextAreaField, DateField, RadioField } from 'jbc-front/components/Form';
import { FormSubmitBtn } from 'src/buttons';
import { ErrorsSection } from 'src/components';
import { REDUCER_NAME, MEMO_COLOR_OPTIONS, saveMemo } from 'src/reducers/memos/memos';
import { getItemErrors } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from './MemoForm.scss';

const reformatMemomapping = memoColorMappings =>
  memoColorMappings.map(mapping => ({
    value: mapping.value,
    label: <span style={{ color: mapping.label, lineHeight: '18px' }}>●</span>
  }));

const RawMemoForm = props => {
  const { handleSubmit, errors, submitting, pristine, submit, cancelBtnClick, memoColorMappings, header } = props;

  return (
    <React.Fragment>
      <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          <ErrorsSection errors={errors} />
          <RadioField name="memoColorId" options={reformatMemomapping(memoColorMappings)} label="メモカラー" />
          <TextAreaField name="note" />
          <div className={styles.dateWrap}>
            <DateField name="alertDate" label="アラート設定" />
          </div>
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={cancelBtnClick} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="保存" {...{ submitting, pristine, submit }} />
      </CommonModal.Footer>
    </React.Fragment>
  );
};

const MemoForm = reduxForm({
  form: MEMO_FORM
})(RawMemoForm);

export default connect(
  (state, ownProps) => ({
    errors: getItemErrors(REDUCER_NAME, state),
    initialValues: ownProps.initialValues || {},
    memoColorMappings: getSelectOptions(state, MEMO_COLOR_OPTIONS)
  }),
  (dispatch, ownProps) => ({
    onSubmit: data => dispatch(saveMemo(data, ownProps.cancelBtnClick))
  })
)(MemoForm);
