import React from 'react';
import { connect } from 'react-redux';

import { LIST_OFFICES_URL, USER_ACCOUNT_EDIT, USER_PROFILE_EDIT, USER_LOGOUT } from 'src/constants/EndpointUrls';
import { Pulldown, User, Question } from 'jbc-front/components/icons';
import DropDownMenu from 'src/components/DropDownMenu';
import { redirectTo } from 'src/utils/Http';
import _ from 'lodash';

import styles from './Header.scss';

export class ShowCurrentUser extends React.Component {
  // ユーザー種別ごとのメニューアイテムを作成
  // 従業員を持つアカウントは、プロフィールへの動線のみ
  // 従業員を持たないアカウントは、アカウント変更、及びログアウト
  createMenuItems(currentUser) {
    const customerCode = {
      id: 0,
      label: '問い合わせ番号',
      description: currentUser.customerCode,
      style: styles.customerCodeStyle
    };
    const profile = {
      id: 1,
      label: 'プロフィール',
      onClick: () => redirectTo(USER_PROFILE_EDIT)
    };
    const accountSetting = {
      id: 2,
      label: 'アカウント設定',
      onClick: () => redirectTo(USER_ACCOUNT_EDIT)
    };
    const logout = {
      id: 3,
      label: 'ログアウト',
      onClick: () => redirectTo(USER_LOGOUT)
    };

    if (currentUser.hasEmployee) {
      if (['admin', 'operator'].includes(currentUser.roleType)) {
        return [customerCode, profile];
      }
      return [profile];
    }
    if (['admin', 'operator'].includes(currentUser.roleType)) {
      return [customerCode, accountSetting, logout];
    }
    return [accountSetting, logout];
  }

  render() {
    const { currentUser } = this.props;
    const currentUserEmail = currentUser.email;
    const employeeDisplayName = _.get(currentUser, 'employeeDisplayName') || null;
    // 従業員なしアカウントと名前が入ってないアカウントは、emailを名前欄に表示する
    const name = currentUser.hasEmployee && employeeDisplayName ? employeeDisplayName : currentUserEmail;
    const menuItems = this.createMenuItems(currentUser);

    const toggle = ({ onClick }) => (
      <a className={styles.link} onClick={onClick} role="presentation">
        <User size={30} />
        <span className={styles.email}>{name}&nbsp;様&nbsp;</span>
        <span className={styles.pulldown}>
          <Pulldown size={10} />&nbsp;
        </span>
      </a>
    );

    return <DropDownMenu toggle={toggle} menuItems={menuItems} />;
  }
}

const CurrentUserInfo = ({ currentUser, currentClient }) => (
  <div className={styles.wrap}>
    <div className={styles.help}>
      <a
        className={styles.menu}
        target="_blank"
        rel="noopener noreferrer"
        href="https://jobcan-payroll.zendesk.com/hc/ja"
      >
        <Question size={20} />ヘルプ
      </a>
    </div>
    <div className={styles.client}>
      <a href={LIST_OFFICES_URL} className={styles.link}>
        {currentClient.name}
      </a>
    </div>
    <div className={styles.user}>
      <ShowCurrentUser currentUser={currentUser} />
    </div>
  </div>
);

export default connect(state => ({
  currentClient: state.currentClient.item.data
}))(CurrentUserInfo);
