import React from 'react';
import 'whatwg-fetch';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { RadioField, SelectField } from 'jbc-front/components/Form';
import ActionButton from 'jbc-front/components/ActionButton';
import { Edit, Copy, PlusCircle } from 'jbc-front/components/icons';
import { CLIENT_CSV_EMPLOYEE_FORMAT_NEW, CLIENT_CSV_EMPLOYEE_FORMAT_EDIT } from 'src/constants/EndpointUrls';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';
import { redirectTo, concatParamsToUrl } from 'src/utils/Http';
import styles from './DownloadCsvModal.scss';

const DOWNLOAD_TARGET = [
  { value: 'working and leaved', label: '在職中＋休職中' },
  { value: 'search', label: '検索絞込中の従業員' },
  { value: 'all', label: '全従業員' }
];
const editUrl = formatId => concatParamsToUrl(CLIENT_CSV_EMPLOYEE_FORMAT_EDIT, { id: formatId });

const DownloadCsvInsideForm = props => {
  const { submit, submitting, hideModal, isOpen, csvFormats, selectedFormat, isTemplate, copyFormat } = props;
  const header = '従業員データダウンロード';
  return (
    <CommonModal isOpen={isOpen} hideModal={hideModal} ariaHideApp={false} closeOnClick className={styles.modalOutLine}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <form>
        <CommonModal.Body className={styles.body}>
          <div className={styles.label}>ファイル形式を選択</div>
          <DownloadCsvEncodingRadioButton name={'encoding'} label={null} options={ENCODING_OPTIONS} required={false} />
          <div className={styles.label}>ダウンロード対象選択</div>
          <RadioField label="" name="target" options={DOWNLOAD_TARGET} />
          <div className={styles.note}>
            在職中＋休職中：在職中および休職中の従業員情報をダウンロードします。<br />
            検索絞込中の従業員：従業員一覧で検索絞込した従業員情報をダウンロードします。<br />
            全従業員：全従業員情報をダウンロードします。（※退職済みの従業員を含む）
          </div>
          <div className={styles.label}>ダウンロード項目選択</div>
          <div className={styles.note}>
            項目とヘッダを指定してフォーマットを作成し、ダウンロードできます。<br />すべての項目をダウンロードしたい場合は【全項目】を選択してください。
          </div>
          <SelectField widthAuto name="csvFormat" options={csvFormats} addBlankOption={false} />
          <ActionButton
            className={styles.marginRight}
            icon={<Edit size={16} />}
            disabled={isTemplate}
            onClick={() => redirectTo(editUrl(selectedFormat.csvFormat))}
          >
            閲覧・編集
          </ActionButton>
          <ActionButton
            className={styles.marginRight}
            as="a"
            href={CLIENT_CSV_EMPLOYEE_FORMAT_NEW}
            icon={<PlusCircle size={16} />}
          >
            新規項目フォーマット作成
          </ActionButton>
          <ActionButton
            icon={<Copy size={16} />}
            disabled={isTemplate}
            onClick={() => copyFormat(selectedFormat.csvFormat)}
          >
            フォーマットのコピー作成
          </ActionButton>
        </CommonModal.Body>
        <CommonModal.Footer>
          <Button onClick={hideModal} className="u-mr20">
            キャンセル
          </Button>
          <Button primary onClick={submit} disabled={submitting}>
            ダウンロード
          </Button>
        </CommonModal.Footer>
      </form>
    </CommonModal>
  );
};

export default DownloadCsvInsideForm;
