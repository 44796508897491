import React from 'react';
import Button from 'jbc-front/components/Button';
import { COORDINATIONS_SETTING_CLIENT_DEPARTMENT } from 'src/constants/EndpointUrls';
import { FileField } from 'jbc-front/components/Form';
import { Upload } from 'jbc-front/components/icons';
import { ErrorsSection } from 'src/components';
import ImportCsvJobStatus from 'src/features/Groups/components/ImportCsvJobStatus';
import { GROUP_DISABLED_REASON } from 'src/reducers/clients/groups';
import Hint from 'jbc-front/components/Hint';
import FileImportDescription from './FileImportDescription';
import styles from './UpdateBatchGroupCsv.scss';
import image from '../images/csv-update-help-group.png';

const Help = () => (
  <Hint
    height={200}
    width={420}
    text={
      <div className={styles.help}>
        <div className={styles.image}>
          <img src={image} alt="設定イメージ" />
        </div>
        グループ情報はグループ設定画面からダウンロードできます
      </div>
    }
  />
);

const UpdateBatchGroupCsv = ({ handleSubmit, submitting, errors, importing }) => (
  <div>
    <div className="l-title-wrap u-sp-pb60">
      <h1 className="m-title-main">グループ情報の更新</h1>
    </div>
    <div className={styles.jobStatus}>
      <ImportCsvJobStatus />
    </div>
    <div className={styles.wrap}>
      <div className={styles.head}>更新するグループ情報を記載したファイルを選択してください</div>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <ErrorsSection errors={errors} />
          <div className={styles.box}>
            <div className={styles.text}>
              対応しているファイル形式はCSVとなります<br />
              グループ情報を
              <a href={`${COORDINATIONS_SETTING_CLIENT_DEPARTMENT}?tab=groups`} className={styles.link}>
                こちら
              </a>
              <Help />
              からダウンロードし、更新したい箇所をご修正ください
            </div>
            <FileField label="グループのデータファイル" name="groups_csv" className={styles.input} required />
            <Button
              as="button"
              primary
              type="submit"
              disabled={submitting || importing}
              disabledReason={GROUP_DISABLED_REASON}
              icon={<Upload size={16} />}
              widthWide
            >
              アップロード
            </Button>
          </div>
        </form>
      </div>
    </div>
    <div className={`u-mt50 ${styles.warningWrap}`}>
      <p className="u-ta-l">
        <span className={styles.warningTitle}>【注意】</span>
        ①グループコードが一致するグループ情報を更新しますので
        <span className={styles.warning}>正しいグループコードをご記入ください</span>
        <br />
        ②グループの数がCSVデータの編集前より減る場合、差分は削除されません。不要なグループは設定画面より削除してください
        <br />
      </p>
    </div>
    <FileImportDescription update />
  </div>
);

export default UpdateBatchGroupCsv;
