import React from 'react';
import { reduxForm, change } from 'redux-form';
import { connect } from 'react-redux';
import { CLIENT_BONUS_ALLOWANCE_FORM } from 'src/constants/FormNames';
import { CLIENT_BONUS_ALLOWANCES_LIST } from 'src/constants/EndpointUrls';
import { Section, RadioField, TextField, SelectField, CheckboxField, Label } from 'jbc-front/components/Form';
import selector from 'src/utils/Utils';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  getClientBonusAllowanceItem,
  getAllowanceFormValues,
  getClientBonusAllowanceErrors,
  saveBonusAllowance,
  getClientBonusExtras
} from 'src/reducers/clients/bonusAllowances';
import { FormSubmitBtn, CancelBtn } from 'src/buttons';
import { maxLength30 } from 'src/utils/CustomValidators';
import { ErrorsSection } from 'src/components';

import styles from './ClientBonusAllowancesForm.scss';

class RawClientBonusAllowanceForm extends React.Component {
  checkonUseYearEndAdjustment(newValue) {
    const { dispatch } = this.props;
    if (newValue) {
      dispatch(change(CLIENT_BONUS_ALLOWANCE_FORM, 'useAsSocialInsurance', 'not_cover'));
      dispatch(change(CLIENT_BONUS_ALLOWANCE_FORM, 'name', '年末調整精算用'));
    }
  }

  render() {
    const {
      errors,
      submitting,
      pristine,
      submit,
      bonusAllowanceTypes,
      bonusUseAsSocialInsurances,
      selectingAllowanceType,
      isUsedBonus,
      originalName,
      isDefaultItem,
      isDefaultDeductionItem,
      useYearEndAdjustment,
      officeStationMappingPaymentItems,
      officeStationMappingDeductionItems,
      isSyncOfficeStation,
      isAvailableOfficeStation,
      clientOfficeStationSetting,
      formulaOrderType
    } = this.props;

    const allowanceTypeDisabled = isUsedBonus || isDefaultItem;
    const disableNameEdit = ['flat_tax_reduction'].includes(formulaOrderType);
    return (
      <form onSubmit={e => e.preventDefault()}>
        <Section>
          <ErrorsSection errors={errors} />

          <RadioField
            name="allowanceType"
            label="支給 / 控除"
            options={bonusAllowanceTypes}
            disabled={allowanceTypeDisabled}
            required
          />

          <TextField
            name="name"
            label="項目名"
            note={
              <div>
                賞与明細に表示される名称です。項目名を変更すると、確定済みの賞与を含め、すべての「賞与明細」「賃金台帳」「支給・控除等一覧表」での項目名が変更されます。
                また、変更前の項目名として集計していたデータを、変更後の項目名として集計します。
              </div>
            }
            required
            validate={maxLength30}
            maxLength="30"
            disabled={disableNameEdit}
          />
          {originalName && <p className={styles.payslipNote}>※元の項目名：{originalName}</p>}

          {isAvailableOfficeStation &&
            clientOfficeStationSetting && (
              <div>
                <CheckboxField
                  name="isSyncOfficeStation"
                  label="オフィスステーションと連携する"
                  normalize={value => !!value}
                />
                {isSyncOfficeStation && (
                  <SelectField
                    name="officeStationItemName"
                    addBlankOption={false}
                    options={
                      selectingAllowanceType === 'payment'
                        ? officeStationMappingPaymentItems
                        : officeStationMappingDeductionItems
                    }
                  />
                )}
              </div>
            )}
          <Label text="詳細設定" />

          {selectingAllowanceType === 'payment' && (
            <div>
              <CheckboxField name="isTaxableTarget" label="所得税の計算対象" />

              <CheckboxField name="isCompensationTarget" label="社会保険の計算対象" />

              <CheckboxField name="isTotalWageIncluded" label="労働保険の計算対象" />

              <CheckboxField name="isInKindPayment" label="現物支給" />

              <CheckboxField
                name="notDisplay0YenOnPayslip"
                label="0円の場合明細に表示しない"
                normalize={value => !!value}
              />

              <CheckboxField
                name="isInDisuse"
                label="この項目を使用しない（非表示設定）"
                normalize={value => !!value}
                note={
                  <div>
                    チェックを入れると、以下のように変更されます。
                    <ul className={styles.list}>
                      <li>・賞与明細から表示されなくなります。</li>
                      <li>・支給・控除項目CSVアップロードで取り込めなくなります。</li>
                    </ul>
                  </div>
                }
              />
            </div>
          )}

          {selectingAllowanceType === 'deduction' && (
            <div>
              {/* 年末調整精算用もしくはデフォルトの控除項目以外の場合に表示 */}
              {(useYearEndAdjustment || !isDefaultDeductionItem) && (
                <React.Fragment>
                  <Label text="社会保険料として扱う" />
                  <RadioField
                    name="useAsSocialInsurance"
                    options={bonusUseAsSocialInsurances}
                    disabled={useYearEndAdjustment}
                  />
                </React.Fragment>
              )}

              <CheckboxField
                name="notDisplay0YenOnPayslip"
                label="0円の場合明細に表示しない"
                normalize={value => !!value}
              />

              {/* 年末調整精算用もしくはデフォルトの控除項目以外の場合に表示 */}
              {(useYearEndAdjustment || !isDefaultDeductionItem) && (
                <CheckboxField
                  name="isInDisuse"
                  label="この項目を使用しない（非表示設定）"
                  normalize={value => !!value}
                  note={
                    <div>
                      チェックを入れると、以下のように変更されます。
                      <ul className={styles.list}>
                        <li>・賞与明細から表示されなくなります。</li>
                        <li>・支給・控除項目CSVアップロードで取り込めなくなります。</li>
                      </ul>
                    </div>
                  }
                />
              )}
            </div>
          )}
        </Section>
        <div className="u-ta-c">
          <CancelBtn href={CLIENT_BONUS_ALLOWANCES_LIST} className="u-mr20" />
          <FormSubmitBtn text="保存" {...{ submitting, pristine, submit }} />
        </div>
      </form>
    );
  }
}

const ClientBonusAllowanceForm = reduxForm({
  form: CLIENT_BONUS_ALLOWANCE_FORM
})(RawClientBonusAllowanceForm);

export default connect(
  state => ({
    errors: getClientBonusAllowanceErrors(state),
    initialValues: getClientBonusAllowanceItem(state),
    bonusAllowanceTypes: getSelectOptions(state, 'bonusAllowanceTypes'),
    bonusUseAsSocialInsurances: getSelectOptions(state, 'bonusUseAsSocialInsurances'),
    selectingAllowanceType: (getAllowanceFormValues(state) || {}).allowanceType,
    isUsedBonus: getClientBonusExtras(state).isUsedBonus || false,
    originalName: getClientBonusExtras(state).originalName || '',
    isDefaultItem: getClientBonusExtras(state).isDefaultItem || false,
    isDefaultDeductionItem: getClientBonusExtras(state).isDefaultDeductionItem || false,
    useYearEndAdjustment: selector(getAllowanceFormValues(state), 'useYearEndAdjustment'),
    officeStationMappingPaymentItems: getSelectOptions(state, 'officeStationMappingPaymentItems'),
    officeStationMappingDeductionItems: getSelectOptions(state, 'officeStationMappingDeductionItems'),
    isSyncOfficeStation: selector(getAllowanceFormValues(state), 'isSyncOfficeStation'),
    isAvailableOfficeStation: state.currentClient.item.data.isAvailableOfficeStation,
    clientOfficeStationSetting: state.currentClient.item.data.clientOfficeStationSetting,
    formulaOrderType: selector(getAllowanceFormValues(state), 'formulaOrderType')
  }),
  dispatch => ({
    onSubmit: data => dispatch(saveBonusAllowance(data))
  })
)(ClientBonusAllowanceForm);
