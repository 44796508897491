import React from 'react';
import { formatDateHourJa } from 'src/utils/Date';
import _ from 'lodash';
import styles from './ShowSystemAnnounce.scss';

const parseHtml = html => {
  if (!html) {
    return { __html: '' };
  }
  let escaped = _.escape(html);
  escaped = escaped.replace(
    /&lt;a href=(&quot;|&#39;|)(https?:\/\/.+?)(&quot;|&#39;|)&gt;(.+?)&lt;\/a&gt;/g,
    '<a href="$2" target="_blank">$4</a>'
  );
  const unescaped = _.unescape(escaped).replace(
    /(^|[^"'=])(https?:\/\/[-_.!~*'()a-zA-Z0-9;/?:@&=+$,%#]+)/g,
    '$1<a href="$2" target="_blank">$2</a>'
  );
  return { __html: unescaped };
};

const ShowSystemAnnounce = ({ systemAnnounce }) => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">お知らせ</h1>
    </div>
    <div className="l-wrap-s">
      <div className={styles.date}>{formatDateHourJa(systemAnnounce.publishAt)}</div>
      <h2 className={styles.title}>{systemAnnounce.title}</h2>
      <div className={styles.body} dangerouslySetInnerHTML={parseHtml(systemAnnounce.sanitizedBody)} />
    </div>
  </div>
);

export default ShowSystemAnnounce;
