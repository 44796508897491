import React, { Component } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getList } from 'src/reducers/common/listReducer';
import styles from './InvitationTable.scss';
import InvitationRow from '../components/InvitationRow';
import EmailForm from './EmailForm';

class InvitationTable extends Component {
  disabledMessage(employee) {
    if (!employee.email) {
      return 'メールアドレスを入力してください';
    } else if (employee.invitationStatus === 'registered') {
      return 'すでに登録済みです';
    } else if (_.isEmpty(employee.joinedOn)) {
      return '先に入社日を設定してください';
    }
    return null;
  }

  state = {
    emailForm: {
      isOpen: false,
      employee: null
    }
  };

  render() {
    const { employees, handleSelectClick, handleSelectAllClick, selectedEmployees, isSelectAll } = this.props;

    return (
      <div className="l-overflow-scroll">
        <table className="m-table-list">
          <thead>
            <tr>
              <th
                onClick={() => handleSelectAllClick(employees)}
                className={isSelectAll ? 'm-table-checkbox-on' : 'm-table-checkbox-off'}
              >
                <input type="checkbox" readOnly checked={isSelectAll} />
              </th>
              <th className={styles.memo}>メモ</th>
              <th className={styles.staffCode}>スタッフコード</th>
              <th className={styles.name}>氏名</th>
              <th className={styles.email}>メールアドレス</th>
              <th className={styles.invitationStatus}>招待状況</th>
              <th className={styles.groupPath}>グループ</th>
              <th className={styles.joinedOn}>入社年月日</th>
            </tr>
          </thead>
          <tbody>
            {!_.isEmpty(employees) &&
              employees.map(employee => (
                <InvitationRow
                  key={employee.id}
                  checkboxOnClick={() => handleSelectClick(employee)}
                  employee={employee}
                  selected={!!selectedEmployees.find(item => item.id === employee.id)}
                  disabledMessage={this.disabledMessage(employee)}
                  openEmailForm={() => {
                    this.setState({ emailForm: { isOpen: true, employee } });
                  }}
                />
              ))}
            {_.isEmpty(employees) && (
              <tr>
                <td colSpan={8}>データがありません</td>
              </tr>
            )}
          </tbody>
        </table>
        <EmailForm
          {...this.state.emailForm}
          hideModal={() => {
            this.setState({ emailForm: { isOpen: false, employee: null } });
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  employees: getList(REDUCER_NAME, state)
});

export default connect(mapStateToProps)(InvitationTable);
