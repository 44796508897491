import React from 'react';
import { FormSection } from 'redux-form';
import { connect } from 'react-redux';

import MARITAL_STATUSES from 'src/constants/Options';
import { RadioField } from 'jbc-front/components/Form';

const MaritalStatusSection = () => (
  <div>
    <FormSection name="employee">
      <RadioField name="maritalStatus" options={MARITAL_STATUSES} />
    </FormSection>
  </div>
);

export default connect()(MaritalStatusSection);
