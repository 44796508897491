import { combineReducers } from 'redux';
import { startSubmit, stopSubmit, change as changeForm } from 'redux-form';
import axios from 'axios';
import humps from 'humps';
import qs from 'qs';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import _ from 'lodash';

import * as Urls from 'src/constants/EndpointUrls';
import { excludeValuesIfNotDefined } from 'src/utils/Http';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { newItemFailure, setItemError } from 'src/reducers/common/itemReducer';
import { fetchList, fetchListSuccess, fetchListFailure } from 'src/reducers/common/listReducer';
import { REPORT_HEALTH_INSURANCE_BONUS_FORM } from 'src/constants/FormNames';

export const REDUCER_NAME = 'reportHealthInsuranceBonus';
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));

// Async Action Creators
export const fetchReportHealthInsuranceBonus = queries => async dispatch => {
  try {
    dispatch(fetchList(REDUCER_NAME));
    const excluedQueries = excludeValuesIfNotDefined(queries);
    const response = await axios.get(Urls.REPORT_HEALTH_INSURANCE_BONUS_SEARCH, {
      params: humps.decamelizeKeys(excluedQueries)
    });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.employeeBonus,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const downloadCsv = (data, searchKey, callBack) => async dispatch => {
  try {
    dispatch(setItemError(REDUCER_NAME, []));
    const params = {
      payment_for: data.paymentFor,
      serial_number: data.serialNumber,
      ...searchKey
    };
    const query = qs.stringify(humps.decamelizeKeys({ csv: params }), { arrayFormat: 'brackets' });
    const downloadUrl = `${Urls.REPORT_HEALTH_INSURANCE_BONUS_CSV}?${query}`;
    const response = await fetch(downloadUrl);

    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      throw new Error(json.errors.messages);
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (exception) {
    dispatch(setItemError(REDUCER_NAME, exception.message.split(',')));
  } finally {
    callBack();
  }
};

export const updateReportItem = data => async dispatch => {
  try {
    dispatch(startSubmit(REPORT_HEALTH_INSURANCE_BONUS_FORM));
    const url = Urls.REPORT_HEALTH_INSURANCE_BONUS_REPORT_ITEM_UPDATE;
    const httpBody = {
      bonus_id: data.bonusId,
      currency_amount: data.currencyAmount,
      genbutsu_amount: data.genbutsuAmount,
      is_over70years_old: data.isOver70YearsOld,
      is_work_in_two_or_more: data.isWorkInTwoOrMore,
      is_add_up: data.isAddUp,
      first_payment_day: data.firstPaymentDay,
      lock_version: data.lockVersion
    };
    await axios.post(url, httpBody);
    window.location.reload();
  } catch (exception) {
    let msg = 'データの更新に失敗しました';
    if (!_.isEmpty(exception.response) && !_.isEmpty(exception.response.data.errors)) {
      msg = exception.response.data.errors.messages;
    }
    // setErrorsだと一覧も同じstateを見ているのでそっちにもエラー情報が表示されてしまう。なのでformに持たせる
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'errors', msg));
    dispatch(stopSubmit(REPORT_HEALTH_INSURANCE_BONUS_FORM));
  }
};

export const resetReportItem = bonusId => async dispatch => {
  try {
    const url = Urls.REPORT_HEALTH_INSURANCE_BONUS_REPORT_ITEM_RESET;
    const response = await axios.get(url, { params: { bonus_id: bonusId } });
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'currencyAmount', response.data.payload.currencyAmount));
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'genbutsuAmount', response.data.payload.genbutsuAmount));
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'isOver70YearsOld', response.data.payload.over70YearsOld));
    // 以降は自動判定がないので固定で設定
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'isWorkInTwoOrMore', false));
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'isAddUp', false));
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'firstPaymentDay', ''));
  } catch (exception) {
    let msg = 'システムエラーが発生しました。';
    if (!_.isEmpty(exception.response) && !_.isEmpty(exception.response.data.errors)) {
      msg = exception.response.data.errors.messages;
    }
    dispatch(changeForm(REPORT_HEALTH_INSURANCE_BONUS_FORM, 'errors', msg));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
