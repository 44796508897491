import { connect } from 'react-redux';

import { getCurrentUserInfo } from 'src/reducers/users/currentUser';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getMode, changeRole, changeUserChecked } from 'src/reducers/users/roleUsers';

import EditableField from '../components/EditableField';

const mapStateToProps = state => ({
  mode: getMode(state),
  clientRoles: getSelectOptions(state, 'clientRoles'),
  currentLoginUser: getCurrentUserInfo(state)
});
const mapDispatchToProps = dispatch => ({
  onChange: (user, newOption) => {
    dispatch(changeRole({ id: user.id, clientRoleId: newOption.value }));
  },
  onChangeUserChecked: userId => {
    dispatch(changeUserChecked(userId));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableField);
