import React from 'react';
import _ from 'lodash';
import { TextField, RadioField } from 'jbc-front/components/Form';
import { insuranceRate } from 'src/utils/CustomValidators';
import { ROUNDING_TYPES } from 'src/constants/Options';
import styles from './RateTable.scss';

const RateTable = props => {
  const { firstRowParams, secondRowParams, isShowSecondRow, roundingTypeForEmployee } = props;

  const roundingTypeForEmployeeName = () => {
    const item = ROUNDING_TYPES.find(element => element.value === roundingTypeForEmployee);
    if (_.isEmpty(item)) {
      return '';
    }
    return item.label;
  };
  return (
    <div>
      <div className="l-overflow-scroll">
        <table className={`${styles.table} m-table-list`}>
          <thead>
            <tr>
              <th className={styles.name} width={120}>
                保険名
              </th>
              <th>従業員負担（％）</th>
              <th>事業主負担（％）</th>
              <th className={styles.sum}>合計（％）</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{firstRowParams.label}</td>
              <td>
                <TextField
                  name={
                    _.isEmpty(firstRowParams.namePrefix)
                      ? 'rateForEmployee'
                      : `${firstRowParams.namePrefix}RateForEmployee`
                  }
                  maxLength="6"
                  validate={insuranceRate}
                  disabled={firstRowParams.disableEmployee}
                />
              </td>
              <td>
                <TextField
                  name={
                    _.isEmpty(firstRowParams.namePrefix)
                      ? 'rateForEmployer'
                      : `${firstRowParams.namePrefix}RateForEmployer`
                  }
                  maxLength="6"
                  validate={insuranceRate}
                  disabled={firstRowParams.disableEmployer}
                />
              </td>
              <td>
                <span>{firstRowParams.totalRate}</span>
              </td>
            </tr>
            {firstRowParams.displayRoundType && (
              <React.Fragment>
                <tr className={styles.roundingRow}>
                  <td colSpan="4" className={styles.roundingText}>
                    従業員負担分の端数処理方法
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className={styles.rounding}>
                    {firstRowParams.disableRoundType ? (
                      roundingTypeForEmployeeName()
                    ) : (
                      <RadioField name={firstRowParams.cloumnName} options={ROUNDING_TYPES} />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )}
            {isShowSecondRow && (
              <React.Fragment>
                <tr>
                  <td>{secondRowParams.label}</td>
                  <td>
                    <TextField
                      name={`${secondRowParams.namePrefix}RateForEmployee`}
                      maxLength="6"
                      validate={insuranceRate}
                      disabled={secondRowParams.disableEmployee}
                    />
                  </td>
                  <td>
                    <TextField
                      name={`${secondRowParams.namePrefix}RateForEmployer`}
                      maxLength="6"
                      validate={insuranceRate}
                      disabled={secondRowParams.disableEmployer}
                    />
                  </td>
                  <td>
                    <span>{secondRowParams.totalRate}</span>
                  </td>
                </tr>
                <tr className={styles.roundingRow}>
                  <td colSpan="4" className={styles.roundingText}>
                    従業員負担分の端数処理方法
                  </td>
                </tr>
                <tr>
                  <td colSpan="4" className={styles.rounding}>
                    {secondRowParams.disableRoundType ? (
                      roundingTypeForEmployeeName()
                    ) : (
                      <RadioField name={secondRowParams.cloumnName} options={ROUNDING_TYPES} />
                    )}
                  </td>
                </tr>
              </React.Fragment>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RateTable;
