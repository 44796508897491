import React from 'react';
import { FormSection, FieldArray, blur, touch, Field } from 'redux-form';
import classNames from 'classnames';
import _ from 'lodash';
import LoadingView from 'src/components/LoadingView';

import ActionButton from 'jbc-front/components/ActionButton';
import { Edit, Task, Strage, MinusCircle, Edditing, Revert } from 'jbc-front/components/icons';
import { TextAreaField } from 'jbc-front/components/Form';

import { ModifyIcon } from 'src/icons';
import { toHourFormat, toFloatFormat } from 'src/utils/Completion';
import {
  isFloatFormat,
  isHourFormat,
  isNumberOfDaysFormat,
  isMoneyNumber,
  isNumberOfTimesFormat
} from 'src/utils/CustomValidators';
import { concatParamsToUrl } from 'src/utils/Http';
import { ErrorsSection } from 'src/components';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';

import { EMPLOYEE_PAY_FORM } from 'src/constants/FormNames';
import { EMPLOYEE_SHOW, PAY_DETAIL_PDF } from 'src/constants/EndpointUrls';
import * as CssClassNames from 'src/constants/CssClassNames';
import MemoList from 'src/features/Memos';

import { numberWithComma } from 'src/utils/Utils';
import RollbackEmployeePayModalContainer from '../containers/RollbackEmployeePayModalContainer';

import PayValueDiff from './PayValueDiff';
import AllPays from './AllPays';
import styles from './EmployeePayForm.scss';
import Confirmed from '../../../components/Confirmed';
import ConfirmOneModalContainer from '../containers/ConfirmOneModalContainer';
import UnconfirmOneModalContainer from '../containers/UnconfirmOneModalContainer';

const renderValue = ({ input, disabled, meta: { touched, error }, modified }) => (
  <div {...(touched && error ? { className: `invalid ${styles.def}` } : { className: styles.def })}>
    <div className="m-table-obvious-modified">
      <ModifyIcon modified={modified} size={14} />
    </div>
    <input {...input} disabled={disabled} />
  </div>
);

const renderDiffValue = ({ input, disabled, diff, meta: { touched, error }, modified }) => (
  <div {...(touched && error ? { className: `invalid ${styles.def}` } : { className: styles.def })}>
    <div className="m-table-obvious-modified">
      <ModifyIcon modified={modified} size={14} />
    </div>
    <input {...input} disabled={disabled} />
    <PayValueDiff diff={diff} />
  </div>
);

const Row = props => {
  const { label, name, validate, onBlur, disabled, modified } = props;
  return (
    <tr>
      <th>{label}</th>
      <td>
        <Field
          component={renderValue}
          type="text"
          name={name}
          validate={validate}
          onBlur={onBlur}
          disabled={disabled}
          modified={modified}
        />
      </td>
    </tr>
  );
};

const allowanceItemValidate = unitType => {
  switch (unitType) {
    case 'time':
      return [isHourFormat];
    case 'number_of_days':
      return [isNumberOfDaysFormat];
    case 'number_of_times':
      return [isNumberOfTimesFormat];
    default:
      return [isFloatFormat];
  }
};

const allowanceItemFormat = (unitType, value) => {
  switch (unitType) {
    case 'time':
      return toHourFormat(value);
    default:
      return toFloatFormat(value);
  }
};

const MoneyField = ({ value, modified, diff }) => (
  <React.Fragment>
    <div className="m-table-obvious-inner">
      <div className="m-table-obvious-modified">
        {modified ? (
          <span className={styles.icon}>
            <Edditing size={14} />
            <span className={styles.baloon}>
              「手入力」「CSVインポート」「外部システム連携」のいずれかにより修正された項目です。
              <br />
              自動計算に戻したい場合は、編集画面から「手入力前に戻す」を押してください。
            </span>
          </span>
        ) : null}
      </div>
      {numberWithComma(value)}
    </div>
    <PayValueDiff diff={diff} />
  </React.Fragment>
);

const PaymentsSection = props => {
  const { fields, isViewOnly } = props;
  return (
    <tbody>
      {fields.map((field, index) => {
        const value = fields.get(index);
        return (
          value.label !== '社会保険合計' && (
            <tr key={field}>
              <th>{value.label}</th>
              <td>
                {!isViewOnly && (
                  <Field
                    component={renderDiffValue}
                    type="text"
                    diff={value.diff}
                    name={`${field}.value`}
                    key={field}
                    validate={value.id ? isMoneyNumber : null}
                    disabled={value.disabled || isViewOnly}
                    modified={value.isInput}
                  />
                )}
                {isViewOnly && <MoneyField value={value.value} modified={value.isInput} diff={value.diff} />}
              </td>
            </tr>
          )
        );
      })}
    </tbody>
  );
};

const NoMemoDefaultDisplay = () => <div className={styles.noMemo} />;

class EmployeePayForm extends React.Component {
  state = {
    isPdfPreview: false,
    isRollbackModalOpen: false,
    isConfirmOneModalOpen: false,
    isUnconfirmOneModalOpen: false,
    isProcessingConfirmation: false,
    currentEmployeePayIsConfirmed: this.props.employeePayIsConfirmed
  };

  static getDerivedStateFromProps(props, state) {
    if (props.employeePayIsConfirmed !== state.currentEmployeePayIsConfirmed) {
      return {
        isProcessingConfirmation: false,
        currentEmployeePayIsConfirmed: props.employeePayIsConfirmed
      };
    }

    return null;
  }

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };
  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };
  render() {
    const {
      dispatch,
      submit,
      handleSubmit,
      errors,
      isListLoading,
      pristine,
      submitting,
      employeeId,
      empId,
      employeePayIsConfirmed,
      initialValues,
      changeViewModeToTrue,
      changeViewModeToFalse,
      isViewOnly,
      reset,
      paymentMonthConfirmed,
      allowanceItems,
      allEmployeePays,
      confirmPayJobs,
      downloadPayCsvJobs,
      importPayCsvJobs,
      clearRollbackError
    } = this.props;

    const { employeePay, calcErrors } = initialValues;

    const hasRunningPayJobs =
      !_.isEmpty(confirmPayJobs.inProgress) ||
      !_.isEmpty(confirmPayJobs.waiting) ||
      !_.isEmpty(downloadPayCsvJobs.inProgress) ||
      !_.isEmpty(downloadPayCsvJobs.waiting) ||
      !_.isEmpty(importPayCsvJobs.inProgress) ||
      !_.isEmpty(importPayCsvJobs.waiting);

    const disabledEditBtn = employeePayIsConfirmed || hasRunningPayJobs;
    const hasNonEmptyValue = obj => Object.values(obj).some(value => !_.isEmpty(value));
    const errorsExist = hasNonEmptyValue(errors) || !_.isEmpty(calcErrors);
    return (
      <form className={classNames(CssClassNames.FORM, CssClassNames.CLIENT_FORM)} onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        <ErrorsSection errors={calcErrors} title="計算時にエラーが発生しました。" />

        <div className={styles.name}>
          <div className={styles.memo}>
            <MemoList employeeId={employeeId} size={44} />
          </div>
          <div className={styles.nameFull}>
            <a href={concatParamsToUrl(EMPLOYEE_SHOW, { id: employeeId })}>{employeePay.employeeDisplayName}</a>
          </div>
        </div>

        <LoadingView isLoading={isListLoading}>
          {/* 給与情報が取得できた場合だけ表示する */}
          {!_.isEmpty(allEmployeePays) && (
            <div>
              <div className={styles.container}>
                <div className={`${styles.confirmed} ${!employeePayIsConfirmed && styles.confirmedDisable}`}>
                  <Confirmed />
                </div>
                <AllPays allEmployeePays={allEmployeePays} employeePay={employeePay} />
              </div>

              <div className={styles.buttons}>
                <div className={styles.buttonsLeft}>
                  {isViewOnly ? (
                    <ActionButton
                      disabled={disabledEditBtn}
                      disabledReason={hasRunningPayJobs ? '給与情報を更新中なので編集できません' : null}
                      onClick={changeViewModeToFalse}
                      icon={<Edit size={15} />}
                    >
                      編集
                    </ActionButton>
                  ) : (
                    <div>
                      <ActionButton
                        onClick={submit}
                        disabled={pristine || submitting || disabledEditBtn}
                        disabledReason={hasRunningPayJobs ? '給与情報を更新中なので編集できません' : null}
                        icon={<Strage size={15} />}
                      >
                        保存
                      </ActionButton>
                      <ActionButton
                        onClick={() => {
                          reset();
                          changeViewModeToTrue();
                        }}
                        disabled={submitting}
                        icon={<MinusCircle size={15} />}
                      >
                        キャンセル
                      </ActionButton>
                    </div>
                  )}
                </div>
                <div className={styles.buttonsRight}>
                  {!isViewOnly ? (
                    <span>
                      <ActionButton
                        icon={<Revert size={14} />}
                        onClick={() => {
                          this.setState({ isRollbackModalOpen: true });
                        }}
                      >
                        手入力前に戻す
                      </ActionButton>
                      <RollbackEmployeePayModalContainer
                        isOpen={this.state.isRollbackModalOpen}
                        hideModal={() => {
                          this.setState({ isRollbackModalOpen: false });
                          clearRollbackError();
                          changeViewModeToTrue();
                        }}
                        employeePayId={empId}
                      />
                    </span>
                  ) : null}
                  {!employeePayIsConfirmed ? (
                    <ActionButton
                      primary
                      icon={<Task size={15} />}
                      onClick={() => {
                        this.setState({ isConfirmOneModalOpen: true });
                      }}
                      disabled={paymentMonthConfirmed || errorsExist || !isViewOnly || submitting || this.state.isProcessingConfirmation}
                    >
                      給与を確定する
                    </ActionButton>
                  ) : (
                    <ActionButton
                      primary
                      icon={<Task size={15} />}
                      onClick={() => {
                        this.setState({ isUnconfirmOneModalOpen: true });
                      }}
                      disabled={paymentMonthConfirmed || submitting || this.state.isProcessingConfirmation}
                    >
                      確定を解除する
                    </ActionButton>
                  )}
                  <ActionButton
                    primary
                    icon={<Task size={15} />}
                    onClick={this.showPreview}
                    disabled={!employeePayIsConfirmed}
                  >
                    PDF出力
                  </ActionButton>
                  <PreviewPdfModalContainer
                    header="給与明細"
                    isOpen={this.state.isPdfPreview}
                    hideModal={this.hidePreview}
                    url={`${PAY_DETAIL_PDF}?id=${empId}`}
                  />
                  {!employeePayIsConfirmed &&
                    this.state.isConfirmOneModalOpen && (
                      <ConfirmOneModalContainer
                        isOpen={this.state.isConfirmOneModalOpen}
                        hideModal={() => {
                          this.setState({ isConfirmOneModalOpen: false });
                          clearRollbackError();
                        }}
                        submitCallback={() => this.setState({ isProcessingConfirmation: true })}
                        empId={empId}
                      />
                    )}
                  {employeePayIsConfirmed &&
                    this.state.isUnconfirmOneModalOpen && (
                      <UnconfirmOneModalContainer
                        isOpen={this.state.isUnconfirmOneModalOpen}
                        hideModal={() => {
                          this.setState({ isUnconfirmOneModalOpen: false });
                          clearRollbackError();
                        }}
                        submitCallback={() => this.setState({ isProcessingConfirmation: true })}
                        empId={empId}
                      />
                    )}
                </div>
              </div>

              <div className="l-overflow-scroll">
                <div className={styles.payment}>
                  <div className={styles.paymentClm}>
                    <FormSection name="employeePay">
                      <table className="m-table-obvious">
                        <thead>
                          <tr>
                            <th colSpan="2">勤怠</th>
                          </tr>
                        </thead>
                        <tbody>
                          {allowanceItems &&
                            allowanceItems.all.map(item => (
                              <Row
                                key={item.name}
                                label={item.label}
                                name={item.name.toString()}
                                validate={allowanceItemValidate(item.unitType)}
                                onBlur={(e, newValue, previousValue) => {
                                  e.preventDefault();
                                  dispatch(
                                    blur(
                                      `${EMPLOYEE_PAY_FORM}`,
                                      `employeePay.${item.name}`,
                                      allowanceItemFormat(item.unitType, previousValue)
                                    )
                                  );
                                  dispatch(touch(`${EMPLOYEE_PAY_FORM}`, `employeePay.${item.name}`));
                                }}
                                disabled={isViewOnly}
                                modified={item.isInput}
                              />
                            ))}
                        </tbody>
                      </table>
                    </FormSection>
                  </div>

                  <div className={styles.paymentClm}>
                    <table className="m-table-obvious">
                      <thead>
                        <tr>
                          <th colSpan="2">支給</th>
                        </tr>
                      </thead>
                      <FieldArray name="payments" component={PaymentsSection} isViewOnly={isViewOnly} />
                    </table>
                  </div>
                  <div className={styles.paymentClm}>
                    <table className="m-table-obvious">
                      <thead>
                        <tr>
                          <th colSpan="2">控除</th>
                        </tr>
                      </thead>
                      <FieldArray name="deductions" component={PaymentsSection} isViewOnly={isViewOnly} />
                    </table>
                  </div>
                  <div className={styles.paymentClm}>
                    <table className="m-table-obvious">
                      <thead>
                        <tr>
                          <th colSpan="2">集計</th>
                        </tr>
                      </thead>
                      <FieldArray name="summaries" component={PaymentsSection} isViewOnly={isViewOnly} />
                    </table>
                    <table className={'m-table-obvious u-mt10'}>
                      <thead>
                        <tr>
                          <th>備考</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="m-table-obvious-remarks">
                            <FormSection name="employeePay">
                              {isViewOnly && (
                                <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                                  {initialValues.employeePay.memo || <NoMemoDefaultDisplay />}
                                </div>
                              )}
                              {!isViewOnly && <TextAreaField name="memo" />}
                            </FormSection>
                            {!isViewOnly && <p className="m-table-obvious-note">最大255文字入力可</p>}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </LoadingView>
      </form>
    );
  }
}

export default EmployeePayForm;
