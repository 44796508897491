import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import Button from 'jbc-front/components/Button';
import { UTF_8, WINDOWS_31J } from 'src/constants/Generals';
import { downloadCsv } from 'src/reducers/reports/reportFlatTaxReductionLedger';
import SimpleConfirmModal from 'src/components/SimpleConfirmModal';

import styles from './FlatTaxReductionLedgerCsv.scss';

const ENCODE_OPTIONS = [{ value: UTF_8, label: 'UTF-8(推奨)' }, { value: WINDOWS_31J, label: 'Shift_JIS' }];

const ModalBody = ({ encoding, setEncoding }) => (
  <>
    <div className={styles.label}>ファイル形式を選択</div>
    <div style={{ 'margin-bottom': '20px' }}>
      {ENCODE_OPTIONS.map(option => (
        <label className={styles.inputLabel}>
          <input
            className="m-radio-input"
            type="radio"
            value={option.value}
            checked={encoding === option.value}
            onChange={e => setEncoding(e.target.value)}
          />
          <span className="m-radio-parts">{option.label}</span>
        </label>
      ))}
    </div>
    <div className={styles.note}>Excel2016より前のバージョンのExcelをお使いの方はShift-JISをご利用ください。</div>
  </>
);

const FlatTaxReductionLedgerCsvDownloadButton = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [encoding, setEncoding] = useState(UTF_8);
  const dispatch = useDispatch();
  const onClick = () => {
    setWaiting(true);
    downloadCsv(dispatch, encoding);
    setIsOpen(false);
    setWaiting(false);
  };

  return (
    <>
      <Button as={'button'} style={{ 'margin-top': '20px' }} primary disabled={false} onClick={() => setIsOpen(true)}>
        CSVダウンロード
      </Button>
      {isOpen && (
        <SimpleConfirmModal
          header={'2024年定額減税各人別控除事績簿ダウンロード'}
          body={<ModalBody {...{ encoding, setEncoding }} />}
          isOpen={isOpen}
          hideModal={() => setIsOpen(false)}
          onClick={onClick}
          confirmBtnText={'ダウンロード'}
          disabled={waiting}
        />
      )}
    </>
  );
};

// eslint-disable-next-line import/prefer-default-export
export { FlatTaxReductionLedgerCsvDownloadButton };
