import React from 'react';

import ActionButton from 'jbc-front/components/ActionButton';
import Storage from 'jbc-front/components/icons/Strage';

const SaveActionBtn = ({ onClick, label = '保存', as, ...rest }) => (
  <ActionButton as={as || 'button'} icon={<Storage />} onClick={onClick} {...rest}>
    {label}
  </ActionButton>
);

export default SaveActionBtn;
