import React from 'react';
import classNames from 'classnames';
import styles from './DeclarationFormInsuranceFeeHatching.scss';

const Hatching = props => {
  if (props.isDisplay) {
    return (
      <div
        className={classNames(
          styles.hatching,
          props.isLessThan2020 ? styles.hatchingHeightLess2020 : styles.hatchingHeight
        )}
      >
        <p className={styles.annotation}>
          労災保険分と雇用保険分が同額のため、<br />
          この部分の申告書への記入は不要です。
        </p>
      </div>
    );
  }
  return null;
};

export default Hatching;
