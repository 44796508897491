import React from 'react';
import { connect } from 'react-redux';
import japanese from 'japanese';
import set from 'lodash/set';
import axios from 'axios';
import { FormSection, FieldArray, arrayPush, touch as formTouch, change as formChange } from 'redux-form';
import _, { debounce } from 'lodash';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { SEARCH_POSTAL_CODE_URL } from 'src/constants/EndpointUrls';
import { calEstimatedAnnualEarnings, determineMaxDependentMoney } from 'jbc-front/utils/calculation';

import {
  SelectField,
  TextField,
  RadioField,
  TextFieldWithKana,
  CheckboxField,
  DateField,
  BoxDouble,
  Section
} from 'jbc-front/components/Form';
import { PlusSquare, DeleteSquare } from 'jbc-front/components/icons';
import { isNumber, zenkakuKatakana } from 'src/utils/CustomValidators';
import selector, { fetchValueByIndexAndField } from 'src/utils/Utils';
import { handlePostalCodeChange } from 'src/utils/Form';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import { getSelectOptions, getRadioOptions } from 'src/reducers/selectOptions';
import { PostalCodeField } from 'src/components';

let GeneralInfo = props => {
  const {
    relationshipTypes,
    relationDetailTypes,
    genders,
    residentTypes,
    disabledPersonTypes,
    children,
    selectedRelationship,
    handleFirstNameKanaChange,
    handleLastNameKanaChange,
    onChangeYearlyIncome
  } = props;

  return (
    <div>
      <RadioField label="続柄" name="relationshipType" required options={relationshipTypes} />

      {selectedRelationship &&
        selectedRelationship === 'other' && (
          <SelectField
            label="続柄詳細"
            name="relationshipDetail"
            required
            options={relationDetailTypes}
            note="リストにない続柄は直接入力して追加できます"
            creatable
          />
        )}

      <BoxDouble>
        <TextFieldWithKana required label="姓" name="lastName" onUpdateKana={handleLastNameKanaChange} />
        <TextFieldWithKana required label="名" name="firstName" onUpdateKana={handleFirstNameKanaChange} />
      </BoxDouble>

      <BoxDouble>
        <TextField label="姓(カナ)" name="lastNameKana" validate={zenkakuKatakana} />
        <TextField label="名(カナ)" name="firstNameKana" validate={zenkakuKatakana} />
      </BoxDouble>

      <DateField viewMode="years" label="生年月日" name="birthday" required />

      <RadioField label="性別" name="gender" options={genders} />

      <RadioField label="同居・別居" name="residentType" options={residentTypes} />

      {children}

      <TextField
        label="年間収入（1月～12月）"
        maxLength="9"
        name="yearlyIncome"
        yen
        validate={isNumber}
        onChange={onChangeYearlyIncome}
      />
      <TextField label="年間所得（1月～12月）" maxLength="9" name="yearlyEarning" yen required validate={isNumber} />

      <RadioField label="障害者区分" name="disabledPersonType" options={disabledPersonTypes} />

      <CheckboxField name="isTaxDependency" label="税の扶養対象" />
    </div>
  );
};

GeneralInfo = connect(
  state => ({
    relationshipTypes: getRadioOptions(state, 'relationshipTypes'),
    relationDetailTypes: getRadioOptions(state, 'relationDetailTypes'),
    genders: getRadioOptions(state, 'genders'),
    residentTypes: getRadioOptions(state, 'residentTypes'),
    disabledPersonTypes: getRadioOptions(state, 'disabledPersonTypes')
  }),
  (dispatch, ownProps) => ({
    handleFirstNameKanaChange: value => {
      const prefix = ownProps.formKey;
      const firstNameKana = japanese.katakanize(value);
      dispatch(formChange(EMPLOYEE_FORM, `${prefix}.firstNameKana`, firstNameKana));
    },
    handleLastNameKanaChange: value => {
      const prefix = ownProps.formKey;
      const lastNameKana = japanese.katakanize(value);
      dispatch(formChange(EMPLOYEE_FORM, `${prefix}.lastNameKana`, lastNameKana));
    },
    onChangeYearlyIncome: (e, value) => {
      const prefix = ownProps.formKey;
      const yearlyEarning = calEstimatedAnnualEarnings(value);
      dispatch(formChange(EMPLOYEE_FORM, `${prefix}.yearlyEarning`, `${yearlyEarning}`));
    }
  })
)(GeneralInfo);

let SeparatedDomesticAddr = ({ handlePostalQueryChange, prefectures }) => (
  <div>
    <PostalCodeField handleOnChange={handlePostalQueryChange} />
    <BoxDouble>
      <SelectField label="都道府県" name="prefectureId" options={prefectures} />
      <TextField label="市区町村" name="city" />
    </BoxDouble>

    <BoxDouble>
      <TextField label="丁目番地号" name="street" />
      <TextField label="建物名" name="building" />
    </BoxDouble>
  </div>
);

SeparatedDomesticAddr = connect(state => ({
  prefectures: getSelectOptions(state, 'prefectures')
}))(SeparatedDomesticAddr);

const SeparatedOverseaAddr = ({ selectedRelationship }) => (
  <div>
    <TextField label="Address" name="overseaAddress" />
    <CheckboxField
      name="isNonResident"
      label="非居住者"
      description={`国外に住所（居所）を有する期間が1年に満たない場合は非居住者のチェックを外してください。`}
    />
    {selectedRelationship &&
      selectedRelationship === 'other' && (
        <CheckboxField
          name="isStudyAbroad"
          label="留学"
          description={`被扶養者が非居住者のうち留学の場合はチェックしてください。`}
        />
      )}
    <TextField label="国外居住親族への送金額" name="overseaSendingMoneyAmount" yen validate={isNumber} />
  </div>
);

class FamilySupport extends React.Component {
  constructor(props) {
    super(props);

    this._postalQueryChange = this._handlePostalQueryChange.bind(this);
    this.searchPostal = debounce(this.searchPostal, 500);
    this.searchPostal = this.searchPostal.bind(this);
  }

  getAddressKana(payload) {
    const { prefectureKana, cityKana, streetKana } = payload;
    return `${prefectureKana}${cityKana}${streetKana}`;
  }

  _handlePostalQueryChange(event, inputText) {
    return handlePostalCodeChange(event)(inputText, this.searchPostal);
  }

  async searchPostal(inputText) {
    try {
      const response = await axios.get(SEARCH_POSTAL_CODE_URL, { params: { code: inputText } });
      const payload = response.data.payload.postalInfo;
      ['prefectureId', 'city', 'street'].forEach(field =>
        this.changeFieldValue(`${this.props.formKey}.${field}`, payload[field])
      );
    } catch (exception) {
      this.props.dispatch(formTouch(EMPLOYEE_FORM, 'employee.postalCode'));
    }
  }

  changeFieldValue(fieldName, fieldValue) {
    this.props.dispatch(formChange(EMPLOYEE_FORM, fieldName, fieldValue));
  }

  render() {
    const { formKey, index, selectedResidentType, selectedRelationship, remove } = this.props;
    return (
      <div>
        <Section title={`扶養情報 ${index + 1}`} icon={<DeleteSquare onClick={remove} />}>
          <FormSection name={formKey}>
            <GeneralInfo {...{ selectedRelationship }} formKey={formKey}>
              {selectedResidentType &&
                selectedResidentType === 'separated_domestic' && (
                  <SeparatedDomesticAddr handlePostalQueryChange={this._postalQueryChange} />
                )}
              {selectedResidentType &&
                selectedResidentType === 'separated_oversea' && (
                  <SeparatedOverseaAddr selectedRelationship={selectedRelationship} />
                )}
            </GeneralInfo>
          </FormSection>
        </Section>
      </div>
    );
  }
}

const FamilySupportItem = connect()(FamilySupport);

const hasFamilySupport = value => value && value === '1';

const defaultFamilySupportItem = {
  disabledPersonType: 'no',
  isTaxDependency: true,
  isNonResident: true,
  residentType: 'together',
  yearlyIncome: 0,
  yearlyEarning: '0'
};

let FamilySupports = ({ fields, selectedFamilySupport, fetchValueByIndex, handleRemoveItem }) => (
  <div>
    {hasFamilySupport(selectedFamilySupport) &&
      fields.map((familySupportItem, index) => (
        <FamilySupportItem
          key={familySupportItem}
          formKey={familySupportItem}
          index={index}
          selectedRelationship={fetchValueByIndex('relationshipType')(index)}
          selectedResidentType={fetchValueByIndex('residentType')(index)}
          remove={() => handleRemoveItem(fields)(index)}
        />
      ))}
    {hasFamilySupport(selectedFamilySupport) && (
      <div className="u-ta-r u-mt20 u-mb20">
        <div role="button" tabIndex="0" onClick={() => fields.push(defaultFamilySupportItem)} className="u-txt-addinfo">
          <PlusSquare size={20} className="u-txt-addinfo-icon" />扶養を追加
        </div>
      </div>
    )}
  </div>
);

FamilySupports = connect(
  state => ({
    selectedFamilySupport: selector(getEmployeeFormValues(state), 'familySupport'),
    fetchValueByIndex: fetchValueByIndexAndField(selector(getEmployeeFormValues(state), 'employeeFamilySupports'))
  }),
  dispatch => ({
    handleRemoveItem: fields => index => {
      fields.remove(index);
      if (fields.length === 1) dispatch(formChange(EMPLOYEE_FORM, 'familySupport', '0'));
    }
  })
)(FamilySupports);

const FamilySupportsSection = ({ familySupports, addDefaultSupportIfBlank }) => (
  <div>
    <RadioField label="扶養の有無" name="familySupport" options={familySupports} onChange={addDefaultSupportIfBlank} />
    <FieldArray name="employeeFamilySupports" component={FamilySupports} />
    <FormSection name="employee">
      <CheckboxField name="isSubmissionDeduction" label="従たる給与についての扶養控除等申告書の提出" />
    </FormSection>
  </div>
);

export default connect(
  () => ({
    familySupports: [{ label: '有', value: '1' }, { label: '無', value: '0' }]
  }),
  (dispatch, ownProps) => ({
    addDefaultSupportIfBlank: e => {
      const inputedSupports = _.get(ownProps, 'inputedSupports');
      const hasSupport = e.target.value === '1';
      if (!hasSupport || !_.isEmpty(inputedSupports)) {
        return;
      }
      dispatch(arrayPush(EMPLOYEE_FORM, 'employeeFamilySupports', defaultFamilySupportItem));
    }
  })
)(FamilySupportsSection);

export const customEmployeeFamilySupportValidate = values => {
  const { employeeFamilySupports } = values;
  const errors = { employeeFamilySupports: [] };

  if (!employeeFamilySupports) {
    return undefined;
  }

  employeeFamilySupports.forEach((familySupport, index) => {
    const maxDependentMoney = determineMaxDependentMoney(familySupport.relationshipType === 'spouse');
    if (+familySupport.yearlyEarning > maxDependentMoney && familySupport.isTaxDependency) {
      set(
        errors.employeeFamilySupports,
        `[${index}].yearlyEarning`,
        `が${maxDependentMoney /
          10000}万円を超えて扶養に入れる事はできません。税の扶養対象のチェックを外すか、年間所得に${maxDependentMoney}以下の値を入力してください。`
      );
    }
  });

  return errors.employeeFamilySupports.length > 0 ? errors : undefined;
};
