import { connect } from 'react-redux';

import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  getClientAllowancesBonusList,
  setClientBonusAllowancesList,
  saveDisplayOrder
} from 'src/reducers/clients/bonusAllowances';
import ChangeBonusAllowanceDisplayOrder from '../components/ChangeBonusAllowanceDisplayOrder';

const mapStateToProps = state => ({
  bonusAllowanceTypes: getSelectOptions(state, 'bonusAllowanceTypes'),
  clientBonusAllowances: getClientAllowancesBonusList(state)
});
const mapDispatchToProps = dispatch => ({
  setAllowances: allowances => {
    dispatch(setClientBonusAllowancesList({ data: allowances }));
  },
  saveDisplayOrder: async () => {
    await dispatch(saveDisplayOrder());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ChangeBonusAllowanceDisplayOrder);
