import { connect } from 'react-redux';
import { getFormValues, blur } from 'redux-form';
import { updateLaborInsuranceSummaryForm } from 'src/reducers/reports/reportLaborInsurances';
import { REPORT_LABOR_INSURANCE_SUMMARY_FORM } from 'src/constants/FormNames';
import { emptyIntegerFormat, toInsuranceRateFormat } from 'src/utils/Completion';
import LaborInsuranceAccidentDeclarationForm2022 from '../components/LaborInsuranceAccidentDeclarationForm2022';

const mapStateToProps = state => ({
  formVal: getFormValues(REPORT_LABOR_INSURANCE_SUMMARY_FORM)(state).laborInsuranceDeclarationForm
});

const mapDispatchToProps = dispatch => ({
  updateCalcResult: (field, value) => {
    dispatch(updateLaborInsuranceSummaryForm(field, value));
  },
  blurMoney: (attrName, previousValue) => {
    dispatch(blur(`${REPORT_LABOR_INSURANCE_SUMMARY_FORM}`, attrName, emptyIntegerFormat(previousValue)));
  },
  blurRate: (attrName, previousValue) => {
    dispatch(blur(`${REPORT_LABOR_INSURANCE_SUMMARY_FORM}`, attrName, toInsuranceRateFormat(previousValue)));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(LaborInsuranceAccidentDeclarationForm2022);
