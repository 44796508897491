import { combineReducers } from 'redux';
import { getFormValues, autofill, startSubmit, stopSubmit } from 'redux-form';
import { push } from 'connected-react-router';
import axios from 'axios';
import _ from 'lodash';

import { CLIENT_ALLOWANCE_FORM } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { setList, getList, setExtras, fetchListFailure } from 'src/reducers/common/listReducer';
import { setOptions } from 'src/reducers/selectOptions';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

import createNamedWrapperReducer from '../createNamedWrapperReducer';
import { listReducer, itemReducer } from '../common';
import {
  setItem,
  setItemExtra,
  newItemFailureWithData,
  editItemFailureWithData,
  fetchItemFailure
} from '../common/itemReducer';

export const REDUCER_NAME = 'clientAllowances';

export const getAllowanceFormValues = state => getFormValues(CLIENT_ALLOWANCE_FORM)(state);
export const updateFormField = (fieldName, value) => autofill(CLIENT_ALLOWANCE_FORM, fieldName, value);

export const createAllowance = data => async (dispatch, getState) => {
  try {
    dispatch(startSubmit(CLIENT_ALLOWANCE_FORM));
    const createUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCE_CREATE, {
      payrollRulesGroupId: data.clientPayrollRulesGroupId
    });
    const response = await axios.post(createUrl, {
      client_allowance: data,
      client_base_salary_client_allowance: { client_allowance_item_id: data.clientAllowanceItemId }
    });
    if (response.data.errors.messages.length > 0) {
      dispatch(stopSubmit(CLIENT_ALLOWANCE_FORM));
      dispatch(newItemFailureWithData(REDUCER_NAME, response.data.errors.messages));
    } else {
      const state = getState();
      dispatch(
        push({
          ...state.router.location,
          pathname: concatParamsToUrl(Urls.CLIENT_ALLOWANCES_LIST, {
            payrollRulesGroupId: data.clientPayrollRulesGroupId
          })
        })
      );
    }
  } catch (exception) {
    dispatch(stopSubmit(CLIENT_ALLOWANCE_FORM));
    dispatch(newItemFailureWithData(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const fetchAllowanceList = payrollRulesGroupId => async dispatch => {
  dispatch(setExtras(REDUCER_NAME, { isLoading: true }));
  try {
    const fetchUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCES_LIST, { payrollRulesGroupId });
    const response = await axios.get(fetchUrl);

    const selectOptions = response.data.payload.selectOptions;
    const clientAllowances = response.data.payload.clientAllowances;

    dispatch(setOptions(selectOptions));
    dispatch(setList(REDUCER_NAME, { data: clientAllowances }));
    dispatch(setExtras(REDUCER_NAME, { isLoading: false }));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const fetchNewAllowance = payrollRulesGroupId => async dispatch => {
  dispatch(setItemExtra(REDUCER_NAME, { isLoading: true }));
  try {
    const fetchUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCE_NEW, { payrollRulesGroupId });
    const response = await axios.get(fetchUrl);

    const selectOptions = response.data.payload.selectOptions;
    const values = response.data.payload.values;

    dispatch(setOptions(selectOptions));
    dispatch(setItem(REDUCER_NAME, values));
    dispatch(setItemExtra(REDUCER_NAME, { isLoading: false }));
  } catch (exception) {
    dispatch(fetchItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const fetchAllowanceDetail = (payrollRulesGroupId, allowanceId) => async dispatch => {
  dispatch(setItemExtra(REDUCER_NAME, { isLoading: true }));
  try {
    const fetchUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCE_EDIT, { payrollRulesGroupId, allowanceId });
    const response = await axios.get(fetchUrl);

    const selectOptions = response.data.payload.selectOptions;
    const values = response.data.payload.values;
    const extras = response.data.payload.extras;

    dispatch(setOptions(selectOptions));
    dispatch(setItem(REDUCER_NAME, values));
    dispatch(setItemExtra(REDUCER_NAME, extras));
    dispatch(setItemExtra(REDUCER_NAME, { isLoading: false }));
  } catch (exception) {
    dispatch(fetchItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editAllowance = data => async (dispatch, getState) => {
  try {
    const editUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCE_UPDATE, {
      payrollRulesGroupId: data.clientPayrollRulesGroupId,
      allowanceId: data.id
    });
    const response = await axios.put(editUrl, {
      client_allowance: data,
      client_base_salary_client_allowance: { client_allowance_item_id: data.clientAllowanceItemId }
    });
    if (response.data.errors.messages.length > 0) {
      dispatch(editItemFailureWithData(REDUCER_NAME, response.data.errors.messages));
    } else {
      const state = getState();
      dispatch(
        push({
          ...state.router.location,
          pathname: concatParamsToUrl(Urls.CLIENT_ALLOWANCES_LIST, {
            payrollRulesGroupId: data.clientPayrollRulesGroupId
          })
        })
      );
    }
  } catch (exception) {
    dispatch(editItemFailureWithData(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

// デッドコードです(消したいですが#3608で使うかもしれないので残す)
export const deleteAllowance = (payrollId, allowanceId) => {
  const deleteAllowanceUrl = concatParamsToUrl(Urls.CLIENT_ALLOWANCE_DELETE, {
    payrollRulesGroupId: payrollId,
    allowanceId
  });
  return axios.delete(deleteAllowanceUrl);
};

const toDisplayOrderParams = clientAllowances =>
  clientAllowances.map((item, index) => ({ id: item.id, displayOrder: index }));

export const saveDisplayOrder = payrollRulesGroupId => async (dispatch, getState) => {
  try {
    const state = getState();
    const clientAllowances = getList(REDUCER_NAME, state);
    const requestParams = toDisplayOrderParams(clientAllowances);
    await axios.post(Urls.CLIENT_ALLOWANCE_UPDATE_DISPLAY_ORDERS, { allowances: requestParams });
    dispatch(setGlobalSuccesses('並べ替えが成功しました'));
    dispatch(
      push({
        ...state.router.location,
        pathname: concatParamsToUrl(Urls.CLIENT_ALLOWANCES_LIST, { payrollRulesGroupId })
      })
    );
  } catch (exception) {
    const errorMsg = _.get(exception, 'response.data.errors.messages', '並べ替えに失敗しました');
    dispatch(setGlobalErrors(errorMsg));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
