import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { Section } from 'jbc-front/components/Form';
import { getItem } from 'src/reducers/common/itemReducer';
import selector, { numberWithComma, display } from 'src/utils/Utils';
import DefaultValue from './DefaultValue';
import tableStyles from '../styles/GeneralTableInfo.scss';

const getEmployeeBankLabel = (bankId, employeeBanks) => {
  const index = _.findIndex(employeeBanks, { id: bankId });
  if (index === -1) {
    return '';
  }
  return `口座情報${index + 1}`;
};

let PaymentItem = ({ item, employeeBanks }) => (
  <div>
    <Section title={item.label}>
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>金額：</th>
            <td>
              {item.transferType} {item.transferType === '固定額' && `：${numberWithComma(item.fixedAmount)}円`}
            </td>
          </tr>
          {item.paymentOrder !== 'fourth' && (
            <React.Fragment>
              <tr>
                <th>振込先：</th>
                <th>{display(item.employeeBankId && getEmployeeBankLabel(item.employeeBankId, employeeBanks))}</th>
              </tr>
              <tr>
                <th>振込元：</th>
                <th>{item.clientBankName}</th>
              </tr>
            </React.Fragment>
          )}
        </tbody>
      </table>
    </Section>
  </div>
);

PaymentItem = connect(state => ({
  employeeBanks: selector(getItem(REDUCER_NAME, state), 'employeeBanks') || []
}))(PaymentItem);

const changeOrderOfGenkinToLast = paymentBanks => {
  const genkinTransfer = _.filter(paymentBanks, { paymentOrder: 'fourth' });
  const bankTransfers = _.reject(paymentBanks, { paymentOrder: 'fourth' }) || [];
  if (genkinTransfer.length === 1 && bankTransfers.length >= 1) {
    bankTransfers.push(genkinTransfer[0]);
  } else if (genkinTransfer.length > 0) {
    return genkinTransfer;
  }
  return bankTransfers;
};

const PaymentBanksSectionShow = ({ payPaymentBanks, bonusPaymentBanks }) => {
  if ((payPaymentBanks && payPaymentBanks.length > 0) || (bonusPaymentBanks && bonusPaymentBanks.length > 0)) {
    // 現金は最後尾にレンダリングしたいので順番を入れ替える
    const payrollPayments = changeOrderOfGenkinToLast(payPaymentBanks);
    const bonusPayments = changeOrderOfGenkinToLast(bonusPaymentBanks);
    return (
      <div>
        <Section title={'給与'}>{_.map(payrollPayments, item => <PaymentItem item={item} key={item.id} />)}</Section>
        <Section title={'賞与'}>{_.map(bonusPayments, item => <PaymentItem item={item} key={item.id} />)}</Section>
      </div>
    );
  }
  return <DefaultValue />;
};

export default connect(state => ({
  payPaymentBanks: selector(getItem(REDUCER_NAME, state), 'payPaymentBanks') || [],
  bonusPaymentBanks: selector(getItem(REDUCER_NAME, state), 'bonusPaymentBanks') || []
}))(PaymentBanksSectionShow);
