import React from 'react';
import { connect } from 'react-redux';

import SettingSideBar from './components/SettingSideBar';
import SettingAccountForm from './components/SettingAccountForm';

const EditAccount = ({ email }) => (
  <div className="l-wrap-l">
    <h2 className="m-title-sub">アカウント設定</h2>
    <div className="l-flex">
      <div className="l-flex3-sub">
        <SettingSideBar />
      </div>
      <div className="l-flex3-main">
        <SettingAccountForm email={email} />
      </div>
    </div>
  </div>
);

export default connect()(EditAccount);
