import React from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/fp/compose';
import LazyLoad from 'react-lazyload';
import { reduxForm, FormSection, getFormSyncErrors, change as formChange } from 'redux-form';
import _ from 'lodash';
import { takeFormErrors } from 'src/utils/Form';
import {
  getUiShowMode,
  getChangedYearMonths,
  getLastRevisionYearMonths,
  uiChangeShowMode,
  INIT_CALC_MODE,
  uiChangeCalcMode,
  uiNotifyValueChanges,
  editRevision,
  recalculateRevision,
  resetRevision,
  REDUCER_NAME,
  transformRevisionsToFormValues,
  fetchRevisions,
  getErrorsOnSave,
  uiSetErrorsOnSave
} from 'src/reducers/reports/monthlyRevisions';
import { takeRevisionsSearchQueries } from 'src/reducers/searchForm';
import { getList } from 'src/reducers/common/listReducer';
import HeadErrors from 'src/components/HeadErrors';
import RevisionItemHeaderContainer from '../containers/RevisionItemHeaderContainer';
import RevisionItemBodyContainer from '../containers/RevisionItemBodyContainer';

const fieldMap = {
  firstMonthBasicDays: '1ヶ月目の支払基礎日数',
  secondMonthBasicDays: '2ヶ月目の支払基礎日数',
  thirdMonthBasicDays: '3ヶ月目の支払基礎日数',
  firstMonthCurrencyAmount: '1ヶ月目の通貨による額',
  secondMonthCurrencyAmount: '2ヶ月目の通貨による額',
  thirdMonthCurrencyAmount: '3ヶ月目の通貨による額',
  firstMonthGenbutsuAmount: '1ヶ月目の現物による額',
  secondMonthGenbutsuAmount: '2ヶ月目の現物による額',
  thirdMonthGenbutsuAmount: '3ヶ月目の現物による額',
  modifiedAverageAmount: '修正平均額',
  retroactiveAmount: '遡及支払額',
  changedAmount: '昇給差の月額',
  reasonOfRaiseReduction: '昇給・降給の理由',
  note: 'その他'
};

const List = props => {
  const {
    revisions,
    showMode,
    changedYearMonths,
    changeToEditMode,
    lastRevisionYearMonths,
    changeToManualCalcMode,
    notifyValueChanges,
    save,
    cancel,
    recalculate,
    rollback,
    setFormField,
    errorsOnSave
  } = props;

  return (
    <div>
      {revisions.map(revision => (
        <div className="u-mb40" key={revision.id}>
          <FormSection name={`revisions.${revision.id}`}>
            <LazyLoad>
              <RevisionItemHeaderContainer
                revision={revision}
                showMode={showMode}
                changeToEditMode={changeToEditMode}
                cancel={cancel}
                save={save}
                reset={rollback}
              />
            </LazyLoad>
            <HeadErrors errors={takeFormErrors(errorsOnSave(revision), fieldMap)} />
            <LazyLoad>
              <RevisionItemBodyContainer
                revision={revision}
                showMode={showMode}
                changedYearMonths={changedYearMonths}
                lastRevisionYearMonths={lastRevisionYearMonths}
                recalculate={recalculate}
                changeToManualCalcMode={changeToManualCalcMode}
                notifyValueChanges={notifyValueChanges}
                setFormField={setFormField}
              />
            </LazyLoad>
          </FormSection>
        </div>
      ))}
    </div>
  );
};

const mapStateToProps = state => ({
  revisions: getList(REDUCER_NAME, state),
  initialValues: transformRevisionsToFormValues(REDUCER_NAME, state),
  changedYearMonths: getChangedYearMonths(state),
  lastRevisionYearMonths: getLastRevisionYearMonths(state),
  showMode: revision => getUiShowMode(state, revision),
  errorsOnSave: revision => getErrorsOnSave(state, revision)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeToEditMode: revision => {
    dispatch(uiChangeShowMode({ revision, showMode: 'EDIT' }));
  },
  changeToManualCalcMode: (revision, name) => {
    dispatch(uiChangeCalcMode({ revision, name, calcMode: 'MANUAL' }));
  },
  notifyValueChanges: revision => {
    dispatch(uiNotifyValueChanges({ revision }));
  },
  save: (revision, errors) => {
    dispatch(editRevision(revision.id)).then(hasFormErrors => {
      if (!hasFormErrors) dispatch(fetchRevisions(takeRevisionsSearchQueries(ownProps.formValues)));
    });
    dispatch(uiSetErrorsOnSave({ revision, errors }));
  },
  cancel: revision => {
    dispatch(formChange('revisions', `revisions.${revision.id}`, revision));
    dispatch(uiChangeShowMode({ revision, showMode: 'SHOW' }));
    _.forIn(INIT_CALC_MODE, (value, name) => {
      dispatch(uiChangeCalcMode({ revision, name, calcMode: 'AUTO' }));
    });
    dispatch(uiSetErrorsOnSave({ revision, errors: {} }));
  },
  recalculate: revision => {
    dispatch(recalculateRevision(revision.id));
  },
  // reduxFormの予約語のresetと被るためresetは使用できない
  rollback: revision => {
    dispatch(resetRevision(revision.id));
  },
  setFormField: (revision, name, val) => {
    dispatch(formChange('revisions', `revisions.${revision.id}.${name}`, val));
  }
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'revisions',
    enableReinitialize: true
  })
)(List);
