import React from 'react';
import { FieldArray, FormSection, change as formChange } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { CLIENT_PAYROLL_RULES_GROUP_EDIT } from 'src/constants/EndpointUrls';
import { SYNC_JBC_SETTING_FORM } from 'src/constants/FormNames';
import { HH_00_HOURS } from 'src/constants/Options';
import { isHHMMFormat } from 'src/utils/CustomValidators';
import { CheckboxField, SelectField } from 'jbc-front/components/Form';
import { FormSubmitBtn } from 'src/buttons';
import { getSelectOptions, getLabeledDays } from 'src/reducers/selectOptions';
import { concatParamsToUrl } from 'src/utils/Http';
import { fetchJbcKintaiCsvFormats } from 'src/reducers/fetchSelectOptions';
import styles from './SyncJbcSettingForm.scss';

const getPayrollRuleGroup = (payrollRulesGroups, taskTargetId) =>
  payrollRulesGroups.find(group => group.value === taskTargetId);

const payrollGroupEditUrl = payrollRulesGroups =>
  concatParamsToUrl(CLIENT_PAYROLL_RULES_GROUP_EDIT, { id: payrollRulesGroups.value });

class SyncTaskSettingItem extends React.Component {
  componentDidMount() {
    this.props.dispatch(fetchJbcKintaiCsvFormats());
  }

  getOrgJbcCsvFormatName = clientSyncTaskSettingId =>
    get(this.props.orgClientSyncTaskSettings.find(item => item.id === clientSyncTaskSettingId), 'jbcCsvFormatName');

  getJbcCsvFormatName = jbcCsvFormatId =>
    get(this.props.jbcCsvFormats.find(item => item.value === jbcCsvFormatId), 'label');

  handleChangeJbcCsvFormatId = (newJbcCsvFormatId, member) =>
    this.props.dispatch(
      formChange(SYNC_JBC_SETTING_FORM, `${member}.jbcCsvFormatName`, this.getJbcCsvFormatName(newJbcCsvFormatId))
    );

  render() {
    const { fields, payrollRulesGroups, days, jbcCsvFormats = [] } = this.props;
    return (
      <React.Fragment>
        {fields.map((member, index) => {
          const value = fields.get(index);
          const payrollRuleGroup = getPayrollRuleGroup(payrollRulesGroups, value.syncTargetId) || {};

          return (
            <FormSection name={member} key={member}>
              <tr>
                <td>
                  <a href={payrollGroupEditUrl(payrollRuleGroup)}>{payrollRuleGroup.label}</a>
                </td>
                <td>{payrollRuleGroup.closingLabel}</td>
                <td>
                  <CheckboxField name="isSetActive" label="連携する" />
                </td>
                <td>
                  <SelectField name="syncDay" options={days} required noLabel />
                </td>
                <td>
                  <SelectField name="syncTime" required noLabel validate={isHHMMFormat} options={HH_00_HOURS} />
                </td>
                <td>
                  <SelectField
                    name="jbcCsvFormatId"
                    label="CSVフォーマット名"
                    options={jbcCsvFormats}
                    required
                    noLabel
                    onChange={(e, selected) => this.handleChangeJbcCsvFormatId(selected, member)}
                  />
                </td>
              </tr>
            </FormSection>
          );
        })}
      </React.Fragment>
    );
  }
}

const ConnectedSyncTaskSettingItem = connect(state => ({
  payrollRulesGroups: getSelectOptions(state, 'payrollRuleGroups'),
  days: getLabeledDays(state),
  jbcCsvFormats: getSelectOptions(state, 'jbcCsvFormats'),
  orgClientSyncTaskSettings: get(state, ['clientSyncTaskSettings', 'list', 'data']) || []
}))(SyncTaskSettingItem);

const SyncJbcSettingForm = ({ handleSubmit, submitting, pristine, submit }) => (
  <form onSubmit={handleSubmit}>
    <div className="l-overflow-scroll ">
      <table className={styles.table}>
        <colgroup>
          <col style={{ width: '17%' }} />
          <col style={{ width: '7%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '12%' }} />
          <col style={{ width: '20%' }} />
        </colgroup>
        <thead>
          <tr>
            <th>給与規定グループ</th>
            <th>締め日</th>
            <th>自動連携</th>
            <th>日</th>
            <th>時</th>
            <th>
              JBC勤怠側の<br />CSVフォーマット設定
            </th>
          </tr>
        </thead>
        <tbody>
          <FieldArray name="clientSyncTaskSettings" component={ConnectedSyncTaskSettingItem} />
        </tbody>
      </table>
    </div>
    <div className="u-ta-c u-mt20">
      <FormSubmitBtn text="保存" disabled={submitting || pristine} {...{ submitting, submit }} />
    </div>
  </form>
);

export default SyncJbcSettingForm;
