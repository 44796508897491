import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { POSITION_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';

import { getMode, getList } from 'src/reducers/common/editableListReducer';
import { REDUCER_NAME, updatePositions } from 'src/reducers/clients/positions';

import { SortableList } from 'src/components';

const EditableListFormed = reduxForm({
  form: POSITION_FORM
})(SortableList);

const mapStateToProps = state => ({
  initialValues: { [EDITABLE_LIST_FIELDS_NAME]: getList(REDUCER_NAME, state) },
  mode: getMode(REDUCER_NAME, state),
  listData: getList(REDUCER_NAME, state),
  fieldName: 'name'
});
const mapDispatchToProps = dispatch => ({
  onSubmit: data => {
    const Positions = data[EDITABLE_LIST_FIELDS_NAME];
    const SortedPositions = Positions.map((item, index) => ({ ...item, orderNumber: index }));
    dispatch(updatePositions(SortedPositions));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EditableListFormed);
