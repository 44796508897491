import { connect } from 'react-redux';
import { formValueSelector, change } from 'redux-form';
import { getPageLimit } from 'src/reducers/searchForm';
import { SortableTh } from '../components/SortableTh';

const mapStateToProps = (state, ownProps) => ({
  currentSortType: `${formValueSelector(ownProps.formName)(state, 'sort')}__${formValueSelector(ownProps.formName)(
    state,
    'order'
  )}`
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onChange: sortType => {
    const [sort, order] = sortType.split('__');
    dispatch(change(ownProps.formName, 'sort', sort));
    dispatch(change(ownProps.formName, 'order', order));
    dispatch(change(ownProps.formName, 'limit', getPageLimit(ownProps.formName)));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SortableTh);
