import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { CLIENT_ALLOWANCES_LIST } from 'src/constants/EndpointUrls';
import * as CssClassNames from 'src/constants/CssClassNames';
import { TextField, RadioField, SelectField, CheckboxField } from 'jbc-front/components/Form';
import { getRadioOptions, getSelectOptions } from 'src/reducers/selectOptions';
import { maxLength30 } from 'src/utils/CustomValidators';
import { ErrorsSection } from 'src/components';
import { FormSubmitBtn, CancelBtn } from 'src/buttons';
import selector from 'src/utils/Utils';
import { concatParamsToUrl } from 'src/utils/Http';
import { REDUCER_NAME, getAllowanceFormValues } from 'src/reducers/clients/allowances';
import { getItem, getItemErrors, getItemExtra } from 'src/reducers/common/itemReducer';
import { setExtras } from 'src/reducers/common/listReducer';
import LoadingView from 'src/components/LoadingView';

import AllowancePayment from './AllowancePayment';
import AllowanceDeduction from './AllowanceDeduction';

import styles from './AllowanceEditingForm.scss';

const allowanceListUrl = payrollRulesGroupId => concatParamsToUrl(CLIENT_ALLOWANCES_LIST, { payrollRulesGroupId });

const AllowanceEditingForm = props => {
  const { submitting, submit, pristine, payrollRulesGroup } = props;

  const errors = useSelector(state => getItemErrors(REDUCER_NAME, state));
  const isUsedPayment = useSelector(state => getItemExtra(REDUCER_NAME, state).isUsedPayment || false);
  const originalName = useSelector(state => getItemExtra(REDUCER_NAME, state).originalName || '');

  const allowanceTypes = useSelector(state => getRadioOptions(state, 'allowanceTypes'));
  const allowanceAmountTypes = useSelector(state => getRadioOptions(state, 'allowanceAmountTypes'));
  const selectedAllowanceType = useSelector(state => state[REDUCER_NAME].list.extras.selectedAllowanceType);
  const isDefaultDeductionItem = useSelector(
    state => getItemExtra(REDUCER_NAME, state).isDefaultDeductionItem || false
  );
  const isDefaultItem = useSelector(state => getItemExtra(REDUCER_NAME, state).isDefaultItem || false);

  const allowance = useSelector(state => getItem(REDUCER_NAME, state));
  const payrollRulesGroupId = allowance?.clientPayrollRulesGroupId;
  const formulaOrderType = allowance?.formulaOrderType;
  const useYearEndAdjustment = allowance?.useYearEndAdjustment;

  const officeStationMappingPaymentItems = useSelector(state =>
    getSelectOptions(state, 'officeStationMappingPaymentItems')
  );
  const officeStationMappingDeductionItems = useSelector(state =>
    getSelectOptions(state, 'officeStationMappingDeductionItems')
  );
  const isSyncOfficeStation = useSelector(state => selector(getAllowanceFormValues(state), 'isSyncOfficeStation'));
  const isAvailableOfficeStation = useSelector(state => state.currentClient.item.data.isAvailableOfficeStation);
  const clientOfficeStationSetting = useSelector(state => state.currentClient.item.data.clientOfficeStationSetting);

  // 基本給か通勤手当のいずれか
  const isBaseSalaryOrCommuting = formulaOrderType === 'base_salary' || formulaOrderType === 'commuting';
  // 支給項目
  const isPaymentAllowance = selectedAllowanceType === 'payment';
  // 控除項目
  const isDeductionAllowance = selectedAllowanceType === 'deduction';
  // 支給・控除の変更が可能かどうか
  const allowanceTypeDisabled = isBaseSalaryOrCommuting || useYearEndAdjustment || isUsedPayment || isDefaultItem;
  const isItemLoading = useSelector(state => getItemExtra(REDUCER_NAME, state).isLoading);
  const dispatch = useDispatch();
  // 項目名の編集が不可かどうか
  const disableNameEdit = ['commuting', 'flat_tax_reduction'].includes(formulaOrderType);
  return (
    <div>
      <LoadingView isLoading={isItemLoading}>
        <div className={styles.error}>
          <ErrorsSection errors={errors} />
        </div>
        <form
          className={classNames(CssClassNames.FORM, CssClassNames.EMPLOYEE_FORM)}
          onSubmit={e => e.preventDefault()}
        >
          <div className={styles.section}>
            <RadioField
              label="支給/控除"
              name="allowanceType"
              required
              options={allowanceTypes}
              disabled={allowanceTypeDisabled}
              onChange={(_event, allowanceType) => {
                dispatch(setExtras(REDUCER_NAME, { selectedAllowanceType: allowanceType }));
              }}
            />

            <TextField
              label="項目名"
              name="name"
              note={
                <div>
                  給与明細に表示される名称です。
                  項目名を変更すると、確定済みの給与を含め、すべての「給与明細」「賃金台帳」「支給・控除等一覧表」での項目名が変更されます。
                  また、変更前の項目名として集計していたデータを、変更後の項目名として集計します。
                </div>
              }
              required
              maxLength="30"
              validate={maxLength30}
              disabled={disableNameEdit}
            />
            {originalName && <p className={styles.payslipNote}>※元の項目名：{originalName}</p>}

            {/* デフォルトの控除項目以外の場合に表示 */}
            {!isDefaultDeductionItem && (
              <RadioField
                label="金額設定"
                name="allowanceAmountType"
                required
                options={allowanceAmountTypes}
                disabled={isBaseSalaryOrCommuting}
              />
            )}

            {isAvailableOfficeStation &&
              clientOfficeStationSetting && (
                <div>
                  <CheckboxField
                    name="isSyncOfficeStation"
                    label="オフィスステーションと連携する"
                    normalize={value => !!value}
                  />
                  {isSyncOfficeStation && (
                    <SelectField
                      name="officeStationItemName"
                      addBlankOption={false}
                      options={
                        isPaymentAllowance ? officeStationMappingPaymentItems : officeStationMappingDeductionItems
                      }
                    />
                  )}
                </div>
              )}

            {isPaymentAllowance && <AllowancePayment payrollRulesGroup={payrollRulesGroup} />}
            {isDeductionAllowance && <AllowanceDeduction />}
          </div>
          <div className="u-ta-c">
            <Link to={allowanceListUrl(payrollRulesGroupId)}>
              <CancelBtn className="u-mr20" />
            </Link>
            <FormSubmitBtn text="保存" {...{ submitting, submit, pristine }} />
          </div>
        </form>
      </LoadingView>
    </div>
  );
};

export default AllowanceEditingForm;
