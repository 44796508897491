import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  getClientSyncTasks,
  setSyncJobInvisible,
  fetchSyncJobStatus
} from 'src/reducers/employees/employeeAttendances';
import { Close } from 'jbc-front/components/icons';
import { JobFailed, JobInprogress, JobSuccess } from 'src/components/JobStatus';
import styles from './AttendanceSyncWithJbcResult.scss';

const TaskResult = ({ tasks, status, hideSyncJobNotifications }) => {
  let statusText = 'の勤怠データを取得しました。';
  let tasksStyle = styles.tasksSuccess;

  if (status === 'failed') {
    statusText = 'の勤怠データを取得できませんでした。';
    tasksStyle = styles.tasksFailed;
  } else if (status === 'inProgress') {
    statusText = 'の勤怠データを取得中です。';
    tasksStyle = styles.tasksInprogress;
  }

  return (
    <React.Fragment>
      {!isEmpty(tasks) &&
        tasks.length > 0 && (
          <div className={tasksStyle}>
            <div className={styles.inner}>
              {status === 'success' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideSyncJobNotifications(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => (
                      <JobSuccess
                        key={task.id}
                        statusText={statusText}
                        jobInfo={`ジョブカン勤怠管理から${task.taskInfo}`}
                        startAt={task.executeAt}
                        endAt={task.executeDoneAt}
                        syncEmployeeTask={task}
                      />
                    ))}
                  </ul>
                </React.Fragment>
              )}
              {status === 'failed' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideSyncJobNotifications(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => (
                      <JobFailed
                        key={task.id}
                        statusText={statusText}
                        jobInfo={`ジョブカン勤怠管理から${task.taskInfo}`}
                        errorShowMsg={task.externalErrorMsg}
                        startAt={task.executeAt}
                        endAt={task.executeDoneAt}
                      />
                    ))}
                  </ul>
                </React.Fragment>
              )}
              {status === 'inProgress' && (
                <React.Fragment>
                  <ul className={styles.list}>
                    {tasks.map(task => (
                      <JobInprogress
                        key={task.id}
                        statusText={statusText}
                        jobInfo={`ジョブカン勤怠管理から${task.taskInfo}`}
                        startAt={task.executeAt}
                      />
                    ))}
                  </ul>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      {isEmpty(tasks) && null}
    </React.Fragment>
  );
};

class AttendanceSyncWithJbcResult extends React.Component {
  componentDidMount() {
    this.pollFetchVisibleSyncJobs();
  }

  // TODO: このポーリングは無条件にステータス確認をしてしまいサーバーに余分な負荷を与えている
  // 処理中のみステータス確認のポーリングを行うのが理想
  // 取得ボタンのonclickでポーリングを開始するなどの修正を行うのが良い
  pollFetchVisibleSyncJobs = () => {
    this.props.fetchVisibleSyncJobs();
    setTimeout(this.pollFetchVisibleSyncJobs, 60000);
  };

  render() {
    const { clientSyncTasks = {}, hideSyncJobNotifications } = this.props;
    return (
      <React.Fragment>
        {isEmpty(clientSyncTasks) ? null : (
          <div>
            {Object.keys(clientSyncTasks).map(status => (
              <TaskResult
                key={status}
                tasks={clientSyncTasks[status]}
                status={status}
                hideSyncJobNotifications={hideSyncJobNotifications}
              />
            ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    clientSyncTasks: getClientSyncTasks(state)
  }),
  dispatch => ({
    hideSyncJobNotifications: jobs => {
      const jobIds = jobs.map(j => j.id).join('_');
      if (jobIds.length > 0) {
        dispatch(setSyncJobInvisible(jobIds));
      }
    },
    fetchVisibleSyncJobs: () => dispatch(fetchSyncJobStatus(true))
  })
)(AttendanceSyncWithJbcResult);
