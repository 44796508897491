import React from 'react';
import PropTypes from 'prop-types';

import GeneralConfirmationBtn from './GeneralConfirmationBtn';

const InviteBtn = ({ showingConfirmBox, confirmText, handleInvite, text, disabled, ...rest }) => (
  <GeneralConfirmationBtn
    {...{ showingConfirmBox, confirmText, text, disabled }}
    handleEvent={handleInvite}
    {...rest}
  />
);

InviteBtn.propTypes = {
  handleInvite: PropTypes.func.isRequired
};

InviteBtn.defaultProps = {
  showingConfirmBox: true,
  confirmText: '選択された従業員宛に、招待メールを送信しますか？',
  text: '招待メールを送信'
};

export default InviteBtn;
