import React from 'react';
import styles from './SelectCard.scss';

export const Card = ({ icon, children, to }) => {
  const Icon = icon;
  return (
    <a className={styles.base} href={to}>
      <Icon size={40} className={styles.icon} />
      <div className={styles.button}>{children}</div>
    </a>
  );
};

export const Container = ({ children, ...rest }) => (
  <div {...rest} className={styles.container}>
    {children}
  </div>
);
