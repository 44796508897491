import axios from 'axios';
import { createAction } from 'redux-actions';
import _ from 'lodash';
import {
  REPORT_HEALTH_INSURANCE_BONUS_EGOV,
  REPORT_MONTHLY_REVISION_EGOV,
  NOTIFICATION_OF_BASE_AMOUNT_REVISION_EGOV
} from 'src/constants/EndpointUrls';
import { setGlobalErrors, setGlobalSuccesses, SYSTEM_ERROR_STRING } from 'src/reducers/global';
import { paramsWithFile } from 'src/utils/Form';
import {
  notificationOfBaseAmountMonthsSearchFormSelector,
  takeNotificationOfBaseAmountRevisionsSearchQueries
} from 'src/reducers/searchForm';

const SET_STATUS = 'SET_STATUS/SET_STATUS';
const E_GOV_SUCCESS_MESSAGE = ['申請準備が完了しました。ジョブカン労務HRの電子申請一覧より申請してください。'];
export const setStatus = createAction(SET_STATUS);
export const REDUCER_NAME = 'egov';
export const HEALTH_INSURANCE_BONUS_TARGET_TYPE = 'health_insurance_bonus';
export const MONTHLY_REVISIONS_TARGET_TYPE = 'monthly_revisions';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_TARGET_TYPE = 'notification_of_base_amount_revisions';

export const postUrl = type => {
  if (type === HEALTH_INSURANCE_BONUS_TARGET_TYPE) {
    return REPORT_HEALTH_INSURANCE_BONUS_EGOV;
  } else if (type === MONTHLY_REVISIONS_TARGET_TYPE) {
    return REPORT_MONTHLY_REVISION_EGOV;
  } else if (type === NOTIFICATION_OF_BASE_AMOUNT_REVISION_TARGET_TYPE) {
    return NOTIFICATION_OF_BASE_AMOUNT_REVISION_EGOV;
  }
  return undefined;
};

export const postEgov = (type, postData, formData, hideModal) => async (dispatch, getState) => {
  try {
    if (formData.egovTarget === 'searched_employee') {
      let searchParams = notificationOfBaseAmountMonthsSearchFormSelector(
        getState(),
        'q',
        'offices',
        'groups',
        'employmentTypes',
        'occupations',
        'positions',
        'payrollRuleGroup',
        'memoColors',
        'noMemo'
      );
      searchParams = takeNotificationOfBaseAmountRevisionsSearchQueries(searchParams);
      Object.assign(postData, searchParams);
    }
    const url = postUrl(type);
    const data = Object.assign(formData, postData);
    const uploadParams = paramsWithFile(data, ['uploadFiles'], true);
    await axios.post(url, uploadParams);
    dispatch(setGlobalSuccesses(E_GOV_SUCCESS_MESSAGE));
    hideModal();
  } catch (exception) {
    const checkErrors = exception.response.data.dataCheckErrors;
    const errors = exception.response.data.errors;
    if (checkErrors && exception.response.status === 400) {
      // データチェックエラー
      dispatch(setStatus({ dataCheckErrors: checkErrors.messages }));
    } else if (errors && exception.response.status === 400) {
      // egov申請エラー
      dispatch(setStatus({ postErrors: errors.messages }));
    } else {
      // システムエラー
      dispatch(setGlobalErrors(SYSTEM_ERROR_STRING));
      hideModal();
    }
  } finally {
    dispatch(setStatus({ loading: false }));
  }
};

export const INITIAL_STATE = {
  loading: false,
  dataCheckErrors: [],
  postErrors: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_STATUS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
