import React from 'react';
import scrollSpy, { AnchorTarget } from 'jbc-front/components/ScrollSpy';
import ListGroup from 'jbc-front/components/ListGroup';
import Affix from 'jbc-front/components/Affix';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { Edit } from 'jbc-front/components/icons';

import { EMPLOYEE_EDIT, EMPLOYEE_SHOW, EMPLOYEES_LIST } from 'src/constants/EndpointUrls';
import MemoList from 'src/features/Memos/MemoList';
import { detailUrlWithQueries } from 'src/utils/Http';
import { NextAndPrevRecordButton, Anchor } from 'src/components';
import { Panel, Item, Headding } from 'src/components/PrimaryPanel';

import BasicInfoSectionShow from './components/BasicInfoSectionShow';
import BusinessInfoSectionShow from './components/BusinessInfoSectionShow';
import TaxClassificationSectionShow from './components/TaxClassificationSectionShow';
import MaritalStatusSectionShow from './components/MaritalStatusSectionShow';
import FamilySupportsSectionShow from './components/FamilySupportsSectionShow';
import PayrollRuleSectionShow from './components/PayrollRuleSectionShow';
import CommutingsSectionShow from './containers/CommutingsSectionShowContainer';
import InhabitantTaxSectionShow from './containers/InhabitantTaxSectionShowContainer';
import BanksSectionShowContainer from './containers/BanksSectionShowContainer';
import PaymentBanksSectionShow from './components/PaymentBanksSectionShow';
import InsuranceSectionShow from './containers/InsuranceSectionShow';
import DeleteEditEmployeeBtn from './components/DeleteEditEmployeeBtn';
import ResidentCardInfoSectionShow from './components/ResidentCardInfoSectionShow';
import ElectronicDeliveryAgreementShow from './containers/ElectronicDeliveryAgreementShowContainer';
import styles from './ShowEmployee.scss';

const EmployeeSideNavi = ({ current, employeeId, isElectronicDeliveryAgreementSetting }) => (
  <div className="l-flex6-sub">
    <Affix offset={209} className={styles.sideNaviHistory}>
      <ListGroup>
        <ListGroup.Title className={styles.title}>履歴</ListGroup.Title>
        <ListGroup.Item as="a" href={`/employees/${employeeId}/personnel_histories`}>
          人事異動履歴一覧
        </ListGroup.Item>
      </ListGroup>
    </Affix>
    <Affix offset={209} className={styles.sideNavi}>
      <ListGroup>
        <Anchor name="BasicInfomation" current={current}>
          基本情報
        </Anchor>
        <Anchor name="ResidentCardInformation" current={current}>
          住民票住所
        </Anchor>
        {isElectronicDeliveryAgreementSetting && (
          <Anchor name="ElectronicDeliveryAgreement" current={current}>
            電子交付同意
          </Anchor>
        )}
        <Anchor name="EmploymentInfomation" current={current}>
          業務情報
        </Anchor>
        <Anchor name="RemunerationInfomation" current={current}>
          給与
        </Anchor>
        <Anchor name="CommutingAllowanceInfomation" current={current}>
          通勤手当
        </Anchor>
        <Anchor name="BankInfomation" current={current}>
          口座情報
        </Anchor>
        <Anchor name="PaymentBankSection" current={current}>
          支給設定
        </Anchor>
        <Anchor name="Dependent" current={current}>
          扶養情報
        </Anchor>
        <Anchor name="Marital" current={current}>
          配偶者情報
        </Anchor>
        <Anchor name="PersonalType" current={current}>
          税区分情報
        </Anchor>
        <Anchor name="InhabitantTax" current={current}>
          住民税
        </Anchor>
        <Anchor name="SocialInsuranceInfomation" current={current}>
          社会保険
        </Anchor>
      </ListGroup>
    </Affix>
  </div>
);

const EmployeeMainContent = ({ editUrl, handleDelete, deletable, isElectronicDeliveryAgreementSetting }) => (
  <div className="l-flex6-main">
    <AnchorTarget name="BasicInfomation" />
    <Panel>
      <a href={`${editUrl}#BasicInfomation`}>
        <Headding icon={<Edit icon={16} />}>基本情報</Headding>
      </a>
      <Item>
        <BasicInfoSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="ResidentCardInformation" />
    <Panel>
      <a href={`${editUrl}#ResidentCardInformation`}>
        <Headding icon={<Edit icon={16} />}>住民票住所</Headding>
      </a>
      <Item>
        <ResidentCardInfoSectionShow />
      </Item>
    </Panel>

    {isElectronicDeliveryAgreementSetting && (
      <React.Fragment>
        <AnchorTarget name="ElectronicDeliveryAgreement" />
        <Panel>
          <a href={`${editUrl}#ElectronicDeliveryAgreement`}>
            <Headding icon={<Edit icon={16} />}>電子交付同意</Headding>
          </a>
          <Item>
            <ElectronicDeliveryAgreementShow />
          </Item>
        </Panel>
      </React.Fragment>
    )}

    <AnchorTarget name="EmploymentInfomation" />
    <Panel>
      <a href={`${editUrl}#EmploymentInfomation`}>
        <Headding icon={<Edit icon={16} />}>業務情報</Headding>
      </a>
      <Item>
        <BusinessInfoSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="RemunerationInfomation" />
    <Panel>
      <a href={`${editUrl}#RemunerationInfomation`}>
        <Headding icon={<Edit icon={16} />}>給与</Headding>
      </a>
      <Item>
        <PayrollRuleSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="CommutingAllowanceInfomation" />
    <Panel>
      <a href={`${editUrl}#CommutingAllowanceInfomation`}>
        <Headding icon={<Edit icon={16} />}>通勤手当</Headding>
      </a>
      <Item>
        <CommutingsSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="BankInfomation" />
    <Panel>
      <a href={`${editUrl}#BankInfomation`}>
        <Headding icon={<Edit icon={16} />}>口座情報</Headding>
      </a>
      <Item>
        <BanksSectionShowContainer />
      </Item>
    </Panel>

    <AnchorTarget name="PaymentBankSection" />
    <Panel>
      <a href={`${editUrl}#PaymentBankSection`}>
        <Headding icon={<Edit icon={16} />}>支給設定</Headding>
      </a>
      <Item>
        <PaymentBanksSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="Dependent" />
    <Panel>
      <a href={`${editUrl}#Dependent`}>
        <Headding icon={<Edit icon={16} />}>扶養情報</Headding>
      </a>
      <Item>
        <FamilySupportsSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="Marital" />
    <Panel>
      <a href={`${editUrl}#Marital`}>
        <Headding icon={<Edit icon={16} />}>配偶者情報</Headding>
      </a>
      <Item>
        <MaritalStatusSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="PersonalType" />
    <Panel>
      <a href={`${editUrl}#PersonalType`}>
        <Headding icon={<Edit icon={16} />}>税区分情報</Headding>
      </a>
      <Item>
        <TaxClassificationSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="InhabitantTax" />
    <Panel>
      <a href={`${editUrl}#InhabitantTax`}>
        <Headding icon={<Edit icon={16} />}>住民税</Headding>
      </a>
      <Item>
        <InhabitantTaxSectionShow />
      </Item>
    </Panel>

    <AnchorTarget name="SocialInsuranceInfomation" />
    <Panel>
      <a href={`${editUrl}#SocialInsuranceInfomation`}>
        <Headding icon={<Edit icon={16} />}>社会保険</Headding>
      </a>
      <Item>
        <InsuranceSectionShow />
      </Item>
    </Panel>

    <DeleteEditEmployeeBtn {...{ handleDelete, deletable, editUrl }} />
  </div>
);

const ShowEmployeeView = scrollSpy(
  ({
    editUrl,
    handleDelete,
    employeeId,
    employeeDisplayName,
    isElectronicDeliveryAgreementSetting,
    deletable,
    nextInfo,
    prevInfo,
    current
  }) => (
    <div>
      <div className={`${styles.wrap} l-wrap-xl l-contents-wrap`}>
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="従業員一覧" path={EMPLOYEES_LIST} />
            <BreadCrumb.Item label="従業員情報" />
          </BreadCrumb>
        </div>
        <div className={styles.navi}>
          <NextAndPrevRecordButton {...{ nextInfo, prevInfo, urlFormat: EMPLOYEE_SHOW }} />
        </div>
      </div>

      <div className={styles.head}>
        <div className={styles.memo}>
          <MemoList employeeId={employeeId} size={44} />
        </div>
        <div className={styles.name}>{employeeDisplayName}</div>
      </div>

      <div className="l-flex">
        <EmployeeSideNavi {...{ current, employeeId, isElectronicDeliveryAgreementSetting }} />
        <EmployeeMainContent {...{ editUrl, handleDelete, deletable, isElectronicDeliveryAgreementSetting }} />
      </div>
    </div>
  )
);

const ShowEmployee = ({
  values,
  isElectronicDeliveryAgreementSetting,
  setItem,
  setExtras,
  fetchEmployeeDetails,
  deleteEmployee
}) => {
  setItem(values);
  setExtras(values);
  fetchEmployeeDetails();

  const employeeId = values.employee.id;
  const employeeDisplayName = values.employee.displayName;
  const { nextInfo, prevInfo, currentQueries, deletable } = values;
  const editUrl = detailUrlWithQueries(employeeId, currentQueries, EMPLOYEE_EDIT);

  return (
    <ShowEmployeeView
      {...{ editUrl, employeeId, employeeDisplayName, isElectronicDeliveryAgreementSetting, deletable }}
      handleDelete={() => deleteEmployee(employeeId)}
      {...{ nextInfo, prevInfo }}
    />
  );
};

export default ShowEmployee;
