import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import * as CssClassNames from 'src/constants/CssClassNames';
import { Section } from 'jbc-front/components/Form';
import scrollSpy, { AnchorTarget } from 'jbc-front/components/ScrollSpy';
import FormErrors, { LabelMapper } from 'jbc-front/components/FormErrors';
import Affix from 'jbc-front/components/Affix';
import ListGroup from 'jbc-front/components/ListGroup';
import { Anchor, ErrorsSection } from 'src/components';
import { FormSubmitBtn, CancelBtn } from 'src/buttons';
import selector from 'src/utils/Utils';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';

import BasicInfoSection from './BasicInfoSection';
import ResidentCardInfoSection from './ResidentCardInfoSection';
import InsuranceSection from '../containers/InsuranceSection';
import InhabitantTaxSection from '../containers/InhabitantTaxSectionContainer';
import TaxClassificationSection from './TaxClassificationSection';
import BanksSection from '../containers/BanksSectionContainer';
import CommutingsSection from './CommutingsSection';
import FamilySupportsSection from './FamilySupportsSection';
import BusinessInfoSection from './BusinessInfoSection';
import MaritalStatusSection from './MaritalStatusSection';
import PayrollRuleSection from './PayrollRuleSection';
import EmployeeAllowancesSection from './EmployeeAllowancesSection';
import EmployeeUnitPricesSection from './EmployeeUnitPricesSection';
import BusinessNameConfirmationModal from './BusinessNameConfirmationModal';
import EmployeeStandardMonthliesSection from '../containers/EmployeeStandardMonthliesSectionContainer';
import styles from './EmployeeEditingForm.scss';
import PaymentBankSectionContainer from '../containers/PaymentBankSectionContainer';
import ElectronicDeliveryAgreement from '../containers/ElectronicDeliveryAgreementContainer';

const DetailEmployeeForm = ({
  showEmployeeUrl,
  submitting,
  handleSubmit,
  errors,
  standardYear,
  standardMonth,
  inputedSupports,
  pristine,
  isElectronicDeliveryAgreementSetting,
  confirmSubmit
}) => (
  <form className={classNames(CssClassNames.FORM, CssClassNames.EMPLOYEE_FORM, styles.form)} onSubmit={handleSubmit}>
    <ErrorsSection errors={errors} />
    <FormErrors />

    <AnchorTarget name="BasicInfomation" />
    <Section title="基本情報">
      <LabelMapper name="employee" label=" " />
      <BasicInfoSection />
    </Section>

    <AnchorTarget name="ResidentCardInformation" />
    <Section title="住民票住所">
      <LabelMapper name="residentCard" label=" " />
      <ResidentCardInfoSection />
    </Section>

    {isElectronicDeliveryAgreementSetting && (
      <React.Fragment>
        <AnchorTarget name="ElectronicDeliveryAgreement" />
        <Section title="電子交付同意">
          <LabelMapper name="employee" label=" " />
          <ElectronicDeliveryAgreement />
        </Section>
      </React.Fragment>
    )}

    <AnchorTarget name="EmploymentInfomation" />
    <Section title="業務情報">
      <LabelMapper name="employee" label=" " />
      <LabelMapper name="employeeLeaveOfAbsences" label="休職期間の" />
      <BusinessInfoSection />
    </Section>

    <AnchorTarget name="RemunerationInfomation" />
    <Section title="給与">
      <LabelMapper name="employeePayrollRule" label="給与の給与規定グループの" />
      <PayrollRuleSection />
      <LabelMapper name="employeeAllowances" label="給与の支給・控除項目設定の" />
      <EmployeeAllowancesSection />
      <LabelMapper name="employeeUnitPrices" label="給与の単価設定の" />
      <EmployeeUnitPricesSection />
      <LabelMapper name="employeeStandardMonthlies" label="給与の標準報酬月額の" />
      <EmployeeStandardMonthliesSection {...{ standardYear, standardMonth }} />
    </Section>

    <AnchorTarget name="CommutingAllowanceInfomation" />
    <Section title="通勤手当">
      <LabelMapper name="employeeCommutings" label="通勤手当の" />
      <CommutingsSection />
    </Section>

    <AnchorTarget name="BankInfomation" />
    <Section title="口座情報">
      <LabelMapper name="employeeBanks" label="口座情報の" />
      <BanksSection />
    </Section>

    <AnchorTarget name="PaymentBankSection" />
    <Section title="支給設定">
      <LabelMapper name="payPaymentBanks" label="支給設定：給与の" />
      <LabelMapper name="bonusPaymentBanks" label="支給設定：賞与の" />
      <PaymentBankSectionContainer />
    </Section>

    <AnchorTarget name="Dependent" />
    <Section title="扶養情報">
      <LabelMapper name="employeeFamilySupports" label="扶養情報の" />
      <FamilySupportsSection inputedSupports={inputedSupports} />
    </Section>

    <AnchorTarget name="Marital" />
    <Section title="配偶者情報">
      <MaritalStatusSection />
    </Section>

    <AnchorTarget name="PersonalType" />
    <Section title="税区分情報">
      <LabelMapper name="employeeTaxClassification" label="税区分情報の" />
      <TaxClassificationSection />
    </Section>

    <AnchorTarget name="InhabitantTax" />
    <Section title="住民税">
      <LabelMapper name="employeeInhabitantTax" label="住民税の" />
      <InhabitantTaxSection />
    </Section>

    <AnchorTarget name="SocialInsuranceInfomation" />
    <Section title="社会保険">
      <LabelMapper name="employeeInsurance" label=" " />
      <InsuranceSection />
    </Section>
    <div className="u-ta-c">
      <CancelBtn href={showEmployeeUrl} className="u-mr20" />
      <FormSubmitBtn {...{ submitting }} submit={confirmSubmit} text="保存" disabled={submitting || pristine} />
    </div>
  </form>
);

const EmployeeEditingForm = scrollSpy(
  ({
    naviSubmit,
    current,
    submitting,
    pristine,
    submit,
    inputedSupports,
    employeeId,
    initialValues,
    isElectronicDeliveryAgreementSetting,
    handleSubmit,
    isOpenConfirmationModal,
    setIsOpenConfirmationModal,
    confirmSubmit,
    ...rest
  }) => (
    <div>
      <div className="l-wrap-xl">
        <div className="l-title-wrap">
          <h1 className="m-title-main">従業員情報の入力</h1>
        </div>
      </div>
      <div className="l-flex">
        <div className="l-flex6-sub">
          <Affix offset={209} className={styles.sideNaviHistory}>
            <ListGroup>
              <ListGroup.Title className={styles.title}>履歴</ListGroup.Title>
              <ListGroup.Item
                as="a"
                rel="noopener noreferrer"
                target="_blank"
                href={`/employees/${employeeId}/personnel_histories`}
              >
                人事異動履歴一覧
              </ListGroup.Item>
            </ListGroup>
          </Affix>
          <Affix offset={271} className={styles.navi}>
            <ListGroup>
              <Anchor name="BasicInfomation" current={current}>
                基本情報
              </Anchor>
              <Anchor name="ResidentCardInformation" current={current}>
                住民票住所
              </Anchor>
              {isElectronicDeliveryAgreementSetting && (
                <Anchor name="ElectronicDeliveryAgreement" current={current}>
                  電子交付同意
                </Anchor>
              )}
              <Anchor name="EmploymentInfomation" current={current}>
                業務情報
              </Anchor>
              <Anchor name="RemunerationInfomation" current={current}>
                給与
              </Anchor>
              <Anchor name="CommutingAllowanceInfomation" current={current}>
                通勤手当
              </Anchor>
              <Anchor name="BankInfomation" current={current}>
                口座情報
              </Anchor>
              <Anchor name="PaymentBankSection" current={current}>
                支給設定
              </Anchor>
              <Anchor name="Dependent" current={current}>
                扶養情報
              </Anchor>
              <Anchor name="Marital" current={current}>
                配偶者情報
              </Anchor>
              <Anchor name="PersonalType" current={current}>
                税区分情報
              </Anchor>
              <Anchor name="InhabitantTax" current={current}>
                住民税
              </Anchor>
              <Anchor name="SocialInsuranceInfomation" current={current}>
                社会保険
              </Anchor>
              <ListGroup.Item>
                <FormSubmitBtn
                  {...{ submitting }}
                  submit={confirmSubmit}
                  text="保存"
                  widthWide
                  disabled={submitting || pristine}
                />
              </ListGroup.Item>
            </ListGroup>
          </Affix>
        </div>
        <div className="l-flex6-main">
          <DetailEmployeeForm
            {...{
              submitting,
              inputedSupports,
              pristine,
              isElectronicDeliveryAgreementSetting,
              confirmSubmit
            }}
            {...rest}
          />
        </div>
      </div>
      {isOpenConfirmationModal && (
        <BusinessNameConfirmationModal
          hideModal={() => setIsOpenConfirmationModal(false)}
          isOpen={isOpenConfirmationModal}
          {...{ handleSubmit, submitting, pristine }}
        />
      )}
    </div>
  )
);

export default connect(state => ({
  inputedSupports: selector(getEmployeeFormValues(state), 'employeeFamilySupports')
}))(EmployeeEditingForm);
