import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/offices/offices';
import { getItem } from 'src/reducers/common/itemReducer';
import LabourInsuranceSection from '../../components/show/LabourInsuranceSection';

const mapStateToProps = state => ({
  accidentInsurance: getItem(REDUCER_NAME, state).insurances.accident,
  employmentInsurance: getItem(REDUCER_NAME, state).insurances.employment
});
export default connect(mapStateToProps)(LabourInsuranceSection);
