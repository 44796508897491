import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/offices/offices';
import { getItem } from 'src/reducers/common/itemReducer';
import selector from 'src/utils/Utils';
import OfficeManageSection from '../../components/show/OfficeManageSection';

const mapStateToProps = state => ({
  office: selector(getItem(REDUCER_NAME, state), 'office') || {}
});
export default connect(mapStateToProps)(OfficeManageSection);
