import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';
import classNames from 'classnames';
import { Strage, Revert } from 'jbc-front/components/icons';
import ActionButton from 'jbc-front/components/ActionButton';

import { redirectTo } from 'src/utils/Http';
import { EMPLOYEE_BONUS_LIST } from 'src/constants/EndpointUrls';
import {
  FORM_NAME_BONUSES_SEARCH,
  takeBonusesSearchQueries,
  takeBonusesSearchQueriesFromLocation
} from 'src/reducers/searchForm';
import { getItem } from 'src/reducers/common/itemReducer';
import { getExtras } from 'src/reducers/common/listReducer';
import { fetchBonus, REDUCER_NAME as CLIENT_BONUS_REDUCER_NAME } from 'src/reducers/clients/clientBonus';
import { fetchEmployeeBonus, saveBonusCheckedState, REDUCER_NAME } from 'src/reducers/employees/employeeBonus';
import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import DetailSearchForm from 'src/components/DetailSearchForm';
import { ConfirmBtn } from 'src/buttons';
import TimeInfo from './components/TimeInfo';
import TargetEmployeeBonusTable from './components/TargetEmployeeBonusTable';
import styles from './TargetEmployeeBonus.scss';

class TargetEmployeeComponent extends React.Component {
  componentDidMount() {
    const { clientBonusId, dispatch } = this.props;
    dispatch(fetchBonus(clientBonusId));
  }

  render() {
    const {
      initialValues: formValues,
      clientBonus,
      location,
      resetValues,
      dispatch,
      isNeedSavingConfirmation
    } = this.props;

    return (
      <div>
        <SearchDetector
          location={location}
          didMountDetectingJudger={() => true}
          willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
          onDetect={() => {
            dispatch(fetchEmployeeBonus(takeBonusesSearchQueries(formValues)));
          }}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">賞与 対象者編集</h1>
        </div>
        {clientBonus && (
          <div>
            <div className={styles.nameWrap}>
              <h2 className={styles.name}>{_.get(clientBonus, 'name')}</h2>
              {<TimeInfo clientBonus={clientBonus} />}
            </div>
            <div className="l-wrap-xl">
              <DetailSearchForm
                containsBonusTarget
                containsOtherSearch={false}
                formName={FORM_NAME_BONUSES_SEARCH}
                resetValues={resetValues}
                resetSearch={`client_bonus_id=${clientBonus.id}`}
                menuRender={() => (
                  <div className={styles.action}>
                    <ConfirmBtn
                      widthAuto
                      text="対象者を保存"
                      confirmText="チェックされた対象者を保存してもよろしいですか"
                      handleConfirm={() => dispatch(saveBonusCheckedState())}
                      as={ActionButton}
                      icon={<Strage size={16} />}
                    >
                      対象者を保存
                    </ConfirmBtn>
                    {isNeedSavingConfirmation ? (
                      <ConfirmBtn
                        widthAuto
                        text="賞与一覧に戻る"
                        confirmText="変更内容が保存されていませんが、移動してもよろしいですか"
                        handleConfirm={() => redirectTo(EMPLOYEE_BONUS_LIST)}
                        as={ActionButton}
                        icon={<Revert size={16} />}
                      >
                        賞与一覧に戻る
                      </ConfirmBtn>
                    ) : (
                      <ActionButton onClick={() => redirectTo(EMPLOYEE_BONUS_LIST)}>
                        <Revert size={16} />賞与一覧に戻る
                      </ActionButton>
                    )}
                  </div>
                )}
              />
            </div>
            <div className={classNames('l-wrap-xl', styles.list)}>
              <TargetEmployeeBonusTable />
              <SearchFormPaginator formName={FORM_NAME_BONUSES_SEARCH} reducerName={REDUCER_NAME} />
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    bonusTarget: 'all',
    ...takeBonusesSearchQueriesFromLocation(state.router.location, true),
    ...getFormValues(FORM_NAME_BONUSES_SEARCH)(state)
  },
  clientBonusId: _.get(takeBonusesSearchQueriesFromLocation(state.router.location), 'clientBonusId'),
  clientBonus: getItem(CLIENT_BONUS_REDUCER_NAME, state),
  resetValues: { clientBonusId: _.get(takeBonusesSearchQueriesFromLocation(state.router.location), 'clientBonusId') },
  location: state.router.location,
  isNeedSavingConfirmation: (getExtras(REDUCER_NAME, state) || {}).isNeedSavingConfirmation
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME_BONUSES_SEARCH,
    enableReinitialize: true
  })
)(TargetEmployeeComponent);
