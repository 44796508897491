import React from 'react';

import { CLIENT_BONUS_ALLOWANCE_EDIT } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { Edit } from 'jbc-front/components/icons';
import Hint from 'jbc-front/components/Hint';
import styles from './BonusAllowancesTable.scss';

const bonusAllowanceEditUrl = allowance => concatParamsToUrl(CLIENT_BONUS_ALLOWANCE_EDIT, { id: allowance.id });

const BonusAllowanceRow = ({ allowance }) => (
  <tr>
    <td className={allowance.isInDisuse ? styles.through : ''}>{allowance.name}</td>
    <td className={allowance.isInDisuse ? styles.through : ''}>
      {allowance.displayFormulaDescription}
      {['health_insurance', 'nursing_insurance', 'pension_insurance'].indexOf(allowance.formulaMethodName) >= 0 && (
        <Hint
          text={
            <p>
              標準賞与額：<br />
              支給額から1,000円未満を切り捨てた額です。支給項目の設定で「社会保険の計算対象」のチェックを外した場合は、標準賞与額の計算対象外です。また、標準賞与額は以下のように上限が存在します。<br />
              ※ジョブカン給与計算上で自動で計算されます。<br />
              ◆健康保険・介護保険：年度累計で573万円まで<br />
              ◆厚生年金保険：1ヶ月合計で150万円まで<br />
              詳細は協会けんぽ「
              <a
                href="https://www.kyoukaikenpo.or.jp/g3/cat320/sb3160/sbb3165/1962-231"
                target="_blank"
                rel="noopener noreferrer"
              >
                標準報酬月額・標準賞与額とは？
              </a>
              」をご覧下さい。
            </p>
          }
        />
      )}
      {allowance.formulaMethodName === 'income_tax' && (
        <Hint
          text={
            <p>
              賞与に対する源泉徴収税額の算出率の表：<br />
              「前月に支給された給与」と「扶養親族等の数」に応じて変動します。
              また、前月に支給した給与がない場合や、賞与の支給額が前月の給与の10倍を超える場合は、特殊な計算を行います。<br />
              ※ジョブカン給与計算上で自動で計算されます。<br />
              詳細は
              {/* ヘルプページ用意できたら設定
              <a
                href=""
                target="_blank"
                rel="noopener noreferrer"
              >
                ヘルプページ
              </a>
              または
              */}
              国税庁「賞与に対する源泉徴収税額の算出率の表」をご覧下さい。
            </p>
          }
        />
      )}
      {allowance.useYearEndAdjustment && (
        <Hint
          text={
            <p>
              年末調整の過不足税額を12月賞与で精算する場合に使用してください。<br />
              年末調整機能で「過不足額の精算」画面から「ジョブカン給与計算へ反映」を行った場合に自動で「超過額又は不足額」の値が入ります。
            </p>
          }
        />
      )}
    </td>
    <td>
      <div className={styles.icons}>
        <span>
          <a href={bonusAllowanceEditUrl(allowance)}>
            <Edit size={18} />
          </a>
        </span>
      </div>
    </td>
  </tr>
);

export default BonusAllowanceRow;
