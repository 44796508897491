import React from 'react';
import styles from '../ImportMonthlyRevisions.scss';

const FileImportDescription = () => (
  <div className="l-wrap-xl">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              スタッフコード<span className={styles.required}>必須</span>
            </th>
            <td>スタッフコードを基にデータが取り込まれるため、正確に入力してください。</td>
            <td>111</td>
          </tr>
          <tr>
            <th>姓</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>山田</td>
          </tr>
          <tr>
            <th>名</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>太郎</td>
          </tr>
          <tr>
            <th>健康保険被保険者整理番号</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>11111</td>
          </tr>
          <tr>
            <th>健保対象区分</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>対象</td>
          </tr>
          <tr>
            <th>健保対象判定方法</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>自動</td>
          </tr>
          <tr>
            <th>厚年対象区分</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>対象</td>
          </tr>
          <tr>
            <th>厚年対象判定方法</th>
            <td>※ダウンロード専用項目のため更新不可</td>
            <td>自動</td>
          </tr>
          <tr>
            <th>
              改定年月<span className={styles.required}>必須</span>
            </th>
            <td>
              yyyy年mm月
              <br />
              表示されている改定年月と「入力された改定年月」が異なる場合はエラーになります。
              <br />
              月額変更届一覧でインポートしたい改定月に画面を切り替えた後、再度「月額変更届CSVインポート」をクリックしてください。
            </td>
            <td>2018年01月</td>
          </tr>
          <tr>
            <th>固定的賃金の変動あり</th>
            <td>
              下記より一つ選択ください
              <ul>
                <li>・有</li>
                <li>・無</li>
              </ul>
              ※固定的賃金の変動ありの場合は「有」、なしの場合は「無」をご選択ください。
            </td>
            <td>有/無</td>
          </tr>
          <tr>
            <th>従前改定月</th>
            <td>yyyy年mm月</td>
            <td>2018年01月</td>
          </tr>
          <tr>
            <th>昇（降）給</th>
            <td>
              昇給（降給）した場合は、「昇（降）給」に1ヶ月の合計差額を入力して下さい。（毎月の給与を基に自動計算されます）<br />
              半角数字9桁以内<br />
              昇（降）給を入力してください。<br />
              例の場合、昇（降）給が「10000円」になります。
            </td>
            <td>10000</td>
          </tr>
          <tr>
            <th>遡及支払あり</th>
            <td>
              下記より一つ選択ください
              <ul>
                <li>・有</li>
                <li>・無</li>
              </ul>
              ※遡及支払ありの場合は「有」、なしの場合は「無」をご選択ください。
            </td>
            <td>有/無</td>
          </tr>
          <tr>
            <th>遡及支払年月</th>
            <td>yyyy年mm月</td>
            <td>2018年01月</td>
          </tr>
          <tr>
            <th>遡及支払額</th>
            <td>半角数字9桁以内</td>
            <td>10000</td>
          </tr>
          <tr>
            <th>支払基礎日数1</th>
            <td>半角数字2桁以内(0〜31)</td>
            <td>30</td>
          </tr>
          <tr>
            <th>支払基礎日数2</th>
            <td>半角数字2桁以内(0〜31)</td>
            <td>30</td>
          </tr>
          <tr>
            <th>支払基礎日数3</th>
            <td>半角数字2桁以内(0〜31)</td>
            <td>30</td>
          </tr>
          <tr>
            <th>通貨による額1</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>通貨による額2</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>通貨による額3</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>現物による額1</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>現物による額2</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>現物による額3</th>
            <td>半角数字9桁以内</td>
            <td>300000</td>
          </tr>
          <tr>
            <th>修正平均額</th>
            <td>
              半角数字9桁以内<br />
              修正平均額を入力してください。<br />
              例の場合、修正平均額が「300000円」になります。マイナス入力の際は数字の前に「-」を入力してください。
            </td>
            <td>300000</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default FileImportDescription;
