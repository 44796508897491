import React from 'react';
import { connect } from 'react-redux';

import { JOIN_INSURANCE } from 'src/constants/Options';
import { TextField, DateField, BoxDouble, RadioField, CheckboxField } from 'jbc-front/components/Form';
import { isNumber, isDateStringFormat } from 'src/utils/CustomValidators';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import selector from 'src/utils/Utils';

const HealthInsuranceSection = ({ isJoinedHealthInsurance }) => (
  <div>
    <RadioField name="isJoinedHealthInsurance" options={JOIN_INSURANCE} />
    {(isJoinedHealthInsurance === true || isJoinedHealthInsurance === '1') && (
      <div>
        <TextField
          label="健康保険被保険者整理番号"
          type="text"
          name="healthInsuranceInsuredPersonnelNumber"
          validate={isNumber}
        />
        <BoxDouble>
          <DateField
            required
            label="健康保険の資格取得日"
            name="healthCertificateAcquisitionOn"
            validate={isDateStringFormat}
          />
          <DateField label="健康保険の資格喪失日" name="healthCertificateExpiredOn" validate={isDateStringFormat} />
        </BoxDouble>
        <CheckboxField
          name="isNursingInsuranceExemption"
          label="介護保険料免除対象者"
          note={
            <div>
              <p>
                介護保険料の徴収対象者のうち、非居住者等で介護保険料が免除となる場合は、チェックを入れることで介護保険料が計算されなくなります。
              </p>
            </div>
          }
        />
      </div>
    )}
  </div>
);

export default connect(state => ({
  isJoinedHealthInsurance: selector(getEmployeeFormValues(state), 'employeeInsurance', 'isJoinedHealthInsurance')
}))(HealthInsuranceSection);
