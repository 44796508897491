import { createAction } from 'redux-actions';

import { namedPayloadCreator, namedMetaCreator } from 'src/utils/Reducer';

// Actions
export const SET = 'ITEM/SET';
export const MERGE_ITEM_FIELDS = 'ITEM/MERGE_ITEM_FIELDS';

export const FETCH = 'ITEM/FETCH';
export const FETCH_SUCCESS = 'ITEM/FETCH_SUCCESS';
export const FETCH_FAILURE = 'ITEM/FETCH_FAILURE';

export const NEW = 'ITEM/NEW';
export const NEW_SUCCESS = 'ITEM/NEW_SUCCESS';
export const NEW_FAILURE = 'ITEM/NEW_FAILURE';
export const NEW_FAILURE_WITH_DATA = 'ITEM/NEW_FAILURE_WITH_DATA';

export const EDIT = 'ITEM/EDIT';
export const EDIT_SUCCESS = 'ITEM/EDIT_SUCCESS';
export const EDIT_FAILURE = 'ITEM/EDIT_FAILURE';
export const EDIT_FAILURE_WITH_DATA = 'ITEM/EDIT_FAILURE_WITH_DATA';

export const DELETE = 'ITEM/DELETE';
export const DELETE_ITEM_EXTRA = 'ITEM/DELETE_ITEM_EXTRA';
export const DELETE_SUCCESS = 'ITEM/DELETE_SUCCESS';
export const DELETE_FAILURE = 'ITEM/DELETE_FAILURE';
export const SET_ITEM_ERROR = 'ITEM/SET_ITEM_ERROR';
export const SET_ITEM_EXTRA = 'ITEM/SET_ITEM_EXTRA';

// Action Creators
export const setItem = createAction(SET, namedPayloadCreator, namedMetaCreator);
export const setItemExtra = createAction(SET_ITEM_EXTRA, namedPayloadCreator, namedMetaCreator);
export const mergeItemFields = createAction(MERGE_ITEM_FIELDS, namedPayloadCreator, namedMetaCreator);

export const fetchItem = createAction(FETCH, namedPayloadCreator, namedMetaCreator);
export const fetchItemSuccess = createAction(FETCH_SUCCESS, namedPayloadCreator, namedMetaCreator);
export const fetchItemFailure = createAction(FETCH_FAILURE, namedPayloadCreator, namedMetaCreator);

export const newItem = createAction(NEW, namedPayloadCreator, namedMetaCreator);
export const newItemSuccess = createAction(NEW_SUCCESS, namedPayloadCreator, namedMetaCreator);
export const newItemFailure = createAction(NEW_FAILURE, namedPayloadCreator, namedMetaCreator);
export const newItemFailureWithData = createAction(NEW_FAILURE_WITH_DATA, namedPayloadCreator, namedMetaCreator);

export const editItem = createAction(EDIT, namedPayloadCreator, namedMetaCreator);
export const editItemSuccess = createAction(EDIT_SUCCESS, namedPayloadCreator, namedMetaCreator);
export const editItemFailure = createAction(EDIT_FAILURE, namedPayloadCreator, namedMetaCreator);
export const editItemFailureWithData = createAction(EDIT_FAILURE_WITH_DATA, namedPayloadCreator, namedMetaCreator);

export const deleteItem = createAction(DELETE, namedPayloadCreator, namedMetaCreator);
export const deleteItemExtra = createAction(DELETE_ITEM_EXTRA, namedPayloadCreator, namedMetaCreator);
export const deleteItemSuccess = createAction(DELETE_SUCCESS, namedPayloadCreator, namedMetaCreator);
export const deleteItemFailure = createAction(DELETE_FAILURE, namedPayloadCreator, namedMetaCreator);
export const setItemError = createAction(SET_ITEM_ERROR, namedPayloadCreator, namedMetaCreator);

// Selectors
export const getItem = (reducerName, state) => state[reducerName].item.data;
export const getItemErrors = (reducerName, state) => state[reducerName].item.errors;
export const getItemExtra = (reducerName, state) => state[reducerName].item.extra;
export const isLoading = (reducerName, state) => state[reducerName].item.loading;

export const INITIAL_STATE = {
  data: null,
  errors: [],
  loading: false,
  extra: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET:
      return { ...state, data: action.payload };
    case SET_ITEM_EXTRA: {
      const currentExtra = { ...state.extra };
      return { ...state, extra: { ...currentExtra, ...action.payload } };
    }
    case MERGE_ITEM_FIELDS:
      return { ...state, data: { ...state.data, ...action.payload } };
    case FETCH:
      return { ...state, data: null, errors: [], loading: true };
    case FETCH_SUCCESS:
      return { ...state, data: action.payload, errors: [], loading: false };
    case FETCH_FAILURE:
      return { ...state, data: null, errors: action.payload, loading: false };
    case NEW:
      return { ...state, data: null, errors: [], loading: true };
    case NEW_SUCCESS:
      return { ...state, data: action.payload, errors: [], loading: false };
    case NEW_FAILURE:
      return { ...state, data: null, errors: action.payload, loading: false };
    case NEW_FAILURE_WITH_DATA:
      return { ...state, data: state.data, errors: action.payload, loading: false };
    case EDIT:
      return { ...state, data: null, errors: [], loading: true };
    case EDIT_SUCCESS:
      return { ...state, data: action.payload, errors: [], loading: false };
    case EDIT_FAILURE:
      return { ...state, data: null, errors: action.payload, loading: false };
    case EDIT_FAILURE_WITH_DATA:
      return { ...state, data: state.data, errors: action.payload, loading: false };
    case DELETE:
      return { ...state, data: null, errors: [], loading: true };
    case DELETE_ITEM_EXTRA:
      return { ...state, extra: {}, errors: [], loading: true };
    case DELETE_SUCCESS:
      return { ...state, data: action.payload, errors: [], loading: false };
    case DELETE_FAILURE:
      return { ...state, data: null, errors: action.payload, loading: false };
    case SET_ITEM_ERROR:
      return { ...state, errors: action.payload, loading: false };
    default:
      return state;
  }
};
