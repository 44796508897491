import React from 'react';
import { connect } from 'react-redux';

import { JOIN_INSURANCE } from 'src/constants/Options';
import { NumberFields, DateField, BoxDouble, RadioField } from 'jbc-front/components/Form';
import { isDateStringFormat } from 'src/utils/CustomValidators';
import { getRadioOptions } from 'src/reducers/selectOptions';
import { getEmployeeFormValues, updateEmployeeForm } from 'src/reducers/employees/employees';
import selector from 'src/utils/Utils';
import filterSelectOption from 'src/utils/EmployeeClassificationOptions';

const InsuranceNumber = ({ required }) => (
  <NumberFields
    texts={['', ' - ']}
    lengths={[4, 6, 1]}
    required={required}
    label="雇用保険 被保険者番号"
    seperateChar=""
    indexOffset={1}
    prefix="employmentInsuranceInsuredNumber"
    exactLength
  />
);

class EmploymentInsuranceSection extends React.Component {
  render() {
    const { isJoinedEmploymentInsurance, changeEmployeeClassification, employeeClassifications } = this.props;

    return (
      <div>
        <RadioField
          name="isJoinedEmploymentInsurance"
          options={JOIN_INSURANCE}
          onChange={changeEmployeeClassification}
        />
        <RadioField
          label="従業員区分"
          name="employeeClassification"
          options={filterSelectOption(isJoinedEmploymentInsurance, employeeClassifications)}
        />
        {(isJoinedEmploymentInsurance === true || isJoinedEmploymentInsurance === '1') && (
          <div>
            <InsuranceNumber />
            <BoxDouble>
              <DateField
                required
                label="雇用保険の資格取得日"
                type="text"
                name="employmentCertificateAcquisitionOn"
                validate={isDateStringFormat}
              />
              <DateField
                label="雇用保険の離職等年月日"
                type="text"
                name="employmentCertificateExpiredOn"
                validate={isDateStringFormat}
              />
            </BoxDouble>
          </div>
        )}
      </div>
    );
  }
}
export default connect(
  state => ({
    isJoinedEmploymentInsurance: selector(
      getEmployeeFormValues(state),
      'employeeInsurance',
      'isJoinedEmploymentInsurance'
    ),
    employeeClassification: selector(getEmployeeFormValues(state), 'employeeInsurance', 'employeeClassification'),
    employeeClassifications: getRadioOptions(state, 'employeeClassifications')
  }),
  dispatch => ({
    changeEmployeeClassification: e => {
      const value = e.target.value === '1' ? 'common' : 'temporary_worker';
      dispatch(updateEmployeeForm('employeeInsurance.employeeClassification', value));
    }
  })
)(EmploymentInsuranceSection);
