import { combineReducers } from 'redux';

import { setItem } from 'src/reducers/common/itemReducer';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { itemReducer } from 'src/reducers/common';

export const REDUCER_NAME = 'currentClient';
export const PLAN_TYPES = {
  trial_plan: 'お試しプラン',
  free_plan: '無料プラン',
  paid_plan: '有料プラン',
  suspended: 'アカウント停止'
};

export const isTrialPlan = planType => planType === 'trial_plan';
export const isFreePlan = planType => planType === 'free_plan';
export const isPaidPlan = planType => planType === 'paid_plan';

export const dispatchInitCurrentClient = (dispatch, currentClient) => dispatch(setItem(REDUCER_NAME, currentClient));

export default combineReducers({
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
