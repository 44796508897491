import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/offices/offices';
import { getItem } from 'src/reducers/common/itemReducer';
import SocialInsuranceSection from '../../components/show/SocialInsuranceSection';

const mapStateToProps = state => ({
  healthInsurance: getItem(REDUCER_NAME, state).insurances.health,
  pensionInsurance: getItem(REDUCER_NAME, state).insurances.pension
});
export default connect(mapStateToProps)(SocialInsuranceSection);
