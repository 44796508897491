import React from 'react';

import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { FormSubmitBtn } from 'src/buttons';
import styles from './BatchDeleteEmployeeModal.scss';

const BatchDeleteEmployeeModal = ({ isOpen, hideModal, handleSubmit, submitting, submit, checkedEmployees }) => {
  const header = '従業員一括削除';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit}>
          <span>【注意】</span>
          <br />
          <br />
          従業員情報を削除すると、削除した従業員に関する全ての情報が消去され、<br />
          当該従業員の情報は<span className={styles.warning}>復旧できなくなります</span>。<br />
          <br />
          <span className={styles.importantWarning}>本当に削除しますか？</span>
          <br />
          <br />
          削除される従業員
          <div>
            <ul>
              {checkedEmployees.map(employee => (
                <li key={employee.id}>
                  ・{employee.staffCode} {employee.displayName}
                </li>
              ))}
            </ul>
          </div>
        </form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <CommonModal.Buttons>
          <FormSubmitBtn text="削除" {...{ submitting, submit }} />
          <Button onClick={hideModal}>キャンセル</Button>
        </CommonModal.Buttons>
      </CommonModal.Footer>
    </CommonModal>
  );
};

export default BatchDeleteEmployeeModal;
