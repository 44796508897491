import React, { useEffect } from 'react';
import styles from './Hint.scss';

const Hint = props => {
  const handleClick = () => {
    props.onClick();
  };

  useEffect(() => {
    document.addEventListener('click', handleClick, true);
    return () => document.removeEventListener('click', handleClick, true);
  }, []);

  return (
    <div className={styles.hint}>
      <div className={styles.hintShape} />
      <div className={styles.hintMessage}>{props.children}</div>
    </div>
  );
};

export default Hint;
