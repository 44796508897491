import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import CommonModal from 'jbc-front/components/CommonModal';
import { IMPORT_FROM_ROUMU } from 'src/constants/FormNames';
import { setGlobalErrors } from 'src/reducers/global';
import { CHOOSE_COORDINATION_EMPLOYEE } from 'src/constants/EndpointUrls';
import styles from './ImportFromRoumuModal.scss';

const ImportForm = ({ handleSubmit, submitting, submit, cancelBtnClick, header }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <div>
          ジョブカン労務HRの 「<a
            href="https://lms.jobcan.jp/employees/coordination_targets"
            rel="noopener noreferrer"
            className="u-txt-link"
          >
            従業員の給与連携設定
          </a>」で選択された従業員が取得されます。 <br />
          従業員情報の更新をする場合は、「<a
            href={CHOOSE_COORDINATION_EMPLOYEE}
            rel="noopener noreferrer"
            className="u-txt-link"
          >
            従業員の労務連携設定
          </a>」でも選択が必要です。
        </div>
        <div className={styles.contentBottom}>
          取得を行うと、全ての
          <a
            href="https://jobcan-payroll.zendesk.com/hc/ja/articles/360002077414#Contents2"
            rel="noopener noreferrer"
            target="_blank"
            className="u-txt-link"
          >
            連携項目
          </a>
          が労務HRの情報で上書きされます。<br />
          労務HRで空欄の場合、空欄として上書きされますのでご注意ください。
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="取得" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let ImportContainer = reduxForm({
  form: IMPORT_FROM_ROUMU
})(ImportForm);

ImportContainer = connect(undefined, (dispatch, ownProps) => ({
  onSubmit: async () => {
    try {
      await ownProps.onSubmit();
      window.location.reload();
    } catch (err) {
      const msg = _.get(err, 'response.data.errors.messages[0]', '同期に失敗しました');
      dispatch(setGlobalErrors(msg));
    }
  }
}))(ImportContainer);

const ImportFromRoumuModal = ({ isOpen, hideModal, onSubmit }) => {
  const header = 'ジョブカン労務HRから従業員情報を取得';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <ImportContainer header={header} cancelBtnClick={hideModal} onSubmit={onSubmit} />
    </CommonModal>
  );
};

export default ImportFromRoumuModal;
