import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import Button from 'jbc-front/components/Button';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { SearchReactSelect } from 'src/components';
import {
  REPORT_INDEX,
  REPORT_LABOR_INSURANCE_DECLARATIONS,
  REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS,
  REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_CSV
} from 'src/constants/EndpointUrls';
import { LABOR_INSURANCE_SUMMARY_TYPES } from 'src/constants/Options';
import { extractCurrentQueries } from 'src/QueriesStorageApi';
import { getSelectOptions } from 'src/reducers/selectOptions';
import LoadingView from 'src/components/LoadingView';
import { getIsLoading, getListQueries, addQuery, addQueries, getList } from 'src/reducers/common/listReducer';
import {
  REDUCER_NAME,
  fetchReportLaborInsurances,
  fetchReportLaborInsuranceDeclarationForm,
  changeUrl,
  fetchApplicableOfficesOfLaborInsuranceYear,
  fetchReportLaborInsuranceData,
  fetchInit,
  setAllFormShowMode,
  clearAllFormErrors
} from 'src/reducers/reports/reportLaborInsurances';

import styles from './ReportLaborInsuranceList.scss';
import DeclarationForm from './components/DeclarationForm';
import SummaryList from './components/SummaryList';
import ConfirmReaggregateSummaryModal from './components/ConfirmReaggregateSummaryModal';

const SelectSummaryType = ({ selectedSummaryType, handleOnClick }) => (
  <div className={styles.summaryTypeWrap}>
    {LABOR_INSURANCE_SUMMARY_TYPES.map((summaryType, index) => (
      <div
        className={summaryType.value === selectedSummaryType ? styles.summaryTypeBtnActive : styles.summaryTypeBtn}
        key={summaryType.value}
        active={summaryType.value === selectedSummaryType}
        onClick={e => {
          e.preventDefault();
          handleOnClick(summaryType);
        }}
        role="presentation"
      >
        <span className={styles.summaryTypeStep}>STEP.{index + 1}</span>
        {summaryType.label}
      </div>
    ))}
  </div>
);

class ReportLaborInsuranceList extends React.Component {
  constructor(props) {
    super(props);

    this.handleOnSelectSummaryType = this.handleOnSelectSummaryType.bind(this);
    this.openReaggregateSummaryModal = this.openReaggregateSummaryModal.bind(this);
    this.closeReaggregateSummaryModal = this.closeReaggregateSummaryModal.bind(this);

    this.state = {
      selectedSummaryType: this.props.location.pathname.indexOf(REPORT_LABOR_INSURANCE_DECLARATIONS) !== -1 ? '2' : '1',
      isReaggregateSummaryModalOpen: false
    };
  }

  componentDidMount() {
    const { laborInsuranceYears } = this.props;
    const queriesCache = extractCurrentQueries(REDUCER_NAME);
    const { laborInsuranceYear = _.get(laborInsuranceYears, [0, 'value']) } = queriesCache;

    const queries = { ...queriesCache, laborInsuranceYear };

    if (!_.find(laborInsuranceYears, o => o.value === laborInsuranceYear)) {
      delete queries.laborInsuranceYear;
    }
    this.props.addQueries(queries);
    this.props.fetchInit(queries);
  }

  handleOnSelectSummaryType(summaryType) {
    this.setState({
      ...this.state,
      selectedSummaryType: summaryType
    });
  }

  openReaggregateSummaryModal() {
    if (this.state.isReaggregateSummaryModalOpen) return;
    this.setState({ ...this.state, isReaggregateSummaryModalOpen: true });
  }

  closeReaggregateSummaryModal() {
    if (!this.state.isReaggregateSummaryModalOpen) return;
    this.setState({ ...this.state, isReaggregateSummaryModalOpen: false });
  }

  render() {
    const {
      queries,
      laborInsuranceYears,
      applicableOffices,
      monthlySummaries,
      onChangeLaborInsuranceYear,
      onChangeApplicableOffice,
      changeLocation,
      alert,
      isListLoading
    } = this.props;

    const detailUrl = laborInsuranceYear =>
      `${REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS.replace(':year', laborInsuranceYear)}`;

    const csvDownloadUrl = laborInsuranceYear =>
      `${REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_CSV}?labor_insurance_year=${laborInsuranceYear}`;

    const alertByLaborInsuranceYear = alert?.find(item => item.year === queries.laborInsuranceYear);
    const isUnknownOffice = alertByLaborInsuranceYear?.unknownOffice;
    const isOfficeChanged = alertByLaborInsuranceYear?.officeChanged;
    const isDetailEdited = alertByLaborInsuranceYear?.detailEdited;

    const isAlert = () => {
      if (!_.isInteger(queries.laborInsuranceYear) || _.isEmpty(alert)) {
        return false;
      }
      return [isUnknownOffice, isOfficeChanged, isDetailEdited].some(item => item === true);
    };

    return (
      <div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
              <BreadCrumb.Item label="労働保険の年度更新" />
            </BreadCrumb>
          </div>
          <h1 className="m-title-main">労働保険の年度更新</h1>
          <div className={styles.container}>
            <div className={styles.selectYear}>
              <SearchReactSelect
                options={laborInsuranceYears}
                onChange={selected => {
                  onChangeLaborInsuranceYear(selected.value);
                }}
                value={queries.laborInsuranceYear}
                size="large"
              />
            </div>
            <div>
              <Button className={styles.detailButton} href={detailUrl(queries.laborInsuranceYear)} as="a">
                内訳
              </Button>
            </div>
            {queries.laborInsuranceYear &&
              monthlySummaries &&
              monthlySummaries.length > 0 && (
                <React.Fragment>
                  <Button widthAuto onClick={this.openReaggregateSummaryModal} className="u-mr10">
                    再集計
                  </Button>
                  {this.state.isReaggregateSummaryModalOpen && (
                    <ConfirmReaggregateSummaryModal
                      isOpen={this.state.isReaggregateSummaryModalOpen}
                      hideModal={this.closeReaggregateSummaryModal}
                      laborInsuranceYear={queries.laborInsuranceYear}
                    />
                  )}
                </React.Fragment>
              )}
            {applicableOffices.length > 1 && (
              <div className={styles.selectOffice}>
                <SearchReactSelect
                  options={applicableOffices}
                  onChange={selected => {
                    onChangeApplicableOffice(selected.value);
                    changeLocation(this.state.selectedSummaryType);
                  }}
                  value={queries.applicableOffice}
                  size="large"
                />
              </div>
            )}
          </div>
          {isAlert() && (
            <div className={styles.alertDiv}>
              <div className={styles.alertBox}>
                {isUnknownOffice && (
                  <p>
                    所属事業所が特定出来ないため、集計されないデータが存在します。
                    <br />
                    <a className={styles.alertAnchor} href={csvDownloadUrl(queries.laborInsuranceYear)}>
                      確認用CSV
                    </a>
                    で対象従業員を特定し、内訳編集画面で当該データの事業所を設定して下さい。
                  </p>
                )}
                {isOfficeChanged && (
                  <p>労働保険の参照先が変更された事業所が存在します。集計表に反映するには再集計を行ってください</p>
                )}
                {isDetailEdited && <p>内訳が編集されています。集計表に反映するには再集計を行ってください</p>}
              </div>
            </div>
          )}
          <SelectSummaryType
            selectedSummaryType={this.state.selectedSummaryType}
            handleOnClick={e => {
              this.handleOnSelectSummaryType(e.value);
              changeLocation(e.value);
            }}
          />
          <LoadingView isLoading={isListLoading}>
            {this.state.selectedSummaryType === '1' && (
              <SummaryList
                laborInsuranceYear={queries.laborInsuranceYear}
                applicableOffice={queries.applicableOffice}
              />
            )}
            {this.state.selectedSummaryType === '2' && (
              <DeclarationForm
                laborInsuranceYear={queries.laborInsuranceYear}
                applicableOffice={queries.applicableOffice}
              />
            )}
          </LoadingView>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  laborInsuranceYears: getSelectOptions(state, 'laborInsuranceYears'),
  applicableOffices: getSelectOptions(state, 'applicableOffices'),
  monthlySummaries: getList(REDUCER_NAME, state).monthlySummaries,
  queries: getListQueries(REDUCER_NAME, state),
  isListLoading: getIsLoading(REDUCER_NAME, state)
});
const mapDispatchToProps = dispatch => ({
  addQueries: queries => {
    dispatch(addQueries(REDUCER_NAME, queries));
  },
  fetchInit: queries => {
    dispatch(fetchInit(queries));
  },
  onChangeLaborInsuranceYear: (query, summaryType) => {
    dispatch(fetchReportLaborInsuranceData(query, summaryType));
  },
  onChangeApplicableOffice: query => {
    dispatch(addQuery(REDUCER_NAME, 'applicableOffice', query));
    dispatch(fetchReportLaborInsurances());
    dispatch(fetchReportLaborInsuranceDeclarationForm());
  },
  changeLocation: summaryType => {
    dispatch(changeUrl(summaryType));
    dispatch(setAllFormShowMode());
    dispatch(clearAllFormErrors());
  },
  fetchApplicableOfficesOfLaborInsuranceYear: () => {
    dispatch(fetchApplicableOfficesOfLaborInsuranceYear());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(ReportLaborInsuranceList);
