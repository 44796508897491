// ジョブカン給与計算 メインページ
export const ABOUT_JOBCAN_PAYROLL = 'https://payroll.jobcan.ne.jp';

// ジョブカン給与計算 情報セキュリティ基本方針
export const INFORMATION_SECURITY_POLICY = 'https://all.jobcan.ne.jp/personalinfo/';

// ジョブカン給与計算 プライバシーポリシー
export const PRIVACY_POLICY = 'https://all.jobcan.ne.jp/policy/';

// ジョブカン給与計算 利用規約
export const TERMS_OF_SERVICE = 'https://payroll.jobcan.ne.jp/terms/';

// ジョブカン給与計算 ヘルプページ
export const ZENDESK_HELP = 'https://jobcan-payroll.zendesk.com/hc/ja';
