import React from 'react';

import { CLIENT_UNIT_PRICE_EDIT } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { DeleteBtn } from 'src/buttons';
import { Edit } from 'jbc-front/components/icons';
import styles from './ClientUnitPriceTableRow.scss';

const allowanceEditUrl = (payrollRulesGroupId, id) =>
  concatParamsToUrl(CLIENT_UNIT_PRICE_EDIT, { payrollRulesGroupId, id });

const ClientUnitPriceTableRow = ({ id, name, payrollRulesGroupId, onDelete }) => (
  <tr>
    <td>{name}</td>
    <td>
      <div className={styles.icons}>
        <span>
          <a href={allowanceEditUrl(payrollRulesGroupId, id)}>
            <Edit size={18} />
          </a>
        </span>
        <span>
          <DeleteBtn widthAuto handleDelete={() => onDelete(payrollRulesGroupId, id)} />
        </span>
      </div>
    </td>
  </tr>
);

export default ClientUnitPriceTableRow;
