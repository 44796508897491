import React from 'react';

import { numberWithComma } from 'src/utils/Utils';
import styles from './PayrollTableData.scss';
import Row from './Row';

const PayrollSummaryDetail = ({ payroll }) => (
  <div className={styles.paymentClm}>
    {payroll && (
      <div>
        <table className="m-table-obvious">
          <thead>
            <tr>
              <th colSpan="2">合計</th>
            </tr>
          </thead>
          <tbody>
            <Row label="差引支給額" value={numberWithComma(payroll.subscriptionAmount)} />
            {payroll.genbutsuAmount !== 0 && <Row label="現物支給額" value={numberWithComma(payroll.genbutsuAmount)} />}
            {payroll.transferWithBank.map(item => (
              <Row label={item.label} value={numberWithComma(item.value)} key={item.id} />
            ))}
          </tbody>
        </table>
        <table className="m-table-obvious u-mt10">
          <thead>
            <tr>
              <th>備考</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="m-table-obvious-remarks">
                <div className={styles.remarks}>{payroll.memo}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )}
  </div>
);

export default PayrollSummaryDetail;
