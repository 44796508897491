import { connect } from 'react-redux';
import { autofill as autofillForm, focus as focusForm } from 'redux-form';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import ManualInsuranceSettingSection from '../components/ManualInsuranceSettingSection';

const mapDispatchToProps = dispatch => ({
  autofill: (fieldName, fieldValue) => dispatch(autofillForm(EMPLOYEE_FORM, fieldName, fieldValue)),
  focus: fieldName => dispatch(focusForm(EMPLOYEE_FORM, fieldName))
});

export default connect(null, mapDispatchToProps)(ManualInsuranceSettingSection);
