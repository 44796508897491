import React from 'react';

import styles from './Header.scss';

// eslint-disable-next-line import/prefer-default-export
export const NotVerified = () => (
  <div className={styles.notVerified}>
    メール送信機能が制限されています。
    <br />
    機能解放をご希望の場合は
    <a
      className={styles.importantLink}
      target="_blank"
      rel="noopener noreferrer"
      href="https://jobcan-payroll.zendesk.com/hc/ja/articles/37297688359321"
    >
      こちら
    </a>
    へ
  </div>
);
