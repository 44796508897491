import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import get from 'lodash/get';
import { updateElectronicDeliveryAgreement } from 'src/reducers/users/profile';
import { PROFILE_FORM } from 'src/constants/FormNames';
import SettingElectronicDeliveryForm from '../components/SettingElectronicDelivery';

const mapStateToProps = (state, ownProps) => {
  const employee = ownProps.employee;
  return {
    initialValues: {
      isElectronicDeliveryAgreement: employee.isElectronicDeliveryAgreement
    },
    isElectronicDeliveryAgreement: employee.isElectronicDeliveryAgreement,
    formValues: getFormValues(PROFILE_FORM)(state),
    isElectronicDeliveryAgreementSetting: get(state, 'currentClient.item.data.isElectronicDeliveryAgreementSetting'),
    isFlex: ownProps.isFlex || false
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const redirectPath = ownProps.redirectPath;
  return {
    onSubmit: () => {
      dispatch(updateElectronicDeliveryAgreement(redirectPath));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SettingElectronicDeliveryForm);
