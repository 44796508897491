import _ from 'lodash';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import { combineReducers } from 'redux';
import { getFormValues } from 'redux-form';
import { setGlobalSuccesses, setGlobalErrors } from 'src/reducers/global';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { setExtras, getExtras } from 'src/reducers/common/listReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { takeEmployeesSearchQueries, FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import { fetchJobStatus, isJobIncomplete } from 'src/reducers/JobStatus';
import { MAX_POLLING_INTERVAL, INITIAL_POLLING_INTERVAL } from 'src/constants/Generals';
import { serializeHttpGetParams } from 'src/utils/Http';
import * as Urls from 'src/constants/EndpointUrls';

export const REDUCER_NAME = 'employeesAsync';
export const getIsCreating = state => _.get(getExtras(REDUCER_NAME, state), 'isCreating');

/**
 * 従業員CSVダウンロード
 */
export const fetchDownloadEmployeeCsvJobStatus = (interval, uniqueString) => async (dispatch, getState) => {
  const params = {
    job_name: 'export_employee_data_job',
    search_params: { unique_string: uniqueString }
  };
  await dispatch(fetchJobStatus(params));
  if (isJobIncomplete(getState().JobStatus.export_employee_data_job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchDownloadEmployeeCsvJobStatus(nextInterval, uniqueString));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { isCreating: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { isCreating: false }));
  }
};

export const downloadEmployeeCsv = (data, props) => async (dispatch, getState) => {
  const queries = takeEmployeesSearchQueries(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(getState())) || {};
  const encoding = _.get(data, 'encoding');
  const csvFormat = _.get(data, 'csvFormat');
  const target = _.get(data, 'target');
  const params = (() => {
    switch (target) {
      case 'search':
        return { ...queries, encoding, csv_format: csvFormat };
      case 'all':
        return { encoding, target_all: true, csv_format: csvFormat };
      case 'working and leaved':
      default:
        return { encoding, target_working_and_leaved: true, csv_format: csvFormat };
    }
  })();

  const downloadUrl = `${Urls.EXPORT_EMPLOYEES_CSV}?${serializeHttpGetParams(params)}`;

  try {
    const response = await fetch(downloadUrl);

    // fetchのbodyで判定を行い辛いのでheaderで処理分岐
    // Content-Typeがapplication/jsonになるのは以下の2パターン
    // - 非同期処理実行時
    // - エラー発生時
    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.errors.messages);
      }

      if (json.is_creating === true) {
        props.hideModal();
        setTimeout(() => {
          dispatch(fetchDownloadEmployeeCsvJobStatus(INITIAL_POLLING_INTERVAL, json.unique_string));
        }, INITIAL_POLLING_INTERVAL);
        dispatch(setExtras(REDUCER_NAME, { isCreating: true }));
        dispatch(setGlobalSuccesses('従業員データはメールにてお送りします'));
      }
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
