import React from 'react';

import tableStyles from 'src/features/Employees/styles/GeneralTableInfo.scss';
import { Section } from 'jbc-front/components/Form';
import { HYPHEN_CHARACTER } from 'src/constants/Generals';
import styles from '../Common.scss';

export default ({ accidentInsurance, employmentInsurance }) => (
  <div>
    <div className={styles.sectionWrap}>
      <Section title="労災保険">
        <table className={tableStyles.mTablePersonal}>
          <tr>
            <th>事業所名:</th>
            <td>{accidentInsurance.contactInfo.name}</td>
          </tr>
          <tr>
            <th>事業所名（カナ）:</th>
            <td>{accidentInsurance.contactInfo.nameKana}</td>
          </tr>
          <tr>
            <th>住所:</th>
            <td>{accidentInsurance.contactInfo.address}</td>
          </tr>
          <tr>
            <th>住所（カナ）:</th>
            <td>{accidentInsurance.contactInfo.addressKana}</td>
          </tr>
          <tr>
            <th>電話番号:</th>
            <td>{accidentInsurance.contactInfo.tel}</td>
          </tr>
          <tr>
            <th>事業主（代表者）:</th>
            <td>{accidentInsurance.contactInfo.representativeName}</td>
          </tr>
          <tr>
            <th>労働保険番号:</th>
            <td>{accidentInsurance.joinedInsuranceCode ? accidentInsurance.joinedInsuranceCode : HYPHEN_CHARACTER}</td>
          </tr>
          <tr>
            <th>労災保険の業種:</th>
            <td>{accidentInsurance.insuranceTypeStr}</td>
          </tr>
        </table>
      </Section>
    </div>
    <div className={styles.sectionWrap}>
      <Section title="雇用保険">
        <table className={tableStyles.mTablePersonal}>
          <tr>
            <th>事業所名:</th>
            <td>{employmentInsurance.contactInfo.name}</td>
          </tr>
          <tr>
            <th>事業所名（カナ）:</th>
            <td>{employmentInsurance.contactInfo.nameKana}</td>
          </tr>
          <tr>
            <th>住所:</th>
            <td>{employmentInsurance.contactInfo.address}</td>
          </tr>
          <tr>
            <th>住所（カナ）:</th>
            <td>{employmentInsurance.contactInfo.addressKana}</td>
          </tr>
          <tr>
            <th>電話番号:</th>
            <td>{employmentInsurance.contactInfo.tel}</td>
          </tr>
          <tr>
            <th>事業主（代表者）:</th>
            <td>{employmentInsurance.contactInfo.representativeName}</td>
          </tr>
          <tr>
            <th>労働保険番号:</th>
            <td>
              {employmentInsurance.joinedLabourInsuranceCode
                ? employmentInsurance.joinedLabourInsuranceCode
                : HYPHEN_CHARACTER}
            </td>
          </tr>
          <tr>
            <th>雇用保険番号:</th>
            <td>
              {employmentInsurance.joinedInsuranceCode ? employmentInsurance.joinedInsuranceCode : HYPHEN_CHARACTER}
            </td>
          </tr>
          <tr>
            <th>管轄のハローワーク:</th>
            <td>{employmentInsurance.governmentOffice}</td>
          </tr>
          <tr>
            <th>雇用保険の業種:</th>
            <td>{employmentInsurance.insuranceTypeStr}</td>
          </tr>
        </table>
      </Section>
    </div>
  </div>
);
