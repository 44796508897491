import React from 'react';
import { connect } from 'react-redux';

import { UPDATE_BATCH_CSV } from 'src/constants/EndpointUrls';
import { getSelectOptions } from 'src/reducers/selectOptions';

import AnyApiRunning from './components/AnyApiRunning';
import FileImportDescription from './components/FileImportDescription';
import FormContainer from './containers/EmployeeCsvInsertFormContainer';
import styles from './styles/FileImport.scss';

const InsertBatchCsv = ({ anyApiRunning }) => (
  <div>
    {anyApiRunning && <AnyApiRunning />}
    <div className="l-title-wrap">
      <h1 className="m-title-main">新規登録：従業員情報</h1>
      <p className="m-title-main-note">
        従業員情報を一括で新規登録できます。<br />※ 登録済みの従業員の情報を更新する場合は<a
          href={UPDATE_BATCH_CSV}
          className="u-txt-link"
        >
          こちら
        </a>
      </p>
    </div>
    <FormContainer />
    <div className={styles.description}>
      <FileImportDescription />
    </div>
  </div>
);

const mapStateToProps = state => ({
  anyApiRunning: getSelectOptions(state, 'anyApiRunning')
});

export default connect(mapStateToProps)(InsertBatchCsv);
