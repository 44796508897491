import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { CLIENT_SETTINGS, CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { CLIENT_ALLOWANCE_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, createAllowance, fetchNewAllowance } from 'src/reducers/clients/allowances';
import { getItem, deleteItem, deleteItemExtra } from 'src/reducers/common/itemReducer';
import { getExtras } from 'src/reducers/common/listReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';

import AllowanceEditingForm from './components/AllowanceEditingForm';

const initializeClientAllowanceItemId = clientAllowanceItems =>
  clientAllowanceItems.map(clientAllowanceItem => (clientAllowanceItem.value ? clientAllowanceItem.id : false));

const NewAllowanceForm = reduxForm({
  form: CLIENT_ALLOWANCE_FORM
})(AllowanceEditingForm);

const NewClientAllowance = ({ match, payrollRulesGroup }) => {
  const { payrollRulesGroupId } = match.params;
  const allowance = useSelector(state => getItem(REDUCER_NAME, state));
  const clientAllowanceItem = useSelector(state => getSelectOptions(state, 'clientAllowanceItems'));

  const selectedAllowanceType = useSelector(state => getExtras(REDUCER_NAME, state)).selectedAllowanceType;
  const clientAllowanceItemId = initializeClientAllowanceItemId(clientAllowanceItem);
  const initialValues = {
    ...allowance,
    allowanceType: selectedAllowanceType,
    allowanceAmount: '0',
    clientAllowanceItemId
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchNewAllowance(payrollRulesGroupId));
    return () => {
      dispatch(deleteItem(REDUCER_NAME));
      dispatch(deleteItemExtra(REDUCER_NAME));
    };
  }, []);

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">支給・控除項目 追加登録</h1>
        <div className="m-title-main-note">{payrollRulesGroup.name}</div>
      </div>
      <div className="l-wrap-m">
        <div className="l-breadcrumb u-mb20">
          <a className="l-breadcrumb-link" href={CLIENT_SETTINGS}>
            設定
          </a>
          <a className="l-breadcrumb-link" href={CLIENT_PAYROLL_RULES_GROUP_LIST}>
            給与規定グループ一覧
          </a>
          <Link to={`/clients/payroll_rules_groups/${payrollRulesGroupId}/allowances`} className="l-breadcrumb-link">
            支給・控除項目一覧
          </Link>
          <span className="l-breadcrumb-here">支給・控除項目 追加登録</span>
        </div>
        <NewAllowanceForm
          payrollRulesGroup={payrollRulesGroup}
          initialValues={initialValues}
          enableReinitialize
          keepDirtyOnReinitialize
          onSubmit={data => dispatch(createAllowance(data))}
        />
      </div>
    </div>
  );
};

export default NewClientAllowance;
