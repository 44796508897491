import React from 'react';

import Affix from 'jbc-front/components/Affix';
import ListGroup from 'jbc-front/components/ListGroup';
import { Anchor } from 'src/components';
import { FormSubmitBtn } from 'src/buttons';
import styles from './SideNavigation.scss';

const SideNavigation = ({ current, submitting, pristine, submit, isOnlyMainOffice, isEdit = false, isMainOffice }) => (
  <Affix offset={271} className={styles.navigation}>
    <ListGroup>
      {!isOnlyMainOffice && (
        <Anchor name="OfficeManageInformation" current={current}>
          事業所管理情報
        </Anchor>
      )}
      <Anchor name="OfficeInformation" current={current}>
        事業所情報
      </Anchor>
      <Anchor name="SocialInsuranceInformation" current={current}>
        社会保険情報
      </Anchor>
      <Anchor name="LabourInsuranceInformation" current={current}>
        労働保険情報
      </Anchor>
      {isMainOffice && (
        <React.Fragment>
          <Anchor name="LabourConsultantInformation" current={current}>
            社労士情報
          </Anchor>
          <Anchor name="BankInformation" current={current}>
            振込元口座情報
          </Anchor>
        </React.Fragment>
      )}
      {isEdit && (
        <ListGroup.Item>
          <FormSubmitBtn {...{ submitting, submit }} text="保存" widthWide disabled={submitting || pristine} />
        </ListGroup.Item>
      )}
    </ListGroup>
  </Affix>
);

export default SideNavigation;
