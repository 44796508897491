import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import { TextField, Section } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';

import { RESET_PASSWORD_FORM } from 'src/constants/FormNames';
import { ErrorsSection } from 'src/components';
import { getLoginMessages } from 'src/reducers/users/currentUser';
import { setNewPassword } from 'src/reducers/users/resetPassword';
import { minLength6 } from 'src/utils/CustomValidators';

const passwordValidate = values => {
  const errors = {};
  if (values.password && values.passwordConfirmation && values.password !== values.passwordConfirmation) {
    errors.passwordConfirmation = 'が一致しません';
  }
  return errors;
};

const RawSetNewPasswordForm = props => {
  const { handleSubmit, pristine, submit, submitting, errors } = props;
  const {
    title = 'パスワードの再設定',
    passwordLabel = '新パスワード',
    resetPasswordLabel = '新パスワード（確認用）',
    btnLabel = 'パスワード再設定'
  } = props;

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">{title}</h1>
      </div>

      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        <Section>
          <TextField name="password" type="password" label={passwordLabel} required validate={minLength6} />
          <TextField
            name="passwordConfirmation"
            type="password"
            label={resetPasswordLabel}
            required
            validate={minLength6}
          />
          <div className="u-ta-c u-mt30">
            <Button primary onClick={submit} disabled={pristine || submitting}>
              {btnLabel}
            </Button>
          </div>
        </Section>
      </form>
    </div>
  );
};

const SetNewPasswordForm = reduxForm({
  form: RESET_PASSWORD_FORM,
  validate: passwordValidate
})(RawSetNewPasswordForm);

export default connect(
  state => ({
    errors: getLoginMessages(state) || []
  }),
  (dispatch, ownProps) => ({
    onSubmit: data => dispatch(setNewPassword(data, ownProps.resetPasswordToken, ownProps.setInvalidToken))
  })
)(SetNewPasswordForm);
