import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import AllJobStatusContainer from 'src/components/JobStatus/AllJobStatusContainer';
import {
  downloadImportErrorOfLaborInsuranceCsv,
  setInvisibleImportCsvJob
} from 'src/reducers/reports/reportLaborInsurances';
import styles from './LaborInsuranceImportCsvJobStatus.scss';

const statusText = job => {
  switch (job.status) {
    case 'failed':
      return '内訳CSVインポートが失敗しました。';
    case 'partial_success':
      return '内訳CSVインポートが失敗しました。なお、以下にエラー表示のない内訳データは、正常に登録されています。';
    case 'in_progress':
    case 'waiting':
      return '内訳CSVインポートの処理中です。';
    default:
      return '内訳CSVインポートが完了しました。';
  }
};

const ImportCsvJobStatus = props => {
  const { importCsvJobs, fetchImportCsvJobs } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    fetchImportCsvJobs();
  }, []);

  return (
    <React.Fragment>
      {!isEmpty(importCsvJobs) &&
        importCsvJobs.map(job => (
          <div className={styles.jobStatus} key={job.jobId}>
            <AllJobStatusContainer
              task={job}
              statusText={statusText}
              isDisplayErrorLink={job.hasErrorFilePath}
              errorHandler={() => dispatch(downloadImportErrorOfLaborInsuranceCsv(job))}
              jobId={job.jobId}
              hideJobMessage={setInvisibleImportCsvJob}
            />
          </div>
        ))}
    </React.Fragment>
  );
};

export default ImportCsvJobStatus;
