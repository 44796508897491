import { connect } from 'react-redux';
import { getFormValues, change as changeForm } from 'redux-form';
import { OFFICE_FORM } from 'src/constants/FormNames';
import {
  fetchSocialInsuranceRates,
  fetchReferenceOffice,
  isSelf,
  changeFormHealthInsuranceRate,
  changeFormPensionInsuranceRate
} from 'src/reducers/offices/insurance';

import { getSelectOptions } from 'src/reducers/selectOptions';
import { getExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME } from 'src/reducers/offices/offices';
import SocialInsuranceSection from '../../components/edit/SocialInsuranceSection';

const mapStateToProps = state => ({
  insuranceTypes: getSelectOptions(state, 'insuranceTypes') || [],
  healthInsurance: getFormValues(OFFICE_FORM)(state).healthInsurance,
  pensionInsurance: getFormValues(OFFICE_FORM)(state).pensionInsurance,
  settingStartDate: getFormValues(OFFICE_FORM)(state).socialInsurance.settingStartDate,
  prefectureId: getFormValues(OFFICE_FORM)(state).office.prefectureId,
  // 編集用のプルダウンの情報
  referenceOffices: getExtras(REDUCER_NAME, state).referenceOffices.social,
  // 対象のクライアントの持つ全ての社会保険情報
  allReferenceOffices: getExtras(REDUCER_NAME, state).allReferenceOffices.social,
  healthInsuranceSelf: isSelf(
    getFormValues(OFFICE_FORM)(state).office.id,
    getFormValues(OFFICE_FORM)(state).healthInsurance.socialInsuranceOfficeId,
    getFormValues(OFFICE_FORM)(state).healthInsurance.officeId
  ),
  masterPensionInsurances: getExtras(REDUCER_NAME, state).insurances.pension.master,
  masterHealthInsurances: getExtras(REDUCER_NAME, state).insurances.health.master,
  isCurrentMonthCollection: getFormValues(OFFICE_FORM)(state).healthInsurance.isCurrentMonthCollection
});

const mapDispatchToProps = dispatch => ({
  fetchSocialInsuranceRates: (healthInsuranceType, settingStartDate, prefectureId) => {
    dispatch(fetchSocialInsuranceRates(healthInsuranceType, settingStartDate, prefectureId));
  },
  changeFormHealthInsuranceRate: (healthInsuranceType, settingStartDate) => {
    dispatch(changeFormHealthInsuranceRate(healthInsuranceType, settingStartDate));
  },
  changeFormPensionInsuranceRate: (pensionManualInput, settingStartDate) => {
    dispatch(changeFormPensionInsuranceRate(pensionManualInput, settingStartDate));
  },
  refChange: (settingStartDate, socialInsuranceOfficeId) => {
    dispatch(fetchReferenceOffice('social_insurance', settingStartDate, socialInsuranceOfficeId));
  },
  changeFormPensionInsuranceRateIsInput: isInput => {
    dispatch(changeForm(OFFICE_FORM, 'pensionInsurance.isInput', isInput));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialInsuranceSection);
