import React from 'react';
import Modal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { COORDINATION_SERVICE_LIST } from 'src/constants/EndpointUrls';

const UnsyncModal = ({ isOpen, hideModal }) => (
  <Modal isOpen={isOpen} hideModal={hideModal}>
    <Modal.Header hideModal={hideModal}>ジョブカン労務HRとの連携</Modal.Header>
    <Modal.Body>
      現在、連携設定が行われていません。<br />
      連携の設定を行うことで各ジョブカンシリーズとの情報の連携が簡単にできます。
    </Modal.Body>
    <Modal.Footer>
      <Modal.Buttons>
        <Button onClick={hideModal}>閉じる</Button>
        <Button primary as="a" href={COORDINATION_SERVICE_LIST}>
          設定画面へ
        </Button>
      </Modal.Buttons>
    </Modal.Footer>
  </Modal>
);

export default UnsyncModal;
