import connect from 'react-redux/es/connect/connect';

import { getSelectOptions } from 'src/reducers/selectOptions';
import { initializeModalData, destructModalData } from 'src/reducers/my_numbers/myNumbers';

import EmployeeTableRowComponent from '../components/EmployeeTableRow';

export default connect(
  state => ({
    enrollmentStatuses: getSelectOptions(state, 'enrollmentStatuses')
  }),
  dispatch => ({
    initializeModal: employeeId => {
      dispatch(initializeModalData(employeeId));
    },
    destructModal: () => {
      dispatch(destructModalData());
    }
  })
)(EmployeeTableRowComponent);
