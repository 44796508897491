export const ACCOUNT_REGISTRATION_FORM = 'accountRegistrationForm';
export const CLIENT_FORM = 'client';
export const OFFICE_FORM = 'office';
export const CURRENT_CLIENT_FORM = 'currentClient';
export const PAYROLL_RULES_GROUP_FORM = 'payrollRulesGroup';
export const PAYROLL_RULES_GROUP_PAYMENT_ON_WEEKEND_CASE_FORM = 'payrollRulesPaymentOnWeekendCaseForm';
export const EMPLOYEE_FORM = 'employee';
export const EMPLOYEE_EMAIL_FORM = 'employeeEmailForm';
export const EMPLOYEE_ATTENDANCE_FORM = 'employeeAttendance';
export const EMPLOYEE_ATTENDANCE_CSV_FORM = 'downloadAttendanceCsvForm';
export const EMPLOYEE_PAY_FORM = 'employeePay';
export const CHANGE_PAYMENT_DATE_MODAL_FORM = 'ChangePaymentDateModalForm';
export const EMPLOYEE_PAY_ROLLBACK_FORM = 'employeePayRollBack';
export const EMPLOYEE_PAY_ALL_ROLLBACK_FORM = 'employeePayAllRollback';
export const EMPLOYEE_PAY_CSV_FORM = 'employeePayCsv';
export const PAYS_SEARCH_FORM = 'paysSearchForm';
export const EMPLOYEE_PERSONNEL_HISTORY_FORM = 'employeePersonnelHistoryForm';
export const EMPLOYMENT_TYPE_FORM = 'employmentType';
export const POSITION_FORM = 'position';
export const OCCUPATION_FORM = 'occupation';
export const GROUP_CSV_DOWNLOAD_MODAL_FORM = 'groupCsvDownloadModalForm';
export const GROUP_CSV_IMPORT_FORM = 'groupCsvImportForm';
export const CLIENT_COMMUTING_FEE_SETTING_FORM = 'clientCommutingFeeSetting';
export const LOGIN_FORM = 'login';
export const CLIENT_TAX_CAL_METHOD_SETTING_FORM = 'clientTaxCalMethodSettingForm';
export const EMPLOYEE_ATTENDANCE_CSV_UPLOAD_FORM = 'employeeAttendanceCsvUploadForm';
export const CLIENT_ALLOWANCE_FORM = 'clientAllowanceForm';
export const CLIENT_UNIT_PRICE_FORM = 'clientUnitPriceForm';
export const INHABITANT_TAX_FB_DATA_FORM = 'inhabitantTaxFBDataForm';
export const PAYROLL_FB_DATA_FORM = 'payrollFBDataForm';
export const RESIDENT_TAX_PAYMENT_ADDRS_FORM = 'residentTaxPaymentAddrsForm';
export const RESIDENT_TAX_PAYMENT_CSV_IMPORT_FORM = 'residentTaxPaymentCsvImportForm';
export const CLIENT_BONUS_FORM = 'clientBonusForm';
export const EMPLOYEE_BONUS_FORM = 'employeeBonusForm';
export const EMPLOYEE_BONUS_ROLLBACK_FORM = 'employeeBonusRollbackForm';
export const MEMO_FORM = 'memoForm';
export const CLIENT_BONUS_ALLOWANCE_FORM = 'clientBonusAllowanceForm';
export const CONFIRM_PAYROLL_GROUP = 'confirmPayrollGroup';
export const UNCONFIRM_PAYROLL_GROUP = 'unconfirmPayrollGroup';
export const DOWNLOAD_BONUS_FB_FORM = 'downloadBonusFBForm';
export const MONTHLY_REVISION_FORM = 'standardMonthlyRevision';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_FORM = 'standardMonthlyRevision';
export const CONFIRM_MONTHLY_REVISION_FORM = 'confirmMonthlyRevisionForm';
export const CONFIRM_NOTIFICATION_OF_BASE_AMOUNT_REVISION_FORM = 'confirmNotificationOfBaseAmountRevisionForm';
export const NOTIFICATION_OF_BASE_AMOUNT_REVISION_CSV_FORM = 'notificationOfBaseAmountRevisionCsvForm';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_FORM = 'reportLaborInsuranceEmployeeDetailForm';
export const REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_CSV_FORM = 'reportLaborInsuranceEmployeeDetailCsvForm';
export const REPORT_LABOR_INSURANCE_SUMMARY_FORM = 'reportLaborInsuranceSummaryForm';
export const REPORT_LABOR_INSURANCE_DECLARATION_FORM = 'reportLaborInsuranceDeclarationForm';
export const REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM =
  'reportLaborInsuranceDeclarationInsuranceRateForm';
export const REPORT_LABOR_INSURANCE_DECLARATION_RECALCULATION_FORM = 'reportLaborInsuranceDeclarationRecalculationForm';
export const CONFIRM_REAGGREGATE_SUMMARY_FORM = 'confirmReaggregateSummaryForm';
export const CONFIRM_EMPLOYEE_DETAIL_ROLLBACK_FORM = 'confirmEmployeeDetailRollbackForm';
export const REPORT_WITHHOLDING_SLIP_CONFIRM_FORM = 'reportWithholdingSlipForm';
export const EMPLOYEE_SEARCH_FORM = 'employeeSearchForm';
export const ROLE_FORM = 'roleForm';
export const ROLE_USER_FORM = 'roleUserForm';
export const INVITE_ACCOUNT_FORM = 'inviteAccountForm';

export const CONFIRM_CLIENT_BONUS = 'confirmClientBonus';
export const UNCONFIRM_CLIENT_BONUS = 'unconfirmClientBonus';
export const EMPLOYEE_BONUS_CSV_FORM = 'employeeBonusCsvForm';
export const BONUSES_SEARCH_FORM = 'bonusesSearchForm';
export const EDITABLE_LIST_FIELDS_NAME = 'fields';
export const USER_PROFILE_FORM = 'userProfileForm';
export const USER_ACCOUNT_EMAIL_FORM = 'userAccountEmailForm';
export const USER_ACCOUNT_PASSWORD_FORM = 'userAccountPasswordForm';
export const RESET_PASSWORD_FORM = 'resetPasswordForm';

export const REPORT_CSV_DOWNLOAD_MODAL_FORM = 'reportCsvDownloadModalForm';
export const REPORT_HEALTH_INSURANCE_BONUS_FORM = 'reportHealthInsuranceBonusForm';
export const REPORT_EDIT_HEALTH_INSURANCE_BONUS_FORM = 'reportEditHealthInsuranceBonusItemForm';

export const CLIENT_ALLOWANCE_ITEM_FORM = 'clientAllowanceItemForm';
export const CLIENT_ALLOWANCES_DEDUCTIONS_EXPORT_FORM = 'clientAllowancesDeductionsExportForm';
export const CLIENT_CSV_FORMAT_FORM = 'clientCsvFormatForm';
export const PROCEDURE_FORM = 'procedureForm';
export const SYNC_JBC_SETTING_FORM = 'syncJbcSettingForm';
export const WAGE_LEDGER_EXPORT_ITEM_FORM = 'wageLedgerExportItemForm';
export const POLICY_FORM = 'policyForm';
export const EXPORT_TO_ROUMU = 'exportToRoumu';
export const IMPORT_FROM_ROUMU = 'importFromRoumu';
export const SETTING_PAYMENT_DATE = 'SettingPaymentDateRdxForm';
export const CHANGE_BONUS_RELEASE_DATE_FORM = 'ChangeBonusReleaseDateForm';
export const CHANGE_PAY_RELEASE_DATE_FORM = 'ChangePayReleaseDateForm';
export const ALLOWED_IP_FORM = 'AllowedIpForm';
export const BONUS_REPORTS_CREATE_FORM = 'BonusReportsCreateForm';
export const BONUS_REPORTS_DOWNLOAD_FORM = 'BonusReportsDownloadForm';
export const PAY_REPORTS_CREATE_FORM = 'PayReportsCreateForm';
export const PAY_REPORTS_DOWNLOAD_FORM = 'PayReportsDownloadForm';
export const INSERT_BATCH_MY_NUMBER_CSV_FORM = 'InsertBatchMyNumberCsvForm';
export const IMPORT_MONTHLY_REVISIONS_FORM = 'ImportMonthlyRevisionsForm';
export const IMPORT_REVISIONS_FORM = 'ImportRevisionsForm';
export const INCOME_TAX_COLLECTION_DATA_FORM = 'IncomeTaxCollectionDataForm';
export const CLIENT_ELECTRONIC_DELIVERY_AGREEMENT_SETTING_FORM = 'ClientElectronicDeliveryAgreementSettingForm';
export const PROFILE_FORM = 'ProfileForm';
export const UPDATE_SUBJECT_MONTHLY_REVISIONS_FORM = 'UpdateSubjectMonthlyRevisionsForm';
export const OFFICE_STATION_MODAL_FORM = 'OfficeStationModalForm';

export const REPORT_EGOV_FORM = 'ReportEgovForm';

export const FLAT_TAX_REDUCTION_CSV_UPLOAD_FORM = 'FlatTaxReductionCsvUploadForm';
