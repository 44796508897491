/* eslint-disable no-underscore-dangle */

import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';

import rootReducer from 'src/reducers';
import { INITIAL_STATE as SelectOptionsInitialState } from 'src/reducers/selectOptions';
import searchForm from 'src/middlewares/searchForm';

export default (preloadedState, history) => {
  const middleware = [];
  middleware.push(routerMiddleware(history));
  middleware.push(thunk);
  middleware.push(searchForm);

  const composeEnhancers =
    (process.env.NODE_ENV === 'development' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        trace: true,
        traceLimit: 25
      })) ||
    compose;
  const enhancer = composeEnhancers(applyMiddleware(...middleware));

  const initialState = Object.assign({}, preloadedState, {});
  initialState.selectOptions = { ...initialState.selectOptions, ...SelectOptionsInitialState };

  if ((initialState.currentUser || {}).email) {
    initialState.currentUser = { isAuthorized: true, ...initialState.currentUser };
  } else {
    initialState.currentUser = { isAuthorized: false };
  }

  return createStore(rootReducer(history), initialState, enhancer);
};
