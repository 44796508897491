import React from 'react';

import classNames from 'classnames';
import Button from 'jbc-front/components/Button';

import SearchDetector from 'src/components/SearchDetector';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import LoadingView from 'src/components/LoadingView';
import { INSERT_BATCH_MY_NUMBER_CSV } from 'src/constants/EndpointUrls';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';

import NotifyJob from './NotifyJob';
import MyNumberSearchForm from '../containers/MyNumberSearchFormContainer';
import EmployeeTable from '../containers/EmployeeTableContainer';
import styles from './MyNumberList.scss';

export default class MyNumberList extends React.Component {
  componentDidMount() {
    this.pollGetLastCsvJobStatus(INITIAL_POLLING_INTERVAL);
  }

  pollGetLastCsvJobStatus = interval => {
    const { getLastCsvJobStatus, anyJobRunning, notNotifiedJob } = this.props;
    let newInterval = interval;
    if (anyJobRunning.length > 0) {
      getLastCsvJobStatus(notNotifiedJob);
      if (newInterval < MAX_POLLING_INTERVAL) {
        newInterval *= 2;
      }
    }
    setTimeout(this.pollUpdatePayConfirmationStatus, newInterval, newInterval);
  };

  render() {
    const {
      initialValues: formValues,
      location,
      resetValues,
      isListLoading,
      formName,
      reducerName,
      searchEmployees,
      notNotifiedJob
    } = this.props;

    return (
      <div>
        <SearchDetector
          location={location}
          didMountDetectingJudger={() => true}
          willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
          onDetect={() => searchEmployees(formValues)}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">マイナンバー一覧</h1>
          <p>
            ジョブカン労務HRをご利用の場合、登録されたマイナンバーや取得状況は<br />
            スタッフコードをもとにジョブカン労務HRにも自動反映されます。
          </p>
        </div>
        {notNotifiedJob && <NotifyJob job={notNotifiedJob} />}
        <div className="l-contents-wrap">
          <div className="l-wrap-xl">
            <div className={styles.buttonArea}>
              <div className={styles.button}>
                <Button as="a" href={INSERT_BATCH_MY_NUMBER_CSV} primary widthAuto>
                  マイナンバー一括登録
                </Button>
              </div>
            </div>
            <MyNumberSearchForm formName={formName} resetValues={resetValues} />
          </div>
          <LoadingView isLoading={isListLoading}>
            <div className={classNames('l-wrap-xl', styles.list)}>
              <EmployeeTable />
              <SearchFormPaginator formName={formName} reducerName={reducerName} />
            </div>
          </LoadingView>
        </div>
      </div>
    );
  }
}
