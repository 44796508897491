import React from 'react';

import ReactSelect from 'react-select';
import { arrowRenderer } from 'jbc-front/components/Form';

import styles from './SearchReactSelect.scss';

const SearchReactSelect = ({ width, size, ...rest }) => (
  <ReactSelect
    style={{ width }}
    className={size === 'large' ? styles.large : styles.default}
    arrowRenderer={arrowRenderer}
    searchable={false}
    clearable={false}
    placeholder="選択してください"
    {...rest}
  />
);

export default SearchReactSelect;
