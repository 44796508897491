import React from 'react';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import moment from 'moment';
import _ from 'lodash';

import { Upload, Pulldown, Link } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';

import * as Urls from 'src/constants/EndpointUrls';
import { redirectTo } from 'src/utils/Http';
import {
  REDUCER_NAME,
  syncAttendanceWithJbc,
  getImportJobs,
  getClientSyncTasks
} from 'src/reducers/employees/employeeAttendances';
import {
  takeAttendancesQueriedUrl,
  takePayConfirmedStateFromSearchForm,
  takePaymentRangeFormSearchForm,
  FORM_NAME_ATTENDANCES_SEARCH
} from 'src/reducers/searchForm';
import { getExtras } from 'src/reducers/common/listReducer';
import Balloon from 'src/features/Employees/components/Balloon';
import { isFreePlan } from 'src/reducers/clients/currentClient';
import FreePlanRestriction from 'src/components/FreePlanRestriction';

import SyncWithJbcModal from './SyncWithJbcModal';
import styles from './ActionSection.scss';

class ActionSection extends React.Component {
  state = {
    isOpen: false,
    syncing: false,
    isBaloonOpen: false
  };

  closeModal = () => this.setState({ ...this.state, isOpen: false });
  openModal = () => this.setState({ ...this.state, isOpen: true });
  syncStart = () => this.setState({ ...this.state, syncing: true });
  setBaloonOpen = isBaloonOpen => this.setState({ ...this.state, isBaloonOpen });
  callBackSyncDone = () => this.setState({ ...this.state, syncing: false });

  render() {
    const {
      downloadTemplateCsvPageUrl,
      isPayConfirmed,
      syncWithJbc,
      extras = {},
      payrollRulesGroups = {},
      importJobs = {},
      clientSyncTasks = {},
      planType
    } = this.props;
    const { syncTaskSettings = {}, ...targetPeriod } = extras;
    const { attendanceStartDate, attendanceEndDate, paymentDate, endDate } = targetPeriod;
    const isInvalidAttendancePeriod = !attendanceStartDate || !attendanceEndDate;

    let targetPeriodText;
    if (paymentDate && endDate) {
      const paymentDateText = moment(paymentDate).format('YYYY年MM月DD日支給');
      const closingDateText = moment(endDate).format('MM月DD日締め');
      targetPeriodText = `${paymentDateText}(${closingDateText})`;
    }

    const dataSync = {
      ...targetPeriod,
      ...payrollRulesGroups
    };

    const isSynchronousable = !!(syncTaskSettings.jbcKintai || []).find(
      item => item.jbcCsvFormatName && item.jbcCsvFormatId
    );
    const hasInProgressImportJob = !_.isEmpty(importJobs.inProgress) || !_.isEmpty(importJobs.waiting);
    const hasInProgressSyncJob = !_.isEmpty(clientSyncTasks.inProgress);
    const hasRunningAttendanceJob = hasInProgressImportJob || hasInProgressSyncJob;
    const syncMenuDisabled =
      isPayConfirmed || isInvalidAttendancePeriod || this.state.syncing || hasRunningAttendanceJob;
    const importMenuDisabled = isPayConfirmed || hasRunningAttendanceJob;

    let disabledReason = hasRunningAttendanceJob ? '勤怠情報を更新中なので編集できません。' : null;
    if (isFreePlan(planType)) {
      disabledReason = <FreePlanRestriction />;
    }

    return (
      <div className={styles.actionSection}>
        <div className={styles.button}>
          <Button
            onClick={() => this.setBaloonOpen(!this.state.isBaloonOpen)}
            className="ignore-react-onclickoutside"
            icon={<Link size={16} />}
            widthAuto
            disabled={hasRunningAttendanceJob || isFreePlan(planType)}
            disabledReason={disabledReason}
          >
            ジョブカン勤怠管理と連携
          </Button>
          {this.state.isBaloonOpen && (
            <Balloon hide={() => this.setBaloonOpen(false)}>
              <ul className={styles.links}>
                <li>
                  <a onClick={() => redirectTo(Urls.SETTING_EMPLOYEES_SYNC_ATTENDANCE_SHOW)} role="presentation">
                    <Pulldown size={8} />従業員の勤怠連携設定
                  </a>
                </li>
                <li>
                  {!isPayConfirmed && !isSynchronousable ? (
                    <div className={styles.rel}>
                      <div className={styles.note}>「設定＞サービス連携」から設定を行ってください。</div>
                      <div className={styles.noteShape} />
                      <a disabled className={styles.disabled} role="presentation">
                        <Pulldown size={8} />ジョブカン勤怠管理から勤怠データ取得
                      </a>
                    </div>
                  ) : (
                    <a
                      className={`u-mr10 ${syncMenuDisabled ? styles.disabled : null}`}
                      disabled={syncMenuDisabled}
                      onClick={this.openModal}
                      role="presentation"
                    >
                      <Pulldown size={8} />ジョブカン勤怠管理から勤怠データ取得
                    </a>
                  )}
                </li>
              </ul>
            </Balloon>
          )}
        </div>

        <Button
          primary
          onClick={() => redirectTo(downloadTemplateCsvPageUrl)}
          icon={<Upload />}
          disabled={importMenuDisabled}
          disabledReason={hasRunningAttendanceJob ? '勤怠情報を更新中なので編集できません。' : null}
        >
          勤怠項目CSVアップロード
        </Button>
        <SyncWithJbcModal
          isOpen={this.state.isOpen}
          targetPeriodText={targetPeriodText}
          handleHide={this.closeModal}
          handleSync={() => {
            this.syncStart();
            syncWithJbc(dataSync, this.callBackSyncDone);
            this.closeModal();
          }}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    downloadTemplateCsvPageUrl: takeAttendancesQueriedUrl(
      Urls.EMPLOYEE_ATTENDANCE_DOWNLOAD_CSV_PAGE,
      getFormValues(FORM_NAME_ATTENDANCES_SEARCH)(state)
    ),
    isPayConfirmed: takePayConfirmedStateFromSearchForm(state, FORM_NAME_ATTENDANCES_SEARCH),
    payrollRulesGroups: takePaymentRangeFormSearchForm(state, FORM_NAME_ATTENDANCES_SEARCH),
    extras: getExtras(REDUCER_NAME, state) || {},
    importJobs: getImportJobs(state) || {},
    clientSyncTasks: getClientSyncTasks(state) || {},
    planType: _.get(state, 'currentClient.item.data.planType')
  }),
  dispatch => ({
    syncWithJbc: (attendanceStartDate, attendanceEndDate, callBackSyncDone) =>
      dispatch(syncAttendanceWithJbc(attendanceStartDate, attendanceEndDate, callBackSyncDone))
  })
)(ActionSection);
