import React, { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { EMPLOYEE_ATTENDANCE_LIST, SETTING_EMPLOYEES_SYNC_ATTENDANCE_UPDATE } from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { ActionButton } from 'jbc-front/components/ActionButton';
import { Strage } from 'jbc-front/components/icons';
import SearchDetector from 'src/components/SearchDetector';
import DetailSearchForm from 'src/components/DetailSearchForm';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import { REDUCER_NAME, fetchEmployees } from 'src/reducers/employees/employees';
import { takeEmployeesSearchQueries, FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import FilterEnrollmentStatusBtnList from 'src/features/Employees/components/EnrollmentStatusBtnList';
import { initSelectedEmployeeIds } from 'src/reducers/settings/coordinations';
import { ConfirmBtn } from 'src/buttons';
import axios from 'axios';

import EmployeeTable from './EmployeeTable';
import styles from './SettingEmployeeSync.scss';
import ChangedContext from '../ChangedContext';

const SettingEmployeeSync = props => {
  const { initialValues: formValues, location, resetValues, dispatch, title } = props;

  const [changed, setChanged] = useState({});
  const [changedAll, setChangedAll] = useState(null);

  const change = changes => {
    setChanged({ ...changed, ...changes });
  };

  const isSelected = employee => {
    if (changed[employee.id] !== undefined) {
      return changed[employee.id];
    }
    if (changedAll !== null) {
      return changedAll;
    }
    return employee.isSyncAttendance;
  };

  const changeAll = value => {
    setChanged({});
    setChangedAll(value);
  };

  const saveTargets = searchParams =>
    axios
      .put(SETTING_EMPLOYEES_SYNC_ATTENDANCE_UPDATE, {
        selected: changed,
        selected_all: changedAll,
        searchParams
      })
      .then(() => {
        window.location = EMPLOYEE_ATTENDANCE_LIST;
      });

  return (
    <div>
      <ChangedContext.Provider value={{ change, isSelected, changeAll }}>
        <SearchDetector
          location={location}
          didMountDetectingJudger={() => true}
          willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
          onDetect={() => {
            dispatch(fetchEmployees(takeEmployeesSearchQueries(formValues), initSelectedEmployeeIds));
          }}
        />

        <div className="l-main-title-wrap">
          <h1 className="m-title-main">{title}</h1>
        </div>

        <div className={styles.nameWrap}>
          <p>
            ジョブカン勤怠管理と連携する従業員を設定します。
            <br />
            設定しない場合、ジョブカン勤怠管理からの勤怠情報は入力されません。
          </p>
        </div>

        <div className="l-contents-wrap">
          <div className="l-wrap-xl">
            <div className="u-mb20">
              <BreadCrumb>
                <BreadCrumb.Item label="勤怠一覧" path={EMPLOYEE_ATTENDANCE_LIST} />
                <BreadCrumb.Item label={title} />
              </BreadCrumb>
            </div>

            <DetailSearchForm
              menuRender={() => <FilterEnrollmentStatusBtnList />}
              containsOtherSearch={false}
              formName={FORM_NAME_EMPLOYEES_SEARCH}
              resetValues={resetValues}
            />
          </div>
          <div className="l-wrap-xl">
            <ConfirmBtn
              widthAuto
              text="対象者を保存"
              confirmText="チェックされた対象者を保存してもよろしいですか"
              handleConfirm={() => {
                saveTargets(takeEmployeesSearchQueries(formValues));
              }}
              as={ActionButton}
              icon={<Strage size={16} />}
            >
              対象者を保存
            </ConfirmBtn>
          </div>
          <div className={classNames('l-wrap-xl', styles.list)}>
            <EmployeeTable />
            <SearchFormPaginator formName={FORM_NAME_EMPLOYEES_SEARCH} reducerName={REDUCER_NAME} />
          </div>
        </div>
      </ChangedContext.Provider>
    </div>
  );
};

export default connect()(SettingEmployeeSync);
