import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { ErrorsSection, LoadingWithDescription } from 'src/components';

import { FormSubmitBtn } from 'src/buttons';
import { CONFIRM_MONTHLY_REVISION_FORM } from 'src/constants/FormNames';
import {
  changeMonthlyRevisionConfirm,
  getConfirmRevisionErrors,
  getTargetRevisions
} from 'src/reducers/reports/monthlyRevisions';
import styles from './ConfirmRevisionModal.scss';

const ConfirmRevisionInsideForm = ({
  handleSubmit,
  submitting,
  submit,
  cancelBtnClick,
  errors,
  header,
  revisions,
  extras
}) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        {submitting && <LoadingWithDescription text="確定中です。少々お待ちください。" />}
        <div>
          <table className="m-table-item">
            <thead>
              <tr>
                <th>スタッフコード</th>
                <th>氏名</th>
                <th>現在</th>
                <th>{extras.changeStandardMonthlyDate}〜</th>
              </tr>
            </thead>
            <tbody>
              {revisions &&
                revisions.map(
                  revision =>
                    (revision.isSubject || revision.isPensionInsuranceSubject) && (
                      <tr key={`revision_tr_${revision.id}`}>
                        <td>{revision.staffCode}</td>
                        <td>{`${revision.lastName} ${revision.firstName}`}</td>
                        <td>{revision.beforeMonthlyCompensationHealthInsuranceAmount}</td>
                        <td>{revision.afterMonthlyCompensationHealthInsuranceAmount}</td>
                      </tr>
                    )
                )}
            </tbody>
          </table>
        </div>
        <ul className={styles.notes}>
          <li>
            ※ {extras.changeStandardMonthlyDate}分以降の標準報酬月額が、上記のように改定されます。本当によろしいですか？
          </li>
        </ul>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="確定" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let ConfirmRevisionForm = reduxForm({
  form: CONFIRM_MONTHLY_REVISION_FORM
})(ConfirmRevisionInsideForm);
const mapStateToProp = state => ({
  errors: getConfirmRevisionErrors(state)
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  onSubmit: () => dispatch(changeMonthlyRevisionConfirm(ownProps, ownProps.cancelBtnClick))
});

ConfirmRevisionForm = connect(mapStateToProp, mapDispatchToProp)(ConfirmRevisionForm);

class ConfirmRevisionModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const header = '標準報酬月額改定';
    const { revisions, isOpen, hideModal, yearMonth, extras } = this.props;

    return (
      <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, yearMonth }}>
        <ConfirmRevisionForm
          cancelBtnClick={hideModal}
          header={header}
          yearMonth={yearMonth}
          extras={extras}
          revisions={revisions}
        />
      </CommonModal>
    );
  }
}

export default connect(state => ({
  revisions: getTargetRevisions(state)
}))(ConfirmRevisionModal);
