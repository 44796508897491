import React from 'react';

import classNames from 'classnames';

import * as CssClassNames from 'src/constants/CssClassNames';

const FCTextWrapper = ({ type, input, maxLength = null, meta: { touched, error, invalid }, onChange, onBlur }) => (
  <div
    className={classNames({
      [CssClassNames.FORM_CONTROL_WRAPPER]: true,
      [CssClassNames.HAS_ERROR]: touched && invalid
    })}
  >
    <input
      {...input}
      type={type}
      maxLength={maxLength}
      className={CssClassNames.FORM_CONTROL}
      onChange={e => {
        input.onChange(e);
        if (onChange) {
          onChange(e.target.value);
        }
      }}
      onBlur={e => {
        input.onBlur(e);
        if (onBlur) {
          onBlur(e.target.value);
        }
      }}
    />
    {touched && (error && <div className={CssClassNames.ERROR}>{error}</div>)}
  </div>
);

export default FCTextWrapper;
