import React from 'react';
import { numberWithComma } from 'src/utils/Utils';
import style from './PayValueDiff.scss';

const PayValueDiff = ({ diff }) => {
  if (diff === undefined) {
    return null;
  }
  const diffStyle = diff > 0 ? style.increase : diff < 0 ? style.decrease : style.unchange;

  return (
    <div className={diffStyle}>{diff > 0 ? `+${numberWithComma(diff)}` : diff < 0 ? numberWithComma(diff) : '±0'}</div>
  );
};

export default PayValueDiff;
