import axios from 'axios';
import { USER_PROFILE_UPDATE_ELECTTONIC_DELIVERY_AGREEMENT } from 'src/constants/EndpointUrls';
import { redirectTo } from 'src/utils/Http';
import { startSubmit, change as formChange } from 'redux-form';
import { PROFILE_FORM } from 'src/constants/FormNames';

export const updateElectronicDeliveryAgreement = redirectPath => async dispatch => {
  try {
    dispatch(startSubmit(PROFILE_FORM));
    await axios.put(USER_PROFILE_UPDATE_ELECTTONIC_DELIVERY_AGREEMENT);
    // dispatch(stopSubmit(PROFILE_FORM));はしない。redirectまでの一瞬ボタンが押せるようになるから。
    redirectTo(redirectPath);
  } catch (e) {
    dispatch(formChange(PROFILE_FORM, 'errors', e.response.data.errors.messages));
  }
};

// lintでPrefer default exportに引っかかるので追加
export default function() {}
