import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, blur, touch } from 'redux-form';

import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { TextField } from 'jbc-front/components/Form';

import { ErrorsSection, LoadingWithDescription } from 'src/components';
import { isInsuranceRateFormat } from 'src/utils/CustomValidators';
import { toInsuranceRateFormat } from 'src/utils/Completion';
import { FormSubmitBtn } from 'src/buttons';
import { REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM } from 'src/constants/FormNames';
import { getItem, getItemExtra } from 'src/reducers/common/itemReducer';
import {
  REDUCER_NAME,
  updateLaborInsuranceRates,
  clearLaborInsuranceRateErrors
} from 'src/reducers/reports/reportLaborInsurances';
import styles from './LaborInsuranceRateModal.scss';

const LaborInsuranceRateInsideForm = ({
  dispatch,
  handleSubmit,
  submitting,
  submit,
  cancelBtnClick,
  errors,
  header,
  is2021,
  is2022
}) => (
  <React.Fragment>
    <CommonModal.Header hideModal={cancelBtnClick}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form onSubmit={handleSubmit}>
        <ErrorsSection errors={errors} />
        {submitting && <LoadingWithDescription text="保存中です。少々お待ちください。" />}
        <div>
          <p className={styles.lead}>年度更新の申告書に記載されている保険料率を入力してください。</p>
          <div className="l-overflow-scroll">
            <table className={styles.table}>
              <tbody>
                {!is2022 && (
                  <React.Fragment>
                    <tr>
                      <th>確定労災保険料率</th>
                      <td>
                        <div className={styles.wrap}>
                          <div className={styles.text}>1000分の</div>
                          <TextField
                            required
                            type="text"
                            name="confirmedAccidentInsuranceRate"
                            noLabel
                            label="確定労災保険料率"
                            maxLength="7"
                            validate={[isInsuranceRateFormat]}
                            onBlur={(e, newValue, previousValue) => {
                              e.preventDefault();
                              dispatch(
                                blur(
                                  `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                                  `confirmedAccidentInsuranceRate`,
                                  toInsuranceRateFormat(previousValue)
                                )
                              );
                              dispatch(
                                touch(
                                  `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                                  `confirmedAccidentInsuranceRate`
                                )
                              );
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>確定雇用保険料率</th>
                      <td>
                        <div className={styles.wrap}>
                          <div className={styles.text}>1000分の</div>
                          <TextField
                            required
                            type="text"
                            name="confirmedEmploymentInsuranceRate"
                            noLabel
                            label="確定雇用保険料率"
                            maxLength="7"
                            validate={[isInsuranceRateFormat]}
                            onBlur={(e, newValue, previousValue) => {
                              e.preventDefault();
                              dispatch(
                                blur(
                                  `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                                  `confirmedEmploymentInsuranceRate`,
                                  toInsuranceRateFormat(previousValue)
                                )
                              );
                              dispatch(
                                touch(
                                  `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                                  `confirmedEmploymentInsuranceRate`
                                )
                              );
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                )}
                <tr>
                  <th>概算・増加概算労災保険料率</th>
                  <td>
                    <div className={styles.wrap}>
                      <div className={styles.text}>1000分の</div>
                      <TextField
                        required
                        type="text"
                        name="estimateAccidentInsuranceRate"
                        noLabel
                        label="概算・増加概算労災保険料率"
                        maxLength="7"
                        validate={[isInsuranceRateFormat]}
                        onBlur={(e, newValue, previousValue) => {
                          e.preventDefault();
                          dispatch(
                            blur(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                              `estimateAccidentInsuranceRate`,
                              toInsuranceRateFormat(previousValue)
                            )
                          );
                          dispatch(
                            touch(
                              `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                              `estimateAccidentInsuranceRate`
                            )
                          );
                        }}
                      />
                    </div>
                  </td>
                </tr>
                {!is2021 && (
                  <tr>
                    <th>概算・増加概算雇用保険料率</th>
                    <td>
                      <div className={styles.wrap}>
                        <div className={styles.text}>1000分の</div>
                        <TextField
                          required
                          type="text"
                          name="estimateEmploymentInsuranceRate"
                          noLabel
                          label="概算・増加概算雇用保険料率"
                          maxLength="7"
                          validate={[isInsuranceRateFormat]}
                          onBlur={(e, newValue, previousValue) => {
                            e.preventDefault();
                            dispatch(
                              blur(
                                `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                                `estimateEmploymentInsuranceRate`,
                                toInsuranceRateFormat(previousValue)
                              )
                            );
                            dispatch(
                              touch(
                                `${REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM}`,
                                `estimateEmploymentInsuranceRate`
                              )
                            );
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={cancelBtnClick} className="u-mr20">
        キャンセル
      </Button>
      <FormSubmitBtn text="保存" {...{ submitting, submit }} />
    </CommonModal.Footer>
  </React.Fragment>
);

let LaborInsuranceRateForm = reduxForm({
  form: REPORT_LABOR_INSURANCE_DECLARATION_INSURANCE_RATE_FORM
})(LaborInsuranceRateInsideForm);
const mapStateToProp = state => ({
  initialValues: {
    confirmedAccidentInsuranceRate: getItem(REDUCER_NAME, state).confirmedInsuranceFeeRateForAccidentInsurance,
    confirmedEmploymentInsuranceRate: getItem(REDUCER_NAME, state)
      .confirmedInsuranceFeeRateForCalculationTargetOfEmploymentInsurance,
    estimateAccidentInsuranceRate: getItem(REDUCER_NAME, state).estimateInsuranceFeeRateForAccidentInsurance,
    estimateEmploymentInsuranceRate: getItem(REDUCER_NAME, state)
      .estimateInsuranceFeeRateForCalculationTargetOfEmploymentInsurance
  },
  errors: getItemExtra(REDUCER_NAME, state).updateLaborInsuranceRateErrors || []
});
const mapDispatchToProp = (dispatch, ownProps) => ({
  clearErrors: dispatch(clearLaborInsuranceRateErrors()),
  onSubmit: () => dispatch(updateLaborInsuranceRates(ownProps))
});

LaborInsuranceRateForm = connect(mapStateToProp, mapDispatchToProp)(LaborInsuranceRateForm);

class LaborInsuranceRateModal extends React.Component {
  render() {
    const header = '労災保険・雇用保険料率の入力';
    const {
      isOpen,
      hideModal,
      laborInsuranceYear,
      applicableOffice,
      confirmedAccidentInsuranceRate,
      confirmedEmploymentInsuranceRate,
      estimateAccidentInsuranceRate,
      estimateEmploymentInsuranceRate,
      allCalculations,
      is2021,
      is2022
    } = this.props;
    return (
      <CommonModal ariaHideApp={false} {...{ isOpen, hideModal, laborInsuranceYear, applicableOffice }}>
        <LaborInsuranceRateForm
          cancelBtnClick={hideModal}
          header={header}
          laborInsuranceYear={laborInsuranceYear}
          applicableOffice={applicableOffice}
          confirmedAccidentInsuranceRate={confirmedAccidentInsuranceRate}
          confirmedEmploymentInsuranceRate={confirmedEmploymentInsuranceRate}
          estimateAccidentInsuranceRate={estimateAccidentInsuranceRate}
          estimateEmploymentInsuranceRate={estimateEmploymentInsuranceRate}
          allCalculations={allCalculations}
          is2021={is2021}
          is2022={is2022}
        />
      </CommonModal>
    );
  }
}

export default LaborInsuranceRateModal;
