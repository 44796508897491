import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { formatDateWithHour, formatDate } from 'src/utils/Date';
import { takeBonusFromSearchForm } from 'src/reducers/searchForm';
import ChangeBonusReleaseDateForm from './ChangeBonusReleaseDateForm';
import styles from './BonusTargetDateRange.scss';

class BonusTargetDateRange extends React.Component {
  state = {
    changeReleaseDateModalOpen: false
  };

  hideModal = () => this.setState({ ...this.state, changeReleaseDateModalOpen: false });
  showModal = () => this.setState({ ...this.state, changeReleaseDateModalOpen: true });

  render() {
    const { bonus = {} } = this.props;
    const { applyForTimeFrom, applyForTimeTo } = bonus;
    const hasApplyForTime = !_.isEmpty(applyForTimeFrom) || !_.isEmpty(applyForTimeFrom);
    const releaseDate = _.get(bonus, 'releaseDate');

    return (
      <div className={styles.container}>
        {hasApplyForTime && (
          <div>
            対象期間&nbsp;{formatDate(applyForTimeFrom)}&nbsp;〜&nbsp;{formatDate(applyForTimeTo)}
          </div>
        )}
        <div className={styles.paymentDate}>支給日&nbsp;{formatDate(_.get(bonus, 'payOn'))}</div>
        {_.get(bonus, 'isConfirmed') && (
          <React.Fragment>
            <div className={`${styles.releaseDate} ${styles.canClick}`} onClick={this.showModal} role="presentation">
              公開日&nbsp;{formatDateWithHour(releaseDate)}
            </div>
            <ChangeBonusReleaseDateForm
              selectedClientBonus={bonus}
              isOpen={this.state.changeReleaseDateModalOpen}
              hideModal={this.hideModal}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default connect(state => ({
  bonus: takeBonusFromSearchForm(state)
}))(BonusTargetDateRange);
