import React from 'react';
import { numberWithComma } from 'src/utils/Utils';
import styles from './IncomeTaxCollectionDataList.scss';

const IncomeTaxCollectionList = ({ incomeTaxCollectionData }) => (
  <div className="l-overflow-scroll">
    <table className={styles.table}>
      <colgroup>
        <col width={130} />
        <col width={75} />
        <col width={121} />
        <col width={121} />
        <col width={75} />
        <col width={121} />
        <col width={75} />
        <col width={121} />
        <col width={121} />
      </colgroup>
      <thead>
        <tr>
          <th rowSpan="2" />
          <th rowSpan="2">人員</th>
          <th rowSpan="2">支給総額</th>
          <th rowSpan="2">算出税額</th>
          <th colSpan="2" rowSpan="1">
            年末調整による不足税額
          </th>
          <th colSpan="2" rowSpan="1">
            年末調整による超過税額
          </th>
          <th rowSpan="2">差引徴収税額</th>
        </tr>
        <tr>
          <th>人員</th>
          <th>税額</th>
          <th>人員</th>
          <th>税額</th>
        </tr>
      </thead>
      <tbody>
        {incomeTaxCollectionData.map(row => (
          <tr key={`${row.yearMonthFirst}${row.yearMonthSecound}`}>
            <td>
              <div className={styles.text}>{row.yearMonthFirst}</div>
              <div>{row.yearMonthSecound}</div>
            </td>
            <td>{numberWithComma(row.count)}</td>
            <td>{numberWithComma(row.totalAmount)}</td>
            <td>{numberWithComma(row.totalTaxAmount)}</td>
            <td>{numberWithComma(row.totalYeaAmountPlusCount)}</td>
            <td>{numberWithComma(row.totalYeaAmountPlus)}</td>
            <td>{numberWithComma(row.totalYeaAmountMinusCount)}</td>
            <td>{numberWithComma(row.totalYeaAmountMinus)}</td>
            <td>{numberWithComma(row.totalDeductedTaxAmount)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

export default IncomeTaxCollectionList;
