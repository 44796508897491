import { combineReducers } from 'redux';
import axios from 'axios';

import {
  CLIENT_RESIDENT_TAX_PAYMENTS_LIST,
  CLIENT_RESIDENT_TAX_PAYMENTS_UPDATE,
  CLIENT_RESIDENT_TAX_PAYMENTS_IMPORT_CSV
} from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { editableListReducer } from 'src/reducers/common';
import { paramsWithFile } from 'src/utils/Form';
import { scrollToTop } from 'src/utils/Utils';
import { setGlobalSuccesses, setGlobalErrors } from 'src/reducers/global';
import { redirectTo } from 'src/utils/Http';
import { getList, getListErrors, updateListFailure, setList } from 'src/reducers/common/editableListReducer';
import _ from 'lodash';

export const REDUCER_NAME = 'clientResidentTaxPaymentAddresses';

export const getListTaxPaymentAddresses = state => getList(REDUCER_NAME, state);
export const getListTaxPaymentAddressesErrors = state => getListErrors(REDUCER_NAME, state);
export const setListTaxPaymentAddresses = data => setList(REDUCER_NAME, data);

const getFormDeleteFields = (state, reducerName, fieldArray) => {
  const originFieldArray = _.get(getList(reducerName, state), 'clientResidentTaxPaymentAddresses');
  return originFieldArray.filter(originField => _.isUndefined(_.find(fieldArray, { id: originField.id })));
};

export const updateResidentTaxPaymentAddrs = data => async (dispatch, getState) => {
  try {
    const params = {
      residentTaxPaymentAddrs: data.residentTaxPaymentAddrs,
      deletings: getFormDeleteFields(getState(), REDUCER_NAME, data.residentTaxPaymentAddrs)
    };
    const response = await axios.put(CLIENT_RESIDENT_TAX_PAYMENTS_UPDATE, params);
    const newData = response.data.payload;
    (newData.clientResidentTaxPaymentAddresses[0] || {}).recentSaved = true;
    dispatch(setListTaxPaymentAddresses({ ...newData }));
  } catch (exception) {
    dispatch(updateListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(editableListReducer, REDUCER_NAME)
});

export const uploadCsv = (data = 'create') => async dispatch => {
  try {
    const params = paramsWithFile(data, ['client_resident_tax_payments_csv']);
    const response = await axios.post(CLIENT_RESIDENT_TAX_PAYMENTS_IMPORT_CSV, params);
    if (response.data.errors.length > 0) {
      dispatch(updateListFailure(REDUCER_NAME, response.data.errors));
      scrollToTop();
    } else {
      dispatch(setGlobalSuccesses('インポートが成功しました。'));
      await new Promise(r => setTimeout(r, 1000));
      redirectTo(CLIENT_RESIDENT_TAX_PAYMENTS_LIST);
    }
  } catch (e) {
    dispatch(setGlobalErrors('CSVのアップロードに失敗しました。'));
  }
};
