import _ from 'lodash';
import moment from 'moment';
import { formatDate } from 'src/utils/Date';
import { HYPHEN_CHARACTER } from 'src/constants/Generals';

export const selectOptionMonths = Array(12)
  .fill()
  .map((_ignore, i) => ({ value: i + 1, label: `${i + 1}月` }));

export const fetchValueByIndexAndField = arrayValues => fieldName => index =>
  _.get(arrayValues, [index, fieldName], undefined);

export const getOptionLabel = value => options => {
  const option = options ? options.find(op => op.value === value) || {} : {};
  return option.label;
};

export const numToStr = num =>
  num &&
  _(`${num}`)
    .toArray()
    .reverse()
    .chunk(3)
    .reverse()
    .map(arr => arr.reverse().join(''))
    .join(',');

export const sumFloatNumber = (...numbers) => fixedPointLength =>
  numbers.reduce((sum, number) => sum + parseFloat(+number), 0.0).toFixed(fixedPointLength);

export const sumFloatNumberFixed5 = (...numbers) =>
  sumFloatNumber(...numbers)(5)
    .replace(/0+$/, '')
    .replace(/\.$/, '');

export const toFloatString = number => {
  if (number === parseInt(number, 10).toFixed()) return parseFloat(number).toFixed(1);
  return number;
};

export const isIncluded = (comparingValue, beComparedValue) =>
  typeof comparingValue === 'string' && comparingValue.indexOf(beComparedValue) >= 0;
export const isEqual = (comparingValue, beComparedValue) => comparingValue === beComparedValue;

export const concatArray = (array1, array2) => booleanFunc => {
  const additions = array2.filter(a2 => booleanFunc(array1, a2));
  return [...array1, ...additions];
};

export const isTimeOverNow = dateString => dateString > new Date().toISOString();

const selector = (state, ...keys) => _.get(state, keys);
export default selector;

export const arrayToObject = (defaultValue, toTargetArray) => {
  const copyDefault = { ...defaultValue };
  if (toTargetArray) {
    Object.keys(copyDefault).forEach(key => {
      if (!toTargetArray.includes(key)) {
        copyDefault[key] = false;
      }
    });
  }
  return copyDefault;
};

export const isArr1ContainArr2 = (arr1, arr2) => arr2.every(item => arr1.indexOf(item) !== -1);

export const deleteFromObjIfNotInRange = (obj, fieldToDelete, valueRange) => {
  const copyObj = { ...obj };
  const currentValue = (obj[fieldToDelete] || '').toString().split('_');
  const allValue = valueRange ? valueRange.map(item => item.value.toString()) : [];
  if (!isArr1ContainArr2(allValue, currentValue)) {
    delete copyObj[fieldToDelete];
  }
  return copyObj;
};

export const isInTargetDateRange = (targetStartDate, targetEndDate, compareDate) => {
  if (!targetStartDate || !targetEndDate || !compareDate) {
    return false;
  }
  return targetStartDate <= compareDate && compareDate <= targetEndDate;
};

export const toJpYear = date => {
  const dateMoment = moment(date);
  if (!dateMoment.isValid()) {
    return '';
  }
  const list = [['1989-01-08', '平成'], ['1926-12-25', '昭和'], ['1912-07-30', '大正'], ['1868-01-25', '明治']];
  let yearStr = null;
  const targetYearFormat = list.find(item => dateMoment.isSameOrAfter(item[0]));
  if (targetYearFormat) {
    const year = dateMoment.year() - moment(targetYearFormat[0]).year() + 1;
    yearStr = year === 1 ? `${targetYearFormat[1]}元年` : `${targetYearFormat[1]}${year}年`;
  }
  return yearStr ? dateMoment.format('LL').replace(/\d{4}年/, yearStr) : dateMoment.format('LL');
};

export const recordDisplay = text => text || HYPHEN_CHARACTER;
export const employeeDisplay = text => text || HYPHEN_CHARACTER;

employeeDisplay.fullName = (record, withKana) => {
  if (!record || !record.lastName || !record.firstName) {
    return employeeDisplay();
  }
  return [
    `${record.lastName} ${record.firstName}`,
    withKana && record.lastNameKana && record.firstNameKana && `（${record.lastNameKana} ${record.firstNameKana}）`
  ]
    .filter(str => str)
    .join(' ');
};

employeeDisplay.fullBusinessName = record => {
  const { businessLastName, businessFirstName } = record;
  if (!record || !businessLastName || !businessFirstName) {
    return employeeDisplay();
  }
  return `${businessLastName} ${businessFirstName}`;
};

employeeDisplay.address = (record, prefectures) => {
  if (!record) {
    return employeeDisplay();
  }
  return (
    [
      record.postalCodePart0 && record.postalCodePart1 && `〒${record.postalCodePart0}-${record.postalCodePart1}\n`,
      getOptionLabel(record.prefectureId)(prefectures),
      record.city,
      record.street,
      record.building
    ]
      .filter(str => str)
      .join(' ') || employeeDisplay()
  );
};

employeeDisplay.birthday = record => {
  if (!record || !record.birthday) {
    return employeeDisplay();
  }
  return `${formatDate(record.birthday)}（${moment().diff(record.birthday, 'years')}歳）`;
};

export const getGroupFullPathName = (groups, groupId, joinChar = '->') => {
  const groupNames = [];
  let group = groups.find(item => item.value === groupId);
  const condition = id => item => item.value === id;

  while (group) {
    groupNames.unshift(group.label);
    group = groups.find(condition(group.parentDepartmentId));
  }

  return groupNames.join(joinChar);
};

export const toMoneyFormat = value => (value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}円` : '0円');
export const numberWithComma = value => (value ? `${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value);
export const arrayToCheckBoxObject = arr => {
  const checkBoxObj = {};
  _.forEach(arr, item => {
    checkBoxObj[item.id] = true;
  });
  return checkBoxObj;
};
export const checkedTrueObjToArr = obj => {
  const result = [];
  _.forEach(obj, (value, key) => {
    if (value) {
      result.push(key);
    }
  });
  return result;
};
recordDisplay.dateOrTime = dateTime => {
  if (!dateTime) {
    return recordDisplay();
  }
  if (moment().isSame(dateTime, 'day')) {
    return moment(dateTime).format('LT');
  }
  if (
    moment()
      .subtract(1, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '昨日';
  }
  if (
    moment()
      .subtract(2, 'days')
      .isSame(dateTime, 'day')
  ) {
    return '一昨日';
  }
  if (moment().isSame(dateTime, 'year')) {
    return moment(dateTime).format('MMMDo');
  }
  return moment(dateTime).format('LL');
};

// param: dateTime
// result: "MM月DD日" (currentYear's time) other: "YYYY年MM月DD日"
recordDisplay.currentOrPrevYear = dateTime => {
  if (!dateTime) {
    return recordDisplay();
  }
  if (moment().isSame(dateTime, 'year')) {
    return moment(dateTime).format('MM月DD日');
  }
  return moment(dateTime).format('LL');
};

export const convertNumberToStrOfValueInArray = arr => arr.map(item => ({ ...item, value: `${item.value}` }));

export const convertArrayIntoJbcStyle = list => list.map(item => ({ id: `${item.value}`, name: item.label }));

export const convertArrayIntoJbcStyleNameLabelV = list =>
  list.map(item => ({ name: `${item.value}`, label: item.label }));

export const display = (value, defaultIfBlank = HYPHEN_CHARACTER) => value || defaultIfBlank;

export const currency = (value, suffix = '円', defaultIfBlank = HYPHEN_CHARACTER) =>
  value && value !== HYPHEN_CHARACTER ? value + suffix : defaultIfBlank;

export const scrollToTop = () => window.scrollTo(0, 0);
export const scrollToBottom = () => window.scroll({ top: window.outerHeight });

export const csrfToken = () => document.getElementsByName('csrf-token')[0].getAttribute('content');

export const replaceMessages = (originals, befores, afters) =>
  originals.map(value => {
    let afterValue = value;
    befores.forEach((str, index) => {
      if (afters[index] !== undefined) {
        afterValue = afterValue.replace(new RegExp(str, 'g'), afters[index]);
      }
    });
    return afterValue;
  });

export const findLatestInsurances = (yearMonth, insurances) => {
  const latestYearMonth = _.sortBy(Object.keys(insurances))
    .reverse()
    .find(i => i <= yearMonth);
  return (insurances[latestYearMonth] || [])[0];
};
