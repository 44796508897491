import _ from 'lodash';
import queryString from 'query-string';
import axios from 'axios';
import { setGlobalErrors } from 'src/reducers/global';
import { createAction } from 'redux-actions';
import { getFormValues, formValueSelector, autofill } from 'redux-form';
import { push } from 'connected-react-router';
import humps from 'humps';
import { unstable_batchedUpdates } from 'react-dom'; // eslint-disable-line camelcase

import * as Urls from 'src/constants/EndpointUrls';
import { getSelectOptions, setOptions } from 'src/reducers/selectOptions';
import {
  convertQueryToFormFieldOfEmployees,
  convertFormToQueryFieldOfEmployees,
  convertQueryToFormFieldOfPaging,
  convertFormToQueryFieldOfPaging,
  convertFormToQueryFieldOfPays,
  convertFormToQueryFieldOfBonuses,
  convertFormToQueryFieldOfRevisions,
  convertFormToQueryFieldOfNotificationOfBaseAmountRevisions,
  convertFormToQueryFieldOfDetails,
  convertQueryToFormFieldOfPays,
  convertQueryToFormFieldOfBonuses,
  convertQueryToFormFieldOfRevisions,
  convertQueryToFormFieldOfNotificationOfBaseAmountRevisions,
  convertQueryToFormFieldOfDetails,
  convertQueryToFormFieldOfWageLedgers,
  convertFormToQueryFieldOfWageLedgers,
  convertQueryToFormFieldOfClientRoleUsers,
  convertFormToQueryFieldOfClientRoleUsers,
  convertQueryToFormFieldOfReportHealthInsuranceBonus,
  convertFormToQueryFieldOfReportHealthInsuranceBonus,
  convertQueryToFormFieldOfLaborInsurance,
  convertFormToQueryFieldOfSyncYea,
  convertFormToQueryFieldOfSaveStatus,
  convertFormToQueryFieldOfIsConfirmedPayroll,
  convertFormToQueryFieldOfIsConfirmed,
  DUMMY_ID_CLIENT_ROLE_ALL
} from 'src/utils/fieldConverters';
import { convertNumberToStrOfValueInArray } from 'src/utils/Utils';
import { redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { setCache } from 'src/Cache';
import { getCurrentUserInfo } from 'src/reducers/users/currentUser';
import { setYearMonthEnd } from './reports/reportWageLedger';

export const REDUCER_NAME = 'searchForm';

// Actions
export const SET_LOADING = 'SEARCH_FORM/SET_LOADING';
export const CLEAR_LOADING = 'SEARCH_FORM/CLEAR_LOADING';

// Action Creators
export const setLoading = createAction(SET_LOADING);
export const clearLoading = createAction(CLEAR_LOADING);

// Selectors
export const isLoading = (name, state) => _.get(state, `${REDUCER_NAME}.${name}.isLoading`, false);

// Async Actions
export const setLoadingWithName = name => dispatch => {
  dispatch(setLoading({ name }));
};
export const clearLoadingWithName = name => dispatch => {
  dispatch(clearLoading({ name }));
};

export const FORM_NAME_EMPLOYEES_SEARCH = 'employeesSearchForm';
export const FORM_NAME_ATTENDANCES_SEARCH = 'attendancesSearchForm';
export const FORM_NAME_PAYS_SEARCH = 'paysSearchForm';
export const FORM_NAME_BONUSES_SEARCH = 'bonusesSearchForm';
export const FORM_NAME_REVISION_MONTHS_SEARCH = 'revisionMonthSearchForm';
export const FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH = 'notificationOfBaseAmountMonthSearchForm';
export const FORM_NAME_WAGE_LEDGER_SEARCH = 'wageLedgerSearchForm';
export const FORM_NAME_ROLE_USERS_SEARCH = 'formNameRoleUsersSearch';
export const FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH = 'formNameReportHealthInsuranceBonusSearchForm';
export const FORM_NAME_FLAT_TAX_REDUCTION_SEARCH = 'employeeFlatTaxReductionSearchForm';

const DEFAULT_LIMIT = '100';
export const LIMITS = ['10', '20', '50', '100', '200'].map(ele => ({ value: ele, label: `${ele}件を表示` }));

export const employeesSearchFormSelector = formValueSelector(FORM_NAME_EMPLOYEES_SEARCH);
export const attendancesSearchFormSelector = formValueSelector(FORM_NAME_ATTENDANCES_SEARCH);
export const paysSearchFormSelector = formValueSelector(FORM_NAME_PAYS_SEARCH);
export const bonusesSearchFormSelector = formValueSelector(FORM_NAME_BONUSES_SEARCH);
export const revisionMonthsSearchFormSelector = formValueSelector(FORM_NAME_REVISION_MONTHS_SEARCH);
export const notificationOfBaseAmountMonthsSearchFormSelector = formValueSelector(
  FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH
);

export const getPageLimit = searchForm => {
  if (window.localStorage) {
    const limit = window.localStorage.getItem(`${searchForm}_PAGE_LIMIT`);
    if (limit !== null) {
      return limit;
    }
  }
  return DEFAULT_LIMIT;
};

export const cachePageLimit = (formName, limit) => {
  setCache(`${formName}_PAGE_LIMIT`, limit);
};

const convertFields = (fields, converter) =>
  Object.assign(
    {},
    ...Object.entries(fields)
      .map(([k, v]) => converter(k, v))
      .filter(_.identity)
  );

const takeQueries = (formQueries, converters) =>
  formQueries !== undefined
    ? converters.reduce(
        (accumulator, converter) => Object.assign({}, accumulator, convertFields(formQueries, converter)),
        {}
      )
    : {};

const changeLocation = (formQueries, converters, dispatch, state) => {
  const search = queryString.stringify(
    converters.reduce(
      (accumulator, converter) => Object.assign({}, accumulator, convertFields(formQueries, converter)),
      {}
    )
  );
  dispatch(push({ ...state.router.location, search }));
};

export const takeEmployeesSearchQueriesFromLocation = location => {
  const converters = [
    convertQueryToFormFieldOfEmployees,
    convertQueryToFormFieldOfPaging,
    convertQueryToFormFieldOfDetails
  ];
  return takeQueries(
    {
      ...{
        enrollment_status: '2',
        sort: 'staffCode',
        order: 'asc',
        page: '1',
        per_page: getPageLimit(FORM_NAME_EMPLOYEES_SEARCH)
      },
      ...queryString.parse(location.search)
    },
    converters
  );
};

export const takePaysSearchQueriesFromLocation = (formName, location, detailSearch = false) => {
  const converters = detailSearch
    ? [convertQueryToFormFieldOfPays, convertQueryToFormFieldOfDetails]
    : [convertQueryToFormFieldOfPays];
  return takeQueries(
    {
      ...{
        sort: 'staffCode',
        order: 'asc',
        page: '1',
        per_page: getPageLimit(formName)
      },
      ...queryString.parse(location.search)
    },
    converters
  );
};

export const takeBonusesSearchQueriesFromLocation = (location, detailSearch = false) => {
  const converters = detailSearch
    ? [convertQueryToFormFieldOfBonuses, convertQueryToFormFieldOfDetails]
    : [convertQueryToFormFieldOfBonuses];
  return takeQueries(
    {
      ...{
        sort: 'staffCode',
        order: 'asc',
        page: '1',
        per_page: getPageLimit(FORM_NAME_BONUSES_SEARCH)
      },
      ...queryString.parse(location.search)
    },
    converters
  );
};

export const takeRevisionsSearchQueriesFromLocation = location =>
  takeQueries(
    {
      ...{
        sort_type: 'staff_code',
        filter_type: 'only_targets',
        page: '1',
        per_page: getPageLimit(FORM_NAME_REVISION_MONTHS_SEARCH)
      },
      ...queryString.parse(location.search)
    },
    [convertQueryToFormFieldOfRevisions]
  );

export const takeNotificationOfBaseAmountSearchQueriesFromLocation = location => {
  const converters = [
    convertQueryToFormFieldOfNotificationOfBaseAmountRevisions,
    convertQueryToFormFieldOfEmployees,
    convertQueryToFormFieldOfPaging,
    convertQueryToFormFieldOfDetails
  ];
  return takeQueries(
    {
      ...{
        sort_type: 'staff_code',
        filter_type: 'only_targets',
        page: '1',
        per_page: getPageLimit(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)
      },
      ...queryString.parse(location.search)
    },
    converters
  );
};

export const takeClientRoleUsersSearchQueriesFromLocation = location => {
  const converters = [
    convertQueryToFormFieldOfClientRoleUsers,
    convertQueryToFormFieldOfPaging,
    convertQueryToFormFieldOfDetails
  ];
  return takeQueries(
    {
      ...{
        sort: 'staffCode',
        order: 'asc',
        page: '1',
        per_page: getPageLimit(FORM_NAME_ROLE_USERS_SEARCH),
        client_role_ids: DUMMY_ID_CLIENT_ROLE_ALL
      },
      ...queryString.parse(location.search)
    },
    converters
  );
};

export const changeEmployeesSearchLocation = (formQueries, detailsSearch = true) => (dispatch, getState) => {
  const converters = detailsSearch
    ? [convertFormToQueryFieldOfEmployees, convertFormToQueryFieldOfPaging, convertFormToQueryFieldOfDetails]
    : [convertFormToQueryFieldOfEmployees, convertFormToQueryFieldOfPaging];
  return changeLocation(formQueries, converters, dispatch, getState());
};

export const takeWageLedgersSearchQueriesFromLocation = location => {
  const converters = [convertQueryToFormFieldOfWageLedgers, convertQueryToFormFieldOfDetails];

  return takeQueries(
    {
      ...{
        sort: 'staffCode',
        order: 'asc',
        page: '1',
        per_page: getPageLimit(FORM_NAME_WAGE_LEDGER_SEARCH)
      },
      ...queryString.parse(location.search)
    },
    converters
  );
};

export const changePaysSearchLocation = (formQueries, detailsSearch = true) => (dispatch, getState) => {
  const converters = detailsSearch
    ? [convertFormToQueryFieldOfPays, convertFormToQueryFieldOfDetails]
    : [convertFormToQueryFieldOfPays];
  return changeLocation(formQueries, converters, dispatch, getState());
};

export const changeBonusesLocation = (formQueries, detailsSearch = true) => (dispatch, getState) => {
  const converters = detailsSearch
    ? [convertFormToQueryFieldOfBonuses, convertFormToQueryFieldOfDetails]
    : [convertFormToQueryFieldOfBonuses];
  return changeLocation(formQueries, converters, dispatch, getState());
};

export const changeRevisionsLocation = formQueries => (dispatch, getState) =>
  changeLocation(formQueries, [convertFormToQueryFieldOfRevisions], dispatch, getState());

export const changeNotificationOfBaseAmountRevisionsLocation = (formQueries, detailsSearch = true) => (
  dispatch,
  getState
) => {
  const converters = detailsSearch
    ? [convertFormToQueryFieldOfNotificationOfBaseAmountRevisions, convertFormToQueryFieldOfDetails]
    : [convertFormToQueryFieldOfNotificationOfBaseAmountRevisions];
  return changeLocation(formQueries, converters, dispatch, getState());
};

export const changeWageLedgersLocation = formQueries => (dispatch, getState) =>
  changeLocation(
    formQueries,
    [convertFormToQueryFieldOfWageLedgers, convertFormToQueryFieldOfDetails],
    dispatch,
    getState()
  );
export const changeClientRolesLocation = formQueries => (dispatch, getState) =>
  changeLocation(
    formQueries,
    [convertFormToQueryFieldOfClientRoleUsers, convertFormToQueryFieldOfDetails, convertFormToQueryFieldOfPaging],
    dispatch,
    getState()
  );

export const takePaysSearchQueries = formQueries =>
  takeQueries(formQueries, [convertFormToQueryFieldOfPays, convertFormToQueryFieldOfDetails]);

export const takePaysSearchQueriesWithoutDetails = formQueries =>
  takeQueries(formQueries, [convertFormToQueryFieldOfPays]);

export const takeBonusesSearchQueries = formQueries =>
  takeQueries(formQueries, [convertFormToQueryFieldOfBonuses, convertFormToQueryFieldOfDetails]);

export const takeEmployeesSearchQueries = formQueries =>
  takeQueries(formQueries, [
    convertFormToQueryFieldOfEmployees,
    convertFormToQueryFieldOfDetails,
    convertFormToQueryFieldOfPaging
  ]);

export const takeEmployeesSearchQueriesForLabor = formQueries =>
  takeQueries(formQueries, [
    convertFormToQueryFieldOfEmployees,
    convertFormToQueryFieldOfDetails,
    convertFormToQueryFieldOfPaging,
    convertQueryToFormFieldOfLaborInsurance
  ]);

export const takeLaborInsuranceEmployeeDetailQueriedUrl = (baseUri, state) => {
  const queries = takeQueries(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state), [
    convertFormToQueryFieldOfDetails,
    convertQueryToFormFieldOfLaborInsurance
  ]);
  return `${baseUri}?${serializeHttpGetParams(queries)}`;
};

export const takeEmployeesSearchQueriesForSyncYea = formQueries =>
  takeQueries(formQueries, [
    convertFormToQueryFieldOfEmployees,
    convertFormToQueryFieldOfDetails,
    convertFormToQueryFieldOfPaging,
    convertFormToQueryFieldOfSyncYea
  ]);

export const takeEmployeesSearchQueriesForSaveStatuses = formQueries =>
  takeQueries(formQueries, [
    convertFormToQueryFieldOfEmployees,
    convertFormToQueryFieldOfDetails,
    convertFormToQueryFieldOfPaging,
    convertFormToQueryFieldOfSaveStatus
  ]);

export const takeEmployeesSearchQueriesWithIsConfirmed = formQueries =>
  takeQueries(formQueries, [
    convertFormToQueryFieldOfEmployees,
    convertFormToQueryFieldOfDetails,
    convertFormToQueryFieldOfPaging,
    convertFormToQueryFieldOfIsConfirmedPayroll,
    convertFormToQueryFieldOfIsConfirmed
  ]);

export const takeRevisionsSearchQueries = formQueries => takeQueries(formQueries, [convertFormToQueryFieldOfRevisions]);

export const takeNotificationOfBaseAmountRevisionsSearchQueries = formQueries =>
  takeQueries(formQueries, [
    convertFormToQueryFieldOfNotificationOfBaseAmountRevisions,
    convertFormToQueryFieldOfDetails,
    convertFormToQueryFieldOfPaging
  ]);

export const takeWageLedgersSearchQueries = formQueries =>
  takeQueries(formQueries, [convertFormToQueryFieldOfWageLedgers, convertFormToQueryFieldOfDetails]);

export const takeClientRoleUsersSearchQueries = formQueries =>
  takeQueries(formQueries, [
    convertFormToQueryFieldOfClientRoleUsers,
    convertFormToQueryFieldOfDetails,
    convertFormToQueryFieldOfPaging
  ]);

export const takePayConfirmedStateFromSearchForm = (state, formName) => {
  const uniquePaymentDates = getSelectOptions(state, 'uniquePaymentDates');
  const currentUniquePaymentDate = formValueSelector(formName)(state, 'uniquePaymentDate');

  if (uniquePaymentDates.length < 1 || !currentUniquePaymentDate) {
    return false;
  }

  return uniquePaymentDates.some(ele => ele.value === currentUniquePaymentDate && ele.confirmed);
};

export const takePaymentRangeFormSearchForm = (state, formName) => ({
  closingDay: formValueSelector(formName)(state, 'closingDay'),
  paymentDay: formValueSelector(formName)(state, 'paymentDay'),
  paymentMonthType: formValueSelector(formName)(state, 'paymentMonthType'),
  payrollRulesGroups: formValueSelector(formName)(state, 'uniquePayrollRuleGroups'),
  yearMonth: formValueSelector(formName)(state, 'uniquePaymentDate')
});

export const takePaymentDateFromSearchForm = (state, formName) => {
  const dates = getSelectOptions(state, 'uniquePaymentDates');
  const targetDate = formValueSelector(formName)(state, 'uniquePaymentDate');

  if (dates.length < 1 || !targetDate) {
    return undefined;
  }

  return dates.find(ele => ele.value === targetDate);
};

export const takeGroupedPaymentMonthsSearchForm = (state, formName) => ({
  groupedPaymentMonths: formValueSelector(formName)(state, 'groupedPaymentMonths')
});

export const takeBonusFromSearchForm = state => {
  const bonuses = getSelectOptions(state, 'bonuses');
  const currentBonusId = bonusesSearchFormSelector(state, 'clientBonusId');

  if (bonuses.length < 1 || !currentBonusId) {
    return undefined;
  }

  return bonuses.find(ele => ele.value === currentBonusId);
};

export const takeBonusConfirmedStateFromSearchForm = state => {
  const bonus = takeBonusFromSearchForm(state);
  if (bonus !== undefined) {
    return bonus.isConfirmed;
  }
  return false;
};

export const takeAttendancesQueriedUrl = (baseUri, formQueries) => {
  const queries = takePaysSearchQueries(formQueries);
  return `${baseUri}?${serializeHttpGetParams(queries)}`;
};

export const takePaysQueriedUrl = (baseUri, formQueries, isPdf = false, extras = {}) => {
  const queries = takePaysSearchQueries(formQueries);
  Object.keys(extras).map(field => {
    queries[field] = extras[field];
    return true;
  });

  if (isPdf) {
    return `${baseUri}?${serializeHttpGetParams(queries)}&pdf%5Btype%5D=zip`;
  }
  return `${baseUri}?${serializeHttpGetParams(queries)}`;
};

export const getPayTitle = (formQueries, state) => {
  const queries = takePaysSearchQueries(formQueries);
  const payrollRulesGroups = queries.payroll_rules_groups;
  const yearMonth = queries.year_month;
  const uniquePaymentDates = getSelectOptions(state, 'uniquePaymentDates');
  const uniquePayrollRuleGroups = getSelectOptions(state, 'uniquePayrollRuleGroups');
  const ruleGroupTitle = (uniquePayrollRuleGroups.find(item => item.value === payrollRulesGroups) || {}).label || '';
  const paymentDateTitle = (uniquePaymentDates.find(item => item.value === yearMonth) || {}).label || '';
  return `${ruleGroupTitle} ${paymentDateTitle}`;
};

export const takeBonusesQueriedUrl = (baseUri, formQueries, isPdf = false) => {
  const queries = takeBonusesSearchQueries(formQueries);
  if (isPdf) {
    return `${baseUri}?${serializeHttpGetParams(queries)}&pdf%5Btype%5D=zip`;
  }
  return `${baseUri}?${serializeHttpGetParams(queries)}`;
};

export const takePaysFbDataUrl = state => {
  const queries = takePaymentRangeFormSearchForm(state, FORM_NAME_PAYS_SEARCH);
  const params = serializeHttpGetParams(queries);
  return `${Urls.SHOW_PAY_FB_DATA_PAGE_URL}?${params}`;
};

//
// 住民税振込一覧表
//
export const takeInhabitantTaxFbDataUrl = state => {
  const queries = takePaymentRangeFormSearchForm(state, FORM_NAME_PAYS_SEARCH);
  return `${Urls.SHOW_INHABITANT_TAX_FB_PAGE}?${serializeHttpGetParams(queries)}`;
};

export const takeDownloadInhabitantTaxFbDataUrl = (state, payrollGroupSelectorType) => {
  let params = {};
  if (payrollGroupSelectorType === 'all') {
    const queries = takeGroupedPaymentMonthsSearchForm(state, FORM_NAME_PAYS_SEARCH);
    const { groupedPaymentMonths } = queries;
    params = { groupedPaymentMonths };
  } else {
    params = takePaymentRangeFormSearchForm(state, FORM_NAME_PAYS_SEARCH);
  }
  return `${Urls.DOWNLOAD_INHABITANT_PDF}?${serializeHttpGetParams(params)}`;
};

export const takeBonusesFbDataUrl = formQueries => {
  const clientBonusId = _.get(takeBonusesSearchQueries(formQueries), 'client_bonus_id');
  return `${Urls.SHOW_BONUS_FB_DATA_PAGE_URL}?${serializeHttpGetParams({ clientBonusId })}`;
};

export const takePaysAndAttendancesResetFormQueries = (formName, state, detailSearch = false) => {
  const { closingDay, paymentDay, paymentMonthType, payrollRulesGroups, yearMonth } = takePaymentRangeFormSearchForm(
    state,
    formName
  );

  // 条件解除時に支給日はリセットしない(値が設定されてないときは、リストの最新をセットする)
  const uniquePaymentDate = yearMonth || _.get(getSelectOptions(state, 'uniquePaymentDates'), '0.value');
  return {
    ...takePaysSearchQueriesFromLocation(formName, { search: '' }, detailSearch),
    closingDay,
    paymentDay,
    paymentMonthType,
    uniquePayrollRuleGroups: payrollRulesGroups,
    uniquePaymentDate
  };
};

export const takeBonusesResetFormQueries = (state, detailSearch = false) => {
  const currentBonusId = bonusesSearchFormSelector(state, 'clientBonusId');
  // 条件解除時にボーナスIDはリセットしない(値が設定されてないときは、リストの最新をセットする)
  const clientBonusId = currentBonusId || _.get(getSelectOptions(state, 'bonuses'), '0.value');
  return {
    ...takeBonusesSearchQueriesFromLocation({ search: '' }, detailSearch),
    clientBonusId
  };
};

export const takeEmployeesResetFormQueries = (state, detailSearch = false) =>
  takeEmployeesSearchQueriesFromLocation({ search: '' }, detailSearch);

export const takeWageLedgersResetFormQueries = (state, detailSearch = false) => ({
  ...takeWageLedgersSearchQueriesFromLocation({ search: '' }, detailSearch),
  bonus: formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(state, 'bonus'),
  yearSelected: formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(state, 'yearSelected'),
  year: formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(state, 'year'),
  yearMonthStart: formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(state, 'yearMonthStart'),
  yearMonthEnd: formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(state, 'yearMonthEnd'),
  yearMonthEndPeriods: formValueSelector(FORM_NAME_WAGE_LEDGER_SEARCH)(state, 'yearMonthEndPeriods')
});

export const takeClientRoleUsersResetFormQueries = () => takeClientRoleUsersSearchQueriesFromLocation({ search: '' });

const makeKeyByState = (key, state) => `${key}#${state.router.location.pathname}#${getCurrentUserInfo(state).email}`;

export const saveUniquePaymentDate = value => (dispatch, getState) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(makeKeyByState('uniquePaymentDate', getState()), value);
  }
};

export const ITEM_NAME_FETCH_UNIQUE_PAYMENT_DATES = 'fetchUniquePaymentDates';
export const fetchUniquePaymentDates = (
  formName,
  uniquePayrollRuleGroupIds,
  onlyConfirmed,
  updateLocation = false
) => async (dispatch, getState) => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_UNIQUE_PAYMENT_DATES));
    const response = await axios.get(Urls.OPTIONS_CLIENT_UNIQUE_PAYMENT_DATE, {
      params: {
        payroll_rules_group_ids: uniquePayrollRuleGroupIds,
        ...(onlyConfirmed ? { is_confirmed: true } : undefined)
      }
    });

    unstable_batchedUpdates(() => {
      const location = getState().router.location;
      dispatch(setOptions({ uniquePaymentDates: response.data.payload.options }));

      // 支給日選択
      // 1. クエリが存在すればその支給日
      // 2. ローカルストレージが存在すればその支給日
      // 3. 最新の確定済み給与の次の支給日 or 未確定のみの場合は操作日の次の支給日
      // 4. 上記で不適当なケースは最新の支給日
      // ※ 左プルダウン変更時(updateLocationがtrueの場合)は3,4のみで支給日を選択
      let paymentDate;
      let sessionPaymentDate;
      if (!updateLocation) {
        paymentDate = takePaysSearchQueriesFromLocation(formName, location).uniquePaymentDate;
        sessionPaymentDate = window.sessionStorage.getItem(makeKeyByState('uniquePaymentDate', getState()));
      }
      const paymentDateOptions = response.data.payload.options;
      const latestConfirmedDateIdx = paymentDateOptions.findIndex(option => option.confirmed === true);
      const beforeCurrentDateIdx = paymentDateOptions.findIndex(
        option => new Date(option.value.replace(/_/g, '-')) < Date.now()
      );
      if (!paymentDate) {
        if (sessionPaymentDate) {
          paymentDate = sessionPaymentDate;
        } else if (latestConfirmedDateIdx !== -1) {
          paymentDate =
            latestConfirmedDateIdx === 0
              ? paymentDateOptions[0].value
              : paymentDateOptions[latestConfirmedDateIdx - 1].value;
        } else {
          paymentDate =
            beforeCurrentDateIdx === 0 || beforeCurrentDateIdx === -1
              ? paymentDateOptions[0].value
              : paymentDateOptions[beforeCurrentDateIdx - 1].value;
        }
      }
      if (!paymentDateOptions.some(option => option.value === paymentDate)) {
        if (paymentDateOptions[0]) paymentDate = paymentDateOptions[0].value;
      }

      dispatch(saveUniquePaymentDate(paymentDate));
      dispatch(autofill(formName, 'uniquePaymentDate', paymentDate));
      if (updateLocation) dispatch(changePaysSearchLocation(getFormValues(formName)(getState())));
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_UNIQUE_PAYMENT_DATES));
  }
};

export const saveUniquePayrollRuleGroupIds = value => (dispatch, getState) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(makeKeyByState('uniquePayrollRuleGroups', getState()), value);
  }
};

export const savePayrollRefDates = value => (dispatch, getState) => {
  if (window.sessionStorage) {
    window.sessionStorage.setItem(makeKeyByState('closingDay', getState()), value.closingDay);
    window.sessionStorage.setItem(makeKeyByState('paymentDay', getState()), value.paymentDay);
    window.sessionStorage.setItem(makeKeyByState('paymentMonthType', getState()), value.paymentMonthType);
  }
};

export const ITEM_NAME_FETCH_UNIQUE_PAYROLL_RULE_GROUPS = 'fetchUniquePayrollRuleGroups';
export const fetchUniquePayrollRuleGroups = (formName, onlyConfirmed) => async (dispatch, getState) => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_UNIQUE_PAYROLL_RULE_GROUPS));
    const response = await axios.get(Urls.OPTIONS_CLIENT_UNIQUE_PAYROLL_RULE_GROUPS, {
      params: { ...(onlyConfirmed ? { is_confirmed: true } : undefined) }
    });
    const uniquePayrollRuleGroups = response.data.payload.options;

    // state.selectOptions.uniquePayrollRuleGroupsにセット
    dispatch(setOptions({ uniquePayrollRuleGroups }));

    // クエリから締め日支給日をセット、なければ一番最初の締め日支給日をセット
    const currentState = getState();
    let uniquePayrollRuleGroupIds = takePaysSearchQueriesFromLocation(formName, currentState.router.location)
      .uniquePayrollRuleGroups;

    // 同時に締日支給日もセット
    const payrollRuleGroup = uniquePayrollRuleGroups.find(item => item.value === uniquePayrollRuleGroupIds);
    let closingDay = payrollRuleGroup?.closingDay;
    let paymentDay = payrollRuleGroup?.paymentDay;
    let paymentMonthType = payrollRuleGroup?.paymentMonthType;

    if (uniquePayrollRuleGroupIds === undefined && window.sessionStorage) {
      uniquePayrollRuleGroupIds = window.sessionStorage.getItem(
        makeKeyByState('uniquePayrollRuleGroups', currentState)
      );
      closingDay = window.sessionStorage.getItem(makeKeyByState('closingDay', currentState));
      paymentDay = window.sessionStorage.getItem(makeKeyByState('paymentDay', currentState));
      paymentMonthType = window.sessionStorage.getItem(makeKeyByState('paymentMonthType', currentState));
    }
    if (
      uniquePayrollRuleGroupIds === undefined ||
      !uniquePayrollRuleGroups.some(option => option.value === uniquePayrollRuleGroupIds)
    ) {
      uniquePayrollRuleGroupIds = uniquePayrollRuleGroups[0].value;
      dispatch(saveUniquePayrollRuleGroupIds(uniquePayrollRuleGroupIds));
      closingDay = uniquePayrollRuleGroups[0].closingDay;
      paymentDay = uniquePayrollRuleGroups[0].paymentDay;
      paymentMonthType = uniquePayrollRuleGroups[0].paymentMonthType;
      dispatch(savePayrollRefDates({ closingDay, paymentDay, paymentMonthType }));
    }

    // state.form.formNameにセット
    dispatch(autofill(formName, 'uniquePayrollRuleGroups', uniquePayrollRuleGroupIds));
    dispatch(autofill(formName, 'closingDay', String(closingDay)));
    dispatch(autofill(formName, 'paymentDay', String(paymentDay)));
    dispatch(autofill(formName, 'paymentMonthType', String(paymentMonthType)));
    fetchUniquePaymentDates(formName, uniquePayrollRuleGroupIds, onlyConfirmed)(dispatch, getState);
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_UNIQUE_PAYROLL_RULE_GROUPS));
  }
};

// 全社に対応したセレクトボックスのオプションを取得する
export const ITEM_NAME_FETCH_GROUPED_PAYMENT_MONTHS = 'fetchGroupedPaymentMonths';
export const fetchGroupedPaymentMonths = () => async dispatch => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_GROUPED_PAYMENT_MONTHS));
    const response = await axios.get(Urls.OPTIONS_CLIENT_GROUPED_PAYMENT_MONTHS);
    dispatch(setOptions({ groupedPaymentMonths: response.data.payload.options }));
  } catch (_e) {
    dispatch(setGlobalErrors('プルダウンの取得に失敗しました'));
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_GROUPED_PAYMENT_MONTHS));
  }
};

export const ITEM_NAME_FETCH_BONUS_OPTIONS = 'fetchBonusOptions';
export const fetchBonusOptions = (onlyConfirmed = false) => async (dispatch, getState) => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_BONUS_OPTIONS));
    const response = await axios.get(Urls.OPTIONS_CLIENT_BONUSES, {
      params: { ...(onlyConfirmed ? { is_confirmed: true } : undefined) }
    });
    const bonuses = convertNumberToStrOfValueInArray(response.data.payload.options);
    dispatch(
      setOptions({
        bonuses
      })
    );
    // クエリから bonus_id をセット、なければ一番最初の bonus_id をセット
    let clientBonusId = takeBonusesSearchQueriesFromLocation(getState().router.location).clientBonusId;
    if (bonuses.length > 0) {
      if (clientBonusId === undefined) {
        clientBonusId = bonuses[0].value;
      } else if (!bonuses.find(bonus => bonus.value === clientBonusId)) {
        redirectTo(Urls.EMPLOYEE_BONUS_LIST);
      }
    }
    dispatch(autofill(FORM_NAME_BONUSES_SEARCH, 'clientBonusId', clientBonusId));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_BONUS_OPTIONS));
  }
};

export const ITEM_NAME_FETCH_REPORT_HEALTH_INSURANCE_BONUS_OFFICE_OPTIONS = 'ReportHealthInsuranceBonusOfficeOptions';
export const fetchReportHealthInsuranceBonusOfficeOptions = bonusId => async dispatch => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_REPORT_HEALTH_INSURANCE_BONUS_OFFICE_OPTIONS));
    const response = await axios.get(Urls.OPTIONS_APPLICABLE_OFFICES_OF_HEALTH_INSURANCE_BONUS, {
      params: humps.decamelizeKeys({ bonusId })
    });
    const applicableOffices = convertNumberToStrOfValueInArray(response.data.payload.options);
    dispatch(setOptions({ applicableOffices }));
  } catch (_e) {
    dispatch(setGlobalErrors('事業所プルダウンの取得に失敗しました'));
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_REPORT_HEALTH_INSURANCE_BONUS_OFFICE_OPTIONS));
  }
};

export const ITEM_NAME_FETCH_REVISION_MONTH_OPTIONS = 'fetchRevisionMonthOptions';
export const fetchRevisionMonthOptions = () => async (dispatch, getState) => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_REVISION_MONTH_OPTIONS));
    const response = await axios.get(Urls.OPTIONS_CLIENT_REVISION_MONTHS);
    const revisionYearMonths = convertNumberToStrOfValueInArray(response.data.payload.options);
    dispatch(setOptions({ revisionYearMonths }));
    // クエリから revision_month_id をセット、なければ一番最初の revision_month_id をセット
    let yearMonth = takeRevisionsSearchQueriesFromLocation(getState().router.location).yearMonth;
    if (yearMonth === undefined) {
      yearMonth = revisionYearMonths[0].value;
    }
    dispatch(autofill(FORM_NAME_REVISION_MONTHS_SEARCH, 'yearMonth', yearMonth));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_REVISION_MONTH_OPTIONS));
  }
};

const ITEM_NAME_FETCH_REVISION_OF_MONTH_OFFICE_OPTIONS = 'fetchRevisionMonthOfficeOptions';
export const fetchRevisionMonthOfficeOptions = () => async (dispatch, getState) => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_REVISION_OF_MONTH_OFFICE_OPTIONS));
    const yearMonth = revisionMonthsSearchFormSelector(getState(), 'yearMonth');
    const response = await axios.get(Urls.OPTIONS_APPLICABLE_OFFICES_OF_MONTHLY_REVISION, {
      params: { year_month: yearMonth }
    });
    const applicableOffices = convertNumberToStrOfValueInArray(response.data.payload.options);
    dispatch(setOptions({ applicableOffices }));

    let applicableOffice = takeRevisionsSearchQueriesFromLocation(getState().router.location).applicableOffice;
    if (applicableOffice === undefined) {
      applicableOffice = applicableOffices[0].value;
    }
    dispatch(autofill(FORM_NAME_REVISION_MONTHS_SEARCH, 'applicableOffice', applicableOffice));
  } catch (_e) {
    dispatch(setGlobalErrors('事業所プルダウンの取得に失敗しました'));
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_REVISION_OF_MONTH_OFFICE_OPTIONS));
  }
};

export const fetchRevisionMonthSyncOptions = () => async dispatch => {
  await dispatch(fetchRevisionMonthOptions());
  dispatch(fetchRevisionMonthOfficeOptions());
};

// 算定基礎
export const ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_REVISION_MONTH_OPTIONS =
  'fetchNotificationOfBaseAmountMonthOptions';
export const fetchNotificationOfBaseAmountMonthOptions = () => async (dispatch, getState) => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_REVISION_MONTH_OPTIONS));
    const response = await axios.get(Urls.OPTIONS_CLIENT_NOTIFICATION_OF_BASE_AMOUNT_MONTHS);
    const revisionYears = convertNumberToStrOfValueInArray(response.data.payload.options);
    dispatch(setOptions({ revisionYears }));
    // クエリから revision_month_id をセット、なければ一番最初の revision_month_id をセット
    let yearMonth = takeNotificationOfBaseAmountSearchQueriesFromLocation(getState().router.location).yearMonth;
    if (yearMonth === undefined) {
      yearMonth = revisionYears[0].value;
    }
    dispatch(autofill(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH, 'yearMonth', yearMonth));
  } catch (e) {
    console.log(e);
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_REVISION_MONTH_OPTIONS));
  }
};

export const ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_OFFICE_OPTIONS = 'fetchNotificationOfBaseAmountOfficeOptions';
export const fetchNotificationOfBaseAmountOfficeOptions = () => async (dispatch, getState) => {
  try {
    dispatch(setLoadingWithName(ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_OFFICE_OPTIONS));
    const yearMonth = notificationOfBaseAmountMonthsSearchFormSelector(getState(), 'yearMonth');
    const response = await axios.get(Urls.OPTIONS_APPLICABLE_OFFICES_OF_NOTIFICATION_OF_BASE_AMOUNT, {
      params: { year: yearMonth }
    });
    const applicableOffices = convertNumberToStrOfValueInArray(response.data.payload.options);
    dispatch(setOptions({ applicableOffices }));

    let applicableOffice = takeNotificationOfBaseAmountSearchQueriesFromLocation(getState().router.location)
      .applicableOffice;
    if (applicableOffice === undefined) {
      applicableOffice = applicableOffices[0].value;
    }
    await dispatch(autofill(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH, 'applicableOffice', applicableOffice));
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_OFFICE_OPTIONS));
  } finally {
    dispatch(clearLoadingWithName(ITEM_NAME_FETCH_NOTIFICATION_OF_BASE_AMOUNT_OFFICE_OPTIONS));
  }
};

export const fetchNotificationOfBaseAmountOptions = () => async dispatch => {
  await dispatch(fetchNotificationOfBaseAmountMonthOptions());
  dispatch(fetchNotificationOfBaseAmountOfficeOptions());
};

// 詳細検索条件を保持しているかどうかを返す
export const checkNotificationOfBaseAmountRevisionsSearchFormHasConditions = (state, ignoreQueries) => {
  const queries = takeNotificationOfBaseAmountRevisionsSearchQueries(
    getFormValues(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(state)
  );

  return !_.isEmpty(_.pickBy(_.omit(queries, ignoreQueries)));
};

// 賃金台帳
export const fetchWageLedgerYear = () => (dispatch, getState) => {
  axios
    .get(Urls.OPTIONS_CLIENT_WAGE_LEDGER_YEARS)
    .then(response => {
      const wageLedgerYears = convertNumberToStrOfValueInArray(response.data.payload.options.years);
      dispatch(
        setOptions({
          wageLedgerYears
        })
      );

      // クエリから year をセット、なければ一番最初の year をセット
      let year = takeWageLedgersSearchQueriesFromLocation(getState().router.location).year;
      if (year === undefined) {
        year = wageLedgerYears[0].value;
      }
      dispatch(autofill(FORM_NAME_WAGE_LEDGER_SEARCH, 'year', year));
      const wageLedgerYearMonths = convertNumberToStrOfValueInArray(response.data.payload.options.yearMonths);
      dispatch(
        setOptions({
          wageLedgerYearMonths
        })
      );
      // デフォルトの設定
      const copy = [...wageLedgerYearMonths].reverse();
      const yearMonth = copy.find(i => i.value.includes(year));
      dispatch(autofill(FORM_NAME_WAGE_LEDGER_SEARCH, 'yearMonthStart', yearMonth.value));
      dispatch(setYearMonthEnd(yearMonth.value, 'true'));
    })
    .catch(error => {
      console.log(error);
    });
};

export const convertFormToQueryString = () => ({});

export const INITIAL_STATE = {};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LOADING:
      if (action.payload && action.payload.name) {
        return { ...state, [action.payload.name]: { isLoading: true } };
      }
      return state;
    case CLEAR_LOADING:
      if (action.payload && action.payload.name) {
        return { ...state, [action.payload.name]: { isLoading: false } };
      }
      return state;
    default:
      return state;
  }
};

export const takeEmployeesSearchQueriesFromLocationMyNumber = location => {
  const converters = [
    convertQueryToFormFieldOfEmployees,
    convertQueryToFormFieldOfDetails,
    convertQueryToFormFieldOfPaging
  ];
  return takeQueries(
    {
      ...{
        enrollment_statuses: '',
        my_number_status: '0',
        sort: 'staffCode',
        order: 'asc',
        page: '1',
        per_page: getPageLimit(FORM_NAME_EMPLOYEES_SEARCH)
      },
      ...queryString.parse(location.search)
    },
    converters
  );
};

export const takeResetFormQueries = (state, searchQueriesFromLocation, detailSearch = false) =>
  searchQueriesFromLocation({ search: '' }, detailSearch);

export const takeReportHealthInsuranceBonusQueriesFromLocation = location =>
  takeQueries(
    {
      ...{
        sort: 'healthInsuranceInsuredPersonnelNumber',
        order: 'asc',
        page: '1',
        per_page: getPageLimit(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH)
      },
      ...queryString.parse(location.search)
    },
    [convertQueryToFormFieldOfReportHealthInsuranceBonus]
  );

export const takeReportHealthInsuranceBonusSearchQueries = formQueries =>
  takeQueries(formQueries, [convertFormToQueryFieldOfReportHealthInsuranceBonus]);

export const changeReportHealthInsuranceBonusLocation = formQueries => (dispatch, getState) =>
  changeLocation(formQueries, [convertFormToQueryFieldOfReportHealthInsuranceBonus], dispatch, getState());
