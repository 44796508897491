import createHistory from 'history/createBrowserHistory';

import configureStore from './store/configureStore';

const singleton = Symbol('singleton');
const singletonEnforcer = Symbol('singletonEnforcer');

class Shared {
  constructor(enforcer) {
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }

    this.history = createHistory();
    this.store = {};
  }

  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Shared(singletonEnforcer);
    }
    return this[singleton];
  }

  initializeStore(state) {
    this.store = configureStore(state, this.history);
  }

  getStore() {
    if (!this.store) {
      throw new Error('It has not been initialized');
    }
    return this.store;
  }

  getHistory() {
    return this.history;
  }
}

export default Shared;
