import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { EMPLOYEE_MY_DOWNLOAD_INCOME_TAX_WITHHOLDING } from 'src/constants/EndpointUrls';
import Button from 'jbc-front/components/Button';
import { SearchReactSelect } from 'src/components';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { executeIfAgreement } from 'src/reducers/my_data/myData';
import styles from './IncomeTaxWithholdingsDetail.scss';

const makeSelectList = (retirementWithholdingSlips, yeaWithholdingSlips) => {
  if (_.isEmpty([...retirementWithholdingSlips, ...yeaWithholdingSlips])) return [];
  const retirementSlip = _.map(retirementWithholdingSlips, item => ({
    label: `${moment(item.year).year()}年（退職時）`,
    value: `retirement-${item.id}`,
    year: moment(item.year).year(),
    type: 'retirement'
  }));
  const yeaSlip = _.map(yeaWithholdingSlips, item => ({
    label: `${item.year}年年末調整`,
    value: `yea-${item.id}`,
    year: item.year,
    type: 'yea'
  }));
  return [...retirementSlip, ...yeaSlip];
};

class IncomeTaxWithholdingsDetail extends React.Component {
  constructor(props) {
    super(props);
    const { retirementWithholdingSlips, yeaWithholdingSlips } = this.props;
    const allWithholdingSlips = makeSelectList(retirementWithholdingSlips, yeaWithholdingSlips);

    this.state = {
      isPdfPreview: false,
      selectingValue: _.get(_.head(allWithholdingSlips), 'value'),
      allWithholdingSlips
    };
  }

  pdfUrl() {
    return `${EMPLOYEE_MY_DOWNLOAD_INCOME_TAX_WITHHOLDING}?year=${this.selectingYear()}&type=${this.selectingType()}`;
  }

  showPreview = () => {
    this.setState({
      ...this.state,
      isPdfPreview: true
    });
  };

  hidePreview = () => {
    this.setState({
      ...this.state,
      isPdfPreview: false
    });
  };

  changeSelectingValue = newValue => {
    this.setState({
      ...this.state,
      selectingValue: newValue
    });
  };

  selectingYear = () =>
    _.get(_.find(this.state.allWithholdingSlips, item => item.value === this.state.selectingValue), 'year');

  selectingType = () =>
    _.get(_.find(this.state.allWithholdingSlips, item => item.value === this.state.selectingValue), 'type');

  showPdfPreview = () => {
    executeIfAgreement(this.showPreview);
  };
  render() {
    if (_.isEmpty(this.state.allWithholdingSlips)) {
      return (
        <div className="l-wrap-xs u-pt100">
          <div className="l-box-message">
            <p className="m-title-sub u-pt0 u-ta-c">登録済みの源泉徴収票がありません</p>
            <p className="u-ta-c">ご不明な点は管理者までお問い合わせください</p>
          </div>
        </div>
      );
    }
    return (
      <div className="l-wrap-xl l-contents-wrap">
        <h1 className="m-title-main">源泉徴収票</h1>
        <div className={styles.wrap}>
          <p className={styles.text}>表示したい年を選んでください</p>
          <div className={styles.action}>
            <div className={styles.selectYear}>
              <SearchReactSelect
                options={this.state.allWithholdingSlips}
                value={this.state.selectingValue}
                onChange={selected => this.changeSelectingValue(selected.value)}
                size="large"
              />
            </div>
            <div className={styles.button}>
              <Button primary onClick={this.showPdfPreview} className="u-mr15">
                源泉徴収票を表示
              </Button>
              <PreviewPdfModalContainer
                header="源泉徴収票"
                isOpen={this.state.isPdfPreview}
                hideModal={this.hidePreview}
                isMyPage
                url={this.pdfUrl()}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({
  startingYear: state.master.startingYear
}))(IncomeTaxWithholdingsDetail);
