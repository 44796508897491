import { connect } from 'react-redux';
import {
  fetchEmployeeMyNumber,
  fetchDependentFamilyMyNumber,
  getEmployeeMyNumber,
  getDependentFamilies,
  getIsLoading
} from 'src/reducers/my_numbers/myNumbers';
import ShowMyNumberModal from '../components/ShowMyNumberModal';

const mapStateToProp = (state, ownProps) => ({
  isOpen: ownProps.isOpen,
  hideModal: ownProps.hideModal,
  isLoading: getIsLoading(state),
  employee: ownProps.employee,
  dependentFamilies: getDependentFamilies(state),
  employeeMyNumber: getEmployeeMyNumber(state)
});
const mapDispatchToProp = dispatch => ({
  fetchEmployeeMyNumber: staffCode => {
    dispatch(fetchEmployeeMyNumber(staffCode));
  },
  fetchDependentFamilyMyNumber: (staffCode, dependent) => {
    dispatch(fetchDependentFamilyMyNumber(staffCode, dependent));
  }
});

export default connect(mapStateToProp, mapDispatchToProp)(ShowMyNumberModal);
