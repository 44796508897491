import { connect } from 'react-redux';
import {
  fetchAllGroupsAndSubgroups,
  fetchCsvAllowanceDeductionFormats,
  fetchAllClientBonus
} from 'src/reducers/fetchSelectOptions';
import ShowAllowanceDeductions from '../components/ShowAllowanceDeductions';

const mapDispatchToProps = dispatch => ({
  allGroupsAndSubgroups: () => dispatch(fetchAllGroupsAndSubgroups(true)),
  csvAllowanceDeductionFormats: () => dispatch(fetchCsvAllowanceDeductionFormats()),
  allClientBonus: () => dispatch(fetchAllClientBonus(true))
});

export default connect(null, mapDispatchToProps)(ShowAllowanceDeductions);
