import React from 'react';
import { reduxForm, getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import * as ExUrls from 'src/constants/ExternalUrls';
import { ACCOUNT_REGISTRATION_FORM } from 'src/constants/FormNames';
import { TextField, BoxDouble, CheckboxField, Section } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { ErrorsSection, TelField } from 'src/components';
import { registerAccount, getRegistrationErrors } from 'src/reducers/clients/accounts';
import { isEmail } from 'src/utils/CustomValidators';
import styles from './registration.scss';

const RawAccountRegistrationForm = ({ errors, handleSubmit, submit, pristine, submitting, agreeToTos }) => (
  <form onSubmit={handleSubmit}>
    <ErrorsSection errors={errors} />

    <Section className={styles.wrap}>
      <TextField name="clientName" label="事業所名" required maxLength="255" />

      <BoxDouble>
        <TextField name="userLastName" label="姓" required maxLength="32" />
        <TextField name="userFirstName" label="名" required maxLength="32" />
      </BoxDouble>

      <TelField required />

      <TextField name="userEmail" label="メールアドレス" required validate={isEmail} maxLength="255" />

      <div>
        <TextField name="userPassword" label="パスワード" type="password" required />
      </div>

      <TextField name="userPasswordConfirmation" label="パスワード(確認用)" type="password" required />

      <CheckboxField
        name="agreeToTos"
        label="利用規約・プライバシーポリシー・個人情報の取り扱い"
        labelContent={
          <span>
            &nbsp;<a className="u-txt-link" href={ExUrls.TERMS_OF_SERVICE} target="_blank" rel="noopener noreferrer">
              利用規約
            </a>および
            <a className="u-txt-link" href={ExUrls.PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
              プライバシーポリシー
            </a>、
            <a
              className="u-txt-link"
              href={ExUrls.INFORMATION_SECURITY_POLICY}
              target="_blank"
              rel="noopener noreferrer"
            >
              個人情報の取り扱い
            </a>に同意します
          </span>
        }
      />
      <div className="u-ta-c u-mt30">
        <Button onClick={submit} primary disabled={pristine || submitting || !agreeToTos}>
          登録する
        </Button>
      </div>
    </Section>
  </form>
);

const AccountRegistrationForm = reduxForm({
  form: ACCOUNT_REGISTRATION_FORM
})(RawAccountRegistrationForm);

export default connect(
  state => ({
    agreeToTos: (getFormValues(ACCOUNT_REGISTRATION_FORM)(state) || {}).agreeToTos,
    errors: getRegistrationErrors(state)
  }),
  dispatch => ({
    onSubmit: data => dispatch(registerAccount(data))
  })
)(AccountRegistrationForm);
