import _ from 'lodash';
import { connect } from 'react-redux';
import { getCurrentUserInfo } from 'src/reducers/users/currentUser';
import Dashboard from '../components/Dashboard';

const mapStateToProps = state => ({
  currentUser: getCurrentUserInfo(state) || {},
  planType: _.get(state, 'currentClient.item.data.planType')
});

export default connect(mapStateToProps)(Dashboard);
