import React from 'react';
import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';

import SortableTh from 'src/components/SortableTh';
import { EMPLOYEE_PAY_EDIT } from 'src/constants/EndpointUrls';
import { getList } from 'src/reducers/common/listReducer';
import { REDUCER_NAME } from 'src/reducers/employees/employeePays';
import { redirectTo, detailUrlWithQueries, getIdsFromArr } from 'src/utils/Http';
import { FORM_NAME_PAYS_SEARCH, takePaysSearchQueries, takePaymentDateFromSearchForm } from 'src/reducers/searchForm';
import EmployeePayTableRow from '../components/EmployeePayTableRow';

const EmployeePayTable = ({ employeePays, queries, paymentRange, attendanceStartDate, attendanceEndDate }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list">
      <thead>
        <tr>
          <th>メモ</th>
          <SortableTh field="staffCode" globalClassName="m-table-list-staffcode" formName={FORM_NAME_PAYS_SEARCH}>
            スタッフコード
          </SortableTh>
          <SortableTh field="name" formName={FORM_NAME_PAYS_SEARCH}>
            氏名
          </SortableTh>
          <th>勤怠連携</th>
          <SortableTh field="payUpdatedAt" formName={FORM_NAME_PAYS_SEARCH}>
            給与最終更新
          </SortableTh>
          <SortableTh field="payUpdatedAt" formName={FORM_NAME_PAYS_SEARCH}>
            給与最終確定
          </SortableTh>
          <SortableTh field="joinedOn" formName={FORM_NAME_PAYS_SEARCH}>
            入社日
          </SortableTh>
          <SortableTh field="resignedOn" formName={FORM_NAME_PAYS_SEARCH}>
            退職日
          </SortableTh>
        </tr>
      </thead>
      <tbody>
        {(!employeePays || employeePays.length === 0) && (
          <tr>
            <td colSpan={7}>データはありません。</td>
          </tr>
        )}
        {employeePays.map(employeePay => (
          <EmployeePayTableRow
            key={employeePay.id}
            paymentRange={paymentRange}
            attendanceStartDate={attendanceStartDate}
            attendanceEndDate={attendanceEndDate}
            onClick={() =>
              redirectTo(detailUrlWithQueries(employeePay.id, queries, EMPLOYEE_PAY_EDIT, getIdsFromArr(employeePays)))
            }
            {...employeePay}
          />
        ))}
      </tbody>
    </table>
  </div>
);

const mapStateToProps = (state, ownProps) => ({
  employeePays: getList(REDUCER_NAME, state),
  queries: takePaysSearchQueries(getFormValues(FORM_NAME_PAYS_SEARCH)(state)),
  paymentRange: takePaymentDateFromSearchForm(state, ownProps.formName)
});
export default connect(mapStateToProps)(EmployeePayTable);
