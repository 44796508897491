import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';

import { FileField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { Download } from 'jbc-front/components/icons';

import { ErrorsSection } from 'src/components';
import { EMPLOYEE_ATTENDANCE_CSV_FORM } from 'src/constants/FormNames';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';

import { FormSubmitBtn } from 'src/buttons';
import {
  getImportJobs,
  getClientSyncTasks,
  downloadTemplateCsv,
  importEmployeeAttendanceCsv,
  getEmployeeAttendanceItemErrors
} from 'src/reducers/employees/employeeAttendances';

import { ENCODING_OPTIONS } from 'src/constants/Options';
import { UTF_8 } from 'src/constants/Generals';

import styles from './EmployeeAttendanceDownloadCsvForm.scss';

const EmployeeAttendanceCsvInsideForm = props => {
  const {
    handleSubmit,
    submitting,
    pristine,
    submit,
    errors,
    downloadCsv,
    clientSyncTasks = {},
    importJobs = {}
  } = props;
  const disabledUpload =
    !_.isEmpty(clientSyncTasks.inProgress) || !_.isEmpty(importJobs.inProgress) || !_.isEmpty(importJobs.waiting);

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>アップロード手順</div>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <dl className={styles.label}>
            <dt className={styles.number}>1</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>ファイルの出力形式を選択してダウンロードしたCSVファイルをご利用ください。</p>
              <DownloadCsvEncodingRadioButton
                name={'encoding'}
                label={null}
                options={ENCODING_OPTIONS}
                required={false}
              />
              <Button
                onClick={() => (disabledUpload ? null : downloadCsv())}
                icon={<Download size={16} />}
                widthWide
                disabled={disabledUpload}
              >
                勤怠情報をダウンロード
              </Button>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>2</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>
                下の説明を参考にしながら、ダウンロードしたCSVファイルに、勤怠項目の値を入れてください。
              </p>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>3</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>完成したファイルを選択し、アップロードしてください。</p>
              <FileField name="employee_attendance_csv" accepted="csv" disabled={disabledUpload} />
              {disabledUpload ? <p>勤怠情報を更新中なので編集できません</p> : null}
            </dd>
          </dl>

          <FormSubmitBtn
            text="アップロード"
            {...{ submitting, pristine, submit }}
            widthWide
            disabled={disabledUpload}
          />

          <ErrorsSection errors={errors} />
        </form>
      </div>
    </div>
  );
};

const EmployeeAttendanceDownloadCsvForm = reduxForm({
  form: EMPLOYEE_ATTENDANCE_CSV_FORM
})(EmployeeAttendanceCsvInsideForm);
const mapStateToProp = (state, ownProps) => ({
  initialValues: { encoding: UTF_8, jobInfo: ownProps.jobInfo },
  errors: getEmployeeAttendanceItemErrors(state),
  clientSyncTasks: getClientSyncTasks(state) || {},
  importJobs: getImportJobs(state) || {}
});
const mapDispatchToProp = dispatch => ({
  onSubmit: data => dispatch(importEmployeeAttendanceCsv(data)),
  downloadCsv: () => dispatch(downloadTemplateCsv())
});

export default connect(mapStateToProp, mapDispatchToProp)(EmployeeAttendanceDownloadCsvForm);
