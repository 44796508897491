import { combineReducers } from 'redux';
import { formValueSelector } from 'redux-form';
import axios from 'axios';
import _ from 'lodash';
import * as Urls from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { MAX_POLLING_INTERVAL, INITIAL_POLLING_INTERVAL } from 'src/constants/Generals';
import { PAY_REPORTS_CREATE_FORM } from 'src/constants/FormNames';
import { listReducer, itemReducer } from 'src/reducers/common';
import { getExtras, setExtras } from 'src/reducers/common/listReducer';
import { setGlobalErrors } from 'src/reducers/global';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';
import { getTargetHealthInsurance } from 'src/reducers/reports/notificationOfBaseAmountRevisions';
import {
  FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH,
  takeNotificationOfBaseAmountRevisionsSearchQueries
} from '../searchForm';

export const REDUCER_NAME = 'notificationOfBaseAmountRevisionAsync';
export const getPdfJob = (state, revisionYear, applicableOffice) =>
  _.get(_.get(_.get(getExtras(REDUCER_NAME, state), 'pdfJob'), revisionYear), applicableOffice);
export const getIsFetchCompleted = state => _.get(getExtras(REDUCER_NAME, state), 'isFetchCompleted');

export const fetchPdfJobStatus = (interval, revisionYear, applicableOffice) => async (dispatch, getState) => {
  try {
    const currYear = formValueSelector(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(getState(), 'yearMonth');
    const currOffice = formValueSelector(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(
      getState(),
      'applicableOffice'
    );
    if (revisionYear !== currYear || applicableOffice !== currOffice) {
      return;
    }

    dispatch(setExtras(REDUCER_NAME, { isFetchCompleted: false }));
    const response = await axios.get(
      concatParamsToUrl(Urls.REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF_FETCH_STATUS, {
        revision_year: revisionYear
      }),
      { params: { applicable_office: applicableOffice } }
    );
    const pdfJob = response.data.payload.pdfJob;
    dispatch(
      setExtras(REDUCER_NAME, {
        pdfJob: { [revisionYear]: { [applicableOffice]: Object.assign({}, response.data.payload.pdfJob) } },
        isFetchCompleted: true
      })
    );
    if (_.includes(['waiting', 'running'], _.get(pdfJob, 'status'))) {
      const nextInterval = MAX_POLLING_INTERVAL > interval ? interval * 2 : interval;
      setTimeout(() => {
        dispatch(fetchPdfJobStatus(nextInterval, revisionYear, applicableOffice));
      }, nextInterval);
    }
  } catch (e) {
    if (!_.isUndefined(e.response)) {
      const { status } = e.response;
      if (status !== 404) {
        dispatch(setGlobalErrors('PDF作成処理が正常に完了しませんでした。時間をおいて再度実行してください。'));
      }
    } else {
      dispatch(setGlobalErrors('不明なエラーが発生しました。'));
    }
    dispatch(setExtras(REDUCER_NAME, { isFetchCompleted: true }));
  }
};

export const setPdfJob = (revisionYear, applicableOffice, hideModal) => async (dispatch, getState) => {
  try {
    const insuranceType = getTargetHealthInsurance(getState()).insuranceType;
    const pdfParams = formValueSelector(PAY_REPORTS_CREATE_FORM)(
      getState(),
      'orderBy',
      'pdfTarget',
      'submitTo',
      'submitAt'
    );

    let response = null;
    if (pdfParams.pdfTarget === 'searched_employee') {
      let searchParams = formValueSelector(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(
        getState(),
        'q',
        'offices',
        'groups',
        'employmentTypes',
        'occupations',
        'positions',
        'payrollRuleGroup',
        'memoColors',
        'noMemo'
      );
      searchParams = takeNotificationOfBaseAmountRevisionsSearchQueries(searchParams);
      Object.assign(pdfParams, searchParams);
    }
    const params = { revisionYear, applicableOffice, pdfParams };
    if (!['it', 'other_association'].includes(insuranceType)) params.pdfParams.submitTo = 'no_classification';

    response = await axios.post(Urls.CREATE_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF, params);
    dispatch(
      setExtras(REDUCER_NAME, {
        pdfJob: { [revisionYear]: { [applicableOffice]: Object.assign({}, response.data.payload.pdfJob) } },
        isFetchCompleted: true
      })
    );

    setTimeout(() => {
      dispatch(fetchPdfJobStatus(INITIAL_POLLING_INTERVAL, revisionYear, applicableOffice));
    }, INITIAL_POLLING_INTERVAL);
    hideModal();
  } catch (e) {
    if (_.get(e, 'response.data.errors')) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      dispatch(setGlobalErrors('エラーが発生しました。ページをリロードしてください。'));
    }
  }
};

export const fetchZipPdf = (revisionYear, applicableOffice) => async dispatch => {
  try {
    const url = `${concatParamsToUrl(Urls.REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF_FETCH_ZIP, {
      revision_year: revisionYear
    })}?applicable_office=${applicableOffice}`;
    redirectTo(url);
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。ページをリロードしてください。'));
  }
};

export const fetchPdf = (revisionYear, applicableOffice) => async dispatch => {
  try {
    const url = `${concatParamsToUrl(Urls.REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS_PDF_FETCH_PDF, {
      revision_year: revisionYear
    })}?applicable_office=${applicableOffice}`;
    const w = window.open(url, '', 'toolbar=0,menubar=0,location=0');
    if (_.isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print();
      });
    }
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。ページをリロードしてください。'));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
