import { connect } from 'react-redux';
import { getFormValues, change as formChange } from 'redux-form';
import get from 'lodash/get';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { getSelectOptions } from 'src/reducers/selectOptions';
import PaymentBankSection from '../components/PaymentBankSection';

const formatEmployeeBankList = employeeBankList => {
  if (!employeeBankList) {
    return [];
  }
  const fomatted = employeeBankList.map((item, index) => ({
    value: item.id ? item.id : `employee_bank_index_${index + 1}`,
    label: `口座情報${index + 1}`
  }));
  return fomatted;
};

const mapStateToProps = state => ({
  clientBanks: getSelectOptions(state, 'clientBanks') || [],
  employeeBankList: formatEmployeeBankList(get(getFormValues(EMPLOYEE_FORM)(state), 'employeeBanks')),
  currentPayPaymentBanks: getFormValues(EMPLOYEE_FORM)(state).payPaymentBanks,
  currentBonusPaymentBanks: getFormValues(EMPLOYEE_FORM)(state).bonusPaymentBanks
});

const mapDispatchToProps = dispatch => ({
  fieldInitialize: value => {
    dispatch(formChange(EMPLOYEE_FORM, `${value}.checked`, false));
    dispatch(formChange(EMPLOYEE_FORM, `${value}.transferType`, null));
    dispatch(formChange(EMPLOYEE_FORM, `${value}.employeeBankId`, null));
    dispatch(formChange(EMPLOYEE_FORM, `${value}.fixedAmount`, null));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentBankSection);
