import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import get from 'lodash/get';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { REDUCER_NAME, createCsvFormat } from 'src/reducers/clients/csvFormats';
import { CLIENT_SETTINGS, CLIENT_CSV_FORMATS_LIST } from 'src/constants/EndpointUrls';
import { CLIENT_CSV_FORMAT_FORM } from 'src/constants/FormNames';
import { fetchCsvAvailableFields, fetchAllGroupsAndSubgroups } from 'src/reducers/fetchSelectOptions';
import { setItem, getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import useInitializeData from 'src/hooks/useInitializeData';
import ClientCsvFormatForm from './components/ClientCsvFormatForm';

const RdxClientCsvFormatForm = reduxForm({
  form: CLIENT_CSV_FORMAT_FORM
})(ClientCsvFormatForm);

const ConnectedClientCsvFormatForm = connect(
  (state, { goBackPath }) => {
    const clientCsvFormats = get(state.clientCsvFormats, 'item.data') || {};

    return {
      initialValues: {
        clientCsvFormat: clientCsvFormats.clientCsvFormat,
        clientCsvFormatFields: clientCsvFormats.currentSelectedFields,
        clientCsvFormatGroups: clientCsvFormats.currentSelectedGroups,
        goBackPath
      },
      allGroups: getSelectOptions(state, 'groups'),
      csvAvaiableItems: getSelectOptions(state, 'csvAvaiableItems'),
      clientCsvData: getItem(REDUCER_NAME, state)
    };
  },
  dispatch => ({
    onSubmit: data => dispatch(createCsvFormat(data))
  })
)(RdxClientCsvFormatForm);

const ClientCsvFormatNew = props => {
  const { dispatch, values } = props;

  useInitializeData(() => {
    dispatch(setItem(REDUCER_NAME, values));
  });

  useEffect(() => {
    dispatch(fetchCsvAvailableFields());
    dispatch(fetchAllGroupsAndSubgroups());
  }, []);

  const search = props.location.search;
  const params = new window.URLSearchParams(search);
  const goBackPath = params.get('back');

  return (
    <div className="l-wrap-xl l-contents-wrap">
      <BreadCrumb>
        <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
        <BreadCrumb.Item label="CSVフォーマット一覧" path={CLIENT_CSV_FORMATS_LIST} />
        <BreadCrumb.Item label="CSVフォーマット作成" />
      </BreadCrumb>
      <div className="l-title-wrap">
        <h1 className="m-title-main">CSVフォーマット作成</h1>
      </div>
      <ConnectedClientCsvFormatForm isCreateForm goBackPath={goBackPath} />
    </div>
  );
};

export default connect()(ClientCsvFormatNew);
