import React from 'react';

import { getOptionLabel } from 'src/utils/Utils';
import tableStyles from 'src/features/Employees/styles/GeneralTableInfo.scss';
import { Item } from 'src/components/PrimaryPanel';
import { Dl, Dd, Dt } from 'src/components/SecondaryPanel';

const LabourConsultantSection = ({ clientLabourConsultant, submitters, prefectures }) => (
  <div>
    {clientLabourConsultant && (
      <Item className={tableStyles.mTablePersonal}>
        <Dl>
          <Dt>社労士名:</Dt>
          <Dd>{clientLabourConsultant.name}</Dd>
        </Dl>
        <Dl>
          <Dt>社労士名（カナ）:</Dt>
          <Dd>{clientLabourConsultant.nameKana}</Dd>
        </Dl>
        <Dl>
          <Dt>社労士コード:</Dt>
          <Dd>{clientLabourConsultant.labourConsultantCode}</Dd>
        </Dl>
        <Dl>
          <Dt>提出元:</Dt>
          <Dd>{getOptionLabel(clientLabourConsultant.submitter)(submitters)}</Dd>
        </Dl>
        <Dl>
          <Dt>社会保険労務士都道府県会:</Dt>
          <Dd>{getOptionLabel(clientLabourConsultant.organizationPrefectureId)(prefectures)}</Dd>
        </Dl>
        <Dl>
          <Dt>社労士事務所名:</Dt>
          <Dd>{clientLabourConsultant.organizationName}</Dd>
        </Dl>
        <Dl>
          <Dt>電話番号:</Dt>
          <Dd>{clientLabourConsultant.tel}</Dd>
        </Dl>
      </Item>
    )}
  </div>
);

export default LabourConsultantSection;
