import React from 'react';

// Form
import CommonModal from 'jbc-front/components/CommonModal';
import DownloadCsvForm from '../containers/DownloadCsvFormContainer';

const DownloadCsvModal = ({ isOpen, hideModal, downloadParams }) => (
  <CommonModal isOpen={isOpen} hideModal={hideModal} ariaHideApp={false} closeOnClick>
    {isOpen && <DownloadCsvForm cancelBtnClick={hideModal} downloadParams={downloadParams} isOpen={isOpen} />}
  </CommonModal>
);

export default DownloadCsvModal;
