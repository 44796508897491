import React, { useState } from 'react';
import LazyLoad from 'react-lazyload';
import ActionButton from 'jbc-front/components/ActionButton';
import { CheckCircle, Excluded } from 'jbc-front/components/icons';

import { EditActionBtn, CancelActionBtn, SaveActionBtn } from 'src/buttons';
import { EMPLOYEE_SHOW } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import UpdateSubjectModalContainer from '../containers/UpdateSubjectModalContainer';

import styles from './RevisionItemHeader.scss';

const buttonLabel = (isSubject, isPensionInsuranceSubject) => {
  let label;
  if (isSubject && isPensionInsuranceSubject) {
    label = '対象';
  } else if (isSubject === true && isPensionInsuranceSubject === false) {
    label = '対象(健保のみ)';
  } else if (isSubject === false && isPensionInsuranceSubject === true) {
    label = '対象(厚年のみ)';
  }
  return label;
};

const RevisionItemHeader = props => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { revision, formRevision, importing, errors } = props;
  const showMode = () => props.showMode(revision);
  const changeToEditMode = () => props.changeToEditMode(revision);
  const cancel = () => props.cancel(revision);
  const save = () => props.save(revision, errors(revision));
  const reset = () => props.reset(revision);
  const isSubject = showMode(revision) === 'SHOW' ? revision.isSubject : formRevision.isSubject;
  const isPensionInsuranceSubject =
    showMode(revision) === 'SHOW' ? revision.isPensionInsuranceSubject : formRevision.isPensionInsuranceSubject;
  const disabledReason = '処理中です。完了までしばらくお待ちください。';

  return (
    <div className={styles.container}>
      <LazyLoad>
        <div className={styles.labels}>
          <div className={styles.item}>
            スタッフコード{'　'}: <span className={styles.code}>{revision.staffCode}</span>
          </div>
          <div className={styles.item}>
            氏名{'　'}:{' '}
            <span className={styles.name}>
              <a href={concatParamsToUrl(EMPLOYEE_SHOW, { id: revision.employeeId })}>
                {revision.lastName} {revision.firstName}
              </a>
            </span>
          </div>
          <div className={styles.subject}>
            {isSubject || isPensionInsuranceSubject ? (
              <ActionButton
                className={styles.isSubject}
                onClick={() => setIsModalOpen(true)}
                icon={<CheckCircle size={16} />}
                as="button"
              >
                {buttonLabel(isSubject, isPensionInsuranceSubject)}
              </ActionButton>
            ) : (
              <ActionButton
                className={styles.isNotSubject}
                onClick={() => setIsModalOpen(true)}
                icon={<Excluded size={16} />}
                as="button"
              >
                対象外
              </ActionButton>
            )}
          </div>
        </div>
        {showMode() === 'SHOW' ? (
          <div className={styles.action}>
            <EditActionBtn disabled={importing} disabledReason={disabledReason} onClick={() => changeToEditMode()} />
          </div>
        ) : (
          <div className={styles.action}>
            <ActionButton onClick={() => reset()} as="button" className="u-mr10">
              手入力前に戻す
            </ActionButton>
            <SaveActionBtn onClick={() => save()} className="u-mr10" />
            <CancelActionBtn onClick={() => cancel()} />
          </div>
        )}
      </LazyLoad>
      <LazyLoad>
        {isModalOpen && (
          <UpdateSubjectModalContainer
            isOpen={isModalOpen}
            revision={revision}
            hideModal={() => setIsModalOpen(false)}
          />
        )}
      </LazyLoad>
    </div>
  );
};

export default RevisionItemHeader;
