import React from 'react';
import _ from 'lodash';
import { FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH } from 'src/reducers/searchForm';
import ReportHealthInsuranceBonusTableRow from '../components/ReportHealthInsuranceBonusTableRow';
import SortableTh from '../containers/TwoStrpSortableThContainer';
import style from './ReportHealthInsuranceBonusTable.scss';

class ReportHealthInsuranceBonusTable extends React.Component {
  render() {
    const { employeeBonus } = this.props;
    return (
      <div className="l-overflow-scroll">
        <table className="m-table-list m-table-fixed">
          <thead>
            <tr>
              <SortableTh field="staffCode" formName={FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH} width={120}>
                スタッフコード
              </SortableTh>
              <SortableTh
                field="healthInsuranceInsuredPersonnelNumber"
                formName={FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH}
                width={130}
              >
                <p>健康保険</p>
                <p>被保険者整理番号</p>
              </SortableTh>
              <th width={130}>氏名</th>
              <th width={100}>生年月日</th>
              <th width={140}>
                <p className={style.twoStepTableHeader}>通貨による額</p>
                <div className={style.rectangle} />
                <p className={style.twoStepTableHeader}>現物による額</p>
              </th>
              <th width={105}>賞与額合計</th>
              <th width={165}>備考</th>
              <th width={60} />
            </tr>
          </thead>
          <tbody>
            {_.isEmpty(employeeBonus) ? (
              <tr>
                <td colSpan={7}>データがありません。</td>
              </tr>
            ) : (
              employeeBonus.map(bonus => <ReportHealthInsuranceBonusTableRow key={bonus.id} bonus={bonus} />)
            )}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ReportHealthInsuranceBonusTable;
