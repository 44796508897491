import React from 'react';
import _ from 'lodash';
import Select from 'react-select';

import Hint from 'jbc-front/components/Hint';

import { getOptionLabel } from 'src/utils/Utils';
import { CheckboxFieldWithoutForm } from 'src/components';
import CheckBoxHint from 'src/components/CheckBoxHint';

import styles from './EditableField.scss';

class EditableField extends React.Component {
  state = {
    showHint: false
  };

  handleClick = () => {
    const { user, currentLoginUser, onChangeUserChecked } = this.props;
    if (user.email === currentLoginUser.email) {
      this.setState({
        ...this.state,
        showHint: true
      });
    } else {
      onChangeUserChecked(user.id);
    }
  };

  hideHint = () => {
    this.setState({
      ...this.state,
      showHint: false
    });
  };

  // スタッフコードのフィールドを取得する
  static getStaffCodeField(user) {
    if (user.hasEmployee) {
      return user.staffCode;
    }
    return (
      <div className={styles.noEmployee}>
        <span>従業員情報なし</span>
        <Hint
          text={
            <dl>
              <dd>「権限管理設定＞アカウント招待」から登録したアカウントです。</dd>
              <dd>従業員情報と紐付けたい場合は、一度「権限管理設定＞編集」からアカウントを削除してください。</dd>
              <dd>その後、「従業員一覧＞従業員を招待」から再度アカウント登録を行ってください。 </dd>
            </dl>
          }
        />
      </div>
    );
  }

  // 権限フィールドのComponentを取得する
  static getRoleField(mode, user, userRoles, onChange, unSelectable) {
    if (mode === 'SHOW' || unSelectable === true) {
      return <div className={styles.show}>{getOptionLabel(user.clientRoleId)(userRoles)}</div>;
    }
    return (
      <Select
        value={user.clientRoleId}
        options={userRoles}
        onChange={newOption => onChange(user, newOption)}
        clearable={false}
      />
    );
  }

  render() {
    const { mode, user, clientRoles, onChange, currentLoginUser } = this.props;
    const userRoles = user.hasEmployee
      ? clientRoles
      : clientRoles.filter(hash => ['管理者', '運用担当者'].includes(hash.label));
    const loginUser = user.email === currentLoginUser.email;
    return (
      <tr>
        {mode === 'EDIT' && (
          <td
            onClick={e => {
              e.stopPropagation();
              this.handleClick();
            }}
            className={_.get(user, 'checked') ? 'm-table-checkbox-on' : 'm-table-checkbox-off'}
            role="presentation"
          >
            {this.state.showHint && <CheckBoxHint onClick={this.hideHint}>自分の権限を変更できません。</CheckBoxHint>}
            <CheckboxFieldWithoutForm checked={_.get(user, 'checked')} readOnly />
          </td>
        )}
        <td className="u-break-word">{user.email}</td>
        <td className="u-break-word">{EditableField.getStaffCodeField(user)}</td>
        <td className="u-break-word">{user.employeeDisplayName}</td>
        <td>{EditableField.getRoleField(mode, user, userRoles, onChange, loginUser)}</td>
      </tr>
    );
  }
}
export default EditableField;
