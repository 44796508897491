import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import classNames from 'classnames';
import compose from 'lodash/fp/compose';
import Button from 'jbc-front/components/Button';
import { AddFile, MenuEllipsis, Link, Pulldown } from 'jbc-front/components/icons';

import { getSelectOptions } from 'src/reducers/selectOptions';
import { TARGET_EMPLOYEE_BONUS } from 'src/constants/EndpointUrls';
import {
  fetchEmployeeBonus,
  fetchConfirmJobStatus,
  hideConfirmBonusJobMessage,
  fetchEmployeeBonusCsvImports,
  hideEmployeeBonusCsvImportMessage,
  fetchUpsertJobStatus,
  REDUCER_NAME,
  hideUpsertBonusJobMessage
} from 'src/reducers/employees/employeeBonus';
import { getIsLoading } from 'src/reducers/common/listReducer';
import {
  fetchOfficeStationJobs,
  hideOfficeStationJobMessage,
  exportToOfficeStation,
  clearErrors as clearModalErrors
} from 'src/reducers/clients/clientBonus';
import Confirmed from 'src/components/Confirmed';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import DetailSearchForm from 'src/components/DetailSearchForm';
import BonusSelectBox from 'src/components/BonusSelectBox';
import IconActionButton from 'src/components/IconActionButton';
import ExtraActionsSection from 'src/features/EmployeeBonus/containers/ExtraActionsSectionContainer';
import SearchDetector from 'src/components/SearchDetector';
import ConfirmBonus from 'src/features/EmployeeBonus/containers/ConfirmBonusContainer';
import {
  takeBonusesSearchQueries,
  takeBonusConfirmedStateFromSearchForm,
  takeBonusesSearchQueriesFromLocation,
  takeBonusesResetFormQueries,
  takeBonusesQueriedUrl,
  bonusesSearchFormSelector,
  fetchBonusOptions,
  FORM_NAME_BONUSES_SEARCH,
  isLoading,
  ITEM_NAME_FETCH_BONUS_OPTIONS
} from 'src/reducers/searchForm';
import LoadingView from 'src/components/LoadingView';
import { getJob } from 'src/reducers/JobStatus';
import BonusFormModal from 'src/components/BonusFormModal';
import Balloon from 'src/features/Employees/components/Balloon';
import EmployeeBonusTable from './components/EmployeeBonusTable';
import DeleteClientBonusModal from './components/DeleteClientBonusModal';
import BonusTargetDateRange from './components/BonusTargetDateRange';
import AllJobStatusContainer from '../../components/JobStatus/AllJobStatusContainer';
import styles from './EmployeeBonusList.scss';
import OfficeStationModal from '../../components/OfficeStationModal';
import LoadingWithDescription from '../../components/LoadingWithDescription';

const EmployeeBonusNoData = ({ handleCreateBonus } = this.props) => (
  <div className={styles.nolist}>
    <div className={styles.notext}>
      <p>
        登録済みの賞与データがありません。<br />新しく賞与データを作成する場合は、下記の「賞与新規作成」ボタンを押してください。
      </p>
    </div>
    <div className={styles.menu}>
      <div onClick={handleCreateBonus} className={styles.createButton} role="presentation">
        <AddFile size={28} />賞与新規作成
      </div>
    </div>
  </div>
);

class EmployeeBonusList extends React.Component {
  constructor(props) {
    super(props);

    this.lockAllTableRows = this.lockAllTableRows.bind(this);
    this.unlockAllTableRows = this.unlockAllTableRows.bind(this);
    this.onClickCheckboxRow = this.onClickCheckboxRow.bind(this);
    this.onClickCreateBonus = this.onClickCreateBonus.bind(this);
    this.onClickEditBonus = this.onClickEditBonus.bind(this);
    this.onClickDeleteBonus = this.onClickDeleteBonus.bind(this);

    this.state = {
      selectingEmployeeBonus: [],
      isNewBonusModalOpen: false,
      isEditBonusModalOpen: false,
      isDeleteBonusModalOpen: false
    };
  }

  componentDidMount() {
    this.props.fetchBonusOptions();
    this.props.fetchConfirmClientBonusJobStatus();
    this.props.fetchEmployeeBonusCsvImportStatus();
    this.props.fetchClientBonusUpsertJobStatus();
    this.props.fetchOfficeStationJobs();
  }

  onDeleteSelectingBonusSuccess(onSuccessCallback) {
    onSuccessCallback();
    this.setState({
      ...this.state,
      selectingEmployeeBonus: []
    });
  }

  onClickCheckboxRow(item) {
    let selectingEmployeeBonus = [];
    if (this.state.selectingEmployeeBonus.find(id => id === item.id)) {
      selectingEmployeeBonus = this.state.selectingEmployeeBonus.filter(id => id !== item.id);
    } else {
      selectingEmployeeBonus = [...this.state.selectingEmployeeBonus, item.id];
    }

    this.setState({
      ...this.state,
      selectingEmployeeBonus
    });
  }

  lockAllTableRows() {
    this.setState({
      ...this.state
    });
  }

  unlockAllTableRows() {
    this.setState({
      ...this.state
    });
  }

  onClickCreateBonus() {
    this.setState({ isNewBonusModalOpen: true });
  }

  onClickEditBonus() {
    this.setState({ isEditBonusModalOpen: true });
  }

  onClickDeleteBonus() {
    this.setState({ isDeleteBonusModalOpen: true });
  }

  statusText = job => {
    switch (job.status) {
      case 'failed':
        return 'の賞与を確定できませんでした。';
      case 'in_progress':
      case 'waiting':
        return 'の賞与を確定中です。';
      default:
        return 'の賞与を確定しました。';
    }
  };

  statusTextImport = job => {
    switch (job.status) {
      case 'failed':
        return 'の賞与データをインポートできませんでした。';
      case 'in_progress':
      case 'waiting':
        return 'の賞与データをインポート中です。';
      default:
        return 'の賞与データをインポートしました。';
    }
  };

  statusTextUpsert = job => {
    switch (job.status) {
      case 'failed':
        return 'の賞与を更新できませんでした。';
      case 'in_progress':
      case 'waiting':
        return 'の賞与を更新中です。';
      default:
        return 'の賞与を更新しました。';
    }
  };

  statusTextOfficesStation = job => {
    const message = (() => {
      switch (job.status) {
        case 'failed':
          return 'オフィスステーション連携で賞与データの連携エラーが発生しました。';
        case 'partial_success':
          return 'オフィスステーション連携で賞与データの連携エラーが発生しました。なお、以下にエラー表示のない従業員は、正常に連携されています。';
        case 'in_progress':
        case 'waiting':
          return 'オフィスステーション連携で賞与データを連携中です。';
        default:
          return 'オフィスステーション連携での賞与データ連携が完了しました。';
      }
    })();
    return `${message}`;
  };

  render() {
    const {
      initialValues: formValues,
      clientBonusId,
      location,
      isConfirmed,
      resetValues,
      editTargetUrl,
      dispatch,
      isBonusBlank,
      isOptionsLoading,
      isListLoading,
      confirmClientBonusJob,
      clientBonusUpsertJob,
      employeeBonusCsvImport,
      containElectronicDeliveryAgreement,
      isAvailableOfficeStation,
      clientOfficeStationSetting,
      callExportToOfficeStation,
      officeStationEntryBonusJob
    } = this.props;

    return (
      <div>
        <SearchDetector
          location={location}
          clientBonusId={clientBonusId}
          willReceivePropsDetectingJudger={(prevProps, nextProps) =>
            nextProps.clientBonusId !== undefined &&
            (prevProps.clientBonusId === undefined || nextProps.location !== prevProps.location)
          }
          onDetect={() => {
            dispatch(fetchEmployeeBonus(takeBonusesSearchQueries(formValues)));
          }}
        />
        {isBonusBlank ? (
          <LoadingView isLoading={isOptionsLoading}>
            <EmployeeBonusNoData handleCreateBonus={this.onClickCreateBonus} />
          </LoadingView>
        ) : (
          <div>
            <div className="l-main-title-wrap">
              <h1 className="m-title-main">賞与一覧</h1>
            </div>
            {!_.isEmpty(confirmClientBonusJob) &&
              !_.isUndefined(confirmClientBonusJob.name) && (
                <AllJobStatusContainer
                  task={confirmClientBonusJob}
                  statusText={this.statusText}
                  jobId={confirmClientBonusJob.jobId}
                  hideJobMessage={hideConfirmBonusJobMessage}
                />
              )}
            {!_.isEmpty(clientBonusUpsertJob) &&
              !_.isUndefined(clientBonusUpsertJob.name) && (
                <AllJobStatusContainer
                  task={clientBonusUpsertJob}
                  statusText={this.statusTextUpsert}
                  jobId={clientBonusUpsertJob.jobId}
                  hideJobMessage={hideUpsertBonusJobMessage}
                />
              )}
            {!_.isEmpty(employeeBonusCsvImport) &&
              !_.isUndefined(employeeBonusCsvImport.name) && (
                <AllJobStatusContainer
                  task={employeeBonusCsvImport}
                  statusText={this.statusTextImport}
                  jobId={employeeBonusCsvImport.jobId}
                  hideJobMessage={hideEmployeeBonusCsvImportMessage}
                />
              )}
            <AllJobStatusContainer
              task={officeStationEntryBonusJob}
              statusText={this.statusTextOfficesStation}
              jobId={_.get(officeStationEntryBonusJob, 'jobId')}
              hideJobMessage={hideOfficeStationJobMessage}
            />
            <div className="l-wrap-xl">
              <div className={styles.select}>
                {isConfirmed && (
                  <div className={styles.confirmed}>
                    <Confirmed />
                  </div>
                )}
                <div className={styles.menu}>
                  <div className={styles.menuItem}>
                    <div onClick={this.onClickCreateBonus} className={styles.createButton} role="presentation">
                      <AddFile size={28} />賞与新規作成
                    </div>
                  </div>
                  <div className={styles.menuItem}>
                    <BonusSelectBox />
                  </div>
                  <div className={styles.menuItem}>
                    <IconActionButton
                      icon={<MenuEllipsis size={24} />}
                      options={() => (
                        <ul className={styles.editlist}>
                          <li>
                            <a
                              onClick={() => {
                                dispatch(clearModalErrors());
                                this.onClickEditBonus();
                              }}
                              role="presentation"
                              className={isConfirmed ? styles.disabled : ''}
                            >
                              賞与情報を編集
                            </a>
                          </li>
                          <li>
                            <a href={editTargetUrl} className={isConfirmed ? styles.disabled : ''}>
                              対象者を編集
                            </a>
                          </li>
                          <li>
                            <a
                              onClick={this.onClickDeleteBonus}
                              role="presentation"
                              className={isConfirmed ? styles.disabled : ''}
                            >
                              賞与情報を削除
                            </a>
                          </li>
                        </ul>
                      )}
                    />
                  </div>
                </div>
                {isAvailableOfficeStation &&
                  clientOfficeStationSetting && (
                    <div className={styles.syncBtn}>
                      <Button
                        className="ignore-react-onclickoutside"
                        icon={<Link size={17} />}
                        widthAuto
                        disabled={!isConfirmed}
                        onClick={() => this.setState({ isBonusCoordinationBaloonOpen: true })}
                      >
                        連携
                      </Button>
                      {this.state.isBonusCoordinationBaloonOpen && (
                        <Balloon hide={() => this.setState({ isBonusCoordinationBaloonOpen: false })}>
                          <ul className={styles.links} style={{ cursor: 'pointer' }}>
                            {true && (
                              <li
                                role="presentation"
                                onClick={e => {
                                  e.stopPropagation();
                                  this.setState({ isOfficeStationModalOpen: true });
                                }}
                              >
                                <span>
                                  <Pulldown size={8} />オフィスステーション連携
                                </span>
                              </li>
                            )}
                          </ul>
                        </Balloon>
                      )}
                    </div>
                  )}
                <ConfirmBonus unlockAllTableRows={this.unlockAllTableRows} />
              </div>
              <BonusTargetDateRange />
            </div>
            <div className="l-wrap-xl">
              <DetailSearchForm
                menuRender={() => <ExtraActionsSection clientBonusId={clientBonusId} isConfirmed={isConfirmed} />}
                containsOtherSearch={false}
                containElectronicDeliveryAgreement={containElectronicDeliveryAgreement}
                formName={FORM_NAME_BONUSES_SEARCH}
                resetValues={resetValues}
              />
            </div>
            {!_.isEmpty(clientBonusUpsertJob) &&
            clientBonusId === clientBonusUpsertJob.bonusId.toString() &&
            (clientBonusUpsertJob.status === 'in_progress' || clientBonusUpsertJob.status === 'waiting') ? (
              <LoadingWithDescription text="賞与を更新中です。少々お待ちください。" />
            ) : (
              <LoadingView isLoading={isListLoading}>
                <div>
                  <div className={classNames('l-wrap-xl', styles.list)}>
                    <EmployeeBonusTable />
                    <SearchFormPaginator formName={FORM_NAME_BONUSES_SEARCH} reducerName={REDUCER_NAME} />
                  </div>
                </div>
              </LoadingView>
            )}
            {this.state.isEditBonusModalOpen && (
              <BonusFormModal
                isEditForm
                isOpen={this.state.isEditBonusModalOpen}
                hideModal={() => {
                  this.setState({ isEditBonusModalOpen: false });
                }}
                clientBonusId={clientBonusId}
              />
            )}
            {this.state.isDeleteBonusModalOpen && (
              <DeleteClientBonusModal
                isOpen={this.state.isDeleteBonusModalOpen}
                hideModal={() => {
                  this.setState({ isDeleteBonusModalOpen: false });
                }}
              />
            )}
          </div>
        )}
        {this.state.isNewBonusModalOpen && (
          <BonusFormModal
            isEditForm={false}
            isOpen={this.state.isNewBonusModalOpen}
            hideModal={() => {
              this.setState({ isNewBonusModalOpen: false });
            }}
          />
        )}
        <OfficeStationModal
          isOpen={this.state.isOfficeStationModalOpen}
          hideModal={() => {
            this.setState({ ...this.state, isOfficeStationModalOpen: false });
          }}
          header={'オフィスステーション連携'}
          buttonName={'反映'}
          clientOfficeStationSetting={clientOfficeStationSetting}
          onSubmit={data => {
            callExportToOfficeStation(data, clientBonusId);
          }}
          components={
            <div style={{ color: 'red' }}>
              【注意】<br />
              すでにオフィスステーション側で入力した項目が存在する場合は、<br />
              値が削除されるのでご注意ください。
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...takeBonusesSearchQueriesFromLocation(state.router.location, true),
    ...getFormValues(FORM_NAME_BONUSES_SEARCH)(state)
  },
  clientBonusId: bonusesSearchFormSelector(state, 'clientBonusId'),
  resetValues: takeBonusesResetFormQueries(state, true),
  location: state.router.location,
  isConfirmed: takeBonusConfirmedStateFromSearchForm(state),
  isBonusBlank: _.isEmpty(getSelectOptions(state, 'bonuses')),
  editTargetUrl: takeBonusesQueriedUrl(TARGET_EMPLOYEE_BONUS, getFormValues(FORM_NAME_BONUSES_SEARCH)(state)),
  isOptionsLoading: isLoading(ITEM_NAME_FETCH_BONUS_OPTIONS, state),
  isListLoading: getIsLoading(REDUCER_NAME, state),
  confirmClientBonusJob: _.get(getJob(state), 'confirmClientBonusJob'),
  clientBonusUpsertJob: _.get(getJob(state), 'clientBonusUpsertJob'),
  employeeBonusCsvImport: _.get(getJob(state), 'employeeBonusCsvImport'),
  containElectronicDeliveryAgreement: _.get(state, 'currentClient.item.data.isElectronicDeliveryAgreementSetting'),
  officeStationEntryBonusJob: _.get(getJob(state), 'officeStationEntryBonusJob'),
  isAvailableOfficeStation: state.currentClient.item.data.isAvailableOfficeStation,
  clientOfficeStationSetting: state.currentClient.item.data.clientOfficeStationSetting
});

const mapDispatchToProps = dispatch => ({
  fetchBonusOptions: () => {
    dispatch(fetchBonusOptions());
  },
  fetchConfirmClientBonusJobStatus: () => {
    dispatch(fetchConfirmJobStatus());
  },
  fetchEmployeeBonusCsvImportStatus: () => {
    dispatch(fetchEmployeeBonusCsvImports());
  },
  fetchClientBonusUpsertJobStatus: () => {
    dispatch(fetchUpsertJobStatus());
  },
  fetchOfficeStationJobs: () => {
    dispatch(fetchOfficeStationJobs());
  },
  callExportToOfficeStation: (data, clientBonusId) => {
    dispatch(exportToOfficeStation(data, clientBonusId));
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_BONUSES_SEARCH,
    enableReinitialize: true
  })
)(EmployeeBonusList);
