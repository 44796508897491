import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import SortableTh from 'src/components/SortableTh';
import { isAllEmployeeChecked, changeAllEmployeeChecked } from 'src/reducers/flat_tax_reductions/employees';
import { FORM_NAME_FLAT_TAX_REDUCTION_SEARCH } from 'src/reducers/searchForm';

import styles from './EmployeeTable.scss';
import EmployeeTableRow from './EmployeeTableRow';

const EmployeeTable = ({ employees, mode }) => {
  const allEmployeeChecked = useSelector(state => isAllEmployeeChecked(state));

  const dispatch = useDispatch();
  const onChangeAllEmployeeChecked = () => {
    dispatch(changeAllEmployeeChecked(!allEmployeeChecked));
  };

  return (
    <div className="l-overflow-scroll">
      <table className="m-table-list">
        <thead>
          <tr>
            <th
              onClick={onChangeAllEmployeeChecked}
              className={allEmployeeChecked ? 'm-table-checkbox-on' : 'm-table-checkbox-off'}
            />
            <th>メモ</th>
            <SortableTh
              field="staffCode"
              globalClassName="m-table-list-staffcode"
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              スタッフコード
            </SortableTh>
            <SortableTh field="name" globalClassName={styles.name} formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}>
              氏名
            </SortableTh>
            <SortableTh
              field="employmentTypeId"
              globalClassName={styles.employmentType}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              雇用形態
            </SortableTh>
            <SortableTh
              field="groupId"
              globalClassName={styles.groupPath}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              グループ
            </SortableTh>
            <SortableTh
              field="positionId"
              globalClassName={styles.positionName}
              formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
            >
              役職
            </SortableTh>
            {mode === 'employee' && (
              <>
                <th
                  field="reduceTargetCount"
                  className={styles.reduceTargetCount}
                  formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
                >
                  減税対象人数
                </th>
                <th
                  field="savedReduceTargetCount"
                  className={styles.savedReduceTargetCount}
                  formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
                >
                  保存済減税対象人数
                </th>
              </>
            )}
            {mode === 'amount' && (
              <>
                <th field="reduceAmount" className={styles.reduceAmount} formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}>
                  既減税額
                </th>
                <th
                  field="savedReduceAmount"
                  className={styles.savedReduceAmount}
                  formName={FORM_NAME_FLAT_TAX_REDUCTION_SEARCH}
                >
                  保存済既減税額
                </th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {(!employees || employees.length === 0) && (
            <tr>
              <td colSpan={9}>データがありません。</td>
            </tr>
          )}

          {employees.map((employee, index) => (
            <EmployeeTableRow key={employee.id} index={index} employee={employees[index]} mode={mode} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeTable;
