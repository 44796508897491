import React from 'react';

import Button from 'jbc-front/components/Button';

const FormSubmitBtn = ({ submitting, pristine, submit, text = '変更', disabledReason, ...rest }) => (
  <Button primary disabled={submitting || pristine} onClick={submit} {...rest} disabledReason={disabledReason}>
    {text}
  </Button>
);

export default FormSubmitBtn;
