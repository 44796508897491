import React, { useState } from 'react';
import classNames from 'classnames';
import { CheckboxFieldWithoutForm } from 'src/components';
import MemoList from 'src/features/Memos';
import { getOptionLabel, getGroupFullPathName } from 'src/utils/Utils';
import { formatDate } from 'src/utils/Date';
import styles from 'src/components/EmployeeTable.scss';

import Hint from './Hint';
import ChangedContext from '../ChangedContext';

const EmployeeTableRow = props => {
  const [showHint, setShowHint] = useState(false);
  const { employee, clientEmploymentTypes, groups, positions } = props;

  return (
    <tr className={classNames('table-hover', styles.tableRow)}>
      <ChangedContext.Consumer>
        {({ isSelected, change }) => {
          const checked = isSelected(employee);
          return (
            <td
              className={`m-table-checkbox-${checked ? 'on' : 'off'} table-hover ${
                !employee.yeaNonTarget ? '' : 'u-cur-notallowed'
              }`}
              onClickCapture={() => (!employee.yeaNonTarget ? change({ [employee.id]: !checked }) : setShowHint(true))}
            >
              {showHint && (
                <Hint onClick={() => setShowHint(false)}>
                  以下の場合は選択できません
                  <br />
                  {/* # この年は画面のリンク同様に必要に応じて変更すること(ex.2021年の年末調整では2022を設定) */}
                  ・入社日が2024/01/01以降の場合
                </Hint>
              )}
              <CheckboxFieldWithoutForm checked={checked} readOnly disabled={employee.yeaNonTarget} />
            </td>
          );
        }}
      </ChangedContext.Consumer>
      <td>
        <MemoList employeeId={employee.id} />
      </td>
      <td>{employee.staffCode}</td>
      <td>{employee.displayName}</td>
      <td>{getOptionLabel(employee.employmentTypeId)(clientEmploymentTypes)}</td>
      <td>{!!employee.groupId && <div>{getGroupFullPathName(groups, employee.groupId)}</div>}</td>
      <td>{getOptionLabel(employee.positionId)(positions)}</td>
      <td>{formatDate(employee.joinedOn)}</td>
    </tr>
  );
};

export default EmployeeTableRow;
