import React from 'react';
import { Hamburger } from 'jbc-front/components/icons';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import styles from './SortableAllowanceItemTable.scss';

const SortableAllowanceItemRow = SortableElement(({ allowanceItem }) => (
  <tr className={styles.line}>
    <th className={styles.icon}>
      <Hamburger size={17} />
    </th>
    <th>{allowanceItem.name}</th>
    <td>{allowanceItem.unitTypeFormatedValue}</td>
    <td>{allowanceItem.payslipDisplayTypeFormatedValue}</td>
    <td>{allowanceItem.numberOfPayrollRulesGroups}</td>
  </tr>
));

const SortableAllowanceItemTable = SortableContainer(({ allowanceItems }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-item m-table-fixed">
      <thead>
        <tr>
          <th width={50} />
          <th width={300}>項目名</th>
          <th width={100}>単位</th>
          <th width={250}>給与明細での表示</th>
          <th width={170}>適用給与規定グループ</th>
        </tr>
      </thead>
      <tbody>
        {(!allowanceItems || allowanceItems.length === 0) && (
          <tr>
            <td colSpan={5}>データがありません。</td>
          </tr>
        )}
        {allowanceItems &&
          allowanceItems.map((allowanceItem, index) => (
            <SortableAllowanceItemRow key={allowanceItem.id} index={index} allowanceItem={allowanceItem} />
          ))}
      </tbody>
    </table>
  </div>
));

export default SortableAllowanceItemTable;
