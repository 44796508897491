import { connect } from 'react-redux';

import { getItemErrors } from 'src/reducers/common/itemReducer';
import { REDUCER_NAME, importEmployeeCsv } from 'src/reducers/employees/employees';

import CsvUpdateForm from '../components/EmployeeCsvUpdateForm';

const mapStateToProps = state => {
  const initialValues = { encoding: 'Windows-31J' };
  return {
    errors: getItemErrors(REDUCER_NAME, state),
    initialValues
  };
};
const mapDispatchToProps = dispatch => ({
  onSubmit: data => dispatch(importEmployeeCsv(data, 'update'))
});

export default connect(mapStateToProps, mapDispatchToProps)(CsvUpdateForm);
