import React from 'react';
import { connect } from 'react-redux';

import * as Urls from 'src/constants/EndpointUrls';
import { FlatTaxReductionLedgerCsvDownloadButton } from './components/FlatTaxReductionLedgerCsv';
import styles from './Report.scss';

const Report = ({ values }) => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">書類作成</h1>
    </div>
    <div className="l-wrap-xxl l-contents-wrap">
      <div className="l-overflow-scroll">
        <table className={styles.table}>
          <thead>
            <tr>
              <th>カテゴリー</th>
              <th>書類名</th>
              <th>提出期限</th>
              <th>詳細説明</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className={styles.category} rowSpan={5}>
                管理資料
              </td>
              <td className={styles.name}>
                <a href={Urls.SHOW_ALLOWANCE_DEDUCTIONS_PAY}>支給・控除等一覧表</a>
                <p>毎月の給与計算時に確認するために必要</p>
              </td>
              <td className={styles.limit}>提出不要</td>
              <td>
                勤怠項目、支給項目、控除項目などお好きな項目を選択して一覧表を作成でき、給与計算の確認資料として役立ちます。また、グループごとの合計も出力できるので、会計処理にも役に立ちます。
              </td>
            </tr>
            <tr>
              <td className={styles.name}>
                <a href={Urls.WAGE_LEDGER}>賃金台帳</a>
                <p>従業員ごとの各月の支給額をまとめたもの</p>
              </td>
              <td className={styles.limit}>
                役所から開示を<br />求められた時
              </td>
              <td>
                最後に記載した日から起算して3年間の保管義務があります。また、離職票を発行する場合は「雇用保険被保険者離職証明書」や「出勤簿」と合わせてハローワークに提出します。
              </td>
            </tr>
            <tr>
              <td className={styles.name}>
                {values.existPayConfirmed ? (
                  <a href={Urls.SHOW_PAY_FB_DATA_PAGE_URL}>給与振込一覧表</a>
                ) : (
                  <div>給与振込一覧表</div>
                )}
                <p>給与振込時に確認するために必要</p>
              </td>
              <td className={styles.limit}>提出不要</td>
              <td>給与振込前に給与振込額や振込先口座を確認できます。</td>
            </tr>
            <tr>
              <td className={styles.name}>
                {values.existBonusConfirmed ? (
                  <a href={Urls.SHOW_BONUS_FB_DATA_PAGE_URL}>賞与振込一覧表</a>
                ) : (
                  <div>賞与振込一覧表</div>
                )}
                <p>賞与振込時に確認するために必要</p>
              </td>
              <td className={styles.limit}>提出不要</td>
              <td>賞与振込前に賞与振込額や振込先口座を確認できます。</td>
            </tr>
            <tr>
              <td className={styles.name}>
                {values.existPayConfirmed ? (
                  <a href={Urls.SHOW_INHABITANT_TAX_FB_PAGE}>住民税一覧表</a>
                ) : (
                  <div>住民税一覧表</div>
                )}
                <p>市区町村ごとに支払う住民税を確認するために必要</p>
              </td>
              <td className={styles.limit}>提出不要</td>
              <td>住民税振込前に住民税振込額や振込先口座を確認できます。</td>
            </tr>
            <tr>
              <td className={styles.category} rowSpan={3}>
                社会保険
              </td>
              <td className={styles.name}>
                <a href={Urls.REPORT_MONTHLY_REVISIONS}>月額変更届</a>
                <p>従業員の給与が大きく変動した時に必要</p>
              </td>
              <td className={styles.limit}>すみやかに</td>
              <td>
                昇給や降給により固定的賃金が大きく変動した場合、現在の標準報酬月額が実態と乖離してしまうため、年1回の改定前に「月額変更届」を提出する必要があります。
              </td>
            </tr>
            <tr>
              <td className={styles.name}>
                <a href={Urls.REPORT_NOTIFICATION_OF_BASE_AMOUNT_REVISIONS}>算定基礎届</a>
                <p>年に一度、社会保険の被保険者全員の標準報酬月額を見直すために必要</p>
              </td>
              <td className={styles.limit}>毎年7月10日までに</td>
              <td>
                社会保険料のベースとなる「標準報酬月額」見直しの手続きです。給与変動の有無に関わらず、年に1回原則すべての被保険者が対象になります。
              </td>
            </tr>
            <tr>
              <td className={styles.name}>
                {values.existBonusConfirmed ? (
                  <a href={Urls.REPORT_HEALTH_INSURANCE_BONUS_LIST}>賞与支払届</a>
                ) : (
                  <div>賞与支払届</div>
                )}
                <p>賞与支給時に必要</p>
              </td>
              <td className={styles.limit}>賞与支給日から5日以内</td>
              <td>
                賞与の社会保険料納付のために必要な書類です。提出した賞与支払届を基に、標準賞与額が決まり、保険料額が通知されます。
              </td>
            </tr>
            <tr>
              <td className={styles.category}>労働保険</td>
              <td className={styles.name}>
                <a href={Urls.REPORT_LABOR_INSURANCE_SUMMARIES}>
                  労働保険の年度更新<br />（算定基礎賃金集計表/労働保険申告書）
                </a>
                <p>年間の労働保険料を申告・納付するために必要</p>
              </td>
              <td className={styles.limit}>毎年7月10日までに</td>
              <td>
                4月1日から翌年3月31日までの1年間を単位として、年度ごとに労働保険料（雇用保険料＋労災保険料）を申告・納付するための手続きです。<br />前年度の確定した労働保険料と、今年度の見込み分の労働保険料を、労働基準監督署などに申告し、納付する必要があります。
              </td>
            </tr>
            <tr>
              <td className={styles.category} rowSpan={3}>
                税・年末調整
              </td>
              <td className={styles.name}>
                <a href={Urls.REPORT_WITHHOLDING_SLIP_LIST}>源泉徴収票</a>
                <p>1月1日から12月31日までに支払われた給与等の支払金額や所得税などが記載されたもの</p>
              </td>
              <td className={styles.limit}>
                翌年の1月31日まで<br />
                （退職者に配布する場合は退職後1ヶ月以内）
              </td>
              <td>
                <p>
                  ■受給者交付用<br />
                  1月1日から12月31日の間に給与・賞与を支給したすべての従業員に配布する必要があります。年末調整後や退職時に配布します。
                </p>
                <br />
                <p>
                  ■税務署提出用<br />
                  給与等の年間支給額などが150万円超の役員や500万円超の従業員など
                  <a href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/hotei/7411.htm" className="u-txt-link">
                    一定の要件
                  </a>
                  を満たした方のみ、税務署への提出が必要になります。
                </p>
              </td>
            </tr>
            <tr>
              <td className={styles.name}>
                <a href={Urls.INCOME_TAX_COLLECTION_DATA}>所得税徴収高集計資料</a>
                <p>源泉所得税の納付書に記入する値の確認用資料です</p>
              </td>
              <td className={styles.limit}>
                給与などを実際に支払った月の翌月10日までに納付を行うことが原則とされています。<br />
                (<a
                  href="https://www.nta.go.jp/taxes/shiraberu/taxanswer/gensen/2505.htm#:~:text=%E6%A6%82%E8%A6%81,%E3%81%8C%E3%81%A7%E3%81%8D%E3%82%8B%E7%89%B9%E4%BE%8B%E3%81%8C%E3%81%82%E3%82%8A%E3%81%BE%E3%81%99%E3%80%82"
                  className="u-txt-link"
                >
                  納期の特例
                </a>に該当した場合は年2回にまとめることも可能です。）
              </td>
              <td>
                <p>
                  所得税徴収高計算書を作成する際の確認資料です。<br />
                  納期の特例に該当する場合や同月2回以上の賞与支給があった場合の合算用に、CSV出力を行っていただくことも可能です。
                </p>
              </td>
            </tr>
            <tr>
              <td className={styles.name}>
                <span>2024年定額減税各人別控除事績簿</span>
                <p>2024年に実施された定額減税の実績を確認する資料です</p>
                <div style={{ 'text-align': 'right' }}>
                  <FlatTaxReductionLedgerCsvDownloadButton />
                </div>
              </td>
              <td className={styles.limit}>提出不要</td>
              <td>
                <p>
                  2024年6月1日以降に支給日のある給与・賞与で実施された定額減税の内容を各人毎に確認するための資料です。
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

export default connect()(Report);
