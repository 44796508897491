import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';
import compose from 'lodash/fp/compose';
import humps from 'humps';

import ActionButton from 'jbc-front/components/ActionButton';
import { Task } from 'jbc-front/components/icons';

import { getList, getExtras } from 'src/reducers/common/listReducer';
import { fetchPayFbData, REDUCER_NAME } from 'src/reducers/employees/employeePays';
import { serializeHttpGetParams } from 'src/utils/Http';
import * as Urls from 'src/constants/EndpointUrls';
import {
  takePaysSearchQueries,
  fetchUniquePayrollRuleGroups,
  takePaymentRangeFormSearchForm,
  takePaysSearchQueriesFromLocation,
  paysSearchFormSelector,
  FORM_NAME_PAYS_SEARCH
} from 'src/reducers/searchForm';
import PaymentDateSelectBoxes from 'src/components/PaymentDateSelectBoxes';
import SearchDetector from 'src/components/SearchDetector';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import SortableTh from 'src/components/SortableTh';
import TargetDateWithReleaseDate from './components/TargetDateWithReleaseDate';
import DownloadFbCsvContainer from './components/DownloadFbCsvContainer';
import styles from './ShowPayFbDataPage.scss';

const lengthOf = employeePays => {
  if (!employeePays) {
    return 0;
  }
  return employeePays.reduce((sum, subArr) => sum + subArr.length, 0);
};

const EmployeePaysFbTable = ({ employeePays, totalTransferAmount }) => (
  <div className="l-overflow-scroll">
    <table className="m-table-list m-table-fixed">
      <thead>
        <tr>
          <SortableTh field="staffCode" globalClassName="m-table-list-staffcode" formName={FORM_NAME_PAYS_SEARCH}>
            スタッフコード
          </SortableTh>
          <th width={140}>金融機関名</th>
          <th width={140}>支店名</th>

          <SortableTh width={140} field="name" formName={FORM_NAME_PAYS_SEARCH}>
            氏名
          </SortableTh>
          <th width={140}>口座番号</th>
          <th width={140}>名義人</th>
          <th width={140}>振込額</th>
        </tr>
      </thead>
      <tbody>
        {employeePays.map(payments =>
          payments.map(payment => (
            <tr key={payment.id}>
              <td>{payment.staffCode}</td>
              <td>{payment.bankName}</td>
              <td>{payment.branchName}</td>
              <td>{payment.employeeFullName}</td>
              <td>{payment.accountNumber}</td>
              <td>{payment.accountHolderKana}</td>
              <td>{payment.transferAmount}</td>
            </tr>
          ))
        )}
        <tr className={styles.total}>
          <td>合計</td>
          <td colSpan={4} />
          <td>{lengthOf(employeePays)}件</td>
          <td>{totalTransferAmount}</td>
        </tr>
      </tbody>
    </table>
  </div>
);

class ShowPayFbDataPage extends React.Component {
  state = {
    isPdfPreview: false
  };

  showPreview = () => {
    this.setState({ ...this.state, isPdfPreview: true });
  };

  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(fetchUniquePayrollRuleGroups(FORM_NAME_PAYS_SEARCH, true));
  }

  get selectedPayrollGroups() {
    const { payrollRulesGroups } = this.props.queries;
    return this.props.clientPayrollRulesGroups.find(item => item.value === payrollRulesGroups) || {};
  }

  get selectedYearMonth() {
    const { yearMonth } = this.props.queries;
    return yearMonth;
  }

  get employeePaysBlank() {
    return this.props.employeePays.length === 0;
  }

  _downloadPdf = () => {
    const { initialValues: formValues } = this.props;
    const { closingDay, paymentDay, paymentMonthType, sort = 'staffCode', order = 'asc' } =
      humps.camelizeKeys(takePaysSearchQueries(formValues)) || {};
    const { payrollRulesGroups, yearMonth } = this.props.paymentRange;
    return `${Urls.DOWNLOAD_PAY_FB_PDF}?${serializeHttpGetParams({
      closingDay,
      paymentDay,
      paymentMonthType,
      yearMonth,
      payrollRulesGroups,
      sort,
      order
    })}`;
  };

  render() {
    const {
      employeePays,
      totalTransferAmount,
      extras,
      initialValues: formValues,
      uniquePaymentDate,
      location,
      paymentRange,
      dispatch
    } = this.props;

    return (
      <div>
        <SearchDetector
          location={location}
          uniquePaymentDate={uniquePaymentDate}
          willReceivePropsDetectingJudger={(prevProps, nextProps) =>
            nextProps.uniquePaymentDate !== undefined &&
            (prevProps.uniquePaymentDate === undefined || nextProps.location !== prevProps.location)
          }
          onDetect={() => {
            dispatch(fetchPayFbData(takePaysSearchQueries(formValues)));
          }}
        />
        <div className="l-wrap-xl l-contents-wrap">
          <div className="u-mb20">
            <BreadCrumb>
              <BreadCrumb.Item label="給与一覧" path={Urls.EMPLOYEE_PAY_LIST} />
              <BreadCrumb.Item label="給与振込一覧表" />
            </BreadCrumb>
          </div>
          <h1 className="m-title-main">給与振込一覧表</h1>
          <p className="m-title-main-note">給与振込前に、対象の従業員の振込先口座や金額を確認できます。</p>

          <div>
            <div className={styles.head}>
              <div className={styles.select}>
                <PaymentDateSelectBoxes formName={FORM_NAME_PAYS_SEARCH} onlyConfirmed />
              </div>
              <div className={styles.action}>
                <ActionButton
                  primary
                  disabled={this.employeePaysBlank}
                  onClick={this.showPreview}
                  icon={<Task size={15} />}
                  className="u-mr10"
                >
                  PDF出力
                </ActionButton>
                <DownloadFbCsvContainer
                  paymentRange={paymentRange}
                  disabled={this.employeePaysBlank}
                  queries={takePaysSearchQueries(formValues)}
                />
                <PreviewPdfModalContainer
                  header="給与振込一覧表"
                  isOpen={this.state.isPdfPreview}
                  hideModal={this.hidePreview}
                  url={this._downloadPdf()}
                />
              </div>
            </div>
            <div className={styles.datespan}>
              <TargetDateWithReleaseDate {...extras} />
            </div>
            {!this.employeePaysBlank && <EmployeePaysFbTable {...{ employeePays, totalTransferAmount }} />}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  extras: getExtras(REDUCER_NAME, state),
  paymentRange: takePaymentRangeFormSearchForm(state, FORM_NAME_PAYS_SEARCH),
  employeePays: getList(REDUCER_NAME, state) || [],
  totalTransferAmount: (getExtras(REDUCER_NAME, state) || {}).totalTransferAmount || 0,
  initialValues: {
    ...takePaysSearchQueriesFromLocation(FORM_NAME_PAYS_SEARCH, state.router.location),
    ...getFormValues(FORM_NAME_PAYS_SEARCH)(state)
  },
  uniquePaymentDate: paysSearchFormSelector(state, 'uniquePaymentDate'),
  location: state.router.location
});
export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME_PAYS_SEARCH,
    enableReinitialize: true
  })
)(ShowPayFbDataPage);
