import React from 'react';
import PropTypes from 'prop-types';
import { Postit, PostitOn } from 'jbc-front/components/icons';
import style from './MemoActionBtn.scss';

const MemoActionBtn = ({ isNewMemo, onClick, size }) => {
  const markIcon = isNewMemo ? <Postit size={size} /> : <PostitOn size={size} />;

  return (
    <span onClick={onClick} role="presentation" className={style.container}>
      {markIcon}
    </span>
  );
};

MemoActionBtn.propTypes = {
  isNewMemo: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
};

MemoActionBtn.defaultProps = {
  isNewMemo: true
};

export default MemoActionBtn;
