import React from 'react';
import Key from 'jbc-front/components/icons/Key';
import style from './LockIcon.scss';

const LockIcon = ({ confirmed, ...rest }) => {
  if (!confirmed) {
    return null;
  }

  return (
    <span className={style.container}>
      <Key {...rest} />
    </span>
  );
};

export default LockIcon;
