import React from 'react';
import Button from 'jbc-front/components/Button';
import { SOCIAL_INSURANCE_NOTIFICATION_SUBMISSION_TYPES } from 'src/constants/Options';
import CommonModal from 'jbc-front/components/CommonModal';
import { RadioField, Required, TextField, DateField } from 'jbc-front/components/Form';
import { ErrorsSection } from 'src/components';
import { eGovSerialNumber } from 'src/utils/CustomValidators';
import styles from './CsvDownloadModal.scss';

const CsvDownloadModal = props => {
  const {
    handleSubmit,
    errors,
    submitting,
    pristine,
    submit,
    isOpen,
    hideModal,
    insuranceType,
    modalTitle,
    withSubmitAt,
    withEmployeeSearch
  } = props;
  const csvTargetOptions = [
    { value: 'all_employee', label: '全従業員' },
    { value: 'searched_employee', label: '検索絞込中の従業員' }
  ];

  return (
    <CommonModal className={styles.modal} ariaHideApp={false} {...{ isOpen, hideModal }}>
      <CommonModal.Header hideModal={hideModal}>{modalTitle}</CommonModal.Header>
      <CommonModal.Body>
        <form onSubmit={handleSubmit} className={styles.modalForm}>
          <ErrorsSection errors={errors} />
          <div className={['it', 'other_association'].includes(insuranceType) ? styles.itemBox : styles.none}>
            <p>
              提出先
              <Required />
            </p>
            <div className={styles.radioBox}>
              <RadioField name="paymentFor" options={SOCIAL_INSURANCE_NOTIFICATION_SUBMISSION_TYPES} />
            </div>
          </div>
          <div className={styles.itemBox}>
            <p>
              媒体通番
              <Required />
            </p>
            <TextField
              required
              noLabel
              label="媒体通番"
              type="text"
              name="serialNumber"
              maxLength="3"
              validate={[eGovSerialNumber]}
            />
          </div>
          {withEmployeeSearch && (
            <div>
              <div className={styles.itemBox}>
                <p>対象選択</p>
                <div className={styles.csvRadioField}>
                  <RadioField name="csvTarget" options={csvTargetOptions} />
                </div>
              </div>
              <div className={styles.note}>
                対象選択：「検索絞込中の従業員」を選んだ場合もステータスによる絞り込み条件は無視されます
              </div>
            </div>
          )}
          {withSubmitAt && (
            <div className={styles.itemBox}>
              <p>提出日</p>
              <div className={styles.dateForm}>
                <DateField name="submitAt" />
              </div>
            </div>
          )}
          <div className={styles.buttonBox}>
            <Button onClick={hideModal} className="u-mr20">
              キャンセル
            </Button>
            <Button primary onClick={submit} disabled={pristine || submitting}>
              ダウンロード
            </Button>
          </div>
        </form>
      </CommonModal.Body>
    </CommonModal>
  );
};

export default CsvDownloadModal;
