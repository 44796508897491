/* eslint-disable react/no-array-index-key */

import React from 'react';
import _ from 'lodash';
import { FieldArray, Field } from 'redux-form';

import { EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';
import Delete from 'jbc-front/components/icons/Delete';

import { isRequired } from 'src/utils/CustomValidators';
import styles from './AllowedIps.scss';

const renderInput = ({ input, meta: { touched, error }, ...rest }) => (
  <td>
    <input className={touched && error ? styles.invalidFormInput : styles.formInput} {...input} {...rest} />
  </td>
);

const EditableListFields = ({ fields, mode, listData }) => (
  <table className={styles.dataTable}>
    <thead>
      <tr>
        <th>IPアドレス</th>
        <th>備考</th>
        <th className={styles.cellIcon} />
      </tr>
    </thead>
    <tbody>
      {_.isEmpty(listData) &&
        mode === 'SHOW' && (
          <tr>
            <td colSpan={3}>
              <div className={styles.listNodata}>IPアドレスを登録して下さい。</div>
            </td>
          </tr>
        )}
      {fields.map((name, index) => {
        const field = fields.get(index);
        return (
          <tr key={index}>
            {mode === 'SHOW' ? (
              <React.Fragment>
                <td className={styles.formInput}>{field.ip}</td>
                <td className={styles.notes}>{field.notes}</td>
                <td />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Field component={renderInput} name={`${name}.ip`} validate={isRequired} />
                <Field component={renderInput} name={`${name}.notes`} />
                <td className={styles.listDelete}>
                  <Delete
                    size={20}
                    onClick={e => {
                      e.preventDefault();
                      fields.remove(index);
                    }}
                  />
                </td>
              </React.Fragment>
            )}
          </tr>
        );
      })}
    </tbody>
  </table>
);

const EditableList = ({ mode, handleSubmit, listData }) => (
  <form onSubmit={handleSubmit}>
    <div className="u-mt30">
      <FieldArray name={EDITABLE_LIST_FIELDS_NAME} component={EditableListFields} mode={mode} listData={listData} />
    </div>
  </form>
);

export default EditableList;
