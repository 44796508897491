import { useState } from 'react';

const useInitializeData = callback => {
  const [initialized, setInitialized] = useState(false);

  if (!initialized) {
    callback();
    setInitialized(true);
  }
};

export default useInitializeData;
