import React, { Component } from 'react';
import _ from 'lodash';
import DropdownMenu from 'react-dd-menu';
import classNames from 'classnames';
import './dropdown-menu.scss';

export default class DropDownMenu extends Component {
  constructor() {
    super();
    this.state = {
      isMenuOpen: false
    };
  }

  toggle = () => {
    this.setState({ isMenuOpen: !this.state.isMenuOpen });
  };

  close() {
    this.setState({ isMenuOpen: false });
  }

  render() {
    const menuOptions = _.assign(
      {
        isOpen: this.state.isMenuOpen,
        close: this.close.bind(this),
        align: 'right',
        closeOnInsideClick: false
      },
      this.props.menuOptions || {}
    );

    const { menuItems } = this.props;
    const Toggle =
      this.props.toggle ||
      (({ onClick }) => (
        <button type="text" onClick={onClick}>
          Click me!
        </button>
      ));

    return (
      <div className="c-dropdown">
        <DropdownMenu {...menuOptions} toggle={<Toggle onClick={this.toggle} />}>
          {menuItems.map(({ id, label, description, style, onClick }) => (
            <li
              onClick={() => {
                if (onClick) {
                  onClick();
                }
              }}
              className={classNames(style, { clickable: onClick })}
              key={id}
              role="presentation"
            >
              {label}
              {description && <span>{description}</span>}
            </li>
          ))}
        </DropdownMenu>
      </div>
    );
  }
}
