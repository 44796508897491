import { combineReducers } from 'redux';
import _ from 'lodash';
import { getFormValues, formValueSelector, startSubmit, stopSubmit, blur } from 'redux-form';
import axios from 'axios';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import { EMPLOYEE_BONUS_FORM, EMPLOYEE_BONUS_CSV_FORM, BONUSES_SEARCH_FORM } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import { serializeHttpGetParams, concatParamsToUrl, redirectTo, getCustomErrors } from 'src/utils/Http';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import {
  getItemErrors,
  newItemFailure,
  setItem,
  getItem,
  getItemExtra,
  setItemExtra
} from 'src/reducers/common/itemReducer';
import {
  setList,
  getList,
  getListQueries,
  fetchListFailure,
  fetchListSuccess,
  fetchList,
  setExtras,
  getExtras,
  addQuery,
  setQueries,
  getListPageCount,
  setSelectAllPages,
  getSelectAllPages,
  getSelectAllPageValue
} from 'src/reducers/common/listReducer';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import {
  takeBonusesSearchQueries,
  takeBonusFromSearchForm,
  FORM_NAME_BONUSES_SEARCH,
  fetchBonusOptions
} from 'src/reducers/searchForm';
import { paramsWithFile } from 'src/utils/Form';
import { scrollToTop } from 'src/utils/Utils';
import { fetchLatestJobStatus, isJobIncomplete, fetchJobStatus } from 'src/reducers/JobStatus';
import { listReducer, itemReducer } from '../common';

export const REDUCER_NAME = 'employeeBonus';

export const getEmployeeBonusItemErrors = state => getItemErrors(REDUCER_NAME, state);
export const getEmployeeBonusFormValues = state => getFormValues(EMPLOYEE_BONUS_FORM)(state);
export const getEmployeeBonusList = state => getList(REDUCER_NAME, state);
export const setEmployeeBonusList = state => setList(REDUCER_NAME, state);
export const clearErrors = () => dispatch => dispatch(newItemFailure(REDUCER_NAME, []));
export const setErrors = errors => dispatch => dispatch(newItemFailure(REDUCER_NAME, errors));
export const addQueryEmployeeBonus = (key, value) => addQuery(REDUCER_NAME, key, value);
export const setEmployeeBonusQueries = queries => setQueries(REDUCER_NAME, queries);
export const setEmployeeBonusItem = state => setItem(REDUCER_NAME, state);
export const getEmployeeBonusItem = state => getItem(REDUCER_NAME, state);
export const getEmployeeBonusQueries = state =>
  takeBonusesSearchQueries(getFormValues(FORM_NAME_BONUSES_SEARCH)(state));
export const setCheckedEmployeeBonuses = checkedEmployeeBonuses =>
  setItemExtra(REDUCER_NAME, { checkedEmployeeBonuses });
export const getCheckedEmployeeBonuses = state => getItemExtra(REDUCER_NAME, state).checkedEmployeeBonuses || [];

// Only get info from state
export const getDownloadBonusFbErrors = state => {
  const errors = getEmployeeBonusItemErrors(state) || [{}];
  return (errors[0] || {}).downloadBonusFbErrors || [];
};
export const getSelectingClientBonus = state => takeBonusFromSearchForm(state) || {};

// 賞与：全てチェックオン、チェックオフアクション（ただし対象者は検索してる現在のページのみ）
export const setAllBonusCheckedTo = newCheckValue => (dispatch, getState) => {
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  const bonusList = getEmployeeBonusList(getState()) || [];
  const bonusListCopy = bonusList.map(item => ({ ...item, isChecked: newCheckValue }));
  const employeeIds = bonusListCopy.map(item => item.id);
  const checkedEmployeeBonuses = getCheckedEmployeeBonuses(getState())
    .filter(item => !employeeIds.includes(item.id))
    .concat(bonusListCopy);
  dispatch(setCheckedEmployeeBonuses(checkedEmployeeBonuses));
  dispatch(setEmployeeBonusList({ data: bonusListCopy, pageCount }));
};

// 賞与検索
export const fetchEmployeeBonus = queries => async (dispatch, getState) => {
  try {
    dispatch(blur(FORM_NAME_BONUSES_SEARCH, 'clientBonusId', _.get(queries, 'client_bonus_id'), true));
    dispatch(fetchList(REDUCER_NAME));
    const response = await axios.get(`${Urls.EMPLOYEE_BONUS_SEARCH}`, { params: queries });
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;

    const bonusList = (() => {
      const checkedEmployeeBonuses = getCheckedEmployeeBonuses(getState());
      const isCheckAllBonusPages = getSelectAllPages(REDUCER_NAME, getState());
      const selectAllPageValue = getSelectAllPageValue(REDUCER_NAME, getState());

      if (selectAllPageValue) {
        return response.data.payload.employeeBonus.map(item => {
          const employee = checkedEmployeeBonuses.find(emp => emp.id === item.id);
          if (employee) return { ...item, isChecked: employee.isChecked };

          return { ...item, isChecked: true };
        });
      }

      return response.data.payload.employeeBonus.map(item => {
        const employee = checkedEmployeeBonuses.find(emp => emp.id === item.id);
        if (employee) return { ...item, isChecked: employee.isChecked };
        // 全ページの従業員を解除を押下した場合
        if (isCheckAllBonusPages) return { ...item, isChecked: false };

        return { ...item };
      });
    })();

    const payload = {
      data: bonusList,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(MEMO_REDUCER_NAME, response.data.payload.memos));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
    dispatch(setExtras(REDUCER_NAME, {}));
  }
};

// 賞与個別削除
export const deleteEmployeeBonus = employeeBonusId => async dispatch => {
  try {
    const deleteUrl = concatParamsToUrl(Urls.EMPLOYEE_BONUS_DELETE, { id: employeeBonusId });
    await axios.delete(deleteUrl);

    redirectTo(Urls.EMPLOYEE_BONUS_LIST);
  } catch (e) {
    dispatch(setGlobalErrors(e.response.data.errors.messages));
  }
};

// 賞与更新
export const updateEmployeeBonus = data => async dispatch => {
  try {
    const updateUrl = concatParamsToUrl(Urls.EMPLOYEE_BONUS_UPDATE, { id: data.id });
    const { deductions, payments, ...rest } = data;
    const allowanceItems = (deductions || []).concat(payments || []);
    const params = { employeeBonus: rest, employeeBonusAllowanceItems: allowanceItems };
    dispatch(startSubmit(EMPLOYEE_BONUS_FORM));

    await axios.put(updateUrl, params);
    // redirectTo(concatParamsToUrl(Urls.EMPLOYEE_BONUS_EDIT, { id: data.id }));

    // Reload to update to the newest data
    // and keep tracking list employee bonuses to select next employee
    window.location.reload();
  } catch (e) {
    if (e.response.status === 409) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      scrollToTop();
      dispatch(setErrors(e.response.data.errors.messages));
      dispatch(stopSubmit(EMPLOYEE_BONUS_FORM));
    }
  }
};

// 手入力前に戻る
export const revertEmployeeBonus = employeeBonusId => async dispatch => {
  try {
    const revertUrl = concatParamsToUrl(Urls.EMPLOYEE_BONUS_REVERT, { id: employeeBonusId });
    await axios.put(revertUrl);

    // Reload to update to the newest data
    // and keep tracking list employee bonuses to select next employee
    window.location.reload();
  } catch (e) {
    if (e.response.status === 409) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      scrollToTop();
      dispatch(setErrors(e.response.data.errors.messages));
    }
  }
};
// 賞与対象者：現在全ての賞与がチェックオンするか？
export const getAllBonusCheckedState = state => {
  const bonusList = getEmployeeBonusList(state);
  if (!bonusList) {
    return false;
  }
  return bonusList.every(item => item.isChecked);
};
// 賞与対象者：現在チェックオンの賞与
export const getCheckedTrueBonusList = state => {
  const bonusList = getCheckedEmployeeBonuses(state) || [];
  return (bonusList.filter(item => item.isChecked) || []).map(item => item.id).join(',');
};
// 賞与対象者：現在チェックオフの賞与
export const getCheckedFalseBonusList = state => {
  const bonusList = getCheckedEmployeeBonuses(state) || [];
  return (bonusList.filter(item => !item.isChecked) || []).map(item => item.id).join(',');
};
// 賞与対象者：個別チェックオン、チェックオフアクション
export const changeBonusItemCheckedState = bonusId => (dispatch, getState) => {
  const pageCount = getListPageCount(REDUCER_NAME, getState());
  const bonusList = getEmployeeBonusList(getState()) || [];
  const bonusListCp = [...bonusList];
  const clickedBonus = bonusList.find(item => item.id === bonusId);
  const checkedEmployeeBonuses = getCheckedEmployeeBonuses(getState());
  const checkedEmployeeBonusesCp = checkedEmployeeBonuses
    .filter(item => item.id !== bonusId)
    .concat({ ...clickedBonus, isChecked: !clickedBonus.isChecked });
  dispatch(setCheckedEmployeeBonuses(checkedEmployeeBonusesCp));

  if (clickedBonus) {
    const index = bonusList.findIndex(item => item.id === clickedBonus.id);
    bonusListCp[index] = { ...clickedBonus, isChecked: !clickedBonus.isChecked };
  }
  dispatch(setEmployeeBonusList({ data: bonusListCp, pageCount }));
};

export const setIsNeedSavingConfirmation = value => async (dispatch, getState) => {
  const currentState = getState();
  const currentEmployeeBonusListExtra = getExtras(REDUCER_NAME, currentState) || {};

  dispatch(setExtras(REDUCER_NAME, { ...currentEmployeeBonusListExtra, isNeedSavingConfirmation: value }));
};

// 賞与対象者保存
export const saveBonusCheckedState = () => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const checkedTrueBonusIds = getCheckedTrueBonusList(currentState);
    const checkedFalseBonusIds = getCheckedFalseBonusList(currentState);
    const clientBonusId = _.get(getEmployeeBonusQueries(currentState), 'client_bonus_id');
    const isCheckAllBonusPages = getSelectAllPages(REDUCER_NAME, currentState);
    const selectAllPageValue = getSelectAllPageValue(REDUCER_NAME, currentState);
    const currentQueries = getEmployeeBonusQueries(currentState);
    const params = {
      checkedTrueBonusIds,
      checkedFalseBonusIds,
      clientBonusId,
      isCheckAllBonusPages,
      selectAllPageValue,
      queries: currentQueries
    };

    await axios.post(Urls.SAVE_BONUS_CHECKED_STATE, params);
    dispatch(fetchEmployeeBonus(currentQueries));
    dispatch(setGlobalSuccesses('対象者を保存しました'));
    dispatch(setIsNeedSavingConfirmation(false));
  } catch (e) {
    if (e.response.status === 409) {
      dispatch(setGlobalErrors(e.response.data.errors.messages));
    } else {
      const msgs = getCustomErrors(e) || e.message;
      dispatch(setGlobalErrors(msgs));
    }
  }
};

// 会計連携用CSVダウンロード
export const downloadAccountingBonusCsv = () => async (dispatch, getState) => {
  try {
    const clientBonusId = formValueSelector(BONUSES_SEARCH_FORM)(getState(), 'clientBonusId');
    const params = { clientBonusId };
    redirectTo(`${Urls.DOWNLOAD_ACCOUNTING_BONUS_CSV}?${serializeHttpGetParams(params)}`);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

// 支給・控除項目の更新: CSVファイルをダウンロード
export const downloadTemplateCsv = () => async (dispatch, getState) => {
  try {
    const encoding = (getFormValues(EMPLOYEE_BONUS_CSV_FORM)(getState()) || {}).encoding;
    const queries = getListQueries(REDUCER_NAME, getState());
    const params = { ...queries, encoding };
    dispatch(clearErrors());
    const response = await fetch(`${Urls.DOWNLOAD_BONUS_TEMPLATE_CSV}?${serializeHttpGetParams(params)}`);
    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      if (!response.ok) {
        dispatch(setGlobalErrors(json.errors.messages));
      }
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (exception) {
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};
// 支給・控除項目の更新: ダウンロードしたCSVファイルをインポートする
export const importEmployeeBonusCsv = data => async (dispatch, getState) => {
  try {
    const { clientBonusId } = getListQueries(REDUCER_NAME, getState());
    const params = paramsWithFile(data, ['employee_bonus_csv']);
    params.append('client_bonus_id', clientBonusId);
    await axios.post(Urls.IMPORT_EMPLOYEE_BONUS_CSV, params);
    dispatch(clearErrors());
    redirectTo(Urls.EMPLOYEE_BONUS_LIST);
  } catch (exception) {
    dispatch(setErrors(exception.response.data.errors.messages));
  }
};

// 賞与振込FB（銀行）出力
export const downloadBonusFb = (data, queries = {}) => async dispatch => {
  try {
    const clientBonusId = queries.client_bonus_id || queries.bonus_id;
    const params = { ...data, clientBonusId };
    redirectTo(`${Urls.DOWNLOAD_BONUS_FB_DATA}?${serializeHttpGetParams(params)}`);
  } catch (e) {
    dispatch(setErrors([{ downloadBonusFbErrors: e.response.data.errors.messages }]));
  }
};

// 賞与振込一覧表
export const getShowBonusFbDataUrl = state => {
  const clientBonusId = _.get(getEmployeeBonusQueries(state), 'client_bonus_id');
  const params = serializeHttpGetParams({ clientBonusId });
  return `${Urls.SHOW_BONUS_FB_DATA_PAGE_URL}?${params}`;
};

// 賞与振込検索
export const fetchBonusFbData = (queries = {}) => async dispatch => {
  try {
    dispatch(setList(REDUCER_NAME, { data: [], pageCount: 0 }));
    dispatch(setExtras(REDUCER_NAME, { totalTransferAmount: 0 }));
    const { sort = 'staffCode', order = 'asc' } = queries;

    const params = serializeHttpGetParams({ clientBonusId: _.get(queries, 'client_bonus_id'), sort, order });
    const fetchUrl = `${Urls.SEARCH_BONUS_FB_DATA}?${params}`;
    const response = await axios.get(fetchUrl);

    const payload = { data: response.data.payload.employeeBonuses, pageCount: 0 }; // ページは使わない
    const totalTransferAmount = response.data.payload.totalTransferAmount;
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
    dispatch(setExtras(REDUCER_NAME, { totalTransferAmount }));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

// 賞与振込 CSV ダウンロード
export const downloadBonusCsvFile = (clientBonusId, queries, data) => async dispatch => {
  try {
    const { encoding } = data;
    const { sort = 'staffCode', order = 'asc' } = queries;
    const params = { clientBonusId, encoding, sort, order };
    redirectTo(`${Urls.DOWNLOAD_BONUS_FB_CSV}?${serializeHttpGetParams(params)}`);
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export const selectAllPages = selectAllPagesObj => async dispatch => {
  dispatch(setCheckedEmployeeBonuses([]));
  dispatch(setSelectAllPages(REDUCER_NAME, selectAllPagesObj));
};

export const updateBonusReleaseDate = (data, callbackOnSuccess) => async dispatch => {
  try {
    const url = concatParamsToUrl(Urls.CHANGE_RELEASE_DATE_CLIENT_BONUS, { id: data.clientBonusId });
    await axios.put(url, data);
    dispatch(setGlobalSuccesses('公開日を変更しました。'));
    dispatch(fetchBonusOptions());
    if (typeof callbackOnSuccess === 'function') callbackOnSuccess();
  } catch (e) {
    const msgs = _.get(e, ['response', 'data', 'errors', 'messages']) || e.message;
    dispatch(setGlobalErrors(msgs));
  }
};

// PDF一括印刷
// 一括作成で作成されたものを印刷する
export const fetchBonusReportsPdf = clientBonusId => async dispatch => {
  try {
    const url = `${Urls.BULK_PRINT_EMPLOYEE_BONUS_REPORTS}?client_bonus_id=${clientBonusId}`;
    const w = window.open(url, '', 'toolbar=0,menubar=0,location=0');
    if (_.isFunction(w.addEventListener)) {
      w.addEventListener('load', () => {
        w.print();
      });
    }
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。'));
  }
};

// PDF一括ダウンロード
// 一括作成で作成されたものをダウンロードする
export const fetchBonusReportsZip = clientBonusId => async dispatch => {
  try {
    const url = `${Urls.BULK_DOWNLOAD_EMPLOYEE_BONUS_REPORTS}?client_bonus_id=${clientBonusId}`;
    redirectTo(url);
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。'));
  }
};

export const fetchReportJobStatus = clientBonusId => async dispatch => {
  const params = {
    job_name: 'reportEmployeeBonusJob',
    search_params: { client_bonus_id: clientBonusId }
  };
  await dispatch(fetchJobStatus(params));
};

export const pollFetchReportJobStatus = (
  clientBonusId,
  interval = INITIAL_POLLING_INTERVAL,
  fetchBonusReauired = false
) => async (dispatch, getState) => {
  if (clientBonusId !== formValueSelector(FORM_NAME_BONUSES_SEARCH)(getState(), 'clientBonusId')) return;

  await dispatch(fetchReportJobStatus(clientBonusId));
  const job = getState().JobStatus.reportEmployeeBonusJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(pollFetchReportJobStatus(clientBonusId, nextInterval, true));
    }, nextInterval);
  } else if (fetchBonusReauired) {
    dispatch(fetchBonusOptions());
    dispatch(fetchEmployeeBonus(getEmployeeBonusQueries(getState())));
  }
};

// PDF一括作成
export const setBonusReportDownloadJob = (clientBonusId, hideModal) => async dispatch => {
  try {
    const params = {
      client_bonus_id: clientBonusId
    };
    await axios.post(Urls.CREATE_EMPLOYEE_BONUS_REPORTS, params);
    dispatch(pollFetchReportJobStatus(clientBonusId));
    hideModal();
  } catch (err) {
    dispatch(setGlobalErrors('エラーが発生しました。'));
  }
};

export const fetchUpsertJobStatus = (interval = INITIAL_POLLING_INTERVAL, fetchBonusRequired = false) => async (
  dispatch,
  getState
) => {
  const params = {
    job_name: 'clientBonusUpsertJob'
  };
  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.clientBonusUpsertJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchUpsertJobStatus(nextInterval, true));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
    if (fetchBonusRequired) {
      dispatch(fetchBonusOptions());
      dispatch(fetchEmployeeBonus(getEmployeeBonusQueries(getState())));
    }
  }
};

export const hideUpsertBonusJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'ClientBonusUpsertJob'
    });
    dispatch(fetchUpsertJobStatus());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const fetchConfirmJobStatus = (interval = INITIAL_POLLING_INTERVAL, fetchBonusReauired = false) => async (
  dispatch,
  getState
) => {
  const params = {
    job_name: 'confirmClientBonusJob'
  };
  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.confirmClientBonusJob;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchConfirmJobStatus(nextInterval, true));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
    if (fetchBonusReauired) {
      dispatch(fetchBonusOptions());
      dispatch(fetchEmployeeBonus(getEmployeeBonusQueries(getState())));
    }
  }
};

export const hideConfirmBonusJobMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'confirmClientBonusJob'
    });
    dispatch(fetchConfirmJobStatus());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export const fetchEmployeeBonusCsvImports = (interval = INITIAL_POLLING_INTERVAL) => async (dispatch, getState) => {
  const params = {
    job_name: 'employeeBonusCsvImport'
  };
  await dispatch(fetchLatestJobStatus(params));
  const job = getState().JobStatus.employeeBonusCsvImport;
  if (!_.isEmpty(job) && isJobIncomplete(job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    setTimeout(() => {
      dispatch(fetchEmployeeBonusCsvImports(nextInterval));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { importing: true }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { importing: false }));
  }
};

export const hideEmployeeBonusCsvImportMessage = jobId => async dispatch => {
  try {
    await axios.put(Urls.HIDE_JOB_MESSAGE, {
      job_id: jobId,
      job_name: 'employeeBonusCsvImport'
    });
    dispatch(fetchEmployeeBonusCsvImports());
  } catch (e) {
    const message = _.get(e, ['response', 'data', 'errors', 'messages']);
    if (!_.isEmpty(message)) {
      dispatch(setGlobalErrors(message));
    }
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
