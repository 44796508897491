import React from 'react';
import classNames from 'classnames';

import { RadioField } from 'jbc-front/components/Form';
import Checked from 'src/icons/Checked';
import { REVISION_OPTIONS } from 'src/constants/Options';

import styles from './EditableRadioField.scss';

class EditableRadioField extends React.Component {
  renderInput() {
    const { input: inputProps, showValue, showMode, checked } = this.props;
    if (showMode === 'SHOW') {
      return (
        <div className={styles.label}>
          {checked && (
            <span className={styles.checked}>
              <Checked checked defaultValue="" />
            </span>
          )}
          {showValue}
        </div>
      );
    }
    // ----↓↓↓↓↓↓ for edit mode
    return <RadioField {...inputProps} input={inputProps} options={REVISION_OPTIONS} />;
  }
  render() {
    const { textAlign, width, className } = this.props;
    return (
      <div
        className={classNames(className, {
          [`${styles.container}`]: true
        })}
        style={{ textAlign, width }}
      >
        {this.renderInput()}
      </div>
    );
  }
}
export default EditableRadioField;
