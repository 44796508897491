import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';

import classNames from 'classnames';
import compose from 'lodash/fp/compose';
import Button from 'jbc-front/components/Button';
import { Link, Pulldown } from 'jbc-front/components/icons';
import { CheckboxField } from 'jbc-front/components/Form';
import Cookies from 'js-cookie';

import {
  EMPLOYEE_INVITATIONS_LIST,
  EMPLOYEE_NEW,
  INSERT_BATCH_CSV,
  UPDATE_BATCH_CSV,
  CHOOSE_COORDINATION_EMPLOYEE,
  CHOOSE_COORDINATION_EMPLOYEE_YEA,
  JBC_LMS_EMPLOYEES_IMPORT,
  LMS_EMPLOYEE_IMPORT_JOB
} from 'src/constants/EndpointUrls';
import SearchDetector from 'src/components/SearchDetector';
import DetailSearchForm from 'src/components/DetailSearchForm';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import FreePlanRestriction from 'src/components/FreePlanRestriction';
import {
  REDUCER_NAME,
  fetchEmployees,
  getCheckedEmployees,
  fetchImportEmployeeCsvJobStatus,
  hideImportEmployeeCsvJobMessage,
  fetchOfficeStationJobs,
  hideOfficeStationJobMessage,
  syncOfficeStation
} from 'src/reducers/employees/employees';
import {
  takeEmployeesSearchQueries,
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesResetFormQueries,
  FORM_NAME_EMPLOYEES_SEARCH
} from 'src/reducers/searchForm';
import FilterEnrollmentStatusBtnList from 'src/features/Employees/components/EnrollmentStatusBtnList';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { isFreePlan } from 'src/reducers/clients/currentClient';
import { getIsLoading, getList, getErrors } from 'src/reducers/common/listReducer';
import { ErrorsSection } from 'src/components';
import UnsyncModal from 'src/components/UnsyncModal';
import LoadingView from 'src/components/LoadingView';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import { setGlobalSuccesses } from 'src/reducers/global';
import EmployeeTable from './containers/EmployeeTableContainer';
import DownloadCsv from './containers/DownloadCsvContainer';
import NotifyLmsJob from './components/NotifyLmsJob';
import Balloon from './components/Balloon';
import ImportFromRoumuModal from './components/ImportFromRoumuModal';
import styles from './EmployeeList.scss';
import BatchDeleteEmployeeModal from './containers/BatchDeleteEmployeeModalContainer';
import AllJobStatusContainer from '../../components/JobStatus/AllJobStatusContainer';
import OfficeStationModal from '../../components/OfficeStationModal';
import { getJob, isJobIncomplete } from '../../reducers/JobStatus';

const syncsWithLms = () => Cookies.get('using_jbc_lms') === 'true';

class EmployeeList extends React.Component {
  constructor(props) {
    super(props);
    // anyApiRunning: APIで労務と連携
    const { anyApiRunning, notNotifiedLmsJob } = this.props;
    this.state = {
      isImportModalOpen: false,
      anyApiRunning,
      notNotifiedLmsJob,
      isEmployeeCoordinationBaloonOpen: false,
      isEmployeeAddBaloonOpen: false,
      mode: 'SHOW',
      isBatchDeleteModalOpen: false,
      isOfficeStationModalOpen: false
    };
  }

  getLastLmsJobStatus = () => {
    axios
      .get(`${LMS_EMPLOYEE_IMPORT_JOB}`, { params: { job_id: _.get(this.state.notNotifiedLmsJob, 'id') } })
      .then(response => {
        const job = response.data.payload.job;
        if (job.status === 'success' || job.status === 'failed') {
          this.setState({ anyApiRunning: false, notNotifiedLmsJob: job });
        }
      })
      .catch(error => console.log('error', error));
  };

  pollGetLastLmsJobStatus = interval => {
    let newInterval = interval;
    if (this.state.anyApiRunning) {
      this.getLastLmsJobStatus();
      if (newInterval < MAX_POLLING_INTERVAL) {
        newInterval *= 2;
      }
    }
    setTimeout(this.pollGetLastLmsJobStatus, newInterval, newInterval);
  };

  changeToShowMode = () => {
    this.setState({ mode: 'SHOW' });
  };

  changeToEditMode = () => {
    this.setState({ mode: 'EDIT' });
  };

  statusTextCsvImport = job => {
    const message = (() => {
      switch (job.status) {
        case 'partial_success':
          return 'CSVで従業員データの登録エラーが発生しました。なお、以下にエラー表示のない従業員は、正常に登録されています。';
        case 'failed':
          return 'CSVで従業員データの登録エラーが発生しました。';
        case 'in_progress':
        case 'waiting':
          return 'CSVで従業員データを登録中です。';
        default:
          return 'CSVでの従業員データ更新が完了しました。';
      }
    })();
    return `${message} （${job.filename}）`;
  };

  statusTextOfficesStation = job => {
    const message = (() => {
      switch (job.status) {
        case 'partial_success':
          return 'オフィスステーション連携で従業員データの登録エラーが発生しました。なお、以下にエラー表示のない従業員は、正常に登録されています。';
        case 'failed':
          return 'オフィスステーション連携で従業員データの登録エラーが発生しました。';
        case 'in_progress':
        case 'waiting':
          return 'オフィスステーション連携で従業員データを登録中です。';
        default:
          return 'オフィスステーション連携での従業員データ更新が完了しました。';
      }
    })();
    return `${message}`;
  };

  componentDidMount() {
    this.props.fetchImportEmployeeCsvJobStatus();
    this.props.fetchOfficeStationJobs();
    this.pollGetLastLmsJobStatus(INITIAL_POLLING_INTERVAL);
  }

  render() {
    const {
      initialValues: formValues,
      errors,
      location,
      resetValues,
      dispatch,
      planType,
      isListLoading,
      employees,
      checkedEmployees,
      jobs,
      formatSavedMessage,
      formatDeletedMessage,
      importEmployeeCsvJob,
      containElectronicDeliveryAgreement,
      clientOfficeStationSetting,
      callSyncOfficeStation,
      officeStationGetEmployeeJob,
      isAvailableOfficeStation
    } = this.props;
    const { anyApiRunning, notNotifiedLmsJob, mode } = this.state;
    const freePlan = isFreePlan(planType);
    formatSavedMessage();
    formatDeletedMessage();
    return (
      <div>
        <SearchDetector
          location={location}
          didMountDetectingJudger={() => true}
          willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
          onDetect={() => {
            dispatch(fetchEmployees(takeEmployeesSearchQueries(formValues)));
          }}
        />
        <div className="l-main-title-wrap">
          <h1 className="m-title-main">従業員一覧</h1>
        </div>
        <ErrorsSection errors={errors} />
        <AllJobStatusContainer
          task={importEmployeeCsvJob}
          statusText={this.statusTextCsvImport}
          jobId={_.get(importEmployeeCsvJob, 'jobId')}
          hideJobMessage={hideImportEmployeeCsvJobMessage}
        />
        <AllJobStatusContainer
          task={officeStationGetEmployeeJob}
          statusText={this.statusTextOfficesStation}
          jobId={_.get(officeStationGetEmployeeJob, 'jobId')}
          hideJobMessage={hideOfficeStationJobMessage}
        />
        {notNotifiedLmsJob && <NotifyLmsJob job={notNotifiedLmsJob} />}
        <div className="l-contents-wrap">
          <div className="l-wrap-xl">
            <div className={styles.buttonArea}>
              <div className={styles.buttonDl}>
                <DownloadCsv downloadEmployeeCsvJobInfo={jobs.downloadEmployeeCsvJobInfo} />
              </div>
              <div className={styles.button}>
                <Button
                  onClick={() =>
                    this.setState({
                      isEmployeeCoordinationBaloonOpen: !this.state.isEmployeeCoordinationBaloonOpen,
                      isEmployeeAddBaloonOpen: false
                    })
                  }
                  disabled={freePlan}
                  disabledReason={freePlan ? <FreePlanRestriction /> : null}
                  className="ignore-react-onclickoutside"
                  icon={<Link size={17} />}
                  widthAuto
                >
                  連携
                </Button>
                {this.state.isEmployeeCoordinationBaloonOpen && (
                  <Balloon hide={() => this.setState({ isEmployeeCoordinationBaloonOpen: false })}>
                    <ul className={styles.links} style={{ cursor: 'pointer' }}>
                      <li
                        role="presentation"
                        onClick={e => {
                          e.stopPropagation();
                          this.setState({
                            isImportModalOpen: true,
                            isEmployeeCoordinationBaloonOpen: true
                          });
                        }}
                      >
                        <span>
                          <Pulldown size={8} />ジョブカン労務HRから取得
                        </span>
                      </li>
                      <li>
                        <a href={CHOOSE_COORDINATION_EMPLOYEE}>
                          <Pulldown size={8} />対象従業員選択(労務HR)
                        </a>
                      </li>
                      <li>
                        <a href={CHOOSE_COORDINATION_EMPLOYEE_YEA}>
                          <Pulldown size={8} />対象従業員選択(年末調整)
                        </a>
                      </li>
                      {isAvailableOfficeStation &&
                        clientOfficeStationSetting && (
                          <li
                            role="presentation"
                            onClick={e => {
                              e.stopPropagation();
                              this.setState({ isOfficeStationModalOpen: true });
                            }}
                          >
                            <span>
                              <Pulldown size={8} />オフィスステーション連携
                            </span>
                          </li>
                        )}
                    </ul>
                  </Balloon>
                )}

                {syncsWithLms() ? (
                  <ImportFromRoumuModal
                    isOpen={this.state.isImportModalOpen}
                    hideModal={() =>
                      this.setState({ isImportModalOpen: false, isEmployeeCoordinationBaloonOpen: true })
                    }
                    onSubmit={() => axios.post(JBC_LMS_EMPLOYEES_IMPORT)}
                  />
                ) : (
                  <UnsyncModal
                    isOpen={this.state.isImportModalOpen}
                    hideModal={() =>
                      this.setState({ isImportModalOpen: false, isEmployeeCoordinationBaloonOpen: true })
                    }
                  />
                )}
              </div>
              <div className={styles.button}>
                <Button href={EMPLOYEE_INVITATIONS_LIST} as="a" primary widthAuto>
                  従業員を招待
                </Button>
              </div>
              <div className={styles.button}>
                <Button
                  primary
                  widthAuto
                  className="ignore-react-onclickoutside"
                  onClick={() =>
                    this.setState({
                      isEmployeeAddBaloonOpen: !this.state.isEmployeeAddBaloonOpen,
                      isEmployeeCoordinationBaloonOpen: false
                    })
                  }
                  disabled={isJobIncomplete(_.get(importEmployeeCsvJob, 'status')) || anyApiRunning}
                >
                  従業員登録
                </Button>
                {this.state.isEmployeeAddBaloonOpen &&
                  !isJobIncomplete(_.get(importEmployeeCsvJob, 'status')) &&
                  !anyApiRunning && (
                    <Balloon hide={() => this.setState({ isEmployeeAddBaloonOpen: false })}>
                      <ul className={styles.links}>
                        <li>
                          <a href={EMPLOYEE_NEW}>
                            <Pulldown size={8} />新規登録（直接入力）
                          </a>
                        </li>
                        {!freePlan && (
                          <li>
                            <a href={INSERT_BATCH_CSV}>
                              <Pulldown size={8} />新規登録（CSVインポート）
                            </a>
                          </li>
                        )}
                        {!freePlan && (
                          <li>
                            <a href={UPDATE_BATCH_CSV}>
                              <Pulldown size={8} />更新（CSVインポート）
                            </a>
                          </li>
                        )}
                      </ul>
                    </Balloon>
                  )}
              </div>
            </div>
            <DetailSearchForm
              menuRender={() => <FilterEnrollmentStatusBtnList />}
              containsOtherSearch={false}
              formName={FORM_NAME_EMPLOYEES_SEARCH}
              containsOfficeSearch
              containElectronicDeliveryAgreement={containElectronicDeliveryAgreement}
              resetValues={resetValues}
            />
          </div>
          <LoadingView isLoading={isListLoading}>
            <div className={classNames('l-wrap-xl', styles.list)}>
              <EmployeeTable employees={employees} mode={mode} />
              <SearchFormPaginator formName={FORM_NAME_EMPLOYEES_SEARCH} reducerName={REDUCER_NAME} />
              {mode === 'EDIT' ? (
                <div className={styles.batchDeleteButtons}>
                  <Button
                    className="u-mr20"
                    primary
                    disabled={!checkedEmployees.length}
                    onClick={() => this.setState({ ...this.state, isBatchDeleteModalOpen: true })}
                  >
                    削除
                  </Button>
                  <Button onClick={this.changeToShowMode}>キャンセル</Button>
                </div>
              ) : (
                employees &&
                employees.length > 0 && (
                  <div>
                    <a className="u-txt-link" onClick={this.changeToEditMode} role="presentation">
                      従業員を一括削除する
                    </a>
                  </div>
                )
              )}
            </div>
          </LoadingView>
        </div>
        <BatchDeleteEmployeeModal
          isOpen={this.state.isBatchDeleteModalOpen}
          hideModal={() => {
            this.setState({ ...this.state, isBatchDeleteModalOpen: false });
          }}
          changeToShowMode={this.changeToShowMode}
          checkedEmployees={checkedEmployees}
        />
        <OfficeStationModal
          isOpen={this.state.isOfficeStationModalOpen}
          hideModal={() => {
            this.setState({ ...this.state, isOfficeStationModalOpen: false });
          }}
          header={'従業員情報取得'}
          buttonName={'実行'}
          clientOfficeStationSetting={clientOfficeStationSetting}
          onSubmit={callSyncOfficeStation}
          components={
            <div>
              <CheckboxField name="isFamily" label="扶養情報を取得する" />
              <CheckboxField name="isResigned" label="退職者を取得する" />
              <CheckboxField name="isSearch" label="一覧に表示中の従業員情報のみ取得する" />
            </div>
          }
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  initialValues: {
    ...takeEmployeesSearchQueriesFromLocation(state.router.location, true),
    ...getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state)
  },
  errors: getErrors(REDUCER_NAME, state) || [{}],
  resetValues: takeEmployeesResetFormQueries(state, true),
  location: state.router.location,
  anyApiRunning: getSelectOptions(state, 'anyApiRunning'),
  planType: _.get(state, 'currentClient.item.data.planType'),
  isListLoading: getIsLoading(REDUCER_NAME, state),
  employees: getList(REDUCER_NAME, state),
  checkedEmployees: getCheckedEmployees(state) || [],
  importEmployeeCsvJob: _.get(getJob(state), 'importEmployeeCsvJob'),
  containElectronicDeliveryAgreement: _.get(state, 'currentClient.item.data.isElectronicDeliveryAgreementSetting'),
  officeStationGetEmployeeJob: _.get(getJob(state), 'officeStationGetEmployeeJob')
});
const mapDispatchToProps = dispatch => ({
  formatSavedMessage: () => {
    if (Cookies.get('formatSaved')) {
      dispatch(setGlobalSuccesses('フォーマットを保存しました。'));
      Cookies.remove('formatSaved');
    }
  },
  formatDeletedMessage: () => {
    if (Cookies.get('formatDeleted')) {
      dispatch(setGlobalSuccesses('フォーマットを削除しました。'));
      Cookies.remove('formatDeleted');
    }
  },
  fetchImportEmployeeCsvJobStatus: () => {
    dispatch(fetchImportEmployeeCsvJobStatus());
  },
  callSyncOfficeStation: data => {
    dispatch(syncOfficeStation(data));
  },
  fetchOfficeStationJobs: () => {
    dispatch(fetchOfficeStationJobs());
  }
});
export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: FORM_NAME_EMPLOYEES_SEARCH,
    enableReinitialize: true
  })
)(EmployeeList);
