import { connect } from 'react-redux';
import get from 'lodash/get';
import { getSelectOptions } from 'src/reducers/selectOptions';
import selector from 'src/utils/Utils';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import EmployeeEditingForm from '../components/EmployeeEditingForm';

const mapStateToProps = (state, ownProps) => ({
  anyJobRunning: getSelectOptions(state, 'anyJobRunning'),
  inputedSupports: selector(getEmployeeFormValues(state), 'employeeFamilySupports'),
  isElectronicDeliveryAgreementSetting: get(state, 'currentClient.item.data.isElectronicDeliveryAgreementSetting'),
  confirmSubmit: () => {
    const useBusinessName = getEmployeeFormValues(state).employee.useBusinessName;
    const businessLastName = !useBusinessName ? null : getEmployeeFormValues(state).employee.businessLastName;
    const businessFirstName = !useBusinessName ? null : getEmployeeFormValues(state).employee.businessFirstName;

    if (ownProps.initBusinessLastName !== businessLastName || ownProps.initBusinessFirstName !== businessFirstName) {
      ownProps.setIsOpenConfirmationModal(true);
    } else {
      ownProps.submit();
    }
  }
});

export default connect(mapStateToProps, null)(EmployeeEditingForm);
