import React, { useEffect } from 'react';
import { reduxForm } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { CLIENT_SETTINGS, CLIENT_PAYROLL_RULES_GROUP_LIST } from 'src/constants/EndpointUrls';
import { CLIENT_ALLOWANCE_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, editAllowance, fetchAllowanceDetail } from 'src/reducers/clients/allowances';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { getItem, deleteItem, deleteItemExtra } from 'src/reducers/common/itemReducer';
import { setExtras } from 'src/reducers/common/listReducer';
import AllowanceEditingForm from './components/AllowanceEditingForm';

const initializeClientAllowanceItemId = clientAllowanceItems =>
  clientAllowanceItems.map(clientAllowanceItem => (clientAllowanceItem.value ? clientAllowanceItem.id : false));

const EditAllowanceForm = reduxForm({
  form: CLIENT_ALLOWANCE_FORM
})(AllowanceEditingForm);

const EditClientAllowance = ({ match, payrollRulesGroup }) => {
  const dispatch = useDispatch();
  const { payrollRulesGroupId } = match.params;
  const { allowanceId } = match.params;

  useEffect(() => {
    dispatch(fetchAllowanceDetail(payrollRulesGroupId, allowanceId));
    return () => {
      dispatch(deleteItem(REDUCER_NAME));
      dispatch(deleteItemExtra(REDUCER_NAME));
    };
  }, []);

  const selectedAllowanceType = useSelector(state => state[REDUCER_NAME].list.extras.selectedAllowanceType);
  const itemAllowanceType = useSelector(state => getItem(REDUCER_NAME, state))?.allowanceType;

  // リロード時に、支給・控除の選択状態fetchした支給控除項目情報からdispatchする
  useEffect(
    () => {
      if (!selectedAllowanceType && itemAllowanceType) {
        dispatch(setExtras(REDUCER_NAME, { selectedAllowanceType: itemAllowanceType }));
      }
    },
    [itemAllowanceType]
  );

  const allowanceItem = useSelector(state => getItem(REDUCER_NAME, state));
  const clientAllowanceItems = useSelector(state => getSelectOptions(state, 'clientAllowanceItems'));

  const initializeValues = {
    ...allowanceItem,
    allowanceAmount: allowanceItem && allowanceItem.allowanceAmount ? allowanceItem.allowanceAmount : '0',
    clientAllowanceItemId: initializeClientAllowanceItemId(clientAllowanceItems),
    allowanceItemTypes: 'number_of_days'
  };

  return (
    <div>
      <div className="l-title-wrap">
        <h1 className="m-title-main">支給・控除項目 編集</h1>
        <div className="m-title-main-note">{payrollRulesGroup.name}</div>
      </div>
      <div className="l-wrap-m">
        <div className="l-breadcrumb u-mb20">
          <a className="l-breadcrumb-link" href={CLIENT_SETTINGS}>
            設定
          </a>
          <a className="l-breadcrumb-link" href={CLIENT_PAYROLL_RULES_GROUP_LIST}>
            給与規定グループ一覧
          </a>
          <Link to={`/clients/payroll_rules_groups/${payrollRulesGroupId}/allowances`} className="l-breadcrumb-link">
            支給・控除項目一覧
          </Link>
          <span className="l-breadcrumb-here">支給・控除項目 編集</span>
        </div>
        <EditAllowanceForm
          payrollRulesGroup={payrollRulesGroup}
          initialValues={initializeValues}
          enableReinitialize
          keepDirtyOnReinitialize
          onSubmit={data => dispatch(editAllowance(data))}
        />
      </div>
    </div>
  );
};

export default EditClientAllowance;
