import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import { Pulldown } from 'jbc-front/components/icons';
import { openNewTab } from 'src/utils/Http';
import DownloadBonusFbContainer from './DownloadBonusFbContainer';
import styles from './ExtraActionsSection.scss';

const EmployeeBonusMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      const { showBonusFbDataUrl, confirmed, isOpen } = this.props;

      return (
        <div className={isOpen ? styles.baloonWrap : styles.hide}>
          <div className={styles.baloonSecond}>
            <ul className={styles.links}>
              <li>
                <DownloadBonusFbContainer confirmed={confirmed} />
              </li>
              <li>
                <a
                  onClick={() => openNewTab(showBonusFbDataUrl)}
                  className={confirmed ? undefined : styles.disabled}
                  role="presentation"
                >
                  <Pulldown size={8} />賞与振込一覧表
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }
);

export default EmployeeBonusMethod;
