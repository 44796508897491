import React from 'react';
import { Section } from 'jbc-front/components/Form';
import { getOptionLabel } from 'src/utils/Utils';
import tableStyles from 'src/features/Employees/styles/GeneralTableInfo.scss';
import styles from './Common.scss';

const BankItemShow = ({ bank, index, depositTypes }) => (
  <div className={styles.sectionWrap}>
    <Section title={`口座情報 ${index + 1}`}>
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          {bank.isUseToTransfer && (
            <tr>
              <th>振込口座に使用する</th>
              <td />
            </tr>
          )}
          <tr>
            <th>銀行：</th>
            <td>{bank.bankName}</td>
          </tr>
          <tr>
            <th>銀行コード：</th>
            <td>{bank.bankCode}</td>
          </tr>
          <tr>
            <th>支店：</th>
            <td>{bank.branchName}</td>
          </tr>
          <tr>
            <th>支店コード：</th>
            <td>{bank.branchCode}</td>
          </tr>
          <tr>
            <th>預金種別：</th>
            <td>{getOptionLabel(bank.depositType)(depositTypes)}</td>
          </tr>
          <tr>
            <th>口座番号：</th>
            <td>{bank.accountNumber}</td>
          </tr>
          <tr>
            <th>口座名義（カナ）：</th>
            <td>{bank.accountHolderKana}</td>
          </tr>
          <tr>
            <th>
              会社コード<br />（給与振込用）：
            </th>
            <td>{bank.consignorCodePay}</td>
          </tr>
          <tr>
            <th>
              会社コード<br />（総合振込用）：
            </th>
            <td>{bank.consignorCodeTotal}</td>
          </tr>
          <tr>
            <th>
              会社コード<br />（住民税振込用）：
            </th>
            <td>{bank.consignorCodeInhabitantTax}</td>
          </tr>
        </tbody>
      </table>
    </Section>
  </div>
);

const BanksSection = ({ banks, depositTypes }) => (
  <div>{banks.map((bank, index) => <BankItemShow {...{ bank, index, depositTypes }} key={bank.id} />)}</div>
);

export default BanksSection;
