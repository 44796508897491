import React from 'react';
import CommonModal from 'jbc-front/components/CommonModal';
import _ from 'lodash';

import ActionButton from 'jbc-front/components/ActionButton';
import Button from 'jbc-front/components/Button';
import LoadingView from 'src/components/LoadingView';
import styles from './ShowMyNumberModal.scss';

export default class ConfirmPayrollGroupInsideForm extends React.Component {
  myNumberText(myNumber, hasMyNumber) {
    let ret = '未取得';
    if (hasMyNumber) {
      ret = myNumber || '************';
    }
    return ret;
  }

  render() {
    const {
      employee,
      isOpen,
      hideModal,
      dependentFamilies,
      fetchEmployeeMyNumber,
      fetchDependentFamilyMyNumber,
      isLoading,
      employeeMyNumber
    } = this.props;
    const header = `${employee.displayName}さんの情報`;
    return (
      <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
        <React.Fragment>
          <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
          <CommonModal.Body>
            <LoadingView isLoading={isLoading}>
              <div>
                <p className={styles.caption}>{employee.fullName}さんのマイナンバー</p>
                <div className={styles.myNumberBox}>
                  <p>マイナンバー</p>
                  <p className={styles.myNumber}>{this.myNumberText(employeeMyNumber, employee.hasMyNumber)}</p>
                  <ActionButton
                    onClick={() => fetchEmployeeMyNumber(employee.id)}
                    disabled={!employee.hasMyNumber || employeeMyNumber}
                  >
                    表示
                  </ActionButton>
                </div>
              </div>
              {dependentFamilies &&
                _.map(dependentFamilies, dependentFamily => (
                  <div className="u-mt10">
                    <p className={styles.caption}>
                      {dependentFamily.lastName} {dependentFamily.firstName}さんのマイナンバー
                    </p>
                    <div className={styles.myNumberBox}>
                      <p>マイナンバー</p>
                      <p className={styles.myNumber}>
                        {this.myNumberText(dependentFamily.myNumber, dependentFamily.hasMyNumber)}
                      </p>
                      <ActionButton
                        onClick={() => fetchDependentFamilyMyNumber(employee.id, dependentFamily)}
                        disabled={!dependentFamily.hasMyNumber || dependentFamily.myNumber}
                      >
                        表示
                      </ActionButton>
                    </div>
                  </div>
                ))}
            </LoadingView>
          </CommonModal.Body>
          <CommonModal.Footer>
            <Button onClick={hideModal} className="u-mr20">
              閉じる
            </Button>
          </CommonModal.Footer>
        </React.Fragment>
      </CommonModal>
    );
  }
}
