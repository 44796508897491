import { connect } from 'react-redux';
import { getSelectOptions } from 'src/reducers/selectOptions';
import EmployeeTableRow from '../components/EmployeeTableRow';

const mapStateToProps = state => ({
  clientEmploymentTypes: getSelectOptions(state, 'employmentTypes'),
  groups: getSelectOptions(state, 'groups'),
  positions: getSelectOptions(state, 'employeePositions')
});

export default connect(mapStateToProps)(EmployeeTableRow);
