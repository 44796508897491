import { getFormValues } from 'redux-form';
import { connect } from 'react-redux';
import { get } from 'lodash';

import { isAllEmployeeChecked, changeAllEmployeeChecked } from 'src/reducers/employees/employees';
import { takeEmployeesSearchQueries, FORM_NAME_EMPLOYEES_SEARCH } from 'src/reducers/searchForm';
import EmployeeTable from '../components/EmployeeTable';

const mapStateToProps = state => ({
  queries: takeEmployeesSearchQueries(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state)),
  isMainOfficeOnly: get(state, 'currentClient.item.data.isMainOfficeOnly'),
  allEmployeeChecked: isAllEmployeeChecked(state)
});
const mapDispatchToProps = dispatch => ({
  onChangeAllEmployeeChecked: newChecked => {
    dispatch(changeAllEmployeeChecked(newChecked));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTable);
