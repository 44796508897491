import _ from 'lodash';
import { getFormValues, change } from 'redux-form';

import {
  FORM_NAME_EMPLOYEES_SEARCH,
  FORM_NAME_ATTENDANCES_SEARCH,
  FORM_NAME_PAYS_SEARCH,
  FORM_NAME_BONUSES_SEARCH,
  FORM_NAME_REVISION_MONTHS_SEARCH,
  FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH,
  FORM_NAME_WAGE_LEDGER_SEARCH,
  FORM_NAME_ROLE_USERS_SEARCH,
  FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH,
  FORM_NAME_FLAT_TAX_REDUCTION_SEARCH,
  changeEmployeesSearchLocation,
  changePaysSearchLocation,
  changeBonusesLocation,
  changeRevisionsLocation,
  changeNotificationOfBaseAmountRevisionsLocation,
  changeWageLedgersLocation,
  changeClientRolesLocation,
  changeReportHealthInsuranceBonusLocation
} from 'src/reducers/searchForm';

const debouncedAttendancesSearch = _.debounce(
  (dispatch, getState) => dispatch(changePaysSearchLocation(getFormValues(FORM_NAME_ATTENDANCES_SEARCH)(getState()))),
  1000
);
const debouncedPaysSearch = _.debounce(
  (dispatch, getState) => dispatch(changePaysSearchLocation(getFormValues(FORM_NAME_PAYS_SEARCH)(getState()))),
  1000
);
const debouncedEmployeesSearch = _.debounce(
  (dispatch, getState) =>
    dispatch(changeEmployeesSearchLocation(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(getState()))),
  1000
);
const debouncedBonusesSearch = _.debounce(
  (dispatch, getState) => dispatch(changeBonusesLocation(getFormValues(FORM_NAME_BONUSES_SEARCH)(getState()))),
  1000
);
const debouncedWageLedgersSearch = _.debounce(
  (dispatch, getState) => dispatch(changeWageLedgersLocation(getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(getState()))),
  1000
);
const debouncedClientRolesSearch = _.debounce(
  (dispatch, getState) => dispatch(changeClientRolesLocation(getFormValues(FORM_NAME_ROLE_USERS_SEARCH)(getState()))),
  1000
);
const debouncedReportHealthInsuranceBonusSearch = _.debounce(
  (dispatch, getState) =>
    dispatch(
      changeReportHealthInsuranceBonusLocation(
        getFormValues(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH)(getState())
      )
    ),
  1000
);
const debouncedEmployeeFlatTaxReductionsSearch = _.debounce(
  (dispatch, getState) =>
    dispatch(changeEmployeesSearchLocation(getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(getState()))),
  1000
);

export default store => next => action => {
  const result = next(action);
  const form = _.get(action, 'meta.form');
  const field = _.get(action, 'meta.field');

  // 検索条件が変更された場合、1ページ目から開始する
  if (form && field && field !== 'page' && action.type === '@@redux-form/CHANGE') {
    const values = getFormValues(form)(store.getState());
    // ページをリセットしない条件のリスト
    const fieldSuffixesThatDoNotResetPage = ['reduceTargetCount', 'reduceAmount'];
    const shouldNotResetPage = fieldSuffixesThatDoNotResetPage.some(suffix => field.endsWith(suffix));
    if (values.page !== '1' && !shouldNotResetPage) {
      return store.dispatch(change(form, 'page', '1'));
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_EMPLOYEES_SEARCH && action.type === '@@redux-form/CHANGE') {
    if (['sort', 'order'].includes(action.meta.field)) return result;

    if (['employmentType', 'limit'].includes(action.meta.field)) {
      store.dispatch(changeEmployeesSearchLocation(getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(store.getState())));
    } else {
      debouncedEmployeesSearch(store.dispatch, store.getState);
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_ATTENDANCES_SEARCH && action.type === '@@redux-form/CHANGE') {
    if (
      ['uniquePayrollRuleGroups', 'closingDay', 'paymentDay', 'paymentMonthType', 'sort', 'order'].includes(
        action.meta.field
      )
    )
      return result;

    if (['uniquePaymentDate', 'limit'].includes(action.meta.field)) {
      store.dispatch(changePaysSearchLocation(getFormValues(FORM_NAME_ATTENDANCES_SEARCH)(store.getState())));
    } else {
      debouncedAttendancesSearch(store.dispatch, store.getState);
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_PAYS_SEARCH && action.type === '@@redux-form/CHANGE') {
    if (
      ['uniquePayrollRuleGroups', 'closingDay', 'paymentDay', 'paymentMonthType', 'sort', 'order'].includes(
        action.meta.field
      )
    )
      return result;

    if (['uniquePaymentDate', 'limit'].includes(action.meta.field)) {
      store.dispatch(changePaysSearchLocation(getFormValues(FORM_NAME_PAYS_SEARCH)(store.getState())));
    } else {
      debouncedPaysSearch(store.dispatch, store.getState);
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_BONUSES_SEARCH && action.type === '@@redux-form/CHANGE') {
    if (['sort', 'order'].includes(action.meta.field)) return result;

    if (['clientBonusId', 'limit'].includes(action.meta.field)) {
      store.dispatch(changeBonusesLocation(getFormValues(FORM_NAME_BONUSES_SEARCH)(store.getState())));
    } else {
      debouncedBonusesSearch(store.dispatch, store.getState);
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_REVISION_MONTHS_SEARCH) {
    if (action.type === '@@redux-form/CHANGE') {
      store.dispatch(changeRevisionsLocation(getFormValues(FORM_NAME_REVISION_MONTHS_SEARCH)(store.getState())));
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH) {
    if (action.type === '@@redux-form/CHANGE') {
      store.dispatch(
        changeNotificationOfBaseAmountRevisionsLocation(
          getFormValues(FORM_NAME_NOTIFICATION_OF_BASE_AMOUNT_MONTHS_SEARCH)(store.getState())
        )
      );
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_WAGE_LEDGER_SEARCH && action.type === '@@redux-form/CHANGE') {
    if (['sort', 'order'].includes(action.meta.field)) return result;

    if (['year', 'limit'].includes(action.meta.field)) {
      store.dispatch(changeWageLedgersLocation(getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(store.getState())));
    } else {
      debouncedWageLedgersSearch(store.dispatch, store.getState);
    }
  }
  if (_.get(action, 'meta.form') === FORM_NAME_ROLE_USERS_SEARCH && action.type === '@@redux-form/CHANGE') {
    if (['sort', 'order'].includes(action.meta.field)) return result;

    if (['clientRoles', 'limit'].includes(action.meta.field)) {
      store.dispatch(changeClientRolesLocation(getFormValues(FORM_NAME_ROLE_USERS_SEARCH)(store.getState())));
    } else {
      debouncedClientRolesSearch(store.dispatch, store.getState);
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH) {
    if (action.type === '@@redux-form/CHANGE') {
      if (['sort', 'order'].includes(action.meta.field)) return result;

      if (['bonusId', 'applicableOffice', 'page', 'limit'].includes(action.meta.field)) {
        store.dispatch(
          changeReportHealthInsuranceBonusLocation(
            getFormValues(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH)(store.getState())
          )
        );
      } else {
        debouncedReportHealthInsuranceBonusSearch(store.dispatch, store.getState);
      }
    }
  }

  if (_.get(action, 'meta.form') === FORM_NAME_FLAT_TAX_REDUCTION_SEARCH && action.type === '@@redux-form/CHANGE') {
    if (['sort', 'order'].includes(action.meta.field) || action.meta.field.endsWith('reduceTargetCount') || action.meta.field.endsWith('reduceAmount')) return result;

    if (['employmentType', 'limit'].includes(action.meta.field)) {
      store.dispatch(changeEmployeesSearchLocation(getFormValues(FORM_NAME_FLAT_TAX_REDUCTION_SEARCH)(store.getState())));
    } else {
      debouncedEmployeeFlatTaxReductionsSearch(store.dispatch, store.getState);
    }
  }

  return result;
};
