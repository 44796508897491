import axios from 'axios';

import { REDUCER_NAME as EMPLOYEE_REDUCER_NAME } from 'src/reducers/employees/employees';
import * as Urls from 'src/constants/EndpointUrls';
import { getExtras, getList, setExtras } from 'src/reducers/common/listReducer';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

export const setSelectedAllPages = selected => dispatch => {
  dispatch(setExtras(EMPLOYEE_REDUCER_NAME, { selectedAllPages: selected }));
};

export const getSelectedEmployeeIds = state => {
  const extras = getExtras(EMPLOYEE_REDUCER_NAME, state) || {};
  return extras.selectedEmployeeIds || [];
};

export const initSelectedEmployeeIds = employees => dispatch => {
  const syncedEmployeeIds = employees.filter(emp => !!emp.isSyncAttendance).map(emp => emp.id);

  dispatch(setExtras(EMPLOYEE_REDUCER_NAME, { selectedEmployeeIds: syncedEmployeeIds }));
  dispatch(setSelectedAllPages(false));
};

export const getSelectedAllPages = state => {
  const extras = getExtras(EMPLOYEE_REDUCER_NAME, state) || {};
  return !!extras.selectedAllPages;
};

export const saveEmployeeAttendanceSyncSetting = () => async (dispatch, getState) => {
  try {
    const currentState = getState();
    const extras = getExtras(EMPLOYEE_REDUCER_NAME, currentState);
    const { selectedEmployeeIds = [], selectedAllPages } = extras;

    if (selectedAllPages) {
      await axios.put(Urls.SETTING_EMPLOYEES_SYNC_ATTENDANCE_UPDATE, { selectedAllPages });
    } else {
      const employeeIds = getList(EMPLOYEE_REDUCER_NAME, currentState).map(emp => emp.id);
      const selectedEmployeeIdsStr = selectedEmployeeIds.join('_');
      const unselectedEmployeeIdsStr = employeeIds.filter(id => !selectedEmployeeIds.includes(id)).join('_');
      const data = {
        selectedEmployeeIds: selectedEmployeeIdsStr,
        unselectedEmployeeIds: unselectedEmployeeIdsStr
      };
      await axios.put(Urls.SETTING_EMPLOYEES_SYNC_ATTENDANCE_UPDATE, data);
    }

    dispatch(setGlobalSuccesses('勤怠連携設定の対象者を保存しました。'));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};
