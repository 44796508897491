import React from 'react';
import CommonModal from 'jbc-front/components/CommonModal';
import MemoForm from './MemoForm';

const MemoDataModal = ({ isOpen, hideModal, initialValues }) => {
  const header = initialValues && !!initialValues.id ? 'メモを更新' : 'メモを新規作成';

  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <MemoForm cancelBtnClick={hideModal} {...{ initialValues }} header={header} />
    </CommonModal>
  );
};

export default MemoDataModal;
