import { connect } from 'react-redux';
import { autofill } from 'redux-form';
import { setYearMonthEnd } from 'src/reducers/reports/reportWageLedger';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { FORM_NAME_WAGE_LEDGER_SEARCH } from 'src/reducers/searchForm';
import SelectBoxYear from '../components/SelectBoxYear';

const mapStateToProps = state => ({
  wageLedgerYears: getSelectOptions(state, 'wageLedgerYears'),
  wageLedgerYearMonths: getSelectOptions(state, 'wageLedgerYearMonths')
});

const mapDispatchToProps = dispatch => ({
  changeYear: (newValue, wageLedgerYearMonths) => {
    const copy = [...wageLedgerYearMonths].reverse();
    const yearMonth = copy.find(i => i.value.includes(newValue));
    dispatch(autofill(FORM_NAME_WAGE_LEDGER_SEARCH, 'yearMonthStart', yearMonth.value));
    dispatch(setYearMonthEnd(yearMonth.value, 'true'));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectBoxYear);
