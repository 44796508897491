import React from 'react';
import { connect } from 'react-redux';
import { Field, blur } from 'redux-form';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { arrowRenderer } from 'jbc-front/components/Form';
import { getSelectOptions } from 'src/reducers/selectOptions';
import {
  fetchUniquePaymentDates,
  saveUniquePayrollRuleGroupIds,
  savePayrollRefDates,
  saveUniquePaymentDate
} from 'src/reducers/searchForm';
import styles from 'src/components/PaymentDateSelectBoxes.scss';

class PaymentDateSelectBoxes extends React.Component {
  handleGroupChange = newValue => {
    const { uniquePayrollRuleGroups, formName, dispatch, onlyConfirmed = false, updateLocation = true } = this.props;
    const { closingDay, paymentDay, paymentMonthType } = uniquePayrollRuleGroups.find(item => item.value === newValue);

    dispatch(blur(formName, 'uniquePayrollRuleGroups', newValue));
    dispatch(blur(formName, 'closingDay', String(closingDay)));
    dispatch(blur(formName, 'paymentDay', String(paymentDay)));
    dispatch(blur(formName, 'paymentMonthType', String(paymentMonthType)));
    dispatch(fetchUniquePaymentDates(formName, newValue, onlyConfirmed, updateLocation));
    dispatch(saveUniquePayrollRuleGroupIds(newValue));
    dispatch(savePayrollRefDates({ closingDay, paymentDay, paymentMonthType }));
  };

  handlePaymentDateChange = (_event, newValue) => {
    const { dispatch } = this.props;
    dispatch(saveUniquePaymentDate(newValue));
  };

  render() {
    const { uniquePayrollRuleGroups, uniquePaymentDates, uniquePayrollRuleGroupsCount, onlyConfirmed } = this.props;
    const confirmedLabeledPaymentDates = uniquePaymentDates.map(date => {
      const label =
        date.confirmed === true ? (
          <span>
            <span>{date.label}</span>
            <span id="confirmed-label">※確定済</span>
          </span>
        ) : (
          date.label
        );
      return { ...date, value: date.value, label };
    });

    const alterUniquePaymentDates = onlyConfirmed
      ? confirmedLabeledPaymentDates.filter(payment => payment.confirmed)
      : confirmedLabeledPaymentDates;

    return (
      <div className={styles.container}>
        {uniquePayrollRuleGroupsCount > 1 && (
          <div className={styles.payrollRuleGroupsWrapper}>
            <Field
              name="uniquePayrollRuleGroups"
              component={SearchSelectBox}
              options={uniquePayrollRuleGroups}
              onSelect={this.handleGroupChange}
              arrowRenderer={arrowRenderer}
            />
          </div>
        )}
        <div className={styles.payrollRuleDatesWrapper}>
          <Field
            name="uniquePaymentDate"
            component={SearchSelectBox}
            options={alterUniquePaymentDates}
            arrowRenderer={arrowRenderer}
            onChange={this.handlePaymentDateChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  uniquePayrollRuleGroups: getSelectOptions(state, 'uniquePayrollRuleGroups'),
  uniquePaymentDates: getSelectOptions(state, 'uniquePaymentDates'),
  uniquePayrollRuleGroupsCount: getSelectOptions(state, 'uniquePayrollRuleGroups').length
});
export default connect(mapStateToProps)(PaymentDateSelectBoxes);
