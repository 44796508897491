import React from 'react';
import _ from 'lodash';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import humps from 'humps';
import Notifications from 'reapop';
import theme from 'reapop-theme-wybo';

import Shared from 'src/Shared';
import * as Urls from 'src/constants/EndpointUrls';
import { dispatchSessionMessages } from 'src/reducers/global';

import { getCurrentUserInfo } from 'src/reducers/users/currentUser';
import { dispatchInitCurrentClient } from 'src/reducers/clients/currentClient';

import Header from 'src/components/Header';
import SideMenu from 'src/components/SideMenu';
import Footer from 'src/components/Footer';
import ErrorPage from 'src/components/ErrorPage';

import Dashboard from 'src/features/Dashboard';
import Settings from 'src/features/Settings';
import { ClientCsvFormatList, ClientCsvFormatNew, ClientCsvFormatEdit } from 'src/features/ClientCsvFormats';
import { ClientCsvEmployeeFormatEdit, ClientCsvEmployeeFormatNew } from 'src/features/ClientCsvEmployeeFormats';
import { EditOffice, ShowOffice, OfficeList, NewOffice } from 'src/features/Offices';
import {
  EmployeeList,
  NewEmployee,
  EditEmployee,
  ShowEmployee,
  ChooseRegisterMethod,
  InsertBatchCsv,
  UpdateBatchCsv
} from 'src/features/Employees';
import { ShowAllowanceDeductionsBonus } from 'src/features/ClientBonus';
import {
  EmployeeBonusList,
  EditEmployeeBonus,
  DownloadCsvPage,
  ShowBonusFbDataPage,
  TargetEmployeeBonus
} from 'src/features/EmployeeBonus';
import {
  EmployeeAttendanceList,
  EditEmployeeAttendance,
  EmployeeAttendanceDownloadCsvPage
} from 'src/features/EmployeeAttendances';
import {
  EmployeePayList,
  EditEmployeePay,
  EmployeePayDownloadCsvPage,
  ShowPayFbDataPage,
  ShowInhabitantTaxFbPage,
  ShowAllowanceDeductions
} from 'src/features/EmployeePays';
import { PersonnelHistoriesList } from 'src/features/EmployeesPersonnelHistories';
import { EmploymentTypesList } from 'src/features/EmploymentTypes';
import { PositionsList } from 'src/features/Positions';
import { OccupationsList } from 'src/features/Occupations';
import { PayrollRulesGroupsList, NewPayrollRulesGroup, EditPayrollRulesGroup } from 'src/features/PayrollRulesGroups';
import { ClientCommutingFeeSetting } from 'src/features/ClientCommutingFeeSetting';
import { Groups, InsertBatchGroupCsv, UpdateBatchGroupCsv } from 'src/features/Groups';
import { ClientTaxCalMethodSetting } from 'src/features/ClientTaxCalMethodSetting';
import { InvitationToDashboardList } from 'src/features/EmployeeInvitations';
import { ChooseCoordinationEmployee } from 'src/features/EmployeeCoordination';
import { ChooseCoordinationEmployeeYea } from 'src/features/EmployeeCoordinationYea';
import {
  ClientAllowancesList,
  EditClientAllowance,
  NewClientAllowance,
  ChangeAllowanceDisplayOrder
} from 'src/features/ClientAllowances';
import { ClientUnitPricesList, NewClientUnitPrice, EditClientUnitPrice } from 'src/features/ClientUnitPrices';
import {
  ClientResidentTaxPaymentAddressesList,
  ClientResidentTaxPaymentInsertBatchCsv
} from 'src/features/ClientResidentTaxPaymentAddresses';
import {
  ClientBonusAllowancesList,
  NewClientBonusAllowance,
  EditClientBonusAllowance,
  ChangeBonusAllowanceDisplayOrder
} from 'src/features/ClientBonusAllowances';
import { MonthlyRevisionsList, ImportMonthlyRevisions } from 'src/features/MonthlyRevisions';
import {
  NotificationOfBaseAmountRevisionsList,
  NotificationOfBaseAmountImportRevisions
} from 'src/features/NotificationOfBaseAmountRevisions';
import { IncomeTaxCollectionDataContainer } from 'src/features/IncomeTaxCollectionData';
import Report from 'src/features/Reports';
import ReportHealthInsuranceBonusList from 'src/features/ReportHealthInsuranceBonus';
import EditReportHealthInsuranceBonusContainer from 'src/features/EditReportHealthInsuranceBonus';
import ReportWithholdingSlipList from 'src/features/ReportWithholdingSlips';
import {
  ReportLaborInsuranceList,
  ReportLaborInsuranceEmployeeList,
  ReportLaborInsuranceDownloadCsvPage
} from 'src/features/ReportLaborInsurances';
import { ReportWageLedgerList, WageLedgerExportItemList } from 'src/features/ReportWageLedger';
import { RolesList, NewRole, CopyRole, EditRole } from 'src/features/Roles';
import { RoleUsersList } from 'src/features/RoleUsers';
import { AuditLogsList, AuditLogShow } from 'src/features/AuditLogs';
import { ShowClientPaidPlan } from 'src/features/ClientPaidPlan';
import {
  NewAccountRegistration,
  AccountRegistrationCompleteNotify,
  PaidPlanRegistration,
  PaidPlanCompleted
} from 'src/features/Registrations';
import { HomePage } from 'src/features/HomePage';
import {
  FlatTaxReductionEmployees,
  UpdateFlatTaxReductionCsv,
  FlatTaxReductionAmounts,
  FlatTaxReductionConfirmations
} from 'src/features/FlatTaxReductions';
import {
  EditProfile,
  EditAccount,
  Login,
  SendResetPasswordEmail,
  SetNewPassword,
  ChangeEmailPage,
  ChangePasswordPage,
  InvalidToken,
  InvitationConfirmation
} from 'src/features/Users';
import {
  BonusDetail,
  PayrollDetail,
  IncomeTaxWithholdingsDetail,
  ElectronicDeliveryAgreement
} from 'src/features/MyData';
import {
  ClientAllowanceItemList,
  ChangeAllowanceItemDisplayOrder,
  NewClientAllowanceItem,
  EditClientAllowanceItem
} from 'src/features/ClientAllowanceItems';
import { ShowSystemAnnounce, SystemAnnounceList } from 'src/features/SystemAnnounce';
import EmployeeAnnounceList from 'src/features/EmployeeAnnounce/EmployeeAnnounceList';
import AlertsList from 'src/features/Memos/AlertsList';
import ClientAnnounceList from 'src/features/ClientAnnounce/ClientAnnounceList';
import {
  ServiceList,
  SyncJbcSettings,
  SettingEmployeeSyncAttendances,
  SettingClientDepartment
} from 'src/features/Coordinations';
import { ExternalServiceList } from 'src/features/ExternalCoordinations';
import { AdminEmailNotificationSettingList } from 'src/features/AdminEmailNotificationSetting';
import { AllowedIpList } from 'src/features/ClientAllowedIpSetting';
import { ElectronicDeliveryAgreementSetting } from 'src/features/ClientElectronicDeliveryAgreementSetting';
import EmployeeDetailList from 'src/features/ReportLaborInsurances/components/EmployeeDetailList';
import YearEndAdjStart from 'src/features/YearEndAdj/Start';
import { MyNumberList, InsertBatchMyNumberCsv } from 'src/features/MyNumbers';

import styles from './App.scss';

const store = Shared.instance.getStore();

const makeRender = (Component, props) => routerProps => <Component {...routerProps} {...props} />;

const RoutingComponents = ({ rest }) => {
  const history = Shared.instance.getHistory();
  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route exact path={Urls.USER_LOGIN} render={makeRender(Login, rest)} />
        <Route exact path={Urls.USER_LOGIN_TEMP} render={makeRender(Login, rest)} />
        <Route exact path={Urls.USER_RESET_PASSWORD_NEW} render={makeRender(SendResetPasswordEmail, rest)} />
        <Route exact path={Urls.USER_RESET_PASSWORD_EDIT} render={makeRender(SetNewPassword, rest)} />
        <Route exact path={Urls.USER_RESET_PASSWORD_INVALID_TOKEN} render={makeRender(InvalidToken, rest)} />
        <Route exact path={Urls.USER_INVITATION_CONFIRMATION} render={makeRender(InvitationConfirmation, rest)} />
        <Route exact path={Urls.CHANGE_EMAIL_PAGE_URL} render={makeRender(ChangeEmailPage, rest)} />
        <Route exact path={Urls.CHANGE_PASSWORD_PAGE_URL} render={makeRender(ChangePasswordPage, rest)} />

        <Route exact path={Urls.HOME_PAGE} render={makeRender(HomePage, rest)} />
        <Route exact path={Urls.DASHBOARD_SHOW} render={makeRender(Dashboard, rest)} />

        <Route exact path={Urls.EMPLOYEE_MY_BONUS} render={makeRender(BonusDetail, rest)} />
        <Route exact path={Urls.EMPLOYEE_MY_PAYSLIPS} render={makeRender(PayrollDetail, rest)} />
        <Route
          exact
          path={Urls.EMPLOYEE_MY_INCOME_TAX_WITHHOLDING}
          render={makeRender(IncomeTaxWithholdingsDetail, rest)}
        />
        <Route
          exact
          path={Urls.EMPLOYEE_MY_DATA_ELECTRONIC_DELIVERY_AGREEMENT}
          render={makeRender(ElectronicDeliveryAgreement, rest)}
        />

        <Route exact path={Urls.USER_PROFILE_EDIT} render={makeRender(EditProfile, rest)} />
        <Route exact path={Urls.USER_ACCOUNT_EDIT} render={makeRender(EditAccount, rest)} />

        <Route exact path={Urls.CLIENT_BONUS_ALLOWANCES_LIST} render={makeRender(ClientBonusAllowancesList, rest)} />
        <Route exact path={Urls.CLIENT_BONUS_ALLOWANCE_NEW} render={makeRender(NewClientBonusAllowance, rest)} />
        <Route exact path={Urls.CLIENT_BONUS_ALLOWANCE_EDIT} render={makeRender(EditClientBonusAllowance, rest)} />
        <Route
          exact
          path={Urls.CLIENT_BONUS_ALLOWANCE_CHANGE_DISPLAY_ORDERS}
          render={makeRender(ChangeBonusAllowanceDisplayOrder, rest)}
        />

        <Route exact path={Urls.CLIENT_ALLOWANCES_LIST} render={makeRender(ClientAllowancesList, rest)} />
        <Route exact path={Urls.CLIENT_ALLOWANCE_EDIT} render={makeRender(EditClientAllowance, rest)} />
        <Route exact path={Urls.CLIENT_ALLOWANCE_NEW} render={makeRender(NewClientAllowance, rest)} />
        <Route
          exact
          path={Urls.CLIENT_ALLOWANCE_CHANGE_DISPLAY_ORDERS}
          render={makeRender(ChangeAllowanceDisplayOrder, rest)}
        />
        <Route exact path={Urls.CLIENT_UNIT_PRICES_LIST} render={makeRender(ClientUnitPricesList, rest)} />
        <Route exact path={Urls.CLIENT_UNIT_PRICE_NEW} render={makeRender(NewClientUnitPrice, rest)} />
        <Route exact path={Urls.CLIENT_UNIT_PRICE_EDIT} render={makeRender(EditClientUnitPrice, rest)} />

        <Route exact path={Urls.CLIENT_PAYROLL_RULES_GROUP_LIST} render={makeRender(PayrollRulesGroupsList, rest)} />
        <Route exact path={Urls.CLIENT_PAYROLL_RULES_GROUP_NEW} render={makeRender(NewPayrollRulesGroup, rest)} />
        <Route exact path={Urls.CLIENT_PAYROLL_RULES_GROUP_EDIT} render={makeRender(EditPayrollRulesGroup, rest)} />
        <Route exact path={Urls.COPY_CLIENT_PAYROLL_RULES_GROUP_URL} render={makeRender(NewPayrollRulesGroup, rest)} />

        <Route exact path={Urls.CLIENT_SETTINGS} render={makeRender(Settings, rest)} />
        <Route exact path={Urls.CLIENT_EMPLOYMENT_TYPES} render={makeRender(EmploymentTypesList, rest)} />
        <Route exact path={Urls.CLIENT_POSITIONS} render={makeRender(PositionsList, rest)} />
        <Route exact path={Urls.CLIENT_OCCUPATIONS} render={makeRender(OccupationsList, rest)} />
        <Route exact path={Urls.CLIENT_GROUPS} render={makeRender(Groups, rest)} />
        <Route exact path={Urls.INSERT_BATCH_CLIENT_GROUPS_URL} render={makeRender(InsertBatchGroupCsv, rest)} />
        <Route exact path={Urls.UPDATE_BATCH_CLIENT_GROUPS_URL} render={makeRender(UpdateBatchGroupCsv, rest)} />
        <Route exact path={Urls.CLIENT_COMMUTING_FEE_SETTINGS} render={makeRender(ClientCommutingFeeSetting, rest)} />
        <Route exact path={Urls.CLIENT_TAX_CAL_METHOD_SETTING} render={makeRender(ClientTaxCalMethodSetting, rest)} />
        <Route exact path={Urls.AUDIT_LOGS_LIST} render={makeRender(AuditLogsList, rest)} />
        <Route exact path={Urls.AUDIT_LOG_SHOW} render={makeRender(AuditLogShow, rest)} />
        <Route exact path={Urls.CLIENT_SETTING_USING_PLAN} render={makeRender(ShowClientPaidPlan, rest)} />
        <Route exact path={Urls.CLIENT_CSV_FORMATS_LIST} render={makeRender(ClientCsvFormatList, rest)} />
        <Route exact path={Urls.CLIENT_CSV_FORMAT_NEW} render={makeRender(ClientCsvFormatNew, rest)} />
        <Route exact path={Urls.CLIENT_CSV_FORMAT_EDIT} render={makeRender(ClientCsvFormatEdit, rest)} />
        <Route exact path={Urls.CLIENT_CSV_EMPLOYEE_FORMAT_NEW} render={makeRender(ClientCsvEmployeeFormatNew, rest)} />
        <Route
          exact
          path={Urls.CLIENT_CSV_EMPLOYEE_FORMAT_EDIT}
          render={makeRender(ClientCsvEmployeeFormatEdit, rest)}
        />

        <Route
          exact
          path={Urls.CLIENT_RESIDENT_TAX_PAYMENTS_LIST}
          render={makeRender(ClientResidentTaxPaymentAddressesList, rest)}
        />
        <Route
          exact
          path={Urls.CLIENT_RESIDENT_TAX_PAYMENTS_INSERT_BATCH_CSV}
          render={makeRender(ClientResidentTaxPaymentInsertBatchCsv, rest)}
        />

        <Route exact path={Urls.LIST_OFFICES_URL} render={makeRender(OfficeList, rest)} />
        <Route exact path={Urls.OFFICE_NEW} render={makeRender(NewOffice, rest)} />
        <Route exact path={Urls.OFFICE_SHOW} render={makeRender(ShowOffice, rest)} />
        <Route exact path={Urls.OFFICE_EDIT} render={makeRender(EditOffice, rest)} />

        <Route
          exact
          path={Urls.EMPLOYEE_ATTENDANCE_DOWNLOAD_CSV_PAGE}
          render={makeRender(EmployeeAttendanceDownloadCsvPage, rest)}
        />
        <Route exact path={Urls.EMPLOYEE_ATTENDANCE_LIST} render={makeRender(EmployeeAttendanceList, rest)} />
        <Route exact path={Urls.EMPLOYEE_ATTENDANCE_EDIT} render={makeRender(EditEmployeeAttendance, rest)} />

        <Route exact path={Urls.EMPLOYEES_PERSONNEL_HISTORIES} render={makeRender(PersonnelHistoriesList, rest)} />

        <Route
          exact
          path={Urls.SHOW_ALLOWANCE_DEDUCTIONS_BONUS}
          render={makeRender(ShowAllowanceDeductionsBonus, rest)}
        />

        <Route exact path={Urls.EMPLOYEE_BONUS_LIST} render={makeRender(EmployeeBonusList, rest)} />
        <Route exact path={Urls.EMPLOYEE_BONUS_EDIT} render={makeRender(EditEmployeeBonus, rest)} />
        <Route exact path={Urls.EMPLOYEE_BONUS_DOWNLOAD_CSV_PAGE_URL} render={makeRender(DownloadCsvPage, rest)} />
        <Route exact path={Urls.SHOW_BONUS_FB_DATA_PAGE_URL} render={makeRender(ShowBonusFbDataPage, rest)} />
        <Route exact path={Urls.TARGET_EMPLOYEE_BONUS} render={makeRender(TargetEmployeeBonus, rest)} />

        <Route exact path={Urls.SHOW_ALLOWANCE_DEDUCTIONS_PAY} render={makeRender(ShowAllowanceDeductions, rest)} />
        <Route exact path={Urls.EMPLOYEE_PAY_DOWNLOAD_CSV_PAGE} render={makeRender(EmployeePayDownloadCsvPage, rest)} />
        <Route exact path={Urls.SHOW_INHABITANT_TAX_FB_PAGE} render={makeRender(ShowInhabitantTaxFbPage, rest)} />
        <Route exact path={Urls.SHOW_PAY_FB_DATA_PAGE_URL} render={makeRender(ShowPayFbDataPage, rest)} />
        <Route exact path={Urls.EMPLOYEE_PAY_LIST} render={makeRender(EmployeePayList, rest)} />
        <Route exact path={Urls.EMPLOYEE_PAY_EDIT} render={makeRender(EditEmployeePay, rest)} />

        <Route exact path={Urls.EMPLOYEE_INVITATIONS_LIST} render={makeRender(InvitationToDashboardList, rest)} />

        <Route exact path={Urls.EMPLOYEES_LIST} render={makeRender(EmployeeList, rest)} />
        <Route exact path={Urls.CHOOSE_REGISTER_METHOD} render={makeRender(ChooseRegisterMethod, rest)} />
        <Route exact path={Urls.INSERT_BATCH_CSV} render={makeRender(InsertBatchCsv, rest)} />
        <Route exact path={Urls.UPDATE_BATCH_CSV} render={makeRender(UpdateBatchCsv, rest)} />
        <Route exact path={Urls.EMPLOYEE_NEW} render={makeRender(NewEmployee, rest)} />
        <Route exact path={Urls.CHOOSE_COORDINATION_EMPLOYEE} render={makeRender(ChooseCoordinationEmployee, rest)} />
        <Route
          exact
          path={Urls.CHOOSE_COORDINATION_EMPLOYEE_YEA}
          render={makeRender(ChooseCoordinationEmployeeYea, rest)}
        />
        <Route exact path={Urls.EMPLOYEE_SHOW} render={makeRender(ShowEmployee, rest)} />
        <Route exact path={Urls.EMPLOYEE_EDIT} render={makeRender(EditEmployee, rest)} />

        <Route
          exact
          path={Urls.REPORT_HEALTH_INSURANCE_BONUS_LIST}
          render={makeRender(ReportHealthInsuranceBonusList, rest)}
        />
        <Route
          exact
          path={Urls.REPORT_HEALTH_INSURANCE_BONUS_ITEM_EDIT}
          render={makeRender(EditReportHealthInsuranceBonusContainer, rest)}
        />
        <Route exact path={Urls.REPORT_WITHHOLDING_SLIP_LIST} render={makeRender(ReportWithholdingSlipList, rest)} />
        <Route
          exact
          path={Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_DOWNLOAD_CSV_PAGE}
          render={makeRender(ReportLaborInsuranceDownloadCsvPage, rest)}
        />
        <Route
          exact
          path={Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_EDIT}
          render={makeRender(EmployeeDetailList, rest)}
        />
        <Route exact path={Urls.REPORT_LABOR_INSURANCE_SUMMARIES} render={makeRender(ReportLaborInsuranceList, rest)} />
        <Route
          exact
          path={Urls.REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS}
          render={makeRender(ReportLaborInsuranceEmployeeList, rest)}
        />
        <Route
          exact
          path={Urls.REPORT_LABOR_INSURANCE_DECLARATIONS}
          render={makeRender(ReportLaborInsuranceList, rest)}
        />
        <Route exact path={Urls.WAGE_LEDGER} render={makeRender(ReportWageLedgerList, rest)} />
        <Route exact path={Urls.REPORT_INDEX} render={makeRender(Report, rest)} />

        <Route exact path={Urls.MONTHLY_REVISION_LIST} render={makeRender(MonthlyRevisionsList, rest)} />
        <Route exact path={Urls.MONTHLY_REVISION_INSERT_BATCH_CSV} render={makeRender(ImportMonthlyRevisions, rest)} />
        <Route
          exact
          path={Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_LIST}
          render={makeRender(NotificationOfBaseAmountRevisionsList, rest)}
        />
        <Route
          exact
          path={Urls.NOTIFICATION_OF_BASE_AMOUNT_REVISION_INSERT_BATCH_CSV}
          render={makeRender(NotificationOfBaseAmountImportRevisions, rest)}
        />
        <Route
          exact
          path={Urls.INCOME_TAX_COLLECTION_DATA}
          render={makeRender(IncomeTaxCollectionDataContainer, rest)}
        />
        <Route exact path={Urls.CLIENT_ROLES_LIST} render={makeRender(RolesList, rest)} />
        <Route exact path={Urls.CLIENT_ROLES_NEW} render={makeRender(NewRole, rest)} />
        <Route exact path={Urls.CLIENT_ROLES_COPY} render={makeRender(CopyRole, rest)} />
        <Route exact path={Urls.CLIENT_ROLES_EDIT} render={makeRender(EditRole, rest)} />
        <Route exact path={Urls.CLIENT_ROLE_USERS_LIST} render={makeRender(RoleUsersList, rest)} />

        <Route exact path={Urls.CLIENT_ALLOWANCE_ITEMS} render={makeRender(ClientAllowanceItemList, rest)} />
        <Route
          exact
          path={Urls.CLIENT_ALLOWANCE_ITEM_CHANGE_DISPLAY_ORDERS}
          render={makeRender(ChangeAllowanceItemDisplayOrder, rest)}
        />
        <Route exact path={Urls.CLIENT_ALLOWANCE_ITEM_NEW} render={makeRender(NewClientAllowanceItem, rest)} />
        <Route exact path={Urls.CLIENT_ALLOWANCE_ITEM_EDIT} render={makeRender(EditClientAllowanceItem, rest)} />

        <Route exact path={Urls.SHOW_SYSTEM_ANNOUNCE} render={makeRender(ShowSystemAnnounce, rest)} />
        <Route exact path={Urls.SYSTEM_ANNOUNCE_LIST} render={makeRender(SystemAnnounceList, rest)} />

        <Route exact path={Urls.EMPLOYEE_ANNOUNCE_LIST} render={makeRender(EmployeeAnnounceList, rest)} />
        <Route exact path={Urls.ACCOUNT_REGISTRATION_NEW} render={makeRender(NewAccountRegistration, rest)} />
        <Route exact path="/registrations/paid_plan" render={makeRender(PaidPlanRegistration, rest)} />
        <Route exact path="/registrations/paid_plan/completed" render={makeRender(PaidPlanCompleted, rest)} />
        <Route
          exact
          path={Urls.ACCOUNT_REGISTRATION_COMPLETED_NOTIFY}
          render={makeRender(AccountRegistrationCompleteNotify, rest)}
        />
        <Route exact path={Urls.COORDINATION_SERVICE_LIST} render={makeRender(ServiceList, rest)} />
        <Route exact path={Urls.EXTERNAL_COORDINATION_SERVICE_LIST} render={makeRender(ExternalServiceList, rest)} />
        <Route exact path={Urls.COORDINATION_JBC_SETTINGS} render={makeRender(SyncJbcSettings, rest)} />
        <Route
          exact
          path={Urls.SETTING_EMPLOYEES_SYNC_ATTENDANCE_SHOW}
          render={makeRender(SettingEmployeeSyncAttendances, rest)}
        />
        <Route
          exact
          path={Urls.COORDINATIONS_SETTING_CLIENT_DEPARTMENT}
          render={makeRender(SettingClientDepartment, rest)}
        />

        <Route exact path={Urls.ALERTS_LIST} render={makeRender(AlertsList, rest)} />
        <Route exact path={Urls.WAGE_LEDGER_EXPORT_ITEM_LIST} render={makeRender(WageLedgerExportItemList, rest)} />

        <Route exact path={Urls.CLIENT_ANNOUNCES} render={makeRender(ClientAnnounceList, rest)} />
        <Route
          exact
          path={Urls.EMAIL_NOTIFICATION_SETTING}
          render={makeRender(AdminEmailNotificationSettingList, rest)}
        />
        <Route exact path={Urls.ALLOWED_IP_SETTING} render={makeRender(AllowedIpList, rest)} />
        <Route exact path={Urls.YEAR_END_ADJ_START} render={makeRender(YearEndAdjStart, rest)} />
        <Route exact path={Urls.MY_NUMBER_LIST} render={makeRender(MyNumberList, rest)} />
        <Route exact path={Urls.INSERT_BATCH_MY_NUMBER_CSV} render={makeRender(InsertBatchMyNumberCsv, rest)} />
        <Route
          exact
          path={Urls.CLIENT_ELECTTONIC_DELIVERY_AGREEMENT_SETTING}
          render={makeRender(ElectronicDeliveryAgreementSetting, rest)}
        />
        <Route
          exact
          path={Urls.FLAT_TAX_REDUCTION_EMPLOYEE_LIST}
          render={makeRender(FlatTaxReductionEmployees, rest)}
        />
        <Route exact path={Urls.FLAT_TAX_REDUCTION_AMOUNT_LIST} render={makeRender(FlatTaxReductionAmounts, rest)} />
        <Route
          exact
          path={Urls.FLAT_TAX_REDUCTION_CONFIRMATION_LIST}
          render={makeRender(FlatTaxReductionConfirmations, rest)}
        />
        <Route exact path={Urls.FLAT_TAX_REDUCTION_UPLOAD_CSV} render={makeRender(UpdateFlatTaxReductionCsv, rest)} />
      </Switch>
    </ConnectedRouter>
  );
};

export default originProps => {
  const rest = humps.camelizeKeys(originProps);
  // const currentState = store.getState()
  const currentUser = getCurrentUserInfo(store.getState());

  let { isAuthorized } = currentUser;
  const { sessionMessages = {}, currentClient = {} } = store.getState().master;

  if (Object.keys(sessionMessages).length > 0) {
    dispatchSessionMessages(store.dispatch, sessionMessages);
  }

  if (Object.keys(currentClient || {}).length > 0) {
    dispatchInitCurrentClient(store.dispatch, currentClient);
  }
  const errorStatus = _.get(rest, 'errorStatus');
  const errorMessage = _.get(rest, 'errorMessage');
  const errorFlashMessage = _.get(rest, 'errorFlashMessage');
  if (!_.isEmpty(errorStatus)) {
    isAuthorized = false;
  }

  return (
    <Provider store={store}>
      <div>
        <Notifications theme={theme} />
        <Header isAuthorized={isAuthorized} isVerified={currentClient.isVerified} currentUser={currentUser} />
        <div className={styles.main}>
          {isAuthorized && <SideMenu />}
          {!_.isEmpty(errorStatus) && (
            <ErrorPage errorStatus={errorStatus} errorMessage={errorMessage} errorFlashMessage={errorFlashMessage} />
          )}
          <div className={isAuthorized ? styles.marginSidebar : null}>
            <div className="l-main-contents">{_.isEmpty(errorStatus) && <RoutingComponents rest={rest} />}</div>
            <Footer />
          </div>
        </div>
      </div>
    </Provider>
  );
};
