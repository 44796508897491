import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';

import { addQuery } from 'src/reducers/common/listReducer';
import { Pulldown } from 'jbc-front/components/icons';
import styles from './SortableHead.scss';

const STAFF_CODE_FIELD = 'staffCode';
const DESC = 'desc';
const ASC = 'asc';

const orderValueToIcon = value =>
  value && value === DESC ? <Pulldown size={8} /> : <Pulldown size={8} className={styles.asc} />;
const reverseOrderValue = value => (value && value === DESC ? ASC : DESC);

// 源泉徴収の独自処理になってしまっているので、以下で統合する
// https://github.com/d-o-n-u-t-s/payrollservice/issues/6085
const SortableHead = props => {
  const { sortField, queries, label, changeSortType } = props;
  const { order = ASC } = queries || {};
  let { sort } = queries || {};
  if (!sort && sortField === STAFF_CODE_FIELD) {
    sort = STAFF_CODE_FIELD;
  } // デフォルトはスタッフコード
  const icon = isEqual(sort, sortField) ? orderValueToIcon(order) : '';
  const nextOrderValue = isEqual(sort, sortField) ? reverseOrderValue(order) : ASC;

  return (
    <span role="presentation" className={styles.label} onClick={() => changeSortType(sortField, nextOrderValue)}>
      {label}
      <span className={styles.icon}>{icon}</span>
    </span>
  );
};

SortableHead.propTypes = {
  queries: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  changeSortType: (sortField, nextOrderValue) => {
    const { reducerName, afterSortChange } = ownProps;
    dispatch(addQuery(reducerName, 'sort', sortField));
    dispatch(addQuery(reducerName, 'order', nextOrderValue));
    dispatch(afterSortChange());
  }
});

export default connect(null, mapDispatchToProps)(SortableHead);
