import React from 'react';
import _ from 'lodash';
import classNames from 'classnames';

import { Section } from 'jbc-front/components/Form';

import { INHABITANT_TAX_COLLECTION_METHODS } from 'src/constants/Options';
import { getOptionLabel, numberWithComma, display } from 'src/utils/Utils';

import { annualInhabitantTax } from '../inhabitantTaxCommon';

import styles from './InhabitantTaxSectionShow.scss';
import tableStyles from '../styles/GeneralTableInfo.scss';

class InhabitantTaxSectionShow extends React.Component {
  render() {
    const { employeeInhabitantTax, prefectures } = this.props;
    return (
      <div>
        <Section title="納付先市区町村名">
          <table className={tableStyles.mTablePersonal}>
            <tbody>
              <tr>
                <th>都道府県：</th>
                <td>{display(getOptionLabel(employeeInhabitantTax.payeePrefectureId)(prefectures))}</td>
              </tr>
              <tr>
                <th>市区町村：</th>
                <td>{display(_.get(employeeInhabitantTax, 'payeeCity.name'))}</td>
              </tr>
              <tr>
                <th>市区町村コード：</th>
                <td>{display(_.get(employeeInhabitantTax, 'payeeCity.organizationCode'))}</td>
              </tr>
            </tbody>
          </table>
        </Section>
        <Section title="給与支払報告書提出先">
          <table className={tableStyles.mTablePersonal}>
            <tbody>
              <tr>
                <th>都道府県：</th>
                <td>{display(getOptionLabel(employeeInhabitantTax.reportSubmissionPrefectureId)(prefectures))}</td>
              </tr>
              <tr>
                <th>市区町村：</th>
                <td>{display(_.get(employeeInhabitantTax, 'reportSubmissionCity.name'))}</td>
              </tr>
              <tr>
                <th>市区町村コード：</th>
                <td>{display(_.get(employeeInhabitantTax, 'reportSubmissionCity.organizationCode'))}</td>
              </tr>
            </tbody>
          </table>
        </Section>
        <Section title="金額">
          <table className={tableStyles.mTablePersonal}>
            <tbody>
              <tr>
                <th>住民税徴収方法：</th>
                <td>
                  {display(getOptionLabel(employeeInhabitantTax.collectionMethod)(INHABITANT_TAX_COLLECTION_METHODS))}
                </td>
              </tr>
              {employeeInhabitantTax.collectionMethod === 'special' && (
                <React.Fragment>
                  <tr>
                    <th>住民税額設定</th>
                    <td />
                  </tr>
                  <tr>
                    <th />
                    {/* thとtdのtopにborderがスタイルされているためスタイル調整のために空のリストを入れる */}
                    <td />
                  </tr>
                </React.Fragment>
              )}
            </tbody>
          </table>
          {employeeInhabitantTax.collectionMethod === 'special' && this.renderTaxAmounts()}
        </Section>
      </div>
    );
  }

  // 住民税額の描画
  renderTaxAmounts = () => {
    const { taxAmounts } = this.props;
    const leftMonthAmounts = [6, 7, 8, 9, 10, 11].map(month => _.find(taxAmounts, ta => ta.month === month));
    const rightMonthAmounts = [12, 1, 2, 3, 4, 5].map(month => _.find(taxAmounts, ta => ta.month === month));
    return (
      <div>
        <table className={classNames(tableStyles.mTablePersonal, styles.taxAmounts)}>
          <tbody>
            <tr>
              <td>
                <div className={classNames(styles.taxAmountsColumn, 'u-ml20')}>
                  <div className={styles.taxAmountsCell}>
                    <div>年税額</div>
                    <div>{display(annualInhabitantTax(taxAmounts))}</div>
                  </div>
                </div>
              </td>
              <td />
            </tr>
            <tr>
              <td>
                <div className={classNames(styles.taxAmountsColumn, 'u-ml20')}>
                  {leftMonthAmounts.map(amount => this.renderAmount(amount))}
                </div>
              </td>
              <td>
                <div className={classNames(styles.taxAmountsColumn, 'u-ml20')}>
                  {rightMonthAmounts.map(amount => this.renderAmount(amount))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  renderAmount = taxAmount => (
    <div key={taxAmount.month} className={styles.taxAmountsCell}>
      <div>{taxAmount.month}月</div>
      <div>{numberWithComma(taxAmount.amount)}円</div>
    </div>
  );
}

export default InhabitantTaxSectionShow;
