import React from 'react';
import _ from 'lodash';
import * as Urls from 'src/constants/EndpointUrls';
import styles from './withSetupNeeded.scss';

const withSetupNeeded = title => Child => ({ notCompletedSetting, ...rest }) => {
  if (_.isEmpty(notCompletedSetting)) {
    return <Child {...rest} />;
  }
  return (
    <div className={styles.nolist}>
      <p>
        {title}
        <br />
        <a href={Urls.DASHBOARD_SHOW} className="u-txt-link">
          ダッシュボード
        </a>からセットアップを完了させてください。
      </p>
    </div>
  );
};

export default withSetupNeeded;
