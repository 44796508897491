import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import * as Urls from 'src/constants/EndpointUrls';
import { Pulldown } from 'jbc-front/components/icons';
import { openNewTab, redirectTo } from 'src/utils/Http';
import styles from './ExtraActionsSection.scss';

const AllowanceDeductionsMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      const { downloadTemplateCsvPageUrl, confirmed, currentBonus, downloadAccountingBonus } = this.props;

      return (
        <div className={styles.baloonWrap}>
          <div className={styles.baloon}>
            <ul className={styles.links}>
              <li>
                <a
                  disabled={!confirmed}
                  className={!confirmed ? styles.disabled : null}
                  onClick={() =>
                    redirectTo(`${Urls.SHOW_ALLOWANCE_DEDUCTIONS_BONUS}?client_bonus_id=${currentBonus.value}`)
                  }
                  role="presentation"
                >
                  <Pulldown size={8} />
                  支給・控除等一覧表
                </a>
              </li>
              <li>
                <a
                  onClick={() => openNewTab(downloadTemplateCsvPageUrl)}
                  className={currentBonus === undefined || confirmed ? styles.disabled : undefined}
                  role="presentation"
                >
                  <Pulldown size={8} />
                  支給・控除項目CSVインポート
                </a>
              </li>
              <li>
                <a
                  disabled={!confirmed}
                  className={confirmed ? null : styles.disabled}
                  onClick={() => downloadAccountingBonus()}
                  role="presentation"
                >
                  <Pulldown size={8} />ジョブカン会計連携用CSVダウンロード
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }
);

export default AllowanceDeductionsMethod;
