import { connect } from 'react-redux';
import { change } from 'redux-form';
import {
  fetchReportHealthInsuranceBonusOfficeOptions,
  FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH
} from 'src/reducers/searchForm';
import SelectBoxGroups from '../components/SelectBoxGroups';

const mapDispatchToProps = dispatch => ({
  changeFormValue: (field, value) => {
    dispatch(change(FORM_NAME_REPORT_HEALTH_INSURANCE_BONUS_SEARCH, field, value));
  },
  fetchReportHealthInsuranceBonusOfficeOptions: bonusId =>
    dispatch(fetchReportHealthInsuranceBonusOfficeOptions(bonusId))
});
export default connect(null, mapDispatchToProps)(SelectBoxGroups);
