import React from 'react';

import { TextField, SelectField } from 'jbc-front/components/Form';
import { isNumber, zenkakuKatakana } from 'src/utils/CustomValidators';
import { TelField } from 'src/components';

const LabourConsultantSection = ({ submitters, prefectures }) => (
  <div>
    <TextField label="社労士名" name="name" />

    <TextField label="社労士名（カナ）" name="nameKana" validate={zenkakuKatakana} />

    <TextField label="社労士コード" name="labourConsultantCode" validate={isNumber} maxLength="8" />

    <SelectField label="提出元" name="submitter" options={submitters} />

    <SelectField label="社会保険労務士都道府県会" name="organizationPrefectureId" options={prefectures} />

    <TextField label="社労士事務所名" name="organizationName" />

    <TelField />
  </div>
);
export default LabourConsultantSection;
