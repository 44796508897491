import React from 'react';
import _ from 'lodash';
import Cookies from 'js-cookie'; // eslint-disable-line
import moment from 'moment';
import {
  CLIENT_SETTINGS,
  EMPLOYEES_LIST,
  LIST_OFFICES_URL,
  // CLIENT_GROUPS,
  COORDINATION_JBC_SETTINGS,
  EDIT_CLIENT_SETTING_USING_PLAN
} from 'src/constants/EndpointUrls';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import Button from 'jbc-front/components/Button';
import { Section } from 'jbc-front/components/Form';
import { KintaiLogo, RoumuLogo, Link, UnLink, Setting } from 'jbc-front/components/icons';
import { HYPHEN_CHARACTER } from 'src/constants/Generals';
import styles from './ServiceList.scss';

const isSync = syncSettings => !!syncSettings.find(setting => setting.isSyncedWithKintai);

const syncsWithLms = () => Cookies.get('using_jbc_lms') === 'true';

const ServiceList = ({ services, client, lmsSyncStatuses, accessToken }) => {
  const jbcKintaiSettings = services.jbcKintai || [];
  return (
    <React.Fragment>
      <div className="l-main-title-wrap">
        <h1 className="m-title-main">サービス連携</h1>
        <p className="m-title-main-note">ジョブカン共通ID連携など、サービス連携を設定できます</p>
      </div>
      <div className="l-wrap-s l-contents-wrap">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="サービス連携" />
        </BreadCrumb>
        {client.planType === 'free_plan' && (
          <Section title="サービス連携" className="u-mt40">
            <div>
              ジョブカンシリーズとのサービス連携は無料プランではご利用いただけません。<br />
              サービス連携の利用をご希望の場合は、以下より有料プランの申込を行ってください。
            </div>
            <div className="u-mt50 u-mb20 u-ta-c">
              <Button success large as="a" href={EDIT_CLIENT_SETTING_USING_PLAN}>
                有料プランの申込はこちら
              </Button>
            </div>
          </Section>
        )}
        {client.planType !== 'free_plan' && (
          <React.Fragment>
            <p className={styles.lead}>連携することで各項目をAPIで情報の連携をすることができます。</p>
            <div className={styles.notes}>
              <div className={styles.serviceWrap}>
                <p className={styles.serviceName}>【ジョブカン勤怠管理】</p>
                <p>勤怠データを取得することができます。</p>
              </div>
              <div className={styles.serviceWrap}>
                <p className={styles.serviceName}>【ジョブカン労務HR】</p>
                <ul className={styles.buttons}>
                  <li>
                    <Button as="a" href={EMPLOYEES_LIST}>
                      従業員情報
                    </Button>
                  </li>
                  <li>
                    <Button as="a" href={LIST_OFFICES_URL}>
                      事業所情報
                    </Button>
                  </li>
                </ul>
                <p>上記の情報を連携することができます</p>
              </div>
            </div>
            <div className="l-overflow-scroll">
              <table className={styles.table}>
                <thead>
                  <tr>
                    <td />
                    <td>サービス名</td>
                    <td>ステータス</td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <a href="https://jobcan.ne.jp/" rel="noopener noreferrer" target="_blank">
                        <KintaiLogo size={227} />
                      </a>
                    </th>
                    <td>
                      <a
                        href="https://ssl.jobcan.jp/login/client"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="u-txt-link"
                      >
                        ジョブカン勤怠管理
                      </a>
                    </td>
                    <td>
                      {isSync(jbcKintaiSettings) && !_.isEmpty(accessToken) ? (
                        <div className={styles.linked}>
                          <Link size={23} />連携済<a href={COORDINATION_JBC_SETTINGS} className={styles.setting}>
                            {!_.isEmpty(accessToken) && <Setting size={18} />}
                          </a>
                        </div>
                      ) : (
                        <div className={styles.nolink}>
                          <UnLink size={23} />未連携
                          <a href={COORDINATION_JBC_SETTINGS} className={styles.setting}>
                            {!_.isEmpty(accessToken) && <Setting size={18} />}
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <a href="https://lms.jobcan.jp/" rel="noopener noreferrer" target="_blank">
                        <RoumuLogo size={222} />
                      </a>
                    </th>
                    <td>
                      <a
                        href="https://lms.jobcan.jp/login"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="u-txt-link"
                      >
                        ジョブカン労務HR
                      </a>
                    </td>
                    <td>
                      {syncsWithLms() ? (
                        <React.Fragment>
                          <div className={styles.linked}>
                            <Link size={23} />連携済
                          </div>
                          <div className={styles.linkedWrap}>
                            <dl className={styles.linkedDate}>
                              <dt>従業員情報の最終連携日時</dt>
                              <dd>
                                {lmsSyncStatuses.employee
                                  ? moment(lmsSyncStatuses.employee.syncedAt).format('LLL')
                                  : HYPHEN_CHARACTER}
                              </dd>
                            </dl>
                            <dl className={styles.linkedDate}>
                              <dt>法人情報の最終連携日時</dt>
                              <dd>
                                {lmsSyncStatuses.client
                                  ? moment(lmsSyncStatuses.client.syncedAt).format('LLL')
                                  : HYPHEN_CHARACTER}
                              </dd>
                            </dl>
                          </div>
                        </React.Fragment>
                      ) : (
                        <div className={styles.nolink}>
                          <UnLink size={23} />未連携
                        </div>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default ServiceList;
