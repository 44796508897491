import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import * as Urls from 'src/constants/EndpointUrls';
import { Pulldown } from 'jbc-front/components/icons';
import { redirectTo } from 'src/utils/Http';
import styles from './ActionSection.scss';

const AllowanceDeductionsMethod = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };

    render() {
      const { downloadTemplateCsvPageUrl, isConfirmed, downloadAccountingPay } = this.props;

      return (
        <div className={styles.baloonWrap}>
          <div className={styles.baloon}>
            <ul className={styles.links}>
              <li>
                <a
                  disabled={!isConfirmed}
                  className={isConfirmed ? null : styles.disabled}
                  onClick={() => redirectTo(Urls.SHOW_ALLOWANCE_DEDUCTIONS_PAY)}
                  role="presentation"
                >
                  <Pulldown size={8} />支給・控除等一覧表
                </a>
              </li>
              <li>
                <a
                  className={isConfirmed ? styles.disabled : undefined}
                  onClick={() => redirectTo(downloadTemplateCsvPageUrl)}
                  disabled={isConfirmed}
                  role="presentation"
                >
                  <Pulldown size={8} />支給・控除項目CSVインポート
                </a>
              </li>
              <li>
                <a
                  disabled={!isConfirmed}
                  className={isConfirmed ? null : styles.disabled}
                  onClick={() => downloadAccountingPay()}
                  role="presentation"
                >
                  <Pulldown size={8} />ジョブカン会計連携用CSVダウンロード
                </a>
              </li>
            </ul>
          </div>
        </div>
      );
    }
  }
);

export default AllowanceDeductionsMethod;
