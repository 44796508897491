import React from 'react';
import { FieldArray, FormSection, Field } from 'redux-form';

import { Edditing } from 'jbc-front/components/icons';
import { numberWithComma } from 'src/utils/Utils';
import { isMoneyNumber } from 'src/utils/CustomValidators';
import { isModeEdit } from './EmployeeBonusForm';
import { EditableTextField } from './EditableTextField';
import styles from './BonusSection.scss';

const Deductions = props => {
  const { fields, mode, totalDeductionAmount } = props;
  return (
    <tbody>
      <tr>
        <th>控除合計</th>
        <td>
          <span className="m-table-obvious-inner">{numberWithComma(totalDeductionAmount)}</span>
        </td>
      </tr>
      {fields.map((item, index) => {
        const deduction = fields.get(index);
        const disableNameEdit = ['flat_tax_reduction'].includes(deduction.formulaOrderType);

        return (
          <tr key={deduction.id}>
            <th>{deduction.name}</th>
            <td>
              <FormSection name={item} key={deduction.id}>
                {!isModeEdit(mode) && (
                  <div className="m-table-obvious-inner">
                    <div className="m-table-obvious-modified">
                      {deduction.inputType === 'manual' ? (
                        <span className={styles.icon}>
                          <Edditing size={14} />
                          <span className={styles.baloon}>
                            「手入力」「CSVインポート」「外部システム連携」のいずれかにより修正された項目です。<br />
                            自動計算に戻したい場合は、編集画面から「手入力前に戻す」を押してください。
                          </span>
                        </span>
                      ) : null}
                    </div>
                    {numberWithComma(deduction.amount)}
                  </div>
                )}
                {isModeEdit(mode) && (
                  <Field
                    name="amount"
                    type="text"
                    component={EditableTextField}
                    validate={isMoneyNumber}
                    disabled={!isModeEdit(mode) || disableNameEdit}
                    modified={deduction.inputType === 'manual'}
                  />
                )}
              </FormSection>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

const DeductionSection = ({ mode, totalDeductionAmount }) => (
  <table className="m-table-obvious">
    <thead>
      <tr>
        <th colSpan="2">控除</th>
      </tr>
    </thead>
    <FieldArray
      name="deductions"
      component={Deductions}
      mode={mode}
      totalDeductionAmount={totalDeductionAmount}
      rerenderOnEveryChange
    />
  </table>
);

export default DeductionSection;
