export const ERROR = 'error';

export const BUTTON = 'button';
export const BUTTON_PRIMARY = 'button-primary';

export const FORM = 'form';
export const FORM_ERRORS = 'form-errors';
export const FORM_GROUP = 'form-group';
export const FORM_CONTROL_WRAPPERS = 'form-control-wrappers';
export const FORM_CONTROL_WRAPPER = 'form-control-wrapper';
export const FORM_CONTROL = 'form-control';

export const HAS_ERROR = 'has-error';

export const CLIENT_FORM = 'client-form';
export const EMPLOYEE_FORM = 'employee-form';
export const CLIENT_INSURANCE_FORM = 'client-insurance-form';
export const CLIENT_HEALTH_INSURANCE = 'health';
export const CLIENT_PENSION_INSURANCE = 'pension';
export const CLIENT_EMPLOYMENT_INSURANCE = 'employment';
export const CLIENT_ACCIDENT_INSURANCE = 'accident';

export const SEARCH_ATTENDANCES_FORM = 'search-attendances-form';
