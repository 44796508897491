import React from 'react';
import { connect } from 'react-redux';

import { getList } from 'src/reducers/common/listReducer';
import { REDUCER_NAME, deleteUnitPrice, getUnitPriceListErrors } from 'src/reducers/clients/unitPrices';
import { ErrorsSection } from 'src/components';

import { LinkBtn } from 'src/buttons';
import { CLIENT_UNIT_PRICE_NEW } from 'src/constants/EndpointUrls';
import { concatParamsToUrl } from 'src/utils/Http';
import { AddFile } from 'jbc-front/components/icons';
import ClientUnitPriceTableRow from '../components/ClientUnitPriceTableRow';
import styles from './ClientUnitPriceTable.scss';

const newUnitPriceUrl = payrollRulesGroupId => concatParamsToUrl(CLIENT_UNIT_PRICE_NEW, { payrollRulesGroupId });

const ClientUnitPriceTable = ({ payrollRulesGroupId, clientUnitPrices, onDelete, errors }) => (
  <div>
    <div>
      <ErrorsSection errors={errors} />
    </div>
    <div className={styles.create}>
      <LinkBtn text="新規作成" link={newUnitPriceUrl(payrollRulesGroupId)} icon={<AddFile size={28} />} />
    </div>
    <div className="l-overflow-scroll">
      <table className="m-table-list">
        <thead>
          <tr>
            <th>単価名</th>
            <th className={styles.action} />
          </tr>
        </thead>
        <tbody>
          {clientUnitPrices.map(clientUnitPrice => (
            <ClientUnitPriceTableRow
              key={clientUnitPrice.id}
              {...clientUnitPrice}
              payrollRulesGroupId={payrollRulesGroupId}
              onDelete={onDelete}
            />
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

const mapStateToProps = state => ({
  clientUnitPrices: getList(REDUCER_NAME, state),
  errors: getUnitPriceListErrors(state)
});

const mapDispatchToProps = dispatch => ({
  onDelete: (payrollRulesGroupId, unitPriceId) => {
    dispatch(deleteUnitPrice(payrollRulesGroupId, unitPriceId));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientUnitPriceTable);
