import React, { useEffect } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import { SearchSelectBox } from 'jbc-front/components/SearchForm';
import { arrowRenderer } from 'jbc-front/components/Form';

import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from 'src/components/PaymentYearMonthSelectBoxes.scss';

const PaymentYearMonthSelectBoxes = props => {
  const { groupedPaymentMonths } = props;

  useEffect(
    () => {
      if (_.isEmpty(groupedPaymentMonths)) {
        return;
      }
      const { dispatch, formName } = props;
      dispatch(change(formName, 'groupedPaymentMonths', groupedPaymentMonths[0].value));
    },
    [groupedPaymentMonths]
  );

  return (
    <div className={styles.container}>
      <div className={styles.payrollRuleGroupsWrapper}>
        <Field
          name="uniquePayrollRuleGroups"
          component={SearchSelectBox}
          placeholder={'全社合計で出力されます'}
          arrowRenderer={arrowRenderer}
          disabled
        />
      </div>
      <div className={styles.groupedPaymentMonthsWrapper}>
        <Field
          name="groupedPaymentMonths"
          component={SearchSelectBox}
          options={groupedPaymentMonths}
          arrowRenderer={arrowRenderer}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  groupedPaymentMonths: getSelectOptions(state, 'groupedPaymentMonths')
});

export default connect(mapStateToProps)(PaymentYearMonthSelectBoxes);
