import { connect } from 'react-redux';
import { fetchDownloadWageLedger, fetchPreviewWageLedger } from 'src/reducers/reports/reportWageLedger';
import PdfDownloadForm from '../components/PdfDownloadForm';

function mapDispatchToProps(dispatch, ownProps) {
  return {
    previewFile: () => {
      dispatch(fetchPreviewWageLedger(ownProps));
    },
    downloadFile: () => {
      dispatch(fetchDownloadWageLedger(ownProps));
    }
  };
}

function mapStateToProps(state, ownProps) {
  return {
    isOpen: ownProps.isOpen,
    hideModal: ownProps.hideModal
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PdfDownloadForm);
