import React from 'react';
import { connect } from 'react-redux';
import { sortBy } from 'lodash';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { EMPLOYEE_BONUS_EDIT, EMPLOYEE_BONUS_LIST } from 'src/constants/EndpointUrls';
import {
  setEmployeeBonusItem,
  getEmployeeBonusItem,
  revertEmployeeBonus,
  getEmployeeBonusItemErrors
} from 'src/reducers/employees/employeeBonus';
import selector from 'src/utils/Utils';

import { setExtras } from 'src/reducers/common/listReducer';
import { REDUCER_NAME as MEMO_REDUCER_NAME } from 'src/reducers/memos/memos';
import { NextAndPrevRecordButton, ErrorsSection } from 'src/components';

import ActionSection from './components/ActionSection';
import EmployeeBonusForm from './components/EmployeeBonusForm';

import styles from './EditEmployeeBonus.scss';

class ClsEditEmployeeBonus extends React.Component {
  constructor(props) {
    super(props);

    this.rollbackInputForm = this.rollbackInputForm.bind(this);
    this.changeFormMode = this.changeFormMode.bind(this);
    this.updateSelectingEmployeeBonus = this.updateSelectingEmployeeBonus.bind(this);

    this.state = {
      formMode: 'view',
      selectingEmployeeBonus: this.props.employeeBonus
    };
  }

  rollbackInputForm(employeeBonusId) {
    this.props.revert(employeeBonusId);
  }

  changeFormMode(mode) {
    this.setState({
      ...this.state,
      formMode: mode
    });
  }

  updateSelectingEmployeeBonus() {
    const { employeeBonus } = this.props;

    this.setState({
      ...this.state,
      selectingEmployeeBonus: { ...employeeBonus }
    });

    this.changeFormMode('view');
  }

  formatInitialValues() {
    const initialValues = { ...this.state.selectingEmployeeBonus };
    const { clientBonusAllowances } = this.props;

    initialValues.payments = [];
    initialValues.deductions = [];

    this.state.selectingEmployeeBonus.allowanceItems.forEach(allowance => {
      const clientBonusAllowance = clientBonusAllowances.find(item => item.id === allowance.clientBonusAllowanceId);

      if (clientBonusAllowance.allowanceType === 'deduction') {
        initialValues.deductions.push({ ...clientBonusAllowance, ...allowance });
      } else if (clientBonusAllowance.allowanceType === 'payment') {
        initialValues.payments.push({ ...clientBonusAllowance, ...allowance });
      }
    });
    initialValues.deductions = sortBy(initialValues.deductions, 'displayOrder');
    initialValues.payments = sortBy(initialValues.payments, 'displayOrder');

    return initialValues;
  }

  render() {
    const { allEmployeeBonus, clientBonusConfirmed, nextInfo, prevInfo, transferWithBank, errors } = this.props;
    const initialValues = this.formatInitialValues();

    return (
      <div>
        <div className="l-wrap-xl l-contents-wrap">
          <div className={styles.head}>
            <div className="u-mb20">
              <BreadCrumb>
                <BreadCrumb.Item label="賞与一覧" path={EMPLOYEE_BONUS_LIST} />
                <BreadCrumb.Item label="賞与詳細" />
              </BreadCrumb>
            </div>
            <NextAndPrevRecordButton {...{ nextInfo, prevInfo, urlFormat: EMPLOYEE_BONUS_EDIT }} />
          </div>
          <div>
            <ErrorsSection errors={errors} />
            <ActionSection {...{ selectingEmployeeBonus: this.state.selectingEmployeeBonus, allEmployeeBonus }} />
            <div className={styles.wrap}>
              <EmployeeBonusForm
                initialValues={initialValues}
                mode={this.state.formMode}
                changeFormMode={this.changeFormMode}
                rollbackInputForm={this.rollbackInputForm}
                callbackSaveSuccess={this.updateSelectingEmployeeBonus}
                clientBonusConfirmed={clientBonusConfirmed}
                transferWithBank={transferWithBank}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const ConnectedEditEmployeeBonus = connect(
  (state, ownProps) => ({
    errors: getEmployeeBonusItemErrors(state),
    clientBonusAllowances: selector(state.master, 'clientBonusAllowances'),
    employeeBonus: selector(getEmployeeBonusItem(state), 'employeeBonus') || ownProps.employeeBonus,
    allEmployeeBonus: selector(getEmployeeBonusItem(state), 'allEmployeeBonus') || ownProps.allEmployeeBonus
  }),
  dispatch => ({
    revert: id => dispatch(revertEmployeeBonus(id))
  })
)(ClsEditEmployeeBonus);

const EditEmployeeBonus = ({ dispatch, values }) => {
  dispatch(setEmployeeBonusItem(values));
  dispatch(setExtras(MEMO_REDUCER_NAME, values.memos));

  return <ConnectedEditEmployeeBonus {...values} />;
};

export default connect()(EditEmployeeBonus);
