import _ from 'lodash';

import { createAction } from 'redux-actions';

import { DEFAULT_PAGING_PER_PAGE } from 'src/Config';
import { namedPayloadCreator, namedMetaCreator } from 'src/utils/Reducer';

export const SET_LIST = 'EDITABLE_LIST/SET_LIST';
export const SET_EXTRAS = 'EDITABLE_LIST/SET_EXTRAS';
export const FETCH_LIST = 'EDITABLE_LIST/FETCH';
export const FETCH_LIST_SUCCESS = 'EDITABLE_LIST/FETCH_SUCCESS';
export const FETCH_LIST_FAILURE = 'EDITABLE_LIST/FETCH_FAILURE';

export const CHANGE_TO_SHOW_MODE = 'EDITABLE_LIST/CHANGE_TO_SHOW_MODE';
export const CHANGE_TO_EDIT_MODE = 'EDITABLE_LIST/CHANGE_TO_EDIT_MODE';

export const UPDATE = 'EDITABLE_LIST/UPDATE';
export const UPDATE_SUCCESS = 'EDITABLE_LIST/UPDATE_SUCCESS';
export const UPDATE_SUCCESS_WITHOUT_DATA = 'EDITABLE_LIST/UPDATE_SUCCESS_WITHOUT_DATA';
export const UPDATE_FAILURE = 'EDITABLE_LIST/UPDATE_FAILURE';
export const UPDATE_FAILURE_WITH_DATA = 'EDITABLE_LIST/UPDATE_FAILURE_WITH_DATA';

// Action Creators
export const setList = createAction(SET_LIST, namedPayloadCreator, namedMetaCreator);
export const setExtras = createAction(SET_EXTRAS, namedPayloadCreator, namedMetaCreator);

export const fetchList = createAction(FETCH_LIST, namedPayloadCreator, namedMetaCreator);
export const fetchListSuccess = createAction(FETCH_LIST_SUCCESS, namedPayloadCreator, namedMetaCreator);
export const fetchListFailure = createAction(FETCH_LIST_FAILURE, namedPayloadCreator, namedMetaCreator);

export const changeToShowMode = createAction(CHANGE_TO_SHOW_MODE, namedPayloadCreator, namedMetaCreator);
export const changeToEditMode = createAction(CHANGE_TO_EDIT_MODE, namedPayloadCreator, namedMetaCreator);
export const updateList = createAction(UPDATE, namedPayloadCreator, namedMetaCreator);
export const updateListSuccess = createAction(UPDATE_SUCCESS, namedPayloadCreator, namedMetaCreator);
export const updateListSuccessWithoutData = createAction(
  UPDATE_SUCCESS_WITHOUT_DATA,
  namedPayloadCreator,
  namedMetaCreator
);
export const updateListFailure = createAction(UPDATE_FAILURE, namedPayloadCreator, namedMetaCreator);
export const updateListFailureWithData = createAction(UPDATE_FAILURE_WITH_DATA, namedPayloadCreator, namedMetaCreator);

// Selectors
export const getList = (reducerName, state) => state[reducerName].list.data;
export const getExtras = (reducerName, state) => state[reducerName].list.extras;
export const getMode = (reducerName, state) => state[reducerName].list.mode;
export const getListErrors = (reducerName, state) => state[reducerName].list.errors;

/**
 * 新たに追加されたFields
 * @param state
 * @param reducerName
 * @param fieldArray
 */
export const getFormNewFields = (state, reducerName, fieldArray) => fieldArray.filter(field => field.id === undefined);

/**
 * 編集されたFields
 * @param state
 * @param reducerName
 * @param fieldArray
 */
export const getFormEditFields = (state, reducerName, fieldArray) => fieldArray.filter(field => field.id > 0);

/**
 * 削除されたFields
 * @param state
 * @param reducerName
 * @param fieldArray
 */
export const getFormDeleteFields = (state, reducerName, fieldArray) => {
  const originFieldArray = state[reducerName].list.data;
  return originFieldArray.filter(originField => _.find(fieldArray, { id: originField.id }) === undefined);
};

export const MODES = {
  SHOW: 'SHOW',
  EDIT: 'EDIT'
};

const INITIAL_STATE = {
  data: [],
  extras: {},
  mode: MODES.SHOW,
  errors: [],
  loading: false,
  pageCount: 0,
  queries: { page: 1, perPage: DEFAULT_PAGING_PER_PAGE }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LIST:
      return { ...INITIAL_STATE, data: action.payload };
    case SET_EXTRAS:
      return { ...state, extras: { ...state.extras, ...action.payload } };
    case FETCH_LIST:
      return { ...state, data: [], errors: [], loading: true };
    case FETCH_LIST_SUCCESS:
      return { ...state, data: action.payload.data, pageCount: action.payload.pageCount, errors: [], loading: false };
    case FETCH_LIST_FAILURE:
      return { ...state, data: [], pageCount: 0, errors: action.payload, loading: false };
    case CHANGE_TO_SHOW_MODE:
      return { ...state, mode: MODES.SHOW, errors: [] };
    case CHANGE_TO_EDIT_MODE:
      return { ...state, mode: MODES.EDIT };
    case UPDATE:
      return { ...state, errors: [], loading: true };
    case UPDATE_SUCCESS:
      return { ...state, data: action.payload, mode: MODES.SHOW, errors: [], loading: false };
    case UPDATE_SUCCESS_WITHOUT_DATA:
      return { ...state, mode: MODES.SHOW, errors: [], loading: false };
    case UPDATE_FAILURE:
      return { ...state, errors: action.payload, loading: false };
    case UPDATE_FAILURE_WITH_DATA:
      return { ...state, data: action.payload.data, errors: action.payload.messages, loading: false };
    default:
      return state;
  }
};
