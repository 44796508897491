export const ERROR_MSG_IS_REQUIRED = '必ず入力してください';
export const ERROR_MSG_IS_REQUIRED_WITH_PREFIX = 'を必ず入力してください';
export const ERROR_MSG_IS_NUMERIC = 'を半角数字で入力してください';
export const ERROR_MSG_IS_NUMERIC_LENGTH = 'を<%= length %>桁以内の半角数字で入力してください';
export const ERROR_MSG_IS_POSTAL_CODE = '郵便番号の「XXX-YYYY」フォーマットが正しくありません';
export const ERROR_MSG_IS_HOUR = 'を正しいフォーマットで入力してください';
export const ERROR_MSG_IS_DATE = 'の入力形式が正しくありません';
export const ERROR_MSG_IS_DATE_STRING = 'の入力形式が正しくありません';
export const ERROR_MSG_IS_TIMES = 'の入力形式が正しくありません';
export const ERROR_MSG_IS_INSRUANCE_RATE = 'を整数部3桁以内、小数部4桁以内で入力してください';
export const ERROR_MSG_IS_BASIC_DAYS = 'を0〜31までの半角数字で入力してください';
export const MAX_LENGTH = max => `は${max}文字以下で入力してください`;
export const MIN_LENGTH = min => `は${min}文字以上で入力してください`;
export const MIN_VALUE = min => `少なくとも${min}文字で入力してください`;
export const INVALID_EMAIL = 'を正しいフォーマットで入力してください';
export const INVALID_STAFF_CODE = 'は正しいフォーマットで入力してください';
export const ONLY_ALPHANUMERIC = 'は英数字のみで入力してください';
export const INVALID_PHONE = '正しい電話番号で入力してください';
export const ONLY_DIGIT_FLOATING_NUMBER = '英数字のみで入力してください 例：123.45';
export const EXACT_LENGTH = length => `${length}文字で入力してください`;
export const ZENKAKU_KATAKANA = 'を全角カタカナで入力してください';
export const CORP_NUMBER = 'が正しくありません';
export const INVALID_FORMULA = 'に登録できない文字が入力されています';
export const REQUIRED_TO_CHECK = 'を選択してください';
export const ERROR_MSG_IS_HHMM = 'を正しいフォーマットで入力してください「HH:MM」';
export const INVALID_INSURANCE_RATE = '半角数字と小数点のみで入力してください 例：123.45';
export const INVALID_YYYYMMDD = 'を正しいフォーマットで入力してください「yyyy/mm/dd」';
export const ERROR_MSG_NO_DATA = 'データが見つかりません';
export const ERROR_MSG_BANK_KANA =
  'はカタカナ、英数字、記号（前カッコ・後カッコ・ピリオド・マイナス記号・スラッシュ）のみ使用できます';
export const ERROR_MSG_DESIGNATION_NUMBER = '指定番号を半角カタカナ、半角英数字、半角ハイフンで入力してください';
export const ERROR_MSG_IS_SELECTED = 'を選択してください';
export const ERROR_MSG_HANKAKU_STRING_NUMBER = 'を半角英数字で入力してください';
export const ERROR_MSG_NOT_NEGATIVE_MONY_NUMBER = 'を0円以上の半角数字で入力してください';
export const EGOV_SERIAL_NUMBER_FORMAT = 'は001〜999までの数字を入力してください。';
