import React from 'react';
import { connect } from 'react-redux';

import ActionButton from 'jbc-front/components/ActionButton';
import { PdfFile } from 'jbc-front/components/icons';

import * as Urls from 'src/constants/EndpointUrls';
import { formatDate } from 'src/utils/Date';
import { concatParamsToUrl } from 'src/utils/Http';
import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from './ReportWithholdingSlipsTableRow.scss';

const ReportWithholdingSlipsTableRow = ({
  id,
  staffCode,
  name,
  joinedOn,
  resignedOn,
  confirmed,
  accountSaved,
  mypageReleaseStatus,
  changedPayOrBonus,
  onClickConfirm,
  onClickUnconfirm,
  onClickPdf
}) => (
  <tr>
    <td className={styles.staffCode}>
      {changedPayOrBonus && <span className={styles.updated}>更新有</span>}
      {staffCode}
    </td>
    <td>
      <a href={concatParamsToUrl(Urls.EMPLOYEE_SHOW, { id })}>{name}</a>
    </td>
    <td>{formatDate(joinedOn)}</td>
    <td>{formatDate(resignedOn)}</td>
    <td>
      {confirmed ? (
        <div>
          <span className={styles.confirmed}>確定済</span>
          <span className={styles.separator}>|</span>
          <ActionButton
            dark
            onClick={() => {
              if (confirmed) onClickUnconfirm();
            }}
          >
            確定解除
          </ActionButton>
        </div>
      ) : (
        <div>
          <span className={styles.not_confirmed}>未確定</span>
          <span className={styles.separator}>|</span>
          <ActionButton
            primary
            onClick={() => {
              if (!confirmed) onClickConfirm();
            }}
          >
            確定する
          </ActionButton>
        </div>
      )}
    </td>
    <td>
      {accountSaved && mypageReleaseStatus}
      {!accountSaved && <a href={Urls.EMPLOYEE_INVITATIONS_LIST}>{mypageReleaseStatus}</a>}
    </td>
    <td>
      <ActionButton
        primary
        icon={<PdfFile size={16} />}
        onClick={() => {
          if (confirmed) onClickPdf();
        }}
        disabled={!confirmed}
      >
        PDF出力
      </ActionButton>
    </td>
  </tr>
);

export default connect(state => ({
  groups: getSelectOptions(state, 'groups'),
  clientEmploymentTypes: getSelectOptions(state, 'employmentTypes')
}))(ReportWithholdingSlipsTableRow);
