import { connect } from 'react-redux';

import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';

import selector from 'src/utils/Utils';

import InhabitantTaxSectionShow from '../components/InhabitantTaxSectionShow';

const mapStateToProps = state => {
  const employeeInhabitantTax = selector(getItem(REDUCER_NAME, state), 'employeeInhabitantTax');
  return {
    employeeInhabitantTax: employeeInhabitantTax || {},
    taxAmounts: employeeInhabitantTax.taxAmounts || [],
    prefectures: getSelectOptions(state, 'prefectures')
  };
};

export default connect(mapStateToProps)(InhabitantTaxSectionShow);
