import { combineReducers } from 'redux';
import axios from 'axios';
import { setGlobalErrors } from 'src/reducers/global';
import { setExtras, setList, fetchListSuccess, fetchListFailure } from 'src/reducers/common/listReducer';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';

export const REDUCER_NAME = 'employeePersonnelHistories';

export const fetchEmployeePersonnelHistories = id => async dispatch => {
  try {
    // ブラウザバック時にjsonが表示されること回避のためtimestamp付与
    const response = await axios.get(`/employees/${id}/personnel_histories`, { params: { timestamp: Date.now() } });
    const payload = { data: response.data.payload.personnelHistories };
    await dispatch(setExtras(REDUCER_NAME, response.data.payload.options));
    await dispatch(setList(REDUCER_NAME, payload));
    await dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(fetchListFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const savePersonnelHistories = data => async dispatch => {
  try {
    const employeeId = data.fields[0].employeeId;
    await axios.post(`/employees/${employeeId}/personnel_histories`, {
      personnel_histories: data.fields
    });
    dispatch(fetchEmployeePersonnelHistories(employeeId));
    window.location.reload();
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
