import React from 'react';
import { connect } from 'react-redux';
import { MemoActionBtn } from 'src/buttons';
import { isCurrentEmployeeHasMemo } from 'src/reducers/memos/memos';
import MemoTable from './components/MemoTable';
import NewMemo from './components/NewMemo';
import styles from './MemoList.scss';

class MemoList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showData: false,
      isMemoModalOpen: false
    };

    this.changeShowDataState = this.changeShowDataState.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.changeMemoModal = this.changeMemoModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  changeMemoModal() {
    this.setState({ ...this.state, isMemoModalOpen: !this.state.isMemoModalOpen });
  }

  changeShowDataState(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ ...this.state, showData: !this.state.showData });
  }

  handleClickOutside(event) {
    if (!this.state.isMemoModalOpen && !(this.node && this.node.contains(event.target))) {
      this.setState({ ...this.state, showData: false });
    }
  }

  render() {
    const { hasMemo, employeeId, size } = this.props;

    if (hasMemo) {
      return (
        <span
          ref={node => {
            this.node = node;
          }}
          className={styles.rel}
        >
          <MemoActionBtn isNewMemo={false} onClick={this.changeShowDataState} size={size} />
          {this.state.showData && (
            <MemoTable showData={this.state.showData} employeeId={employeeId} changeMemoModal={this.changeMemoModal} />
          )}
        </span>
      );
    }
    return <NewMemo {...{ employeeId }} fromMemoTable={false} changeMemoModal={this.changeMemoModal} size={size} />;
  }
}

const mapStateToProps = (state, ownProps) => ({
  hasMemo: isCurrentEmployeeHasMemo(state, ownProps.employeeId)
});

export default connect(mapStateToProps)(MemoList);
