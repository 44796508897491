import axios from 'axios';
import _ from 'lodash';
import { decamelize } from 'humps';
import { combineReducers } from 'redux';
import { initialize as initializeForm } from 'redux-form';

import { ALLOWED_IP_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { editableListReducer } from 'src/reducers/common';

import { updateListSuccess, getExtras } from 'src/reducers/common/editableListReducer';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

export const REDUCER_NAME = 'allowedIps';

// Async Action Creators
export const updateIps = data => async (dispatch, getState) => {
  try {
    const selectedTab = getExtras(REDUCER_NAME, getState()).selectedTab;
    const response = await axios.put('/clients/allowed_ips/update_batch', {
      allowedIps: data,
      permissionType: decamelize(selectedTab, { separator: '_' })
    });
    const values = response.data.payload;
    dispatch(updateListSuccess(REDUCER_NAME, values.allAllowedIps));
    dispatch(setGlobalSuccesses('IPアドレスを更新しました。'));
  } catch (exception) {
    _.forEach(exception.response.data.errors, error => dispatch(setGlobalErrors(error)));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(editableListReducer, REDUCER_NAME)
});
