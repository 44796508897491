import { combineReducers } from 'redux';
import saveAs from 'file-saver';
import contentDisposition from 'content-disposition';
import _ from 'lodash';

import * as Urls from 'src/constants/EndpointUrls';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import { setExtras, getExtras } from 'src/reducers/common/listReducer';
import { serializeHttpGetParams } from 'src/utils/Http';
import { fetchJobStatus, isJobIncomplete } from 'src/reducers/JobStatus';
import { MAX_POLLING_INTERVAL, INITIAL_POLLING_INTERVAL } from 'src/constants/Generals';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';

export const REDUCER_NAME = 'employeePaysAsync';
export const getIsCreating = state => _.get(getExtras(REDUCER_NAME, state), 'isCreating');

/**
 * 支給・控除等一覧表
 */
export const fetchDownloadBonusAllowancesDeductionsJobStatus = (interval, jobName, searchParams) => async (
  dispatch,
  getState
) => {
  const params = {
    job_name: jobName,
    search_params: searchParams
  };
  await dispatch(fetchJobStatus(params));
  if (isJobIncomplete(getState().JobStatus.export_bonus_allowance_deduction_csv_job.status) === true) {
    const nextInterval = interval < MAX_POLLING_INTERVAL ? interval * 2 : interval;
    const pollingId = setTimeout(() => {
      dispatch(fetchDownloadBonusAllowancesDeductionsJobStatus(nextInterval, jobName, searchParams));
    }, nextInterval);
    dispatch(setExtras(REDUCER_NAME, { isCreating: true, pollingId }));
  } else {
    dispatch(setExtras(REDUCER_NAME, { isCreating: false, pollingId: null }));
  }
};

export const downloadBonusAllowancesDeductions = data => async dispatch => {
  try {
    if (!data.clientBonusId) {
      dispatch(setGlobalErrors('賞与の対象を選択してください。'));
      return;
    }
    const downloadUrl = `${Urls.EXPORT_ALLOWANCE_DEDUCTIONS_BONUS}?${serializeHttpGetParams(data)}`;
    const response = await fetch(downloadUrl);
    // fetchのbodyで判定を行い辛いのでheaderで処理分岐
    // Content-Typeがapplication/jsonになるのは以下の2パターン
    // - 非同期処理実行時
    // - エラー発生時
    if (_.includes(response.headers.get('Content-Type'), 'application/json')) {
      const json = await response.json();
      if (!response.ok) {
        throw new Error(json.errors.messages);
      }

      const pollingId = setTimeout(() => {
        dispatch(
          fetchDownloadBonusAllowancesDeductionsJobStatus(
            INITIAL_POLLING_INTERVAL,
            'export_bonus_allowance_deduction_csv_job',
            { unique_string: data.uniqueString }
          )
        );
      }, INITIAL_POLLING_INTERVAL);
      dispatch(setGlobalSuccesses('支給・控除等一覧表はメールにてお送りします'));
      dispatch(setExtras(REDUCER_NAME, { isCreating: true, pollingId }));
    } else {
      const responseBlob = await response.blob();
      const blob = new Blob([responseBlob], { type: 'text/csv' });
      const disposition = contentDisposition.parse(response.headers.get('content-disposition'));
      saveAs(blob, disposition.parameters.filename);
    }
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export const resetPolling = () => async (dispatch, getStare) => {
  const pollingId = getStare().pollingId;
  if (_.isUndefined(pollingId)) {
    return;
  }
  clearTimeout(pollingId);
  dispatch(setExtras(REDUCER_NAME, { pollingId: null }));
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
