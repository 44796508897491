import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import { FORM_NAME_WAGE_LEDGER_SEARCH } from 'src/reducers/searchForm';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { setYearMonthEnd } from 'src/reducers/reports/reportWageLedger';
import SelectBoxYearMonth from '../components/SelectBoxYearMonth';

const mapStateToProps = state => ({
  wageLedgerYearMonths: getSelectOptions(state, 'wageLedgerYearMonths'),
  formValues: getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(state)
});

const mapDispatchToProps = dispatch => ({
  calcYearMonthEnd: newValue => {
    dispatch(setYearMonthEnd(newValue, 'false'));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SelectBoxYearMonth);
