import React from 'react';
import { connect } from 'react-redux';
import { Field, change } from 'redux-form';
import _ from 'lodash';

import Balloon from 'src/components/Balloon';
import { MONTHLY_REVISION_TARGET_INSURANCE_TYPES } from 'src/constants/Options';
import classNames from 'classnames';
import { numberWithComma } from 'src/utils/Utils';
import { formatYearMonth } from 'src/utils/Date';
import { isRequiredWithPrefix, maxLength37 } from 'src/utils/CustomValidators';
import EditableTextField from '../Util/EditableTextField';
import EditableSelectField from '../Util/EditableSelectField';
import RevisionGrade from './RevisionGrade';
import RevisionGradeAmount from './RevisionGradeAmount';
import styles from './RevisionItem.scss';

const NOTE_70_OVER = '1.70歳以上の被用者算定';
const NOTE_WORK_IN_2_OR_MORE = '2.二以上勤務';
const CHANGE_MONTHLY_REVISION_IN_JULY = '3.月額変更予定';
const MIDDLE_JOINED = '4.途中入社';
const SPECIAL_REASON_FOR_LEAVE = '5.病休・育休・休職等';
const SHORT_TIME_WORKER = '6.短時間労働者（特定適用事業所等）';
const PART_TIMER = '7.パート';
const ANNUAL_AVERAGE = '8.年間平均';
const NOTE_OTHER = '9.その他';

const getMonthFromDateString = dateString => {
  // yyyy-mm-dd形式
  const date = new Date(dateString);
  return date.getMonth() + 1;
};

const ContentBottom = props => {
  const { dispatch, revision, fetchValue, showMode, selectableMonths, changeToManualCalcMode } = props;
  const isHiddenNote = !fetchValue('isOtherNote') || (showMode === 'SHOW' && _.isEmpty(fetchValue('note')));
  // 70歳以上の被用者算定基礎月の選択肢は5月と6月のみ
  const isHiddenOver70YearsOldRevisionDate1 =
    !fetchValue('isOver70YearsOld') || (showMode === 'SHOW' && _.isEmpty(fetchValue('over70YearsOldRevisionDate1')));
  const isHiddenOver70YearsOldRevisionDate2 =
    !fetchValue('over70YearsOldRevisionDate1') ||
    getMonthFromDateString(fetchValue('over70YearsOldRevisionDate1')) !== 5;
  const isHiddenMonthlyRevisionTargetInsuranceType = !fetchValue('isChangeMonthlyRevisionInJuly');
  const targetInsuranceType = value => MONTHLY_REVISION_TARGET_INSURANCE_TYPES.find(type => type.value === value).label;
  const isForceSubjectHealth = revision.healthInsuranceForceSubjectType === 'applicable';
  const isForceSubjectPension = revision.pensionInsuranceForceSubjectType === 'applicable';
  const isDisableChangeMonthlyRevisionInJuly =
    isForceSubjectHealth && isForceSubjectPension && !fetchValue('isChangeMonthlyRevisionInJuly');
  const monthlyRevisionInsuranceTypeOptions = () =>
    MONTHLY_REVISION_TARGET_INSURANCE_TYPES.map(
      item =>
        (item.value === 'health_and_pension' && (isForceSubjectHealth || isForceSubjectPension)) ||
        (item.value === 'only_health' && isForceSubjectHealth) ||
        (item.value === 'only_pension' && isForceSubjectPension)
          ? { ...item, disabled: true }
          : item
    );
  dispatch(change());
  return (
    <div className={styles.contentBottom}>
      <div className={styles.boxHeader}>決定後の健保</div>
      <div>
        <RevisionGrade
          isJoined={fetchValue('isJoinedHealthInsurance')}
          grade={fetchValue('afterHealthInsuranceGrade')}
        />
      </div>
      <div className={styles.amount}>
        <RevisionGradeAmount
          isJoined={fetchValue('isJoinedHealthInsurance')}
          amount={numberWithComma(fetchValue('afterMonthlyCompensationHealthInsuranceAmount'))}
        />
      </div>
      <div className={styles.boxHeader}>決定後の厚年</div>
      <div>
        <RevisionGrade
          isJoined={fetchValue('isJoinedPensionInsurance')}
          grade={fetchValue('afterPensionInsuranceGrade')}
        />
      </div>
      <div className={styles.amount}>
        <RevisionGradeAmount
          isJoined={fetchValue('isJoinedPensionInsurance')}
          amount={numberWithComma(fetchValue('afterMonthlyRemunerationPensionInsuranceAmount'))}
        />
      </div>
      <div className={styles.boxHeader}>備考</div>
      <div>
        <div className={styles.remarks}>
          <Field
            name="isOver70YearsOld"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isOver70YearsOld')
            })}
            width="initial"
            label={NOTE_70_OVER}
            showValue={NOTE_70_OVER}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            component={EditableTextField}
          />
          <Field
            name="isWorkInTwoOrMore"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isWorkInTwoOrMore')
            })}
            width="initial"
            label={NOTE_WORK_IN_2_OR_MORE}
            showValue={NOTE_WORK_IN_2_OR_MORE}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            component={EditableTextField}
          />
          <div className={styles.alertWrapper}>
            <div className={styles.alert}>
              {isDisableChangeMonthlyRevisionInJuly && (
                <Balloon className={styles.balloon}>
                  手動で提出対象に設定されているため、こちらは選択できません。
                </Balloon>
              )}
              <Field
                name="isChangeMonthlyRevisionInJuly"
                className={classNames(styles.noteCheck, styles.employeesDownload, {
                  [styles.disabled]: showMode === 'SHOW' && !fetchValue('isChangeMonthlyRevisionInJuly')
                })}
                width="initial"
                label={CHANGE_MONTHLY_REVISION_IN_JULY}
                showValue={CHANGE_MONTHLY_REVISION_IN_JULY}
                showMode={showMode}
                textAlign="left"
                type="checkbox"
                component={EditableTextField}
                disabled={isDisableChangeMonthlyRevisionInJuly}
                onChange={(e, checked) => {
                  if (!checked) return;
                  if (isForceSubjectHealth) {
                    dispatch(
                      change('revisions', `revisions.${revision.id}.monthlyRevisionTargetInsuranceType`, 'only_pension')
                    );
                  } else if (isForceSubjectPension) {
                    dispatch(
                      change('revisions', `revisions.${revision.id}.monthlyRevisionTargetInsuranceType`, 'only_health')
                    );
                  }
                }}
              />
            </div>
          </div>
          <Field
            name="isMiddleJoined"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isMiddleJoined')
            })}
            width="initial"
            label={MIDDLE_JOINED}
            showValue={MIDDLE_JOINED}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            component={EditableTextField}
          />
        </div>
        {!isHiddenOver70YearsOldRevisionDate1 && (
          <div className={classNames(styles.remarks, styles.additionalPulldown)}>
            70歳以上の被用者算定基礎月：
            <Field
              name="over70YearsOldRevisionDate1"
              showValue={formatYearMonth(fetchValue('over70YearsOldRevisionDate1'))}
              showMode={showMode}
              textAlign="center"
              options={selectableMonths([5, 6])}
              Width={100}
              component={EditableSelectField}
              addBlankOption
              onChange={(e, selected) => {
                if (getMonthFromDateString(selected) === 5) {
                  const juneValue = selectableMonths([6])[0].value;
                  dispatch(change('revisions', `revisions.${revision.id}.over70YearsOldRevisionDate2`, juneValue));
                }
              }}
            />
            {!isHiddenOver70YearsOldRevisionDate2 && (
              <Field
                name="over70YearsOldRevisionDate2"
                showValue={formatYearMonth(fetchValue('over70YearsOldRevisionDate2'))}
                showMode={showMode}
                textAlign="center"
                options={selectableMonths([5, 6])}
                Width={100}
                component={EditableSelectField}
                addBlankOption
                disabled
              />
            )}
          </div>
        )}
        {!isHiddenMonthlyRevisionTargetInsuranceType && (
          <div className={classNames(styles.remarks, styles.additionalPulldown)}>
            月額変更予定対象：
            <Field
              name="monthlyRevisionTargetInsuranceType"
              showValue={targetInsuranceType(fetchValue('monthlyRevisionTargetInsuranceType'))}
              showMode={showMode}
              textAlign="center"
              options={monthlyRevisionInsuranceTypeOptions()}
              Width={100}
              component={EditableSelectField}
            />
          </div>
        )}
        <div className={styles.remarks}>
          <Field
            name="isSpecialReasonForLeave"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isSpecialReasonForLeave')
            })}
            width="initial"
            label={SPECIAL_REASON_FOR_LEAVE}
            showValue={SPECIAL_REASON_FOR_LEAVE}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            component={EditableTextField}
          />
          <Field
            name="isShortTimeWorker"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isShortTimeWorker')
            })}
            width="initial"
            label={SHORT_TIME_WORKER}
            showValue={SHORT_TIME_WORKER}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            onChange={() => {
              changeToManualCalcMode('isShortTimeWorker');
            }}
            component={EditableTextField}
          />
          <Field
            name="isPartTimer"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isPartTimer')
            })}
            width="initial"
            label={PART_TIMER}
            showValue={PART_TIMER}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            onChange={() => {
              changeToManualCalcMode('isPartTimer');
            }}
            component={EditableTextField}
          />
          <Field
            name="isAnnualAverage"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isAnnualAverage')
            })}
            width="initial"
            label={ANNUAL_AVERAGE}
            showValue={ANNUAL_AVERAGE}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            component={EditableTextField}
          />
        </div>
        <div className={styles.remarks}>
          <Field
            name="isOtherNote"
            className={classNames(styles.noteCheck, {
              [styles.disabled]: showMode === 'SHOW' && !fetchValue('isOtherNote')
            })}
            width="initial"
            label={NOTE_OTHER}
            showValue={`${NOTE_OTHER}${_.isEmpty(fetchValue('note')) ? '' : '：'}`}
            showMode={showMode}
            textAlign="left"
            type="checkbox"
            component={EditableTextField}
          />
        </div>
        {!isHiddenNote && (
          <div className={styles.remarks}>
            <Field
              name="note"
              className={classNames(styles.noteText, {
                [styles.noteTextPreview]: showMode === 'SHOW'
              })}
              inputHeight={32}
              showValue={fetchValue('note')}
              showMode={showMode}
              textAlign="left"
              component={EditableTextField}
              validate={[isRequiredWithPrefix, maxLength37]}
            />
          </div>
        )}
      </div>
    </div>
  );
};
// TODO: connectやめてuseDispatchに置き換える
/* eslint react-redux/prefer-separate-component-file: 0 */
export default connect()(ContentBottom);
