import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { SearchReactSelect } from 'src/components';
import { Download } from 'jbc-front/components/icons';
import { REPORT_INDEX } from 'src/constants/EndpointUrls';
import Loading from 'src/components/Loading';
import ActionButton from 'jbc-front/components/ActionButton';
import IncomeTaxCollectionList from './components/IncomeTaxCollectionDataList';
import DownloadCsvModalContainer from './containers/DownloadCsvModalContainer';
import styles from './IncomeTaxCollectionData.scss';

const IncomeTaxCollectionData = props => {
  const { selectableYears, incomeTaxCollectionData, loading, search } = props;
  const [selectedYear, setSelectedYear] = useState(_.get(_.head(selectableYears), 'value'));
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const openDownloadModal = () => {
    if (isDownloadModalOpen) return;
    setIsDownloadModalOpen(true);
  };

  const closeDownloadModal = () => {
    if (!isDownloadModalOpen) return;
    setIsDownloadModalOpen(false);
  };

  const onChangeYear = selected => {
    setSelectedYear(selected.value);
    search(selected.value);
  };

  // 初期表示時の検索
  useEffect(() => {
    search(selectedYear);
  }, []);

  return (
    <div>
      <div className="l-wrap-xl l-contents-wrap">
        <div className="u-mb20">
          <BreadCrumb>
            <BreadCrumb.Item label="書類作成" path={REPORT_INDEX} />
            <BreadCrumb.Item label="所得税徴収高集計資料" />
          </BreadCrumb>
        </div>
        <h1 className="m-title-main">所得税徴収高集計資料</h1>
        <div className={styles.container}>
          <div className={styles.selectYear}>
            <SearchReactSelect
              options={selectableYears}
              onChange={selected => {
                onChangeYear(selected);
              }}
              value={selectedYear}
              size="large"
              disabled={loading}
            />
          </div>
          <div className={styles.csv}>
            <ActionButton disabled={loading} primary onClick={openDownloadModal} icon={<Download size={15} />}>
              CSVダウンロード
            </ActionButton>
            <DownloadCsvModalContainer
              isOpen={isDownloadModalOpen}
              hideModal={closeDownloadModal}
              selectedYear={selectedYear}
            />
          </div>
        </div>
        <div className={styles.list}>
          {loading ? (
            <div>
              <div className={styles.empty_messages}>データを取得中です。</div>
              <Loading />
            </div>
          ) : incomeTaxCollectionData.length > 0 ? (
            <IncomeTaxCollectionList incomeTaxCollectionData={incomeTaxCollectionData} />
          ) : (
            <div className={styles.empty_messages}>確定済みの給与・賞与データがありません。</div>
          )}
        </div>
      </div>
    </div>
  );
};
export default IncomeTaxCollectionData;
