import { reduxForm } from 'redux-form';
import { REPORT_EGOV_FORM } from 'src/constants/FormNames';
import EgovModal from 'src/components/Egov/EgovModal';

const EgovModalForm = reduxForm({
  form: REPORT_EGOV_FORM,
  enableReinitialize: true
})(EgovModal);

export default EgovModalForm;
