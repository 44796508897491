import React from 'react';
import _ from 'lodash';

import ActionButton from 'jbc-front/components/ActionButton';
import { SelectTable } from 'jbc-front/components/icons';
import { numToStr, currency } from 'src/utils/Utils';
import styles from './StandardMonthlyAmountTable.scss';

// 健康保険の報酬月額に対応する厚生年金保険の報酬月額を取得する
// 対応する厚生年金保険の情報がない場合はundefinedを返す
export const getPairPensionStandardWithHealthStandard = (pensionStandardAmounts, healthStandard) =>
  pensionStandardAmounts.find(
    ({ monthlyAmountTo, monthlyAmountFrom }) =>
      (healthStandard.monthlyAmountTo !== null && healthStandard.monthlyAmountTo === monthlyAmountTo) ||
      (monthlyAmountTo === null && monthlyAmountFrom === healthStandard.monthlyAmountFrom)
  );

// 厚生年金保険の等級を取得する
// pensionStandardMonthlyAmountがundefineの場合、対となる健康保険の等級が1~3等級であれば1等級、35等級以上であれば31等級とする)
export const getPensionInsuranceGrade = (targetPensionStandard, pairHealthStandard, pensionStandardAmounts) => {
  const sortedPensionStandardAmounts = _.sortBy(pensionStandardAmounts, pension => pension.grade);
  // 厚生年金保険があればその等級を返す
  if (targetPensionStandard) {
    return targetPensionStandard.grade;
  }
  // 対の健康保険の標準報酬が厚生年金保険の最小の標準報酬より小さい場合は、最小の等級を返す
  const min = _.first(sortedPensionStandardAmounts);
  if (pairHealthStandard.standardMonthlyAmount < min.standardMonthlyAmount) {
    return min.grade;
  }
  // 対の健康保険の標準報酬が厚生年金保険の最大の標準報酬より大きい場合は、最大の等級を返す
  const max = _.last(sortedPensionStandardAmounts);
  if (pairHealthStandard.standardMonthlyAmount > max.standardMonthlyAmount) {
    return max.grade;
  }
  return null; // 想定外エラー
};

// 対応する等級が最大等級かを判定する
export const isLastGrade = (amounts, grade) => !amounts.find(item => item.grade > grade);

// 等級から対応する標準報酬月額を取得
export const getStandardMonthlyAmount = (amounts, grade) => {
  const find = _.find(amounts, amount => amount.grade === grade);
  return find ? find.standardMonthlyAmount : 0;
};

class StandardMonthlyAmountTable extends React.Component {
  constructor(props) {
    super(props);

    this.controlDisplayTable = this.controlDisplayTable.bind(this);

    this.state = {
      isShowTable: false
    };
  }

  controlDisplayTable() {
    const { selectingTimeValue, standardMonthlyAmounts } = this.props;

    standardMonthlyAmounts(selectingTimeValue);
    this.setState({
      ...this.state,
      isShowTable: !this.state.isShowTable
    });
  }

  render() {
    const {
      healthStandardMonthlyAmounts,
      pensionStandardMonthlyAmounts,
      fillAllStandardAfterCurrentSettingTime,
      fields,
      index,
      syncFields,
      autofill,
      settingStartDate,
      standardItem
    } = this.props;

    const standardMonthly = fields.get(index);

    return (
      <div>
        <div className={styles.action}>
          <div className={styles.buttons}>
            <ActionButton onClick={this.controlDisplayTable} icon={<SelectTable size={16} />}>
              {this.state.isShowTable ? '表を閉じる' : '表から選択'}
            </ActionButton>
          </div>
        </div>

        {this.state.isShowTable && (
          <div className={styles.tableContainer}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th colSpan={2}>等級</th>
                  <th rowSpan={2}>標準報酬月額</th>
                  <th rowSpan={2}>報酬月額（円以上～円未満）</th>
                </tr>
                <tr>
                  <th>健康保険</th>
                  <th>厚生年金保険</th>
                </tr>
              </thead>
              <tbody>
                {/* 健康保険の方が等級数が多いため健康保険を基準の行を作成 */}
                {healthStandardMonthlyAmounts.map(healthStandardMonthlyAmount => {
                  // 健康保険と対になる厚生年金を取得。厚生年金のほうが等級幅が小さいため対となる情報がない場合もある。(ない場合undefine)
                  const pensionStandardMonthlyAmount = getPairPensionStandardWithHealthStandard(
                    pensionStandardMonthlyAmounts,
                    healthStandardMonthlyAmount
                  );
                  // 等級を算出
                  const healthInsuranceGrade = healthStandardMonthlyAmount.grade;
                  const pensionInsuranceGrade = getPensionInsuranceGrade(
                    pensionStandardMonthlyAmount,
                    healthStandardMonthlyAmount,
                    pensionStandardMonthlyAmounts
                  );
                  // 対応する標準報酬月額のデータを取得
                  const monthlyCompensationHealthInsuranceAmount = getStandardMonthlyAmount(
                    healthStandardMonthlyAmounts,
                    healthInsuranceGrade
                  );
                  const monthlyRemunerationPensionInsuranceAmount = getStandardMonthlyAmount(
                    pensionStandardMonthlyAmounts,
                    pensionInsuranceGrade
                  );
                  // 最大等級かどうかの判定
                  const isLastGradeOfHealthInsurance = isLastGrade(healthStandardMonthlyAmounts, healthInsuranceGrade);
                  const isLastGradeOfPensionInsurance = isLastGrade(
                    pensionStandardMonthlyAmounts,
                    pensionInsuranceGrade
                  );
                  return (
                    <tr
                      key={healthStandardMonthlyAmount.id}
                      onClick={() => {
                        autofill(`${standardItem}.inputType`, 'selecting');
                        fillAllStandardAfterCurrentSettingTime(fields, autofill, settingStartDate)({
                          ..._.pick(standardMonthly, syncFields),
                          monthlyCompensationHealthInsuranceAmount,
                          monthlyRemunerationPensionInsuranceAmount,
                          healthInsuranceGrade,
                          isLastGradeOfHealthInsurance,
                          pensionInsuranceGrade,
                          isLastGradeOfPensionInsurance
                        });
                        this.controlDisplayTable();
                      }}
                    >
                      <td>{healthStandardMonthlyAmount.grade}</td>
                      <td>{pensionStandardMonthlyAmount && pensionStandardMonthlyAmount.grade}</td>
                      <td>{currency(numToStr(healthStandardMonthlyAmount.standardMonthlyAmount))}</td>
                      <td>{`${currency(numToStr(healthStandardMonthlyAmount.monthlyAmountFrom))} 〜 ${
                        healthStandardMonthlyAmount.monthlyAmountTo
                          ? `${currency(numToStr(healthStandardMonthlyAmount.monthlyAmountTo))}`
                          : ''
                      }`}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default StandardMonthlyAmountTable;
