import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import _ from 'lodash';
import queryString from 'query-string';
import { REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_CSV_FORM } from 'src/constants/FormNames';
import { ENCODING_OPTIONS } from 'src/constants/Options';
import { UTF_8 } from 'src/constants/Generals';
import { FileField, RadioField } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';
import { downloadTemplateCsv, importLaborInsuranceCsv } from 'src/reducers/reports/reportLaborInsurances';
import { existIncompleteJob } from 'src/reducers/JobStatus';
import DownloadCsvEncodingRadioButton from 'src/components/DownloadCsvEncodingRadioButton';

import styles from './LaborInsuranceDownloadCsvForm.scss';

const LaborInsuranceCsvInsideForm = ({ downloadCsv, handleSubmit, submitting, pristine, submit, importCsvJobs }) => {
  const disabledUploadBtn = !_.isEmpty(existIncompleteJob(importCsvJobs));
  const csvTargetOptions = [
    { value: 'all_employee', label: '全従業員' },
    { value: 'searched_employee', label: '検索絞込中の従業員' }
  ];

  return (
    <div className={styles.wrap}>
      <div className={styles.head}>アップロード手順</div>
      <div className={styles.body}>
        <form onSubmit={handleSubmit}>
          <dl className={styles.label}>
            <dt className={styles.number}>1</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>
                ファイルの出力形式と出力対象を選択してダウンロードしたCSVファイルをご利用ください。
              </p>
              <div className="u-mt30">
                <DownloadCsvEncodingRadioButton
                  name={'encoding'}
                  label={null}
                  options={ENCODING_OPTIONS}
                  required={false}
                />
              </div>
              <div className={styles.itemBox}>
                <div className={styles.inputLabel}>
                  <p>対象選択：</p>
                </div>
                <div className={styles.csvRadioField}>
                  <RadioField name="csvTarget" label={null} options={csvTargetOptions} required={false} />
                </div>
              </div>
              <div className={styles.note}>
                対象選択：「検索絞込中の従業員」を選択した場合、内訳一覧で検索した従業員を出力します。
              </div>
              <Button onClick={() => downloadCsv()} widthWide>
                月別内訳データをダウンロード
              </Button>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>2</dt>
            <dd className={styles.detail}>
              下の説明を参考に、ダウンロードしたCSVファイルの内容を変更したい値に修正してください。
              <div className={styles.warning}>
                以下の項目は<span className={styles.caution}>入力必須</span>になります。
                <ul className={styles.requireList}>
                  <li>・スタッフコード</li>
                  <li>・（行を新規追加する場合）</li>
                  <li>　　・種別</li>
                  <li>　　・月度</li>
                  <li>　　・事業所管理名</li>
                  <li>・（行を更新または削除する場合）</li>
                  <li>　　・更新用キー</li>
                </ul>
              </div>
            </dd>
          </dl>

          <dl className={styles.label}>
            <dt className={styles.number}>3</dt>
            <dd className={styles.detail}>
              <p className={styles.text}>完成したファイルを選択し、アップロードして下さい。</p>
              <FileField label="月別内訳項目のデータファイル" name="labor_insurance_csv" accepted=".csv" required />
            </dd>
          </dl>

          <FormSubmitBtn
            text="アップロード"
            {...{ submitting, pristine, submit }}
            widthWide
            disabled={disabledUploadBtn}
          />
        </form>
      </div>
    </div>
  );
};

// https://github.com/d-o-n-u-t-s/payrollservice/pull/10936
// TODO: useDispatch, useSelectorを使って書き換える
const LaborInsuranceDownloadCsvForm = reduxForm({
  form: REPORT_LABOR_INSURANCE_EMPLOYEE_DETAIL_CSV_FORM,
  enabledReinitialize: true
})(LaborInsuranceCsvInsideForm);

const hasConditions = state => {
  const ignoreQueries = ['labor_insurance_year'];
  const queries = queryString.parse(state.router.location.search);

  return !_.isEmpty(_.pickBy(_.omit(queries, ignoreQueries)));
};

const mapStateToProps = state => ({
  initialValues: {
    encoding: UTF_8,
    csvTarget: hasConditions(state) ? 'searched_employee' : 'all_employee'
  },
  importCsvJobs: state.JobStatus.importLaborInsuranceEmployeeDetailsCsvJob
});

const mapDispatchToProps = dispatch => ({
  downloadCsv: () => dispatch(downloadTemplateCsv()),
  onSubmit: data => dispatch(importLaborInsuranceCsv(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(LaborInsuranceDownloadCsvForm);
