import _ from 'lodash';
import selector, { recordDisplay, numberWithComma } from 'src/utils/Utils';
import { roundingTo, isNumber } from 'src/utils/Number';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import { change as formChange } from 'redux-form';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';

// 年税額を取得する
export const annualInhabitantTax = taxAmounts => {
  const sum = _.sumBy(taxAmounts, ta => Number(ta.amount));
  return recordDisplay(Number.isFinite(sum) && `${numberWithComma(sum)}円`);
};

// 年税額を取得する(カンマ、単位なしの編集用)
export const annualInhabitantTaxForEdit = taxAmounts => {
  const sum = _.sumBy(taxAmounts, ta => Number(ta.amount));
  return Number.isFinite(sum) ? sum : 0;
};

// 年税額から各月の住民税額を分割計算する
// https://github.com/d-o-n-u-t-s/payrollservice/issues/10176
// サーバ側にも同様のロジックがあるため、変更する際はそちらも併せて修正する。
export const calcEachMonthTaxAmount = annualTaxAmount => {
  if (!isNumber(annualTaxAmount)) return [];

  const eachMonthAmount = annualTaxAmount / 12;
  const roundDownAmount = 100;
  const roundedEachMonthAmount = roundingTo(roundDownAmount, eachMonthAmount);
  const juneAmount = annualTaxAmount - roundedEachMonthAmount * 11;

  return [...Array(12)].map((__, i) => {
    const month = i + 1;
    if (month === 6) return juneAmount;

    return roundedEachMonthAmount;
  });
};

export const calcAnnualTaxAmount = () => (dispatch, getState) => {
  const taxAmounts = selector(getEmployeeFormValues(getState()), 'employeeInhabitantTax', 'taxAmounts');
  dispatch(formChange(EMPLOYEE_FORM, `employeeInhabitantTax.annualTaxAmount`, annualInhabitantTaxForEdit(taxAmounts)));
};
