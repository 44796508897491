import React from 'react';
import Button from 'jbc-front/components/Button';
import CommonModal from 'jbc-front/components/CommonModal';
import { ErrorsSection } from 'src/components';
import styles from './ConfirmModal.scss';

const ConfirmOneForm = ({ hideModal, errors, header, onSubmit }) => (
  <React.Fragment>
    <CommonModal.Header hideModal={hideModal}>{header}</CommonModal.Header>
    <CommonModal.Body>
      <form className={styles.wrap}>
        <ErrorsSection errors={errors} />
        <div>
          個別の給与確定を行います。<br />
          現在表示中の従業員のみ確定されます。<br />
          全体を確定したい場合は給与一覧画面で「給与確定」を押して下さい。
        </div>
      </form>
    </CommonModal.Body>
    <CommonModal.Footer>
      <Button onClick={hideModal} className="u-mr20">
        キャンセル
      </Button>
      <Button primary onClick={onSubmit}>
        確定
      </Button>
    </CommonModal.Footer>
  </React.Fragment>
);

const ConfirmOneModal = ({ isOpen, hideModal, onSubmit }) => {
  const header = '給与確定';
  return (
    <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
      <ConfirmOneForm hideModal={hideModal} header={header} onSubmit={onSubmit} />
    </CommonModal>
  );
};

export default ConfirmOneModal;
