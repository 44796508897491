import React from 'react';
import _ from 'lodash';
import queryString from 'query-string';

import { convertArrayIntoJbcStyle } from 'src/utils/Utils';
import { EMPLOYMENT_STATUSES } from 'src/constants/Options';
import { MainFormControls, MultiTagFilteringFieldRow } from 'jbc-front/components/SearchForm';
import jbcStyles from 'jbc-front/components/SearchForm.scss';
import styles from './MyNumberSearchForm.scss';
import FilterMyNumberStatusBtnList from './MyNumberStatusBtnList';

const DEFAULT_SEARCH = {
  enrollment_statuses: '',
  my_number_status: '0',
  sort: 'staffCode',
  order: 'asc'
};

const OMIT_FIELDS = ['page', 'per_page'];

export default class MyNumberSearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isExpand: false
    };
  }

  render() {
    const { location, handleClear } = this.props;
    const { isExpand } = this.state;
    const omitedSearch = _.omit(queryString.parse(location.search), OMIT_FIELDS);
    const isSearchParamsBlank = location.search === '';
    const isSearchParamsEqualDefaultCondition = _.isEqual(omitedSearch, DEFAULT_SEARCH);
    return (
      <div className="u-mb20">
        <div className={styles.mainSearchFields}>
          <FilterMyNumberStatusBtnList />
          <MainFormControls
            isExpand={isExpand}
            hasConditions={!isSearchParamsBlank && !isSearchParamsEqualDefaultCondition}
            onToggleClick={() => {
              this.setState({ isExpand: !isExpand });
            }}
            handleClear={() => handleClear(location)}
          />
        </div>
        {isExpand && (
          <div className={jbcStyles.details}>
            <div className={styles.flex}>
              <div className={styles.left}>
                <div className={jbcStyles.detailsRow}>
                  <MultiTagFilteringFieldRow
                    header="在籍状況"
                    fieldName="enrollmentStatuses"
                    list={convertArrayIntoJbcStyle(EMPLOYMENT_STATUSES.slice(1))}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
