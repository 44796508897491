import React from 'react';
import { connect } from 'react-redux';

import { getOptionLabel } from 'src/utils/Utils';
import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from './PayrollRuleInfo.scss';

const PayrollRuleInfo = ({ payrollRule, payrollTypes }) => (
  <div>
    {payrollRule && (
      <div className="u-mb20">
        <table className={styles.table}>
          <tbody>
            <tr>
              <th>締め日</th>
              <td>{payrollRule.closingLabel}</td>
            </tr>
            <tr>
              <th>支給日</th>
              <td>{payrollRule.paymentLabel}</td>
            </tr>
            <tr>
              <th>給与形態</th>
              <td>{getOptionLabel(payrollRule.payrollType)(payrollTypes)}</td>
            </tr>
            <tr>
              <th>1日の所定労働時間</th>
              <td>{payrollRule.prescribedWorkingHoursPerDay}</td>
            </tr>
            <tr>
              <th>1ヶ月の平均所定労働日数</th>
              <td>{payrollRule.avgRegularWorkingDaysPerMonth}</td>
            </tr>
            <tr>
              <th>1ヶ月の平均所定労働時間</th>
              <td>{payrollRule.avgRegularWorkingHoursPerMonth}</td>
            </tr>
          </tbody>
        </table>
      </div>
    )}
  </div>
);

export default connect(state => ({
  payrollTypes: getSelectOptions(state, 'payrollTypes')
}))(PayrollRuleInfo);
