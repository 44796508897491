import React from 'react';

import { EMPLOYEES_LIST, EMPLOYEE_ATTENDANCE_LIST, EMPLOYEE_PAY_LIST } from 'src/constants/EndpointUrls';
import { Employee, Kintai, Calculator, Pulldown, ArrowBold } from 'jbc-front/components/icons';
import { redirectTo } from 'src/utils/Http';

import styles from './EmployeeAndSalarySetting.scss';

const EmployeeAndSalarySetting = () => (
  <div>
    {/* 従業員情報の更新 */}
    <div className={styles.item} onClick={() => redirectTo(EMPLOYEES_LIST)} role="presentation">
      <div className={styles.icon}>
        <Employee size={35} />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>従業員情報の更新</div>
        <p className={styles.note}>「住所変更」「扶養変更」「給与改定」があった場合は従業員情報を更新しましょう</p>
      </div>
      <div className={styles.arrow}>
        <Pulldown size={10} />
      </div>
    </div>

    <div className={styles.next}>
      <ArrowBold size={15} />
    </div>

    {/* 勤怠情報の更新 */}
    <div className={styles.item} onClick={() => redirectTo(EMPLOYEE_ATTENDANCE_LIST)} role="presentation">
      <div className={styles.icon}>
        <Kintai size={35} />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>勤怠情報の更新</div>
        <p className={styles.note}>勤怠情報を取り込み、確認しましょう</p>
      </div>
      <div className={styles.arrow}>
        <Pulldown size={10} />
      </div>
    </div>

    <div className={styles.next}>
      <ArrowBold size={15} />
    </div>

    {/* 給与計算 */}
    <div className={styles.item} onClick={() => redirectTo(EMPLOYEE_PAY_LIST)} role="presentation">
      <div className={styles.icon}>
        <Calculator size={35} />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>給与計算</div>
        <p className={styles.note}>給与情報が正しいかチェックし、給与情報を確定させてください。</p>
      </div>
      <div className={styles.arrow}>
        <Pulldown size={10} />
      </div>
    </div>
  </div>
);
export default EmployeeAndSalarySetting;
