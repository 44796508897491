import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import { Pulldown } from 'jbc-front/components/icons';
import Balloon from 'src/components/Balloon';
import styles from './EgovBaloon.scss';

const EgovBaloon = onClickOutside(
  class extends Component {
    handleClickOutside = () => {
      this.props.hide();
    };
    constructor(props) {
      super(props);

      this.state = {};
    }

    render() {
      const { isLmsActive, openCsvModal, openEgovModal } = this.props;
      const handleOnClick = (event, disabled, action) => {
        if (disabled) {
          event.preventDefault();
        } else {
          action();
        }
      };
      const disabledEgovModal = !isLmsActive;

      return (
        <React.Fragment>
          <div className={styles.baloonWrap}>
            <div className={styles.baloon}>
              <ul className={styles.links}>
                <li>
                  <a onClick={e => handleOnClick(e, false, openCsvModal)} role="presentation">
                    <Pulldown size={8} />提出用CSVダウンロード
                  </a>
                </li>
                <li>
                  <a
                    disabled={disabledEgovModal}
                    className={disabledEgovModal ? styles.disabled : null}
                    onClick={e => handleOnClick(e, disabledEgovModal, openEgovModal)}
                    role="presentation"
                  >
                    <Pulldown size={8} />
                    <span className={styles.description}>
                      電子申請（e-Gov連携）
                      {!isLmsActive && (
                        <Balloon className={styles.balloon}>
                          ジョブカン労務HRをお使いの方のみご利用いただけます
                        </Balloon>
                      )}
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </React.Fragment>
      );
    }
  }
);

export default EgovBaloon;
