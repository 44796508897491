import _ from 'lodash';
import moment from 'moment';
import { createAction } from 'redux-actions';

import * as NumberUtils from 'src/utils/Number';

import { PAGING_PER_PAGE_NUMBERS } from '../Config';

const yearsAgo = ago =>
  moment()
    .subtract(ago, 'year')
    .year();
const optionsGenerator = (numberOptions, offset) => createOptionFn =>
  Array(numberOptions)
    .fill()
    .map((unused, i) => createOptionFn(offset + i));

const createYearOption = year => ({ value: year, label: year });
const createMonthOption = month => ({ value: month, label: month });
const createDayOption = day => ({ value: day, label: day });

const generateYearMonths = () => {
  const last = moment()
    .subtract(1, 'year')
    .subtract(0, 'month');
  let month = last.month() + 1;
  let year = last.year();

  const options = [];
  for (let i = 0; i < 12; i += 1) {
    if (month === 12) {
      month = 1;
      year += 1;
    } else {
      month += 1;
    }
    options.push({ label: `${year}年${NumberUtils.pad(month, 2)}月`, value: `${year}_${month}` });
  }

  return options.reverse();
};

export const generateWarekiYearMonth = baseYear => {
  const options = [];
  for (let i = 0; i < 12; i += 1) {
    const date = new Date(baseYear, 3 + i, 1);
    const wareki = date.toLocaleDateString('ja-JP-u-ca-japanese', { era: 'long', year: 'numeric', month: 'long' });
    options.push({ label: wareki, value: moment(date).format('YYYY-MM-DD') });
  }
  return options;
};

const generateYears = (numberYears, startYear) => optionsGenerator(numberYears, startYear)(createYearOption);
const generateMonths = () => optionsGenerator(12, 1)(createMonthOption);
const generateDays = () => optionsGenerator(31, 1)(createDayOption);
const generatePerPages = () => PAGING_PER_PAGE_NUMBERS.map(page => ({ value: page, label: `表示件数：${page}件` }));

// Selectors
export const getSelectOptions = (state, key) => _.get(state, `selectOptions.${key}`, []);
export const getRadioOptions = (state, key) => _.get(state, `selectOptions.${key}`, []);
export const getLabeledYears = state =>
  state.selectOptions.years.map(yMap => ({ value: yMap.value, label: `${yMap.label}年` }));
export const getLabeledMonths = state =>
  state.selectOptions.month.map(mMap => ({ value: mMap.value, label: `${mMap.label}月` }));
export const getLabeledDays = state =>
  state.selectOptions.days.map(dMap => {
    const label = dMap.value !== 31 ? `${dMap.label}日` : '末日';
    return { value: dMap.value, label };
  });

export const getLabeledYearsWithEmpty = state => [{ value: '', label: '' }, ...getLabeledYears(state)];
export const getLabeledMonthsWithEmpty = state => [{ value: '', label: '' }, ...getLabeledMonths(state)];
export const getLabeledDaysWithEmpty = state => [{ value: '', label: '' }, ...getLabeledDays(state)];

export const memoColors = [
  { value: '1', label: '#3498DB', subColor: '#EAF4FB' },
  { value: '2', label: '#FFCB03', subColor: '#FFFAE5' },
  { value: '3', label: '#F48080', subColor: '#FEECEC' },
  { value: '4', label: '#17B491', subColor: '#EAF5F3' },
  { value: '5', label: '#7D3CB2', subColor: '#F0EBF5' },
  { value: '6', label: '#FB8B4F', subColor: '#FAF2ED' }
];

export const otherSearchOptions = [
  { value: 'different_salary', label: '前月と基本給が異なる従業員のみ' },
  { value: 'different_commuting', label: '前月と通勤手当が異なる従業員のみ' },
  { value: 'joined_this_month', label: '当月に入社した従業員' },
  { value: 'retired_this_month', label: '当月に退職した従業員' }
];

export const syncYeaOptions = [
  { value: 'target', label: '連携対象に設定されている従業員' },
  { value: 'non_target', label: '連携対象に設定されていない従業員' }
];

export const saveStatusOptions = [{ value: 'not_yet', label: '未保存' }, { value: 'saved', label: '保存済' }];

export const isConfirmedPayrollOptions = [{ value: '0', label: '無し' }, { value: '1', label: '有り' }];

export const isConfirmedOptions = [{ value: '0', label: '未確定' }, { value: '1', label: '確定済' }];

const SET_OPTIONS = 'SELECT_OPTIONS/SET_OPTIONS';
export const setOptions = createAction(SET_OPTIONS);

export const INITIAL_STATE = {
  yearMonths: generateYearMonths(),
  years: generateYears(20, yearsAgo(19)),
  months: generateMonths(),
  days: generateDays(),
  perPages: generatePerPages(),
  memoColors,
  otherSearchOptions,
  syncYeaOptions,
  saveStatusOptions,
  isConfirmedPayrollOptions,
  isConfirmedOptions
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OPTIONS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
