import { connect } from 'react-redux';
import { change as formChange } from 'redux-form';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import selector from 'src/utils/Utils';
import BanksSection from '../components/BanksSection';

const mapStateToProps = state => ({
  lastNameKana: selector(getEmployeeFormValues(state), 'employee', 'lastNameKana'),
  firstNameKana: selector(getEmployeeFormValues(state), 'employee', 'firstNameKana')
});

const mapDispatchToProps = dispatch => ({
  fieldInitialize: () => {
    dispatch(formChange(EMPLOYEE_FORM, `payPaymentBanks[0].checked`, true));
    dispatch(formChange(EMPLOYEE_FORM, `payPaymentBanks[0].transferType`, 'all'));
    dispatch(formChange(EMPLOYEE_FORM, `bonusPaymentBanks[0].checked`, true));
    dispatch(formChange(EMPLOYEE_FORM, `bonusPaymentBanks[0].transferType`, 'all'));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(BanksSection);
