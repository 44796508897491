import React, { Component } from 'react';
import { Pulldown } from 'jbc-front/components/icons';
import { connect } from 'react-redux';
import { getEmployeeBonusQueries, clearErrors } from 'src/reducers/employees/employeeBonus';
import DownloadBonusFbModalContainer from '../containers/DownloadBonusFbModalContainer';
import styles from './DownloadBonusFbContainer.scss';

class DownloadBonusFbContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { isExportBonusModalOpen: false };

    this.openExportBonusModal = this.openExportBonusModal.bind(this);
    this.closeExportBonusModal = this.closeExportBonusModal.bind(this);
  }

  openExportBonusModal() {
    if (this.state.isExportBonusModalOpen) return;
    this.setState({ ...this.state, isExportBonusModalOpen: true });
  }

  closeExportBonusModal() {
    if (!this.state.isExportBonusModalOpen) return;
    this.props.clearErrors();
    this.setState({ ...this.state, isExportBonusModalOpen: false });
  }

  render() {
    const { confirmed, queries } = this.props;

    return (
      <div className={styles.links}>
        <a className={confirmed ? undefined : styles.disabled} onClick={this.openExportBonusModal} role="presentation">
          <Pulldown size={8} />賞与振込データ出力
        </a>
        <DownloadBonusFbModalContainer
          isOpen={this.state.isExportBonusModalOpen}
          queries={queries}
          hideModal={this.closeExportBonusModal}
        />
      </div>
    );
  }
}

export default connect(
  state => ({
    queries: getEmployeeBonusQueries(state)
  }),
  dispatch => ({
    clearErrors: () => dispatch(clearErrors())
  })
)(DownloadBonusFbContainer);
