import axios from 'axios';
import { combineReducers } from 'redux';
import { startSubmit, stopSubmit } from 'redux-form';

import * as Urls from 'src/constants/EndpointUrls';
import { concatParamsToUrl, redirectTo } from 'src/utils/Http';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import {
  newItemFailure,
  editItem,
  editItemFailure,
  deleteItem,
  deleteItemFailure,
  getItemErrors
} from 'src/reducers/common/itemReducer';
import { setList } from 'src/reducers/common/listReducer';
import { PAYROLL_RULES_GROUP_FORM } from 'src/constants/FormNames';

export const REDUCER_NAME = 'payrollRulesGroups';
export const getPayrollRulesGroupsList = state => state[REDUCER_NAME].list.data;
export const setPayrollRulesGroupsList = data => setList(REDUCER_NAME, { data });

export const getDeletePayrollRulesGroupError = state => getItemErrors(REDUCER_NAME, state);
export const clearErrors = () => dispatch => dispatch(deleteItem(REDUCER_NAME, []));

// Async Action Creators
export const newPayrollRulesGroup = data => async dispatch => {
  try {
    dispatch(startSubmit(PAYROLL_RULES_GROUP_FORM));
    await axios.post(Urls.CREATE_CLIENT_PAYROLL_RULES_GROUP_URL, data);
    redirectTo(Urls.CLIENT_PAYROLL_RULES_GROUP_LIST);
  } catch (exception) {
    dispatch(stopSubmit(PAYROLL_RULES_GROUP_FORM));
    dispatch(newItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const delPayrollRulesGroup = payrollRulesGroupId => async dispatch => {
  try {
    dispatch(deleteItem(REDUCER_NAME));
    await axios.delete(concatParamsToUrl(Urls.UPDATE_CLIENT_PAYROLL_RULES_GROUP_URL, { id: payrollRulesGroupId }));
    redirectTo(Urls.CLIENT_PAYROLL_RULES_GROUP_LIST);
  } catch (exception) {
    dispatch(deleteItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const editPayrollRulesGroup = data => async dispatch => {
  try {
    dispatch(editItem(REDUCER_NAME));
    await axios.put(concatParamsToUrl(Urls.UPDATE_CLIENT_PAYROLL_RULES_GROUP_URL, { id: data.id }), data);
    redirectTo(Urls.CLIENT_PAYROLL_RULES_GROUP_LIST);
  } catch (exception) {
    dispatch(editItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

export const settingPaymentDate = (data, callback) => async dispatch => {
  try {
    const resp = await axios.put(Urls.SETTING_PAYMENT_DATES_CLIENT_PAYROLL_RULES_GROUPS, data);
    dispatch(setGlobalSuccesses('締め日・支給日を設定しました。'));
    if (typeof callback === 'function') {
      callback();
    }
    dispatch(setPayrollRulesGroupsList(resp.data.payload.payrollRulesGroups));
  } catch (e) {
    dispatch(setGlobalErrors(e.message));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
