import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import isEmpty from 'lodash/isEmpty';

import { EMPLOYEE_SHOW, EMPLOYEES_LIST } from 'src/constants/EndpointUrls';
import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, editEmployee } from 'src/reducers/employees/employees';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { onSubmitFail } from 'jbc-front/components/FormErrors';
import { getItem, getItemErrors, setItem } from 'src/reducers/common/itemReducer';
import { concatArray } from 'src/utils/Utils';
import { defaultCommutingItem } from 'src/utils/Form';
import { detailUrlWithQueries } from 'src/utils/Http';
import { getRadioOptions } from 'src/reducers/selectOptions';
import filterSelectOption from 'src/utils/EmployeeClassificationOptions';
import { fetchEmployeeDetails } from 'src/reducers/fetchSelectOptions';
import useInitializeData from 'src/hooks/useInitializeData';

import { customEmployeeFamilySupportValidate } from './components/FamilySupportsSection';
import { validate as bussinessValidate } from './components/BusinessInfoSection';
import EmployeeEditingForm from './containers/EmployeeEditingFormContainer';

const validate = values => {
  const errors = Object.assign(
    {},
    ...[customEmployeeFamilySupportValidate, bussinessValidate].map(customValidate => customValidate(values))
  );
  return isEmpty(errors) ? undefined : errors;
};

let EditEmployeeForm = reduxForm({
  form: EMPLOYEE_FORM,
  onSubmitFail,
  validate
})(EmployeeEditingForm);

const mapStateToProps = state => ({
  errors: getItemErrors(REDUCER_NAME, state),
  initialValues: {
    ...getItem(REDUCER_NAME, state)
  }
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => {
    const employeeId = data.employee.id;
    dispatch(editEmployee(data, employeeId, ownProps.showEmployeeUrl));
  }
});

EditEmployeeForm = connect(mapStateToProps, mapDispatchToProps)(EditEmployeeForm);

const EditEmployee = props => {
  const { dispatch, values } = props;

  useInitializeData(() => {
    dispatch(fetchEmployeeDetails());
    // Format 通勤手当データ
    const basicCommuting = ['train', 'car', 'bus'];
    const initEmployeeCommutings = values.employeeCommutings || [];
    basicCommuting.forEach(meansOfCommunication => {
      if (!initEmployeeCommutings.find(item => item.meansOfCommunication === meansOfCommunication)) {
        initEmployeeCommutings.push(defaultCommutingItem(meansOfCommunication));
      }
    });
    values.employeeCommutings = initEmployeeCommutings;

    // Format 支給・控除項目の金額設定データ
    const clientAllowanceToEmployeeAllowance = clientAllowance => ({
      clientAllowanceId: clientAllowance.id,
      clientAllowanceName: clientAllowance.name,
      clientPayrollRulesGroupId: clientAllowance.clientPayrollRulesGroupId,
      paymentAmount: 0
    });

    const blankclientAllowancesWithId = values.clientAllowanceAmountTypeIndividual.map(item =>
      clientAllowanceToEmployeeAllowance(item)
    );
    const pickUpCondition = (employeeAllowances, blankclientAllowancesItem) =>
      !employeeAllowances.map(item => item.clientAllowanceId).includes(blankclientAllowancesItem.clientAllowanceId);

    const initEmployeeAllowances = concatArray(values.employeeAllowances, blankclientAllowancesWithId)(pickUpCondition);
    values.employeeAllowances = initEmployeeAllowances;

    // Format 支給・控除項目の金額設定データ
    const clientUnitPriceToEmployeeUnitPrice = clientUnitPrice => ({
      clientUnitPriceId: clientUnitPrice.id,
      clientUnitPriceName: clientUnitPrice.name,
      clientPayrollRulesGroupId: clientUnitPrice.clientPayrollRulesGroupId,
      useType: 'payroll_rules_group',
      amount: 0
    });

    const blankclientUnitPricesWithId = values.clientUnitPrices.map(item => clientUnitPriceToEmployeeUnitPrice(item));
    const pickUpUnitPriceCondition = (employeeUnitPrices, blankclientUnitPricesItem) =>
      !employeeUnitPrices.map(item => item.clientUnitPriceId).includes(blankclientUnitPricesItem.clientUnitPriceId);

    const initEmployeeUnitPrices = concatArray(values.employeeUnitPrices, blankclientUnitPricesWithId)(
      pickUpUnitPriceCondition
    );
    values.employeeUnitPrices = initEmployeeUnitPrices;

    // Format ＜社会保険＞
    if (!values.employeeInsurance.employeeClassification) {
      values.employeeInsurance.employeeClassification = 'common';
    }
    const { isJoinedEmploymentInsurance, employeeClassification } = values.employeeInsurance;

    const selectOptions = filterSelectOption(isJoinedEmploymentInsurance, props.employeeClassifications);
    if (!_.isEmpty(selectOptions) && !_.some(selectOptions, item => _.get(item, 'value') === employeeClassification)) {
      values.employeeInsurance.employeeClassification = selectOptions[0].value;
    }

    dispatch(setItem(REDUCER_NAME, values));
  });

  const { standardYear, standardMonth } = values;
  const employeeId = _.get(values, 'employee.id');
  const initBusinessLastName = _.get(values, 'employee.businessLastName');
  const initBusinessFirstName = _.get(values, 'employee.businessFirstName');
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const showEmployeeUrl = detailUrlWithQueries(
    _.get(values, 'employee.id'),
    _.get(values, 'currentQueries'),
    EMPLOYEE_SHOW
  );

  return (
    <div className="l-wrap-xl l-contents-wrap">
      <div className="u-mb20">
        <BreadCrumb>
          <BreadCrumb.Item label="従業員一覧" path={EMPLOYEES_LIST} />
          <BreadCrumb.Item label="従業員情報" path={showEmployeeUrl} />
          <BreadCrumb.Item label="従業員情報編集" />
        </BreadCrumb>
      </div>
      <EditEmployeeForm
        {...{
          standardYear,
          standardMonth,
          employeeId,
          showEmployeeUrl,
          initBusinessLastName,
          initBusinessFirstName,
          isOpenConfirmationModal,
          setIsOpenConfirmationModal
        }}
      />
    </div>
  );
};

export default connect(state => ({
  employeeClassifications: getRadioOptions(state, 'employeeClassifications')
}))(EditEmployee);
