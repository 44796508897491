import React from 'react';
import * as Urls from 'src/constants/EndpointUrls';
// CSS
import styles from './DownloadColumnDetail.scss';
// CSV ダウンロードカラム説明
const DownloadColumnDetail = () => (
  <div className="l-wrap-l">
    <div className="l-overflow-scroll">
      <table className="m-table-description">
        <thead>
          <tr>
            <th className="m-table-description-item-name">データ項目名</th>
            <th>説明文</th>
            <th className="m-table-description-example">例</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className={styles.detail_item_column}>
              スタッフコード<span className={styles.required}>必須</span>
            </th>
            <td>スタッフコードを基にデータが取り込まれるため、正確に入力してください。</td>
            <td>111</td>
          </tr>
          <tr>
            <th>姓</th>
            <td>スタッフコードによって判別しているため、空白でも更新可能です。</td>
            <td>山田</td>
          </tr>
          <tr>
            <th>名</th>
            <td>スタッフコードによって判別しているため、空白でも更新可能です。</td>
            <td>太郎</td>
          </tr>
          <tr>
            <th>
              支給日<span className={styles.required}>必須</span>
            </th>
            <td>
              yyyy/mm/dd<br />
              表示されている支給日と「入力された支給日」が異なる場合はエラーになります。<br />
              賞与一覧でアップロードしたい月に画面を切り替えた後、再度「支給・控除項目CSVインポート」をクリックしてください。
            </td>
            <td>2018/01/25</td>
          </tr>
          <tr>
            <th>
              賞与名<span className={styles.required}>必須</span>
            </th>
            <td>
              表示されている賞与名と「入力された賞与名」が異なる場合はエラーになります。<br />
              賞与一覧でアップロードしたい月に画面を切り替えた後、再度「支給・控除項目CSVインポート」をクリックしてください。
            </td>
            <td>業績賞与</td>
          </tr>
          <tr>
            <th>
              支給項目名<br />控除項目名
            </th>
            <td>
              <a href={Urls.CLIENT_BONUS_ALLOWANCES_LIST} className="u-txt-link">
                賞与規定
              </a>
              で設定された「支給項目」「控除項目」をアップロード可能です。<br />
              各種保険料など、項目を自動計算させたい場合は、その支給・控除項目を空白にするか、列ごと削除してから取り込んでください。<br />
              値を入力して取り込んだ場合は、自動計算されません。<br />
              ※取り込み後に自動計算に戻したい場合は、各従業員の賞与編集画面から「手入力前に戻す」を押してください。<br />
              <br />
              また、アップロードが不要な従業員がいる場合は、該当するデータを行ごと削除してからアップロードすることも可能です。
            </td>
            <td>賞与額</td>
          </tr>
          <tr>
            <th>備考</th>
            <td>
              賞与明細の備考欄に表示したい内容を入力してください。（255文字以内）
            </td>
            <td>社長からの一言：非常に高い目標にも関わらず、部署達成おめでとうございます。</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

export default DownloadColumnDetail;
