import React from 'react';
import { connect } from 'react-redux';

import ExtensionSelectField from 'src/components/ExtensionSelectField';
import { getSelectOptions } from 'src/reducers/selectOptions';

import styles from './GeneralInsuranceInfoSection.scss';

const GeneralInsuranceInfoSection = ({ insuredPersonTypes }) => (
  <div>
    <ExtensionSelectField
      name="insuredPersonType"
      label="被保険者区分"
      options={insuredPersonTypes}
      addBlankOption={false}
      note={
        <ul className={styles.list}>
          <li>一般の被保険者・・・「パート扱いする者」「短時間労働者」以外はコチラを選択してください。</li>
          <li>
            パート扱いする者・・・1週間の所定労働時間および1カ月の所定労働日数が正社員の4分の3以上の短時間就労者の場合に選択してください。
          </li>
          <li>短時間労働者・・・特定適用事業所に勤務する短時間労働者の場合に選択してください。</li>
          {/*
          <li>
            パート扱いする者・・・1週間の所定労働時間および1カ月の所定労働日数が正社員の4分の3以上の短時間就労者の場合に選択してください。定時決定（算定基礎届）での算定方法が変更されます。算定方法の詳細につきましては<a
              href="https://jobcan-payroll.zendesk.com/hc/ja"
              rel="noopener noreferrer"
              target="_blank"
            >
              ヘルプページ
            </a>をご覧ください。
          </li>
          <li>
            短時間労働者・・・特定適用事業所に勤務する短時間労働者の場合に選択してください。定時決定（算定基礎届）や随時改定（月額変更届）での判定方法が変更されます。「短時間労働者」の定義や、算定方法の詳細につきましては<a
              href="https://jobcan-payroll.zendesk.com/hc/ja"
              rel="noopener noreferrer"
              target="_blank"
            >
              ヘルプページ
            </a>をご覧ください。
          </li>
          */}
        </ul>
      }
    />
  </div>
);

export default connect(state => ({
  insuredPersonTypes: getSelectOptions(state, 'insuredPersonTypes')
}))(GeneralInsuranceInfoSection);
