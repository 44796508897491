import { connect } from 'react-redux';
import { reduxForm, change as formChange } from 'redux-form';
import _ from 'lodash';
import compose from 'lodash/fp/compose';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { CLIENT_CSV_FORMAT_FORM } from 'src/constants/FormNames';
import { REDUCER_NAME, createCsvFormat, editCsvFormat, deleteCsvFormat } from 'src/reducers/clients/csvFormats';
import { getItem } from 'src/reducers/common/itemReducer';
import { EMPLOYEES_LIST } from 'src/constants/EndpointUrls';
import { redirectTo } from 'src/utils/Http';
import Cookies from 'js-cookie'; // eslint-disable-line
import ClientCsvEmployeeFormatForm from '../components/ClientCsvEmployeeFormatForm';

const addPosition = items => [...items.map((item, index) => ({ ...item, position: index }))];

const mapStateToProps = state => ({
  initialValues: {
    clientCsvFormat: _.get(state, 'clientCsvFormats.item.data.clientCsvFormat'),
    clientCsvFormatFields: _.get(state, 'clientCsvFormats.item.data.currentSelectedFields'),
    goBackPath: EMPLOYEES_LIST,
    noFlushMessage: true
  },
  csvAvaiableItems: getSelectOptions(state, 'csvAvaiableItems'),
  clientCsvData: getItem(REDUCER_NAME, state)
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  onSubmit: data => {
    if (ownProps.isCreateForm) {
      dispatch(createCsvFormat(data));
    } else {
      dispatch(editCsvFormat(data));
    }
  },
  onDelete: async formatId => {
    await dispatch(deleteCsvFormat(formatId, true));
    Cookies.set('formatDeleted', 1, { expires: 10 });
    redirectTo(EMPLOYEES_LIST);
  },
  dispatchFormValue: data => {
    if (Object.keys(data).includes('rightItems')) {
      dispatch(formChange(CLIENT_CSV_FORMAT_FORM, 'clientCsvFormatFields', addPosition(data.rightItems)));
    }
  }
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: CLIENT_CSV_FORMAT_FORM,
    enableReinitialize: true
  })
)(ClientCsvEmployeeFormatForm);
