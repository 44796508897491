import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { numberWithComma } from 'src/utils/Utils';

import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { CLIENT_SETTINGS, EDIT_CLIENT_SETTING_USING_PLAN } from 'src/constants/EndpointUrls';
import { PLAN_TYPES, isTrialPlan, isPaidPlan } from 'src/reducers/clients/currentClient';
import Button from 'jbc-front/components/Button';
import CreditCardInfo from 'jbc-front/components/CreditCardInfo';
import styles from './ShowClientPaidPlan.scss';
import ChangePaymentMethod from './ChangePaymentMethod';

const MonthlyBillingRows = ({ monthlyBillings }) => {
  if (_.isEmpty(monthlyBillings)) {
    return (
      <tr>
        <td colSpan="5">請求情報の履歴はありません</td>
      </tr>
    );
  }
  return monthlyBillings.map(monthlyBilling => (
    <tr key={monthlyBilling.id}>
      <td>{moment(monthlyBilling.dateOn).format('YYYY年M月')}</td>
      <td>¥{numberWithComma(monthlyBilling.basePrice)}</td>
      <td>{monthlyBilling.chargeableEmployeesCount}人</td>
      <td>¥{numberWithComma(monthlyBilling.price)}</td>
      <td>¥{numberWithComma(monthlyBilling.totalPrice)}</td>
    </tr>
  ));
};

const ShowClientPaidPlan = ({
  trialFinishAt,
  planType,
  planControllable,
  paymentMethodType,
  cards,
  employeeEmail,
  monthlyBillings
}) => (
  <div>
    <div className="l-main-title-wrap">
      <h1 className="m-title-main">利用プラン</h1>
    </div>
    <div className="l-wrap-m l-contents-wrap">
      <div className="u-mb20">
        <BreadCrumb>
          <BreadCrumb.Item label="設定" path={CLIENT_SETTINGS} />
          <BreadCrumb.Item label="利用プラン" />
        </BreadCrumb>
      </div>

      <div className={styles.box}>
        <div className={styles.boxHead}>利用プラン</div>
        <div className={styles.boxBody}>
          <div className={styles.plans}>
            <div className={styles.plansName}>{PLAN_TYPES[planType]}</div>
            {isTrialPlan(planType) ? (
              <div className={styles.plansDate}>
                お試し期間終了日: <span>{moment(trialFinishAt).format('YYYY年MM月DD日')}</span>
              </div>
            ) : null}
          </div>
          {!isPaidPlan(planType) ? (
            <div>
              <p className={styles.plansNote}>
                お試しプラン終了後は無料プランに移行します。無料プランは機能制限があります
              </p>
              <ul className={styles.plansNoteLists}>
                <li>・従業員の登録人数が5人まで</li>
                <li>・サービス連携が利用できません</li>
                <li>・チャットサポートが利用できません</li>
              </ul>
              <div className="u-mt10">
                <a
                  href="https://payroll.jobcan.ne.jp/plan/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="u-txt-link"
                >
                  詳しくはこちら
                </a>
              </div>
              <div className="u-mt50 u-mb20 u-ta-c">
                <Button success large as="a" href={EDIT_CLIENT_SETTING_USING_PLAN}>
                  有料プランの申込はこちら
                </Button>
              </div>
            </div>
          ) : (
            planControllable && (
              <div>
                <div className={styles.payments}>
                  <p className={styles.paymentsTitle}>
                    支払い方法
                    <ChangePaymentMethod employeeEmail={employeeEmail} />
                  </p>
                  <div className={styles.paymentsMethod}>{paymentMethodType}</div>
                  {cards &&
                    cards[0] && (
                      <div>
                        <div className="u-mb5 u-mt20">カード情報</div>
                        <CreditCardInfo
                          cardInfo={{ ...cards[0], exp_month: cards[0].expMonth, exp_year: cards[0].expYear }}
                          className={styles.cardInfo}
                        />
                      </div>
                    )}
                </div>
              </div>
            )
          )}
        </div>
      </div>

      {isPaidPlan(planType) && planControllable ? (
        <div className={styles.billings}>
          <p className={styles.billingsTitle}>請求情報</p>
          <table className="m-table-list">
            <thead>
              <tr>
                <th>年/月</th>
                <th>単価</th>
                <th>ユーザー数</th>
                <th>合計額（税抜き）</th>
                <th>合計額（税込み）</th>
              </tr>
            </thead>
            <tbody>
              <MonthlyBillingRows monthlyBillings={monthlyBillings} />
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  </div>
);

export default ShowClientPaidPlan;
