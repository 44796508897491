import { connect } from 'react-redux';

import { setReportHealthInsuranceBonusItem } from 'src/reducers/reports/reportEditHealthInsuranceBonus';
import EditReportHealthInsuranceBonus from '../components/EditReportHealthInsuranceBonus';

const mapStateToProps = state => ({ ...state });
const mapDispatchToProps = (dispatch, ownProps) => ({
  setFormValuesToState: () => {
    dispatch(setReportHealthInsuranceBonusItem(ownProps.values));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditReportHealthInsuranceBonus);
