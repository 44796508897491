import React from 'react';
import LazyLoad from 'react-lazyload';
import { FormSection } from 'redux-form';
import { takeFormErrors } from 'src/utils/Form';
import HeadErrors from 'src/components/HeadErrors';
import RevisionItemBody from './RevisionItemBody';
import RevisionItemHeader from './RevisionItemHeader';

const fieldMap = {
  firstMonthBasicDays: '1ヶ月目の支払基礎日数',
  secondMonthBasicDays: '2ヶ月目の支払基礎日数',
  thirdMonthBasicDays: '3ヶ月目の支払基礎日数',
  firstMonthCurrencyAmount: '1ヶ月目の通貨による額',
  secondMonthCurrencyAmount: '2ヶ月目の通貨による額',
  thirdMonthCurrencyAmount: '3ヶ月目の通貨による額',
  firstMonthGenbutsuAmount: '1ヶ月目の現物による額',
  secondMonthGenbutsuAmount: '2ヶ月目の現物による額',
  thirdMonthGenbutsuAmount: '3ヶ月目の現物による額',
  firstMonthRetroactiveAmount: '1ヶ月目の遡及支払額',
  secondMonthRetroactiveAmount: '2ヶ月目の遡及支払額',
  thirdMonthRetroactiveAmount: '3ヶ月目の遡及支払額',
  modifiedAverageAmount: '修正平均額',
  totalRetroactiveAmount: '遡及支払額',
  changedAmount: '昇給差の月額',
  over70YearsOldRevisionDate1: '70歳以上の被用者算定基礎月',
  note: '備考'
};

const RevisionItem = ({
  revision,
  formRevision,
  showMode,
  changedYearMonths,
  lastRevisionYearMonths,
  changeToEditMode,
  changeToManualCalcMode,
  notifyValueChanges,
  setChangedAmountByAmountChangedAt,
  insuredPersonTypes,
  cancel,
  save,
  recalculate,
  reset,
  setFormField,
  errorsOnSave,
  setRetroactiveEnabledAllTrue
}) => (
  <div className="u-mb40">
    <FormSection name={`revisions.${revision.id}`}>
      <LazyLoad>
        <RevisionItemHeader
          revision={revision}
          formRevision={formRevision}
          showMode={showMode}
          changeToEditMode={changeToEditMode}
          cancel={cancel}
          save={save}
          reset={reset}
        />
      </LazyLoad>
      <HeadErrors errors={takeFormErrors(errorsOnSave, fieldMap)} />
      <LazyLoad>
        <RevisionItemBody
          revision={revision}
          formRevision={formRevision}
          showMode={showMode}
          changedYearMonths={changedYearMonths}
          insuredPersonTypes={insuredPersonTypes}
          recalculate={recalculate}
          changeToManualCalcMode={changeToManualCalcMode}
          notifyValueChanges={notifyValueChanges}
          setChangedAmountByAmountChangedAt={setChangedAmountByAmountChangedAt}
          setFormField={setFormField}
          lastRevisionYearMonths={lastRevisionYearMonths}
          setRetroactiveEnabledAllTrue={setRetroactiveEnabledAllTrue}
        />
      </LazyLoad>
    </FormSection>
  </div>
);

export default RevisionItem;
