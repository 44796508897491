import React, { Component } from 'react';

import EditableListButtons from 'src/features/EmploymentTypes/containers/EditableListButtons';
import EditableListFormed from 'src/features/EmploymentTypes/containers/EditableListFormed';

export default class EmploymentTypes extends Component {
  render() {
    return (
      <div>
        <EditableListButtons />
        <EditableListFormed type="雇用形態" />
      </div>
    );
  }
}
