import { connect } from 'react-redux';

import { fetchReportWageLedger } from 'src/reducers/reports/reportWageLedger';
import { takeWageLedgersSearchQueries } from 'src/reducers/searchForm';
import YearSelect from '../components/YearSelect';

function mapDispatchToProps(dispatch) {
  return {
    searchReportWageLedger: form => {
      dispatch(fetchReportWageLedger(takeWageLedgersSearchQueries(form)));
    }
  };
}

export default connect(null, mapDispatchToProps)(YearSelect);
