import { concatParamsToUrl, serializeHttpGetParams } from 'src/utils/Http';
import { findIndex } from 'lodash';
import { combineReducers } from 'redux';
import axios from 'axios';

import * as Urls from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { newItemFailure } from 'src/reducers/common/itemReducer';
import {
  setList,
  getList,
  getExtras,
  setExtras,
  getListQueries,
  fetchList,
  fetchListSuccess
} from 'src/reducers/common/listReducer';
import { listReducer, itemReducer } from 'src/reducers/common';
import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

export const REDUCER_NAME = 'memos';
export const MEMO_COLOR_OPTIONS = 'memoColors';

// Use hash for look up
export const isCurrentEmployeeHasMemo = (state, employeeId) => {
  const memoExtras = getExtras(REDUCER_NAME, state);
  return memoExtras[employeeId];
};

// get Employee Memo from state
export const getEmployeeMemo = (state, employeeId) => {
  const currentMemoList = getList(REDUCER_NAME, state) || [];
  return currentMemoList.filter(memo => memo.employeeId === employeeId);
};

// Fetch Memos List For Employee
export const fetchMemosWithEmployeeId = employeeId => async (dispatch, getState) => {
  const currentMemoList = getList(REDUCER_NAME, getState());
  const fetchedList = currentMemoList.find(memo => memo.employeeId === employeeId);
  // only fetch when it has not been fetched before
  if (!fetchedList) {
    dispatch(fetchList(REDUCER_NAME));

    const queries = getListQueries(REDUCER_NAME, getState());
    const params = serializeHttpGetParams(queries);

    const response = await axios.get(`${Urls.SEARCH_MEMO_URL}?${params}`);
    const newPayLoad = { data: currentMemoList.concat(response.data.payload.memos) };
    dispatch(fetchListSuccess(REDUCER_NAME, newPayLoad));
  }
};

// excute if callback is function
const excuteCallBack = callback => {
  if (typeof callback === 'function') {
    callback();
  }
};

// add new employeeId to memoExtra
export const addMemoExtra = (dispatch, state, newMemo) => {
  const memoExtras = getExtras(REDUCER_NAME, state);
  if (!memoExtras[newMemo.employeeId]) {
    memoExtras[newMemo.employeeId] = newMemo;
    dispatch(setExtras(REDUCER_NAME, memoExtras));
  }
};

// Save Memo(Edit & Save)
export const saveMemo = (data, onSuccessCallBack) => async (dispatch, getState) => {
  try {
    const isUpdate = !!data.id;
    const memoList = getList(REDUCER_NAME, getState());
    if (isUpdate) {
      const updateUrl = concatParamsToUrl(Urls.UPDATE_MEMO_URL, { id: data.id });
      const response = await axios.put(updateUrl, { memo: data });
      const index = findIndex(memoList, item => item.id === data.id);
      memoList.splice(index, 1, response.data.payload.memo);

      excuteCallBack(onSuccessCallBack);
      dispatch(setList(REDUCER_NAME, { data: [...memoList] }));
    } else {
      const response = await axios.post(Urls.CREATE_MEMO_URL, { memo: data });
      // Update memoList
      excuteCallBack(onSuccessCallBack);
      dispatch(setList(REDUCER_NAME, { data: [...memoList, response.data.payload.memo] }));
      addMemoExtra(dispatch, getState(), response.data.payload.memo);
    }

    dispatch(newItemFailure(REDUCER_NAME, []));
  } catch (exception) {
    dispatch(newItemFailure(REDUCER_NAME, exception.response.data.errors.messages));
  }
};

// Delete Memo
export const deleteMemo = (memoId, employeeId) => async (dispatch, getState) => {
  try {
    const deleteUrl = concatParamsToUrl(Urls.DELETE_MEMO, { id: memoId });
    const memoList = getList(REDUCER_NAME, getState());
    const memoExtras = getExtras(REDUCER_NAME, getState());
    await axios.delete(deleteUrl);
    // delete from memoList
    dispatch(setList(REDUCER_NAME, { data: [...memoList.filter(item => item.id !== memoId)] }));
    // delete from memoExtras if employee has no memo
    const isEmployeeHasMemo = memoList.find(item => item.id !== memoId && item.employeeId === employeeId);
    if (!isEmployeeHasMemo) {
      memoExtras[employeeId] = false;
      dispatch(setExtras(REDUCER_NAME, memoExtras));
    }
    dispatch(setGlobalSuccesses('メモを削除しました。'));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export const fetchTodayMemos = () => async (dispatch, getState) => {
  try {
    dispatch(fetchList(REDUCER_NAME));

    const queries = getListQueries(REDUCER_NAME, getState());
    const params = serializeHttpGetParams(queries);

    const response = await axios.get(`${Urls.SEARCH_TODAY_ALERTS}?${params}`);
    const { totalCount = 0, displayFrom = 0, displayTo = 0 } = response.data.payload;
    const payload = {
      data: response.data.payload.memos,
      pageCount: response.data.payload.totalPages,
      totalCount,
      displayFrom,
      displayTo
    };
    dispatch(fetchListSuccess(REDUCER_NAME, payload));
  } catch (exception) {
    dispatch(setGlobalErrors(exception.response.data.errors.messages));
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(listReducer, REDUCER_NAME),
  item: createNamedWrapperReducer(itemReducer, REDUCER_NAME)
});
