import React from 'react';
import { connect } from 'react-redux';

import { IS_ACCIDENT_INSURANCE_CALC } from 'src/constants/Options';
import { RadioField, Label } from 'jbc-front/components/Form';
import { getEmployeeFormValues } from 'src/reducers/employees/employees';
import selector from 'src/utils/Utils';

const AccidentInsuranceSection = ({ isJoinedEmploymentInsurance, employeeClassification }) => (
  <div>
    {(isJoinedEmploymentInsurance === false || isJoinedEmploymentInsurance === '0') &&
    employeeClassification === 'officer' ? (
      <div>
        <Label
          text="労災保険料の計算対象"
          note={<p>在籍出向等で労災保険料の計算対象とならない場合は、対象外を選択してください。</p>}
        />
        <p>対象外</p>
      </div>
    ) : (
      <RadioField
        label="労災保険料の計算対象"
        name="isAccidentInsuranceCalc"
        options={IS_ACCIDENT_INSURANCE_CALC}
        note={<p>在籍出向等で労災保険料の計算対象とならない場合は、対象外を選択してください。</p>}
      />
    )}
  </div>
);

const mapStateToProps = state => ({
  isJoinedEmploymentInsurance: selector(
    getEmployeeFormValues(state),
    'employeeInsurance',
    'isJoinedEmploymentInsurance'
  ),
  employeeClassification: selector(getEmployeeFormValues(state), 'employeeInsurance', 'employeeClassification')
});

export default connect(mapStateToProps)(AccidentInsuranceSection);
