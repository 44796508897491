import React from 'react';
import CommonModal from 'jbc-front/components/CommonModal';
import Button from 'jbc-front/components/Button';
import { FormSubmitBtn } from 'src/buttons';

const DeleteMemoModal = ({ isOpen, hideModal, removeMemo }) => (
  <CommonModal ariaHideApp={false} {...{ isOpen, hideModal }}>
    <React.Fragment>
      <CommonModal.Header hideModal={hideModal}>メモを削除</CommonModal.Header>
      <CommonModal.Body>
        <form>メモを削除してもよろしいですか。</form>
      </CommonModal.Body>
      <CommonModal.Footer>
        <Button onClick={hideModal} className="u-mr20">
          キャンセル
        </Button>
        <FormSubmitBtn text="削除" onClick={() => removeMemo()} />
      </CommonModal.Footer>
    </React.Fragment>
  </CommonModal>
);

export default DeleteMemoModal;
