import axios from 'axios';
import * as Urls from 'src/constants/EndpointUrls';
import { redirectTo } from 'src/utils/Http';

import { setGlobalErrors, setGlobalSuccesses } from 'src/reducers/global';

export const sendResetPasswordEmail = data => async dispatch => {
  try {
    await axios.post(Urls.USER_RESET_PASSWORD_CREATE, { user: data });
    dispatch(setGlobalSuccesses('パスワードの再設定メールを送信しました。'));
  } catch (e) {
    if (e.response.status === 401) {
      dispatch(setGlobalErrors('ページをリロードしてもう一度試してください。'));
    } else if (e.response.status === 400) {
      dispatch(setGlobalErrors(e.response.data.message));
    } else if (e.response.data.errors) {
      dispatch(setGlobalErrors('メールは見つかりませんでした。'));
    } else {
      dispatch(setGlobalErrors(e.message));
    }
  }
};

export const setNewPassword = (data, resetPasswordToken, setInvalidToken) => async dispatch => {
  try {
    const httpPayload = { user: { ...data, resetPasswordToken } };

    await axios.put(Urls.USER_RESET_PASSWORD_UPDATE, httpPayload);
    dispatch(setGlobalSuccesses('パスワードを再設定しました。'));
    redirectTo(Urls.DASHBOARD_SHOW);
  } catch (e) {
    if (e.response.status === 401 || e.response.status === 410) {
      // reset_password_tokenは有効期限がすぎてるか、認証終わりましたか。
      setInvalidToken();
    } else {
      dispatch(
        setGlobalErrors('パスワードは6文字以上で入力してくださいまたは確認用パスワードとパスワードの入力が一致しません')
      );
    }
  }
};
