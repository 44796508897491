import React from 'react';
import axios from 'axios';

import * as Urls from 'src/constants/EndpointUrls';
import CommonModal from 'jbc-front/components/CommonModal';
import CitySearchForm from './CitySearchForm';

class CitySearchModal extends React.Component {
  state = {
    cities: [],
    searchCount: 0,
    currentPage: 0,
    totalPages: 0,
    serverError: ''
  };

  searchCities = (inputText, page = 1) => {
    axios
      .get(Urls.SEARCH_CITY_URL_WITH_PREFECTURE, { params: { query: inputText, page } })
      .then(response => {
        const payload = response.data.payload;
        this.setState({
          ...this.state,
          cities: payload.cities,
          searchCount: payload.count,
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          serverError: ''
        });
      })
      .catch(exception => {
        const errors = exception.response.data.errors;
        this.setState({
          ...this.state,
          cities: [],
          searchCount: 0,
          currentPage: 0,
          totalPages: 0,
          serverError: errors[0]
        });
      });
  };

  render() {
    const { isOpen, hideModal, onSelectCity, selectedCity } = this.props;
    return (
      <CommonModal isOpen={isOpen} hideModal={hideModal} closeOnClick onModalShow={this.searchCities}>
        <CommonModal.Header hideModal={hideModal}>市区町村選択</CommonModal.Header>
        <CommonModal.Body>
          <CitySearchForm
            {...{ hideModal, onSelectCity, selectedCity }}
            cities={this.state.cities}
            searchCount={this.state.searchCount}
            currentPage={this.state.currentPage}
            totalPages={this.state.totalPages}
            serverError={this.state.serverError}
            searchCities={this.searchCities}
          />
        </CommonModal.Body>
      </CommonModal>
    );
  }
}

export default CitySearchModal;
