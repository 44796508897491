import React from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  setImportPayCsvJobInvisible,
  fetchImportPayCsvJobs,
  getImportPayCsvJobs
} from 'src/reducers/employees/employeePays';
import { Close } from 'jbc-front/components/icons';
import { JobFailed, JobInprogress, JobSuccess } from 'src/components/JobStatus';
import { INITIAL_POLLING_INTERVAL, MAX_POLLING_INTERVAL } from 'src/constants/Generals';
import styles from './ImportPayCsvJobStatus.scss';

const ImportJobResult = ({ tasks, status, hideImportJobResults }) => {
  let statusText = 'の給与データをインポートしました。';
  let tasksStyle = styles.tasksSuccess;

  if (status === 'failed') {
    statusText = 'の給与データをインポートできませんでした。';
    tasksStyle = styles.tasksFailed;
  } else if (status === 'inProgress' || status === 'waiting') {
    statusText = 'の給与データをインポート中です。';
    tasksStyle = styles.tasksInprogress;
  }

  return (
    <React.Fragment>
      {!isEmpty(tasks) &&
        tasks.length > 0 && (
          <div className={tasksStyle}>
            <div className={styles.inner}>
              {status === 'success' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideImportJobResults(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobSuccess key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
              {status === 'failed' && (
                <React.Fragment>
                  <div className={styles.close} role="presentation" onClick={() => hideImportJobResults(tasks)}>
                    <Close size={20} />
                  </div>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobFailed key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
              {(status === 'inProgress' || status === 'waiting') && (
                <React.Fragment>
                  <ul className={styles.list}>
                    {tasks.map(task => <JobInprogress key={task.id} statusText={statusText} {...task} />)}
                  </ul>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      {isEmpty(tasks) && null}
    </React.Fragment>
  );
};

class ImportPayCsvJobStatus extends React.Component {
  componentDidMount() {
    this.props.fetchVisibleImportCsvJobs();
    this.pollFetchVisibleImportCsvJobs(INITIAL_POLLING_INTERVAL);
  }

  pollFetchVisibleImportCsvJobs = interval => {
    const { importJobs, fetchVisibleImportCsvJobs } = this.props;
    let newInterval = interval;
    if (importJobs && (Object.keys(importJobs).includes('inProgress') || Object.keys(importJobs).includes('waiting'))) {
      fetchVisibleImportCsvJobs();
      if (newInterval < MAX_POLLING_INTERVAL) {
        newInterval *= 2;
      }
    }
    setTimeout(this.pollFetchVisibleImportCsvJobs, newInterval, newInterval);
  };

  render() {
    const { importJobs = {}, hideImportJobResults } = this.props;
    const sortCondition = (_status1, status2) => {
      if (status2 === 'inProgress' || status2 === 'waiting') return -1; // In progress tasks are on bottom
      if (status2 === 'success') return -1; // Successful tasks are on top
      return 0; // Failed tasks are in middle
    };
    return (
      <React.Fragment>
        {isEmpty(importJobs) ? null : (
          <div>
            {Object.keys(importJobs)
              .sort(sortCondition)
              .map(status => (
                <ImportJobResult
                  key={status}
                  tasks={importJobs[status]}
                  status={status}
                  hideImportJobResults={hideImportJobResults}
                />
              ))}
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  state => ({
    importJobs: getImportPayCsvJobs(state)
  }),
  dispatch => ({
    hideImportJobResults: tasks => {
      if (isEmpty(tasks)) return;

      const taskIds = tasks.map(j => j.id).join('_');
      dispatch(setImportPayCsvJobInvisible(taskIds));
    },
    fetchVisibleImportCsvJobs: () => dispatch(fetchImportPayCsvJobs(true))
  })
)(ImportPayCsvJobStatus);
