import React from 'react';
import axios from 'axios';
import BankSearchModal from 'jbc-front/components/BankSearchModal';

import * as Urls from 'src/constants/EndpointUrls';

const initialState = {
  selectedBank: null,
  banks: [],
  branches: [],
  count: 0,
  currentPage: 0,
  totalPages: 0
};

class BankSearchModalWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  _handleBankSearch = (query, page) => {
    const params = page !== undefined ? { query, page } : { query };
    axios
      .get(Urls.SEARCH_BANKS_WITH_PAGE_URL, { params })
      .then(response => {
        const payload = response.data.payload;
        this.setState({
          banks: payload.banks,
          branches: [],
          count: payload.count,
          currentPage: payload.currentPage,
          totalPages: payload.totalPages
        });
      })
      .catch(response => {
        console.log(response);
      });
  };

  _handleBranchSearch = (bank, query, page) => {
    const params = page !== undefined ? { bank_id: bank.id, query, page } : { bank_id: bank.id, query };
    axios
      .get(Urls.SEARCH_BANK_BRANCHES_WITH_PAGE_URL, { params })
      .then(response => {
        const payload = response.data.payload;
        this.setState({
          banks: [],
          branches: payload.bankBranches,
          count: payload.count,
          currentPage: payload.currentPage,
          totalPages: payload.totalPages
        });
      })
      .catch(response => {
        console.log(response);
      });
  };

  render() {
    const { isOpen, hideModal, onSelect } = this.props;
    const { selectedBank, banks, branches, count, currentPage, totalPages } = this.state;

    return (
      <BankSearchModal
        isOpen={isOpen}
        hideModal={hideModal}
        selectedBank={selectedBank}
        banks={banks}
        branches={branches}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        handleBankSearch={this._handleBankSearch}
        handleBranchSearch={this._handleBranchSearch}
        onBankSelect={bank => {
          this.setState({
            selectedBank: bank,
            banks: []
          });
          this._handleBranchSearch(bank, '');
        }}
        onReset={() => {
          this.setState(initialState);
          this._handleBankSearch('');
        }}
        onSelect={(bank, branch) => {
          onSelect(bank, branch);
          this.setState(initialState);
          hideModal();
        }}
        onHide={() => this.setState(initialState)}
      />
    );
  }
}

export default BankSearchModalWrapper;
