import { connect } from 'react-redux';
import { REDUCER_NAME } from 'src/reducers/offices/offices';
import { getItem } from 'src/reducers/common/itemReducer';
import { getSelectOptions } from 'src/reducers/selectOptions';
import selector from 'src/utils/Utils';
import BanksSection from '../../components/show/BanksSection';

const mapStateToProps = state => ({
  banks: selector(getItem(REDUCER_NAME, state), 'clientBanks'),
  depositTypes: getSelectOptions(state, 'depositTypes')
});

export default connect(mapStateToProps)(BanksSection);
