import React from 'react';
import { connect } from 'react-redux';

import {
  USER_PROFILE_EDIT,
  USER_ACCOUNT_EDIT,
  EMPLOYEE_MY_BONUS,
  EMPLOYEE_MY_PAYSLIPS,
  EMPLOYEE_MY_INCOME_TAX_WITHHOLDING
} from 'src/constants/EndpointUrls';
import ListGroup from 'jbc-front/components/ListGroup';
import { getCurrentUserInfo, isRoleTypeAdmin, isRoleTypeOperator } from 'src/reducers/users/currentUser';

export class InnerSettingSideBar extends React.Component {
  // プロフィール編集へのメニューリンク
  profileItem() {
    return (
      <ListGroup.Item as="a" href={USER_PROFILE_EDIT}>
        プロフィール<ListGroup.Icon />
      </ListGroup.Item>
    );
  }
  // アカウント設定へのメニューリンク
  accountItem() {
    return (
      <ListGroup.Item as="a" href={USER_ACCOUNT_EDIT}>
        アカウント設定<ListGroup.Icon />
      </ListGroup.Item>
    );
  }
  // 管理者権限のみのメニューリンク
  // 管理者が自分の明細を確認するために使用される
  // 従業員なしアカウントは表示しない
  adminEmployee() {
    return (
      <React.Fragment>
        <ListGroup.Item as="a" href={EMPLOYEE_MY_PAYSLIPS}>
          給与明細<ListGroup.Icon />
        </ListGroup.Item>
        <ListGroup.Item as="a" href={EMPLOYEE_MY_BONUS}>
          賞与明細<ListGroup.Icon />
        </ListGroup.Item>
        <ListGroup.Item as="a" href={EMPLOYEE_MY_INCOME_TAX_WITHHOLDING}>
          源泉徴収票<ListGroup.Icon />
        </ListGroup.Item>
      </React.Fragment>
    );
  }
  render() {
    const { currentUser } = this.props;
    // 従業員を持つアカウントは、アカウント設定への動線は持たない(共通ID側で制御するため)
    // 従業員を持たないアカウントは、プロフィールへの動線は持たない(プロフィールがないため)
    // 従業員&管理者の場合は、自分の明細への動線を持つ
    return (
      <ListGroup>
        {currentUser.hasEmployee && this.profileItem()}
        {!currentUser.hasEmployee && this.accountItem()}
        {currentUser.hasEmployee &&
          (isRoleTypeAdmin(currentUser.roleType) || isRoleTypeOperator(currentUser.roleType)) &&
          this.adminEmployee()}
      </ListGroup>
    );
  }
}

export default connect(state => ({
  currentUser: getCurrentUserInfo(state)
}))(InnerSettingSideBar);
