import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import * as Urls from 'src/constants/EndpointUrls';
import { FormSection, change as changeForm, touch as formTouch } from 'redux-form';
import { useSelector, useDispatch } from 'react-redux';

import { EMPLOYEE_FORM } from 'src/constants/FormNames';
import { handlePostalCodeChange } from 'src/utils/Form';
import { PostalCodeField } from 'src/components';
import ExtensionSelectField from 'src/components/ExtensionSelectField';
import Button from 'jbc-front/components/Button';
import { Copy } from 'jbc-front/components/icons';
import { BoxDouble, TextField } from 'jbc-front/components/Form';
import { copyEmployeeAddress } from 'src/reducers/employees/employees';
import { getSelectOptions } from 'src/reducers/selectOptions';
import styles from './ResidentCardInfoSection.scss';

const ResidentCardInfoSection = () => {
  const dispatch = useDispatch();
  const prefectures = useSelector(state => getSelectOptions(state, 'prefectures'));

  const copy = () => {
    dispatch(copyEmployeeAddress());
  };
  const complementAddress = ({ prefectureId, city, street, prefectureKana, cityKana, streetKana } = {}) => {
    const addressKana = [prefectureKana, cityKana, streetKana].map(e => e || '').join('');
    dispatch(changeForm(EMPLOYEE_FORM, `residentCard.prefectureId`, prefectureId || ''));
    dispatch(changeForm(EMPLOYEE_FORM, `residentCard.city`, city || ''));
    dispatch(changeForm(EMPLOYEE_FORM, `residentCard.street`, street || ''));
    dispatch(changeForm(EMPLOYEE_FORM, `residentCard.addressKana`, addressKana));
  };
  const notifyPostalCodeChanged = async code => {
    try {
      const response = await axios.get(Urls.SEARCH_POSTAL_CODE_URL, { params: { code } });
      complementAddress(response.data.payload.postalInfo);
    } catch (exception) {
      complementAddress();
      formTouch(EMPLOYEE_FORM, `residentCard.postalCode`);
    }
  };

  const handlePostalCodeChanged = (e, inputText) =>
    handlePostalCodeChange(e)(inputText, _.debounce(notifyPostalCodeChanged, 500));

  return (
    <div>
      <FormSection name="residentCard">
        <div className={styles.buttonWrap}>
          <Button className={styles.copyButton} onClick={copy} icon={<Copy size={17} />}>
            現住所をコピーする
          </Button>
        </div>
        <PostalCodeField handleOnChange={handlePostalCodeChanged} />
        <BoxDouble>
          <ExtensionSelectField label="都道府県" name="prefectureId" options={prefectures} />
          <TextField label="市区町村" name="city" type="text" />
        </BoxDouble>
        <BoxDouble>
          <TextField label="丁目番地号" name="street" type="text" />
          <TextField label="建物名" name="building" type="text" />
        </BoxDouble>
        <TextField label="住所カナ" name="addressKana" type="text" />
      </FormSection>
    </div>
  );
};

export default ResidentCardInfoSection;
