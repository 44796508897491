import React from 'react';

import { ACCOUNT_REGISTRATION_NEW, USER_LOGIN } from 'src/constants/EndpointUrls';
import Button from 'jbc-front/components/Button';

const HomePage = () => (
  <div className="u-ta-c">
    <div style={{ padding: '200px' }}>いい感じのHome画面</div>
    <div style={{ paddingBottom: '30px' }}>
      <a href={ACCOUNT_REGISTRATION_NEW} style={{ display: 'block', marginBottom: '30px' }}>
        <Button large primary widthAuto>
          アカウント登録
        </Button>
      </a>
      <a href={USER_LOGIN} style={{ display: 'block' }}>
        <Button large primary widthAuto>
          ログイン
        </Button>
      </a>
    </div>
  </div>
);

export default HomePage;
