import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { getFormValues } from 'redux-form';
import ActionButton from 'jbc-front/components/ActionButton';
import PdfFile from 'jbc-front/components/icons/PdfFile';
import { formatDate } from 'src/utils/Date';
import { getOptionLabel, getGroupFullPathName } from 'src/utils/Utils';
import { getSelectOptions } from 'src/reducers/selectOptions';
import { CheckboxFieldWithoutForm } from 'src/components';
import PreviewPdfModalContainer from 'src/containers/PreviewPdfModalContainer';
import { changeChecked } from 'src/reducers/reports/reportWageLedger';
import { WAGE_LEDGER_PERSONAL_PDF } from 'src/constants/EndpointUrls';
import { FORM_NAME_WAGE_LEDGER_SEARCH } from 'src/reducers/searchForm';
import classNames from 'classnames';

import styles from './ReportWageLedgerTableRow.scss';

class ReportWageLedgerTableRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isPdfPreview: false
    };
  }

  showPreview = (year, employeeId, yearMonthStart, yearMonthEnd, bonus, yearSelected) => {
    this.setState({
      ...this.state,
      isPdfPreview: true,
      year,
      employeeId,
      yearMonthStart,
      yearMonthEnd,
      bonus,
      yearSelected
    });
  };
  hidePreview = () => {
    this.setState({ ...this.state, isPdfPreview: false });
  };
  personalPdfUrl = (year, employeeId, yearMonthStart, yearMonthEnd, bonus, yearSelected) =>
    `${WAGE_LEDGER_PERSONAL_PDF}?employee_ids=${employeeId}&year=${year}&year_month_start=${yearMonthStart}&year_month_end=${yearMonthEnd}&bonus=${bonus}&year_selected=${yearSelected}`;

  render() {
    const {
      year,
      checked,
      id,
      staffCode,
      name,
      employmentTypeId,
      groupId,
      joinedOn,
      resignedOn,
      groups,
      clientEmploymentTypes,
      onChangeCheckedClick,
      formValues
    } = this.props;
    const tdClass = checked ? 'm-table-checkbox-on' : 'm-table-checkbox-off';
    const trClass = checked ? styles.abled : styles.disabled;

    const yearMonthStart = _.get(formValues, 'yearMonthStart');
    const yearMonthEnd = _.get(formValues, 'yearMonthEnd');
    const bonus = _.get(formValues, 'bonus') ? 'true' : 'false';
    const yearSelected = _.get(formValues, 'yearSelected') ? 'true' : 'false';

    return (
      <tr className={classNames('table-hover', styles.tableRow)}>
        <td
          role="presentation"
          className={tdClass}
          onClick={e => {
            e.stopPropagation();
            onChangeCheckedClick(id);
          }}
        >
          <CheckboxFieldWithoutForm checked={checked} readOnly={'readonly'} />
        </td>
        <td className={`${trClass}`}>{staffCode}</td>
        <td className={`${trClass}`}>{name}</td>
        <td className={`${trClass}`}>{getOptionLabel(employmentTypeId)(clientEmploymentTypes)}</td>
        <td className={`${trClass}`}>{!!groupId && <div>{getGroupFullPathName(groups, groupId)}</div>}</td>
        <td className={`${trClass}`}>{formatDate(joinedOn)}</td>
        <td className={`${trClass}`}>{formatDate(resignedOn)}</td>
        <td className={`${styles.abled}`}>
          <ActionButton
            primary
            icon={<PdfFile size={18} />}
            onClick={() => {
              this.showPreview(year, id, yearMonthStart, yearMonthEnd, bonus, yearSelected);
            }}
          >
            PDF出力
          </ActionButton>
        </td>
        {this.state.isPdfPreview && (
          <PreviewPdfModalContainer
            header="賃金台帳"
            isOpen={this.state.isPdfPreview}
            hideModal={this.hidePreview}
            url={this.personalPdfUrl(
              this.state.year,
              this.state.employeeId,
              yearMonthStart,
              yearMonthEnd,
              bonus,
              yearSelected
            )}
            downloadUrl={this.personalPdfUrl(
              this.state.year,
              this.state.employeeId,
              yearMonthStart,
              yearMonthEnd,
              bonus,
              yearSelected
            )}
          />
        )}
      </tr>
    );
  }
}

export default connect(
  state => ({
    groups: getSelectOptions(state, 'groups'),
    clientEmploymentTypes: getSelectOptions(state, 'employmentTypes'),
    formValues: getFormValues(FORM_NAME_WAGE_LEDGER_SEARCH)(state)
  }),
  dispatch => ({
    onChangeCheckedClick: id => dispatch(changeChecked(id))
  })
)(ReportWageLedgerTableRow);
