import axios from 'axios';
import { combineReducers } from 'redux';
import { initialize as initializeForm } from 'redux-form';

import { OCCUPATION_FORM, EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';
import * as Urls from 'src/constants/EndpointUrls';
import createNamedWrapperReducer from 'src/reducers/createNamedWrapperReducer';
import { editableListReducer } from 'src/reducers/common';
import {
  updateList,
  updateListSuccess,
  getList,
  getFormNewFields,
  getFormEditFields,
  getFormDeleteFields,
  updateListFailureWithData
} from 'src/reducers/common/editableListReducer';

export const REDUCER_NAME = 'occupations';

// Async Action Creators
export const updateOccupations = data => async (dispatch, getState) => {
  try {
    dispatch(updateList(REDUCER_NAME));
    const httpBody = {
      clientDepartments: {
        creatings: getFormNewFields(getState(), REDUCER_NAME, data),
        updatings: getFormEditFields(getState(), REDUCER_NAME, data),
        deletings: getFormDeleteFields(getState(), REDUCER_NAME, data)
      }
    };
    const response = await axios.post(Urls.CREATE_CLIENT_OCCUPATIONS_URL, httpBody);
    const values = response.data.payload;
    dispatch(updateListSuccess(REDUCER_NAME, values.clientDepartments));
    dispatch(initializeForm(OCCUPATION_FORM, { [EDITABLE_LIST_FIELDS_NAME]: getList(REDUCER_NAME, getState()) }));
  } catch (exception) {
    dispatch(
      updateListFailureWithData(REDUCER_NAME, {
        messages: exception.response.data.errors.messages,
        data: exception.response.data.errors.clientDepartments
      })
    );
    dispatch(
      initializeForm(OCCUPATION_FORM, { [EDITABLE_LIST_FIELDS_NAME]: exception.response.data.errors.clientDepartments })
    );
  }
};

export default combineReducers({
  list: createNamedWrapperReducer(editableListReducer, REDUCER_NAME)
});
