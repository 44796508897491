import React from 'react';
import Modal, { withModal } from 'jbc-front/components/CommonModal';
import { connect } from 'react-redux';
import moment from 'moment';
import { setGlobalErrors } from 'src/reducers/global';
import PaymentMethodForm from './PaymentMethodForm';

const ChangePaymentMethod = ({ isModalOpen, hideModal, showModal, employeeEmail, dispatch }) => (
  <React.Fragment>
    <a
      className="u-txt-link u-ml10"
      onClick={() => {
        const now = moment().utcOffset(9);
        if (now.date() === 1 && now.hour() <= 3 && now.hour() >= 0) {
          dispatch(setGlobalErrors('毎月1日0時から4時までは支払い方法の変更ができません'));
        } else {
          showModal();
        }
      }}
      role="button"
      tabIndex="-1"
    >
      変更
    </a>
    <Modal isOpen={isModalOpen}>
      <Modal.Header hideModal={hideModal}>決済方法を選択してください</Modal.Header>
      {isModalOpen && (
        <PaymentMethodForm
          hideModal={hideModal}
          initialValues={{
            paymentMethodType: 'credit_card',
            creditInfo: {
              employeeEmail
            }
          }}
        />
      )}
    </Modal>
  </React.Fragment>
);

export default connect()(withModal(ChangePaymentMethod));
