import React from 'react';
import { CheckboxField } from 'jbc-front/components/Form';
import SelectBoxYear from '../containers/SelectBoxYear';
import SelectBoxYearMonth from '../containers/SelectBoxYearMonth';
import styles from './SelectPeriodForm.scss';

class SelectPeriodForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      yearSelected: true
    };
  }

  // 年単位と期間指定の切り替え
  handleChangePeriodSelector = newValue => {
    const { changePeriodSelector } = this.props;
    this.setState({ yearSelected: newValue });
    changePeriodSelector(newValue);
  };

  render() {
    return (
      <div className={styles.box}>
        <ul className={styles.periodSelector}>
          <li>
            <button
              className={this.state.yearSelected === true ? styles.periodSelectorActive : ''}
              onClick={() => this.handleChangePeriodSelector(true)}
            >
              年単位
            </button>
          </li>
          <li>
            <button
              className={this.state.yearSelected === false ? styles.periodSelectorActive : ''}
              onClick={() => this.handleChangePeriodSelector(false)}
            >
              期間指定
            </button>
          </li>
        </ul>
        <span>
          {this.state.yearSelected === true ? (
            <span className={styles.period}>
              <SelectBoxYear />
            </span>
          ) : (
            <span className={styles.period}>
              <SelectBoxYearMonth />
            </span>
          )}
        </span>
        <span className={styles.bonus}>
          <CheckboxField name="bonus" label="賞与を含む" />
        </span>
      </div>
    );
  }
}
export default SelectPeriodForm;
