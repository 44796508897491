import React, { useState, useCallback } from 'react';
import { ArrowDownSquare, ArrowRightSquare, Employee, Delete } from 'jbc-front/components/icons';
import classNames from 'classnames';
import { concatParamsToUrl, redirectTo, serializeHttpGetParams } from 'src/utils/Http';
import { OFFICE_EDIT, OFFICE_SHOW, EMPLOYEES_LIST } from 'src/constants/EndpointUrls';
import styles from '../OfficeList.scss';
import DeleteOfficeConfirmModal from './DeleteOfficeConfirmModal';

const OfficeDetail = props => {
  const { office } = props;
  const [clicked, setClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const officeUrl = officeId => concatParamsToUrl(OFFICE_SHOW, { id: officeId });
  const employeesUrl = officeId => `${EMPLOYEES_LIST}?${serializeHttpGetParams({ office_ids: officeId })}`;

  const handleClick = () => {
    setClicked(!clicked);
  };

  const redirectToOffice = useCallback(
    () => {
      redirectTo(officeUrl(office.id));
    },
    [office.id]
  );

  return (
    <React.Fragment>
      <tr key={office.id}>
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <td onClick={handleClick} className={styles.clickable}>
          <span className={styles.icon}>
            {clicked ? <ArrowDownSquare size={15} /> : <ArrowRightSquare size={15} />}
          </span>
        </td>
        <td className={styles.clickable} onClick={redirectToOffice}>
          {office.manageCode}
        </td>
        <td className={styles.clickable} onClick={redirectToOffice}>
          {office.manageName}
        </td>
        <td className={styles.clickable} onClick={redirectToOffice}>
          {office.address}
        </td>
        <td className={styles.clickable} onClick={redirectToOffice}>
          {office.tel}
        </td>
        {/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */}
        <td>
          <span className={classNames(styles.icon)}>
            <Employee onClick={() => redirectTo(employeesUrl(office.id))} size={18} />
          </span>
          {office.officeType !== 'main' &&
            (office.deletable ? (
              <span className={styles.icon}>
                <Delete
                  onClick={() => {
                    setIsOpen(true);
                  }}
                  size={18}
                />
              </span>
            ) : (
              <span className={classNames(styles.icon, styles.disabled)}>
                <Delete onClick={e => e.preventDefault()} size={18} />
              </span>
            ))}
        </td>
      </tr>
      <tr className={clicked ? styles.officeDetail : styles.hiddenOfficeDetail}>
        <td colSpan={6} className={styles.officeDetailContent}>
          <table className={styles.table}>
            <tbody>
              <tr>
                <th>事業所名：</th>
                <td>{office.name}</td>
              </tr>
              <tr>
                <th>法人番号：</th>
                <td>{office.corporateNumber}</td>
              </tr>
              <tr>
                <th>事業主（代表者）：</th>
                <td>{office.representativeName}</td>
              </tr>
            </tbody>
          </table>
          <table className={styles.table}>
            <tbody>
              <tr>
                <th>社会保険の適用事業所：</th>
                <td>
                  {office.socialInsuranceOffice && (
                    <a
                      className={styles.applicableOfficeLink}
                      href={concatParamsToUrl(`${OFFICE_EDIT}#SocialInsuranceInformation`, {
                        id: office.socialInsuranceOffice.id
                      })}
                    >
                      {office.socialInsuranceOffice.manageName}
                    </a>
                  )}
                </td>
              </tr>
              <tr>
                <th>労災保険の適用事業所：</th>
                <td>
                  {office.occupationAccidentInsuranceOffice && (
                    <a
                      className={styles.applicableOfficeLink}
                      href={concatParamsToUrl(`${OFFICE_EDIT}#LabourInsuranceInformation`, {
                        id: office.occupationAccidentInsuranceOffice.id
                      })}
                    >
                      {office.occupationAccidentInsuranceOffice.manageName}
                    </a>
                  )}
                </td>
              </tr>
              <tr>
                <th>雇用保険の適用事業所：</th>
                <td>
                  {office.employmentInsuranceOffice && (
                    <a
                      className={styles.applicableOfficeLink}
                      href={concatParamsToUrl(`${OFFICE_EDIT}#LabourInsuranceInformation`, {
                        id: office.employmentInsuranceOffice.id
                      })}
                    >
                      {office.employmentInsuranceOffice.manageName}
                    </a>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
      <DeleteOfficeConfirmModal
        hideModal={() => {
          setIsOpen(false);
        }}
        isOpen={isOpen}
        office={office}
      />
    </React.Fragment>
  );
};
export default OfficeDetail;
