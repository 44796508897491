import React from 'react';
import { FieldArray } from 'redux-form';

import { EDITABLE_LIST_FIELDS_NAME } from 'src/constants/FormNames';

import SortableListFields from './SortableListFields';

const SortableList = ({ mode, fieldName, handleSubmit, type, fieldCode = 'departmentCode' }) => (
  <form onSubmit={handleSubmit}>
    <div className="u-mt30">
      <FieldArray
        name={EDITABLE_LIST_FIELDS_NAME}
        component={SortableListFields}
        fieldName={fieldName}
        fieldCode={fieldCode}
        mode={mode}
        type={type}
      />
    </div>
  </form>
);

export default SortableList;
