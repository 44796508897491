import React, { useState, useEffect } from 'react';
import { FormSection, change as changeForm } from 'redux-form';

import { OFFICE_FORM } from 'src/constants/FormNames';
import { Section } from 'jbc-front/components/Form';
import { DeleteSquare, Copy } from 'jbc-front/components/icons';
import Button from 'jbc-front/components/Button';
import OfficeSection from '../../containers/edit/OfficeSectionContainer';
import styles from './LabourInsuranceContactInfoSection.scss';

const LabourInsuranceContactInfoSection = ({ office, labourInsuranceContactInfo, useContactInfo, dispatch }) => {
  const [isManual, setIsManual] = useState(useContactInfo === 'manual');
  const deleteIcon = () => {
    if (isManual) {
      return (
        <DeleteSquare
          onClick={() => {
            setIsManual(false);
          }}
        />
      );
    }
    return null;
  };

  const handleCopyButton = () => {
    Object.keys(labourInsuranceContactInfo).forEach(k => {
      if (k !== 'id') {
        dispatch(changeForm(OFFICE_FORM, `labourInsuranceContactInfo.${k}`, office[k]));
      }
    });
  };

  useEffect(
    () => {
      if (isManual) {
        dispatch(changeForm(OFFICE_FORM, 'accidentInsurance.useContactInfo', 'manual'));
      } else {
        dispatch(changeForm(OFFICE_FORM, 'accidentInsurance.useContactInfo', 'main_office'));
        dispatch(changeForm(OFFICE_FORM, 'employmentInsurance.useContactInfo', 'main_office'));
      }
    },
    [isManual]
  );

  return (
    <Section title="労働保険 事業所情報" icon={deleteIcon()}>
      {isManual && (
        <React.Fragment>
          <div className={styles.copyField}>
            <p>労働保険の事業所情報が社会保険の事業所情報と異なる場合、ここに事業所情報を別途入力してください。</p>
            <p className={styles.subCaption}>
              ※このページ上部で入力した事業所情報と労働保険の事業所情報が同一の場合は、右上のゴミ箱ボタンを押してフォームを閉じてください。
            </p>
            <Button className={styles.copyButton} onClick={handleCopyButton}>
              <Copy />事業所情報をコピーする
            </Button>
          </div>
          <FormSection name="labourInsuranceContactInfo">
            <OfficeSection formSectionName={'labourInsuranceContactInfo'} />
          </FormSection>
        </React.Fragment>
      )}

      {!isManual && (
        <div className={styles.copyField}>
          <p>
            労働保険の事業所情報が事業所情報と異なる場合、このボタンをクリックして事業所情報を別途入力してください。
          </p>
          <p className={styles.subCaption}>
            ※このページ上部で入力した事業所情報と労働保険の事業所情報が同一の場合は入力の必要はありません。
          </p>
          <Button
            className={styles.copyButton}
            onClick={() => {
              setIsManual(true);
            }}
          >
            労働保険事業所情報を別途入力
          </Button>
        </div>
      )}
    </Section>
  );
};

export default LabourInsuranceContactInfoSection;
