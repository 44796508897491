import React from 'react';
import { FormSection, Field } from 'redux-form';
import _ from 'lodash';

import { isMoneyNumber10, isEmployeeNumber5 } from 'src/utils/CustomValidators';
import { numberWithComma } from 'src/utils/Utils';
import EditableTextField from './EditableTextField';
import styles from './MonthlySummary.scss';

class MonthlySummaryAccidentInsurance extends React.Component {
  constructor(props) {
    super(props);
    const { monthlySummary } = props;
    this.state = { ...monthlySummary };
  }

  componentDidMount() {
    this.calculation({ ...this.state });
    this.props.onRef(this);
  }

  componentWillUnmount() {
    this.props.onRef(undefined);
  }

  calculation(params) {
    const calcValues = {
      totalAccidentInsuranceEmployeeCount: this.calcTotalAccidentInsuranceCount(params),
      totalAccidentInsuranceEmployeePay: this.calcTotalAccidentInsurancePay(params),
      totalEmploymentInsuranceEmployeeCount: this.calcTotalEmploymentInsuranceCount(params),
      totalEmploymentInsuranceEmployeePay: this.calcTotalEmploymentInsurancePay(params)
    };

    this.setState({
      ...params,
      ...calcValues
    });

    return { ...params, ...calcValues };
  }

  // 労災保険及び一般拠出金の合計人数
  calcTotalAccidentInsuranceCount(params) {
    return this.sum([
      params.accidentInsuranceEmployeeCommonCount,
      params.accidentInsuranceEmployeeTreatedCount,
      params.accidentInsuranceEmployeeTemporaryCount
    ]);
  }

  // 労災保険及び一般拠出金の合計賃金
  calcTotalAccidentInsurancePay(params) {
    return this.sum([
      params.accidentInsuranceEmployeeCommonPay,
      params.accidentInsuranceEmployeeTreatedPay,
      params.accidentInsuranceEmployeeTemporaryPay
    ]);
  }

  // 雇用保険の合計人数
  calcTotalEmploymentInsuranceCount(params) {
    return this.sum([params.employmentInsuranceEmployeeCommonCount, params.employmentInsuranceEmployeeTreatedCount]);
  }

  // 雇用保険の合計賃金
  calcTotalEmploymentInsurancePay(params) {
    return this.sum([params.employmentInsuranceEmployeeCommonPay, params.employmentInsuranceEmployeeTreatedPay]);
  }

  sum(list) {
    // すべてが空の場合は空文字を返す
    if (_.every(list, o => !_.isNumber(o))) return '';

    _.filter(list, o => {
      _.toInteger(o);
    });

    return _.reduce(list, (sum, n) => sum + n, 0);
  }

  calcYearlySummary(changedParams) {
    const params = {
      ...this.state,
      ...changedParams
    };

    this.props.calcYearlySummary(this.calculation(params));
  }

  // 労災保険常用労働者の人数変更イベント
  onChangeAccidentInsuranceEmployeeCommonCount(value) {
    this.calcYearlySummary({ accidentInsuranceEmployeeCommonCount: _.toInteger(value) });
  }

  // 労災保険常用労働者の賃金変更イベント
  onChangeAccidentInsuranceEmployeeCommonPay(value) {
    this.calcYearlySummary({ accidentInsuranceEmployeeCommonPay: _.toInteger(value) });
  }

  // 労災保険役員兼従業員の人数変更イベント
  onChangeAccidentInsuranceEmployeeTreatedCount(value) {
    this.calcYearlySummary({ accidentInsuranceEmployeeTreatedCount: _.toInteger(value) });
  }

  // 労災保険役員兼従業員の賃金変更イベント
  onChangeAccidentInsuranceEmployeeTreatedPay(value) {
    this.calcYearlySummary({ accidentInsuranceEmployeeTreatedPay: _.toInteger(value) });
  }

  // 労災保険臨時労働者の人数変更イベント
  onChangeAccidentInsuranceEmployeeTemporaryCount(value) {
    this.calcYearlySummary({ accidentInsuranceEmployeeTemporaryCount: _.toInteger(value) });
  }

  // 労災保険臨時労働者の賃金変更イベント
  onChangeAccidentInsuranceEmployeeTemporaryPay(value) {
    this.calcYearlySummary({ accidentInsuranceEmployeeTemporaryPay: _.toInteger(value) });
  }

  displaySnap(attr, isInput) {
    const { actionMode, monthlySummary, monthlySummarySnap } = this.props;
    const style = actionMode === 'SHOW' ? styles.snap_show : styles.snap_edit;

    if (monthlySummarySnap === '') {
      return <div className={style}>{`(修正前: ー )`}</div>;
    }
    if (monthlySummary[isInput]) {
      return <div className={style}>{`(修正前: ${numberWithComma(monthlySummarySnap[attr])})`}</div>;
    }
    return null;
  }

  render() {
    const { actionMode, monthlySummary } = this.props;
    return (
      <FormSection name={`monthlySummaries.${monthlySummary.id}`}>
        <tr key={`monthly_summary_${monthlySummary.id}`}>
          <th>
            {monthlySummary.paymentType === 'pay' && monthlySummary.displayMonthlySummaryDate}
            {monthlySummary.paymentType === 'bonus' && `賞与 ${monthlySummary.displayMonthlySummaryDate}`}
          </th>
          <td>
            {monthlySummary.paymentType === 'pay' && (
              <React.Fragment>
                <Field
                  name="accidentInsuranceEmployeeCommonCount"
                  showValue={monthlySummary.accidentInsuranceEmployeeCommonCount}
                  showMode={actionMode}
                  inputWidth={40}
                  validate={[isEmployeeNumber5]}
                  component={EditableTextField}
                  editProps={{ maxLength: 5 }}
                  onChange={e => {
                    this.onChangeAccidentInsuranceEmployeeCommonCount(e.target.value);
                  }}
                  onBlur={e => {
                    e.preventDefault();
                    this.onChangeAccidentInsuranceEmployeeCommonCount(e.target.value);
                  }}
                />
                {this.displaySnap(
                  'accidentInsuranceEmployeeCommonCount',
                  'isInputAccidentInsuranceEmployeeCommonCount'
                )}
              </React.Fragment>
            )}
          </td>
          <td>
            <React.Fragment>
              <Field
                name="accidentInsuranceEmployeeCommonPay"
                showValue={monthlySummary.accidentInsuranceEmployeeCommonPay}
                showMode={actionMode}
                inputWidth={100}
                validate={[isMoneyNumber10]}
                component={EditableTextField}
                editProps={{ maxLength: 10 }}
                onChange={e => {
                  this.onChangeAccidentInsuranceEmployeeCommonPay(e.target.value);
                }}
                onBlur={e => {
                  e.preventDefault();
                  this.onChangeAccidentInsuranceEmployeeCommonPay(e.target.value);
                }}
              />
              {this.displaySnap('accidentInsuranceEmployeeCommonPay', 'isInputAccidentInsuranceEmployeeCommonPay')}
            </React.Fragment>
          </td>
          <td>
            {monthlySummary.paymentType === 'pay' && (
              <React.Fragment>
                <Field
                  name="accidentInsuranceEmployeeTreatedCount"
                  showValue={monthlySummary.accidentInsuranceEmployeeTreatedCount}
                  showMode={actionMode}
                  inputWidth={70}
                  validate={[isEmployeeNumber5]}
                  component={EditableTextField}
                  editProps={{ maxLength: 5 }}
                  onChange={e => {
                    this.onChangeAccidentInsuranceEmployeeTreatedCount(e.target.value);
                  }}
                  onBlur={e => {
                    e.preventDefault();
                    this.onChangeAccidentInsuranceEmployeeTreatedCount(e.target.value);
                  }}
                />
                {this.displaySnap(
                  'accidentInsuranceEmployeeTreatedCount',
                  'isInputAccidentInsuranceEmployeeTreatedCount'
                )}
              </React.Fragment>
            )}
          </td>
          <td>
            <React.Fragment>
              <Field
                name="accidentInsuranceEmployeeTreatedPay"
                showValue={monthlySummary.accidentInsuranceEmployeeTreatedPay}
                showMode={actionMode}
                inputWidth={70}
                validate={[isMoneyNumber10]}
                component={EditableTextField}
                editProps={{ maxLength: 10 }}
                onChange={e => {
                  this.onChangeAccidentInsuranceEmployeeTreatedPay(e.target.value);
                }}
                onBlur={e => {
                  e.preventDefault();
                  this.onChangeAccidentInsuranceEmployeeTreatedPay(e.target.value);
                }}
              />
              {this.displaySnap('accidentInsuranceEmployeeTreatedPay', 'isInputAccidentInsuranceEmployeeTreatedPay')}
            </React.Fragment>
          </td>
          <td>
            {monthlySummary.paymentType === 'pay' && (
              <React.Fragment>
                <Field
                  name="accidentInsuranceEmployeeTemporaryCount"
                  showValue={monthlySummary.accidentInsuranceEmployeeTemporaryCount}
                  showMode={actionMode}
                  inputWidth={70}
                  validate={[isEmployeeNumber5]}
                  component={EditableTextField}
                  editProps={{ maxLength: 5 }}
                  onChange={e => {
                    this.onChangeAccidentInsuranceEmployeeTemporaryCount(e.target.value);
                  }}
                  onBlur={e => {
                    e.preventDefault();
                    this.onChangeAccidentInsuranceEmployeeTemporaryCount(e.target.value);
                  }}
                />
                {this.displaySnap(
                  'accidentInsuranceEmployeeTemporaryCount',
                  'isInputAccidentInsuranceEmployeeTemporaryCount'
                )}
              </React.Fragment>
            )}
          </td>
          <td>
            <React.Fragment>
              <Field
                name="accidentInsuranceEmployeeTemporaryPay"
                showValue={monthlySummary.accidentInsuranceEmployeeTemporaryPay}
                showMode={actionMode}
                inputWidth={70}
                validate={[isMoneyNumber10]}
                component={EditableTextField}
                editProps={{ maxLength: 10 }}
                onChange={e => {
                  this.onChangeAccidentInsuranceEmployeeTemporaryPay(e.target.value);
                }}
                onBlur={e => {
                  e.preventDefault();
                  this.onChangeAccidentInsuranceEmployeeTemporaryPay(e.target.value);
                }}
              />
              {this.displaySnap(
                'accidentInsuranceEmployeeTemporaryPay',
                'isInputAccidentInsuranceEmployeeTemporaryPay'
              )}
            </React.Fragment>
          </td>
          <td>{numberWithComma(this.state.totalAccidentInsuranceEmployeeCount)}</td>
          <td>{numberWithComma(this.state.totalAccidentInsuranceEmployeePay)}</td>
        </tr>
      </FormSection>
    );
  }
}
export default MonthlySummaryAccidentInsurance;
