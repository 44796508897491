import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, getFormValues } from 'redux-form';

import classNames from 'classnames';
import compose from 'lodash/fp/compose';
import {
  REPORT_LABOR_INSURANCE_SUMMARIES,
  REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_DOWNLOAD_CSV_PAGE,
  REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_SEARCH
} from 'src/constants/EndpointUrls';
import SearchDetector from 'src/components/SearchDetector';
import DetailSearchForm from 'src/components/DetailSearchForm';
import SearchFormPaginator from 'src/components/SearchFormPaginator';
import { REDUCER_NAME, fetchEmployees } from 'src/reducers/employees/employees';
import {
  takeEmployeesSearchQueriesFromLocation,
  takeEmployeesSearchQueriesForLabor,
  takeEmployeesResetFormQueries,
  takeLaborInsuranceEmployeeDetailQueriedUrl,
  FORM_NAME_EMPLOYEES_SEARCH
} from 'src/reducers/searchForm';
import ActionButton from 'jbc-front/components/ActionButton';
import BreadCrumb from 'jbc-front/components/BreadCrumb';
import { getIsLoading, getList } from 'src/reducers/common/listReducer';
import LoadingView from 'src/components/LoadingView';
import { Upload } from 'jbc-front/components/icons';
import { redirectTo } from 'src/utils/Http';
import EmployeeTable from '../containers/EmployeeTableContainer';
import ImportCsvJobStatus from '../containers/ImportCsvJobStatusContainer';
import styles from './EmployeeList.scss';

class EmployeeList extends React.Component {
  render() {
    const {
      initialValues: formValues,
      location,
      resetValues,
      dispatch,
      isListLoading,
      employees,
      year,
      downloadCsvPageUrl
    } = this.props;

    return (
      <div>
        <div className="l-contents-wrap">
          <div className="l-wrap-xl">
            <SearchDetector
              location={location}
              didMountDetectingJudger={() => true}
              willReceivePropsDetectingJudger={(prevProps, nextProps) => nextProps.location !== prevProps.location}
              onDetect={() => {
                dispatch(
                  fetchEmployees(
                    takeEmployeesSearchQueriesForLabor(formValues),
                    undefined,
                    REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_SEARCH
                  )
                );
              }}
            />
            <div className="u-mb20">
              <BreadCrumb>
                <BreadCrumb.Item label="労働保険の年度更新" path={REPORT_LABOR_INSURANCE_SUMMARIES} />
                <BreadCrumb.Item label="内訳一覧" />
              </BreadCrumb>
            </div>
            <h1 className="m-title-main">内訳一覧</h1>
            <DetailSearchForm
              menuRender={() => (
                <ActionButton
                  primary
                  icon={<Upload size={15} />}
                  onClick={() => redirectTo(downloadCsvPageUrl)}
                  className={styles.summary_list_csv_download_button}
                >
                  CSVインポート
                </ActionButton>
              )}
              containsOtherSearch={false}
              formName={FORM_NAME_EMPLOYEES_SEARCH}
              containsOfficeSearch
              resetValues={resetValues}
            />
          </div>
          <ImportCsvJobStatus />
          <LoadingView isLoading={isListLoading}>
            <div className={classNames('l-wrap-xl', styles.list)}>
              <EmployeeTable employees={employees} laborInsuranceYear={year} />
              <SearchFormPaginator formName={FORM_NAME_EMPLOYEES_SEARCH} reducerName={REDUCER_NAME} />
            </div>
          </LoadingView>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    ...takeEmployeesSearchQueriesFromLocation(state.router.location, true),
    ...getFormValues(FORM_NAME_EMPLOYEES_SEARCH)(state),
    laborInsuranceYear: ownProps.year
  },
  employees: getList(REDUCER_NAME, state),
  isListLoading: getIsLoading(REDUCER_NAME, state),
  resetValues: takeEmployeesResetFormQueries(state, true),
  downloadCsvPageUrl: takeLaborInsuranceEmployeeDetailQueriedUrl(
    REPORT_LABOR_INSURANCE_EMPLOYEE_DETAILS_DOWNLOAD_CSV_PAGE,
    state
  )
});

export default compose(
  connect(mapStateToProps),
  reduxForm({
    form: FORM_NAME_EMPLOYEES_SEARCH,
    enableReinitialize: true
  })
)(EmployeeList);
