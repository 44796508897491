import { connect } from 'react-redux';
import { REDUCER_NAME } from 'src/reducers/employees/employees';
import { getItem } from 'src/reducers/common/itemReducer';
import selector from 'src/utils/Utils';
import ElectronicDeliveryAgreement from '../components/ElectronicDeliveryAgreement';

const mapStateToProps = state => ({
  employeeElectronicDeliveryAgreementDetail:
    selector(getItem(REDUCER_NAME, state), 'employeeElectronicDeliveryAgreementDetail') || [],
  employee: selector(getItem(REDUCER_NAME, state), 'employee') || {}
});

export default connect(mapStateToProps)(ElectronicDeliveryAgreement);
