import React from 'react';
import { connect } from 'react-redux';

import { CLIENT_SETTINGS } from 'src/constants/EndpointUrls';
import * as CssClassNames from 'src/constants/CssClassNames';
import { RadioField, BoxDouble } from 'jbc-front/components/Form';
import Button from 'jbc-front/components/Button';
import { getRadioOptions } from 'src/reducers/selectOptions';
import { ErrorsSection } from 'src/components';
import { CancelBtn } from 'src/buttons';

const ClientTaxCalMethodSettingForm = ({ handleSubmit, submitting, pristine, submit, errors, calMethods }) => (
  <form className={CssClassNames.FORM} onSubmit={handleSubmit}>
    <ErrorsSection errors={errors} />

    <RadioField required name="withholdingTaxCalculatingMethod" options={calMethods} />
    <BoxDouble>
      <CancelBtn href={CLIENT_SETTINGS} />
      <Button primary onClick={submit} disabled={submitting || pristine}>
        保存
      </Button>
    </BoxDouble>
  </form>
);

export default connect(state => ({
  calMethods: getRadioOptions(state, 'withholdingTaxCalculatingMethods')
}))(ClientTaxCalMethodSettingForm);
