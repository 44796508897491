import React from 'react';
import { display } from 'src/utils/Utils';
import tableStyles from '../styles/GeneralTableInfo.scss';

const ElectronicDeliveryAgreementShow = props => {
  const { employee, employeeElectronicDeliveryAgreementDetail } = props;

  return (
    <div>
      <table className={tableStyles.mTablePersonal}>
        <tbody>
          <tr>
            <th>同意状況：</th>
            <td>{employee.isElectronicDeliveryAgreement ? '同意済' : '未同意'}</td>
          </tr>
          <tr>
            <th>同意日：</th>
            <td>{display(employeeElectronicDeliveryAgreementDetail.agreementDate)}</td>
          </tr>
          <tr>
            <th>操作者：</th>
            <td>{display(employeeElectronicDeliveryAgreementDetail.displayUserName)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ElectronicDeliveryAgreementShow;
